import { Button } from "@zenfolio/core-components";
import React from "react";
import warningIcon from "../../../../../assets/icons/icon-warning.svg";
import ModalBase from "../../modalBase";
import styles from "./index.module.scss";

interface IGenericErrorProps {
  onClose: () => void;
}

class GenericError extends React.Component<IGenericErrorProps> {
  public render() {
    return (
      <ModalBase onClose={this.onModalClose} className={styles.modal} overlayClassName={styles.overlay}>
        <div className={styles.content}>
          <div className={styles.warning}>
            <img src={warningIcon} alt="warning" />
          </div>
          <h2>Uh-Oh!</h2>
          <h3>
            It looks like something failed.
            <br />
            Please try again later. If this continues to happen, please contact support@zenfolio.com for assistance.
          </h3>
          <Button className={styles.close} styleType="secondary" onClick={this.onCloseClick}>
            Close
          </Button>
        </div>
      </ModalBase>
    );
  }

  private onModalClose = () => this.props.onClose();

  private onCloseClick = () => this.props.onClose();
}

export default GenericError;
