import * as React from "react";
import { IWidgetStep, IWidgetStepProps } from "../../../contracts";
import PaymentDetails from "../../payment/booking/details";
import styles from "./index.module.scss";

interface IPaymentConfirmationProps extends IWidgetStepProps {
  resetValues: () => void;
  photographerTimezoneOffsetMinutes: number;
  initialTaxAmount?: number;
  totalTaxAmount?: number;
}

class Confirmation extends React.Component<IPaymentConfirmationProps> implements IWidgetStep {
  constructor(props: IPaymentConfirmationProps) {
    super(props);

    this.state = {
      detailsOpen: false
    };
  }

  public componentDidMount() {
    this.props.onRef(this);
  }

  public componentWillUnmount() {
    this.props.onRef(undefined);
  }

  public render() {
    const values = this.props.getValues();
    const { photographerTimezoneOffsetMinutes, initialTaxAmount, totalTaxAmount } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.title}>
            <p>Booking Confirmed.</p>
          </div>
          <div>{`An email confirmation has been sent to ${values.email}`}</div>
          <div>Thank you for your order!</div>
        </div>
        <PaymentDetails
          values={values}
          photographerTimezoneOffsetMinutes={photographerTimezoneOffsetMinutes}
          detailsOpen={false}
          initialTaxAmount={initialTaxAmount}
          totalTaxAmount={totalTaxAmount}
        />
      </div>
    );
  }

  public saveAndContinue = (): void => {
    this.props.resetValues();
  };

  public deferNavigation = (): boolean => {
    return true;
  };
}

export default Confirmation;
