import cx from "classnames";
import LoadingIcon from "components/Icons/Loading";
import { isEmpty } from "lodash";
import { IColor, IZenSiteTheme } from "../../../models/models";
import React from "react";
import { getSiteFonts } from "utilities/blocks/site";
import colors, { getContrastYIQ } from "utilities/colors";
import styles from "./zenButton.module.scss";

export interface IButtonProps {
  labelText?: string;
  layout?: string;
  disabled?: boolean;
  siteTheme: IZenSiteTheme;
  onClick?: () => void;
  accentColor?: IColor;
  isDarkBackground?: boolean;
  isEditionView?: boolean;
  className?: string;
  buttonRef?: React.Ref<HTMLButtonElement>;
  isLoading?: boolean;
}

class ZenButton extends React.Component<IButtonProps> {
  public render() {
    const {
      layout,
      labelText,
      disabled,
      siteTheme,
      isDarkBackground,
      className,
      buttonRef,
      isLoading,
      children
    } = this.props;
    const accentColor = this.props.accentColor || siteTheme.accentColor;
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    const styledButtonTextColor = getStyledButtonTextColor(accentColor);
    const buttonClass = cx(
      styles.button,
      layout && layout === "B" ? styles.layoutB : styles.layoutA,
      styles.buttonStyles,
      styles[siteFonts.primary],
      styles[siteTheme.buttonsStyle],
      accentColor ? "accentColor-" + accentColor.name : "",
      `textColor-${styledButtonTextColor.name}`,
      siteTheme.accentColor?.name.toUpperCase() === "WHITE" ? styles.inverted : "",
      disabled ? styles.disabled : "",
      isDarkBackground ? styles.darkBackground : ""
    );

    return (
      <button
        onClick={this.props.onClick}
        className={cx(buttonClass, className)}
        ref={buttonRef}
        style={!isLoading ? siteFonts.buttonStyles : undefined}
      >
        {accentColor && (
          <style>
            {`
              .accentColor-${accentColor.name}{
                --accent-color: ${accentColor.value};
              }

              .textColor-${styledButtonTextColor.name}{
                --text-color: ${styledButtonTextColor.value};
              }
            `}
          </style>
        )}
        {!isLoading ? (
          <div className={styles[siteFonts.secondary]}>{isEmpty(children) ? labelText : children}</div>
        ) : (
          <div className={styles.loading}>
            <LoadingIcon size={40} />
          </div>
        )}
      </button>
    );
  }
}

export default ZenButton;

export const getStyledButtonTextColor = (accentColor: IColor): IColor => {
  const contrastColor: string = getContrastYIQ(accentColor.value || "", {
    darkColor: colors.black,
    lightColor: colors.white
  });

  const buttonTextColor: IColor = {
    name: contrastColor === colors.black ? "Black" : "White",
    value: contrastColor
  };

  return buttonTextColor;
};
