import castArray from "lodash/castArray";
import { Editor } from "slate";

export const isMarkActive = (editor: Editor, type: string) => {
  const marks = Editor.marks(editor);
  return marks ? !!marks[type] : false;
};

export const isBlockActive = (editor: Editor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: (n: any) => n.type === format
  }) as any;
  return !!match;
};

export const findBlockActive = (editor: Editor, formats: string[]) => {
  const [match] = Editor.nodes(editor, {
    match: (n: any) => formats.includes(n.type)
  }) as any;
  return match;
};

export const toggleMark = (editor: Editor, key: string, clear: string | string[] = []) => {
  const isActive = isMarkActive(editor, key);
  if (isActive) {
    editor.removeMark(key);
    return;
  }

  const clears: string[] = castArray(clear);
  clears.forEach(item => {
    editor.removeMark(item);
  });
  editor.addMark(key, true);
};
