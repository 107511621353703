import * as React from "react";
import { IInvoiceInfo } from "../../../../../store/widget/model";
import Loader from "../../../../layout/loader";
import { IWidgetStep, IWidgetStepProps } from "../../../contracts";
import PaymentDetails from "../../payment/invoice/details";
import styles from "./index.module.scss";

interface IPaymentConfirmationProps extends IWidgetStepProps {
  switchWidgetMode: () => void;
  invoice: IInvoiceInfo;
}

class Confirmation extends React.Component<IPaymentConfirmationProps> implements IWidgetStep {
  constructor(props: IPaymentConfirmationProps) {
    super(props);

    this.state = {
      detailsOpen: false
    };
  }

  public componentDidMount() {
    this.props.onRef(this);
  }

  public componentWillUnmount() {
    this.props.onRef(undefined);
  }

  public render() {
    const { invoice } = this.props;

    const loading = !invoice;

    return loading ? (
      <Loader />
    ) : (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.title}>
            <p>Invoice Payment Confirmed.</p>
          </div>
          <div>{`An email confirmation has been sent to ${invoice.customerInfo.email}`}</div>
          <div>Thank you for your order!</div>
        </div>
        <PaymentDetails invoice={invoice} />
      </div>
    );
  }

  public saveAndContinue = (): void => {
    this.props.switchWidgetMode();
  };

  public deferNavigation = (): boolean => {
    return true;
  };
}

export default Confirmation;
