import React from "react";
import Carousel from "./CarouselWrapper";
import ScrollCarousel from "../ScrollCarousel";
import { HeroAnimationType } from "utilities/constant";
import CrossFadeCarousel from "../CrossFadeCarousel";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import { isMobile } from "react-device-detect";

export interface ICustomCarouselProps {
  useKeyboardArrows: boolean;
  animationType: HeroAnimationType;
  showThumbs: boolean;
  showIndicators: boolean;
  infiniteLoop: boolean;
  autoPlay: boolean;
  interval: number;
  controlHeight?: number;
  controlHeightStyle?: any;
  selectedItem?: number;
  isEditionView?: boolean;
  navbarHeight: number;
  statusFormatter: (current: number, total: number) => string;
  onSetCurrentPicture?: (picture: number) => void;
}

const getSlideCarousel = (props: any) => {
  return (
    <>
      {isMobile && (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={props.controlHeight}
          totalSlides={props.children.length}
          currentSlide={props.selectedItem}
          isPlaying={props.autoPlay}
          interval={props.interval}
        >
          <Slider>
            {props.children.map((child: any, i: number) => {
              return <Slide index={i}>{child}</Slide>;
            })}
          </Slider>
        </CarouselProvider>
      )}
      {!isMobile && (
        <Carousel
          axis="horizontal"
          useKeyboardArrows={props.useKeyboardArrows}
          showThumbs={props.showThumbs}
          showIndicators={props.showIndicators}
          infiniteLoop={props.infiniteLoop}
          autoPlay={props.autoPlay}
          interval={props.interval}
          selectedItem={props.selectedItem}
          statusFormatter={props.statusFormatter}
          stopOnHover={true}
          swipeable
        >
          {props.children}
        </Carousel>
      )}
    </>
  );
};

const getScrollCarousel = (props: any) => {
  return (
    <ScrollCarousel
      navbarHeight={props.navbarHeight}
      controlHeight={props.controlHeight}
      controlHeightStyle={props.controlHeightStyle}
      selectedItem={props.selectedItem}
      infiniteLoop
      autoPlay={props.autoPlay}
      interval={props.interval}
      isEditionView={props.isEditionView}
      onSetCurrentPicture={props.onSetCurrentPicture}
    >
      {props.children}
    </ScrollCarousel>
  );
};

const getCrossFadeCarousel = (props: any) => {
  return (
    <CrossFadeCarousel
      navbarHeight={props.navbarHeight}
      controlHeight={props.controlHeight}
      controlHeightStyle={props.controlHeightStyle}
      selectedItem={props.selectedItem}
      infiniteLoop
      autoPlay={props.autoPlay}
      interval={props.interval}
      isEditionView={props.isEditionView}
    >
      {props.children}
    </CrossFadeCarousel>
  );
};

const getCarouselType = (animationType: HeroAnimationType, props: any) => {
  let result: React.ReactNode;

  switch (animationType) {
    case HeroAnimationType.SLIDE:
      result = getSlideCarousel(props);
      break;
    case HeroAnimationType.SCROLL:
      result = getScrollCarousel(props);
      break;
    case HeroAnimationType.CROSS_FADE:
      result = getCrossFadeCarousel(props);
      break;
  }

  return result;
};

const CustomCarousel: React.FC<ICustomCarouselProps> = props => {
  return <React.Fragment>{getCarouselType(props.animationType, props)}</React.Fragment>;
};

export default CustomCarousel;
