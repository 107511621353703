import { INavigationMenu, IPage } from "../../models/models";
import { themeFontsStyles } from "../constant";

export const isClientSideRendering = !!(process as any).browser;

export const getUUID = () => {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    // tslint:disable-next-line: no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // tslint:disable-next-line: no-bitwise
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const findPageById = (pages: IPage[] | null, pageId: string, level: number = 0): any | null => {
  if (pages) {
    for (const page of pages) {
      if (page.id === pageId) {
        return { ...page, level };
      }

      const res: any | null = findPageById(page.pages, pageId, level + 1);
      if (res) {
        return { ...res, level };
      }
    }
  }

  return null;
};

export const findPage = (pages: IPage[] | null, pageObj: any, level: number = 0): any | null => {
  if (pages) {
    for (const page of pages) {
      if (pageObj.sortIndex === page.sortIndex && pageObj.alias === page.alias) {
        return { ...page, level };
      }

      const res: any | null = findPage(page.pages, pageObj);
      if (res) {
        return { ...res, level };
      }
    }
  }

  return null;
};

export const isSinglePageSite = (menuItems: INavigationMenu): boolean => {
  let pageCount = menuItems.items.length;
  if (pageCount > 0 && menuItems.items[0].childs) pageCount += menuItems.items[0].childs?.length;
  return pageCount <= 1;
};

// wrapper for js native window that can be used from CSR and SSR
export const universalWindow = isClientSideRendering
  ? (window as any)
  : {
      height: 0,
      width: 0,
      innerWidth: 0,
      innerHeight: 0,
      location: {
        href: ""
      },
      addEventListener: (name: string, callback: any) => false
    };

export const getSiteScrollbarWidth = (element: HTMLElement) => {
  const body = element.ownerDocument?.body;
  return body ? body.offsetWidth - body.clientWidth : 0;
};

export interface ISiteFontPair {
  primary: string;
  primaryExtra: string;
  secondary: string;
  buttonStyles?: React.CSSProperties;
}

const fontsWithSize40 = ["pinyonscript", "medulaone"];
const fontsWithBold = ["montserrat", "quattrocento", "syncopatebold"];
const fontsWithButtonStyles = [
  { name: "josefinslab", styles: { paddingTop: "15px" } },
  { name: "palanquin", styles: { paddingTop: "6px" } }
];

// Returns an object with site font css classnames and other styling data
export const getSiteFonts = (fontStyle: string): ISiteFontPair => {
  const fontPair = themeFontsStyles.find(fs => fs.name === fontStyle);
  let arr: string[] = [];
  if (fontPair) {
    arr = fontPair.fonts.split("|");
  } else {
    arr = fontStyle
      .toLowerCase()
      .replace("fonts-", "")
      .split("|");
  }

  // primaryExtra prop can contain additional classes for specific styles depending on primary font
  let primaryExtraClasses: string[] = [];
  if (fontsWithSize40.some(f => f === arr[0])) {
    primaryExtraClasses.push("fontSize40");
  }

  if (fontsWithBold.some(f => f === arr[0])) {
    primaryExtraClasses.push("fontWeightBold");
  }

  let btnStyleObj = fontsWithButtonStyles.find(f => f.name === arr[0] || f.name === arr[1]);
  let buttonStyles: React.CSSProperties | undefined;
  if (btnStyleObj) {
    buttonStyles = btnStyleObj.styles;
  }

  return {
    primary: `font-${arr[0]}`,
    secondary: `font-${arr[1]}`,
    primaryExtra: primaryExtraClasses.join(" "),
    buttonStyles
  };
};
