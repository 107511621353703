import { IAddressPrediction } from "@zenfolio/core-components";
import { IAddressQuery, IPlaceDetails } from "@zenfolio/core-components/dist/models/location";
import { apiClient } from ".";
import { ICoordinates } from "../components/addressSelection/map";
import { apiType } from "./apiType";

export interface IApiLocation {
  searchAddress: (query: IAddressQuery) => Promise<IAddressPrediction[]>;
  loadPlaceDetails: (placeId: string) => Promise<IPlaceDetails>;
  loadPlaceDetailsFromLocation: (location: ICoordinates) => Promise<IPlaceDetails>;
  loadPlaceDetailsFromIp: () => Promise<IPlaceDetails>;
}

const api: IApiLocation = {
  searchAddress: async (query: IAddressQuery) => {
    return (
      await apiClient.get<{ predictions: IAddressPrediction[] }>(`${apiType.location}address/auto-complete`, {
        params: query
      })
    ).predictions;
  },

  loadPlaceDetails: (placeId: string) =>
    apiClient.get<IPlaceDetails>(`${apiType.location}address/places`, { params: { placeId } }),

  loadPlaceDetailsFromLocation: (location: ICoordinates) =>
    apiClient.get<IPlaceDetails>(`${apiType.location}address/geocode/location`, { params: location }),

  loadPlaceDetailsFromIp: () => apiClient.get<IPlaceDetails>(`${apiType.location}address/geocode/ip`)
};

export default api;
