import { IAddress } from "@zenfolio/core-components/dist/models/location";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import InRadiusLocationStep from "../../../../../components/widget/steps/location/inRadius";
import { IAppState } from "../../../../../store/state";
import { doValidateLocation } from "../../../../../store/widget/actions";

function mapStateToProps({ widget }: IAppState) {
  return {
    locationIsValid: widget.locationIsValid,
    locationValidationStatus: widget.validateLocation.status,
    primaryShootLocation: widget.loadWidgetInfo.primaryShootLocation
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    validateLocation: (photographerServiceId: string, address: IAddress) =>
      dispatch(doValidateLocation({ photographerServiceId, address }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InRadiusLocationStep);
