import _ from "lodash";
import { getUUID } from "utilities/blocks/site";
import { IAnalyticParams } from ".";
import { IPlaybackStatsRecord } from "../../Video/Player";

export abstract class Analytics {
  public static createApi(videoId: string, params?: IAnalyticParams): Analytics {
    return new AnalyticsApi(videoId, params);
  }

  public static createEmulator(videoId: string, params?: IAnalyticParams): Analytics {
    return new AnalyticsEmulator(videoId, params);
  }

  constructor(videoId: string, params?: IAnalyticParams) {
    this.setMetadata(videoId, params);
  }

  public update(videoId: string, params?: IAnalyticParams) {
    if (videoId != this.videoId || !_.isEqual(this.params, params)) {
      this.commitRecords(false);
      this.committedRecords = [];
      this.setMetadata(videoId, params);
    }
  }

  public pushRecord(record: IPlaybackStatsRecord): void {
    if (!this.params) {
      return;
    }

    record.duration && this.pendingRecords.push(record);

    if (record.source === "stop" || record.source === "page-hide") {
      this.commitRecords(record.source === "page-hide");
    }
  }

  protected abstract commitRecordsImpl(records: IPlaybackStatsRecord[], pageHide: boolean): void;

  protected commitRecords(pageHide: boolean) {
    if (this.pendingRecords.length > 0) {
      this.committedRecords.push(...this.pendingRecords);
      this.pendingRecords = [];
      this.commitRecordsImpl(this.committedRecords, pageHide);
    }
  }

  protected get token(): string | undefined {
    function get(key: string) {
      return localStorage.getItem(key) || undefined;
    }
    return get("login_token") || get("impersonate_token") || get("token");
  }

  private setMetadata(videoId: string, params?: IAnalyticParams) {
    this.sessionId = getUUID();
    this.videoId = videoId;
    this.params = params;
  }

  protected sessionId!: string;
  protected videoId!: string;
  protected params?: IAnalyticParams;
  private committedRecords: IPlaybackStatsRecord[] = [];
  private pendingRecords: IPlaybackStatsRecord[] = [];
}

class AnalyticsApi extends Analytics {
  protected commitRecordsImpl(records: IPlaybackStatsRecord[], pageHide: boolean): void {
    if (!this.apiUrl) {
      return;
    }

    const formData = new FormData();
    formData.append("sessionId", this.sessionId);
    formData.append("videoId", this.videoId);
    formData.append("galleryId", this.params!.galleryId);
    formData.append("type", this.params!.type);
    this.token && formData.append("token", this.token);

    const intervals = records.map(rec => ({
      offset: rec.offset * 1000,
      duration: rec.duration * 1000,
      quality: `${rec.quality}p`
    }));
    formData.append("intervals", JSON.stringify(intervals));

    try {
      if (pageHide) {
        navigator.sendBeacon(this.apiUrl, formData);
      } else {
        const req = new XMLHttpRequest();
        req.open("POST", this.apiUrl);
        req.send(formData);
      }
    } catch {}
  }

  private get apiUrl(): string | undefined {
    return process.env.REACT_APP_VIDEO_ANALYTICS_URL;
  }
}

class AnalyticsEmulator extends Analytics {
  protected commitRecordsImpl(records: IPlaybackStatsRecord[], pageHide: boolean): void {
    console.log("commit records", [...records], pageHide);

    let data: {
      sessionId: string;
      videoId: string;
      galleryId: string;
      type: string;
      records: IPlaybackStatsRecord[];
    } | null = JSON.parse(localStorage.getItem("nz-video-analytics-emulator") || "null");

    const { sessionId, videoId } = this;
    const { galleryId, type } = this.params!;
    if (data?.sessionId !== sessionId) {
      data = { sessionId, videoId, galleryId, type, records };
    } else {
      data.records = records;
    }

    localStorage.setItem("nz-video-analytics-emulator", JSON.stringify(data));
  }
}
