import _ from "lodash";
import moment from "moment";
import { combineReducers } from "redux";
import ServiceEditOrCreate from "../../components/services/serviceEditOrCreate";
import { MinDays } from "../../components/services/serviceEditOrCreate/constants";
import IError from "../../utilities/error";
import { ActionStatus } from "../common";
import { insertItem, removeItem } from "../helpers";
import {
  CreateDetailsAction,
  DeleteServiceAction,
  DeleteShootTypeAction,
  DuplicateServiceAction,
  LoadServiceDetailsAction,
  RenameShootTypeAction,
  ReorderServicesAction,
  ReorderShootTypesAction,
  ServicesFetchAction,
  ToggleServiceStateAction,
  UpdateServiceDetailsAction
} from "./actions";
import { ServiceActionType } from "./constants";
import { IServiceDetails, IServiceDetailsApi, IServiceItem } from "./model";

export interface IServicesState {
  fetchServicesStatus: ActionStatus;
  toggleServiceStatus: ActionStatus;
  reorderServicesStatus: ActionStatus;
  deleteServiceStatus: ActionStatus;
  duplicateServiceStatus: ActionStatus;

  services: IServiceItem[];
  currentServiceId: string | null;
  duplicatedServiceId: string | null;

  renameShootTypeStatus: ActionStatus;
  reorderShootTypesStatus: ActionStatus;
  deleteShootTypeStatus: ActionStatus;
  currentShootTypeId: string | null;
  filter?: string;
  error: IError | null;
}

const initialServicesState: IServicesState = {
  fetchServicesStatus: "Init",
  toggleServiceStatus: "Init",
  reorderServicesStatus: "Init",
  deleteServiceStatus: "Init",
  duplicateServiceStatus: "Init",
  renameShootTypeStatus: "Init",
  reorderShootTypesStatus: "Init",
  deleteShootTypeStatus: "Init",
  currentShootTypeId: null,
  services: [],
  currentServiceId: null,
  duplicatedServiceId: null,
  error: null
};

const fetchServices = (
  state: IServicesState = initialServicesState,
  action:
    | ServicesFetchAction
    | ToggleServiceStateAction
    | ReorderServicesAction
    | DeleteServiceAction
    | DuplicateServiceAction
    | RenameShootTypeAction
    | DeleteShootTypeAction
    | ReorderShootTypesAction
): IServicesState => {
  switch (action.type) {
    case ServiceActionType.LOAD_SERVICES_STARTED:
      return { ...state, fetchServicesStatus: "Pending", error: null };
    case ServiceActionType.LOAD_SERVICES_SUCCESS:
      return {
        ...state,
        filter: action.filter,
        fetchServicesStatus: "Success",
        services: mapServices(action.services.slice(), updateServiceOrder),
        error: null
      };
    case ServiceActionType.LOAD_SERVICES_ERROR:
      return {
        ...state,
        fetchServicesStatus: "Error",
        services: [],
        error: action.error
      };
    case ServiceActionType.TOGGLE_SERVICE_STATE_STARTED:
      return {
        ...state,
        toggleServiceStatus: "Pending",
        currentServiceId: action.serviceId,
        error: null
      };
    case ServiceActionType.TOGGLE_SERVICE_STATE_SUCCESS:
      return state.currentServiceId === action.serviceId
        ? {
            ...state,
            services: state.services.map(item => {
              return item.id === action.serviceId ? { ...item, enabled: action.isEnabled } : item;
            }),
            toggleServiceStatus: "Success",
            error: null
          }
        : state;
    case ServiceActionType.TOGGLE_SERVICE_STATE_ERROR:
      return state.currentServiceId === action.serviceId
        ? {
            ...state,
            toggleServiceStatus: "Error",
            error: action.error
          }
        : state;
    case ServiceActionType.REORDER_SERVICES_STARTED:
      return {
        ...state,
        reorderServicesStatus: "Pending",
        services: mapServices(
          _.sortBy(state.services, item => action.serviceIds.indexOf(item.id)),
          (item: IServiceItem, index: number) => {
            if (item.order !== index || item.id === action.serviceId) {
              item = {
                ...item,
                order: index
              };

              if (item.id === action.serviceId) {
                item.shootType = {
                  ...item.shootType,
                  id: action.shootType.id,
                  name: action.shootType.name
                };
                item.originalShootType = {
                  ...item.originalShootType,
                  id: action.originalShootType.id,
                  name: action.originalShootType.name
                };
              }
            }

            return item;
          }
        ),
        error: null
      };
    case ServiceActionType.REORDER_SERVICES_SUCCESS:
      return {
        ...state,
        reorderServicesStatus: "Success",
        error: null
      };
    case ServiceActionType.REORDER_SERVICES_ERROR:
      return {
        ...state,
        reorderServicesStatus: "Error",
        services: action.originalServices,
        error: action.error
      };
    case ServiceActionType.DELETE_SERVICE_STARTED:
      return { ...state, deleteServiceStatus: "Pending", currentServiceId: action.serviceId, error: null };
    case ServiceActionType.DELETE_SERVICE_SUCCESS:
      return state.currentServiceId === action.serviceId
        ? {
            ...state,
            deleteServiceStatus: "Success",
            services: mapServices(
              removeItem(
                state.services,
                state.services.findIndex(s => s.id === action.serviceId)
              ),
              updateServiceOrder
            )
          }
        : state;
    case ServiceActionType.DELETE_SERVICE_ERROR:
      return state.currentServiceId === action.serviceId
        ? {
            ...state,
            deleteServiceStatus: "Error",
            error: action.error
          }
        : state;
    case ServiceActionType.DUPLICATE_SERVICE_STARTED:
      return { ...state, duplicateServiceStatus: "Pending", currentServiceId: action.serviceId, error: null };
    case ServiceActionType.DUPLICATE_SERVICE_SUCCESS:
      return state.currentServiceId === action.serviceId
        ? {
            ...state,
            duplicateServiceStatus: "Success",
            duplicatedServiceId: action.duplicatedService.id,
            services: mapServices(
              insertItem(
                state.services,
                action.duplicatedService,
                state.services.findIndex(s => s.id === action.serviceId) + 1
              ),
              updateServiceOrder
            )
          }
        : state;
    case ServiceActionType.DUPLICATE_SERVICE_ERROR:
      return state.currentServiceId === action.serviceId
        ? {
            ...state,
            duplicateServiceStatus: "Error",
            error: action.error
          }
        : state;

    case ServiceActionType.RENAME_SHOOT_TYPE_STARTED:
      return {
        ...state,
        renameShootTypeStatus: "Pending",
        error: null
      };
    case ServiceActionType.RENAME_SHOOT_TYPE_SUCCESS:
      return {
        ...state,
        renameShootTypeStatus: "Success",
        services: mapServices(state.services.slice(), (item: IServiceItem, index: number) => {
          if (item.shootType.id !== action.shootType.id) {
            return item;
          }

          return {
            ...item,
            shootType: {
              ...item.shootType,
              name: action.shootType.name
            },
            originalShootType: {
              ...item.originalShootType,
              id: action.originalShootType.id,
              name: action.originalShootType.name
            }
          };
        }),
        error: null
      };
    case ServiceActionType.RENAME_SHOOT_TYPE_ERROR:
      return {
        ...state,
        renameShootTypeStatus: "Error",
        error: action.error
      };

    case ServiceActionType.REORDER_SHOOT_TYPES_STARTED:
      return {
        ...state,
        reorderShootTypesStatus: "Pending",
        services: mapServices(
          _.sortBy(state.services, item => action.shootTypeIds.indexOf(item.shootType.id)),
          updateServiceOrder
        ),
        error: null
      };
    case ServiceActionType.REORDER_SHOOT_TYPES_SUCCESS:
      return {
        ...state,
        reorderShootTypesStatus: "Success",
        error: null
      };
    case ServiceActionType.REORDER_SHOOT_TYPES_ERROR:
      return {
        ...state,
        reorderShootTypesStatus: "Error",
        services: action.originalServices,
        error: action.error
      };
    case ServiceActionType.DELETE_SHOOT_TYPE_STARTED:
      return { ...state, deleteShootTypeStatus: "Pending", currentShootTypeId: action.shootTypeId, error: null };
    case ServiceActionType.DELETE_SHOOT_TYPE_SUCCESS:
      return state.currentShootTypeId === action.shootTypeId
        ? {
            ...state,
            deleteShootTypeStatus: "Success",
            services: state.services.filter(item => item.shootType.id !== action.shootTypeId)
          }
        : state;
    case ServiceActionType.DELETE_SHOOT_TYPE_ERROR:
      return state.currentShootTypeId === action.shootTypeId
        ? {
            ...state,
            deleteShootTypeStatus: "Error",
            services: action.originalServices,
            error: action.error
          }
        : state;

    default:
      return state;
  }
};

export interface IServiceDetailsState {
  serviceId: string | null;
  service: IServiceDetails | null;
  create: ICreateDetailsState;
  load: ILoadDetailsState;
  update: IUpdateDetailsState;
}

export interface ICreateDetailsState {
  status: ActionStatus;
  error: IError | null;
}

export interface ILoadDetailsState {
  status: ActionStatus;
  error: IError | null;
}

export interface IUpdateDetailsState {
  status: ActionStatus;
  error: IError | null;
}

const initialCreateDetailsState: ICreateDetailsState = {
  status: "Init",
  error: null
};

const initialLoadDetailsState: ILoadDetailsState = {
  status: "Init",
  error: null
};

const initialUpdateDetailsState: IUpdateDetailsState = {
  status: "Init",
  error: null
};

const initialDetailsState: IServiceDetailsState = {
  serviceId: null,
  service: null,
  create: initialCreateDetailsState,
  load: initialLoadDetailsState,
  update: initialUpdateDetailsState
};

export const details = (
  state: IServiceDetailsState = initialDetailsState,
  action: LoadServiceDetailsAction | UpdateServiceDetailsAction | CreateDetailsAction
): IServiceDetailsState => {
  switch (action.type) {
    case ServiceActionType.CREATE_SERVICE_STARTED:
      return {
        serviceId: null,
        service: null,
        load: initialLoadDetailsState,
        update: initialUpdateDetailsState,
        create: {
          ...state.create,
          status: "Pending",
          error: null
        }
      };
    case ServiceActionType.CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        serviceId: action.serviceId,
        service: wrapService(action.creator),
        create: {
          ...state.create,
          status: "Success",
          error: null
        },
        load: {
          ...state.load,
          status: "Success",
          error: null
        }
      };
    case ServiceActionType.CREATE_SERVICE_ERROR:
      return {
        ...state,
        serviceId: null,
        service: null,
        create: {
          ...state.create,
          status: "Error",
          error: action.error
        }
      };
    case ServiceActionType.LOAD_SERVICE_DETAILS_STARTED:
      return {
        serviceId: action.serviceId,
        service: null,
        create: initialCreateDetailsState,
        load: {
          ...state.load,
          status: "Pending",
          error: null
        },
        update: initialUpdateDetailsState
      };
    case ServiceActionType.LOAD_SERVICE_DETAILS_SUCCESS:
      return state.serviceId === action.serviceId
        ? {
            ...state,
            service: wrapService(action.service),
            load: {
              ...state.load,
              status: "Success",
              error: null
            }
          }
        : state;
    case ServiceActionType.LOAD_SERVICE_DETAILS_ERROR:
      return state.serviceId === action.serviceId
        ? {
            ...state,
            service: null,
            load: {
              ...state.load,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ServiceActionType.UPDATE_SERVICE_DETAILS_STARTED:
      return state.serviceId === action.serviceId
        ? {
            ...state,
            update: {
              ...state.update,
              status: "Pending",
              error: null
            },
            service: wrapService(action.updater)
          }
        : state;
    case ServiceActionType.UPDATE_SERVICE_DETAILS_SUCCESS:
      return state.serviceId === action.serviceId
        ? {
            ...state,
            update: {
              ...state.update,
              status: "Success",
              error: null
            },
            service: wrapService(action.updater)
          }
        : state;
    case ServiceActionType.UPDATE_SERVICE_DETAILS_ERROR:
      return state.serviceId === action.serviceId
        ? {
            ...state,
            update: {
              ...state.update,
              status: "Error",
              error: action.error
            }
          }
        : state;

    case ServiceActionType.RESET_SERVICE_DETAILS_DETAILS:
      return initialDetailsState;
    default:
      return state;
  }
};

const wrapService = (service: IServiceDetailsApi): IServiceDetails => {
  const { availableStartDate, availableEndDate } = service;
  return {
    ...service,
    availableStartDate: availableStartDate ? moment(availableStartDate) : null,
    availableEndDate: availableEndDate ? moment(availableEndDate) : null,
    deposit: service.useDeposit
      ? {
          ...service.deposit!,
          sendInvoiceDays: service.deposit!.sendInvoiceDays || MinDays
        }
      : ServiceEditOrCreate.defaultDepositState
  };
};

const updateServiceOrder = (item: IServiceItem, index: number) => {
  return item.order === index
    ? item
    : {
        ...item,
        order: index
      };
};

const mapServices = (services: IServiceItem[], map: (item: IServiceItem, index: number) => IServiceItem) => {
  for (let i = 0; i < services.length; i++) {
    services[i] = map(services[i], i);
  }

  return services;
};

export default combineReducers({
  fetchState: fetchServices,
  detailsState: details
});
