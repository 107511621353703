import React, { useState, useEffect } from "react";

import { ICollectionOptions } from "./../../models";
import cx from "classnames";
import styles from "./collections.module.scss";
import Button from "components/Button";
import { ScrollableNav } from "components/Navigation/ScrollableNav";

export interface ICollectionsProps {
  collectionsOptions?: ICollectionOptions[];
  defaultActiveIndex?: number;
  isDisplayFavorite?: boolean;
  onOptionSelected?: (collectionId: string) => void;
  invertButtonStyle?: boolean;
  bgColor?: string;
}

const Collections: React.FC<ICollectionsProps> = props => {
  const [optionSelected, setOptionSelected] = useState(
    props.collectionsOptions ? props.collectionsOptions[props.defaultActiveIndex || 0].collectionId : null
  );

  useEffect(() => {
    const activeOption = props.collectionsOptions
      ? props.collectionsOptions[props.defaultActiveIndex || 0].collectionId
      : null;
    setOptionSelected(activeOption);
  }, [props.defaultActiveIndex, props.collectionsOptions]);

  const handleClickOption = (collectionId: string) => {
    setOptionSelected(collectionId);
    if (props.onOptionSelected) {
      props.onOptionSelected(collectionId);
    }
  };

  return (
    <div className={cx(styles.layout, { [styles.displayNone]: props.collectionsOptions?.length === 1 })}>
      <ScrollableNav className={styles.galleryNames} bgColor={props.bgColor}>
        <ul className={styles.galleryNameList}>
          {props.collectionsOptions?.map((option: ICollectionOptions, index: number) => {
            return (
              <Button
                key={`collection-${option.collectionId}`}
                className={cx(styles.galleryButton, {
                  [styles.active]: index === props.defaultActiveIndex,
                  [styles.inverted]: props.invertButtonStyle,
                })}
                onClick={() => {
                  handleClickOption(option.collectionId);
                }}
              >
                {option.name}
              </Button>
            );
          })}
        </ul>
      </ScrollableNav>
    </div>
  );
};

export default Collections;
