import React from "react";
import IIConProps from "../../IIconProps";

const getRadius = (size: string | number = 0) => (Number(size) || 0) / 2;

const FavoriteMedia = ({ minX = 0, minY = 0, ...props }: IIConProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox={`${minX} ${minY} ${props.size} ${props.size}`}
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx={getRadius(props.size)}
        cy={getRadius(props.size)}
        r={getRadius(props.size)}
        fill="#000"
        opacity=".8"
      />
      <path
        stroke={props.color}
        strokeWidth="2"
        d="M16 23.978c3.642-2.218 5.942-4.557 7.184-6.873.436-.813.709-1.566.852-2.244.088-.413.112-.715.106-.888l.001.01-.002-.065c0-2.099-1.646-3.775-3.708-3.775-1.28 0-2.455.682-3.715 1.982l-.718.74-.718-.74c-1.26-1.3-2.435-1.982-3.715-1.982-2.117 0-3.71 1.526-3.71 3.571 0 .82.26 1.984 1.072 3.472 1.271 2.331 3.543 4.641 7.07 6.792z"
      />
    </g>
  </svg>
);

export default FavoriteMedia;
