import { FormTextArea } from "@zenfolio/core-components";
import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

interface IModalNoteProps {
  note: string;
  onChange: (note: string) => void;
  placeholder?: string;
  required?: boolean;
  className?: string;
}

class ModalNote extends React.Component<IModalNoteProps> {
  public render() {
    const { note, placeholder, required, className } = this.props;

    const textArea = (
      <FormTextArea
        className={classNames(required ? styles.container : "", styles.note, required ? className : "")}
        onChange={this.onNoteChange}
        value={note}
        placeholder={placeholder === undefined ? "Add a note" : placeholder}
        maxLength={1000}
      />
    );

    return required ? (
      textArea
    ) : (
      <label className={classNames(styles.container, styles.label, className)}>
        Optional
        {textArea}
      </label>
    );
  }

  private onNoteChange = (value: string) => this.props.onChange(value);
}

export default ModalNote;
