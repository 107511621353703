import classNames from "classnames";
import Panel from "components/Collapse/panel";
import { formatTime } from "components/TimeSlotDay/utils";
import _ from "lodash";
import { isOldEdge } from "utilities/responsive";
import React from "react";
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars";
import arrowLeft from "../arrow-left.svg";
import arrowRight from "../arrow-right.svg";
import {
  isActiveControlledNavigation,
  isActiveUncontrolledNavigation,
  isPassiveNavigation,
  Navigation,
  NavigationDirection
} from "./navigation";
import styles from "./timePicker.module.scss";

interface ITimePickerProps {
  defaultTime?: number | null;
  time?: number | null;
  times?: number[];
  noTimeText?: string;
  allowGrouping?: boolean;
  onChange?: (time: number) => void;
  navigation?: Navigation;
  scrollbar?: ScrollbarProps;
  className?: string;
  style?: React.CSSProperties;
}

interface ITimePickerState {
  time?: number;
  position?: number;
  lastAnimPosition?: number;
  lastAnimTimes?: number[];
}

class TimePicker extends React.Component<ITimePickerProps, ITimePickerState> {
  public state: ITimePickerState = {};

  public componentDidMount() {
    this.setState({ lastAnimPosition: this.position, lastAnimTimes: this.props.times });
    this.containerRef.current!.addEventListener("animationend", this.onAnimEnd);
  }

  public componentWillUnmount() {
    this.containerRef.current!.removeEventListener("animationend", this.onAnimEnd);
  }

  public render() {
    const { className, times, style, navigation } = this.props;
    const { lastAnimPosition, lastAnimTimes } = this.state;
    const currentPosition = lastAnimPosition !== undefined ? lastAnimPosition : this.position;
    const currentTimes = lastAnimTimes || times;
    const animating = this.animating;
    const animatingForward = animating && lastAnimPosition! < this.position!;
    const animatingBack = animating && lastAnimPosition! > this.position!;
    const canNavigateBack =
      navigation &&
      !isPassiveNavigation(navigation) &&
      (navigation.minPosition === undefined || navigation.minPosition < this.position!);
    const canNavigateForward =
      navigation &&
      !isPassiveNavigation(navigation) &&
      (navigation.maxPosition === undefined || navigation.maxPosition > this.position!);
    // enable legacy animation only for old Edge
    const legacyAnim = isOldEdge;

    return (
      <div className={classNames(styles.container, className)} style={style}>
        <div
          className={classNames(styles.innerContainer, {
            [styles.withAnimations]: this.position !== undefined,
            [styles.anim]: animating,
            [styles.forward]: animatingForward,
            [styles.back]: animatingBack
          })}
          ref={this.containerRef}
        >
          {currentPosition !== undefined &&
            this.renderTimes(animatingBack ? this.position : undefined, animatingBack ? times || [] : undefined)}
          {this.renderTimes(currentPosition, currentTimes || [])}
          {currentPosition !== undefined &&
            this.renderTimes(animatingForward ? this.position : undefined, animatingForward ? times || [] : undefined)}
        </div>
        {canNavigateBack && (
          <div
            className={classNames(styles.nav, legacyAnim ? styles.legacyAnim : "", styles.navBack)}
            onClick={this.onNavigateBackClick}
          >
            <img alt="" src={arrowLeft} />
          </div>
        )}
        {canNavigateForward && (
          <div
            className={classNames(styles.nav, legacyAnim ? styles.legacyAnim : "", styles.navForward)}
            onClick={this.onNavigateForwardClick}
          >
            <img className={styles.arrowRight} alt="" src={arrowRight} />
          </div>
        )}
      </div>
    );
  }

  private get time() {
    const { props, state } = this;
    return props.time !== undefined ? props.time : state.time !== undefined ? state.time : props.defaultTime;
  }

  private get position() {
    const {
      props: { navigation },
      state
    } = this;

    return !navigation
      ? undefined
      : !isActiveUncontrolledNavigation(navigation)
      ? navigation.position
      : state.position !== undefined
      ? state.position
      : navigation.defaultPosition;
  }

  private get animating() {
    const { lastAnimPosition } = this.state;
    return lastAnimPosition !== undefined && lastAnimPosition !== this.position;
  }

  private renderTimes(position?: number, times?: number[]) {
    const { scrollbar } = this.props;
    const morning = times ? _.sortBy(times.filter(t => t < 720)) : undefined;
    const afternoon = times ? _.sortBy(times.filter(t => t >= 720)) : undefined;

    const container = (
      <div className={classNames(styles.timesContainer, { [styles.insideScroll]: scrollbar })} key={position}>
        {morning && this.renderSection("Morning", morning)}
        {afternoon && this.renderSection("Afternoon", afternoon)}
      </div>
    );

    return scrollbar ? (
      <Scrollbars
        {...this.props.scrollbar}
        renderTrackVertical={this.animating ? this.renderHiddenTrack : undefined}
        key={position}
      >
        {container}
      </Scrollbars>
    ) : (
      container
    );
  }

  private renderSection(title: string, times: number[]) {
    return (
      <div className={styles.section}>
        <div className={styles.title}>{title}</div>
        {times && times.length > 0 ? (
          <div className={styles.times}>
            {this.enableGrouping(times)
              ? _.map(
                  _.groupBy(times, time => Math.floor(time / groupSize)),
                  (v, k) => this.renderGroup(+k, v)
                )
              : times.map(this.renderTime)}
          </div>
        ) : (
          <div className={styles.empty}>{this.props.noTimeText}</div>
        )}
      </div>
    );
  }

  private renderTime = (time: number) => (
    <div
      key={time}
      className={classNames(styles.time, { [styles.selected]: time === this.time })}
      onClick={this.onTimeClick(time)}
    >
      {formatTime(time, timeFormat)}
    </div>
  );

  private renderGroup = (groupStart: number, times: number[]) => {
    const from = formatTime(groupStart * groupSize, timeFormat);
    const to = formatTime((groupStart + 1) * groupSize, timeFormat);
    return (
      <Panel
        key={groupStart}
        name=""
        open={false}
        showArrow={true}
        timeout={300}
        title={`${from} - ${to}`}
        className={styles.group}
        headerClassName={styles.header}
      >
        <div className={styles.content}>{times.map(this.renderTime)}</div>
      </Panel>
    );
  };

  private renderHiddenTrack = (props: any) => {
    return <div {...props} style={{ display: "none" }} />;
  };

  private onTimeClick = _.memoize((time: number) => () => {
    if (this.animating) {
      return;
    }

    const onChange = (t: number) => {
      if (this.props.onChange) {
        this.props.onChange(t);
      }
    };

    if (this.props.time === undefined) {
      this.setState({ time }, () => onChange(this.time!));
    } else {
      onChange(time);
    }
  });

  private onNavigateBackClick = () => this.navigate("back");
  private onNavigateForwardClick = () => this.navigate("forward");

  private onAnimEnd = (event: AnimationEvent) => {
    if (event.target === this.containerRef.current!) {
      this.setState({ lastAnimPosition: this.position, lastAnimTimes: this.props.times });
    }
  };

  private enableGrouping(times: number[]) {
    return this.props.allowGrouping && times.length > 12;
  }

  private navigate = (direction: NavigationDirection) => {
    const navigation = this.props.navigation!;

    if (this.animating) {
      return;
    }

    const onChange = (dp: NavigationDirection | number) => {
      if (isActiveControlledNavigation(navigation)) {
        navigation.onChange(dp as NavigationDirection);
      } else if (isActiveUncontrolledNavigation(navigation)) {
        navigation.onChange(dp as number);
      }
    };

    if (isActiveUncontrolledNavigation(navigation)) {
      this.setState({ position: this.position! + (direction === "back" ? -1 : 1) }, () => onChange(this.position!));
    } else if (isActiveControlledNavigation(navigation)) {
      onChange(direction);
    }
  };

  private containerRef = React.createRef<HTMLDivElement>();
}

const groupSize = 60; // in minutes
const timeFormat = "h:mm a";

export default TimePicker;
