import { IFeature, IGallerySettings } from "../../models/blocks";
import { IPage, INavigationMenuItem, IRoutes, ILink } from "../../models/models";
import { LinkTypeOption } from "utilities/constant";
import { combineURLs, getAllQuerystringParameters } from "./url";

const isDevelopment = process.env.REACT_APP_ENVIRONMENT === "development";

export enum CONTENT_TYPE {
  SITEPAGE = 1,
  DYNAMICGALLERY = 2,
  DYNAMICFOLDER = 3,
  BLOGPOST = 4,
  SHARED_FOLDER_PAGE = 5
}

export enum INTERACTION_TYPE {
  URL = "url",
  PAGE = "page",
  ALBUMFOLDER = "albumFolder",
  NONE = "none"
}

export type getInteractionUrlType = (
  pages: INavigationMenuItem[],
  interactionType: string,
  interactionUrl: string,
  interactionPage: string,
  routes: IRoutes | undefined,
  galleryId: string,
  gallerySettings: IGallerySettings | null,
  folderId: string,
  galleryFolderAlias?: string,
  feature?: IFeature
) => string;

export type localInteractionUrlType = (
  pages: INavigationMenuItem[],
  interactionType: string,
  interactionUrl: string,
  interactionPage: string,
  routes: IRoutes | undefined,
  galleryId: string,
  gallerySettings: IGallerySettings | null,
  folderId: string,
  feature?: IFeature
) => string;

export const getFinalInteractionUrl = (
  isPublish: boolean,
  pages: INavigationMenuItem[],
  interactionUrl?: string,
  interactionPage?: string,
  interactionType?: string,
  routes?: IRoutes,
  link?: ILink,
  feature?: IFeature
) => {
  let sLink: string = "";
  const newInteractionType = link?.type || interactionType || LinkTypeOption.URL;
  const newInteractionUrl = link?.url || interactionUrl || "";
  const newInteractionPage = link?.page || interactionPage || "";
  const lastItem = (path: string) => path.substring(path.lastIndexOf("/") + 1);

  if (isPublish) {
    sLink = getInteractionUrlPublish(
      pages,
      newInteractionType as LinkTypeOption,
      newInteractionUrl,
      newInteractionPage,
      routes,
      link?.albumFolderData?.album.id,
      link?.albumFolderData
        ? ({
            gridType: link?.albumFolderData?.album.gridType,
            title: link?.albumFolderData?.title,
            folderGridType: link?.albumFolderData?.folder.gridType,
            showTitles: link?.albumFolderData?.showTitleAndText,
            allowShowCaption: link?.allowShowCaption
          } as IGallerySettings)
        : null,
      link?.albumFolderData?.folder.id,
      link?.albumFolderData?.galleryFolderAlias,
    );
  } else {
    sLink = getInteractionUrl(
      pages,
      newInteractionType as LinkTypeOption,
      newInteractionUrl,
      newInteractionPage,
      routes,
      link?.albumFolderData?.album.id,
      link?.albumFolderData
        ? ({
            gridType: link?.albumFolderData?.album.gridType,
            title: link?.albumFolderData?.title,
            folderGridType: link?.albumFolderData?.folder.gridType,
            showTitles: link?.albumFolderData?.showTitleAndText,
            allowShowCaption: link?.allowShowCaption
          } as IGallerySettings)
        : null,
      link?.albumFolderData?.folder.id,
      "",
      feature
    );
  }

  let pathsNames = window.location.pathname.split("/");
  let isSubPage = pathsNames.length === 4;
  if (link?.type?.toLocaleLowerCase() === "page" && isSubPage && isPublish) {
    sLink = `${window.location.origin}/${pathsNames[1]}/${sLink}`;
  }

  return sLink;
};

export const onLinkClick = (
  pages: INavigationMenuItem[],
  interactionNewTab?: boolean,
  interactionUrl?: string,
  interactionPage?: string,
  interactionType?: string,
  routes?: IRoutes,
  link?: ILink,
  isPublish?: boolean,
  getInteractionUrl?: getInteractionUrlType,
  feature?: IFeature,
) => {
  let sLink: any = "";

  if (isPublish && link && getInteractionUrl) {
    sLink = getInteractionUrl(
      pages,
      link?.type || "",
      link?.url || "",
      link?.page || "",
      routes ? routes : {},
      link?.albumFolderData?.album.id || "",
      link?.albumFolderData
        ? {
            gridType: link?.albumFolderData?.album.gridType,
            title: link?.albumFolderData?.title,
            folderGridType: link?.albumFolderData?.folder.gridType,
            showTitles: link?.albumFolderData?.showTitleAndText,
            allowShowCaption: link?.allowShowCaption
          }
        : null,
      link?.albumFolderData?.folder.id || "",
      link?.albumFolderData?.galleryFolderAlias,
      feature
    );
  } else {
    sLink = getFinalInteractionUrl(!!isPublish, pages, interactionUrl, interactionPage, interactionType, routes, link, feature);
  }

  if (sLink) {
    if (
      isPublish &&
      ((link?.type === LinkTypeOption.PAGE && link?.page) ||
        (interactionType === INTERACTION_TYPE.PAGE && interactionPage))
    ) {
      const urlData = window.location.pathname.split("/");
      sLink = isDevelopment
        ? window.location.origin + "/" + urlData[1] + "/" + sLink
        : window.location.origin + "/" + sLink;
    }

    if (link?.newTab || interactionNewTab) {
      window.open(sLink, "_blank");
    } else {
      document.location.href = sLink;
    }
  }
};

export const getFolderContentUrlPublish = (
  galleryId: string = "",
  gallerySettings: IGallerySettings | null = null,
  folderId: string = "",
  galleryFolderAlias: string = "",
  linkedFolderId: string = "",
  linkedFolderQuery: string = "",
  currentPage: IPage | undefined
) => {
  let result = "";
  const actualUrl: string = window.location.href.split("?")[0];
  const urlParams = getAllQuerystringParameters(window.location.search);
  if (gallerySettings) {
    // In-case: we will set rootId to linkedFolderId if it's using the LinkecFolderBlock
    const rootId = urlParams.rootId || linkedFolderId;
    if (galleryId) {
      result = `${actualUrl}/${galleryFolderAlias}?ct=${CONTENT_TYPE.DYNAMICGALLERY}&gt=${
        gallerySettings.gridType
      }&st=${gallerySettings.showTitles ? "1" : "0"}&id=${galleryId}&rootId=${rootId}${linkedFolderQuery}&rootPageId=${
        urlParams.rootPageId ? urlParams.rootPageId : currentPage?.id
      }${urlParams.srf ? "&srf=" + urlParams.srf : ""}${urlParams.ah ? "&ah="+urlParams.ah : ""}`;
    }

    if (folderId) {
      result = `${actualUrl}/${galleryFolderAlias}?ct=${CONTENT_TYPE.DYNAMICFOLDER}&gt=${gallerySettings.gridType}&st=${
        gallerySettings.showTitles ? "1" : "0"
      }&id=${folderId}&rootId=${rootId}${linkedFolderQuery}&rootPageId=${
        urlParams.rootPageId ? urlParams.rootPageId : currentPage?.id
      }${urlParams.srf ? "&srf=" + urlParams.srf : ""}${urlParams.ah ? "&ah="+urlParams.ah : ""}`;
    }
  }

  return result;
};

export const getFolderContentUrl = (
  galleryId: string = "",
  gallerySettings: IGallerySettings | null = null,
  folderId: string = "",
  rootFolderId: string = "",
  linkedFolderQuery: string = ""
) => {
  let result = "";
  let actualUrl: string = window.location.href;
  const pos = actualUrl.indexOf("folders");
  const urlParams = getAllQuerystringParameters(window.location.search);
  if (pos >= 0) {
    actualUrl = actualUrl.substring(0, pos - 1);
  }

  if (gallerySettings) {
    actualUrl = actualUrl.endsWith("/") ? actualUrl : `${actualUrl}/`;
    if (galleryId) {
      result =
        actualUrl +
        "folders/" +
        rootFolderId +
        "/galleries/" +
        galleryId +
        "?gt=" +
        gallerySettings.gridType +
        "&st=" +
        (gallerySettings.showTitles ? "1" : "0") +
        linkedFolderQuery +
        (urlParams.srf ? "&srf=" + urlParams.srf : "")+
        (urlParams.ah ? "&ah=" + urlParams.ah : "");
    }
    if (folderId) {
      result =
        actualUrl +
        "folders/" +
        rootFolderId +
        "/subfolders/" +
        folderId +
        "?gt=" +
        gallerySettings.gridType +
        "&st=" +
        (gallerySettings.showTitles ? "1" : "0") +
        linkedFolderQuery +
        (urlParams.srf ? "&srf=" + urlParams.srf : "")+
        (urlParams.ah ? "&ah=" + urlParams.ah : "");
    }
  }
  return result;
};

export const getValidUrl = (url: string) => {
  const pattern = /^((http|https):\/\/)/;

  if (!pattern.test(url)) {
    url = "http://" + url;
  }
  return url;
};

export const searchNode = (node: INavigationMenuItem, matchingId: any, path: string, getPath: boolean): any => {
  if (node.id === matchingId) {
    return getPath ? path : node;
  } else if (node.childs && node.childs.length > 0) {
    let i;
    let result = null;
    for (i = 0; result === null && i < node.childs.length; i++) {
      result = searchNode(node.childs[i], matchingId, `${path}/${node.childs[i].menuLink}`, getPath);
    }
    return result;
  }
  return null;
};

const getPageUrlById = (pages: INavigationMenuItem[], id: string) => {
  const node = searchNode(
    {
      id: "",
      menuCaption: "",
      menuName: "",
      childs: pages
    },
    id,
    "",
    false
  );
  return `/clientview/pages/${node ? node.id : pages && pages.length ? pages[0].id : ""}`;
};

export const getInteractionUrlPublish = (
  pages: INavigationMenuItem[],
  interactionType: string,
  interactionUrl: string,
  interactionPage: string,
  routes?: IRoutes,
  galleryId: string = "",
  gallerySettings: IGallerySettings | null = null,
  folderId: string = "",
  galleryFolderAlias: string = "",
) => {
  let result = "";
  switch (interactionType) {
    case LinkTypeOption.URL:
      result = interactionUrl ? getValidUrl(interactionUrl) : "";
      break;
    case LinkTypeOption.PAGE:
      if (routes) {
        result =
          Object.keys(routes).length > 0
            ? routes[interactionPage]
              ? routes[interactionPage]
              : Object.keys(routes)[0]
            : getPageUrlById(pages, interactionPage);
      }
      break;
    case LinkTypeOption.GALLERY:
    case LinkTypeOption.ALBUMFOLDER:
      const baseUrl = window.location.pathname;
      if (gallerySettings) {
        if (galleryId) {
          result = `${baseUrl}/${galleryFolderAlias}?ct=${CONTENT_TYPE.DYNAMICGALLERY}&gt=${
            gallerySettings.gridType
          }&st=${gallerySettings.showTitles ? "1" : "0"}&id=${galleryId}`;
        }
        if (folderId) {
          result = `${baseUrl}/${galleryFolderAlias}?ct=${CONTENT_TYPE.DYNAMICFOLDER}&gt=${
            gallerySettings.gridType
          }&st=${gallerySettings.showTitles ? "1" : "0"}&id=${folderId}&rootId=${folderId}`;
        }
      }
      break;
    default:
      result = "";
      break;
  }
  return result;
};

export const getInteractionUrl = (
  pages: INavigationMenuItem[],
  interactionType: string,
  interactionUrl: string,
  interactionPage: string,
  routes?: IRoutes,
  galleryId: string = "",
  gallerySettings: IGallerySettings | null = null,
  folderId: string = "",
  galleryFolderAlias: string = "",
  feature?: IFeature
) => {
  let result = "";
  switch (interactionType) {
    case INTERACTION_TYPE.URL:
    case LinkTypeOption.URL:
      result = interactionUrl ? getValidUrl(interactionUrl) : "";
      break;
    case INTERACTION_TYPE.PAGE:
    case LinkTypeOption.PAGE:
      if (routes) {
        result =
          Object.keys(routes).length > 0
            ? routes[interactionPage]
              ? routes[interactionPage]
              : Object.keys(routes)[0]
            : getPageUrlById(pages, interactionPage);
      }
      break;
    case LinkTypeOption.ALBUMFOLDER:
    case LinkTypeOption.GALLERY:
      if (gallerySettings) {
        const captionSetting =
          gallerySettings.allowShowCaption !== undefined ? `&sc=${Number(gallerySettings.allowShowCaption)}` : "";
        if (galleryId) {
          result = combineURLs(
            window.location.href,
            `/galleries/${galleryId}?ct=${CONTENT_TYPE.DYNAMICGALLERY}&gt=${gallerySettings.gridType}&st=${
              gallerySettings.showTitles ? "1" : "0"
            }${captionSetting}&srf=true${feature?.isAddHeroBlock ? "&ah=true" : ""}`
          );
        }
        if (folderId) {
          result = combineURLs(
            window.location.href,
            `/folders/${folderId}?gt=${gallerySettings.gridType}&st=${
              gallerySettings.showTitles ? "1" : "0"
            }${captionSetting}&srf=true${feature?.isAddHeroBlock ? "&ah=true" : ""}`
          );
        }
      }
      break;
    default:
      result = "";
      break;
  }
  return result;
};

export const executeHeroScroll = (target: number) => {
  let currentValue = document.body.scrollTop;

  const frame = () => {
    if (currentValue < target) {
      currentValue = currentValue + 40;

      if (currentValue > target) {
        currentValue = target;
      }

      document.body.scrollTop = currentValue;
      window.requestAnimationFrame(frame);
    }
  };

  window.requestAnimationFrame(frame);
};
