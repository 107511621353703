import {
  Button,
  Collapse,
  DurationInput,
  FormCheckbox,
  FormRadio,
  NumberInput,
  Panel,
  SingleDateInput
} from "@zenfolio/core-components";
import classNames from "classnames";
import _ from "lodash";
import { Moment } from "moment";
import * as React from "react";
import Scrollbars from "react-custom-scrollbars";
import BookableHours from "../../../containers/modal/modals/bookableHours/simple/index";
import ModalDialog from "../../../containers/modal/modals/modalDialog/index";
import { ITimeSlot } from "../../../store/profile/availability/model";
import {
  IAvailability,
  IAvailabilityEdit,
  ServiceAvailability,
  SessionsPerDayLimit
} from "../../../store/services/model";
import { hideTrackHorizontal } from "../../../utilities/helpers";
import { NavigationManager } from "../../../utilities/navigationManager";
import LinkButton from "../../linkButton/index";
import UrlConstants from "../../router/constants";
import { renderDurationAsTime } from "../common";
import styles from "./index.module.scss";

type PanelName = "buffer-time" | "sessions-per-day" | "available-dates";

interface IServiceAvailabilityModalProps {
  availability: IAvailability;
  serviceAvailability: ServiceAvailability;
  sessionsPerDayLimit: SessionsPerDayLimit;
  onSave: (updatedAvailability: IAvailabilityEdit) => void;
  onCancel: () => void;
}

interface IServiceAvailabilityModalState {
  panels: { [id: string]: boolean };
  updatedAvailability: IAvailabilityEdit;
}

class ServiceAvailabilityModal extends React.Component<IServiceAvailabilityModalProps, IServiceAvailabilityModalState> {
  constructor(props: IServiceAvailabilityModalProps) {
    super(props);
    this.state = {
      panels: {},
      updatedAvailability: {
        availableStartDate: props.availability.availableStartDate,
        availableEndDate: props.availability.availableEndDate,
        availableHours: props.availability.availableHours,
        preEventBuffer: props.availability.preEventBuffer,
        postEventBuffer: props.availability.postEventBuffer,
        sessionsPerDay: props.availability.sessionsPerDay,
        serviceAvailability: props.serviceAvailability,
        sessionsPerDayLimit: props.sessionsPerDayLimit
      }
    };
  }

  public render() {
    const { availableHours } = this.state.updatedAvailability;
    const valid = this.isValid();

    return (
      <ModalDialog
        className={styles.availabilityDialog}
        okWhenClosed={false}
        title="SERVICE AVAILABILITY"
        onCancel={this.onCancel}
        noFooter={true}
        isFullScreen={true}
      >
        <Scrollbars renderTrackHorizontal={hideTrackHorizontal}>
          <div className={styles.container}>
            <div
              className={classNames(styles.availableDaysAndHours, this.isGlobal && styles.availableDaysAndHoursGlobal)}
            >
              <div className={styles.header}>Available Days and Hours</div>
              <div className={styles.option}>
                <FormRadio
                  name="serviceAvailability"
                  checked={this.isGlobal}
                  onChange={this.handleSelectGlobalServiceAvailability}
                  className={classNames(styles.radio, this.isGlobal && styles.active)}
                >
                  Use <LinkButton onClick={this.openGlobalBookableHours}>Global Bookable Hours</LinkButton>
                </FormRadio>
              </div>
              <div className={styles.option}>
                <FormRadio
                  name="serviceAvailability"
                  checked={!this.isGlobal}
                  onChange={this.handleSelectCustomServiceAvailability}
                  className={classNames(styles.radio, !this.isGlobal && styles.active)}
                >
                  Set custom availability
                </FormRadio>
              </div>
              {!this.isGlobal && <BookableHours onChange={this.onChangeDates} availableHours={availableHours} />}
            </div>
            {this.renderPanel("Buffer Time", "buffer-time", false, false, true, this.renderBufferTime)}
            {this.renderPanel("Sessions Per Day", "sessions-per-day", false, false, true, this.renderSessionsPerDay)}
            {this.renderPanel(
              "Available Dates",
              "available-dates",
              true,
              this.isGlobal,
              false,
              this.renderAvailableDates
            )}
            <div className={classNames(styles.footer, !this.isPanelOpen("available-dates") && styles.footerBorder)}>
              <Button type="button" styleType="primary" onClick={this.onSave} disabled={!valid}>
                Save
              </Button>
            </div>
          </div>
        </Scrollbars>
      </ModalDialog>
    );
  }

  private renderPanel(
    title: string,
    name: PanelName,
    optional: boolean,
    disabled: boolean,
    bordered: boolean,
    render: () => React.ReactNode
  ) {
    return (
      <>
        <div className={classNames(styles.section, disabled && styles.disabled)}>
          <Collapse>
            <Panel
              title={this.renderTitle(title, optional)}
              name={name}
              headerClassName={styles.panelHeader}
              {...Panel.defaultProps}
              open={this.isPanelOpen(name)}
              onChange={this.handleTogglePanel}
              showArrow={true}
            >
              {render()}
            </Panel>
          </Collapse>
        </div>
        {bordered && <hr className={styles.panelBorder} />}
      </>
    );
  }

  private renderAvailableDates = () => {
    const { availableStartDate, availableEndDate } = this.state.updatedAvailability;
    const isStartOutsideRange = (day: any) => (availableEndDate ? !availableEndDate.isSameOrAfter(day) : false);
    const isEndOutsideRange = (day: any) => (availableStartDate ? !availableStartDate.isSameOrBefore(day) : false);

    return (
      <div className={styles.availableDates}>
        <div className={styles.description}>
          Use this section to specify a single day, or a date range, the service is available.
        </div>

        <label className={styles.header}>Start</label>
        <div className={styles.dateContainer}>
          <SingleDateInput
            date={availableStartDate}
            placeholder="Date"
            onChangeDate={this.onChangeStartDate}
            isOutsideRange={isStartOutsideRange}
          />
          <span onClick={this.handleResetStartDate}>Clear</span>
        </div>

        <label className={styles.header}>End</label>
        <div className={styles.dateContainer}>
          <SingleDateInput
            date={availableEndDate}
            placeholder="Date"
            onChangeDate={this.onChangeEndDate}
            isOutsideRange={isEndOutsideRange}
          />
          <span onClick={this.handleResetEndDate}>Clear</span>
        </div>
      </div>
    );
  };

  private renderBufferTime = () => {
    const { preEventBuffer, postEventBuffer } = this;
    const totalBuffer = preEventBuffer + postEventBuffer;

    return (
      <div className={styles.bufferTime}>
        <div className={styles.bufferTimeForm}>
          <div className={styles.bufferTimeColumn}>
            <label>Pre-event buffer</label>
            <DurationInput
              {...ServiceAvailabilityModal.bufferProps}
              value={preEventBuffer}
              onChange={this.onChangePreEventBuffer}
              errorMessage={this.preEventBufferErrorMessage}
            />
          </div>
          <div className={styles.bufferTimeColumn}>
            <label>Post-event buffer</label>
            <DurationInput
              {...ServiceAvailabilityModal.bufferProps}
              value={postEventBuffer}
              onChange={this.onChangePostEventBuffer}
              errorMessage={this.postEventBufferErrorMessage}
            />
          </div>
        </div>
        <p>
          {totalBuffer > 0
            ? `When a client books this service, BookMe will pad the calendar invite by ${renderDurationAsTime(
                totalBuffer
              )}.`
            : "When a client books this service, BookMe will not pad the calendar invite."}
        </p>
      </div>
    );
  };

  private renderSessionsPerDay = () => {
    const limitNotSet = this.state.updatedAvailability.sessionsPerDayLimit === SessionsPerDayLimit.NotSet;

    return (
      <div className={styles.sessionsPerDay}>
        <label>How many of this session can be booked in a day?</label>
        <NumberInput
          value={this.state.updatedAvailability.sessionsPerDay}
          min={1}
          max={288}
          disabled={limitNotSet}
          onChange={this.onChangeSessionsPerDay}
          inputContainer={{ className: styles.sessionsPerDayContainer }}
        />
        <FormCheckbox
          checked={limitNotSet}
          onChange={this.onChangeSessionsPerDayLimit}
          className={classNames(styles.sessionsPerDayLimit, limitNotSet && styles.checked)}
        >
          Do not set a limit
        </FormCheckbox>
      </div>
    );
  };

  private renderTitle = (title: string, optional: boolean) => {
    return <label>{`${title}${optional ? " (optional)" : ""}`}</label>;
  };

  private handleSelectGlobalServiceAvailability = () => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        serviceAvailability: ServiceAvailability.Global
      }
    });
  };

  private handleSelectCustomServiceAvailability = () => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        serviceAvailability: ServiceAvailability.Custom
      }
    });
  };

  private onChangeDates = (availableHours: ITimeSlot[]) => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        availableHours
      }
    });
  };

  private onChangeStartDate = (availableStartDate: Moment | null) => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        availableStartDate
      }
    });
  };

  private handleResetStartDate = () => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        availableStartDate: null
      }
    });
  };

  private onChangeEndDate = (availableEndDate: Moment | null) => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        availableEndDate
      }
    });
  };

  private handleResetEndDate = () => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        availableEndDate: null
      }
    });
  };

  private onChangePreEventBuffer = (preEventBuffer: number) => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        preEventBuffer
      }
    });
  };

  private onChangePostEventBuffer = (postEventBuffer: number) => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        postEventBuffer
      }
    });
  };

  private onChangeSessionsPerDay = (sessionsPerDay: number | null | undefined) => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        sessionsPerDay: sessionsPerDay!
      }
    });
  };

  private onChangeSessionsPerDayLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      updatedAvailability: {
        ...this.state.updatedAvailability,
        sessionsPerDayLimit: event.target.checked ? SessionsPerDayLimit.NotSet : SessionsPerDayLimit.Set
      }
    });
  };

  private isValid = () => {
    const {
      availableStartDate,
      availableEndDate,
      availableHours,
      serviceAvailability
    } = this.state.updatedAvailability;

    if (serviceAvailability === ServiceAvailability.Custom) {
      if (_.isEmpty(availableHours)) {
        return false;
      }

      if (availableStartDate && availableEndDate && availableStartDate > availableEndDate) {
        return false;
      }
    }

    if (this.preEventBufferErrorMessage || this.postEventBufferErrorMessage) {
      return false;
    }

    return true;
  };

  private validateBuffer(buffer: number) {
    return buffer % 5 === 0 ? undefined : "Must end in 5 or 0";
  }

  private isPanelOpen = (key: PanelName) => {
    if (key === "available-dates" && this.isGlobal) {
      return false;
    }

    return !!this.state.panels[key];
  };

  private handleTogglePanel = (key: string, open: boolean) => {
    this.setState({
      panels: {
        ...this.state.panels,
        [key]: open
      }
    });
  };

  private openGlobalBookableHours = () => {
    NavigationManager.postParentMessage({
      type: "open-in-new-tab",
      path: `${UrlConstants.profileAvailability}/bookable-hours`
    });
  };

  private onCancel = () => {
    this.props.onCancel();
  };

  private onSave = () => {
    this.props.onSave(this.state.updatedAvailability);
  };

  private get isGlobal() {
    return this.state.updatedAvailability.serviceAvailability === ServiceAvailability.Global;
  }

  private get preEventBuffer() {
    return this.state.updatedAvailability.preEventBuffer || 0;
  }

  private get postEventBuffer() {
    return this.state.updatedAvailability.postEventBuffer || 0;
  }

  private get preEventBufferErrorMessage() {
    return this.validateBuffer(this.preEventBuffer);
  }

  private get postEventBufferErrorMessage() {
    return this.validateBuffer(this.postEventBuffer);
  }

  private static readonly bufferProps = {
    min: 0,
    max: 480,
    step: 5
  };
}

export default ServiceAvailabilityModal;
