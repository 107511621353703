import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import DateTimeStep from "../../../../components/widget/steps/dateTime";
import { IAppState } from "../../../../store/state";
import { loadAvailability as loadAvailabilityAction } from "../../../../store/widget/actions";

function mapStateToProps({ widget: { loadAvailability } }: IAppState) {
  return {
    availability: loadAvailability.availability,
    availabilityServiceId: loadAvailability.serviceId,
    loadAvailabilityStatus: loadAvailability.status
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    onLoadAvailability: (serviceId: string) => dispatch(loadAvailabilityAction(serviceId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeStep);
