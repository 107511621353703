import React, { useMemo } from "react";
import styles from "./GalleryHeader.module.scss";
import cx from "classnames";
import ZenBaseBlock, { IZenBaseBlockProps } from "../../../blocks/zenBaseBlocks";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { getSiteFonts } from "utilities/blocks/site";
import { RichText } from "utilities/richText";

export interface IGalleryHeaderProps extends IZenBaseBlockProps {
  titleText?: string;
  shootDate?: string;
  blockText?: string;
  bgColor: string;
}

const GalleryHeader: React.FC<IGalleryHeaderProps> = props => {
  const descriptionHtml = useMemo(() => {
    const html = RichText.toHTML(RichText.fromJSON(props.blockText));
    return html ? { __html: html } : undefined;
  }, [props.blockText]);

  const {
    siteTheme,
    readOnly,
    desktopEnabled,
    tabletEnabled,
    mobileEnabled,
    divider,
    alignment,
    fontFamily,
    padding,
    backgroundType,
    titleText,
    shootDate,
    bgColor
  } = props;

  const blockTextColor = {
    color: getBlockTextColor(bgColor, siteTheme.accentColor.value, backgroundType, bgColor)
  };
  const siteFonts = getSiteFonts(siteTheme.fontsStyle);
  const titleStyle = { color: siteTheme.accentColor.value };
  const subtitleStyle = blockTextColor;
  const blockStyle = blockTextColor;

  return (
    <ZenBaseBlock
      siteTheme={siteTheme}
      readOnly={readOnly}
      desktopEnabled={desktopEnabled}
      tabletEnabled={tabletEnabled}
      mobileEnabled={mobileEnabled}
      divider={divider}
      alignment={alignment}
      fontFamily={siteFonts.secondary}
      padding={padding}
      paddingMobile={true}
    >
      <div className={cx(styles.zenContainer)}>
        <div className={cx(styles.titles)}>
          {titleText && titleText !== "" && (
            <div
              className={cx(styles.title, styles[siteFonts.primary], styles[siteFonts.primaryExtra])}
              style={titleStyle}
            >
              <span>{titleText}</span>
            </div>
          )}
          {shootDate && shootDate !== "" && (
            <div className={cx(styles.dateText, styles[siteFonts.secondary])} style={subtitleStyle}>
              <span>{shootDate}</span>
            </div>
          )}
        </div>

        {descriptionHtml && (
          <div className={cx(styles.block, styles[siteFonts.secondary])} style={blockStyle}>
            <span dangerouslySetInnerHTML={descriptionHtml}></span>
          </div>
        )}
      </div>
    </ZenBaseBlock>
  );
};

export default GalleryHeader;
