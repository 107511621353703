import { MAT_COLOR } from "../../designer/model";
import React, { Ref } from "react";

interface IMatProps {
  /** Original product size width (pixel) */
  productWidth: number;
  /** Original product size height (pixel) */
  productHeight: number;
  matColor: MAT_COLOR;
  /** Original mat width (pixel) */
  matWidth: number;
  matHeight?: number;
  isDisable: boolean;
  forwardedRef?: Ref<SVGSVGElement>;
}

export class Mat extends React.PureComponent<IMatProps, any> {
  isMatDifferent = () => {
    const { matHeight, matWidth } = this.props;
    return matHeight !== matWidth;
  }
  renderMats = () => {
    const { matColor, matHeight, matWidth, productWidth, productHeight } = this.props;

    return !!matHeight && this.isMatDifferent() ? (
      <>
        <rect x="0" y="20" width={matWidth} fill={matColor.toLowerCase()} height="100%" stroke={matColor.toLowerCase()}/>
        <rect x={productWidth - matWidth} y="20" fill={matColor.toLowerCase()} width={matWidth} height="100%" stroke={matColor.toLowerCase()}/>
        <rect x="50" y="0" width="100%" fill={matColor.toLowerCase()} height={matHeight} stroke={matColor.toLowerCase()}/>
        <rect x="50" y={productHeight - matHeight} fill={matColor.toLowerCase()} width="100%" height={matHeight} stroke={matColor.toLowerCase()}/>
      </>
    ) : (
      <rect width="100%" height="100%" fill="transparent" strokeWidth={matWidth*2} stroke={matColor.toLowerCase()} />
    )
  }
  public render() {
    const { children, productWidth, productHeight, forwardedRef, isDisable } = this.props;
    if (isDisable) {
      return children;
    }
    return (
      <svg ref={forwardedRef} viewBox={`0 0 ${productWidth} ${productHeight}`} >
        {children}
        {this.renderMats()}
      </svg>
    )
  }
}
