import { AddressInputValue } from "@zenfolio/core-components/dist/components/AddressInput/model";
import { IShootLocation } from "../../store/profile/shootLocation/model";
import { IService } from "../../store/widget/model";
import { StepName } from "./steps/constants";
import { IDateTimeParams } from "./steps/dateTime";
import { IDetailsParams } from "./steps/details";
import { IInRadiusLocationParams } from "./steps/location/inRadius";
import { IPrimaryOnlyLocationParams } from "./steps/location/primaryOnly";
import { IPaymentParams } from "./steps/payment/booking";
import { IServicesParams } from "./steps/services";

export enum Direction {
  Forward,
  Backward
}

export enum LayoutType {
  Desktop = "DESKTOP",
  Portrait = "PORTRAIT",
  Landscape = "LANDSCAPE"
}

export interface INavigable {
  deferNavigation: () => boolean;
}

export interface IWidgetStepProps {
  name: StepName;
  onRef: (ref: IWidgetStep | undefined) => void;
  getValues: () => IWidgetValues;
  nextStep?: () => void;
}

export interface IEditableWidgetStepProps extends IWidgetStepProps {
  updateValues: (values: StepParams) => void;
  updateValidate: (name: StepName, state: boolean) => void;
}

export interface ISupportValidation {
  isValid: () => boolean;
}

export interface IWidgetStep extends INavigable {
  saveAndContinue: (direction: Direction) => void;
}

export interface IValidatableWidgetStep extends IWidgetStep, ISupportValidation {}

export interface IShoppingAddressComponents {
  addressInputValue: AddressInputValue;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
}

export const emptyShoppingAddressComponents: IShoppingAddressComponents = {
  addressInputValue: null,
  addressLine2: "",
  city: "",
  state: "",
  zipCode: ""
};

export interface IState {
  value: string;
  label: string;
}

export interface IWidgetValues {
  service: IService | null;
  oldService: IService | null;
  shootLocation: IShootLocation | null;
  address: AddressInputValue;
  addressDetails: string | null;
  additionalInfo: string | null;
  dateTime: Date | null;
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  cardholderName: string | null;
  zipCode: string | null;
  agreePayDeposit: { [key: string]: boolean };
  billingAddress: IShoppingAddressComponents | null;
  customerAddress: IShoppingAddressComponents | null;
}

export type StepParams =
  | IServicesParams
  | IInRadiusLocationParams
  | IPrimaryOnlyLocationParams
  | IDetailsParams
  | IDateTimeParams
  | IPaymentParams;
