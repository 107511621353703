import cx from "classnames";
import pipe from "lodash/fp/pipe";
import React, { FC, useCallback, useMemo, useState } from "react";
import { createEditor, Editor, Node } from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";

import { INavigationMenuItem } from "models/models";
import FixedToolbar from "./components/FixedToolbar";
import FloatingToolbar from "./components/FloatingToolbar";
import styles from "./texteditor.module.scss";
import { deserialize, ELEMENT_TAGS, getSiteBackgroundColors, normalizeHtml, serialize, TEXT_TAGS } from "./utils";
import withLinks from "./utils/withLinks";

export interface IEditorProps {
  pages?: INavigationMenuItem[];
  contents: string;
  placeHolder?: string;
  onChange: (data: string) => void;
  onBlur?: (ev?: any) => void;
  readOnly?: boolean;
  toolBarClassName?: string;
  toolbarExtraText?: string;
  toolbarExtraComponent?: JSX.Element;
  editorClassName?: string;
  autoFocus?: boolean;
  spellCheck?: boolean;
  isFloating?: boolean;
  headingSize?: number;
  showLink?: boolean;
  showHeadings?: boolean;
  onHeadingChange?: (size: number) => void;
  onToggleDropdown?: (isOpen: boolean) => void;
  editorId: string;
  backgroundColor?: string;
  accentColor?: string;
}

interface IElementProps {
  element: any;
  attributes: any;
  children?: Node[];
}

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.heading === 32) {
    children = <h1 style={{ fontSize: "32px" }}>{children}</h1>;
  }

  if (leaf.heading === 24) {
    children = <h2 style={{ fontSize: "24px" }}>{children}</h2>;
  }

  if (leaf.heading === 18) {
    children = <h3 style={{ fontSize: "18px" }}>{children}</h3>;
  }

  return <span {...attributes}>{children}</span>;
};

const Element: FC<IElementProps> = ({ element, attributes, children }) => {
  switch (element.type) {
    case "link":
      return (
        <a href={`${element.href}`} target={element.target} {...attributes}>
          {children}
        </a>
      );
    case "heading-one":
      return (
        <h1 style={{ fontSize: 32 }} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={{ fontSize: 24 }} {...attributes}>
          {children}
        </h2>
      );
    case "heading-three":
      return (
        <h3 style={{ fontSize: 18 }} {...attributes}>
          {children}
        </h3>
      );
    default:
      return <div {...attributes}>{children}</div>;
  }
};

let editorId: string | null = null;
let fragment: any = null;
let range: any = null;

const createEditorWithPlugins = pipe(withReact, withHistory, withLinks);

const TextEditor: React.FC<IEditorProps> = ({
  autoFocus = false,
  spellCheck = true,
  isFloating = false,
  readOnly = false,
  showLink = false,
  showHeadings = false,
  ...props
}) => {
  const document = new DOMParser().parseFromString(normalizeHtml(props.contents), "text/html");
  const nodes = deserialize(document.body, ELEMENT_TAGS, TEXT_TAGS);

  const [value, setValue] = useState(nodes);
  const [savedSelection, setSavedSelection] = useState<any>(null);
  const [isShowToolbar, setIsShowToolbar] = useState<boolean>(false);
  const [canSaveRange, setCanSaveRange] = useState<boolean>(false);

  const renderLeaf = (propsLeaf: any) => <Leaf {...propsLeaf} />;
  const renderElement = useCallback(props => <Element {...props} />, []);
  const editor = useMemo(() => createEditorWithPlugins(createEditor()), []);

  const saveSelection = () => {
    const window = ReactEditor.getWindow(editor);
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel && sel.getRangeAt && sel.rangeCount) {
        return sel.getRangeAt(0);
      }
    }

    return null;
  };

  const saveRangeEvent = () => {
    range = saveSelection();
    if (range && !range.collapsed) {
      fragment = range.cloneContents();
    }
  };

  const removeHighlight = () => {
    if (fragment) {
      range.deleteContents();
      range.insertNode(fragment);
    }

    fragment = null;
  };

  const onEditorFocus = () => {
    if (editorId !== props.editorId) {
      editorId = props.editorId;
    }
  };

  const onChange = (valueParam: Node[]) => {
    setValue(valueParam);
    let element: string = "";
    valueParam.forEach(n => {
      element = element + serialize(n);
    });

    if (editor.selection) {
      const currentSelectedText = Editor.string(editor, editor.selection);
      setSavedSelection(editor.selection);
      if (!isShowToolbar && currentSelectedText !== "") {
        setIsShowToolbar(true);
      }

      if (currentSelectedText === "") {
        if (isShowToolbar) {
          setIsShowToolbar(false);
          setCanSaveRange(false);
        }

        removeHighlight();
      }
    } else {
      if (editorId !== props.editorId) {
        // remove old state select if it was saved
        if (savedSelection) {
          removeHighlight();
          setSavedSelection(null);
        }

        // close toolbar if it's displaying
        if (isShowToolbar) {
          setIsShowToolbar(false);
          setCanSaveRange(false);
        }
      } else {
        if (fragment) {
          const span = document.createElement("span");
          span.className = styles.bgColor;
          range.surroundContents(span);
        }
      }
    }

    props.onChange(element);
  };

  const onBlur = () => {
    if (canSaveRange) {
      saveRangeEvent();
    }
  };

  const onCancel = () => {
    setIsShowToolbar(false);
    setCanSaveRange(false);
    removeHighlight();
    setSavedSelection(null);
  };

  const onLinkClick = (val: boolean) => {
    setCanSaveRange(val);
  };

  const sideClass = styles[`${getSiteBackgroundColors(props.backgroundColor)}`];
  const accentColor = props.accentColor;

  return (
    <div id={props.editorId} onMouseDown={onEditorFocus}>
      <style>
        {`
            :root {
              --hover-hyper-link: ${accentColor};
              }
            `}
      </style>
      <Slate editor={editor} value={value} onChange={onChange}>
        {isFloating ? (
          <FloatingToolbar
            editor={editor}
            pages={props.pages}
            className={props.toolBarClassName}
            showLink={showLink}
            showHeadings={showHeadings}
            headingSize={props.headingSize}
            onHeadingChange={props.onHeadingChange}
            onToggleDropdown={props.onToggleDropdown}
            isShowToolbar={isShowToolbar}
            savedSelection={savedSelection}
            onCancel={onCancel}
            onLinkClick={onLinkClick}
          />
        ) : (
          <FixedToolbar
            className={props.toolBarClassName}
            extraText={props.toolbarExtraText}
            extraComponent={props.toolbarExtraComponent}
          />
        )}

        <Editable
          renderLeaf={renderLeaf}
          renderElement={renderElement}
          placeholder={props.placeHolder}
          spellCheck={spellCheck}
          autoFocus={autoFocus}
          className={cx(styles.container, props.editorClassName, sideClass)}
          onDrop={(e: any) => e.preventDefault()}
          readOnly={readOnly}
          onBlur={props.onBlur}
        />
      </Slate>
    </div>
  );
};

export default TextEditor;
