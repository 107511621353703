import React from "react";
import { initializeIconProps } from "../../../utilities/icons";
import IIConProps from "../IIconProps";

const Activation = (rawProps: IIConProps) => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`${props.minX} ${props.minY} 24 24`}
    >
      <g fill="none" fillRule="evenodd" stroke={props.stroke} strokeLinecap="round" strokeLinejoin="round">
        <path d="M17.503 13h2.164v-2h-2.165c-.14-.578-.367-1.454-.669-1.948l1.53-1.53-1.885-1.886-1.531 1.53c-.494-.3-1.37-.528-1.947-.667V4.333h-2V6.5c-.578.139-1.454.366-1.947.668L7.52 5.636 5.635 7.522l1.532 1.53c-.302.494-.53 1.37-.67 1.948H4.333v2h2.164c.14.578.368 1.454.67 1.947L5.635 16.48l1.886 1.885 1.53-1.531c.495.302 1.37.53 1.949.67v2.164h2v-2.164c.578-.14 1.454-.368 1.947-.67l1.532 1.531 1.885-1.885-1.53-1.532c.3-.493.529-1.369.669-1.947z" />
        <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
      </g>
    </svg>
  );
};

export default Activation;
