import cx from "classnames";
import BentoBoxResponsive, { IBentoBoxPropsPhoto } from "components/BentoBoxResponsive";
import ZenButton from "components/blocks/zenButton";
import Breadcrumb, {
  buildLinkedFolderQueryParam,
  excludeLinkedFolderItem,
  getCurrentBreadcrumb,
  getFlattenPages,
  getPagesBreadcrumbs,
  IBreadcrumbItem,
  IExtraPage
} from "components/ClientView/Breadcrumb";
import { portfolioErrorGoBack } from "components/ClientView/DynamicGallery/Views/PrivateError";
import AlbumLock from "components/Icons/AlbumLock";
import { IToaster } from "components/Toaster";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import _find from "lodash/find";
import _cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import pluralize from "pluralize";
import React, { PureComponent } from "react";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { getFolderContentUrl, getFolderContentUrlPublish } from "utilities/blocks/blockInteractions";
import { getSiteFonts, ISiteFontPair } from "utilities/blocks/site";
import { parseBody, renderLineBreaks, stringIsGuid } from "utilities/blocks/string";
import {
  getAllQuerystringParameters,
  getFolderUrlAlias,
  getLinkFolderIdFromUrl,
  processStaticImageUrl
} from "utilities/blocks/url";
import colors from "utilities/colors";
import styles from "./folder.module.scss";
import {
  IClientViewFolderState,
  IFolderContent,
  IPage,
  IPhotoFolder,
  IPhotographerFoldersState,
  IZenSiteTheme
} from "../../../../models/models";
import { PageContext } from "utilities/pageContext";
import { orderItemsByKey } from "utilities/helpers";
import {
  formatPhotoSrcSet,
  formatPhotoUrl,
  formatThumbnailUrl,
  formatVideoSrcSet,
  MESizeCodes,
  MESizeMethod
} from "utilities/getImgUrl";
import { IGallerySettings } from "models/blocks";

export interface IDynamicFolderProps {
  alignment?: string;
  showInfo?: boolean;
  perPage?: string;
  order?: string;
  siteTheme: IZenSiteTheme;
  folderId: string;
  appCdnUrl: string;
  sitePages: IPage[];
  pageAlias: string; // Needed only for breadcrumbs
  subdomain?: string;
  extraPages?: IExtraPage[];
  defaultPictureUrl?: string;
  gallerySettings: IGallerySettings;
  clientViewFolder: IClientViewFolderState;
  photographerFolders: IPhotographerFoldersState;
  rootFolderId?: string; // Needed to determine root folder or linked id if it can't be defined from query param. Is used for breadcrumbs and on click urls
  isDevelopment: boolean;
  isPublish?: boolean; // This props indicate component runs on published site
  readOnly?: boolean; // This props indicate component runs in preview
  isNormalCase?: boolean;
  visibleItemsCount?: number;
  isLinkedFolder?: boolean;
  padding?: string;
  onShowAlertError: (toaster: IToaster) => void;

  customHandleButtonClick?: (content: IFolderContent) => void;
  breadcrumbItems?: IBreadcrumbItem[];
  isBreadcrumbLoaded?: boolean;
}

interface IDynamicFolderState {
  breadcrumbItems: IBreadcrumbItem[];
  isBreadcrumbLoaded: boolean;
  scaleFactor?: number;
}

class DynamicFolderView extends PureComponent<IDynamicFolderProps, IDynamicFolderState> {
  public static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  constructor(props: IDynamicFolderProps) {
    super(props);
    this.state = {
      breadcrumbItems: [],
      isBreadcrumbLoaded: false
    };
  }

  public componentDidMount() {
    portfolioErrorGoBack();

    if (!this.props.breadcrumbItems && this.props.photographerFolders.folders.length > 0) {
      this.updateBreadcrumbState();
    }
  }

  public componentDidUpdate(prevProps: IDynamicFolderProps) {
    if (
      !this.props.breadcrumbItems &&
      this.breadcrumbDataIsAvailable(prevProps.photographerFolders, this.props.photographerFolders)
    ) {
      this.updateBreadcrumbState();
    }
  }

  private cancelDrag = (event: any) => {
    event.preventDefault();
    return false;
  };

  private getLinkedFolderId = () => {
    let linkedFolderId: string = getLinkFolderIdFromUrl();
    if (!stringIsGuid(linkedFolderId) && !!this.props.isLinkedFolder && !!this.props.rootFolderId) {
      linkedFolderId = this.props.rootFolderId;
    }
    return linkedFolderId;
  };

  // It will redirect to selected item (gallery, folder) by changing url or ...(preview)
  private handleButtonClick = (content: IFolderContent) => {
    const { gallerySettings, readOnly, sitePages, pageAlias } = this.props;
    let sUrl = "";

    if (readOnly === true) {
      return;
    }

    const linkedFolderId = this.getLinkedFolderId();
    const linkedFolderQuery = buildLinkedFolderQueryParam(linkedFolderId);
    const rootFolderId = stringIsGuid(linkedFolderId) ? linkedFolderId : this.props.rootFolderId;

    const pagesNode: IPage = {
      id: "root",
      alias: "rootAlias",
      name: "rootName",
      html: "rootHtml",
      css: "rootCss",
      parentId: "rootParentId",
      sortIndex: 0,
      pages: _cloneDeep(sitePages)
    };
    const flattenPages: IPage[] = [];
    let currentPage: IPage | undefined;
    getFlattenPages(pagesNode, pageAlias, flattenPages);
    currentPage = _find(flattenPages, { alias: pageAlias });
    if (this.props.isPublish) {
      sUrl = getFolderContentUrlPublish(
        content.isAlbum ? content.id : "",
        gallerySettings,
        !content.isAlbum ? content.id : "",
        !content.isAlbum ? getFolderUrlAlias(content.name) : content.albumAlias,
        linkedFolderId,
        linkedFolderQuery,
        currentPage
      );
    } else {
      sUrl = getFolderContentUrl(
        content.isAlbum ? content.id : "",
        gallerySettings,
        !content.isAlbum ? content.id : "",
        rootFolderId,
        linkedFolderQuery
      );
    }

    if (
      !this.context.isPreview &&
      ((content.isProtected && content.isVisitorAllowed) ||
        (content.isVisitorEmailRequired && content.isVisitorAllowed))
    ) {
      const win = window.open(sUrl, "_blank");
      if (win) {
        win.focus();
      }
    } else {
      window.location.href = sUrl;
    }
  };

  public get buttonClickHandler() {
    return this.props.customHandleButtonClick ?? this.handleButtonClick;
  }

  public updateBreadcrumbState = () => {
    const { sitePages, pageAlias, subdomain, rootFolderId, isPublish, isDevelopment, extraPages } = this.props;

    const pagesBreadcrumb = getPagesBreadcrumbs(
      sitePages,
      pageAlias,
      subdomain ? subdomain : "",
      isDevelopment,
      isPublish,
      extraPages
    );

    let foldersGalleriesBreadcrumb = getCurrentBreadcrumb(pagesBreadcrumb, this.props, true, rootFolderId, isPublish);

    // Exclude linkedFolder item in the list of menus
    const linkedFolderId = this.getLinkedFolderId();
    const urlParams = getAllQuerystringParameters(window.location.search);
    if (stringIsGuid(linkedFolderId) && !urlParams.srf) {
      foldersGalleriesBreadcrumb = excludeLinkedFolderItem(foldersGalleriesBreadcrumb, linkedFolderId);
    }

    this.setState({
      breadcrumbItems: [...foldersGalleriesBreadcrumb],
      isBreadcrumbLoaded: true
    });
  };

  private getSortedItems(items: IFolderContent[], sortKey: string | undefined): IFolderContent[] {
    if (!!sortKey) {
      items = orderItemsByKey(items, sortKey);
    }
    return items;
  }

  private breadcrumbDataIsAvailable = (
    prevFolders: IPhotographerFoldersState,
    currentFolders: IPhotographerFoldersState
  ) => prevFolders !== currentFolders && currentFolders.folders.length > prevFolders.folders.length;

  private getButton = (content: IFolderContent, buttonText: string) => {
    return (
      <div className={styles.buttonContainer}>
        <ZenButton siteTheme={this.props.siteTheme} labelText={buttonText} layout="A" />
      </div>
    );
  };

  private getBrickAlbum = (content: IFolderContent) => {
    const imgUrl = content.coverPhoto
      ? formatPhotoUrl(content.photoUrlTemplate, content.coverPhoto, MESizeCodes.SIZE500x500, MESizeMethod.Contain)
      : content.coverVideo
      ? formatThumbnailUrl(
          content.thumbnailUrlTemplate || "",
          content.coverVideo,
          MESizeCodes.SIZE500x500,
          MESizeMethod.Contain
        )
      : processStaticImageUrl("/storage/images/common/pictureNoContent.png");

    return (
      <div id={content.id} className={styles.contentBrickPictures} onClick={e => this.buttonClickHandler(content)}>
        <img
          alt={content.name}
          className={cx(
            "lazyload",
            styles.contentBrickPictureImg,
            content.isProtected || !content.isVisitorAllowed ? styles.protectedImage : ""
          )}
          data-src={imgUrl}
          onContextMenu={this.cancelDrag}
          draggable={false}
        />
        {(content.isProtected || !content.isVisitorAllowed) && (
          <div className={styles.albumLock}>
            <AlbumLock color={colors.darkGrey} size={24} />
          </div>
        )}
        {this.getButton(content, "View Gallery")}
      </div>
    );
  };

  private getBrickFolder = (content: IFolderContent, defaultPictureUrl?: string) => {
    const photos: IBentoBoxPropsPhoto[] = [];
    content.images.map((image: IPhotoFolder, idx: number) => {
      return photos.push({
        url: !!image.isVideo
          ? formatThumbnailUrl(
              image.thumbnailUrlTemplate || "",
              {
                ...image,
                videoVersion: image.photoVersion
              },
              MESizeCodes.SIZE500x500,
              MESizeMethod.Contain
            )
          : formatPhotoUrl(image.photoUrlTemplate, image, MESizeCodes.SIZE500x500, MESizeMethod.Contain),
        srcset: !!image.isVideo
          ? formatVideoSrcSet(image.thumbnailUrlTemplate || "", image, MESizeMethod.Contain)
          : formatPhotoSrcSet(image.photoUrlTemplate, image, MESizeMethod.Contain),
        isProtected: image.isProtected,
        alt: content.name
      });
    });

    return (
      <div className={styles.contentBrickFolder} onClick={e => this.buttonClickHandler(content)}>
        <BentoBoxResponsive
          id={content.id}
          photos={photos}
          defaultPictureUrl={
            defaultPictureUrl
              ? defaultPictureUrl
              : processStaticImageUrl("/storage/images/blocks/featured-works/NonFolderPlaceholder.png")
          }
        />
        {this.getButton(content, "View More")}
      </div>
    );
  };

  private getBricks = (defaultPictureUrl?: string) => {
    const { perPage, showInfo, isNormalCase, order, visibleItemsCount, isLinkedFolder } = this.props;
    let sortedFolderContent = this.props.clientViewFolder
      ? this.getSortedItems(this.props.clientViewFolder.folderContent, order)
      : [];
    const siteFonts = getSiteFonts(this.props.siteTheme.fontsStyle);

    // In-case: Limit the number of item display that appears on a page
    const maxItemsDisplay: number = !!visibleItemsCount ? visibleItemsCount : 0;
    if (!!isLinkedFolder && !isNaN(maxItemsDisplay) && maxItemsDisplay > 0) {
      sortedFolderContent = sortedFolderContent.slice(0, maxItemsDisplay);
    }

    return sortedFolderContent.map((content: IFolderContent, idx: number) => {
      const itemDate = content.shootDate ? content.shootDate : content.dateCreated;
      const formattedDate = moment(new Date(itemDate)).format("MMMM DD, YYYY");

      const albumCounters = pluralize("Photo", content.totalPhotos, true) + " - " + formattedDate;

      const folderCounters =
        pluralize("Folder", content.totalFolders, true) + " - " + pluralize("Gallery", content.totalAlbums, true);

      return (
        <div key={content.id} className={cx("lazyload", styles.contentBrick)}>
          {content.isAlbum ? this.getBrickAlbum(content) : this.getBrickFolder(content, defaultPictureUrl)}
          <span onClick={e => this.buttonClickHandler(content)} className={styles.contentBrickName}>
            {isNormalCase === true ? content.name : content.name.toUpperCase()}
          </span>
          {showInfo !== false &&
            (content.isAlbum ? (
              <span className={cx(styles.contentBrickDescription, styles[siteFonts.secondary])}>{albumCounters}</span>
            ) : (
              <span className={cx(styles.contentBrickDescription, styles[siteFonts.secondary])}>{folderCounters}</span>
            ))}
        </div>
      );
    });
  };

  private renderEmptyFolder = (siteFonts: ISiteFontPair, isLinkedFolder?: boolean) => {
    const message = !!isLinkedFolder
      ? "It appears there is no content here right now."
      : "It appears there is no content here right now. Check out another page or come back later.";
    return <div className={cx(styles.message, styles[siteFonts.secondary])}>{message}</div>;
  };

  private renderShowMoreItems = (siteFonts: ISiteFontPair) => {
    return (
      <div className={cx(styles.showMoreItemsLine, styles[siteFonts.secondary])}>
        Click preview to see the full galleries and folders.
      </div>
    );
  };

  public render() {
    const {
      siteTheme,
      clientViewFolder,
      gallerySettings,
      sitePages,
      defaultPictureUrl,
      alignment,
      readOnly,
      isNormalCase,
      isLinkedFolder,
      visibleItemsCount,
      padding,
      breadcrumbItems: breadcrumbItemsFromProps,
      isBreadcrumbLoaded: isBreadcrumbLoadedFromProps
    } = this.props;

    const { isBreadcrumbLoaded, breadcrumbItems: breadcrumbItemsInState } = this.state;
    const title = parseBody(clientViewFolder.name);
    const description = parseBody(clientViewFolder.description);
    const isEmptyFolder = clientViewFolder.folderContent.length === 0 && !clientViewFolder.isFetching;
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    const isShowMoreItemsView =
      !!isLinkedFolder && !!visibleItemsCount && visibleItemsCount < clientViewFolder.folderContent.length;
    const showBreadcrumbsBar =
      (!!breadcrumbItemsFromProps && isBreadcrumbLoadedFromProps) ||
      (isBreadcrumbLoaded && breadcrumbItemsInState.length > 0);
    const paddingStyle = !!padding
      ? {
          paddingTop: padding,
          paddingBottom: padding
        }
      : {};
    const marginStyle = !!padding ? { marginTop: "0px" } : {};

    const breadcrumbItems = breadcrumbItemsFromProps || breadcrumbItemsInState;

    return (
      <div
        id="container"
        className={cx(styles.container, styles[siteFonts.primary], {
          [styles.dynamicFolderEmpty]: isEmptyFolder && isLinkedFolder !== true,
          [styles.linkedFolderContainer]: isLinkedFolder === true,
          [styles.linkedFolderEmpty]: isEmptyFolder && isLinkedFolder === true
        })}
        style={paddingStyle}
      >
        <style>
          {`
            :root {
              --scale-factor: ${this.state.scaleFactor || 1};
              --accent-color: ${siteTheme.accentColor.value};
              --text-color: ${getBlockTextColor(siteTheme.backgroundColor.value, siteTheme.accentColor.value, "")}
              }
            `}
        </style>
        <div className={cx(styles.content, styles.lazyFadeIn)}>
          {showBreadcrumbsBar && (
            <Breadcrumb siteTheme={siteTheme} show={showBreadcrumbsBar} sitePages={sitePages} separator=">">
              {breadcrumbItems.map(({ to, label, isFull }) => (
                <a key={to} href={`${!isFull ? window.location.origin : ""}${to}`}>
                  {label}
                </a>
              ))}
            </Breadcrumb>
          )}
          <div
            className={cx(
              styles.folderTitleContainer,
              alignment === "left"
                ? styles.titlesLeft
                : alignment === "right"
                ? styles.titlesRight
                : styles.titlesCenter,
              styles[siteFonts.primary],
              { [styles.isEmpty]: isEmptyFolder }
            )}
            style={marginStyle}
          >
            {title && gallerySettings.showTitles && (
              <div
                className={cx(styles.folderTitle, styles[siteFonts.primaryExtra], {
                  [styles.titleClickable]: readOnly
                })}
              >
                {isNormalCase === true ? title : title.toUpperCase()}
              </div>
            )}
            {description && gallerySettings.showTitles && (
              <div
                className={cx(styles.folderDescription, styles[siteFonts.secondary], {
                  [styles.bodyClickable]: readOnly
                })}
              >
                {renderLineBreaks(description, true)}
              </div>
            )}
          </div>
          <div
            className={cx(styles.folderContent, {
              [styles.isEmpty]: isEmptyFolder
            })}
          >
            {isEmptyFolder ? this.renderEmptyFolder(siteFonts, isLinkedFolder) : this.getBricks(defaultPictureUrl)}
            {isShowMoreItemsView && this.renderShowMoreItems(siteFonts)}
          </div>
        </div>
      </div>
    );
  }
}

export default DynamicFolderView;
