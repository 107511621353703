import { SelectDropdown } from "@zenfolio/core-components";
import classNames from "classnames";
import React from "react";
import { ActivityStatusCode } from "../../../../../../store/billing/model";
import { ActivityActionCode, activityStatusActions, getActivityActionName } from "../model";
import styles from "./index.module.scss";

interface IActivityActionsProps {
  activityStatus?: ActivityStatusCode;
  availableRefundAmount?: number;
  onAction: (action: ActivityActionCode) => void;
  reset?: boolean;
  className?: string;
}

class ActivityActions extends React.Component<IActivityActionsProps> {
  public render() {
    const { className, reset } = this.props;
    const options = this.dropdownOptions;

    return (
      <div className={classNames(styles.container, className)}>
        <div className={styles.label}>Choose an action</div>
        <SelectDropdown
          options={options}
          value={null}
          isReset={reset}
          onChange={this.onChange}
          isDisabled={(options || []).length === 0}
          placeholder="Select"
          width="175px"
          minHeight={50}
          indicator={true}
          isSearchable={false}
          openMenuOnFocus={true}
        />
      </div>
    );
  }

  private get dropdownOptions() {
    const { activityStatus, availableRefundAmount } = this.props;

    return activityStatus === undefined
      ? []
      : activityStatusActions[activityStatus]
          .filter(action => action !== "refund" || (availableRefundAmount != null && availableRefundAmount >= 1))
          .map(action => ({ label: getActivityActionName(action), value: action }));
  }

  private onChange = (action: { value: ActivityActionCode }) => this.props.onAction(action.value);
}

export default ActivityActions;
