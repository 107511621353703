import _ from "lodash";
import IError from "../../utilities/error";
import { ActionStatus } from "../common";
import { LoadCustomizeDataAction, ResetCustomizeDataAction, UpdateCustomizeDataAction } from "./actions";
import { CustomizeActionType } from "./constants";
import { ICustomizeData } from "./model";

export interface ICustomizeState {
  data: ICustomizeData | null;
  load: ILoadCustomizeDataState;
  update: IUpdateCustomizeDataState;
}

export interface ILoadCustomizeDataState {
  status: ActionStatus;
  error: IError | null;
}

export interface IUpdateCustomizeDataState {
  status: ActionStatus;
  error: IError | null;
}

const initialLoadCustomizeDataState: ILoadCustomizeDataState = {
  status: "Init",
  error: null
};

const initialUpdateCustomizeDataState: IUpdateCustomizeDataState = {
  status: "Init",
  error: null
};

const initialCustomizeState: ICustomizeState = {
  data: null,
  load: initialLoadCustomizeDataState,
  update: initialUpdateCustomizeDataState
};

export const customize = (
  state: ICustomizeState = initialCustomizeState,
  action: ResetCustomizeDataAction | LoadCustomizeDataAction | UpdateCustomizeDataAction
): ICustomizeState => {
  switch (action.type) {
    case CustomizeActionType.RESET_CUSTOMIZE_DATA:
      return initialCustomizeState;
    case CustomizeActionType.LOAD_CUSTOMIZE_DATA_STARTED:
      return {
        ...state,
        load: {
          status: "Pending",
          error: null
        }
      };
    case CustomizeActionType.LOAD_CUSTOMIZE_DATA_SUCCESS:
      return state.load.status === "Pending"
        ? {
            ...state,
            load: {
              status: "Success",
              error: null
            },
            data: action.data
          }
        : state;
    case CustomizeActionType.LOAD_CUSTOMIZE_DATA_ERROR:
      return state.load.status === "Pending"
        ? {
            ...state,
            load: {
              status: "Error",
              error: action.error
            }
          }
        : state;
    case CustomizeActionType.UPDATE_CUSTOMIZE_DATA_STARTED:
      return {
        ...state,
        update: {
          status: "Pending",
          error: null
        }
      };
    case CustomizeActionType.UPDATE_CUSTOMIZE_DATA_SUCCESS:
      return state.update.status === "Pending"
        ? {
            ...state,
            update: {
              status: "Success",
              error: null
            },
            data: _.merge({}, state.data, action.updater)
          }
        : state;
    case CustomizeActionType.UPDATE_CUSTOMIZE_DATA_ERROR:
      return state.update.status === "Pending"
        ? {
            ...state,
            update: {
              status: "Error",
              error: null
            }
          }
        : state;
    default:
      return state;
  }
};

export default customize;
