import { IBlockPhotoAnimationClasses, IPhotoMasonry, NameType, NameVisibility } from "../blockModels";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import cx from "classnames";
import React from "react";
import Dropzone from "react-dropzone";
import { handleLazyLoaded } from "utilities/blocks/images";
import { getNameProps } from "utilities/blocks/masonry";
import { Utilities } from "utilities/utilities";
import { getPictureStyle } from "utilities/blocks/blockColors";
import ContextMenu from "utilities/contextMenu";
import colors from "utilities/colors";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import ZenPhotoTile from "components/blocks/zenPhotoTile";
import { universalWindow as window } from "utilities/blocks/site";
import styles from "./zenMasonryBlock.module.scss";
import { GalleryPresentSettingList } from "utilities/constant";

export interface IMasonryBlockProps extends IZenBaseBlockProps {
  images: IPhotoMasonry[];
  imageUpdated?: { index: number; image: IPhotoMasonry };
  brakePoints?: number[];
  layout: string;
  mobileLayout?: GalleryPresentSettingList;
  isStacked: boolean;
  resizeToFit?: boolean;
  selectedIndex?: number;
  isEditionView?: boolean;
  galleryStyle: string;
  dropShadow: boolean;
  className?: string;
  isGridFluid?: boolean;
  overlayComponent?: Array<JSX.Element | null>;
  overlayStyle?: string;
  animationClasses?: IBlockPhotoAnimationClasses;
  rowHeight?: number;
  showFileSelector?: boolean;
  acceptImageType?: string;
  titleClassName?: string;
  blockImageEvents?: boolean;
  contentsRef?: React.RefObject<HTMLDivElement>;
  isEditor?: boolean;
  showNames?: NameType;
  namesVisibility?: NameVisibility;
  onFilesSelected?: (file: File[]) => void;
  onPhotoClick?: (evt: React.MouseEvent<HTMLElement>, photoIndex: number) => void;
  getBadgeComponent?: (index: number) => JSX.Element | Element | null;
  getHoverComponent?: (index: number) => JSX.Element;
  onHoverPhoto?: (hoveredPhotoIndex: number) => void;
  onGetTitle?: (photo: IPhotoMasonry) => string;
  hasLightbox?: boolean;
  showLightbox?: boolean;
}
interface IMasonryBlockState {
  columns: number;
  maxCols: number;
  photoTiles: JSX.Element[];
  fullWidthStack: boolean;
  dropShadow: boolean;
  galleryStyle: string;
  gridFluidHeight: number;
  isFluidView: boolean;
  imgsUrlsMap: Map<string, IPhotoMasonry>;
  masonryColumns: JSX.Element[][];
}

export class ZenMasonryBlock extends React.Component<IMasonryBlockProps, IMasonryBlockState> {
  public static defaultProps = {
    images: [],
    accentColor: colors.white,
    dropShadow: false,
    galleryStyle: "none",
    isStacked: false,
    rowHeight: 300
  };

  private MasonryRef: React.RefObject<HTMLDivElement>;
  private delayTimerId: any;

  constructor(props: IMasonryBlockProps) {
    super(props);
    this.MasonryRef = React.createRef();
    let cols: number = props.layout === "A" ? 4 : props.layout === "B" ? 3 : props.layout === "C" ? 2 : 6;
    if (this.props.isStacked) {
      cols = 1;
    }
    let imgsUrlsMap = new Map();
    this.props.images.forEach(x => {
      imgsUrlsMap.set(x.url, x);
    });

    this.state = {
      columns: this.isMobile() ? this.getMobileColumns() : cols,
      photoTiles: [],
      masonryColumns: [],
      imgsUrlsMap,
      maxCols: cols,
      dropShadow: false,
      galleryStyle: "none",
      fullWidthStack: (props.layout === "A" || this.isMobile()) && props.isStacked,
      gridFluidHeight: 0,
      isFluidView: this.getIsFluidView()
    };
    this.handleResize = this.handleResize.bind(this);
  }

  public componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    if (this.props.images.length) {
      this.preparePhotos();
    }

    // This delay is necessary for waiting for the container element being created
    this.delayTimerId = setTimeout(
      (t: ZenMasonryBlock) => {
        t.handleResize();
      },
      500,
      this
    );

    const masonryRef = this.getRef();
    if (masonryRef && masonryRef.current) {
      masonryRef.current?.ownerDocument?.addEventListener("lazyloaded", this.handleLazyLoaded);
    }
  }

  public componentDidUpdate(prevProps: IMasonryBlockProps) {
    const { overlayComponent } = this.props;
    const images = this.props.images;
    const prevImages = prevProps.images;

    if ((this.props.layout && this.props.layout !== prevProps.layout) || this.props.isStacked !== prevProps.isStacked) {
      let cols: number =
        this.props.layout === "A" ? 4 : this.props.layout === "B" ? 3 : this.props.layout === "C" ? 2 : 6;
      if (this.props.isStacked) {
        cols = 1;
      }
      this.setState(
        {
          columns: cols,
          maxCols: cols,
          fullWidthStack: (this.props.layout === "A" || this.isMobile()) && this.props.isStacked
        },
        this.handleResize
      );
    }

    if (
      JSON.stringify(this.props.imageUpdated) !== JSON.stringify(prevProps.imageUpdated) ||
      images.length !== prevImages.length ||
      images.some(img => prevImages.indexOf(img) === -1) ||
      this.overlayComponentHasChanged(prevProps.overlayComponent, overlayComponent)
    ) {
      this.preparePhotos();
    }

    if (
      this.props.galleryStyle !== prevProps.galleryStyle ||
      this.props.dropShadow !== prevProps.dropShadow ||
      this.props.layout !== prevProps.layout
    ) {
      this.setState({
        galleryStyle: this.props.galleryStyle,
        dropShadow: this.props.dropShadow
      });
      const photoTiles = this.getPhotoTiles(this.props.galleryStyle, this.props.dropShadow);
      this.setState({ photoTiles });
    }
  }

  public shouldComponentUpdate(nextProps: IMasonryBlockProps) {
    if (nextProps.selectedIndex !== this.props.selectedIndex) {
      this.validateImageHighlight(nextProps);
    }
    return true;
  }

  public componentWillUnmount() {
    clearTimeout(this.delayTimerId);
    const masonryRef = this.getRef();
    if (masonryRef.current) {
      masonryRef.current?.ownerDocument?.removeEventListener("lazyloaded", this.handleLazyLoaded);
    }
    window?.removeEventListener("resize", this.handleResize);
  }

  private getRef = () => {
    return this.props.contentsRef ? this.props.contentsRef : this.MasonryRef;
  };

  private prepareImgsUrlsMap = (): Map<string, IPhotoMasonry> => {
    let imgsUrlsMap = new Map<string, IPhotoMasonry>();
    this.props.images.forEach(x => {
      imgsUrlsMap.set(x.url, x);
    });

    return imgsUrlsMap;
  };

  private preparePhotos = () => {
    const imgsUrlsMap = this.prepareImgsUrlsMap();
    const photoTiles = this.getPhotoTiles(this.props.galleryStyle, this.props.dropShadow);
    const masonryColumns = this.getMasonryColumns(photoTiles, imgsUrlsMap);

    this.setState({ photoTiles: photoTiles, imgsUrlsMap, masonryColumns });
  };

  private getContentWidthSize(): number {
    const masonryRef = this.getRef();
    return masonryRef.current ? masonryRef.current.offsetWidth : window.innerWidth;
  }

  private isGridType = () => this.props.layout === "D" || this.isResizeToFit();
  private isMobile = () => window.innerWidth <= (!this.props.isEditionView ? 768 : 1157);
  private isMobileLayout = () =>
    this.props.mobileLayout && this.getMobileColumns() == this.state.columns && this.getContentWidthSize() < 400;
  private isResizeToFit = () => this.props.resizeToFit && !this.isMobileLayout();

  private handleLazyLoaded = (e: Event) => {
    handleLazyLoaded(e, styles.imgRevealTop, styles.imgRevealBottom, styles.triggerReveal, styles.containerLoaded);
    if (this.state.isFluidView) {
      this.checkGridFluidHeight();
    }
  };

  private addHighlight = (element: HTMLElement | null) => {
    element?.classList.add("outlineHighlight");
  };

  private removeHighlight = (element: HTMLElement | null) => {
    element?.classList.remove("outlineHighlight");
  };

  private validateSingleImage = (index: number, selectedIndex: number) => {
    const masonryRef = this.getRef();
    if (masonryRef && masonryRef.current) {
      const image = masonryRef.current.getElementsByTagName("img")[index];
      const attrIndex = image.getAttribute("data-index");
      if (image && attrIndex && parseInt(attrIndex, 10) === selectedIndex) {
        this.addHighlight(image.parentElement);
        this.scrollIntoImgView(image);
      }
    }
  };

  private validateImageHighlight = (nextProps: IMasonryBlockProps) => {
    const masonryRef = this.getRef();
    if (masonryRef && masonryRef.current && nextProps.selectedIndex !== undefined) {
      const listElements = masonryRef.current.getElementsByTagName("img");
      let index = 0;
      for (const element of Array.from(listElements)) {
        this.removeHighlight(element.parentElement);
        this.validateSingleImage(index, nextProps.selectedIndex);
        index = index + 1;
      }
    }
  };

  private scrollIntoImgView = (element: HTMLImageElement) => {
    element.scrollIntoView({
      block: "center",
      behavior: "smooth"
    });
  };

  private overlayComponentHasChanged = (
    prevValue?: Array<JSX.Element | null>,
    currentValue?: Array<JSX.Element | null>
  ) => {
    const prev = prevValue || [];
    const curr = currentValue || [];

    return prev.length !== curr.length || prev.some((el, idx) => curr[idx] !== el);
  };

  private getPhotoTiles = (galleryStyle: string, dropShadow: boolean) => {
    const {
      titleClassName,
      showFileSelector,
      overlayStyle,
      overlayComponent,
      siteTheme,
      backgroundColor,
      images,
      imageUpdated,
      rowHeight,
      selectedIndex,
      isEditionView,
      blockImageEvents,
      onGetTitle,
      getBadgeComponent,
      getHoverComponent,
      onPhotoClick,
      onHoverPhoto,
      isEditor,
      showNames,
      namesVisibility,
      hasLightbox,
      showLightbox
    } = this.props;
    const resizeToFit = this.isResizeToFit();
    const borderStyle = getPictureStyle(galleryStyle, siteTheme.backgroundColor.value, backgroundColor);
    const masonryRef = this.getRef();
    const picWidth = masonryRef.current ? (masonryRef.current.offsetWidth - 10) / this.state.columns : 0;
    const isFile = (photo: IPhotoMasonry) => photo?.file instanceof File;

    const getUrl = (photo: IPhotoMasonry) => {
      if (photo?.file && isFile(photo)) {
        return URL.createObjectURL(photo.file);
      }
      return photo.url;
    };

    const results = images.map((image: IPhotoMasonry, idx: number) => {
      const picHeight = picWidth > 0 && image.width && image.height ? (picWidth * image.height) / image.width : 0;
      const currentImage = imageUpdated && idx === imageUpdated?.index ? { ...imageUpdated.image, url: "" } : image;
      const imageAspectRatio = image.height && image.width ? (image.width / image.height).toFixed(3) : undefined;

      const isPortrait = !this.isGridType() && !this.state.gridFluidHeight;
      const rowHeightGrid = this.state.isFluidView ? this.state.gridFluidHeight : this.getChildWidth(this.props.layout);

      const newRowHeight = isPortrait ? rowHeight : rowHeightGrid;
      const isGif = Utilities.isGifImage(currentImage.file?.name ?? currentImage.fileName);
      const isPng = Utilities.isPngImage(currentImage.file?.name ?? currentImage.fileName);
      const isVideo = currentImage?.video?.isVideo;
      const { dominantColor } = currentImage;

      return (
        <ZenPhotoTile
          height={!this.state.gridFluidHeight ? picHeight : this.state.gridFluidHeight}
          key={`${currentImage.url}-${idx}`}
          index={idx}
          focalX={currentImage.focalX}
          focalY={currentImage.focalY}
          addOpacity={currentImage.isFallback}
          resizeToFit={resizeToFit}
          borderWidth={borderStyle.borderWidth}
          isEditionView={isEditionView}
          blockImageEvents={blockImageEvents}
          dropShadow={dropShadow}
          followContainerSize={this.isGridType() && this.state.isFluidView}
          fullWidth={this.state.fullWidthStack}
          rowHeight={newRowHeight}
          onTileMounted={() => {
            if (selectedIndex !== undefined && selectedIndex !== -1) {
              this.validateSingleImage(idx, selectedIndex);
            }
          }}
          src={getUrl(currentImage)}
          srcset={currentImage.srcset ? currentImage.srcset : undefined}
          aspectRatio={imageAspectRatio}
          alt={currentImage.altText ? currentImage.altText : ""}
          title={onGetTitle ? onGetTitle(currentImage) : undefined}
          titleClassName={titleClassName}
          onClick={
            onPhotoClick
              ? (e: React.MouseEvent<HTMLElement>) => (onPhotoClick ? onPhotoClick(e, idx) : null)
              : undefined
          }
          overlayComponent={overlayComponent ? overlayComponent[idx] : undefined}
          overlayStyle={overlayStyle}
          onHoverPhoto={onHoverPhoto}
          animationClasses={
            !isGif
              ? {
                  imgContainer: cx(styles.lazyLoadingContainer, { [styles.dominantColor]: !!dominantColor }),
                  imgContainerLoaded: styles.containerLoaded,
                  revealContainerTop: styles.imgRevealTop,
                  revealContainerBottom: styles.imgRevealBottom,
                  revealFlag: styles.triggerReveal
                }
              : undefined
          }
          getBadgeComponent={getBadgeComponent}
          getHoverComponent={getHoverComponent}
          duration={currentImage?.video?.durationMilliseconds}
          isEditor={isEditor}
          isGif={isGif}
          isPng={isPng}
          isVideo={isVideo}
          {...getNameProps(image, idx, images.length, showNames, namesVisibility)}
          hasLightbox={hasLightbox}
          showLightbox={showLightbox}
          dominantColor={currentImage.dominantColor}
        />
      );
    });

    if (showFileSelector) {
      return [...results, this.getFileSelector()];
    }
    return results;
  };

  private getColumns(width: number): number {
    if (!this.props.brakePoints) {
      return this.state.maxCols;
    }

    let nCols: number =
      this.props.brakePoints.reduceRight((p: number, c: number, i: number) => {
        return c < width ? p : i;
      }, this.props.brakePoints.length) + 1;

    if (nCols == 1 && this.props.mobileLayout) {
      nCols = this.getMobileColumns();
      return nCols;
    }

    return nCols > this.state.maxCols ? this.state.maxCols : nCols;
  }

  private getMobileColumns(): number {
    const { mobileLayout } = this.props;
    return mobileLayout && mobileLayout == GalleryPresentSettingList.TwoColumn ? 2 : 1;
  }

  private checkGridFluidHeight = () => {
    const masonryRef = this.getRef();
    if (masonryRef.current) {
      let noCols = 3;
      let adjustGap = 12;
      let adjustMargins = 0;

      switch (this.props.layout) {
        case "B":
          noCols = 4;
          break;
        case "C":
          noCols = 5;
          break;
      }

      let gridFluidHeight =
        window.innerWidth >= (!this.props.isEditionView ? 768 : 1157)
          ? (masonryRef.current.offsetWidth - adjustMargins - adjustGap * (noCols - 1)) / noCols
          : masonryRef.current.offsetWidth;

      if (this.state.gridFluidHeight !== gridFluidHeight) {
        this.setState({ gridFluidHeight: gridFluidHeight });
      }
    }
  };

  private getIsFluidView() {
    return this.props.isGridFluid || window.innerWidth < 768;
  }

  private handleResize() {
    const masonryRef = this.getRef();
    if (masonryRef.current) {
      const columns = this.getColumns(masonryRef.current.offsetWidth);
      if (columns !== this.state.columns) {
        // Sending the calculated columns value that is not yet in state
        const masonryColumns = this.getMasonryColumns(this.state.photoTiles, undefined, columns);
        this.setState({ columns, masonryColumns });
      }
    }

    this.setState({ isFluidView: this.getIsFluidView() }, () => {
      if (this.state.isFluidView) {
        this.checkGridFluidHeight();

        const isPortrait = !this.isGridType() && !this.state.gridFluidHeight;

        if (!isPortrait) {
          const photoTiles = this.getPhotoTiles(this.props.galleryStyle, this.props.dropShadow);
          const masonryColumns = this.getMasonryColumns(photoTiles);
          this.setState({ photoTiles: photoTiles, masonryColumns });
        }
      }
    });
  }

  private getChildWidth = (layout: string): number | undefined => {
    let result;
    if (layout === "A") {
      result = 370;
    } else if (layout === "B") {
      result = 270;
    } else if (layout === "C") {
      result = 211;
    }
    return result;
  };

  private getChildStyle = (i: number) => {
    let result = "";
    if (!this.isResizeToFit()) {
      result = this.props.rowHeight ? `${this.props.rowHeight}px` : "";
    } else {
      result = `${
        this.state.isFluidView && this.state.gridFluidHeight !== 0
          ? this.state.gridFluidHeight
          : this.getChildWidth(this.props.layout)
      }px`;
    }

    return {
      width: result,
      height: result
    } as React.CSSProperties;
  };

  private renderGrid(photoTiles: JSX.Element[]): JSX.Element[] | [] {
    const gridComponents = photoTiles.map((child, i) => (
      <div
        key={i}
        className={cx(styles.layoutDChild, { [styles.marginGrid]: this.state.isFluidView })}
        style={this.getChildStyle(i)}
        onContextMenu={ContextMenu.handleBlockContextMenu}
      >
        {child}
      </div>
    ));

    return gridComponents;
  }

  private getMasonryColumns(photoTiles: JSX.Element[], urls?: Map<string, IPhotoMasonry>, cols?: number) {
    const columns = cols ? cols : this.state.columns;
    const masonryColumns: JSX.Element[][] = [];
    const heightsArray: number[] = [];

    for (let i = 0; i < columns; i++) {
      masonryColumns.push([]);
      heightsArray[i] = 0;
    }

    const masonryRef = this.getRef();
    const picWidth = masonryRef.current ? (masonryRef.current.offsetWidth - 10) / this.state.columns : 0;
    const urlsMap = urls ? urls : this.state.imgsUrlsMap;

    photoTiles.forEach((photoTile: JSX.Element, idx: number) => {
      if (this.props.layout !== "D") {
        let minHeightIndex = 0;
        minHeightIndex = heightsArray.indexOf(Math.min(...heightsArray));

        // puts the photoTile in the column with less total height
        masonryColumns[minHeightIndex].push(photoTile);

        let img = urlsMap.get(photoTile.props.src);
        let height = img?.height ? img?.height : 1;
        let width = img?.width ? img?.width : 1;
        let aspectRatio = height / width;
        let newHeight = picWidth * aspectRatio;
        heightsArray[minHeightIndex] += newHeight;
      } else {
        masonryColumns[idx % columns].push(photoTile);
      }
    });
    return masonryColumns;
  }

  private getGridClass = () => {
    let result = "";

    if (!this.state.isFluidView) {
      result = cx({
        [styles.layoutAcontainer]: this.props.layout === "A",
        [styles.layoutBcontainer]: this.props.layout === "B",
        [styles.layoutCcontainer]: this.props.layout === "C"
      });
    } else {
      result = cx(
        {
          [styles.layoutAfluidcontainer]: this.props.layout === "A",
          [styles.layoutBfluidcontainer]: this.props.layout === "B",
          [styles.layoutCfluidcontainer]: this.props.layout === "C"
        },
        styles.layoutFluidcontainer
      );
    }
    return result;
  };

  public render() {
    const {
      className,
      siteTheme,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      readOnly,
      divider,
      alignment,
      fontFamily,
      padding,
      animationScrollIn,
      animationScrollOut,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      selectedBlock,
      isStacked,
      layout,
      noPadding
    } = this.props;
    const { fullWidthStack, masonryColumns: stateMasonryColumns } = this.state;
    // need photoTiles and masonryColumns for SSR
    const photoTiles = this.getPhotoTiles(this.props.galleryStyle, this.props.dropShadow);
    const imgsUrlsMap = this.prepareImgsUrlsMap();
    const SSRmasonryColumns = this.getMasonryColumns(photoTiles, imgsUrlsMap);
    const masonryColumns = stateMasonryColumns.length > 0 ? stateMasonryColumns : SSRmasonryColumns;

    const layoutClass = cx(
      styles.zenContainerMasonry,
      fullWidthStack ? styles.containerNoPadding : "",
      isStacked
        ? layout === "C"
          ? styles.layoutStackC
          : layout === "B"
          ? styles.layoutStackB
          : layout === "D"
          ? styles.layoutStackD
          : ""
        : "",
      !this.isGridType() && this.isMobileLayout() ? styles.zenMobileModeContainerMasonry : ""
    );

    const marginLeftStyle = this.isMobileLayout() ? "7px" : "12px";
    const marginBottomStyle = {
      marginBottom: this.isMobileLayout() ? "7px" : "12px"
    };

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        readOnly={readOnly}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        noPadding={noPadding || fullWidthStack}
        padding={padding}
        animationScrollIn={animationScrollIn}
        paddingMobile={window.innerWidth <= (!this.props.isEditionView ? 768 : 1157)}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        selectedBlock={selectedBlock}
        fullWidth={fullWidthStack}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div className={cx(layoutClass, className)} ref={this.props.contentsRef || this.MasonryRef}>
            {!this.isGridType() ? (
              <>
                {masonryColumns.map((col, colIdx) => {
                  return (
                    <div
                      className={styles.column}
                      style={{
                        marginLeft: `${colIdx > 0 && colIdx < masonryColumns.length ? marginLeftStyle : "0px"}`
                      }}
                      unselectable="on"
                      key={colIdx}
                      onContextMenu={ContextMenu.handleBlockContextMenu}
                    >
                      {col.map((child, childIdx) => {
                        return (
                          <div style={marginBottomStyle} key={childIdx}>
                            {child}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </>
            ) : (
              <div
                style={
                  this.state.isFluidView && this.state.gridFluidHeight !== 0
                    ? { gridAutoRows: `${this.state.gridFluidHeight}px` }
                    : {}
                }
                className={this.getGridClass()}
              >
                {this.renderGrid(photoTiles)}
              </div>
            )}
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }

  private getFileSelector = () => {
    const picHeight = this.props.rowHeight!;
    const acceptImageType = this.props.acceptImageType || this.acceptImageType();

    return (
      <Dropzone
        className={styles.imageUpload}
        style={{ height: picHeight }}
        activeStyle={{ borderColor: "#ff5a00" }}
        accept={acceptImageType}
        onDrop={this.onDrop}
        maxSize={this.MEDIA_ENGINE_MAX_FILE_SIZE}
        multiple={true}
      >
        <label>
          <div className={styles.plusIcon} />
          <div className={styles.addMore}>Add More</div>
        </label>
      </Dropzone>
    );
  };

  private acceptImageType = (): string => {
    return ".jpg,.jpeg,.jpe,.png,.gif,.heif,.heic";
  };

  private onDrop = (files: File[]) => {
    if (!files) {
      return;
    }

    if (this.props.onFilesSelected) {
      this.props.onFilesSelected(files);
    }
  };

  private MEDIA_ENGINE_MAX_FILE_SIZE = 104857600;
}
