import _ from "lodash";
import * as React from "react";
import { RefForwardingComponent } from "react";
import { useResizeDetector } from "react-resize-detector";
import CoreVideo from "../../components/Video";
import {
  IPlaybackStatsRecord,
  IQualityOptions,
  IVideoPlayer,
  LeaveAction,
  VideoPlacement
} from "../../components/Video/Player";
import { ImagePlacement } from "../../components/Video/Thumbnail";
import { formatVideoUrl, IVideoBase, MESizeCodes, MESizeMethod } from "utilities/getImgUrl";
import Player, { IAnalyticParams } from "./Player";
import useAnalytics from "./Player/useAnalytics";
import Thumbnail, { Overlay } from "./Thumbnail";
import { convertThumbnailProps } from "./utils";

export interface INzVideoProps {
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  video: IVideoBase;
  /**
   * Specifies how image should be positioned inside thumbnail. *"cover"* by default.
   */
  imagePlacement?: ImagePlacement;
  /**
   * Specifies whether to show the play button in thumbnail. You can customize its size in
   * *thumbnailOverlay*. It also determines which overlay elements are shown by default.
   */
  playable?: boolean;
  /**
   * Thumbnail overlay configuration.
   */
  thumbnailOverlay?: Overlay;
  /**
   * Specifies whether you *prefer* the video to be preloaded while thumbnail is shown. Whether it will actually
   * be preloaded and how depends on many things, so this is just a preference. If *playable* is set
   * to *true*, then this is enabled by default.
   */
  preload?: boolean;
  /**
   * Specifies whether the video should automatically play. Note that autoplay might fail sometimes,
   * and in that case thumbnail will be shown, so you might want to configure thumbnail in all cases.
   */
  autoplay?: boolean;
  /**
   * Forced size code for thumbnail. If not specified, then the size code is automatically (re-)calculated
   * based on component's size.
   */
  forcedSizeCode?: MESizeCodes;
  /**
   * Forces size method for thumbnail. If not specified, then it's derived from *imagePlacement*.
   */
  forcedSizeMethod?: MESizeMethod;
  /**
   * Default volume in the range [0, 1]. Default is 0.5. Session-wide volume takes precedence.
   */
  defaultVolume?: number;
  /**
   * Specifies whether the video should be muted by default.
   */
  defaultMuted?: boolean;
  /**
   * Specifies whether the video playback should be looped.
   */
  loop?: boolean;
  /**
   * Specifies the action that is executed when the video player leaves the viewport. *"none"* by default.
   */
  playerLeaveAction?: LeaveAction;
  /**
   * Specifies whether to disable (hide) the Picture-in-picture feature. *true* by default.
   */
  disablePiP?: boolean;
  /**
   * Specifies whether the component should listen to keyboard events on document level, recognize relevant
   * key presses and intercept them. Use with caution because it might affect functionality of other elements
   * on the page, if they use the same key presses for something else. *true* by default.
   */
  globalKeyboardControls?: boolean;
  /**
   * Specifies whether the user can interact with the video player. If set to *false* then all player controls
   * are hidden, the player is not focusable and ignores all mouse and keyboard inputs. *true* by default.
   */
  playerInteractive?: boolean;
  /**
   * Specifies how video should be positioned inside container. *"contain"* by default.
   */
  videoPlacement?: VideoPlacement;
  /**
   * Specifies whether the video should be shown in "background" mode. Background video automatically plays,
   * it is muted, looped, not interactive, and covers its container. Basically this prop is just a shortcut for
   * a combination of several other props. You can still override those props.
   */
  backgroundMode?: boolean;
  /**
   * Specifies whether to show the loading indicator in the video player. *true* by default.
   */
  playerLoadingIndicator?: boolean;
  /**
   * Specifies parameters for playback analytics. If specified, then the component automatically sends playback
   * analytics based on these parameters, process environment variables and local storage data.
   */
  analyticsParams?: IAnalyticParams;
  /**
   * Specifies quality control settings.
   */
  quality?: IQualityOptions;
  className?: string;
  style?: React.CSSProperties;
  /**
   * Called when the thumbnail is clicked. Clicks on the play button will not execute this callback.
   */
  onThumbnailClick?: () => void;
  /**
   * Called every time the thumbnail image is loaded.
   */
  onThumbnailImageLoad?: () => void;
  /**
   * Called every time an error occurs when loading the thumbnail image.
   */
  onThumbnailImageError?: () => void;
  /**
   * Called when the video player leaves the viewport.
   */
  onPlayerLeave?: () => void;
  /**
   * Called when the video starts playing (for the first time), either as a result of clicking on the play button,
   * or because of autoplay. If the video has been *stopped* (not just paused, or ended), then this callback resets
   * and can be executed again under the same conditions.
   */
  onStart?: () => void;
  /**
   * Called when the video is *stopped* (not just paused, or ended). Video can be stopped imperatively
   * (using *stop* method) or via the *playerLeaveAction*.
   */
  onStop?: () => void;
  /**
   * Called when enough data has been loaded to start playing the video.
   */
  onVideoLoad?: () => void;
  /**
   * Called when an error occurs when loading the video.
   */
  onVideoError?: () => void;
  /**
   * Called when a playback stats record is generated.
   */
  onPlaybackStats?: (record: IPlaybackStatsRecord) => void;
}

function forwardRef(Component: RefForwardingComponent<IVideoPlayer, INzVideoProps>) {
  return React.forwardRef(Component);
}

/**
 * NextZen Video component. Adapts core *Video* component for NextZen needs. Integrates with NextZen data objects,
 * sets some defaults that match NextZen use cases and supports automatically (re-)calculating thumbnail image source
 * based on component's size.
 */
const NzVideo: ReturnType<typeof forwardRef> & {
  /**
   * NextZen Video Thumbnail component. Adapts core *Thumbnail* component for NextZen needs. Integrates with NextZen
   * data objects, sets some defaults that match NextZen use cases and supports automatically (re-)calculating
   * image source based on component's size.
   */
  Thumbnail: typeof Thumbnail;
  /**
   * NextZen Video Player component. Adapts core *Player* component for NextZen needs. Integrates with NextZen
   * data objects and sets some defaults that match NextZen use cases.
   */
  Player: typeof Player;
} = React.forwardRef((props: INzVideoProps, ref: React.Ref<IVideoPlayer>) => {
  const { videoUrlTemplate, video, imagePlacement, thumbnailOverlay, ...core } = props;

  core.defaultVolume = _.defaultTo(core.defaultVolume, 0.5);
  core.disablePiP = _.defaultTo(core.disablePiP, true);
  core.globalKeyboardControls = _.defaultTo(core.globalKeyboardControls, true);
  core.onPlaybackStats = useAnalytics(props);

  const { width: containerWidth, height: containerHeight, ref: containerRef } = useResizeDetector();

  const { imageSrc, processing, overlay } = convertThumbnailProps({
    ...props,
    thumbnail: video,
    overlay: thumbnailOverlay,
    containerWidth,
    containerHeight
  });

  const videoSrc = formatVideoUrl(videoUrlTemplate, video);

  return (
    <CoreVideo
      ref={ref}
      thumbnailImageSrc={imageSrc}
      video={{ src: videoSrc, type: "application/x-mpegURL" }}
      processing={processing}
      thumbnailImagePlacement={imagePlacement}
      thumbnailOverlay={overlay}
      containerRef={containerRef}
      {...core}
    />
  );
}) as any;

NzVideo.Thumbnail = Thumbnail;
NzVideo.Player = Player;

export default NzVideo;
