import countries from "./countries";
import regions from "./regions";
import states from "./states";

export interface Country {
  name: string;
  isoCode: string;
  currency: string;
  regionCode: number | null;
  isEuropeanUnion?: boolean;
}

export interface Region {
  name: string;
  code: number;
}

export interface State {
  name: string;
  isoCode: string;
  countryCode: string;
}

export abstract class CountryManager {
  public static getAllRegions(): Region[] {
    return regions;
  }

  public static getAllCountries(includeUS?: boolean): Country[] {
    return !!includeUS ? countries : countries.filter((c: Country) => c.isoCode !== "US");
  }

  public static getCountriesByRegion(regionCode: number): Country[] {
    return countries.filter((c: Country) => c.regionCode === regionCode);
  }

  public static getCountryByCode(isoCode: string): Country | undefined {
    return countries.find((c: Country) => c.isoCode === isoCode);
  }

  public static getStatesByCountryCode(countryIsoCode: string): State[] {
    return states.filter((s: State) => s.countryCode === countryIsoCode);
  }

  public static getEUCountries(): Country[] {
    return countries.filter((c: Country) => c.isEuropeanUnion);
  }
}
