import { IBookMeService } from "models/models";

export enum PaymentType {
  None,
  NoPayment,
  Partial,
  Full
}

export function getPaymentType(service: IBookMeService): PaymentType {
  if (service.useDeposit && service.deposit!.depositAmount === 0) {
    return PaymentType.NoPayment;
  }

  return service.useDeposit ? PaymentType.Partial : PaymentType.Full;
}

export function isZeroPrice(service: IBookMeService) {
  return service.price === 0;
}

export function isFreeService(service: IBookMeService) {
  return getPaymentType(service) === PaymentType.NoPayment || isZeroPrice(service);
}

export function isOfflinePaymentService(service: IBookMeService) {
  return service.useOfflinePayment;
}
