import cx from "classnames";
import debounce from "lodash/debounce";
import React, { useRef, useState, useEffect, useCallback } from "react";
import styles from "./index.module.scss";
import { isMobileOnly } from "react-device-detect";
import colors, { setOpacity } from "utilities/colors";

export interface IScrollableNavProps {
  className?: string;
  bgColor?: string;
}

enum Direction {
  Left,
  Right
}

const ChevronIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13">
      <path
        d="M1.408 0a1.43 1.43 0 0 0-.987.397c-.542.538-.542 1.405 0 1.942l4.214 4.159-4.229 4.159c-.542.538-.542 1.405 0 1.942a1.42 1.42 0 0 0 1.974 0l5.215-5.13c.264-.26.404-.602.404-.971s-.152-.712-.404-.971L2.395.397C2.129.146 1.776.004 1.408 0z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ScrollableNav: React.SFC<IScrollableNavProps> = props => {
  const { className, bgColor } = props;
  const scrollAmount = isMobileOnly ? 200 : 500;
  const [scrollableLeft, setScrollableLeft] = useState(false);
  const [scrollableRight, setScrollableRight] = useState(false);
  const intervalIdRef: { current: NodeJS.Timeout | null } = useRef(null);
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const isDarkBackground: boolean = bgColor === colors.inputDark || bgColor === colors.black;

  const classes = cx(
    className,
    styles.scrollableNav,
    scrollableLeft && styles.scrollableLeft,
    scrollableRight && styles.scrollableRight
  );

  const checkScrollable = () => {
    if (scrollDivRef.current) {
      const container = scrollDivRef.current;

      setScrollableRight(container.scrollWidth - 5 > container.clientWidth + container.scrollLeft);
      setScrollableLeft(container.scrollLeft > 1);
    }
  };

  const debouncedCheckScrollable = useCallback(debounce(checkScrollable, 50), []);

  const handleWheel = (e: any) => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollLeft += e.deltaY;
    }
  };

  const handleButtonClick = (direction: Direction) => {
    const container = scrollDivRef.current;

    if (container) {
      if (direction === Direction.Left) {
        container.scrollBy({
          top: 0,
          left: scrollAmount * -1,
          behavior: "smooth"
        });
      } else {
        container.scrollBy({
          top: 0,
          left: scrollAmount,
          behavior: "smooth"
        });
      }
    }

    if (intervalIdRef.current) clearInterval(intervalIdRef.current);
  };

  const handleMouseOver = (direction: Direction) => {
    const container = scrollDivRef.current;

    if (container) {
      intervalIdRef.current = setInterval(function() {
        if (direction === Direction.Left) {
          container.scrollLeft -= 1;
        } else {
          container.scrollLeft += 1;
        }
      }, 3);
    }
  };

  const handleMouseOut = () => {
    if (intervalIdRef.current) clearInterval(intervalIdRef.current);
  };

  useEffect(() => {
    const scrollContainer = scrollDivRef.current || null;
    checkScrollable();
    if (window) window.addEventListener("resize", debouncedCheckScrollable);
    if (scrollContainer) scrollContainer.addEventListener("scroll", debouncedCheckScrollable);

    return () => {
      if (window) window.removeEventListener("resize", debouncedCheckScrollable);
      if (scrollContainer) scrollContainer.removeEventListener("scroll", debouncedCheckScrollable);
    };
  }, [scrollDivRef.current]);

  return (
    <div className={classes}>
      {props.bgColor && (
        <style>
          {`
            :root {
              --block-bkg-color: ${props.bgColor};
              --block-bkg-transparent: ${setOpacity(props.bgColor, 0)};
            }
          `}
        </style>
      )}
      <div className={styles.scrollableNavWrapper}>
        <div ref={scrollDivRef} className={styles.scrollContainer} onWheel={e => handleWheel(e)}>
          {props.children}
        </div>
        <div className={cx(styles.scrollControls, { [styles.darkBackground]: isDarkBackground })}>
          <button
            className={styles.buttonLeft}
            onClick={() => {
              handleButtonClick(Direction.Left);
            }}
            onMouseOver={() => {
              handleMouseOver(Direction.Left);
            }}
            onMouseOut={handleMouseOut}
          >
            <ChevronIcon />
          </button>
          <button
            className={styles.buttonRight}
            onClick={() => {
              handleButtonClick(Direction.Right);
            }}
            onMouseOver={() => {
              handleMouseOver(Direction.Right);
            }}
            onMouseOut={handleMouseOut}
          >
            <ChevronIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
