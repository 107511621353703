import _ from "lodash";
import moment from "moment";
import { apiClient } from ".";
import {
  IBooking,
  IBookingBase,
  IBookingsQuery,
  IBookingStatus,
  IBookingUpdater,
  IRescheduleResult,
  IRescheduleSettings
} from "../store/bookings/model";
import { map } from "../utilities/mapper";
import { apiType } from "./apiType";
import { mapActivity } from "./billing";

export interface IApiBookings {
  getBooking: (bookingId: string) => Promise<IBookingBase>;
  load: (query: IBookingsQuery) => Promise<IBooking[]>;
  update: (bookingId: string, updater: IBookingUpdater) => Promise<void>;
  cancel: (bookingId: string, note?: string) => Promise<void>;
  approve: (bookingId: string, note?: string) => Promise<IBookingStatus>;
  decline: (bookingId: string, note: string) => Promise<void>;
  reschedule: (bookingId: string, settings: IRescheduleSettings, force?: boolean) => Promise<IRescheduleResult>;
}

const api: IApiBookings = {
  getBooking: async (bookingId: string) =>
    mapBookingBase(await apiClient.get<IBookingBase>(`${apiType.bookingWidget}photographer/bookings/${bookingId}`)),

  load: async (query: IBookingsQuery) =>
    mapBookings(
      (
        await apiClient.get<{ bookings: IBooking[] }>(`${apiType.bookingWidget}photographer/bookings`, {
          params: query
        })
      ).bookings
    ),

  update: (bookingId: string, updater: IBookingUpdater) =>
    apiClient.put<void>(`${apiType.bookingWidget}photographer/bookings`, {
      bookingId,
      bookingData: {
        name: updater.name,
        address: updater.address,
        locationDetails: updater.locationDetails,
        notes: updater.additionalInfo,
        acceptanceNote: updater.acceptanceNote,
        internalNote: updater.internalNote,
        canceledNote: updater.canceledNote
      },
      customerData: updater.customer
    }),

  cancel: (bookingId: string, note?: string) =>
    apiClient.delete(`${apiType.bookingWidget}photographer/bookings`, {
      data: { id: bookingId, canceledNote: note }
    }),

  approve: async (bookingId: string, note?: string) =>
    (
      await apiClient.post<{ status: IBookingStatus }>(`${apiType.bookingWidget}photographer/bookings/approve`, {
        bookingId,
        reason: note
      })
    ).status,

  decline: (bookingId: string, note: string) =>
    apiClient.post(`${apiType.bookingWidget}photographer/bookings/decline`, { bookingId, reason: note }),

  reschedule: async (bookingId: string, settings: IRescheduleSettings, force?: boolean) => {
    const { startDateTime, endDateTime, clientNote, internalNote, sendNotification } = settings;
    const response = await apiClient.put<any>(`${apiType.bookingWidget}photographer/bookings/reschedule`, {
      bookingId,
      bookingDate: startDateTime.format("YYYY-MM-DD"),
      offsetMinutes: startDateTime.hours() * 60 + startDateTime.minutes(),
      durationMinutes: endDateTime.diff(startDateTime, "minutes"),
      noteToClient: clientNote,
      internalNote,
      notifyCustomer: sendNotification,
      force
    });
    return {
      bookingStatus: response.bookingStatus,
      internalNote: response.internalNote,
      conflicts: {
        bookings: _.filter(response.conflicts, { eventType: "user" }).map(c => ({
          startDateTime: moment(c.startDateTime).utc(true),
          endDateTime: moment(c.endDateTime).utc(true),
          bookingId: c.eventId,
          bookingName: c.eventName,
          bookingStatus: c.bookingStatus
        })),
        calendar: _.filter(response.conflicts, c => c.eventType !== "user").map(c => ({
          startDateTime: moment(c.startDateTime).utc(true),
          endDateTime: moment(c.endDateTime).utc(true),
          eventName: c.eventType === "reservation" ? "Reservation" : c.eventName || "(No title)"
        }))
      }
    };
  }
};

export function mapBookingBase(booking: any): IBookingBase {
  return map(booking)
    .toDate("startDateTime")
    .value() as IBookingBase;
}

export function mapBooking(booking: any): IBooking {
  return map(mapBookingBase(booking))
    .map("latestBillingActivity", mapActivity)
    .removeNullProps()
    .value() as IBooking;
}

export function mapBookings(bookings: any[]): IBooking[] {
  return map(bookings)
    .mapEach(mapBooking)
    .value() as IBooking[];
}

export default api;
