import cx from "classnames";
import CalendarArrow from "components/Icons/CalendarArrow";
import React from "react";
import colors from "../../../utilities/colors";
import { Utilities } from "../../../utilities/utilities";
import { IDatePickerCalendarHeaderProps } from "../models";
import styles from "./defaultDatePickerCalendarHeader.module.scss";

const { shouldComponentUpdateDeep } = Utilities;

export interface IDefaultDatePickerCalendarHeaderProps extends IDatePickerCalendarHeaderProps {}

class DefaultDatePickerCalendarHeader extends React.Component<IDefaultDatePickerCalendarHeaderProps> {
  public shouldComponentUpdate(nextProps: IDefaultDatePickerCalendarHeaderProps): boolean {
    return shouldComponentUpdateDeep(this, nextProps);
  }

  public render() {
    const { mode, month, className, style, containerRef } = this.props;

    return (
      <div className={cx(styles.container, className)} style={style} ref={containerRef}>
        <button
          className={cx(styles.month, mode === "months" ? styles.open : styles.close)}
          onClick={this.onMonthClick}
        >
          {month.format("MMMM")}
        </button>
        <button className={cx(styles.year, mode === "years" ? styles.open : styles.close)} onClick={this.onYearClick}>
          {month.format("YYYY")}
        </button>
        <div className={cx(styles.navigation, { [styles.hidden]: mode !== "days" })}>
          <button className={styles.back} onClick={this.onNavigationBackClick}>
            <CalendarArrow direction="left" color={colors.darkGrey} />
          </button>
          <button className={styles.forward} onClick={this.onNavigationForwardClick}>
            <CalendarArrow direction="right" color={colors.darkGrey} />
          </button>
        </div>
      </div>
    );
  }

  private onMonthClick = () => this.props.onMonthClick?.();

  private onYearClick = () => this.props.onYearClick?.();

  private onNavigationBackClick = () => this.props.onNavigate?.("back");

  private onNavigationForwardClick = () => this.props.onNavigate?.("forward");
}

export default DefaultDatePickerCalendarHeader;
