import some from "lodash/some";
import orderBy from "lodash/orderBy";
import { IMediaBase, IMediaId, IPhoto, IVideo, PhotoOrVideo, ISortOption, sortOptions } from "../../models";

const validationError = "request_validation_error";
const supportedDomains = ["zenfolio.com", "photobooker.com"];

export interface IError {
  type: string;
  title?: string;
  message?: string;
  [key: string]: any;
}

function getFullErrorType(domain: string, type: string): string {
  return `https://${domain}/api/error/${type}`;
}

export function formatErrorType(type: string): string {
  return getFullErrorType(supportedDomains[0], type);
}

export function getUserIdFromToken(decodedToken: Record<string, string>): string {
  return getPropertyFromToken(decodedToken, "user_id");
}

export function getPropertyFromToken(decodedToken: Record<string, string>, property: string): string {
  let result = decodedToken[property];
  if (result) {
    return result;
  }

  for (const domain of supportedDomains) {
    result = decodedToken[`https://${domain}/${property}`];
    if (result) {
      return result;
    }
  }

  return "";
}

export function isErrorType(type: string, error: string): boolean {
  for (const domain of supportedDomains) {
    if (type === getFullErrorType(domain, error)) {
      return true;
    }
  }

  return false;
}

export function isValidationError(error: IError): boolean {
  return isErrorType(error.type, validationError);
}

export function isPhoto(media: PhotoOrVideo): media is IPhoto {
  return !media.isVideo;
}

export function isVideo(media: PhotoOrVideo): media is IVideo {
  return !!media.isVideo;
}

export function toMediaId(media: IMediaId): IMediaId {
  return media.isVideo ? videoToMediaId(media.id) : photoToMediaId(media.id);
}

export function photoToMediaId(photoId: string): IMediaId {
  return { id: photoId };
}

export function videoToMediaId(videoId: string): IMediaId {
  return { id: videoId, isVideo: true };
}

export function sortMediaInPlace<TPhoto extends IMediaBase, TVideo extends IMediaBase>(container: {
  photos: TPhoto[];
  videos: TVideo[];
}) {
  container.photos.sort((a, b) => a.sortIndex - b.sortIndex);
  container.videos.sort((a, b) => a.sortIndex - b.sortIndex);
}

export function mergeMedia<TPhoto extends IMediaBase, TVideo extends IMediaBase>(
  photos: TPhoto[],
  videos: TVideo[]
): (TPhoto | TVideo)[] {
  const allMedia: (TPhoto | TVideo)[] = [];

  let photoIndex = 0;
  let videoIndex = 0;

  while (photoIndex < photos.length && videoIndex < videos.length) {
    allMedia.push(
      photos[photoIndex].sortIndex < videos[videoIndex].sortIndex ? photos[photoIndex++] : videos[videoIndex++]
    );
  }

  while (photoIndex < photos.length) {
    allMedia.push(photos[photoIndex++]);
  }

  while (videoIndex < videos.length) {
    allMedia.push(videos[videoIndex++]);
  }

  return allMedia;
}

export function mediaIdsEqual(first: IMediaId, second: IMediaId) {
  return first.id === second.id && !first.isVideo === !second.isVideo;
}

export function isShoppingAllowedForMedia(media: PhotoOrVideo) {
  // TODO: Remove when video starts supporting shopping.
  return !isVideo(media);
}

export function isShoppingAllowedForGallery(gallery: {
  isEcom: boolean;
  photos: IPhoto[] | null;
  videos: IVideo[] | null;
  medias?: PhotoOrVideo[] | null;
}) {
  return (
    gallery.isEcom &&
    (some(gallery.photos, (p: any) => isShoppingAllowedForMedia(p)) ||
      some(gallery.medias, (m: any) => isShoppingAllowedForMedia(m)) ||
      some(gallery.videos, (v: any) => isShoppingAllowedForMedia(v)))
  );
}

export function getSortOption(key: string): ISortOption {
  return sortOptions.find((option: any) => option.key === key)!;
}

export function orderItemsByKey(items: any[], sortKey: string | undefined): any[] {
  if (!!sortKey && !!items) {
    const sort = getSortOption(sortKey);
    const sortField =
      sort.field === "name" ? (item: any) => item.name?.toLowerCase() || item.fileName?.toLowerCase() : sort.field;
    items = orderBy(items, [sortField], [sort.direction]);
  }
  return items;
}
