// tslint:disable: max-classes-per-file
import context from "./context";
import {
  ConsumerFlow,
  IConsumerEvents,
  IConsumerPayment,
  IEvents,
  IPhotographerBookingsEvents,
  IPhotographerProfileEvents
} from "./model";

class EventsBase {
  protected trackEvent(data: any) {
    window.dataLayer.push({
      ...this.getEventBase(),
      ...data
    });
  }

  protected getEventBase(): any {
    return {
      event: null,
      eventCategory: null,
      eventAction: null,
      eventLabel: null,
      // flow: null,
      bookme_photographer_id: context.photographer.id || null,
      flagship_photographer_id: context.photographer.zenfolioId || null,
      photographer_plan: context.photographer.plan || null,
      add_on_plan: context.photographer.addOnPlan || null,
      booking_id: null,
      error_message: null,
      ecommerce: null
    };
  }
}

class PhotographerProfileEvents extends EventsBase implements IPhotographerProfileEvents {
  public shootLocationUpdated() {
    this.trackEvent({ eventLabel: "shoot location" });
  }

  public bookableHoursUpdated() {
    this.trackEvent({ eventLabel: "availability" });
  }

  public contactInfoUpdated() {
    this.trackEvent({ eventLabel: "contact information" });
  }

  public paymentStatementDescriptorUpdated() {
    this.trackEvent({ eventLabel: "collecting payments" });
  }

  protected getEventBase(): any {
    return {
      ...super.getEventBase(),
      event: "widget photographer portal",
      eventCategory: "widget photographer portal",
      eventAction: "profile"
    };
  }
}

class PhotographerBookingsEvents extends EventsBase implements IPhotographerBookingsEvents {
  public bookingApproved(bookingId: string) {
    this.trackEvent({ eventLabel: "approve", booking_id: bookingId });
  }

  public bookingDeclined(bookingId: string) {
    this.trackEvent({ eventLabel: "decline", booking_id: bookingId });
  }

  public invoiceMarkedAsPaid() {
    this.trackEvent({ eventLabel: "mark as paid", booking_id: context.photographer.bookingId });
  }

  protected getEventBase(): any {
    return {
      ...super.getEventBase(),
      event: "widget photographer portal",
      eventCategory: "widget photographer portal",
      eventAction: "my bookings"
    };
  }
}

class ConsumerEvents extends EventsBase implements IConsumerEvents {
  public widgetOpened() {
    this.trackEvent({ eventAction: "open widget" });
  }

  public selectServiceReached() {
    this.trackEvent({ eventAction: "select a service" });
  }

  public dateAndTimeReached() {
    this.trackEvent({ eventAction: "date & time" });
  }

  public locationReached() {
    this.trackEvent({ eventAction: "location" });
  }

  public detailsReached() {
    this.trackEvent({ eventAction: "details" });
  }

  public paymentReached() {
    this.trackEvent({ eventAction: "payment" });
  }

  public paymentSubmitted() {
    // don't track
  }

  public paymentFailed(errorMessage: string) {
    this.trackEvent({ eventAction: "payment", error_message: errorMessage });
  }

  public paymentCompleted(payment: IConsumerPayment) {
    this.trackEvent({
      eventAction: "transaction complete",
      booking_id: context.consumer.bookingId,
      ecommerce: {
        currencyCode: "USD",
        purchase: {
          actionField: {
            id: context.consumer.bookingId,
            revenue: payment.totalAmount,
            tax: 0,
            option: this.getPaymentType(payment),
            coupon: "na",
            actual_amount_paid: payment.paidAmount
          },
          products: [
            {
              id: context.photographer.id,
              name: context.photographer.businessName,
              category: context.consumer.service!.name,
              variant: `hours=${context.consumer.service!.duration / 60}`,
              quantity: 1,
              price: payment.totalAmount
            }
          ]
        }
      }
    });
  }

  protected getPaymentType(payment: IConsumerPayment): string {
    if (context.consumer.flow === "invoice") {
      return "invoice";
    }

    const mainType =
      payment.paidAmount !== 0
        ? payment.totalAmount === payment.paidAmount
          ? "full payment"
          : "deposit"
        : payment.totalAmount === 0
        ? "free"
        : "pay later";
    const option = payment.approvalRequired ? ", reservation" : "";
    return mainType + option;
  }

  protected trackEvent(data: any) {
    if (context.consumer.flow === "booking") {
      super.trackEvent(data);
    }
  }

  protected getEventBase(): any {
    return {
      ...super.getEventBase(),
      event: "widget booking flow",
      eventCategory: "widget booking flow",
      eventLabel: context.consumer.service?.name || null
      // flow: context.consumer.flow ? ConsumerEvents.flowMap[context.consumer.flow] : null
    };
  }

  private static readonly flowMap: Record<ConsumerFlow, string> = {
    booking: "widget booking flow",
    invoice: "invoice flow"
  };
}

const gtmEvents: IEvents = {
  photographer: {
    profile: new PhotographerProfileEvents(),
    bookings: new PhotographerBookingsEvents()
  },
  consumer: new ConsumerEvents()
};

export default gtmEvents;
