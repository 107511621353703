import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import ConfirmationStep from "../../../../../components/widget/steps/confirmation/invoice";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ widget }: IAppState) {
  return {
    invoice: widget.loadInvoiceInfo!
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationStep);
