import colors from "utilities/colors";
import React from "react";

const CalendarArrow = (props: {
  direction: "left" | "right";
  color?: string;
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={13}
    viewBox="0 0 8 13"
    className={props.className}
    style={props.style}
    onClick={props.onClick}
  >
    <path
      fill={props.color || colors.black}
      fillRule="evenodd"
      d={
        props.direction === "left"
          ? "M6.497 0c.356 0 .7.137.973.398a1.382 1.382 0 0 1 0 1.946L3.317 6.51l4.167 4.167a1.382 1.382 0 0 1 0 1.946 1.382 1.382 0 0 1-1.946 0l-5.14-5.14A1.357 1.357 0 0 1 0 6.511c0-.37.15-.713.398-.973L5.524.398c.274-.26.63-.398.973-.398z"
          : "M1.388 0c-.357 0-.7.137-.973.398a1.382 1.382 0 0 0 0 1.946L4.568 6.51.4 10.678a1.382 1.382 0 0 0 0 1.946 1.382 1.382 0 0 0 1.946 0l5.14-5.14c.26-.26.398-.603.398-.973s-.15-.713-.398-.973L2.361.398A1.429 1.429 0 0 0 1.388 0z"
      }
    />
  </svg>
);
export default CalendarArrow;
