import { push } from "connected-react-router";
import { History, LocationState } from "history";
import { Dispatch } from "redux";
import UrlConstants from "../components/router/constants";
import { addNotification, NotificationType } from "../store/notification/actions";
import { IProfileState } from "../store/profile/state/model";

export function redirectToNextStep<S = LocationState>(history: History<S>, state: IProfileState) {
  const url: string | null = getRedirectNextStepUrl(state);
  if (url) {
    history.push(url);
  }
}

export function redirectToNextStepOrShowSuccefulNotification(
  dispatch: Dispatch,
  profileState: IProfileState,
  successMessage?: string
): boolean {
  const nextStepUrl = getRedirectNextStepUrl(profileState);
  if (nextStepUrl) {
    dispatch(push(nextStepUrl));
  } else if (successMessage) {
    dispatch(addNotification(successMessage, NotificationType.Success));
  }
  return nextStepUrl != null;
}

export function getRedirectNextStepUrl(profileState: IProfileState) {
  let url: string | null = null;

  if (!profileState.profile.shootLocation) {
    url = UrlConstants.profileShootLocation;
  } else if (!profileState.profile.availability) {
    url = UrlConstants.profileAvailability;
  } else if (!profileState.profile.contacts) {
    url = UrlConstants.profileContactInformation;
  } else if (!profileState.profile.payments) {
    url = UrlConstants.profileCollectingPayments;
  } else if (!profileState.services.createdAndEnabled) {
    url = UrlConstants.services;
  }

  return url;
}
