import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import ServicesStep from "../../../../components/widget/steps/services";
import { IAppState } from "../../../../store/state";

function mapStateToProps({ widget }: IAppState) {
  return {
    services: widget.loadWidgetInfo.services
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesStep);
