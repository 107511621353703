import React from "react";
import IIConProps from "components/Icons/IIconProps";
import { getRadius } from "components/Icons/utils";

const CartMedia = ({ minX = 0, minY = 0, ...props }: IIConProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox={`${minX} ${minY} ${props.size} ${props.size}`}
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx={getRadius(props.size)}
        cy={getRadius(props.size)}
        r={getRadius(props.size)}
        fill="#000"
        opacity=".8"
      />
      <g transform="translate(5 6)">
        <path
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M0.471 2.48L3.902 2.48 6.843 14.735 16.647 14.735"
        />
        <circle cx="7.5" cy="18.5" r="1.5" fill="#FFF" />
        <circle cx="15.5" cy="18.52" r="1.5" fill="#FFF" />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6.353 11.931L16.825 11.931 19 5 5 5"
        />
      </g>
    </g>
  </svg>
);

export default CartMedia;
