import classNames from "classnames";
import React from "react";
import { Utilities } from "utilities/utilities";
import TextInput from "components/TextInput";
import styles from "./priceInput.module.scss";

interface IPriceInputProps {
  disabled?: boolean;
  placeholder?: string;
  value?: number | null;
  icon?: string;
  height?: number;
  iconHeight?: number;
  iconPaddingTop?: number;
  textPaddingLeft?: number;
  textPaddingTop?: number;
  textPaddingBottom?: number;
  styles?: any;
  readonly?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  focus?: () => void;
  errorMessage?: string | null | undefined;
  className?: string;
  errorClassName?: string;
  inputClassName?: string;
  tabIndex?: number;
  onChange?: (value: number | null) => void;
  defaultValue?: number | null;
  forceShowingError?: boolean;
  currencySymbol?: string;
  locale?: string;
  max?: number;
  currencyBlock?: boolean;
  currencyBlockClassName?: string;
}

interface IPriceInputState {
  value?: number | null;
  displayError: boolean;
  focus: boolean;
}

interface IValidationThresholds {
  min?: number;
  max?: number;
  step?: number;
}

interface IValidationSettings extends IValidationThresholds {
  locale?: string;
  currencySymbol?: string;
  overMaxErrorMessage?: string | null | undefined;
  underMinErrorMessage?: string | null | undefined;
  emptyErrorMessage?: string | null | undefined;
  roundToStepErrorMessage?: string | null | undefined;
}

class PriceInput extends React.Component<IPriceInputProps, IPriceInputState> {
  public state: IPriceInputState = { displayError: false, focus: false };

  private backspacePressed: boolean = false;
  private cursorPosition: number | null = null;
  private inputRef = React.createRef<HTMLInputElement>();

  public componentDidUpdate() {
    if (this.cursorPosition != null) {
      if (this.inputRef.current != null) {
        this.inputRef.current.setSelectionRange(this.cursorPosition, this.cursorPosition);
      }

      this.cursorPosition = null;
    }
  }

  public static validate(value: number | null, settings: IValidationSettings) {
    if (value == null) {
      return settings.emptyErrorMessage;
    }

    if (settings.min != null && settings.underMinErrorMessage && value < settings.min) {
      return PriceInput.formatError(settings.underMinErrorMessage, "min", settings);
    }

    if (settings.max != null && settings.overMaxErrorMessage && value > settings.max) {
      return PriceInput.formatError(settings.overMaxErrorMessage, "max", settings);
    }

    if (
      settings.step != null &&
      settings.step !== PriceInput.defaultStep &&
      settings.roundToStepErrorMessage &&
      value % settings.step !== 0
    ) {
      return PriceInput.formatError(settings.roundToStepErrorMessage, "step", settings);
    }

    return null;
  }

  public focus = () => {
    if (this.inputRef.current != null) {
      this.inputRef.current.focus();
    }
  };

  public render() {
    const props = this.props;
    const errorMessage = this.errorMessage;
    const inputClassName = classNames(styles.input, props.inputClassName, { [styles.error]: errorMessage });

    return (
      <div className={classNames(styles.container, props.currencyBlock && styles.withCurrencyBlock, props.className)}>
        <TextInput
          disabled={props.disabled}
          onChange={this.onChange}
          placeholder={props.placeholder}
          type="text"
          value={this.textValue}
          icon={props.icon}
          height={props.height}
          iconHeight={props.iconHeight}
          iconPaddingTop={props.iconPaddingTop}
          textPaddingLeft={props.textPaddingLeft}
          textPaddingTop={props.textPaddingTop}
          textPaddingBottom={props.textPaddingBottom}
          styles={props.styles}
          readonly={props.readonly}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onKeyDown={this.onKeyDown}
          ref={this.inputRef}
          errorMessage={errorMessage}
          className={inputClassName}
          errorClassName={classNames(styles.errorMessage, props.errorClassName)}
          tabIndex={props.tabIndex}
          maxLength={this.maxLength}
        />
        {props.currencyBlock && (
          <div className={classNames(styles.currencyBlock, props.currencyBlockClassName)}>
            {PriceInput.getCurrencySymbol(props.currencySymbol)}
          </div>
        )}
      </div>
    );
  }

  private get numberValue() {
    return this.props.value !== undefined
      ? this.props.value
      : this.state.value !== undefined
      ? this.state.value
      : this.props.defaultValue !== undefined
      ? this.props.defaultValue
      : null;
  }

  private get currencySymbolText() {
    return this.props.currencyBlock ? "" : PriceInput.getCurrencySymbol(this.props.currencySymbol);
  }

  private get textValue() {
    return `${this.currencySymbolText}${PriceInput.formatValue(this.numberValue, this.props.locale)}`;
  }

  private get errorMessage() {
    if (!this.props.forceShowingError && !this.state.displayError) {
      return null;
    }

    return this.props.errorMessage;
  }

  private get maxLength() {
    return `${this.currencySymbolText}${PriceInput.formatValue(
      this.props.max != null ? this.props.max : PriceInput.defaultSafeMax,
      this.props.locale
    )}`.length;
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const previousNumberValue = this.numberValue;
    const newNumberValue = this.processNewNumberValue(event, previousNumberValue);

    const onChange = (value: number | null) => {
      if (previousNumberValue === value) {
        return false;
      }

      if (this.props.onChange) {
        this.props.onChange(value);
      }

      return true;
    };

    if (this.props.value === undefined) {
      this.setState({ value: newNumberValue }, () => {
        onChange(this.numberValue);
      });
    } else if (!onChange(newNumberValue)) {
      this.forceUpdate();
    }
  };

  private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ displayError: true, focus: false }, () => {
      if (this.props.onBlur) {
        this.props.onBlur(event);
      }
    });
  };

  private onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ focus: true }, () => {
      if (this.props.onFocus) {
        this.props.onFocus(event);
      }
    });
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    this.backspacePressed = event.key === "Backspace";

    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  };

  private parseValue(text: string) {
    text = text && text.replace(/\D/g, "");

    return text ? Number.parseInt(text, 10) : null;
  }

  private processNewNumberValue(event: React.ChangeEvent<HTMLInputElement>, previousNumberValue: number | null) {
    const { locale, currencyBlock } = this.props;
    const currencySymbolText = this.currencySymbolText;
    const previousTextValue = PriceInput.formatValue(previousNumberValue, locale);
    let userTextValue = event.target.value;
    let userCursorPosition = event.target.selectionStart || 0;
    let newNumberValue = this.parseValue(userTextValue);
    let newTextValue = PriceInput.formatValue(newNumberValue, locale);

    if (!currencyBlock) {
      const currencySymbolIndex = userTextValue.indexOf(currencySymbolText);

      if (currencySymbolIndex >= 0) {
        // ignore currency symbol in cursor position calculations (if it's still there)
        userTextValue = PriceInput.removeByIndex(userTextValue, currencySymbolIndex);

        if (currencySymbolIndex < userCursorPosition) {
          // move cursor position to the left if it was after the removed currency symbol
          userCursorPosition -= currencySymbolText.length;
        }
      }
    }

    if (previousTextValue === newTextValue && userTextValue.length < newTextValue.length) {
      // in the case when we removed thousands separator we need to remove digit from the
      // left side of it in case of Backspace pressed and otherwise from the right side
      userCursorPosition = this.backspacePressed ? userCursorPosition - 1 : userCursorPosition + 1;
      userTextValue = PriceInput.removeByIndex(newTextValue, userCursorPosition);
      newNumberValue = this.parseValue(userTextValue);
      newTextValue = PriceInput.formatValue(newNumberValue, locale);
    }

    this.cursorPosition = Math.max(userCursorPosition - (userTextValue.length - newTextValue.length), 0);

    if (
      this.cursorPosition > 0 &&
      newTextValue.length > previousTextValue.length &&
      /^\D+$/.test(newTextValue.charAt(this.cursorPosition - 1))
    ) {
      // if after adding new digits cursor became right after thousands
      // separator, then move it back to the left side to be before it
      this.cursorPosition--;
    }

    // finally move cursor position to the right by the length of currency symbol
    this.cursorPosition += currencySymbolText.length;

    return newNumberValue;
  }

  private static getCurrencySymbol(currencySymbol?: string) {
    return currencySymbol || PriceInput.defaultCurrencySymbol;
  }

  private static formatValue(value: number | null | undefined, locale?: string) {
    return value == null ? "" : Utilities.formatNumber(value, locale || PriceInput.defaultLocale);
  }

  private static formatError(template: string, argument: keyof IValidationThresholds, settings: IValidationSettings) {
    return template.replace(
      "{" + argument + "}",
      PriceInput.getCurrencySymbol(settings.currencySymbol) +
        PriceInput.formatValue(settings[argument], settings.locale)
    );
  }

  private static removeByIndex(str: string, index: number) {
    return str.substring(0, index) + str.substring(index + 1);
  }

  private static readonly defaultStep = 1;
  private static readonly defaultLocale = "en-US";
  private static readonly defaultCurrencySymbol = "$";
  private static readonly defaultSafeMax = 999999999999999;
}

export default PriceInput;
