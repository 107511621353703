import { Dispatch } from "redux";
import { IApiServices } from "../../../api";
import IError from "../../../utilities/error";
import { redirectToNextStepOrShowSuccefulNotification } from "../../../utilities/redirections";
import { hideApproveBookingModal } from "../../modal/actions";
import { updateIsReauthorizedRequired } from "../../session/actions";
import { IAppState } from "../../state";
import { IGoogleCalendar } from "../googleCalendar/model";
import { ActionType } from "./constants";
import { IAvailability, IBookableHours } from "./model";

interface ILoadStarted {
  type: ActionType.LOAD_STARTED;
}

interface ILoadSuccess {
  type: ActionType.LOAD_SUCCESS;
  availability: IAvailability;
}

interface ILoadError {
  type: ActionType.LOAD_ERROR;
  error: IError;
}

export type LoadAction = ILoadStarted | ILoadSuccess | ILoadError;

const loadStarted = (): ILoadStarted => ({
  type: ActionType.LOAD_STARTED
});

const loadSuccess = (availability: IAvailability): ILoadSuccess => ({
  type: ActionType.LOAD_SUCCESS,
  availability
});

const loadError = (error: IError): ILoadError => ({
  type: ActionType.LOAD_ERROR,
  error
});

export const load = () => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadStarted());
    try {
      const availability = await apiService.profile.availability.load();
      dispatch(loadSuccess(availability));
      dispatch(
        updateIsReauthorizedRequired(
          availability.googleCalendar ? availability.googleCalendar.isReauthorizedRequired : false
        )
      );
    } catch (error) {
      dispatch(loadError(error));
    }
  };
};

interface IUpdateBookableHoursStarted {
  type: ActionType.UPDATE_BOOKABLE_HOURS_STARTED;
  updater: IBookableHours;
}

interface IUpdateBookableHoursSuccess {
  type: ActionType.UPDATE_BOOKABLE_HOURS_SUCCESS;
  updater: IBookableHours;
}

interface IUpdateBookableHoursError {
  type: ActionType.UPDATE_BOOKABLE_HOURS_ERROR;
  updater: IBookableHours;
  error: IError;
}

interface IUpdateBookableHoursReset {
  type: ActionType.UPDATE_BOOKABLE_HOURS_RESET;
}

export type UpdateBookableHoursAction =
  | IUpdateBookableHoursStarted
  | IUpdateBookableHoursSuccess
  | IUpdateBookableHoursError
  | IUpdateBookableHoursReset;

const updateBookableHoursStarted = (updater: IBookableHours): IUpdateBookableHoursStarted => ({
  type: ActionType.UPDATE_BOOKABLE_HOURS_STARTED,
  updater
});

const updateBookableHoursSuccess = (updater: IBookableHours): IUpdateBookableHoursSuccess => ({
  type: ActionType.UPDATE_BOOKABLE_HOURS_SUCCESS,
  updater
});

const updateBookableHoursError = (updater: IBookableHours, error: IError): IUpdateBookableHoursError => ({
  type: ActionType.UPDATE_BOOKABLE_HOURS_ERROR,
  updater,
  error
});

export const updateBookableHoursReset = (): IUpdateBookableHoursReset => ({
  type: ActionType.UPDATE_BOOKABLE_HOURS_RESET
});

export const updateBookableHours = (updater: IBookableHours) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(updateBookableHoursStarted(updater));
    try {
      const response = await apiService.profile.availability.updateBookableHours(updater);
      dispatch(updateBookableHoursSuccess(updater));
      if (redirectToNextStepOrShowSuccefulNotification(dispatch, response)) {
        dispatch(hideApproveBookingModal());
      }
    } catch (error) {
      dispatch(updateBookableHoursError(updater, error));
    }
  };
};

interface IUpdateGoogleCalendarStarted {
  type: ActionType.UPDATE_GOOGLE_CALENDAR_STARTED;
  calendarId: string;
}

interface IUpdateGoogleCalendarSuccess {
  type: ActionType.UPDATE_GOOGLE_CALENDAR_SUCCESS;
  calendarId: string;
  calendarName: string;
}

interface IUpdateGoogleCalendarError {
  type: ActionType.UPDATE_GOOGLE_CALENDAR_ERROR;
  calendarId: string;
  error: IError;
}

export type UpdateGoogleCalendarAction =
  | IUpdateGoogleCalendarStarted
  | IUpdateGoogleCalendarSuccess
  | IUpdateGoogleCalendarError;

const updateGoogleCalendarStarted = (calendarId: string): IUpdateGoogleCalendarStarted => ({
  type: ActionType.UPDATE_GOOGLE_CALENDAR_STARTED,
  calendarId
});

const updateGoogleCalendarSuccess = (calendarId: string, calendarName: string): IUpdateGoogleCalendarSuccess => ({
  type: ActionType.UPDATE_GOOGLE_CALENDAR_SUCCESS,
  calendarId,
  calendarName
});

const updateGoogleCalendarError = (calendarId: string, error: IError): IUpdateGoogleCalendarError => ({
  type: ActionType.UPDATE_GOOGLE_CALENDAR_ERROR,
  calendarId,
  error
});

export const updateGoogleCalendar = (calendar: IGoogleCalendar) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(updateGoogleCalendarStarted(calendar.id));
    try {
      await apiService.profile.availability.updateGoogleCalendar(calendar.id);
      dispatch(updateGoogleCalendarSuccess(calendar.id, calendar.name));
    } catch (error) {
      dispatch(updateGoogleCalendarError(calendar.id, error));
    }
  };
};

interface IApplyGoogleCalendarDisconnect {
  type: ActionType.APPLY_GOOGLE_CALENDAR_DISCONNECT;
}

export type ApplyGoogleCalendarDisconnectAction = IApplyGoogleCalendarDisconnect;

export const applyGoogleCalendarDisconnect = (): IApplyGoogleCalendarDisconnect => ({
  type: ActionType.APPLY_GOOGLE_CALENDAR_DISCONNECT
});
