import cx from "classnames";
import playVideoIconUrl from "icons/btn-play-video.svg";
import spinnerIconUrl from "icons/icon-spinner.svg";
import defaultTo from "lodash/defaultTo";
import isEqual from "lodash/isEqual";
import * as React from "react";
import { useLayoutEffect, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import useCombinedRef from "../../../hooks/useCombinedRef";
import styles from "./thumbnail.module.scss";

export type Processing = boolean | "spinner" | "spinner-message";
export type ImagePlacement = "cover" | "contain" | { focalPoint: { x: number; y: number } };
export type Overlay = {
  /**
   * Duration in milliseconds.
   */
  duration?: number;
  /**
   * Specifies the size of the play button in pixels. 54 by default.
   */
  playButtonSize?: number;
};

export interface IThumbnailProps {
  imageSrc?: string;
  /**
   * Specifies whether to show processing indicator and optional message. *true* is the same
   * as *"spinner"*.
   */
  processing?: Processing;
  /**
   * Specifies how image should be positioned inside container. *"cover"* by default.
   */
  imagePlacement?: ImagePlacement;
  /**
   * Specifies whether to show the play button. You can customize its size in *overlay*.
   */
  playable?: boolean;
  /**
   * Overlay configuration.
   */
  overlay?: Overlay;
  className?: string;
  style?: React.CSSProperties;
  containerRef?: React.Ref<HTMLDivElement>;
  /**
   * Called when the thumbnail is clicked. If you also specify *onPlay*, then clicks on the play button will not
   * execute this callback.
   */
  onClick?: () => void;
  /**
   * Called when the play button is clicked. If specified, then clicks on the play button will not execute *onClick*.
   */
  onPlay?: () => void;
  /**
   * Called every time the image (*imageSrc*) is loaded.
   */
  onImageLoad?: () => void;
  /**
   * Called every time an error occurs when loading the image (*imageSrc*).
   */
  onImageError?: () => void;
}

function Thumbnail(props: IThumbnailProps) {
  const { imageSrc, processing, imagePlacement = "cover", playable, overlay, className, style } = props;

  const imageRef = useRef<HTMLImageElement>(null);
  const { internalRef: internalContainerRef, combinedRef: containerRef } = useCombinedRef(props.containerRef);
  const { width: containerWidth, height: containerHeight } = useResizeDetector({
    targetRef: internalContainerRef
  });
  const [bottomRightOverlayPosition, setBottomRightOverlayPosition] = useState<IBottomRightOverlayPosition>();
  const [imageSize, setImageSize] = useState<ISize>();
  useLayoutEffect(onComponentUpdate);

  const focalPoint = getFocalPoint(imagePlacement);
  const playButtonSize = defaultTo(overlay?.playButtonSize, 54);
  const showPlayButton = playable && !processing;
  const showDuration = overlay?.duration !== undefined;
  const bottomRightOverlayPositioned = imagePlacement !== "contain" || !!bottomRightOverlayPosition || !!processing;
  const bottomRightOverlayStyle = imagePlacement !== "contain" || processing ? undefined : bottomRightOverlayPosition;

  function onComponentUpdate() {
    updateOverlayPosition();
  }

  function onClick() {
    props.onClick?.();
  }

  function onPlayClick(event: React.MouseEvent) {
    if (props.onPlay) {
      props.onPlay();
      event.stopPropagation();
    }
  }

  function onImageLoad() {
    const image = imageRef.current!;
    setImageSize({ width: image!.naturalWidth, height: image!.naturalHeight });
    props.onImageLoad?.();
  }

  function onImageError() {
    setImageSize(undefined);
    props.onImageError?.();
  }

  function updateOverlayPosition() {
    const position =
      containerWidth && containerHeight && imageSize && imagePlacement === "contain"
        ? calculateBottomRightOverlayPosition({ width: containerWidth, height: containerHeight }, imageSize)
        : undefined;

    if (!isEqual(position, bottomRightOverlayPosition)) {
      setBottomRightOverlayPosition(position);
    }
  }

  return (
    <div
      className={cx(styles.container, { [styles.processing]: processing }, className)}
      style={style}
      ref={containerRef}
      onClick={onClick}
    >
      {!processing && imageSrc && (
        <img
          src={imageSrc}
          alt=""
          className={cx(styles.image, { [styles.ready]: imageSize })}
          style={{
            objectFit: imagePlacement === "contain" ? "contain" : "cover",
            objectPosition: focalPoint ? `${focalPoint.x}% ${focalPoint.y}%` : "center"
          }}
          ref={imageRef}
          onLoad={onImageLoad}
          onError={onImageError}
        />
      )}
      <div className={styles.overlay}>
        {processing && (
          <div className={styles.processing}>
            <img src={spinnerIconUrl} alt="Processing" className={styles.spinner} />
            {processing === "spinner-message" && <div className={styles.message}>Processing…</div>}
          </div>
        )}
        {showPlayButton && (
          <img
            src={playVideoIconUrl}
            alt="Play"
            className={cx(styles.play, { [styles.active]: props.onPlay })}
            style={{ width: playButtonSize, height: playButtonSize }}
            onClick={onPlayClick}
          />
        )}
        {showDuration && bottomRightOverlayPositioned && (
          <div className={styles.duration} style={bottomRightOverlayStyle}>
            {formatDuration(overlay!.duration!)}
          </div>
        )}
      </div>
    </div>
  );
}

interface IBottomRightOverlayPosition {
  bottom: number;
  right: number;
}

interface ISize {
  width: number;
  height: number;
}

function getFocalPoint(imagePlacement: ImagePlacement): { x: number; y: number } | undefined {
  return (imagePlacement as any).focalPoint;
}

export function formatDuration(duration: number): string {
  duration = Math.round(duration / 1000);
  const minutes = Math.floor(duration / 60);
  const seconds = duration - minutes * 60;
  return `${minutes}:${String(seconds).padStart(2, "0")}`;
}

export function calculateBottomRightOverlayPosition(container: ISize, image: ISize): IBottomRightOverlayPosition {
  const scale = Math.max(image.width / container.width, image.height / container.height);

  return {
    bottom: Math.round((container.height - image.height / scale) / 2),
    right: Math.round((container.width - image.width / scale) / 2)
  };
}

export default Thumbnail;
