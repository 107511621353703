import { Descendant, Editor, Element as SlateElement, Node, Path, Range, Transforms } from "slate";
import { ReactEditor } from "slate-react";

export type LinkElement = {
  type: "link";
  href: string;
  target: string;
  children: Descendant[];
};

export const isValidURL = (url: string) => {
  var res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const urlWithHttp = (url: string) => (!/^https?:\/\//i.test(url) ? `https://${url}` : url);

export const getCurrentLinkNodes = (editor: ReactEditor) => {
  const linkNodes = Editor.nodes(editor, {
    match: (n: Node) => {
      return n.type === "link";
    }
  });

  return Array.from(linkNodes).map(item => item[0]);
};

const isLinkActive = (editor: ReactEditor) => {
  const linkNodes = getCurrentLinkNodes(editor);
  return linkNodes.length > 0;
};

export const unwrapLink = (editor: any, opts = {}) => {
  if (isLinkActive(editor)) {
    ReactEditor.focus(editor);
    Transforms.unwrapNodes(editor, {
      ...opts,
      match: (n: Node) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link"
    });
  }
};

export const wrapLink = (editor: any, url: string, target: boolean = false) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: "link",
    href: url,
    target: target ? "_blank" : "_self",
    children: isCollapsed ? [{ text: url }] : []
  };

  ReactEditor.focus(editor);
  const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path);
  if (editor.isVoid(parentNode)) {
    Transforms.insertNodes(editor, link, {
      at: Path.next(parentPath),
      select: true
    });
  } else if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, {
      at: editor.selection,
      split: true
    });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const withLinks = (editor: any) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: any) => element.type === "link" || isInline(element);

  editor.insertText = (text: string) => {
    if (text && isValidURL(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: any) => {
    const text = data.getData("text/plain");

    if (text && isValidURL(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withLinks;
