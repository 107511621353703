import { useLayoutEffect } from "react";

export default function useWindowLoad(action: () => void): void {
  useLayoutEffect(onMount, []);

  function onMount() {
    function onWindowLoad() {
      action();
    }

    window.addEventListener("load", onWindowLoad);

    return function onUnmount() {
      window.removeEventListener("load", onWindowLoad);
    };
  }
}
