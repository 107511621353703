import React, { useState, useEffect } from "react";
import styles from "./menuMobile.module.scss";
import MenuItem from "./MenuItem";
import cx from "classnames";

import {
  FacebookLink,
  InstagramLink,
  LinkedinLink,
  TwitterLink,
  YoutubeLink
} from "../../components/Icons/SocialLinks";

import { getSiteFonts } from "utilities/blocks/site";
import { INavigationMenu, INavigationMenuItem, IZenSiteTheme } from "../../models/models";

export interface IMenuMobile {
  siteTheme: IZenSiteTheme;
  open: any;
  menu: INavigationMenu;
  backgroundColor: string;
  color: string;
  hoverState: string;
  socialMedia: boolean;
  isEditionView: boolean;
  facebook: string;
  twitter: string;
  clientPortalLink?: string;
  instagram: string;
  youtube: string;
  showAccount?: boolean;
  linkedIn: string;
  isTabletView?: boolean;
  logoSize?: string;
  noSiteRoutes?: boolean;
  siteSubdomain?: string;
  onItemClick: (e: any, id: string) => void;
  onClickAccount?: () => void;
  formatLink: (item: INavigationMenuItem) => string | undefined;
  isPreview?:boolean
}

const MenuMobile: React.FC<IMenuMobile> = props => {
  const [open, setOpen] = useState(null);
  const [, setMenu] = useState(props.menu ? props.menu : {});
  const [color, setColor] = useState(props.color ? props.color : "white");
  const [, setHoverState] = useState(props.hoverState ? props.hoverState : "underline");
  const preventAnchorLink = props.isEditionView || props.noSiteRoutes && !props.isPreview;

  const mapItems = (menu: INavigationMenu) => {
    const { formatLink } = props;
    const listItems = menu.items.map((item: INavigationMenuItem, index) => (
      <MenuItem
        key={index}
        id={item.id}
        link={formatLink(item) || ""}
        delay={`${index * 0.1}s`}
        open={open || false}
        fontSize={props.isTabletView ? 26 : 24}
        marginTop={props.isTabletView ? 30 : 25}
        color={color}
        initOpenItem={false}
        childs={item.childs ? item.childs : []}
        backgroundColor={props.backgroundColor}
        hoverState={props.hoverState}
        onItemClick={props.onItemClick}
        className={styles.mobileMenuItem}
        preventAnchorLink={preventAnchorLink}
        formatLink={formatLink}
      >
        {item.menuName.toUpperCase()}
      </MenuItem>
    ));
    return listItems;
  };

  const getOpenState = () => {
    return open !== null ? (open ? styles.show : styles.hide) : "";
  };

  const getSocialMediaSize = () => {
    const TABLET_ICON_SIZE = 30;
    const MOBILE_ICON_SIZE = 26;
    return props.isTabletView ? TABLET_ICON_SIZE : MOBILE_ICON_SIZE;
  };

  const getMarginTopList = (logoSize?: string) => {
    if (!logoSize) {
      return "80px";
    }

    switch (logoSize) {
      case "small":
        return "80px";
      case "medium":
        return "110px";
      case "large":
        return "140px";
      default:
        return "80px";
    }
  };

  useEffect(() => {
    setOpen(props.open);
    setMenu(props.menu);
    setColor(props.color);
    setHoverState(props.hoverState);
  }, [props.open, props.menu, props.color, props.hoverState]);
  const siteFonts = getSiteFonts(props.siteTheme.fontsStyle);
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor === "black" ? "rgba(0, 0, 0, 0.9)" : "white"
      }}
      className={cx(
        getOpenState(),
        !props.isEditionView ? styles.menuMobilePublish : styles.menuMobile,
        props.backgroundColor === "black" ? styles.blurEffect : "",
        styles[siteFonts.primary]
      )}
    >
      <div
        id="menuList"
        style={{ paddingTop: props.isEditionView ? "50px" : getMarginTopList(props.logoSize) }}
        className={styles.menuList}
      >
        {mapItems(props.menu)}
      </div>

      {props.socialMedia && (
        <div className={styles.socialMedia}>
          {props.facebook && (
            <a
              className={cx({ [styles.socialMediaMarginRightTablet]: props.isTabletView })}
              href={props.facebook}
              target="_blank"
            >
              <FacebookLink color={props.color} size={getSocialMediaSize()} />
            </a>
          )}

          {props.twitter && (
            <a
              className={cx({ [styles.socialMediaMarginRightTablet]: props.isTabletView })}
              href={props.twitter}
              target="_blank"
            >
              <TwitterLink color={props.color} size={getSocialMediaSize()} />
            </a>
          )}
          {props.instagram && (
            <a
              className={cx({ [styles.socialMediaMarginRightTablet]: props.isTabletView })}
              href={props.instagram}
              target="_blank"
            >
              <InstagramLink color={props.color} size={getSocialMediaSize()} />
            </a>
          )}
          {props.youtube && (
            <a
              className={cx({ [styles.socialMediaMarginRightTablet]: props.isTabletView })}
              href={props.youtube}
              target="_blank"
            >
              <YoutubeLink color={props.color} size={getSocialMediaSize()} />
            </a>
          )}
          {props.linkedIn && (
            <a
              className={cx({ [styles.socialMediaMarginRightTablet]: props.isTabletView })}
              href={props.linkedIn}
              target="_blank"
            >
              <LinkedinLink color={props.color} size={getSocialMediaSize()} />
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default MenuMobile;
