export const serviceUnavailableError = "service_unavailable_error";
export const identityInvalidRefreshTokenError = "identity_invalid_refresh_token_error";
export const bookingPaymentError = "booking_payment_error";
export const widgetIsNotActivatedError = "widget_is_not_activated_error";
export const photographerDoesNotExistError = "photographer_doesnt_exist_error";
export const inappropriateInvoiceStatusError = "inappropriate_invoice_status_error";
export const invoiceDoesNotExistError = "invoice_doesnt_exist_error";
export const bookingDoesNotBelongPhotographerError = "booking_doesnt_belong_photographer_error";
export const shootTypeNameAlreadyExistsError = "shoot_type_name_already_exists_error";

export const userNotAuthenticated = "user_not_authenticated";
export const createPaymentMethodError = "create_payment_method_error";
