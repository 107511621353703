import cx from "classnames";
import _ from "lodash";
import React from "react";
import { ISlotRange } from "..";
import { dayOfWeekName, formatTime } from "../utils";
import styles from "./collapsedSlot.module.scss";

function renderSlots(slot: ISlotRange) {
  return `${formatTime(slot.selectedStart.value)} - ${formatTime(slot.selectedEnd.value)}`;
}

const CollapsedSlot: React.FC<any> = (props: any) => (
  <div className={cx(styles.container, props.className)}>
    <div className={cx(styles.dayContainer, props.dayClassName)}>
      <p className={cx(styles.day, props.slots.length > 0 && styles.dayWithSlots)}>
        <span className={cx(styles.dayPrefix, props.dayPrefixClassName)}>Every</span> {dayOfWeekName(props.dayOfWeek)}
      </p>
      <ul>
        {_.map(props.slots, (slot, index) => (
          <li key={index} className={styles.date}>
            {renderSlots(slot)}
          </li>
        ))}
      </ul>
    </div>
    <button className={styles.addTimeSlot} onClick={props.onToggleEdit}>
      {props.slots.length > 0 ? "Edit Timeslot" : "+Add Timeslot"}
    </button>
  </div>
);

export default CollapsedSlot;
