import React from "react";
import Badge from "../Badge";
import styles from "./tabs.module.scss";

interface ITab {
  activeTab: string;
  label: string;
  onClick: (label: string) => void;
  title: string;
  badge?: string;
  icon?: string;
}

const Tab: React.FC<ITab> = ({ activeTab, label, title, onClick, badge, icon }) => {
  let className = styles.tabListItem;

  if (activeTab === label) {
    className += " " + styles.tabListActive;
  }

  return (
    // tslint:disable-next-line: jsx-no-lambda
    <li className={className} onClick={() => onClick(label)} key={label}>
      {title}
      {badge && <Badge value={badge} className={styles.badge} />}
      {icon && <img src={icon} alt="icon" className={styles.icon} />}
    </li>
  );
};

export default Tab;
