import { IStripeSettings } from "../../store/profile/stripe/model";
import { apiType } from "../apiType";
import { apiClient } from "../index";
import { ILoadProfileStateResponse } from "./state";

export interface ISetupStripeRequest {
  redirectUri: string;
}

export interface ISetupStripeResponse {
  antiForgery: string;
  paymentUri: string;
}

export interface IStripeSettingsResponse extends IStripeSettings {}

export interface IToggleApplePayRequest {
  showPaymentRequestButton: boolean;
}

export interface IUpdateStatementDescriptorRequest {
  statementDescriptor: string;
}

export interface IGetPaymentDashboardLinkResponse {
  dashboardLink: string;
}

export interface IApiPayments {
  setupStripe: (request: ISetupStripeRequest) => Promise<ISetupStripeResponse>;
  loadStripeSettings: () => Promise<IStripeSettingsResponse>;
  toggleApplePay: (request: IToggleApplePayRequest) => Promise<void>;
  updateStatementDescriptor: (request: IUpdateStatementDescriptorRequest) => Promise<ILoadProfileStateResponse>;
  disconnectStripe: () => Promise<void>;
  getPaymentDashboardLink: () => Promise<IGetPaymentDashboardLinkResponse>;
}

const api: IApiPayments = {
  setupStripe: (request: ISetupStripeRequest) =>
    apiClient.post<ISetupStripeResponse>(`${apiType.bookingWidget}photographer/profile/payments/connect`, request),

  loadStripeSettings: () =>
    apiClient.get<IStripeSettingsResponse>(`${apiType.bookingWidget}photographer/profile/payments`),

  toggleApplePay: (request: IToggleApplePayRequest) =>
    apiClient.put<void>(`${apiType.bookingWidget}photographer/profile/payments`, request),

  updateStatementDescriptor: (request: IUpdateStatementDescriptorRequest) =>
    apiClient.put<ILoadProfileStateResponse>(`${apiType.bookingWidget}photographer/profile/payments`, request),

  disconnectStripe: () => apiClient.post<void>(`${apiType.bookingWidget}photographer/profile/payments/disconnect`),

  getPaymentDashboardLink: () =>
    apiClient.get<IGetPaymentDashboardLinkResponse>(`${apiType.bookingWidget}photographer/profile/payments/dashboard`)
};

export default api;
