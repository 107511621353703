import React from "react";

function AlertIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#F8A645" d="M0 0h32v32H0z"/>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M15.388 18.617h1.017a.313.313 0 00.313-.308l.627-7.229a.313.313 0 00-.094-.219.307.307 0 00-.22-.088h-2.27a.31.31 0 00-.313.307l.627 7.23c0 .17.14.307.313.307zm.508 1.057c-.842 0-1.526.672-1.526 1.5s.684 1.5 1.526 1.5c.843 0 1.527-.672 1.527-1.5s-.684-1.5-1.527-1.5zm8.033 5.773H7.867a2.886 2.886 0 01-2.484-1.41 2.783 2.783 0 010-2.816l8.026-13.659a2.887 2.887 0 012.484-1.409c1.02 0 1.972.54 2.483 1.41l7.936 13.504c.313.461.48 1.006.48 1.563 0 1.541-1.276 2.805-2.864 2.817z"
        />
      </g>
    </svg>
  );
}

export default AlertIcon;
