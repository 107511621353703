import _ from "lodash";
import { ImagePlacement, Overlay as CoreOverlay, Processing } from "../../components/Video/Thumbnail";
import {
  formatThumbnailUrl,
  getImageSizeCode,
  isThumbnailReady,
  isVideoReady,
  IThumbnailBase,
  MESizeCodes,
  MESizeMethod
} from "utilities/getImgUrl";
import { Overlay } from "./Thumbnail";

export interface IConvertThumbnailPropsInput {
  thumbnailUrlTemplate: string;
  thumbnail: IThumbnailBase;
  overlay?: Overlay;
  imagePlacement?: ImagePlacement;
  forcedSizeCode?: MESizeCodes;
  forcedSizeMethod?: MESizeMethod;
  containerWidth?: number;
  containerHeight?: number;
}

export interface IConvertThumbnailPropsOutput {
  processing: Processing;
  imageSrc?: string;
  overlay: CoreOverlay;
}

/**
 * Converts NextZen Thumbnail props into core Thumbnail props.
 */
export function convertThumbnailProps(input: IConvertThumbnailPropsInput): IConvertThumbnailPropsOutput {
  const { thumbnail, overlay, forcedSizeCode, forcedSizeMethod, imagePlacement } = input;
  const { thumbnailUrlTemplate, containerWidth, containerHeight } = input;

  const processing: Processing = isThumbnailReady(thumbnail)
    ? false
    : isVideoReady(thumbnail)
    ? "spinner"
    : "spinner-message";

  const overlayDuration = (_.defaultTo(overlay?.duration, true) ? thumbnail.durationMilliseconds : null) ?? undefined;

  let imageSrc: string | undefined;
  if (!processing && containerWidth && containerHeight) {
    const sizeCode = forcedSizeCode ?? getImageSizeCode(containerWidth, containerHeight);
    const resizeMethod = forcedSizeMethod ?? (imagePlacement === "contain" ? MESizeMethod.Contain : MESizeMethod.Cover);
    imageSrc = formatThumbnailUrl(thumbnailUrlTemplate, thumbnail, sizeCode, resizeMethod);
  }

  return {
    processing,
    imageSrc,
    overlay: { duration: overlayDuration, playButtonSize: overlay?.playButtonSize }
  };
}
