import React from "react";
import styles from "./zenTextTitleSubtitle.module.scss";
import cx from "classnames";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import TextEditor from "../../TextEditor";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { getSiteFonts, getUUID } from "utilities/blocks/site";
import { normalizeHtml } from "../../TextEditor/utils";
import { INavigationMenuItem } from "../../../models/models";
import { parseBody } from "utilities/blocks/string";

export interface ITextTitleSubtitleBlockProps extends IZenBaseBlockProps {
  subtitlePosition?: string;
  titleText?: string;
  subtitleText?: string;
  blockText?: string;
  onTextChange?: (data: any) => void;
  showTitle: boolean;
  showSubtitle: boolean;
  pages?: INavigationMenuItem[];
  editor1?: string;
  editor2?: string;
  editor3?: string;
  onBlur?: (ev: any) => void;
}

interface ITextTitleSubtitleBlocktate {
  titleText: string;
  subtitleText: string;
  blockText: string;
}
class ZenTextTitleSubtitleBlock extends React.Component<ITextTitleSubtitleBlockProps, ITextTitleSubtitleBlocktate> {
  constructor(props: ITextTitleSubtitleBlockProps) {
    super(props);
    this.state = {
      titleText: parseBody(this.props.titleText || "") || "<p>Title</p>",
      subtitleText: parseBody(this.props.subtitleText || "") || "<p>Subtitle</p>",
      blockText:
        parseBody(this.props.blockText || "") ||
        "<p>I am a photographer ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure.quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequaquis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequa.</p>"
    };
  }

  public componentDidMount() {
    if (this.props.titleText) {
      this.setState({ titleText: parseBody(this.props.titleText) });
    }
    if (this.props.subtitleText) {
      this.setState({ subtitleText: parseBody(this.props.subtitleText) });
    }
    if (this.props.blockText) {
      this.setState({ blockText: parseBody(this.props.blockText) });
    }
  }

  private onTitleChange = (newTitle: string) => {
    if (this.state.titleText !== newTitle) {
      this.setState({ titleText: newTitle });
      if (this.props.onTextChange) {
        this.props.onTextChange({
          titleText: newTitle,
          subtitleText: this.state.subtitleText,
          blockText: this.state.blockText
        });
      }
    }
  };

  private onSubtitleChange = (newSubtitle: string) => {
    this.setState({ subtitleText: newSubtitle });
    if (this.props.onTextChange) {
      this.props.onTextChange({
        titleText: this.state.titleText,
        subtitleText: newSubtitle,
        blockText: this.state.blockText
      });
    }
  };

  private onTextblockChange = (newTextblock: string) => {
    if (normalizeHtml(this.state.blockText) !== newTextblock) {
      this.setState({ blockText: newTextblock });
      if (this.props.onTextChange) {
        this.props.onTextChange({
          titleText: this.state.titleText,
          subtitleText: this.state.subtitleText,
          blockText: newTextblock
        });
      }
    }
  };

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      layout,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      subtitlePosition,
      fontFamily,
      divider,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      padding,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      showTitle,
      showSubtitle
    } = this.props;

    const { titleText, subtitleText, blockText } = this.state;
    const blockTextColor = {
      color: getBlockTextColor(
        backgroundType === "none" ? siteTheme.backgroundColor.value : backgroundColor,
        siteTheme.accentColor.value,
        backgroundType,
        siteTheme.backgroundColor.value
      )
    };
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    let titlesClass = cx(styles.titles);
    const titleClass = cx(styles.title);
    const titleStyle = { color: siteTheme.accentColor.value };
    const subtitleClass = cx(styles.subtitle);
    const subtitleStyle = blockTextColor;
    const blockClass = cx(styles.block, styles[siteFonts.secondary]);
    const blockStyle = blockTextColor;

    titlesClass = cx(titlesClass, subtitlePosition === "above" ? styles.above : styles.below);
    const editor1 = this.props.editor1 || getUUID();
    const editor2 = this.props.editor2 || getUUID();
    const editor3 = this.props.editor3 || getUUID();
    const bgColorTextEditor = backgroundType === "none" ? siteTheme.backgroundColor.value : backgroundColor;

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        readOnly={readOnly}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        layout={layout}
        selectedBlock={selectedBlock}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div className={cx(styles.zenContainer)}>
            <div className={cx(titlesClass)}>
              {showTitle && (
                <div
                  className={cx(titleClass, styles[siteFonts.primary], styles[siteFonts.primaryExtra])}
                  style={titleStyle}
                >
                  <TextEditor
                    contents={titleText}
                    onChange={this.onTitleChange}
                    placeHolder={"Add a title"}
                    readOnly={readOnly}
                    isFloating
                    showLink={false}
                    editorId={editor1}
                    backgroundColor={bgColorTextEditor}
                    accentColor={siteTheme.accentColor.value}
                    onBlur={this.props.onBlur}
                  />
                </div>
              )}
              {showSubtitle && (
                <div className={cx(subtitleClass, styles[siteFonts.secondary])} style={subtitleStyle}>
                  <span>
                    <TextEditor
                      contents={subtitleText}
                      onChange={this.onSubtitleChange}
                      placeHolder={"Add a subtitle"}
                      readOnly={readOnly}
                      isFloating
                      showLink={false}
                      editorId={editor2}
                      backgroundColor={bgColorTextEditor}
                      accentColor={siteTheme.accentColor.value}
                      onBlur={this.props.onBlur}
                    />
                  </span>
                </div>
              )}
            </div>
            <div className={blockClass} style={blockStyle}>
              <div className={styles.textEditor}>
                <TextEditor
                  pages={this.props.pages}
                  contents={blockText}
                  onChange={this.onTextblockChange}
                  placeHolder={
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                  }
                  readOnly={readOnly}
                  isFloating
                  showLink
                  editorId={editor3}
                  backgroundColor={bgColorTextEditor}
                  accentColor={siteTheme.accentColor.value}
                  onBlur={this.props.onBlur}
                />
              </div>
            </div>
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}

export default ZenTextTitleSubtitleBlock;
