import { TimeSlotDay } from "@zenfolio/core-components";
import { ISlotRange } from "@zenfolio/core-components/dist/components/TimeSlotDay";
import _ from "lodash";
import React from "react";
import { IDay } from "..";
import { ITimeSlot } from "../../../../../store/profile/availability/model";
import { DayOfWeek, DaysOfWeek, formatTimeOfDay } from "../../../../../utilities/datetime";
import { convertSlotRangesToTimeSlots, convertTimeSlotsToSlotRanges } from "../utils";
import styles from "./index.module.scss";

interface IBookableHoursState {
  days: IDay[];
}

interface IBookableHoursProps {
  availableHours: ITimeSlot[];
  onChange: (availableHours: ITimeSlot[]) => void;
}

class BookableHours extends React.Component<IBookableHoursProps, IBookableHoursState> {
  public state: IBookableHoursState = BookableHours.defaultState;

  public render() {
    return (
      <div className={styles.container}>
        <div className={styles.body}>{this.renderTimeSlots()}</div>
      </div>
    );
  }

  private get slots(): ITimeSlot[] {
    const { availableHours } = this.props;
    return availableHours || BookableHours.defaultBookableHours.items;
  }

  private getTimeSlots = (dayNumber: DayOfWeek | number) =>
    this.state.days[dayNumber].timeSlots || this.convertTimeSlots(this.slots)[dayNumber];

  private renderTimeSlots = () => {
    return (
      <div className={styles.timeSlots}>
        {_.map(DaysOfWeek.fromMonday, dayOfWeek => (
          <TimeSlotDay
            key={dayOfWeek}
            step={5}
            minSlotRange={5}
            className={styles.timeSlot}
            collapsedSlotClassName={styles.timeSlot}
            collapsedDayClassName={styles.day}
            dayPrefixClassName={styles.dayPrefix}
            slots={this.getTimeSlots(dayOfWeek)}
            edit={this.state.days[dayOfWeek].editing}
            dayOfWeek={dayOfWeek}
            onChangeSlots={this.onDaySlotsChange}
            onAddSlot={this.onDaySlotAdd}
            onDeleteSlot={this.onDaySlotDelete}
            onToggleEdit={this.onDayEdit}
          />
        ))}
      </div>
    );
  };

  private onDaySlotsChange = (dayNumber: number, slots: ISlotRange[]) =>
    this.onDayUpdate(dayNumber, day => ({ ...day, timeSlots: slots }));

  private onDaySlotAdd = (dayNumber: number, slot: ISlotRange) =>
    this.onDayUpdate(dayNumber, day => ({
      ...day,
      timeSlots: this.getTimeSlots(dayNumber).concat(slot)
    }));

  private onDaySlotDelete = (dayNumber: number, index: number) =>
    this.onDayUpdate(dayNumber, day => ({
      ...day,
      timeSlots: _.filter(this.getTimeSlots(dayNumber as DayOfWeek), (d, idx) => idx !== index)
    }));

  private onDayEdit = (dayNumber: number, applyToAll: boolean) =>
    applyToAll
      ? this.setState(
          {
            days: DaysOfWeek.numericOrder.map(() => ({ timeSlots: this.getTimeSlots(dayNumber), editing: false }))
          },
          this.onChange
        )
      : this.onDayUpdate(dayNumber, day => ({
          editing: !day.editing,
          timeSlots:
            !day.editing && this.getTimeSlots(dayNumber).length === 0
              ? [BookableHours.defaultFirstTimeSlot]
              : day.timeSlots
        }));

  private onDayUpdate = (dayNumber: number, update: (day: IDay) => IDay) => {
    this.setState({ days: this.state.days.map((day, idx) => (idx === dayNumber ? update(day) : day)) }, this.onChange);
  };

  private onChange = () => {
    this.props.onChange(this.updateSlots);
  };

  public get updateSlots(): ITimeSlot[] {
    return this.convertSlotRanges(DaysOfWeek.numericOrder.map(this.getTimeSlots));
  }

  private convertTimeSlots = _.memoize(convertTimeSlotsToSlotRanges);
  private convertSlotRanges = convertSlotRangesToTimeSlots;

  private static readonly defaultFirstTimeSlot: ISlotRange = {
    selectedStart: { value: 480, label: formatTimeOfDay(480) },
    selectedEnd: { value: 1020, label: formatTimeOfDay(1020) }
  };

  private static readonly defaultState: IBookableHoursState = {
    days: DaysOfWeek.numericOrder.map(() => ({ editing: false }))
  };

  private static readonly defaultBookableHours = {
    items: []
  };
}

export default BookableHours;
