import cx from "classnames";
import { IElementBaseProps } from "components/NumericInputs/utils";
import _ from "lodash";
import React, { PureComponent } from "react";
import checked from "../../icons/white-checked.svg";
import Button from "../Button";
import CollapsedSlot from "./CollapsedSlot/index";
import TimeSlot from "./TimeSlot";
import styles from "./timeSlotDay.module.scss";
import { dayOfWeekName, formatTime } from "./utils";

export interface ISlot {
  value: number;
  label: string;
}

export interface ISlotRange {
  selectedStart: ISlot;
  selectedEnd: ISlot;
}

interface ITimeSlotDayProps {
  edit: boolean;
  slots: ISlotRange[];
  dayOfWeek: number;
  step?: number;
  minSlotRange?: number;
  initialSlotRange?: number;
  onChangeSlots: (dayOfWeek: number, slots: ISlotRange[]) => void;
  onAddSlot: (dayOfWeek: number, slot: ISlotRange) => void;
  onDeleteSlot: (dayOfWeek: number, index: number) => void;
  onToggleEdit: (dayOfWeek: number, applyToAll: boolean) => void;
  className?: string;
  collapsedSlotClassName?: string;
  collapsedDayClassName?: string;
  dayPrefixClassName?: string;
  timeInputButtonsContainer?: IElementBaseProps<HTMLDivElement>;
}

interface ITimeSlotDayState {
  applyToAll: boolean;
  viewportWidth: number;
}

class TimeSlotDay extends PureComponent<ITimeSlotDayProps, ITimeSlotDayState> {
  public state = {
    applyToAll: false,
    viewportWidth: window.innerWidth
  };

  public componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public updateWindowDimensions = () => {
    this.setState({
      viewportWidth: window.innerWidth
    });
  };

  private handleChangeCheckbox = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      applyToAll: value
    });
  };

  private onStartChange = (value: number, index: number) => {
    const { slots, onChangeSlots, dayOfWeek } = this.props;

    const newSlots = _.map(slots, (slot, i) => {
      if (i === index) {
        const endValue =
          slot.selectedEnd.value >= value + this.minSlotRange ? slot.selectedEnd.value : value + this.minSlotRange;
        return {
          selectedStart: {
            value,
            label: formatTime(value)
          },
          selectedEnd: {
            value: endValue,
            label: formatTime(endValue)
          }
        };
      }

      return slot;
    });

    onChangeSlots(dayOfWeek, newSlots);
  };

  private onEndChange = (value: number, index: number) => {
    const { slots, onChangeSlots, dayOfWeek } = this.props;

    const newSlots = _.map(slots, (slot, i) => {
      if (i === index) {
        return {
          ...slot,
          selectedEnd: {
            value,
            label: formatTime(value)
          }
        };
      }

      return slot;
    });

    onChangeSlots(dayOfWeek, newSlots);
  };

  private generateStartLimits = (prevSlot: ISlotRange | null, nextSlot: ISlotRange | null) => {
    let initialTime = 0;
    if (prevSlot) {
      initialTime = prevSlot.selectedEnd.value;
    }
    const end = nextSlot ? nextSlot.selectedStart.value - this.minSlotRange : 1440 - this.minSlotRange;

    return { min: initialTime, max: end };
  };

  private generateEndLimits = (start: ISlot, nextSlot: ISlotRange | null) => {
    const initialTime = start.value + this.minSlotRange;
    const end = nextSlot ? nextSlot.selectedStart.value : 1440;

    return { min: initialTime, max: end };
  };

  private handleAddSlot = (e: any) => {
    e.preventDefault();
    const { slots, onAddSlot, dayOfWeek } = this.props;

    if (!slots.length) {
      return onAddSlot(dayOfWeek, {
        selectedStart: {
          value: 480,
          label: formatTime(480)
        },
        selectedEnd: {
          value: 1020,
          label: formatTime(1020)
        }
      });
    }

    const lastSlot = slots[slots.length - 1];
    const lastApprovedTime = 1440 - this.minSlotRange;

    if (lastSlot.selectedEnd.value <= lastApprovedTime) {
      const newStartTime = lastSlot.selectedEnd.value;
      const newEndValue = Math.min(1440, lastSlot.selectedEnd.value + this.initialSlotRange);
      onAddSlot(dayOfWeek, {
        selectedStart: {
          value: newStartTime,
          label: formatTime(newStartTime)
        },
        selectedEnd: {
          value: newEndValue,
          label: formatTime(newEndValue)
        }
      });
    }
  };

  private handleDeleteSlot = (index: number) => {
    const { onDeleteSlot, dayOfWeek } = this.props;
    onDeleteSlot(dayOfWeek, index);
  };

  private handleEditToggle = () => {
    const { onToggleEdit, dayOfWeek } = this.props;
    onToggleEdit(dayOfWeek, false);
  };

  private handleSave = () => {
    const { onToggleEdit, dayOfWeek } = this.props;
    const { applyToAll } = this.state;

    onToggleEdit(dayOfWeek, applyToAll);
    this.setState({ applyToAll: false });
  };

  private get step() {
    return this.props.step === undefined ? TimeSlotDay.defaultStep : this.props.step;
  }

  private get minSlotRange() {
    return this.props.minSlotRange === undefined ? TimeSlotDay.defaultMinSlotRange : this.props.minSlotRange;
  }

  private get initialSlotRange() {
    return this.props.initialSlotRange === undefined
      ? TimeSlotDay.defaultInitialSlotRange
      : this.props.initialSlotRange;
  }

  public render() {
    const {
      slots,
      edit,
      dayOfWeek,
      className,
      collapsedSlotClassName,
      collapsedDayClassName,
      dayPrefixClassName,
      timeInputButtonsContainer
    } = this.props;
    const { viewportWidth } = this.state;
    const hasSlots = slots.length > 0;
    const disableAddSlot = hasSlots && slots[slots.length - 1].selectedEnd.value > 1440 - this.minSlotRange;

    if (!edit) {
      return (
        <CollapsedSlot
          className={collapsedSlotClassName}
          dayClassName={collapsedDayClassName}
          dayPrefixClassName={dayPrefixClassName}
          dayOfWeek={dayOfWeek}
          slots={slots}
          onToggleEdit={this.handleEditToggle}
        />
      );
    }

    return (
      <div className={cx(styles.container, className)}>
        <p className={cx(styles.day, hasSlots && styles.dayWithSlots)}>
          <span className={cx(styles.dayPrefix, dayPrefixClassName)}>Every</span> {dayOfWeekName(dayOfWeek)}
        </p>
        {_.map(slots, (slot, index) => {
          const prevSlot = Number(index) > 0 ? slots[Number(index) - 1] : null;
          const nextSlot = Number(index) + 1 < slots.length ? slots[Number(index) + 1] : null;

          const startLimits = this.generateStartLimits(prevSlot, nextSlot);
          const endLimits = this.generateEndLimits(slot.selectedStart, nextSlot);
          return (
            <TimeSlot
              key={index}
              index={index}
              start={{ ...startLimits, value: slot.selectedStart.value }}
              end={{ ...endLimits, value: slot.selectedEnd.value }}
              step={this.step}
              timeInputButtonsContainer={timeInputButtonsContainer}
              onStartChange={this.onStartChange}
              onEndChange={this.onEndChange}
              onDeleteSlot={this.handleDeleteSlot}
            />
          );
        })}

        <a
          className={cx(styles.addTimeSlot, {
            [styles.disabled]: disableAddSlot
          })}
          onClick={this.handleAddSlot}
        >
          +Add another Timeslot
        </a>

        <div className={styles.save}>
          <div className={styles.checkboxContainer}>
            <label className={styles.label}>
              <input
                name="applyToAll"
                type="checkbox"
                checked={this.state.applyToAll}
                onChange={this.handleChangeCheckbox}
              />
              <span>{this.state.applyToAll && <img src={checked} alt="apply to all" />}</span>
              Apply to All Days
            </label>
          </div>

          <Button
            onClick={this.handleSave}
            styles={{
              width: viewportWidth <= 767 ? "100%" : "170px",
              height: "50px"
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }

  public static readonly defaultStep = 30;
  public static readonly defaultMinSlotRange = 60;
  public static readonly defaultInitialSlotRange = 60;
}

export default TimeSlotDay;
