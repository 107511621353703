import React from "react";
import styles from "./zenFooterBlock.module.scss";
import cx from "classnames";
import { searchNode, getValidUrl } from "utilities/blocks/blockInteractions";
import {
  FacebookLink,
  TwitterLink,
  InstagramLink,
  LinkedinLink,
  YoutubeLink,
  FacebookCircleLink,
  TwitterCircleLink,
  InstagramCircleLink,
  YoutubeCircleLink,
  LinkedinCircleLink,
} from "../../../components/Icons/SocialLinks";
import {
  getSiteFonts,
  isClientSideRendering,
  isSinglePageSite,
  universalWindow as window
} from "utilities/blocks/site";
import UserAgreement from "./userAgreement";
import _ from "lodash";
import { INavigationMenu, INavigationMenuItem, IZenSiteTheme, IRoutes } from "../../../models/models";

const MOBILE_BREAK = 768;

export interface IFooterBlockProps {
  siteTheme: IZenSiteTheme;
  numberOfItems: number;
  showNavItems: boolean;
  custom: string;
  socialMedia: boolean;
  hoverState: string;
  padding?: string;
  studioName: string;
  studioLogo: string;
  studioAddress: string;
  studioPhone: string;
  footerColor: string;
  layout?: string;
  menuItems: INavigationMenu;
  isSelected: boolean;
  showCopyright: boolean;
  showPoweredBy: boolean;
  forceDesktop?: boolean;
  isEditionView?: boolean;
  siteRoutes?: IRoutes;
  onLayoutChange?: (layout: string) => void;
  templatePreview?: boolean;
  templateId?: string;
  facebook: string;
  instagram: string;
  twitter: string;
  youtube: string;
  linkedIn: string;
  isTrialUser?: boolean;
  siteSubdomain?: string;
  planName?: string;
  tierName?: string;
  handleShowCookieSettings?: () => void;
  isEnabledCookieSettings?: boolean;
}

interface IFooterBlockState {
  layout: string;
  showAgreement: boolean;
}

export class ZenFooterBlock extends React.Component<IFooterBlockProps, IFooterBlockState> {
  public static defaultProps = {
    numberOfItems: 10,
    socialMedia: false,
    showCopyright: true,
    showPoweredBy: true,
    hoverState: "lighten",
    studioName: "",
    studioLogo: "",
    studioAddress: "",
    studioPhone: "",
    custom: "",
    footerColor: "white",
    isSelected: false,
    layout: "A"
  };

  constructor(props: IFooterBlockProps) {
    super(props);
    this.state = {
      layout: this.props.layout || "A",
      showAgreement: false
    };
  }

  public componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public updateWindowDimensions = () => {
    if (window.innerWidth > 0 && window.innerWidth <= MOBILE_BREAK) {
      if (this.props.layout === "B") {
        this.setState({ layout: "A" });
      }
    }
  };

  private ghost = () => {
    const { facebook, instagram, twitter, youtube, linkedIn } = this.props;
    const isEmpty = !facebook && !instagram && !twitter && !youtube && !linkedIn;
    return isEmpty;
  };

  public getGhostClass = (prop: string) => {
    return !prop ? styles.ghost : styles.noGhost;
  };

  private getNavigateLink = (node: INavigationMenuItem): string | undefined => {
    const { templatePreview, templateId, siteRoutes, siteSubdomain } = this.props;
    const isExternal = node ? node.isExternal : false;
    if (isExternal && node) {
      return node.menuLink ? getValidUrl(node.menuLink) : undefined;
    } else if (siteSubdomain && siteRoutes) {
      const url = siteRoutes[node.id];
      const includeSubdomain =
        process.env.REACT_APP_PAGE_LINKS_INCLUDE_SUBDOMAIN &&
        process.env.REACT_APP_PAGE_LINKS_INCLUDE_SUBDOMAIN === "1";
      if (url) {
        return `${includeSubdomain ? `/${siteSubdomain}` : ""}/${url}`;
      }
    } else {
      if (node) {
        const currentLocation = window?.location?.href || "";
        const noghost =
          isClientSideRendering && !_.isEmpty(currentLocation)
            ? new URL(currentLocation).searchParams.has("noghost")
            : false;
        const searchParams = noghost ? "?noghost" : "";
        if (templatePreview) {
          return `/clientview/templates/${templateId}/pages/${node.id}${searchParams}`;
        } else {
          return `/clientview/pages/${node.id}${searchParams}`;
        }
      }
    }
    return "/";
  };

  private redirectToPage = (pageId: string) => {
    const { siteRoutes } = this.props;

    const parentNode: INavigationMenuItem = {
      id: "",
      menuCaption: "",
      menuName: "",
      childs: this.props.menuItems.items
    };

    const node = searchNode(parentNode, pageId, "", false);
    const isExternal = node ? node.isExternal : false;

    if (isExternal && node) {
      if (node.openInNewTab) {
        window.open(getValidUrl(node.menuLink), "_blank");
      } else {
        window.location.href = getValidUrl(node.menuLink);
      }
      return;
    }

    if (!siteRoutes && node) {
      if (this.props.templatePreview) {
        window.location.href = `/clientview/templates/${this.props.templateId}/pages/${node.id}`;
      } else {
        window.location.href = `/clientview/pages/${node.id}`;
      }
    }
  };

  private isShowZenfolioBranding = (tierName?: string) =>
    !!tierName && tierName.toLowerCase() === "tier1";

  public handleMenuClick = (e: any, id: string) => {
    const { siteRoutes, isEditionView } = this.props;

    if (isEditionView) {
      e.preventDefault();
      return;
    } else if (!siteRoutes) {
      e.preventDefault();
      this.redirectToPage(id);
    }
  };

  public setLayoutState = (layout: string) => {
    this.setState(
      {
        layout: layout === "A" ? "B" : "A"
      },
      () => {
        if (this.props.onLayoutChange) {
          this.props.onLayoutChange(this.state.layout);
        }
      }
    );
  };

  public buildMenu = (item: INavigationMenuItem) => {
    const { hoverState } = this.props;
    const spacing = 20;

    return (
      <div className={styles.footerMenuItemContainer} key={item.menuName || ""}>
        <li
          style={{
            margin: "0 " + spacing + "px",
            paddingBottom: 15
          }}
        >
          <a
            href={this.getNavigateLink(item)}
            className={cx(styles.footerMenuItem, hoverState === "lighten" ? styles.lighten : styles.underline)}
            id={item.menuName}
            onClick={e => this.handleMenuClick(e, item.id)}
            target={item.openInNewTab ? "_blank" : ""}
          >
            {item.menuCaption.toUpperCase()}
          </a>
        </li>
      </div>
    );
  };

  public cancelDrag = (event: any) => {
    event.preventDefault();
    return false;
  };

  public handleAgreementClick = () => {
    const { isEditionView } = this.props;
    if (!isEditionView) {
      this.setState({ showAgreement: true });
    }
  };

  public hideAgreement = () => {
    const { isEditionView } = this.props;
    if (!isEditionView) {
      this.setState({ showAgreement: false });
    }
  };

  public getCopyrightsText = (layout: string, props: any) => {
    const { studioName, showCopyright } = props;
    if (layout === "A") {
      return (
        <React.Fragment>
          {showCopyright && (
            <span className={styles.footerCopyright}>
              All content copyright © {new Date().getFullYear()} {studioName}
            </span>
          )}
          <div className={styles.footerUserAgreement}>
            <span className={styles.userAgreement} onClick={this.handleAgreementClick}>
              USER AGREEMENT
            </span>
            {this.props.isEnabledCookieSettings && (
              <span className={styles.userAgreement} onClick={this.props.handleShowCookieSettings}>
                COOKIE SETTINGS
              </span>
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div>
          {showCopyright && (
            <span className={cx(styles.footerCopyright, styles.layoutB)}>
              All content copyright © {new Date().getFullYear()} {studioName}
            </span>
          )}
          <div>
            <span className={cx(styles.userAgreement, styles.layoutB)} onClick={this.handleAgreementClick}>
              USER AGREEMENT
            </span>
            {this.props.isEnabledCookieSettings && (
              <span className={styles.userAgreement} onClick={this.props.handleShowCookieSettings}>
                COOKIE SETTINGS
              </span>
            )}
          </div>
        </div>
      );
    }
  };

  public getCopyrightsAndPoweredBySection = (props: any) => {
    const { layout } = this.state;
    return (
      <div className={layout === "B" ? styles.layoutBCopyrightContainer : ""}>
        {this.getCopyrightsText(layout, props)}
      </div>
    );
  };

  public getDesktopFooter = (props: IFooterBlockProps, footerMenuClass: any, visibleItems: INavigationMenu) => {
    const {
      studioName,
      studioLogo,
      studioAddress,
      studioPhone,
      custom,
      showNavItems,
      socialMedia,
      facebook,
      instagram,
      twitter,
      youtube,
      linkedIn,
      isEditionView
    } = props;
    const { layout } = this.state;

    return (
      <React.Fragment>
        <div className={styles.footerSpans}>
          {studioName && <span className={cx(styles.footerLogo, styles.footerSpan)}>{studioName}</span>}
          {studioAddress && <span className={cx(styles.footerAddress, styles.footerSpan)}>{studioAddress}</span>}
          {studioPhone && (
            <span className={cx(styles.footerPhone, styles.footerSpan)}>
              <a href={"tel:" + studioPhone}>{studioPhone}</a>
            </span>
          )}
          {custom && <span className={cx(styles.footerPhone, styles.footerSpan)}>{custom}</span>}
        </div>
        <div
          className={!showNavItems || isSinglePageSite(this.props.menuItems) ? styles.footerMenuSocialSingle : styles.footerMenuSocial}
        >
          {showNavItems && !isSinglePageSite(this.props.menuItems) && (
            <ul className={footerMenuClass} id="footerMenu">
              {visibleItems.items.map((item: INavigationMenuItem, index: number) => {
                return this.buildMenu(item);
              })}
            </ul>
          )}
          {socialMedia && (isEditionView || facebook || twitter || instagram || youtube || linkedIn) && (
            <div className={styles.socialMedia}>
              {layout === "B" && (
                <div className={styles.socialLight}>
                  {(facebook || (isEditionView && this.ghost())) && (
                    <a className={this.getGhostClass(facebook)} href={facebook} target="_blank">
                      <FacebookLink size={32} />
                    </a>
                  )}
                  {(twitter || (isEditionView && this.ghost())) && (
                    <a className={this.getGhostClass(twitter)} href={twitter} target="_blank">
                      <TwitterLink size={32} />
                    </a>
                  )}
                  {(instagram || (isEditionView && this.ghost())) && (
                    <a className={this.getGhostClass(instagram)} href={instagram} target="_blank">
                      <InstagramLink size={32} />
                    </a>
                  )}
                  {(youtube || (isEditionView && this.ghost())) && (
                    <a className={this.getGhostClass(youtube)} href={youtube} target="_blank">
                      <YoutubeLink size={32} />
                    </a>
                  )}
                  {(linkedIn || (isEditionView && this.ghost())) && (
                    <a className={this.getGhostClass(linkedIn)} href={linkedIn} target="_blank">
                      <LinkedinLink size={32} />
                    </a>
                  )}
                </div>
              )}
              {layout === "A" && (
                <div className={styles.socialCircle}>
                  {(facebook || (this.props.isEditionView && this.ghost())) && (
                    <a className={this.getGhostClass(facebook)} href={facebook} target="_blank">
                      <FacebookCircleLink size={32} />
                    </a>
                  )}

                  {(twitter || (this.props.isEditionView && this.ghost())) && (
                    <a className={this.getGhostClass(twitter)} href={twitter} target="_blank">
                      <TwitterCircleLink size={32} />
                    </a>
                  )}
                  {(instagram || (this.props.isEditionView && this.ghost())) && (
                    <a className={this.getGhostClass(instagram)} href={instagram} target="_blank">
                      <InstagramCircleLink size={32} />
                    </a>
                  )}
                  {(youtube || (this.props.isEditionView && this.ghost())) && (
                    <a className={this.getGhostClass(youtube)} href={youtube} target="_blank">
                      <YoutubeCircleLink size={32} />
                    </a>
                  )}
                  {(linkedIn || (this.props.isEditionView && this.ghost())) && (
                    <a className={this.getGhostClass(linkedIn)} href={linkedIn} target="_blank">
                      <LinkedinCircleLink size={32} />
                    </a>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {this.getCopyrightsAndPoweredBySection(props)}
      </React.Fragment>
    );
  };

  public componentDidUpdate() {
    if (this.props.layout !== this.state.layout && this.props.layout) {
      if (window.innerWidth > MOBILE_BREAK) {
        this.setState({ layout: this.props.layout });
      }
    }
  }

  public render() {
    const { footerColor, menuItems, numberOfItems, isSelected, padding, siteTheme } = this.props;

    const { layout, showAgreement } = this.state;

    let footerClass = styles.zenContainerFooter;
    if (isSelected) {
      footerClass = cx(footerClass, styles.selected);
    }
    const visibleItems: INavigationMenu = { items: [] };
    const navItems: number = numberOfItems;

    // Calculate main navbar menu and more sub-menu
    for (let i: number = 0; i < menuItems.items.length; i++) {
      if (i < navItems) {
        visibleItems.items.push(menuItems.items[i]);
      }
    }

    const paddingStyle = {
      marginTop: padding ? padding : "0px"
    };

    let footerbackground = cx(styles.footerContentBackground);

    // Add class for nav background
    if (footerColor === "creme") {
      footerbackground = cx(footerbackground, styles.footerCreme);
    }
    if (footerColor === "white") {
      footerbackground = cx(footerbackground, styles.footerWhite);
    }
    if (footerColor === "black") {
      footerbackground = cx(footerbackground, styles.footerBlack);
    }
    if (footerColor === "grey") {
      footerbackground = cx(footerbackground, styles.footerGrey);
    }

    footerClass = cx(footerClass, layout === "A" ? styles.layoutA : styles.layoutB);
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    return (
      <nav onDragStart={this.cancelDrag} className={footerClass}>
        <div className={footerbackground}>
          <div className={cx(styles.footerContent, styles[siteFonts.secondary])} style={paddingStyle}>
            {this.getDesktopFooter(this.props, styles.footerMenu, visibleItems)}
            <div
              id="layoutBar"
              className={cx(styles.footerLayout, isSelected ? styles.footerLayoutVisible : "")}
              onClick={e => {
                this.setLayoutState(layout);
              }}
            >
              <div className={styles.tooltip}>
                <p>Change Block Layout</p>
              </div>
              <span>LAYOUT</span>
              <div className={styles.footerLayoutBox}>{layout}</div>
            </div>
          </div>
        </div>
        <UserAgreement show={showAgreement} siteTheme={siteTheme} onHideAgreement={this.hideAgreement} />
      </nav>
    );
  }
}
