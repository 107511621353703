import Vimeo from "@u-wave/react-vimeo";
import cx from "classnames";
import "intersection-observer";
import React from "react";
import { isMobileOnly, isTablet as isTabletOnly } from "react-device-detect";
import YouTube from "react-youtube";
import { getSiteFonts, universalWindow as window } from "utilities/blocks/site";
import playIcon from "../../../icons/btn-play-video.svg";
import videoErrorImg from "../../../icons/video-unavailable.svg";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { getInteractionUrlType, INTERACTION_TYPE, onLinkClick } from "utilities/blocks/blockInteractions";
import ContextMenu from "utilities/contextMenu";
import { parseBody, renderLineBreaks } from "utilities/blocks/string";
import NzVideo from "../../NzVideo";
import { IVideoCoverImageData, IVideoProperty } from "../blockModels";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import styles from "./zenVideoBlock.module.scss";
import { ILink, IRoutes, IZenSiteTheme } from "../../../models/models";
import { LinkTypeOption } from "utilities/constant";
import { PageContext } from "utilities/pageContext";

import "./external-styles.scss";

// If we need add more fonts for other block texts this should be moved to a common file in future
// Pinyon Script font is cursive and is overflowing from title container, we add padding to control that a bit
const fontsWithTitleStyles: Record<string, React.CSSProperties | undefined> = {
  "fonts-pinyonscript": {
    paddingRight: "10px",
    paddingBottom: "5px",
    paddingLeft: "15px"
  }
};
interface IVideo {
  src: string;
  id: string;
}

export interface IVideoBlockProps extends IZenBaseBlockProps {
  title?: string;
  titlePosition?: string;
  body: string;
  interactionType?: string;
  interactionPage?: string;
  interactionUrl?: string;
  interactionNewTab?: boolean;
  pages?: any;
  routes?: IRoutes;
  onTextChange?: (data: any) => void;
  focalX?: number;
  focalY?: number;
  link?: ILink;
  isPublish?: boolean;
  getInteractionUrl?: getInteractionUrlType;
  videoUrl: string;
  autoplay?: boolean;
  loop?: boolean;
  audio?: boolean;
  defaultVideoUrl: string;
  videoCoverImage?: IVideoCoverImageData;
  cover?: boolean;
  siteTheme: IZenSiteTheme;
  isEditionView?: boolean;
  linkType: LinkTypeOption;
  galleryVideo?: IVideoProperty;
}

interface IVideoBlockState {
  containerWidth: number;
  body: string;
  videoContainerWidth: number;
  videoError: any;
  playAction: boolean;
  autoplay: boolean;
}

const BRAKE_MOBILE = 450;

export class ZenVideoBlock extends React.PureComponent<IVideoBlockProps, IVideoBlockState> {
  public static defaultProps = {
    size: 0
  };

  static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  private Container: any;
  private videoContainer: any;
  private intersectionObserver: IntersectionObserver | null = null;

  constructor(props: IVideoBlockProps) {
    super(props);
    this.Container = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.videoContainer = React.createRef();
    this.intersectionObserver = null;
    this.state = {
      containerWidth: 1096,
      videoContainerWidth: 10,
      body: parseBody(this.props.body || ""),
      videoError: null,
      playAction: false,
      autoplay: false
    };
  }

  public componentDidUpdate(prevProps: IVideoBlockProps) {
    if (prevProps.body !== this.props.body) {
      this.setState({ body: this.props.body });
    }
    if (typeof window !== "undefined" && !this.intersectionObserver) {
      this.intersectionObserver = new IntersectionObserver(this.callback, {});
    }
    if (prevProps.autoplay !== this.props.autoplay && this.videoContainer.current) {
      if (this.props.autoplay) {
        this.intersectionObserver?.observe(this.videoContainer.current);
      } else {
        this.setState({ autoplay: false });
        this.intersectionObserver?.unobserve(this.videoContainer.current);
      }
    }
    if (this.props.cover && prevProps.cover !== this.props.cover && this.state.playAction) {
      this.setState({ playAction: false });
    }
  }

  public componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    // required to adjust view for mobile after first render
    this.updateWindowDimensions();
    if (typeof window !== "undefined" && !this.intersectionObserver) {
      this.intersectionObserver = new IntersectionObserver(this.callback, {});
    }

    if (this.props.autoplay && this.videoContainer.current) {
      this.intersectionObserver?.observe(this.videoContainer.current);
    }

    // sometimes in web editor more time is needed to correctly get the container size
    setTimeout(() => {
      this.updateWindowDimensions();
    }, 300);
  }

  public UNSAFE_componentWillMount() {
    this.updateWindowDimensions();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    if (this.videoContainer.current && this.state.autoplay) {
      this.intersectionObserver?.unobserve(this.videoContainer.current);
    }
  }

  public updateWindowDimensions = () => {
    if (this.Container && this.Container.current) {
      this.setState({ containerWidth: this.Container.current.offsetWidth });
    }
    if (this.videoContainer && this.videoContainer.current) {
      this.setState({ videoContainerWidth: this.videoContainer.current.offsetWidth });
    }
  };

  private callback = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting) {
      this.setState({ autoplay: true });
    }
  };

  private hasLinkType = (): boolean => {
    const { link } = this.props;

    switch (link?.type) {
      case LinkTypeOption.GALLERY:
        return link?.albumFolderData !== undefined;
      case LinkTypeOption.URL:
        return link?.url != undefined && link?.url != "";
      case LinkTypeOption.PAGE:
        return link?.page != undefined && link?.page != "";
      default:
        return true;
    }
  };

  private hasInteractions = (): boolean => {
    const { interactionType, interactionUrl, interactionPage, interactionNewTab } = this.props;

    switch (interactionType) {
      case INTERACTION_TYPE.URL:
        return interactionUrl !== undefined && interactionUrl !== "";
      case INTERACTION_TYPE.PAGE:
        return interactionPage !== undefined && interactionPage !== "";
      case INTERACTION_TYPE.NONE:
        return false;
      default:
        return false;
    }
  };

  private validURL(str: string) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  private getVideoIdFromUrl = (url: string, resetError: boolean): IVideo | null => {
    let videoId: string | string[] = "";

    if (!url || !this.validURL(url)) {
      return null;
    }

    const modifiedUrl: string[] = url.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (modifiedUrl[2] !== undefined) {
      videoId = modifiedUrl[2].split(/[^0-9a-z_\-]/i);
      videoId = videoId[0];
      if (videoId && resetError) {
        this.setState({ videoError: null });
      }
      return { src: "youtube", id: videoId };
    }

    videoId = url;

    if (videoId.indexOf("vimeo") !== -1) {
      const splitedVimeoUrl: string[] = videoId.split("/");
      const id = splitedVimeoUrl[splitedVimeoUrl.length - 1];

      if (id && resetError) {
        this.setState({ videoError: null });
      }
      return { src: "vimeo", id };
    }

    this.setState({ videoError: "error" });
    return null;
  };

  public handleButtonClick = () => {
    const {
      pages,
      link,
      routes,
      interactionType,
      interactionPage,
      interactionUrl,
      interactionNewTab,
      readOnly
    } = this.props;

    if (readOnly && pages) {
      onLinkClick(
        pages,
        interactionNewTab,
        interactionUrl,
        interactionPage,
        interactionType,
        routes ? routes : {},
        link,
        this.props.isPublish,
        this.props.getInteractionUrl
      );
    }
  };

  private onVimeoEnd = (e: any) => {
    this.setState({ playAction: false });
  };

  private onYoutubeEnd = (e: any) => {
    if (this.props.loop) {
      e.target.playVideo();
    } else {
      e.target.playVideo();
      e.target.pauseVideo();
      this.setState({ playAction: false });
    }
  };

  private onYoutubeReady = (e: any) => {
    if (!this.props.audio) {
      e.target.setVolume(0);
    }
  };

  private onVideoError = (e: any) => {
    this.setState({ videoError: e });
  };

  private handleVideoPlay = (e: any) => {
    this.setState({ playAction: true });
  };

  private isMobile = () => {
    return isMobileOnly || window.innerWidth < (this.props.isEditionView ? 1157 : 768);
  };

  private isTablet = () => {
    const isEdition = this.props.isEditionView;
    const min = isEdition ? 1157 : 768;
    const max = isEdition ? 1413 : 1024;

    const result = isTabletOnly || (window.innerWidth >= min && window.innerWidth < max);
    return result;
  };

  private getAlignment = (alignment?: string) => {
    return this.isMobile() ? "center" : alignment;
  };

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      layout,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      fontFamily,
      divider,
      padding,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      fullWidth,
      cover,
      loop,
      audio,
      linkType,
      galleryVideo
    } = this.props;
    const videoUrl = parseBody(this.props.videoUrl || "");
    const title = parseBody(this.props.title || "");
    const { body, containerWidth, videoContainerWidth, videoError, autoplay } = this.state;
    const blockTextColor = getBlockTextColor(
      backgroundType === "none" && backgroundColor ? siteTheme.backgroundColor.value : backgroundColor,
      siteTheme.accentColor.value,
      backgroundType,
      siteTheme.backgroundColor.value
    );

    // these styles will depend on the current site font in case we need
    // to adjust paddings, etc
    const titleFontStyles = fontsWithTitleStyles[siteTheme.fontsStyle];

    const titleClass = cx(styles.title);
    const titleStyle = { color: siteTheme.accentColor.value, ...titleFontStyles };

    const blockClass = cx(styles.block);
    const blockStyle = { color: blockTextColor };

    let containerClass = cx(styles.zenContainer, styles.alignCenter);
    if (layout) {
      containerClass = cx(
        containerClass,
        layout === "A"
          ? styles.layoutA
          : layout === "B"
          ? styles.layoutB
          : layout === "C"
          ? styles.layoutC
          : styles.layoutD
      );
    }

    if (containerWidth <= BRAKE_MOBILE) {
      containerClass = cx(containerClass, styles.zenMobile);
    }

    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    const hasLink = this.props.link ? this.hasLinkType() : this.hasInteractions();
    const newVideoLink = this.getVideoIdFromUrl(videoUrl, true);
    const videoLink = newVideoLink ? newVideoLink : this.getVideoIdFromUrl(this.props.defaultVideoUrl, false);

    const classContainer = cx(containerClass, {
      [styles.layoutA]: layout === "A",
      [styles.layoutB]: layout === "B",
      [styles.layoutC]: layout === "C",
      [styles.layoutD]: layout === "D"
    });

    let textBlockStyle: React.CSSProperties = {};

    if (this.props.layout === "C" || this.props.layout === "D") {
      if (!this.isMobile() && !this.isTablet()) {
        textBlockStyle = {
          minHeight: "336px"
        };
      } else if (this.isTablet()) {
        textBlockStyle = {
          minHeight: "200px"
        };
      }
    }

    const notEmptyContent = this.props.title?.trim() !== "" || this.props.body?.trim() !== "";
    const isGhost = !this.context.noGhost && !!this.props.videoCoverImage?.isFallback;
    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        readOnly={readOnly}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        divider={divider}
        alignment={this.getAlignment(alignment)}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        layout={""}
        selectedBlock={selectedBlock}
        fullWidth={fullWidth}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div ref={this.Container} className={classContainer}>
            <div
              className={cx(
                styles.picture,
                { [styles.clickable]: hasLink && this.props.readOnly },
                { [styles.error]: videoError },
                { [styles.maxWidthEmpty]: (layout === "C" || layout === "D") && !notEmptyContent },
                { [styles.pictureTop]: this.props?.body?.trim() !== "" && this.props?.title?.trim() !== "" }
              )}
              onContextMenu={ContextMenu.handleBlockContextMenu}
              onClick={this.handleButtonClick}
            >
              {this.props.videoCoverImage && this.props.cover && !this.state.playAction && (
                <div className={styles.coverImageParent}>
                  <div
                    className={cx(styles.coverImage, {
                      [styles.coverImageFull]: this.props.layout === "A" || this.props.layout === "B",
                      [styles.coverImageSmall]: this.props.layout === "C" || this.props.layout === "D"
                    })}
                    style={{
                      backgroundImage: `url(${this.props.videoCoverImage.url})`,
                      backgroundPosition:
                        this.props.videoCoverImage.focalX + "% " + this.props.videoCoverImage.focalY + "%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: "100%"
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: isGhost ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.4)"
                    }}
                    className={cx(styles.overlay, {
                      [styles.coverImageFull]: this.props.layout === "A" || this.props.layout === "B",
                      [styles.coverImageSmall]: this.props.layout === "C" || this.props.layout === "D"
                    })}
                    onClick={this.handleVideoPlay}
                  >
                    <img className={styles.overlayIconPlay} src={playIcon} width="108px" height="108px" />
                  </div>
                </div>
              )}

              {(!this.props.cover || this.state.playAction) && (
                <>
                  {videoError && (
                    <div className={styles.errorContainer} ref={this.videoContainer}>
                      <img src={videoErrorImg} />
                    </div>
                  )}
                  {!videoError && videoLink?.src === "vimeo" && linkType === LinkTypeOption.URL && (
                    <div className={styles.vimeo} ref={this.videoContainer}>
                      <Vimeo
                        video={videoLink.id}
                        responsive
                        width={videoContainerWidth}
                        autoplay={autoplay || cover ? true : false}
                        loop={loop ? true : false}
                        volume={!audio ? 0 : 1}
                        onError={this.onVideoError}
                        onEnd={this.onVimeoEnd}
                      />
                    </div>
                  )}

                  {!videoError && videoLink?.src === "youtube" && linkType === LinkTypeOption.URL && (
                    <div ref={this.videoContainer}>
                      <YouTube
                        videoId={videoLink.id}
                        className={styles.youtube}
                        opts={{
                          width: "100%",
                          playerVars: {
                            autoplay: autoplay || cover ? 1 : 0,
                            mute: !audio ? 1 : 0,
                            loop: loop ? 1 : 0
                          }
                        }}
                        onReady={this.onYoutubeReady}
                        onEnd={this.onYoutubeEnd}
                        onError={this.onVideoError}
                      />
                    </div>
                  )}

                  {linkType === LinkTypeOption.GALLERY && galleryVideo && (
                    <div className={styles.nzVideo} ref={this.videoContainer}>
                      <NzVideo
                        video={galleryVideo.detail}
                        analyticsParams={
                          galleryVideo.galleryId
                            ? { type: "website_block_video", galleryId: galleryVideo.galleryId }
                            : undefined
                        }
                        thumbnailUrlTemplate={galleryVideo.thumbnailUrlTemplate}
                        videoUrlTemplate={galleryVideo.videoUrlTemplate}
                        imagePlacement="contain"
                        playable={true}
                        preload={false}
                        autoplay={autoplay || cover}
                        defaultMuted={!audio}
                        loop={loop}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            {notEmptyContent && (
              <div
                className={cx(styles.textBlock, styles[siteFonts.primary], {
                  [styles.left]: layout === "C" || layout === "D",
                  [styles.right]: layout === "A" || layout === "B",
                  [styles.topAlign]: layout === "D" || layout === "B",
                  [styles.bottomAlign]: layout === "A" || layout === "C",
                  [styles.textMaxWidth]: (layout === "C" || layout === "D") && !this.isMobile(),
                  [styles.textBlockDesktop]: !this.isMobile()
                })}
                style={textBlockStyle}
              >
                {title && title.trim() !== "" && (
                  <div
                    className={cx(
                      titleClass,
                      styles[siteFonts.primaryExtra],
                      { [styles.titleClickable]: !readOnly },
                      { [styles.marginBottom]: layout !== "B" && layout !== "D" },
                      { [styles.marginTop]: layout !== "A" && layout !== "C" }
                    )}
                    style={titleStyle}
                  >
                    <span>{title}</span>
                  </div>
                )}
                {body && body?.trim() !== "" && (
                  <div
                    className={cx(
                      blockClass,
                      { [styles.blockTop]: notEmptyContent },
                      { [styles.marginBottom]: layout !== "B" && layout !== "D" },
                      { [styles.marginTop]: layout !== "A" && layout !== "C" },
                      { [styles.pictureBottom]: notEmptyContent }
                    )}
                    style={blockStyle}
                  >
                    <div className={cx(styles.textEditor, styles[siteFonts.secondary])}>
                      <span>{renderLineBreaks(body.replace(new RegExp("<br />", "g"), "\n"), true)}</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}
