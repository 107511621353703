import { apiClient } from "..";
import { IConnectSetup, IGoogleCalendar } from "../../store/profile/googleCalendar/model";
import { apiType } from "../apiType";

export interface IApiGoogleCalendar {
  setupConnect: (redirectUri: string) => Promise<IConnectSetup>;
  loadCalendars: () => Promise<IGoogleCalendar[]>;
  disconnect: () => Promise<void>;
}

const api: IApiGoogleCalendar = {
  setupConnect: (redirectUri: string) =>
    apiClient.post<IConnectSetup>(`${apiType.bookingWidget}photographer/calendar/google/connect`, { redirectUri }),

  loadCalendars: async () => {
    return (await apiClient.get<{ calendars: IGoogleCalendar[] }>(`${apiType.bookingWidget}photographer/calendars`))
      .calendars;
  },

  disconnect: () => apiClient.post<void>(`${apiType.bookingWidget}photographer/calendar/google/disconnect`)
};

export default api;
