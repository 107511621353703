import * as React from "react";
import { ErrorBoundary } from "../../common/error-boundary";

class Content extends React.Component {
  public render() {
    return (
      <ErrorBoundary>
        <div>{this.props.children}</div>
      </ErrorBoundary>
    );
  }
}

export default Content;
