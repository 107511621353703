import React from "react";
import { RenderLeafProps } from "slate-react";

import { RenderLeaf, SlatePlugin } from "../core/types";

export const renderLeafPlugins = (plugins: SlatePlugin[], renderLeafList: RenderLeaf[]) => (
  leafProps: RenderLeafProps
) => {
  renderLeafList.forEach(renderLeaf => {
    leafProps.children = renderLeaf(leafProps);
  });

  plugins.forEach(({ renderLeaf }) => {
    if (!renderLeaf) {
      return;
    }
    leafProps.children = renderLeaf(leafProps);
  });

  // Based on: https://github.com/ianstormtaylor/slate/blob/main/site/examples/inlines.tsx
  return (
    <span style={leafProps.leaf.text === "" ? { paddingLeft: 0.1 } : undefined} {...leafProps.attributes}>
      {leafProps.children}
    </span>
  );
};
