import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { isEdge } from "react-device-detect";
import checkmark from "./checkmark-green.svg";
import styles from "./stepsRadioButtons.module.scss";

interface IStepsRadioButtonsProps {
  actualStep: number;
  finishedStep: number;
  stepNavs: string[];
  blocked: boolean;
  stepWidth: number;
  onChangeStep: (step: number) => void;
  className?: string;
}

class StepsRadioButtons extends React.Component<IStepsRadioButtonsProps> {
  public render() {
    const { stepNavs, actualStep, finishedStep, onChangeStep, blocked, stepWidth, className } = this.props;

    return (
      <div className={classNames(styles.container, className)}>
        {_.map(stepNavs, (step, index) => (
          <Step
            key={index}
            selected={actualStep === index}
            label={step}
            success={index < finishedStep}
            onChangeStep={onChangeStep}
            step={index}
            selectable={index <= finishedStep}
            blocked={blocked && index > actualStep}
            width={stepWidth}
            connected={{
              before: index > 0 && index - 1 < finishedStep,
              after: index < stepNavs.length - 1 && index < finishedStep
            }}
          />
        ))}
      </div>
    );
  }
}

interface IStepProps {
  selected: boolean;
  success: boolean;
  onChangeStep: (step: number) => void;
  label: string;
  step: number;
  blocked: boolean;
  selectable: boolean;
  width: number;
  connected: { before: boolean; after: boolean };
}

// tslint:disable-next-line: max-classes-per-file
class Step extends React.Component<IStepProps> {
  public render() {
    const { label, success, selected, width, selectable, blocked, connected } = this.props;

    const stateClassNames = classNames({
      [styles.active]: selected,
      [styles.success]: success && !selected,
      [styles.selectable]: selectable && !selected && !blocked,
      [styles.edge]: isEdge
    });

    return (
      <div className={styles.step} style={{ width }}>
        <div className={styles.graphics}>
          <div className={classNames(styles.before, { [styles.connected]: connected.before })} />
          <div className={classNames(styles.node, stateClassNames)} onClick={this.onClick}>
            {success && !selected && <img className={styles.success} alt="success" src={checkmark} />}
          </div>
          <div className={classNames(styles.after, { [styles.connected]: connected.after })} />
        </div>
        <div className={styles.label}>{label}</div>
      </div>
    );
  }

  private onClick = () => {
    const { selectable, selected, blocked, onChangeStep, step } = this.props;
    if (selectable && !selected && !blocked) {
      onChangeStep(step);
    }
  };
}

export default StepsRadioButtons;
