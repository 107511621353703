import React from "react";

import { BLOCK_LINK, RenderElement } from "../../../core/types";

// Based on: https://github.com/ianstormtaylor/slate/blob/main/site/examples/inlines.tsx
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

export const renderElementLink: RenderElement = ({ attributes, children, element }) => {
  if (element.type === BLOCK_LINK) {
    const url: string = element.url as string;
    return (
      <a {...attributes} target="_blank" href={url} rel="noopener noreferrer">
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </a>
    );
  }
  return undefined;
};
