import escapeHtml from "escape-html";
import _ from "lodash";
import { Node, Text, Transforms } from "slate";
import { jsx } from "slate-hyperscript";
import { ReactEditor } from "slate-react";

export const ELEMENT_TAGS = {
  A: (el: any) => ({
    type: "link",
    href: el.getAttribute("href"),
    target: el.getAttribute("target")
  }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H1: () => ({ type: "heading-one" }),
  H2: () => ({ type: "heading-two" }),
  H3: () => ({ type: "heading-three" }),
  H4: () => ({ type: "heading-four" }),
  H5: () => ({ type: "heading-five" }),
  H6: () => ({ type: "heading-six" }),
  IMG: (el: any) => ({ type: "image", url: el.getAttribute("src") }),
  LI: () => ({ type: "list-item" }),
  OL: () => ({ type: "numbered-list" }),
  P: () => ({ type: "paragraph" }),
  PRE: () => ({ type: "code" }),
  UL: () => ({ type: "bulleted-list" })
};

export const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true })
};

const SPECIAL_CHARACTERS_REGEX = /[&\/\\#,+()$~%.'":*?<>{}-]/g;
const PHONE_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const serialize = (node: Node): string => {
  if (Text.isText(node)) {
    let text: string = escapeHtml(node.text);
    if (node.bold) {
      text = `<strong>${text}</strong>`;
    }
    if (node.italic) {
      text = `<em>${text}</em>`;
    }
    if (node.underline) {
      text = `<u>${text}</u>`;
    }
    if (node.heading === 32) {
      text = `<h1 style="font-size: 32px">${text}</h1>`;
    }
    if (node.heading === 24) {
      text = `<h2 style="font-size: 24px">${text}</h2>`;
    }
    if (node.heading === 18) {
      text = `<h3 style="font-size: 18px">${text}</h3>`;
    }
    return text;
  }

  const children = node.children.map((n: any) => serialize(n)).join("");
  switch (node.type) {
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "link":
      const url = node.url || node.href;
      return `<a href="${escapeHtml(url as string)}" target="${node.target}">${children}</a>`;
    default:
      return children;
  }
};

// Based on: https://github.com/ianstormtaylor/slate/blob/main/site/examples/paste-html.tsx
export const deserialize = (el: any, elementTags: any, textTags: any, trim?: boolean): any => {
  // Workaround for phone number links that are auto inserted in iPad Safari and break the node parsing
  // Here we just extract the text content and ignore the anchor element
  if (
    el.nodeType === 3 ||
    (el.nodeName === "A" &&
      el.textContent
        ?.replace(/ /g, "")
        .replace(SPECIAL_CHARACTERS_REGEX, "")
        .match(PHONE_REGEX))
  ) {
    return trim ? _.trim(el.textContent, "\r\n") || null : el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  }

  const { nodeName } = el;
  let parent = el;

  if (nodeName === "PRE" && el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
    parent = el.childNodes[0];
  }
  let children = Array.from(parent.childNodes)
    .map(node => deserialize(node, elementTags, textTags, trim))
    .flat();

  if (children.length === 0) {
    children = [{ text: "" }];
  }

  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }

  const textTag = textTags[nodeName];
  if (textTag) {
    const attrs = textTag(el);
    return children.map(child => jsx("text", attrs, child));
  }

  const elementTag = elementTags[nodeName] || elementTags[""];
  if (elementTag) {
    const attrs = elementTag(el);
    return jsx("element", attrs, children);
  }

  return children;
};

export const normalizeHtml = (data: string) => {
  let newData = data;
  if (!data.startsWith("<p>")) {
    newData = `<p>${newData}</p>`;
  }

  return newData;
};

export const withPlainText = (editor: ReactEditor) => {
  // Force Slate to ignore formatted text when pasting. Ensure Slate only
  // pastes plain text content. Reference:
  // https://github.com/ianstormtaylor/slate/blob/5ef346feb9e6430b3b6af66f196e5445a9ee3ff2/packages/slate-react/src/plugin/with-react.ts#L216-L234

  const { insertData } = editor;

  editor.insertData = (data: DataTransfer) => {
    const slateData: string = data.getData("application/x-slate-fragment");

    if (slateData) {
      try {
        const nodes: Node[] = JSON.parse(decodeURIComponent(atob(slateData)));
        Transforms.insertNodes(editor, nodes);
        return;
      } catch (e) {
        // Continue with plain text parsing
      }
    }

    // Fall back to plaintext clipboard contents
    const text: string = data.getData("text/plain");

    if (text) {
      const nodes = text.split(/\r\n|\n/).map(line => {
        return {
          type: "paragraph",
          children: [{ text: line }]
        };
      });

      // For copy & paste over a placeholder we check
      // if there is an empty paragraph that need to be removed
      const isEmptyParagraph =
        editor.children?.length === 1 &&
        editor.children[0].type === "paragraph" &&
        (editor.children[0] as any).children?.length === 1 &&
        (editor.children[0] as any).children[0].text === "";

      if (isEmptyParagraph) {
        Transforms.removeNodes(editor, { at: [0] });
      }

      Transforms.insertNodes(editor, nodes);
      return;
    }

    insertData(data);
  };

  return editor;
};

export enum BG_COLORS {
  CREAM = "#FCFCF4",
  WHITE = "#FFFFFF",
  BLACK = "#000000",
  GRAY = "#333333"
}

export const themeBackgroundColors = [
  { name: BG_COLORS.CREAM, class: "creamHyperLink" },
  { name: BG_COLORS.WHITE, class: "whiteHyperLink" },
  { name: BG_COLORS.GRAY, class: "grayHyperLink" },
  { name: BG_COLORS.BLACK, class: "blackHyperLink" }
];

export const getSiteBackgroundColors = (backgroundColor: string | undefined): string => {
  const bgColor = backgroundColor ? (backgroundColor.toUpperCase() as BG_COLORS) : BG_COLORS.WHITE;
  const bgThemeItem = themeBackgroundColors.find(fs => fs.name === bgColor);
  if (bgThemeItem) return bgThemeItem.class;

  return "blackHyperLink";
};
