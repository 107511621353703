import cx from "classnames";
import Image from "../../../Image";
import VideoDuration from "../../../VideoDuration";
import playVideoSmallIconUrl from "icons/btn-play-video-small.svg";
import playVideoIconUrl from "icons/btn-play-video.svg";
import React, { Fragment, useContext, useRef, useState } from "react";
import { IGetPictureStyle } from "utilities/blocks/blockColors";
import ContextMenu from "utilities/contextMenu";
import Name, { showName } from "../../zenPhotoTile/name";
import styles from "./zenLandscapePhotoTile.module.scss";
import { PageContext } from "utilities/pageContext";
import { IBlockPhotoAnimationClasses, IPhotoNameProps } from "../../blockModels";
import { matchMediaPhone } from "utilities/responsive";

export interface ILandscapePhotoTileProps extends Partial<IPhotoNameProps> {
  isFallback: boolean;
  imageWidth: string;
  borderStyle: IGetPictureStyle;
  paddingRight: string;
  paddingBottom: string;
  backgroundColor?: string;
  src: string;
  srcset?: string;
  aspectRatio?: string;
  alt?: string;
  dropShadow?: boolean;
  galleryStyle?: string;
  index: number;
  overlayComponent?: JSX.Element | null;
  overlayStyle?: string;
  height?: number;
  focalX?: number;
  focalY?: number;
  isEditionView?: boolean;
  animationClasses?: IBlockPhotoAnimationClasses;
  blockImageEvents?: boolean;
  isEditor?: boolean;
  duration?: number;
  isGif?: boolean;
  isPng?: boolean;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  getHoverComponent?: (index: number) => JSX.Element;
  getBadgeComponent?: (index: number) => JSX.Element | Element | null;
  onHoverPhoto?: (hoveredPhotoIndex: number) => void;
  hasLightbox?: boolean;
  showLightbox?: boolean;
  dominantColor?: string;
}

const ZenLandscapePhotoTile: React.FC<ILandscapePhotoTileProps> = ({
  src,
  srcset,
  aspectRatio,
  alt,
  index,
  dropShadow,
  overlayComponent,
  overlayStyle,
  focalX,
  focalY,
  isEditionView,
  isFallback,
  imageWidth,
  borderStyle,
  galleryStyle,
  paddingRight,
  paddingBottom,
  animationClasses,
  duration,
  isEditor,
  isGif,
  isPng,
  onClick,
  getHoverComponent,
  getBadgeComponent,
  onHoverPhoto,
  blockImageEvents,
  name,
  nameAlignment,
  nameVisibility,
  isVideo,
  hasLightbox,
  showLightbox,
  dominantColor
}) => {
  const pageContext = useContext(PageContext);
  const ref = useRef<HTMLImageElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [containerHeight, setContainerHeight] = useState("300px");

  const handlePhotoLoad = () => {
    setContainerHeight("unset");
  };

  const getImagePadding = (borderWidth?: number) => {
    return borderWidth && `${(borderWidth > 1 ? 1 : 2) * borderWidth}px`;
  };

  const onClickImageParent = (evt: React.MouseEvent<HTMLElement>) => {
    if (blockImageEvents && onClick) {
      onClick(evt);
    }
  };

  const renderPlayIcon = (duration?: number) => {
    const imageWidth = (ref.current?.offsetWidth || 221) / 2;
    return (
      <Fragment>
        <div className={styles.videoPlay}>
          <img
            src={matchMediaPhone ? playVideoIconUrl : playVideoSmallIconUrl}
            alt="Play"
            className={styles.videoPlay}
            style={{
              left: imageWidth + "px"
            }}
          />
        </div>
        {duration && <VideoDuration className={styles.videoDuration} durationMilliseconds={duration} />}
      </Fragment>
    );
  };

  const checkClickable = (): boolean => {
    if (hasLightbox) {
      return !!showLightbox || !!isVideo;
    } else {
      return !!onClick;
    }
  };

  const isGhost = !pageContext.noGhost && isFallback;
  const isTransparent = isGif || isPng;
  const withDuration = isEditor && !!duration;
  const showHoverComponent = isHovered && !!getHoverComponent;
  const isClickable = checkClickable();

  let imageStyle: any = {
    objectPosition: focalX + "% " + focalY + "%",
    padding: getImagePadding(borderStyle.borderWidth),
    cursor: onClick ? "pointer" : "default",
    width: `100%`
  };

  const dominantColorStyle = {
    backgroundColor: dominantColor
  }

  return (
    <>
      <div
        unselectable="on"
        onContextMenu={ContextMenu.handleBlockContextMenu}
        className={cx(
          isGif ? styles.gif : animationClasses?.imgContainer,
          styles.zenContainerPhotoTile,
          styles.masonryBrickHorizontal,
          styles.masonryBrick,
          isClickable ? styles.clickable : "",
          dropShadow ? styles.dropShadow : "",
          !isGhost ? "canSelect" : ""
        )}
        onClick={onClickImageParent}
        style={{
          width: `${imageWidth}`,
          height: containerHeight,
          display: "flex",
          position: "relative",
          marginRight: paddingRight,
          marginBottom: paddingBottom
        }}
        onMouseEnter={e => {
          setIsHovered(true);
          if (onHoverPhoto) {
            onHoverPhoto(index);
          }
        }}
        onMouseLeave={e => {
          setIsHovered(false);
          if (onHoverPhoto) {
            onHoverPhoto(-1);
          }
        }}
      >
        <Image
          ref={ref}
          data-index={index}
          src={src}
          srcset={srcset}
          aspectratio={aspectRatio}
          className={cx(
            styles.masonryBrickImage,
            dropShadow ? styles.dropShadow : "",
            styles.transparentMansonryBrickImage
          )}
          isGhost={isGhost}
          styles={imageStyle}
          alt={alt ? alt : name}
          onLoad={handlePhotoLoad}
          onClick={onClick}
          isClickable={isClickable}
        />
        {isVideo && renderPlayIcon(duration)}
        {showName(isHovered, name, nameVisibility) && (
          <Name
            name={name!}
            alignment={nameAlignment}
            isHovered={showHoverComponent}
            withDuration={withDuration || isVideo}
          />
        )}
        {getBadgeComponent && getBadgeComponent(index)}
        {overlayComponent && <div className={overlayStyle}>{overlayComponent}</div>}
        {showHoverComponent && <div className={overlayStyle}>{getHoverComponent!(index)}</div>}
        {
          <>
            <div style={dominantColorStyle} className={animationClasses?.revealContainerBottom} />
            <div style={dominantColorStyle} className={animationClasses?.revealContainerTop} />
          </>
        }
      </div>
    </>
  );
};

export default ZenLandscapePhotoTile;
