export enum ActionType {
  GET_BOOKING_STARTED = "BOOKINGS_GET_BOOKING_STARTED",
  GET_BOOKING_SUCCESS = "BOOKINGS_GET_BOOKING_SUCCESS",
  GET_BOOKING_ERROR = "BOOKINGS_GET_BOOKING_ERROR",
  LOAD_STARTED = "BOOKINGS_LOAD_STARTED",
  LOAD_SUCCESS = "BOOKINGS_LOAD_SUCCESS",
  LOAD_ERROR = "BOOKINGS_LOAD_ERROR",
  UPDATE_STARTED = "BOOKINGS_UPDATE_STARTED",
  UPDATE_SUCCESS = "BOOKINGS_UPDATE_SUCCESS",
  UPDATE_ERROR = "BOOKINGS_UPDATE_ERROR",
  CANCEL_STARTED = "BOOKINGS_CANCEL_STARTED",
  CANCEL_SUCCESS = "BOOKINGS_CANCEL_SUCCESS",
  CANCEL_ERROR = "BOOKINGS_CANCEL_ERROR",
  APPROVE_STARTED = "BOOKINGS_APPROVE_STARTED",
  APPROVE_SUCCESS = "BOOKINGS_APPROVE_SUCCESS",
  APPROVE_ERROR = "BOOKINGS_APPROVE_ERROR",
  DECLINE_STARTED = "BOOKINGS_DECLINE_STARTED",
  DECLINE_SUCCESS = "BOOKINGS_DECLINE_SUCCESS",
  DECLINE_ERROR = "BOOKINGS_DECLINE_ERROR",
  RESCHEDULE_STARTED = "BOOKINGS_RESCHEDULE_STARTED",
  RESCHEDULE_SUCCESS = "BOOKINGS_RESCHEDULE_SUCCESS",
  RESCHEDULE_ERROR = "BOOKINGS_RESCHEDULE_ERROR",
  PATCH_BOOKING = "BOOKINGS_PATCH_BOOKING"
}
