import React from "react";
import cx from "classnames";
import styles from "./largeBannerSale1.module.scss";
import { IBannerContent } from "models/coupon";
import { BannerViewMode, BannerFontStyle, BannerDiscountType } from "utilities/constant";
import Present from "./Present";
import _ from "lodash";
import MobilePresentsSVG from "./MobilePresents";
import { getLargeBannerStyle } from "../Service";

export interface ILargeBannerSeasonal1Props {
  content?: IBannerContent;
  viewMode: BannerViewMode;
  onClick?: () => void;
}

interface ILargeBannerSeasonal1State {
  containerWidth: number;
}

const DEFAULT_COLORS = {
  white: "#ffffff",
  lightPurple: "#d1b4ce",
  darkPurple: "#a93f9e"
};

export class LargeBannerSeasonal1 extends React.PureComponent<ILargeBannerSeasonal1Props, ILargeBannerSeasonal1State> {
  private containerRef = React.createRef<HTMLDivElement>();
  public state = {
    containerWidth: 391
  };
  public getProps = () => {
    const defaultProps = {
      viewMode: BannerViewMode.DesktopClient,
      content: {
        header: {
          text: "Mother’s Day Sale",
          color: DEFAULT_COLORS.white
        },
        body: {
          text: "20% off all purchases",
          color: DEFAULT_COLORS.darkPurple
        },
        code: {
          text: "Code: [code]",
          color: DEFAULT_COLORS.white
        },
        button: {
          text: "SHOP NOW",
          color: DEFAULT_COLORS.lightPurple,
          backgroundColor: DEFAULT_COLORS.white
        },
        fontStyle: BannerFontStyle.Serif,
        backgroundColor: DEFAULT_COLORS.lightPurple,
        discountAmount: "20",
        discountType: BannerDiscountType.PercentOff
      }
    };
    const renderProps = _.merge(defaultProps, this.props);
    return renderProps;
  };

  private renderDiscountSymbol = () => {
    const discountType = this.getProps().content?.discountType;
    return (
      (discountType === BannerDiscountType.PercentOff && "%") || (discountType === BannerDiscountType.DollarOff && "$")
    );
  };

  private renderPresentsSVG = () => {
    const { viewMode, content } = this.getProps();
    switch (viewMode) {
      case BannerViewMode.DesktopClient: {
        return <Present top={110} height={268} color={content.backgroundColor} />;
      }
      case BannerViewMode.MobileClient: {
        return <MobilePresentsSVG color={content.backgroundColor} width={this.state.containerWidth} />;
      }
      default: {
        return <Present top={110} height={268} color={content.backgroundColor} />;
      }
    }
  };

  private updateContainerWidth = () => {
    const width = this.containerRef.current?.offsetWidth;
    if (width) {
      this.setState({ containerWidth: width });
    }
  };

  public componentDidMount() {
    this.updateContainerWidth();
    window.addEventListener("resize", this.updateContainerWidth);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateContainerWidth);
  }

  public render() {
    const { content, viewMode } = this.getProps();

    return (
      <div
        className={cx(styles.container, [styles[viewMode]], [styles[content.fontStyle || BannerFontStyle.SansSerif]])}
        style={getLargeBannerStyle(viewMode, content)}
        ref={this.containerRef}
      >
        <div className={styles.leftContent}>
          <div className={styles.header} style={{ color: content.header?.color }}>
            {content?.header?.text}
          </div>
          <div className={styles.body} style={{ color: content.body?.color }}>
            {/* <span className={styles.percent}>{content.discountAmount}</span>
            {this.renderDiscountSymbol()} */}
            {content?.body?.text}
          </div>
          <div className={styles.code} style={{ color: content.code?.color }}>
            {content?.code?.text}
          </div>
          <div className={styles.btnContainer}>
            <button
              className={styles.button}
              style={{ color: content.button?.color, backgroundColor: content.button?.backgroundColor }}
              onClick={this.props.onClick}
            >
              <div>{content?.button?.text}</div>
            </button>
          </div>
        </div>

        <div className={styles.rightContent}>{this.renderPresentsSVG()}</div>
      </div>
    );
  }
}

export default LargeBannerSeasonal1;
