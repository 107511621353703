export enum ServiceActionType {
  LOAD_SERVICES_STARTED = "LOAD_SERVICES_STARTED",
  LOAD_SERVICES_SUCCESS = "LOAD_SERVICES_SUCCESS",
  LOAD_SERVICES_ERROR = "LOAD_SERVICES_ERROR",

  LOAD_SERVICE_DETAILS_STARTED = "LOAD_SERVICE_DETAILS_STARTED",
  LOAD_SERVICE_DETAILS_SUCCESS = "LOAD_SERVICE_DETAILS_SUCCESS",
  LOAD_SERVICE_DETAILS_ERROR = "LOAD_SERVICE_DETAILS_ERROR",
  RESET_SERVICE_DETAILS_DETAILS = "RESET_SERVICE_DETAILS_DETAILS",

  CREATE_SERVICE_STARTED = "CREATE_SERVICE_STARTED",
  CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS",
  CREATE_SERVICE_ERROR = "CREATE_SERVICE_ERROR",

  UPDATE_SERVICE_DETAILS_STARTED = "UPDATE_SERVICE_DETAILS_STARTED",
  UPDATE_SERVICE_DETAILS_SUCCESS = "UPDATE_SERVICE_DETAILS_SUCCESS",
  UPDATE_SERVICE_DETAILS_ERROR = "UPDATE_SERVICE_DETAILS_ERROR",

  DELETE_SERVICE_STARTED = "DELETE_SERVICE_STARTED",
  DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS",
  DELETE_SERVICE_ERROR = "DELETE_SERVICE_ERROR",

  TOGGLE_SERVICE_STATE_STARTED = "TOGGLE_SERVICE_STATE_STARTED",
  TOGGLE_SERVICE_STATE_SUCCESS = "TOGGLE_SERVICE_STATE_SUCCESS",
  TOGGLE_SERVICE_STATE_ERROR = "TOGGLE_SERVICE_STATE_ERROR",

  DUPLICATE_SERVICE_STARTED = "DUPLICATE_SERVICE_STARTED",
  DUPLICATE_SERVICE_SUCCESS = "DUPLICATE_SERVICE_SUCCESS",
  DUPLICATE_SERVICE_ERROR = "DUPLICATE_SERVICE_ERROR",

  REORDER_SERVICES_STARTED = "REORDER_SERVICES_STARTED",
  REORDER_SERVICES_SUCCESS = "REORDER_SERVICES_SUCCESS",
  REORDER_SERVICES_ERROR = "REORDER_SERVICES_ERROR",

  DELETE_SHOOT_TYPE_STARTED = "DELETE_SHOOT_TYPE_STARTED",
  DELETE_SHOOT_TYPE_SUCCESS = "DELETE_SHOOT_TYPE_SUCCESS",
  DELETE_SHOOT_TYPE_ERROR = "DELETE_SHOOT_TYPE_ERROR",

  REORDER_SHOOT_TYPES_STARTED = "REORDER_SHOOT_TYPES_STARTED",
  REORDER_SHOOT_TYPES_SUCCESS = "REORDER_SHOOT_TYPES_SUCCESS",
  REORDER_SHOOT_TYPES_ERROR = "REORDER_SHOOT_TYPES_ERROR",

  RENAME_SHOOT_TYPE_STARTED = "RENAME_SHOOT_TYPE_STARTED",
  RENAME_SHOOT_TYPE_SUCCESS = "RENAME_SHOOT_TYPE_SUCCESS",
  RENAME_SHOOT_TYPE_ERROR = "RENAME_SHOOT_TYPE_ERROR"
}
