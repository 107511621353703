import React from "react";
import classNames from "classnames";
import styles from "../SprintSizes.module.scss";
import { ICurrency } from "../../../models/models";
import { formatPriceByCurrency } from "utilities/currency";
import { isEmpty, max, min } from "lodash";
import { Unit, attachUnitForSFF, formatWithUnit } from "utilities/unit";

interface ISprintSizesProps {
  isActive?: boolean;
  height: number;
  width: number;
  walletSet?: number | null;
  id: string;
  fromPrice?: number;
  defaultWidth?: number;
  currency?: ICurrency;
  name?: string;
  isSFF?: boolean;
  unit: Unit;
}

class SprintSizeItem extends React.PureComponent<ISprintSizesProps> {
  get displayName() {
    const { width, height, walletSet, name, unit, isSFF } = this.props;
    let displayName = name || "";
    if (isEmpty(displayName)) {
      if (walletSet) {
        displayName = `${walletSet} Up Wallets`;
      } else {
        displayName = `${width} x ${height}`;
      }
    }
    return `${isSFF ? attachUnitForSFF(displayName, unit, width, height) : formatWithUnit(displayName, width, height, unit)}`;
  }

  public render() {
    const { width, height, walletSet, fromPrice, defaultWidth, currency } = this.props;
    const defaultWidthValue = defaultWidth || 40;
    const ratio = width / height;
    const priceClassNames = fromPrice ? styles.price : styles.hiddenPrice;
    const longestDimension = max([height, width]) || 0;
    const shortestDimension = min([height, width]) || 0;
    const isLandscapeFormat = longestDimension / shortestDimension >= 2;
    const printStyle = {
      width: ratio >= 1 ? `${defaultWidthValue}px` : `${(width * defaultWidthValue) / height}px`,
      height: ratio < 1 ? `${defaultWidthValue}px` : `${(height * defaultWidthValue) / width}px`
    } as React.CSSProperties;

    return (
      <div className={classNames(styles.sprintContent)}>
        <div
          className={classNames(styles.sprintLayout, {
            [styles.landscape]: isLandscapeFormat
          })}
        >
          {!walletSet ? (
            <div className={classNames(styles.sprintElm)} style={printStyle} />
          ) : (
            <div
              style={{ width: `${defaultWidthValue}px`, height: `${defaultWidthValue}px` }}
              className={classNames(styles.wallet)}
            >
              <div className={classNames(styles.layer)} />
              <div className={classNames(styles.layer)} />
              <div className={classNames(styles.layer)} />
              <div className={classNames(styles.layer)} />
            </div>
          )}
        </div>
        <label className={classNames(styles.sprintInfo)}>{this.displayName}</label>
        <label className={classNames(styles.sprintDes, priceClassNames)}>
          {fromPrice ? `from ${formatPriceByCurrency(fromPrice.toFixed(2), currency?.symbol)}` : "None"}
        </label>
      </div>
    );
  }
}

export default SprintSizeItem;
