import React from "react";
import styles from "./crossFadeCarousel.module.scss";

export interface IScrollCarouselProps {
  controlHeight: number;
  navbarHeight: number;
  controlHeightStyle: any;
  selectedItem: number;
  interval: number;
  infiniteLoop?: boolean;
  autoPlay?: boolean;
  isEditionView?: boolean;
}

const CrossFadeCarousel: React.FC<IScrollCarouselProps> = props => {
  return (
    <React.Fragment>
      <style>
        {`
            :root {
              --animationDelay: ${`${
                props.children ? React.Children.count(props.children) * (props.interval / 1000) : 0
              }s`}
              }
            `}
      </style>
      <div className={styles.zenContainerscrol}>
        <ul className={!props.isEditionView ? styles.unOrderList : styles.unOrderListEdition}>{props.children}</ul>
      </div>
    </React.Fragment>
  );
};

export default CrossFadeCarousel;
