import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import DetailsStep from "../../../../components/widget/steps/details";
import { IAppState } from "../../../../store/state";

function mapStateToProps({ widget }: IAppState) {
  return {
    photographerName: widget.loadWidgetInfo.photographerName
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsStep);
