import * as React from "react";
import { useResizeDetector } from "react-resize-detector";
import CoreThumbnail, { ImagePlacement } from "../../../components/Video/Thumbnail";
import { IThumbnailBase, MESizeCodes, MESizeMethod } from "utilities/getImgUrl";
import { convertThumbnailProps } from "../utils";

export type Overlay = {
  /**
   * Specifies whether to show the duration. *true* by default.
   */
  duration?: boolean;
  /**
   * Specifies the size of the play button in pixels. 54 by default.
   */
  playButtonSize?: number;
};

export interface IThumbnailProps {
  thumbnailUrlTemplate: string;
  thumbnail: IThumbnailBase;
  /**
   * Specifies how image should be positioned inside container. *"cover"* by default.
   */
  imagePlacement?: ImagePlacement;
  /**
   * Specifies whether to show the play button. You can customize its size in *overlay*.
   * It also determines which overlay elements are shown by default.
   */
  playable?: boolean;
  /**
   * Overlay configuration.
   */
  overlay?: Overlay;
  /**
   * Forced size code. If not specified, then the size code is automatically (re-)calculated
   * based on component's size.
   */
  forcedSizeCode?: MESizeCodes;
  /**
   * Forces size method. If not specified, then it's derived from *imagePlacement*.
   */
  forcedSizeMethod?: MESizeMethod;
  className?: string;
  style?: React.CSSProperties;
  /**
   * Called when the thumbnail is clicked. If you also specify *onPlay*, then clicks on the play button will not
   * execute this callback.
   */
  onClick?: () => void;
  /**
   * Called when the play button is clicked. If specified, then clicks on the play button will not execute *onClick*.
   */
  onPlay?: () => void;
  /**
   * Called every time the image is loaded.
   */
  onImageLoad?: () => void;
  /**
   * Called every time an error occurs when loading the image.
   */
  onImageError?: () => void;
}

/**
 * NextZen Video Thumbnail component. Adapts core *Thumbnail* component for NextZen needs. Integrates with NextZen
 * data objects, sets some defaults that match NextZen use cases and supports automatically (re-)calculating
 * image source based on component's size.
 */
function Thumbnail(props: IThumbnailProps) {
  const { imagePlacement, playable, className, style, onClick, onPlay, onImageLoad, onImageError } = props;

  const { width: containerWidth, height: containerHeight, ref: containerRef } = useResizeDetector();

  const { imageSrc, processing, overlay } = convertThumbnailProps({ ...props, containerWidth, containerHeight });

  return (
    <CoreThumbnail
      imageSrc={imageSrc}
      processing={processing}
      imagePlacement={imagePlacement}
      overlay={overlay}
      playable={playable}
      containerRef={containerRef}
      className={className}
      style={style}
      onClick={onClick}
      onPlay={onPlay}
      onImageLoad={onImageLoad}
      onImageError={onImageError}
    />
  );
}

export default Thumbnail;
