import classNames from "classnames";
import {
  getInputMax,
  IComponentBaseProps,
  isDecrementDisabled,
  isIncrementDisabled,
  resolveValue
} from "components/NumericInputs/utils";
import { isOldEdge } from "utilities/responsive";
import React from "react";
import { Utilities } from "utilities/utilities";
import styles from "./fixedLengthNumberUnit.module.scss";

export type ChangeType = "editor" | "committed" | "filled" | "increment" | "decrement";

interface IFixedLengthNumberUnitProps extends IComponentBaseProps<HTMLInputElement> {
  length: number;
  defaultValue?: number | null;
  value?: number | null;
  min?: number;
  max?: number;
  step?: number;
  incrementDisabled?: boolean;
  decrementDisabled?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  formatter?: (value: number | null) => string;

  onChange?: (value: number | null, type: ChangeType) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface IFixedLengthNumberUnitState {
  value?: number | null;
  inputValue?: number | null;
  focused: boolean;
  position?: number;
}

class FixedLengthNumberUnit extends React.Component<IFixedLengthNumberUnitProps, IFixedLengthNumberUnitState> {
  public state: IFixedLengthNumberUnitState = { focused: false };

  public shouldComponentUpdate(
    nextProps: IFixedLengthNumberUnitProps,
    nextState: IFixedLengthNumberUnitState
  ): boolean {
    return Utilities.shouldComponentUpdateDeep(this, nextProps, nextState);
  }

  public render() {
    const { containerRef, style, className, disabled, length, tabIndex } = this.props;

    return (
      <input
        type="text"
        value={this.formatValue(this.value)}
        disabled={disabled}
        min={this.min}
        maxLength={length}
        tabIndex={tabIndex}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        onChange={this.onChange} // no-op function to fix warning
        className={classNames(styles.container, isOldEdge && styles.oldEdge, className)}
        style={{ width: length * 11, ...style }}
        ref={containerRef}
      />
    );
  }

  private get value() {
    return resolveValue(this.state.inputValue, this.props.value, this.state.value, this.props.defaultValue, null);
  }

  private get max() {
    return getInputMax(this.props.max, this.props.length);
  }

  private get min() {
    return this.props.min || 0;
  }

  private onChange = () => undefined;

  private onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ focused: true, position: 0, inputValue: undefined });
    this.props.onFocus?.(event);
  };

  private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = Utilities.limitNumber(this.value, this.min, this.max);
    this.setState(
      {
        value: this.props.value === undefined ? value : undefined,
        focused: false,
        position: undefined,
        inputValue: undefined
      },
      () => this.props.onChange?.(value, "committed")
    );
    this.props.onBlur?.(event);
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const digit = parseInt(event.key, 10);

    if (event.key === "Backspace" || event.key === "Delete") {
      this.setState({ value: this.props.value === undefined ? null : undefined, inputValue: null, position: 0 }, () =>
        this.props.onChange?.(null, "editor")
      );
    } else if (event.key === "ArrowUp") {
      this.increment();
    } else if (event.key === "ArrowDown") {
      this.decrement();
    } else if (!isNaN(digit)) {
      const position = this.state.position!;
      const value = position === 0 ? 0 : this.value;
      const newPosition = position + 1;
      const newValue = (value || 0) * 10 + digit;

      if (newPosition === this.props.length) {
        const limitedValue = Utilities.limitNumber(newValue, this.min, this.max);
        this.setState(
          { value: this.props.value === undefined ? limitedValue : undefined, inputValue: undefined, position: 0 },
          () => this.props.onChange?.(limitedValue, "filled")
        );
      } else {
        this.setState(
          {
            value: this.props.value === undefined ? newValue : undefined,
            inputValue: newValue,
            position: newPosition
          },
          () => this.props.onChange?.(newValue, "editor")
        );
      }
    } else {
      this.props.onKeyDown?.(event);
    }

    const isCharacter = event.key.length === 1; // probably not 100% reliable
    if (isCharacter) {
      event.preventDefault();
    }
  };

  private increment() {
    const { value, step, incrementDisabled, onChange } = this.props;

    if (!isIncrementDisabled(this.value, step, this.max, incrementDisabled)) {
      const newValue = Utilities.limitNumber((this.value || 0) + (step || 1), this.min, this.max);
      this.setState({ value: value === undefined ? newValue : undefined, inputValue: undefined, position: 0 }, () =>
        onChange?.(newValue, "increment")
      );
    }
  }

  private decrement() {
    const { value, step, decrementDisabled, onChange } = this.props;

    if (!isDecrementDisabled(this.value, step, this.min, decrementDisabled)) {
      const newValue = Utilities.limitNumber((this.value || 0) - (step || 1), this.min, this.max);
      this.setState({ value: value === undefined ? newValue : undefined, inputValue: undefined, position: 0 }, () =>
        onChange?.(newValue, "decrement")
      );
    }
  }

  private formatValue(value: number | null) {
    return this.props.formatter
      ? this.props.formatter(value)
      : (value || 0).toString().padStart(this.props.length, "0");
  }
}

export default FixedLengthNumberUnit;
