import React from "react";

import styles from "./preview.module.scss";
import iconCloseX from "../../icons/icon-close-x.svg";
import iconCloseXWhite from "../../icons/icon-close-x-white.svg";
import { isMobile } from "react-device-detect";
import cx from "classnames";
import { formatPriceByCurrencyCode } from "utilities/currency";
import { PhotoOrVideo } from "../../../../../../models/models";
import get from "lodash/get";
import { getOverridedPrice } from "utilities/pricingPhoto";
import { ILightboxZoomImage } from "components/LightboxZoom";

export interface IQuickShopProps {
  items: IQuickShopItem[];
  media: PhotoOrVideo | ILightboxZoomImage;
  imageUrl?: string;
  onClickShopAllProduct: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClickAddItem: (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo | ILightboxZoomImage) => void;
  onCloseQuickShop?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  isGIFPhoto?: boolean;
}

export interface IQuickShopItem {
  id: string;
  digitalProductId: string;
  physicalProductId: string;
  name: string;
  price: number;
  isDigital: boolean;
  unit: string;
  rank: number;
  currency: string;
  pricingId: string;
  categoryId: string;
  isSFF: boolean;
  selectedOptions: any[];
  categoryName?: string;
}

export enum PRINT_IDS {
  LargeFormatPrintsCategoryId = "fe2a3e05-7b65-49e8-b1db-1409add78bc8",
  SquarePrintsCategoryId = "7c1e0e83-f927-4e79-b177-c5c704086542",
  PhotoPrintsCategoryId = "c07ce7f0-843c-4fd1-9207-b2a2df74cebe"
}

export enum WALL_ART_IDS {
  FramedPrintCategoryId = "3f431fe7-7110-4e7f-8449-f90d1f58e1e9",
  MetalPrintCategoryId = "e7804891-2044-4652-ae40-b93d05754d90",
  CanvasCategoryId = "7f3e0996-d0de-4df0-89af-12da5dcdb05b"
}

export const MAPPING_ID_TYPE = {
  [WALL_ART_IDS.FramedPrintCategoryId.toString()]: "Framed Print",
  [PRINT_IDS.LargeFormatPrintsCategoryId.toString()]: "Print",
  [PRINT_IDS.SquarePrintsCategoryId.toString()]: "Print",
  [PRINT_IDS.PhotoPrintsCategoryId.toString()]: "Print",
  [WALL_ART_IDS.MetalPrintCategoryId.toString()]: "Metal Print",
  [WALL_ART_IDS.CanvasCategoryId.toString()]: "Canvas"
};

export const createQuickshopProductName = (name: string, categoryId: string, categoryName?: string) => {
  return `${name} ${categoryName || get(MAPPING_ID_TYPE, categoryId, "Print")}`;
};

class QuickShopPreview extends React.Component<IQuickShopProps> {
  constructor(props: any) {
    super(props);
  }

  public state = {
    animatedOpen: true,
    animatedClose: false
  };

  private onClose = (e: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ animatedClose: true });
    setTimeout(() => {
      const { onCloseQuickShop } = this.props;
      onCloseQuickShop && onCloseQuickShop(e);
    }, 500);
  };

  private closeQuickShop = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onCloseQuickShop } = this.props;
    onCloseQuickShop && onCloseQuickShop(e);
  };

  private addItemToCart = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onClickAddItem, media } = this.props;
    onClickAddItem(e, media);
  };

  private clickShopAllProduct = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onClickShopAllProduct } = this.props;
    onClickShopAllProduct(e);
  };

  private renderQuickShopItemName = (item: IQuickShopItem) => {
    const { isDigital, name } = item;
    if (isDigital) {
      return "Digital Download " + this.mappingQuickShopItemResolutionName(name);
    }
    return createQuickshopProductName(item.name, item.categoryId, item.categoryName);
  };

  private mappingQuickShopItemResolutionName = (name: string) => {
    if (name.includes("Original")) {
      return "(Original Res)";
    }
    return `(${name} Res)`;
  };

  public onOverlayClick = (evt: React.BaseSyntheticEvent) => {
    if (!evt || !evt.target) {
      return;
    }

    const target = evt.target as Element;
    if (!target.classList.contains(styles.modalOverlay)) {
      return;
    }

    this.closeQuickShop(evt as React.MouseEvent<HTMLDivElement>);
  };

  render() {
    const { items, imageUrl = "", className, isGIFPhoto, media } = this.props;
    const quickShopItems = items.filter(item => !isGIFPhoto || item.isDigital);

    return (
      <div
        className={styles.modalOverlay}
        style={isMobile ? { height: `${window.innerHeight}px` } : {}}
        onMouseDown={this.onOverlayClick}
        onTouchStart={this.onOverlayClick}
      >
        <div className={styles.preview}>
          <div className={cx(styles.quickShopPreview, className)}>
            <label>
              QUICK SHOP
              <div className={styles.closeXWrapper} onClick={this.closeQuickShop}>
                <img className={styles.closeXBtn} src={isMobile ? iconCloseXWhite : iconCloseX} alt="close-x" />
              </div>
            </label>
            {<img className={styles.quickShopImgPreview} src={imageUrl} alt="preview" />}
            <div className={styles.diver} />
            {quickShopItems.map((x, idx) => (
              <div className={styles.item} key={idx} id={x.id} onClick={this.addItemToCart}>
                <label>{this.renderQuickShopItemName(x)}</label>
                <div className={styles.itemDetail}>
                  <label>{formatPriceByCurrencyCode(getOverridedPrice(x, media), x.currency)}</label>
                  <div className={styles.btnAdd}>Add</div>
                </div>
              </div>
            ))}

            <div className={styles.btn} onClick={this.clickShopAllProduct}>
              SHOP ALL PRODUCTS
            </div>
            <div className={styles.footer}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuickShopPreview;
