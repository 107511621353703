import { Utilities } from "@zenfolio/core-components";
import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

export type AmountType = "total" | "paid" | "remaining" | "available-refund" | "service-amount";

interface IAmountProps {
  type: AmountType;
  value?: number;
  className?: string;
}

class Amount extends React.Component<IAmountProps> {
  public render() {
    const { type } = this.props;

    switch (type) {
      case "total":
        return this.renderImpl("Service Amount", styles.main);
      case "available-refund":
        return this.renderImpl("Refund Amount Available", styles.main);
      case "paid":
        return this.renderImpl("Amount Paid", styles.paid);
      case "remaining":
        return this.renderImpl("Remaining Amount Due", styles.remaining);
      case "service-amount":
        return this.renderImpl("Service Amount", styles.main);
      default:
        return null;
    }
  }

  private renderImpl(label: string, typeClassName?: string) {
    const { value, className } = this.props;

    return (
      <div className={classNames(styles.container, className, typeClassName)}>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>
          {value !== undefined ? Utilities.formatFractionalNumberWithCurrency(value) : "\u00A0"}
        </div>
      </div>
    );
  }
}

export default Amount;
