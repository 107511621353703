import React from "react";
import IIConProps from "../IIconProps";

export const IconBed = (props: IIConProps) => {
  const width = props.width || 56;
  const height = props.height || 56;
  const fill = props.fill || "#FFF";
  const color = props.color || "#000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <filter id="nfx8t86jwa" width="127.1%" height="127.1%" x="-13.5%" y="-11.5%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        </filter>
        <circle id="g0ueo2rrfb" cx="24" cy="24" r="24" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-470 -565) translate(346 568) translate(128)">
                <use fill={fill} filter="url(#nfx8t86jwa)" xlinkHref="#g0ueo2rrfb" />
                <use fill={fill} xlinkHref="#g0ueo2rrfb" />
              </g>
              <path
                fill={color}
                d="M35 28c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1h-1c-.552 0-1-.448-1-1v-1H15v1c0 .552-.448 1-1 1h-1c-.552 0-1-.448-1-1v-3c0-.552.448-1 1-1h22zm-3-6c2.21 0 4 1.79 4 4v1H12v-1c0-2.21 1.79-4 4-4h16zm2-7c1.105 0 2 .895 2 2v7c0-1.598-1.249-2.904-2.824-2.995L33 21l-.005-.15c-.073-.982-.856-1.768-1.838-1.844L31 19h-4c-1.054 0-1.918.816-1.995 1.85L25 21h-2c0-1.054-.816-1.918-1.85-1.995L21 19h-4c-1.054 0-1.918.816-1.995 1.85L15 21c-1.598 0-2.904 1.249-2.995 2.824L12 24v-7c0-1.105.895-2 2-2h20z"
                transform="translate(-470 -565) translate(346 568) translate(128)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconSofaOn = (props: IIConProps) => {
  const width = props.width || 56;
  const height = props.height || 56;
  const fill = props.fill || "#FFF";
  const color = props.color || "#000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <filter id="cxugch0oia" width="127.1%" height="127.1%" x="-13.5%" y="-11.5%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        </filter>
        <circle id="1hpzr8da1b" cx="24" cy="24" r="24" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g fill={fill} transform="translate(-342 -565) translate(346 568)">
              <use filter="url(#cxugch0oia)" xlinkHref="#1hpzr8da1b" />
              <use xlinkHref="#1hpzr8da1b" />
            </g>
            <path
              fill={color}
              d="M11 29v2c0 .552-.448 1-1 1s-1-.448-1-1v-2H8c-.552 0-1-.448-1-1v-5c0-.552.448-1 1-1h1c.552 0 1 .448 1 1v2h28v-2c0-.552.448-1 1-1h1c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1h-1v2c0 .552-.448 1-1 1s-1-.448-1-1v-2H11zm28-12c.552 0 1 .448 1 1v3h-2c-.513 0-.936.386-.993.883L37 22v2h-6c-.552 0-1-.448-1-1v-5c0-.552.448-1 1-1h8zm-22 0c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1h-6v-2l-.007-.117c-.057-.497-.48-.883-.993-.883H8v-3c0-.552.448-1 1-1zm11 0c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1h-8c-.552 0-1-.448-1-1v-5c0-.552.448-1 1-1h8z"
              transform="translate(-342 -565) translate(346 568)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconDesk = (props: IIConProps) => {
  const width = props.width || 56;
  const height = props.height || 56;
  const fill = props.fill || "#FFF";
  const color = props.color || "#000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <filter id="d3meyf1zua" width="127.1%" height="127.1%" x="-13.5%" y="-11.5%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        </filter>
        <circle id="hzx79697tb" cx="24" cy="24" r="24" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-406 -565) translate(346 568) translate(64)">
                <use fill={fill} filter="url(#d3meyf1zua)" xlinkHref="#hzx79697tb" />
                <use fill={fill} xlinkHref="#hzx79697tb" />
              </g>
              <path
                fill={color}
                d="M28 13c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1h-3v2h12c.552 0 1 .448 1 1s-.448 1-1 1h-2v9c0 .552-.448 1-1 1s-1-.448-1-1v-9H15v9c0 .552-.448 1-1 1s-1-.448-1-1v-9h-2c-.552 0-1-.448-1-1s.448-1 1-1h12v-2h-3c-.552 0-1-.448-1-1v-5c0-.552.448-1 1-1h8z"
                transform="translate(-406 -565) translate(346 568) translate(64)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconBookCase = (props: IIConProps) => {
  const width = props.width || 56;
  const height = props.height || 56;
  const fill = props.fill || "#FFF";
  const color = props.color || "#000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <filter id="9e1f6ugjta" width="127.1%" height="127.1%" x="-13.5%" y="-11.5%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        </filter>
        <circle id="fiydssd9qb" cx="24" cy="24" r="24" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g fill={fill} transform="translate(-342 -565) translate(346 568)">
              <use filter="url(#9e1f6ugjta)" xlinkHref="#fiydssd9qb" />
              <use xlinkHref="#fiydssd9qb" />
            </g>
            <path
              fill={color}
              d="M33 8c.552 0 1 .448 1 1v27c0 .552-.448 1-1 1s-1-.448-1-1v-2H16v2c0 .552-.448 1-1 1s-1-.448-1-1V9c0-.552.448-1 1-1h18zm-1 18H16v6h10v-3.5c0-.276.224-.5.5-.5s.5.224.5.5V32h.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4H32v-6zm0-8H16v6h16v-6zm0-8H16v6l2.268.001C18.098 15.707 18 15.365 18 15v-3h4v3c0 .365-.098.707-.268 1.001L32 16v-6z"
              transform="translate(-342 -565) translate(346 568)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconWritingDesk = (props: IIConProps) => {
  const width = props.width || 56;
  const height = props.height || 56;
  const fill = props.fill || "#FFF";
  const color = props.color || "#000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <filter id="wvsjgoe8da" width="127.1%" height="127.1%" x="-13.5%" y="-11.5%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        </filter>
        <circle id="ghw51nz2rb" cx="24" cy="24" r="24" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-406 -565) translate(346 568) translate(64)">
                <use fill={color} filter="url(#wvsjgoe8da)" xlinkHref="#ghw51nz2rb" />
                <use fill={fill} xlinkHref="#ghw51nz2rb" />
              </g>
              <path
                fill={color}
                d="M24.544 10.533l1.035 3.864c.143.534-.174 1.082-.707 1.225l-1.932.518c-.534.143-1.082-.174-1.225-.708l-.508-1.897c-.15.038-.31.042-.47.005l-.976-.226L17.987 21H21c.552 0 1 .448 1 1h8.268c-.17-.294-.268-.636-.268-1v-3h.755l-.218-1.385c-.043-.273.143-.529.415-.572.273-.043.53.143.573.416l.243 1.54h.297l.357-2.54c.039-.273.292-.463.565-.425.274.038.464.291.426.565l-.338 2.4L34 18v3c0 .364-.097.706-.268 1H37c.552 0 1 .448 1 1s-.448 1-1 1h-2v9c0 .552-.448 1-1 1s-1-.448-1-1v-9H15v9c0 .552-.448 1-1 1s-1-.448-1-1v-9h-2c-.552 0-1-.448-1-1s.448-1 1-1h2c0-.552.448-1 1-1h1.994c-.013-.11-.007-.225.02-.34l2.024-8.77c.106-.46.516-.773.97-.775l.114.006c.038.005.077.011.115.02l1.419.328-.01-.046c-.194-1.02.43-2.03 1.448-2.304 1.067-.286 2.164.348 2.45 1.414z"
                transform="translate(-406 -565) translate(346 568) translate(64)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconFirePlace = (props: IIConProps) => {
  const width = props.width || 56;
  const height = props.height || 56;
  const fill = props.fill || "#FFF";
  const color = props.color || "#000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <filter id="29lfkmx86a" width="127.1%" height="127.1%" x="-13.5%" y="-11.5%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        </filter>
        <circle id="6bq6hk3r3b" cx="24" cy="24" r="24" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-470 -565) translate(346 568) translate(128)">
                <use fill={color} filter="url(#29lfkmx86a)" xlinkHref="#6bq6hk3r3b" />
                <use fill={fill} xlinkHref="#6bq6hk3r3b" />
              </g>
              <path
                fill={color}
                d="M36 17v17h-4V22c0-.513-.386-.936-.883-.993L31 21H17c-.513 0-.936.386-.993.883L16 22v12h-4V17h24zm-10 7c.373 0 .32.194.16.52l-.113.228c-.38.763-.914 2.008.953 3.252 2 1.333 2.333 3.333 1 6h-8c-2-.667-2-3 0-7 .667 2 1.667 3 3 3-1.333-3.333-.333-5.333 3-6zm12-12c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1H10c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28z"
                transform="translate(-470 -565) translate(346 568) translate(128)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
