import React, { PureComponent, ReactNode } from "react";
import cx from "classnames";
import { IService } from "../index";
import styles from "./styles.module.scss";
import TextInput from "components/TextInput";
import PhoneInput from "components/PhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Validation } from "utilities/validation";
import enhanceWithClickOutside from "react-click-outside";
import { getFontColor, getBackgroundColor, getPlaceholderColor } from "utilities/blocks/blockColors";
import ZenButton from "../../zenButton";
import { IZenSiteTheme } from "../../../../models/models";
import colors from "utilities/colors";
import { getSiteFonts } from "utilities/blocks/site";

interface IContactFormProps {
  siteTheme: IZenSiteTheme;
  value: IService;
  packTitle: string;
  accentColor: string;
  subdomain?: string;
  backgroundColor: string;
  studioName: string;
  layout: string;
  international?: boolean;
  countryCode?: string;
  onSendButtonClick: (formData: any, onSuccess: () => void, onError: (error: any) => void) => void;
  onClose: () => void;
  renderLineBreaks: (text: string) => ReactNode;
}

interface IContactFormState {
  name: string;
  email: string;
  phoneNumber: string;
  comment: string;
  nameError: string;
  emailError: string;
  commentError: string;
  phoneNumberError: string;
  showThankYou: boolean;
}

class ContactForm extends PureComponent<IContactFormProps, IContactFormState> {
  constructor(props: IContactFormProps) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comment: `Hi ${props.studioName} \nI am interested in booking the ${props.packTitle} for your ${props.value.category}.`,
      nameError: "",
      emailError: "",
      commentError: "",
      phoneNumberError: "",
      showThankYou: false
    };
  }

  public handleClickOutside = () => {
    this.props.onClose();
  };

  private handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value });
  };

  private handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  private handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ comment: e.target.value });
  };

  private handleNameBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.state.name === "") {
      this.setState({ nameError: "Please enter your name" });
      return;
    }

    this.setState({ nameError: "" });
  };

  private handleEmailBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.state.email === "") {
      this.setState({ emailError: "Please enter your email" });
      return;
    }

    if (!Validation.isValidMail(this.state.email.trim())) {
      this.setState({ emailError: "Please enter a valid email" });
      return;
    } else {
      const emailArray = this.state.email.split("@");
      if (emailArray[0].length > 64) {
        this.setState({ emailError: "Please enter a valid email" });
        return;
      }

      if (emailArray[1].length > 255) {
        this.setState({ emailError: "Please enter a valid email" });
        return;
      }
    }

    this.setState({ emailError: "" });
  };

  private handlePhoneChange = (value: string) => {
    this.setState({ phoneNumber: value });
  };

  private handleCommentBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (this.state.comment === "") {
      this.setState({ commentError: "Please enter your comment" });
      return;
    }

    this.setState({ commentError: "" });
  };

  private handlePhoneBlur = () => {
    const { phoneNumber } = this.state;
    if (phoneNumber === "" || phoneNumber === undefined || phoneNumber === null) {
      this.setState({ phoneNumberError: "" });
    } else if (!isValidPhoneNumber(phoneNumber)) {
      this.setState({ phoneNumberError: "Please enter a valid phone number" });
    } else {
      this.setState({ phoneNumberError: "" });
    }
  };

  private getFormData = () => {
    const data: any = {
      serviceName: this.props.value.category,
      packageTitle: this.props.packTitle,
      name: this.state.name,
      email: this.state.email,
      comment: this.state.comment,
      extraInformation: {},
      sendToEmail: this.props.value.sendTo
    };

    if (this.state.phoneNumber !== "" && this.state.phoneNumber !== undefined && this.state.phoneNumber !== null) {
      data.extraInformation.phoneNumber = this.state.phoneNumber;
    }

    return data;
  };

  private handleButtonClick = () => {
    const { onSendButtonClick } = this.props;
    if (this.state.showThankYou) {
      this.props.onClose();
    } else if (onSendButtonClick) {
      onSendButtonClick(
        this.getFormData(),
        () => this.setState({ showThankYou: true }),
        (error: any) => console.log(error)
      );
    }
  };

  public onOverlayClick = () => {
    this.props.onClose();
  };
  public onDialogClick = (evt: any) => {
    evt.stopPropagation();
  };

  public listenKeyboard = (evt: any) => {
    if (evt.key === "Escape" || evt.keyCode === 27) {
      this.props.onClose();
    }
  };

  public componentWillUnmount() {
    window.removeEventListener("keydown", this.listenKeyboard, true);
  }

  public componentDidMount() {
    window.addEventListener("keydown", this.listenKeyboard, true);
  }

  public render() {
    const {
      value,
      onClose,
      accentColor,
      renderLineBreaks,
      backgroundColor,
      layout,
      siteTheme,
      international,
      countryCode
    } = this.props;
    const {
      name,
      email,
      phoneNumber,
      comment,
      nameError,
      emailError,
      commentError,
      phoneNumberError,
      showThankYou
    } = this.state;
    const isButtonDisabled =
      name === "" || email === "" || comment === "" || nameError !== "" || emailError !== "" || commentError !== "";
    const isDarkBackground: boolean = backgroundColor === colors.inputDark || backgroundColor === colors.black;
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    return (
      <div className={cx(styles.overlay, layout === "B" ? styles.layoutB : "")} onMouseDown={this.onOverlayClick}>
        <style>
          {`
            :root {
              --input-background: ${getBackgroundColor(backgroundColor)};
              --placeholder-color: ${getPlaceholderColor(backgroundColor)}
              }
            `}
        </style>
        <div
          className={cx(styles.container, layout === "B" ? styles.layoutB : "", styles[siteFonts.primary])}
          onMouseDown={this.onDialogClick}
        >
          <div className={styles.header} style={{ backgroundColor: isDarkBackground ? colors.black : colors.white }}>
            <p className={styles.title} style={{ color: getFontColor(backgroundColor) }}>
              {value.buttonLabel}
            </p>
            <div className={styles.closeContainer} onClick={onClose}>
              <div className={styles.close} style={{ backgroundColor: getFontColor(backgroundColor) }} />
            </div>
          </div>
          {showThankYou ? (
            <div className={cx(styles.thankContainer)}>
              <div className={styles.thank} style={{ backgroundColor: backgroundColor }}>
                <div className={styles.title} style={{ color: accentColor }}>
                  {value.confirmationTitle}
                </div>
                <div className={styles.body} style={{ color: getFontColor(backgroundColor) }}>
                  {renderLineBreaks(value.confirmationBody)}
                </div>
              </div>
            </div>
          ) : (
            <div className={cx(styles.form, styles[siteFonts.secondary])} style={{ backgroundColor: backgroundColor }}>
              <TextInput
                value={name}
                height={46}
                className={cx(styles.input, nameError !== "" ? styles.error : "", {
                  [styles.gray]: backgroundColor === colors.black
                })}
                placeholder={"Name *"}
                maxLength={30}
                onChange={this.handleNameChange}
                onBlur={this.handleNameBlur}
              />
              <div className={styles.errorMsg}>{nameError}</div>
              <TextInput
                value={email}
                height={46}
                className={cx(styles.input, emailError !== "" ? styles.error : "", {
                  [styles.gray]: backgroundColor === colors.black
                })}
                placeholder={"Email *"}
                maxLength={320}
                onChange={this.handleEmailChange}
                onBlur={this.handleEmailBlur}
              />
              <div className={styles.errorMsg}>{emailError}</div>
              <PhoneInput
                className={styles.phone}
                inputClassName={cx(phoneNumberError !== "" ? styles.error : "", {
                  [styles.gray]: backgroundColor === colors.black
                })}
                placeholder="Phone Number"
                value={phoneNumber}
                numberError={phoneNumberError}
                international={international}
                defaultCountry={countryCode}
                onChangeNumber={this.handlePhoneChange}
                onBlur={this.handlePhoneBlur}
              />
              <textarea
                className={cx(styles.textArea, commentError !== "" ? styles.error : "", {
                  [styles.gray]: backgroundColor === colors.black
                })}
                value={comment}
                maxLength={500}
                placeholder="Your message"
                onChange={this.handleCommentChange}
                onBlur={this.handleCommentBlur}
              />
              <div className={styles.errorMsg}>{commentError}</div>
            </div>
          )}
          <div className={styles.footer} style={{ backgroundColor: isDarkBackground ? colors.black : colors.white }}>
            <ZenButton
              className={layout === "B" ? styles.buttonLayoutB : ""}
              onClick={this.handleButtonClick}
              disabled={isButtonDisabled}
              isDarkBackground={isDarkBackground}
              siteTheme={siteTheme}
              layout={"A"}
              labelText={showThankYou ? "Close" : "Send"}
            ></ZenButton>
          </div>
        </div>
      </div>
    );
  }
}

export default enhanceWithClickOutside(ContactForm);
