import React from "react";

export const isNullOrUndefined = (str: string | undefined | null, defaultStr: string) => {
  return str !== undefined && str != null ? str : defaultStr;
};

export const renderLineBreaks = (text: string, outsideSubBlock?: boolean) => {
  return (
    <React.Fragment>
      {text.split("\n").map((i, key) => {
        return (
          <div key={key} data-outside-subblock={outsideSubBlock ? outsideSubBlock : false}>
            {i === "" ? <br /> : i}
          </div>
        );
      })}
    </React.Fragment>
  );
};

export function padNumber(num: number, size: number): string {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

// body prop will come encoded so it can keep newlines defined in Editor,
// we must decode it before using it
export const parseBody = (body: string) => {
  if (!body) {
    return body;
  }

  // We also replace any singular % for %25 to avoid URI malformed errors
  return decodeURIComponent(body.replace(/%(?![0-9a-fA-F][0-9a-fA-F]+)/gi, "%25"));
};

export const stringIsGuid = (value: string): boolean => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value);
};

export const parseHtmlInnerJsonStringExt = (str: string) => {
  return JSON.parse(decodeURIComponent(str));
};
