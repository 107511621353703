// tslint:disable: jsx-no-lambda
import React, { PureComponent } from "react";
import {
  ICompleteViewCommonProps,
  NameType,
  NameVisibility,
  siteThemeDefault
} from "../../../../../../components/blocks/blockModels";
import {
  GalleryPresentSettingList,
  GalleryPresentSettingType,
  ICartItem,
  IUnreadComment,
  PreviewModes
} from "../../../../../../components/ClientView/models";

import cx from "classnames";
import { ZenMasonryBlock } from "components/blocks/zenMasonryBlock";
import { ZenMasonryLandscapeBlock } from "components/blocks/zenMasonryLandscapeBlock";
import CartMediaIcon from "components/Icons/ClientView/CartMedia";
import CommentMediaIcon from "components/Icons/ClientView/CommentMedia";
import DownloadMediaIcon from "components/Icons/ClientView/DownloadMedia";
import FavoriteMediaIcon from "components/Icons/ClientView/FavoriteMedia";
import isEqual from "lodash/isEqual";
import { isShoppingAllowedForMedia, isVideo } from "utilities/helpers";
import { isMobile, isMobileOnly } from "react-device-detect";
import colors from "utilities/colors";
import { isGIFPhoto } from "utilities/image";
import { Utilities } from "utilities/utilities";
import { PhotoOrVideo } from "../../../../../../models/models";
import CartIcon from "../Media/icons/icon-cart.svg";
import QuickShopPreview, { IQuickShopItem } from "../QuickShop/Preview";
import styles from "./media.module.scss";
import MediaOverlayActions from "./MediaOverlayActions";

const brakePoints = [400, 500, 600, 700, 1440];

interface IMediaProps extends ICompleteViewCommonProps {
  allMedia: PhotoOrVideo[];
  gridTypeId?: GalleryPresentSettingList;
  allowPhotoDownload: boolean;
  allowVideoDownload: boolean;
  allowMediaCommenting?: boolean;
  peopleFilteredPhotos: string[];
  bgColor: string;
  cartItems?: ICartItem[];
  previewMode?: PreviewModes;
  quickShopItems?: IQuickShopItem[];
  onClickQuickShopItem?: (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => void;
}

interface IMediaState {
  cartItems?: ICartItem[];
  photosInCart: string[];
  videosInCart: string[];
  mediaBadges: (JSX.Element | null)[];
  itemsPerRow: number;
  isRenderQuickShop: boolean;
  selectedIndex: number;
}

class Media extends PureComponent<IMediaProps, IMediaState> {
  constructor(props: any) {
    super(props);

    this.state = {
      cartItems: props.cartItems || [],
      photosInCart: [],
      videosInCart: [],
      itemsPerRow: 0,
      mediaBadges: [],
      isRenderQuickShop: false,
      selectedIndex: -1
    };
  }

  public componentDidMount() {
    this.setState({
      photosInCart: Utilities.getAllCartPhotos(this.props.cartItems || []),
      videosInCart: Utilities.getAllCartVideos(this.props.cartItems || []),
      mediaBadges: this.getOverlayBadges()
    });
  }

  public componentDidUpdate(prevProps: IMediaProps, prevState: IMediaState) {
    if (!isEqual(this.state.cartItems, this.props.cartItems)) {
      this.setState({
        cartItems: this.props.cartItems,
        photosInCart: Utilities.getAllCartPhotos(this.props.cartItems || []),
        videosInCart: Utilities.getAllCartVideos(this.props.cartItems || [])
      });
    }

    if (this.shouldUpdateBadges(prevProps, prevState)) {
      this.setState({
        mediaBadges: this.getOverlayBadges()
      });
    }
  }

  private shouldUpdateBadges = (prevProps: IMediaProps, prevState: IMediaState) => {
    const {
      clientViewFavorites,
      clientViewDownloads,
      peopleFilteredPhotos,
      clientViewCommenting,
      allowMediaCommenting
    } = this.props;
    const { photosInCart, videosInCart } = this.state;
    return (
      clientViewDownloads?.downloads?.photoIds.length !== prevProps.clientViewDownloads?.downloads?.photoIds.length ||
      clientViewDownloads?.downloads?.videoIds.length !== prevProps.clientViewDownloads?.downloads?.videoIds.length ||
      !isEqual(clientViewCommenting?.commentedMedia, prevProps.clientViewCommenting?.commentedMedia) ||
      allowMediaCommenting !== prevProps.allowMediaCommenting ||
      clientViewFavorites.favoritePhotos.length !== prevProps.clientViewFavorites.favoritePhotos.length ||
      clientViewFavorites.favoriteVideos.length !== prevProps.clientViewFavorites.favoriteVideos.length ||
      peopleFilteredPhotos.length !== prevProps.peopleFilteredPhotos.length ||
      photosInCart.length !== prevState.photosInCart.length ||
      videosInCart.length !== prevState.videosInCart.length ||
      this.props.clientViewCommenting !== prevProps.clientViewCommenting ||
      this.props.allMedia !== prevProps.allMedia
    );
  };

  private handleShowQuickShop = (index: number) => {
    this.setState({ isRenderQuickShop: true });
    this.setState({ selectedIndex: index });
  };

  private handleCloseQuickShop = () => {
    this.setState({ isRenderQuickShop: false });
    this.setState({ selectedIndex: -1 });
  };

  private addItemToCartFromQuickShop = (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => {
    const { onClickQuickShopItem } = this.props;
    if (onClickQuickShopItem) {
      onClickQuickShopItem(e, media);
    }
  };

  private onClickShopAllProduct = (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => {
    e.preventDefault();
    e.stopPropagation();
    this.handleCloseQuickShop();
    const { onMediaCartClick } = this.props;
    onMediaCartClick && onMediaCartClick(e, media);
  };

  private getOverlayBadges = () => {
    const {
      allMedia,
      clientViewFavorites,
      clientViewDownloads,
      clientViewCommenting,
      peopleFilteredPhotos,
      bgColor,
      onMediaClick,
      allowMediaCommenting,
      quickShopItems,
      getSetting,
      previewMode
    } = this.props;
    const badges: Array<JSX.Element | null> = [];
    allMedia.forEach((media: PhotoOrVideo, index: number) => {
      const mediaIsFavorite = isVideo(media)
        ? clientViewFavorites.favoriteVideos
          ? clientViewFavorites.favoriteVideos.some(fv => fv.videoId === media.id)
          : false
        : clientViewFavorites.favoritePhotos
        ? clientViewFavorites.favoritePhotos.some(fp => fp.photoId === media.id)
        : false;

      const singleMediaDownloaded = clientViewDownloads?.downloads
        ? (isVideo(media) ? clientViewDownloads.downloads.videoIds : clientViewDownloads.downloads.photoIds).some(
            id => id === media.id
          )
        : false;

      const mediaIsDownloaded =
        (isVideo(media)
          ? clientViewDownloads?.galleryVideosDownloadSuccess
          : clientViewDownloads?.galleryPhotosDownloadSuccess) || singleMediaDownloaded;

      const matchesComment = (cm: IUnreadComment) => {
        return (isVideo(media) ? cm.videoId : cm.photoId) === media.id;
      };
      const mediaHasUnreadComment = clientViewCommenting?.commentedMedia.some(
        cm => matchesComment(cm) && cm.hasUnreadComment && cm.hasCommented
      );
      const mediaHasCommented = clientViewCommenting?.commentedMedia.some(cm => matchesComment(cm) && cm.hasCommented);

      const mediaIsAddedToCart = (isVideo(media) ? this.state.videosInCart : this.state.photosInCart).some(
        id => id === media.id
      );

      let mediaIsActive = true;

      if (peopleFilteredPhotos.length > 0) {
        mediaIsActive = !isVideo(media) && peopleFilteredPhotos.includes(media.id);
      }

      const isQuickShopEnable = Utilities.checkQuickShopEnabled(quickShopItems) && isShoppingAllowedForMedia(media);

      const mobileGridType = getSetting(GalleryPresentSettingType.MobileGridType);
      const isMobileMode = previewMode === PreviewModes.Mobile || window.innerWidth < 400;
      const customMobileStyle =
        mobileGridType && mobileGridType.id == GalleryPresentSettingList.TwoColumn && (isMobileMode || isMobileOnly)
          ? { width: 64, height: 64 }
          : {};

      const mediaOverlay = (
        <div
          className={cx(styles.mediaOverlay, {
            [styles.inactiveWhite]: !mediaIsActive && bgColor === colors.white,
            [styles.inactiveCream]: !mediaIsActive && bgColor === colors.creme,
            [styles.inactiveGray]: !mediaIsActive && bgColor === colors.grayNav,
            [styles.inactiveBlack]: !mediaIsActive && bgColor === colors.black
          })}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            (!isQuickShopEnable || (isQuickShopEnable && isMobile)) && onMediaClick(e, index);
          }}
        >
          <span className={styles.mediaOverlayIcon}>
            {mediaIsFavorite ? <FavoriteMediaIcon size={32} color="#FFF" /> : ""}
            {mediaIsAddedToCart ? <CartMediaIcon size={32} color="#FFF" /> : ""}
            {mediaHasCommented && allowMediaCommenting ? (
              <CommentMediaIcon size={32} color="#FFF" hasUnreadComment={mediaHasUnreadComment} />
            ) : (
              ""
            )}
            {mediaIsDownloaded ? <DownloadMediaIcon size={32} color="#FFF" /> : ""}
          </span>
          {isMobile && isQuickShopEnable && (
            <span
              className={styles.cartHoverIcon}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();
                this.handleShowQuickShop(index);
              }}
            >
              <img src={CartIcon} alt="" />
            </span>
          )}
        </div>
      );

      badges.push(mediaOverlay);
    });

    return badges;
  };

  private getOverlayActions = (index: number) => {
    if (isMobile) {
      return <div />;
    }
    const { getSetting } = this.props;
    const gridType = getSetting(GalleryPresentSettingType.GridType);
    const isLandscape =
      gridType &&
      (gridType.id === GalleryPresentSettingList.LandscapeSmall ||
        gridType.id === GalleryPresentSettingList.LandscapeLarge);

    return <MediaOverlayActions {...this.props} isLandscape={isLandscape!} index={index} />;
  };

  private handleOnItemsPerRow = (items: number) => {
    this.setState({ itemsPerRow: items, mediaBadges: this.getOverlayBadges() });
  };

  private renderQuickShop = () => {
    const { quickShopItems, allMedia, images } = this.props;
    const { selectedIndex, isRenderQuickShop } = this.state;
    const media = allMedia[selectedIndex];
    const imageUrl = images[selectedIndex]?.url;
    if (isRenderQuickShop && quickShopItems) {
      return (
        <QuickShopPreview
          className={styles.quickShopPreview}
          items={quickShopItems || []}
          media={media}
          imageUrl={imageUrl}
          onClickShopAllProduct={e => this.onClickShopAllProduct(e, media)}
          onClickAddItem={e => this.addItemToCartFromQuickShop(e, media)}
          onCloseQuickShop={this.handleCloseQuickShop}
          isGIFPhoto={isGIFPhoto(media.fileName)}
        />
      );
    }
    return null;
  };

  private get photoNameProps() {
    const { getSetting } = this.props;
    const layoutOptionsContent = getSetting(GalleryPresentSettingType.LayoutOptionsContent);
    const layoutOptionsShow = getSetting(GalleryPresentSettingType.LayoutOptionsShow);
    const showNames: NameType =
      layoutOptionsContent?.id === GalleryPresentSettingList.LayoutOptionsContentNone
        ? "none"
        : layoutOptionsContent?.id !== GalleryPresentSettingList.LayoutOptionsContentSequenceNumbers
        ? "fileName"
        : "sequenceNumber";
    const namesVisibility: NameVisibility =
      layoutOptionsShow?.id !== GalleryPresentSettingList.LayoutOptionsShowAlways ? "hover" : "always";

    return { showNames, namesVisibility };
  }

  private renderImageBlock = () => {
    const { getSetting, images, onMediaClick, animationClasses } = this.props;
    const { mediaBadges } = this.state;
    const gridType = getSetting(GalleryPresentSettingType.GridType);
    const mobileGridType = getSetting(GalleryPresentSettingType.MobileGridType);

    if (
      gridType &&
      (gridType.id === GalleryPresentSettingList.PortraitLarge ||
        gridType.id === GalleryPresentSettingList.PortraitSmall)
    ) {
      return (
        <ZenMasonryBlock
          siteTheme={siteThemeDefault}
          resizeToFit={false}
          noPadding={true}
          layout={gridType.id === GalleryPresentSettingList.PortraitSmall ? "A" : "B"}
          mobileLayout={mobileGridType?.id}
          brakePoints={brakePoints}
          overlayStyle={styles.overlayStyle}
          onPhotoClick={onMediaClick}
          images={images}
          className={styles.gallery}
          animationClasses={animationClasses}
          getHoverComponent={this.getOverlayActions}
          overlayComponent={mediaBadges}
          blockImageEvents={true}
          {...this.photoNameProps}
        />
      );
    }

    if (
      gridType &&
      (gridType.id === GalleryPresentSettingList.LandscapeSmall ||
        gridType.id === GalleryPresentSettingList.LandscapeLarge)
    ) {
      return (
        <ZenMasonryLandscapeBlock
          siteTheme={siteThemeDefault}
          layout={gridType.id === GalleryPresentSettingList.LandscapeSmall ? "B" : "C"}
          mobileLayout={mobileGridType?.id}
          noPadding={true}
          images={images}
          className={styles.gallery}
          onPhotoClick={onMediaClick}
          galleryStyle=""
          dropShadow={false}
          onItemsPerRow={this.handleOnItemsPerRow}
          getHoverComponent={this.getOverlayActions}
          overlayComponent={mediaBadges}
          {...this.photoNameProps}
        />
      );
    } else {
      return (
        <ZenMasonryBlock
          siteTheme={siteThemeDefault}
          layout={gridType && gridType.id === GalleryPresentSettingList.GridSmall ? "B" : "A"}
          mobileLayout={mobileGridType?.id}
          noPadding={true}
          brakePoints={brakePoints}
          images={images}
          className={styles.gallery}
          onPhotoClick={onMediaClick}
          blockImageEvents={true}
          isStacked={gridType && gridType.id === GalleryPresentSettingList.Stacked ? true : false}
          resizeToFit={
            gridType &&
            (gridType.id === GalleryPresentSettingList.GridSmall || gridType.id === GalleryPresentSettingList.GridLarge)
              ? true
              : false
          }
          getHoverComponent={this.getOverlayActions}
          overlayComponent={mediaBadges}
          {...this.photoNameProps}
        />
      );
    }
  };

  public render() {
    return (
      <>
        {this.renderImageBlock()}
        {this.renderQuickShop()}
      </>
    );
  }
}

export default Media;
