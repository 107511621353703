import { Utilities } from "@zenfolio/core-components";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { IActivity } from "../../../../../../store/billing/model";
import { hideTrackHorizontal } from "../../../../../../utilities/helpers";
import styles from "./index.module.scss";

interface IActivitiesProps {
  activities: IActivity[];
  selectedActivityId?: string;
  onSelect: (activityId: string) => void;
  className?: string;
}

class Activities extends React.Component<IActivitiesProps> {
  public render() {
    const { className, activities, selectedActivityId } = this.props;

    return (
      <div className={classNames(styles.container, className)}>
        <div className={styles.header}>
          <span className={classNames(styles.cell, styles.date)}>Date</span>
          <span className={classNames(styles.cell, styles.type)}>Type</span>
          <span className={classNames(styles.cell, styles.status)}>Status</span>
          <span className={classNames(styles.cell, styles.amount)}>Amount</span>
          <span className={classNames(styles.cell, styles.filler)} />
        </div>
        {activities.length === 0 && <div className={styles.empty}>There are no transactions at this time.</div>}
        {activities.length > 0 && (
          <Scrollbars renderTrackHorizontal={hideTrackHorizontal} className={styles.body}>
            {activities.map(activity => (
              <div
                key={activity.id}
                className={classNames(styles.activity, activity.id === selectedActivityId ? styles.selected : "")}
                onClick={this.onActivityClick(activity.id)}
              >
                <span className={classNames(styles.cell, styles.date)}>
                  {activity.date ? moment(activity.date).format("MMM-DD-YYYY") : null}
                </span>
                <span className={classNames(styles.cell, styles.type)}>{activity.type.name}</span>
                <span className={classNames(styles.cell, styles.status, styles[activity.status.code])}>
                  {activity.status.name}
                </span>
                <span className={classNames(styles.cell, styles.amount)}>
                  {Utilities.formatFractionalNumberWithCurrency(activity.amount)}
                </span>
                <span className={classNames(styles.cell, styles.filler)} />
              </div>
            ))}
          </Scrollbars>
        )}
      </div>
    );
  }

  private onActivityClick = _.memoize((activityId: string) => () => this.props.onSelect(activityId));
}

export default Activities;
