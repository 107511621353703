import { Button } from "@zenfolio/core-components";
import React, { PureComponent } from "react";
import warningIcon from "../../../../assets/icons/icon-warning.svg";
import { IWidgetErrorModalProps } from "../../../../store/modal/model";
import withModal, { InjectedModalProps } from "../../withModal";
import ModalBase from "../modalBase";
import styles from "./index.module.scss";

interface IWidgetErrorProps extends InjectedModalProps<IWidgetErrorModalProps> {}

class WidgetError extends PureComponent<IWidgetErrorProps> {
  public render() {
    const { message, title } = this.props;
    return (
      <ModalBase onClose={this.handleClose} className={styles.modal} overlayClassName={styles.overlay}>
        <div className={styles.content}>
          <div className={styles.warning}>
            <img src={warningIcon} alt="warning icon" />
          </div>
          <h2>{title || "Uh-Oh!"}</h2>
          <h3 dangerouslySetInnerHTML={{ __html: message }} />
          <Button className={styles.button} styleType="secondary" onClick={this.handleClose}>
            Continue
          </Button>
        </div>
      </ModalBase>
    );
  }

  private handleClose = () => {
    const { onContinueButtonClick, onHideModal } = this.props;

    onHideModal();
    onContinueButtonClick();
  };
}

export default withModal(WidgetError);
