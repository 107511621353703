export class AsyncLoader {
  public static readonly iframeResizerParent: string = "iframeResizerParent";
  public static readonly iframeResizerChild: string = "iframeResizerChild";
  public static readonly stripeFactory: string = "stripeFactory";

  private static readonly itemsMap: { [type: string]: any } = {};
  private static readonly callbacksMap: { [type: string]: Array<(item: any) => void> } = {};

  public static register(type: string, item: any) {
    AsyncLoader.itemsMap[type] = item;
    const callbacks = this.callbacksMap[type];
    if (callbacks) {
      for (const callback of callbacks) {
        callback(item);
      }

      delete this.callbacksMap[type];
    }
  }

  public static subscribe<T>(type: string, callback: (item: T) => void) {
    const item = AsyncLoader.itemsMap[type];
    if (item) {
      callback(item);
      return;
    }

    let callbacks = this.callbacksMap[type];
    if (!callbacks) {
      callbacks = [];
      this.callbacksMap[type] = callbacks;
    }

    callbacks.push(callback);
  }

  public static unsubscribe<T>(type: string, callback: (item: T) => void) {
    const callbacks = this.callbacksMap[type];
    if (!callbacks) {
      return;
    }

    const callbackIndex = callbacks.indexOf(callback);
    if (callbackIndex >= 0) {
      callbacks.splice(callbackIndex, 1);
    }
  }
}
