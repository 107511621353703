import { deserialize } from "components/TextEditor/utils";
import _ from "lodash";
import { Editor, Node, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { RichText } from "utilities/richText";
import * as types from "../core/types";
import { defaultValue } from "../serialize/serialize-html";

const elementTags = _.memoize((showList: boolean) => ({
  A: (el: any) => ({
    type: types.BLOCK_LINK,
    url: el.getAttribute("href")
  }),
  ...(showList
    ? {
        LI: () => ({ type: types.BLOCK_LI }),
        OL: () => ({ type: types.BLOCK_OL }),
        UL: () => ({ type: types.BLOCK_UL })
      }
    : null),
  "": () => ({ type: types.BLOCK_PARAGRAPH })
}));

const textTags = {
  ..._.reduce(
    [
      "ABBR",
      "ACRONYM",
      "BDO",
      "BIG",
      "BUTTON",
      "CITE",
      "CODE",
      "DFN",
      "IMG",
      "INPUT",
      "KBD",
      "LABEL",
      "MAP",
      "OBJECT",
      "OUTPUT",
      "Q",
      "SAMP",
      "SCRIPT",
      "SELECT",
      "SMALL",
      "SPAN",
      "SUB",
      "SUP",
      "TEXTAREA",
      "TIME",
      "TT",
      "VAR"
    ],
    (o, k) => ((o[k] = () => ({})), o),
    {} as any
  ),
  EM: () => ({ [types.MARK_ITALIC]: true }),
  I: () => ({ [types.MARK_ITALIC]: true }),
  B: () => ({ [types.MARK_BOLD]: true }),
  STRONG: () => ({ [types.MARK_BOLD]: true }),
  U: () => ({ [types.MARK_UNDERLINE]: true })
};

export const withPasteHtml = (showList?: boolean, maxLength?: number) => <T extends ReactEditor>(editor: T) => {
  const { insertData } = editor;

  editor.insertData = data => {
    try {
      const format = "text/html";
      const html = data.getData(format);

      if (html) {
        const parsed = new DOMParser().parseFromString(html, format);
        const fragment = deserialize(parsed.body, elementTags(!!showList), textTags, true);
        if (_.isArray(fragment)) {
          const normalized: Node[] = [];
          let children: Node[] = [];

          const pushChildren = () => {
            if (!_.isEmpty(children)) {
              normalized.push({ type: types.BLOCK_PARAGRAPH, children });
              children = [];
            }
          };

          for (const node of fragment) {
            if (node) {
              if (!node.type || node.type === types.BLOCK_LINK) {
                children.push(node);
              } else if (_.isEqual(node, defaultValue[0]) && !_.isEmpty(children)) {
                node.children = children;
                children = [];
                normalized.push(node);
              } else {
                pushChildren();
                normalized.push(node);
              }
            }
          }

          pushChildren();

          if (!_.isEmpty(normalized)) {
            let canPaste: boolean;

            if (maxLength != null) {
              const currentLength = RichText.getLength(editor.children);
              const insertedLength = RichText.getLength(normalized);
              const selectedLength = editor.selection ? Editor.string(editor, editor.selection).length : 0;

              canPaste = currentLength + insertedLength - selectedLength <= maxLength;
            } else {
              canPaste = true;
            }

            if (canPaste) {
              Transforms.insertFragment(editor, normalized);
            }
          }
        }

        return;
      }
    } catch (e) {}

    insertData(data);
  };

  return editor;
};
