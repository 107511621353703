import React from "react";
import { BLOCK_ALIGN_CENTER, BLOCK_ALIGN_LEFT, BLOCK_ALIGN_RIGHT, RenderElement } from "../../../core/types";
import styles from "./align.module.scss";

export const renderElementAlign: RenderElement = ({ attributes, children, element }) => {
  if (element.type === BLOCK_ALIGN_LEFT) {
    return (
      <div {...attributes} className={styles.alignLeft}>
        {children}
      </div>
    );
  }
  if (element.type === BLOCK_ALIGN_CENTER) {
    return (
      <div {...attributes} className={styles.alignCenter}>
        {children}
      </div>
    );
  }
  if (element.type === BLOCK_ALIGN_RIGHT) {
    return (
      <div {...attributes} className={styles.alignRight}>
        {children}
      </div>
    );
  }
  return undefined;
};
