import { Alert, Button, TimeSlotDay, Utilities } from "@zenfolio/core-components";
import { ISlotRange } from "@zenfolio/core-components/dist/components/TimeSlotDay";
import _ from "lodash";
import React from "react";
import { IBookableHoursModalProps } from "../../../../store/modal/model";
import { IBookableHours, ITimeSlot } from "../../../../store/profile/availability/model";
import { DayOfWeek, DaysOfWeek, formatTimeOfDay } from "../../../../utilities/datetime";
import eventTracker from "../../../../utilities/eventTracker";
import { hasActionCompleted, hasActionStarted } from "../../../../utilities/helpers";
import withModal, { InjectedModalProps } from "../../withModal";
import ModalDialog from "../modalDialog";
import styles from "./index.module.scss";
import ModalPage from "./modalPage";
import { IUpdateBookableHours } from "./model";
import { convertSlotRangesToTimeSlots, convertTimeSlotsToSlotRanges } from "./utils";
import withStore from "./withStore";

interface IBookableHoursProps extends InjectedModalProps<IBookableHoursModalProps> {
  updateBookableHours: IUpdateBookableHours;
}

export interface IDay {
  editing: boolean;
  timeSlots?: ISlotRange[];
}

interface IBookableHoursState {
  days: IDay[];
  showAlert: boolean;
  showBackConfirm: boolean;
}

class BookableHours extends React.Component<IBookableHoursProps, IBookableHoursState> {
  public state: IBookableHoursState = BookableHours.defaultState;

  public componentWillMount() {
    Utilities.scrollTop();
  }

  public componentDidUpdate(prevProps: IBookableHoursProps) {
    const prevUpdateStatus = prevProps.updateBookableHours.status;
    const updateStatus = this.props.updateBookableHours.status;
    const saveCompleted = hasActionCompleted(prevUpdateStatus, updateStatus);
    const saveStarted = hasActionStarted(prevUpdateStatus, updateStatus);

    if (saveCompleted) {
      this.setState({ ...BookableHours.defaultState, showAlert: true });
    } else if (saveStarted) {
      this.setState({ showAlert: false });
    }
  }

  public componentWillUnmount() {
    this.props.updateBookableHours.onReset();
  }

  public render() {
    const { showBackConfirm } = this.state;
    const saveDisabled =
      this.props.updateBookableHours.status === "Pending" ||
      _.flatten(DaysOfWeek.numericOrder.map(this.getTimeSlots)).length === 0;

    return (
      <ModalPage className={styles.container}>
        <div className={styles.pageHeader}>
          <button className={styles.back} onClick={this.onBackClick}>
            ← Back to Service Availability
          </button>
        </div>
        <div className={styles.body}>
          {this.renderAlert()}
          <div className={styles.header}>Global Bookable Hours</div>
          {this.renderTimeSlots()}
          <Button className={styles.save} styleType="primary" disabled={saveDisabled} onClick={this.onSaveClick}>
            Save Changes
          </Button>
        </div>
        {showBackConfirm && (
          <ModalDialog
            okWhenClosed={true}
            title="Unsaved changes"
            cancelText="Yes"
            okText="No"
            className={styles.backConfirm}
            onCancel={this.onLeaveWithUnsavedChanges}
            onOk={this.onStayWithUnsavedChanges}
          >
            Are you sure you want to do this? Going back to your previous screen will not save your unsaved changes.
          </ModalDialog>
        )}
      </ModalPage>
    );
  }

  private get bookableHours() {
    const { bookableHours, updateBookableHours } = this.props;
    return updateBookableHours.bookableHours || bookableHours || BookableHours.defaultBookableHours;
  }

  private getTimeSlots = (dayNumber: DayOfWeek | number) =>
    this.state.days[dayNumber].timeSlots || this.convertTimeSlots(this.bookableHours.items)[dayNumber];

  private renderAlert = () => {
    const { showAlert } = this.state;
    const updateStatus = this.props.updateBookableHours.status;
    const status = updateStatus === "Success" ? "success" : updateStatus === "Error" ? "error" : null;
    const message = status === "error" ? "Please try again." : "Availability Selection saved";

    return (
      showAlert &&
      status && <Alert className={styles.alert} type={status} message={message} duration={5000} scrollTo={"page-top"} />
    );
  };

  private renderTimeSlots = () => {
    return (
      <div className={styles.timeSlots}>
        {_.map(DaysOfWeek.fromMonday, dayOfWeek => (
          <TimeSlotDay
            key={dayOfWeek}
            step={5}
            minSlotRange={5}
            slots={this.getTimeSlots(dayOfWeek)}
            edit={this.state.days[dayOfWeek].editing}
            dayOfWeek={dayOfWeek}
            onChangeSlots={this.onDaySlotsChange}
            onAddSlot={this.onDaySlotAdd}
            onDeleteSlot={this.onDaySlotDelete}
            onToggleEdit={this.onDayEdit}
          />
        ))}
      </div>
    );
  };

  private onDaySlotsChange = (dayNumber: number, slots: ISlotRange[]) =>
    this.onDayUpdate(dayNumber, day => ({ ...day, timeSlots: slots }));

  private onDaySlotAdd = (dayNumber: number, slot: ISlotRange) =>
    this.onDayUpdate(dayNumber, day => ({
      ...day,
      timeSlots: this.getTimeSlots(dayNumber).concat(slot)
    }));

  private onDaySlotDelete = (dayNumber: number, index: number) =>
    this.onDayUpdate(dayNumber, day => ({
      ...day,
      timeSlots: _.filter(this.getTimeSlots(dayNumber as DayOfWeek), (d, idx) => idx !== index)
    }));

  private onDayEdit = (dayNumber: number, applyToAll: boolean) =>
    applyToAll
      ? this.setState({
          days: DaysOfWeek.numericOrder.map(() => ({ timeSlots: this.getTimeSlots(dayNumber), editing: false }))
        })
      : this.onDayUpdate(dayNumber, day => ({
          editing: !day.editing,
          timeSlots:
            !day.editing && this.getTimeSlots(dayNumber).length === 0
              ? [BookableHours.defaultFirstTimeSlot]
              : day.timeSlots
        }));

  private onDayUpdate = (dayNumber: number, update: (day: IDay) => IDay) =>
    this.setState({ days: this.state.days.map((day, idx) => (idx === dayNumber ? update(day) : day)) });

  private onBackClick = () => {
    const slotSorter = (slot: ITimeSlot) => `${slot.dayOfWeek}-${slot.durationMinutes}`;
    const haveUnsavedChanges = !_.isEqual(
      _.sortBy(this.convertSlotRanges(DaysOfWeek.numericOrder.map(this.getTimeSlots)), slotSorter),
      _.sortBy(this.bookableHours.items, slotSorter)
    );

    if (haveUnsavedChanges) {
      this.setState({ showBackConfirm: true });
    } else {
      this.props.onHideModal();
    }
  };

  private onLeaveWithUnsavedChanges = () => {
    this.props.onHideModal();
  };

  private onStayWithUnsavedChanges = () => {
    this.setState({ showBackConfirm: false });
  };

  private onSaveClick = () => {
    eventTracker.events.photographer.profile.bookableHoursUpdated();
    this.props.updateBookableHours.onUpdate({
      items: this.convertSlotRanges(DaysOfWeek.numericOrder.map(this.getTimeSlots))
    });
  };

  private convertTimeSlots = _.memoize(convertTimeSlotsToSlotRanges);
  private convertSlotRanges = convertSlotRangesToTimeSlots;

  private static readonly defaultFirstTimeSlot: ISlotRange = {
    selectedStart: { value: 480, label: formatTimeOfDay(480) },
    selectedEnd: { value: 1020, label: formatTimeOfDay(1020) }
  };

  private static readonly defaultState: IBookableHoursState = {
    showAlert: false,
    showBackConfirm: false,
    days: DaysOfWeek.numericOrder.map(() => ({ editing: false }))
  };

  private static readonly defaultBookableHours: IBookableHours = {
    items: []
  };
}

export default withModal(withStore(BookableHours));
