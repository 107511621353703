import cx from "classnames";
import * as React from "react";

import styles from "./popover.module.scss";

export interface IPopoverProps {
  renderHandle: (ref: React.Ref<HTMLElement>, onClick: () => void) => React.ReactElement;
  defaultVisible?: boolean;
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  className?: string;
  style?: React.CSSProperties;
  content?: { className?: string; style?: React.CSSProperties };
}

export interface IPopoverState {
  visible?: boolean;
}

class Popover extends React.Component<IPopoverProps, IPopoverState> {
  public state: IPopoverState = {};

  public componentDidMount() {
    document.addEventListener("mousedown", this.onDocumentMouseDown);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.onDocumentMouseDown);
  }

  public render() {
    const { children, className, style, renderHandle } = this.props;
    const visible = this.visible;
    const content = this.props.content || {};

    return (
      <div className={cx(styles.container, className)} style={style}>
        {renderHandle(this.handleRef, this.onHandleClick)}
        <div
          className={cx(styles.content, content.className, !visible && styles.hidden)}
          style={content.style}
          ref={this.contentRef}
        >
          {children}
        </div>
      </div>
    );
  }

  private get visible() {
    const { props, state } = this;

    return props.visible !== undefined
      ? props.visible
      : state.visible !== undefined
      ? state.visible
      : props.defaultVisible || false;
  }

  private onDocumentMouseDown = (event: any) => {
    if (this.handleRef.current && this.handleRef.current.contains(event.target)) {
      return;
    }

    if (this.contentRef.current && !this.contentRef.current.contains(event.target)) {
      this.changeVisible(false);
    }
  };

  private onHandleClick = () => this.changeVisible(true);

  private changeVisible(visible: boolean) {
    if (visible === this.visible) {
      return;
    }

    if (this.props.visible !== undefined) {
      this.props.onVisibleChange!(visible);
    } else {
      this.setState({ visible }, () => this.props.onVisibleChange?.(visible));
    }
  }

  private readonly contentRef = React.createRef<HTMLDivElement>();
  private readonly handleRef = React.createRef<HTMLElement>();
}

export default Popover;
