import classNames from "classnames";
import React from "react";
import { Utilities } from "utilities/utilities";
import { getInputMax, IComponentBaseProps, isDecrementDisabled, isIncrementDisabled, resolveValue } from "../../utils";
import styles from "./numberUnit.module.scss";

export type ChangeType = "editor" | "committed" | "increment" | "decrement";

interface INumberUnitProps extends IComponentBaseProps<HTMLInputElement> {
  defaultValue?: number | null;
  value?: number | null;
  maxLength?: number;
  min?: number;
  max?: number;
  step?: number;
  incrementDisabled?: boolean;
  decrementDisabled?: boolean;
  disabled?: boolean;
  tabIndex?: number;

  formatter?: (value: number | null) => string;
  parser?: (value: string) => number | null;
  filter?: (value: string) => string;

  onChange?: (value: number | null, type: ChangeType) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface INumberUnitState {
  value?: number | null;
  inputValue?: number | null;
  inputString?: string;
}

class NumberUnit extends React.Component<INumberUnitProps, INumberUnitState> {
  public static defaultFormatter(value: number | null) {
    return value === null ? "" : value.toString();
  }

  public static defaultParser(value: string) {
    const num = value ? parseInt(value, 10) : null;
    return num === null || isNaN(num) ? null : num;
  }

  public static defaultFilter(value: string) {
    return value.replace(/[^-\d]/g, "");
  }

  public state: INumberUnitState = {};

  public shouldComponentUpdate(nextProps: INumberUnitProps, nextState: INumberUnitState): boolean {
    return Utilities.shouldComponentUpdateDeep(this, nextProps, nextState);
  }

  public render() {
    const { containerRef, style, className, formatter, disabled, maxLength, tabIndex } = this.props;
    const { inputString } = this.state;

    return (
      <input
        type="text"
        value={inputString !== undefined ? inputString : (formatter || NumberUnit.defaultFormatter)(this.value)}
        disabled={disabled}
        maxLength={maxLength}
        tabIndex={tabIndex}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        onKeyUp={this.onKeyUp}
        className={classNames(styles.container, className)}
        style={style}
        ref={containerRef}
      />
    );
  }

  private get value() {
    return resolveValue(this.state.inputValue, this.props.value, this.state.value, this.props.defaultValue, null);
  }

  private get max() {
    return getInputMax(this.props.max, this.props.maxLength);
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => this.changeValue(event.currentTarget.value, false);

  private onFocus = (event: React.FocusEvent<HTMLInputElement>) => this.props.onFocus?.(event);

  private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.changeValue(event.currentTarget.value, true);
    this.props.onBlur?.(event);
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp") {
      this.increment();
      event.preventDefault();
    } else if (event.key === "ArrowDown") {
      this.decrement();
      event.preventDefault();
    } else {
      this.props.onKeyDown?.(event);
    }
  };

  private onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    this.props.onKeyUp?.(event);
  };

  private increment() {
    const { value, step, min, incrementDisabled, onChange } = this.props;

    if (!isIncrementDisabled(this.value, step, this.max, incrementDisabled)) {
      const newValue = Utilities.limitNumber((this.value || 0) + (step || 1), min, this.max);
      this.setState(
        { value: value === undefined ? newValue : undefined, inputString: undefined, inputValue: undefined },
        () => onChange?.(newValue, "increment")
      );
    }
  }

  private decrement() {
    const { value, step, min, decrementDisabled, onChange } = this.props;

    if (!isDecrementDisabled(this.value, step, min, decrementDisabled)) {
      const newValue = Utilities.limitNumber((this.value || 0) - (step || 1), min, this.max);
      this.setState(
        { value: value === undefined ? newValue : undefined, inputString: undefined, inputValue: undefined },
        () => onChange?.(newValue, "decrement")
      );
    }
  }

  private changeValue = (valueString: string, committed: boolean) => {
    const { min, parser, filter, onChange } = this.props;

    valueString = (filter || NumberUnit.defaultFilter)(valueString);
    let numericValue = (parser || NumberUnit.defaultParser)(valueString);
    numericValue = committed ? Utilities.limitNumber(numericValue, min, this.max) : numericValue;

    this.setState(
      {
        value: this.props.value === undefined ? numericValue : undefined,
        inputValue: committed ? undefined : numericValue,
        inputString: committed ? undefined : valueString
      },
      () => onChange?.(numericValue, committed ? "committed" : "editor")
    );
  };
}

export default NumberUnit;
