import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { hideModal } from "../../../../store/modal/actions";
import { IModalState } from "../../../../store/modal/reducer";
import { IAppState } from "../../../../store/state";
import ModalBase from "../modalBase";
import styles from "./index.module.scss";

interface IConnectErrorProps extends RouteComponentProps {
  modal: IModalState;
  onHideModal: () => void;
}

class ConnectError extends PureComponent<IConnectErrorProps> {
  public render() {
    return (
      <ModalBase onClose={this.onClose} className={styles.modal}>
        <div className={styles.content}>
          <div className={styles.box}>
            <h2>Uh-oh!</h2>
            <h3>Something isn’t right.</h3>
            <p>Let’s try this again. Just click below to go back to the profile page.</p>
            <div className={styles.back} onClick={this.onClose}>
              Back to profile page
            </div>
          </div>
        </div>
      </ModalBase>
    );
  }

  private onClose = () => {
    this.props.onHideModal();
  };
}
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onHideModal: () => dispatch(hideModal())
});

const mapStateToProps = (state: IAppState) => ({
  modal: state.modal
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectError));
