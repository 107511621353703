// tslint:disable: no-console

export const enableDetailedLogging = process.env.REACT_APP_ENABLE_DETAILED_LOGGING === "true";

export const log = (message?: any, ...optionalParams: any[]): void => {
  if (enableDetailedLogging) {
    console.log(message, ...optionalParams);
  }
};

export const logError = (message?: any, ...optionalParams: any[]): void => {
  if (enableDetailedLogging) {
    console.error(message, ...optionalParams);
  }
};

export const getLogStyle = (color: string): string => {
  return `color: ${color}; font-weight: bold; font-size: 14px`;
};
