import cx from "classnames";
import { IZenSiteTheme, ICurrency, IPage, IPhoto, IPhotographerFoldersState } from "../../../models/models";
import { IGallerySettings } from "models/blocks";
import { IQuickShopItem } from "../../ClientView/CommonUI/Views/components/QuickShop/Preview";
import { ICartItem, IClientViewGalleryState, PreviewModes } from "../../ClientView/models";
import { ILightboxZoomImage } from "../../LightboxZoom";
import { IToaster } from "../../Toaster";
import React, { PureComponent } from "react";
import { getSiteFonts } from "utilities/blocks/site";
import { IExtraPage } from "../Breadcrumb";
import styles from "./dynamicgallery.module.scss";
import CompleteView from "./Views/CompleteView";
import PrivateError from "./Views/PrivateError";
import ProtectedView from "./Views/ProtectedView";
import { IGalleryCoupon } from "models/coupon";

export interface IDynamicGalleryProps {
  OverlapPhotos?: Array<JSX.Element | null>;
  galleryId: string;
  albumAlias?: string;
  sitePages: IPage[];
  error?: Error;
  siteTheme: IZenSiteTheme;
  gallerySettings: IGallerySettings;
  clientViewGallery: IClientViewGalleryState;
  subdomain: string;
  extraPages?: IExtraPage[];
  pageAlias: string;
  folderId?: string;
  rootFolderId?: string;
  isPublish?: boolean;
  isEcom?: boolean;
  isDevelopment: boolean;
  photographerFolders: IPhotographerFoldersState;
  currency?: ICurrency;
  couponList?: IGalleryCoupon[];
  contentsRef?: React.RefObject<HTMLDivElement>;
  onClickButtonCart?: () => void;
  onHoverPhoto?: (index: number) => void;
  onClickBannerButton?: () => void;
  onSendPassword?: (galleryId: string, password: string, email: string) => void;
  onSendPasswordPublish?: (subdomain: string, galleryAlias: string, password: string, email: string) => void;
  onShowAlertError: (toaster: IToaster) => void;
  onShowShopLightbox?: (index: number) => void;
  onChangeCollection?: (collectionId: string) => void;
  quickShopItems?: IQuickShopItem[];
  onClickQuickShopItem?: (e: React.MouseEvent<HTMLElement>, photo: IPhoto | ILightboxZoomImage) => void;
  cartItems?: ICartItem[];
  previewMode?: PreviewModes;
  alignment?: string;
  perPage?: string;
  order?: string;
  padding?: string;
}

interface IDynamicGalleryState {
  imagesUrls: string[];
}

export class DynamicGallery extends PureComponent<IDynamicGalleryProps, IDynamicGalleryState> {
  constructor(props: IDynamicGalleryProps) {
    super(props);
    this.state = {
      imagesUrls: []
    };
  }

  public componentDidMount() {
    document.addEventListener("lazyloaded", this.handleLazyLoaded);
  }

  public componentWillUnmount() {
    document.removeEventListener("lazyloaded", this.handleLazyLoaded);
  }

  private handleLazyLoaded = (e: Event) => {
    if (e.target) {
      const img = e.target as HTMLImageElement;

      // this logic allows to have the unfolding effect in lazy loading imgs
      // but the css classes have to be defined in index.css
      img.parentElement?.classList.add("show");

      img.parentElement?.getElementsByClassName("imgreveal-top")[0]?.classList.add("reveal");
      img.parentElement?.getElementsByClassName("imgreveal-bottom")[0]?.classList.add("reveal");

      img.parentElement?.classList.add("loaded");
    }
  };

  private onConfirmClick = (password: string, email: string) => {
    const {
      onSendPasswordPublish,
      subdomain,
      clientViewGallery,
      albumAlias,
      isPublish,
      onSendPassword,
      galleryId
    } = this.props;
    if (onSendPasswordPublish && albumAlias && isPublish) {
      onSendPasswordPublish(subdomain, albumAlias, password, email);
    }

    if (onSendPassword && galleryId && !isPublish) {
      onSendPassword(galleryId, password, email);
    }
  };

  public render() {
    const {
      isEcom,
      cartItems,
      clientViewGallery,
      gallerySettings,
      quickShopItems,
      couponList,
      galleryId,
      currency,
      siteTheme,
      sitePages,
      isPublish,
      subdomain,
      extraPages,
      pageAlias,
      rootFolderId,
      folderId,
      photographerFolders,
      isDevelopment,
      OverlapPhotos,
      contentsRef,
      alignment,
      perPage,
      order,
      padding,
      onShowAlertError,
      onHoverPhoto,
      onShowShopLightbox,
      onClickBannerButton,
      onClickQuickShopItem,
      onChangeCollection,
      onClickButtonCart
    } = this.props;

    if (
      (!clientViewGallery.isVisitorAllowed || clientViewGallery.expiration?.active === false) &&
      clientViewGallery.id
    ) {
      return (
        <PrivateError
          isDevelopment={isDevelopment}
          sitePages={sitePages}
          siteTheme={siteTheme}
          subdomain={subdomain}
          rootFolderId={rootFolderId}
          pageAlias={pageAlias}
          extraPages={extraPages}
          isPublish={isPublish}
          clientViewGallery={clientViewGallery}
          photographerFolders={photographerFolders}
        />
      );
    }

    if (
      !clientViewGallery.logged &&
      (clientViewGallery.isProtected ||
        clientViewGallery.askPassword ||
        !clientViewGallery.isVisitorAllowed ||
        clientViewGallery.isVisitorEmailRequired)
    ) {
      return (
        // View to obtain password passing onConfirmClick callback
        <ProtectedView
          galleryId={galleryId}
          siteTheme={siteTheme}
          clientViewGallery={clientViewGallery}
          onShowAlertError={onShowAlertError}
          onConfirmClick={this.onConfirmClick}
        />
      );
    }
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    if (clientViewGallery.error && !clientViewGallery.logged) {
      const errorMessage = clientViewGallery.status === 400 ? "Gallery not found" : "An error has occurred";
      return (
        <div id="container" className={cx(styles.container, styles[siteFonts.primary])}>
          <div className={styles.content}>
            <h1>{errorMessage}</h1>
          </div>
        </div>
      );
    }
    return (
      <CompleteView
        contentsRef={contentsRef}
        cartItems={cartItems}
        quickShopItems={quickShopItems}
        onClickQuickShopItem={onClickQuickShopItem}
        isDevelopment={isDevelopment}
        isEcom={isEcom}
        currency={currency}
        OverlapPhotos={OverlapPhotos}
        onHoverPhoto={onHoverPhoto}
        sitePages={sitePages}
        galleryId={galleryId}
        gallerySettings={gallerySettings}
        siteTheme={siteTheme}
        clientViewGallery={clientViewGallery}
        onShowAlertError={onShowAlertError}
        onShowShopLightbox={onShowShopLightbox}
        subdomain={subdomain}
        rootFolderId={rootFolderId}
        extraPages={extraPages}
        pageAlias={pageAlias}
        folderId={folderId}
        isPublish={isPublish}
        photographerFolders={photographerFolders}
        couponList={couponList}
        onClickBannerButton={onClickBannerButton}
        onChangeCollection={onChangeCollection}
        onClickButtonCart={onClickButtonCart}
        previewMode={this.props.previewMode}
        alignment={alignment}
        order={order}
        padding={padding}
        perPage={perPage}
      />
    );
  }
}
