import React from "react";
import IIConProps from "../IIconProps";

const Face = (props: IIConProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`${props.minX} ${props.minY} ${props.width} ${props.height}`}
      >
        <defs>
            <clipPath id="q9z8xjssla">
                <path d="M1844 0v2904H0V0h1844z"/>
            </clipPath>
            <clipPath id="k7hmjzuk5b">
                <path d="M1823 76a5 5 0 0 1 5 5v2803a5 5 0 0 1-5 5H21a5 5 0 0 1-5-5V81a5 5 0 0 1 5-5h1802z"/>
            </clipPath>
            <clipPath id="zk7bv86g1c">
                <path d="M29.23 16a.75.75 0 0 1 .743.648l.007.102v3.2a2.35 2.35 0 0 1-2.19 2.345l-.16.005h-3.2a.75.75 0 0 1-.102-1.493l.102-.007h3.2a.85.85 0 0 0 .843-.743l.007-.107v-3.2a.75.75 0 0 1 .75-.75zM.75 16a.75.75 0 0 1 .743.648l.007.102v3.2c0 .433.324.79.743.843l.107.007h3.2a.75.75 0 0 1 .102 1.493l-.102.007h-3.2a2.35 2.35 0 0 1-2.345-2.19L0 19.95v-3.2A.75.75 0 0 1 .75 16zM14.855 1.905a7.705 7.705 0 0 1 7.704 7.705v3.273a7.705 7.705 0 0 1-15.409 0V9.61a7.705 7.705 0 0 1 7.705-7.705zm2.293 5.404c-.87.898-.563 3.528-6.29 1.892-.937-.469-1.673-.334-2.208.405v3.277a6.205 6.205 0 0 0 12.41 0v-2.76c-1.547.316-2.851-.622-3.912-2.814zm.255 6.869a.75.75 0 0 1 .75.75c0 1.397-1.483 2.386-3.204 2.386-1.72 0-3.205-.989-3.205-2.386a.75.75 0 0 1 1.494-.102l.006.102c0 .41.715.886 1.705.886.932 0 1.62-.421 1.697-.813l.007-.073a.75.75 0 0 1 .75-.75zm-4.909-3.68c.452 0 .819.366.819.817v.819a.818.818 0 1 1-1.637 0v-.819c0-.451.367-.818.818-.818zm4.91 0c.451 0 .818.366.818.817v.819a.818.818 0 1 1-1.637 0v-.819c0-.451.367-.818.818-.818zM5.55 0a.75.75 0 0 1 .102 1.493L5.55 1.5h-3.2a.85.85 0 0 0-.843.743L1.5 2.35v3.2a.75.75 0 0 1-1.493.102L0 5.55v-3.2A2.35 2.35 0 0 1 2.19.005L2.35 0h3.2zm22.08 0a2.35 2.35 0 0 1 2.345 2.19l.005.16v3.2a.75.75 0 0 1-1.493.102l-.007-.102v-3.2a.85.85 0 0 0-.743-.843L27.63 1.5h-3.2a.75.75 0 0 1-.102-1.493L24.43 0h3.2z"/>
            </clipPath>
        </defs>
        <g clip-path="url(#q9z8xjssla)" transform="translate(-1662 -831)">
            <g clip-path="url(#k7hmjzuk5b)">
                <g clip-path="url(#zk7bv86g1c)" transform="translate(1665 833)">
                  <path fill={props.color} opacity={props.opacity} d="M0 0h29.98v22.3H0V0z"/>
                </g>
            </g>
        </g>
      </svg>
  );
};

export default Face;
