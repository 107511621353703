import Breadcrumb, {
  getCurrentBreadcrumb,
  getPagesBreadcrumbs,
  IBreadcrumbItem,
  IExtraPage
} from "../../../ClientView/Breadcrumb";
import { IClientViewGalleryState } from "../../../ClientView/models";
import { IPage, IPhotographerFoldersState, IZenSiteTheme } from "../../../../models/models";
import React, { PureComponent } from "react";

export interface IBreadcrumBarProps {
  subdomain: string;
  siteTheme: IZenSiteTheme;
  sitePages: IPage[];
  extraPages?: IExtraPage[];
  pageAlias?: string;
  isPublished: boolean;
  isDevelopment: boolean;
  rootFolderId: string;
  folderId: string;
  isFolderView: boolean;
  clientViewGallery: IClientViewGalleryState;
  photographerFolders: IPhotographerFoldersState;

  breadcrumbItems?: IBreadcrumbItem[];
}

interface IBreadcrumBarState {
  breadcrumbItems: IBreadcrumbItem[];
}

export class ZendBreadcrumBar extends PureComponent<IBreadcrumBarProps, IBreadcrumBarState> {
  constructor(props: IBreadcrumBarProps) {
    super(props);
    this.state = {
      breadcrumbItems: []
    };
  }

  public componentDidMount() {
    if (!this.props.breadcrumbItems && this.props.photographerFolders.folders.length > 0) {
      this.updateBreadcrumbState();
    }
  }

  public componentDidUpdate(prevProps: IBreadcrumBarProps) {
    if (
      !this.props.breadcrumbItems &&
      this.breadcrumbDataIsAvailable(prevProps.photographerFolders, this.props.photographerFolders)
    ) {
      this.updateBreadcrumbState();
    }
  }

  private breadcrumbDataIsAvailable = (
    prevFolders: IPhotographerFoldersState,
    currentFolders: IPhotographerFoldersState
  ) => prevFolders !== currentFolders && currentFolders.folders.length != prevFolders.folders.length;

  public updateBreadcrumbState = () => {
    const {
      sitePages,
      pageAlias,
      subdomain,
      isDevelopment,
      isPublished,
      rootFolderId,
      isFolderView,
      extraPages
    } = this.props;

    const pagesBreadcrumb = getPagesBreadcrumbs(
      sitePages || [],
      pageAlias || "",
      subdomain || "",
      isDevelopment,
      isPublished,
      extraPages
    );

    const breadcrumbItems = getCurrentBreadcrumb(pagesBreadcrumb, this.props, isFolderView, rootFolderId, isPublished);

    this.setState({
      breadcrumbItems: [...breadcrumbItems]
    });
  };

  public render() {
    const { siteTheme, sitePages, breadcrumbItems: breadcrumbItemsFromProps } = this.props;
    const { breadcrumbItems: breadcrumbItemsFromState } = this.state;
    const isBreadcrumbLoaded = !!breadcrumbItemsFromProps || breadcrumbItemsFromState.length > 0;
    const breadcrumbItems = breadcrumbItemsFromProps || breadcrumbItemsFromState;

    return (
      <>
        {isBreadcrumbLoaded && (
          <Breadcrumb siteTheme={siteTheme} show={isBreadcrumbLoaded} sitePages={sitePages} separator=">">
            {breadcrumbItems.map(({ to, label, isFull }) => (
              <a key={to} href={`${!isFull ? window.location.origin : ""}${to}`}>
                {label}
              </a>
            ))}
          </Breadcrumb>
        )}
      </>
    );
  }
}
