import _ from "lodash";
import React, { PureComponent, ReactElement } from "react";
import { Circle, GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";

export interface ICoordinates {
  lat: number;
  lng: number;
}

interface ICircle {
  center: ICoordinates;
  radius: number; // in miles
}

interface IMapProps {
  center?: ICoordinates;
  zoom?: number;
  marker?: ICoordinates;
  circle?: ICircle;
}

class Map extends PureComponent<IMapProps> {
  public componentDidMount() {
    this.fitBounds();
  }

  public componentDidUpdate(prevProps: IMapProps) {
    const getBoundsProps = (props: IMapProps) => ({ marker: props.marker, circle: props.circle, zoom: props.zoom });
    const boundsHaveChanged = !_.isEqual(getBoundsProps(this.props), getBoundsProps(prevProps));

    if (boundsHaveChanged) {
      this.fitBounds();
    }
  }

  public render() {
    const { zoom, center, marker, circle } = this.props;
    const resolvedCenter = center || marker || (circle && circle.center) || undefined;

    return (
      <GoogleMap
        zoom={zoom === undefined ? Map.defaultZoomLevel : zoom}
        center={resolvedCenter}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          gestureHandling: "cooperative"
        }}
        ref={this.googleMapRef}
      >
        {marker && <Marker position={marker} />}
        {circle && (
          <Circle
            center={circle.center}
            radius={circle.radius * 1609.344} // in meters
            options={{
              strokeWeight: 0,
              fillColor: "rgb(255, 90, 0)",
              fillOpacity: 0.27,
              zIndex: 1,
              clickable: false
            }}
            ref={this.circleRef}
          />
        )}
      </GoogleMap>
    );
  }

  private fitBounds() {
    if (this.props.circle && this.props.zoom === undefined) {
      const bounds = new google.maps.LatLngBounds();
      bounds.union(this.circleRef.current!.getBounds());

      if (this.props.marker) {
        bounds.extend(this.props.marker);
      }

      this.googleMapRef.current!.fitBounds(bounds);
    }
  }

  private googleMapRef = React.createRef<GoogleMap>();
  private circleRef = React.createRef<Circle>();

  private static defaultZoomLevel = 11;
}

interface IInjectedDefaultsProps {
  googleMapURL: string;
  loadingElement: ReactElement;
  containerElement: ReactElement;
  mapElement: ReactElement;
}

const withDefaults = <P extends IInjectedDefaultsProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Pick<P, Exclude<keyof P, keyof IInjectedDefaultsProps>>> => {
  // tslint:disable-next-line: max-classes-per-file
  return class WithDefaults extends React.Component<Pick<P, Exclude<keyof P, keyof IInjectedDefaultsProps>>> {
    public render() {
      return (
        <Component
          {...(this.props as P)}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&language=en&region=US`}
          loadingElement={<div style={{ height: "100%" }} />}
          containerElement={<div style={{ height: "100%" }} />}
          mapElement={<div style={{ height: "100%" }} />}
        />
      );
    }
  };
};

export default withDefaults(withScriptjs(withGoogleMap(Map)));
