import { Utilities } from "@zenfolio/core-components";
import * as React from "react";
import { IInvoiceInfo } from "../../../../../../store/widget/model";
import styles from "./index.module.scss";

interface ICustomerInfoProps {
  invoice: IInvoiceInfo;
}

class CustomerInfo extends React.Component<ICustomerInfoProps> {
  public render() {
    const { serviceInfo, paymentActionInfo, customerInfo } = this.props.invoice;
    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.title}>
            <p>{`Hi ${customerInfo.name}!`}</p>
          </div>
          <div>
            {`Use this form to pay for the balance of $${Utilities.formatNumber(
              paymentActionInfo.totalDueToday
            )} of your ${serviceInfo.name} with ${this.getBusinessName()}`}
            .
          </div>
        </div>
      </div>
    );
  }

  private getBusinessName = () => {
    const { photographerInfo } = this.props.invoice;

    if (!photographerInfo.businessName) {
      return photographerInfo.firstName + " " + photographerInfo.lastName;
    }

    return photographerInfo.businessName;
  };
}

export default CustomerInfo;
