import cx from "classnames";
import React, { PureComponent } from "react";
import enhanceWithClickOutside from "react-click-outside";
import styles from "./timePicker.module.scss";

export interface IOptions {
  text: string;
  id: number;
}

interface ITimePickerProps {
  onChange?: (value: number, callback?: () => void) => void;
  onClickOutside?: () => void;
  time?: number;
  options: IOptions[];
  onAfterChange: () => void;
}

interface ITimeItem {
  item: IOptions;
  style: string;
  onChange?: (value: number, callback?: () => void) => void;
  onAfterChange: () => void;
  onClickOutside?: () => void;
}

const TimeItem: React.FC<ITimeItem> = ({ item, style, onChange, onAfterChange, onClickOutside }) => {
  function handleChange() {
    if (onChange) {
      if (onClickOutside) {
        onClickOutside();
      }
      onChange(item.id, onAfterChange);
    }
  }
  return (
    <div className={style} key={item.id} onClick={handleChange}>
      {item.text}
    </div>
  );
};

class TimePicker extends PureComponent<ITimePickerProps> {
  public handleClickOutside(evt: any) {
    evt.stopPropagation();
    const { onClickOutside } = this.props;
    if (onClickOutside) {
      onClickOutside();
    }
  }

  public render() {
    const { options, onChange, time } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.timePicker}>
          {options.map(t => {
            const itemStyle = cx(styles.timeItem, {
              [styles.timeItemActive]: t.id === time
            });

            return (
              <TimeItem
                key={t.id}
                item={t}
                onChange={onChange}
                style={itemStyle}
                onAfterChange={this.props.onAfterChange}
                onClickOutside={this.props.onClickOutside}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default enhanceWithClickOutside(TimePicker);
