import React from "react";
import { ICanvasPhotoProps } from ".";
import styles from "./canvasPrint.module.scss";

class CanvasNaturalWrapPreview extends React.PureComponent<ICanvasPhotoProps> {
  constructor(props: ICanvasPhotoProps) {
    super(props);
  }

  render() {
    const {
      printWidthInPixel,
      printHeightInPixel,
      canvasWrapBorderInPixel,
      canvasWrapBorderInPixelForBothSide,
      renderImage
    } = this.props;
    return (
      <>
        <defs>
          <clipPath id="trim-option-preview" transform="">
            <rect
              x={canvasWrapBorderInPixel}
              y={canvasWrapBorderInPixel}
              width={printWidthInPixel - canvasWrapBorderInPixelForBothSide}
              height={printHeightInPixel - canvasWrapBorderInPixelForBothSide}
            ></rect>
          </clipPath>
        </defs>
        <g className={styles.borderGElement}>
          <g clip-path="url(#trim-option-preview)">{renderImage}</g>
        </g>
      </>
    );
  }
}

export default CanvasNaturalWrapPreview;
