import React, { PureComponent } from "react";
import { getUUID } from "utilities/blocks/site";
import { IPage, NodeType, Orientation } from "./interface";
import { pageMapping } from "./utils";

interface ICardThumbnailProps {
  data: IPage;
  className?: string;
  style?: object;
  trimOptionSVGUrl?: string;
}
interface ICardThumbnailState {
  haveTrimOption: boolean;
}

class CardThumbnail extends PureComponent<ICardThumbnailProps, ICardThumbnailState> {
  private page: IPage;
  private id: string;

  constructor(props: ICardThumbnailProps) {
    super(props);
    this.id = getUUID();
    this.page = pageMapping(this.props.data);
    this.state = {
      haveTrimOption: true
    };
  }

  private loadTrimOption = (ref: SVGClipPathElement) => {
    if (!this.props.trimOptionSVGUrl) {
      this.setState({
        haveTrimOption: false
      });
      return;
    }
    try {
      fetch(this.props.trimOptionSVGUrl)
        .then(res => res.text())
        .then(svgContent => {
          this.setState({
            haveTrimOption: true
          });
          this.processTrimOption(ref, svgContent);
        });
    } catch (error) {
      this.setState({
        haveTrimOption: false
      });
    }
  };

  private processTrimOption = (ref: SVGClipPathElement, svgContent: string) => {
    if (!ref || !svgContent) {
      return;
    }
    const refElem = (ref as unknown) as HTMLElement;
    if (!refElem) {
      return;
    }
    refElem.innerHTML = svgContent;
    const path = ((ref as unknown) as HTMLElement).querySelector("g path, g rect");
    refElem.innerHTML = path?.outerHTML || "";
    const svgPath = (refElem.childNodes[0] as unknown) as SVGPathElement;
    if (!svgPath) {
      return;
    }
    const bbox = svgPath.getBBox();
    const cardWidth = (this.page || {}).width || 0;
    const cardHeight = (this.page || {}).height || 0;
    let ratiox = cardWidth / bbox.width;
    let ratioy = cardHeight / bbox.height;

    let translateX = -bbox.x * ratiox;
    let translateY = -bbox.y * ratioy;
    let scaleX = cardWidth / bbox.width;
    let scaleY = cardHeight / bbox.height;

    if (this.page.orientation === Orientation.LANDSCAPE) {
      scaleX = cardHeight / bbox.width;
      scaleY = cardWidth / bbox.height;
      ratiox = cardHeight / bbox.width;
      ratioy = cardWidth / bbox.height;
      translateX = -bbox.x * ratiox;
      translateY = -bbox.y * ratioy;
      refElem.setAttribute(
        "transform",
        `
            translate(${(cardWidth - cardHeight) / 2}, ${(cardWidth - cardHeight) / 2})
            rotate(90 ${cardWidth / 2} ${cardHeight / 2})
          `
      );
    } else {
      refElem.setAttribute("transform", "");
    }

    svgPath.setAttribute("transform", `translate(${translateX}, ${translateY}) scale(${scaleX}, ${scaleY})`);
  };

  render() {
    const { className, style, trimOptionSVGUrl } = this.props;
    const viewports = this.page.nodes.filter(node => node.nodeType === NodeType.PHOTO);
    const texts = this.page.nodes.filter(node => node.nodeType === NodeType.TEXT);
    if (this.page.svg) {
      return <div ref={ref => ref && (ref.innerHTML = this.page.svg)}></div>;
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${this.page.width || 1000} ${this.page.height || 1000}`}
        preserveAspectRatio="xMidYMid meet"
        className={className || ""}
        style={style || {}}
      >
        <defs>
          {trimOptionSVGUrl && this.state.haveTrimOption ? <clipPath id={this.id} ref={this.loadTrimOption} /> : null}
        </defs>
        <g clipPath={`url(#${this.id})`}>
          {(viewports || []).map((viewport, index) => {
            const { savedData } = viewport;
            return (
              <svg
                key={"viewport_" + index}
                className="viewport"
                width={viewport?.position?.width || 0}
                height={viewport?.position?.height || 0}
                x={viewport?.position?.left || 0}
                y={viewport?.position?.top || 0}
                style={{ overflow: "hidden" }}
              >
                <g
                  className="svg-pan-zoom_viewport"
                  transform={`matrix(${savedData?.cropping.zoom},0,0,${savedData?.cropping.zoom},${savedData?.cropping
                    .x || 0},${savedData?.cropping.y || 0})`}
                  style={{
                    transform: `matrix(${savedData?.cropping.zoom},0,0,${savedData?.cropping.zoom},${savedData?.cropping
                      .x || 0},${savedData?.cropping.y || 0})`
                  }}
                >
                  <image
                    xlinkHref={savedData?.cropping?.src || savedData?.image || ""}
                    data-id={viewport.id}
                    className="img-loaded"
                    width={savedData?.cropping.width || 0}
                    height={savedData?.cropping.height || 0}
                  />
                </g>
              </svg>
            );
          })}
          <image width="100%" id="img-mask" height="100%" xlinkHref={this.page.templateUrl} />
        </g>
        <g id="texts_editor">
          {texts.map((text, index) => {
            return <g key={"group_" + index} ref={ref => ref && (ref.innerHTML = text.textSvg || "")} />;
          })}
        </g>
      </svg>
    );
  }
}

export default CardThumbnail;
