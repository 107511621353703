import cx from "classnames";
import React from "react";
import styles from "./breadcrumbItem.module.scss";

export interface IBreadcrumbItemsProps {
  className?: string;
  last?: boolean;
}

const BreadcrumbItem: React.FC<IBreadcrumbItemsProps> = props => {
  return (
    <li
      className={cx(styles.breadcrumbItemContainer, props.className, {
        [styles.last]: props.last
      })}
    >
      {props.children}
    </li>
  );
};

export default BreadcrumbItem;
