import cx from "classnames";
import React from "react";
import styles from "./textInput.module.scss";

export interface IProps {
  id?: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value?: string;
  icon?: string;
  height?: number;
  iconHeight?: number;
  iconPaddingTop?: number;
  textPaddingLeft?: number;
  textPaddingTop?: number;
  textPaddingBottom?: number;
  styles?: any;
  readonly?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  ref?: React.Ref<HTMLInputElement>;
  errorMessage?: string | null | undefined;
  className?: string;
  errorClassName?: string;
  tabIndex?: number;
  maxLength?: number;
}

const TextInput: React.FC<IProps> = React.forwardRef((props: IProps, ref?: React.Ref<HTMLInputElement>) => {
  let iconStyles = {};
  if (props.icon) {
    iconStyles = {
      background: `url(${props.icon}) no-repeat scroll 17px ${props.iconPaddingTop}px / ${props.iconHeight}px`,
      paddingLeft: `${props.textPaddingLeft}px`,
      paddingTop: `${props.textPaddingTop}px`,
      paddingBottom: `${props.textPaddingBottom}px`
    };
  }

  if (props.height) {
    iconStyles = { ...iconStyles, height: `${props.height}px` };
  }
  if (props.styles) {
    iconStyles = {
      ...iconStyles,
      ...props.styles
    };
  }

  return (
    <>
      <input
        id={props.id}
        className={cx(styles.textInput, props.className)}
        style={iconStyles}
        type={props.type || "text"}
        onChange={props.onChange}
        placeholder={props.placeholder || ""}
        value={props.value}
        disabled={props.disabled}
        onBlur={props.onBlur ? props.onBlur : () => null}
        onFocus={props.onFocus ? props.onFocus : () => null}
        ref={ref ? ref : null}
        readOnly={props.readonly || false}
        maxLength={props.maxLength}
        tabIndex={props.tabIndex}
        onKeyDown={props.onKeyDown}
      />
      {props.errorMessage && <div className={cx(styles.error, props.errorClassName)}>{props.errorMessage}</div>}
    </>
  );
});
export default TextInput;
