import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Widget from "../../components/widget";
import { showWidgetErrorModal } from "../../store/modal/actions";
import { IWidgetErrorModalProps } from "../../store/modal/model";
import { IAppState } from "../../store/state";
import {
  doLoadInvoiceState,
  doLoadWidgetState,
  doResetBookingError,
  doResetWidgetState
} from "../../store/widget/actions";
import { getFirstError } from "./selectors";

function mapStateToProps({ widget }: IAppState) {
  return {
    loadInvoiceInfo: widget.loadInvoiceInfo,
    isBookingCreating: widget.createBookingState.status === "Pending",
    isInvoicePaying: widget.payInvoiceState.status === "Pending",
    error: getFirstError([
      widget.loadWidgetInfoState.error,
      widget.loadAvailability.error,
      widget.validateLocation.error,
      widget.createBookingState.error,
      widget.loadInvoiceInfoState.error,
      widget.payInvoiceState.error
    ])
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    onLoadWidgetState: () => dispatch(doLoadWidgetState()),
    onLoadInvoiceState: (invoiceId: string) => dispatch(doLoadInvoiceState(invoiceId)),
    onResetWidget: () => dispatch(doResetWidgetState()),
    onResetBookingError: (failureCode: string) => dispatch(doResetBookingError(failureCode)),
    onWidgetError: (error: IWidgetErrorModalProps) => dispatch(showWidgetErrorModal(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
