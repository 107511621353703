import { Moment } from "moment";

export function changeDate(dateTime: Moment, newDate: Moment) {
  return changeTime(newDate, getTotalMinutes(dateTime));
}

export function changeTime(dateTime: Moment, newTime: number) {
  return dateTime
    .clone()
    .startOf("day")
    .minutes(newTime);
}

export function roundTime(dateTime: Moment, step: number, round: "up" | "down") {
  let totalMinutes = getTotalMinutes(dateTime);
  totalMinutes = (round === "down" ? Math.floor(totalMinutes / step) : Math.ceil(totalMinutes / step)) * step;
  return changeTime(dateTime, totalMinutes);
}

export function getTotalMinutes(time: Moment) {
  return time.hours() * 60 + time.minutes();
}
