// We have 3 kind of photos:
// Preview Photo: photo is used to display on paper print and cropper component.
//                There resolution will vary according to the desktop/mobile screen
//                On desktop: preview photo will has high resolution
//                On mobile: preview photo will has low resolution
//                to maximize render performance
//
// Thumbnail photo: photo is generated for cart item preview, with lowest resolution, and stored on S3
// Original Photo: the original photo that user uploaded, with largest resolution
//                 which is using for resolution and spaces validation

export enum MAT_COLOR {
  None = "None",
  Black = "Black",
  White = "White"
}

export interface IGalleryPhoto {
  id: string;
  src: string;
  width?: number;
  height?: number;
}
export interface IEditablePhoto {
  id: string;
  index?: number;
  src: string;
  width?: number;
  height?: number;
  cropping?: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
}

export interface IFrame {
  name?: string;
  /** Frame Id */
  id: string;
  /** Frame url used to get the frame fragment png */
  frameKey: string;
  /** Original frame width (pixel) */
  frameWidth: number;
  /** Small preview of frame in png */
  thumbnail?: string;
  /** Direct url of frame fragment */
  edgesSrc?: {
    top: string;
    left: string;
    bottom: string;
    right: string;
  };
}

export interface IEdgeWrap {
  id: string;
  name: string;
  canvasWrapType: CANVAS_WRAP_TYPE;
  canvasWrapBorder: number; // per inch
  isShoppingCartItem?: boolean;
}

export enum CANVAS_WRAP_TYPE {
  PHOTO_WRAP = "photoWrap",
  BLACK_WRAP = "blackWrap",
  WHITE_WRAP = "whiteWrap"
}

export const CANVAS_WRAP_WIDTH_DEFAULT = 2.125;
export interface IMat {
  id: string;
  color: MAT_COLOR;
  /** Original mat width (pixel) */
  matWidth: number;
  matHeight?: number;
}

export interface IProduct {
  id: string;
  width: number; // pixel
  height: number; // pixel
  name: string;
}

export const PIXEL_PER_INCH = 96;

export const BEAUTY_ICON_CLASSES = {
  ICON_BED: "BED",
  ICON_DESK: "TABLE",
  ICON_SOFA_ON: "SOFA",
  ICON_WRITTING_DESK: "WRITINGDESK",
  ICON_FIREPLACE: "FIREPLACE",
  ICON_BOOKCASE: "BOOKCASE"
};

export const IMAGE_TITLES = ["frames", "glass", "mat"];
