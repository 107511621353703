import { IEmbedSettingsState } from "../../store/activation/embed/model";
import { apiType } from "../apiType";
import { apiClient } from "../index";

export interface ILoadActivationEmbedStateResponse extends IEmbedSettingsState {}

export interface IUpdateActivationEmbedStateRequest {
  invoicePaymentUrl: string;
}

export interface IApiActivationEmbed {
  loadActivationEmbedState: () => Promise<ILoadActivationEmbedStateResponse>;
  updateActivationEmbedState: (request: IUpdateActivationEmbedStateRequest) => Promise<void>;
}

const api: IApiActivationEmbed = {
  loadActivationEmbedState: () =>
    apiClient.get<ILoadActivationEmbedStateResponse>(`${apiType.bookingWidget}photographer/widget/export/settings`),
  updateActivationEmbedState: (request: IUpdateActivationEmbedStateRequest) =>
    apiClient.put<void>(`${apiType.bookingWidget}photographer/widget/export/setup`, request)
};

export default api;
