import { IPlaybackStatsRecord } from "components/Video/Player";
import { useEffect, useRef } from "react";
import { IAnalyticParams } from ".";
import { Analytics } from "./Analytics";

export interface IUseAnalyticsProps {
  video: { id: string };
  analyticsParams?: IAnalyticParams;
  onPlaybackStats?: (record: IPlaybackStatsRecord) => void;
}

export default function useAnalytics(props: IUseAnalyticsProps): (record: IPlaybackStatsRecord) => void {
  const analyticsRef = useRef<Analytics>();
  if (!analyticsRef.current) {
    analyticsRef.current = Analytics.createApi(props.video.id, props.analyticsParams);
  }

  useEffect(onUpdate);

  function onUpdate() {
    analyticsRef.current!.update(props.video.id, props.analyticsParams);
  }

  function onPlaybackStats(record: IPlaybackStatsRecord) {
    props.onPlaybackStats?.(record);
    analyticsRef.current!.pushRecord(record);
  }

  return onPlaybackStats;
}
