import { AddressInputValue } from "@zenfolio/core-components/dist/components/AddressInput/model";
import { IAddressQuery } from "@zenfolio/core-components/dist/models/location";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import AddressSelection, { IAddressSelectionProps } from "../../components/addressSelection";
import { ICoordinates } from "../../components/addressSelection/map";
import {
  clearSearchAddressPredictions as clearSearchAddressPredictionsAction,
  doLoadPlaceDetailsFromIp,
  doLoadPlaceDetailsFromLocation,
  doLoadPlaceDetailsFromLocationOrIpReset,
  loadPlaceDetails as loadPlaceDetailsAction,
  resetSearchAddress as resetSearchAddressAction,
  searchAddress as searchAddressAction
} from "../../store/location/actions";
import { IShootLocation } from "../../store/profile/shootLocation/model";
import { IAppState } from "../../store/state";

export interface IAddressSelectionOwnProps {
  onAddressChanged?: (address: AddressInputValue) => void;
  onAddressDetailsChanged?: (addressDetails: string | null) => void;
  onValidate?: (address: AddressInputValue) => string;
  shootLocation: IShootLocation | null;
  className?: string;
  formClassName?: string;
  fieldClassName?: string;
  locationClassName?: string;
  locationErrorClassName?: string;
  errorClassName?: string;
  descriptionLabelClassName?: string;
  title: string;
  icon?: string;
  addressPlaceholder: string;
  descriptionPlaceholder: string;
  locationEmptyError: string;
  defaultAddress: AddressInputValue | undefined;
  defaultDetails: string | null;
  coordinate: ICoordinates;
  radius: number;
  forceShowingError?: boolean;
  showMap?: boolean;
  descriptionLabel?: string;
  validationInProgress?: boolean;
  showDescriptionCharCount?: boolean;
  descriptionMaxLength?: number;
}

function mapStateToProps({
  location: { searchAddress, loadPlaceDetails, loadPlaceDetailsFromLocationOrIpState }
}: IAppState) {
  return {
    searchAddress,
    loadPlaceDetails,
    geoCodePlaceDetails: {
      placeDetails: loadPlaceDetailsFromLocationOrIpState.placeDetails,
      loadFromLocationStatus: loadPlaceDetailsFromLocationOrIpState.loadFromLocation,
      loadFromIpStatus: loadPlaceDetailsFromLocationOrIpState.loadFromIpStatus
    }
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    searchAddress: {
      onSearch: (query: IAddressQuery) => dispatch(searchAddressAction(query, true)),
      onReset: () => dispatch(resetSearchAddressAction()),
      onClearPredictions: () => dispatch(clearSearchAddressPredictionsAction())
    },
    loadPlaceDetails: {
      onLoad: (placeId: string) => dispatch(loadPlaceDetailsAction(placeId))
    },
    geoCodePlaceDetails: {
      loadPlaceDetailsFromLocation: (location: ICoordinates) => dispatch(doLoadPlaceDetailsFromLocation(location)),
      loadPlaceDetailsFromIp: () => dispatch(doLoadPlaceDetailsFromIp()),
      loadPlaceDetailsFromLocationOrIpReset: () => dispatch(doLoadPlaceDetailsFromLocationOrIpReset())
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: IAddressSelectionOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    searchAddress: { ...stateProps.searchAddress, ...dispatchProps.searchAddress },
    loadPlaceDetails: { ...stateProps.loadPlaceDetails, ...dispatchProps.loadPlaceDetails },
    geoCodePlaceDetails: { ...stateProps.geoCodePlaceDetails, ...dispatchProps.geoCodePlaceDetails }
  } as IAddressSelectionProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddressSelection);
