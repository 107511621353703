export enum ActionType {
  SEARCH_ADDRESS_RESET = "LOCATION_SEARCH_ADDRESS_RESET",
  SEARCH_ADDRESS_CLEAR_PREDICTIONS = "LOCATION_SEARCH_ADDRESS_CLEAR_PREDICTIONS",
  SEARCH_ADDRESS_STARTED = "LOCATION_SEARCH_ADDRESS_STARTED",
  SEARCH_ADDRESS_SUCCESS = "LOCATION_SEARCH_ADDRESS_SUCCESS",
  SEARCH_ADDRESS_ERROR = "LOCATION_SEARCH_ADDRESS_ERROR",

  LOAD_PLACE_DETAILS_STARTED = "LOCATION_LOAD_PLACE_DETAILS_STARTED",
  LOAD_PLACE_DETAILS_SUCCESS = "LOCATION_LOAD_PLACE_DETAILS_SUCCESS",
  LOAD_PLACE_DETAILS_ERROR = "LOCATION_LOAD_PLACE_DETAILS_ERROR",

  LOAD_PLACE_DETAILS_RESET = "LOAD_PLACE_DETAILS_RESET",

  LOAD_PLACE_DETAILS_FROM_LOCATION_STARTED = "LOAD_PLACE_DETAILS_FROM_LOCATION_STARTED",
  LOAD_PLACE_DETAILS_FROM_LOCATION_SUCCESS = "LOAD_PLACE_DETAILS_FROM_LOCATION_SUCCESS",
  LOAD_PLACE_DETAILS_FROM_LOCATION_ERROR = "LOAD_PLACE_DETAILS_FROM_LOCATION_ERROR",

  LOAD_PLACE_DETAILS_FROM_IP_STARTED = "LOAD_PLACE_DETAILS_FROM_FROM_IP_STARTED",
  LOAD_PLACE_DETAILS_FROM_IP_SUCCESS = "LOAD_PLACE_DETAILS_FROM_FROM_IP_SUCCESS",
  LOAD_PLACE_DETAILS_FROM_IP_ERROR = "LOAD_PLACE_DETAILS_FROM_FROM_IP_ERROR"
}
