import cx from "classnames";
import { IPhotoMasonry } from "components/blocks/blockModels";
import Button from "components/Button";
import { IClientViewGalleryState } from "components/ClientView/models";
import AlbumLock from "components/Icons/AlbumLock";
import PasswordEyeIcon from "components/Icons/PasswordEye";
import { IToaster } from "components/Toaster";
import { IZenSiteTheme } from "../../../../models/models";
import { PageContext } from "utilities/pageContext";
import React, { CSSProperties, PureComponent } from "react";
import { getSiteFonts } from "utilities/blocks/site";
import colors from "utilities/colors";
import { Validation } from "utilities/validation";
import styles from "./protected.module.scss";
import { formatPhotoUrl, formatThumbnailUrl, MESizeMethod } from "utilities/getImgUrl";
import { FULL_SCREEN_SIZE_CODE } from "utilities/constant";

export interface IDynamicGalleryProps {
  siteTheme: IZenSiteTheme;
  galleryId: string;
  clientViewGallery: IClientViewGalleryState;
  onConfirmClick: (password: string, email: string) => void;
  onShowAlertError: (toaster: IToaster) => void;
}

interface IDynamicGalleryState {
  images: IPhotoMasonry[];
  hidePassword: boolean;
  password: string;
  email: string;
  emailError: string;
}

class DynamicGalleryProtectedView extends PureComponent<IDynamicGalleryProps, IDynamicGalleryState> {
  static contextType = PageContext;
  declare context: React.ContextType<typeof PageContext>;

  constructor(props: IDynamicGalleryProps) {
    super(props);

    this.state = {
      images: [],
      hidePassword: true,
      password: "",
      email: "",
      emailError: ""
    };
  }

  public componentDidMount() {
    if (this.context.setIsLoginActive) {
      this.context.setIsLoginActive(true);
    }
  }

  public componentWillUnmount() {
    if (this.context.setIsLoginActive) {
      this.context.setIsLoginActive(false);
    }
  }

  private isButtonDisabled = () => {
    const { clientViewGallery } = this.props;
    if (clientViewGallery.isProtected && this.state.password === "") {
      return true;
    }
    if (clientViewGallery.isVisitorEmailRequired && (this.state.email === "" || this.state.emailError !== "")) {
      return true;
    }
    return false;
  };

  private togglePasswordView = () => {
    this.setState((prevState: IDynamicGalleryState) => ({
      hidePassword: !prevState.hidePassword
    }));
  };

  private handleConfirmClick = () => {
    if (!this.isButtonDisabled()) {
      this.props.onConfirmClick(this.state.password, this.state.email);
    }
  };

  private handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      if (this.state.email === "") {
        this.setState({ emailError: "Email is invalid" });
        return;
      } else if (!this.isButtonDisabled()) {
        this.props.onConfirmClick(this.state.password, this.state.email);
      }
    }
  };

  private onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.target.value });
  };

  private onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  private onEmailBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.state.email === "" && this.componentDidUpdate) {
      this.setState({ emailError: "Email is invalid" });
      return;
    }

    if (!Validation.isValidMail(this.state.email.trim())) {
      this.setState({ emailError: "Email is invalid" });
      return;
    } else {
      const emailArray = this.state.email.split("@");
      if (emailArray[0].length > 64) {
        this.setState({ emailError: "Email is invalid" });
        return;
      }

      if (emailArray[1].length > 255) {
        this.setState({ emailError: "Email is invalid" });
        return;
      }
    }

    this.setState({ emailError: "" });
  };

  private getInstructionsText = () => {
    const { clientViewGallery } = this.props;

    if (clientViewGallery.isVisitorEmailRequired && !clientViewGallery.isProtected) {
      return "Enter your email to view this gallery.";
    } else if (!clientViewGallery.isVisitorEmailRequired && clientViewGallery.isProtected) {
      return "Enter the provided gallery password to view this gallery.";
    } else {
      return "Enter your email and the provided password to view this gallery.";
    }
  };

  public render() {
    const { clientViewGallery, siteTheme } = this.props;
    const { hidePassword, password, email, emailError } = this.state;
    const heroImageUrl =
      clientViewGallery.coverPhoto && clientViewGallery.heroImageUrlTemplate
        ? formatPhotoUrl(
            clientViewGallery.heroImageUrlTemplate,
            clientViewGallery.coverPhoto,
            FULL_SCREEN_SIZE_CODE,
            MESizeMethod.Contain
          )
        : clientViewGallery.coverVideo && clientViewGallery.thumbnailUrlTemplate
        ? formatThumbnailUrl(
            clientViewGallery.thumbnailUrlTemplate || "",
            clientViewGallery.coverVideo,
            FULL_SCREEN_SIZE_CODE,
            MESizeMethod.Contain
          )
        : "";

    const isWhiteAccent = siteTheme.accentColor.value === colors.white;
    const buttonStyles: CSSProperties = {
      backgroundColor: siteTheme.accentColor.value,
      color: isWhiteAccent ? colors.black : undefined
    };
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    return (
      <div
        id="container"
        className={cx(styles.container, styles[siteFonts.primary], {
          [styles.isPreview]: this.context.isPreview
        })}
      >
        {!this.context.isPreview && (
          <style>
            {`
            body {
              overflow:hidden
            }
            `}
          </style>
        )}
        <style>
          {`
            ${
              !this.context.isPreview
                ? `
            body {
              overflow:hidden;
            } `
                : ""
            }

            :root {
              --navbar-height: ${this.context.navBarHeight}px;
            }
            `}
        </style>
        <div className={styles.content}>
          <div className={styles.heroImageContainer} style={{ backgroundImage: `url(${heroImageUrl})` }}>
            {clientViewGallery.isProtected && (
              <div className={styles.albumLock}>
                <AlbumLock color={colors.darkGrey} size={24} />
              </div>
            )}
            <div className={styles.albumTitleContainer}>
              <div className={cx(styles.albumName, styles.spectral)}>{clientViewGallery.name}</div>
              <div className={styles.form}>
                <div className={styles.instructions}>{this.getInstructionsText()}</div>
                {clientViewGallery.isVisitorEmailRequired && (
                  <div className={styles.inputErrorContainer}>
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        className={cx(styles.input, clientViewGallery.invalidPassword ? styles.inputError : "")}
                        placeholder="Email"
                        onKeyPress={this.handleKeyPress}
                        onChange={this.onEmailChange}
                        onBlur={this.onEmailBlur}
                        value={email}
                        autoFocus={clientViewGallery.isVisitorEmailRequired}
                      />
                    </div>
                    {emailError && <div className={cx(styles.validationError, styles.emailError)}>{emailError}</div>}
                  </div>
                )}
                {clientViewGallery.isProtected && (
                  <div className={styles.inputErrorContainer}>
                    <div className={styles.inputContainer}>
                      <input
                        type={hidePassword ? "password" : "text"}
                        className={cx(styles.input, clientViewGallery.invalidPassword ? styles.inputError : "")}
                        placeholder="Password"
                        onKeyPress={this.handleKeyPress}
                        onChange={this.onPasswordChange}
                        value={password}
                        autoFocus={!clientViewGallery.isVisitorEmailRequired}
                      />
                      <span className={styles.passwordIcon} onClick={this.togglePasswordView}>
                        <PasswordEyeIcon width={24} height={13} color={hidePassword ? "#757575" : "#000000"} />
                      </span>
                    </div>
                    {clientViewGallery.invalidPassword && (
                      <div className={styles.validationError}>
                        The password you entered is incorrect, please try again.
                      </div>
                    )}
                  </div>
                )}
                <div>
                  <Button
                    className={styles.confirmBtn}
                    disabled={this.isButtonDisabled()}
                    onClick={this.handleConfirmClick}
                    styles={!this.isButtonDisabled() ? buttonStyles : undefined}
                  >
                    Enter
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DynamicGalleryProtectedView;
