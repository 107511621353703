import { FormRadio, Utilities } from "@zenfolio/core-components";
import classNames from "classnames";
import React from "react";
import FractionalPriceInput, { IFractionalValue } from "../../../../../../components/fractionalPriceInput";
import styles from "./index.module.scss";

export type Amount = IFractionalValue | null | "full";

interface ISetupProps {
  maxAmount: number;
  onChange: (amount: Amount, valid: boolean) => void;
  className?: string;
}

interface ISetupState {
  amount: Amount;
  valid: boolean;
}

class Setup extends React.Component<ISetupProps, ISetupState> {
  public state: ISetupState = Setup.defaultState;

  public render() {
    const { className, maxAmount } = this.props;
    const { amount, valid } = this.state;
    const fullAmount = amount === "full";
    const limits = `Min. $1 Max. ${Utilities.formatNumberWithCurrency(maxAmount)}`;

    return (
      <div className={classNames(styles.container, className)}>
        <div className={styles.title}>Choose Refund</div>
        <div className={classNames(styles.option, styles.full)}>
          <FormRadio
            className={classNames(styles.radio, fullAmount ? styles.selected : "")}
            name="amount"
            checked={fullAmount}
            onChange={this.onFullRefundChange}
          >
            Full Refund
          </FormRadio>
        </div>
        <div className={classNames(styles.option, styles.partial)}>
          <FormRadio
            className={classNames(styles.radio, fullAmount ? "" : styles.selected)}
            name="amount"
            checked={!fullAmount}
            onChange={this.onPartialRefundChange}
          >
            Partial Refund
          </FormRadio>
        </div>
        <div className={styles.partialAmount}>
          <div className={classNames(styles.label, fullAmount ? styles.disabled : "")}>Amount to refund:</div>
          <div className={styles.valueContainer}>
            <FractionalPriceInput
              value={amount === "full" ? null : amount}
              disabled={fullAmount}
              errorMessage={valid ? null : limits}
              errorMessageClassName={styles.error}
              forceShowingError={true}
              onChange={this.onPartialAmountChange}
              inputClassName={styles.price}
            />
            {valid && <div className={styles.limits}>{limits}</div>}
          </div>
        </div>
      </div>
    );
  }

  private setAmount = (amount: Amount) => {
    const valid =
      amount == null || amount === "full" || (1 <= amount.floatValue && amount.floatValue <= this.props.maxAmount);
    this.setState({ amount, valid }, () => this.props.onChange(amount, valid));
  };

  private onFullRefundChange = () => this.setAmount("full");
  private onPartialRefundChange = () => this.setAmount(null);
  private onPartialAmountChange = (value: IFractionalValue | null) => {
    if (this.state.amount !== "full") {
      this.setAmount(value);
    }
  };

  public static defaultState: ISetupState = { amount: "full", valid: true };
}

export default Setup;
