import React from "react";
import { initializeIconProps } from "../../../utilities/icons";
import IIConProps from "../IIconProps";

const Success = (rawProps: IIConProps) => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`${props.minX} ${props.minY} 25 24`}
    >
      <g fill={props.fill} fillRule="evenodd">
        <path d="M20.263.625c.815-.795 2.184-.837 3.054-.095l-.036-.032c.871.743.923 1.993.119 2.789L10.368 16.184a2.693 2.693 0 0 1-3.848-.045L.546 9.921C-.24 9.104-.17 7.838.7 7.097l-.037.031c.871-.742 2.215-.684 3.002.13l4.037 4.178a1.022 1.022 0 0 0 1.45.023L20.263.625z" />
      </g>
    </svg>
  );
};

export default Success;
