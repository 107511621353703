import "lazysizes";

import React, { PureComponent } from "react";
import {
  COLLECTION,
  GalleryPresentSettingList,
  GalleryPresentSettingType,
  IClientViewCommentingState,
  IClientViewDownloadState,
  IClientViewEcomState,
  IClientViewFavoritesState,
  IClientViewGalleryState,
  IIdentityState,
  IUnreadComment,
  PreviewModes
} from "../../../../components/ClientView/models";

import cx from "classnames";
import { checkBgColor } from "utilities/blocks/blockColors";
import ContextMenu from "utilities/contextMenu";
import colors from "utilities/colors";
import { IBlockPhotoAnimationClasses, IPhotoMasonry } from "components/blocks/blockModels";
import { IVisibleOption } from "../../../../components/MyAccount";
import Actions from "../Actions";
import { Collections } from "../Collections";
import Content from "../Content";
import Footer from "../Footer";
import HeroImage from "../HeroImage";
import People, { IPersonFilter } from "../People";
import iconFacebook from "./icons/facebook.svg";
import iconDownload from "./icons/icon-download-2.svg";
import iconEdit from "./icons/icon-edit.svg";
import iconLike from "./icons/icon-recommend.svg";
import iconInstagram from "./icons/instagram.svg";
// @ts-ignore
import iconMoreOptions from "./icons/icon-more-options.svg";
import iconRename from "./icons/icon-rename.svg";
import iconShare from "./icons/icon-share-2.svg";
import iconMore from "./icons/iconMore.png";

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import { isShoppingAllowedForGallery } from "utilities/helpers";
import { IGalleryCoupon } from "models/coupon";
import { ICurrency, ISetting, PhotoOrVideo } from "../../../../models";
import CouponBanners from "../CouponBanners";
import Description from "../Description";
import styles from "./complete.module.scss";
import { IQuickShopItem } from "./components/QuickShop/Preview";
import iconShop from "./icons/icon-shop.svg";
import iconUserOut from "./icons/icon-user-out.svg";
import iconUser from "./icons/icon-user.svg";
import iconTwitter from "./icons/twitter.svg";
import { getActiveCollection, getCollectionsOptions } from "./utils";

export interface IGalleryCompleteViewProps {
  previewMode?: PreviewModes;
  clientViewGallery: IClientViewGalleryState;
  clientViewFavorites: IClientViewFavoritesState;
  clientViewCommenting?: IClientViewCommentingState;
  clientViewIdentity: IIdentityState;
  clientViewDownloads?: IClientViewDownloadState;
  clientViewEcom: IClientViewEcomState;
  allowFavorite: boolean;
  allowMediaCommenting?: boolean;
  allowPhotoDownload: boolean;
  allowVideoDownload: boolean;
  allowGalleryPhotosDownload: boolean;
  allowGalleryVideosDownload: boolean;
  allowShareGallery: boolean;
  allowShoppingCart?: boolean;
  allowPeopleFilter: boolean;
  getSetting: (settingType: number) => ISetting | undefined;
  loadMenuMenuOptions: () => IVisibleOption[] | undefined;
  heroImage?: PhotoOrVideo;
  heroImageFocalX?: number;
  heroImageFocalY?: number;
  videoAnalyticsBaseType?: string;

  peopleImgs: IPersonFilter[];
  selectedPeople: string[];
  peopleFilteredPhotos: string[];
  selectPerson: (id: string) => void;
  deselectPerson: (id: string) => void;

  images: IPhotoMasonry[];
  showLightbox: boolean;
  contentsRef: React.RefObject<HTMLDivElement>;

  onMediaClick: (e: React.MouseEvent<HTMLElement>, mediaIndex: number) => void;
  onMediaFavoriteClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaDownloadClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaCartClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onDownloadClick: (e: React.MouseEvent<HTMLElement>) => void;
  onShareClick: (e: React.MouseEvent<HTMLElement>) => void;
  onMediaCommentClick?: (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => void;
  onMediaFavoritesViewClick: () => void;
  onMediaCommentsViewClick?: () => void;
  onAccountDisabledClick: () => void;
  onLogoutClick: () => void;
  onHandleCollectionSelected?: (collectionId: string) => void;
  studioName?: string;
  cartTooltip?: JSX.Element;
  cartBadge?: number;
  disabledShop?: boolean;
  onClickButtonCart?: () => void;
  onClickButtonShop?: () => void;
  contactEmail?: string;
  bottomBanner?: JSX.Element;
  currency?: ICurrency;
  countryCode?: string;

  quickShopItems?: IQuickShopItem[];
  onClickQuickShopItem?: (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => void;
  // coupon Props
  couponList?: IGalleryCoupon[];
  animationClasses?: IBlockPhotoAnimationClasses;

  onClickBannerButton?: () => void;

  observerComponent?: JSX.Element;
  handleShowCookieSettings?: () => void;
  isEnabledCookieSettings?: boolean;
}

interface IGalleryState {
  collectionId: string;
}

class GalleryCompleteView extends PureComponent<IGalleryCompleteViewProps, IGalleryState> {
  private Container: any;
  private HeroImage: any;

  constructor(props: IGalleryCompleteViewProps) {
    super(props);
    this.Container = React.createRef();
    this.HeroImage = React.createRef();

    this.state = {
      collectionId: COLLECTION.ALL_MEDIA_ID
    };
  }

  private handleCollectionSelected = (collectionId: string) => {
    this.setState({ collectionId }, () => {
      if (this.props.onHandleCollectionSelected) {
        this.props.onHandleCollectionSelected(this.state.collectionId);
      }
    });
  };

  private handleTopClick = (e: any) => {
    e.preventDefault();
    if (this.Container.current) {
      this.Container.current.scrollTo({
        top: (this.props.heroImage && this.HeroImage.current?.clientHeight) || 0,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  private getFontClassName(fontStyle?: ISetting) {
    switch (fontStyle?.id) {
      case GalleryPresentSettingList.Serif:
        return styles.spectral;
      case GalleryPresentSettingList.SansSerif:
        return styles.oswald;
      default:
        return undefined;
    }
  }

  public render() {
    const {
      clientViewGallery,
      clientViewFavorites,
      clientViewDownloads,
      clientViewCommenting,
      clientViewIdentity,
      clientViewEcom,
      getSetting,
      heroImage,
      heroImageFocalX,
      heroImageFocalY,
      peopleImgs,
      images,
      showLightbox,
      allowFavorite,
      allowMediaCommenting,
      allowPhotoDownload,
      allowVideoDownload,
      allowShoppingCart,
      currency,
      onMediaClick,
      onMediaFavoriteClick,
      onMediaDownloadClick,
      onMediaCartClick,
      onMediaFavoritesViewClick,
      onMediaCommentsViewClick,
      onAccountDisabledClick,
      onMediaCommentClick,
      onLogoutClick,
      loadMenuMenuOptions,
      studioName,
      cartBadge,
      cartTooltip,
      disabledShop,
      onClickButtonCart,
      onClickButtonShop,
      contactEmail,
      onDownloadClick,
      onShareClick,
      allowGalleryPhotosDownload,
      allowGalleryVideosDownload,
      allowShareGallery,
      selectedPeople,
      selectPerson,
      deselectPerson,
      peopleFilteredPhotos,
      allowPeopleFilter,
      animationClasses,
      contentsRef,
      onClickBannerButton,
      quickShopItems,
      onClickQuickShopItem,
      videoAnalyticsBaseType,
      handleShowCookieSettings,
      isEnabledCookieSettings
    } = this.props;

    const myDate: string =
      clientViewGallery.shootDate || clientViewGallery.dateModified || clientViewGallery.dateCreated;
    const hasFavorites = clientViewFavorites.favoritePhotos.length > 0 || clientViewFavorites.favoriteVideos.length > 0;
    const hasUnreadComments = clientViewCommenting?.commentedMedia.some(
      (cp: IUnreadComment) => cp.hasUnreadComment && cp.hasCommented
    );

    const hasPhotos = clientViewGallery.photoCount > 0 || !!clientViewGallery.photos?.length;
    const hasVideos = clientViewGallery.videoCount > 0 || !!clientViewGallery.videos?.length;

    const canDownloadGalleryPhotos = allowGalleryPhotosDownload && hasPhotos;
    const canDownloadGalleryVideos = allowGalleryVideosDownload && hasVideos;

    const titleStyle = getSetting(GalleryPresentSettingType.TitleStyle);
    const fontStyle = getSetting(GalleryPresentSettingType.FontStyle);
    const color = getSetting(GalleryPresentSettingType.GridBackgroundColor);
    const hideTitle = getSetting(GalleryPresentSettingType.HideTitle);
    const hideDate = getSetting(GalleryPresentSettingType.HideDate);
    const showCaptions = getSetting(GalleryPresentSettingType.ShowCaptions);
    const descriptionText = showCaptions?.value === "true" ? clientViewGallery.description : undefined;
    const bgColor: string = color && color.value ? color.value.toLowerCase() : "";
    const accentColor = bgColor ? checkBgColor(bgColor.toLowerCase()) : "";
    const style = {
      color: accentColor,
      opacity: color && color.id === GalleryPresentSettingList.Black ? 1 : 0.3
    };
    const footerColor =
      bgColor === colors.white
        ? "#f9f9f9"
        : bgColor === colors.creme
        ? colors.white
        : bgColor === colors.grayNav
        ? "#4a4a4a"
        : bgColor === colors.black
        ? colors.grayNav
        : colors.white;
    const footerTextColor =
      bgColor === colors.white
        ? colors.darkGrey
        : bgColor === colors.creme
        ? colors.grayLightTextNav
        : bgColor === colors.grayNav
        ? colors.grayLightTextNav
        : bgColor === colors.black
        ? colors.grayLightTextNav
        : colors.white;
    const lineColor =
      bgColor === colors.white
        ? colors.lighGrey
        : bgColor === colors.creme
        ? colors.lighGrey
        : bgColor === colors.grayNav
        ? "#454545"
        : bgColor === colors.black
        ? "#454545"
        : colors.white;
    const downloadColor =
      bgColor === colors.white
        ? colors.grayTextNav
        : bgColor === colors.creme
        ? colors.grayTextNav
        : bgColor === colors.grayNav
        ? colors.white
        : colors.white;
    const titlesColor =
      bgColor === colors.white
        ? colors.black
        : bgColor === colors.creme
        ? colors.black
        : bgColor === colors.grayNav
        ? colors.grayLightTextNav
        : bgColor === colors.black
        ? colors.grayLightTextNav
        : colors.white;
    const opacity =
      bgColor === colors.white ? ".4" : bgColor === colors.creme ? ".4" : bgColor === colors.grayNav ? "1" : "1";
    const fontClassName = this.getFontClassName(fontStyle);
    const withPeopleFilter =
      allowPeopleFilter && this.state.collectionId === COLLECTION.ALL_MEDIA_ID && peopleImgs.length > 0;
    const showCover = titleStyle?.id === GalleryPresentSettingList.Cover;

    const getUserEmail = () => {
      const isClient = clientViewIdentity.userEmail && clientViewIdentity.userEmail !== "";
      return isClient
        ? clientViewIdentity.userEmail
        : clientViewFavorites.visitorEmail || clientViewGallery.visitorEmail;
    };

    const getUserName = () => {
      const isClient = clientViewIdentity.userName && clientViewIdentity.userName !== "";
      return isClient ? clientViewIdentity.userName : "";
    };

    const visitorId = () => {
      return clientViewGallery.visitorId || clientViewFavorites.visitorId;
    };

    const isUserLogged = () => {
      return !!visitorId() || clientViewIdentity.token !== "";
    };

    const collectionsOptions = getCollectionsOptions(
      clientViewGallery.collections || [],
      !!clientViewGallery?.medias?.length
    );

    return (
      <div
        ref={this.Container}
        id="container"
        className={cx(styles.container, showLightbox ? styles.noScroll : "")}
        style={{ backgroundColor: bgColor || colors.white }}
      >
        <style>
          {`
              :root {
                --page-bkg-color: ${bgColor};
              }
            `}
        </style>

        {titleStyle?.id !== GalleryPresentSettingList.NoCover && (
          <div ref={this.HeroImage}>
            <HeroImage
              showCover={showCover}
              heroImage={heroImage}
              heroImageFocalX={heroImageFocalX}
              heroImageFocalY={heroImageFocalY}
              photoUrlTemplate={clientViewGallery.heroImageUrlTemplate!}
              thumbnailUrlTemplate={clientViewGallery.heroThumbnailUrlTemplate!}
              videoUrlTemplate={clientViewGallery.videoUrlTemplate!}
              style={style}
              albumNameClassName={fontClassName}
              accentColor={accentColor}
              name={showCover && hideTitle?.value === "true" ? "" : clientViewGallery.name}
              myDate={hideDate?.value === "true" ? "" : myDate}
              colors={colors}
              countryCode={this.props.countryCode}
              onHandleCaretClick={this.handleTopClick}
              handleBlockContextMenu={ContextMenu.handleBlockContextMenu}
              studioName={studioName}
              galleryId={clientViewGallery.id}
              videoAnalyticsBaseType={videoAnalyticsBaseType}
            />
          </div>
        )}

        <div id="gallery" className={cx(styles.content, showLightbox ? styles.hide : "")}>
          <Actions
            lineColor={lineColor}
            titlesColor={titlesColor}
            bgColor={bgColor || colors.white}
            name={clientViewGallery.name}
            hasFavorites={hasFavorites}
            hasUnreadComments={hasUnreadComments}
            opacity={opacity}
            isEcom={isShoppingAllowedForGallery(clientViewGallery)}
            token={clientViewIdentity.token}
            iconUser={iconUser}
            iconUserOut={iconUserOut}
            userName={getUserName()}
            userEmail={getUserEmail()}
            iconMore={iconMore}
            iconShare={iconShare}
            iconDownload={iconDownload}
            allowFavorite={allowFavorite}
            allowShareGallery={allowShareGallery}
            allowGalleryPhotosDownload={canDownloadGalleryPhotos}
            allowGalleryVideosDownload={canDownloadGalleryVideos}
            allowMediaCommenting={allowMediaCommenting}
            onMediaFavoritesViewClick={onMediaFavoritesViewClick}
            onMediaCommentsViewClick={onMediaCommentsViewClick}
            onAccountDisabledClick={onAccountDisabledClick}
            onLogoutClick={onLogoutClick}
            loadMenuMenuOptions={loadMenuMenuOptions}
            studioName={studioName}
            cartBadge={cartBadge}
            cartTooltip={cartTooltip}
            disabledShop={disabledShop}
            onClickCart={onClickButtonCart}
            onClickShop={onClickButtonShop}
            onDownloadClick={onDownloadClick}
            onShareClick={onShareClick}
            isUserLogged={isUserLogged()}
          />

          <Collections
            onOptionSelected={this.handleCollectionSelected}
            collectionsOptions={collectionsOptions}
            defaultActiveIndex={getActiveCollection(collectionsOptions, clientViewGallery?.collection)}
            lineColor={lineColor}
            accentColor={accentColor}
            iconMoreOptions={iconMoreOptions}
            iconRename={iconRename}
            bgColor={bgColor || colors.white}
          />

          <Description
            description={descriptionText}
            className={cx(fontClassName, styles.description, withPeopleFilter && styles.withPeopleFilter)}
            textColor={titlesColor}
            linkColor={accentColor}
          />

          {withPeopleFilter && (
            <People
              peopleImgs={peopleImgs}
              iconMoreOptions={iconMoreOptions}
              iconEdit={iconEdit}
              accentColor={accentColor}
              bgColor={bgColor}
              selectedPeople={selectedPeople}
              titlesColor={titlesColor}
              opacity={opacity}
              selectPerson={selectPerson}
              deselectPerson={deselectPerson}
            />
          )}

          <Content
            previewMode={this.props.previewMode}
            contentsRef={contentsRef}
            quickShopItems={quickShopItems}
            onClickQuickShopItem={onClickQuickShopItem}
            collection={this.state.collectionId}
            accentColor={accentColor}
            titlesColor={titlesColor}
            allowFavorite={allowFavorite}
            allowMediaCommenting={allowMediaCommenting}
            allowPhotoDownload={allowPhotoDownload}
            allowVideoDownload={allowVideoDownload}
            allowShoppingCart={allowShoppingCart}
            allowGalleryPhotosDownload={canDownloadGalleryPhotos}
            allowGalleryVideosDownload={canDownloadGalleryVideos}
            onDownloadClick={onDownloadClick}
            images={images}
            clientViewGallery={clientViewGallery}
            clientViewFavorites={clientViewFavorites}
            clientViewDownloads={clientViewDownloads}
            clientViewCommenting={clientViewCommenting}
            cartItems={clientViewEcom?.cart?.cartItems || []}
            getSetting={getSetting}
            bgColor={bgColor}
            downloadColor={downloadColor}
            colors={colors}
            peopleFilteredPhotos={peopleFilteredPhotos}
            onMediaClick={onMediaClick}
            onMediaFavoriteClick={onMediaFavoriteClick}
            onMediaCommentClick={onMediaCommentClick}
            onMediaDownloadClick={onMediaDownloadClick}
            onMediaCartClick={onMediaCartClick}
            handleTopClick={this.handleTopClick}
            animationClasses={animationClasses}
            couponBanners={
              <CouponBanners
                currency={currency}
                couponList={this.props.couponList}
                onClickBannerButton={onClickBannerButton}
              />
            }
          />

          {this.props.observerComponent}

          <Footer
            footerColor={footerColor}
            iconFacebook={iconFacebook}
            iconTwitter={iconTwitter}
            iconInstagram={iconInstagram}
            iconShop={iconShop}
            iconLike={iconLike}
            footerTextColor={footerTextColor}
            studioName={studioName}
            contactEmail={contactEmail}
            photoCount={clientViewGallery.photoCount}
            videoCount={clientViewGallery.videoCount}
            handleShowCookieSettings={handleShowCookieSettings}
            isEnabledCookieSettings={isEnabledCookieSettings}
          />
        </div>
        {this.props.bottomBanner}
      </div>
    );
  }
}

export default GalleryCompleteView;
