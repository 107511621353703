import React from "react";
import Loadable from "react-loadable";
import Activation from "../../components/icons/Activation";
import Calendar from "../../components/icons/Calendar";
import Customize from "../../components/icons/Customize";
import Profile from "../../components/icons/Profile";
import Reports from "../../components/icons/Reports";
import Service from "../../components/icons/Service";
import Welcome from "../../components/icons/Welcome";
import { IMenuItem } from "../../store/menu/model";
import { IUserInfo } from "../../store/session/model";
import colors from "../../utilities/colors";
import IIConProps from "../icons/IIconProps";

export interface IRoute extends IMenuItem {
  component: any;
  params?: any;
  getAddon?: (userInfo: IUserInfo) => { type: string; value: any } | null;
}

const loadable = (loader: any) =>
  Loadable({
    loader,
    delay: false,
    loading: () => null
  });

const iconProps: IIConProps = {
  size: 24,
  color: colors.darkGrey
};

const routes: IRoute[] = [
  {
    key: "welcome",
    title: "Welcome",
    header: "Welcome to BookMe!",
    path: "/welcome",
    component: loadable(() => import("../../containers/welcome")),
    icon: <Welcome {...iconProps} />,
    layout: "/main",
    isDefault: true,
    isHidden: user => user.welcomeClosed
  },
  {
    key: "profile",
    title: "Profile",
    header: "Your Profile",
    path: "/profile",
    component: loadable(() => import("../../containers/profile")),
    icon: <Profile {...iconProps} />,
    layout: "/main",
    params: "/:tab/:section?",
    getAddon: userInfo => ({
      type: "alert",
      value: userInfo.isStripeSetupComplete === false || userInfo.isReauthorizedRequired
    })
  },
  {
    key: "services",
    title: "Services",
    path: "/services",
    component: loadable(() => import("../../containers/services")),
    icon: <Service {...iconProps} />,
    layout: "/main"
  },
  {
    key: "create-services",
    path: "/services/create",
    component: loadable(() => import("../../containers/services/serviceEditOrCreate")),
    layout: "/page"
  },
  {
    key: "edit-services",
    path: "/services/edit",
    component: loadable(() => import("../../containers/services/serviceEditOrCreate")),
    layout: "/page",
    params: "/:serviceId"
  },
  {
    key: "bookings",
    title: "My Bookings",
    path: "/bookings",
    component: loadable(() => import("../../containers/bookings")),
    icon: <Calendar {...iconProps} />,
    layout: "/main",
    params: "/:tabOrBookingId/:bookingId?/:bookingAction?",
    isDefault: true,
    getAddon: userInfo =>
      userInfo.pendingBookingsCount ? { type: "badge", value: userInfo.pendingBookingsCount } : null
  },
  {
    key: "customize",
    title: "Customize",
    path: "/customize",
    component: loadable(() => import("../../containers/customize")),
    icon: <Customize {...iconProps} />,
    layout: "/main",
    params: "/:tab"
  },
  {
    key: "activation",
    title: "Activation",
    path: "/activation",
    component: loadable(() => import("../../containers/activation")),
    icon: <Activation {...iconProps} />,
    layout: "/main",
    params: "/:tab",
    getAddon: userInfo => ({ type: "onOff", value: userInfo.widgetIsActive })
  },
  {
    key: "reports",
    title: "Reports",
    path: "/reports",
    component: loadable(() => import("../../containers/reports")),
    icon: <Reports {...iconProps} />,
    layout: "/main",
    isHidden: () => true
  }
];

export default routes;
