import cx from "classnames";
import { IZenSiteTheme } from "../../../models/models";
import { hexToRgb } from "utilities/blocks/blockColors";
import React, { PropsWithChildren } from "react";
import Divider, { DIVIDER } from "../../Divider";
import styles from "./zenBaseBlocks.module.scss";

export interface IZenBaseBlockProps {
  readOnly?: boolean;
  siteTheme: IZenSiteTheme;
  desktopEnabled?: boolean;
  tabletEnabled?: boolean;
  mobileEnabled?: boolean;
  divider?: DIVIDER;
  alignment?: string;
  fontFamily?: string;
  padding?: string;
  noPadding?: boolean;
  animationScrollIn?: string;
  animationScrollOut?: string;
  backgroundType?: string;
  backgroundColor?: string;
  backgroundOpacity?: number;
  backgroundWidth?: string;
  layout?: string;
  selectedBlock?: boolean;
  fullWidth?: boolean;
  noPaddingMobile?: boolean;
  paddingMobile?: boolean;
  applyLayoutClass?: boolean;
  contentClassName?: string;
  children?: React.ReactNode;
}

export const getBlockBackgroundColor = (
  siteTheme: IZenSiteTheme,
  backgroundColor?: string,
  backgroundOpacity?: number
) => {
  const bgColor = backgroundColor === "accent" ? siteTheme.accentColor.value : backgroundColor;
  const rgb = bgColor ? hexToRgb(bgColor) : hexToRgb(siteTheme.accentColor.value);
  // Set block background styles
  const backgroundStyle = rgb
    ? {
        backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${backgroundOpacity ? backgroundOpacity / 100 : "1"})`
      }
    : {};

  return backgroundStyle;
};

const ZenBaseBlock = React.forwardRef<HTMLDivElement, PropsWithChildren<IZenBaseBlockProps>>((props, ref) => {
  const {
    alignment,
    layout,
    siteTheme,
    backgroundType,
    backgroundColor,
    backgroundOpacity,
    backgroundWidth,
    fontFamily,
    padding,
    divider,
    fullWidth,
    readOnly,
    desktopEnabled,
    tabletEnabled,
    mobileEnabled,
    noPadding,
    noPaddingMobile,
    paddingMobile,
    contentClassName,
    applyLayoutClass = true
  } = props;

  let containerClass = cx(styles.zenBaseContainer);

  if (readOnly && !desktopEnabled) {
    containerClass = cx(containerClass, styles.hideDesktop);
  }
  if (readOnly && !tabletEnabled) {
    containerClass = cx(containerClass, styles.hideTablet);
  }
  if (readOnly && !mobileEnabled) {
    containerClass = cx(containerClass, styles.hideMobile);
  }

  let contentClass = styles.content;

  if (!fullWidth) {
    contentClass = cx(contentClass, styles.reducedContent);
  }

  if (alignment) {
    if (alignment === "center") {
      contentClass = cx(contentClass, styles.aligncenter);
    }
    if (alignment === "right") {
      contentClass = cx(contentClass, styles.alignright);
    }
  }
  const layoutClass = !applyLayoutClass
    ? ""
    : layout && layout === "B"
    ? styles.layoutB
    : layout && layout === "C"
    ? styles.layoutC
    : styles.layoutA;

  contentClass = cx(contentClass);

  if (noPadding) {
    contentClass = cx(contentClass, styles.noPadding);
  }

  if (noPaddingMobile) {
    contentClass = cx(contentClass, styles.noPaddingMobile);
  }

  if (paddingMobile) {
    contentClass = cx(contentClass, styles.paddingMobile);
  }

  const backgroundStyle = getBlockBackgroundColor(siteTheme, backgroundColor, backgroundOpacity);
  const backgroundStyleContainer = backgroundType === "color" && backgroundWidth === "full" ? backgroundStyle : {};
  const backgroundStyleContent = backgroundType === "color" && backgroundWidth === "fixed" ? backgroundStyle : {};

  const paddingStyle = {
    paddingTop: padding ? padding : "0px",
    paddingBottom: padding ? padding : "0px",
    paddingLeft: (backgroundWidth === "fixed" && backgroundType === "color") || fullWidth ? "40px" : "0",
    paddingRight: (backgroundWidth === "fixed" && backgroundType === "color") || fullWidth ? "40px" : "0"
  };

  const fontStyle = {
    fontFamily: fontFamily ? fontFamily : "'Nunito Sans', sans-serif"
  };

  return (
    <div ref={ref} className={containerClass} style={Object.assign(backgroundStyleContainer, fontStyle)}>
      <style>
        {`
            :root {
              --accent-color: ${siteTheme.accentColor.value};
              }
            `}
      </style>
      <div className={cx(contentClass, contentClassName)} style={Object.assign(backgroundStyleContent, paddingStyle)}>
        <div className={layoutClass} data-outside-subblock={true}>
          {props.children}
        </div>
      </div>
      <Divider templateBackgroundColor={siteTheme.backgroundColor.value} dividerType={divider} padding={padding} />
    </div>
  );
});

export default ZenBaseBlock;
