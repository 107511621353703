import classNames from "classnames";
import React, { Component } from "react";
import Tab from "./tab";
import styles from "./tabs.module.scss";

interface ITab {
  label: string;
  default: boolean;
  target: React.RefObject<any>;
}

interface ITabsProps {
  tabs?: ITab[];
  children?: any[];
  defaultActiveTab?: string;
  activeTab?: string;
  onChange?: (activeKey: string) => void;
  header?: { className?: string; style?: React.CSSProperties; ref?: React.Ref<HTMLOListElement> };
  content?: { className?: string; style?: React.CSSProperties; ref?: React.Ref<HTMLDivElement> };
  className?: string;
}

interface ITabsState {
  activeTabIndex: number;
  activeTab: string;
}

const NAVBAR_HEIGHT = 103;

class Tabs extends Component<ITabsProps, ITabsState> {
  constructor(props: ITabsProps) {
    super(props);
    this.state = {
      activeTabIndex: props.tabs ? props.tabs.findIndex(tab => tab.default === true) : 0,
      activeTab: this.getDefaultActiveTab()
    };
  }

  private getDefaultActiveTab = () => {
    const { defaultActiveTab, activeTab } = this.props;
    const tab = activeTab !== undefined ? activeTab : defaultActiveTab;

    if (this.props.children) {
      let tabIndex = 0;

      if (tab) {
        for (let index = 0; index < this.props.children.length; index++) {
          const child = this.props.children[index];
          const { id } = child.props;
          if (id === tab) {
            tabIndex = index;
            break;
          }
        }
      }

      return this.props.children[tabIndex].props.id;
    }

    return "";
  };

  private handleTabClick = (index: number, ref: React.RefObject<any>, e: any) => {
    window.scrollTo(0, ref.current.offsetTop - NAVBAR_HEIGHT);
    this.setState({ activeTabIndex: index });
  };

  private onClickTabItem = (tab: string) => {
    const { onChange } = this.props;

    this.setState({ activeTab: tab });

    if (onChange) {
      onChange(tab);
    }
  };

  public static getDerivedStateFromProps(props: ITabsProps, state: ITabsState) {
    return props.activeTab === undefined || props.activeTab === state.activeTab ? null : { activeTab: props.activeTab };
  }

  public render() {
    if (this.props.children) {
      const {
        onClickTabItem,
        props: { children },
        state: { activeTab }
      } = this;

      const header = this.props.header || {};
      const content = this.props.content || {};

      const { className } = this.props;

      return (
        <div className={styles.tabsWithData}>
          <ol className={classNames(styles.tabList, className, header.className)} style={header.style} ref={header.ref}>
            {children.map(child => {
              const { id } = child.props;
              const badge = child.props["data-badge"];
              const icon = child.props["data-icon"];
              let title = child.props["data-title"];
              if (!title) {
                title = id;
              }
              return (
                <Tab
                  activeTab={activeTab}
                  key={id}
                  label={id}
                  title={title}
                  onClick={onClickTabItem}
                  badge={badge}
                  icon={icon}
                />
              );
            })}
          </ol>
          <div className={classNames(styles.tabContent, content.className)} style={content.style} ref={content.ref}>
            {children.map((child, index) => {
              if (child.props.id !== activeTab) {
                return undefined;
              }
              return <div key={index}>{child.props.children}</div>;
            })}
          </div>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <ul className={styles.tabs}>
            {this.props.tabs!.map((tab, index) => {
              return (
                <li key={index}>
                  <a
                    className={index === this.state.activeTabIndex ? classNames(styles.tab, styles.active) : styles.tab}
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={e => this.handleTabClick(index, tab.target, e)}
                  >
                    {tab.label}
                  </a>
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      );
    }
  }
}

export default Tabs;
