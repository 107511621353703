import React, { memo, useRef, useState, useEffect, MouseEvent } from "react";
import { Range } from "slate";
import cx from "classnames";

import MarkButton from "../MarkButton";
import Toolbar from "../Toolbar";
import ButtonIcon from "../ButtonIcon";
import Button from "../Button";
import { INavigationMenuItem } from "models/models";
import LinkModal from "./linkModal";
import { getCurrentLinkNodes } from "components/TextEditor/utils/withLinks";
import styles from "./floatingToolbar.module.scss";
import MarkDropdown from "../MarkDropdown";

interface IFloatingToolbar {
  editor: any;
  pages?: INavigationMenuItem[];
  show?: boolean;
  className?: string;
  showLink?: boolean;
  headingSize?: number;
  showHeadings?: boolean;
  onHeadingChange?: (size: number) => void;
  onToggleDropdown?: (isOpen: boolean) => void;
  savedSelection: Range;
  isShowToolbar: boolean;
  onCancel: () => void;
  onLinkClick: (val: boolean) => void;
}

const FloatingToolbar = (props: IFloatingToolbar) => {
  const { editor } = props;
  const [isShowLinkModal, setIsShowLinkModal] = useState<boolean>(false);

  const toolbarRef = useRef<HTMLDivElement>(null);
  const onToggleLinkModal = (e: MouseEvent) => {
    e.preventDefault();
    setIsShowLinkModal(!isShowLinkModal);
    props.onLinkClick(!isShowLinkModal);
  };

  useEffect(() => {
    if (!props.isShowToolbar && isShowLinkModal) {
      setIsShowLinkModal(false);
    }

    if (!toolbarRef.current) return;
    if (!props.isShowToolbar) {
      toolbarRef.current.removeAttribute("style");
    }

    const menu = toolbarRef.current;
    menu.style.opacity = "1";
    menu.style.bottom = "15px";
    menu.style.left = "20px";
  });

  return props.isShowToolbar ? (
    <Toolbar ref={toolbarRef} className={cx(styles.zenContainer, props.className)}>
      <MarkButton
        format="bold"
        iconClassName={cx("fa fa-bold")}
        className={cx(styles.button, styles.first)}
        activeClassName={styles.active}
      />
      <MarkButton
        format="italic"
        iconClassName={cx("fa fa-italic")}
        className={styles.button}
        activeClassName={styles.active}
      />
      <MarkButton
        format="underline"
        iconClassName={cx("fa fa-underline")}
        className={cx(styles.button, { [styles.last]: !props.showLink && !props.showHeadings })}
        activeClassName={styles.active}
      />
      {props.showLink && (
        <Button
          // The user might have highlighted text that spans more than one link. If
          // that's the case, do not mark the button as active. This matches how the
          // other formatting buttons behave.
          isActive={getCurrentLinkNodes(editor).length === 1}
          className={cx(styles.button, styles.last, isShowLinkModal ? styles.active : "")}
          activeClassName={styles.active}
          onMouseDown={onToggleLinkModal}
        >
          <ButtonIcon className={cx("fa fa-link")} />
        </Button>
      )}
      {isShowLinkModal && (
        <div className={styles.showLinkContainer}>
          <LinkModal
            editor={editor}
            pages={props.pages}
            savedSelection={props.savedSelection}
            onCancel={props.onCancel}
          />
        </div>
      )}
      {props.showHeadings && (
        <MarkDropdown
          editor={editor}
          type="heading"
          headingSize={props.headingSize}
          onChange={props.onHeadingChange}
          onToggleDropdown={props.onToggleDropdown}
        />
      )}
    </Toolbar>
  ) : null;
};

export default memo(FloatingToolbar);
