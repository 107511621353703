import { Collapse, Panel, Utilities } from "@zenfolio/core-components";
import classNames from "classnames";
import moment from "moment";
import * as React from "react";
import { IInvoiceInfo } from "../../../../../../store/widget/model";
import Auxiliary from "../../../../../../utilities/auxiliary";
import { formatTimeOfDay, splitDateTime } from "../../../../../../utilities/datetime";
import styles from "./index.module.scss";

interface IPaymentDetailsState {
  detailsOpen: boolean;
}

interface IPaymentDetailsProps {
  invoice: IInvoiceInfo;
}

class PaymentDetails extends React.Component<IPaymentDetailsProps, IPaymentDetailsState> {
  constructor(props: IPaymentDetailsProps) {
    super(props);

    this.state = {
      detailsOpen: false
    };
  }

  public render() {
    const { detailsOpen } = this.state;
    const { bookingInfo, serviceInfo } = this.props.invoice;

    const dateTime = moment(bookingInfo.bookingDate)
      .add(bookingInfo.offsetMinutes, "minute")
      .toDate();
    const dateTimeInfo = splitDateTime(dateTime);

    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.collapse}>
            <Collapse>
              <Panel
                name="details"
                headerClassName={styles.header}
                contentClassName={styles.panel}
                {...Panel.defaultProps}
                open={detailsOpen}
                title={this.renderTitle()}
                onChange={this.onClickCollapse}
              >
                <div className={styles.detail}>
                  <div className={styles.info}>
                    <label>Service</label>
                    <div className={styles.value}>{serviceInfo.name}</div>
                  </div>

                  <div className={styles.info}>
                    <label>Date</label>
                    <div className={styles.value}>{dateTimeInfo.date!.format("MMMM D, YYYY")}</div>
                  </div>

                  <div className={styles.info}>
                    <label>Time</label>
                    <div className={styles.value}>{formatTimeOfDay(dateTimeInfo.time!, "h:mm a")}</div>
                  </div>

                  <div className={styles.info}>
                    <label>Location</label>
                    <div className={styles.value}>{bookingInfo.location}</div>
                  </div>

                  {bookingInfo.locationDetails && (
                    <div className={styles.info}>
                      <label>Location Details</label>
                      <div className={styles.value}>{bookingInfo.locationDetails}</div>
                    </div>
                  )}

                  {bookingInfo.additionalInfo && (
                    <div className={styles.info}>
                      <label>About the Occasion</label>
                      <div className={styles.value}>{bookingInfo.additionalInfo}</div>
                    </div>
                  )}

                  {this.renderAmount("Service Total", serviceInfo.price, bookingInfo.isTaxable)}
                  {this.renderDepositDescription()}
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }

  private renderAmount(name: string, value: number, taxable?: boolean) {
    return (
      <div className={styles.info}>
        <label>{name}</label>
        <div className={classNames(styles.value, taxable && styles.taxable)}>
          {Utilities.formatFractionalNumberWithCurrency(value)}
          {taxable && <em>incl. tax</em>}
        </div>
      </div>
    );
  }

  private renderDepositDescription = (): React.ReactNode => {
    const { paymentActionInfo, bookingInfo } = this.props.invoice;

    return (
      <Auxiliary>
        {paymentActionInfo.lastPaymentOn && (
          <div className={styles.info}>
            <label>Last Payment On</label>
            <div className={styles.value}>{moment(paymentActionInfo.lastPaymentOn).format("MMMM D, YYYY")}</div>
          </div>
        )}
        {this.renderAmount("Total Amount Paid", bookingInfo.totalAmountPaid, bookingInfo.isTaxable)}
        {this.renderAmount("Total Due Today", paymentActionInfo.totalDueToday, bookingInfo.isTaxable)}
      </Auxiliary>
    );
  };

  private renderTitle = (): React.ReactNode => {
    const { paymentActionInfo } = this.props.invoice;

    return (
      <div className={styles.info}>
        <div className={styles.name}>Total Due Today</div>
        <div className={styles.price}>
          <em>USD</em> {Utilities.formatFractionalNumberWithCurrency(paymentActionInfo.totalDueToday)}
        </div>
      </div>
    );
  };

  private onClickCollapse = (key: string, open: boolean) => {
    this.setState({
      detailsOpen: open
    });
  };
}

export default PaymentDetails;
