import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IEditInvoiceOwnProps, IEditInvoiceSetupProps } from ".";
import { calculateTaxForEditingInvoice, editInvoice as editInvoiceAction } from "../../../../../store/billing/actions";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ billing: { editInvoice, calculateTaxForEditingInvoice: calculateTax } }: IAppState) {
  return { editInvoice, calculateTax };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    editInvoice: {
      updateInvoice: (invoiceId: string, amount: number, internalNote?: string) =>
        dispatch(editInvoiceAction(invoiceId, amount, internalNote))
    },
    calculateTax: {
      calculateTax: (invoiceId: string, amount: number) => dispatch(calculateTaxForEditingInvoice(invoiceId, amount))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: IEditInvoiceOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    editInvoice: { ...stateProps.editInvoice, ...dispatchProps.editInvoice },
    calculateTax: { ...stateProps.calculateTax, ...dispatchProps.calculateTax }
  } as IEditInvoiceSetupProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
