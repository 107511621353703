import React from "react";
import ModalDialog from "../../modalDialog";
import styles from "./index.module.scss";

interface INoCalendarWarningProps {
  onOk: () => void;
  onCancel: () => void;
}

class NoCalendarWarning extends React.Component<INoCalendarWarningProps> {
  public render() {
    return (
      <ModalDialog
        title="CANCEL CALENDAR SELECTION"
        okText="Yes"
        cancelText="Back"
        onCancel={this.onCancel}
        onOk={this.onOk}
      >
        <div className={styles.container}>
          By not choosing a calendar BookMe will be unable to write events to your Google Calendar.
          <br />
          <br />
          <br />
          Do you wish to continue?
        </div>
      </ModalDialog>
    );
  }

  private onCancel = () => this.props.onCancel();

  private onOk = () => this.props.onOk();
}

export default NoCalendarWarning;
