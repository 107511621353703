import React from "react";

const Circle = (className: string) => {
  const r = 5;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={r * 2}
      height={r * 2}
      viewBox={`0 0 ${r * 2} ${r * 2}`}
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
        <circle id="Oval" fill="#FF5A00" cx={r} cy={r} r={r} />
      </g>
    </svg>
  );
};

export default Circle;
