import { IUserState } from "../store/session/model";
import { apiType } from "./apiType";
import { apiClient } from "./index";

export interface IApiSession {
  loadState: () => Promise<IUserState>;
}

const api: IApiSession = {
  loadState: () => apiClient.get<IUserState>(`${apiType.bookingWidget}photographer/state`)
};

export default api;
