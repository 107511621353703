import React from "react";
import { initializeIconProps } from "../../../utilities/icons";
import IIConProps from "../IIconProps";

const Welcome = (rawProps: IIConProps) => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`${props.minX} ${props.minY} 25 24`}
    >
      <g fill="none" fillRule="evenodd" stroke={props.stroke}>
        <path d="M18.21 10.316a5.895 5.895 0 1 1-11.789 0 5.895 5.895 0 0 1 11.79 0z" />
        <path strokeLinejoin="round" d="M9.79 15.79v2.105h5.052v-2.106M10.632 19.579H14v-1.684h-3.368z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.737 15.79l1.074-4.653a1.06 1.06 0 0 1 1.031-.821.841.841 0 1 1 0 1.684H9.79a.841.841 0 1 1 0-1.684c.493 0 .92.34 1.032.82l1.074 4.653"
        />
      </g>
    </svg>
  );
};

export default Welcome;
