import { Utilities } from "utilities/utilities";
const { resolveValue } = Utilities;

export interface IComponentBaseProps<TRef> {
  containerRef?: React.Ref<TRef>;
  style?: React.CSSProperties;
  className?: string;
}

export interface IElementBaseProps<TRef> {
  ref?: React.Ref<TRef>;
  style?: React.CSSProperties;
  className?: string;
}

export const storybookContainerStyle = {
  display: "inline-block",
  width: "auto",
  height: "auto",
  margin: 30,
  padding: 0
} as React.CSSProperties;

export { resolveValue };

export function getInputMax(max: number | undefined, maxLength: number): number;
export function getInputMax(max?: number, maxLength?: number): number | undefined;
export function getInputMax(max?: number, maxLength?: number) {
  max = Math.min(
    max !== undefined ? max : Number.MAX_SAFE_INTEGER,
    maxLength !== undefined ? Math.pow(10, maxLength) - 1 : Number.MAX_SAFE_INTEGER
  );
  return max !== Number.MAX_SAFE_INTEGER ? max : undefined;
}

export function isIncrementDisabled(value?: number | null, step?: number, max?: number, incrementDisabled?: boolean) {
  return incrementDisabled !== undefined ? incrementDisabled : max !== undefined && (value || 0) + (step || 1) > max;
}

export function isDecrementDisabled(value?: number | null, step?: number, min?: number, decrementDisabled?: boolean) {
  return decrementDisabled !== undefined ? decrementDisabled : min !== undefined && (value || 0) - (step || 1) < min;
}
