import cx from "classnames";
import Autosuggest from "components/Autosuggest";
import _ from "lodash";
import { Utilities } from "utilities/utilities";
import { Validation } from "utilities/validation";
import * as React from "react";

import { IAddress } from "../../models";
import styles from "./addressInput.module.scss";
import arrowIcon from "./arrow.svg";
import {
  AddressInputValue,
  IAddressInfo,
  ICoordinates,
  IGeoCodePlaceDetails,
  ILoadPlaceDetails,
  isAddress,
  isAddressPrediction,
  ISearchAddress,
  MaxSearchAddressRadius
} from "./model";
import withApi from "./withApi";

const { shouldComponentUpdateDeep, isCompleteAddress, getFullAddress, isEnterKey, hasActionCompleted } = Utilities;

export interface IAddressInputErrors {
  locationNotFoundError: string;
  locationIncompleteError: string;
  locationEmptyError: string;
}

export interface IAddressInputOwnProps {
  address: AddressInputValue;
  onChange: (address: AddressInputValue) => void;
  countryCode?: string;
  allowEmpty?: boolean;
  allowIncomplete?: boolean;
  allowAutoDetection?: boolean;
  allowAnyCountry?: boolean;
  className?: string;
  style?: React.CSSProperties;
  errorClassName?: string;
  errorMessageClassName?: string;
  placeholder?: string;
  errors?: Partial<IAddressInputErrors>;
  forceShowingError?: boolean;
  isCompleteAddress?: (address: IAddress) => boolean;
  formatAddress?: (address: IAddress) => string;
  onFocus?: () => void;
}

export interface IAddressInputProps extends IAddressInputOwnProps {
  searchAddress: ISearchAddress;
  loadPlaceDetails: ILoadPlaceDetails;
  geoCodePlaceDetails?: IGeoCodePlaceDetails;
}

interface IAddressInputState {
  isFocused: boolean | null;
  wasFocused: boolean;
  changed: boolean;
}

const defaultErrors: IAddressInputErrors = {
  locationNotFoundError: "We’re unable to find this address. Please verify the address and try again.",
  locationIncompleteError: "Please verify your building number.",
  locationEmptyError: "Please enter an address."
};

class AddressInput extends React.Component<IAddressInputProps, IAddressInputState> {
  public static readonly isAddressPrediction = isAddressPrediction;
  public static readonly isAddress = isAddress;
  public static readonly withApi = withApi;
  public static readonly defaultErrors = defaultErrors;

  private readonly errors: IAddressInputErrors;

  constructor(props: IAddressInputProps) {
    super(props);

    this.state = { isFocused: null, wasFocused: false, changed: false };

    this.errors = {
      ...AddressInput.defaultErrors,
      ...props.errors
    };
  }

  public shouldComponentUpdate(nextProps: IAddressInputProps, nextState: IAddressInputState): boolean {
    return shouldComponentUpdateDeep(this, nextProps, nextState);
  }

  public componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress, true);

    const { searchAddress, geoCodePlaceDetails } = this.props;
    searchAddress.onReset();
    geoCodePlaceDetails?.loadPlaceDetailsFromLocationOrIpReset();
  }

  public componentDidUpdate(prevProps: IAddressInputProps) {
    const { loadPlaceDetails, geoCodePlaceDetails } = this.props;

    this.loadPlaceDetailsForPlaceId(loadPlaceDetails, prevProps);
    if (geoCodePlaceDetails) {
      this.loadPlaceDetailsForLocationOrIp(geoCodePlaceDetails, prevProps);
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, true);
    this.props.searchAddress.onReset();
  }

  public render() {
    const {
      searchAddress,
      allowEmpty,
      allowAutoDetection,
      className,
      style,
      errorClassName,
      errorMessageClassName,
      placeholder = allowEmpty ? "Address line 1 (optional)" : "Address line 1"
    } = this.props;
    const { addressInfo, errorMessage } = this;

    return (
      <div className={cx(styles.container, className, errorMessage && errorClassName)} style={style}>
        <Autosuggest
          value={addressInfo.value}
          suggestions={searchAddress.predictions || []}
          placeholder={placeholder}
          renderInputComponent={this.renderInputComponent}
          icon={allowAutoDetection ? arrowIcon : undefined}
          onChange={this.onChange}
          onGetSuggestions={this.onGetSuggestions}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          onFocus={this.onFocus}
          onClickIcon={this.onClickIcon}
          onBlur={this.onBlur}
        />
        {errorMessage ? <div className={cx(styles.errorMessage, errorMessageClassName)}>{errorMessage}</div> : null}
      </div>
    );
  }

  private renderInputComponent = ({ onClear, ...inputProps }: any) => (
    <input {...inputProps} className={cx(inputProps.className, styles.input, this.errorMessage && styles.error)} />
  );

  private get address() {
    return this.props.address;
  }

  private get addressInfo(): IAddressInfo {
    const { address, errors, props } = this;
    const { wasFocused, isFocused, changed } = this.state;
    const searchAddressError = props.searchAddress.error;
    const { allowEmpty, allowIncomplete, forceShowingError } = props;

    if (isAddressPrediction(address)) {
      return {
        value: address.description || "",
        isComplete: false,
        coordinates: null,
        errorMessage:
          (!allowEmpty ? Validation.errorIfEmpty(address.description, errors.locationEmptyError) : "") ||
          (searchAddressError ? errors.locationNotFoundError : "")
      };
    }

    if (isAddress(address)) {
      const isComplete = (props.isCompleteAddress || isCompleteAddress)(address);
      return {
        value: (props.formatAddress || getFullAddress)(address),
        isComplete,
        coordinates: { lat: address.latitude, lng: address.longitude },
        errorMessage: isComplete || allowIncomplete ? "" : errors.locationIncompleteError
      };
    }

    const emptyError =
      (changed || wasFocused || forceShowingError) && !allowEmpty
        ? Validation.errorIfEmpty(address, errors.locationEmptyError)
        : "";
    const notFoundError = searchAddressError || (isFocused === false && address) ? errors.locationNotFoundError : "";

    return {
      value: address || "",
      isComplete: false,
      coordinates: null,
      errorMessage: emptyError || notFoundError
    };
  }

  private get errorMessage() {
    return this.state.isFocused != null || this.props.forceShowingError ? this.addressInfo.errorMessage : "";
  }

  private onClickIcon = () => {
    const { loadPlaceDetailsFromIp, loadPlaceDetailsFromLocation } = this.props.geoCodePlaceDetails!;
    if ("geolocation" in navigator) {
      const options: PositionOptions = { enableHighAccuracy: true };
      navigator.geolocation.getCurrentPosition(
        position => {
          const location: ICoordinates = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          loadPlaceDetailsFromLocation(location);
        },
        () => loadPlaceDetailsFromIp(),
        options
      );
    } else {
      loadPlaceDetailsFromIp();
    }
  };

  private onChange = (event: React.FormEvent<any>, { newValue }: any) => {
    this.updateAddress(newValue);
  };

  private updateAddress = (address: AddressInputValue) => {
    this.setState({ changed: true });
    this.props.onChange(address || null);
  };

  private onSuggestionsClearRequested = () => {
    this.props.searchAddress.onClearPredictions();
  };

  private onSuggestionSelected = (event: any, { suggestionIndex }: any) => {
    this.selectSuggestion(suggestionIndex);
  };

  private selectSuggestion = (suggestionIndex: number) => {
    const { searchAddress } = this.props;
    if (!_.isEmpty(searchAddress.predictions)) {
      const address = searchAddress.predictions![suggestionIndex];
      this.updateAddress(address);
      this.props.loadPlaceDetails.onLoad(address.placeId);
    }
  };

  private onFocus = () => {
    this.setState({ isFocused: true }, this.props.onFocus);
  };

  private onBlur = () => {
    this.setState({ isFocused: false, wasFocused: true });
  };

  private handleKeyPress = (evt: KeyboardEvent) => {
    if (!evt || !evt.key) {
      return;
    }

    if (this.state.isFocused && isEnterKey(evt)) {
      this.selectSuggestion(0);
    }
  };

  private onGetSuggestions = (query: string) => {
    let lat: number | null = null,
      lng: number | null = null;
    let autoDetect: boolean = true;

    const { searchAddress, countryCode, allowAnyCountry } = this.props;

    if (searchAddress.currentLocation) {
      lat = searchAddress.currentLocation.latitude;
      lng = searchAddress.currentLocation.longitude;
      autoDetect = false;
    }

    if (query) {
      searchAddress.onSearch({
        query,
        lat: lat,
        lng: lng,
        radius: MaxSearchAddressRadius,
        country: countryCode || (allowAnyCountry ? "ZZ" : "US"),
        address: true,
        autoDetect: autoDetect
      });
    } else {
      this.props.searchAddress.onReset();
    }
  };

  private loadPlaceDetailsForLocationOrIp(geoCodePlaceDetails: IGeoCodePlaceDetails, prevProps: IAddressInputProps) {
    const loadPlaceDetailsFromIpHasCompleted = hasActionCompleted(
      prevProps.geoCodePlaceDetails!.loadFromIpStatus,
      geoCodePlaceDetails.loadFromIpStatus
    );

    const loadPlaceDetailsFromLocationHasCompleted = hasActionCompleted(
      prevProps.geoCodePlaceDetails!.loadFromLocationStatus,
      geoCodePlaceDetails.loadFromLocationStatus
    );

    if (loadPlaceDetailsFromLocationHasCompleted || loadPlaceDetailsFromIpHasCompleted) {
      this.updateAddress(geoCodePlaceDetails.placeDetails);
    }
  }

  private loadPlaceDetailsForPlaceId(loadPlaceDetails: ILoadPlaceDetails, prevProps: IAddressInputProps) {
    const address = this.address;
    const loadPlaceDetailsHasCompleted =
      hasActionCompleted(prevProps.loadPlaceDetails.status, loadPlaceDetails.status) &&
      isAddressPrediction(address) &&
      loadPlaceDetails.placeId === address.placeId;

    if (loadPlaceDetailsHasCompleted) {
      this.updateAddress(_.omit(loadPlaceDetails.placeDetails, "viewPort"));
    }
  }
}

export default AddressInput;
