import {
  IClientViewCommentingState,
  IClientViewDownloadState,
  IClientViewFavoritesState,
  IClientViewGalleryState
} from "../ClientView/models";
import { IInstagramImage, IMediaVideo } from "../../models/blocks";
import { ILink } from "../../models/models";
import { IZenSiteTheme, ISetting, PhotoOrVideo } from "../../models/models";
import { GRID_TYPE } from "utilities/constant";
import React from "react";
import { MESizeCodes } from "utilities/getImgUrl";

export interface IPhotoMasonry {
  url: string;
  srcset?: string;
  file?: File;
  width?: number;
  height?: number;
  isFallback?: boolean;
  focalX?: number;
  focalY?: number;
  altText?: string;
  video?: IMediaVideo;
  fileName?: string;
  caption?: string;
  name?: string;
  isVideo?: true;
  dominantColor?: string;
}

export type NameType = "fileName" | "sequenceNumber" | "none";
export type NameAlignment = "left" | "right";
export type NameVisibility = "always" | "hover";

export interface IPhotoNameProps {
  name: string;
  nameAlignment: NameAlignment;
  nameVisibility?: NameVisibility;
  isVideo?: boolean;
}

export interface IBlockPhotoAnimationClasses {
  revealContainerTop: string;
  revealContainerBottom: string;
  imgContainer: string;
  imgContainerLoaded: string;
  revealFlag: string;
}

export interface ICompleteViewCommonProps {
  images: IPhotoMasonry[];
  clientViewGallery: IClientViewGalleryState;
  clientViewFavorites: IClientViewFavoritesState;
  clientViewDownloads?: IClientViewDownloadState;
  clientViewCommenting?: IClientViewCommentingState;
  allowFavorite: boolean;
  allowShoppingCart?: boolean;
  animationClasses?: IBlockPhotoAnimationClasses;

  getSetting: (settingType: number) => ISetting | undefined;
  onMediaClick: (e: React.MouseEvent<HTMLElement>, mediaIndex: number) => void;
  onMediaFavoriteClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaDownloadClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaCartClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaCommentClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
}

export interface IGallerySettings {
  gridType: GRID_TYPE;
  title?: string;
  folderGridType?: GRID_TYPE;
  showTitles: boolean;
}

export interface IAlbumFolderData {
  title: string;
  imageUrl: string;
  showTitleAndText: boolean;
  isFolderFlow: boolean;
  folder: {
    id?: string;
    gridType: GRID_TYPE;
    quantity: number;
  };
  album: {
    id: string;
    coverId: string;
    gridType: GRID_TYPE;
    quantity: number;
    isGalleryProtected?: boolean;
    isVisitorAllowed?: boolean;
    pricingName?: string;
  };
  isGalleryAndAlbum?: boolean;
  galleryFolderAlias?: string;
}

export interface IGallerySettings {
  gridType: GRID_TYPE;
  title?: string;
  folderGridType?: GRID_TYPE;
  showTitles: boolean;
}

export interface IDataSSRInstangramBlock {
  isGetDefaultImages?: boolean;
  images?: IInstagramImage[];
}

export type Video = {
  id: string;
  type: VideoType;
};

export enum VideoType {
  Youtube = "youtube",
  Vimeo = "vimeo"
}

export enum LayoutType {
  A = "A",
  B = "B",
  C = "C"
}

export enum AnimationType {
  SLIDE = "slide",
  SCROLL = "scroll",
  CROSS_FADE = "cross-fade"
}

export type PhotoTestimonial = {
  id: string;
  index: number;
  image: string;
  imageSrcSet?: string;
  fileName: string;
  imageId: string;
  width: number;
  height: number;
  isVisible: boolean;
  isSelected: boolean;
  tint: boolean;
  quote: string;
  source: string;
  alignment: string;
  focalX?: number;
  focalY?: number;
  galleryId?: string;
  galleryFolderAlias?: string;
  galleryIsProtected?: boolean;
  galleryHasVisitorsAllowed?: boolean;
  galleryIsVisitorEmailRequired?: boolean;
  folderId?: string;
  coverId?: string;
  gallerySettings?: IGallerySettings;
  getGalleryPhoto?: boolean;
  folderCount?: number;
  albumCount?: number;
  altText?: string;
  photoAlbumId?: string;
  isLoading?: boolean;
};

export interface IEditorImageData {
  fileName: string;
  url: string;
  index: number;
  id: string;
  sizeCode?: MESizeCodes;
  width?: number;
  height?: number;
  isFallback?: boolean;
  focalX?: number;
  focalY?: number;
  altText?: string;
  album?: string;
  version?: number;
  srcset?: string;
  caption?: string;
  name?: string;
  photoUrlTemplate?: string;
}

export interface IComment {
  id: string;
  user: string;
  text: string;
  time: string;
  isYou: boolean;
  isDeleted: boolean;
}

export interface IVideoCoverImageData extends IEditorImageData {}

export interface IImageComments extends IEditorImageData {
  comments?: IComment[];
  isVideo?: true;
  durationMilliseconds?: number;
}

export interface ICarouselImageData extends IEditorImageData {
  title: string;
  caption: string;
  link?: ILink;
  linkCaption?: string;
  noGhost?: boolean;
  interactionType?: string;
  interactionPage?: string;
  interactionNewTab?: boolean;
  interactionUrl?: string;
  album?: string;
}

export const themeButtonsStyles = ["rectangle-fill", "rectangle-hollow", "rounded-fill", "rounded-hollow"];

export const siteThemeDefault: IZenSiteTheme = {
  accentColor: { name: "Orange", value: "orange" },
  backgroundColor: { name: "Black", value: "black" },
  fontsStyle: "fonts-lato",
  buttonsStyle: "rectangle-fill"
};

export interface IVideoProperty {
  galleryId: string;
  galleryName: string;
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  duration: string;
  detail: any;
  videoThumbnail: string;
}
