import React from "react";

const IconUnderline = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.32796 12.648C7.32796 14.856 8.71996 16.296 11.664 16.296C14.48 16.296 15.856 14.776 15.856 12.488V6.28L16.976 5.992V5H13.12V6.008L14.384 6.28V12.392C14.384 14.184 13.52 14.92 11.968 14.92C10.448 14.92 9.55196 14.184 9.55196 12.392V6.264L10.816 5.992V5H6.07996V5.992L7.32796 6.28V12.648ZM19 17.938H5V19.938H19V17.938Z"
    />
  </svg>
);

export default IconUnderline;
