import React from "react";
import styles from "./Content.module.scss";
import cx from "classnames";
import DownloadSmIcon from "./../Views/icons/icon-download-sm";
import GoTop from "components/Icons/ClientView/GoTop";
import Media from "./../Views/components/Media";
import { ICompleteViewCommonProps } from "../../../blocks/blockModels";
import { COLLECTION, ICartItem, PreviewModes } from "components/ClientView/models";
import { IQuickShopItem } from "../Views/components/QuickShop/Preview";
import { PhotoOrVideo } from "../../../../models/models";

interface IContentProps extends ICompleteViewCommonProps {
  accentColor: string;
  titlesColor: string;
  collection: string;
  bgColor: string;
  downloadColor: string;
  colors: any;
  previewMode?: PreviewModes;
  allowPhotoDownload: boolean;
  allowVideoDownload: boolean;
  allowGalleryPhotosDownload: boolean;
  allowGalleryVideosDownload: boolean;
  allowMediaCommenting?: boolean;
  peopleFilteredPhotos: string[];
  contentsRef: React.RefObject<HTMLDivElement>;
  onDownloadClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleTopClick: (e: any) => void;
  // coupon Props
  couponBanners?: any;
  cartItems?: ICartItem[];
  quickShopItems?: IQuickShopItem[];
  onClickQuickShopItem?: (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => void;
}

const Content: React.FC<IContentProps> = props => {
  const showFooterButtons = !props.clientViewGallery.pageableContext?.hasNextPage;

  return (
    <div className={styles.innerContent} ref={props.contentsRef}>
      <div className={styles.couponBanner}>{props.couponBanners ? props.couponBanners : null}</div>

      <div className={styles.imagesContainer} id="complete-view-content">
        <div className={cx(styles.sliderTopBar)}>
          <div
            style={{ color: props.accentColor }}
            className={cx(styles.sliderPlace, { [styles.displayNone]: props.collection === COLLECTION.ALL_MEDIA_ID })}
          >
            <h3>
              {
                props.clientViewGallery.collections?.find(collection => collection.collectionId === props.collection)
                  ?.name
              }
            </h3>
          </div>
          <div className={cx(styles.slideShowLink, styles.displayNone)}>
            <span style={{ color: props.titlesColor }}>Getting Ready</span>
          </div>
        </div>

        <Media
          previewMode={props.previewMode}
          images={props.images}
          clientViewGallery={props.clientViewGallery}
          clientViewFavorites={props.clientViewFavorites}
          clientViewDownloads={props.clientViewDownloads}
          clientViewCommenting={props.clientViewCommenting}
          allMedia={props.clientViewGallery.mediaFiltered || []}
          allowFavorite={props.allowFavorite}
          allowMediaCommenting={props.allowMediaCommenting}
          peopleFilteredPhotos={props.peopleFilteredPhotos}
          bgColor={props.bgColor}
          getSetting={props.getSetting}
          onMediaClick={props.onMediaClick}
          onMediaFavoriteClick={props.onMediaFavoriteClick}
          onMediaDownloadClick={props.onMediaDownloadClick}
          onMediaCartClick={props.onMediaCartClick}
          onMediaCommentClick={props.onMediaCommentClick}
          allowPhotoDownload={props.allowPhotoDownload}
          allowVideoDownload={props.allowVideoDownload}
          allowShoppingCart={props.allowShoppingCart}
          animationClasses={props.animationClasses}
          cartItems={props.cartItems || []}
          quickShopItems={props.quickShopItems}
          onClickQuickShopItem={props.onClickQuickShopItem}
        />
      </div>
      <div className={styles.footerContainer}>
        {showFooterButtons && (
          <div className={styles.footerButtons}>
            {(props.allowGalleryPhotosDownload || props.allowGalleryVideosDownload) && (
              <button
                className={styles.downloadBtn}
                style={{ backgroundColor: props.bgColor }}
                onClick={props.onDownloadClick}
              >
                <DownloadSmIcon color={props.downloadColor} />
                <span style={{ color: props.downloadColor }}>
                  Download{" "}
                  {props.allowGalleryPhotosDownload && props.allowGalleryVideosDownload
                    ? "Media"
                    : props.allowGalleryVideosDownload
                    ? "Videos"
                    : "Photos"}
                </span>
              </button>
            )}
            <a href="#top" className={styles.goTopLink} onClick={props.handleTopClick}>
              <GoTop color={props.colors.white} size={50} minX={0} minY={0} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;
