import classNames from "classnames";
import * as React from "react";
import styles from "./index.module.scss";

export interface INavbarBrandProps {
  className?: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const NavbarBrand: React.SFC<INavbarBrandProps> = props => {
  const { className, href, onClick } = props;
  const classes = classNames(className, styles.navbarBrand);
  return (
    <a href={href} onClick={onClick} className={classes}>
      {props.children}
    </a>
  );
};
