import _ from "lodash";
import * as React from "react";
import AddressInput, { IAddressInputProps } from ".";
import { IAddressAutoComplete, IAddressQuery, IPlaceDetails } from "../../models/location";
import { ICoordinates, IGeoCodePlaceDetails, ILoadPlaceDetails, ISearchAddress } from "./model";

export interface IInjectedApiProps {
  searchAddress: ISearchAddress;
  loadPlaceDetails: ILoadPlaceDetails;
  geoCodePlaceDetails: IGeoCodePlaceDetails;
}

interface IApiState {
  searchAddress: SearchAddressState;
  loadPlaceDetails: LoadPlaceDetailsState;
  geoCodePlaceDetails: GeoCodePlaceDetailsState;
}

export interface IApiClient {
  get<T = any>(url: string, config?: { params: any }): Promise<T>;
}

export interface IApi {
  searchAddress: (query: IAddressQuery) => Promise<IAddressAutoComplete>;
  loadPlaceDetails: (placeId: string) => Promise<IPlaceDetails>;
  loadPlaceDetailsFromLocation: (location: ICoordinates) => Promise<IPlaceDetails>;
  loadPlaceDetailsFromIp: () => Promise<IPlaceDetails>;
}

type SearchAddressState = Omit<ISearchAddress, "onReset" | "onClearPredictions" | "onSearch">;
type LoadPlaceDetailsState = Omit<ILoadPlaceDetails, "onLoad">;
type GeoCodePlaceDetailsState = Omit<
  IGeoCodePlaceDetails,
  "loadPlaceDetailsFromLocation" | "loadPlaceDetailsFromLocationOrIpReset" | "loadPlaceDetailsFromIp"
>;
const initialSearchAddressState: SearchAddressState = {
  status: "Init",
  query: null,
  predictions: null,
  currentLocation: null,
  error: null
};
const initialLoadPlaceDetailsState: LoadPlaceDetailsState = {
  status: "Init",
  placeId: null,
  placeDetails: null,
  error: null
};
const initialGeoCodePlaceDetailsState: GeoCodePlaceDetailsState = {
  loadFromIpStatus: "Init",
  loadFromLocationStatus: "Init",
  location: null,
  placeDetails: null,
  error: null
};

function withApi(apiClient: IApiClient): React.ComponentType<Omit<IAddressInputProps, keyof IInjectedApiProps>>;
function withApi(api: IApi): React.ComponentType<Omit<IAddressInputProps, keyof IInjectedApiProps>>;
function withApi(
  apiOrApiClient: IApi | IApiClient
): React.ComponentType<Omit<IAddressInputProps, keyof IInjectedApiProps>> {
  const api: IApi =
    "searchAddress" in apiOrApiClient
      ? apiOrApiClient
      : {
          searchAddress: (query: IAddressQuery) =>
            apiOrApiClient.get<IAddressAutoComplete>("location/v1/address/auto-complete", {
              params: query
            }),
          loadPlaceDetails: (placeId: string) =>
            apiOrApiClient.get<IPlaceDetails>("location/v1/address/places", { params: { placeId } }),
          loadPlaceDetailsFromIp: () => apiOrApiClient.get<IPlaceDetails>("location/v1/address/geocode/ip"),
          loadPlaceDetailsFromLocation: (location: ICoordinates) =>
            apiOrApiClient.get<IPlaceDetails>("location/v1/address/geocode/location", {
              params: { lat: location.lat, lng: location.lng }
            })
        };

  class WithApi extends React.Component<Omit<IAddressInputProps, keyof IInjectedApiProps>, IApiState> {
    public state: IApiState = {
      searchAddress: initialSearchAddressState,
      loadPlaceDetails: initialLoadPlaceDetailsState,
      geoCodePlaceDetails: initialGeoCodePlaceDetailsState
    };

    public render() {
      const { searchAddress, loadPlaceDetails, geoCodePlaceDetails } = this.state;

      return (
        <AddressInput
          {...this.props}
          searchAddress={{
            ...searchAddress,
            onReset: this.onSearchAddressReset,
            onClearPredictions: this.onSearchAddressClearPredictions,
            onSearch: this.onSearchAddressSearch
          }}
          loadPlaceDetails={{ ...loadPlaceDetails, onLoad: this.onLoadPlaceDetailsLoad }}
          geoCodePlaceDetails={{
            ...geoCodePlaceDetails,
            loadPlaceDetailsFromLocationOrIpReset: this.onGeoCodePlaceDetailsLoadPlaceDetailsFromLocationOrIpReset,
            loadPlaceDetailsFromIp: this.onGeoCodePlaceDetailsLoadPlaceDetailsFromIp,
            loadPlaceDetailsFromLocation: this.onGeoCodePlaceDetailsLoadPlaceDetailsFromLocation
          }}
        />
      );
    }

    private onSearchAddressReset = () => {
      this.setState({ searchAddress: initialSearchAddressState });
    };

    private onSearchAddressClearPredictions = () => {
      this.setState({ searchAddress: { ...this.state.searchAddress, predictions: [] } });
    };

    private onSearchAddressSearch = async (query: IAddressQuery) => {
      this.setState({ searchAddress: { ...this.state.searchAddress, status: "Pending", query } });
      try {
        const addressAutoComplete = await api.searchAddress(query);
        if (this.state.searchAddress.status === "Pending" && _.isEqual(query, this.state.searchAddress.query)) {
          this.setState({
            searchAddress: {
              ...this.state.searchAddress,
              status: "Success",
              predictions: addressAutoComplete.predictions,
              currentLocation: this.state.searchAddress.currentLocation
                ? this.state.searchAddress.currentLocation
                : addressAutoComplete.currentLocation,
              error: null
            }
          });
        }
      } catch (err) {
        const error: any = err;
        if (this.state.searchAddress.status === "Pending" && _.isEqual(query, this.state.searchAddress.query)) {
          this.setState({
            searchAddress: {
              ...this.state.searchAddress,
              status: "Error",
              predictions: null,
              error
            }
          });
        }
      }
    };

    private onLoadPlaceDetailsLoad = async (placeId: string) => {
      this.setState({ loadPlaceDetails: { status: "Pending", placeId, placeDetails: null, error: null } });
      try {
        const placeDetails = await api.loadPlaceDetails(placeId);
        if (this.state.loadPlaceDetails.status === "Pending" && placeId === this.state.loadPlaceDetails.placeId) {
          this.setState({
            loadPlaceDetails: { ...this.state.loadPlaceDetails, status: "Success", placeDetails }
          });
        }
      } catch (err) {
        const error: any = err;
        if (this.state.loadPlaceDetails.status === "Pending" && placeId === this.state.loadPlaceDetails.placeId) {
          this.setState({
            loadPlaceDetails: { ...this.state.loadPlaceDetails, status: "Error", error }
          });
        }
      }
    };

    private onGeoCodePlaceDetailsLoadPlaceDetailsFromLocationOrIpReset = () => {
      this.setState({ geoCodePlaceDetails: initialGeoCodePlaceDetailsState });
    };

    private onGeoCodePlaceDetailsLoadPlaceDetailsFromIp = async () => {
      this.setState({
        geoCodePlaceDetails: {
          ...this.state.geoCodePlaceDetails,
          loadFromIpStatus: "Pending",
          placeDetails: null,
          error: null
        }
      });
      try {
        const placeDetails = await api.loadPlaceDetailsFromIp();

        if (this.state.geoCodePlaceDetails.loadFromIpStatus === "Pending") {
          this.setState({
            geoCodePlaceDetails: {
              ...this.state.geoCodePlaceDetails,
              loadFromIpStatus: "Success",
              placeDetails,
              error: null
            },
            searchAddress: {
              ...this.state.searchAddress,
              currentLocation: this.state.searchAddress.currentLocation
                ? this.state.searchAddress.currentLocation
                : { latitude: placeDetails.latitude, longitude: placeDetails.longitude }
            }
          });
        }
      } catch (err) {
        const error: any = err;
        if (this.state.geoCodePlaceDetails.loadFromIpStatus === "Pending") {
          this.setState({
            geoCodePlaceDetails: {
              ...this.state.geoCodePlaceDetails,
              loadFromIpStatus: "Error",
              placeDetails: null,
              error
            }
          });
        }
      }
    };

    private onGeoCodePlaceDetailsLoadPlaceDetailsFromLocation = async (location: ICoordinates) => {
      this.setState({
        geoCodePlaceDetails: {
          ...this.state.geoCodePlaceDetails,
          loadFromLocationStatus: "Pending",
          location,
          placeDetails: null,
          error: null
        }
      });
      try {
        const placeDetails = await api.loadPlaceDetailsFromLocation(location);

        if (
          this.state.geoCodePlaceDetails.loadFromLocationStatus === "Pending" &&
          _.isEqual(location, this.state.geoCodePlaceDetails.location)
        ) {
          this.setState({
            geoCodePlaceDetails: {
              ...this.state.geoCodePlaceDetails,
              loadFromLocationStatus: "Success",
              placeDetails,
              error: null
            },
            searchAddress: {
              ...this.state.searchAddress,
              currentLocation: this.state.searchAddress.currentLocation
                ? this.state.searchAddress.currentLocation
                : { latitude: location.lat, longitude: location.lng }
            }
          });
        }
      } catch (err) {
        const error: any = err;
        if (
          this.state.geoCodePlaceDetails.loadFromLocationStatus === "Pending" &&
          _.isEqual(location, this.state.geoCodePlaceDetails.location)
        ) {
          this.setState({
            geoCodePlaceDetails: {
              ...this.state.geoCodePlaceDetails,
              loadFromLocationStatus: "Error",
              placeDetails: null,
              error
            }
          });
        }
      }
    };
  }

  return WithApi;
}

export default withApi;
