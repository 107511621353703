import { NameType, NameVisibility } from "components/blocks/blockModels";
import { GalleryPresentSettingList, GalleryPresentSettingType } from "components/ClientView/models";
import { ISetting } from "../../models";

interface GallerySettings {
  nameType: NameType;
  nameVisibility: NameVisibility;
  showCaption: boolean;
}

export enum NAME_TYPE {
  fileName = "fileName",
  sequenceNumber = "sequenceNumber",
  none = "none"
}

export enum NAME_VISIBILITY {
  always = "always",
  hover = "hover"
}

export const getAlbumPresentSettings = (albumSettings: ISetting[]): GallerySettings => {
  let settings = {} as GallerySettings;

  albumSettings.forEach(({ settingType, id, value }) => {
    if (settingType === GalleryPresentSettingType.LayoutOptionsContent) {
      settings.nameType =
        id === GalleryPresentSettingList.LayoutOptionsContentNone
          ? "none"
          : id !== GalleryPresentSettingList.LayoutOptionsContentSequenceNumbers
          ? "fileName"
          : "sequenceNumber";
    }
    if (settingType === GalleryPresentSettingType.LayoutOptionsShow) {
      settings.nameVisibility = id !== GalleryPresentSettingList.LayoutOptionsShowAlways ? "hover" : "always";
    }
    if (settingType === GalleryPresentSettingType.ShowCaptions) {
      settings.showCaption = value === "true";
    }
  });

  return settings;
};
