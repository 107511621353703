import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { loadCalendarsReset } from "../../../../store/profile/googleCalendar/actions";

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    onReset: () => dispatch(loadCalendarsReset())
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
