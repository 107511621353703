import { isMobile } from "react-device-detect";

export const getButtonAction = (
  mediaHoverIndex: number,
  currentIndex: number,
  clickedIconIndex: number,
  hoveredIconIndex: number,
  mediaIsActive: boolean,
  defaultIcon: string,
  hoverIcon: string,
  clickedIcon: string,
  activeIcon: string,
  inactivateIcon: string
) => {
  let result =
    mediaHoverIndex === currentIndex || isMobile
      ? clickedIconIndex === currentIndex && !isMobile
        ? hoveredIconIndex === currentIndex
          ? clickedIcon
          : mediaIsActive
          ? inactivateIcon
          : defaultIcon
        : hoveredIconIndex === currentIndex && !isMobile
        ? mediaIsActive
          ? inactivateIcon
          : hoverIcon
        : mediaIsActive
        ? activeIcon
        : defaultIcon
      : "";
  return result;
};

export const getButtonActionHover = (
  mediaHoverIndex: number,
  currentIndex: number,
  clickedIconIndex: number,
  hoveredIconIndex: number,
  defaultIcon: string,
  hoverIcon: string,
  clickedIcon: string
) => {
  return mediaHoverIndex === currentIndex || isMobile
    ? clickedIconIndex === currentIndex && !isMobile
      ? hoveredIconIndex === currentIndex
        ? clickedIcon
        : ""
      : hoveredIconIndex === currentIndex && !isMobile
      ? hoverIcon
      : defaultIcon
    : "";
};
