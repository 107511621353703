import classNames from "classnames";
import * as React from "react";
import styles from "./index.module.scss";
import toNumber from "lodash/toNumber";

interface IProgressProps {
  value: number;
  max: number;
  className?: string;
}

export const FormProgress: React.FC<IProgressProps> = props => {
  const percent = (toNumber(props.value) / toNumber(props.max)) * 100;
  const progressClasses = classNames(styles.progressBar, props.className);
  return (
    <div className={styles.container}>
      <div
        className={progressClasses}
        style={{ width: `${percent}%` }}
        role="progressbar"
        aria-valuenow={props.value}
        aria-valuemin={0}
        aria-valuemax={props.max}
      />
    </div>
  );
};
