import cx from "classnames";
import { IZenSiteTheme } from "../../../models/models";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { getSiteFonts } from "utilities/blocks/site";
import { PageContext } from "utilities/pageContext";
import React, { Component, ContextType, createRef, RefObject, SyntheticEvent } from "react";
import { IBlogPostCategoryInfo } from "../zenBlogLandingBlock/model";
import Line from "./Line";
import LikesCounter from "./options/LikesCounter/LikesCounter";
import Share from "./options/Share/Share";
import ViewsCounter from "./options/ViewsCounter/ViewsCounter";
import Tags from "./Tags";
import styles from "./zenBlogPostFooter.module.scss";

export enum ZenBlogPostFooterBlockAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right"
}

export interface IBlogPostFooterSettings {
  showViews: boolean;
  showLikes: boolean;
  showShare: boolean;
}

export interface IFooterCategory extends IBlogPostCategoryInfo {
  url?: string;
}

export interface IZenBlogPostFooterBlockProps {
  siteTheme: IZenSiteTheme;
  isSelected: boolean;
  backgroundType?: string;
  backgroundColor?: string;

  config: IBlogPostFooterSettings;
  viewsCounter?: number;
  likesCounter?: number;
  categories?: IFooterCategory[];
  onShareClick?: () => void;
  onLikeClick?: () => void;
  liked?: boolean;
  tags?: string[];
  loadedInEditor?: boolean;
}

interface IZenBlogPostFooterBlockState {}

function CategoryDivider() {
  const categoryDivider = "   •   ";
  return <div className={styles.divider}>{categoryDivider}</div>;
}

export class ZenBlogPostFooterBlock extends Component<IZenBlogPostFooterBlockProps, IZenBlogPostFooterBlockState> {
  public static contextType = PageContext;
  private containerRef: RefObject<HTMLDivElement>;

  constructor(props: IZenBlogPostFooterBlockProps, context: ContextType<typeof PageContext>) {
    super(props);
    this.containerRef = createRef<HTMLDivElement>();

    this.state = {
      showAllTags: false
    };
  }

  private cancelDrag = (event: SyntheticEvent) => {
    event.preventDefault();
    return false;
  };

  private renderCategories = (categories?: IFooterCategory[]) => {
    return categories?.map((c, i) => {
      const link = <a href={c.url}>{c.name}</a>;

      if (i) {
        return (
          <React.Fragment key={i}>
            <CategoryDivider />
            {link}
          </React.Fragment>
        );
      }

      return <React.Fragment key={i}>{link}</React.Fragment>;
    });
  };

  public render() {
    const {
      isSelected,
      siteTheme,
      config,
      viewsCounter,
      likesCounter,
      categories,
      onShareClick,
      backgroundType,
      backgroundColor,
      onLikeClick,
      liked,
      tags,
      loadedInEditor
    } = this.props;

    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    const colorStyles = {
      backgroundColor: siteTheme.backgroundColor ? siteTheme.backgroundColor.value : undefined,
      color: getBlockTextColor(
        backgroundType === "none" ? siteTheme.backgroundColor.value : backgroundColor,
        siteTheme.accentColor.value,
        backgroundType,
        siteTheme.backgroundColor.value
      )
    };

    const contentContainerClass = cx(styles.content, styles[siteFonts.secondary]);

    let rootContainerClass = styles.root;

    if (isSelected) {
      rootContainerClass = cx(rootContainerClass, styles.selected);
    }

    return (
      <div
        id="blogFooter"
        onDragStart={this.cancelDrag}
        ref={this.containerRef}
        className={rootContainerClass}
        style={colorStyles}
      >
        <style>
          {`
            :root {
              --accent-color: ${siteTheme.accentColor.value};
              --text-color: ${colorStyles.color}
              }
            `}
        </style>
        <div className={contentContainerClass}>
          {tags && !!tags.length && (
            <Tags
              tags={tags}
              buttonsStyle={siteTheme.buttonsStyle}
              loadedInEditor={loadedInEditor}
              accentColor={siteTheme.accentColor}
            />
          )}
          <div>
            <Line />
            <div className={styles.categoriesAndShareContainer}>
              <div>{config.showShare ? <Share onShareClick={onShareClick} /> : null}</div>
              <div className={styles.categories}>{this.renderCategories(categories)}</div>
            </div>
            <Line />
            {config.showViews || config.showLikes ? (
              <div className={styles.viewsAndLikesContainer}>
                <div>{config.showViews ? <ViewsCounter count={viewsCounter} /> : null}</div>
                <div>
                  {config.showLikes ? (
                    <LikesCounter count={likesCounter} onClick={liked ? undefined : onLikeClick} liked={liked} />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
