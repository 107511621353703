import { Utilities } from "@zenfolio/core-components";
import cx from "classnames";
import * as React from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";

import styles from "./index.module.scss";

export interface IFractionalValue {
  formattedValue: string;
  floatValue: number;
}

export function toFractionalValue(value: number): IFractionalValue {
  return {
    formattedValue: Utilities.formatFractionalNumber(value),
    floatValue: value
  };
}

interface IFractionalPriceInputProps {
  value?: IFractionalValue | null;
  disabled?: boolean;
  errorMessage?: string | null;
  forceShowingError?: boolean;
  className?: string;
  withErrorClassName?: string;
  inputClassName?: string;
  errorMessageClassName?: string;
  currencyBlock?: boolean;
  onChange?: (value: IFractionalValue | null) => void;
  onBlur?: () => void;
  inputRef?: React.Ref<HTMLInputElement>;
}

interface IFractionalPriceInputState {
  wasFocused: boolean;
}

interface IValidationThresholds {
  min?: number;
  max?: number;
}

interface IValidationSettings extends IValidationThresholds {
  countryCode: string;
  overMaxErrorMessage?: string | null | undefined;
  underMinErrorMessage?: string | null | undefined;
  emptyErrorMessage?: string | null | undefined;
}

class FractionalPriceInput extends React.PureComponent<IFractionalPriceInputProps, IFractionalPriceInputState> {
  constructor(props: IFractionalPriceInputProps) {
    super(props);

    this.state = {
      wasFocused: false
    };
  }

  public render() {
    const { wasFocused } = this.state;
    const {
      value,
      disabled,
      className,
      inputClassName,
      errorMessage,
      currencyBlock,
      forceShowingError,
      withErrorClassName,
      errorMessageClassName,
      inputRef
    } = this.props;
    const showError = !!errorMessage && (forceShowingError || wasFocused);
    const { culture, currencyCode } = Utilities.getCurrencyFormattingInfo("US");
    const currencySymbol = Utilities.getCurrencyInfo(currencyCode).symbol;

    return (
      <div
        className={cx(
          styles.container,
          showError && styles.withError,
          currencyBlock && styles.withCurrencyBlock,
          className,
          showError && withErrorClassName
        )}
      >
        <NumberFormat
          className={cx(styles.input, inputClassName)}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          placeholder={currencyBlock ? Utilities.formatFractionalNumber(0, culture) : currencySymbol}
          prefix={currencyBlock ? undefined : currencySymbol}
          value={value?.formattedValue || ""}
          disabled={disabled}
          onBlur={this.onBlur}
          onValueChange={this.onValueChange}
          getInputRef={inputRef}
        />
        {showError && <div className={cx(styles.errorMessage, errorMessageClassName)}>{errorMessage}</div>}
        {currencyBlock && <div className={styles.currencyBlock}>{currencySymbol}</div>}
      </div>
    );
  }

  public static validate(value: number | null, settings: IValidationSettings) {
    if (value == null) {
      return settings.emptyErrorMessage;
    }

    if (settings.min != null && settings.underMinErrorMessage && value < settings.min) {
      return FractionalPriceInput.formatError(settings.underMinErrorMessage, "min", settings);
    }

    if (settings.max != null && settings.overMaxErrorMessage && value > settings.max) {
      return FractionalPriceInput.formatError(settings.overMaxErrorMessage, "max", settings);
    }

    return null;
  }

  private static formatError(template: string, argument: keyof IValidationThresholds, settings: IValidationSettings) {
    return template.replace(
      "{" + argument + "}",
      Utilities.formatNumberWithCurrency(settings[argument]!, settings.countryCode)
    );
  }

  private onBlur = () => {
    this.setState({ wasFocused: true }, this.props.onBlur);
  };

  private onValueChange = (values: NumberFormatValues) => {
    this.props.onChange?.(
      !values.formattedValue || values.floatValue == null
        ? null
        : {
            formattedValue: values.formattedValue,
            floatValue: values.floatValue
          }
    );
  };
}

export default FractionalPriceInput;
