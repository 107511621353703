import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { cancel as cancelAction } from "../../../../store/bookings/actions";
import { IAppState } from "../../../../store/state";

function mapStateToProps({ bookings: { cancel } }: IAppState) {
  return { cancel };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    cancel: {
      onCancel: (bookingId: string, note?: string) => dispatch(cancelAction(bookingId, note))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: any) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    cancel: { ...stateProps.cancel, ...dispatchProps.cancel }
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
