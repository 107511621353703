import cx from "classnames";
import _ from "lodash";
import { IBookMeService } from "models/models";
import { getSiteFonts, getSiteScrollbarWidth } from "utilities/blocks/site";
import colors from "utilities/colors";
import { getBookMeServicesPlaceHolders } from "utilities/placeholdes";
import { Utilities } from "utilities/utilities";
import React from "react";
import Loading from "../../../icons/loading-no-bg.svg";
import { IAddress } from "../../../models/location";
import { ILink } from "../../../models/models";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks";
import Service from "./service/index";
import styles from "./zenService.module.scss";
import ZenServiceBlockWrapper from "./zenServiceBlockWrapper/index";

export interface IPackage {
  id: string;
  title: string;
  price: number;
  isSelected: boolean;
  includes: string[];
  isVisible: boolean;
}

export interface IService {
  id: string;
  category: string;
  isConnected: boolean;
  connectedServiceID?: string;
  index: number;
  aboutService: string;
  showMore: boolean;
  showContact: boolean;
  buttonLabel: string;
  linkType: string;
  imageUrl: string;
  imageSrcSet?: string;
  imageName: string;
  imageWidth: number;
  imageHeight: number;
  imageId: string;
  packages: IPackage[];
  isSelected: boolean;
  isVisible: boolean;
  confirmationTitle: string;
  confirmationBody: string;
  focalX?: number;
  focalY?: number;
  altText?: string;
  imageAlbumId?: string;
  link?: ILink;
  sendTo?: number;
}

export interface IShootLocation extends IAddress {
  id?: string;
  isPrimary: boolean;
  isCustom?: boolean;
  displayName: string;
  locationDetails: string | null;
}

export enum SendInvoiceOption {
  None = "none",
  SameAsShootDate = "sameAsShootDate",
  BeforeShootDate = "beforeShootDate",
  AfterShootDate = "afterShootDate",
  Manually = "manually"
}

export interface IDeposit<T = number> {
  depositAmount: T | null;
  sendInvoiceOption: SendInvoiceOption;
  sendInvoiceDays: number;
}

export interface IPhotographerShootType {
  shootTypeId: number;
  name: string;
}

export interface IBookMePackage extends IPackage, IBookMeService {}

export interface IServiceBlockProps extends IZenBaseBlockProps {
  services?: IService[];
  showImages: boolean;
  readOnly: boolean;
  updateBlockState?: boolean;
  subdomain?: string;
  layout: string;
  studioName: string;
  isEditionView?: boolean;
  bookMePhotographerId?: string;
  bookMeWidgetUrl?: string;
  isFetching?: boolean;
  setServices?: (value: IService[]) => void;
  onSendButtonClick: (formData: any, onSuccess: () => void, onError: (error: any) => void) => void;
  onGetAddServiceNotes?: () => React.ReactNode;
  isPublish?: boolean;
  SSRContextManager?: any;
  international?: boolean;
  countryCode?: string;
  currencyCode?: string;
}

interface IServiceBlockState {
  services: IService[];
  updateServices: boolean;
  selectedIndex: number;
  layout: string;
  scaleFactor?: number;
}

// this brake value matches the value used in media query
const MOBILE_BRAKE_WIDTH = 450;
const MAX_CONTAINER_WIDTH = 1176;
export class ZenServicesBlock extends React.Component<IServiceBlockProps, IServiceBlockState> {
  private mainContainerRef: React.RefObject<HTMLDivElement>;
  private servicesContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: IServiceBlockProps) {
    super(props);
    this.state = {
      services: this.props.services ? this.initServices(this.props.services) : [],
      updateServices: false,
      selectedIndex: -1,
      layout: this.props.layout
    };

    this.mainContainerRef = React.createRef();
    this.servicesContainerRef = React.createRef();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IServiceBlockProps) {
    if (this.props.layout !== nextProps.layout && nextProps.layout) {
      this.setState({ layout: nextProps.layout }, () => {
        this.validateLayout();
      });
    }

    if (nextProps.services && nextProps.services !== this.state.services) {
      this.setState({ services: nextProps.services });
    }
  }

  public componentDidMount() {
    window.addEventListener("resize", this.resizeListener);
    this.validateLayout();
  }

  public shouldComponentUpdate(nextProps: IServiceBlockProps, nextState: IServiceBlockState) {
    if (nextProps.updateBlockState !== this.props.updateBlockState) {
      this.setState({ updateServices: !this.state.updateServices });
    }

    return (
      nextProps.updateBlockState !== this.props.updateBlockState ||
      nextProps.layout !== this.props.layout ||
      nextState.selectedIndex !== this.state.selectedIndex ||
      nextState.layout !== this.state.layout ||
      nextProps.siteTheme !== this.props.siteTheme ||
      nextState.scaleFactor !== this.state.scaleFactor ||
      nextProps.services !== this.props.services ||
      nextProps.isFetching !== this.props.isFetching
    );
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  private initServices = (services: IService[]) => {
    if (this.props.isEditionView) {
      const newServices = services.map(service => {
        return {
          ...service,
          isSelected: false
        };
      });

      return newServices;
    } else {
      return services;
    }
  };

  private setSelectedIndex = (idx: number) => {
    this.setState({ selectedIndex: idx });
  };

  private validateLayout = () => {
    const containerEl = this.mainContainerRef.current ? this.mainContainerRef.current : null;
    const scrollbarWidth = containerEl ? getSiteScrollbarWidth(containerEl) : 0;
    const blockWidth = containerEl ? containerEl.offsetWidth : null;

    if (this.props.layout === "B" && blockWidth && blockWidth <= MOBILE_BRAKE_WIDTH - scrollbarWidth) {
      this.setState({ layout: "A" });
    } else if (this.props.layout === "B") {
      this.setState({ layout: "B" });
    } else {
      this.setState({ layout: "A" });
    }

    if (blockWidth) {
      this.setState({
        scaleFactor: blockWidth <= MAX_CONTAINER_WIDTH ? blockWidth / MAX_CONTAINER_WIDTH : 1
      });
    }
  };

  private resizeListener = () => this.validateLayout();

  private getServicesMap = (services: IService[]) => {
    const {
      siteTheme,
      showImages,
      readOnly,
      subdomain,
      onSendButtonClick,
      isEditionView,
      bookMePhotographerId,
      bookMeWidgetUrl,
      international,
      countryCode
    } = this.props;
    const hasVisiblePackages = (svc: IService) => svc.packages?.some(p => p.isVisible);
    const isBookMe = !!bookMePhotographerId;
    const servicesToMap = isBookMe ? services.filter(hasVisiblePackages) : services;
    const hasFractionalPrices = _.some(servicesToMap, svc =>
      _.some(
        svc.packages,
        pkg =>
          pkg.isVisible &&
          (Utilities.isFractionalNumber(pkg.price) ||
            (isBookMe && Utilities.isFractionalNumber((pkg as IBookMePackage).deposit?.depositAmount)))
      )
    );
    return servicesToMap.map((service: IService, index: number) => {
      return (
        <React.Fragment key={index}>
          <Service
            isEditionView={isEditionView}
            siteTheme={siteTheme}
            showImage={showImages}
            index={index}
            value={service}
            isLast={services.length - 1 === index}
            accentColor={siteTheme.accentColor.value}
            backgroundColor={siteTheme.backgroundColor.value}
            readOnly={readOnly}
            selectedIndex={this.state.selectedIndex}
            setSelectedIndex={this.setSelectedIndex}
            subdomain={subdomain}
            layout={this.state.layout}
            international={international}
            hasFractionalPrices={hasFractionalPrices}
            countryCode={countryCode}
            studioName={this.props.studioName}
            onSendButtonClick={onSendButtonClick}
            bookMePhotographerId={bookMePhotographerId}
            bookMeWidgetUrl={bookMeWidgetUrl}
            currencyCode={this.props.currencyCode}
          />
        </React.Fragment>
      );
    });
  };

  public render() {
    const {
      siteTheme,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      readOnly,
      divider,
      alignment,
      fontFamily,
      padding,
      animationScrollIn,
      animationScrollOut,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      selectedBlock,
      isFetching
    } = this.props;
    const { services } = this.state;
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    return (
      <ZenBaseBlock
        ref={this.mainContainerRef}
        siteTheme={siteTheme}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        readOnly={readOnly}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        selectedBlock={selectedBlock}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <style>
            {`
            :root {
              --svc-scale-factor: ${this.state.scaleFactor || 1};
              }
            `}
          </style>
          {services && (
            <ZenServiceBlockWrapper>
              <div
                ref={this.servicesContainerRef}
                className={cx(
                  styles.zenContainerService,
                  isFetching && styles.fetching,
                  this.state.layout === "A" ? styles.flexDirectionRow : styles.flexDirectionColumn,
                  styles[siteFonts.primary]
                )}
                data-outside-subblock={true}
              >
                {this.renderAddServiceNotes() || this.getServicesMap(services)}
                {isFetching && this.renderLoading()}
              </div>
            </ZenServiceBlockWrapper>
          )}
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }

  private renderAddServiceNotes() {
    if (this.props.isFetching) {
      return;
    }

    const addServiceNotes = this.props.onGetAddServiceNotes?.();
    if (!addServiceNotes) {
      return;
    }

    const bgColor = this.props.siteTheme.backgroundColor.value;
    const isDarkBackground = bgColor === colors.inputDark || bgColor === colors.black;
    const services = _.cloneDeep(getBookMeServicesPlaceHolders(isDarkBackground));

    return (
      <>
        {this.getServicesMap(services)}
        <div
          className={styles.addService}
          style={{ backgroundColor: `rgba(${isDarkBackground ? "0, 0, 0" : "255, 255, 255"}, 0.35)` }}
        >
          <div className={styles.notes}>
            <label>{addServiceNotes}</label>
          </div>
        </div>
      </>
    );
  }

  private renderLoading() {
    return (
      <div className={styles.loading}>
        <img src={Loading} alt="loading" />
      </div>
    );
  }
}
