import React from "react";

function OrientationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#CCC"
          d="M7 21v1H6v-1h1zm2 0v1H8v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zM7 19v1H6v-1h1zm14 0v1h-1v-1h1zM7 17v1H6v-1h1zm14 0v1h-1v-1h1zM7 15v1H6v-1h1zm14 0v1h-1v-1h1zM7 13v1H6v-1h1zm2 0v1H8v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1z"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 9l2 2 2-2"
        />
        <path stroke="#000" d="M2.5 17.5v-14h8v14z"/>
        <path
          stroke="#000"
          strokeLinecap="round"
          d="M13 5c3 0 4.333 1.667 4 5"
        />
      </g>
    </svg>
  );
}

export default OrientationIcon;
