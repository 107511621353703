import cx from "classnames";
import { IGallerySettings } from "models/blocks";
import { IToaster } from "components/Toaster";
import {
  IClientViewFolderState,
  IFolderContent,
  IPage,
  IPhotographerFoldersState,
  IZenSiteTheme
} from "../../../models/models";
import React, { PureComponent } from "react";
import { getSiteFonts } from "utilities/blocks/site";
import { IBreadcrumbItem, IExtraPage } from "../Breadcrumb";
import styles from "./dynamicfolder.module.scss";
import DynamicFolderView from "./Views/FolderView";

export interface IDynamicFolderProps {
  alignment?: string;
  showInfo?: boolean;
  perPage?: string;
  order?: string;
  appCdnUrl: string;
  folderId: string;
  error?: Error;
  subdomain?: string;
  sitePages: IPage[];
  siteTheme: IZenSiteTheme;
  pageAlias: string;
  extraPages?: IExtraPage[];
  gallerySettings: IGallerySettings;
  clientViewFolder: IClientViewFolderState;
  photographerFolders: IPhotographerFoldersState;
  isPublish?: boolean;
  rootFolderId?: string;
  isDevelopment: boolean;
  isNormalCase?: boolean;
  visibleItemsCount?: number;
  isLinkedFolder?: boolean;
  padding?: string;
  readonly?: boolean;
  onShowAlertError: (toaster: IToaster) => void;

  customHandleButtonClick?: (content: IFolderContent) => void;
  breadcrumbItems?: IBreadcrumbItem[];
  isBreadcrumbLoaded?: boolean;
}

// It's a wrapper for DynamicFolderView which just will render error message in case of current folder fetch was an error
export class DynamicFolder extends PureComponent<IDynamicFolderProps> {
  constructor(props: IDynamicFolderProps) {
    super(props);
  }

  public render() {
    const {
      clientViewFolder,
      gallerySettings,
      folderId,
      onShowAlertError,
      siteTheme,
      sitePages,
      pageAlias,
      photographerFolders,
      subdomain,
      extraPages,
      rootFolderId,
      isPublish,
      appCdnUrl,
      isDevelopment,
      alignment,
      showInfo,
      perPage,
      order,
      isNormalCase,
      visibleItemsCount,
      isLinkedFolder,
      padding,
      readonly,
      customHandleButtonClick,
      breadcrumbItems,
      isBreadcrumbLoaded
    } = this.props;

    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    if (clientViewFolder.errorDescription) {
      const { errorDescription, errorStatus } = clientViewFolder;
      const errorMessage = (errorStatus === 400 || errorStatus === 404) ? "Folder not found" : "An error has occurred";
      return (
        <div id="container" className={cx(styles.container, styles.hasError, styles[siteFonts.primary])}>
          <div className={styles.content}>
            <h1>{errorMessage}</h1>
            {errorStatus === 404 && (<p>{errorDescription}</p>)}
          </div>
        </div>
      );
    }

    return (
      <DynamicFolderView
        pageAlias={pageAlias}
        isPublish={isPublish}
        rootFolderId={rootFolderId}
        sitePages={sitePages}
        subdomain={subdomain}
        extraPages={extraPages}
        folderId={folderId}
        gallerySettings={gallerySettings}
        siteTheme={siteTheme}
        clientViewFolder={clientViewFolder}
        onShowAlertError={onShowAlertError}
        photographerFolders={photographerFolders}
        appCdnUrl={appCdnUrl}
        isDevelopment={isDevelopment}
        alignment={alignment}
        showInfo={showInfo}
        perPage={perPage}
        order={order}
        isNormalCase={isNormalCase}
        visibleItemsCount={visibleItemsCount}
        isLinkedFolder={isLinkedFolder}
        padding={padding}
        readOnly={readonly}
        customHandleButtonClick={customHandleButtonClick}
        breadcrumbItems={breadcrumbItems}
        isBreadcrumbLoaded={isBreadcrumbLoaded}
      />
    );
  }
}
