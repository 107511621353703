import classNames from "classnames";
import React from "react";
import Toastr from "../../../containers/toastr";
import Auxiliary from "../../../utilities/auxiliary";
import SearchInput from "../../searchInput";
import styles from "./index.module.scss";

export interface IPageHeaderProps {
  title: string;
  pageMode?: boolean;
  showSearchPanel?: boolean;
  onChangeSearchInput: (value: string) => void;
  onClearSearchInput: () => void;
}

class PageHeader extends React.Component<IPageHeaderProps> {
  public render() {
    const { showSearchPanel, onChangeSearchInput, onClearSearchInput } = this.props;
    return (
      <Auxiliary>
        <div className={classNames(styles.container, this.props.pageMode ? styles.pageMode : null)}>
          <div className={styles.title}>
            {this.props.title}
            {showSearchPanel && (
              <SearchInput placeholder="Search services" onChange={onChangeSearchInput} onClear={onClearSearchInput} />
            )}
          </div>
          <Toastr pageMode={this.props.pageMode} className={showSearchPanel ? styles.moveDown : undefined} />
        </div>
      </Auxiliary>
    );
  }
}

export default PageHeader;
