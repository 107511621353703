import React from "react";
import styles from "./breadcrumbSeparator.module.scss";
import cx from "classnames";

export interface IBreadcrumbSeparatorProps {
  className?: string;
}

const BreadcrumbSeparator: React.FC<IBreadcrumbSeparatorProps> = (props) => {
  return (
    <li className={cx(styles.breadcrumbSeparator, props.className)}>
      {props.children}
    </li>
  );
};

export default BreadcrumbSeparator;
