import { connect } from "react-redux";
import { injectStripe } from "react-stripe-elements";
import { ThunkDispatch } from "redux-thunk";
import PaymentStep from "../../../../../components/widget/steps/payment/invoice";
import { IAppState } from "../../../../../store/state";
import { doPayInvoice } from "../../../../../store/widget/actions";
import { IInvoicePaymentCreator, IPaymentCreator } from "../../../../../store/widget/model";

function mapStateToProps({ widget }: IAppState) {
  return {
    payInvoiceState: widget.payInvoiceState,
    loadInvoiceInfo: widget.loadInvoiceInfo!,
    loadInvoiceInfoState: widget.loadInvoiceInfoState
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    onPayInvoice: (paymentCreator: IPaymentCreator, invoicePaymentCreator: IInvoicePaymentCreator) =>
      dispatch(doPayInvoice(paymentCreator, invoicePaymentCreator))
  };
}

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(PaymentStep));
