import { Modal } from "@zenfolio/core-components";
import classNames from "classnames";
import React, { PureComponent } from "react";
import IFramePageInfoChange, { IPageInfo } from "../../../../utilities/iFramePageInfoChange";
import { NavigationManager } from "../../../../utilities/navigationManager";
import styles from "./index.module.scss";

interface IModalBaseState {
  isCompactHeight: boolean;
}

interface IModalBaseProps {
  onClose?: () => void;
  className?: string;
  overlayClassName?: string;
  compactHeightClassName?: string;
  isFullScreen?: boolean;
}

class ModalBase extends PureComponent<IModalBaseProps, IModalBaseState> {
  private modalRef = React.createRef<HTMLDivElement>();

  public state = {
    isCompactHeight: false
  };

  public componentDidMount() {
    document.documentElement.classList.add(styles.modalVisible);

    if (ModalBase.iframeMode) {
      NavigationManager.postParentMessage({ type: "modal-shown" });
      window.addEventListener("message", this.processMessage);
      IFramePageInfoChange.subscribe(this.onIFramePageInfoChange);
    }
  }

  public componentWillUnmount() {
    document.documentElement.classList.remove(styles.modalVisible);

    if (ModalBase.iframeMode) {
      NavigationManager.postParentMessage({ type: "modal-closed" });
      window.removeEventListener("message", this.processMessage);
      IFramePageInfoChange.unsubscribe(this.onIFramePageInfoChange);
    }
  }

  public render() {
    return (
      <Modal
        onClose={this.onClose}
        className={classNames(
          styles.container,
          ModalBase.iframeMode ? styles.modalInIframe : null,
          this.props.className,
          this.state.isCompactHeight && this.props.compactHeightClassName
        )}
        ref={this.modalRef}
        overlayClassName={this.props.overlayClassName}
      >
        {this.props.children}
      </Modal>
    );
  }

  private static get iframeMode() {
    return !NavigationManager.instance.widgetMode && NavigationManager.isInIframe();
  }

  private onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private processMessage = (event: MessageEvent) => {
    const data = NavigationManager.parseMessage(event);
    if (!data || !data.type) {
      return;
    }

    if (data.type === "close-modal") {
      this.onClose();
    }
  };

  private onIFramePageInfoChange = (info: IPageInfo) => {
    const element = this.modalRef.current;
    const baseOffset = info.scrollTop - info.offsetTop;

    if (element) {
      element.style.visibility = "visible";
      if (this.props.isFullScreen) {
        element.style.top = Math.max(baseOffset, 0) + "px";
        element.style.bottom = Math.max(info.iframeHeight - info.windowHeight - baseOffset, 0) + "px";
      } else {
        const minPadding = 15;
        const elementHeight = element.offsetHeight;
        let offset = baseOffset + (info.windowHeight - elementHeight) / 2;
        const diff = offset + elementHeight + minPadding - info.iframeHeight;

        if (diff > 0) {
          offset = Math.max(offset - diff, baseOffset + minPadding);
        }

        element.style.top = Math.max(offset, 0) + "px";
      }
    }

    this.setState({ isCompactHeight: info.windowHeight < 800 });
  };
}

export default ModalBase;
