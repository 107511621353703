import cx from "classnames";
import ClipboardJS from "clipboard";
import React from "react";
import styles from "./button.module.scss";

type ButtonType = "submit" | "reset" | "button";
type ButtonStyleType =
  | "primary"
  | "secondary"
  | "plain"
  | "plain-navbar"
  | "plain-navbar-white"
  | "borderedMedium"
  | "disabled";

export interface IButtonProps {
  onClick?: (evt: React.FormEvent<HTMLElement>) => void;
  type?: ButtonType;
  styleType?: ButtonStyleType;
  height?: number;
  styles?: React.CSSProperties;
  disabled?: boolean;
  clipBoardCustomText?: string;
  className?: string;
  customDataAttribute?: any;
  children?: React.ReactNode;
}

const Button: React.FC<IButtonProps> = props => {
  if (props.clipBoardCustomText) {
    // tslint:disable-next-line: no-unused-expression
    new ClipboardJS(".clipBoard");
  }

  const btnStyles = cx(styles.button, props.className, styles[props.styleType || "primary"], {
    clipBoard: props.clipBoardCustomText !== undefined
  });

  const customDataAttribute = { ...{}, ...props.customDataAttribute };

  return (
    <button
      disabled={props.disabled}
      type={props.type || "button"}
      className={btnStyles}
      onClick={props.onClick}
      style={{ ...props.styles }}
      data-clipboard-text={props.clipBoardCustomText}
      {...customDataAttribute}
    >
      {props.children}
    </button>
  );
};

export default Button;
