import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ISelectCalendarOwnProps, ISelectCalendarProps } from ".";
import { updateGoogleCalendar as updateGoogleCalendarAction } from "../../../../../store/profile/availability/actions";
import { loadCalendars as loadCalendarsAction } from "../../../../../store/profile/googleCalendar/actions";
import { IGoogleCalendar } from "../../../../../store/profile/googleCalendar/model";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({
  profile: {
    googleCalendar: { loadCalendars },
    availability: { updateGoogleCalendar }
  }
}: IAppState) {
  return {
    loadCalendars,
    save: updateGoogleCalendar
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    loadCalendars: {
      onLoad: () => dispatch(loadCalendarsAction())
    },
    save: {
      onSave: (calendar: IGoogleCalendar) => dispatch(updateGoogleCalendarAction(calendar))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: ISelectCalendarOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    loadCalendars: { ...stateProps.loadCalendars, ...dispatchProps.loadCalendars },
    save: { ...stateProps.save, ...dispatchProps.save }
  } as ISelectCalendarProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
