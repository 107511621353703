import { BannerType, BannerViewMode, BANNER_DESIGN_IDS } from "utilities/constant";
import { IGalleryCoupon } from "models/coupon";
import { LargeBannerDiscount1 } from "components/CouponBanner/LargeBannerDiscount1";
import { LargeBannerDiscount4 } from "components/CouponBanner/LargeBannerDiscount4";
import { LargeBannerSeasonal1 } from "components/CouponBanner/LargeBannerSeasonal1";
import { LargeBannerSeasonal3 } from "components/CouponBanner/LargeBannerSeasonal3";
import { CampaignBanner } from "components/CouponBanner/Campaign";
import { LargeBannerFreeShipping } from "components/CouponBanner/LargeBannerFreeShipping";
import { TextBanner } from "components/CouponBanner/TextBanner";
import React from "react";
import styles from "./couponBanners.module.scss";
import { ICurrency } from "../../../../models/models";
import { universalWindow as window } from "utilities/blocks/site";

export interface ICouponBannersProps {
  couponList?: IGalleryCoupon[];
  onClickBannerButton?: () => void;
  bannerStyle?: BANNER_STYLE;
  currency?: ICurrency;
}

interface BANNER_STYLE {
  largerBannerStyle?: Object;
  textBannerStyle?: Object;
}

interface ICouponBannersState {
  viewportWidth: number;
  isMobileSize: boolean;
}

class CouponBanners extends React.PureComponent<ICouponBannersProps, ICouponBannersState> {
  public state = {
    viewportWidth: window.innerWidth,
    isMobileSize: window.innerWidth < 767
  };
  public componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth, isMobileSize: window.innerWidth < 767 });
  };
  private renderTextBanner = (coupon: IGalleryCoupon) => {
    const { currency } = this.props;
    return (
      <TextBanner
        key={coupon.couponDiscountCode}
        couponType={coupon.couponDiscountType}
        couponAmount={coupon.couponDiscountAmount}
        couponCode={coupon.couponDiscountCode}
        backGroundColor={coupon.backgroundColor || ""}
        textColor={coupon.textColor || ""}
        title={coupon.title || ""}
        currency={currency}
      />
    );
  };

  private renderLargeBanner = (coupon: IGalleryCoupon) => {
    const { isMobileSize } = this.state;
    const { currency, onClickBannerButton } = this.props;
    const designId = coupon.designId as BANNER_DESIGN_IDS;
    switch (designId) {
      case BANNER_DESIGN_IDS.FreeShipping: {
        return (
          <LargeBannerFreeShipping
            key={coupon.couponDiscountCode}
            currency={currency}
            content={coupon.designContentObj}
            viewMode={isMobileSize ? BannerViewMode.MobileClient : BannerViewMode.DesktopClient}
            onClick={onClickBannerButton}
          />
        );
      }
      case BANNER_DESIGN_IDS.Discount1: {
        return (
          <LargeBannerDiscount1
            key={coupon.couponDiscountCode}
            currency={currency}
            content={coupon.designContentObj}
            viewMode={isMobileSize ? BannerViewMode.MobileClient : BannerViewMode.DesktopClient}
            onClick={onClickBannerButton}
          />
        );
      }
      case BANNER_DESIGN_IDS.Discount4: {
        return (
          <LargeBannerDiscount4
            key={coupon.couponDiscountCode}
            content={coupon.designContentObj}
            viewMode={isMobileSize ? BannerViewMode.MobileClient : BannerViewMode.DesktopClient}
            onClick={onClickBannerButton}
          />
        );
      }
      case BANNER_DESIGN_IDS.Seasonal2: {
        return (
          <LargeBannerSeasonal1
            key={coupon.couponDiscountCode}
            content={coupon.designContentObj}
            viewMode={isMobileSize ? BannerViewMode.MobileClient : BannerViewMode.DesktopClient}
            onClick={onClickBannerButton}
          />
        );
      }
      case BANNER_DESIGN_IDS.Seasonal3: {
        return (
          <LargeBannerSeasonal3
            key={coupon.couponDiscountCode}
            content={coupon.designContentObj}
            viewMode={isMobileSize ? BannerViewMode.MobileClient : BannerViewMode.DesktopClient}
            currency={currency}
          />
        );
      }
      case BANNER_DESIGN_IDS.Seasonal5: {
        return (
          <LargeBannerSeasonal3
            key={coupon.couponDiscountCode}
            content={coupon.designContentObj}
            viewMode={isMobileSize ? BannerViewMode.MobileClient : BannerViewMode.DesktopClient}
            currency={currency}
          />
        );
      }
      case BANNER_DESIGN_IDS.Halloween:
      case BANNER_DESIGN_IDS.Fathers:
      case BANNER_DESIGN_IDS.Mothers:
      case BANNER_DESIGN_IDS.FlashSale:
      case BANNER_DESIGN_IDS.Holiday:
      case BANNER_DESIGN_IDS.Spring:
      case BANNER_DESIGN_IDS.Summer:
      case BANNER_DESIGN_IDS.Valentine:
      case BANNER_DESIGN_IDS.BacktoSchool:
      case BANNER_DESIGN_IDS.BoxingDay: {
        return (
          <CampaignBanner
            key={coupon.couponDiscountCode}
            content={coupon.designContentObj}
            viewMode={isMobileSize ? BannerViewMode.MobileClient : BannerViewMode.DesktopClient}
            currency={currency}
          />
        );
      }
      default: {
        return (
          <LargeBannerDiscount1
            key={coupon.couponDiscountCode}
            content={coupon.designContentObj}
            viewMode={isMobileSize ? BannerViewMode.MobileClient : BannerViewMode.DesktopClient}
            onClick={onClickBannerButton}
          />
        );
      }
    }
  };

  private renderBannerType = (coupon: IGalleryCoupon, index: number) => {
    const { couponList, bannerStyle } = this.props;
    let margin = "0px";
    // if this is the last banner
    if (couponList && couponList.length - 1 === index) {
      margin = "0px";
    } else if (this.state.isMobileSize) {
      margin = "12px";
    } else {
      margin = "20px";
    }
    switch (coupon.bannerType.name) {
      case BannerType.LargeBanner: {
        return (
          <div key={index} style={{ marginBottom: margin, ...bannerStyle?.largerBannerStyle }}>
            {this.renderLargeBanner(coupon)}
          </div>
        );
      }
      case BannerType.TextBanner: {
        return (
          <div key={index} style={{ marginBottom: margin, ...bannerStyle?.textBannerStyle }}>
            {this.renderTextBanner(coupon)}
          </div>
        );
      }
    }
  };

  private hasLargeBannerOnly = (couponList: IGalleryCoupon[]) => {
    return !couponList.find(item => item.bannerType.name === BannerType.TextBanner);
  };

  public render() {
    const { couponList } = this.props;
    return couponList && couponList.length > 0 ? (
      <div className={styles.container}>
        {this.hasLargeBannerOnly(couponList) && <div className={styles.containerTextBanner}></div>}
        {couponList.map((item, index) => {
          return this.renderBannerType(item, index);
        })}
      </div>
    ) : null;
  }
}

export default CouponBanners;
