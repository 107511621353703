import { IBannerContent } from "models/coupon";
import { BannerViewMode } from "utilities/constant";

export function getLargeBannerStyle(viewMode: BannerViewMode, content?: IBannerContent) {
  switch (viewMode) {
    case BannerViewMode.DesktopClient: {
      return content?.backgroundImage
        ? { backgroundImage: `url(${content.backgroundImage})`, backgroundSize: "cover" }
        : { backgroundColor: content?.backgroundColor };
    }
    case BannerViewMode.MobileClient: {
      return content?.backgroundImageMobile
        ? { backgroundImage: `url(${content.backgroundImageMobile})`, backgroundSize: "cover" }
        : { backgroundColor: content?.backgroundColor };
    }
    default:
      return { backgroundColor: content?.backgroundColor };
  }
}
