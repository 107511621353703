import { Collapse, FormCheckbox, Panel, Utilities } from "@zenfolio/core-components";
import classNames from "classnames";
import _ from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import { SendInvoiceOption } from "../../../../store/services/model";
import { IShootType } from "../../../../store/shootTypes/model";
import { IService } from "../../../../store/widget/model";
import Auxiliary from "../../../../utilities/auxiliary";
import eventTracker from "../../../../utilities/eventTracker";
import Circle from "../../../icons/Circle";
import Success from "../../../icons/Success";
import { getGroupedServices, isFreeService, renderDurationAsStringLong } from "../../../services/common";
import { IEditableWidgetStepProps, IValidatableWidgetStep } from "../../contracts";
import styles from "./index.module.scss";

interface IServicesProps extends IEditableWidgetStepProps {
  services: IService[];
  onScrollTop: (top: number) => void;
}

export interface IServicesParams {
  oldService: IService | null;
  service: IService | null;
  agreePayDeposit: { [key: string]: boolean };
}

interface IServicesState extends IServicesParams {
  panels: { [id: string]: boolean };
}

class ServicesStep extends React.Component<IServicesProps, IServicesState> implements IValidatableWidgetStep {
  constructor(props: IServicesProps) {
    super(props);

    const values = props.getValues();

    this.state = {
      oldService: null,
      service: values.service,
      agreePayDeposit: values.agreePayDeposit,
      panels: {}
    };
  }

  public saveAndContinue = (): void => {
    const { service, agreePayDeposit } = this.state;

    eventTracker.context.consumer.service = { name: service!.name, duration: service!.duration };

    if (this.props.getValues().service !== service) {
      this.props.updateValues({
        oldService: this.props.getValues().service,
        service,
        agreePayDeposit
      });
    }
  };

  public deferNavigation = (): boolean => {
    return false;
  };

  public componentDidMount() {
    eventTracker.context.consumer.service = undefined;
    eventTracker.context.consumer.bookingId = undefined;
    this.props.onRef(this);
  }

  public componentWillUnmount() {
    this.props.onRef(undefined);
  }

  public isValid = (): boolean => {
    const { service, agreePayDeposit } = this.state;

    if (service == null) {
      return false;
    }

    if (isFreeService(service)) {
      return true;
    }

    return service.useDeposit ? agreePayDeposit[service.id] : true;
  };

  public render() {
    const { services } = this.props;

    const groupedServices = getGroupedServices(services);
    const renderShootTypes =
      groupedServices.length > 1 && groupedServices.findIndex(item => item.services.length > 1) !== -1;

    return (
      <div className={styles.container}>
        <div ref={this.collapseRef} className={styles.collapse}>
          <Collapse>
            {renderShootTypes ? this.renderShootTypes(groupedServices) : this.renderServices(services)}
          </Collapse>
        </div>
      </div>
    );
  }

  private renderShootTypes = (
    groupedServices: Array<{ shootType: IShootType; originalShootType: IShootType; services: IService[] }>
  ) => {
    const isOpen = (shootTypeId: string) => {
      return this.state.panels[shootTypeId];
    };

    return groupedServices.map(({ shootType, originalShootType, services }, groupIndex) => (
      <div key={shootType.id} className={styles.shootType}>
        <Panel
          key={groupIndex}
          title={this.renderShootTypeTitle(shootType, isOpen(shootType.id))}
          name={shootType.id}
          headerClassName={classNames(styles.header, styles.shootTypeHeader)}
          {...Panel.defaultProps}
          onChange={this.onShootTypePanelClick}
          showArrow={true}
        >
          {this.renderServices(services)}
        </Panel>
      </div>
    ));
  };

  private renderServices = (services: IService[]) => {
    return services.map((service, serviceIndex) => (
      <div key={service.id} className={this.getStyleForSelectedService(service)} data-service-id={service.id}>
        <Panel
          key={serviceIndex}
          title={this.renderServiceTitle(service)}
          name={service.id}
          headerClassName={styles.header}
          contentClassName={styles.panel}
          {...Panel.defaultProps}
          showArrow={false}
          onChange={this.onServicePanelClick}
          open={this.isServiceSelected(service)}
          onEnter={this.onServiceEnter}
          onExit={this.onServiceExit}
          onEntered={this.onServiceEntered}
          onExited={this.onServiceExited}
        >
          <Auxiliary>
            {service.serviceDetails.map((detail, detailIndex) => (
              <div className={styles.detail} key={`${serviceIndex}-${detailIndex}`}>
                <i>
                  <Success size={12} />
                </i>
                <p>{detail}</p>
              </div>
            ))}
            {this.renderNotes(service)}
          </Auxiliary>
        </Panel>
      </div>
    ));
  };

  private getStyleForSelectedService = (service: IService): string | undefined => {
    return classNames(styles.service, this.isServiceSelected(service) ? styles.active : undefined);
  };

  private onShootTypePanelClick = (key: string, open: boolean) => {
    this.setState({
      panels: {
        ...this.state.panels,
        [key]: open
      }
    });
  };

  private onServicePanelClick = (key: string, open: boolean) => {
    this.scrollToServiceId = open && (!this.state.service || this.state.service.id !== key) ? key : null;

    if (!open) {
      return;
    }

    const service =
      _.find(this.props.services, item => {
        return item.id === key;
      }) || null;

    this.setState(
      {
        service: open ? service : null
      },
      this.updateValidate
    );
  };

  private isServiceSelected = (service: IService) => {
    return service === this.state.service;
  };

  private renderShootTypeTitle = (shootType: IShootType, status: boolean): React.ReactNode => {
    return (
      <p className={styles.shootTypeTitle}>
        {this.renderStatusIcon(status)}
        <span className={styles.shootTypeName}>{shootType.name}</span>
      </p>
    );
  };

  private renderStatusIcon = (status: boolean) => {
    return status ? <span className={styles.icon}>{Circle(styles.circle)}</span> : null;
  };

  private renderServiceTitle = (service: IService): React.ReactNode => {
    return (
      <div className={styles.info}>
        <div className={styles.name}>{service.name}</div>
        <div className={styles.details}>
          <div className={styles.price}>
            <span className={styles.currency}>$</span>
            {Utilities.formatNumber(service.price)}
          </div>
          <div className={styles.duration}>{renderDurationAsStringLong(service.duration)}</div>
        </div>
      </div>
    );
  };

  private renderNotes = (service: IService): React.ReactNode => {
    if (isFreeService(service)) {
      return service.useDeposit ? this.renderFreeServiceDeposit(service) : this.renderApprovalRequiredNote(service);
    } else {
      return service.useDeposit ? this.renderDeposit(service) : this.renderApprovalRequiredNote(service);
    }
  };

  private renderDeposit = (service: IService): React.ReactNode => {
    const deposit = service.deposit;
    return (
      <div className={styles.deposit}>
        <div className={styles.description}>
          {this.renderApprovalRequiredNote(service)}
          <div>
            <span className={styles.currency}>$</span>
            {`${Utilities.formatNumber(deposit.depositAmount!)} dollar booking fee at time of ${
              service.approvalRequired ? "approval" : "booking"
            }.`}
          </div>
          {this.renderDepositDescription(service)}
        </div>

        <FormCheckbox
          checked={this.state.agreePayDeposit[service.id] || false}
          onChange={this.handleChangeDepositRequired}
          className={styles.depositRequired}
        >
          I acknowledge I am paying a non-refundable booking fee upon approval
        </FormCheckbox>
      </div>
    );
  };

  private renderFreeServiceDeposit = (service: IService): React.ReactNode => {
    const deposit = service.deposit;
    return (
      <div className={styles.deposit}>
        <div className={classNames(styles.description, styles.freeService)}>
          {this.renderApprovalRequiredNote(service)}
          <div>
            <span className={styles.currency}>$</span>
            {`${Utilities.formatNumber(deposit.depositAmount!)} ${
              service.approvalRequired ? "is due at time of booking" : "booking fee at time of booking"
            }.`}
          </div>
          {service.approvalRequired
            ? this.renderFreeDepositDescription(service)
            : this.renderDepositDescription(service)}
        </div>
      </div>
    );
  };

  private renderApprovalRequiredNote = (service: IService) => {
    if (!service.approvalRequired) {
      return null;
    }

    return (
      <div className={styles.approvalRequiredNote}>
        <div>Appointment subject to photographer approval.</div>
        {!isFreeService(service) && <div>Credit card will not be charged until appointment is approved.</div>}
      </div>
    );
  };

  private renderDepositDescription = (service: IService): React.ReactNode => {
    const deposit = service.deposit;
    switch (deposit.sendInvoiceOption) {
      case SendInvoiceOption.SameAsShootDate:
        return (
          <div>
            Remainder amount <span className={styles.currency}>$</span>
            {`${Utilities.formatNumber(service.price - deposit.depositAmount!)} is due on the day of the shoot.`}
          </div>
        );
      case SendInvoiceOption.Manually:
        return (
          <div>
            Remainder amount due is <span className={styles.currency}>$</span>
            {`${Utilities.formatNumber(service.price - deposit.depositAmount!)}.`}
          </div>
        );
      case SendInvoiceOption.BeforeShootDate:
      case SendInvoiceOption.AfterShootDate:
        return (
          <div>
            Remainder <span className={styles.currency}>$</span>
            {`${Utilities.formatNumber(service.price - deposit.depositAmount!)} is due ${this.getDaysPeriodDescription(
              deposit.sendInvoiceDays,
              deposit.sendInvoiceOption
            )}.`}
          </div>
        );

      default:
        return null;
    }
  };

  private renderFreeDepositDescription = (service: IService): React.ReactNode => {
    const deposit = service.deposit;
    switch (deposit.sendInvoiceOption) {
      case SendInvoiceOption.SameAsShootDate:
        return (
          <div>
            The full amount of <span className={styles.currency}>$</span>
            {`${Utilities.formatNumber(service.price)} is due on the day of the shoot.`}
          </div>
        );
      case SendInvoiceOption.Manually:
        return (
          <div>
            The full amount due is <span className={styles.currency}>$</span>
            {`${Utilities.formatNumber(service.price)}.`}
          </div>
        );
      case SendInvoiceOption.BeforeShootDate:
      case SendInvoiceOption.AfterShootDate:
        return (
          <div>
            The full amount of <span className={styles.currency}>$</span>
            {`${Utilities.formatNumber(service.price)} is due ${this.getDaysPeriodDescription(
              deposit.sendInvoiceDays,
              deposit.sendInvoiceOption
            )}.`}
          </div>
        );

      default:
        return null;
    }
  };

  private getDaysPeriodDescription = (days: number, sendInvoiceOption: SendInvoiceOption) => {
    switch (sendInvoiceOption) {
      case SendInvoiceOption.BeforeShootDate:
        return `${pluralize("day", days, true)} before shoot`;

      case SendInvoiceOption.AfterShootDate:
        return `${pluralize("day", days, true)} after shoot`;

      default:
        return "";
    }
  };

  private handleChangeDepositRequired = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    const serviceId = this.state.service!.id;
    this.setState(
      {
        agreePayDeposit: {
          ...this.state.agreePayDeposit,
          [serviceId]: event.target.checked
        }
      },
      this.updateValidate
    );
  };

  private onServiceExit = () => {
    this.waitForServiceExited = true;
  };

  private onServiceEnter = () => {
    this.waitForServiceEntered = true;
  };

  private onServiceExited = () => {
    this.waitForServiceExited = false;
    this.scrollToService();
  };

  private onServiceEntered = () => {
    this.waitForServiceEntered = false;
    this.scrollToService();
  };

  private scrollToService() {
    if (this.waitForServiceExited || this.waitForServiceEntered || !this.scrollToServiceId) {
      return;
    }

    const id = this.scrollToServiceId;
    this.scrollToServiceId = null;

    const collapseDiv = this.collapseRef.current;
    if (!collapseDiv) {
      return;
    }

    const serviceElement = collapseDiv.querySelector(`[data-service-id="${id}"]`) as HTMLElement;
    if (!serviceElement) {
      return;
    }

    const padding = collapseDiv.firstChild === serviceElement ? 5 : 4;
    this.props.onScrollTop(serviceElement.offsetTop - padding);
  }

  private updateValidate() {
    const { updateValidate, name } = this.props;
    updateValidate(name, this.isValid());
  }

  private waitForServiceExited: boolean = false;
  private waitForServiceEntered: boolean = false;
  private scrollToServiceId: string | null = null;
  private collapseRef = React.createRef<HTMLDivElement>();
}

export default ServicesStep;
