import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { approve as approveAction } from "../../../../store/bookings/actions";
import { IAppState } from "../../../../store/state";

function mapStateToProps({ bookings: { approve } }: IAppState) {
  return { approve };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    approve: {
      onApprove: (bookingId: string, note?: string) => dispatch(approveAction(bookingId, note))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: any) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    approve: { ...stateProps.approve, ...dispatchProps.approve }
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
