import React from "react";
import IIConProps from "../IIconProps";

const ShareIcon = (props: IIConProps) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`${props.minX || 0} ${props.minY || 0} ${props.width} ${props.height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.538 7c1.814 0 3.295 1.432 3.295 3.212 0 1.779-1.48 3.211-3.295 3.211a3.332 3.332 0 0 1-2.3-.912l-3.51 2.758a3.134 3.134 0 0 1 .077 1.957l3.464 1.733a3.333 3.333 0 0 1 2.269-.882c1.814 0 3.295 1.432 3.295 3.211 0 1.78-1.48 3.212-3.295 3.212s-3.296-1.432-3.296-3.212c0-.375.066-.735.188-1.07l-3.369-1.686a3.328 3.328 0 0 1-2.432 1.045c-1.815 0-3.296-1.432-3.296-3.212 0-1.779 1.481-3.211 3.296-3.211.87 0 1.664.33 2.255.87l3.534-2.776a3.135 3.135 0 0 1-.176-1.036c0-1.78 1.481-3.212 3.296-3.212zm0 12.577c-.997 0-1.796.772-1.796 1.711 0 .94.799 1.712 1.796 1.712s1.795-.772 1.795-1.712-.798-1.711-1.795-1.711zm-8.91-4.923c-.996 0-1.795.772-1.795 1.711 0 .94.799 1.712 1.796 1.712s1.795-.772 1.795-1.712-.798-1.711-1.795-1.711zm8.91-6.154c-.997 0-1.796.772-1.796 1.712s.799 1.711 1.796 1.711 1.795-.772 1.795-1.711c0-.94-.798-1.712-1.795-1.712z"
        fill={props.color || "currentColor"}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default ShareIcon;
