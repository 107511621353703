import { Dispatch } from "redux";
import { IApiServices } from "../../api";
import IError from "../../utilities/error";
import { addNotification, NotificationType } from "../notification/actions";
import { IAppState } from "../state";
import { ShootTypesActionType } from "./constants";
import { IShootType } from "./model";

export enum ShootTypeSection {
  Default,
  Custom
}

export interface IFetchShootTypesStarted {
  type: ShootTypesActionType.LOAD_SHOOT_TYPES_STARTED;
}

export interface IFetchShootTypesSuccess {
  type: ShootTypesActionType.LOAD_SHOOT_TYPES_SUCCESS;
  shootTypes: IShootType[][];
}

export interface IShootTypesFetchError {
  type: ShootTypesActionType.LOAD_SHOOT_TYPES_ERROR;
  error: IError;
}

export type ShootTypesFetchAction = IFetchShootTypesStarted | IFetchShootTypesSuccess | IShootTypesFetchError;

const fetchShootTypesStarted = (): IFetchShootTypesStarted => ({
  type: ShootTypesActionType.LOAD_SHOOT_TYPES_STARTED
});

const fetchShootTypesSuccess = (shootTypes: IShootType[][]): IFetchShootTypesSuccess => ({
  type: ShootTypesActionType.LOAD_SHOOT_TYPES_SUCCESS,
  shootTypes
});

const fetchShootTypesError = (error: IError) => ({
  type: ShootTypesActionType.LOAD_SHOOT_TYPES_ERROR,
  error
});

export const doFetchShootTypes = () => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiShootType: IApiServices) => {
    dispatch(fetchShootTypesStarted());

    try {
      const response = await apiShootType.shootTypes.fetchShootTypes();
      const result = [];
      result[ShootTypeSection.Custom] = response.customShootTypes;
      result[ShootTypeSection.Default] = response.defaultShootTypes;
      dispatch(fetchShootTypesSuccess(result));
    } catch (error) {
      dispatch(fetchShootTypesError(error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};
