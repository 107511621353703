import { formatErrorType } from "@zenfolio/core-components";
import { Dispatch } from "redux";
import { IApiServices } from "../../api";
import { createPaymentMethodError } from "../../api/errorTypes";
import { ILocationValidateRequest } from "../../api/widget";
import colors from "../../utilities/colors";
import IError from "../../utilities/error";
import * as logging from "../../utilities/logging";
import { addNotification, NotificationType } from "../notification/actions";
import { IAppState } from "../state";
import { WidgetActionType } from "./constants";
import {
  IAvailability,
  IBookingCreator,
  ICalculateServiceTaxQuery,
  IInvoiceInfo,
  IInvoicePaymentCreator,
  IPaymentCreator,
  ITaxInfo,
  IWidgetInfo
} from "./model";

interface ILoadWidgetStateStarted {
  type: WidgetActionType.LOAD_WIDGET_STATE_STARTED;
}

interface ILoadWidgetStateSuccess {
  type: WidgetActionType.LOAD_WIDGET_STATE_SUCCESS;
  info: IWidgetInfo;
}

interface ILoadWidgetStateError {
  type: WidgetActionType.LOAD_WIDGET_STATE_ERROR;
  error: IError;
}

interface IResetWidgetState {
  type: WidgetActionType.RESET_WIDGET_STATE;
}

interface IStripeFactoryReady {
  type: WidgetActionType.STRIPE_FACTORY_READY;
  stripeFactory: stripe.StripeStatic;
}

interface IStripeAccountIdReady {
  type: WidgetActionType.STRIPE_ACCOUNT_ID_READY;
  stripeAccountId: string;
}

export type LoadWidgetStateAction =
  | ILoadWidgetStateStarted
  | ILoadWidgetStateSuccess
  | ILoadWidgetStateError
  | IResetWidgetState
  | IStripeFactoryReady
  | IStripeAccountIdReady;

const loadWidgetStateStarted = (): ILoadWidgetStateStarted => ({
  type: WidgetActionType.LOAD_WIDGET_STATE_STARTED
});

const loadWidgetStateSuccess = (info: IWidgetInfo): ILoadWidgetStateSuccess => ({
  type: WidgetActionType.LOAD_WIDGET_STATE_SUCCESS,
  info
});

const loadWidgetStateError = (error: IError): ILoadWidgetStateError => ({
  type: WidgetActionType.LOAD_WIDGET_STATE_ERROR,
  error
});

export const stripeFactoryReady = (stripeFactory: stripe.StripeStatic): IStripeFactoryReady => ({
  type: WidgetActionType.STRIPE_FACTORY_READY,
  stripeFactory
});

const stripeAccountIdReady = (stripeAccountId: string): IStripeAccountIdReady => ({
  type: WidgetActionType.STRIPE_ACCOUNT_ID_READY,
  stripeAccountId
});

export const doLoadWidgetState = () => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadWidgetStateStarted());

    try {
      const response = await apiService.widget.loadWidgetInfo();
      dispatch(loadWidgetStateSuccess(response));
      dispatch(stripeAccountIdReady(response.stripeAccountId));
    } catch (error) {
      dispatch(loadWidgetStateError(error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

export const doResetWidgetState = (): IResetWidgetState => {
  return {
    type: WidgetActionType.RESET_WIDGET_STATE
  };
};

interface IValidateLocationStarted {
  type: WidgetActionType.VALIDATE_LOCATION_STARTED;
}

interface IValidateLocationSuccess {
  type: WidgetActionType.VALIDATE_LOCATION_SUCCESS;
  isValid: boolean;
}

interface IValidateLocationError {
  type: WidgetActionType.VALIDATE_LOCATION_ERROR;
  error: IError;
}

export type ValidateLocationAction = IValidateLocationStarted | IValidateLocationSuccess | IValidateLocationError;

const validateLocationStarted = (): IValidateLocationStarted => ({
  type: WidgetActionType.VALIDATE_LOCATION_STARTED
});

const validateLocationSuccess = (isValid: boolean): IValidateLocationSuccess => ({
  type: WidgetActionType.VALIDATE_LOCATION_SUCCESS,
  isValid
});

const validateLocationError = (error: IError): IValidateLocationError => ({
  type: WidgetActionType.VALIDATE_LOCATION_ERROR,
  error
});

export const doValidateLocation = (request: ILocationValidateRequest) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(validateLocationStarted());

    try {
      const response = await apiService.widget.validateLocation(request);
      dispatch(validateLocationSuccess(response.isValid));
    } catch (error) {
      dispatch(validateLocationError(error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface ILoadAvailabilityStarted {
  type: WidgetActionType.LOAD_AVAILABILITY_STARTED;
  serviceId: string;
}

interface ILoadAvailabilitySuccess {
  type: WidgetActionType.LOAD_AVAILABILITY_SUCCESS;
  serviceId: string;
  availability: IAvailability;
}

interface ILoadAvailabilityError {
  type: WidgetActionType.LOAD_AVAILABILITY_ERROR;
  serviceId: string;
  error: IError;
}

export type LoadAvailabilityAction = ILoadAvailabilityStarted | ILoadAvailabilitySuccess | ILoadAvailabilityError;

const loadAvailabilityStarted = (serviceId: string): ILoadAvailabilityStarted => ({
  type: WidgetActionType.LOAD_AVAILABILITY_STARTED,
  serviceId
});

const loadAvailabilitySuccess = (serviceId: string, availability: IAvailability): ILoadAvailabilitySuccess => ({
  type: WidgetActionType.LOAD_AVAILABILITY_SUCCESS,
  serviceId,
  availability
});

const loadAvailabilityError = (serviceId: string, error: IError): ILoadAvailabilityError => ({
  type: WidgetActionType.LOAD_AVAILABILITY_ERROR,
  serviceId,
  error
});

export const loadAvailability = (serviceId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadAvailabilityStarted(serviceId));
    try {
      const availability = await apiService.widget.loadAvailability(serviceId);
      dispatch(loadAvailabilitySuccess(serviceId, availability));
    } catch (error) {
      dispatch(loadAvailabilityError(serviceId, error));
    }
  };
};

interface ICreateBookingStarted {
  type: WidgetActionType.CREATE_BOOKING_STARTED;
}

interface ICreateBookingSuccess {
  type: WidgetActionType.CREATE_BOOKING_SUCCESS;
  bookingId: string;
  transactionId?: string;
  initialTaxAmount?: number;
  totalTaxAmount?: number;
}

interface ICreateBookingError {
  type: WidgetActionType.CREATE_BOOKING_ERROR;
  error: IError;
}

interface IResetBookingError {
  type: WidgetActionType.RESET_BOOKING_ERROR;
  failureCode: string;
}

export type CreateBookingAction =
  | ICreateBookingStarted
  | ICreateBookingSuccess
  | ICreateBookingError
  | IResetBookingError;

const createBookingStarted = (): ICreateBookingStarted => ({
  type: WidgetActionType.CREATE_BOOKING_STARTED
});

const createBookingSuccess = (
  bookingId: string,
  initialTaxAmount?: number,
  totalTaxAmount?: number,
  transactionId?: string
): ICreateBookingSuccess => ({
  type: WidgetActionType.CREATE_BOOKING_SUCCESS,
  bookingId,
  initialTaxAmount,
  totalTaxAmount,
  transactionId
});

const createBookingError = (error: IError): ICreateBookingError => ({
  type: WidgetActionType.CREATE_BOOKING_ERROR,
  error
});

const createPaymentMethod = async (paymentCreator: IPaymentCreator) => {
  try {
    const response = await paymentCreator.stripe.createPaymentMethod("card", paymentCreator.paymentMethodOptions);
    if (response && !response.error && response.paymentMethod && response.paymentMethod.id) {
      return response.paymentMethod.id;
    }
    // tslint:disable-next-line:no-empty
  } catch (error) {}

  // eslint-disable-next-line
  throw { type: formatErrorType(createPaymentMethodError), message: "Something went wrong, please try again later." };
};

export const doCreateBooking = (paymentCreator: IPaymentCreator, bookingCreator: IBookingCreator) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(createBookingStarted());

    try {
      const paymentMethodId = await createPaymentMethod(paymentCreator);
      bookingCreator.customer.paymentIdentifier = paymentMethodId;
      const { bookingId, bookingTransactionId: transactionId } = await apiService.widget.createBooking(bookingCreator);
      dispatch(
        createBookingSuccess(
          bookingId,
          bookingCreator.expectedInitialTax,
          bookingCreator.expectedTotalTax,
          transactionId
        )
      );
    } catch (error) {
      dispatch(createBookingError(error));
    }
  };
};

export const doCreateFreeServiceBooking = (bookingCreator: IBookingCreator) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(createBookingStarted());

    try {
      const bookingId = (await apiService.widget.createBooking(bookingCreator)).bookingId;
      dispatch(createBookingSuccess(bookingId, bookingCreator.expectedInitialTax, bookingCreator.expectedTotalTax));
    } catch (error) {
      dispatch(createBookingError(error));
    }
  };
};

export const doResetBookingError = (failureCode: string): IResetBookingError => {
  return {
    type: WidgetActionType.RESET_BOOKING_ERROR,
    failureCode
  };
};

interface ILoadInvoiceStateStarted {
  type: WidgetActionType.LOAD_INVOICE_STATE_STARTED;
}

interface ILoadInvoiceStateSuccess {
  type: WidgetActionType.LOAD_INVOICE_STATE_SUCCESS;
  info: IInvoiceInfo;
}

interface ILoadInvoiceStateError {
  type: WidgetActionType.LOAD_INVOICE_STATE_ERROR;
  error: IError;
}

export type LoadInvoiceStateAction = ILoadInvoiceStateStarted | ILoadInvoiceStateSuccess | ILoadInvoiceStateError;

const loadInvoiceStateStarted = (): ILoadInvoiceStateStarted => ({
  type: WidgetActionType.LOAD_INVOICE_STATE_STARTED
});

const loadInvoiceStateSuccess = (info: IInvoiceInfo): ILoadInvoiceStateSuccess => ({
  type: WidgetActionType.LOAD_INVOICE_STATE_SUCCESS,
  info
});

const loadInvoiceStateError = (error: IError): ILoadInvoiceStateError => ({
  type: WidgetActionType.LOAD_INVOICE_STATE_ERROR,
  error
});

export const doLoadInvoiceState = (invoiceId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadInvoiceStateStarted());

    try {
      const response = await apiService.widget.loadInvoiceInfo(invoiceId);
      dispatch(loadInvoiceStateSuccess(response));
      dispatch(stripeAccountIdReady(response.photographerInfo.stripeAccountId));
    } catch (error) {
      dispatch(loadInvoiceStateError(error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface IPayInvoiceStarted {
  type: WidgetActionType.PAY_INVOICE_STARTED;
}

interface IPayInvoiceSuccess {
  type: WidgetActionType.PAY_INVOICE_SUCCESS;
  transactionId: string;
}

interface IPayInvoiceError {
  type: WidgetActionType.PAY_INVOICE_ERROR;
  error: IError;
}

export type PayInvoiceAction = IPayInvoiceStarted | IPayInvoiceSuccess | IPayInvoiceError | IResetBookingError;

const payInvoiceStarted = (): IPayInvoiceStarted => ({
  type: WidgetActionType.PAY_INVOICE_STARTED
});

const payInvoiceSuccess = (transactionId: string): IPayInvoiceSuccess => ({
  type: WidgetActionType.PAY_INVOICE_SUCCESS,
  transactionId
});

const payInvoiceError = (error: IError): IPayInvoiceError => ({
  type: WidgetActionType.PAY_INVOICE_ERROR,
  error
});

export const doPayInvoice = (paymentCreator: IPaymentCreator, invoicePaymentCreator: IInvoicePaymentCreator) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(payInvoiceStarted());

    try {
      const paymentMethodId = await createPaymentMethod(paymentCreator);
      invoicePaymentCreator.paymentIdentifier = paymentMethodId;
      const transactionId = (await apiService.widget.payInvoice(invoicePaymentCreator)).transactionId;
      dispatch(payInvoiceSuccess(transactionId));
    } catch (error) {
      dispatch(payInvoiceError(error));
    }
  };
};

interface ICalculateServiceTaxStarted {
  type: WidgetActionType.CALCULATE_SERVICE_TAX_STARTED;
  query: ICalculateServiceTaxQuery;
}

interface ICalculateServiceTaxSuccess {
  type: WidgetActionType.CALCULATE_SERVICE_TAX_SUCCESS;
  query: ICalculateServiceTaxQuery;
  initialTax: ITaxInfo;
  totalTax: ITaxInfo;
}

interface ICalculateServiceTaxError {
  type: WidgetActionType.CALCULATE_SERVICE_TAX_ERROR;
  query: ICalculateServiceTaxQuery;
  error: IError;
}

export type CalculateServiceTaxAction =
  | ICalculateServiceTaxStarted
  | ICalculateServiceTaxSuccess
  | ICalculateServiceTaxError;

const calculateServiceTaxStarted = (query: ICalculateServiceTaxQuery): ICalculateServiceTaxStarted => ({
  type: WidgetActionType.CALCULATE_SERVICE_TAX_STARTED,
  query
});

const calculateServiceTaxSuccess = (
  query: ICalculateServiceTaxQuery,
  initialTax: ITaxInfo,
  totalTax: ITaxInfo
): ICalculateServiceTaxSuccess => ({
  type: WidgetActionType.CALCULATE_SERVICE_TAX_SUCCESS,
  query,
  initialTax,
  totalTax
});

const calculateServiceTaxError = (query: ICalculateServiceTaxQuery, error: IError): ICalculateServiceTaxError => ({
  type: WidgetActionType.CALCULATE_SERVICE_TAX_ERROR,
  query,
  error
});
export const doCalculateServiceTax = (query: ICalculateServiceTaxQuery) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    logging.log("%cCalculating taxes...", logging.getLogStyle(colors.filterOrangeText));

    dispatch(calculateServiceTaxStarted(query));

    try {
      const response = await apiService.widget.calculateServiceTax(query.serviceId, { ...query });

      logging.log(
        "%cInitial tax: %o Total tax: %o",
        logging.getLogStyle(colors.filterOrangeText),
        response.initial,
        response.total
      );

      dispatch(calculateServiceTaxSuccess(query, response.initial, response.total));
    } catch (error) {
      logging.logError(
        "%cFailed to calculate taxes with an error: %o",
        logging.getLogStyle(colors.redNotification),
        error
      );

      dispatch(calculateServiceTaxError(query, error));
    }
  };
};
