import { apiClient } from "..";
import { IShootLocation } from "../../store/profile/shootLocation/model";
import { apiType } from "../apiType";
import { ILoadProfileStateResponse } from "./state";

export interface IApiShootLocation {
  load: () => Promise<IShootLocation>;
  update: (shootLocation: IShootLocation) => Promise<ILoadProfileStateResponse>;
}

const api: IApiShootLocation = {
  load: () => apiClient.get<IShootLocation>(`${apiType.bookingWidget}photographer/profile/shoot-location`),

  update: (shootLocation: IShootLocation) =>
    apiClient.put<ILoadProfileStateResponse>(
      `${apiType.bookingWidget}photographer/profile/shoot-location`,
      shootLocation
    )
};

export default api;
