import React from "react";

function CropIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="#000" strokeWidth="1.467">
        <path d="M2 5.867h4m12.133 12.266v4m-8.8-16.266h8.8v8.8"/>
        <path d="M22 18H6V2"/>
      </g>
    </svg>
  );
}

export default CropIcon;
