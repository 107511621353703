import React, { Fragment } from "react";
import classNames from "classnames";

import styles from "./caterogy.module.scss";

interface IPrint {
    backgroundHeight: number,
    backgroundWidth: number,
    backgroundPhotoId: string,
    photo: {
        width: number,
        height: number,
        top: number,
        left: number,
    }
    url: string
}

interface IPrintProps {
    print: IPrint;
    isIgnoreSvg?: boolean;
}

interface IPrintStates {
    top: number;
    left: number;
    width: number;
    height: number;
}

export class Caterogy extends React.PureComponent<IPrintProps, IPrintStates> {

    public state: IPrintStates = {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
    }

    public render() {
        const { print, isIgnoreSvg } = this.props;
        const { photo } = print;

        return <Fragment>
            {print && <svg className={classNames(styles.cover, styles.container)} viewBox={`0 0 ${print.backgroundWidth} ${print.backgroundHeight}`}>
                <image
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    className={classNames(styles.images)}
                    xlinkHref={print.backgroundPhotoId} />
                {!isIgnoreSvg && (<svg viewBox={`${0} ${0} ${photo.width} ${photo.height}`}
                    x={photo.left}
                    y={photo.top}
                    width={photo.width}
                    height={photo.height} >
                    <image
                        width="100%"
                        height="100%"
                        preserveAspectRatio="xMidYMid slice"
                        className={classNames(styles.imagePlacholder)}
                        xlinkHref={print.url} />
                </svg>)}
            </svg>}
        </Fragment>
    }
}
