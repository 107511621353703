import React from "react";

const IconLink = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7299 7.20806L12.6825 5.2555C14.3565 3.5815 17.0705 3.5815 18.7445 5.2555C20.4185 6.92953 20.4185 9.64357 18.7445 11.3176L15.8747 14.1873C15.5819 14.4801 15.2572 14.7215 14.9119 14.9119C13.9658 15.4335 12.8645 15.5707 11.8367 15.3233C11.0437 15.1325 10.3544 14.7291 9.81274 14.1873C9.60283 13.9774 9.43926 13.7711 9.28201 13.5326L10.779 12.0357C10.8873 12.2827 11.0618 12.5343 11.2638 12.7363C12.1301 13.6027 13.5499 13.6101 14.4237 12.7363L17.2935 9.86653C18.1647 8.99529 18.1647 7.57773 17.2935 6.7065C16.4223 5.83527 15.0047 5.83527 14.1335 6.7065L13.2489 7.59117C12.4446 7.27206 11.5815 7.14425 10.7299 7.20806ZM9.08811 9.08815C8.74279 9.27852 8.41809 9.51996 8.12523 9.81278L5.25547 12.6825C3.58148 14.3565 3.58152 17.0705 5.25551 18.7445C6.9295 20.4185 9.64353 20.4185 11.3175 18.7445L13.2701 16.7919C12.4186 16.8558 11.5554 16.728 10.7511 16.4089L9.86648 17.2935C8.9953 18.1647 7.57773 18.1647 6.70655 17.2935C5.83532 16.4223 5.83528 15.0047 6.70647 14.1336L9.57631 11.2638C9.88641 10.9536 10.2659 10.7545 10.6652 10.6652C11.7259 10.428 12.7916 10.9848 13.2211 11.9645L14.718 10.4675C13.481 8.59152 11.0126 8.02725 9.08811 9.08815Z"
    />
  </svg>
);

export default IconLink;
