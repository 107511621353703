import React from "react";
import IIConProps from "../IIconProps";

export const AlbumLock = (props: IIConProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
      <g fill="none" fillRule="evenodd" transform="translate(.5 1)">
        <rect width="10.612" height="7.801" x="1.125" y="6.971" stroke="#FFF" strokeWidth="2.25" rx="1.436" />
        <rect width="1.5" height="3" x="5.675" y="9.25" fill="#FFF" rx=".75" />
        <path
          stroke="#FFF"
          strokeWidth="2.25"
          d="M9.836 6.564v-3.32c0-1.735-1.524-3.141-3.405-3.141-1.88 0-3.405 1.406-3.405 3.14v3.321"
        />
      </g>
    </svg>
  );
};

export default AlbumLock;
