import cx from "classnames";
import AlbumLock from "components/Icons/AlbumLock";
import Image from "../../Image";
import React from "react";
import ContextMenu from "utilities/contextMenu";
import CaretDown from "../../Icons/CaretDown";
import { ILink, IRoutes } from "../../../models/models";
import ZenButton from "components/blocks/zenButton";
import styles from "./zenHeroImage.module.scss";
import {
  HeroCaretType,
  HeroImageAlignment,
  HeroImageCaretType,
  HeroImageMaskPosition,
  HeroImageStyle,
  LinkTypeOption
} from "utilities/constant";
import { IZenSiteTheme } from "../../../models";
import { getSiteFonts } from "utilities/blocks/site";
import { executeHeroScroll, getInteractionUrlType, onLinkClick } from "utilities/blocks/blockInteractions";
import { ScreenSizeBreakPoints } from "utilities/screenResolution";
import colors from "utilities/colors";
import { PageContext } from "utilities/pageContext";
import { parseBody } from "utilities/blocks/string";

export interface IHeroImageBlockProps {
  customNavbarHeight?: number;
  readOnly?: boolean;
  desktopEnabled?: boolean;
  tabletEnabled?: boolean;
  mobileEnabled?: boolean;
  alignment: HeroImageAlignment;
  headerText?: string;
  subtitleText?: string;
  buttonText?: string;
  altText?: string;
  imageUrl: string;
  imageSrcSet?: string;
  imageId?: string;
  imageHeight: number;
  imageWidth: number;
  styleMode: HeroImageStyle;
  tint: boolean;
  caret?: HeroCaretType | HeroImageCaretType;
  maskPosition?: HeroImageMaskPosition;
  interactionType?: string;
  interactionPage?: string;
  interactionUrl?: string;
  interactionNewTab?: boolean;
  pages?: any;
  routes?: IRoutes;
  onUpdateDimensions?: () => void;
  parallaxEnabled?: boolean;
  isEditionView?: boolean;
  siteTheme: IZenSiteTheme;
  focalX?: number;
  focalY?: number;
  link?: ILink;
  isPublish?: boolean;
  getInteractionUrl?: getInteractionUrlType;
}

interface IHeroImageBlockState {
  navBarHeight: number;
  blockHeight: string;
}

const isServer = typeof window === "undefined";
export class ZenHeroImage extends React.Component<IHeroImageBlockProps, IHeroImageBlockState> {
  public static defaultProps = {
    maskType: "none",
    maskPosition: "center",
    dropShadow: false,
    fullWidth: false,
    size: 0
  };

  static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  private Container: React.RefObject<HTMLDivElement>;

  constructor(props: IHeroImageBlockProps) {
    super(props);
    this.Container = React.createRef();

    this.state = {
      navBarHeight: 0,
      blockHeight: `auto`
    };
  }

  public componentDidMount() {
    window.addEventListener("resize", this.changeOrientation);
    window.addEventListener("orientationchange", this.changeOrientation);

    this.updateControlHeight();
    this.determineVisibility();
  }

  public componentDidUpdate() {
    if (this.state.navBarHeight !== this.context.navBarHeight) {
      this.setState({ navBarHeight: this.context.navBarHeight }, () => this.updateControlHeight());
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.changeOrientation);
    window.removeEventListener("orientationchange", this.changeOrientation);
  }

  private determineVisibility = () => {
    const { desktopEnabled, tabletEnabled, mobileEnabled } = this.props;

    if (window.innerWidth <= ScreenSizeBreakPoints.Mobile && mobileEnabled) {
      this.context.setHasHero();
    } else if (
      window.innerWidth > ScreenSizeBreakPoints.Mobile &&
      window.innerWidth <= ScreenSizeBreakPoints.Tablet &&
      tabletEnabled
    ) {
      this.context.setHasHero();
    } else if (desktopEnabled) {
      this.context.setHasHero();
    }
  };

  private changeOrientation = () => {
    this.updateControlHeight();
  };

  private updateControlHeight = () => {
    const controlHeightEditor = "100vh";

    let controlHeightPublish = "";
    if (!isServer) {
      controlHeightPublish = `${window.innerHeight - this.context.navBarHeight}px`;
    } else {
      controlHeightPublish = controlHeightEditor;
    }

    this.setState({ blockHeight: this.props.isEditionView ? controlHeightEditor : controlHeightPublish });
  };

  private cancelDrag = (event: any) => {
    event.preventDefault();
    return false;
  };

  private handleButtonClick = () => {
    const {
      readOnly,
      pages,
      link,
      routes,
      interactionType,
      interactionPage,
      interactionUrl,
      interactionNewTab
    } = this.props;

    if (readOnly && pages) {
      onLinkClick(
        pages,
        interactionNewTab,
        interactionUrl,
        interactionPage,
        interactionType,
        routes ? routes : {},
        link,
        this.props.isPublish,
        this.props.getInteractionUrl
      );
    }
  };

  private handleCaretClick = (e: any) => {
    if (this.Container.current) {
      executeHeroScroll(this.Container.current.clientHeight);
    }
  };

  private getCaretColor = (caret: HeroCaretType | HeroImageCaretType) => {
    let result = this.props.siteTheme.accentColor.value;

    switch (caret) {
      case HeroCaretType.BLACK_CIRCLE:
        result = colors.black;
        break;
      case HeroCaretType.WHITE_CIRCLE:
        result = colors.white;
        break;
    }

    return result;
  };

  public render() {
    const {
      alignment,
      styleMode,
      tint,
      caret,
      readOnly,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      siteTheme,
      imageWidth,
      imageHeight,
      isEditionView,
      focalX,
      focalY,
      parallaxEnabled,
      imageId,
      link
    } = this.props;

    const headerText = parseBody(this.props.headerText || "");
    const subtitleText = parseBody(this.props.subtitleText || "");
    const buttonText = parseBody(this.props.buttonText || "");
    const imageUrl = parseBody(this.props.imageUrl || "");
    const imageSrcSet = parseBody(this.props.imageSrcSet || "");
    const newFocalX = focalX ?? 50;
    const newFocalY = focalY ?? 50;

    let alignmentClass =
      alignment === HeroImageAlignment.LEFT
        ? styles.textAlignLeft
        : alignment === HeroImageAlignment.CENTER
        ? styles.textAlignCenter
        : styles.textAlignRight;

    if (styleMode === HeroImageStyle.FIXED) {
      alignmentClass = cx(alignmentClass, styles.textFixed);
    }

    let containerClass = cx(styles.zenContainer);
    if (readOnly && !desktopEnabled) {
      containerClass = cx(containerClass, styles.hideDesktop);
    }
    if (readOnly && !tabletEnabled) {
      containerClass = cx(containerClass, styles.hideTablet);
    }
    if (readOnly && !mobileEnabled) {
      containerClass = cx(containerClass, styles.hideMobile);
    }

    const isGhost = !this.context.noGhost && !imageId;
    const buttonLabel = link?.label ? link?.label : buttonText;
    const renderButton = link ? link.button : buttonText !== "";
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    const isParalax = parallaxEnabled && !this.props.isEditionView;

    return (
      <div ref={this.Container} onDragStart={this.cancelDrag} className={containerClass}>
        <style>
          {`
            :root {
              --accent-color: ${siteTheme.accentColor.value};
              --coverSize: ${this.state.blockHeight};
              }
            `}
        </style>
        <div
          className={cx(styles.zenContent, styleMode === HeroImageStyle.FIXED ? styles.fixedImage : styles.imageCover, {
            [styles.protectedImage]:
              (link?.albumFolderData?.album.isGalleryProtected ||
                (link?.albumFolderData?.album.isVisitorAllowed === false && !link?.albumFolderData?.folder.id)) &&
              link?.type === LinkTypeOption.GALLERY,
            [styles.withParalax]: isParalax,
            [styles.withTint]: tint
          })}
          onContextMenu={ContextMenu.handleBlockContextMenu}
        >
          <Image
            src={imageUrl}
            srcset={imageSrcSet}
            alt={headerText}
            width={imageWidth}
            height={imageHeight}
            fitParent={true}
            className={styles.imgTag}
            styles={{
              objectPosition: newFocalX + "% " + newFocalY + "%"
            }}
            isGhost={isGhost}
          />
        </div>
        <div
          className={cx(
            styles.zenContent,
            styles.textContent,
            styleMode === HeroImageStyle.FIXED ? styles.fixedImage : styles.imageCover
          )}
          onContextMenu={ContextMenu.handleBlockContextMenu}
        >
          {(link?.albumFolderData?.album.isGalleryProtected ||
            (link?.albumFolderData?.album.isVisitorAllowed === false && !link?.albumFolderData?.folder.id)) &&
            link?.type === LinkTypeOption.GALLERY && (
              <div className={styles.albumLock}>
                <AlbumLock color={colors.darkGrey} size={24} />
              </div>
            )}
          <div className={cx(styles.textContainer, alignmentClass, styles[siteFonts.primary])}>
            <div
              className={cx(styles.title, styles[siteFonts.primaryExtra], { [styles.titleClickable]: isEditionView })}
            >
              {headerText}
            </div>
            <div
              className={cx(styles.subtitle, styles[siteFonts.secondary], {
                [styles.subtitleClickable]: isEditionView
              })}
            >
              {subtitleText}
            </div>
            {renderButton && (
              <div className={cx({ [styles.buttonClickable]: isEditionView })}>
                <ZenButton
                  onClick={this.handleButtonClick}
                  siteTheme={siteTheme}
                  accentColor={siteTheme.accentColor}
                  layout={"A"}
                  labelText={buttonLabel ? buttonLabel : "View Portfolio"}
                />
              </div>
            )}
          </div>
          {caret && styleMode !== HeroImageStyle.FIXED && (
            <div className={styles.caretContainer}>
              <span className={styles.circle} onClick={this.handleCaretClick}>
                <CaretDown color={this.getCaretColor(caret)} size={40} />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
