import React from "react";
import HoursMinutesInput, { IHoursMinutesInputProps } from "../HoursMinutesInput";

export interface ITimeInputProps extends Omit<IHoursMinutesInputProps, "mode"> {}

class TimeInput extends React.Component<ITimeInputProps> {
  public render() {
    return <HoursMinutesInput {...this.props} mode="time" />;
  }
}

export default TimeInput;
