import { Editor, Operation } from "slate";
import { RichText } from "utilities/richText";

export const withTextLimit = (limit: number) => <T extends Editor>(editor: T) => {
  const { apply } = editor;

  if (limit != null) {
    editor.apply = operation => {
      apply(operation);

      if (RichText.getLength(editor.children) > limit) {
        apply(Operation.inverse(operation));
      }
    };
  }

  return editor;
};
