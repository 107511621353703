import classNames from "classnames";
import React from "react";
import { Utilities } from "../../utilities";
import TextInput from "../TextInput";
import styles from "./formTextInput.module.scss";
import { FormTextInputBase, IFormTextInputPropsBase, IFormTextInputStateBase } from "./FormTextInputBase";

interface IFormTextInputProps extends IFormTextInputPropsBase<HTMLInputElement> {
  type?: string;
  icon?: string;
  height?: number;
  iconHeight?: number;
  iconPaddingTop?: number;
  textPaddingLeft?: number;
  textPaddingTop?: number;
  textPaddingBottom?: number;
  styles?: any;
  focus?: () => void;
}

// tslint:disable-next-line:no-empty-interface
interface IFormTextInputState extends IFormTextInputStateBase {}

export class FormTextInput extends FormTextInputBase<HTMLInputElement, IFormTextInputProps, IFormTextInputState> {
  public constructor(props: IFormTextInputProps) {
    super(props, { displayError: false });
  }

  public render() {
    const props = this.props;
    const errorMessage = this.errorMessage;
    const className = classNames(styles.container, props.className, {
      [classNames(styles.error, props.withErrorClassName)]: errorMessage
    });

    return (
      <TextInput
        id={props.id}
        disabled={props.disabled}
        onChange={this.onChange}
        placeholder={props.placeholder}
        type={props.type || "text"}
        value={this.value}
        icon={props.icon}
        height={props.height}
        iconHeight={props.iconHeight}
        iconPaddingTop={props.iconPaddingTop}
        textPaddingLeft={props.textPaddingLeft}
        textPaddingTop={props.textPaddingTop}
        textPaddingBottom={props.textPaddingBottom}
        styles={props.styles}
        readonly={props.readonly}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        ref={Utilities.combineRefsCached(this.inputRef)(props.inputRef)}
        errorMessage={errorMessage}
        className={className}
        errorClassName={props.errorClassName}
        tabIndex={props.tabIndex}
        maxLength={props.maxLength}
      />
    );
  }
}
