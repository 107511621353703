import memoize from "lodash/memoize";
import React from "react";
import { Utilities } from "../../utilities/utilities";

export interface IFormTextInputPropsBase<TElement> {
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  readonly?: boolean;
  onBlur?: (event: React.FocusEvent<TElement>) => void;
  onFocus?: (event: React.FocusEvent<TElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<TElement>) => void;
  errorMessage?: string | null | undefined;
  className?: string;
  withErrorClassName?: string;
  errorClassName?: string;
  tabIndex?: number;
  maxLength?: number;
  onChange?: (value: string) => void;
  defaultValue?: string | null;
  forceShowingError?: boolean;
  allowedCharsRegex?: string;
  autoTrim?: boolean;
  inputRef?: React.Ref<TElement>;
}

export interface IFormTextInputStateBase {
  value?: string;
  displayError: boolean;
}

export abstract class FormTextInputBase<
  TElement extends HTMLInputElement | HTMLTextAreaElement,
  TProps extends IFormTextInputPropsBase<TElement>,
  TState extends IFormTextInputStateBase
> extends React.Component<TProps, TState> {
  public state: TState;

  protected inputRef = React.createRef<TElement>();
  private cursorPosition: number | null = null;

  protected constructor(props: TProps, defaultState: TState) {
    super(props);

    this.state = defaultState;
  }

  public componentDidUpdate() {
    if (this.cursorPosition != null) {
      if (this.inputRef.current != null) {
        this.inputRef.current.setSelectionRange(this.cursorPosition, this.cursorPosition);
      }

      this.cursorPosition = null;
    }
  }

  public componentWillUnmount() {
    Utilities.combineRefsCached.cache.delete(this.inputRef);
  }

  public focus = (caretPosition?: "start" | "end" | boolean, selectAll?: boolean) => {
    if (this.inputRef.current != null) {
      this.inputRef.current.focus();
      if (caretPosition === "start") {
        Utilities.placeCaretAtStart(this.inputRef.current);
      } else if (caretPosition) {
        Utilities.placeCaretAtEnd(this.inputRef.current);
      } else if (selectAll) {
        Utilities.selectAll(this.inputRef.current);
      }
    }
  };

  protected get value() {
    return (
      ((this.props.value !== undefined
        ? this.props.value
        : this.state.value !== undefined
        ? this.state.value
        : this.props.defaultValue) as string) || ""
    );
  }

  protected get errorMessage() {
    if (!this.props.forceShowingError && !this.state.displayError) {
      return null;
    }

    return this.props.errorMessage;
  }

  protected onChange = (event: React.ChangeEvent<TElement>) => {
    this.changeValue(() => this.processNewValue(event), true);
  };

  protected onBlur = (event: React.FocusEvent<TElement>) => {
    if (this.props.autoTrim !== false) {
      this.changeValue(previousValue => Utilities.trim(previousValue), false);
    }

    this.setState({ displayError: true }, () => {
      if (this.props.onBlur) {
        this.props.onBlur(event);
      }
    });
  };

  protected onFocus = (event: React.FocusEvent<TElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  protected onKeyDown = (event: React.KeyboardEvent<TElement>) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  };

  private changeValue(getNewValue: (previousValue: string) => string, forceUpdate: boolean) {
    const previousValue = this.value;
    const newValue = getNewValue(previousValue);

    const onChange = (value: string) => {
      if (previousValue === value) {
        return false;
      }

      if (this.props.onChange) {
        this.props.onChange(value);
      }

      return true;
    };

    if (this.props.value === undefined) {
      this.setState({ value: newValue }, () => {
        onChange(this.value);
      });
    } else if (!onChange(newValue) && forceUpdate) {
      this.forceUpdate();
    }
  }

  private processNewValue(event: React.ChangeEvent<TElement>) {
    const { allowedCharsRegex } = this.props;
    const userValue = event.target.value;
    const userCursorPosition = event.target.selectionStart || 0;
    let newValue = userValue;

    if (allowedCharsRegex) {
      const regex = FormTextInputBase.regex(allowedCharsRegex);
      let match: RegExpExecArray | null = null;
      newValue = "";
      // tslint:disable-next-line: no-conditional-assignment
      while ((match = regex.exec(userValue)) !== null) {
        newValue += match[0];
      }
    }

    this.cursorPosition = Math.max(userCursorPosition - (userValue.length - newValue.length), 0);

    return newValue;
  }

  private static regex = memoize(r => new RegExp(r, "g"));
}
