import escapeHtml from "escape-html";
import { Node, Text } from "slate";
import { Validation } from "utilities/validation";
import {
  BLOCK_ALIGN_CENTER,
  BLOCK_ALIGN_LEFT,
  BLOCK_ALIGN_RIGHT,
  BLOCK_LI,
  BLOCK_LINK,
  BLOCK_OL,
  BLOCK_PARAGRAPH,
  BLOCK_UL,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE
} from "../core/types";

export const defaultValue: Node[] = [{ type: BLOCK_PARAGRAPH, children: [{ text: "" }] }];

export interface ISerializationOptions {
  linkColor?: string;
}

const serialize = (node: Node, options: ISerializationOptions): string => {
  if (Text.isText(node)) {
    let result = escapeHtml(node.text);
    if (node[MARK_BOLD]) {
      result = `<strong>${result}</strong>`;
    }
    if (node[MARK_ITALIC]) {
      result = `<em>${result}</em>`;
    }
    if (node[MARK_UNDERLINE]) {
      result = `<u>${result}</u>`;
    }
    return result;
  }
  const children = node.children.map(n => serialize(n, options)).join("");
  switch (node.type) {
    case BLOCK_PARAGRAPH:
      return Validation.isNotEmptyString(children) ? `<p>${children}</p>` : "<br />";
    case BLOCK_ALIGN_LEFT:
      return `<div style="text-align: left">${children}</div>`;
    case BLOCK_ALIGN_CENTER:
      return `<div style="text-align: center">${children}</div>`;
    case BLOCK_ALIGN_RIGHT:
      return `<div style="text-align: right">${children}</div>`;
    case BLOCK_UL:
      return `<ul>${children}</ul>`;
    case BLOCK_OL:
      return `<ol>${children}</ol>`;
    case BLOCK_LI:
      return `<li>${children}</li>`;
    case BLOCK_LINK:
      return `<a target="_blank"${options.linkColor ? ` style="color: ${options.linkColor}"` : ""} href="${escapeHtml(
        node.url as string
      )}" rel="noopener noreferrer">${children}</a>`;
    default:
      return children;
  }
};

export const serializeHtml = (nodes: Node[], options?: ISerializationOptions) => {
  const editor = {
    children: nodes
  };
  return serialize(editor, options || {});
};
