enum queryString {
  phonePortrait = "only screen and (max-width: 767.98px) and (orientation: portrait)",
  phoneLandscape = "only screen and (max-width: 1023px) and (max-height: 500px) and (orientation: landscape)",
  tabletPortrait = "only screen and (max-width: 1024px) and (orientation: portrait)",
  tabletLandscape = "only screen and (max-width: 1366px) and (orientation: landscape)",
  isTouch = "only screen and (pointer: coarse)"
}

const isClientSide = typeof window !== "undefined";
const UA = isClientSide ? window.navigator.userAgent : "";

const matchesQuery = (query: queryString) => {
  return !!window.matchMedia ? window.matchMedia(query).matches : false;
};

export const matchMediaPhone = ((): boolean => {
  if (isClientSide) {
    const UAPhone = /Android|iPhone|BlackBerry|Opera Mini/i.test(UA);
    const MQPhone = matchesQuery(queryString.phonePortrait) || matchesQuery(queryString.phoneLandscape);
    return MQPhone && UAPhone && matchesQuery(queryString.isTouch);
  }
  return false;
})();

export const matchMediaPhoneOrTablet = ((): boolean => {
  if (isClientSide) {
    const UAPoT = /Android|iPhone|iPad|BlackBerry|AppleWebKit|Safari|Opera Mini/i.test(UA);
    const MQPoT = matchesQuery(queryString.tabletPortrait) || matchesQuery(queryString.tabletLandscape);
    return MQPoT && UAPoT && matchesQuery(queryString.isTouch);
  }
  return false;
})();

export const isIOS = ((): boolean => {
  if (isClientSide) {
    return /iPhone|iPad|AppleWebKit|Safari/i.test(UA) && !/Android/i.test(UA) && matchesQuery(queryString.isTouch);
  }
  return false;
})();

export const isOldEdge = ((): boolean => {
  if (isClientSide) {
    return UA.indexOf("Edge/") !== -1;
  }
  return false;
})();

// MacBook:    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Safari/605.1.15"

// iPadPro 12: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.2 Safari/605.1.15"
// Portrait
// 1024 x 1292
// Landscape
// 1366 x 950

// iPhone13 Pro Max: "Mozilla/5.0 (iPhone; CPU iPhone OS 15_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.2 Mobile/15E148 Safari/604.1"
// Portrait
// 428 x746
// Landscape
// 832 x 345
