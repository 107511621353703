import React from "react";
import UnauthorizedPage from "../../pages/401";
import { LoginState } from "../../store/session/model";
import Layout from "../layout";
import Loader from "../layout/loader";

interface ISessionProps {
  state: LoginState;
  onLogin: () => void;
}

const Session: React.FC<ISessionProps> = props => {
  const layout = (
    <Layout>
      <Loader />
    </Layout>
  );

  switch (props.state) {
    case LoginState.Init:
      props.onLogin();
      return layout;
    case LoginState.Pending:
      return layout;
    case LoginState.Success:
      return null;
    case LoginState.Error:
      return (
        <Layout>
          <UnauthorizedPage />
        </Layout>
      );
  }
};

export default Session;
