export const MinDuration: number = 5;
export const MaxDuration: number = 720;

export const MinDays: number = 1;
export const MaxDays: number = 30;

export const DurationChunk: number = 5;

export const NameMaxCharacters: number = 40;
export const DescriptionMaxCharacters: number = 50;
export const MaxDescriptions: number = 10;

export const MinPrice = 5;
export const MaxPrice = 10000;

export const MinDepositAmount = 1;

export const NoLeadingZeroRegex = "^[1-9][0-9]*$";
