import moment from "moment";
import React, { ReactNode } from "react";
import { IRescheduleConflicts, IRescheduleSettings } from "../../../../store/bookings/model";
import { IRescheduleBookingModalProps } from "../../../../store/modal/model";
import withModal, { InjectedModalProps } from "../../withModal";
import Conflicts from "./conflicts";
import Reschedule from "./reschedule";

type RescheduleModalCode = "reschedule" | "conflicts";

interface IRescheduleBookingProps extends InjectedModalProps<IRescheduleBookingModalProps> {}

interface IRescheduleBookingState {
  modal: RescheduleModalCode;
  settings?: IRescheduleSettings;
  conflicts?: IRescheduleConflicts;
}

class RescheduleBooking extends React.Component<IRescheduleBookingProps, IRescheduleBookingState> {
  public state: IRescheduleBookingState = { modal: "reschedule" };

  public render() {
    const { modal } = this.state;

    const modalRenderers: Record<RescheduleModalCode, () => ReactNode> = {
      reschedule: () => this.renderReschedule(),
      conflicts: () => this.renderConflict()
    };

    return modalRenderers[modal]();
  }

  private renderReschedule() {
    const { bookingId, startDateTime, duration, clientName } = this.props;
    const { settings } = this.state;
    return (
      <Reschedule
        onClose={this.onRescheduleClose}
        bookingId={bookingId}
        clientName={clientName}
        startDateTime={settings ? settings.startDateTime : moment(startDateTime).utc(true)}
        endDateTime={
          settings
            ? settings.endDateTime
            : moment(startDateTime)
                .utc(true)
                .add(duration, "minutes")
        }
        clientNote={settings ? settings.clientNote : undefined}
        internalNote={settings ? settings.internalNote : undefined}
        sendNotification={settings ? settings.sendNotification : undefined}
        onSuccess={this.onRescheduleSuccess}
        onConflicts={this.onRescheduleConflicts}
      />
    );
  }

  private renderConflict() {
    const { bookingId } = this.props;
    const { conflicts, settings } = this.state;
    return (
      <Conflicts
        bookingId={bookingId}
        settings={settings!}
        conflicts={conflicts!}
        onCancel={this.onConflictsCancel}
        onSuccess={this.onConflictsSuccess}
      />
    );
  }

  private onRescheduleClose = () => this.props.onHideModal();

  private onRescheduleSuccess = () => this.props.onHideModal();

  private onRescheduleConflicts = (settings: IRescheduleSettings, conflicts: IRescheduleConflicts) =>
    this.setState({ modal: "conflicts", settings, conflicts });

  private onConflictsCancel = () => this.setState({ modal: "reschedule", conflicts: undefined });

  private onConflictsSuccess = () => this.props.onHideModal();
}

export default withModal(RescheduleBooking);
