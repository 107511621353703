import { ConnectedRouter, ConnectedRouterProps } from "connected-react-router";
import { UnregisterCallback } from "history";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MainLayout from "../../containers/layout/main";
import PageLayout from "../../containers/layout/page";
import TestLayout from "../../containers/layout/test";
import WidgetLayout from "../../containers/layout/widget";
import UnauthorizedPage from "../../pages/401";
import { getDefaultRoute, getRouteBasePath } from "../../store/helpers";
import { LoginState } from "../../store/session/model";
import { AuthenticationManager } from "../../utilities/authenticationManager";
import eventTracker from "../../utilities/eventTracker";
import { isProduction } from "../../utilities/helpers";
import { NavigationManager } from "../../utilities/navigationManager";
import AccessGuard from "../layout/content/accessGuard";
import Session from "../session";
import UrlConstants from "./constants";
import { IRoute } from "./routes";

interface IRouterProps extends ConnectedRouterProps {
  routes: IRoute[];
  loginState: LoginState;
  onLogin: () => void;
  onRemoveNotification: (force: boolean) => void;
}

export enum AnalyticsParams {
  FlagshipPhotographerId = "flagshipPhotographerId",
  PhotographerPlan = "photographerPlan",
  AddOnPlan = "addOnPlan"
}

class Router extends React.Component<IRouterProps> {
  private unregisterHistory: UnregisterCallback | null = null;

  public componentDidMount() {
    const { history, onRemoveNotification } = this.props;
    this.unregisterHistory = history.listen(location => {
      const force = !location.pathname.startsWith(UrlConstants.services);
      onRemoveNotification(force);
    });

    if (NavigationManager.instance.widgetMode) {
      eventTracker.context.photographer.id = NavigationManager.instance.widgetPhotographerId || undefined;
    }

    const urlParams = new URLSearchParams(window.location.search);
    eventTracker.context.photographer.zenfolioId = urlParams.get(AnalyticsParams.FlagshipPhotographerId) || undefined;
    eventTracker.context.photographer.plan = urlParams.get(AnalyticsParams.PhotographerPlan) || undefined;
    eventTracker.context.photographer.addOnPlan = urlParams.get(AnalyticsParams.AddOnPlan) || undefined;
  }

  public componentWillUnmount() {
    if (this.unregisterHistory != null) {
      this.unregisterHistory();
      this.unregisterHistory = null;
    }
  }

  public componentDidUpdate(prevProps: IRouterProps) {
    const loginHasCompletedSuccessfully =
      prevProps.loginState !== LoginState.Success && this.props.loginState === LoginState.Success;

    if (loginHasCompletedSuccessfully) {
      eventTracker.context.photographer.id = AuthenticationManager.getPhotographerStorage().photographerId;
    }
  }

  public render() {
    const { history, loginState, onLogin } = this.props;

    return (
      <ConnectedRouter history={history}>
        <Route path="/unauthorized" render={this.renderUnauthorized} />
        {NavigationManager.instance.widgetMode ? (
          <AccessGuard haveAccess={!isProduction() || NavigationManager.isInIframe()} to={"/unauthorized"}>
            <Route render={this.renderWidgetLayout} />
          </AccessGuard>
        ) : NavigationManager.isInTestMode(history) ? (
          <AccessGuard haveAccess={!isProduction()} to={"/unauthorized"}>
            <Route render={this.renderTestLayout} />
          </AccessGuard>
        ) : loginState === LoginState.Success ? (
          <AccessGuard haveAccess={!isProduction() || NavigationManager.isInIframe()} to={"/unauthorized"}>
            <Switch>
              <Redirect from="/" exact={true} push={false} to={this.defaultRoute} />
              <Redirect from={UrlConstants.profile} exact={true} push={false} to={UrlConstants.profileShootLocation} />
              <Redirect from={UrlConstants.bookings} exact={true} push={false} to={UrlConstants.bookingsUpcoming} />
              <Redirect from={UrlConstants.customize} exact={true} push={false} to={UrlConstants.customizeDesign} />
              <Redirect from={UrlConstants.activation} exact={true} push={false} to={UrlConstants.activationPages} />
              <Route path="/page" render={this.renderPageLayout} />
              <Route render={this.renderMainLayout} />
            </Switch>
          </AccessGuard>
        ) : (
          <AccessGuard haveAccess={!isProduction() || NavigationManager.isInIframe()} to={"/unauthorized"}>
            <Session state={loginState} onLogin={onLogin} />
          </AccessGuard>
        )}
      </ConnectedRouter>
    );
  }

  private get defaultRoute() {
    return getRouteBasePath(getDefaultRoute(this.props.routes));
  }

  private renderMainLayout = () => <MainLayout {...this.props} />;

  private renderPageLayout = () => <PageLayout {...this.props} />;

  private renderWidgetLayout = () => <WidgetLayout {...this.props} />;

  private renderTestLayout = () => <TestLayout {...this.props} />;

  private renderUnauthorized = () => <UnauthorizedPage />;
}

export default Router;
