import { Editor, Range, Element, Text, Transforms, Path } from "slate";
import { BLOCK_LINK } from "../core/types";

// Based on: https://github.com/ianstormtaylor/slate/issues/3772#issuecomment-808224883
export const withInsertBreakHack = () => <T extends Editor>(editor: T) => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    if (editor.selection) {
      const [selectedElement, path] = Editor.parent(editor, editor.selection);

      if (Element.isElement(selectedElement) && (selectedElement as any).type === BLOCK_LINK) {
        const endPoint = Range.end(editor.selection);
        const [selectedLeaf] = Editor.node(editor, endPoint);
        if (Text.isText(selectedLeaf) && selectedLeaf.text.length === endPoint.offset) {
          if (Range.isExpanded(editor.selection)) {
            Transforms.delete(editor);
          }
          Transforms.select(editor, Path.next(path));
        }
      }
    }

    insertBreak();
  };

  return editor;
};
