import classNames from "classnames";
import styles from "./index.module.scss";

export const TIMEOUT = {
  COLLAPSE: 350
};

export const TRANSITION_KEYS = [
  "in",
  "mountOnEnter",
  "unmountOnExit",
  "appear",
  "enter",
  "exit",
  "timeout",
  "onEnter",
  "onEntering",
  "onEntered",
  "onExit",
  "onExiting",
  "onExited"
];

export const TRANSITION_STATUS = {
  ENTERING: "entering",
  ENTERED: "entered",
  EXITING: "exiting",
  EXITED: "exited"
};

export const TRANSITION_CLASS_MAP = {
  [TRANSITION_STATUS.ENTERING]: styles.collapsing,
  [TRANSITION_STATUS.ENTERED]: classNames(styles.collapse, styles.show),
  [TRANSITION_STATUS.EXITING]: styles.collapsing,
  [TRANSITION_STATUS.EXITED]: styles.collapse
};
