import React from "react";
import IIConProps, { initializeIconProps } from "../IIconProps";

interface ICaret32Props extends IIConProps {
  left: boolean;
}

const Caret32: React.FC<ICaret32Props> = rawProps => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox={`${props.minX} ${props.minY} ${props.size} ${props.size}`}
    >
      <g fill="none" fillRule="evenodd" opacity=".8">
        <g fill={props.color} stroke={props.color} strokeWidth="2">
          <g>
            <g>
              <path
                d="M16 1c4.142 0 7.892 1.679 10.607 4.393C29.32 8.108 31 11.858 31 16c0 4.142-1.679 7.892-4.393 10.607C23.892 29.32 20.142 31 16 31c-4.142 0-7.892-1.679-10.607-4.393C2.68 23.892 1 20.142 1 16c0-4.142 1.679-7.892 4.393-10.607C8.108 2.68 11.858 1 16 1zm-3 6.5l-.192.007c-.638.05-1.257.342-1.706.866-.435.507-.633 1.136-.583 1.777.045.591.304 1.202.822 1.72h0L16.16 16l-4.786 4.102-.145.135c-.418.418-.659.955-.703 1.532-.044.577.11 1.205.577 1.858.434.507 1.026.799 1.667.848.59.046 1.235-.117 1.826-.55h0l7.032-6.027.147-.137c.47-.47.71-1.083.71-1.72 0-.653-.255-1.342-.826-1.912h0l-7.031-6.027-.161-.127c-.494-.359-1.084-.512-1.658-.468z"
                transform={
                  rawProps.left
                    ? "translate(-535 -452) translate(387 203) matrix(-1 0 0 1 180 249)"
                    : "translate(-1647 -452) translate(387 203) translate(1260 249)"
                }
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Caret32;
