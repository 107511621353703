import cx from "classnames";
import AlbumLock from "../../../Icons/AlbumLock";
import Image from "../../../Image";
import { IColor, INavigationMenuItem, IZenSiteTheme, IRoutes } from "../../../../models/models";
import { LinkTypeOption } from "utilities/constant";
import { PageContext } from "utilities/pageContext";
import React, { useContext } from "react";
import { getBackGroundTile, getFontColor } from "utilities/blocks/blockColors";
import {
  getFinalInteractionUrl,
  getInteractionUrl as localGetInteractionUrl,
  getInteractionUrlType,
  INTERACTION_TYPE,
  onLinkClick
} from "utilities/blocks/blockInteractions";
import { getSiteFonts } from "utilities/blocks/site";
import { renderLineBreaks } from "utilities/blocks/string";
import colors from "utilities/colors";
import { IFeature } from "./../index";
import styles from "./featuredWork.module.scss";

export interface IFeaturedWorkComponent {
  value: IFeature;
  index: number;
  isLast: boolean;
  siteTheme: IZenSiteTheme;
  themeColor: IColor;
  isEdit: boolean;
  layout: string;
  pages?: INavigationMenuItem[];
  routes?: IRoutes;
  readOnly?: boolean;
  isPublish?: boolean;
  blockImageEvents?: boolean;
  getInteractionUrl?: getInteractionUrlType;
}

const FeaturedWork: React.FC<IFeaturedWorkComponent> = props => {
  const pageContext = useContext(PageContext);
  const getInteractionUrl = props.getInteractionUrl || localGetInteractionUrl;
  const cancelDrag = (event: any) => {
    event.preventDefault();
    return false;
  };
  const siteFonts = getSiteFonts(props.siteTheme.fontsStyle);

  const handleImageClick = (interactionLinkStr: string) => {
    const { readOnly, pages, value, routes } = props;

    if (value.link) {
      if (readOnly && pages) {
        onLinkClick(
          pages,
          value.interactionNewTab,
          value.interactionUrl,
          value.interactionPage,
          value.interactionType,
          routes ? routes : {},
          value.link,
          props.isPublish,
          getInteractionUrl,
          value
        );
      }
    } else {
      if (
        !pageContext.isPreview &&
        (props.value.interactionNewTab ||
          (props.value.galleryIsProtected && props.value.galleryHasVisitorsAllowed) ||
          (props.value.galleryIsVisitorEmailRequired && props.value.galleryHasVisitorsAllowed))
      ) {
        const win = window.open(interactionLinkStr, "_blank");
        if (win) {
          win.focus();
        }
      } else {
        window.location.href = interactionLinkStr;
      }
    }
  };

  const newFocalX = props.value.focalX ?? 50;
  const newFocalY = props.value.focalY ?? 50;

  // If id is null is default image
  const isGhost = !pageContext.noGhost && !props.value.imageId;

  const renderImage = (feature: IFeature, interactionLinkStr: string) => {
    const {
      link,
      galleryIsProtected,
      galleryHasVisitorsAllowed,
      folderId,
      interactionType,
      imageUrl,
      imageSrcSet,
      imageWidth,
      imageHeight,
      isLoading
    } = props.value;
    const isGalleryProtected = link ? link.albumFolderData?.album.isGalleryProtected : galleryIsProtected;
    const folderIdFeat = link ? link.albumFolderData?.folder.id : folderId;
    const visitorsAllowed = link ? link.albumFolderData?.album.isVisitorAllowed : galleryHasVisitorsAllowed;
    const galleryIsLinked = link
      ? link.type === LinkTypeOption.GALLERY
      : interactionType === INTERACTION_TYPE.ALBUMFOLDER;

    return (
      <Image
        src={imageUrl}
        srcset={imageSrcSet}
        alt={props.value.altText}
        width={imageWidth}
        height={imageHeight}
        className={cx(
          styles.image,
          (isGalleryProtected || (visitorsAllowed === false && !folderIdFeat)) && galleryIsLinked
            ? styles.protectedImage
            : "",
          interactionLinkStr ? styles.pointerHover : ""
        )}
        styles={{
          objectPosition: newFocalX + "% " + newFocalY + "%"
        }}
        copyEnabled={!props.blockImageEvents || !props.isEdit}
        isGhost={isGhost}
      />
    );
  };

  const getTileImage = (interactionLinkStr: string) => {
    const { link, galleryIsProtected, galleryHasVisitorsAllowed, folderId, interactionType, isLoading } = props.value;
    const isGalleryProtected = link ? link.albumFolderData?.album.isGalleryProtected : galleryIsProtected;
    const folderIdFeat = link ? link.albumFolderData?.folder.id : folderId;
    const visitorsAllowed = link ? link.albumFolderData?.album.isVisitorAllowed : galleryHasVisitorsAllowed;
    const galleryIsLinked = link
      ? link.type === LinkTypeOption.GALLERY
      : interactionType === INTERACTION_TYPE.ALBUMFOLDER;

    return (
      <div className={styles.noOverflow}>
        <div onClick={e => (interactionLinkStr ? handleImageClick(interactionLinkStr) : null)}>
          {renderImage(props.value, interactionLinkStr)}
        </div>
        {(isGalleryProtected || (visitorsAllowed === false && !folderIdFeat)) && galleryIsLinked && (
          <div className={styles.albumLock}>
            <AlbumLock color={colors.darkGrey} size={24} />
          </div>
        )}
      </div>
    );
  };

  const hasInteractions = (interactionLinkStr: string) => {
    if (props.value.link) {
      return interactionLinkStr.trim() !== "";
    } else {
      return props.value.interactionType !== "none" && props.value.buttonLabel.trim() !== "";
    }
  };

  const getTarget = (propsData: React.PropsWithChildren<IFeaturedWorkComponent>) => {
    if (propsData.value.link) {
      return propsData.value.link.newTab ? "_blank" : "";
    } else {
      return !pageContext.isPreview &&
        (propsData.value.interactionNewTab ||
          (propsData.value.galleryIsProtected && propsData.value.galleryHasVisitorsAllowed) ||
          (propsData.value.galleryIsVisitorEmailRequired && propsData.value.galleryHasVisitorsAllowed))
        ? "_blank"
        : "";
    }
  };

  const renderLink = (propsData: React.PropsWithChildren<IFeaturedWorkComponent>, interactionLinkStr: string) => {
    const target = getTarget(propsData);

    const renderButton = propsData.value.link ? true : propsData.value.buttonLabel.trim() !== "";

    const buttonLabel = propsData.value.link?.label
      ? renderButton
        ? propsData.value.link?.label
        : ""
      : propsData.value.buttonLabel;

    return (
      <React.Fragment>
        {hasInteractions(interactionLinkStr) ? (
          <a
            className={cx(styles.button, { [styles.seeMoreClickable]: !propsData.readOnly })}
            href={interactionLinkStr}
            target={target}
          >
            {buttonLabel}
            {renderButton && <div className={cx(styles.triangle, propsData.readOnly ? styles.publish : "")} />}
          </a>
        ) : (
          <div className={cx(styles.button, { [styles.seeMoreClickable]: !propsData.readOnly })}>
            {buttonLabel}
            {renderButton && <div className={cx(styles.triangle, propsData.readOnly ? styles.publish : "")} />}
          </div>
        )}
      </React.Fragment>
    );
  };

  const serviceLayoutB = (propsData: React.PropsWithChildren<IFeaturedWorkComponent>, interactionLinkStr: string) => {
    return (
      <React.Fragment>
        <div
          style={{
            flexDirection: propsData.index % 2 === 0 ? "row" : "row-reverse"
          }}
          className={styles.featuredBContainer}
        >
          {getTileImage(interactionLinkStr)}
          <div className={styles.featuredInfo}>
            <div className={cx(styles.titleContainer, justifyContentClass)}>
              <div
                className={cx(styles.feature, styles[siteFonts.primaryExtra], {
                  [styles.featureClickable]: !propsData.readOnly
                })}
              >
                {propsData.value.title}
              </div>
            </div>
            <div
              className={cx(styles.bodyContainer, styles[siteFonts.secondary], justifyContentClass, {
                [styles.bodyClickable]: !propsData.readOnly
              })}
            >
              {renderLineBreaks(propsData.value.body)}
            </div>
            <div className={cx(styles.buttonContainer, justifyContentClass, styles[siteFonts.secondary])}>
              {renderLink(propsData, interactionLinkStr)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const serviceLayoutA = (propsData: React.PropsWithChildren<IFeaturedWorkComponent>, interactionLinkStr: string) => {
    return (
      <React.Fragment>
        {getTileImage(interactionLinkStr)}
        <div className={cx(styles.titleContainer, justifyContentClass)}>
          <p
            className={cx(styles.feature, styles[siteFonts.primaryExtra], {
              [styles.featureClickable]: !propsData.readOnly
            })}
          >
            {propsData.value.title}
          </p>
        </div>
        <div
          className={cx(styles.bodyContainer, justifyContentClass, styles[siteFonts.secondary], {
            [styles.bodyClickable]: !propsData.readOnly
          })}
        >
          {renderLineBreaks(propsData.value.body)}
        </div>
        <div className={cx(styles.buttonContainer, justifyContentClass, styles[siteFonts.secondary])}>
          {renderLink(propsData, interactionLinkStr)}
        </div>
      </React.Fragment>
    );
  };

  const desAlign = props.value.alignment;
  const justifyContentClass = cx(
    styles.justifyContent,
    desAlign === "left"
      ? styles.justifyContentLeft
      : desAlign === "right"
      ? styles.justifyContentRight
      : styles.justifyContentCenter
  );

  const interactionLink = (propsData: React.PropsWithChildren<IFeaturedWorkComponent>) => {
    const { readOnly, pages, value, routes, isPublish } = propsData;

    if (value.link) {
      if (isPublish && propsData.getInteractionUrl) {
        return propsData.getInteractionUrl(
          pages || [],
          value.link?.type || "",
          value.link?.url || "",
          value.link?.page || "",
          routes ? routes : {},
          value.link?.albumFolderData?.album.id || "",
          value.link?.albumFolderData
            ? {
                gridType: value.link?.albumFolderData?.album.gridType,
                title: value.link?.albumFolderData?.title,
                folderGridType: value.link?.albumFolderData?.folder.gridType,
                showTitles: value.link?.albumFolderData?.showTitleAndText,
                allowShowCaption: value.link?.allowShowCaption === true
              }
            : null,
          value.link?.albumFolderData?.folder.id || "",
          value.link?.albumFolderData?.galleryFolderAlias,
          value
        );
      } else if (readOnly && pages) {
        return getFinalInteractionUrl(
          !!propsData.isPublish,
          pages,
          propsData.value.interactionUrl,
          propsData.value.interactionPage,
          propsData.value.interactionType,
          routes ? routes : {},
          propsData.value.link,
          value
        );
      } else {
        return "";
      }
    } else {
      return getInteractionUrl(
        propsData.pages ?? [],
        propsData.value.interactionType ? propsData.value.interactionType : "",
        propsData.value.interactionUrl ? propsData.value.interactionUrl : "",
        propsData.value.interactionPage ? propsData.value.interactionPage : "",
        propsData.routes ? propsData.routes : {},
        propsData.value.galleryId ?? "",
        propsData.value.gallerySettings ?? null,
        propsData.value.folderId ?? "",
        propsData.value.galleryFolderAlias,
        value
      );
    }
  };
  return (
    <div
      style={{
        outline: props.value.isSelected && props.isEdit ? "solid 2px #f15a0b" : "",
        display: props.value.isVisible ? "block" : "none",
        color: getFontColor(props.themeColor.value)
      }}
      className={cx(
        props.layout === "A" ? styles.zenContainerFeatureMain : styles.zenContainerFeatureMainB,
        props.value.isVisible ? styles.displayBlock : styles.displayNone,
        styles[siteFonts.primary],
        "featureTheme-" + props.themeColor.name,
        { [`slide-${props.index}`]: !props.readOnly }
      )}
      data-index={props.index}
      id={props.value.galleryId}
    >
      <style>
        {`
            .${"featureTheme-" + props.themeColor.name} {
              --ft-background-color: ${getBackGroundTile(props.themeColor.value)};
              --theme-color: ${props.themeColor.value};
              --font-color: ${getFontColor(props.themeColor.value)};
              }
            `}
      </style>
      {props.layout === "A"
        ? serviceLayoutA(props, interactionLink(props))
        : serviceLayoutB(props, interactionLink(props))}
    </div>
  );
};

export default FeaturedWork;
