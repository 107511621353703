import cx from "classnames";
import withWindowSize, { IWindowSize } from "components/hoc/withWindowSize";
import _ from "lodash";
import React, { CSSProperties } from "react";
import { isMobileOnly } from "react-device-detect";
import ReactDOM from "react-dom";
import { Utilities } from "utilities/utilities";
import ZenButton, { IButtonProps } from "../zenButton";
import styles from "./zenBookmeButton.module.scss";

export interface IBookmeButtonProps extends IButtonProps {
  window: IWindowSize;
  bookmeUrl: string;
  widgetPadding?: number;
  widgetMarginBottom?: number;
  onWidgetOpenChange?: (widgetOpen: boolean) => void;
}

interface IBookmeButtonState {
  widgetOpen: boolean;
  widgetState: "init" | "loaded" | "error";
}

class ZenBookmeButton extends React.Component<IBookmeButtonProps, IBookmeButtonState> {
  public state: IBookmeButtonState = { widgetOpen: false, widgetState: "init" };

  public componentDidMount() {
    window.addEventListener("message", this.onWindowMessage);
    window.addEventListener("keydown", this.onWindowKeyDown, true);
    window.addEventListener("click", this.onWindowClick, true);
  }

  public componentWillUnmount() {
    window.removeEventListener("message", this.onWindowMessage);
    window.removeEventListener("keydown", this.onWindowKeyDown, true);
    window.removeEventListener("click", this.onWindowClick, true);
  }

  public componentDidUpdate(prevProps: IBookmeButtonProps, prevState: IBookmeButtonState) {
    const widgetHasOpened = !prevState.widgetOpen && this.state.widgetOpen;
    if (widgetHasOpened && this.widgetElement!.getBoundingClientRect().top < 0) {
      this.widgetElement!.scrollIntoView();
    }

    if (prevState.widgetOpen !== this.state.widgetOpen) {
      this.props.onWidgetOpenChange?.(this.state.widgetOpen);
    }
  }

  public render() {
    const { isEditionView } = this.props;
    const { widgetOpen } = this.state;

    return (
      <div className={styles.container}>
        {isMobileOnly &&
          ReactDOM.createPortal(
            <div className={cx(styles.overlay, !widgetOpen && styles.hidden)}>{this.renderWidget()}</div>,
            document.body
          )}
        {!isMobileOnly && this.renderWidget()}
        <ZenButton
          {..._.omit(this.props, "window", "bookmeUrl")}
          onClick={isEditionView ? undefined : this.onButtonClick}
          buttonRef={this.buttonRef}
        />
      </div>
    );
  }

  private renderWidget() {
    const { widgetOpen, widgetState } = this.state;

    const sizeAndPosition: CSSProperties | undefined =
      typeof window === "undefined" || isMobileOnly
        ? undefined
        : {
            width: ui.widget.width + 2 * this.widgetPadding,
            height: this.widgetHeight,
            left: this.widgetLeft,
            bottom: this.widgetBottom,
            marginBottom: this.widgetMarginBottom - this.widgetPadding
          };

    return (
      widgetState !== "error" && (
        <iframe
          ref={this.widgetRef}
          allow="geolocation"
          scrolling="no"
          src={this.widgetUrl}
          style={widgetOpen ? sizeAndPosition : undefined}
          className={cx(styles.widget, !widgetOpen && styles.hidden, isMobileOnly && styles.mobile)}
        />
      )
    );
  }

  private get widgetElement() {
    return this.widgetRef.current;
  }

  private get buttonElement() {
    return this.buttonRef.current;
  }

  private get widgetUrl() {
    const urlBuilder = new URL(this.props.bookmeUrl);
    urlBuilder.searchParams.set("layoutType", isMobileOnly ? "FULL_DIMMED" : "FULL_TRANSPARENT");

    const accentColor = this.props.accentColor?.value;
    if (accentColor) {
      urlBuilder.searchParams.set("accentColor", accentColor);
    }

    return urlBuilder.href;
  }

  private get widgetPadding() {
    return this.props.widgetPadding || ui.widget.padding;
  }

  private get widgetMarginBottom() {
    return this.props.widgetMarginBottom || ui.widget.marginBottom;
  }

  private get widgetHeight() {
    function getScrollTop(el: HTMLElement | null): number {
      return !el ? 0 : el.scrollTop || getScrollTop(el.parentElement);
    }

    const button = this.buttonElement;
    const windowHeight = this.props.window.height;
    const spaceOnTop = button
      ? button.getBoundingClientRect().top + getScrollTop(button) - this.widgetMarginBottom + this.widgetPadding
      : windowHeight;
    const result = Math.min(ui.widget.height + 2 * this.widgetPadding, windowHeight, spaceOnTop);
    return result;
  }

  private get widgetLeft() {
    const button = this.buttonElement;
    if (!button) {
      return "auto";
    }

    const buttonRect = button.getBoundingClientRect();
    const offset = Math.round((ui.widget.width - buttonRect.width) / 2 + this.widgetPadding);

    const widgetLeft = buttonRect.left - offset;
    if (widgetLeft < 0) {
      return -this.widgetPadding;
    }

    const widgetRight = buttonRect.right + offset;
    if (widgetRight > this.props.window.width) {
      return Math.round(buttonRect.width - ui.widget.width - this.widgetPadding);
    }

    return "auto";
  }

  private get widgetBottom() {
    const button = this.buttonElement;
    return button ? button.offsetHeight : 0;
  }

  private onButtonClick = () => {
    if (this.props.disabled) {
      return;
    }

    this.props.onClick?.();
    const { widgetOpen, widgetState } = this.state;
    if (widgetState === "loaded") {
      if (widgetOpen) {
        this.sendWidgetMessage({ type: "toggle" });
      } else {
        this.sendWidgetMessage({ type: "toggle" });
        this.setState({ widgetOpen: true });
      }
    }
  };

  private onWindowMessage = (event: MessageEvent) => {
    if (event && event.data && event.source === this.widgetElement?.contentWindow) {
      this.processWidgetMessage(event);
    }
  };

  private onWindowKeyDown = (event: KeyboardEvent) => {
    const { widgetOpen } = this.state;
    if (!event || !event.key || !widgetOpen) {
      return;
    }

    if (event.key === "Escape") {
      this.sendWidgetMessage({ type: "escape" });
    } else if (Utilities.isEnterKey(event)) {
      this.sendWidgetMessage({ type: "enter" });
    }
  };

  private onWindowClick = (event: MouseEvent) => {
    const { widgetOpen } = this.state;
    if (!event || !event.target || (event.target as Element).tagName === "HTML" || !widgetOpen) {
      return;
    }

    if (!this.widgetElement?.contains(event.target as Node) && !this.buttonElement?.contains(event.target as Node)) {
      this.sendWidgetMessage({ type: "close" });
    }
  };

  private sendWidgetMessage(data: any) {
    this.widgetElement?.contentWindow?.postMessage(JSON.stringify(data), "*");
  }

  private processWidgetMessage(event: MessageEvent) {
    let data: any = undefined;

    try {
      data = JSON.parse(event.data);
    } catch (e) {}

    if (!data || !data.type) {
      return;
    }

    if (data.type === "loaded") {
      this.setState({ widgetState: "loaded" });
    } else if (data.type === "expanding") {
      this.sendWidgetMessage({ type: "expanded" });
    } else if (data.type === "collapsed") {
      this.setState({ widgetOpen: false });
    } else if (data.type === "hide") {
      this.setState({ widgetState: "error", widgetOpen: false });
    } else if (data.type === "strong-customer-authentication-request") {
      this.handleStrongCustomerAuthentication(data);
    }
  }

  private handleStrongCustomerAuthentication(data: any) {
    const Stripe = (window as any).Stripe;

    if (typeof Stripe !== "function") {
      console.error("Stripe is not loaded on the website.");

      this.finishStrongCustomerAuthentication();
    } else if (Stripe.version !== data.stripeVersion) {
      console.error(
        "Stripe version %d on the website is incompatible with BookMe version %d.",
        Stripe.version,
        data.stripeVersion
      );

      this.finishStrongCustomerAuthentication();
    } else {
      const stripe = Stripe(data.stripePublishableKey, {
        stripeAccount: data.stripeAccountId,
        locale: "en"
      });

      if (data.approvalRequired) {
        stripe.confirmCardSetup(data.clientSecret).then((response: any) => {
          this.finishStrongCustomerAuthentication(response.setupIntent, response.error);
        });
      } else {
        stripe.handleCardAction(data.clientSecret).then((response: any) => {
          this.finishStrongCustomerAuthentication(response.paymentIntent, response.error);
        });
      }
    }
  }

  private finishStrongCustomerAuthentication(intent?: any, error?: any) {
    this.sendWidgetMessage({
      type: "strong-customer-authentication-response",
      paymentIdentifier: intent?.id,
      failureCode: error?.code
    });
  }

  private readonly widgetRef = React.createRef<HTMLIFrameElement>();
  private readonly buttonRef = React.createRef<HTMLButtonElement>();
}

const ui = {
  widget: { width: 320, height: 532, padding: 12, marginBottom: 24 }
};

export default withWindowSize(ZenBookmeButton, 100);
