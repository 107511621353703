import styles from "./toolbar-button.module.scss";

import cx from "classnames";
import React, { CSSProperties, forwardRef, FunctionComponent, MouseEventHandler } from "react";

interface OwnProps {
  style?: CSSProperties;
  active?: boolean;
  className?: string;
  onMouseDown?: MouseEventHandler;
  ref?: any;
}

type Props = OwnProps;

const ToolbarButton: FunctionComponent<Props> = forwardRef((props, ref) => {
  const { children, active = false, style, onMouseDown, className } = props;

  const handleMouseDown: MouseEventHandler = event => {
    event.preventDefault();
    onMouseDown?.(event);
  };

  return (
    <button
      style={style}
      className={cx(styles.toolbarBtn, active && styles.active, className)}
      ref={ref as any}
      onMouseDown={handleMouseDown}
    >
      {children}
    </button>
  );
});

export { ToolbarButton };
