import React from "react";
import styles from "../zenInstagramBlock.module.scss";
import { Swipeable } from "react-swipeable";
import Images from "./images";
import { IInstagramImage, IInstagramImageLayoutB } from "../";

const IMG_SIZE = 297;
const IMG_SIZE_WITHOUT_MARGINS = 285;

export interface IMobileCarouselProps {
  viewportWidth: number;
  currentPhoto: number;
  photosLength: number;
  hasNextPage: boolean;
  layout?: string;
  images: IInstagramImage[];
  imagesLayoutB: IInstagramImageLayoutB[];
  isFetchingImages: boolean;
  readOnly?: boolean;
  caption: boolean;
  lightbox: string;
  handleImageClick: (index: number) => void;
  goNext: (target: number, pos: number, ref: React.RefObject<HTMLDivElement>) => void;
  goBack: (target: number, pos: number, ref: React.RefObject<HTMLDivElement>, callback?: () => void) => void;
  getImagesNextPage: () => void;
}

class MobileCarousel extends React.PureComponent<IMobileCarouselProps> {
  public imagesContentRef: React.RefObject<HTMLDivElement>;

  constructor(props: IMobileCarouselProps) {
    super(props);

    this.imagesContentRef = React.createRef();
  }

  public handleSwipedRight = () => {
    if (this.imagesContentRef.current && this.props.currentPhoto > 0) {
      const pos = Number(this.imagesContentRef.current.style.left.replace("px", ""));
      this.props.goBack(IMG_SIZE, pos, this.imagesContentRef);
    }
  };

  public handleSwipedLeft = () => {
    const { currentPhoto, photosLength, hasNextPage, isFetchingImages, getImagesNextPage } = this.props;
    if (this.imagesContentRef.current && currentPhoto < photosLength - 1) {
      const pos = Number(this.imagesContentRef.current.style.left.replace("px", ""));

      this.props.goNext(-IMG_SIZE, pos, this.imagesContentRef);

      if (currentPhoto === photosLength - 4 && hasNextPage && !isFetchingImages) {
        getImagesNextPage();
      }
    }
  };

  public render() {
    const {
      viewportWidth,
      currentPhoto,
      layout,
      images,
      imagesLayoutB,
      readOnly,
      caption,
      lightbox,
      handleImageClick
    } = this.props;
    const left = Math.trunc((viewportWidth - IMG_SIZE_WITHOUT_MARGINS) / 2);
    return (
      <div className={styles.sliderBlock}>
        <Swipeable onSwipedRight={this.handleSwipedRight} onSwipedLeft={this.handleSwipedLeft}>
          <div className={styles.sliderContainer} ref={this.imagesContentRef} style={{ left: `${left}px` }}>
            <Images
              images={images}
              imagesLayoutB={imagesLayoutB}
              layout={layout}
              isMobileSize
              readOnly={readOnly}
              currentPhoto={currentPhoto}
              caption={caption}
              lightbox={lightbox}
              handleImageClick={handleImageClick}
            />
          </div>
        </Swipeable>
      </div>
    );
  }
}

export default MobileCarousel;
