import React, { useState, useEffect } from "react";
import styles from "./imagesLightBox.module.scss";
import arrowPrev from "./icon-prev.svg";
import arrowNext from "./icon-next.svg";
import close from "./close.svg";
import cx from "classnames";
import { Swipeable } from "react-swipeable";
import ContextMenu from "utilities/contextMenu";

export interface IImagesLightBoxProps {
  images: string[];
  show: boolean;
  isPreview?: boolean;
  selectedIndexImage: number;
  onClose?: () => void;
}

const ImagesLightBox: React.FC<IImagesLightBoxProps> = props => {
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndexImage);

  const handleLeftClick = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const handleRightClick = () => {
    if (selectedIndex < props.images.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handleCloseClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const keyPressHandler = (e: any) => {
    if (e.keyCode === 27 && props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    setSelectedIndex(props.selectedIndexImage);

    window.addEventListener("keydown", keyPressHandler);

    return () => {
      window.removeEventListener("keydown", keyPressHandler);
    };
  }, [props.selectedIndexImage]);

  return (
    <div className={cx(styles.lightboxModal, { [styles.hide]: !props.show })}>
      <span className={cx(styles.lightboxClose, { [styles.lightboxClosePreview]: props.isPreview })}>
        <img src={close} width="18" onClick={handleCloseClick} />
      </span>
      <div className={styles.lightboxContainer}>
        <button className={cx(styles.lightboxArrow, styles.lightboxArrowPrev, { [styles.hide]: selectedIndex === 0 })}>
          <img src={arrowPrev} width="24" onClick={handleLeftClick} />
        </button>
        <Swipeable onSwipedRight={handleLeftClick} onSwipedLeft={handleRightClick}>
          <figure>
            <img src={props.images[selectedIndex]} width="640" onContextMenu={ContextMenu.handleBlockContextMenu} />
          </figure>
        </Swipeable>
        <button
          className={cx(styles.lightboxArrow, styles.lightboxArrowNext, {
            [styles.hide]: selectedIndex === props.images.length - 1
          })}
          onClick={handleRightClick}
        >
          <img src={arrowNext} width="24" />
        </button>
      </div>
    </div>
  );
};

export default ImagesLightBox;
