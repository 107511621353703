import * as React from "react";
import { IShootLocation } from "../../../../../store/profile/shootLocation/model";
import eventTracker from "../../../../../utilities/eventTracker";
import Map, { ICoordinates } from "../../../../addressSelection/map";
import { IEditableWidgetStepProps, IWidgetStep } from "../../../contracts";
import { renderAddress } from "../../../helpers";
import styles from "./index.module.scss";

export interface IPrimaryOnlyLocationParams {
  shootLocation: IShootLocation;
}

interface ISelectLocationProps extends IPrimaryOnlyLocationParams, IEditableWidgetStepProps {}

class PrimaryOnlyLocationStep extends React.Component<ISelectLocationProps> implements IWidgetStep {
  public componentDidMount() {
    eventTracker.events.consumer.locationReached();
    this.props.onRef(this);
  }

  public componentWillUnmount() {
    this.props.onRef(undefined);
  }

  public render() {
    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <label>Photoshoot Address:</label>
          <div className={styles.address}>{renderAddress(this.props.shootLocation, true)}</div>
          <div className={styles.map}>
            <Map marker={this.coordinates} zoom={15} />
          </div>
        </div>
      </div>
    );
  }

  get coordinates(): ICoordinates {
    const { shootLocation } = this.props;
    return {
      lat: shootLocation.latitude,
      lng: shootLocation.longitude
    };
  }

  public saveAndContinue = (): void => {
    this.props.updateValues({
      shootLocation: this.props.shootLocation
    });
  };

  public deferNavigation = (): boolean => {
    return false;
  };
}

export default PrimaryOnlyLocationStep;
