import _ from "lodash";
import moment from "moment";

export function renamePath(object: any, oldPath: string, newPath: string) {
  _.set(object, newPath, _.get(object, oldPath));
  _.unset(object, oldPath);
  return object;
}

export function renamePathInArray(object: any, arrayPath: string, oldPath: string, newPath: string) {
  _.forEach(_.get(object, arrayPath), item => renamePath(item, oldPath, newPath));
  return object;
}

export function removeNullProps(object: any) {
  for (const key of _.keys(object)) {
    const value = object[key];
    if (value === null) {
      delete object[key];
    } else if (typeof value === "object") {
      removeNullProps(value);
    }
  }
  return object;
}

export interface IMapper {
  rename: (oldPath: string, newPath: string) => IMapper;
  renameInEach: (oldPath: string, newPath: string) => IMapper;
  renameInArray: (arrayPath: string, oldPath: string, newPath: string) => IMapper;
  map: (path: string, mapper: (object: any) => any) => IMapper;
  mapEach: (mapper: (object: any) => any) => IMapper;
  mapArray: (arrayPath: string, mapper: (object: any) => any) => IMapper;
  set: (path: string, value: any) => IMapper;
  toDate: (path: string) => IMapper;
  removeNullProps: () => IMapper;
  value: () => any;
}

export function map(object: any): IMapper {
  const chain = {
    rename: (oldPath: string, newPath: string) => {
      renamePath(object, oldPath, newPath);
      return chain;
    },
    renameInEach: (oldPath: string, newPath: string) => {
      _.forEach(object, item => renamePath(item, oldPath, newPath));
      return chain;
    },
    renameInArray: (arrayPath: string, oldPath: string, newPath: string) => {
      renamePathInArray(object, arrayPath, oldPath, newPath);
      return chain;
    },
    map: (path: string, mapper: (object: any) => any) => {
      mapper(_.get(object, path));
      return chain;
    },
    mapEach: (mapper: (object: any) => any) => {
      _.forEach(object, mapper);
      return chain;
    },
    mapArray: (arrayPath: string, mapper: (object: any) => any) => {
      _.forEach(_.get(object, arrayPath), item => mapper(item));
      return chain;
    },
    set: (path: string, value: any) => {
      _.set(object, path, value);
      return chain;
    },
    toDate: (path: string) => {
      const value = _.get(object, path);
      if (value != null) {
        _.set(object, path, moment(value).toDate());
      }
      return chain;
    },
    removeNullProps: () => {
      removeNullProps(object);
      return chain;
    },
    value: () => object
  };
  return chain;
}
