import cx from "classnames";
import { NameAlignment, NameVisibility } from "components/blocks/blockModels";
import { matchMediaPhone } from "utilities/responsive";
import React from "react";
import styles from "./name.module.scss";

export interface INameProps {
  name: string;
  alignment?: NameAlignment;
  isHovered?: boolean;
  withDuration?: boolean;
}

export function showName(isHovered: boolean, name?: string, visibility?: NameVisibility) {
  return !matchMediaPhone && !!name && (visibility === "always" || (visibility === "hover" && isHovered));
}

const Name: React.FC<INameProps> = props => {
  return (
    <div className={cx(styles.container, props.isHovered && styles.hovered)}>
      <div
        className={cx(styles.name, {
          [styles.leftAligned]: props.alignment === "left",
          [styles.withDuration]: props.withDuration
        })}
      >
        &lrm;{props.name}
      </div>
    </div>
  );
};

export default Name;
