import React, { useState, useEffect } from "react";
import cx from "classnames";
import styles from "./menuItem.module.scss";
import colors from "utilities/colors";
import Caret from "../../../components/Icons/Caret";
import { INavigationMenuItem } from "../../../models/models";

export interface IMenuItem {
  delay: string;
  open: boolean;
  color: string;
  backgroundColor: string;
  initOpenItem: boolean;
  childs: INavigationMenuItem[];
  key: number | string;
  hoverState: string;
  id: string;
  link: string | undefined;
  fontSize?: number;
  marginTop?: number;
  onItemClick: (e: any, id: string) => void;
  isDesktop?: boolean;
  className?: string;
  siteSubdomain?: string;
  preventAnchorLink?: boolean;
  formatLink: (item: INavigationMenuItem) => string | undefined;
}

enum HOVER_STATE {
  UNDERLINE = "underline"
}

const MenuItem: React.FC<IMenuItem> = props => {
  const [open, setOpen] = useState(props.open ? props.open : false);
  const [color, setColor] = useState(props.color ? props.color : "white");
  const [hoverState, setHoverState] = useState(props.hoverState ? props.hoverState : HOVER_STATE.UNDERLINE);

  const [closedItem, setClosedItem] = useState(props.initOpenItem ? props.initOpenItem : false);

  const { isDesktop } = props;

  const stylesMenuItem = {
    animationDelay: props.delay,
    marginTop: `${props.marginTop}px`
  };

  const mapChildItems = (childs: INavigationMenuItem[]) => {
    const listItems = childs.map((item: INavigationMenuItem, index) => {
      const { formatLink } = props;
      const itemLink = formatLink(item);
      return (
        <li key={item.id || item.menuName || index}>
          <a
            href={itemLink || ""}
            style={{ fontSize: `${props.fontSize}px` }}
            className={styles.childItem}
            onClick={e => props.onItemClick(e, item.id)}
            target={item.openInNewTab ? "_blank" : ""}
          >
            {item.menuCaption}
          </a>
        </li>
      );
    });
    return listItems;
  };

  const handleOnOpenItem = () => {
    setClosedItem(!closedItem);
  };

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.TouchEvent<HTMLAnchorElement>
  ) => {
    if (!isDesktop && props.childs.length > 0) {
      e.stopPropagation();
    }
    if (props.preventAnchorLink) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setOpen(props.open);
    setColor(props.color);
    setHoverState(props.hoverState);
    setClosedItem(false);
  }, [props.open, props.color, props.hoverState]);

  const hasChilds = props.childs.length > 0;

  return (
    <div className={open ? styles.open : styles.close}>
      <div
        onClick={e => {
          handleOnOpenItem();
        }}
        style={stylesMenuItem}
        className={cx(styles.menuItem, {
          [styles.isMobile]: !isDesktop,
          [styles.submenuOpened]: closedItem,
          [styles.hoverUnderline]: hoverState === HOVER_STATE.UNDERLINE
        })}
      >
        <a
          href={props.link}
          onClick={e => {
            handleLinkClick(e);
          }}
          style={{ fontSize: `${props.fontSize}px` }}
          className={cx(styles.linkItem, props.className)}
        >
          {props.children}
        </a>
        {hasChilds && (
          <span className={cx(styles.arrowIcon, { [styles.desktopArrow]: isDesktop })}>
            <Caret
              down={true}
              open={!closedItem}
              color={isDesktop ? color : colors.black}
              minX={0}
              minY={0}
              size={isDesktop ? 9 : 16}
            />
          </span>
        )}
        {hasChilds && closedItem && (
          <ul className={cx(styles.childList, isDesktop && styles.childListDesktop)}>{mapChildItems(props.childs)}</ul>
        )}
      </div>
    </div>
  );
};

export default MenuItem;
