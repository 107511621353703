import cx from "classnames";
import Caret from "../../Icons/Caret32px";
import Image from "../../Image";
import VideoDuration from "../../VideoDuration";
import playVideoSmallIconUrl from "icons/btn-play-video-small.svg";
import playVideoIconUrl from "icons/btn-play-video.svg";
import { HeroSpeedType, HeroTransitionType } from "utilities/constant";
import { PageContext } from "utilities/pageContext";
import { IEditorMediaData } from "utilities/placeholdes";
import React, { Fragment } from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import "slick-carousel/slick/slick.css";
import ContextMenu from "utilities/contextMenu";
import { universalWindow as window } from "utilities/blocks/site";
import colors from "utilities/colors";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks";
import styles from "./zenPhotoCarousel.module.scss";

export interface IPhotoCarouselBlockProps extends IZenBaseBlockProps {
  images: IEditorMediaData[];
  caret: string;
  transition: string;
  speed: string;
  selectedIndex?: number;
  blockImageEvents?: boolean;
  onPhotoSelected?: (id: number) => void;
}

interface IPhotoCarouselBlockState {
  viewportWidth: number;
}

function SampleNextArrow(props: any) {
  const { onClick } = props;

  if (isMobile) {
    return null;
  }

  return (
    <button className={styles.sliderArrow + " " + styles.nextArrow} onClick={onClick}>
      <Caret left={false} color={props.color} size={32} />
    </button>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;

  if (isMobile) {
    return null;
  }
  return (
    <button className={styles.sliderArrow + " " + styles.prevArrow} onClick={onClick}>
      <Caret left color={props.color} size={32} />
    </button>
  );
}

export class ZenPhotoCarouselBlock extends React.Component<IPhotoCarouselBlockProps, IPhotoCarouselBlockState> {
  static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  public state = {
    viewportWidth: 0
  };

  private slider: any = null;

  private getCaretColor = (caret: string) => {
    let result = this.props.siteTheme.accentColor.value;

    switch (caret) {
      case "black":
        result = colors.black;
        break;
      case "white":
        result = colors.white;
        break;
    }

    return result;
  };

  private getSpeed = (speed: string) => {
    switch (speed) {
      case HeroSpeedType.FAST:
        return 600;
      case HeroSpeedType.MEDIUM:
        return 1000;
      case HeroSpeedType.SLOW:
        return 1500;
      default:
        return 600;
    }
  };

  private getPhotoMargin = (layout: string, isMobile: boolean) => {
    switch (layout) {
      case "A":
        return isMobile ? 1 : 4;
      case "B":
        return 2;
      case "C":
        return 1;
      default:
        return 0;
    }
  };

  private handleImageClick = (index: number) => {
    const { readOnly, onPhotoSelected } = this.props;
    if (!readOnly && onPhotoSelected) {
      onPhotoSelected(index);
    }
  };

  private renderPlayIcon = (duration: number) => {
    return (
      <Fragment>
        <div className={styles.videoPlay}>
          <img src={isMobileOnly ? playVideoIconUrl : playVideoSmallIconUrl} alt="Play" className={styles.videoPlay} />
        </div>
        <VideoDuration className={styles.videoDuration} durationMilliseconds={duration} />
      </Fragment>
    );
  };

  private renderContent = (color: string) => {
    const { images, transition, speed, selectedIndex, readOnly, layout } = this.props;
    if (images.length === 1) {
      const img = images[0];
      const isGhost = !this.context.noGhost && images[0].isFallback;
      return (
        <div onClick={e => this.handleImageClick(0)}>
          <img
            src={img.url}
            data-srcset={img.srcset}
            data-sizes="auto"
            className={cx("hasLazyLoad", "lazyload", {
              [styles.fallback]: isGhost,
              [styles.hightlightLayoutA]: selectedIndex === 0 && layout === "A",
              [styles.hightlight]: selectedIndex === 0 && layout !== "A",
              [styles.clickable]: !readOnly,
              [styles.disableEvents]: this.props.blockImageEvents
            })}
            alt={img.altText || img.fileName}
          />
          {img.video && this.renderPlayIcon(img.video?.durationMilliseconds || 0)}
        </div>
      );
    } else if (typeof window !== "undefined") {
      const Slider = require("react-slick").default;

      const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: transition === HeroTransitionType.AUTOMATIC ? true : false,
        pauseOnHover: false,
        speed: this.getSpeed(speed),
        autoplaySpeed: this.getSpeed(speed) * 4,
        cssEase: "linear",
        nextArrow: <SampleNextArrow color={color} />,
        prevArrow: <SamplePrevArrow color={color} />
      };

      return (
        <Slider ref={(slider: any) => (this.slider = slider)} {...settings}>
          {images.map(img => {
            const width = img.width || 0;
            const height = img.height || 0;
            const isGhost = !this.context.noGhost && img.isFallback;

            return (
              <div className={styles.imagesContainer} onClick={e => this.handleImageClick(img.index)} key={img.id}>
                <Image
                  src={img.url}
                  srcset={img.srcset}
                  alt={img.altText || img.fileName}
                  width={width}
                  height={height}
                  className={cx({
                    [styles.hightlightLayoutA]: selectedIndex === img.index && layout === "A",
                    [styles.hightlight]: selectedIndex === img.index && layout !== "A",
                    [styles.imgLandscape]: width > height,
                    [styles.imgPortrait]: height > width,
                    [styles.imgSquare]: width === height,
                    [styles.clickable]: !readOnly
                  })}
                  copyEnabled={!this.props.blockImageEvents}
                  isGhost={isGhost}
                  key={img.id}
                />
                {img.video && this.renderPlayIcon(img.video?.durationMilliseconds || 0)}
              </div>
            );
          })}
        </Slider>
      );
    } else {
      return <div />;
    }
  };

  private calculateTrackWidthMobile = () => {
    const { layout } = this.props;
    const slickTrack = document.getElementsByClassName("slick-track")[0] as HTMLElement;
    if (slickTrack) {
      const margin = this.getPhotoMargin(layout || "", this.state.viewportWidth < 767);

      const children = slickTrack.childNodes;

      let widthChildren = 0;
      for (let index = 0; index < children.length; index++) {
        const child = children[index] as HTMLElement;

        if (child) {
          widthChildren = widthChildren + child.clientWidth + margin;
        }
      }

      if (widthChildren) {
        slickTrack.style.width = widthChildren + "px";
      }
    }
  };

  private updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth });
    window.lazySizes?.autoSizer.checkElems();
  };

  public componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    if (isMobile) {
      setTimeout(() => {
        this.calculateTrackWidthMobile();
      }, 2000);
    }
  }

  public componentDidUpdate(prevProps: IPhotoCarouselBlockProps) {
    if (
      prevProps.selectedIndex !== this.props.selectedIndex &&
      this.props.selectedIndex !== undefined &&
      this.props.selectedIndex >= 0 &&
      this.props.images.length > 1 &&
      !this.props.readOnly
    ) {
      this.slider.slickGoTo(this.props.selectedIndex, false);
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      fontFamily,
      divider,
      padding,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      caret,
      images
    } = this.props;

    const isMobileSize = isMobileOnly || this.state.viewportWidth < 767;

    const layout = isMobileSize ? "A" : this.props.layout;

    const contentClass = cx(styles.container, {
      [styles.containerEditor]: !readOnly,
      [styles.layoutA]: layout === "A" && !isMobile,
      [styles.layoutB]: layout === "B" && !isMobile,
      [styles.layoutC]: layout === "C" && !isMobile,
      [styles.layoutATablet]: layout === "A" && isMobile,
      [styles.layoutBTablet]: layout === "B" && isMobile,
      [styles.layoutCTablet]: layout === "C" && isMobile,
      [styles.flex]: images.length === 1
    });

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        readOnly={readOnly}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        selectedBlock={selectedBlock}
        fullWidth
        noPadding
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div className={contentClass} onContextMenu={ContextMenu.handleBlockContextMenu}>
            {this.renderContent(this.getCaretColor(caret))}
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}
