import React, { useRef } from "react";
import styles from "./People.module.scss";
import cx from "classnames";
import IconHide from "./../Views/icons/icon-eye-disabled";
import IconFace from "components/Icons/Face";
import colors from "utilities/colors";
import ContextMenu from "utilities/contextMenu";
import Scrollbars from "react-custom-scrollbars";
import { COLLECTION } from "utilities/constant";

export interface IPersonFilter {
  url: string;
  personId: string;
}

export interface IPeopleProps {
  peopleImgs: IPersonFilter[];
  selectedPeople: string[];
  iconMoreOptions: string;
  iconEdit: string;
  accentColor: string;
  bgColor: string;
  titlesColor: string;
  opacity: ".4" | "1";
  selectPerson: (id: string) => void;
  deselectPerson: (id: string) => void;
  padding?: string;
  isEditor?: boolean;
  reducedContent?: boolean;
}

const People: React.FC<IPeopleProps> = props => {
  const ref = useRef<HTMLDivElement>(null);

  const getBackGroundColor = () => {
    let result = colors.whiteGray;
    switch (props.bgColor.toLowerCase()) {
      case colors.white:
        result = colors.whiteGray;
        break;
      case colors.creme:
        result = colors.cremeFacialBackground;
        break;
      case colors.grayNav:
        result = colors.grayFacialBackground;
        break;
      case colors.black:
        result = colors.inputDark;
        break;
    }
    return result;
  };

  const handlePersonClick = (id: string, isSelected: boolean) => {
    if (isSelected) {
      props.deselectPerson(id);
    } else {
      props.selectPerson(id);
    }
  };

  const showFilters = () => {
    let pos = 0;
    const frame = () => {
      if (ref.current) {
        if (pos >= 108) {
          ref.current.style.height = 108 + "px";
          ref.current.style.marginTop = 20 + "px";
        } else {
          pos += 6;
          ref.current.style.height = pos + "px";
          ref.current.style.marginTop = 20 + "px";
          window.requestAnimationFrame(frame);
        }
      }
    };

    window.requestAnimationFrame(frame);
  };

  const hideFilters = () => {
    let pos = 108;
    const frame = () => {
      if (ref.current) {
        if (pos <= 0) {
          ref.current.style.height = 0 + "px";
          ref.current.style.marginTop = 0 + "px";
        } else {
          pos = pos - 6;
          ref.current.style.height = pos + "px";
          ref.current.style.marginTop = 0 + "px";
          window.requestAnimationFrame(frame);
        }
      }
    };

    window.requestAnimationFrame(frame);
  };

  const handleHideClick = () => {
    if (ref.current && !props.isEditor) {
      if (ref.current.style.height === "108px") {
        hideFilters();
      } else {
        showFilters();
      }
    }
  };

  return (
    <div className={cx({ [styles.reducedContent]: props.reducedContent })}>
      <div className={styles.bar} style={{ paddingLeft: props.padding || "", paddingRight: props.padding || "" }}>
        <p className={cx(styles.title)} style={{ color: props.titlesColor }}>
          {COLLECTION.ALL_MEDIA}
        </p>
        <div className={styles.faceIcon} onClick={handleHideClick}>
          <span className={cx(styles.iconLabel)} style={{ color: props.titlesColor }}>
            {COLLECTION.ICON_LABEL}
          </span>
          <IconFace color={props.titlesColor} width={35} height={25} minX={0} minY={0} opacity={props.opacity} />
        </div>
      </div>
      <div
        className={styles.layout}
        style={{
          backgroundColor: getBackGroundColor(),
          height: !props.isEditor ? "108px" : "0px",
          paddingLeft: props.padding || "0px",
          paddingRight: props.padding || "0px",
          marginTop: "20px"
        }}
        ref={ref}
      >
        <Scrollbars autoHide={true} className={styles.peopleContainer}>
          <div className={styles.wrapper}>
            <div className={styles.containerFaces}>
              {props.peopleImgs.map((img: IPersonFilter, idx: number) => {
                const isSelected = props.selectedPeople.includes(img.personId);
                return (
                  <div
                    className={cx(styles.face, { [styles.selected]: isSelected })}
                    key={img.personId}
                    onClick={e => handlePersonClick(img.personId, isSelected)}
                    onContextMenu={ContextMenu.handleBlockContextMenu}
                  >
                    <div className={styles.faceEditMenu}>
                      <div className={styles.faceMoreOptionsDots}>
                        <img src={props.iconMoreOptions} width="13" />
                      </div>

                      <div className={styles.faceMoreOptionsMenu}>
                        <div className={styles.arrowBox} />
                        <ul>
                          <li>
                            <a href="#">
                              <span>
                                <img src={props.iconEdit} width="25" />
                              </span>
                              Edit Details
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span>
                                <IconHide color={props.accentColor} />
                              </span>
                              Hide
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className={styles.faceImage}
                      style={{
                        backgroundImage: `url(${img.url})`
                      }}
                    >
                      {/* <strong>Name</strong> */}
                      <div className={styles.faceAddData}>
                        <div className={styles.arrowBox} />
                        <div className={styles.addForm}>
                          <div className={styles.addFormRow}>
                            <input type="text" placeholder="Add name" />
                          </div>
                          <div className={styles.addFormRow}>
                            <input type="text" placeholder="Add email" />
                          </div>
                          <div className={styles.addFormRow + " " + styles.addAction}>
                            <button>Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default People;
