import { default as cx } from "classnames";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Radio, RadioGroup } from "react-radio-group";
import colors from "utilities/colors";
import { Utilities } from "utilities/utilities";
import { IBookMePackage, IPackage } from "../index";
import ServiceNote from "../service/bookMe/ServiceNotes";
import { isFreeService } from "../service/bookMe/ServiceNotes/helper";
import styles from "./styles.module.scss";

export interface IServiceRadioGroupProps {
  style?: React.CSSProperties;
  packages: IPackage[];
  uniqueRadioGroupName: string;
  color: string;
  layout: string;
  showImage: boolean;
  isEditionView?: boolean;
  setSelectedPackName: (value: string) => void;
  backgroundColor?: string;
  onUpdateValidate: (packageId: string, state: boolean) => void;
  bookMePhotographerId?: string;
  formatPrice: (value: number) => string;
}

interface IPackageItem {
  alt: string;
  className?: string;
  include: string;
  color: string;
  layout: string;
}

const TickGreen = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9">
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M9.352.313a1.007 1.007 0 0 1 1.41-.048l-.017-.016a.983.983 0 0 1 .055 1.394L4.785 8.092A1.177 1.177 0 0 1 3.01 8.07L.252 4.96A1.015 1.015 0 0 1 .323 3.55l-.016.015a.969.969 0 0 1 1.385.065l1.863 2.09c.182.203.482.208.67.01L9.351.313z"
      />
    </svg>
  );
};

const getMarginStyle = (layout: string) => {
  return {
    marginLeft: layout === "A" ? "-2px" : "3px",
    padding: layout === "A" ? "0px 5px" : "0px"
  } as React.CSSProperties;
};

export const PackageItem: React.FC<IPackageItem> = props => {
  return (
    <React.Fragment>
      {props.include && (
        <div style={getMarginStyle(props.layout)} className={cx(styles.packItem, props.className)}>
          <div className={styles.tick}>
            <TickGreen color={props.color} />
          </div>
          <div className={styles.itemText}>{props.include}</div>
        </div>
      )}
    </React.Fragment>
  );
};

const ServiceRadioGroup: React.FC<IServiceRadioGroupProps> = props => {
  const initialSelected = props.packages.find(pkg => pkg.isSelected && pkg.isVisible);
  const [selectedValue, setSelectedValue] = useState(
    initialSelected ? initialSelected.id : props.packages.length > 0 ? props.packages[0].id : ([] as IPackage[])
  );
  const [packs, setPacks] = useState(props.packages);
  const [showDetailsForPackIds, setShowDetailsForPackIds] = useState({} as any);

  const containerRef = useRef<HTMLDivElement>(null);

  const validateRadioSelection = (color: string) => {
    if (containerRef && containerRef.current) {
      const radioButtons = containerRef.current.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;

      for (let i = 0; i < radioButtons.length; i++) {
        const radioButton = radioButtons[i];

        if (radioButton !== undefined && radioButton.parentElement !== undefined) {
          const parentElement = radioButton.parentElement;

          if (radioButton && parentElement) {
            if (radioButton.type == "checkbox") {
              continue;
            }

            parentElement.style.backgroundColor = radioButton.checked ? color : "#FFFFFF";
            parentElement.getElementsByTagName("div")[0].style.display = radioButton.checked ? "block" : "none";
          }
        }
      }
    }
  };

  const setRadioValue = (pkgId: string) => {
    if (containerRef && containerRef.current) {
      const radioButtons = containerRef.current.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;

      if (radioButtons.length > 0) {
        const radioElement = Array.from(radioButtons).find(rb => rb.value === pkgId);
        if (radioElement) {
          radioElement.checked = true;
        }
      }
    }
  };

  const validateOneChecked = (packages: IPackage[]) => {
    let result: IPackage[] = [...packages];
    let isHidden = false;
    result.forEach((pkg: IPackage) => {
      if ((pkg.isSelected && !pkg.isVisible) || result.filter((pkg: IPackage) => pkg.isSelected).length === 0) {
        isHidden = true;
        pkg.isSelected = false;
      }
    });

    if (isHidden) {
      const visiblePkg = result.find((pkg: IPackage) => pkg.isVisible);
      if (visiblePkg) {
        visiblePkg.isSelected = true;
      }
    }
    return result;
  };

  const handleChange = (value: any, color: string, packs: IPackage[]) => {
    setSelectedValue(value);

    let newPacks = [...packs];
    newPacks.forEach((pack: IPackage) => {
      pack.isSelected = value === pack.id;
    });

    setPacks(newPacks);
    validateRadioSelection(color);
    props.setSelectedPackName(newPacks.filter(pack => pack.isSelected)[0].title);
  };

  useEffect(() => {
    setPacks(validateOneChecked(props.packages));

    if (initialSelected && initialSelected.id !== selectedValue) {
      setRadioValue(initialSelected.id);
    }

    if (initialSelected) {
      setSelectedValue(initialSelected.id);
    }

    validateRadioSelection(props.color);
  }, [props.color, props.packages, selectedValue, initialSelected]);

  const bookMe = {
    renderPriceAndDuration: (pack: IBookMePackage, isLayoutB: boolean) => {
      return (
        <div className={cx(styles.priceAndDuration, { [styles.layoutB]: isLayoutB })}>
          <span>{Utilities.renderDurationAsStringLong(pack.duration)}</span>
          <span className={styles.price}>{props.formatPrice(pack.price)}</span>
        </div>
      );
    },
    handleDetailsToggleClick: (packId: string, value: boolean) => {
      setShowDetailsForPackIds({
        ...showDetailsForPackIds,
        [packId]: value
      });
    }
  };

  const isBookMe = !!props.bookMePhotographerId;
  const isLayoutA = props.layout === "A";
  const isLayoutB = props.layout === "B";

  return (
    <div className={styles.zenContainerServiceRadioGroup} style={props.style ? props.style : {}} ref={containerRef}>
      <RadioGroup
        name={`radio-${props.uniqueRadioGroupName}`}
        selectedValue={selectedValue}
        onChange={event => {
          handleChange(event, props.color, packs);
        }}
      >
        <div style={{ width: props.showImage ? "100%" : "70%" }} className={styles.packagesContainer}>
          {packs
            .filter(p => p.isVisible)
            .map((pack: IPackage, i: number) => {
              const packAsBookme = isBookMe ? (pack as IBookMePackage) : null;
              const hasBookmePackage =
                packAsBookme && !_.isEmpty(packAsBookme.packageName) && !_.isEmpty(packAsBookme.packageDetails);
              const hasIncludes = !_.isEmpty(pack.includes);
              const hasDetails = hasIncludes || hasBookmePackage;
              const showDetailsForPack = showDetailsForPackIds[pack.id];
              const isUserCheckboxRequired = isBookMe && !isFreeService(packAsBookme!) && packAsBookme!.useDeposit;
              const hideShowNeverClicked = showDetailsForPack === undefined;
              const showDetailsAutomatically = isUserCheckboxRequired && pack.isSelected && hideShowNeverClicked;
              const isDetailsDisplayed = isBookMe ? !!showDetailsForPack || showDetailsAutomatically : pack.isSelected;
              return (
                <React.Fragment key={`pack-${i}`}>
                  <div className={`servicePack-${i}`}>
                    <div className={styles.header}>
                      <div className={styles.radioButton}>
                        <span className={styles.checkmark}>
                          <Radio value={pack.id} />
                          <div
                            style={{
                              backgroundColor:
                                props.backgroundColor === colors.black || props.backgroundColor === colors.grayNav
                                  ? colors.black
                                  : colors.white
                            }}
                            className={styles.checkmarkDot}
                          />
                        </span>
                      </div>
                      <span className={styles.radioText}>
                        <span className={cx(styles.bold, { [styles.clickable]: props.isEditionView })}>
                          {pack.title}
                        </span>
                        {!isBookMe && (
                          <>
                            {" "}
                            -{" "}
                            <span className={cx(styles.bold, { [styles.clickable]: props.isEditionView })}>
                              {pack.price ? props.formatPrice(pack.price) : "Contact for Pricing "}
                            </span>
                          </>
                        )}
                      </span>
                      {isLayoutB && isBookMe && bookMe.renderPriceAndDuration(packAsBookme!, isLayoutB)}
                    </div>
                    {isLayoutA && isBookMe && bookMe.renderPriceAndDuration(packAsBookme!, isLayoutB)}
                    {isBookMe && hasDetails && (
                      <div
                        onClick={() => bookMe.handleDetailsToggleClick(pack.id, !isDetailsDisplayed)}
                        className={styles.detailsToggle}
                      >
                        {isDetailsDisplayed ? "Hide Details" : "Show Details"}
                      </div>
                    )}
                    <div className={styles.details}>
                      <div
                        className={cx(styles.container, {
                          [styles.layoutB]: isLayoutB,
                          [styles.bookMe]: isBookMe,
                          [styles.hidden]: !isDetailsDisplayed
                        })}
                      >
                        <div className={cx(styles.items, { [styles.layoutB]: isLayoutB })}>
                          {hasIncludes && (
                            <div
                              className={cx(styles.includes, {
                                [styles.layoutB]: isLayoutB,
                                [styles.only]: !hasBookmePackage
                              })}
                            >
                              {hasBookmePackage && <div className={styles.subheader}>Included with this service</div>}
                              {pack.includes.map((include, index) => {
                                return (
                                  <PackageItem
                                    key={index}
                                    className={cx({ [styles.clickable]: props.isEditionView })}
                                    alt={`includes${index + 1}`}
                                    include={include}
                                    color={props.color}
                                    layout={props.layout}
                                  />
                                );
                              })}
                            </div>
                          )}
                          {hasBookmePackage && (
                            <div className={styles.bookmePackage}>
                              <div className={cx(styles.subheader, { [styles.second]: hasIncludes && !isLayoutB })}>
                                Includes {packAsBookme!.packageName}
                              </div>
                              {packAsBookme!.packageDetails!.map((details, index) => {
                                return (
                                  <PackageItem
                                    key={index}
                                    className={cx({ [styles.clickable]: props.isEditionView })}
                                    alt={`packageDetails${index + 1}`}
                                    include={details}
                                    color={props.color}
                                    layout={props.layout}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                        {isBookMe && (
                          <ServiceNote
                            accentColor={props.color}
                            service={packAsBookme!}
                            layoutB={isLayoutB}
                            formatPrice={props.formatPrice}
                            onUpdateValidate={props.onUpdateValidate}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </RadioGroup>
    </div>
  );
};

export default ServiceRadioGroup;
