import colors from "utilities/colors";

export class IGetPictureStyle {
  borderWidth: number = 0;
  borderColor: string = "";
}

export function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

export const getFontColor = (backgroundColor?: string) => {
  if (backgroundColor) {
    const background = backgroundColor.toLowerCase();

    switch (background) {
      case colors.white:
        return colors.grayTextNav;
      case colors.creme:
        return colors.grayTextNav;
      case colors.grayNav:
      case colors.black:
        return colors.grayLightTextNav;
      default:
        return "";
    }
  }

  return "";
};

export const getBackgroundColor = (backgroundColor?: string) => {
  if (backgroundColor) {
    const background = backgroundColor.toLowerCase();

    switch (background) {
      case colors.white:
        return colors.whiteInput;
      case colors.creme:
        return colors.creamInput;
      case colors.grayNav:
        return colors.grayTextNav;
      case colors.black:
        return colors.grayNav;
      default:
        return "";
    }
  }

  return "";
};

export const getOptionsColor = (backgroundColor?: string) => {
  if (backgroundColor) {
    const background = backgroundColor.toLowerCase();

    switch (background) {
      case colors.white:
      case colors.creme:
        return colors.grayTextNav;
      case colors.grayNav:
      case colors.black:
        return colors.grayLightTextNav;
      default:
        return "";
    }
  }

  return "";
};

export const getPlaceholderColor = (backgroundColor?: string) => {
  let result = colors.lightPlaceholder;

  if (backgroundColor) {
    const background = backgroundColor.toLowerCase();

    switch (background) {
      case colors.white:
      case colors.creme:
        result = colors.lightPlaceholder;
        break;
      case colors.grayNav:
        result = colors.grayLightTextNav;
        break;
      case colors.black:
        result = colors.grayTextNav;
        break;
      default:
        result = colors.lightPlaceholder;
        break;
    }
  }

  return result;
};

export const checkIsAccentCase = (backgroundType: string | undefined, backgroundColor: string | undefined) => {
  let result: any = backgroundType && backgroundType !== "none" && backgroundColor && backgroundColor === "accent";
  return result;
};

export const checkBgColor = (bgColor: string) => {
  let result: string = "";
  switch (bgColor) {
    case colors.white:
    case colors.creme:
      result = colors.grayTextNav;
      break;
    case colors.grayNav:
    case colors.black:
      result = colors.grayLightTextNav;
      break;
  }
  return result;
};

export const checkIsWhiteTheme = (templateColor: string | undefined) => {
  return (
    (templateColor && templateColor.toLowerCase() === colors.white) ||
    (templateColor && templateColor.toLowerCase() === colors.creme)
  );
};

export const getBlockTextColor = (
  backgroundColor: string | undefined,
  accentColor: string,
  backgroundType: string | undefined = undefined,
  templateColor: string | undefined = undefined
): string => {
  let isAccent = false;

  isAccent = checkIsAccentCase(backgroundType, backgroundColor);

  if (backgroundType === "none" || !backgroundColor) {
    const bgColor = templateColor ? templateColor.toLowerCase() : "";
    return checkBgColor(bgColor);
  } else {
    const bgColor = backgroundColor ? backgroundColor.toLowerCase() : "";
    return isAccent ? (checkIsWhiteTheme(templateColor) ? colors.grayNav : colors.lighGrey) : checkBgColor(bgColor);
  }
};

export const getBackGroundTile = (color: string) => {
  let result = "#FAFAFA";
  switch (color.toLowerCase()) {
    case colors.white:
      result = "#FAFAFA";
      break;
    case colors.creme:
      result = "#F7F7EF";
      break;
    case colors.grayNav:
      result = "#2c2c2c";
      break;
    case colors.black:
      result = colors.grayNav;
      break;
  }
  return result;
};

export const getPictureStyle = (
  style: string | undefined,
  templateBackgroundColor: string | undefined,
  blockBackgroundColor: string | undefined
): IGetPictureStyle => {
  let backgroundColor = blockBackgroundColor ? blockBackgroundColor : templateBackgroundColor;
  let result = new IGetPictureStyle();
  if (backgroundColor) backgroundColor = backgroundColor.toLowerCase();

  if (style && style === "stroke") {
    result.borderWidth = 1;
    switch (backgroundColor) {
      case colors.white:
        result.borderColor = colors.graySuperLight;
        break;
      case colors.creme:
        result.borderColor = colors.creamInput;
        break;
      case colors.grayNav:
        result.borderColor = colors.grayLightTextNav;
        break;
      case colors.black:
        result.borderColor = colors.grayLightTextNav;
        break;
      default:
        result.borderColor = colors.grayLightTextNav;
        break;
    }
  } else {
    switch (style) {
      case "frameSmall":
        result.borderWidth = 8;
        break;
      case "frameMedium":
        result.borderWidth = 16;
        break;
      case "frameLarge":
        result.borderWidth = 24;
        break;
    }
    result.borderColor = colors.white;
  }
  return result;
};
