import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js/min";
import _ from "lodash";

import { ICartItem } from "../../models/cart";
import { IAddress } from "../../models/location";
import { ActionStatus, ICurrency } from "../../models/models";
import { IQuickShopItem } from "../../models/pricelist";
import { cartTypes } from "../constant";
import { CURRENCIES, DEFAULT_CURRENCY } from "../currency";

export abstract class Utilities {
  public static iso3166DefaultCountryCode: CountryCode = "US";
  public static iso3166UKCode: CountryCode = "GB";
  public static iso3166CanadaCode: CountryCode = "CA";
  public static iso3166AustraliaCode: CountryCode = "AU";

  public static demoStripeAccountId: string = "acct_demo";

  public static scrollTo(x: number, y: number) {
    const parentPage = (window as any).parentIFrame;

    if (parentPage) {
      parentPage.scrollToOffset(x, y);
    } else {
      window.scrollTo(x, y);
    }
  }

  public static scrollTop() {
    const parentPage = (window as any).parentIFrame;

    if (parentPage) {
      parentPage.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }

  // https://github.com/jquery/jquery-ui/blob/main/ui/scroll-parent.js
  public static getScrollParent(
    element: HTMLElement,
    includeHidden: boolean = false,
    includeStaticParent: boolean = false
  ): HTMLElement {
    const style = getComputedStyle(element);
    const position = style.position;
    const excludeStaticParent = !includeStaticParent && position === "absolute";
    const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    function isScrollParent(element: HTMLElement) {
      const style = getComputedStyle(element);
      if (excludeStaticParent && style.position === "static") {
        return false;
      } else {
        return overflowRegex.test(style.overflow + style.overflowY + style.overflowX);
      }
    }

    let parent: HTMLElement | null = element.parentElement;
    while (parent && !isScrollParent(parent)) {
      parent = parent.parentElement;
    }

    return position === "fixed" || !parent ? document.body : parent;
  }

  /**
   * Similar to built-in *scrollIntoView*, but only scrolls vertically (which actually might be required in some
   * cases) and has the ability to add a padding between scroll edge and the *element*.
   * @param element The element to scroll to.
   * @param align Similar to *scrollIntoView's* *alignToTop* option.
   * @param padding An optional padding between scroll edge and the *element*.
   * @param scrollParentOptions Options for finding scroll parent. See *getScrollParent* function.
   */
  public static scrollToElementV(
    element: HTMLElement,
    align: "top" | "bottom",
    padding: number = 0,
    scrollParentOptions?: {
      includeHidden?: boolean;
      includeStaticParent?: boolean;
    }
  ) {
    const parent = this.getScrollParent(
      element,
      scrollParentOptions?.includeHidden,
      scrollParentOptions?.includeStaticParent
    );
    const elementRect = element.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();
    const top = elementRect.top - parentRect.top + parent.scrollTop;

    if (align === "top") {
      parent.scrollTo({ top: top - padding });
    } else {
      parent.scrollTo({
        top: top - parent.clientHeight + element.offsetHeight + padding
      });
    }
  }

  public static trim(str: string | null | undefined) {
    return _.trim(str || "");
  }

  public static parsePhoneNumber(
    str: string | null | undefined,
    iso3166CountryCode: CountryCode = Utilities.iso3166DefaultCountryCode
  ) {
    if (str) {
      try {
        return parsePhoneNumberFromString(str, iso3166CountryCode) || null;
        // tslint:disable-next-line:no-empty
      } catch (e) {}
    }

    return null;
  }

  public static formatPhoneNumber(
    str: string | null | undefined,
    iso3166CountryCode: CountryCode = Utilities.iso3166DefaultCountryCode
  ) {
    const phoneNumber = Utilities.parsePhoneNumber(str, iso3166CountryCode);

    return phoneNumber ? phoneNumber.formatNational() : "";
  }

  public static isFractionalNumber(value: number | null | undefined) {
    return value != null && value > Math.floor(value);
  }

  public static formatNumber(value: number, culture: string = "en-US") {
    return this.formatNumberImpl(value, culture, this.isFractionalNumber(value));
  }

  public static formatFractionalNumber(value: number, culture: string = "en-US") {
    return this.formatNumberImpl(value, culture, true);
  }

  private static formatNumberImpl(value: number, culture: string, fractional: boolean) {
    return value.toLocaleString(culture, {
      minimumFractionDigits: fractional ? 2 : 0
    });
  }

  public static formatNumberWithCurrency(
    value: number,
    countryCode?: string,
    currencyCode?: string,
    hasAbbreviation?: boolean
  ) {
    return this.formatNumberImplWithCurrency(
      value,
      countryCode,
      currencyCode,
      this.isFractionalNumber(value),
      hasAbbreviation
    );
  }

  public static formatFractionalNumberWithCurrency(
    value: number,
    countryCode?: string,
    currencyCode?: string,
    hasAbbreviation?: boolean
  ) {
    return this.formatNumberImplWithCurrency(value, countryCode, currencyCode, true, hasAbbreviation);
  }

  public static getCultureInfo(countryCode: string | undefined) {
    return this.getCurrencyFormattingInfo(countryCode).culture;
  }

  public static getCurrencyFormattingInfo(countryCode: string | undefined) {
    switch (countryCode?.toUpperCase()) {
      case Utilities.iso3166UKCode:
        return { culture: "en-GB", currencyCode: "GBP" };
      case Utilities.iso3166CanadaCode:
        return { culture: "en-CA", currencyCode: "CAD" };
      case Utilities.iso3166AustraliaCode:
        return { culture: "en-AU", currencyCode: "AUD" };
      default:
        return { culture: "en-US", currencyCode: "USD" };
    }
  }

  private static formatNumberImplWithCurrency(
    value: number,
    countryCode: string | undefined,
    currencyCode: string | undefined,
    fractional: boolean,
    hasAbbreviation: boolean | undefined
  ) {
    const culture = this.getCultureInfo(countryCode);
    const currency = currencyCode
      ? this.getCurrencyInfo(currencyCode).code
      : this.getCurrencyFormattingInfo(currencyCode).currencyCode;

    const currencySymbol = Utilities.getCurrencyInfo(currencyCode).symbol;
    const abbr = hasAbbreviation && currencySymbol === "$" ? `${currencyCode} ` : "";

    const formattedNumber = new Intl.NumberFormat(culture, {
      maximumFractionDigits: 2,
      minimumFractionDigits: fractional ? 2 : 0,
      style: "currency",
      currency: currency,
      currencyDisplay: "symbol"
    }).format(value);

    return `${abbr}${formattedNumber}`;
  }

  public static combineRefs = <T extends any>(...refs: Array<React.Ref<T> | undefined>): React.Ref<T> => (element: T) =>
    refs.forEach(ref => {
      if (!ref) {
        return;
      }

      if (typeof ref === "function") {
        return ref(element);
      }

      (ref as any).current = element;
    });

  public static combineRefsCached = _.memoize(<T extends any>(componentRef: React.Ref<T>) =>
    _.memoize((propsRef?: React.Ref<T>) => Utilities.combineRefs(propsRef, componentRef))
  );

  public static setRef<T>(ref: React.Ref<T> | undefined, instance: T | null) {
    if (ref) {
      if (_.isFunction(ref)) {
        ref(instance);
      } else {
        (ref as any).current = instance;
      }
    }
  }

  public static limitNumber(value: number, min?: number, max?: number): number;
  public static limitNumber(value: number | null, min?: number, max?: number): number | null;
  public static limitNumber(value: number | null, min?: number, max?: number) {
    value = max !== undefined && (value || 0) > max ? max : value;
    value = min !== undefined && (value || 0) < min ? min : value;
    return value;
  }

  public static isGifImage(fileName: string | undefined) {
    return !!fileName && fileName.toLowerCase().endsWith(".gif");
  }

  public static isPngImage(fileName: string | undefined) {
    return !!fileName && fileName.toLowerCase().endsWith(".png");
  }

  public static shouldComponentUpdateDeep<TProps, TState>(
    component: React.Component<TProps, TState>,
    nextProps: TProps,
    nextState?: TState
  ) {
    return !_.isEqual(component.props, nextProps) || (!!nextState && !_.isEqual(component.state, nextState));
  }

  public static hasActionCompleted(prevStatus: ActionStatus, currentStatus: ActionStatus) {
    return prevStatus === "Pending" && (currentStatus === "Success" || currentStatus === "Error");
  }

  public static isEnterKey(evt: KeyboardEvent) {
    return evt.key === "Enter" && (!evt.target || (evt.target as Element).tagName !== "TEXTAREA");
  }

  public static getFullAddress(address: IAddress) {
    const result: string[] = [];

    function addAddressComponent(addressComponent: string | null, separator?: string) {
      if (addressComponent) {
        if (result.length > 0 && separator) {
          result.push(separator);
        }

        result.push(addressComponent);
      }
    }

    addAddressComponent(address.houseNumber);
    addAddressComponent(address.streetName, " ");
    addAddressComponent(address.city, ", ");
    addAddressComponent(address.state, ", ");
    addAddressComponent(address.zipCode, " ");
    addAddressComponent(address.country, ", ");

    return result.join("");
  }

  public static isBasicAddress(address: IAddress): boolean {
    return !!(address.country && address.latitude != null && address.longitude != null);
  }

  public static isAddressWithStreet(address: IAddress): boolean {
    return !!(address.city && address.state && address.zipCode && Utilities.isBasicAddressWithStreet(address));
  }

  public static isCompleteAddress(address: IAddress): boolean {
    return !!address.houseNumber && Utilities.isAddressWithStreet(address);
  }

  public static isBasicAddressWithStreet(address: IAddress): boolean {
    return !!(address.streetName && Utilities.isBasicAddress(address));
  }

  public static isBasicAddressWithHouse(address: IAddress): boolean {
    return !!(address.houseNumber && Utilities.isBasicAddressWithStreet(address));
  }

  // https://github.com/Zenfolio/zf-utilities/blob/develop/Zenfolio.Common.Utility/HtmlUtils.cs#L30
  public static highlightLinksInText(text: string | null | undefined): string {
    text = Utilities.trim(text);
    if (!text) {
      return text;
    }

    // https://stackoverflow.com/a/17773849
    const urlRegExp = new RegExp(
      "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})",
      "gm"
    );

    let result = "";
    let startIndex = 0;

    while (true) {
      const match = urlRegExp.exec(text);
      if (match) {
        const endIndex = match.index;
        result += _.escape(text.substring(startIndex, endIndex));

        const matchValue = match[1];
        const matchValueWithoutPunctuation = _.trimEnd(matchValue, ".,\"'`-–?:!;");

        let hrefStr = matchValueWithoutPunctuation;
        if (hrefStr.indexOf("www") === 0) {
          hrefStr = "http://" + hrefStr;
        }

        result += `<a href="${_.escape(hrefStr)}" target="_blank" rel="noopener noreferrer">${_.escape(
          matchValueWithoutPunctuation
        )}</a>`;

        if (matchValueWithoutPunctuation.length < matchValue.length) {
          result += _.escape(matchValue.substring(matchValueWithoutPunctuation.length));
        }

        startIndex = endIndex + matchValue.length;
      } else {
        if (startIndex < text.length) {
          result += _.escape(text.substring(startIndex));
        }

        break;
      }
    }

    return result.replace(/\r\n?|\n/g, "<br />");
  }

  public static renderDurationAsString(durationInMin: number, hrUnit: string, minUnit: string) {
    const durationHours = Math.floor(durationInMin / 60);
    const durationMinutes = durationInMin % 60;

    let durationAsString = durationHours > 0 ? durationHours + hrUnit : "";
    if (durationMinutes > 0) {
      durationAsString += (durationAsString ? " " : "") + durationMinutes + minUnit;
    }

    return durationAsString;
  }

  public static renderDurationAsStringLong(durationInMin: number) {
    return Utilities.renderDurationAsString(durationInMin, " hr", " min");
  }

  public static placeCaretAtStart(element: HTMLInputElement | HTMLTextAreaElement) {
    if (document.activeElement !== element) {
      element.focus();
    }

    if (typeof element.selectionStart === "number") {
      element.selectionStart = element.selectionEnd = 0;
    }
  }

  public static placeCaretAtEnd(element: HTMLInputElement | HTMLTextAreaElement) {
    if (document.activeElement !== element) {
      element.focus();
    }

    if (typeof element.selectionStart === "number") {
      element.selectionStart = element.selectionEnd = element.value.length;
    }
  }

  public static selectAll(element: HTMLInputElement | HTMLTextAreaElement) {
    if (document.activeElement !== element) {
      element.focus();
    }

    if (typeof element.selectionStart === "number") {
      element.selectionStart = 0;
      element.selectionEnd = element.value.length;
    }
  }

  public static resolveValue<TValue>(...values: (TValue | undefined)[]) {
    return _.find(values, v => v !== undefined) as TValue;
  }

  public static parseMessageEventData(event: MessageEvent) {
    let data: any;

    if (typeof event.data === "string") {
      try {
        data = JSON.parse(event.data);
      } catch (ex) {
        data = null;
      }
    } else {
      data = event.data;
    }

    return data;
  }

  public static getTransitionClassNames(styles: Record<string, string>, transitionName: string) {
    return _.chain(styles)
      .pickBy((v, k) => k.startsWith(transitionName))
      .mapKeys((v, k) => _.camelCase(k.substr(transitionName.length)))
      .value();
  }

  public static isHorizontal(width: number, height: number) {
    return width > height;
  }

  public static useKilometers(countryCode: string | null | undefined) {
    return !!countryCode && countryCode.toUpperCase() !== Utilities.iso3166DefaultCountryCode;
  }

  public static getCurrencyInfo(currencyCode: string | null | undefined): ICurrency {
    return (currencyCode && CURRENCIES[currencyCode.toLowerCase()]) || DEFAULT_CURRENCY;
  }

  /**
   * Rotates the specified array for the specified number of times in the specified direction,
   * and returns the result as a new array.
   * @param array The array to rotate.
   * @param steps The number of rotations and their direction (negative values mean rotation
   * to the left, positive - to the right).
   * @returns The new rotated array.
   */
  public static rotateArray<T>(array: T[], steps: number) {
    const len = array.length;
    const ret = new Array(len);
    for (let i = 0; i < len; i++) {
      ret[i] = array[(i - steps + len) % len];
    }
    return ret;
  }

  public static preciseRound = (value: number, decimals: number) => {
    const temp = Math.pow(10, decimals);
    return (
      Math.round(value * temp + (decimals > 0 ? 1 : 0) * (Math.sign(value) * (10 / Math.pow(100, decimals)))) / temp
    ).toFixed(decimals);
  };

  public static roundingTwoDecimalsNumber = (number: number) => {
    return parseFloat(Utilities.preciseRound(number, 2));
  };

  public static checkQuickShopEnabled = (items: IQuickShopItem[] | undefined) => {
    return items && items.length > 0;
  };

  public static getAllCartPhotos = (cartItems: ICartItem[] | []) => {
    let addedPhotos: string[] = [];
    cartItems.forEach((cart: any) => {
      switch (cart.type) {
        case cartTypes.PACKAGE: {
          if (Array.isArray(cart?.productSnapshots)) {
            const photoIds = cart?.productSnapshots?.map((snapshot: any) => snapshot.photoId);
            addedPhotos = [...addedPhotos, ...photoIds];
          }
          if (Array.isArray(cart?.package?.physicalPackageItems)) {
            const photos = _.flatten(
              cart?.package?.physicalPackageItems?.map((physical: any) => physical.selectedPhotos)
            );
            const photoIds = photos?.map((p: any) => p.id) || [];
            addedPhotos = [...addedPhotos, ...photoIds];
          }
          break;
        }
        case cartTypes.DIGITAL: {
          const photos = _.flatten(cart?.productSnapshots?.map((snapshot: any) => snapshot.photos));
          if (Array.isArray(photos)) {
            const photoIds = photos?.map((p: any) => p.photoId) || [];
            addedPhotos = [...addedPhotos, ...photoIds];
          }
          break;
        }
        case cartTypes.PHYSICAL: {
          const photoId = cart?.physicalProductSnapshots?.selectedPhoto?.originalPhoto?.id;
          addedPhotos = [...addedPhotos, photoId];
          break;
        }
        default:
          break;
      }
    });
    return addedPhotos;
  };

  public static getAllCartVideos = (cartItems: ICartItem[] | []) => {
    // TODO: Add support for videos.
    return [];
  };

  public static addLeadingZeros = (num: number, totalLength: number): string => {
    let count = 3;

    const total = String(totalLength);
    if (total.length > count) {
      count = total.length;
    }

    return String(num).padStart(count, "0");
  };

  public static get isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;
  }
}
