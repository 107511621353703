enum Unit {
  none,
  inch,
  cm,
}
const INCH_TO_CM_VALUE = 2.54;
const REGEX = /([+-]?([0-9]*[.])?[0-9]+x[+-]?([0-9]*[.])?[0-9]+)/gm;
const UNIT_ICONS = {
  [Unit.none.toString()]: '',
  [Unit.inch.toString()]: '"',
  [Unit.cm.toString()]: "cm",
};

const convertCentermeterToInches = (value: number) => {
  return Math.round((value / INCH_TO_CM_VALUE) * 4) / 4;
};

const convertInchesToCentermeter = (value: number) => {
  return Math.round((value * INCH_TO_CM_VALUE) * 2) / 2;
};

const formatName = (value: number, unit: Unit) => {
  if(unit === Unit.inch) return value;
  return convertInchesToCentermeter(value);
}

const convertUnit = (value: number = 0, unit: Unit = Unit.none) => {
  if(unit === Unit.inch) return convertInchesToCentermeter(value);
  return convertCentermeterToInches(value);
};

const roundNearestForUnit = (value: number = 0, unit: Unit = Unit.none) => {
  if(unit === Unit.inch) return Math.round(value * 4) / 4;
  return Math.round(value * 2) / 2;
};

const formatCanvasWithUnit = (value: number = 0, unit: Unit = Unit.none) => {
  if(unit !== Unit.cm) return`${value} Inches`
  return `${formatName(value, unit)} Cm`;
};

const getNameWithUnit = (width: number, height: number, unit: Unit) => {
  return `${formatName(width, unit)}x${formatName(height, unit)}${UNIT_ICONS[unit]}`;
}

const attachUnit = (name: string, unit: Unit) => {
  return `${name}${UNIT_ICONS[unit]}`;
};

const attachUnitForSFF = (name: string, unit: Unit, width: number, height: number) => {
  const basicName = `${roundNearestForUnit(width, unit)}x${roundNearestForUnit(height, unit)}`
  if(name.length > basicName.length) {
    return name.replace(REGEX, basicName + UNIT_ICONS[unit]);
  }
  return attachUnit(name, unit);
};

const formatWithUnit = (name: string = "", width: number, height: number, unit: Unit) => {
  if(unit === Unit.none) return name;
  const basicName = `${width}x${height}`;
  if(!name.includes(basicName)) return name;
  const nameWithUnit = getNameWithUnit(width, height, unit);
  if(name.length > basicName.length) {
    return name.replace(REGEX, nameWithUnit);
  }
  return nameWithUnit;
};

export {
  attachUnit,
  attachUnitForSFF,
  formatWithUnit,
  convertUnit,
  roundNearestForUnit,
  formatCanvasWithUnit,
  Unit,
  UNIT_ICONS
};
