import { IProfileState } from "../../store/profile/state/model";
import { apiType } from "../apiType";
import { apiClient } from "../index";

export interface ILoadProfileStateResponse extends IProfileState {}

export interface IApiProfileState {
  load: () => Promise<ILoadProfileStateResponse>;
}

const api: IApiProfileState = {
  load: () => apiClient.get<ILoadProfileStateResponse>(`${apiType.bookingWidget}photographer/profile/state`)
};

export default api;
