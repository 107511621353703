import React from "react";
import IIConProps from "../IIconProps";

export interface ICaretProps extends IIConProps {
  open: boolean;
  down?: boolean;
}

const Caret: React.FC<ICaretProps> = (props) => {
  if (props.open) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size}
        height={props.size}
        viewBox={`${props.minX} ${props.minY} ${16} ${16}`}
      >
        <path
          fill="none"
          fillRule="evenodd"
          stroke={props.color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12.5 5.75L8 10.25l-4.5-4.5"
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size}
        height={props.size}
        viewBox={`${props.minX} ${props.minY} ${16} ${16}`}
      >
        <g
          transform={`rotate(${props.down ? -90 : 0}) translate${
            props.down ? "(-16 0)" : "(0)"
          }`}
        >
          <path
            fill="none"
            fillRule="evenodd"
            stroke={props.color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6.5 4L11 8.5 6.5 13"
          />
        </g>
      </svg>
    );
  }
};

export default Caret;
