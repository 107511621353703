import React from "react";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import ModalDialog from "../../modalDialog";
import styles from "./index.module.scss";
import { IDisconnect } from "./model";
import withStore from "./withStore";

export interface IDisconnectOwnProps {
  onClose: () => void;
}

export interface IDisconnectProps extends IDisconnectOwnProps {
  disconnect: IDisconnect;
}

class Disconnect extends React.Component<IDisconnectProps> {
  public componentDidUpdate(prevProps: IDisconnectProps) {
    const { disconnect, onClose } = this.props;
    const disconnectHasCompleted = hasActionCompleted(prevProps.disconnect.status, disconnect.status);

    if (disconnectHasCompleted && disconnect.status === "Success") {
      disconnect.onDisconnected();
      onClose();
    }
  }

  public render() {
    const { disconnect } = this.props;
    const disconnectPending = disconnect.status === "Pending";

    return (
      <ModalDialog
        title="SIGN OUT FROM GOOGLE SYNC"
        okText="Yes"
        cancelText="No"
        onCancel={this.onCancel}
        onOk={this.onOk}
        okDisabled={disconnectPending}
        okPending={disconnectPending}
      >
        <div className={styles.container}>
          By disconnecting Google Sync your BookMe events will no longer update or appear on Google Calendar.
          <br />
          <br />
          <br />
          Do you still wish to continue?
        </div>
      </ModalDialog>
    );
  }

  private onCancel = () => this.props.onClose();

  private onOk = () => this.props.disconnect.onDisconnect();
}

export default withStore(Disconnect);
