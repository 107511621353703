import find from "lodash/find";
import React, { PureComponent, RefObject } from "react";
import { isMobile } from "react-device-detect";
import Select from "react-select";
import colors from "../../utilities/colors";

export interface ISelectDropdownOptions {
  value: string | null;
  label: string | null;
}

interface ISelectDropdown {
  placeholder?: string;
  minHeight?: number;
  maxMenuHeight?: number;
  width: string;
  maxWidth?: string;
  options?: any;
  onChange: (value: any) => void;
  isSearchable?: boolean;
  paddingLeft?: number;
  value?: any;
  indicator?: boolean;
  onInputChange?: any;
  icon?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  tabIndex?: string | null;
  openMenuOnFocus?: boolean;
  error?: boolean;
  defaultValue?: any;
  resetValuesFinished?: () => void;
  isReset?: boolean;
  onOptionDisabled?: (option: any) => boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  isDisabled?: boolean;
  autoScroll?: boolean;
  ignoreDefaultFilterOption?: boolean;
  filterOption?: ((option: any, rawInput: string) => boolean) | null;
  menuPortalTarget?: any;
}

interface ISelectDropdownProps {
  viewportWidth: number;
  value: any;
}

const noOptionsMsg = () => null;

// https://github.com/JedWatson/react-select/issues/3648#issuecomment-592622713
export const onSelectMenuOpen = (selectRef: RefObject<Select>, autoScroll?: boolean, onMenuOpen?: () => void) => {
  onMenuOpen?.();
  if (autoScroll === true) {
    const selectedOption = selectRef.current?.select?.state?.selectValue?.[0];
    if (selectedOption) {
      setTimeout(() => {
        if (selectRef.current?.select) {
          const focusedOption = find(
            selectRef.current.select.state.menuOptions.focusable,
            o => o.value === selectedOption.value
          );

          if (focusedOption) {
            selectRef.current.select.scrollToFocusedOptionOnUpdate = false;
            selectRef.current.select.inputIsHiddenAfterUpdate = false;

            selectRef.current.select.setState(
              {
                focusedValue: null,
                focusedOption
              },
              () => {
                const focusedOptionElement = selectRef.current?.select?.focusedOptionRef as any;
                const menuListElement = selectRef.current?.select?.menuListRef as any;
                if (focusedOptionElement && menuListElement) {
                  menuListElement.scrollTop = focusedOptionElement.offsetTop;
                }
              }
            );
          }
        }
      });
    }
  }
};

export class SelectDropdown extends PureComponent<ISelectDropdown, ISelectDropdownProps> {
  public state = {
    value: this.props.value ? (this.props.value.value != null ? this.props.value : null) : null,
    viewportWidth: window.innerWidth
  };

  public componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  public componentDidUpdate(prevProps: ISelectDropdown, prevState: ISelectDropdownProps) {
    const { value, isReset, resetValuesFinished } = this.props;

    if (isReset === prevProps.isReset && value === prevProps.value) {
      return;
    }

    if (isReset) {
      this.setState(
        {
          value: null
        },
        () => {
          if (resetValuesFinished) {
            resetValuesFinished();
          }
        }
      );
    }

    if ((value && (!prevProps.value || value.value !== prevProps.value.value)) || value === 0) {
      this.setState({
        value
      });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth });
  };

  public handleChange = (value: any) => {
    const { onChange } = this.props;
    this.setState({ value });
    onChange(value);
  };

  public render() {
    const icon = () => {
      if (this.props.icon) {
        return {
          alignItems: "center",
          display: "flex",

          ":before": {
            content: `url(${this.props.icon})`,
            display: "block",
            marginRight: 16,
            height: 11,
            width: 11,
            marginTop: -3
          }
        };
      } else {
        return {};
      }
    };
    const isMobileSize = this.state.viewportWidth < 767;
    const borderColor = this.props.error ? colors.redNotification : colors.inputGray;
    const customStyles = {
      control: (provided: any, state: any) => {
        return {
          ...provided,
          minHeight: isMobileSize ? 46 : this.props.minHeight || 65,
          width: this.props.width || "100%",
          boxShadow: "none",
          borderColor,
          color: colors.black,
          fontSize: 18,
          fontFamily: "Nunito Sans",
          caretColor: colors.orange,
          "&:hover": { borderColor }
        };
      },
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.children === this.props.placeholder ? " rgba(0,0,0,0.3);" : "",
        lineHeight: "22px",
        paddingRight: 16,
        marginLeft: this.props.icon ? 27 : 0,
        maxWidth: this.props.icon ? "calc(100% - 40px)" : "calc(100% - 8px)"
      }),
      input: (provided: any) => ({
        ...provided,
        ...icon(),
        margin: this.props.icon || this.props.isSearchable ? 0 : 2,
        div: { width: "100% !important" },
        input: {
          maxWidth: this.props.maxWidth ? this.props.maxWidth : this.props.width,
          width: `${this.props.width} !important`
        }
      }),
      valueContainer: (provided: any) => ({
        paddingLeft: this.props.paddingLeft || 8,
        width: "100%"
      }),
      indicatorSeparator: () => ({
        display: "none"
      }),
      dropdownIndicator: () =>
        ({
          display: this.props.indicator ? "block" : "none",
          paddingRight: 18,
          position: "absolute",
          right: 0
        } as React.CSSProperties),
      menu: (provided: any) => ({
        ...provided,
        paddingTop: 0,
        marginTop: 0
      }),
      placeholder: (provided: any) => ({
        ...provided,
        fontSize: 18,
        fontFamily: "Nunito Sans",
        color: colors.placeholder,
        ...icon(),
        margin: this.props.icon || this.props.isSearchable ? 0 : 2
      }),
      menuList: (provided: any) => ({
        ...provided,
        padding: "12px 0"
      }),
      option: (provided: any, state: any) => {
        return {
          ...provided,
          color: state.isSelected ? colors.orange : colors.black,
          fontWeight: 500,
          fontFamily: "Nunito Sans",
          fontSize: state.isDisabled ? 14 : 16,
          opacity: state.isDisabled ? ".5" : "1",
          padding: "12px 16px",
          cursor: state.isDisabled ? "default" : "pointer",
          backgroundColor: isMobile
            ? null
            : state.isSelected && state.isFocused
            ? colors.graySuperLight
            : state.isDisabled
            ? null
            : state.isSelected
            ? null
            : state.isFocused
            ? colors.graySuperLight
            : null,
          "&:hover": {
            backgroundColor: state.isDisabled ? null : colors.graySuperLight
          },
          ":active": {
            backgroundColor: state.isDisabled ? null : colors.graySuperLight
          }
        };
      }
    };
    return (
      <Select
        ref={this.selectRef}
        className="react-select-container"
        classNamePrefix="react-select"
        styles={customStyles}
        options={this.props.options}
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
        value={this.state.value}
        isSearchable={this.props.isSearchable}
        defaultValue={this.props.defaultValue || undefined}
        onInputChange={this.props.onInputChange ? value => this.props.onInputChange(value) : undefined}
        noOptionsMessage={noOptionsMsg}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        tabIndex={this.props.tabIndex}
        openMenuOnFocus={this.props.openMenuOnFocus}
        filterOption={this.props.ignoreDefaultFilterOption ? this.props.filterOption : this.filterOption}
        isOptionDisabled={this.props.onOptionDisabled}
        onMenuOpen={this.onMenuOpen}
        onMenuClose={this.props.onMenuClose}
        menuPortalTarget={this.props.menuPortalTarget}
        maxMenuHeight={this.props.maxMenuHeight}
        components={
          this.props.isDisabled
            ? {
                Menu: () => null
              }
            : undefined
        }
      />
    );
  }

  public focus = () => {
    this.selectRef.current?.focus();
  };

  private onMenuOpen = () => {
    onSelectMenuOpen(this.selectRef, this.props.autoScroll, this.props.onMenuOpen);
  };

  private filterOption = (): boolean => {
    return true;
  };

  private readonly selectRef = React.createRef<Select>();
}

export default SelectDropdown;
