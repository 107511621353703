import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IActivitiesOwnProps, IActivitiesProps } from ".";
import { load as loadAction } from "../../../../../store/billing/actions";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ billing: { billing, load } }: IAppState) {
  return { billing, load };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    load: {
      onLoad: (bookingId: string) => dispatch(loadAction(bookingId))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: IActivitiesOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    load: { ...stateProps.load, ...dispatchProps.load }
  } as IActivitiesProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
