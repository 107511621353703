import { apiClient } from "..";
import { IContactInfo } from "../../store/profile/contactInfo/model";
import { apiType } from "../apiType";
import { ILoadProfileStateResponse } from "./state";

export interface ILoadContactInformationResponse extends IContactInfo {}

export interface IUpdateContactInformationRequest extends IContactInfo {}

export interface IApiContactInfo {
  load: () => Promise<ILoadContactInformationResponse>;
  update: (request: IUpdateContactInformationRequest) => Promise<ILoadProfileStateResponse>;
}

const api: IApiContactInfo = {
  load: () => apiClient.get<ILoadContactInformationResponse>(`${apiType.bookingWidget}photographer/profile/contacts`),

  update: (request: IUpdateContactInformationRequest) =>
    apiClient.put<ILoadProfileStateResponse>(`${apiType.bookingWidget}photographer/profile/contacts`, request)
};

export default api;
