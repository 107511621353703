import classNames from "classnames";
import moment, { Moment } from "moment";
import React, { PureComponent } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import leftArrow from "../../icons/calendar_left_arrow.svg";
import rightArrow from "../../icons/calendar_right_arrow.svg";
import calendar from "./calendar.svg";
import calendarAlt from "./calendarAlt.svg";
import calendarSelected from "./calendarSelected.svg";
import calendarSelectedAlt from "./calendarSelectedAlt.svg";
import styles from "./single.module.scss";
import "./singleDateInput.scss";

interface IProps {
  date: moment.Moment | null;
  placeholder: string;
  onChangeDate: (date: Moment | null) => void;
  isDayBlocked?: (day: any) => boolean;
  onNextMonthClick?: (newCurrentMonth: Moment) => void;
  onPrevMonthClick?: (newCurrentMonth: Moment) => void;
  focusedCustom?: boolean;
  error?: boolean;
  hasSelectedIco?: boolean;
  forceSelectedIco?: boolean;
  isOutsideRange?: (day: any) => boolean;
  displayFormat?: string;
  onFocusChange: (focused: boolean | null) => void;
  anchorDirection?: "left" | "right";
  useAlternativeIcon?: boolean;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean
}

interface IState {
  calendarFocused: boolean;
}

const NavPrev = () => (
  <button className={styles.arrowLeft}>
    <img src={leftArrow} alt="arrow icon" />
  </button>
);

const NavNext = () => (
  <button className={styles.arrowRight}>
    <img src={rightArrow} alt="arrow icon" />
  </button>
);

class SingleDateInput extends PureComponent<IProps, IState> {
  public static defaultProps: Partial<IProps> = { displayFormat: "MMM DD, YYYY", anchorDirection: "left" };

  public state = {
    calendarFocused: false
  };

  private onDateChange = (date: Moment | null) => {
    this.props.onChangeDate(date);
  };

  private onFocusChange = ({ focused }: any) => {
    this.setState({ calendarFocused: focused }, () => this.props.onFocusChange?.(this.state.calendarFocused));
  };

  public render() {
    const { useAlternativeIcon, className, style, disabled } = this.props;

    return (
      <div className={classNames(this.props.error ? "error" : undefined, className)} style={style}>
        <SingleDatePicker
          id="single-date-picker"
          isDayBlocked={this.props.isDayBlocked}
          onPrevMonthClick={this.props.onPrevMonthClick}
          onNextMonthClick={this.props.onNextMonthClick}
          placeholder={this.props.placeholder}
          date={this.props.date}
          displayFormat={this.props.displayFormat}
          onDateChange={this.onDateChange}
          focused={this.props.focusedCustom ? this.props.focusedCustom : this.state.calendarFocused}
          onFocusChange={this.onFocusChange}
          hideKeyboardShortcutsPanel={true}
          readOnly={true}
          numberOfMonths={1}
          disabled={disabled}
          customInputIcon={
            <img
              alt="calendar icon"
              src={
                this.props.forceSelectedIco || (this.props.date && this.props.hasSelectedIco)
                  ? useAlternativeIcon
                    ? calendarSelectedAlt
                    : calendarSelected
                  : useAlternativeIcon
                  ? calendarAlt
                  : calendar
              }
            />
          }
          inputIconPosition="after"
          navPrev={<NavPrev />}
          navNext={<NavNext />}
          isOutsideRange={this.props.isOutsideRange}
          anchorDirection={this.props.anchorDirection}
        />
      </div>
    );
  }
}

export default SingleDateInput;
