import classNames from "classnames";
import React from "react";
import { Utilities } from "utilities/utilities";
import NumericInputHost from "../NumericInputHost";
// @ts-ignore
import NumberUnit, { ChangeType } from "../Units/NumberUnit";
import {
  getInputMax,
  IComponentBaseProps,
  IElementBaseProps,
  isDecrementDisabled,
  isIncrementDisabled,
  resolveValue
} from "../utils";
import styles from "./numberInput.module.scss";

interface INumberInputProps extends IComponentBaseProps<HTMLInputElement> {
  input?: IElementBaseProps<HTMLInputElement>;
  inputContainer?: IElementBaseProps<HTMLDivElement>;
  buttonsContainer?: IElementBaseProps<HTMLDivElement>;
  errorContainer?: IElementBaseProps<HTMLDivElement>;

  defaultValue?: number | null;
  value?: number | null;
  maxLength?: number;
  min?: number;
  max?: number;
  step?: number;
  incrementDisabled?: boolean;
  decrementDisabled?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  tabIndex?: number;

  formatter?: (value: number | null) => string;
  parser?: (value: string) => number | null;
  filter?: (value: string) => string;

  onChange?: (value: number | null, type: ChangeType) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface INumberInputState {
  value?: number | null;
}

class NumberInput extends React.Component<INumberInputProps, INumberInputState> {
  public static defaultFormatter = NumberUnit.defaultFormatter;
  public static defaultParser = NumberUnit.defaultParser;
  public static defaultFilter = NumberUnit.defaultFilter;

  public state: INumberInputState = {};

  public shouldComponentUpdate(nextProps: INumberInputProps, nextState: INumberInputState): boolean {
    return Utilities.shouldComponentUpdateDeep(this, nextProps, nextState);
  }

  public componentWillUnmount() {
    Utilities.combineRefsCached.cache.delete(this.buttonsContainerRef);
    Utilities.combineRefsCached.cache.delete(this.inputRef);
  }

  public render() {
    const { input, inputContainer, buttonsContainer, errorContainer, containerRef } = this.props;
    const { style, className, disabled, errorMessage, tabIndex } = this.props;
    const { formatter, parser, filter, onFocus, onKeyDown, onKeyUp, maxLength, min, max, step } = this.props;

    return (
      <NumericInputHost
        inputContainer={inputContainer}
        buttonsContainer={{
          ...buttonsContainer,
          ref: Utilities.combineRefsCached(this.buttonsContainerRef)(buttonsContainer?.ref)
        }}
        errorContainer={errorContainer}
        disabled={disabled}
        errorMessage={errorMessage}
        incrementDisabled={this.incrementDisabled}
        decrementDisabled={this.decrementDisabled}
        onIncrement={this.onIncrement}
        onDecrement={this.onDecrement}
        className={classNames(styles.container, className)}
        style={style}
        containerRef={containerRef}
      >
        <NumberUnit
          min={min}
          max={max}
          step={step}
          maxLength={maxLength}
          tabIndex={tabIndex}
          incrementDisabled={this.incrementDisabled}
          decrementDisabled={this.decrementDisabled}
          disabled={disabled}
          formatter={formatter}
          parser={parser}
          filter={filter}
          value={this.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          className={classNames(styles.input, input?.className)}
          style={input?.style}
          containerRef={Utilities.combineRefsCached(this.inputRef)(input?.ref)}
        />
      </NumericInputHost>
    );
  }

  private get value() {
    return resolveValue(this.props.value, this.state.value, this.props.defaultValue, null);
  }

  private get max() {
    return getInputMax(this.props.max, this.props.maxLength);
  }

  private get incrementDisabled() {
    const { step, incrementDisabled } = this.props;
    return isIncrementDisabled(this.value, step, this.max, incrementDisabled);
  }

  private get decrementDisabled() {
    const { step, decrementDisabled, min } = this.props;
    return isDecrementDisabled(this.value, step, min, decrementDisabled);
  }

  private onIncrement = () => {
    const { value, step, min, incrementDisabled, onChange } = this.props;

    if (!isIncrementDisabled(this.value, step, this.max, incrementDisabled)) {
      const newValue = Utilities.limitNumber((this.value || 0) + (step || 1), min, this.max);
      this.setState({ value: value === undefined ? newValue : undefined }, () => onChange?.(newValue, "increment"));
      this.ensureInputFocused();
    }
  };

  private onDecrement = () => {
    const { value, step, min, decrementDisabled, onChange } = this.props;

    if (!isDecrementDisabled(this.value, step, min, decrementDisabled)) {
      this.ensureInputFocused();
      const newValue = Utilities.limitNumber((this.value || 0) - (step || 1), min, this.max);
      this.setState({ value: value === undefined ? newValue : undefined }, () => onChange?.(newValue, "decrement"));
    }
  };

  private onChange = (value: number | null, type: ChangeType) =>
    this.setState({ value: this.props.value === undefined ? value : undefined }, () =>
      this.props.onChange?.(value, type)
    );

  private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.relatedTarget instanceof Node && this.buttonsContainerRef.current?.contains(event.relatedTarget)) {
      event.currentTarget.focus();
    } else {
      this.props.onBlur?.();
    }
  };

  private ensureInputFocused() {
    if (document.activeElement !== this.inputRef.current) {
      this.inputRef.current?.focus();
    }
  }

  private inputRef = React.createRef<HTMLInputElement>();
  private buttonsContainerRef = React.createRef<HTMLDivElement>();
}

export type ChangeType = ChangeType;
export default NumberInput;
