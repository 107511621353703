import classNames from "classnames";
import React from "react";
import { Utilities } from "utilities/utilities";
import NumericInputHost from "../NumericInputHost";
import FixedLengthNumberUnit, { ChangeType } from "../Units/FixedLengthNumberUnit";
import TimePeriodUnit, { TimePeriod } from "../Units/TimePeriodUnit";
import { IComponentBaseProps, IElementBaseProps, resolveValue } from "../utils";
import styles from "./hoursMinutesInput.module.scss";

export type Mode = "time" | "duration";
type Segment = "hours" | "minutes" | "time-period";

export interface IHoursMinutesInputProps extends IComponentBaseProps<HTMLInputElement> {
  inputContainer?: IElementBaseProps<HTMLDivElement>;
  buttonsContainer?: IElementBaseProps<HTMLDivElement>;
  errorContainer?: IElementBaseProps<HTMLDivElement>;
  hoursInput?: IElementBaseProps<HTMLInputElement>;
  minutesInput?: IElementBaseProps<HTMLInputElement>;
  timePeriodInput?: IElementBaseProps<HTMLInputElement>;

  mode: Mode;
  defaultValue?: number;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  errorMessage?: string;
  tabIndex?: number;

  onChange?: (value: number) => void;
  onBlur?: () => void;
}

interface IHoursMinutesInputState {
  value?: number;
  inputValue?: number;
  focusedSegment?: Segment;
}

class HoursMinutesInput extends React.Component<IHoursMinutesInputProps, IHoursMinutesInputState> {
  public state: IHoursMinutesInputState = {};

  public shouldComponentUpdate(nextProps: IHoursMinutesInputProps, nextState: IHoursMinutesInputState): boolean {
    return Utilities.shouldComponentUpdateDeep(this, nextProps, nextState);
  }

  public componentWillUnmount() {
    Utilities.combineRefsCached.cache.delete(this.buttonsContainerRef);
    Utilities.combineRefsCached.cache.delete(this.hoursInputRef);
    Utilities.combineRefsCached.cache.delete(this.minutesInputRef);
    Utilities.combineRefsCached.cache.delete(this.timePeriodInputRef);
  }

  public render() {
    const { hoursInput, minutesInput, timePeriodInput, buttonsContainer, errorContainer, inputContainer } = this.props;
    const { containerRef, style, className, disabled, step, mode, errorMessage, tabIndex } = this.props;
    const { hours, minutes, timePeriod } = this.inputData;
    const currentInput =
      this.focusedSegment === "hours" ? hours : this.focusedSegment === "minutes" ? minutes : timePeriod;

    return (
      <NumericInputHost
        className={classNames(styles.container, className)}
        style={style}
        containerRef={containerRef}
        inputContainer={inputContainer}
        buttonsContainer={{
          ...buttonsContainer,
          ref: Utilities.combineRefsCached(this.buttonsContainerRef)(buttonsContainer?.ref)
        }}
        errorContainer={errorContainer}
        errorMessage={errorMessage}
        disabled={disabled}
        incrementDisabled={currentInput.incrementDisabled}
        decrementDisabled={currentInput.decrementDisabled}
        onIncrement={this.onIncrement}
        onDecrement={this.onDecrement}
      >
        <FixedLengthNumberUnit
          length={2}
          value={hours.displayValue}
          min={hours.min}
          max={hours.max}
          tabIndex={tabIndex}
          incrementDisabled={hours.incrementDisabled}
          decrementDisabled={hours.decrementDisabled}
          disabled={disabled}
          className={hoursInput?.className}
          style={hoursInput?.style}
          containerRef={Utilities.combineRefsCached(this.hoursInputRef)(hoursInput?.ref)}
          onChange={this.onHoursChange}
          onFocus={this.onHoursFocus}
          onBlur={this.onHoursBlur}
          onKeyDown={this.onHoursKeyDown}
        />
        <span className={classNames(styles.separator, disabled && styles.disabled)}>:</span>
        <FixedLengthNumberUnit
          length={2}
          value={minutes.displayValue}
          min={minutes.min}
          max={minutes.max}
          tabIndex={tabIndex}
          incrementDisabled={minutes.incrementDisabled}
          decrementDisabled={minutes.decrementDisabled}
          step={step}
          disabled={disabled}
          className={minutesInput?.className}
          style={minutesInput?.style}
          containerRef={Utilities.combineRefsCached(this.minutesInputRef)(minutesInput?.ref)}
          onChange={this.onMinutesChange}
          onFocus={this.onMinutesFocus}
          onBlur={this.onMinutesBlur}
          onKeyDown={this.onMinutesKeyDown}
        />
        {mode === "time" && (
          <TimePeriodUnit
            value={timePeriod.displayValue as TimePeriod}
            disabled={disabled || timePeriod.incrementDisabled}
            tabIndex={tabIndex}
            className={classNames(
              styles.timePeriod,
              timePeriodInput?.className,
              timePeriod.incrementDisabled && styles.blocked
            )}
            style={timePeriodInput?.style}
            containerRef={Utilities.combineRefsCached(this.timePeriodInputRef)(timePeriodInput?.ref)}
            onChange={this.onTimePeriodChange}
            onFocus={this.onTimePeriodFocus}
            onBlur={this.onTimePeriodBlur}
            onKeyDown={this.onTimePeriodKeyDown}
          />
        )}
      </NumericInputHost>
    );
  }

  private get value() {
    return resolveValue(this.state.inputValue, this.props.value, this.state.value, this.props.defaultValue, 0);
  }

  private get max() {
    return this.props.max !== undefined ? this.props.max : this.props.mode === "time" ? 1439 : 5999;
  }

  private get min() {
    return this.props.min || 0;
  }

  private get step() {
    return this.focusedSegment === "hours"
      ? 60
      : this.focusedSegment === "minutes"
      ? this.props.step || 1
      : this.value % 1440 < 720
      ? 720
      : -720;
  }

  private get focusedSegment() {
    return this.state.focusedSegment || "minutes";
  }

  private get inputData() {
    const step = this.props.step || 1;
    const { max, min } = this;
    const isDuration = this.props.mode === "duration";
    const hours = Math.floor(this.value / 60);
    const minutes = this.value - hours * 60;
    const timePeriod = isDuration ? undefined : this.value % 1440 < 720 ? "am" : "pm";
    const hoursMin = Math.floor(min / 60);
    const hoursMax = Math.floor(max / 60);
    const timePeriodChangeValue = this.value + (timePeriod === "am" ? 720 : -720);
    const timePeriodChangeDisabled = timePeriodChangeValue > max || timePeriodChangeValue < min;
    const looped = !isDuration && this.props.min === undefined && this.props.max === undefined;

    return {
      looped,
      hours: {
        value: hours,
        displayValue: isDuration ? hours : hours % 12 === 0 ? 12 : hours % 12,
        min: isDuration ? hoursMin : 1,
        max: isDuration ? hoursMax : 12,
        incrementDisabled: looped ? false : hours >= hoursMax,
        decrementDisabled: looped ? false : hours <= hoursMin
      },
      minutes: {
        value: minutes,
        displayValue: minutes,
        min: Utilities.limitNumber(min - hours * 60, 0, 59),
        max: Utilities.limitNumber(max - hours * 60, 0, 59),
        incrementDisabled: looped ? false : this.value + step > max,
        decrementDisabled: looped ? false : this.value - step < min
      },
      timePeriod: {
        value: timePeriod,
        displayValue: timePeriod,
        incrementDisabled: timePeriodChangeDisabled,
        decrementDisabled: timePeriodChangeDisabled
      }
    };
  }

  private onIncrement = () => {
    this.ensureMinutesFocusedByDefault();
    if (this.focusedSegment === "time-period") {
      this.changeValue(this.value + this.step);
    } else {
      let value = this.value + this.step;
      value = this.props.mode === "time" && this.inputData.looped ? value % 1440 : value;
      this.changeValue(value);
    }
  };

  private onDecrement = () => {
    this.ensureMinutesFocusedByDefault();
    if (this.focusedSegment === "time-period") {
      this.changeValue(this.value + this.step);
    } else {
      let value = this.value - this.step;
      value = this.props.mode === "time" && this.inputData.looped ? (value + 1440) % 1440 : value;
      this.changeValue(value);
    }
  };

  private onHoursChange = (value: number | null, type: ChangeType) => {
    value = value || 0;
    if (this.props.mode === "time") {
      const timePeriod = this.inputData.timePeriod.value;
      const currentValue = this.inputData.hours.value;
      if (type === "increment") {
        value = currentValue + 1;
        value = this.inputData.looped ? value % 24 : value;
      } else if (type === "decrement") {
        value = currentValue - 1;
        value = this.inputData.looped ? (value + 24) % 24 : value;
      } else {
        const days = this.inputData.looped ? 0 : Math.floor(currentValue / 24);
        value =
          days * 24 +
          (value === 12 && timePeriod === "am" ? 0 : value !== 12 && timePeriod === "pm" ? value + 12 : value);
      }
    }

    value =
      type !== "editor" ? Utilities.limitNumber(value, Math.floor(this.min / 60), Math.floor(this.max / 60)) : value;
    this.changeValue(value * 60 + this.inputData.minutes.value, type !== "editor");

    if (type === "filled") {
      setTimeout(() => this.minutesInputRef.current?.focus(), 0);
    }
  };

  private onMinutesChange = (value: number | null, type: ChangeType) => {
    value = value || 0;
    const currentValue = this.inputData.minutes.value;
    const step = this.props.step || 1;
    if (type === "increment") {
      value = currentValue + step;
    } else if (type === "decrement") {
      value = currentValue - step;
    }

    value = this.inputData.hours.value * 60 + value;
    value = this.props.mode === "time" && this.inputData.looped ? (value + 1440) % 1440 : value;
    this.changeValue(value, type !== "editor");

    if (type === "filled") {
      setTimeout(
        () =>
          this.props.mode === "duration" || this.inputData.timePeriod.incrementDisabled
            ? this.minutesInputRef.current?.blur()
            : this.timePeriodInputRef.current?.focus(),
        0
      );
    }
  };

  private onTimePeriodChange = () => this.changeValue(this.value + this.step);

  private onHoursFocus = () => this.setState({ focusedSegment: "hours" });

  private onMinutesFocus = () => this.setState({ focusedSegment: "minutes" });

  private onTimePeriodFocus = () => this.setState({ focusedSegment: "time-period" });

  private onHoursBlur = (event: React.FocusEvent<HTMLInputElement>) => this.onInputBlur(event);

  private onMinutesBlur = (event: React.FocusEvent<HTMLInputElement>) => this.onInputBlur(event);

  private onTimePeriodBlur = (event: React.FocusEvent<HTMLInputElement>) => this.onInputBlur(event);

  private onInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (event.relatedTarget instanceof Node && this.buttonsContainerRef.current?.contains(event.relatedTarget)) {
      event.currentTarget.focus();
    } else if (
      event.relatedTarget !== this.hoursInputRef.current &&
      event.relatedTarget !== this.minutesInputRef.current &&
      (this.props.mode === "duration" || event.relatedTarget !== this.timePeriodInputRef.current)
    ) {
      this.setState({ focusedSegment: undefined });
      this.props.onBlur?.();
    }
  }

  private onHoursKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowRight") {
      this.minutesInputRef.current?.focus();
    }
  };

  private onMinutesKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowLeft") {
      this.hoursInputRef.current?.focus();
    } else if (
      event.key === "ArrowRight" &&
      this.props.mode === "time" &&
      !this.inputData.timePeriod.incrementDisabled
    ) {
      this.timePeriodInputRef.current?.focus();
    }
  };

  private onTimePeriodKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowLeft") {
      this.minutesInputRef.current?.focus();
    }
  };

  private changeValue = (value: number, committed: boolean = true) => {
    value = committed ? Utilities.limitNumber(value, this.min, this.max) : value;
    this.setState(
      {
        value: committed && this.props.value === undefined ? value : undefined,
        inputValue: committed ? undefined : value
      },
      () => committed && this.props.onChange?.(value)
    );
  };

  private ensureMinutesFocusedByDefault() {
    if (this.state.focusedSegment === undefined) {
      this.minutesInputRef.current?.focus();
    }
  }

  private hoursInputRef = React.createRef<HTMLInputElement>();
  private minutesInputRef = React.createRef<HTMLInputElement>();
  private timePeriodInputRef = React.createRef<HTMLInputElement>();
  private buttonsContainerRef = React.createRef<HTMLDivElement>();
}

export default HoursMinutesInput;
