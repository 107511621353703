import React, { useEffect, useRef, useState } from "react";

import cx from "classnames";
import { PhotoOrVideo } from "../../../models/models";
import { Dot } from "pure-react-carousel";
import { formatMediaUrl, getImageSizeCode, MESizeMethod } from "utilities/getImgUrl";
import { calculateIdxToLoad } from "../util";
import styles from "./dotsImageGroup.module.scss";
import VideoIcon from "./icon-video-sm.svg";
import { isVideo } from "utilities/helpers";

export interface IDotsImageGroupProps {
  images: PhotoOrVideo[];
  photoUrlTemplate: string;
  thumbnailUrlTemplate: string;
  totalCount: number;
  rawIndex: number;
  currentIndex: number;
  className?: string;
  onClickDot?: (selectedIndex: number) => void;
  observerComponent?: JSX.Element;
}

const imageMargin = 14;
const videoIconHeight = 16;
const imageDimensions = {
  width: 56,
  height: 56
};

const DotsImageGroup: React.FC<IDotsImageGroupProps> = props => {
  const refScroll = useRef<HTMLDivElement>(null);
  const refImagesContainer = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(calculateIdxToLoad(props.currentIndex));
  const [hideLeftCover, setHideLeftCover] = useState(false);
  const [hideRightCover, setHideRightCover] = useState(false);

  useEffect(() => {
    if (refScroll.current && refImagesContainer.current) {
      const imageSize = imageDimensions.width / 2;
      const width = refImagesContainer.current.clientWidth;
      let lessLeft = 0;

      lessLeft = props.currentIndex === 0 ? imageSize : (imageSize * 2 + imageMargin) * props.currentIndex + imageSize;

      const leftValue = width / 2 - lessLeft;
      refScroll.current.style.left = `${leftValue}px`;

      setHideLeftCover(leftValue > 0);
      setHideRightCover(!(leftValue + refScroll.current.clientWidth >= refImagesContainer.current.clientWidth));

      const updatedList = [...loaded];
      const idxToLoad = calculateIdxToLoad(props.images.length, props.currentIndex);
      for (let i = idxToLoad[0]; i <= idxToLoad[idxToLoad.length - 1]; i++) {
        if (!loaded.some(x => x === i)) {
          updatedList.push(i);
        }
        setLoaded(updatedList);
      }
    }
  }, [props.currentIndex]);

  const onClickDo = (index: number) => {
    if (props.onClickDot) {
      props.onClickDot(index);
    }
  };

  return (
    <div className={cx(styles.dotsImageGroup, props.className)}>
      <div ref={refImagesContainer} className={styles.imagesContainer}>
        <div ref={refScroll} className={styles.scroll}>
          {props.images.map((image: PhotoOrVideo, index: number) => {
            return (
              <div key={`dot-group-${index}`} className={styles.imageContainer} style={{ marginRight: imageMargin }}>
                <Dot onClick={() => onClickDo(index)} className={styles.dot} slide={index}>
                  {loaded.some(x => x === index) ? (
                    <>
                      <img
                        src={formatMediaUrl(
                          image,
                          props.photoUrlTemplate,
                          props.thumbnailUrlTemplate,
                          getImageSizeCode(imageDimensions.width, imageDimensions.height),
                          MESizeMethod.Cover
                        )}
                        className={styles.image}
                        style={imageDimensions}
                      />
                      {isVideo(image) && (
                        <img
                          src={VideoIcon}
                          className={styles.videoIcon}
                          style={{ height: videoIconHeight, top: imageDimensions.height - videoIconHeight }}
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_STORAGE_CDN_HOST || ""}/storage/loading.gif`}
                      className={styles.imagePlaceholder}
                      style={imageDimensions}
                    />
                  )}
                </Dot>
              </div>
            );
          })}

          {props.observerComponent}
        </div>
        {!hideRightCover && <div className={styles.coverEnd} style={imageDimensions} />}
        {!hideLeftCover && <div className={styles.coverStart} style={imageDimensions} />}
      </div>
      <div className={styles.counter}>
        {props.rawIndex + 1} / {props.totalCount}
      </div>
    </div>
  );
};

export default DotsImageGroup;
