import cx from "classnames";
import React, { Component } from "react";
import ContextMenu from "utilities/contextMenu";
import { IInstagramImage, IInstagramImageLayoutB, ILayoutBImage } from "../";
import styles from "../zenInstagramBlock.module.scss";
import { PageContext } from "utilities/pageContext";

export interface IImagesProps {
  images: IInstagramImage[];
  imagesLayoutB: IInstagramImageLayoutB[];
  layout?: string;
  isMobileSize: boolean;
  readOnly?: boolean;
  currentPhoto: number;
  caption: boolean;
  lightbox: string;
  handleImageClick: (index: number) => void;
}

class Images extends Component<IImagesProps> {
  static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  private renderRowItem = (
    isMobileSize: boolean,
    index: number,
    width: number,
    height: number,
    img: IInstagramImage,
    imgIndex: number,
    showDescription: boolean
  ) => {
    const { readOnly, currentPhoto } = this.props;

    const isGhost = !this.context.noGhost && img.isFallback;

    return (
      <div className={styles.rowItem} key={img.id} onClick={() => this.props.handleImageClick(imgIndex)}>
        <div
          className={cx(styles.picture, {
            [styles.withLightbox]: this.props.lightbox !== "none" && readOnly,
            [styles.inactive]: (isMobileSize && currentPhoto !== index) || isGhost
          })}
        >
          <img src={img.url} width={width} height={height} onContextMenu={ContextMenu.handleBlockContextMenu} />
        </div>
        {this.props.caption && img.description && showDescription && (
          <div className={styles.layerInfo}>
            <div className={styles.layerData}>{img.description}</div>
          </div>
        )}
      </div>
    );
  };

  public shouldComponentUpdate(nextProps: IImagesProps) {
    return (
      (nextProps.images !== this.props.images && this.props.layout !== "B") ||
      (nextProps.imagesLayoutB !== this.props.imagesLayoutB && this.props.layout === "B") ||
      nextProps.layout !== this.props.layout ||
      nextProps.caption !== this.props.caption ||
      nextProps.lightbox !== this.props.lightbox ||
      (nextProps.currentPhoto !== this.props.currentPhoto && this.props.isMobileSize)
    );
  }

  public render() {
    const { images, layout, isMobileSize } = this.props;

    if (layout === "A" || layout === "C") {
      return images.map((img: IInstagramImage, index: number) => {
        return (
          <div className={styles.sliderItem} key={img.id}>
            {this.renderRowItem(
              isMobileSize,
              index,
              layout === "A" || isMobileSize ? 285 : 164,
              layout === "A" || isMobileSize ? 285 : 164,
              img,
              index,
              layout === "A"
            )}
          </div>
        );
      });
    } else {
      return this.props.imagesLayoutB.map((img: IInstagramImageLayoutB, index: number) => {
        if (img.images.length === 3) {
          return (
            <div className={styles.sliderItem} key={`group-${img.images[0].id}`}>
              <div className={styles.row}>
                {img.images.slice(0, 2).map((image: ILayoutBImage) => {
                  return this.renderRowItem(isMobileSize, index, 136, 136, image, image.index, false);
                })}
              </div>
              <div className={styles.row}>
                {this.renderRowItem(isMobileSize, index, 285, 136, img.images[2], img.images[2].index, false)}
              </div>
            </div>
          );
        } else if (img.images.length === 2) {
          return (
            <div className={styles.sliderItem} key={`group-${img.images[0].id}`}>
              <div className={styles.row}>
                {this.renderRowItem(isMobileSize, index, 285, 136, img.images[0], img.images[0].index, false)}
              </div>
              <div className={styles.row}>
                {this.renderRowItem(isMobileSize, index, 285, 136, img.images[1], img.images[1].index, false)}
              </div>
            </div>
          );
        } else {
          return (
            <div className={styles.sliderItem} key={img.images[0].id}>
              {this.renderRowItem(isMobileSize, index, 285, 285, img.images[0], img.images[0].index, true)}
            </div>
          );
        }
      });
    }
  }
}

export default Images;
