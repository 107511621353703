import _ from "lodash";
import { useLayoutEffect, useState } from "react";

import useMutable from "./useMutable";

export interface IWindowSize {
  width: number;
  height: number;
}

export default function useWindowSize(throttle: number = 200): IWindowSize {
  const [windowSize, setWindowSize] = useState<IWindowSize>(getWindowSize());
  const mutableWindowSize = useMutable(windowSize);

  useLayoutEffect(onMount, []);

  function onMount() {
    const onWindowResize = _.throttle(() => {
      const currentWindowSize = mutableWindowSize.current;
      const newWindowSize = getWindowSize();
      if (!_.isEqual(newWindowSize, currentWindowSize)) {
        setWindowSize(newWindowSize);
      }
    }, throttle);

    window.addEventListener("resize", onWindowResize);
    window.addEventListener("orientationchange", onWindowResize);

    return function onUnmount() {
      window.removeEventListener("resize", onWindowResize);
      window.removeEventListener("orientationchange", onWindowResize);
      onWindowResize.cancel();
    };
  }

  function getWindowSize(): IWindowSize {
    return typeof window !== "undefined"
      ? { width: window.innerWidth, height: window.innerHeight }
      : { width: 0, height: 0 };
  }

  return windowSize;
}
