import React from "react";
import { initializeIconProps } from "../../../utilities/icons";
import IIConProps from "../IIconProps";

const Service = (rawProps: IIConProps) => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`${props.minX} ${props.minY} 24 24`}
    >
      <g fill="none" fillRule="evenodd" stroke={props.stroke} strokeLinejoin="round">
        <path d="M15.333 19.667h-10v-14h14v14z" />
        <path
          strokeLinecap="round"
          d="M7.333 4.333v3M10.667 4.333v3M14 4.333v3M17.333 4.333v3M10.667 10l-2 2-1-1M10.667 14.667l-2 2-1-1M12 11.333h4.333M12 15.667h4.333"
        />
      </g>
    </svg>
  );
};

export default Service;
