import _ from "lodash";
import moment from "moment";
import React from "react";
import Scrollbars from "react-custom-scrollbars";
import UrlConstants from "../../../../../components/router/constants";
import {
  IRescheduleConflictBase,
  IRescheduleConflicts,
  IRescheduleSettings
} from "../../../../../store/bookings/model";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import { NavigationManager } from "../../../../../utilities/navigationManager";
import ModalDialog from "../../modalDialog";
import { IReschedule } from "../model";
import styles from "./index.module.scss";
import withStore from "./withStore";

export interface IConflictsOwnProps {
  bookingId: string;
  settings: IRescheduleSettings;
  conflicts: IRescheduleConflicts;
  onCancel: () => void;
  onSuccess: () => void;
}

export interface IConflictsProps extends IConflictsOwnProps {
  reschedule: IReschedule;
}

class Conflicts extends React.Component<IConflictsProps> {
  public componentDidUpdate(prevProps: IConflictsProps) {
    const { reschedule } = this.props;
    const rescheduleHasCompleted = hasActionCompleted(prevProps.reschedule.status, reschedule.status);

    if (rescheduleHasCompleted) {
      this.props.onSuccess();
    }
  }

  public render() {
    const {
      reschedule,
      settings: { startDateTime, endDateTime },
      conflicts
    } = this.props;
    const contextDate = startDateTime.isSame(endDateTime, "day") ? startDateTime : undefined;

    return (
      <ModalDialog
        title="SCHEDULING CONFLICT"
        okText="Continue"
        cancelText="Cancel"
        noBodyPadding={true}
        okDisabled={reschedule.status === "Pending"}
        onCancel={this.onCancel}
        onOk={this.onOk}
      >
        <div className={styles.container}>
          <div className={styles.alert}>
            Conflicts have been found for {this.renderDateRange(startDateTime, endDateTime, "-")}
          </div>
          <Scrollbars className={styles.conflicts} autoHeight={true} autoHeightMax={265}>
            <ul className={styles.list}>
              {conflicts.calendar.map((item, idx) => (
                <li key={idx} className={styles.item}>
                  {`Google Calendar - ${item.eventName} - ${this.renderConflictDateRange(item, contextDate)}`}
                </li>
              ))}
              {conflicts.bookings.map((item, idx) => (
                <li key={idx} className={styles.item}>
                  <span className={styles.link} onClick={this.onBookingConflictClick(idx)}>
                    {item.bookingName}
                  </span>
                  {` - ${this.renderConflictDateRange(item, contextDate)}`}
                </li>
              ))}
            </ul>
          </Scrollbars>
          <div className={styles.message}>Do you want to continue with this time?</div>
        </div>
      </ModalDialog>
    );
  }

  private renderConflictDateRange(conflict: IRescheduleConflictBase, contextDate?: moment.Moment) {
    return this.renderDateRange(conflict.startDateTime, conflict.endDateTime, "to", contextDate);
  }

  private renderDateRange(
    startDateTime: moment.Moment,
    endDateTime: moment.Moment,
    separator: string,
    contextDate?: moment.Moment
  ) {
    const dateFormat = "MMM D, YYYY ";
    const timeFormat = "h:mm a";
    const needStartDate =
      !contextDate || !contextDate.isSame(startDateTime, "day") || !startDateTime.isSame(endDateTime, "day");
    const needEndDate = !startDateTime.isSame(endDateTime, "day");

    return `${needStartDate ? startDateTime.format(dateFormat) : ""}${startDateTime.format(timeFormat)} ${separator} ${
      needEndDate ? endDateTime.format(dateFormat) : ""
    }${endDateTime.format(timeFormat)}`;
  }

  private onBookingConflictClick = _.memoize((index: number) => () => {
    const item = this.props.conflicts.bookings[index];
    NavigationManager.postParentMessage({
      type: "open-in-new-tab",
      path: UrlConstants.booking(item.bookingStatus.code, item.bookingId)
    });
  });

  private onCancel = () => this.props.onCancel();

  private onOk = () => this.props.reschedule.onReschedule(this.props.bookingId, this.props.settings, true);
}

export default withStore(Conflicts);
