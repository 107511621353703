import cx from "classnames";
import React from "react";
import { Utilities } from "../../../utilities/utilities";
import { IDatePickerCalendarContainerProps } from "../models";
import styles from "./defaultDatePickerCalendarContainer.module.scss";

const { shouldComponentUpdateDeep } = Utilities;

export interface IDefaultDatePickerCalendarContainerProps extends IDatePickerCalendarContainerProps {}

class DefaultDatePickerCalendarContainer extends React.Component<IDefaultDatePickerCalendarContainerProps> {
  public shouldComponentUpdate(nextProps: IDefaultDatePickerCalendarContainerProps): boolean {
    return shouldComponentUpdateDeep(this, nextProps);
  }

  public componentWillMount() {
    document.addEventListener("mousedown", this.onDocumentMouseDown);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.onDocumentMouseDown);
    Utilities.combineRefsCached.cache.delete(this.containerRef);
  }

  public render() {
    const { open, children, className, style, containerRef } = this.props;

    return open ? (
      <div
        className={cx(styles.container, className)}
        style={style}
        ref={Utilities.combineRefsCached(this.containerRef)(containerRef)}
      >
        {children}
      </div>
    ) : null;
  }

  private onDocumentMouseDown = (event: MouseEvent) => {
    if (event.button === 0 && this.props.open && !this.containerRef.current?.contains(event.target as Element)) {
      this.props.onClose?.();
    }
  };

  private readonly containerRef = React.createRef<HTMLDivElement>();
}

export default DefaultDatePickerCalendarContainer;
