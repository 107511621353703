import React from "react";
import { Redirect } from "react-router";
import Auxiliary from "./../../../utilities/auxiliary";

interface IAccessGuardProps {
  haveAccess: boolean;
  to?: string;
}

class AccessGuard extends React.Component<IAccessGuardProps> {
  public render() {
    const { children, to = "/404", haveAccess } = this.props;

    const checkChildrenAccess = () => {
      if (!haveAccess) {
        return <Redirect to={to} />;
      }

      return <Auxiliary>{children}</Auxiliary>;
    };
    return checkChildrenAccess();
  }
}

export default AccessGuard;
