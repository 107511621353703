import classNames from "classnames";
import * as React from "react";
import styles from "./index.module.scss";

export interface INavProps {
  className?: string;
  horizontal?: boolean;
}

export const Nav: React.SFC<INavProps> = props => {
  const { className, horizontal } = props;

  const classes = classNames(className, styles.nav, horizontal && styles.horizontal);

  return <ul className={classes}>{props.children}</ul>;
};
