import cx from "classnames";
import { IPhotoMasonry } from "components/blocks/blockModels";
import { IGallerySettings } from "models/blocks";
import { ZenMasonryBlock } from "components/blocks/zenMasonryBlock";
import { ZenMasonryLandscapeBlock } from "components/blocks/zenMasonryLandscapeBlock";
import Breadcrumb, {
  getCurrentBreadcrumb,
  getPagesBreadcrumbs,
  IBreadcrumbItem,
  IExtraPage
} from "components/ClientView/Breadcrumb";
import { IToaster } from "components/Toaster";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import map from "lodash/map";
import {
  defaultSiteTheme,
  ICurrency,
  IPage,
  IPhotographerFoldersState,
  IZenSiteTheme
} from "../../../../models/models";
import Collections from "../Collections";

import Description from "components/ClientView/CommonUI/Description";
import { IQuickShopItem } from "components/ClientView/CommonUI/Views/components/QuickShop/Preview";
import {
  COLLECTION,
  ICartItem,
  IClientViewGalleryState,
  ICollectionOptions,
  PreviewModes
} from "components/ClientView/models";
import ImagesLightBox, { ILightboxZoomImage } from "components/LightboxZoom";
import React, { PureComponent } from "react";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { getAlbumPresentSettings } from "utilities/galleryPresentSetting";
import { formatMediaUrl, MESizeCodes, MESizeMethod } from "utilities/getImgUrl";
import { getSiteFonts } from "utilities/blocks/site";
import events, { EVENT_NAME } from "utilities/events";
import stylesCouponContent from "../../CommonUI/Content/Content.module.scss";
import CouponBanners from "../../CommonUI/CouponBanners";
import styles from "./complete.module.scss";
import { isVideo, mergeMedia, orderItemsByKey } from "utilities/helpers";
import { GRID_TYPE, FULL_SCREEN_SIZE_CODE } from "utilities/constant";
import { IGalleryCoupon } from "models/coupon";

export interface IDynamicGalleryProps {
  OverlapPhotos?: Array<JSX.Element | null>;
  siteTheme: IZenSiteTheme;
  galleryId?: string;
  sitePages: IPage[];
  gallerySettings: IGallerySettings;
  clientViewGallery: IClientViewGalleryState;
  photographerFolders: IPhotographerFoldersState;
  subdomain: string;
  extraPages?: IExtraPage[];
  pageAlias: string;
  folderId?: string;
  rootFolderId?: string;
  isPublish?: boolean;
  isEcom?: boolean;
  isDevelopment: boolean;
  currency?: ICurrency;
  couponList?: IGalleryCoupon[];
  cartItems?: ICartItem[];
  previewMode?: PreviewModes;
  quickShopItems?: IQuickShopItem[];
  contentsRef?: React.RefObject<HTMLDivElement>;
  alignment?: string;
  perPage?: string;
  order?: string;
  padding?: string;
  onHoverPhoto?: (index: number) => void;
  onShowAlertError: (toaster: IToaster) => void;
  onShowShopLightbox?: (index: number) => void;
  onClickBannerButton?: () => void;
  onClickQuickShopItem?: (e: React.MouseEvent<HTMLElement>, photo: IPhoto | ILightboxZoomImage) => void;
  onChangeCollection?: (collectionId: string) => void;
  onClickButtonCart?: () => void;
}

interface IPhoto extends IPhotoMasonry {
  urlZoom: string;
  urlZoomMax: string;
  urlLightbox: string;
  fileName: string;
  name: string;
  id: string;
}

interface IDynamicGalleryState {
  images: IPhoto[];
  breadcrumbItems: IBreadcrumbItem[];
  isBreadcrumbLoad: boolean;
  showLightBox: boolean;
  initLightBoxImage: number;
  itemsPerRow: number;
  selectedCollectionID: string;
}

const brakePoints = [400, 500, 600, 700, 1440];

interface IItemTree {
  id: string;
  value: string;
  childs: IItemTree[];
}

class DynamicGalleryCompleteView extends PureComponent<IDynamicGalleryProps, IDynamicGalleryState> {
  private Container: any;

  constructor(props: IDynamicGalleryProps) {
    super(props);
    this.Container = React.createRef();

    this.state = {
      images: [],
      breadcrumbItems: [],
      isBreadcrumbLoad: false,
      showLightBox: false,
      initLightBoxImage: 0,
      itemsPerRow: 0,
      selectedCollectionID: ""
    };
  }

  public componentDidMount() {
    const { clientViewGallery } = this.props;
    if (clientViewGallery && (clientViewGallery.photos || clientViewGallery.videos)) {
      this.prepareImages();
    }
    events.on(EVENT_NAME.CLOSE_LIGHT_BOX_VIEW, this.handleLightBoxClose);
  }

  public componentWillUnmount() {
    events.clear(EVENT_NAME.CLOSE_LIGHT_BOX_VIEW, this.handleLightBoxClose);
  }

  public componentDidUpdate(prevProps: IDynamicGalleryProps) {
    const { clientViewGallery } = this.props;

    if (
      (!prevProps.clientViewGallery && clientViewGallery) ||
      prevProps.clientViewGallery.photoCount !== clientViewGallery.photoCount ||
      prevProps.clientViewGallery.videoCount !== clientViewGallery.videoCount
    ) {
      this.prepareImages();
    }

    if (this.breadcrumbDataIsAvailable(this.props)) {
      this.updateBreadcrumbState();
    }
  }

  public updateBreadcrumbState = () => {
    const { sitePages, pageAlias, subdomain, rootFolderId, isPublish, isDevelopment, extraPages } = this.props;
    const pagesBreadcrumb = getPagesBreadcrumbs(
      sitePages,
      pageAlias,
      subdomain ? subdomain : "",
      isDevelopment,
      isPublish,
      extraPages
    );
    const foldersGalleriesBreadcrumb = getCurrentBreadcrumb(
      pagesBreadcrumb,
      this.props,
      false,
      rootFolderId,
      isPublish
    );

    this.setState({
      breadcrumbItems: [...foldersGalleriesBreadcrumb],
      isBreadcrumbLoad: true
    });
  };

  public getNewBreadcrumbItem = (to: string, label: string, isFull: boolean, level: number) => {
    const result: IBreadcrumbItem = {
      to,
      label,
      isFull,
      level
    };
    return result;
  };

  private breadcrumbDataIsAvailable = (currentProps: IDynamicGalleryProps) =>
    !this.state.isBreadcrumbLoad &&
    currentProps.photographerFolders.folders.length > 0 &&
    currentProps.clientViewGallery.id;

  private prepareImages = (): any => {
    const { clientViewGallery, gallerySettings } = this.props;
    if (!clientViewGallery) {
      return null;
    }
    const allMedia = mergeMedia(clientViewGallery.photos || [], clientViewGallery.videos || []);
    let images: any[] = map(allMedia, (media: any) => {
      const width = isVideo(media) ? media.thumbnailWidth! : media.width!;
      const height = isVideo(media) ? media.thumbnailHeight! : media.height!;
      return {
        url: formatMediaUrl(
          media,
          clientViewGallery.photoUrlTemplate,
          clientViewGallery.thumbnailUrlTemplate,
          gallerySettings.gridType === GRID_TYPE.STACKED ? FULL_SCREEN_SIZE_CODE : MESizeCodes.SIZE1170x1170,
          MESizeMethod.Contain
        ),
        urlZoom: formatMediaUrl(
          media,
          clientViewGallery.photoUrlTemplate,
          clientViewGallery.thumbnailUrlTemplate,

          MESizeCodes.SIZE1488x1488,
          MESizeMethod.Contain
        ),
        urlZoomMax: formatMediaUrl(
          media,
          clientViewGallery.photoUrlTemplate,
          clientViewGallery.thumbnailUrlTemplate,
          MESizeCodes.SIZE2975x2975,
          MESizeMethod.Contain
        ),
        urlLightbox: formatMediaUrl(
          media,
          clientViewGallery.photoUrlTemplate,
          clientViewGallery.thumbnailUrlTemplate,
          MESizeCodes.SIZE1170x1170,
          MESizeMethod.Contain
        ),
        width,
        height,
        id: media.id,
        fileName: media.fileName,
        name: media.title,
        caption: media.caption,
        isVideo: media.isVideo,
        durationMilliseconds: isVideo(media) ? media.durationMilliseconds : undefined,
        galleryName: clientViewGallery.name,
        thumbnailUrlTemplate: clientViewGallery.thumbnailUrlTemplate,
        videoUrlTemplate: clientViewGallery.videoUrlTemplate,
        video: isVideo(media) ? media : undefined,
        dateCreated: media.dateCreated
      };
    });

    if (images.length > 0) {
      images = orderItemsByKey(images, this.props.order);
      this.setState({ images });
    }
  };

  public searchItemPath: any = (item: IItemTree, id: string, path: string) => {
    path = path + item.value + "/";
    if (id === item.id) {
      return path;
    }

    for (const singleItem of item.childs) {
      const currentResult = this.searchItemPath(singleItem, id, path);
      if (currentResult !== undefined) {
        return currentResult;
      }
    }
  };

  private handleCollectionSelected = (collectionId: string) => {
    const { onChangeCollection } = this.props;
    this.setState({ selectedCollectionID: collectionId === COLLECTION.ALL_MEDIA_ID ? "" : collectionId }, () => {
      if (onChangeCollection) {
        onChangeCollection(collectionId);
      }
    });
  };

  private getActiveCollection = () => {
    const collections = this.getCollectionsOptions();
    const index = collections?.findIndex(item => item.collectionId === this.state.selectedCollectionID);
    return index !== -1 ? index : 0;
  };

  private getCollectionsOptions = (): ICollectionOptions[] => {
    let result: ICollectionOptions[] = [{ collectionId: COLLECTION.ALL_MEDIA_ID, name: COLLECTION.ALL_MEDIA }];
    let collections = this.props.clientViewGallery.collections?.filter(
      collection => collection.name !== COLLECTION.EXCLUDE_COLLECTION
    );

    if (collections) {
      collections
        .filter(collection => collection.mediaSortIndices.some(mediaId => !mediaId.isVideo))
        .forEach(collection => {
          result.push({
            collectionId: collection.collectionId ? collection.collectionId : collection.id,
            name: collection.name
          });
        });
    }

    return result;
  };

  public handleImageClick = (evt: React.MouseEvent<HTMLElement>, index: number) => {
    this.setState({ initLightBoxImage: index, showLightBox: true });
  };

  private handleLightBoxClose = () => {
    this.setState({ showLightBox: false });
  };

  private getOverlapPhotos = (OverlapPhotos: Array<JSX.Element | null>) => {
    return OverlapPhotos.map((Element: any) => {
      return <Element.type {...Element.props} onPhotoClick={this.handleImageClick} />;
    });
  };

  private handleSetItemsPerRow = (itemsPerRow: number) => {
    this.setState({ itemsPerRow });
  };

  private getStyleByGridType = (gridType: GRID_TYPE) => {
    let style = {};
    if (!isMobile) {
      if (gridType === GRID_TYPE.JUSTIFIED || gridType === GRID_TYPE.MASONRY) {
        style = { largerBannerStyle: { margin: "0 47px" } };
      } else if (gridType === GRID_TYPE.GRID) {
        style = { largerBannerStyle: { margin: "0 68px" } };
      }
    }
    return style;
  };

  public render() {
    const {
      isEcom,
      cartItems,
      galleryId,
      siteTheme,
      sitePages,
      OverlapPhotos,
      couponList,
      currency,
      contentsRef,
      quickShopItems,
      gallerySettings,
      clientViewGallery,
      onHoverPhoto,
      onClickButtonCart,
      onShowShopLightbox,
      onClickBannerButton,
      onClickQuickShopItem
    } = this.props;
    const quantityCart = cartItems?.length || 0;
    const { images, isBreadcrumbLoad, breadcrumbItems, selectedCollectionID } = this.state;

    const gridType = gallerySettings.gridType;
    const showTitles = gallerySettings.showTitles;
    const showCaption = gallerySettings.allowShowCaption;
    const caption = clientViewGallery.caption;
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    let overlayComponent: Array<JSX.Element | null> = [];
    const isEditor = this.props.previewMode ? true : false;
    const textColor = getBlockTextColor(siteTheme.backgroundColor.value, siteTheme.accentColor.value);
    const collectionsOptions = this.getCollectionsOptions();
    const withCoupons = isEcom && couponList;
    const withCollections = collectionsOptions.length !== 1;
    const { nameType, nameVisibility } = getAlbumPresentSettings(clientViewGallery.albumPresent?.settings || []);

    if (OverlapPhotos) {
      overlayComponent = this.getOverlapPhotos(OverlapPhotos);
    }

    let filteredPhotos = images;

    if (selectedCollectionID) {
      const imgsCollection = clientViewGallery.collections?.find(
        collection => collection.collectionId === selectedCollectionID
      );

      if (imgsCollection) {
        filteredPhotos = images.filter(imgUrl =>
          imgsCollection.mediaSortIndices.some(mediaId => !mediaId.isVideo && imgUrl.id === mediaId.id)
        );
      }
    }

    return (
      <div ref={this.Container} id="container" className={cx(styles.container, styles[siteFonts.primary])}>
        <style>
          {`
            :root {
              --dg-accent-color: ${siteTheme.accentColor.value};
              }
            `}
        </style>
        <div className={styles.content}>
          <Breadcrumb siteTheme={siteTheme} show={isBreadcrumbLoad} sitePages={sitePages} separator=">">
            {breadcrumbItems.map(({ to, label, isFull }) => (
              <a key={to} href={`${!isFull ? window.location.origin : ""}${to}`}>
                {label}
              </a>
            ))}
          </Breadcrumb>
          <div className={styles.albumTitleContainer}>
            {showTitles && (
              <div className={styles.albumName} style={{ color: siteTheme.accentColor.value }}>
                {clientViewGallery.name}
              </div>
            )}
            {caption && (
              <div
                className={styles.albumDescription}
                style={{
                  color: textColor
                }}
              >
                {caption}
              </div>
            )}
          </div>
          {withCoupons && (
            <div
              className={stylesCouponContent.innerContent}
              style={{
                width: "100%"
              }}
            >
              <div className={stylesCouponContent.couponBannerInDynamicGalery}>
                <CouponBanners
                  currency={currency}
                  couponList={couponList}
                  onClickBannerButton={onClickBannerButton}
                  bannerStyle={this.getStyleByGridType(gridType)}
                />
              </div>
            </div>
          )}

          <Collections
            onOptionSelected={this.handleCollectionSelected}
            collectionsOptions={collectionsOptions}
            defaultActiveIndex={this.getActiveCollection()}
            invertButtonStyle={siteTheme.accentColor?.name.toUpperCase() === "WHITE"}
            bgColor={siteTheme.backgroundColor.value}
          />

          <Description
            className={cx(
              styles.description,
              withCoupons && styles.withCoupons,
              withCollections && styles.withCollections
            )}
            description={clientViewGallery.description}
            textColor={textColor}
            linkColor={siteTheme.accentColor.value}
          />

          <div className={styles.innerContent}>
            <div className={styles.imagesContainer}>
              {gridType === GRID_TYPE.JUSTIFIED ? (
                <ZenMasonryLandscapeBlock
                  contentsRef={contentsRef}
                  siteTheme={defaultSiteTheme}
                  layout={"B"}
                  noPadding={true}
                  images={filteredPhotos}
                  className={styles.gallery}
                  overlayComponent={overlayComponent}
                  rowHeight={200}
                  galleryStyle=""
                  dropShadow={false}
                  blockImageEvents={true}
                  onHoverPhoto={onHoverPhoto}
                  onPhotoClick={this.handleImageClick}
                  onItemsPerRow={this.handleSetItemsPerRow}
                  isEditor={isEditor}
                  showNames={nameType}
                  namesVisibility={nameVisibility}
                />
              ) : (
                <ZenMasonryBlock
                  contentsRef={contentsRef}
                  siteTheme={defaultSiteTheme}
                  layout={gridType === GRID_TYPE.GRID ? "A" : "B"}
                  noPadding={true}
                  brakePoints={brakePoints}
                  images={filteredPhotos}
                  className={styles.gallery}
                  blockImageEvents={true}
                  overlayComponent={overlayComponent}
                  isStacked={gridType === GRID_TYPE.STACKED ? true : false}
                  resizeToFit={gridType === GRID_TYPE.GRID ? true : false}
                  onHoverPhoto={onHoverPhoto}
                  onPhotoClick={this.handleImageClick}
                  isEditor={isEditor}
                  showNames={nameType}
                  namesVisibility={nameVisibility}
                />
              )}
            </div>
          </div>
        </div>
        {this.state.showLightBox &&
          ReactDOM.createPortal(
            <ImagesLightBox
              cartItems={cartItems}
              cartBadge={quantityCart}
              isEcom={isEcom}
              selectedIndexImage={this.state.initLightBoxImage}
              images={filteredPhotos}
              showImgName={showCaption}
              nameType={nameType}
              onClose={this.handleLightBoxClose}
              onShowShopLightbox={onShowShopLightbox}
              quickShopItems={quickShopItems}
              onClickQuickShopItem={onClickQuickShopItem}
              onClickButtonCart={onClickButtonCart}
              videoAnalyticsParams={galleryId ? { type: "featured_gallery_lightbox", galleryId } : undefined}
            />,
            document.documentElement
          )}
      </div>
    );
  }
}

export default DynamicGalleryCompleteView;
