import cx from "classnames";
import _ from "lodash";
import * as React from "react";
import { ReactStripeElements } from "react-stripe-elements";
import {
  IBookingCreator,
  ICalculateServiceTaxQuery,
  IPaymentCreator,
  IShippingInfo
} from "../../../../../store/widget/model";
import { ICalculateServiceTaxState, ICreateBookingState } from "../../../../../store/widget/reducers";
import Auxiliary from "../../../../../utilities/auxiliary";
import { splitDateTime } from "../../../../../utilities/datetime";
import eventTracker from "../../../../../utilities/eventTracker";
import { hasActionCompletedWithError, hasActionSuccessfullyCompleted } from "../../../../../utilities/helpers";
import Loader from "../../../../layout/loader";
import { isFreeService } from "../../../../services/common";
import ShoppingAddress, { addressMarginBottom, dropDownHeight } from "../../../../shoppingAddress";
import {
  Direction,
  emptyShoppingAddressComponents,
  IEditableWidgetStepProps,
  IShoppingAddressComponents,
  IValidatableWidgetStep,
  IWidgetValues
} from "../../../contracts";
import { buildPaymentMethodOptions, buildShoppingAddress, scrollToSelector, showTaxes } from "../../../helpers";
import CardInfo from "../common/cardInfo";
import ContactInfo from "../common/contactInfo";
import PaymentDetails from "./details";
import styles from "./index.module.scss";

interface IPaymentProps extends IEditableWidgetStepProps, ReactStripeElements.InjectedStripeProps {
  onCreateBooking: (paymentCreator: IPaymentCreator, bookingCreator: IBookingCreator) => void;
  onCreateFreeServiceBooking: (bookingCreator: IBookingCreator) => void;
  onEnsureVisible: (top: number, height: number, minimum?: boolean) => void;
  onCalculateServiceTax: (query: ICalculateServiceTaxQuery) => void;
  createBookingState: ICreateBookingState;
  calculateServiceTaxState: ICalculateServiceTaxState;
  photographerTimezoneOffsetMinutes: number;
  businessName: string;
}

export interface IPaymentParams {
  email: string;
  name: string;
  phoneNumber: string;
  cardholderName: string;
  billingAddress: IShoppingAddressComponents;
  customerAddress: IShoppingAddressComponents;
}

interface IPaymentState extends IPaymentParams {
  detailsOpen: boolean;
  values: IWidgetValues;
  isContactInfoValid: boolean;
  isCardInfoValid: boolean;
  billingZipCodeError: boolean;
  calculateServiceTaxQuery: ICalculateServiceTaxQuery | null;
}

class PaymentStep extends React.Component<IPaymentProps, IPaymentState> implements IValidatableWidgetStep {
  constructor(props: IPaymentProps) {
    super(props);

    const values = props.getValues();

    this.state = {
      name: values.name || "",
      email: values.email || "",
      phoneNumber: values.phoneNumber || "",
      billingAddress: values.billingAddress || emptyShoppingAddressComponents,
      customerAddress: values.customerAddress || emptyShoppingAddressComponents,
      cardholderName: values.cardholderName || "",
      values,
      isContactInfoValid: false,
      isCardInfoValid: false,
      detailsOpen: false,
      billingZipCodeError: false,
      calculateServiceTaxQuery: null
    };
  }

  public componentDidUpdate(prevProps: IPaymentProps, prevState: IPaymentState) {
    this.calculateServiceTaxIfNeeded(prevState.calculateServiceTaxQuery);

    if (
      !this.state.billingZipCodeError &&
      prevProps.createBookingState.paymentFailureCode !== this.props.createBookingState.paymentFailureCode &&
      this.props.createBookingState.paymentFailureCode === "zipCode"
    ) {
      this.setState({ billingZipCodeError: true });
    }

    if (this.state.billingZipCodeError && prevState.billingAddress.zipCode !== this.state.billingAddress.zipCode) {
      this.setState({ billingZipCodeError: false });
    }

    const createBookingHasCompleted = hasActionSuccessfullyCompleted(
      prevProps.createBookingState.status,
      this.props.createBookingState.status
    );
    const createBookingHasCompletedWithError = hasActionCompletedWithError(
      prevProps.createBookingState.status,
      this.props.createBookingState.status
    );

    if (createBookingHasCompletedWithError && this.props.createBookingState.paymentFailureCode) {
      eventTracker.events.consumer.paymentFailed(this.props.createBookingState.paymentFailureCode);
    }

    if (createBookingHasCompleted) {
      const service = this.props.getValues().service!;
      const totalAmount = service.price;

      eventTracker.context.consumer.bookingId = this.props.createBookingState.bookingId!;
      eventTracker.events.consumer.paymentCompleted({
        paidAmount: service.useDeposit ? service.deposit.depositAmount! : totalAmount,
        totalAmount,
        approvalRequired: service.approvalRequired,
        transactionId: this.props.createBookingState.transactionId || undefined
      });
      this.props.nextStep!();
    }

    const wasValid = PaymentStep.isValid(prevProps, prevState);
    const isValid = PaymentStep.isValid(this.props, this.state);

    if (wasValid !== isValid) {
      this.props.updateValidate(this.props.name, isValid);
    }
  }

  public componentDidMount() {
    eventTracker.context.photographer.businessName = this.props.businessName;
    eventTracker.events.consumer.paymentReached();
    this.props.onRef(this);
    this.props.updateValidate(this.props.name, PaymentStep.isValid(this.props, this.state));
    this.recalculateTax();
  }

  public componentWillUnmount() {
    this.props.onRef(undefined);
  }

  public render() {
    const { name, email, phoneNumber, cardholderName, values, billingAddress, billingZipCodeError } = this.state;
    const { createBookingState, photographerTimezoneOffsetMinutes } = this.props;
    const isFree = isFreeService(values.service!);
    const loading = !this.props.stripe;
    const showBilling = !isFree;
    const showCustomerAddress = this.showCustomerAddress(showBilling);
    const needPadding = showBilling || showCustomerAddress;

    return (
      <div
        ref={this.containerRef}
        className={cx(styles.container, loading ? styles.loading : null)}
        style={{ paddingBottom: needPadding ? dropDownHeight - addressMarginBottom : undefined }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Auxiliary>
            <ContactInfo
              email={email}
              name={name}
              phoneNumber={phoneNumber}
              onEmailChange={this.onEmailChange}
              onNameChange={this.onNameChange}
              onPhoneNumberChange={this.onPhoneNumberChange}
              onValidationChanged={this.onContactInfoValidationChanged}
            />
            {!showCustomerAddress || this.renderCustomerAddress()}
            <div className={styles.details}>
              <PaymentDetails
                values={this.props.getValues()}
                photographerTimezoneOffsetMinutes={photographerTimezoneOffsetMinutes}
                detailsOpen={isFree}
                {...this.taxAmounts}
              />
            </div>
            {!isFree && (
              <CardInfo
                billingAddress={billingAddress}
                billingZipCodeError={billingZipCodeError}
                onBillingAddressChange={this.onBillingAddressChange}
                onBillingAddressSubmit={this.recalculateTax}
                cardholderName={cardholderName}
                onValidationChanged={this.onCardInfoValidationChanged}
                onCardholderNameChange={this.onCardholderNameChange}
                onScrollToSelector={this.onScrollToSelector}
                paymentFailureCode={createBookingState.paymentFailureCode}
              />
            )}
          </Auxiliary>
        )}
      </div>
    );
  }

  private static getValidatedAddresses(state: IPaymentState) {
    const { billingAddress, billingZipCodeError, customerAddress } = state;
    const billing = buildShoppingAddress(billingAddress, billingZipCodeError);
    const customer = buildShoppingAddress(customerAddress, false);

    return { billing, customer };
  }

  private renderCustomerAddress() {
    const { customerAddress } = this.state;

    return (
      <div className={styles.block}>
        <div className={styles.title}>Address</div>
        <div className={styles.form}>
          <ShoppingAddress
            {...customerAddress}
            zipCodeError={false}
            placeholder="Street Address"
            onAddressSubmit={this.recalculateTax}
            onAddressComponentsChange={this.onCustomerAddressChange}
            onScrollToSelector={this.onScrollToSelector}
            className={styles.customerAddress}
            emptyClassName={styles.emptyCustomerAddress}
          />
        </div>
      </div>
    );
  }

  private get taxAmounts() {
    const service = this.state.values.service!;
    const { calculateServiceTaxState } = this.props;
    const serviceMatches = calculateServiceTaxState.query?.serviceId === service.id;

    return {
      initialTaxAmount:
        !serviceMatches || !calculateServiceTaxState.initialTax
          ? undefined
          : calculateServiceTaxState.initialTax.taxAmount,
      totalTaxAmount:
        !serviceMatches || !calculateServiceTaxState.totalTax ? undefined : calculateServiceTaxState.totalTax.taxAmount
    };
  }

  private get validatedAddresses() {
    return PaymentStep.getValidatedAddresses(this.state);
  }

  private static showCustomerAddress = (showBilling: boolean) => {
    return showTaxes() && !showBilling;
  };

  private showCustomerAddress(showBilling: boolean) {
    return PaymentStep.showCustomerAddress(showBilling);
  }

  private buildCalculateServiceTaxQuery(): ICalculateServiceTaxQuery | null {
    if (!showTaxes()) {
      return null;
    }

    const service = this.state.values.service!;
    const showBilling = !isFreeService(service);
    const { validatedAddresses } = this;
    const showCustomerAddress = this.showCustomerAddress(showBilling);

    let shipTo: IShippingInfo | null = null;

    if (showBilling) {
      if (validatedAddresses.billing) {
        shipTo = {
          shippingAddress: validatedAddresses.billing
        };
      }
    } else if (showCustomerAddress) {
      if (validatedAddresses.customer) {
        shipTo = {
          shippingAddress: validatedAddresses.customer
        };
      }
    }

    return shipTo
      ? {
          serviceId: service.id,
          ...shipTo
        }
      : null;
  }

  private recalculateTax = () => {
    this.setState({ calculateServiceTaxQuery: this.buildCalculateServiceTaxQuery() });
  };

  private calculateServiceTaxIfNeeded(prevCalculateServiceTaxQuery: ICalculateServiceTaxQuery | null) {
    if (
      prevCalculateServiceTaxQuery !== this.state.calculateServiceTaxQuery &&
      this.state.calculateServiceTaxQuery &&
      !_.isEqual(this.props.calculateServiceTaxState.query, this.state.calculateServiceTaxQuery)
    ) {
      this.props.onCalculateServiceTax(this.state.calculateServiceTaxQuery);
    }
  }

  private onCustomerAddressChange = (addressComponents: Partial<IShoppingAddressComponents>, callback?: () => void) => {
    this.setState(
      state => ({
        customerAddress: {
          ...state.customerAddress,
          ...addressComponents
        }
      }),
      callback
    );
  };

  private onBillingAddressChange = (addressComponents: Partial<IShoppingAddressComponents>, callback?: () => void) => {
    this.setState(
      state => ({
        billingAddress: {
          ...state.billingAddress,
          ...addressComponents
        }
      }),
      callback
    );
  };

  private onScrollToSelector = (selector: string, customHeight?: number) =>
    scrollToSelector(selector, this.containerRef, this.props.onEnsureVisible, customHeight);

  private static isValid(props: IPaymentProps, state: IPaymentState) {
    const { values, isContactInfoValid, isCardInfoValid } = state;

    const validatedAddresses = PaymentStep.getValidatedAddresses(state);
    const showBilling = !isFreeService(values.service!);
    const showCustomerAddress = PaymentStep.showCustomerAddress(showBilling);

    const billingIsValid = !showBilling || (!!validatedAddresses.billing && isCardInfoValid);
    const customerAddressIsValid = !showCustomerAddress || !!validatedAddresses.customer;
    const taxesAreValid =
      !showTaxes() ||
      (!!state.calculateServiceTaxQuery &&
        _.isEqual(props.calculateServiceTaxState.query, state.calculateServiceTaxQuery) &&
        props.calculateServiceTaxState.status === "Success");

    return isContactInfoValid && billingIsValid && customerAddressIsValid && taxesAreValid;
  }

  public isValid = (): boolean => {
    return PaymentStep.isValid(this.props, this.state);
  };

  public saveAndContinue = (direction: Direction): void => {
    this.saveInfo();
    if (this.isValid() && direction === Direction.Forward) {
      this.createBooking();
    }
  };

  public deferNavigation = (): boolean => {
    return true;
  };

  private onNameChange = (name: string) => {
    this.setState({
      name
    });
  };

  private onEmailChange = (email: string) => {
    this.setState({
      email
    });
  };

  private onPhoneNumberChange = (phoneNumber: string) => {
    this.setState({
      phoneNumber
    });
  };

  private onCardholderNameChange = (cardholderName: string) => {
    this.setState({
      cardholderName
    });
  };

  private onContactInfoValidationChanged = (isContactInfoValid: boolean) => {
    this.setState({
      isContactInfoValid
    });
  };

  private onCardInfoValidationChanged = (isCardInfoValid: boolean) => {
    this.setState({
      isCardInfoValid
    });
  };

  private saveInfo = () => {
    const { email, name, phoneNumber, cardholderName, billingAddress, customerAddress } = this.state;
    if (this.infoChanged(email, name, phoneNumber, cardholderName, billingAddress)) {
      this.props.updateValues({
        email,
        name,
        phoneNumber,
        cardholderName,
        billingAddress,
        customerAddress
      });
    }
  };

  private createBooking = () => {
    const { name, email, cardholderName, phoneNumber } = this.state;
    const { service, dateTime, shootLocation, additionalInfo } = this.state.values;
    const { stripe, onCreateBooking, onCreateFreeServiceBooking } = this.props;
    const showBilling = !isFreeService(service!);
    const { validatedAddresses, taxAmounts } = this;
    const dateTimeInfo = splitDateTime(dateTime);
    const showCustomerAddress = this.showCustomerAddress(showBilling);

    const bookingCreator: IBookingCreator = {
      booking: {
        serviceId: service!.id,
        bookingDate: dateTimeInfo.date!.format("YYYY-MM-DD"),
        offsetMinutes: dateTimeInfo.time!,
        additionalInfo: service!.askAdditionalInfo ? additionalInfo! : null,
        serviceDateModifiedUtc: service!.dateModifiedUtc
      },
      bookingShootLocation: shootLocation!,
      customer: {
        name,
        email,
        phoneNumber,
        useStrongCustomerAuthentication: true
      },
      billingAddress: showBilling ? validatedAddresses.billing : null,
      customerAddress: showCustomerAddress ? validatedAddresses.customer : null,
      expectedInitialTax: taxAmounts.initialTaxAmount,
      expectedTotalTax: taxAmounts.totalTaxAmount
    };

    if (!showBilling) {
      onCreateFreeServiceBooking(bookingCreator);
    } else if (validatedAddresses.billing) {
      const paymentCreator: IPaymentCreator = {
        stripe: stripe!,
        paymentMethodOptions: buildPaymentMethodOptions(email, phoneNumber, cardholderName, validatedAddresses.billing)
      };

      onCreateBooking(paymentCreator, bookingCreator);
    }
  };

  private infoChanged(
    email: string,
    name: string,
    phoneNumber: string,
    cardholderName: string,
    billingAddress: IShoppingAddressComponents
  ) {
    const values = this.props.getValues();
    return (
      values.email !== email ||
      values.name !== name ||
      values.phoneNumber !== phoneNumber ||
      values.cardholderName !== cardholderName ||
      values.billingAddress !== billingAddress
    );
  }

  private readonly containerRef = React.createRef<HTMLDivElement>();
}

export default PaymentStep;
