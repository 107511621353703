import React, { FunctionComponent } from "react";
import IconBulletedList from "../../../assets/svg/bulleted-list";
import { isBlockActive } from "../../../common/transforms";

import { useSlate } from "slate-react";
import { ToolbarButton } from "../../../components/toolbar-button";
import { BLOCK_UL } from "../../../core/types";
import { toggleList } from "../../../utils/toggleList";

interface OwnProps {}

type Props = OwnProps;

const ButtonBulletedList: FunctionComponent<Props> = props => {
  const editor = useSlate();

  return (
    <ToolbarButton
      onMouseDown={() => {
        toggleList(editor, BLOCK_UL);
      }}
      active={isBlockActive(editor, BLOCK_UL)}
    >
      <IconBulletedList />
    </ToolbarButton>
  );
};

export { ButtonBulletedList };
