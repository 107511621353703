import _ from "lodash";
import IError from "../../../utilities/error";
import { ActionStatus } from "../../common";
import {
  ApplyGoogleCalendarDisconnectAction,
  LoadAction,
  UpdateBookableHoursAction,
  UpdateGoogleCalendarAction
} from "./actions";
import { ActionType } from "./constants";
import { IAvailability, IBookableHours } from "./model";

export interface IState {
  availability: IAvailability | null;
  load: ILoadState;
  updateBookableHours: IUpdateBookableHoursState;
  updateGoogleCalendar: IUpdateGoogleCalendarState;
}

interface ILoadState {
  status: ActionStatus;
  error: IError | null;
}

interface IUpdateBookableHoursState {
  status: ActionStatus;
  updater: IBookableHours | null;
  error: IError | null;
}

interface IUpdateGoogleCalendarState {
  status: ActionStatus;
  calendarId: string | null;
  error: IError | null;
}

const initialLoadState: ILoadState = {
  status: "Init",
  error: null
};

const initialUpdateBookableHoursState: IUpdateBookableHoursState = {
  status: "Init",
  updater: null,
  error: null
};

const initialUpdateGoogleCalendarState: IUpdateGoogleCalendarState = {
  status: "Init",
  calendarId: null,
  error: null
};

const initialState: IState = {
  availability: null,
  load: initialLoadState,
  updateBookableHours: initialUpdateBookableHoursState,
  updateGoogleCalendar: initialUpdateGoogleCalendarState
};

export const availability = (
  state: IState = initialState,
  action: LoadAction | UpdateBookableHoursAction | UpdateGoogleCalendarAction | ApplyGoogleCalendarDisconnectAction
): IState => {
  switch (action.type) {
    case ActionType.LOAD_STARTED:
      return {
        ...state,
        availability: null,
        load: {
          status: "Pending",
          error: null
        },
        updateBookableHours: initialUpdateBookableHoursState
      };
    case ActionType.LOAD_SUCCESS:
      return {
        ...state,
        availability: action.availability,
        load: {
          status: "Success",
          error: null
        }
      };
    case ActionType.LOAD_ERROR:
      return {
        ...state,
        availability: null,
        load: {
          status: "Error",
          error: action.error
        }
      };
    case ActionType.UPDATE_BOOKABLE_HOURS_STARTED:
      return {
        ...state,
        updateBookableHours: {
          status: "Pending",
          updater: action.updater,
          error: null
        }
      };
    case ActionType.UPDATE_BOOKABLE_HOURS_SUCCESS:
      return _.isEqual(action.updater, state.updateBookableHours.updater)
        ? {
            ...state,
            availability: {
              ...state.availability!,
              bookableHours: {
                ...action.updater,
                lastUpdate: new Date()
              }
            },
            updateBookableHours: {
              ...state.updateBookableHours,
              status: "Success",
              error: null
            }
          }
        : state;
    case ActionType.UPDATE_BOOKABLE_HOURS_ERROR:
      return _.isEqual(action.updater, state.updateBookableHours.updater)
        ? {
            ...state,
            updateBookableHours: {
              ...state.updateBookableHours,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.UPDATE_BOOKABLE_HOURS_RESET:
      return {
        ...state,
        updateBookableHours: initialUpdateBookableHoursState
      };
    case ActionType.UPDATE_GOOGLE_CALENDAR_STARTED:
      return {
        ...state,
        updateGoogleCalendar: {
          status: "Pending",
          calendarId: action.calendarId,
          error: null
        }
      };
    case ActionType.UPDATE_GOOGLE_CALENDAR_SUCCESS:
      return action.calendarId === state.updateGoogleCalendar.calendarId
        ? {
            ...state,
            availability: {
              ...state.availability!,
              googleCalendar: {
                ...state.availability!.googleCalendar!,
                selected: { id: action.calendarId, name: action.calendarName }
              }
            },
            updateGoogleCalendar: {
              ...state.updateGoogleCalendar,
              status: "Success",
              error: null
            }
          }
        : state;
    case ActionType.UPDATE_GOOGLE_CALENDAR_ERROR:
      return action.calendarId === state.updateGoogleCalendar.calendarId
        ? {
            ...state,
            updateGoogleCalendar: {
              ...state.updateGoogleCalendar,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.APPLY_GOOGLE_CALENDAR_DISCONNECT:
      return {
        ...state,
        availability: {
          ...state.availability!,
          googleCalendar: null
        }
      };
    default:
      return state;
  }
};

export default availability;
