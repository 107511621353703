import { ActionStatus } from "../../models";
import {
  IAddress,
  IAddressPrediction,
  IAddressQuery,
  ICoordinates as ILocationCoordinates,
  IPlaceDetails
} from "../../models/location";
import { IError } from "../../utilities/helpers";

export interface ISearchAddress {
  status: ActionStatus;
  query: IAddressQuery | null;
  predictions: IAddressPrediction[] | null;
  currentLocation: ILocationCoordinates | null;
  error: IError | null;
  onSearch: (query: IAddressQuery) => void;
  onReset: () => void;
  onClearPredictions: () => void;
}

export interface ILoadPlaceDetails {
  status: ActionStatus;
  placeId: string | null;
  placeDetails: IPlaceDetails | null;
  error: IError | null;
  onLoad: (placeId: string) => void;
}

export interface IGeoCodePlaceDetails {
  location: ICoordinates | null;
  placeDetails: IPlaceDetails | null;
  error: IError | null;
  loadPlaceDetailsFromLocation: (location: ICoordinates) => void;
  loadPlaceDetailsFromIp: () => void;
  loadPlaceDetailsFromLocationOrIpReset: () => void;
  loadFromLocationStatus: ActionStatus;
  loadFromIpStatus: ActionStatus;
}

export interface ICoordinates {
  lat: number;
  lng: number;
}

export interface IBaseAddressInfo {
  value: string;
  isComplete: boolean;
  coordinates: ICoordinates | null;
}

export interface IAddressInfo extends IBaseAddressInfo {
  errorMessage: string;
}

export type AddressInputValue = string | IAddress | IAddressPrediction | null;

export const MaxSearchAddressRadius = 30;

export function isAddressPrediction(address: AddressInputValue): address is IAddressPrediction {
  return !!address && typeof address !== "string" && !("streetName" in address);
}

export function isAddress(address: AddressInputValue): address is IAddress {
  return !!address && typeof address !== "string" && "streetName" in address;
}
