import classNames from "classnames";
import * as React from "react";
import styles from "./index.module.scss";
import radioBtnOff from "./radio-btn-off.svg";
import radioBtnOn from "./radio-btn-on.svg";

interface IFormRadioProps {
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  checked: boolean;
  name: string;
  tabIndex?: number;
}

export class FormRadio extends React.Component<IFormRadioProps> {
  public render() {
    const { className, children, onChange, checked, tabIndex, onBlur, ...attrs } = this.props;
    const labelClasses = classNames(className, styles.label);

    return (
      <div className={styles.container}>
        <label className={labelClasses}>
          <input {...attrs} type="radio" checked={checked} onChange={onChange} tabIndex={tabIndex} onBlur={onBlur} />
          <span aria-hidden="true">
            {checked ? <img src={radioBtnOn} alt="checked icon" /> : <img src={radioBtnOff} alt="checked icon" />}
          </span>
          <div className={styles.description}>{children}</div>
        </label>
      </div>
    );
  }
}
