import videojs from "video.js";
import "videojs-contrib-quality-levels";
import { getQualityValue } from "../utils";

const Plugin = videojs.getPlugin("plugin");

export interface IPlaybackStatsPluginOptions {}

export type PlaybackStatsRecordSource = "quality-change" | "played-to-end" | "seek" | "stop" | "page-hide";

export interface IPlaybackStatsRecord {
  /** Start time (in seconds) of the record relative to the beginning of the video. */
  offset: number;
  /**
   * Duration (in seconds) of the record. It can be 0 in special cases: when "stop" or "page-hide" events
   * occur but there is no last record to push, a 0 duration record will still be generated.
   */
  duration: number;
  /** Video quality of the record. */
  quality: number;
  /** The source (event / action) that generated the record. */
  source: PlaybackStatsRecordSource;
}

class PlaybackStatsPlugin extends Plugin {
  constructor(player: videojs.Player, options: IPlaybackStatsPluginOptions) {
    super(player, options);

    player.qualityLevels().on("change", () => {
      this.pushRecord("quality-change");
      const qualityLevels = player.qualityLevels();
      const selectedQuality = qualityLevels[qualityLevels.selectedIndex];
      this.currentQuality =
        selectedQuality.width && selectedQuality.height ? getQualityValue(selectedQuality) : undefined;
    });
    player.on("play", () => {
      if (player.currentTime() === 0) {
        this.startRecord();
      }
    });
    player.on("stop" /* this is a custom event */, () => {
      this.pushRecord("stop");
    });
    player.on("ended", () => {
      this.pushRecord("played-to-end");
    });
    player.on("timeupdate", () => {
      if (!player.paused()) {
        this.seeking = false;
        this.lastPlayedTime = this.player.currentTime();
      }
    });
    player.on("seeking", () => {
      if (!this.seeking) {
        this.pushRecord("seek");
      }
      this.startRecord();
      this.seeking = true;
    });

    const onDocumentVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        this.pushRecord("page-hide");
      }
    };
    document.addEventListener("visibilitychange", onDocumentVisibilityChange);
    this.on("dispose", () => {
      document.removeEventListener("visibilitychange", onDocumentVisibilityChange);
    });

    const onWindowPageHide = () => {
      this.pushRecord("page-hide");
    };
    window.addEventListener("pagehide", onWindowPageHide);
    this.on("dispose", () => {
      window.removeEventListener("pagehide", onWindowPageHide);
    });
  }

  public dispose() {
    this.pushRecord("stop");
    super.dispose();
    this.disposed = true;
  }

  private startRecord() {
    this.recordStart = this.lastPlayedTime = this.currentTime;
  }

  private pushRecord(source: PlaybackStatsRecordSource) {
    if (this.disposed) {
      return;
    }

    if (this.currentQuality === undefined) {
      this.startRecord();
      return;
    }

    const recordEnd = source === "seek" || source === "stop" ? this.lastPlayedTime : this.currentTime;
    const duration = recordEnd - this.recordStart;

    const specialRecord = source === "stop" || source === "page-hide";
    const ignoreRecord = duration === 0 && (!specialRecord || this.pendingRecords === 0);
    if (ignoreRecord) {
      return;
    }

    this.trigger({
      type: "record",
      record: { offset: this.recordStart, duration, quality: this.currentQuality, source }
    });

    if (specialRecord) {
      this.pendingRecords = 0;
    } else {
      this.pendingRecords++;
    }

    this.startRecord();
  }

  private get currentTime() {
    try {
      return this.player.currentTime();
    } catch {
      return this.lastPlayedTime;
    }
  }

  private recordStart: number = 0;
  private lastPlayedTime: number = 0;
  private currentQuality?: number;
  private seeking: boolean = false;
  private pendingRecords: number = 0;
  private disposed: boolean = false;
}

videojs.registerPlugin("playbackStatsPlugin", PlaybackStatsPlugin);

declare module "video.js" {
  export interface VideoJsPlayer {
    playbackStatsPlugin(options?: IPlaybackStatsPluginOptions): PlaybackStatsPlugin;
  }
}

export default PlaybackStatsPlugin;
