import { ActivityStatusCode } from "../../../../../store/billing/model";
import { ActionStatus } from "../../../../../store/common";
import IError from "../../../../../utilities/error";

export interface ILoad {
  status: ActionStatus;
  bookingId?: string;
  error?: IError;
  onLoad: (bookingId: string) => void;
}

export type ActivityActionCode =
  | "mark-as-paid"
  | "send-invoice"
  | "resend-invoice"
  | "delete-invoice"
  | "edit-invoice"
  | "refund";

export const activityStatusActions: Record<ActivityStatusCode, ActivityActionCode[]> = {
  scheduled: ["mark-as-paid", "send-invoice", "delete-invoice", "edit-invoice"],
  "manually-sent": ["mark-as-paid", "resend-invoice", "delete-invoice", "edit-invoice"],
  "manually-send": ["mark-as-paid", "send-invoice", "delete-invoice", "edit-invoice"],
  sent: ["mark-as-paid", "resend-invoice", "delete-invoice", "edit-invoice"],
  paid: ["refund"],
  "marked-as-paid": [],
  refunded: []
};

export function getActivityActionName(code: ActivityActionCode) {
  return {
    "mark-as-paid": "Mark as Paid",
    "send-invoice": "Send",
    "resend-invoice": "Resend",
    "delete-invoice": "Delete",
    "edit-invoice": "Edit",
    refund: "Refund"
  }[code];
}
