import cx from "classnames";
import { FormTextArea } from "components/FormTextArea";
import * as React from "react";
import { Utilities } from "utilities/utilities";
import editPenIcon from "../../icons/edit-pen.svg";
import styles from "./inlineEdit.module.scss";

const { shouldComponentUpdateDeep, resolveValue } = Utilities;

export interface IElementProps {
  style?: React.CSSProperties;
  className?: string;
}

export interface IInputProps {
  containerClassName?: string;
  textAreaClassName?: string;
  errorClassName?: string;
  statusClassName?: string;
}

export interface IInlineEditProps {
  value?: string;
  defaultValue?: string;
  defaultEditing?: boolean;
  editing?: boolean;
  multilineView?: boolean;
  maxLength?: number;
  errorMessage?: string;
  inputDisabled?: boolean;
  editDisabled?: boolean;
  allowedCharsRegex?: string;
  autoSelect?: boolean;
  onEdit?: (value: string) => void;
  onChange?: (value: string, committedValue: string) => void;
  onCommit?: (value: string, committedValue: string) => void;
  onCancel?: (value: string, committedValue: string) => void;
  className?: string;
  style?: React.CSSProperties;
  valueElement?: IElementProps;
  editElement?: IElementProps;
  inputElement?: IInputProps;
}

interface IInlineEditState {
  editing?: boolean;
  value?: string;
  committedValue: string;
}

class InlineEdit extends React.Component<IInlineEditProps, IInlineEditState> {
  public state: IInlineEditState = { committedValue: this.value };

  public componentDidMount() {
    const { editing, defaultEditing, autoSelect } = this.props;
    if (editing || defaultEditing) {
      this.editorRef.current!.focus(!autoSelect, autoSelect);
    }
  }

  public componentDidUpdate(prevProps: IInlineEditProps) {
    const hasStartedControlledEditing = this.props.editing && prevProps.editing === false;
    if (hasStartedControlledEditing) {
      this.editorRef.current!.focus(true);
    }
  }

  public shouldComponentUpdate(nextProps: IInlineEditProps, nextState: IInlineEditState): boolean {
    return shouldComponentUpdateDeep(this, nextProps, nextState);
  }

  public render() {
    const { multilineView, errorMessage, maxLength, inputDisabled, editDisabled, className, style } = this.props;
    const { valueElement, editElement, inputElement, allowedCharsRegex } = this.props;
    const { editing, value } = this;

    return (
      <div className={cx(styles.container, className)} style={style}>
        {!editing && (
          <span
            className={cx(styles.value, { [styles.multiLine]: multilineView }, valueElement?.className)}
            style={valueElement?.style}
          >
            {value}
          </span>
        )}
        {editing && (
          <FormTextArea
            value={value}
            maxLength={maxLength}
            autoSize={true}
            errorMessage={errorMessage}
            forceShowingError={true}
            disabled={inputDisabled}
            charCount={false}
            allowedCharsRegex={allowedCharsRegex}
            className={cx(styles.input, inputElement?.containerClassName)}
            textAreaClassName={cx(styles.textArea, errorMessage && styles.error, inputElement?.textAreaClassName)}
            errorClassName={cx(styles.error, inputElement?.errorClassName)}
            statusClassName={cx(styles.status, inputElement?.statusClassName)}
            ref={this.editorRef}
            onChange={this.onInputChange}
            onBlur={this.onInputBlur}
            onKeyDown={this.onInputKeyDown}
          />
        )}
        <img
          src={editPenIcon}
          alt="Edit"
          onClick={this.onEditClick}
          className={cx(styles.edit, { [styles.hidden]: editing || editDisabled }, editElement?.className)}
          style={editElement?.style}
        />
      </div>
    );
  }

  private get editing() {
    return resolveValue(this.props.editing, this.state.editing, this.props.defaultEditing, false);
  }

  private get value() {
    return resolveValue(this.props.value, this.state?.value, this.props.defaultValue, "");
  }

  private onEditClick = () => {
    if (this.props.editing === undefined) {
      this.setState({ editing: true }, () => this.editorRef.current!.focus(true));
    }
    this.setState({ committedValue: this.value });
    this.props.onEdit?.(this.value);
  };

  private onInputChange = (value: string) => {
    if (this.props.value === undefined) {
      this.setState({ value });
    }
    this.props.onChange?.(value, this.state.committedValue);
  };

  private onInputBlur = () => {
    this.commit();
  };

  private onInputKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Escape") {
      this.cancel();
    } else if (event.key === "Enter") {
      this.commit();
      event.preventDefault();
    }
  };

  private commit() {
    if (this.props.editing === undefined && !this.props.errorMessage) {
      this.setState({ editing: false });
    }
    this.props.onCommit?.(this.value, this.state.committedValue);
  }

  private cancel() {
    if (this.props.value === undefined) {
      this.setState({ value: this.state.committedValue });
    }
    if (this.props.editing === undefined) {
      this.setState({ editing: false });
    }
    this.props.onCancel?.(this.value, this.state.committedValue!);
  }

  private readonly editorRef = React.createRef<FormTextArea>();
}

export default InlineEdit;
