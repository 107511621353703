import React from "react";
import IIConProps, { initializeIconProps } from "../IIconProps";

const CaretDown: React.FC<IIConProps> = rawProps => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox={`${props.minX} ${props.minY} ${props.size} ${props.size}`}
    >
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm6.505 15.671L20 22.99l-6.505-7.319a2 2 0 1 0-2.99 2.658l8 9a2 2 0 0 0 2.99 0l8-9a2 2 0 1 0-2.99-2.658z"
      />
    </svg>
  );
};

export default CaretDown;
