import React, { ReactNode } from "react";
import { MaxPrice } from "../../../../components/services/serviceEditOrCreate/constants";
import { IActivity, IBilling } from "../../../../store/billing/model";
import { IBookingBillingModalProps } from "../../../../store/modal/model";
import eventTracker from "../../../../utilities/eventTracker";
import withModal, { InjectedModalProps } from "../../withModal";
import Activities from "./activities";
import { ActivityActionCode } from "./activities/model";
import AddInvoiceSetup from "./addInvoice";
import BillingModal from "./billingModal";
import DeleteInvoice from "./deleteInvoice";
import EditInvoiceSetup from "./editInvoice";
import GenericError from "./genericError";
import MarkAsPaid from "./markAsPaid";
import Refund from "./refund";
import RefundSetup from "./refundSetup";
import SendInvoice from "./sendInvoice";
import withStore from "./withStore";

export type BillingModalCode = "activities" | "refund-setup" | "generic-error" | "add-invoice" | ActivityActionCode;

interface IBookingBillingProps extends InjectedModalProps<IBookingBillingModalProps> {
  billing?: IBilling;
  onReset: () => void;
}

interface IBookingBillingState {
  modal: BillingModalCode;
  actionActivity?: IActivity;
  refundAmount?: number;
  totalAmount: number;
}

class BookingBilling extends React.Component<IBookingBillingProps, IBookingBillingState> {
  constructor(props: IBookingBillingProps) {
    super(props);

    this.state = {
      modal: "activities",
      totalAmount: props.totalAmount
    };
  }

  public componentDidUpdate(prevProps: IBookingBillingProps, prevState: IBookingBillingState) {
    if (this.props.billing && this.props.billing !== prevProps.billing) {
      this.setState({
        totalAmount: this.props.billing.totalAmount
      });
    }
  }

  public componentDidMount() {
    eventTracker.context.photographer.bookingId = this.props.bookingId;
  }

  public componentWillUnmount() {
    eventTracker.context.photographer.bookingId = undefined;
    this.props.onReset();
  }

  public render() {
    const { modal } = this.state;

    const modalRenderers: Record<BillingModalCode, () => ReactNode> = {
      activities: () => this.renderActivities(),
      "refund-setup": () => this.renderRefundSetup(),
      refund: () => this.renderRefund(),
      "send-invoice": () => this.renderSendInvoice(),
      "resend-invoice": () => this.renderSendInvoice(true),
      "edit-invoice": () => this.renderEditInvoice(),
      "delete-invoice": () => this.renderDeleteInvoice(),
      "add-invoice": () => this.renderAddInvoice(),
      "mark-as-paid": () => this.renderMarkAsPaid(),
      "generic-error": () => this.renderGenericError()
    };

    return modalRenderers[modal]();
  }

  private get baseBillingModalProps() {
    const { billing } = this.props;
    return {
      paidAmount: billing === undefined ? undefined : billing.paidAmount,
      remainingAmount: billing === undefined ? undefined : billing.remainingAmount
    };
  }

  private renderActivities() {
    const { bookingId } = this.props;
    const { totalAmount } = this.state;

    return (
      <BillingModal
        title={"BILLING"}
        {...this.baseBillingModalProps}
        headerAmount={totalAmount}
        headerAmountType="total"
        onClose={this.onClose}
      >
        <Activities
          bookingId={bookingId}
          onActivityAction={this.onActivityAction}
          onAddInvoice={this.onAddInvoiceSetup}
        />
      </BillingModal>
    );
  }

  private renderRefundSetup() {
    const activity = this.state.actionActivity!;

    return (
      <BillingModal
        title={"BILLING"}
        {...this.baseBillingModalProps}
        headerAmount={activity.availableRefundAmount!}
        headerAmountType="available-refund"
        onClose={this.onRefundSetupClose}
      >
        <RefundSetup
          maxAmount={activity.availableRefundAmount!}
          onCancel={this.onRefundSetupCancel}
          onRefund={this.onRefundSetupRefund}
        />
      </BillingModal>
    );
  }

  private renderSendInvoice(resend?: boolean) {
    const invoice = this.state.actionActivity!;

    return (
      <SendInvoice
        invoiceId={invoice.id}
        invoiceAmount={invoice.amount}
        onClose={this.onSendInvoiceClose}
        resend={resend}
      />
    );
  }

  private renderEditInvoice() {
    const { totalAmount } = this.state;
    const { isTaxable } = this.props;
    const invoice = this.state.actionActivity!;
    return (
      <BillingModal
        title={"EDIT INVOICE"}
        {...this.baseBillingModalProps}
        headerAmount={totalAmount}
        headerAmountType="service-amount"
        onClose={this.onEditInvoiceSetupClose}
        bodyOnly={true}
      >
        <EditInvoiceSetup
          invoiceId={invoice.id}
          maxAmount={MaxPrice}
          minAmount={invoice.minAmount}
          amount={invoice.amount - invoice.taxAmount}
          isTaxable={isTaxable}
          initialTaxAmount={invoice.taxAmount}
          onCancel={this.onEditInvoiceSetupCancel}
          onSave={this.onEditInvoiceSetupSave}
          onError={this.onGenericError}
        />
      </BillingModal>
    );
  }

  private renderAddInvoice() {
    const { totalAmount } = this.state;
    const { bookingId, isTaxable } = this.props;

    return (
      <BillingModal
        title={"ADD NEW INVOICE"}
        {...this.baseBillingModalProps}
        headerAmount={totalAmount}
        headerAmountType="service-amount"
        onClose={this.onAddInvoiceSetupClose}
        bodyOnly={true}
      >
        <AddInvoiceSetup
          bookingId={bookingId}
          maxAmount={MaxPrice}
          isTaxable={isTaxable}
          onCancel={this.onAddInvoiceSetupCancel}
          onSave={this.onAddInvoiceSetupSave}
          onError={this.onGenericError}
        />
      </BillingModal>
    );
  }

  private renderDeleteInvoice() {
    const invoice = this.state.actionActivity!;
    const newRemainingAmount = this.props.billing!.remainingAmount - invoice.amount;

    return (
      <DeleteInvoice
        invoiceId={invoice.id}
        newRemainingAmount={newRemainingAmount}
        onClose={this.onDeleteInvoiceClose}
      />
    );
  }

  private renderRefund() {
    const activity = this.state.actionActivity!;
    return (
      <Refund
        activityId={activity.id}
        activityType={activity.type.code}
        amount={this.state.refundAmount!}
        onClose={this.onRefundClose}
        onError={this.onGenericError}
      />
    );
  }

  private renderMarkAsPaid() {
    const invoice = this.state.actionActivity!;
    return (
      <MarkAsPaid
        invoiceId={invoice.id}
        photographerBalanceChargeAmount={invoice.photographerBalanceChargeAmount}
        onClose={this.onMarkAsPaidClose}
        onError={this.onGenericError}
      />
    );
  }

  private renderGenericError() {
    return <GenericError onClose={this.onGenericErrorClose} />;
  }

  private goToActivities = () =>
    this.setState({ modal: "activities", actionActivity: undefined, refundAmount: undefined });

  private onActivityAction = (activity: IActivity, action: ActivityActionCode) =>
    activityActionModal[action] && this.setState({ modal: activityActionModal[action]!, actionActivity: activity });

  private onRefundSetupRefund = (amount: number) => this.setState({ modal: "refund", refundAmount: amount });
  private onAddInvoiceSetup = () => this.setState({ modal: "add-invoice" });

  private onClose = () => this.props.onHideModal();
  private onRefundSetupClose = () => this.goToActivities();
  private onRefundSetupCancel = () => this.goToActivities();

  private onEditInvoiceSetupClose = () => this.goToActivities();
  private onEditInvoiceSetupCancel = () => this.goToActivities();
  private onEditInvoiceSetupSave = () => this.goToActivities();

  private onAddInvoiceSetupClose = () => this.goToActivities();
  private onAddInvoiceSetupCancel = () => this.goToActivities();
  private onAddInvoiceSetupSave = () => this.goToActivities();

  private onSendInvoiceClose = () => this.goToActivities();
  private onDeleteInvoiceClose = () => this.goToActivities();

  private onRefundClose = () => this.goToActivities();
  private onMarkAsPaidClose = () => this.goToActivities();

  private onGenericError = () => this.setState({ modal: "generic-error" });
  private onGenericErrorClose = () => this.goToActivities();
}

const activityActionModal: Record<ActivityActionCode, BillingModalCode> = {
  "mark-as-paid": "mark-as-paid",
  "send-invoice": "send-invoice",
  "resend-invoice": "resend-invoice",
  "delete-invoice": "delete-invoice",
  "edit-invoice": "edit-invoice",
  refund: "refund-setup"
};

export default withModal(withStore(BookingBilling));
