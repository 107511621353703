import React from "react";
import styles from "./loading.module.scss";
import spinner from "./spinner.svg";

interface ILoading {
  styles?: React.CSSProperties;
}

const Loading: React.SFC<ILoading> = props => (
  <div className={styles.container} style={props.styles}>
    <div className={styles.elements}>
      <img className={styles.spinner} alt="Spinner" src={spinner} />
      <p className={styles.textLoading}>Loading...</p>
    </div>
  </div>
);

export default Loading;
