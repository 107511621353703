import classNames from "classnames";
import React from "react";
import { Utilities } from "utilities/utilities";
import {
  FormTextInputBase,
  IFormTextInputPropsBase,
  IFormTextInputStateBase
} from "../FormTextInput/FormTextInputBase";
import { TextArea } from "../TextArea";
import styles from "./formTextArea.module.scss";

interface IFormTextAreaProps extends IFormTextInputPropsBase<HTMLTextAreaElement> {
  textAreaClassName?: string;
  statusClassName?: string;
  charCountClassName?: string;
  charCount?: boolean;
  autoSize?: boolean | "height";
}

// tslint:disable-next-line:no-empty-interface
interface IFormTextAreaState extends IFormTextInputStateBase {}

export class FormTextArea extends FormTextInputBase<HTMLTextAreaElement, IFormTextAreaProps, IFormTextAreaState> {
  public constructor(props: IFormTextAreaProps) {
    super(props, { displayError: false });
  }

  public render() {
    const props = this.props;
    const errorMessage = this.errorMessage;
    const textAreaClassName = classNames(styles.textArea, props.textAreaClassName, {
      [classNames(styles.error, props.withErrorClassName)]: errorMessage
    });

    return (
      <TextArea
        id={props.id}
        disabled={props.disabled}
        onChange={this.onChange}
        placeholder={props.placeholder}
        value={this.value}
        readonly={props.readonly}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        ref={Utilities.combineRefsCached(this.inputRef)(props.inputRef)}
        errorMessage={errorMessage}
        className={props.className}
        textAreaClassName={textAreaClassName}
        statusClassName={props.statusClassName}
        errorClassName={props.errorClassName}
        charCountClassName={props.charCountClassName}
        tabIndex={props.tabIndex}
        maxLength={props.maxLength}
        charCount={props.charCount}
        autoSize={props.autoSize}
      />
    );
  }
}
