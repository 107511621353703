import React, { FC, useEffect, useRef, useState } from "react";

import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks";
import { IBlogPostCategoryInfo, IBlogPostInfo, IBlogPostsInfo, IBlogPostsQuery } from "./model";

import cx from "classnames";
import Button from "../../Button";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";
import { getBackgroundColor } from "utilities/blocks/blockColors";
import { getSiteFonts } from "utilities/blocks/site";
import useIsMounted from "../../../hooks/useIsMounted";
import usePrevious from "../../../hooks/usePrevious";
import Loading from "../../../icons/loading-no-bg.svg";
import { IPhotoFull } from "utilities/getImgUrl";
import { buildPublicPhotoInfosMap } from "utilities/blocks/photo";
import { ZenCategoryFilterBlock, ALL_POSTS_CATEGORY_ID, ICategoryFilterItem } from "../zenCategoryFilterBlock";
import ZenLoading from "../zenLoading";
import ZenPagination from "../zenPagination";
import BlogPosts, { IBlogPosts } from "./BlogPosts";
import FeaturedPost from "./FeaturedPost";
import iconBlogPage from "./icon-blog-page.svg";
import iconTextBubble from "./icon-text-bubble.svg";
import styles from "./zenBlogLandingBlock.module.scss";

export interface IZenBlogLandingBlockProps extends IZenBaseBlockProps {
  publishedMode: boolean;
  photoUrlTemplate: string;
  enableFeaturedBlogPost: boolean;
  allBlogPostsIncludeFeatured: boolean;
  enableBlogPostCategories: boolean;
  initialBlogPosts?: IBlogPostsInfo;
  initialCategoryName?: string;
  initialPage?: number;
  initialPhotosMap?: Record<string, IPhotoFull | null>;
  loadPhotoInfos: (photoIds: string[]) => Promise<any>;
  loadBlogPosts: (query: IBlogPostsQuery) => Promise<IBlogPostsInfo>;
  buildCategoryUrl?: (category?: IBlogPostCategoryInfo) => string;
  buildBlogPostUrl?: (blogPost: IBlogPostInfo) => string;
  onQueryChange?: (page: number, categoryName?: string) => void;
  onBlogPostSelect?: (blogPost: IBlogPostInfo) => void;
  onManagePosts?: () => void;
}

export const blogPostsPerPage = 6;

enum LoadingState {
  None,
  Partial,
  Full
}

interface ISelectedCategory {
  id: string;
  name?: string;
}

const allPostsCategory: ISelectedCategory = {
  id: ALL_POSTS_CATEGORY_ID
};

export function buildBlogPostsQuery(
  page: number,
  enableFeaturedBlogPost: boolean,
  allBlogPostsIncludeFeatured: boolean,
  enableBlogPostCategories: boolean,
  categoryName?: string
): IBlogPostsQuery {
  return {
    skip: (page - 1) * blogPostsPerPage,
    take: blogPostsPerPage,
    category: categoryName,
    enableFeaturedBlogPost,
    allBlogPostsIncludeFeatured,
    enableBlogPostCategories
  };
}

function addUniquePhotoId(
  photoIds: Set<string>,
  photosMap?: Record<string, IPhotoFull | null>,
  blogPost?: IBlogPostInfo
) {
  if (blogPost && photosMap?.[blogPost.coverPhotoId] === undefined) {
    photoIds.add(blogPost.coverPhotoId);
  }
}

function findSelectedCategory(allCategories: ICategoryFilterItem[], categoryName?: string) {
  let result: ISelectedCategory | undefined;
  if (categoryName) {
    const upperCategoryName = categoryName.toUpperCase();
    result = allCategories.find(c => c.name.toUpperCase() === upperCategoryName);
  }

  return result || allPostsCategory;
}

export async function refreshBlogPhotosMap(
  blogPosts: IBlogPostsInfo,
  loadPhotoInfos: (photoIds: string[]) => Promise<any>,
  photosMap?: Record<string, IPhotoFull | null>
) {
  const photoIdsSet = new Set<string>();
  addUniquePhotoId(photoIdsSet, photosMap, blogPosts.featuredPost);
  each(blogPosts.blogPosts, blogPost => addUniquePhotoId(photoIdsSet, photosMap, blogPost));

  if (photoIdsSet.size > 0) {
    let rawPhotoInfos: any;
    const photoIds = Array.from(photoIdsSet);

    try {
      rawPhotoInfos = await loadPhotoInfos(photoIds);
    } catch (e) {
      // treat errors as photos not found
    }

    return buildPublicPhotoInfosMap(photoIds, rawPhotoInfos, photosMap && { ...photosMap });
  }

  return photosMap;
}

export const ZenBlogLandingBlock: FC<IZenBlogLandingBlockProps> = props => {
  const {
    siteTheme,
    readOnly,
    divider,
    padding,
    desktopEnabled,
    tabletEnabled,
    mobileEnabled,
    publishedMode,
    photoUrlTemplate,
    enableFeaturedBlogPost,
    allBlogPostsIncludeFeatured,
    enableBlogPostCategories,
    initialBlogPosts,
    initialCategoryName,
    initialPage,
    initialPhotosMap,
    loadPhotoInfos,
    loadBlogPosts,
    buildCategoryUrl,
    buildBlogPostUrl,
    onQueryChange,
    onBlogPostSelect,
    onManagePosts
  } = props;

  const isMounted = useIsMounted();

  const blogPostsRef = useRef<IBlogPosts>(null);
  const [loading, setLoading] = useState(LoadingState.None);
  const [page, setPage] = useState(initialPage || 1);
  const [blogPosts, setBlogPosts] = useState(initialBlogPosts);
  const [photosMap, setPhotosMap] = useState(initialPhotosMap || {});
  const [allCategories, setAllCategories] = useState<ICategoryFilterItem[] | undefined>(() =>
    initialBlogPosts ? getAllCategories(initialBlogPosts) : undefined
  );
  const [category, setCategory] = useState<ISelectedCategory | undefined>(() =>
    allCategories ? findSelectedCategory(allCategories, initialCategoryName) : undefined
  );

  const prevLoading = usePrevious(loading);
  const prevSettings = usePrevious({ enableFeaturedBlogPost, allBlogPostsIncludeFeatured, enableBlogPostCategories });

  useEffect(onComponentMount, []);
  useEffect(onComponentUpdate);

  function onComponentMount() {
    if (!blogPosts && !publishedMode) {
      loadData();
    }
  }

  function onComponentUpdate() {
    if (
      prevSettings &&
      (prevSettings.enableFeaturedBlogPost !== enableFeaturedBlogPost ||
        prevSettings.allBlogPostsIncludeFeatured !== allBlogPostsIncludeFeatured ||
        prevSettings.enableBlogPostCategories !== enableBlogPostCategories)
    ) {
      setPage(1);
      setLoading(LoadingState.Full);
    }

    if (loading !== prevLoading && loading !== LoadingState.None) {
      loadData();
    }
  }

  function getAllCategories(blogPosts: IBlogPostsInfo) {
    return blogPosts.showCategories
      ? blogPosts.allCategories?.map(
          c =>
            ({
              id: c.id,
              name: c.name,
              url: buildCategoryUrl?.(c)
            } as ICategoryFilterItem)
        )
      : [];
  }

  async function loadData() {
    let blogPosts: IBlogPostsInfo | undefined;

    try {
      blogPosts = await loadBlogPosts(
        buildBlogPostsQuery(
          page,
          enableFeaturedBlogPost,
          allBlogPostsIncludeFeatured,
          enableBlogPostCategories,
          category ? category.name : initialCategoryName
        )
      );
    } catch (e) {
      // treat errors as blog posts not found
    }

    if (!blogPosts) {
      blogPosts = {
        blogPosts: [],
        count: 0,
        showCategories: false
      };
    }

    const newPhotosMap = await refreshBlogPhotosMap(blogPosts, loadPhotoInfos, photosMap);

    if (isMounted()) {
      if (newPhotosMap !== photosMap) {
        setPhotosMap(newPhotosMap!);
      }

      const fullReload = loading === LoadingState.Full;
      const newAllCategories = (!fullReload && allCategories) || getAllCategories(blogPosts) || [];
      const newCategory = (!fullReload && category) || findSelectedCategory(newAllCategories, initialCategoryName);

      if (newCategory !== category) {
        setCategory(newCategory);
      }

      if (newAllCategories !== allCategories) {
        setAllCategories(newAllCategories);
      }

      setBlogPosts(blogPosts);

      setLoading(LoadingState.None);
    }
  }

  function onCategorySelected(selectedCategory: ICategoryFilterItem | undefined) {
    if (loading === LoadingState.None) {
      const page = 1;
      const newCategory = selectedCategory || allPostsCategory;

      setPage(page);
      setCategory(newCategory);
      setLoading(LoadingState.Partial);

      onQueryChange?.(page, newCategory.name);
    }
  }

  function onPageChange(page: number) {
    if (loading === LoadingState.None && category) {
      setPage(page);
      setLoading(LoadingState.Partial);

      onQueryChange?.(page, category.name);
      blogPostsRef.current?.scrollIntoView();
    }
  }

  function onFeaturedPostClick() {
    const featuredPost = blogPosts?.featuredPost;
    if (featuredPost) {
      if (buildBlogPostUrl) {
        window.location.href = buildBlogPostUrl(featuredPost);
      } else {
        onBlogPostSelect?.(featuredPost);
      }
    }
  }

  function onManagePostsClick() {
    onManagePosts?.();
  }

  function renderInitializing() {
    return (
      <div className={cx(styles.container, styles.initializing)} style={{ marginBottom: `-${padding}` }}>
        <img className={styles.loading} src={Loading} alt="loading" />
      </div>
    );
  }

  function renderFeaturedPost() {
    const featuredPost = blogPosts?.featuredPost;

    const blogPostUrl = featuredPost && buildBlogPostUrl ? buildBlogPostUrl(featuredPost) : "";
    return (
      <FeaturedPost
        photoUrlTemplate={photoUrlTemplate}
        siteTheme={siteTheme}
        featuredBlogPost={featuredPost}
        photoInfo={(featuredPost && photosMap[featuredPost.coverPhotoId]) || undefined}
        onFeaturedPostClick={onFeaturedPostClick}
        blogPostUrl={blogPostUrl}
      />
    );
  }

  function renderEmptyEditMode() {
    return (
      <div className={cx(styles.container, styles.emptyEdit)}>
        {renderFeaturedPost()}
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <img src={iconBlogPage} alt="icon" />
            <p>You haven't published any blog posts yet</p>
            <Button className={styles.button} onClick={onManagePostsClick}>
              Manage Posts
            </Button>
          </div>
        </div>
      </div>
    );
  }

  function renderEmptyPublishedMode() {
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    return (
      <div className={cx(styles.container, styles.emptyPublished)}>
        <div
          className={cx(styles.bubble, styles[siteFonts.secondary])}
          style={{ backgroundImage: `url(${iconTextBubble})` }}
        >
          <div className={styles.title}>Watch this space…</div>
          <div className={styles.content}>Blog things are coming!</div>
        </div>
      </div>
    );
  }

  const blogPostsEmpty = isEmpty(blogPosts?.blogPosts) || blogPosts!.count <= 0;
  const totalPages = Math.ceil((blogPosts?.count || 0) / blogPostsPerPage);
  const emptyPublishedMode = readOnly && (!blogPosts ? publishedMode : !blogPosts.featuredPost && blogPostsEmpty);
  const hideBlogPosts =
    blogPosts?.count === 1 &&
    enableFeaturedBlogPost &&
    allBlogPostsIncludeFeatured &&
    category === allPostsCategory &&
    loading === LoadingState.None;

  return (
    <ZenBaseBlock
      siteTheme={siteTheme}
      readOnly={readOnly}
      divider={divider}
      padding={padding}
      desktopEnabled={desktopEnabled}
      tabletEnabled={tabletEnabled}
      mobileEnabled={mobileEnabled}
      fullWidth={true}
      noPadding={true}
      applyLayoutClass={false}
      contentClassName={styles.content}
      backgroundType="color"
      backgroundWidth="full"
      backgroundColor={
        emptyPublishedMode || (blogPosts?.featuredPost && (blogPostsEmpty || hideBlogPosts))
          ? getBackgroundColor(siteTheme.backgroundColor.value)
          : siteTheme.backgroundColor.value
      }
    >
      {!blogPosts ? (
        readOnly && publishedMode ? (
          renderEmptyPublishedMode()
        ) : (
          renderInitializing()
        )
      ) : !blogPosts.featuredPost && blogPostsEmpty ? (
        readOnly ? (
          renderEmptyPublishedMode()
        ) : (
          renderEmptyEditMode()
        )
      ) : (
        <div className={styles.container}>
          {blogPosts.featuredPost && renderFeaturedPost()}
          {!isEmpty(allCategories) && category && (
            <ZenCategoryFilterBlock
              siteTheme={siteTheme}
              disabled={loading !== LoadingState.None}
              categories={allCategories!}
              defaultSelectedCategoryId={category.id}
              onCategorySelected={onCategorySelected}
              allPostsUrl={buildCategoryUrl?.()}
            />
          )}
          {!blogPostsEmpty && (
            <>
              {!hideBlogPosts && (
                <BlogPosts
                  ref={blogPostsRef}
                  siteTheme={siteTheme}
                  photoUrlTemplate={photoUrlTemplate}
                  blogPosts={blogPosts!.blogPosts}
                  photosMap={photosMap}
                  loading={loading === LoadingState.Partial}
                  onSelectBlogPost={onBlogPostSelect}
                  buildBlogPostUrl={buildBlogPostUrl}
                />
              )}
              {totalPages > 1 && (
                <ZenPagination
                  className={styles.pagination}
                  edgeLeftClassName={styles.paginationLeft}
                  edgeRightClassName={styles.paginationRight}
                  pagesClassName={styles.paginationPages}
                  siteTheme={siteTheme}
                  page={page}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                  prevText="Newer Posts"
                  nextText="Older Posts"
                />
              )}
            </>
          )}
          {loading == LoadingState.Full && <ZenLoading siteTheme={siteTheme} spinnerClassName={styles.spinner} />}
        </div>
      )}
    </ZenBaseBlock>
  );
};
