import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Router from "../../components/router";
import { removeNotification } from "../../store/notification/actions";
import { loginFromIframe } from "../../store/session/actions";
import { IAppState } from "../../store/state";

function mapStateToProps({ session }: IAppState) {
  return {
    loginState: session.loginState,
    routes: session.routes
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    onLogin: () => dispatch(loginFromIframe()),
    onRemoveNotification: (force: boolean) => dispatch(removeNotification(force))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
