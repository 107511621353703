import { Utilities } from "@zenfolio/core-components";
import * as React from "react";
import { ActionStatus } from "../store/common";
import * as logging from "../utilities/logging";
import { NavigationManager } from "./navigationManager";

export function isDevelopment() {
  return process.env.REACT_APP_ENVIRONMENT_TYPE === "development";
}

export function isProduction() {
  return process.env.REACT_APP_ENVIRONMENT_TYPE === "production";
}

export function hasActionCompleted(prevStatus: ActionStatus, currentStatus: ActionStatus) {
  return prevStatus === "Pending" && (currentStatus === "Success" || currentStatus === "Error");
}

export function hasActionSuccessfullyCompleted(prevStatus: ActionStatus, currentStatus: ActionStatus) {
  return prevStatus === "Pending" && currentStatus === "Success";
}

export function hasActionCompletedWithError(prevStatus: ActionStatus, currentStatus: ActionStatus) {
  return prevStatus === "Pending" && currentStatus === "Error";
}

export function hasActionStarted(prevStatus: ActionStatus, currentStatus: ActionStatus) {
  return prevStatus !== "Pending" && currentStatus === "Pending";
}

export function addAsyncScript(src: string, onload?: () => void) {
  const script = document.createElement("script");
  document.body.appendChild(script);

  script.onload = () => {
    if (!script.parentNode) {
      return;
    }

    logging.log(`Loading succeeded for the script: ${src}.`);

    if (onload) {
      onload();
    }
  };

  script.onerror = () => {
    if (!script.parentNode) {
      return;
    }

    logging.log(`Loading failed for the script: ${src}.`);

    script.onload = null;
    script.onerror = null;
    script.remove();

    setTimeout(() => addAsyncScript(src, onload), 1000);
  };

  script.async = true;
  script.src = src;
}

export function isEnterKey(evt: KeyboardEvent) {
  return evt.key === "Enter" && (!evt.target || (evt.target as Element).tagName !== "TEXTAREA");
}

export function openInNewTab(url: string) {
  const win = window.open(url, "_blank");
  if (win) {
    win.focus();
  }
}

export const fastScrollClassName = "fastScroll";

function toggleFastScroll(enabled: boolean) {
  NavigationManager.postParentMessage({ type: enabled ? "fast-scroll-started" : "fast-scroll-finished" });
  document.documentElement.classList.toggle(fastScrollClassName, enabled);
}

export function fastScrollTo(offsetTop: number) {
  toggleFastScroll(true);

  // timeout is required to give parent page time to resize iframe after its content has changed
  setTimeout(() => {
    Utilities.scrollTo(0, offsetTop);
    toggleFastScroll(false);
  }, 200);
}

// https://muffinman.io/react-rerender-in-component-did-mount/#finally-put-it-in-a-helper
export function startAnimation(callback: () => void) {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      callback();
    });
  });
}

export function hideTrackHorizontal(props: any) {
  return <div {...props} style={{ display: "none" }} />;
}

const neverActivatedWidgetBeforeSettingBaseName = "never-activated-widget-before-";

export function getNeverActivatedWidgetBeforeSetting(photographerId: string) {
  return sessionStorage.getItem(neverActivatedWidgetBeforeSettingBaseName + photographerId) === "1";
}

export function setNeverActivatedWidgetBeforeSetting(photographerId: string, value: boolean) {
  if (value) {
    sessionStorage.setItem(neverActivatedWidgetBeforeSettingBaseName + photographerId, "1");
  }
}

export function stringsAreEqualIgnoreCase(first: string, second: string) {
  return first.toLowerCase() === second.toLowerCase();
}
