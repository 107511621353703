import IError from "../../../utilities/error";
import { ActionStatus } from "../../common";
import { IProfileActivationState } from "../../profile/state/model";
import { LoadActivationPagesStateAction, UpdateActivationPagesStateAction } from "./actions";
import { ActivationPagesActionType } from "./constants";

export interface IActivationPagesState {
  profileState: IProfileActivationState;
  load: ILoadActivationPagesState;
  update: IUpdateActivationPagesState;
}

export interface ILoadActivationPagesState {
  status: ActionStatus;
  error: IError | null;
}

export interface IUpdateActivationPagesState {
  status: ActionStatus;
  error: IError | null;
}

const initialProfileState: IProfileActivationState = {
  isEnabled: false,
  canBeActivated: false,
  profile: {
    shootLocation: false,
    availability: false,
    payments: false,
    contacts: false
  },
  services: {
    createdAndEnabled: false
  },
  activation: {
    enabled: false
  }
};

const initialLoadActivationPagesState: ILoadActivationPagesState = {
  status: "Init",
  error: null
};

const initialUpdateActivationPagesState: IUpdateActivationPagesState = {
  status: "Init",
  error: null
};

const initialActivationPagesState: IActivationPagesState = {
  profileState: initialProfileState,
  load: initialLoadActivationPagesState,
  update: initialUpdateActivationPagesState
};

export const pages = (
  state: IActivationPagesState = initialActivationPagesState,
  action: LoadActivationPagesStateAction | UpdateActivationPagesStateAction
): IActivationPagesState => {
  switch (action.type) {
    case ActivationPagesActionType.LOAD_ACTIVATION_PAGES_STATE_STARTED:
      return {
        ...state,
        load: {
          status: "Pending",
          error: null
        }
      };
    case ActivationPagesActionType.LOAD_ACTIVATION_PAGES_STATE_SUCCESS:
      return state.load.status === "Pending"
        ? {
            ...state,
            load: {
              status: "Success",
              error: null
            },
            profileState: action.state
          }
        : state;
    case ActivationPagesActionType.LOAD_ACTIVATION_PAGES_STATE_ERROR:
      return state.load.status === "Pending"
        ? {
            ...state,
            load: {
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActivationPagesActionType.UPDATE_ACTIVATION_PAGES_STATE_STARTED:
      return {
        ...state,
        update: {
          status: "Pending",
          error: null
        }
      };
    case ActivationPagesActionType.UPDATE_ACTIVATION_PAGES_STATE_SUCCESS:
      return state.update.status === "Pending"
        ? {
            ...state,
            update: {
              status: "Success",
              error: null
            },
            profileState: { ...state.profileState, isEnabled: action.isEnabled }
          }
        : state;
    case ActivationPagesActionType.UPDATE_ACTIVATION_PAGES_STATE_ERROR:
      return state.update.status === "Pending"
        ? {
            ...state,
            update: {
              status: "Error",
              error: action.error
            }
          }
        : state;
    default:
      return state;
  }
};

export default pages;
