import { Button, FormCheckbox } from "@zenfolio/core-components";
import React from "react";
import styles from "./index.module.scss";
import Setup, { Amount } from "./setup";

export interface IRefundSetupProps {
  maxAmount: number;
  onCancel: () => void;
  onRefund: (amount: number) => void;
}

interface IRefundSetupState {
  amount: Amount;
  confirmed: boolean;
  valid: boolean;
}

class RefundSetup extends React.Component<IRefundSetupProps, IRefundSetupState> {
  public state: IRefundSetupState = { confirmed: false, ...Setup.defaultState };

  public render() {
    const { maxAmount } = this.props;
    const { amount, confirmed, valid } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.title}>Refund</div>
        <Setup className={styles.setup} maxAmount={maxAmount} onChange={this.onSetupChange} />
        <ul className={styles.note}>
          <li>A {amount === "full" ? "full" : "partial"} refund will be issued through Stripe in 3-5 business days.</li>
        </ul>
        <div className={styles.actions}>
          <FormCheckbox className={styles.confirm} checked={confirmed} onChange={this.onConfirmChange}>
            Confirm {amount === "full" ? "Full" : "Partial"} Refund
          </FormCheckbox>
          <button className={styles.cancel} onClick={this.onCancelClick}>
            Cancel
          </button>
          <Button className={styles.send} disabled={!valid || amount === null || !confirmed} onClick={this.onSendClick}>
            Send
          </Button>
        </div>
      </div>
    );
  }

  private onSetupChange = (amount: Amount, valid: boolean) => this.setState({ amount, valid });

  private onConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ confirmed: event.target.checked });

  private onSendClick = () =>
    this.props.onRefund(this.state.amount === "full" ? this.props.maxAmount : this.state.amount!.floatValue);

  private onCancelClick = () => this.props.onCancel();
}

export default RefundSetup;
