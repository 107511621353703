export enum ActionType {
  LOAD_STARTED = "PROFILE_AVAILABILITY_LOAD_STARTED",
  LOAD_SUCCESS = "PROFILE_AVAILABILITY_LOAD_SUCCESS",
  LOAD_ERROR = "PROFILE_AVAILABILITY_LOAD_ERROR",
  UPDATE_BOOKABLE_HOURS_STARTED = "PROFILE_AVAILABILITY_UPDATE_BOOKABLE_HOURS_STARTED",
  UPDATE_BOOKABLE_HOURS_SUCCESS = "PROFILE_AVAILABILITY_UPDATE_BOOKABLE_HOURS_SUCCESS",
  UPDATE_BOOKABLE_HOURS_ERROR = "PROFILE_AVAILABILITY_UPDATE_BOOKABLE_HOURS_ERROR",
  UPDATE_BOOKABLE_HOURS_RESET = "PROFILE_AVAILABILITY_UPDATE_BOOKABLE_HOURS_RESET",
  UPDATE_GOOGLE_CALENDAR_STARTED = "PROFILE_AVAILABILITY_UPDATE_GOOGLE_CALENDAR_STARTED",
  UPDATE_GOOGLE_CALENDAR_SUCCESS = "PROFILE_AVAILABILITY_UPDATE_GOOGLE_CALENDAR_SUCCESS",
  UPDATE_GOOGLE_CALENDAR_ERROR = "PROFILE_AVAILABILITY_UPDATE_GOOGLE_CALENDAR_ERROR",
  APPLY_GOOGLE_CALENDAR_DISCONNECT = "PROFILE_AVAILABILITY_APPLY_GOOGLE_CALENDAR_DISCONNECT"
}
