import { Editor, NodeEntry, Range } from "slate";
import { RenderElementProps, RenderLeafProps } from "slate-react";

export type Decorate = (entry: NodeEntry, editor: Editor) => Range[];
export type OnDOMBeforeInput = (event: Event, editor: Editor) => void;
export type RenderElement = (props: RenderElementProps) => JSX.Element | undefined;
export type RenderLeaf = (props: RenderLeafProps) => JSX.Element | undefined;
export type OnKeyDown = (e: any, editor: Editor, options?: any) => void;

export interface SlatePlugin {
  decorate?: Decorate;
  onDOMBeforeInput?: OnDOMBeforeInput;
  renderElement?: RenderElement;
  renderLeaf?: RenderLeaf;
  onKeyDown?: OnKeyDown;
}

export interface PluginOption {
  hotkey?: string;
}

export const MARK_BOLD = "bold";
export const MARK_ITALIC = "italic";
export const MARK_UNDERLINE = "underline";

export const BLOCK_PARAGRAPH = "paragraph";
export const BLOCK_ALIGN_LEFT = "align-left";
export const BLOCK_ALIGN_CENTER = "align-center";
export const BLOCK_ALIGN_RIGHT = "align-right";
export const BLOCK_UL = "ul";
export const BLOCK_OL = "ol";
export const BLOCK_LI = "li";
export const BLOCK_LINK = "link";
