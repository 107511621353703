import cx from "classnames";
import styles from "Description.module.scss";
import isEmpty from "lodash/isEmpty";
import React, { useMemo } from "react";
import { RichText } from "utilities/richText";

export interface IDescriptionProps {
  description?: string;
  textColor?: string;
  linkColor?: string;
  className?: string;
}

const Description: React.FC<IDescriptionProps> = props => {
  const value = useMemo(() => {
    const html = RichText.toHTML(RichText.fromJSON(props.description));
    return html ? { __html: html } : null;
  }, [props.description]);

  const style = useMemo(() => {
    const result: any = {};

    if (props.textColor) {
      result["--description-text-color"] = props.textColor;
    }

    if (props.linkColor) {
      result["--description-link-color"] = props.linkColor;
    }

    return isEmpty(result) ? undefined : result;
  }, [props.textColor, props.linkColor]);

  return value ? (
    <div style={style} className={cx(styles.container, props.className)} dangerouslySetInnerHTML={value}></div>
  ) : null;
};

export default Description;
