import { Utilities } from "@zenfolio/core-components";
import _ from "lodash";
import * as React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import Helmet from "react-helmet";
import { Route, RouteComponentProps, Switch } from "react-router";
import Content from "../../components/layout/content";
import { IRoute } from "../../components/router/routes";
import PageHeader from "../../containers/layout/header";
import ModalRoot from "../../containers/modal";
import { getPageLayoutRoutes, getRouteFullPath } from "../../store/helpers";
import { NavigationManager } from "../../utilities/navigationManager";
import styles from "./index.module.scss";

export interface IPageLayoutProps extends RouteComponentProps {
  routes: IRoute[];
}

export interface IPageLayoutState {
  title?: string;
  backUrl?: string;
  header?: string;
}

class PageLayout extends React.PureComponent<IPageLayoutProps, IPageLayoutState> {
  constructor(props: IPageLayoutProps) {
    super(props);

    this.state = {};
  }

  public componentDidUpdate(prevProps: any) {
    const { location } = this.props;
    const prevLocation = prevProps.location;

    if (location !== prevLocation) {
      Utilities.scrollTop();
    }
  }

  public render() {
    const { title, header } = this.state;

    return (
      <React.Fragment>
        <ModalRoot />
        <Helmet titleTemplate="BookMe | %s" onChangeClientState={NavigationManager.notifyParentOnTitleChange} />
        <Content>
          <Grid fluid={true} className={styles.container}>
            <Row className={styles.pageHeader}>
              <Col>
                <button className={styles.back} onClick={this.handleBackButtonClick}>
                  ← {title || ""}
                </button>
              </Col>
            </Row>
            <Row center="md">
              <Col>
                {header && (
                  <Row start="md">
                    <PageHeader title={header} pageMode={true} />
                  </Row>
                )}
                <Content>
                  <Row start="md">
                    <div className={styles.body}>
                      <Switch>{this.getRoutes()}</Switch>
                    </div>
                  </Row>
                </Content>
              </Col>
            </Row>
          </Grid>
        </Content>
      </React.Fragment>
    );
  }

  private handleBackButtonClick = () => {
    if (this.state.backUrl) {
      this.props.history.push(this.state.backUrl);
    }
  };

  private getRoutes = () => {
    const routes = getPageLayoutRoutes(this.props.routes);

    return routes.map((route, key) => {
      return <Route path={getRouteFullPath(route)} key={key} render={this.renderRoute(route)} />;
    });
  };

  private renderRoute = _.memoize((route: IRoute) => {
    return (routeProps: any) => {
      const props = { ...routeProps, updateTitle: this.updateTitle, updateHeader: this.updateHeader };
      return React.createElement(route.component, props);
    };
  });

  private updateTitle = (title: string, backUrl: string) => {
    this.setState({
      title,
      backUrl
    });
  };

  private updateHeader = (header: string) => {
    this.setState({
      header
    });
  };
}

export default PageLayout;
