import React, { Fragment, useEffect, useImperativeHandle, useRef, useState } from "react";
import ContextMenu from "utilities/contextMenu";
import styles from "./lightboxImage.module.scss";
import cx from "classnames";
import { PhotoOrVideo } from "../../../models/models";
import { isPhoto, isVideo } from "utilities/helpers";
import NzVideo from "components/NzVideo";
import { formatPhotoUrl, MESizeMethod } from "utilities/getImgUrl";
import { IVideoPlayer } from "components/Video/Player";
import { IAnalyticParams } from "../../NzVideo/Player";
import { Utilities } from "utilities/utilities";
import { LIGHT_BOX_SIZE_CODE } from "utilities/constant";

interface ILightboxImageProps {
  image: PhotoOrVideo;
  photoUrlTemplate: string;
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  className: string;
  style?: React.CSSProperties;
  isVisible: boolean;
  videoAnalyticsParams?: IAnalyticParams;
  onVideoStart?: () => void;
}

export interface ILightboxImage {
  stopVideo(): void;
}

const LightboxImage = React.forwardRef((props: ILightboxImageProps, ref: React.Ref<ILightboxImage>) => {
  const [source, setSource] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [videoHasStarted, setVideoHasStarted] = useState(false);

  const videoRef = useRef<IVideoPlayer>(null);
  const isGif = Utilities.isGifImage(props.image.fileName);

  useImperativeHandle(ref, () => ({ stopVideo }));

  useEffect(() => {
    if (props.isVisible && !isLoading) {
      setIsLoading(true);

      if (isPhoto(props.image)) {
        const src = formatPhotoUrl(props.photoUrlTemplate, props.image, LIGHT_BOX_SIZE_CODE, MESizeMethod.Contain);

        if (isGif) {
          setIsLoaded(true);
          setSource(src);
        } else {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            setIsLoaded(true);
            setSource(src);
          };
        }
      }
    }
  });

  function onThumbnailImageLoad() {
    setIsLoaded(true);
  }

  function onVideoStart() {
    setVideoHasStarted(true);
    props.onVideoStart?.();
  }

  function stopVideo() {
    videoRef.current?.stop();
    setVideoHasStarted(false);
  }

  function blockSwipe(ev: React.SyntheticEvent) {
    ev.stopPropagation();
  }

  return (
    <Fragment>
      {!isLoaded && !isGif && <div className={styles.loadingPlaceholder} />}
      <div
        className={cx(props.className, {
          [styles.loading]: !isLoaded && !isGif,
          [styles.loaded]: isLoaded
        })}
        style={{ ...props.style, backgroundImage: source ? `url(${source})` : undefined }}
        onContextMenu={ContextMenu.handleBlockContextMenu}
        onTouchStart={videoHasStarted ? blockSwipe : undefined}
        onTouchMove={videoHasStarted ? blockSwipe : undefined}
        onTouchEnd={videoHasStarted ? blockSwipe : undefined}
        onTouchCancel={videoHasStarted ? blockSwipe : undefined}
        onMouseDown={videoHasStarted ? blockSwipe : undefined}
        onClickCapture={videoHasStarted ? blockSwipe : undefined}
      >
        {isVideo(props.image) && (
          <NzVideo
            ref={videoRef}
            video={props.image}
            analyticsParams={props.videoAnalyticsParams}
            thumbnailUrlTemplate={props.thumbnailUrlTemplate}
            videoUrlTemplate={props.videoUrlTemplate}
            forcedSizeCode={LIGHT_BOX_SIZE_CODE}
            imagePlacement="contain"
            playable={true}
            preload={false}
            onStart={onVideoStart}
            onThumbnailImageLoad={onThumbnailImageLoad}
          />
        )}
      </div>
    </Fragment>
  );
});

export default LightboxImage;
