import React, { Fragment } from "react";
import classNames from "classnames";

import SprintSizeItem from "./SprintSizeItem";
import SmoothCarousel from "../SmoothCarousel";

import styles from "./SprintSizes.module.scss";
import { ICurrency } from "../../models/models";
import { Unit } from "utilities/unit";
import { get } from "lodash";

interface ISprintSize {
  height: number;
  width: number;
  walletSet?: number | null;
  id: string;
  price?: number;
  name?: string;
}

interface ISprintSizesProps {
  tableView?: boolean;
  activeIndex?: Number;
  className?: string;
  sprints: ISprintSize[];
  onSelectSprint: (sprint: ISprintSize) => void;
  defaultWidth?: number;
  currency?: ICurrency;
  unit: Unit;
}

interface ISprintSizesState {
  activeIndex: Number | 0;
}

class SpritSizes extends React.PureComponent<ISprintSizesProps, ISprintSizesState> {
  public static defaultProps = {
    unit: Unit.inch
  }
  public state: ISprintSizesState = {
    activeIndex: this.props.activeIndex || 0
  };

  public componentDidUpdate(prevProps: ISprintSizesProps, prevState: ISprintSizesState) {
    if (prevProps.activeIndex !== this.props.activeIndex && prevState.activeIndex === this.state.activeIndex) {
      this.setState({ activeIndex: this.props.activeIndex || 0 });
    }
  }

  private onSelectSprint = (sprint: ISprintSize, idx: number) => {
    this.setState({ activeIndex: idx }, () => {
      this.props.onSelectSprint(sprint);
    });
  };

  private renderItem = (sprint: ISprintSize, idx: number) => {
    const { defaultWidth, currency, unit } = this.props;
    const { activeIndex } = this.state;

    const activeClassName = activeIndex === idx ? styles.activeSprint : null;

    return (
      <div
        key={`${idx}`}
        className={classNames(styles.sprint, activeClassName)}
        onClick={() => this.onSelectSprint(sprint, idx)}
      >
        <SprintSizeItem
          {...sprint}
          defaultWidth={defaultWidth}
          currency={currency}
          unit={unit}
          isSFF={!!get(sprint, "selfFulfilledMetaData", null)}
        />
      </div>
    );
  };

  public render() {
    const { sprints, className, tableView } = this.props;
    const tableViewClassName = tableView ? styles.tableView : null;
    return (
      <Fragment>
        {!tableView ? (
          <div className={classNames(styles.container, className)}>
            {sprints.map((item, idx) => this.renderItem(item, idx))}
          </div>
        ) : (
          <SmoothCarousel customClassName={styles.carousel}>
            <div className={classNames(styles.container, className, tableViewClassName)}>
              {sprints.map((item, idx) => this.renderItem(item, idx))}
            </div>
          </SmoothCarousel>
        )}
      </Fragment>
    );
  }
}

export default SpritSizes;
