import React from "react";

export interface IDividerProps {
  templateBackgroundColor?: string;
  dividerType: DIVIDER | undefined;
  padding?: string;
}

export enum DIVIDER {
  NONE = "none",
  SMALL_SOLID = "small-solid",
  MEDIUM_SOLID = "medium-solid",
  LARGE_SOLID = "large-solid",
  SMALL_DASHED = "small-dashed",
  MEDIUM_DASHED = "medium-dashed",
  LARGE_DASHED = "large-dashed",
  FULL_DASHED = "full-dashed",
  FULL_SOLID = "full-solid"
}

enum DIVIDER_WIDTH {
  SMALL = "576px",
  MEDIUM = "776px",
  LARGE = "1176px",
  FULL = "100%"
}

enum BG_COLORS {
  CREAM = "#FCFCF4",
  WHITE = "#FFFFFF",
  BLACK = "#000000",
  GRAY = "#333333"
}

enum DIVIDER_COLORS {
  DARK = "#CCCCCC",
  LIGHT = "#606060"
}

const Divider: React.FC<IDividerProps> = ({ templateBackgroundColor, dividerType, padding }) => {
  const getDividerBorder = (divider: DIVIDER | undefined) => {
    let newBorder;
    if (divider && divider !== DIVIDER.NONE) {
      const type = String(divider)
        .split("-")[1]
        .toLowerCase();
      const color = getDividerColor(templateBackgroundColor);
      newBorder = `1px ${type} ${color}`;
    } else {
      newBorder = "none";
    }
    return newBorder;
  };

  const getDividerColor = (templateBackgroundColor: string | undefined) => {
    const bgcColor = String(templateBackgroundColor).toUpperCase();
    let color;
    switch (bgcColor) {
      case BG_COLORS.CREAM:
      case BG_COLORS.WHITE:
        color = DIVIDER_COLORS.DARK;
        break;
      case BG_COLORS.BLACK:
      case BG_COLORS.GRAY:
        color = DIVIDER_COLORS.LIGHT;
        break;
      default:
        color = DIVIDER_COLORS.DARK;
        break;
    }
    return color;
  };

  const getDividerWidth = (divider: DIVIDER | undefined) => {
    let newWidth;
    if (divider && divider !== DIVIDER.NONE) {
      const type = String(divider)
        .split("-")[0]
        .toUpperCase() as keyof typeof DIVIDER_WIDTH;
      newWidth = DIVIDER_WIDTH[type];
    } else {
      newWidth = "0px";
    }
    return newWidth;
  };

  const dividerStyle = (divider: DIVIDER | undefined) => {
    return {
      border: getDividerBorder(divider),
      width: getDividerWidth(divider),
      marginBottom: padding === "0px" ? undefined : "30px"
    };
  };

  const dividerContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "0px",
    width: "100%"
  };

  return (
    <div style={dividerContainerStyle}>
      <hr className="divider" style={dividerStyle(dividerType)} />
    </div>
  );
};

export default Divider;
