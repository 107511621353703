import { Utilities } from "@zenfolio/core-components";
import { History } from "history";
import _ from "lodash";
import { AnalyticsParams } from "../components/router";
import { AuthenticationManager } from "./authenticationManager";

export class NavigationManager {
  private static readonly invoiceIdParam: string = "invoiceId";
  private static readonly hidePoweredByParam: string = "hidePoweredBy";

  private static readonly photographerIdParam: string = "photographerId";
  private static readonly baseUrlParam: string = "baseUrl";
  private static readonly pathParam: string = "path";
  private static readonly tokenParam: string = "token";
  private static readonly layoutTypeParam: string = "layoutType";
  private static readonly widgetPath: string = "/widget";

  private static instanceValue: NavigationManager | null;

  public readonly widgetLayoutType: string | null;
  public readonly widgetPhotographerId: string | null;
  public readonly parentReferer: string | null;

  private invoiceId: string | null;

  public token: string | null;
  private baseUrl: string | null;

  private constructor() {
    this.token = null;
    this.baseUrl = null;
    this.widgetLayoutType = null;
    this.invoiceId = null;

    const isInIframe = NavigationManager.isInIframe();

    if (window.location.pathname === NavigationManager.widgetPath) {
      this.widgetPhotographerId = NavigationManager.getWidgetPhotographerId();
      this.invoiceId = NavigationManager.getInvoiceId();

      if (isInIframe) {
        this.widgetLayoutType = NavigationManager.getWidgetLayoutType();
        NavigationManager.removeSearchParams(NavigationManager.layoutTypeParam, NavigationManager.photographerIdParam);
      }
    } else {
      this.widgetPhotographerId = null;
      this.invoiceId = null;
    }

    this.parentReferer = this.widgetMode ? null : (isInIframe ? document.referrer : null) || "http://localhost/";
  }

  public static get instance(): NavigationManager {
    if (!this.instanceValue) {
      this.instanceValue = new NavigationManager();
    }

    return this.instanceValue;
  }

  public static isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public static isInTestMode(history: History) {
    return !NavigationManager.isInIframe() && history.location.pathname === "/test";
  }

  public static buildTestIframeUrl(token: string | null) {
    const urlBuilder = new URL(process.env.REACT_APP_IFRAME_BASE_URL!);

    if (token) {
      urlBuilder.searchParams.set(NavigationManager.tokenParam, token);
    } else {
      urlBuilder.searchParams.delete(NavigationManager.tokenParam);
    }

    const parentUrl = new URL(window.location.href);

    new URLSearchParams(parentUrl.search).forEach((value, key) => {
      if (key !== NavigationManager.photographerIdParam && key !== NavigationManager.pathParam) {
        urlBuilder.searchParams.set(key, value);
        parentUrl.searchParams.delete(key);
      }
    });

    NavigationManager.replaceUrl(parentUrl.href);

    const parentUrlPath = parentUrl.searchParams.get(NavigationManager.pathParam);
    parentUrl.searchParams.delete(NavigationManager.pathParam);
    urlBuilder.searchParams.set(NavigationManager.baseUrlParam, parentUrl.href);

    if (parentUrlPath) {
      const storedUrl = new URL(urlBuilder.origin + parentUrlPath);
      urlBuilder.pathname = storedUrl.pathname;
      urlBuilder.hash = storedUrl.hash;

      storedUrl.searchParams.forEach((value, key) => {
        urlBuilder.searchParams.set(key, value);
      });
    }

    return urlBuilder.href;
  }

  public static setPathOnTestParentUrl(path: string) {
    const urlBuilder = new URL(window.location.href);
    urlBuilder.searchParams.set(NavigationManager.pathParam, path);

    return urlBuilder.href;
  }

  public static setPhotographerIdOnTestParentUrl(photographerId: string) {
    const urlBuilder = new URL(window.location.href);

    if (photographerId) {
      urlBuilder.searchParams.set(NavigationManager.photographerIdParam, photographerId);
    } else {
      urlBuilder.searchParams.delete(NavigationManager.photographerIdParam);
    }

    return urlBuilder.href;
  }

  public static pushUrl(url: string) {
    if (window.location.href !== url) {
      window.history.pushState({}, document.title, url);
    }
  }

  public static replaceUrl(url: string) {
    if (window.location.href !== url) {
      window.history.replaceState({}, document.title, url);
    }
  }

  public static removeSearchParams(...searchParams: string[]) {
    const urlBuilder = new URL(window.location.href);

    for (const searchParam of searchParams) {
      urlBuilder.searchParams.delete(searchParam);
    }

    NavigationManager.replaceUrl(urlBuilder.href);
  }

  public static getTestPhotographerId() {
    return (
      new URLSearchParams(window.location.search).get(NavigationManager.photographerIdParam) ||
      AuthenticationManager.getPhotographerStorage().photographerId ||
      process.env.REACT_APP_DEFAULT_PHOTOGRAPHER_ID ||
      null
    );
  }

  public static getHidePoweredBy() {
    return new URLSearchParams(window.location.search).get(NavigationManager.hidePoweredByParam) || null;
  }

  public static postParentMessage(data: any) {
    if (NavigationManager.isInIframe()) {
      NavigationManager.postMessage(window.parent, data);
    }
  }

  public static postChildMessage(target: Window, data: any) {
    NavigationManager.postMessage(target, data);
  }

  public static parseMessage(event: MessageEvent) {
    if (!event || !event.data) {
      return null;
    }

    let data: any = null;

    try {
      data = JSON.parse(event.data);
      // tslint:disable-next-line:no-empty
    } catch (e) {}

    return data;
  }

  public static notifyParentOnTitleChange(titleState: any) {
    const title = Utilities.trim(titleState && titleState.title);
    NavigationManager.postParentMessage({ type: "title-changed", title });
  }

  private static getWidgetPhotographerId() {
    return (
      new URLSearchParams(window.location.search).get(NavigationManager.photographerIdParam) ||
      process.env.REACT_APP_DEFAULT_PHOTOGRAPHER_ID ||
      null
    );
  }

  private static getInvoiceId() {
    return new URLSearchParams(window.location.search).get(NavigationManager.invoiceIdParam) || null;
  }

  private static getWidgetLayoutType() {
    return new URLSearchParams(window.location.search).get(NavigationManager.layoutTypeParam);
  }

  private static postMessage(target: Window, data: any) {
    target.postMessage(JSON.stringify(data), "*");
  }

  private static buildPath(location: { pathname: string; search: string; hash: string }) {
    return `${location.pathname}${location.search}${location.hash}`;
  }

  public get widgetMode() {
    return !!this.widgetPhotographerId;
  }

  public get currentInvoiceId(): string | null {
    return this.invoiceId;
  }

  public resetInvoiceId() {
    NavigationManager.removeSearchParams(NavigationManager.invoiceIdParam);
    NavigationManager.postParentMessage({
      type: "remove-query-params",
      queryParams: [NavigationManager.invoiceIdParam]
    });
    this.invoiceId = null;
  }

  public async init() {
    const urlParams = new URLSearchParams(window.location.search);

    this.token = urlParams.get(NavigationManager.tokenParam);
    this.baseUrl = urlParams.get(NavigationManager.baseUrlParam);

    if (!NavigationManager.isInIframe()) {
      if (!this.token) {
        this.token = await AuthenticationManager.getTestAccessTokenByPhotographerId(
          NavigationManager.getTestPhotographerId()
        );
      }

      if (!this.baseUrl && this.token) {
        const urlBuilder = new URL(window.location.href);
        urlBuilder.search = "";
        urlBuilder.searchParams.set(NavigationManager.tokenParam, this.token);

        this.baseUrl = urlBuilder.href;
      }
    }

    if (this.token && this.baseUrl) {
      NavigationManager.removeSearchParams(
        NavigationManager.baseUrlParam,
        NavigationManager.photographerIdParam,
        NavigationManager.tokenParam
      );

      return true;
    }

    return false;
  }

  public subscribeToUrlChange(history: History<any>) {
    history.listen((location, action) => {
      const path = NavigationManager.buildPath(location);
      NavigationManager.postParentMessage({ type: "path", path, replace: action === "REPLACE" });
    });
  }

  public buildRedirectUrl(): string {
    const urlBuilder = new URL(this.baseUrl!);
    const currentLocation = new URL(window.location.href);
    _.each(_.values(AnalyticsParams), key => currentLocation.searchParams.delete(key));

    if (NavigationManager.isInIframe()) {
      urlBuilder.searchParams.set(NavigationManager.pathParam, NavigationManager.buildPath(currentLocation));
    } else {
      urlBuilder.pathname = currentLocation.pathname;
      currentLocation.searchParams.forEach((value, key) => urlBuilder.searchParams.set(key, value));
      urlBuilder.hash = currentLocation.hash;
    }

    return urlBuilder.href;
  }

  public redirectTo(url: string) {
    if (!NavigationManager.isInIframe()) {
      window.location.href = url;
    } else {
      NavigationManager.postParentMessage({ type: "redirect", url });
    }
  }
}
