export enum NodeType {
  TEXT = 1,
  PHOTO = 0
}

export enum Orientation {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait"
}

export interface ITemplate {
  id: string;
  templateName: string;
  orientation: string;
  groupId: string;
  pages: IPage[];
  layouts: IPage[];
}
export interface IPage {
  id: string;
  pageNumber: number;
  thumbnailUrl: string;
  templateUrl: string;
  isDefault?: boolean;
  width: number;
  height: number;
  orientation?: "portrait" | "landscape" | undefined;
  nodes: INode[];
  isLayout?: boolean;
  svg?: any;
  name?: string;
}

export interface ISavedData {
  imageId: string;
  originalHeight: number;
  originalWidth: number;
  image?: string;
  cropping: ICropping;
}
export interface ICropping {
  x: number;
  y: number;
  width: number;
  height: number;
  zoom: number;
  src?: string; // for croppedSrc if any...
};

export interface INode {
  id: string;
  nodeType: number;
  sampleImageUrl: string | null;
  position?: IPosition | null;
  textSpec?: ITextV2 | null;

  savedData?: ISavedData;
  label?: string;
  textSvg?: any;
}

export interface IPosition {
  left: number;
  top: number;
  width: number;
  height: number;
  zIndex: number;
  rotate?: number;
}

export interface ITextV2 {
  font: string;
  fontPath: string | null;
  size: number;
  color: string;
  textAlign: string;
  style: string;
  letterSpacing: number | null;
  lineHeight: number | null;
  isMultiple: boolean;
}
