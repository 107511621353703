import { Button, FormCheckbox } from "@zenfolio/core-components";
import React from "react";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import styles from "./index.module.scss";
import { IAddInvoice, ICalculateTaxForNewInvoice } from "./model";
import Setup, { Amount } from "./setup";
import withStore from "./withStore";

export interface IAddInvoiceOwnProps {
  bookingId: string;
  maxAmount: number;
  isTaxable: boolean;
  onCancel: () => void;
  onSave: () => void;
  onError: () => void;
}

export interface IAddInvoiceSetupProps extends IAddInvoiceOwnProps {
  addInvoice: IAddInvoice;
  calculateTax: ICalculateTaxForNewInvoice;
}

interface IAddInvoiceSetupState {
  amount: Amount;
  valid: boolean;
  clientNote?: string;
  internalNote?: string;
  sendNotification: boolean;
}

class AddInvoiceSetup extends React.Component<IAddInvoiceSetupProps, IAddInvoiceSetupState> {
  public state: IAddInvoiceSetupState = { ...Setup.defaultState };

  public componentDidUpdate(prevProps: IAddInvoiceSetupProps) {
    const { addInvoice, onSave, onError } = this.props;

    const saveInvoiceHasCompleted = hasActionCompleted(prevProps.addInvoice.status, addInvoice.status);

    if (saveInvoiceHasCompleted) {
      if (addInvoice.status === "Success") {
        onSave();
      } else if (addInvoice.status === "Error") {
        onError();
      }
    }
  }

  public render() {
    const { maxAmount, isTaxable } = this.props;
    const { valid, internalNote, clientNote, sendNotification, amount } = this.state;

    const effectiveTax = this.getEffectiveTax(amount);
    const saveDisabled =
      this.props.addInvoice.status === "Pending" || !valid || amount === null || (isTaxable && effectiveTax == null);

    return (
      <div className={styles.container}>
        <Setup
          amount={amount}
          maxAmount={maxAmount}
          internalNote={internalNote}
          clientNote={clientNote}
          sendNotification={sendNotification}
          isTaxable={isTaxable}
          taxAmount={isTaxable ? effectiveTax : undefined}
          onChange={this.onSetupChange}
          onAmountBlur={this.onAmountBlur}
        />
        <div className={styles.actions}>
          <FormCheckbox className={styles.confirm} checked={sendNotification} onChange={this.onSendNotificationChange}>
            Send notification to client
          </FormCheckbox>
          <button className={styles.cancel} onClick={this.onCancelClick}>
            Cancel
          </button>
          <Button className={styles.save} disabled={saveDisabled} onClick={this.onSaveClick}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  private getEffectiveTax(amount: Amount) {
    return !!amount &&
      this.props.bookingId === this.props.calculateTax.bookingId &&
      amount.floatValue === this.props.calculateTax.amount &&
      this.props.calculateTax.status === "Success"
      ? this.props.calculateTax.taxAmount
      : undefined;
  }

  private onSendNotificationChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ sendNotification: event.target.checked });

  private onSetupChange = (
    amount: Amount,
    valid: boolean,
    sendNotification: boolean,
    clientNote?: string,
    internalNote?: string
  ) => {
    this.setState({ amount, valid, sendNotification, clientNote, internalNote });
  };

  private onAmountBlur = (amount: Amount, valid: boolean) => {
    if (this.props.isTaxable && valid && this.getEffectiveTax(amount) == null) {
      this.props.calculateTax.calculateTax(this.props.bookingId, amount!.floatValue);
    }
  };

  private onSaveClick = () => {
    const { addInvoice, bookingId } = this.props;

    addInvoice.addInvoice(
      bookingId,
      this.state.amount!.floatValue,
      this.state.sendNotification,
      this.state.sendNotification ? this.state.clientNote : undefined,
      this.state.internalNote
    );
  };

  private onCancelClick = () => this.props.onCancel();
}

export default withStore(AddInvoiceSetup);
