import { Action, INotificationMessage } from "./actions";
import { NotificationActionType } from "./constants";

export interface INotificationState {
  version: number;
  notification: INotificationMessage | null;
}

const initialState: INotificationState = {
  version: 0,
  notification: null
};

export const notifications = (state: INotificationState = initialState, action: Action): INotificationState => {
  switch (action.type) {
    case NotificationActionType.ADD_NOTIFICATION:
      return { version: state.version + 1, notification: action.notification };
    case NotificationActionType.REMOVE_NOTIFICATION:
      return action.force || !state.notification?.pin ? { ...state, notification: null } : state;
    default:
      return state;
  }
};
