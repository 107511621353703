import React from "react";
import styles from "./agreementContent.module.scss";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { IZenSiteTheme } from "index";

interface IAgreementContentProps {
  siteTheme: IZenSiteTheme;
}

const AgreementContent: React.FC<IAgreementContentProps> = props => {
  const fontColor = getBlockTextColor(
    props.siteTheme.backgroundColor.value,
    props.siteTheme.accentColor.value,
    "none",
    props.siteTheme.backgroundColor.value
  );

  return (
    <div className={styles.textContainer}>
      <p
        style={{
          color: fontColor
        }}
      >
        <b>Last Updated: October 27, 2021</b>
        <br />
        <br />
        This website and related services are provided subject to your agreement with this User Agreement (“Agreement”),
        which may be modified or supplemented at any time by Zenfolio, Inc. (“Zenfolio,” “we,” “us” or “our”). By using
        or accessing this website or by placing orders through this website, you agree to be bound by this Agreement as
        it exists at the time of your use, and that by posting of modifications of this Agreement on this website you
        have received sufficient notice regarding such modifications. If you do not agree with this Agreement, please
        promptly exit the website. As used in this Agreement, "you" and "your" will refer to anyone accessing or
        visiting the website, and "Photographer" refers to the individual or company who has a subscription with
        Zenfolio to upload and/or sell photographs or related products through this website. In the event of any
        conflict between this Agreement and any statement, understanding or agreement between you and Photographer, this
        Agreement controls.
        <br />
        <br />
        <b>NOTICE REGARDING ARBITRATION: </b> PLEASE READ THIS AGREEMENT CAREFULLY. IT CONTAINS AN AGREEMENT TO
        ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT
        TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST ZENFOLIO, ITS PARENT
        COMPANY, ITS SUBSIDIARIES, ITS AFFILIATES AND THEIR RESPECTIVE OFFICERS, EMPLOYEES, DIRECTORS, AGENTS,
        SUCCESSORS AND ASSIGNS (COLLECTIVELY, “ZENFOLIO PARTIES”) TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU
        WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST ZENFOLIO PARTIES ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR
        CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF
        (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO
        HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW.
        <br />
        <br />
        <ol className={styles.agreementMainList}>
          <li>
            <b>
              <u>Scope.</u>
            </b>{" "}
            Zenfolio is an online subscription service that provides photographers a hosted platform and related tools
            for organizing, exhibiting and selling photographs (the “Platform”). This website is managed by the
            Photographer who has licensed rights from Zenfolio to operate this website on the Platform. YOU ACKNOWLEDGE
            AND AGREE THAT THE PHOTOGRAPHER IS SOLELY RESPONSIBLE FOR THE CONTENT MADE AVAILABLE THROUGH THIS WEBSITE
            AND FOR THE MANAGEMENT OF CONTENT AND INFORMATION ON THIS WEBSITE, INCLUDING BUT NOT LIMITED ANY CONTENT
            GENERATED BY TOOLS UTILIZED BY PHOTOGRAPHERS AND DATA DERIVED THEREFROM, SUCH AS FACIAL RECOGNITION DATA,
            AND THAT ZENFOLIO PARTIES SHALL NOT HAVE ANY LIABILITY TO YOU, AND YOU HEREBY WAIVE ANY CLAIMS AGAINST
            ZENFOLIO PARTIES AND COVENANT NOT TO SUE ZENFOLIO PARTIES, FOR, ANY CONTENT OR INFORMATION EITHER: (I) MADE
            AVAILABLE THROUGH THIS WEBSITE, (II) GENERATED BY A PHOTOGRAPHER’S USE OF FEATURES OR TOOLS MADE AVAILABLE
            BY ZENFOLIO, OR (3) ANY ACTION OR FAILURE TO ACT BY A PHOTOGRAPHER.
          </li>
          <li>
            <b>
              <u>Orders.</u>
            </b>
            <ol>
              <li>
                Depending on preferences made available by the Photographer, you may have the option of placing orders
                to be fulfilled by the Photographer (“Photographer Orders”) or by Zenfolio and its affiliates and
                suppliers (“Direct Orders”). You agree that the Photographer shall be solely responsible for all aspects
                of Photographer Orders, including but not limited to processing and fulfillment, and that Zenfolio shall
                not have any liability to you for the same. You agree that you shall be responsible for reviewing,
                approving, and taking all other necessary steps to place Photographer Orders and Direct Orders, and that
                neither Photographers nor Zenfolio will have any liability to you for errors you make when placing
                orders. You are responsible for paying the Photographer for any Photographer Orders and for any
                applicable taxes. For Direct Orders, payments are processed by Zenfolio on behalf of the Photographer.
                By placing an order you authorize Zenfolio and/or its payment processors to charge your credit card the
                purchase price for the order, plus applicable taxes. To the extent permissible by law, risk of loss for
                all items purchased by you through the website passes to you upon Zenfolio's or the Photographer's
                delivery to a shipping carrier.
              </li>
              <li>
                As a purchaser of products through this website, you acknowledge that such products are purchased for
                personal use only and are custom made to your order and have no market beyond your purchase thereof.
                Except as provided in the applicable End User License with the Photographer, you may not reproduce,
                scan, display, transmit, distribute, modify, alter or otherwise exploit those physical products, or any
                portion thereof, for commercial use, in any manner, without the prior written consent of the
                Photographer. You acknowledge that products (including digital products) purchased through the website
                are purchased subject to the terms set forth by the Photographer in a separate End User License as
                described below.
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Privacy Policy.</u>
            </b>
            <ol>
              <li>
                Information you provide directly to Zenfolio in our role as a “data controller,” including information
                provided for Direct Orders, is subject to our{" "}
                <a style={{ color: fontColor }} target="blank" href="https://zenfolio.com/privacypolicy">
                  Privacy Policy.
                </a>
              </li>
              <li style={{ textTransform: "uppercase" }}>
                <b>NOTICE REGARDING TRANSFER OF DATA.</b> ORDER PROCESSING AND FULFILLMENT SERVICES, AND CERTAIN
                PLATFORM FEATURES UTILIZED BY PHOTOGRAPHERS ON THIS WEBSITE, REQUIRE THAT PERSONAL DATA BE PROCESSED BY
                ZENFOLIO IN THE UNITED STATES OF AMERICA, WHERE THE NECESSARY COMPUTING SYSTEMS ARE LOCATED. THOSE
                SERVICES AND FEATURES WOULD NOT BE AVAILABLE WITHOUT SUCH PROCESSING OF PERSONAL DATA IN THE UNITED
                STATES OF AMERICA. PLEASE BE AWARE THAT THE UNITED STATES MAY NOT PROVIDE THE SAME LEVEL OF PROTECTION
                of personal information as in your country, state, or other jurisdiction of residence or nationality,
                and when transferred to the United States , your personal information may be accessible by, or otherwise
                made available to, local government authorities and officials pursuant to judicial and/or administrative
                orders, decrees, and demands, and/or other domestic laws, statutes, and regulations. You hereby
                acknowledge and agree that in order to satisfy our contractual obligations and to provide you the
                Services described here, we have to transfer and process your personal information in the United States.
                For more information, please see our{" "}
                <a style={{ color: fontColor }} target="blank" href="https://zenfolio.com/privacypolicy">
                  Privacy Policy.
                </a>
              </li>
              <li>
                <b>NOTICE REGARDING ADDITIONAL RIGHTS.</b> SHOULD YOU DESIRE TO EXERCISE ADDITIONAL RIGHTS AVAILABLE TO
                YOU UNDER APPLICABLE LAWS (E.G., ACCESS, ERASURE, PORTABILITY) YOU SHOULD FIRST CONTACT THE
                PHOTOGRAPHER, WHEN IT IS ACTING AS THE DATA CONTROLLER. THE PHOTOGRAPHER, AS THE MANAGER OF THE WEBSITE,
                IS THE CONTROLLER FOR ANY PERSONAL INFORMATION PROVIDED BY YOU EXCEPT FOR DIRECT ORDERS (AS DEFINED
                BELOW). ZENFOLIO, ACTING AS A PROCESSOR OR SERVICE PROVIDER ON BEHALF OF THE PHOTOGRAPHER, OR AS A
                CONTROLLER OF DATA FOR DIRECT ORDERS, CAN BE CONTACTED AT{" "}
                <a style={{ color: fontColor }} target="blank" href="mailto:support@zenfolio.com">
                  SUPPORT@ZENFOLIO.COM
                </a>{" "}
                WITH YOUR SPECIFIC REQUEST.
              </li>
              <li>
                <b>FACIAL RECOGNITION.</b> PLEASE NOTE THAT PHOTOGRAPHERS MAY ELECT TO MAKE IT EASIER FOR THEIR
                CUSTOMERS TO IDENTIFY OR SORT PHOTOGRAPHS IN THEIR PHOTO GALLERIES BY IMPLEMENTING FACIAL RECOGNITION
                TOOLS ZENFOLIO MAKES AVAILABLE THROUGH OUR SERVICES. WHILE ZENFOLIO MAY HOST SUCH FACIAL RECOGNITION
                DATA ON ITS PLATFORM ON BEHALF OF THOSE PHOTOGRAPHERS TO ENABLE IMAGE IDENTIFICATION AND ORGANIZATION,
                ZENFOLIO DOES NOT HAVE ANY INDEPENDENT METHOD TO BE ABLE TO IDENTIFY OR VERIFY ANY INDIVIDUAL BASED ON
                THAT FACIAL RECOGNITION DATA. ACCORDINGLY, THEREFORE, REQUESTS PERTAINING TO THE SAME MAY HAVE TO BE
                RESOLVED BY THE APPLICABLE PHOTOGRAPHER. FURTHER, YOU ACKNOWLEDGE AND AGREE THAT ZENFOLIO PARTIES SHALL
                NOT HAVE ANY LIABILITY TO YOU, AND YOU HEREBY WAIVE ANY CLAIMS AGAINST ZENFOLIO PARTIES AND COVENANT NOT
                TO SUE ZENFOLIO PARTIES, RELATED TO SUCH FACIAL RECOGNITION DATA. Notwithstanding the foregoing,
                Zenfolio has implemented commercially reasonable protocols to safeguard and, when appropriate, to
                permanently delete or dispose of images and photographs subject to the facial recognition features. More
                specifically, Zenfolio will delete images subject to the facial recognition features from its custody or
                control when it has been more than three (3) years since the last interaction by an applicable
                photographer with Zenfolio. Zenfolio will disclose and disseminate images subject to the facial
                recognition features to third parties in accordance with the data sharing and third party disclosure
                terms and conditions set forth the agreements (i.e., the{" "}
                <a style={{ color: fontColor }} target="blank" href="https://zenfolio.com/termsofuse">
                  Terms of Service
                </a>
                ) between Zenfolio and the applicable photographer/data controller. Individuals who are residents of, or
                WHO otherwise are located in, the state of Illinois (United States), are prohibited from using, or being
                subject to, Zenfolio’s facial recognition features.
              </li>
              <li>
                <b>
                  <u>Opting Out of Certain Communications.</u>
                </b>{" "}
                Zenfolio may notify you of the receipt and/or status of orders and send you other transaction- related
                correspondence, and you will not be able to opt out of receiving those communications. For other
                communications, if you are no longer interested in receiving them, please e-mail your request to{" "}
                <a style={{ color: fontColor }} target="blank" href="mailto:support@zenfolio.com">
                  support@zenfolio.com
                </a>{" "}
                or utilize the unsubscribe features within those emails. If you contact us via email, please include
                your complete name, e-mail address and mailing address. Note that you may still receive
                transaction-related communications from Zenfolio.
              </li>
              <li>
                <b>
                  <u>Intellectual Property Rights in Purchased Products.</u>
                </b>{" "}
                Products purchased through this website are purchased subject to the terms set forth by Photographer in
                a separate license agreement (each an “End User License”), and the acquisition of a copy of a product
                does not itself convey any rights under copyright or other laws to exploit the work. Products purchased
                by you through this website may not be reproduced, scanned, displayed, transmitted, distributed,
                modified, altered or otherwise exploited in any manner without the prior written consent of Photographer
                or as otherwise provided in an End User License. Any End User License or agreement between you and a
                Photographer is the sole responsibility of those parties. IT IS YOUR SOLE RESPONSIBILITY TO REVIEW AND
                UNDERSTAND THE TERMS OF ANY SUCH LICENSES AND AGREEMENTS. YOU ACKNOWLEDGE AND AGREE THAT ZENFOLIO
                PARTIES SHALL NOT HAVE ANY LIABILITY TO YOU ARISING OUT OF OR RELATED TO ANY SUCH END USER LICENSES. YOU
                ACKNOWLEDGE AND AGREE THAT ALL END USER LICENSES FOR DIGITAL PRODUCTS BETWEEN YOU AND A PHOTOGRAPHER ARE
                ENTERED INTO AT YOUR OWN RISK. Zenfolio reserves the right, but has no obligation, to become involved in
                any way with disputes between you and a Photographer.
              </li>
              <li>
                <b>
                  <u>Copyrighted Materials.</u>
                </b>
                Photographers and/or their clients retain their full rights to any content they upload to this website,
                including, but not limited to, images, videos, biographies and business information. All other content
                on this website, including but not limited to text, graphics, logos, icons, images, audio clips, video
                clips, digital downloads, data compilations, and software, as well as any compilation consisting of the
                selection, organization, or arrangement of content on this website, is the property of Zenfolio or its
                licensors or suppliers, and no portion of such other content may be reprinted, republished, modified, or
                distributed in any form without the express written permission of Zenfolio. Zenfolio respects the
                intellectual property of others, and we ask that you and Photographers to do the same. However, Zenfolio
                is not in a position to determine copyright status of any content uploaded to the website, which may
                depend upon many facts that are unknown to Zenfolio. Zenfolio makes no representations or warranties as
                to the accuracy, correctness or reliability of the content posted on the website or sold by
                Photographer. Zenfolio cannot and does not ensure that all persons depicted in the photographs and
                trademark owners have consented to the display of their image or trademark therein or on the Site. If
                you believe that your work has been copied in a way that constitutes copyright infringement, or that
                your intellectual property or privacy rights have been otherwise violated, you should notify Zenfolio of
                your claim in accordance with Zenfolio’s{" "}
                <a style={{ color: fontColor }} target="blank" href="https://zenfolio.com/copyright">
                  Copyright Policy.
                </a>
              </li>
              <li>
                <b>
                  <u>Participant Conduct.</u>
                </b>{" "}
                You are solely responsible for all content and materials that you upload, post, publish or display or
                otherwise use or make available (hereinafter, “upload”) via or in connection with this website. Zenfolio
                reserves the right to investigate and take appropriate legal action against anyone who, in Zenfolio’s
                sole discretion, violates this provision, including without limitation, removing the offending content,
                suspending or terminating the account of such violators and reporting you to the law enforcement
                authorities. You agree to not: (a) upload or store any content that: (i) infringes or misappropriates
                any intellectual property rights, or violates any other rights, of any other party; (ii) you do not have
                a right to upload under any law or under contractual or fiduciary relationships; (iii) contains viruses
                or any other computer code, files or programs designed to interrupt, destroy or limit the functionality
                of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or
                security risk to any person; (v) is unlawful, harmful, threatening, abusive, harassing, tortious,
                excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy,
                hateful racially or ethnically, or otherwise objectionable as determined by Zenfolio in its sole
                discretion; (vi) constitutes or contains any inaccurate information or data; or (vii) in the sole
                judgment of Zenfolio, is objectionable or which restricts or inhibits any website visitors, or other
                person from using or enjoying the website, or which may expose Zenfolio or others to any harm or
                liability of any type; (b) interfere with or disrupt the Platform or servers or networks connected to
                the Platform, or disobey any requirements, procedures, policies or regulations of networks connected to
                the Platform; (c) violate any applicable local, state, national or international law, or any regulations
                having the force of law; (d) impersonate any person or entity, or falsely state or otherwise
                misrepresent your affiliation with a person or entity; (e) solicit personal information from anyone
                under the age of 18; (f) harvest or collect email addresses or other contact information of other
                participants from the Services by electronic or other means for the purposes of sending unsolicited
                emails or other unsolicited communications; (g) harvest or collect email addresses or other contact
                information of other participants from the Services by electronic or other means for the purposes of
                sending unsolicited emails or other unsolicited communications; (h) advertise or offer to sell or buy
                any goods or services for any purpose that is not specifically authorized or for which you don’t have
                any necessary rights; (i) further or promote any criminal activity or enterprise or provide
                instructional information about illegal activities; or (j) obtain or attempt to access or otherwise
                obtain any materials or information through any means not intentionally made available or provided for
                through the Services.
              </li>
              <li>
                <b>
                  <u>Disclaimer of Warranties.</u>
                </b>{" "}
                THIS WEBSITE, THE PLATFORM AND ANY RELATED SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
                AND ZENFOLIO EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY,
                INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, TITLE AND NON- INFRINGEMENT.
              </li>
              <li>
                <b>
                  <u>Limitation of Liability.</u>
                </b>{" "}
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT ZENFOLIO PARTIES WILL NOT BE LIABLE FOR ANY INDIRECT,
                INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT
                LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF ZENFOLIO PARTIES
                HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
                STRICT LIABILITY OR OTHERWISE, ARISING OUT OF OR RELATED TO THIS AGREEMENT. IN NO EVENT WILL ZENFOLIO
                PARTIES’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED ONE HUNDRED DOLLARS
                ($100).
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>General</u>
            </b>
            <ol>
              <li>
                For purposes of any action or claim against Zenfolio, this Agreement shall be governed by and construed
                in accordance with the laws of the State of California, exclusive of its choice of law rules, and except
                as otherwise provided in the Arbitration Agreement below, you agree to submit to the exclusive
                jurisdiction of the state and federal courts in and for the County of San Mateo in the State of
                California, USA, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.
              </li>
              <li>
                The failure of Zenfolio to exercise or enforce any right or provision of this Agreement will not
                constitute a waiver of such right or provision. If any provision of this Agreement is found by a court
                of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor
                to give effect to the parties’ intentions as reflected in the provision, and the other provisions of
                this Agreement remain in full force and effect. You agree that regardless of any statute or law to the
                contrary, any claim or cause of action arising out of or related to this Agreement must be filed within
                one (1) year after such claim or cause of action arose or be forever barred. A printed version of this
                agreement and of any notice given in electronic form will be admissible in judicial or administrative
                proceedings based upon or relating to this agreement to the same extent and subject to the same
                conditions as other business documents and records originally generated and maintained in printed form.
                You may not assign this Agreement without the prior written consent of Zenfolio, but Zenfolio may assign
                or transfer this Agreement in whole or in part, without restriction. Zenfolio Parties shall not have any
                liability to you for any or be deemed to be in default, for any delay or failure to perform any act
                under this Agreement resulting, directly or indirectly, from acts of God, civil or military authority,
                acts of public enemy, terrorism, war, accidents, fires, explosions, earthquake, flood, failure of
                transportation, strikes or other work stoppages, or any other cause beyond its reasonable control. The
                section titles in these Agreement are for convenience only and have no legal or contractual effect.{" "}
              </li>
              <li>
                We reserve the right to amend this Agreement at any time. This Agreement, together with any terms linked
                herein, sets forth the entire Agreement and understanding between the parties as to the subject matter
                hereof and merges all prior discussions between the parties. Neither party shall be bound by any
                conditions, definitions, warranties, understandings or representations with respect to such subject
                matter other than as expressly provided herein, or in any prior existing written agreement between the
                parties, or as duly set forth on or subsequent to the effective date hereof in writing and signed by a
                proper and duly authorized representative of the party to be bound thereby. Each party acknowledges and
                agrees that there are no and neither party has relied on any written or oral representations,
                statements, warranties, promises, covenants, agreements or guaranties of any kind or character
                whatsoever, whether express or implied, oral or written, past, present or future by the other party
                (collectively the “Representations”) except as specifically set forth herein, and each party
                specifically acknowledges and agrees that neither the other party nor any of the other party’s agents or
                representatives has made and or shall be deemed to have made and hereby disclaims any such
                Representation.
              </li>
            </ol>
          </li>
          <li>
            <b>
              <u>Dispute Resolution By Binding Arbitration:</u>
            </b>
            <h4>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.</h4>
            <ol>
              <li>
                This Dispute Resolution by Binding Arbitration section is referred to in this Terms of Service as the
                “Arbitration Agreement.” If you have a dispute with any Zenfolio Parties regarding this Agreement, you
                must first provide Zenfolio with written notice of the dispute via mail or overnight courier to
                Zenfolio’s address noted below, with such notice to include your name and contact information, a written
                explanation of the claim including all legal claims you intend to assert and each set of facts which
                support each of such claims, and the relief you’re requesting. You agree that if any of your claims are
                omitted from that notice, you forever waive those claims and covenant not to assert them in any action
                or proceeding related to this Agreement. We will attempt to resolve the dispute with you without further
                court action or arbitration.
              </li>
              <li>
                All disputes you may have with Zenfolio Parties, whether based on circumstances in the past or future,
                which cannot be resolved as provided above which arise out of or relate to this Agreement (including but
                not limited to this “Dispute Resolution” Section) shall be resolved by binding arbitration before a sole
                arbitrator, except that you may assert claims in small claims court if your claims qualify. The Federal
                Arbitration Act and federal arbitration law apply. Arbitration does not involve a court or a judge;
                instead the arbitrator follows these Terms and applicable law and awards relief accordingly.
              </li>
              <li>
                You or Zenfolio may initiate arbitration through JAMS and the JAMS JA streamlined arbitration rules and
                procedures then in force (see{" "}
                <a style={{ color: fontColor }} target="blank" href="http://www.jamsadr.com/">
                  www.jamsadr.com
                </a>{" "}
                for additional information), or may pursue a dispute in court in San Mateo County, California, which you
                hereby consent to be the exclusive jurisdiction and venue for such dispute, and not by arbitration if
                the dispute qualifies for small claims court or you opt out of arbitration, as provided below. The
                arbitration shall be commenced as an individual arbitration, and not in a class, representative,
                consolidated or action involving multiple plaintiffs. You shall not join or consolidate claims or
                arbitrate or otherwise participate in any claim as a class representative, class member or in a private
                attorney general capacity. Any arbitration will be confidential. You or Zenfolio may initiate
                arbitration in either San Mateo County, California, or the federal judicial district where you reside.
                The arbitrator’s decision shall be in writing and shall comply with all terms and conditions in this
                Agreement. The decision and award rendered shall be final and binding on the parties. The parties
                acknowledge and agree that this Agreement and any award rendered pursuant hereto shall be governed by
                the UN Convention on the Recognition and Enforcement of Foreign Arbitral Awards. Judgment on the award
                may be entered in any court of competent jurisdiction. All aspects of the arbitration proceeding, and
                any ruling, decision, or award by the arbitrator, will be strictly confidential for the benefit of all
                parties.
              </li>
              <li>
                YOU MAY OPT OUT OF ARBITRATION BY PROVIDING WRITTEN NOTICE TO ZENFOLIO AT ZENFOLIO’S ADDRESS NOTED
                BELOW, TO THE ATTENTION OF “CLASS OPT OUT COORDINATOR” TO BE RECEIVED NO LATER THAN THIRTY (30) CALENDAR
                DAYS FROM THE DATE OF YOUR ORIGINAL ACCEPTANCE OF THIS AGREEMENT OR VISIT TO THE WEBSITE WHERE THIS
                AGREEMENT IS PUBLISHED. IF YOU DO NOT SEND NOTICE AS REQUIRED IN THE FOREGOING SENTENCE, YOU WILL NOT
                HAVE OPTED OUT OF ARBITRATION. IF YOU OPT OUT OF ARBITRATION AS PROVIDED ABOVE, THE OTHER PROVISIONS OF
                THESE TERMS SHALL STILL APPLY. Use of the website where this Agreement is published is not authorized in
                any jurisdiction that does not give effect to all provisions of this Agreement, including without
                limitation, this section.
              </li>
              <li>
                <b>Notices and Questions.</b>All notices and demands hereunder shall be in writing and shall be served
                by personal service. All notices or demands by mail shall be by certified or registered mail, return
                receipt requested, or by nationally-recognized private express courier and shall be deemed complete upon
                receipt. Any such written notice or demand by you to Zenfolio shall be submitted to Zenfolio, Inc., 3515
                A Edison Way, Menlo Park CA 94025. Zenfolio may provide you with notice at any shipping address or email
                address provided to photographer or Zenfolio during registration for or use of the Zenfolio services,
                unless you provides written notice to Zenfolio specifying a different address where such notices shall
                be delivered.
              </li>
            </ol>
          </li>
        </ol>
      </p>
    </div>
  );
};

export default AgreementContent;
