import { IBaseService, IServiceDetailsApi, IServiceItem } from "../store/services/model";
import { apiType } from "./apiType";
import { clean } from "./helpers";
import { apiClient } from "./index";

export interface IFetchServicesRequest {
  name?: string;
}

export interface IFetchServicesResponse {
  services: IServiceItem[];
}

export interface ILoadServiceDetailsRequest {
  photographerServiceId: string;
}

export interface ILoadServiceDetailsResponse {
  serviceDetails: IServiceDetailsApi;
}

export interface IBaseServiceRequest extends IBaseService {
  askAdditionalInfo: boolean;
  serviceDetails: string[];
  shootTypeId: string;
  price: number | null;
}

export interface IUpdateServiceRequest extends IBaseServiceRequest {
  serviceId: string;
}

export interface ICreateServiceRequest extends IBaseServiceRequest {}

export interface ICreateServiceResponse {
  photographerServiceId: string;
}

export interface IDeleteServiceRequest {
  photographerServiceId: string;
}

export interface IToggleServiceStateRequest {
  serviceId: string;
  isEnabled: boolean;
}

export interface IDuplicateServiceRequest {
  photographerServiceId: string;
}

export interface IDuplicateServiceResponse extends IServiceItem {}

export interface IReorderServicesRequest {
  shootTypeId: string;
  photographerServiceIds: string[];
}

export interface IApiPhotographerServices {
  fetchServices: (request: IFetchServicesRequest) => Promise<IFetchServicesResponse>;
  loadDetails: (request: ILoadServiceDetailsRequest) => Promise<ILoadServiceDetailsResponse>;
  updateDetails: (request: IUpdateServiceRequest) => Promise<void>;
  createService: (request: ICreateServiceRequest) => Promise<ICreateServiceResponse>;
  deleteService: (request: IDeleteServiceRequest) => Promise<void>;
  toggleServiceState: (request: IToggleServiceStateRequest) => Promise<void>;
  duplicateService: (request: IDuplicateServiceRequest) => Promise<IDuplicateServiceResponse>;
  reorderServices: (request: IReorderServicesRequest) => Promise<void>;
}

const api: IApiPhotographerServices = {
  fetchServices: (request: IFetchServicesRequest) =>
    apiClient.get<IFetchServicesResponse>(`${apiType.bookingWidget}photographer/services`, {
      params: { ...clean(request) }
    }),

  loadDetails: (request: ILoadServiceDetailsRequest) =>
    apiClient.get<ILoadServiceDetailsResponse>(
      `${apiType.bookingWidget}photographer/services/${request.photographerServiceId}`
    ),
  updateDetails: (request: IUpdateServiceRequest) =>
    apiClient.put<void>(`${apiType.bookingWidget}photographer/services`, request),

  createService: (request: ICreateServiceRequest) =>
    apiClient.post<ICreateServiceResponse>(`${apiType.bookingWidget}photographer/services`, request),

  deleteService: (request: IDeleteServiceRequest) =>
    apiClient.request<void>({
      method: "delete",
      url: `${apiType.bookingWidget}photographer/services`,
      data: request
    }),

  toggleServiceState: (request: IToggleServiceStateRequest) =>
    apiClient.put<void>(`${apiType.bookingWidget}photographer/services/toggle`, request),

  duplicateService: (request: IDuplicateServiceRequest) =>
    apiClient.post<IDuplicateServiceResponse>(`${apiType.bookingWidget}photographer/services/duplicate`, request),

  reorderServices: (request: IReorderServicesRequest) =>
    apiClient.put<void>(`${apiType.bookingWidget}photographer/services/sort`, request)
};

export default api;
