const colors = {
  white: "#ffffff",
  black: "#000000",
  jetBlack: "#343434",
  bodyBg: "#fbfbfd",
  inputGray: "rgba(151,151,151, .25)",
  placeholder: "rgba(0, 0, 0, 0.3)",
  tooltip: "rgba(56, 60, 66, 0.95)",
  grey: "#cccccc",
  lightGrey: "#ececec",
  darkGrey: "#757575",
  darkGreen: "#0d9f37",
  filterGrayBorder: "#e1e1e1",
  orange: "#f15a0b",
  lightOrange: "#f7e8da",
  darkOrange: "#d44900",
  filterOrangeText: "#ff5a00",
  crushOrange: "#ff5900",
  grayPlaceholder: "#757575",
  grayBackground: "#f6f6f6",
  grayBorder: "#a9a9a9",
  grayCaret: "#A3ABB4",
  strokeOrange: "#fe884b",
  redNotification: "#c11616"
};

export default colors;
