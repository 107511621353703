import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import SideMenu from "../../../../components/layout/menu/sidemenu";
import { IAppState } from "../../../../store/state";

function mapStateToProps({ menu, session }: IAppState) {
  return {
    items: menu.items,
    userInfo: session.userInfo
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideMenu));
