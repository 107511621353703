import React from "react";
import styles from "./zenInstagramBlock.module.scss";
import cx from "classnames";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import { IDataSSRInstangramBlock } from "../blockModels";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import colors from "utilities/colors";
import { parseBody } from "utilities/blocks/string";
import { renderLineBreaks } from "utilities/blocks/string";
import { getSiteFonts, ISiteFontPair, universalWindow as window } from "utilities/blocks/site";

import DesktopCarousel from "./carousel/desktop";
import MobileCarousel from "./carousel/mobile";
import ImagesLightBox from "./../../ImagesLightBox";
import { INavigationMenuItem, IRoutes } from "../../../models/models";
import { PageContext } from "utilities/pageContext";

export interface IInstagramImage {
  id: string;
  isFallback: boolean;
  url: string;
  description: string;
}

export interface ILayoutBImage extends IInstagramImage {
  index: number;
}

export interface IInstagramImageLayoutB {
  images: ILayoutBImage[];
}

export interface IInstagramBlockProps extends IZenBaseBlockProps {
  images: IInstagramImage[];
  newImages?: IInstagramImage[];
  labelText?: string;
  pages?: INavigationMenuItem[];
  routes?: IRoutes;
  caption: boolean;
  disabled?: boolean;
  isEditionView?: boolean;
  isDarkBackground?: boolean;
  appearanceWidth: string;
  buttonLabel: string;
  title: string;
  titleType: string;
  body: string;
  caret: string;
  instagramUsername: string;
  lightbox: string;
  hasError?: boolean;
  instagramIsConnected?: boolean;
  hasNextPage: boolean;
  isFetchingImages: boolean;
  getImagesNextPage: () => void;
  dataSSRInstagramBlock?: IDataSSRInstangramBlock;
}

interface IInstagramBlockState {
  viewportWidth: number;
  currentPhoto: number;
  imagesLayoutB: IInstagramImageLayoutB[];
  showLightBox: boolean;
  initLightBoxImage: number;
  layoutBPhotoIndex: number;
}

export class ZenInstagramBlock extends React.Component<IInstagramBlockProps, IInstagramBlockState> {
  public static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  constructor(props: IInstagramBlockProps) {
    super(props);
    this.state = {
      viewportWidth: 0,
      currentPhoto: 0,
      imagesLayoutB: [],
      showLightBox: false,
      initLightBoxImage: 0,
      layoutBPhotoIndex: 0
    };
  }

  public shouldComponentUpdate(nextProps: IInstagramBlockProps, nextState: IInstagramBlockState) {
    return nextProps !== this.props || this.state !== nextState;
  }

  public componentDidMount() {
    this.updateWindowDimensions();
    if (this.props.layout === "B" && this.props.images.length > 0) {
      this.processImagesLayoutB(this.props.images);
    }
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  public componentDidUpdate(prevProps: IInstagramBlockProps) {
    if (this.props.images.length > prevProps.images.length && this.props.layout === "B" && this.props.newImages) {
      this.processImagesLayoutB(this.props.newImages);
    }
    if (this.props.layout === "B" && this.props.layout !== prevProps.layout && this.state.imagesLayoutB.length === 0) {
      this.processImagesLayoutB(this.props.images);
    }
    if (this.props.layout === "B" && this.props.images !== prevProps.images && this.state.imagesLayoutB.length === 0) {
      this.processImagesLayoutB(this.props.images);
    }
  }

  public componentWillUnmount() {
    this.restoreBodyValues();
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  private getTitle = (title: string, titleStyle: React.CSSProperties, titleType: string, siteFonts: ISiteFontPair) => {
    if (titleType === "navigate") {
      return (
        <a
          className={cx(styles.title, styles[siteFonts.primaryExtra], {
            [styles.titleClickable]: !this.props.readOnly
          })}
          style={titleStyle}
          href={`https://www.instagram.com/${this.props.instagramUsername}`}
          target="_blank"
        >
          {title}
        </a>
      );
    } else {
      return (
        <h2
          className={cx(styles.title, styles[siteFonts.primaryExtra], {
            [styles.titleClickable]: !this.props.readOnly
          })}
          style={titleStyle}
        >
          {title}
        </h2>
      );
    }
  };

  private getCaretColor = (caret: string) => {
    let result = this.props.siteTheme.accentColor.value;

    switch (caret) {
      case "black":
        result = colors.black;
        break;
      case "white":
        result = colors.white;
        break;
    }

    return result;
  };

  private updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth });
  };

  private restoreBodyValues = () => {
    document.body.style.overflowX = "auto";
    document.body.style.overflowY = this.context.isPreview ? "scroll" : "auto";
  };

  private handleImageClick = (index: number) => {
    if (!this.props.isEditionView && this.props.lightbox !== "none") {
      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = "hidden";
      this.setState({ initLightBoxImage: index, showLightBox: true });
    }
  };

  private goNext = (target: number, pos: number, ref: React.RefObject<HTMLDivElement>) => {
    let move = 0;
    const frame = () => {
      if (ref.current) {
        if (move > target) {
          move = move - 15;

          if (target - move > -15) {
            move = target;
          }

          if (move === target) {
            this.setState({ currentPhoto: this.state.currentPhoto + 1 });
          }

          ref.current.style.left = pos + move + "px";
          window.requestAnimationFrame(frame);
        }
      }
    };
    window.requestAnimationFrame(frame);
  };

  private goBack = (target: number, pos: number, ref: React.RefObject<HTMLDivElement>, callback?: () => void) => {
    let move = 0;
    const frame = () => {
      if (ref.current) {
        if (move < target) {
          move = move + 15;

          if (target - move < 15) {
            move = target;
            this.setState({ currentPhoto: this.state.currentPhoto - 1 });
          }

          if (pos + move === 0 && callback) {
            callback();
          }

          ref.current.style.left = pos + move + "px";
          window.requestAnimationFrame(frame);
        }
      }
    };
    window.requestAnimationFrame(frame);
  };

  private renderContent = (isMobileSize: boolean) => {
    const {
      hasError,
      layout,
      appearanceWidth,
      caret,
      images,
      hasNextPage,
      isFetchingImages,
      lightbox,
      readOnly,
      caption,
      getImagesNextPage
    } = this.props;
    if (hasError) {
      return (
        <div className={styles.errorMsg}>
          <p>Connection Error. Check your account settings and try again.</p>
        </div>
      );
    } else if (isMobileSize) {
      return (
        <MobileCarousel
          viewportWidth={this.state.viewportWidth}
          photosLength={layout === "B" ? this.state.imagesLayoutB.length : images.length}
          currentPhoto={this.state.currentPhoto}
          layout={layout}
          hasNextPage={hasNextPage}
          caption={caption}
          images={layout === "B" ? [] : images}
          imagesLayoutB={this.state.imagesLayoutB}
          lightbox={lightbox}
          readOnly={readOnly}
          isFetchingImages={isFetchingImages}
          handleImageClick={this.handleImageClick}
          goBack={this.goBack}
          goNext={this.goNext}
          getImagesNextPage={getImagesNextPage}
        />
      );
    } else {
      return (
        <DesktopCarousel
          caretColor={this.getCaretColor(caret)}
          appearanceWidth={appearanceWidth}
          layout={layout}
          photosLength={layout === "B" ? this.state.imagesLayoutB.length : images.length}
          hasNextPage={hasNextPage}
          goBack={this.goBack}
          goNext={this.goNext}
          getImagesNextPage={getImagesNextPage}
          isFetchingImages={isFetchingImages}
          caption={caption}
          currentPhoto={this.state.currentPhoto}
          images={layout === "B" ? [] : images}
          imagesLayoutB={this.state.imagesLayoutB}
          lightbox={lightbox}
          readOnly={readOnly}
          handleImageClick={this.handleImageClick}
        />
      );
    }
  };

  private processImagesLayoutB = (images: IInstagramImage[]) => {
    const imagesLayoutB: IInstagramImageLayoutB[] = [];
    let renderAsBentobox = true;
    let imgLayoutB: IInstagramImageLayoutB = {
      images: []
    };

    let currentIndex = this.state.layoutBPhotoIndex;

    images.map((item: IInstagramImage, idx: number) => {
      if (renderAsBentobox) {
        imgLayoutB.images.push({ ...item, index: currentIndex });
        if (imgLayoutB.images.length === 3 || idx === images.length - 1) {
          imagesLayoutB.push(imgLayoutB);
          renderAsBentobox = false;
          imgLayoutB = {
            images: []
          };
        }
      } else {
        imgLayoutB.images = [{ ...item, index: currentIndex }];
        imagesLayoutB.push(imgLayoutB);
        // if there are at least two images we render a bentobox
        if (images.length - idx + 1 >= 2) {
          renderAsBentobox = true;
        }
        imgLayoutB = {
          images: []
        };
      }
      currentIndex++;
    });

    this.setState({ imagesLayoutB: [...this.state.imagesLayoutB, ...imagesLayoutB], layoutBPhotoIndex: currentIndex });
  };

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      layout,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      fontFamily,
      divider,
      padding,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      appearanceWidth,
      titleType,
      body,
      hasError
    } = this.props;

    const title = parseBody(this.props.title || "");
    const isMobileSize = this.state.viewportWidth < 767;
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    const contentClass = cx(styles[siteFonts.primary], {
      [styles.containerEditor]: !readOnly && appearanceWidth === "full",
      [styles.layoutA]: layout === "A" || (layout === "C" && isMobileSize),
      [styles.layoutB]: layout === "B",
      [styles.layoutC]: layout === "C" && !isMobileSize,
      [styles.fixedBlock]: appearanceWidth === "fixed" || isMobileSize,
      [styles.fullBlock]: appearanceWidth === "full" && !isMobileSize
    });

    const blockTextColor = {
      color: getBlockTextColor(
        backgroundType === "none" ? siteTheme.backgroundColor.value : backgroundColor,
        siteTheme.accentColor.value,
        backgroundType,
        siteTheme.backgroundColor.value
      )
    };

    const handleLightBoxClose = () => {
      this.restoreBodyValues();
      this.setState({ showLightBox: false });
    };

    const parsedBody = parseBody(body);

    const titleStyle: React.CSSProperties = { color: siteTheme.accentColor.value };
    const subtitleStyle = blockTextColor;

    if (hasError && readOnly) {
      return null;
    }
    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        readOnly={readOnly}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        selectedBlock={selectedBlock}
        fullWidth={appearanceWidth === "full" || isMobileSize}
        noPadding={appearanceWidth === "full" || isMobileSize}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div className={contentClass}>
            <div className={styles.blockHead}>
              {this.getTitle(title, titleStyle, titleType, siteFonts)}
              {parsedBody && (
                <div
                  className={cx(styles[siteFonts.secondary], { [styles.subtitleClickable]: !this.props.readOnly })}
                  style={subtitleStyle}
                >
                  {renderLineBreaks(parsedBody, true)}
                </div>
              )}
            </div>

            {this.renderContent(isMobileSize)}
          </div>
        </ZenAnimatedComponent>

        {this.state.showLightBox && (
          <ImagesLightBox
            show={this.state.showLightBox}
            selectedIndexImage={this.state.initLightBoxImage}
            images={this.props.images.map(image => image.url)}
            onClose={handleLightBoxClose}
            isPreview={this.context.isPreview}
          />
        )}
      </ZenBaseBlock>
    );
  }
}
