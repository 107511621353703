import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IDisconnectOwnProps, IDisconnectProps } from ".";
import { applyGoogleCalendarDisconnect } from "../../../../../store/profile/availability/actions";
import { disconnect as disconnectCalendarAction } from "../../../../../store/profile/googleCalendar/actions";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({
  profile: {
    googleCalendar: { disconnect }
  }
}: IAppState) {
  return { disconnect };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    disconnect: {
      onDisconnect: () => dispatch(disconnectCalendarAction()),
      onDisconnected: () => dispatch(applyGoogleCalendarDisconnect())
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: IDisconnectOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    disconnect: { ...stateProps.disconnect, ...dispatchProps.disconnect }
  } as IDisconnectProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
