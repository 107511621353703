import cx from "classnames";
import { IZenSiteTheme, IPage, IPhotographerFoldersState } from "../../../../models/models";
import ZenButton from "components/blocks/zenButton";
import Breadcrumb, {
  getCurrentBreadcrumb,
  getPagesBreadcrumbs,
  IBreadcrumbItem,
  IExtraPage
} from "components/ClientView/Breadcrumb";
import { IClientViewGalleryState } from "components/ClientView/models";
import React, { PureComponent } from "react";
import { getSiteFonts } from "utilities/blocks/site";
import styles from "./private.module.scss";

interface IPrivateErrorProps {
  siteTheme: IZenSiteTheme;
  sitePages: IPage[];
  pageAlias: string;
  extraPages?: IExtraPage[];
  subdomain: string;
  rootFolderId?: string;
  isPublish?: boolean;
  isDevelopment: boolean;
  clientViewGallery: IClientViewGalleryState;
  photographerFolders: IPhotographerFoldersState;
}

interface IDynamicGalleryState {
  breadcrumbItems: IBreadcrumbItem[];
  isBreadcrumbLoad: boolean;
}

export const portfolioErrorGoBack = () => {
  const backId = sessionStorage.getItem("PORTFOLIO_BACK_GALLERY_ID");
  if (backId) {
    setTimeout(() => {
      const brick = document.getElementById(backId);
      if (brick) {
        brick.scrollIntoView();
      }
      sessionStorage.removeItem("PORTFOLIO_BACK_GALLERY_ID");
    }, 800);
  }
};

class PrivateError extends PureComponent<IPrivateErrorProps, IDynamicGalleryState> {
  constructor(props: IPrivateErrorProps) {
    super(props);
    this.state = {
      breadcrumbItems: [],
      isBreadcrumbLoad: false
    };
  }

  private updateBreadcrumbState = () => {
    const { sitePages, pageAlias, subdomain, rootFolderId, isPublish, isDevelopment, extraPages } = this.props;

    const pagesBreadcrumb = getPagesBreadcrumbs(
      sitePages,
      pageAlias,
      subdomain ? subdomain : "",
      isDevelopment,
      isPublish,
      extraPages
    );
    const foldersGalleriesBreadcrumb = getCurrentBreadcrumb(
      pagesBreadcrumb,
      this.props,
      false,
      rootFolderId,
      isPublish
    );

    this.setState({
      breadcrumbItems: [...foldersGalleriesBreadcrumb],
      isBreadcrumbLoad: true
    });
  };

  private handleBackClick = () => {
    sessionStorage.setItem("PORTFOLIO_BACK_GALLERY_ID", this.props.clientViewGallery.id);
    window.history.back();
  };

  private breadcrumbDataIsAvailable = (currentProps: IPrivateErrorProps) =>
    !this.state.isBreadcrumbLoad &&
    currentProps.photographerFolders.folders.length > 0 &&
    currentProps.clientViewGallery.id;

  public componentDidUpdate() {
    if (this.breadcrumbDataIsAvailable(this.props)) {
      this.updateBreadcrumbState();
    }
  }

  public render() {
    const { siteTheme, sitePages, clientViewGallery } = this.props;
    const { isBreadcrumbLoad, breadcrumbItems } = this.state;
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    return (
      <div id="container" className={cx(styles.container, styles[siteFonts.primary])}>
        <div className={styles.content}>
          <Breadcrumb siteTheme={siteTheme} show={isBreadcrumbLoad} sitePages={sitePages} separator=">">
            {breadcrumbItems.map(({ to, label, isFull }) => (
              <a key={to} href={`${!isFull ? window.location.origin : ""}${to}`}>
                {label}
              </a>
            ))}
          </Breadcrumb>
          <div className={styles.titleContainer}>
            <div className={styles.title} style={{ color: siteTheme.accentColor.value }}>
              {`${
                clientViewGallery.expiration?.active === false
                  ? "Sorry, this gallery is inactive."
                  : "Sorry, this gallery is private."
              }`}
            </div>
            <ZenButton
              onClick={this.handleBackClick}
              siteTheme={siteTheme}
              layout={"A"}
              labelText="Back"
              accentColor={siteTheme.accentColor}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PrivateError;
