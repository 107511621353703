import React, { useState } from "react";
import styles from "./scrollCarousel.module.scss";
import { useAsyncState } from "utilities/blocks/hooks";

export interface IScrollCarouselProps {
  controlHeight: number;
  navbarHeight: number;
  controlHeightStyle: any;
  selectedItem: number;
  interval: number;
  infiniteLoop?: boolean;
  autoPlay?: boolean;
  isEditionView?: boolean;
  onSetCurrentPicture?: (picture: number) => void;
}

const ScrollCarousel: React.FC<IScrollCarouselProps> = props => {
  const [noPictures, setNoPictures] = useAsyncState(0);
  const [activeAnimation, setActiveAnimation] = useState(true);
  const [refContainer, setRefContainer] = useState(React.createRef<HTMLDivElement>());
  const [currentPicture, setCurrentPicture] = useAsyncState(props.selectedItem ? props.selectedItem : 0);

  const getRelativeHeight = React.useCallback(
    (newPosition: number) => {
      return props.isEditionView
        ? `${parseInt(props.controlHeightStyle.split("v")[0]) * newPosition}vh`
        : `${newPosition * props.controlHeight}px`;
    },
    [props.controlHeight, props.controlHeightStyle, props.isEditionView]
  );

  const setPicturePosition = React.useCallback(
    (position: number, isSmoot: boolean) => {
      if (refContainer.current) {
        const newPosition = -1 * position;
        const newTopPosition = getRelativeHeight(newPosition);

        if (props.isEditionView) {
          refContainer.current.style.top = newTopPosition;
        } else if (refContainer.current) {
          if (isSmoot) {
            refContainer.current.scrollTo({
              top: position * props.controlHeight,
              behavior: "smooth"
            });
          } else {
            refContainer.current.scrollTo(0, position * props.controlHeight);
          }
        }
      }
    },
    [getRelativeHeight, refContainer, props.controlHeight, props.isEditionView]
  );

  const clickStep = React.useCallback(
    async (isRight: boolean, prevRef: any) => {
      const conditionRight = currentPicture < noPictures;
      const conditionLeft = currentPicture > 0;

      let prevCurrentPicture = currentPicture;
      let isLast = true;

      if (props.infiniteLoop) {
        if (currentPicture === 0 && !isRight) {
          prevCurrentPicture = await setCurrentPicture(noPictures + 1);
          if (props.onSetCurrentPicture) {
            props.onSetCurrentPicture(prevCurrentPicture);
          }
        }

        if (currentPicture === noPictures && isRight) {
          isLast = false;
          prevCurrentPicture = await setCurrentPicture(-1);
          if (props.onSetCurrentPicture) {
            props.onSetCurrentPicture(prevCurrentPicture);
          }
        }
      }

      if ((isRight ? conditionRight : conditionLeft) || props.infiniteLoop) {
        const newOp = isRight ? 1 : -1;
        const newCurrentPicture = await setCurrentPicture(prevCurrentPicture + newOp);
        if (props.onSetCurrentPicture) {
          props.onSetCurrentPicture(prevCurrentPicture);
        }
        setRefContainer(prevRef);
        setPicturePosition(newCurrentPicture, isLast);
      }
    },
    [currentPicture, noPictures, props.infiniteLoop, setCurrentPicture, setPicturePosition]
  );

  React.useEffect(() => {
    if (props.children) {
      setNoPictures(React.Children.count(props.children) - 1);
    }

    let selectedItem = props.selectedItem ? props.selectedItem : 0;
    setCurrentPicture(props.isEditionView ? selectedItem : currentPicture);
    setPicturePosition(props.isEditionView ? selectedItem : currentPicture, true);

    if (props.onSetCurrentPicture) {
      props.onSetCurrentPicture(currentPicture);
    }

    const prevRef = { ...refContainer };

    let interval: ReturnType<typeof setTimeout>;
    if (props.autoPlay) {
      interval = setInterval(() => {
        if (activeAnimation) {
          clickStep(true, prevRef);
        }
      }, props.interval);
    }
    return () => clearInterval(interval);
  }, [
    props.children,
    clickStep,
    currentPicture,
    props.autoPlay,
    props.interval,
    refContainer,
    setNoPictures,
    setCurrentPicture,
    setPicturePosition,
    activeAnimation,
    props.isEditionView,
    props.selectedItem,
    getRelativeHeight
  ]);

  return (
    <React.Fragment>
      <div
        style={{
          height: props.controlHeightStyle
        }}
        onMouseEnter={() => {
          setActiveAnimation(false);
        }}
        onMouseLeave={() => {
          setActiveAnimation(true);
        }}
        className={styles.zenContainerscroll}
      >
        <div
          style={{
            height: props.isEditionView ? "auto" : props.controlHeightStyle,
            position: props.isEditionView ? "absolute" : "relative",
            transition: props.isEditionView ? "top 1s" : "none"
          }}
          ref={refContainer}
          className={styles.zenContainerscrollCarousel}
        >
          <div className={styles.children}>{props.children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ScrollCarousel;
