import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import React from "react";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { logger } from "redux-logger";
import thunk from "redux-thunk";
import apiService from "./api";
import Router from "./containers/router";
import reducers from "./store/reducers";
import "./styles/_styles.scss";
import { AsyncLoader } from "./utilities/asyncLoader";
import { addAsyncScript, isDevelopment } from "./utilities/helpers";
import { NavigationManager } from "./utilities/navigationManager";
import NewRelic from "./utilities/newRelic";

NewRelic.init();

const history = createBrowserHistory({ basename: "/" });
const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk.withExtraArgument(apiService), routeMiddleware];

if (isDevelopment()) {
  middlewares.push(logger);
}

export const store = createStore(reducers(history), composeWithDevTools(applyMiddleware(...middlewares)));

if (NavigationManager.instance.widgetMode) {
  addAsyncScript("https://js.stripe.com/v3/", () => AsyncLoader.register(AsyncLoader.stripeFactory, Stripe));
} else if (NavigationManager.isInIframe()) {
  (window as any).iFrameResizer = {
    onReady: () => AsyncLoader.register(AsyncLoader.iframeResizerChild, (window as any).parentIFrame)
  };

  addAsyncScript(`${process.env.PUBLIC_URL}/iframeResizer.contentWindow.min.js`);

  NavigationManager.instance.subscribeToUrlChange(history);
} else if (NavigationManager.isInTestMode(history)) {
  addAsyncScript(`${process.env.PUBLIC_URL}/iframeResizer.min.js`, () =>
    AsyncLoader.register(AsyncLoader.iframeResizerParent, (window as any).iFrameResize)
  );
}

const WidgetApp = () => {
  return (
    <Provider store={store}>
      <Router history={history} />
    </Provider>
  );
};

export default WidgetApp;
