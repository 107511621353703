import { reduce } from "lodash";
import get from "lodash/get";

enum PRICING_TYPE {
  photo = "pricingPhotos",
  tag = "pricingTags"
}

const getPricingPhoto = (product: any, photoId: string = "") => {
  return get(product, `${PRICING_TYPE.photo}.${photoId}`, null);
};

/**
 * 
 * @param product 
 * @param keywords 
 * @returns highest overridedPrice pricing tag
 */
const getPricingTag = (product: any, keywords: string[] = []) => {
  return reduce(keywords, (max: any, current: string) => {
    const pricingTag = get(product, `${PRICING_TYPE.tag}.${current}`, null);
    if (!pricingTag) return max;
    return get(max, "overridedPrice") > get(pricingTag, "overridedPrice") ? max : pricingTag;
  }, null);
}

export const getOverridedPrice = (product: any, photo: any) => {
  const { id: photoId, keywords } = photo;
  const customPricing = getPricingPhoto(product, photoId) || getPricingTag(product, keywords);
  return customPricing?.overridedPrice || product.price;
};