import map from "lodash/map";
import videojs from "video.js";
import { Quality } from ".";
import Item from "./Item";
import { log } from "./utils";

const MenuButton = videojs.getComponent("MenuButton");

export interface IMenuOptions extends videojs.MenuButtonOptions {}

class Menu extends MenuButton {
  constructor(player: videojs.Player, options?: IMenuOptions) {
    super(player, options);
  }

  public createEl() {
    return videojs.dom.createEl("div", {
      className: "vjs-menu-button vjs-menu-button-popup vjs-control vjs-button"
    });
  }

  public buildCSSClass() {
    return `${super.buildCSSClass()} vjs-icon-cog`;
  }

  public setQualities(qualities: Quality[]) {
    if (this.qualities !== qualities) {
      this.qualities = qualities;
      this.update();
    }
  }

  public selectQuality(quality: Quality) {
    if (this.selectedQuality !== quality) {
      this.selectedQuality = quality;
      this.update();
    }
  }

  public update() {
    super.update();

    if (this.qualities && this.qualities.length > 1) {
      log("update menu, show");
      this.show();
    } else {
      log("update menu, hide");
      this.hide();
    }
  }

  public createItems() {
    const items = map(this.qualities, quality => {
      const item = new Item(this.player(), { quality, selected: quality === this.selectedQuality });
      item.on("select", () => {
        log(`select menu item ${item.options_.label}`);
        this.trigger({ type: "qualityselect", quality });
      });
      return item;
    });

    return items;
  }

  private qualities?: Quality[];
  private selectedQuality?: Quality;
}

export default Menu;
