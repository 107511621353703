import uniq from "lodash/uniq";
import trim from "lodash/trim";

const isDevelopment = process.env.REACT_APP_ENVIRONMENT === "development";

export const getAllQuerystringParameters = (url: string) => {
  if (url.indexOf("?") > -1) {
    url = url.substr(url.indexOf("?") + 1);
  }

  const params = url.split("&");
  const obj: any = {};
  params.forEach((str: string) => {
    const parts = str.split("=");
    obj[parts[0]] = parts[1];
  });

  return obj;
};

export const getQuerystringParameter = (url: string, paramName: string) => {
  const params = getAllQuerystringParameters(url);
  return params[paramName];
};

export const getAbsoluteUrl = (relativeUrl: string) => {
  relativeUrl = relativeUrl.startsWith("/") ? relativeUrl.substr(1) : relativeUrl;

  const location = window.location;
  const url = `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ""}/${relativeUrl}`;

  return url;
};

export const resolveMappedApiUrl = (relativePath: string) =>
  isDevelopment
    ? `${process.env.REACT_APP_STORAGE_CDN_HOST}/api/${relativePath}`
    : getMappedApiUrl(process.env.REACT_APP_API_URL as string, relativePath);

export const resolveMappedApiHeaders = () =>
  isDevelopment
    ? {
        headers: {
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_TEST_SUBSCRIPTION_KEY
        }
      }
    : undefined;

// Builds a full url extracting the environment base api url and adding a relative path to it
export const getMappedApiUrl = (apiUrl: string, relativePath: string) =>
  apiUrl.substr(0, apiUrl.indexOf("api/") + 4) + relativePath;

// Transforms storage relative paths to absolute urls only for local env.
// storage relative mapping exists on test env & above
export const processStaticImagesUrls = (htmlOrCss: string) => {
  let result = htmlOrCss.replace(
    /src=["'](\/storage\/[\w\-_/]+\..{3})["']/g,
    (_: string, url: string) => `src="${processStaticImageUrl(url)}"`
  );

  result = result.replace(
    /url\(([\w\-_/]+\..{3})\)/g,
    (_: string, url: string) => `url("${processStaticImageUrl(url)}")`
  );

  return result;
};

// Transforms an storage relative url to an absolute url for local env
// storage relative mapping exists on test env & above
export const processStaticImageUrl = (url: string) => {
  let finalUrl = url;
  if (url.startsWith("/storage")) {
    finalUrl = url.replace(/\/storage/g, `${process.env.REACT_APP_STORAGE_CDN_HOST}/storage`);
  }

  return finalUrl;
};

export const getPublishedSiteUrl = (subDomain: string) => {
  const url = process.env.REACT_APP_SITE_VIEWER_URL as string;
  if (!isDevelopment) {
    return url.replace("<subdomain>", subDomain);
  }

  return combineURLs(url, subDomain);
};

export const combineURLs = (baseURL: string, relativeURL: string) => {
  return relativeURL ? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "") : baseURL;
};

export const supportedMediaExtensions = {
  photo: ["jpg", "jpeg", "jpe", "png", "gif", "heif", "heic"],
  thumbnail: ["jpg", "jpeg", "jpe", "png"],
  video: ["mp4", "mov"]
};

const defaultExtension = "jpg";
const allMediaExtensions = uniq([
  ...supportedMediaExtensions.photo,
  ...supportedMediaExtensions.thumbnail,
  ...supportedMediaExtensions.video
]);
const supportedCdnExtensions = ["jpg", "jpeg", "png", "gif", "mp4"];

const fixMediaExtension = (name: string) => {
  const nameLowerCase = name.toLowerCase();
  const mediaExtension = allMediaExtensions.find(extension => nameLowerCase.endsWith("." + extension));

  if (mediaExtension) {
    if (supportedCdnExtensions.includes(mediaExtension)) {
      return name;
    }

    name = name.substring(0, name.length - mediaExtension.length - 1);
  }

  return `${name}.${defaultExtension}`;
};

export interface IMediaName {
  fileName?: string | null;
  title?: string | null;
  name?: string | null;
}

// keep in sync with "MediaUrlEncode": https://dev.azure.com/zenfolio/Zenfolio/_git/cs-folders?path=/Zenfolio.Folders/src/Zenfolio.Folders.Common/Extensions/StringExt.cs&version=GBmain&_a=contents
export const mediaUrlEncode = (media: IMediaName, id?: string | null) => {
  const sanitizedName =
    trim((media.title || media.name || media.fileName)?.replace(/(\s|\/)+/g, " "), " .") ||
    id?.substring(0, 8) ||
    "image";

  const result = encodeURIComponent(sanitizedName).replace(
    /[!'()*]/g,
    x =>
      `%${x
        .charCodeAt(0)
        .toString(16)
        .toUpperCase()}`
  );

  return fixMediaExtension(result);
};

export const getFolderUrlAlias = (title: string) => {
  title = title.toLowerCase();
  title = title.replace(/\s+/g, "-");
  title = title.replace(/[^a-zA-Z0-9 -]/g, "");
  return title;
};

export const getLinkFolderIdFromUrl = () => {
  const urlParams: any = getAllQuerystringParameters(window.location.search);
  return urlParams["lf"];
};

export const buildReferralUrl = (subdomain: string) => {
  const baseUrl = process.env.REACT_APP_REFERRAL_LINK_URL;
  if (!baseUrl) return null;
  return `${baseUrl}?utm_medium=user-site&utm_source=${subdomain}`;
};
