// Do not re-order imports here!
import "./styles/_reset.scss";
// tslint:disable-next-line:ordered-imports
import "@zenfolio/core-components/dist/index.css";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import WidgetApp from "./widget-app";

ReactDOM.render(<WidgetApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
