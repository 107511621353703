import * as React from "react";

interface ILinkButtonProps {
  className?: string;
  onClick?: () => void;
}

const LinkButton: React.FC<ILinkButtonProps> = props => {
  const emptyHref: string = undefined!;

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <a href={emptyHref} className={props.className} onClick={onClick}>
      {props.children}
    </a>
  );
};

export default LinkButton;
