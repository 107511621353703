import React from "react";
import { initializeIconProps } from "../../../utilities/icons";
import IIConProps from "../IIconProps";

const Comments = (rawProps: IIConProps) => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`${props.minX} ${props.minY} 24 24`}
    >
      <g fill="none" fillRule="evenodd" stroke={props.stroke} strokeLinejoin="round">
        <path d="M9.667 9.667V4.333h10v15.334H4.333v-10z" />
        <g strokeLinecap="round">
          <path d="M7.5 17.5l9-.5M11.5 16.5v-5h-2v5M15.5 16.5v-10h-2v10" />
        </g>
        <path d="M9.333 4L4 9.333" />
      </g>
    </svg>
  );
};

export default Comments;
