import classNames from "classnames";
import * as React from "react";
import styles from "./index.module.scss";

export interface INavItemProps {
  active?: boolean;
  disabled?: boolean;
  className?: string;
}

export const NavItem: React.SFC<INavItemProps> = props => {
  const { className, active, disabled } = props;
  const classes = classNames(className, styles.navItem, active && styles.active, disabled && styles.disabled);

  return <li className={classes}>{props.children}</li>;
};
