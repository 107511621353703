import React, { Fragment } from "react";
import cx from "classnames";
import styles from "./productOption.module.scss";
import { FormCheckbox } from "components/FormCheckbox";
import { IMAGE_TITLES } from "../../designer/model";
import { ICurrency } from "models";
import { formatPriceByCurrency } from "utilities/currency";

interface IProductOptionProps {
  customClass?: string;
  title?: string;
  description?: string;
  note?: string;
  imgSrc?: string;
  type?: string;
  price?: number;
  onChange: (value: boolean) => void;
  onViewLarger?: () => void;
  value: boolean;
  isAddon?: boolean;
  currency?: ICurrency;
  isSelfFulfilled?: boolean;
}

interface IProductOptionStates {
  isChecked: boolean;
}

export class ProductOption extends React.PureComponent<IProductOptionProps, IProductOptionStates> {
  public state: IProductOptionStates = {
    isChecked: this.props.value
  };

  public onChangeRadio = () => {
    this.setState((prevState: IProductOptionStates) => ({
      isChecked: !prevState.isChecked
    }));
    this.props.onChange && this.props.onChange(!this.state.isChecked);
  };

  componentDidUpdate(prevProps: IProductOptionProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        isChecked: this.props.value
      });
    }
  }

  private renderImageTile = () => {
    const { customClass = null, title, imgSrc, price, isAddon, currency } = this.props;
    return (
      <div
        className={cx(styles.otherContainer, {
          [styles.isActive]: this.state.isChecked
        })}
      >
        <div className={cx(styles.titleWrapper)}>
          {title && <span className={cx(styles.title)}>{title}</span>}
          <div className={cx(styles.label)}>
            {price ? (
              <div className={cx(styles.price)}>{`+${formatPriceByCurrency(price.toFixed(2), currency?.symbol)}`}</div>
            ) : null}
            <FormCheckbox
              checked={this.state.isChecked}
              onChange={this.onChangeRadio}
              className={cx(styles.productOption, { [styles.isMandatory]: !isAddon })}
            />
          </div>
        </div>
        {imgSrc && <img src={imgSrc} className={cx(styles.preview)} />}
        {this.props.children && this.props.children}
      </div>
    );
  };

  private renderNormalTile = () => {
    const { customClass = null, title, imgSrc, description, note, price, isAddon, currency, onViewLarger } = this.props;
    return (
      <div className={cx(styles.paperContainer)}>
        {title && <span className={cx(styles.title)}>{title}</span>}
        {imgSrc && <img src={imgSrc} className={cx(styles.preview)} />}
        {onViewLarger && (
          <span className={cx(styles.viewLarger)} onClick={onViewLarger}>
            View Larger
          </span>
        )}
        {description && <p className={cx(styles.description)}>{description}</p>}
        {note && <p className={cx(styles.note)}>{note}</p>}
        <div className={cx(styles.coatingWrapper)}>
          <FormCheckbox
            checked={this.state.isChecked}
            onChange={this.onChangeRadio}
            className={cx(styles.productOption, { [styles.isMandatory]: !isAddon })}
          />
          <span>{!!isAddon ? `ADD` : `SELECT`}</span>
          {price ? (
            <div className={cx(styles.price)}>{`+${formatPriceByCurrency(price.toFixed(2), currency?.symbol)}`}</div>
          ) : null}
        </div>
      </div>
    );
  };

  private renderItem = (type: string | undefined) => {
    const isImageTile = !!IMAGE_TITLES.includes(type || "") || this.props.isSelfFulfilled;
    if (isImageTile) {
      return this.renderImageTile();
    }
    return this.renderNormalTile();
  };

  public render() {
    const { type, customClass } = this.props;
    return (
      <Fragment>
        <div
          className={cx(styles.container, {
            [styles.sffContainer]: this.props.isSelfFulfilled,
            [customClass!]: !!customClass
          })}
        >
          {this.renderItem(type)}
        </div>
      </Fragment>
    );
  }
}
