import React, { FunctionComponent } from "react";
import { useSlate } from "slate-react";

import IconItalic from "../../../assets/svg/italic";
import { isMarkActive, toggleMark } from "../../../common/transforms";
import { ToolbarButton } from "../../../components/toolbar-button";
import { MARK_ITALIC } from "../../../core/types";

interface OwnProps {}

type Props = OwnProps;

const ButtonItalic: FunctionComponent<Props> = props => {
  const editor = useSlate();

  return (
    <ToolbarButton
      onMouseDown={() => {
        toggleMark(editor, MARK_ITALIC);
      }}
      active={isMarkActive(editor, MARK_ITALIC)}
    >
      <IconItalic />
    </ToolbarButton>
  );
};

export { ButtonItalic };
