import Welcome from "../../../components/welcome";
import IError from "../../../utilities/error";
import { ActionStatus } from "../../common";
import { LoadProfileStateAction } from "./actions";
import { ActionType } from "./constants";
import { IProfileState } from "./model";

export interface ILoadProfileState {
  loadStatus: ActionStatus;
  info: IProfileState;
  error: IError | null;
}

const initialProfileState: ILoadProfileState = {
  loadStatus: "Init",
  info: Welcome.defaultsProfileState,
  error: null
};

export const profileState = (
  state: ILoadProfileState = initialProfileState,
  action: LoadProfileStateAction
): ILoadProfileState => {
  switch (action.type) {
    case ActionType.LOAD_PROFILE_STATE_STARTED:
      return {
        ...state,
        loadStatus: "Pending",
        info: Welcome.defaultsProfileState,
        error: null
      };
    case ActionType.LOAD_PROFILE_STATE_SUCCESS:
      return {
        ...state,
        loadStatus: "Success",
        info: action.state
      };
    case ActionType.LOAD_PROFILE_STATE_ERROR:
      return {
        ...state,
        loadStatus: "Error",
        error: action.error
      };
    default:
      return state;
  }
};

export default profileState;
