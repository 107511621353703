import cx from "classnames";
import { getStyledButtonTextColor } from "../../zenButton";
import { IColor } from "../../../../models/models";
import React, { FC, useCallback, useLayoutEffect, useRef, useState } from "react";
import useWindowLoad from "../../../../hooks/useWindowLoad";
import useWindowSize from "../../../../hooks/useWindowSize";
import Tag, { MoreTag } from "../Tag";
import styles from "./index.module.scss";

const tagsGap = 8;

interface ITagsProps {
  tags: string[];
  buttonsStyle: string;
  accentColor: IColor;
  loadedInEditor?: boolean;
  isDarkBackground?: boolean;
}

const Tags: FC<ITagsProps> = props => {
  const { tags, buttonsStyle, loadedInEditor, accentColor, isDarkBackground } = props;

  const [showAllTags, setShowAllTags] = useState(false);

  const { showMore, ellipsisLeft, setRef } = useTagsDisplayLogic(tags, showAllTags, loadedInEditor);

  const onMoreTagClick = () => {
    setShowAllTags(true);
  };

  const styledButtonTextColor = getStyledButtonTextColor(accentColor);

  const tagsAccentColorClassName = `tagsAccentColor-${accentColor.name}`;
  const tagsTextColorClassName = `tagsTextColor-${styledButtonTextColor.name}`;

  const styledButtonClasses = cx(accentColor ? tagsAccentColorClassName : "", tagsTextColorClassName);

  return (
    <div className={cx(styles.tagsContainer)}>
      {accentColor && (
        <style>
          {`
              .${tagsAccentColorClassName}{
                --accent-color: ${accentColor.value};
              }

              .${tagsTextColorClassName}{
                --text-color: ${styledButtonTextColor.value};
              }
            `}
        </style>
      )}
      <div ref={setRef} className={cx(styles.actualTags, { [styles.showAllTags]: showAllTags })}>
        {tags.map((t, i) => (
          <Tag
            key={i}
            tag={t}
            buttonsStyle={buttonsStyle}
            accentColor={accentColor}
            className={styledButtonClasses}
            isDarkBackground={isDarkBackground}
          />
        ))}
      </div>
      <div className={cx(styles.moreTagContainer, { [styles.hidden]: showAllTags || !showMore })}>
        <MoreTag
          onClick={onMoreTagClick}
          buttonsStyle={buttonsStyle}
          style={{ left: ellipsisLeft, position: "absolute" }}
          className={styledButtonClasses}
        />
      </div>
    </div>
  );
};

export default Tags;

const useTagsDisplayLogic = (tags: string[], showAllTags: boolean, loadedInEditor?: boolean) => {
  const [loaded, setLoaded] = useState(false);
  const [refIsReady, setRefIsReady] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [ellipsisLeft, setEllipsisLeft] = useState<number | undefined>();

  const ref = useRef<HTMLDivElement>();

  const setRef = useCallback((node: HTMLDivElement) => {
    if (node != null) {
      ref.current = node;

      setRefIsReady(true);
    }
  }, []);

  const { width } = useWindowSize();

  useWindowLoad(() => {
    setLoaded(true);
  });

  useLayoutEffect(() => {
    if (!showAllTags) {
      setShowMore(checkShouldShowMore());
    }
  }, [loaded, width, refIsReady, loadedInEditor, tags]);

  useLayoutEffect(() => {
    if (!showAllTags) {
      setEllipsisLeft(getEllipsisLeftPosition());
    }
  }, [loaded, showMore, width, tags, loadedInEditor]);

  const checkShouldShowMore = () => {
    const firstChild = ref.current?.firstElementChild as HTMLDivElement;
    const firstChildRect = firstChild?.getBoundingClientRect();
    const lastChild = ref.current?.lastElementChild as HTMLDivElement;
    const lastChildRect = lastChild?.getBoundingClientRect();

    const wrapped = lastChildRect?.top !== firstChildRect?.top;

    return wrapped;
  };

  const getEllipsisLeftPosition = () => {
    const firstChild = ref.current?.firstElementChild;
    const firstChildRect = firstChild?.getBoundingClientRect();

    const getLastUnwrappedElement = () => {
      const children = ref.current?.children!;
      let lastUnwrapped: HTMLElement | undefined;

      for (let index = 0; index < children.length; index++) {
        const element = children[index];
        const rect = element.getBoundingClientRect();

        if (firstChildRect?.top !== rect.top) {
          lastUnwrapped = children[index - 1] as HTMLElement;
          break;
        }
      }

      return lastUnwrapped;
    };

    if (showMore) {
      const lastUnwrappedElement = getLastUnwrappedElement();
      if (lastUnwrappedElement) {
        const unwrappedElementsWidth = lastUnwrappedElement.offsetLeft + lastUnwrappedElement.offsetWidth;

        const left = unwrappedElementsWidth + tagsGap;
        return left;
      }
    }

    return undefined;
  };

  return { showMore, ellipsisLeft, setRef };
};
