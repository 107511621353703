import React from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import styles from "./photoCropper.module.scss";
import { withAutoDetectOrientation, IAutoDetectOrientationProps } from "../../designer/withAutoDetectOrientation";
import { ICroppingData } from "../CropperEditor";
import round from "lodash/round";

export interface IPhotoCropperProps extends IAutoDetectOrientationProps {
  /** Product width in pixel */
  productWidth: number;
  /** Product height in pixel */
  productHeight: number;
  /** Preview photo src */
  photoSrc: string;
  /** Cropping Data */
  croppingData?: {
    cropping?: ICroppingData;
    /** Zoom scales */
    zoomScale: number;
    /** Photo rotation degree */
    rotate: number;
  };
  isLandscape?: boolean;
  onFirstRender: (cropper: Cropper, initialAspectRatio: number) => void;
  onCrop: (cropper: Cropper, zoomRatio: number) => void;
}

interface IPhotoCropperState {
  cropper: Cropper | null;
  scaledProductWidth: number;
  scaledProductHeight: number;
}

class PhotoCropper extends React.PureComponent<IPhotoCropperProps, IPhotoCropperState> {
  constructor(props: IPhotoCropperProps) {
    super(props);
    this.state = {
      cropper: null,
      scaledProductHeight: props.productHeight,
      scaledProductWidth: props.productWidth
    };
  }
  private photoRef = React.createRef<HTMLImageElement>();

  private onCrop = (event: Cropper.CropEvent) => {
    const { cropper } = this.state;
    if (cropper) {
      const image = cropper.getImageData();
      const zoomRatio = image.width / image.naturalWidth;
      this.props.onCrop(cropper, zoomRatio);
    }
  };

  private calculateDefaultCropperData = (cropper: Cropper, initAspect: number) => {
    const { croppingData } = this.props;

    cropper.setData({
      rotate: croppingData?.rotate
    });

    this.props.onFirstRender(cropper, initAspect);
  };

  private get hasInitialCroppingData() {
    const { croppingData } = this.props;

    return croppingData && (croppingData.cropping?.height || 0) > 0 && (croppingData.cropping?.width || 0) > 0;
  }

  private setInitialCroppingData(cropper: Cropper, initAspect: number) {
    const { croppingData, productWidth, productHeight } = this.props;
    if (croppingData) {
      const { cropping } = croppingData;
      const canvasData: Cropper.ImageData = cropper.getImageData();

      const imageWidth = canvasData.naturalWidth;
      const imageHeight = canvasData.naturalHeight;
      const top = (cropping?.top || 0) * imageHeight;
      const left = (cropping?.left || 0) * imageWidth;

      const width = (cropping?.width || 0) * imageWidth;
      const height = (cropping?.height || 0) * imageHeight;
      const imageRatio = width / height;
      const productRatio = productWidth / productHeight;

      if (round(imageRatio, 2) !== round(productRatio, 2)) {
        this.calculateDefaultCropperData(cropper, initAspect);
      } else {
        const aspectRatio = width / height;
        cropper.setAspectRatio(aspectRatio);
        cropper.setData({
          x: left,
          y: top,
          width: width,
          height: height,
          rotate: croppingData?.rotate
        });

        this.props.onFirstRender(cropper, aspectRatio);
      }
    }
  }

  private initCropper = () => {
    const { cropper: initCropper } = this.state;
    if (initCropper) {
      initCropper.destroy();
    }
    const { productHeight, productWidth } = this.props;
    const aspectRatio = productWidth / productHeight;
    const cropper = new Cropper(this.photoRef.current as HTMLImageElement, {
      aspectRatio,
      dragMode: "move",
      viewMode: 1,
      autoCropArea: 1,
      scalable: false,
      crop: this.onCrop,
      ready: e => {
        if (e.target !== null) {
          const target = e.target as any;
          if (this.hasInitialCroppingData) {
            this.setInitialCroppingData(target.cropper, aspectRatio);
          } else {
            this.calculateDefaultCropperData(target.cropper, aspectRatio);
          }
        }
      }
    });
    this.setState({ cropper });
  };

  public componentDidMount() {
    this.initCropper();
  }

  public componentDidUpdate(prevProps: IPhotoCropperProps) {
    if (prevProps.productHeight !== this.props.productHeight || prevProps.productWidth !== this.props.productWidth) {
      this.initCropper();
    }
  }

  public render() {
    return (
      <div className={styles.cropContainer}>
        <img src={this.props.photoSrc} className={styles.photo} ref={this.photoRef} />
      </div>
    );
  }
}

export default withAutoDetectOrientation(PhotoCropper);
