import React from "react";

export interface IPageContext {
  isPreview: boolean;
  photoUrlTemplate: string;
  hasHero: boolean;
  navBarHeight: number;
  countryCode: string;
  currencyCode: string;
  externalUrl?: string;
  noGhost?: boolean;
  extras?: Record<string, any>;
  setHasHero: () => void;
  setNavBarHeight: (height: number) => void;
  setIsLoginActive?: (active: boolean) => void;
  onShowCartIcon?: () => void;
  photographerId?: string;
}

export const PageContext = React.createContext<IPageContext>({
  isPreview: false,
  photoUrlTemplate: "",
  hasHero: false,
  navBarHeight: 0,
  countryCode: "",
  currencyCode: "",
  setHasHero: () => null,
  setNavBarHeight: (height: number) => null,
  onShowCartIcon: () => null
});
