import React from "react";
import { initializeIconProps } from "../../../utilities/icons";
import IIConProps from "../IIconProps";

const Calendar = (rawProps: IIConProps) => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`${props.minX} ${props.minY} 25 24`}
    >
      <g fill="none" fillRule="evenodd" stroke={props.stroke} strokeLinecap="round" strokeLinejoin="round">
        <path d="M7 5.667H4.333v14h15.334v-14H17" />
        <path d="M7 7h2V4.333H7zM15 7h2V4.333h-2zM9 5.667h6M4.333 9h15.334" />
      </g>
    </svg>
  );
};

export default Calendar;
