import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

interface IModalInfoProps {
  title: React.ReactNode;
  items: React.ReactNode[];
  important?: boolean;
  className?: string;
}

class ModalInfo extends React.Component<IModalInfoProps> {
  public render() {
    const { title, items, important, className } = this.props;

    return (
      <div className={classNames(styles.container, className, important ? styles.important : "")}>
        <div className={classNames(styles.text, styles.title)}>{title}</div>
        <ul className={styles.items}>
          {items.map((item, idx) => (
            <li key={idx} className={classNames(styles.text, styles.item)}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default ModalInfo;
