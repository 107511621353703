import _ from "lodash";
import React, { PureComponent } from "react";
import Autosuggest, {
  BlurEvent,
  ChangeEvent,
  OnSuggestionSelected,
  RenderInputComponent,
  RenderSuggestion
} from "react-autosuggest";
import styles from "./autosuggest.module.scss";

function getSuggestionValue(suggestion: any) {
  return suggestion.description;
}

function renderSuggestion(suggestion: any) {
  return <span>{suggestion.description}</span>;
}

function renderInputComponent({ onClear, ...inputProps }: any) {
  return <input {...inputProps} />;
}

interface IAutosuggestSelectProps {
  value: string;
  placeholder?: string;
  suggestions: any[];
  onChange: (event: React.FormEvent<any>, params: ChangeEvent) => void;
  onSuggestionsClearRequested: () => void;
  onGetSuggestions: (value: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<any>, params?: BlurEvent<any>) => void;
  tabIndex?: number;
  onSuggestionSelected: OnSuggestionSelected<any>;
  icon?: string;
  renderSuggestion?: RenderSuggestion<any>;
  shouldRenderSuggestions?: () => boolean;
  customStyle?: object;
  renderInputComponent?: RenderInputComponent<any>;
  onClear?: () => void;
  refdata?: any;
  errormessage?: string;
  onClickIcon?: () => void;
}

class AutosuggestSelect extends PureComponent<IAutosuggestSelectProps> {
  public state = {
    value: "",
    suggestions: []
  };

  public lastRequestId: any = null;

  private debouncedLoadSuggestions = _.debounce(this.loadSuggestions, 150);

  private loadSuggestions(value: any) {
    this.props.onGetSuggestions(value);
  }

  private onSuggestionsFetchRequested = ({ value }: any) => {
    this.debouncedLoadSuggestions(value);
  };

  private onShouldRenderSuggestions() {
    return true;
  }

  public render() {
    const { value, suggestions, placeholder, onSuggestionsClearRequested, shouldRenderSuggestions } = this.props;

    const inputProps = {
      placeholder,
      value,
      onClear: this.props.onClear,
      onChange: this.props.onChange,
      onFocus: this.props.onFocus,
      onBlur: this.props.onBlur,
      tabIndex: this.props.tabIndex,
      refdata: this.props.refdata,
      errormessage: this.props.errormessage,
      style: this.props.icon
        ? {
            paddingLeft: 40
          }
        : {}
    };

    return (
      <div className={styles.container}>
        {this.renderIcon()}
        <Autosuggest
          suggestions={suggestions}
          shouldRenderSuggestions={shouldRenderSuggestions ? shouldRenderSuggestions : this.onShouldRenderSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={this.props.renderSuggestion || renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={this.props.onSuggestionSelected}
          theme={this.props.customStyle ? this.props.customStyle : styles}
          renderInputComponent={this.props.renderInputComponent || renderInputComponent}
        />
      </div>
    );
  }

  private renderIcon = () => {
    const { icon, onClickIcon } = this.props;

    return icon ? <img className={styles.icon} src={icon} onClick={onClickIcon} alt="icon" /> : null;
  };
}

export default AutosuggestSelect;
