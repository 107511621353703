import React from "react";

function ScissorsHorizLine() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="391" height="36" viewBox="0 0 391 36">
      <g fill="#FFF" fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M12.645 21.071H0v-6.106h12.645v6.106zm18.355 0H18.752v-6.106H31v6.106zm53.806 0h-7.248v-6.106h7.248v6.106zm20.355 0H90.913v-6.106h14.247v6.106zm20.355 0h-14.248v-6.106h14.248v6.106zm20.355 0h-14.248v-6.106h14.247v6.106zm20.355 0h-14.249v-6.106h14.249v6.106zm20.355 0h-14.249v-6.106h14.249v6.106zm20.354 0h-14.248v-6.106h14.248v6.106zm20.355 0h-14.248v-6.106h14.248v6.106zm20.355 0h-14.248v-6.106h14.248v6.106zm20.355 0h-14.248v-6.106H268v6.106zm122.023.035V15h-14.249v6.106h14.249zm-20.355 0V15h-14.249v6.106h14.249zm-20.355 0V15h-14.248v6.106h14.248zm-20.355 0V15H314.71v6.106h14.248zm-20.355 0V15h-14.248v6.106h14.248zm-20.355 0V15H274v6.106h14.248z"
        />
        <path d="M80.026 24.458c-2.078 2.507-4.91 3.558-8.497 3.154l-.36-.045-18.253-6.476-7.247 2.59-.264-.067a7.294 7.294 0 011.337 4.212c0 4.148-3.485 7.511-7.784 7.511-4.299 0-7.784-3.363-7.784-7.511 0-2.948 1.76-5.499 4.32-6.729l-.025-.006.17-.062c.38-.173.777-.317 1.188-.43l7.086-2.564-7.086-2.563a8.005 8.005 0 01-.9-.306l-.288-.124-.17-.062.025-.006c-2.56-1.23-4.32-3.781-4.32-6.729 0-4.148 3.485-7.511 7.784-7.511 4.3 0 7.784 3.363 7.784 7.511a7.3 7.3 0 01-1.337 4.212l.264-.067 7.247 2.59 18.252-6.476.361-.045c3.587-.404 6.419.647 8.497 3.154l-14.337 4.812-3.708 1.61 3.714 1.613 14.33 4.81zm-36.505 3.368c0-2.432-2.043-4.404-4.563-4.404-2.52 0-4.563 1.972-4.563 4.404 0 2.431 2.043 4.403 4.563 4.403 2.52 0 4.563-1.972 4.563-4.403zm0-19.58c0-2.432-2.043-4.404-4.563-4.404-2.52 0-4.563 1.972-4.563 4.403 0 2.432 2.043 4.404 4.563 4.404 2.52 0 4.563-1.972 4.563-4.404zm10.199 9.79l-.005-.06c-.058-.52-.514-.924-1.068-.924-.593 0-1.073.464-1.073 1.036l.003-.053.003.06c.058.52.514.924 1.067.924.575 0 1.044-.435 1.073-.983z" />
      </g>
    </svg>
  );
}

export default ScissorsHorizLine;
