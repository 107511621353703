import React from "react";
import styles from "./Footer.module.scss";
import cx from "classnames";
import { ZenfolioResources } from "utilities/resources";

export interface IFooterProps {
  footerColor: string;
  iconFacebook: string;
  iconTwitter: string;
  iconInstagram: string;
  iconShop: string;
  iconLike: string;
  footerTextColor: string;
  studioName?: string;
  contactEmail?: string;
  photoCount: number;
  videoCount: number;
  handleShowCookieSettings?: () => void;
  isEnabledCookieSettings?: boolean;
}

const Footer: React.FC<IFooterProps> = props => {
  const mediaTitle =
    props.photoCount > 0 && props.videoCount > 0 ? "Media" : props.videoCount > 0 ? "Videos" : "Photos";

  return (
    <div className={styles.bottomFoot} style={{ backgroundColor: props.footerColor }}>
      <div className={styles.wrapper}>
        <div className={cx(styles.whatsNext, styles.displayNone)}>
          <h3>WHAT’S NEXT?</h3>
          <ul>
            <li>
              <div className={styles.iconGropu}>
                <span>
                  <img src={props.iconFacebook} width="32" />
                </span>
                <span>
                  <img src={props.iconTwitter} width="32" />
                </span>
                <span>
                  <img src={props.iconInstagram} width="32" />
                </span>
              </div>
              <h4>SHARE</h4>
              <p>
                Share your {mediaTitle.toLowerCase()} with family and <br />
                friends on Social Media
              </p>
            </li>
            <li>
              <div className={styles.iconGropu}>
                <span>
                  <span>
                    <img src={props.iconShop} width="32" />
                  </span>
                </span>
              </div>
              <h4>SHOP</h4>
              <p>
                Choose from a variety of products
                <br /> and start printing your photos.
              </p>
            </li>
            <li>
              <div className={styles.iconGropu}>
                <span>
                  <span>
                    <img src={props.iconLike} width="32" />
                  </span>
                </span>
              </div>
              <h4>RECOMMEND</h4>
              <p>
                Love your photographs? You can
                <br /> recommend your photographer by
                <br /> writing a testimonial here!
              </p>
            </li>
          </ul>
        </div>
        <div className={styles.copyright} style={{ color: props.footerTextColor }}>
          <p>
            <span className={styles.studioLink} style={{ color: props.footerTextColor }}>
              {mediaTitle} by: {props.studioName?.toUpperCase()}
            </span>{" "}
            <span className={cx(styles.hidePipe, styles.separator)}>|</span> <br />
            <a href={ZenfolioResources.TermsOfServicePage} style={{ color: props.footerTextColor }}>
              Terms of Service
            </a>{" "}
            <span className={styles.separator}>|</span>{" "}
            <a href={ZenfolioResources.PrivacyPolicyPage} style={{ color: props.footerTextColor }}>
              Privacy Policy
            </a>
            {props.isEnabledCookieSettings && (
              <>
                {" "}
                <span className={styles.separator}>|</span>{" "}
                <span
                  className={styles.cookieSettings}
                  onClick={props.handleShowCookieSettings}
                  style={{ color: props.footerTextColor }}
                >
                  Cookie Settings
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
