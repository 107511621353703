import { ICustomizeData, ICustomizeDataUpdater } from "../store/customize/model";
import { apiType } from "./apiType";
import { apiClient } from "./index";

export interface IApiCustomize {
  loadCustomizeData: () => Promise<ICustomizeData>;
  updateCustomizeData: (updater: ICustomizeDataUpdater) => Promise<void>;
}

const api: IApiCustomize = {
  loadCustomizeData: () => apiClient.get<ICustomizeData>(`${apiType.bookingWidget}photographer/customize`),
  updateCustomizeData: (updater: ICustomizeDataUpdater) =>
    apiClient.put<void>(`${apiType.bookingWidget}photographer/customize`, updater)
};

export default api;
