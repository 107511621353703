import {
  Button,
  Collapse,
  DurationInput,
  FormCheckbox,
  FormRadio,
  FormTextInput,
  Panel,
  PriceInput,
  SelectDropdown,
  Utilities,
  Validation
} from "@zenfolio/core-components";
import { ISlotRange } from "@zenfolio/core-components/dist/components/TimeSlotDay";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import * as React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Col, Row } from "react-flexbox-grid";
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router";
import ReactTooltip, { TooltipProps } from "react-tooltip";
import addIcon from "../../../assets/icons/add3.svg";
import toolTipInfoIcon from "../../../assets/icons/info.svg";
import removeIcon from "../../../assets/icons/remove.svg";
import { convertTimeSlotsToSlotRanges } from "../../../containers/modal/modals/bookableHours/utils";
import { ActionStatus } from "../../../store/common";
import { insertItem, removeItem, updateItem } from "../../../store/helpers";
import {
  IAvailabilityEdit,
  IDeposit,
  IServiceCreator,
  IServiceDetails,
  IServiceUpdater,
  SendInvoiceOption,
  ServiceAvailability,
  SessionsPerDayLimit
} from "../../../store/services/model";
import { IShootType } from "../../../store/shootTypes/model";
import Auxiliary from "../../../utilities/auxiliary";
import colors from "../../../utilities/colors";
import { AsciiRegex } from "../../../utilities/constants";
import { DaysOfWeek, formatTimeOfDay, getDayOfWeekName } from "../../../utilities/datetime";
import { hasActionCompleted } from "../../../utilities/helpers";
import Widget from "../../icons/Widget/index";
import Loader from "../../layout/loader";
import UrlConstants from "../../router/constants";
import ShootTypes from "../../shootTypes";
import { getPaymentType, isZeroPrice, PaymentType, renderDurationAsStringLong } from "../common";
import ServiceAvailabilityModal from "../serviceAvailabilityModal";
import {
  DescriptionMaxCharacters,
  DurationChunk,
  MaxDays,
  MaxDescriptions,
  MaxDuration,
  MaxPrice,
  MinDays,
  MinDepositAmount,
  MinPrice,
  NameMaxCharacters,
  NoLeadingZeroRegex
} from "./constants";
import styles from "./index.module.scss";

enum ShootLocation {
  None,
  PrimaryOnly,
  InRadius
}

type PanelName = "pricing-options";

export interface IDetailParams {
  serviceId: string;
}

interface IPageProps {
  updateTitle: (title: string, backUrl: string) => void;
  updateHeader: (header: string) => void;
}

export interface IAddServiceProps extends RouteComponentProps<IDetailParams>, IPageProps {
  serviceId: string | null;
  className?: string;
  service: IServiceDetails | null;
  shootTypes: IShootType[][];

  loadStatus: ActionStatus;
  updateStatus: ActionStatus;
  createStatus: ActionStatus;
  shootTypesStatus: ActionStatus;

  onLoadShootTypes: () => void;
  onLoadServiceDetails: (serviceId: string) => void;
  onCreateOrUpdate: (id: string | null, service: IServiceUpdater | IServiceCreator) => void;
  onReset: () => void;
}

interface IAddServiceState {
  serviceId: string | null;
  service: IServiceDetails;
  validateDuration: boolean;
  validateShootType: boolean;

  validateNoPayment: boolean;
  validatePartialPayment: boolean;
  shootLocation: ShootLocation;
  serviceAvailability: ServiceAvailability;
  sessionsPerDayLimit: SessionsPerDayLimit;
  paymentType: PaymentType;
  depositSendInvoiceOption: SendInvoiceOption;
  depositSendInvoiceAfterOrBeforeOption: IAfterOrBeforeOption;
  noPaymentSendInvoiceOption: SendInvoiceOption;
  noPaymentSendInvoiceAfterOrBeforeOption: IAfterOrBeforeOption;
  priceWasFocused: boolean;
  panels: { [id: string]: boolean };

  forceShowingDepositAmountError: boolean;
  showAvailabilityDialog: boolean;
}

interface IBasicOption<T> {
  label: string;
  value: T;
}

interface IOption extends IBasicOption<number> {}

interface IAfterOrBeforeOption {
  days: number;
  sendInvoiceOption: SendInvoiceOption;
}

interface ISendInvoiceOptions extends IBasicOption<IAfterOrBeforeOption> {}

class EditOrCreate extends React.Component<IAddServiceProps, IAddServiceState> {
  constructor(props: IAddServiceProps) {
    super(props);

    this.state = this.defaultState;
    props.updateTitle("Back to Your Services", UrlConstants.services);
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutsidePricing);
    document.removeEventListener("click", this.handleClickOutsidePriceOptions);
  }

  public componentDidMount() {
    const { onLoadShootTypes, onLoadServiceDetails, updateHeader, onReset } = this.props;

    onLoadShootTypes();
    if (this.state.serviceId) {
      onLoadServiceDetails(this.state.serviceId);
    } else {
      updateHeader("Create a Service");
      onReset();
    }
  }

  public componentDidUpdate(prevProps: IAddServiceProps) {
    const { service, loadStatus, serviceId, updateHeader, updateStatus, createStatus } = this.props;
    const loadServiceHasCompleted = hasActionCompleted(prevProps.loadStatus, loadStatus);
    const updateServiceHasCompleted = hasActionCompleted(prevProps.updateStatus, updateStatus);
    const createServiceHasCompleted = hasActionCompleted(prevProps.createStatus, createStatus);

    if (service && (loadServiceHasCompleted || updateServiceHasCompleted || createServiceHasCompleted)) {
      this.setState({
        serviceId,
        service: {
          ...service,
          sessionsPerDay:
            service.sessionsPerDay == null ? this.defaultState.service.sessionsPerDay : service.sessionsPerDay,
          deposit: {
            ...service.deposit!,
            depositAmount: getPaymentType(service) === PaymentType.NoPayment ? null : service.deposit!.depositAmount
          }
        },
        validateDuration: false,
        validateShootType: false,
        shootLocation: service.radius > 0 ? ShootLocation.InRadius : ShootLocation.PrimaryOnly,
        serviceAvailability: _.isEmpty(service.availableHours)
          ? ServiceAvailability.Global
          : ServiceAvailability.Custom,
        sessionsPerDayLimit: service.sessionsPerDay == null ? SessionsPerDayLimit.NotSet : SessionsPerDayLimit.Set,
        paymentType: isZeroPrice(service) ? PaymentType.None : getPaymentType(service),
        noPaymentSendInvoiceOption: this.getSendInvoiceOption(
          service,
          PaymentType.NoPayment,
          this.defaultState.noPaymentSendInvoiceOption
        ),
        noPaymentSendInvoiceAfterOrBeforeOption: this.getSendInvoiceAfterOrBeforeOption(
          service,
          PaymentType.NoPayment,
          this.defaultState.noPaymentSendInvoiceAfterOrBeforeOption
        ),
        depositSendInvoiceOption: this.getSendInvoiceOption(
          service,
          PaymentType.Partial,
          this.defaultState.depositSendInvoiceOption
        ),
        depositSendInvoiceAfterOrBeforeOption: this.getSendInvoiceAfterOrBeforeOption(
          service,
          PaymentType.Partial,
          this.defaultState.depositSendInvoiceAfterOrBeforeOption
        )
      });

      updateHeader(`Editing ${service.name}`);
    }
  }

  public render() {
    const { className, loadStatus, service } = this.props;
    const { serviceId } = this.state;
    const editing = !!serviceId;
    return (
      <div>
        <div className={classNames(styles.container, className)}>
          <Helmet title={service ? `Editing ${service.name}` : serviceId ? "Editing" : "Create a Service"} />
          {editing && loadStatus === "Pending" && <Loader />}
          {editing && loadStatus === "Success" && this.renderForm()}
          {!editing && this.renderForm()}
        </div>
      </div>
    );
  }

  private renderForm() {
    const { updateStatus, createStatus, shootTypes } = this.props;
    const {
      validateDuration,
      validateShootType,
      forceShowingDepositAmountError,
      depositSendInvoiceOption,
      noPaymentSendInvoiceOption,
      noPaymentSendInvoiceAfterOrBeforeOption,
      depositSendInvoiceAfterOrBeforeOption,
      paymentType,
      service,
      showAvailabilityDialog,
      validateNoPayment,
      validatePartialPayment
    } = this.state;

    const {
      name,
      price,
      duration,
      radius,
      askAdditionalInfo,
      serviceDetails,
      approvalRequired,
      deposit,
      shootType
    } = this.state.service;

    const updatePending = updateStatus === "Pending";
    const createPending = createStatus === "Pending";

    const durationErrorMessage = validateDuration && this.durationErrorMessage;
    const shootTypeErrorMessage = validateShootType && this.shootTypeErrorMessage;

    const isNoPaymentOptionValid = validateNoPayment && this.isNoPaymentOptionEmpty;
    const isPartialPaymentOptionValid = validatePartialPayment && this.isPartialPaymentOptionEmpty;

    const valid = this.isValid();

    const priceOptionsAttr = { disabled: isZeroPrice(service) };

    return (
      <div className={styles.form}>
        <Row>
          <Col md={12}>
            <div className={classNames(styles.field, styles.shootType)}>
              <label>Shoot Type</label>
              <div className={styles.shootTypeDescription}>
                Select a shoot type that most closely matches the service you’re setting up. You can rename the <br />
                shoot type at any time.
              </div>
              <div className={shootTypeErrorMessage ? styles.shootTypeErrorError : undefined}>
                <ShootTypes
                  onChangeShootType={this.handleChangeShootType}
                  shootTypes={shootTypes}
                  activeShootType={shootType}
                  onBlur={this.handleShootTypeBlur}
                />
                {shootTypeErrorMessage && <p>{shootTypeErrorMessage}</p>}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className={classNames(styles.field, styles.name)}>
              <label>
                Service Name<span className={styles.tips}>{this.renderNameRestrictionsHelp()}</span>
              </label>
              <div>
                <FormTextInput
                  value={name}
                  placeholder="Select"
                  onChange={this.handleServiceNameChange}
                  styles={{ height: 50, paddingLeft: 16 }}
                  errorMessage={this.nameErrorMessage}
                  tabIndex={1}
                  maxLength={NameMaxCharacters}
                  allowedCharsRegex={AsciiRegex}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div ref={this.pricingRef}>
          <Row>
            <Col md={4}>
              <div className={classNames(styles.field, styles.price)}>
                <label>Pricing</label>
                <div>
                  <PriceInput
                    value={price}
                    max={MaxPrice}
                    onBlur={this.handlePriceBlur}
                    onChange={this.handlePriceChange}
                    errorMessage={this.priceErrorMessage}
                    tabIndex={2}
                  />
                  {this.renderZeroPriceDescription()}
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className={classNames(styles.field, styles.duration)}>
                <label>
                  Duration
                  <img data-tip={true} data-for="duration-tips" src={toolTipInfoIcon} alt="tool tip info icon" />
                  <ReactTooltip
                    id="duration-tips"
                    {...EditOrCreate.tooltipProps}
                    getContent={this.renderDurationTips}
                    offset={{ top: -43, left: -15 }}
                  />
                </label>

                <div className={durationErrorMessage ? styles.durationError : undefined}>
                  <DurationInput
                    min={0}
                    max={MaxDuration}
                    step={DurationChunk}
                    tabIndex={3}
                    value={duration}
                    onChange={this.handleDurationChange}
                    onBlur={this.handleDurationBlur}
                  />
                  {durationErrorMessage && <p>{durationErrorMessage}</p>}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div ref={this.pricingOptionsRef}>
          <Collapse>
            <Panel
              title="Pricing Options"
              name="pricing-options"
              headerClassName={styles.panel}
              {...Panel.defaultProps}
              open={this.isPanelOpen("pricing-options")}
              onChange={this.handleTogglePanel}
              showArrow={true}
            >
              <Row>
                <Col md={12}>
                  <div className={classNames(styles.field, styles.paymentType)}>
                    <div className={styles.option}>
                      <FormRadio
                        name="paymentType"
                        checked={paymentType === PaymentType.NoPayment}
                        onChange={this.handleSelectNoPayment}
                        className={classNames(
                          styles.radio,
                          isZeroPrice(service) && styles.disabled,
                          paymentType === PaymentType.NoPayment && styles.active
                        )}
                        tabIndex={4}
                        {...priceOptionsAttr}
                      >
                        Collect <span className={styles.highlight}>no payment</span> at time of booking but collect the
                        full amount later.
                      </FormRadio>
                    </div>
                    <div className={paymentType !== PaymentType.NoPayment ? styles.hidden : ""}>
                      <Row>
                        <Col md={12}>
                          <div className={classNames(styles.field, styles.sendInvoice, styles.noPayment)}>
                            {this.renderInvoiceOptions(
                              "paymentInvoice",
                              "Send invoice for full payment",
                              isZeroPrice(service),
                              noPaymentSendInvoiceOption,
                              noPaymentSendInvoiceAfterOrBeforeOption,
                              isNoPaymentOptionValid,
                              5
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className={styles.option}>
                      <FormRadio
                        name="paymentType"
                        checked={paymentType === PaymentType.Full}
                        onChange={this.handleSelectFullPayment}
                        className={classNames(
                          styles.radio,
                          isZeroPrice(service) && styles.disabled,
                          paymentType === PaymentType.Full && styles.active
                        )}
                        tabIndex={10}
                        {...priceOptionsAttr}
                      >
                        Collect <span className={styles.highlight}> full payment</span> at time of booking.
                      </FormRadio>
                    </div>
                    <div className={styles.option}>
                      <FormRadio
                        name="paymentType"
                        checked={paymentType === PaymentType.Partial}
                        onChange={this.handleSelectPartialPayment}
                        className={classNames(
                          styles.radio,
                          isZeroPrice(service) && styles.disabled,
                          paymentType === PaymentType.Partial && styles.active
                        )}
                        tabIndex={11}
                        {...priceOptionsAttr}
                      >
                        Collect <span className={styles.highlight}> partial payment</span> at time of booking and
                        <span className={styles.highlight}> remainder </span>
                        later.
                      </FormRadio>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className={paymentType !== PaymentType.Partial ? styles.hidden : ""}>
                <div className={styles.depositSection}>
                  <Row middle="md">
                    <Col md={4}>
                      <div className={classNames(styles.field, styles.depositAmount)}>
                        <label className={classNames(!this.canUseDeposit && styles.disabled)}>
                          Partial Payment Amount
                        </label>
                        <div>
                          <PriceInput
                            value={deposit!.depositAmount}
                            max={MaxPrice}
                            onChange={this.handleDepositAmountChange}
                            errorMessage={this.depositAmountErrorMessage}
                            tabIndex={this.getTabIndex(this.canUseDeposit, 12)}
                            disabled={!this.canUseDeposit}
                            inputClassName={classNames(!this.canUseDeposit && styles.disabled)}
                            forceShowingError={forceShowingDepositAmountError}
                            ref={this.partialPaymentAmount}
                          />
                        </div>
                      </div>
                    </Col>
                    {this.renderRemainingAmount()}
                  </Row>
                </div>

                <div className={paymentType !== PaymentType.Partial ? styles.hidden : ""}>
                  <Row>
                    <Col md={12}>
                      <div className={classNames(styles.field, styles.sendInvoice)}>
                        {this.renderInvoiceOptions(
                          "depositInvoice",
                          "Send invoice for remaining amount",
                          !this.canUseDeposit,
                          depositSendInvoiceOption,
                          depositSendInvoiceAfterOrBeforeOption,
                          isPartialPaymentOptionValid,
                          13
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
        <Row>
          <Col md={12}>
            <div className={classNames(styles.field, styles.availability)}>
              <label>Service Availability</label>
              <div className={styles.option}>
                <div className={styles.descriptions}>{this.renderServiceAvailability()}</div>
              </div>
            </div>

            <div className={styles.btnAdd} onClick={this.handleAvailabilityChangeClick}>
              <img src={addIcon} alt="add icon" />
              <label>Modify availability</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className={classNames(styles.field, styles.shootLocation)}>
              <label>Photoshoot Location</label>
              <div className={styles.option}>
                <FormRadio
                  name="shootLocation"
                  checked={this.state.shootLocation === ShootLocation.PrimaryOnly}
                  onChange={this.handleSelectPrimaryShootLocationOnly}
                  className={classNames(
                    styles.radio,
                    this.state.shootLocation === ShootLocation.PrimaryOnly && styles.active
                  )}
                  tabIndex={18}
                >
                  Primary Location
                </FormRadio>
              </div>
              <div className={styles.option}>
                <FormRadio
                  name="shootLocation"
                  checked={this.state.shootLocation === ShootLocation.InRadius}
                  onChange={this.handleSelectPrimaryShootLocationWithRadius}
                  className={classNames(
                    styles.radio,
                    this.state.shootLocation === ShootLocation.InRadius && styles.active
                  )}
                  tabIndex={19}
                >
                  Client’s Location within radius from Primary Location
                </FormRadio>
                <span className={styles.radius}>
                  <FormTextInput
                    placeholder="miles"
                    onChange={this.handleRadiusChange}
                    styles={{ height: 50, paddingLeft: 16 }}
                    value={radius > 0 ? radius.toString() : ""}
                    disabled={this.state.shootLocation !== ShootLocation.InRadius}
                    maxLength={3}
                    errorMessage={this.radiusErrorMessage}
                    tabIndex={this.getTabIndex(this.state.shootLocation === ShootLocation.InRadius, 20)}
                    allowedCharsRegex={NoLeadingZeroRegex}
                    ref={this.clientLocationDistance}
                  />
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className={classNames(styles.field, styles.details)}>
              <label>
                Service Details
                <span className={styles.tips}>(Optional)</span>
                <img data-tip={true} data-for="service-details-tips" src={toolTipInfoIcon} alt="tool tip info icon" />
                <ReactTooltip
                  id={"service-details-tips"}
                  {...EditOrCreate.tooltipProps}
                  getContent={this.renderServiceDetailsTips}
                  offset={{ top: 122, left: -17 }}
                />
              </label>
              <DragDropContext onDragEnd={this.handleDragEnd}>{this.renderDescriptions()}</DragDropContext>
            </div>
          </Col>
        </Row>
        {serviceDetails.length < MaxDescriptions && (
          <Row>
            <Col md={12}>
              <div className={styles.btnAdd} onClick={this.handleAddDescription}>
                <img src={addIcon} alt="add icon" />
                <label>
                  Add another detail<span className={styles.tips}>{this.renderDetailsRestrictionsHelp()}</span>
                </label>
              </div>
            </Col>
          </Row>
        )}
        <div className={styles.agreement}>
          <Row>
            <Col md={12}>
              <div className={classNames(styles.field, styles.additionalInfo)}>
                <h2>Additional Settings</h2>
                {this.renderTitle("Additional Information")}
                <div>
                  <FormCheckbox
                    checked={askAdditionalInfo}
                    onChange={this.handleChangeAskAdditionalInfo}
                    className={classNames(styles.askAdditionalInfo, askAdditionalInfo && styles.checked)}
                    tabIndex={25}
                  >
                    Enable comment field to allow client to add notes about the session.
                  </FormCheckbox>
                  <div className={styles.askAdditionalInfoDescription}>
                    <em>Name, phone number</em> and <em>email</em> are always asked for at checkout.
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={classNames(styles.field, styles.bookingApproval)}>
                {this.renderTitle("Reservation")}
                <div>
                  <FormCheckbox
                    checked={approvalRequired}
                    onChange={this.handleChangeBookingApproval}
                    className={classNames(styles.bookingApproval, approvalRequired && styles.checked)}
                    tabIndex={26}
                  >
                    I will approve this booking before the deposit/payment is processed.
                  </FormCheckbox>
                  <div className={styles.bookingApprovalDescription}>
                    The appointment will be written to your calendar. The payment will{" "}
                    <span className={styles.highlight}>not</span> be processed until the appointment is approved.
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {showAvailabilityDialog && this.renderAvailabilityDialog()}
        <Row>
          <Button
            type="button"
            styleType="primary"
            onClick={this.handleSaveClick}
            height={50}
            disabled={!valid || updatePending || createPending}
            styles={{ width: 295, marginTop: 29, height: 50 }}
          >
            Save
          </Button>
        </Row>
      </div>
    );
  }

  private renderZeroPriceDescription() {
    const { priceWasFocused, service } = this.state;

    if (service.price && !isZeroPrice(service)) {
      return null;
    }

    if (priceWasFocused && this.priceErrorMessage) {
      return null;
    }

    return <div className={styles.tips}>Services prices at $0 will be free.</div>;
  }

  private renderAvailabilityDialog() {
    return (
      <ServiceAvailabilityModal
        availability={this.state.service}
        serviceAvailability={this.state.serviceAvailability}
        sessionsPerDayLimit={this.state.sessionsPerDayLimit}
        onSave={this.handleSaveAvailability}
        onCancel={this.onCancelAvailabilityChange}
      />
    );
  }

  private renderServiceAvailability() {
    return (
      <Auxiliary>
        {this.renderTimeSlots()}
        {this.renderEventBuffers()}
        {this.renderSessionsPerDay()}
        {this.renderAvailableDays()}
      </Auxiliary>
    );
  }

  private renderTimeSlots() {
    const { serviceAvailability } = this.state;
    const { availableHours } = this.state.service;

    if (serviceAvailability === ServiceAvailability.Global) {
      return <p key="global">Service is available during global bookable hours</p>;
    }

    const slotRanges = this.convertTimeSlots(availableHours);

    return _.map(DaysOfWeek.fromMonday, (dayOfWeek, weekIndex) => {
      const length = slotRanges[dayOfWeek].length;
      if (length > 0) {
        return (
          <p key={`day${weekIndex}`}>
            {`${getDayOfWeekName(dayOfWeek)}: ${_.join(
              _.map(slotRanges[dayOfWeek], slot => this.renderSlots(slot)),
              ", "
            )}`}
          </p>
        );
      }
      return null;
    });
  }

  private renderSlots(slot: ISlotRange) {
    return `${formatTimeOfDay(slot.selectedStart.value, "hh:mm a")} to ${formatTimeOfDay(
      slot.selectedEnd.value,
      "hh:mm a"
    )}`;
  }

  private renderEventBuffer(name: string, duration: number | null) {
    return `${name}: ${renderDurationAsStringLong(duration || 0) || "No Buffer"}`;
  }

  private renderEventBuffers() {
    return (
      <Auxiliary>
        <p key="preEventBuffer" className={styles.withMargin}>
          {this.renderEventBuffer("Buffer before event", this.state.service.preEventBuffer)}
        </p>
        <p key="postEventBuffer">{this.renderEventBuffer("Buffer after event", this.state.service.postEventBuffer)}</p>
      </Auxiliary>
    );
  }

  private renderSessionsPerDay() {
    return (
      <p key="sessionsPerDay" className={styles.withMargin}>{`Number of Sessions a day: ${
        this.state.sessionsPerDayLimit === SessionsPerDayLimit.NotSet
          ? "No Limit Set"
          : this.state.service.sessionsPerDay
      }`}</p>
    );
  }

  private renderAvailableDays() {
    if (this.state.serviceAvailability !== ServiceAvailability.Global) {
      const description = this.getAvailableDaysDescription();

      if (description) {
        return <p className={styles.withMargin}>{description}</p>;
      }
    }

    return null;
  }

  private getAvailableDaysDescription() {
    const { availableStartDate, availableEndDate } = this.state.service;

    const format = "MMM Do, YYYY";

    if (availableStartDate && availableEndDate) {
      if (availableStartDate.isSame(availableEndDate)) {
        return `Available: Only on ${availableStartDate.format(format)}`;
      } else {
        return `Available: ${availableStartDate.format(format)} to ${moment(availableEndDate).format(format)}`;
      }
    }

    if (availableStartDate) {
      return `Available: Starting on ${availableStartDate.format(format)}`;
    }

    if (availableEndDate) {
      return `Available: Until ${availableEndDate.format(format)}`;
    }

    return null;
  }

  private renderTitle(title: string) {
    return (
      <label>
        {title}
        <span className={styles.tips}>(Optional)</span>
      </label>
    );
  }

  private renderServiceDetailsTips = () => {
    return (
      <div className={styles.body}>
        Service details let your consumer know what is included in the booking. This should be a brief reminder of what
        is included in the booking; you can put more detailed information on your website.
        <Widget />
      </div>
    );
  };

  private renderDurationTips = () => {
    return (
      <div className={styles.body}>
        Duration is how long the session with <br />
        the client will last. If you need to add <br />
        extra time before or after the shoot, <br />
        you can add Buffer Time to the <br />
        service under Modify Availability.
      </div>
    );
  };

  public handleClickOutsidePricing = (event: any) => {
    document.removeEventListener("click", this.handleClickOutsidePricing, false);
    if (this.pricingRef && !this.pricingRef.current!.contains(event.target)) {
      this.setState({
        validateDuration: true
      });
    }
  };

  public handleClickOutsidePriceOptions = (event: any) => {
    const { paymentType } = this.state;
    document.removeEventListener("click", this.handleClickOutsidePriceOptions, false);
    if (this.pricingOptionsRef && !this.pricingOptionsRef.current!.contains(event.target)) {
      this.setState({
        validateNoPayment: paymentType === PaymentType.NoPayment,
        validatePartialPayment: paymentType === PaymentType.Partial
      });
    }
  };

  private get isNoPaymentOptionEmpty() {
    return this.state.noPaymentSendInvoiceOption === SendInvoiceOption.None;
  }

  private get isPartialPaymentOptionEmpty() {
    return this.state.depositSendInvoiceOption === SendInvoiceOption.None;
  }

  private isPanelOpen(key: PanelName) {
    return !!this.state.panels[key];
  }

  private handleAvailabilityChangeClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.setState({ showAvailabilityDialog: true });
  };

  private onCancelAvailabilityChange = () => {
    this.setState({
      showAvailabilityDialog: false
    });
  };

  private renderInvoiceOptions(
    name: string,
    title: string,
    disabled: boolean,
    option: SendInvoiceOption,
    afterOrBeforeOption: IAfterOrBeforeOption,
    highlight: boolean,
    index: number
  ) {
    const sendInvoiceDays = this.getSendInvoiceDays();
    const sendInvoiceOptions = this.getSendInvoiceOptions()[afterOrBeforeOption.days];

    const sendInvoiceDaySelected = this.getSelectedItem(sendInvoiceDays, value => value === afterOrBeforeOption.days);
    const sendInvoiceOptionSelected = this.getSelectedItem(
      sendInvoiceOptions,
      value => value.sendInvoiceOption === afterOrBeforeOption.sendInvoiceOption
    );

    const sendInvoiceOptionsAttr = { disabled };

    const isAfterOrBeforeShootDate = this.isSendInvoiceActiveOptions(option, [
      SendInvoiceOption.AfterShootDate,
      SendInvoiceOption.BeforeShootDate
    ]);

    return (
      <Auxiliary>
        <label className={classNames(disabled && styles.disabled, highlight && styles.error)}>{title}</label>
        <div className={styles.option}>
          <FormRadio
            name={name}
            checked={this.isSendInvoiceActiveOption(option, SendInvoiceOption.SameAsShootDate)}
            onChange={this.handleSelectSendInvoiceSameAsShootDate}
            className={classNames(
              styles.radio,
              disabled && styles.disabled,
              this.isSendInvoiceActiveOption(option, SendInvoiceOption.SameAsShootDate) && styles.active
            )}
            tabIndex={this.getTabIndex(!disabled, index)}
            {...sendInvoiceOptionsAttr}
          >
            The day of the shoot.
          </FormRadio>
        </div>

        <div className={styles.option}>
          <FormRadio
            name={name}
            checked={isAfterOrBeforeShootDate}
            onChange={this.handleSelectSendInvoiceAfterOrBeforeShootDate}
            className={classNames(styles.radio, disabled && styles.disabled, isAfterOrBeforeShootDate && styles.active)}
            tabIndex={this.getTabIndex(!disabled, index + 1)}
            {...sendInvoiceOptionsAttr}
          />
          <Col md={2} className={styles.sendInvoiceDays}>
            <div
              className={classNames(
                styles.field,
                styles.sendInvoiceDays,
                disabled && styles.disabled,
                !isAfterOrBeforeShootDate && styles.inactive
              )}
            >
              <SelectDropdown
                options={sendInvoiceDays}
                placeholder=""
                onChange={this.handleSendInvoiceDaysChange}
                width={"100%"}
                maxWidth={"100%"}
                minHeight={50}
                value={sendInvoiceDaySelected}
                indicator={true}
                isSearchable={false}
                openMenuOnFocus={true}
                isDisabled={disabled || !isAfterOrBeforeShootDate}
                tabIndex={this.getTabIndex(!disabled && isAfterOrBeforeShootDate, index + 2).toString()}
              />
            </div>
          </Col>

          <Col md={4} className={styles.sendInvoiceOptions}>
            <div
              className={classNames(
                styles.field,
                styles.sendInvoiceOptions,
                disabled && styles.disabled,
                !isAfterOrBeforeShootDate && styles.inactive
              )}
            >
              <SelectDropdown
                options={sendInvoiceOptions}
                placeholder=""
                onChange={this.handleSendInvoiceOptionsChange}
                width={"100%"}
                maxWidth={"100%"}
                minHeight={50}
                value={sendInvoiceOptionSelected}
                indicator={true}
                isSearchable={false}
                openMenuOnFocus={true}
                isDisabled={disabled || !isAfterOrBeforeShootDate}
                tabIndex={this.getTabIndex(!disabled && isAfterOrBeforeShootDate, index + 3).toString()}
              />
            </div>
          </Col>
        </div>

        <div className={styles.option}>
          <FormRadio
            name={name}
            checked={this.isSendInvoiceActiveOption(option, SendInvoiceOption.Manually)}
            onChange={this.handleSelectSendInvoiceManually}
            className={classNames(
              styles.radio,
              disabled && styles.disabled,
              this.isSendInvoiceActiveOption(option, SendInvoiceOption.Manually) && styles.active
            )}
            tabIndex={this.getTabIndex(!disabled, index + 4)}
            {...sendInvoiceOptionsAttr}
          >
            I will send the invoice manually or collect payment in person.
          </FormRadio>
        </div>
      </Auxiliary>
    );
  }

  private renderDetailsRestrictionsHelp() {
    return `${this.state.service.serviceDetails.length} of ${MaxDescriptions}`;
  }

  private renderNameRestrictionsHelp() {
    return `${this.state.service.name.length} / ${NameMaxCharacters}`;
  }

  private renderDescriptionRestrictionsHelp(index: number) {
    return `${this.state.service.serviceDetails[index].length} / ${DescriptionMaxCharacters}`;
  }

  private renderRemainingAmount() {
    const { service } = this.state;
    const { price, deposit } = service;
    const remainingAmount = price && deposit ? price - deposit.depositAmount! : 0;
    const canRenderRemainingAmount =
      !this.priceErrorMessage && !isZeroPrice(service) && !this.depositAmountErrorMessage;

    if (canRenderRemainingAmount) {
      return (
        <Col md={8}>
          <div className={classNames(styles.field, styles.remainingAmountInfo)}>
            <label>Remaining Amount</label>
            <span className={styles.price}>
              <span className={styles.currency}>$</span>
              {Utilities.formatFractionalNumber(remainingAmount)}
            </span>
          </div>
        </Col>
      );
    }
    return null;
  }

  private renderDescriptions() {
    const { serviceDetails } = this.state.service!;

    return (
      <Droppable droppableId="droppable">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {serviceDetails.map((description, index) => this.renderDescription(description, index))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  private renderDescription(description: string, index: number) {
    const removeDescription = () => this.handleRemoveDescription(index);
    const descriptionChange = (value: string) => this.handleDescriptionChange(value, index);
    return (
      <Draggable key={index} draggableId={index.toString()} index={index}>
        {provided => {
          return (
            <div className={styles.description} key={index} ref={provided.innerRef} {...provided.draggableProps}>
              <label>
                <span className={styles.tips}>{this.renderDescriptionRestrictionsHelp(index)}</span>
              </label>
              <div>
                <div className={styles.reorder} {...provided.dragHandleProps} />
                <div>
                  <FormTextInput
                    value={description}
                    errorMessage={this.getServiceDetailsErrorMessage(index)}
                    placeholder="Give a brief description of what they'll get."
                    onChange={descriptionChange}
                    styles={{ height: 50, paddingLeft: 16 }}
                    maxLength={DescriptionMaxCharacters}
                    allowedCharsRegex={AsciiRegex}
                    tabIndex={21 + index}
                    forceShowingError={true}
                  />
                </div>
                <img className={styles.remove} src={removeIcon} alt="remove icon" onClick={removeDescription} />
              </div>
            </div>
          );
        }}
      </Draggable>
    );
  }

  private handleSaveAvailability = (updatedAvailability: IAvailabilityEdit) => {
    const { serviceAvailability, sessionsPerDayLimit, ...availability } = updatedAvailability;
    this.setState({
      showAvailabilityDialog: false,
      serviceAvailability,
      sessionsPerDayLimit,
      service: {
        ...this.state.service,
        ...availability
      }
    });
  };

  private prepareAvailableDate(serviceAvailability: ServiceAvailability, availableDate: moment.Moment | null) {
    return serviceAvailability === ServiceAvailability.Global || !availableDate
      ? null
      : availableDate.format("YYYY-MM-DD");
  }

  private handleSaveClick = () => {
    const { serviceId, service, shootLocation, paymentType, serviceAvailability, sessionsPerDayLimit } = this.state;

    if (this.isValid()) {
      const radius = shootLocation === ShootLocation.InRadius ? service.radius : 0;
      this.props.onCreateOrUpdate(serviceId, {
        ...service,
        radius,
        availableStartDate: this.prepareAvailableDate(serviceAvailability, service.availableStartDate),
        availableEndDate: this.prepareAvailableDate(serviceAvailability, service.availableEndDate),
        availableHours: serviceAvailability === ServiceAvailability.Global ? [] : service.availableHours,
        preEventBuffer: service.preEventBuffer || 0,
        postEventBuffer: service.postEventBuffer || 0,
        sessionsPerDay: sessionsPerDayLimit === SessionsPerDayLimit.NotSet ? null : service.sessionsPerDay,
        deposit:
          service.useDeposit && !isZeroPrice(service)
            ? {
                ...service.deposit!,
                depositAmount: paymentType === PaymentType.NoPayment ? 0 : service.deposit!.depositAmount
              }
            : null
      });
    }
  };

  private handleTogglePanel = (key: string, open: boolean) => {
    this.setState({
      panels: {
        ...this.state.panels,
        [key]: open
      }
    });
  };

  private handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const serviceDetails = this.reorder(
      this.state.service!.serviceDetails,
      result.source.index,
      result.destination.index
    );
    this.setState({
      service: {
        ...this.state.service,
        serviceDetails
      }
    });
  };

  private get nameErrorMessage() {
    return Validation.errorIfEmpty(this.state.service.name, "Please enter service name.");
  }

  private get shootTypeErrorMessage() {
    return Validation.errorIfEmpty(this.state.service.shootType?.name, "Please select shoot type.");
  }

  private get priceErrorMessage() {
    const { service } = this.state;
    return PriceInput.validate(service.price, {
      min: isZeroPrice(service) ? 0 : MinPrice,
      max: MaxPrice,
      overMaxErrorMessage: "The service can cost up to {max}",
      underMinErrorMessage: "The service should cost at least {min}. Don't sell yourself short.",
      emptyErrorMessage: "Please enter service price."
    });
  }

  private get depositAmountErrorMessage() {
    const { price, deposit } = this.state.service;
    if (this.canUseDeposit) {
      return PriceInput.validate(deposit!.depositAmount, {
        min: MinDepositAmount,
        max: price! - 1,
        overMaxErrorMessage: "The amount must be a maximum of {max}",
        underMinErrorMessage: "The amount must be a minimum of {min}.",
        emptyErrorMessage: "Please enter the partial payment amount."
      });
    }

    return null;
  }

  private get durationErrorMessage() {
    return this.state.service.duration <= 0 ? "Please enter duration." : "";
  }

  private get radiusErrorMessage() {
    return this.state.shootLocation === ShootLocation.InRadius && this.state.service.radius <= 0
      ? "Please enter radius."
      : "";
  }

  private handleSelectNoPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        service: {
          ...this.state.service,
          useDeposit: true,
          deposit: {
            ...this.state.service.deposit!,
            sendInvoiceDays: this.state.noPaymentSendInvoiceAfterOrBeforeOption.days,
            sendInvoiceOption: this.state.noPaymentSendInvoiceOption
          }
        },
        paymentType: PaymentType.NoPayment
      },
      () => document.addEventListener("click", this.handleClickOutsidePriceOptions, false)
    );
  };

  private handleSelectFullPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      service: {
        ...this.state.service,
        useDeposit: false
      },
      paymentType: PaymentType.Full
    });
  };

  private handleSelectPartialPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        service: {
          ...this.state.service,
          useDeposit: true,
          deposit: {
            ...this.state.service.deposit!,
            sendInvoiceDays: this.state.depositSendInvoiceAfterOrBeforeOption.days,
            sendInvoiceOption: this.state.depositSendInvoiceOption
          }
        },
        paymentType: PaymentType.Partial
      },
      () => {
        this.partialPaymentAmount.current!.focus();
        document.addEventListener("click", this.handleClickOutsidePriceOptions, false);
      }
    );
  };

  private handleSelectPrimaryShootLocationOnly = () => {
    this.setState({
      shootLocation: ShootLocation.PrimaryOnly
    });
  };

  private handleSelectSendInvoiceSameAsShootDate = () => {
    this.handleSendInvoiceOptionChange(SendInvoiceOption.SameAsShootDate, SendInvoiceOption.SameAsShootDate);
  };

  private handleSelectSendInvoiceAfterOrBeforeShootDate = () => {
    this.handleSendInvoiceOptionChange(
      this.state.noPaymentSendInvoiceAfterOrBeforeOption.sendInvoiceOption,
      this.state.depositSendInvoiceAfterOrBeforeOption.sendInvoiceOption
    );
  };

  private handleSelectSendInvoiceManually = () => {
    this.handleSendInvoiceOptionChange(SendInvoiceOption.Manually, SendInvoiceOption.Manually);
  };

  private handleSendInvoiceOptionChange = (
    noPaymentSendInvoiceOption: SendInvoiceOption,
    depositSendInvoiceOption: SendInvoiceOption
  ) => {
    this.setState({
      service: {
        ...this.state.service,
        deposit: {
          ...this.state.service.deposit!,
          sendInvoiceOption:
            this.state.paymentType === PaymentType.NoPayment ? noPaymentSendInvoiceOption : depositSendInvoiceOption
        }
      },
      noPaymentSendInvoiceOption:
        this.state.paymentType === PaymentType.NoPayment
          ? noPaymentSendInvoiceOption
          : this.state.noPaymentSendInvoiceOption,
      depositSendInvoiceOption:
        this.state.paymentType !== PaymentType.NoPayment
          ? depositSendInvoiceOption
          : this.state.depositSendInvoiceOption
    });
  };

  private handleSendInvoiceDaysChange = (option: IOption) => {
    this.setState({
      service: {
        ...this.state.service,
        deposit: {
          ...this.state.service.deposit!,
          sendInvoiceDays: option.value
        }
      },
      noPaymentSendInvoiceAfterOrBeforeOption:
        this.state.paymentType === PaymentType.NoPayment
          ? {
              ...this.state.noPaymentSendInvoiceAfterOrBeforeOption,
              days: option.value
            }
          : this.state.noPaymentSendInvoiceAfterOrBeforeOption,
      depositSendInvoiceAfterOrBeforeOption:
        this.state.paymentType !== PaymentType.NoPayment
          ? {
              ...this.state.depositSendInvoiceAfterOrBeforeOption,
              days: option.value
            }
          : this.state.depositSendInvoiceAfterOrBeforeOption
    });
  };

  private handleSendInvoiceOptionsChange = (option: ISendInvoiceOptions) => {
    this.setState({
      service: {
        ...this.state.service,
        deposit: {
          ...this.state.service.deposit!,
          sendInvoiceOption: option.value.sendInvoiceOption
        }
      },
      noPaymentSendInvoiceAfterOrBeforeOption:
        this.state.paymentType === PaymentType.NoPayment
          ? {
              ...this.state.noPaymentSendInvoiceAfterOrBeforeOption,
              sendInvoiceOption: option.value.sendInvoiceOption
            }
          : this.state.noPaymentSendInvoiceAfterOrBeforeOption,
      depositSendInvoiceAfterOrBeforeOption:
        this.state.paymentType !== PaymentType.NoPayment
          ? {
              ...this.state.depositSendInvoiceAfterOrBeforeOption,
              sendInvoiceOption: option.value.sendInvoiceOption
            }
          : this.state.depositSendInvoiceAfterOrBeforeOption
    });
  };

  private handleSelectPrimaryShootLocationWithRadius = () => {
    this.setState(
      {
        shootLocation: ShootLocation.InRadius
      },
      () => this.clientLocationDistance.current!.focus()
    );
  };

  private handleServiceNameChange = (value: string) => {
    this.setState({
      service: {
        ...this.state.service,
        name: value
      }
    });
  };

  private handlePriceChange = (value: number | null) => {
    const useDeposit = value === 0 ? false : this.state.service.useDeposit;

    this.setState({
      service: {
        ...this.state.service,
        price: value,
        useDeposit
      }
    });
  };

  private handlePriceBlur = () => {
    this.setState({ priceWasFocused: true });
    document.addEventListener("click", this.handleClickOutsidePricing, false);
  };

  private get canUseDeposit() {
    const { service, paymentType } = this.state;
    return !this.priceErrorMessage && !isZeroPrice(service) && paymentType === PaymentType.Partial;
  }

  private handleChangeShootType = (shootType: IShootType) => {
    this.setState({
      service: {
        ...this.state.service,
        shootType
      }
    });
  };

  private handleDepositAmountChange = (value: number | null) => {
    this.setState({
      service: {
        ...this.state.service,
        deposit: {
          ...this.state.service.deposit!,
          depositAmount: value
        }
      }
    });
  };

  private handleDurationChange = (duration: number) => {
    this.setState({
      service: {
        ...this.state.service,
        duration: Math.ceil(duration / DurationChunk) * DurationChunk
      }
    });
  };

  private handleDurationBlur = () => {
    this.setState({ validateDuration: true });
  };

  private handleShootTypeBlur = () => {
    this.setState({ validateShootType: true });
  };

  private handleDescriptionChange = (value: string, index: number) => {
    const serviceDetails = this.state.service.serviceDetails;

    this.setState({
      service: {
        ...this.state.service,
        serviceDetails: updateItem(serviceDetails, value, index)
      }
    });
  };

  private handleAddDescription = () => {
    const serviceDetails = this.state.service.serviceDetails;
    if (serviceDetails.length >= MaxDescriptions) {
      return;
    }

    this.setState({
      service: {
        ...this.state.service,
        serviceDetails: insertItem(serviceDetails, "", serviceDetails.length)
      }
    });
  };

  private handleRemoveDescription = (index: number) => {
    const serviceDetails = this.state.service.serviceDetails;

    this.setState({
      service: {
        ...this.state.service,
        serviceDetails: removeItem(serviceDetails, index)
      }
    });
  };

  private handleRadiusChange = (radius: string) => {
    this.setState({
      service: {
        ...this.state.service,
        radius: parseInt(radius || "0", 10)
      }
    });
  };

  private handleChangeAskAdditionalInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      service: {
        ...this.state.service,
        askAdditionalInfo: event.target.checked
      }
    });
  };

  private handleChangeBookingApproval = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      service: {
        ...this.state.service,
        approvalRequired: event.target.checked
      }
    });
  };

  private isValid() {
    return (
      !this.nameErrorMessage &&
      !this.priceErrorMessage &&
      !this.durationErrorMessage &&
      !this.radiusErrorMessage &&
      !this.shootTypeErrorMessage &&
      this.state.shootLocation !== ShootLocation.None &&
      this.isPaymentTypeValid() &&
      this.isValidDepositOptions() &&
      this.isValidServiceDetails()
    );
  }

  private isSendInvoiceActiveOption(sendInvoiceOption: SendInvoiceOption, selectedOptions: SendInvoiceOption) {
    return this.isSendInvoiceActiveOptions(sendInvoiceOption, [selectedOptions]);
  }

  private isSendInvoiceActiveOptions(sendInvoiceOption: SendInvoiceOption, selectedOptions: SendInvoiceOption[]) {
    return _.includes(selectedOptions, sendInvoiceOption);
  }

  private isPaymentTypeValid() {
    if (isZeroPrice(this.state.service)) {
      return true;
    }

    return this.state.paymentType !== PaymentType.None;
  }

  private isValidDepositOptions() {
    const { service } = this.state;

    if (!service.useDeposit) {
      return true;
    }

    return !this.depositAmountErrorMessage && service.deposit!.sendInvoiceOption !== SendInvoiceOption.None;
  }

  private isValidServiceDetails() {
    const nonEmptyServiceDetails = _.filter(_.map(this.state.service.serviceDetails, Utilities.trim), sd => !!sd);
    const uniqueServiceDetails = _.uniq(nonEmptyServiceDetails);

    return nonEmptyServiceDetails.length === uniqueServiceDetails.length;
  }

  private getServiceDetailsErrorMessage(index: number) {
    const serviceDetails = this.state.service.serviceDetails;
    const current = Utilities.trim(serviceDetails[index]);

    if (current) {
      for (let i = 0; i < index; i++) {
        if (Utilities.trim(serviceDetails[i]) === current) {
          return "Duplicate service details are not allowed, please revise.";
        }
      }
    }

    return null;
  }

  private reorder(list: string[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  private getSendInvoiceDays = _.memoize((): IOption[] => {
    return this.getOptions(MaxDays, MinDays, index => {
      return { label: index.toString(), value: index };
    });
  });

  private getSendInvoiceOptions = _.memoize((): { [days: number]: ISendInvoiceOptions[] } => {
    return _.reduce(
      this.getSendInvoiceDays(),
      (result, { value }) => {
        result[value] = [
          {
            label: `${pluralize("day", value)} after shoot.`,
            value: {
              days: value,
              sendInvoiceOption: SendInvoiceOption.AfterShootDate
            }
          },
          {
            label: `${pluralize("day", value)} before shoot.`,
            value: {
              days: value,
              sendInvoiceOption: SendInvoiceOption.BeforeShootDate
            }
          }
        ];

        return result;
      },
      {} as { [days: number]: ISendInvoiceOptions[] }
    );
  });

  private getSendInvoiceOption(service: IServiceDetails, paymentType: PaymentType, defaultOption: SendInvoiceOption) {
    return getPaymentType(service) === paymentType ? service.deposit!.sendInvoiceOption : defaultOption;
  }

  private getSendInvoiceAfterOrBeforeOption(
    service: IServiceDetails,
    paymentType: PaymentType,
    defaultAfterOrBeforeOption: IAfterOrBeforeOption
  ) {
    const isAfterOrBeforeShootDate = this.isSendInvoiceActiveOptions(service.deposit!.sendInvoiceOption, [
      SendInvoiceOption.AfterShootDate,
      SendInvoiceOption.BeforeShootDate
    ]);

    if (!isAfterOrBeforeShootDate) {
      return defaultAfterOrBeforeOption;
    }

    return getPaymentType(service) === paymentType
      ? {
          days: service.deposit!.sendInvoiceDays,
          sendInvoiceOption: service.deposit!.sendInvoiceOption
        }
      : defaultAfterOrBeforeOption;
  }

  private getOptions(max: number, step: number, callback: (index: number) => IOption) {
    const options: IOption[] = [];
    const count = max / step;

    for (let index = 1; index <= count; index++) {
      options.push(callback(index));
    }

    return options;
  }

  private getSelectedItem<T>(items: Array<IBasicOption<T>>, comparer: (value: T) => boolean) {
    const index = _.findIndex(items, item => {
      return comparer(item.value);
    });

    return index >= 0 ? items[index] : "";
  }

  private getServiceId() {
    return (this.props.match && this.props.match.params.serviceId) || null;
  }

  private getTabIndex(enabled: boolean, tabIndex: number) {
    return enabled ? tabIndex : -1;
  }

  public static defaultDepositState: IDeposit = {
    depositAmount: null,
    sendInvoiceDays: 1,
    sendInvoiceOption: SendInvoiceOption.None
  };

  public static defaultSendInvoiceAfterOrBeforeOption: IAfterOrBeforeOption = {
    days: 1,
    sendInvoiceOption: SendInvoiceOption.AfterShootDate
  };

  private readonly defaultState: IAddServiceState = {
    serviceId: this.getServiceId(),
    shootLocation: ShootLocation.None,
    serviceAvailability: ServiceAvailability.Global,
    sessionsPerDayLimit: SessionsPerDayLimit.Set,
    paymentType: PaymentType.None,
    validateDuration: false,
    validateShootType: false,
    validateNoPayment: false,
    validatePartialPayment: false,
    depositSendInvoiceOption: SendInvoiceOption.None,
    noPaymentSendInvoiceOption: SendInvoiceOption.None,
    priceWasFocused: false,
    panels: { "pricing-options": true },
    forceShowingDepositAmountError: false,
    noPaymentSendInvoiceAfterOrBeforeOption: EditOrCreate.defaultSendInvoiceAfterOrBeforeOption,
    depositSendInvoiceAfterOrBeforeOption: EditOrCreate.defaultSendInvoiceAfterOrBeforeOption,
    service: {
      name: "",
      price: null,
      duration: 60,
      radius: 0,
      askAdditionalInfo: false,
      serviceDetails: [""],
      approvalRequired: false,
      useDeposit: false,
      deposit: EditOrCreate.defaultDepositState,
      shootType: undefined,
      originalShootType: undefined,
      availableStartDate: null,
      availableEndDate: null,
      availableHours: [],
      preEventBuffer: 30,
      postEventBuffer: 30,
      sessionsPerDay: 4
    },
    showAvailabilityDialog: false
  };

  private static tooltipProps: TooltipProps = {
    className: styles.toolTipsInfo,
    place: "right",
    type: "dark",
    effect: "solid",
    event: "click",
    globalEventOff: "click",
    multiline: true,
    wrapper: "span",
    backgroundColor: colors.tooltip,
    arrowColor: colors.tooltip
  };

  private readonly convertTimeSlots = _.memoize(convertTimeSlotsToSlotRanges);
  private readonly pricingRef = React.createRef<HTMLDivElement>();
  private readonly pricingOptionsRef = React.createRef<HTMLDivElement>();
  private readonly partialPaymentAmount = React.createRef<PriceInput>();
  private readonly clientLocationDistance = React.createRef<FormTextInput>();
}

export default EditOrCreate;
