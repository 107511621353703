import classNames from "classnames";
import React from "react";
import ModalDialog from "../../modalDialog";
import Amount, { AmountType } from "../amount";
import styles from "./index.module.scss";

interface IBillingModalProps {
  title: string;
  headerAmount: number;
  headerAmountType: AmountType;
  paidAmount?: number;
  remainingAmount?: number;
  onClose: () => void;
  bodyOnly?: boolean;
}

class BillingModal extends React.Component<IBillingModalProps> {
  public render() {
    const { title, paidAmount, remainingAmount, headerAmount, headerAmountType, children, bodyOnly } = this.props;

    return (
      <ModalDialog
        title={title}
        noFooter={true}
        noBodyPadding={true}
        onCancel={this.onCancel}
        contentClassName={classNames(styles.content, bodyOnly && styles.bodyOnly)}
        compactHeightClassName={styles.compactHeight}
      >
        <div className={styles.container}>
          {!bodyOnly && <Amount type={headerAmountType} value={headerAmount} className={styles.headerAmount} />}
          <div className={styles.body}>{children}</div>
          {!bodyOnly && (
            <Amount type="paid" value={paidAmount} className={classNames(styles.paidAmount, styles.headerAmount)} />
          )}
          {!bodyOnly && (
            <Amount
              type="remaining"
              value={remainingAmount}
              className={classNames(styles.remainingAmount, styles.headerAmount)}
            />
          )}
        </div>
      </ModalDialog>
    );
  }

  private onCancel = () => this.props.onClose();
}

export default BillingModal;
