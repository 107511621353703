import cx from "classnames";
import React from "react";
import styles from "./toggle.module.scss";

interface IToggleProps {
  active: boolean;
  onClick?: () => void;
  className?: string;
}

const Toggle: React.FC<IToggleProps> = props => {
  const toggleStyles = cx(styles.toggle, props.className, {
    [styles.active]: props.active
  });

  const switchStyles = cx(styles.switch, {
    [styles.active]: props.active
  });

  return (
    <div className={toggleStyles} onClick={props.onClick}>
      <div className={switchStyles} />
    </div>
  );
};

export default Toggle;
