import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import embed from "./activation/embed/reducers";
import pages from "./activation/pages/reducers";
import billing from "./billing/reducers";
import bookings from "./bookings/reducers";
import customize from "./customize/reducers";
import location from "./location/reducers";
import menu from "./menu/reducers";
import modal from "./modal/reducer";
import { notifications } from "./notification/reducers";
import availability from "./profile/availability/reducers";
import contactInfo from "./profile/contactInfo/reducers";
import googleCalendar from "./profile/googleCalendar/reducers";
import shootLocation from "./profile/shootLocation/reducers";
import state from "./profile/state/reducers";
import stripe from "./profile/stripe/reducers";
import services from "./services/reducers";
import session from "./session/reducers";
import shootTypes from "./shootTypes/reducers";
import widget from "./widget/reducers";

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    session,
    menu,
    location,
    profile: combineReducers({ shootLocation, contactInfo, stripe, state, availability, googleCalendar }),
    modal,
    notifications,
    services,
    activation: combineReducers({ pages, embed }),
    customize,
    widget,
    bookings,
    billing,
    shootTypes
  });
