import cx from "classnames";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { StrictOmit } from "types";
import { IElementProps } from "../../../models/props";
import { Utilities } from "../../../utilities/utilities";
import Weekdays from "../../../utilities/weekdays";
import { IDatePickerCalendarDaysProps, getMonthDates, isDateDisabled } from "../models";
import styles from "./defaultDatePickerCalendarDays.module.scss";

const { shouldComponentUpdateDeep } = Utilities;

export interface IDefaultDatePickerCalendarDaysProps extends IDatePickerCalendarDaysProps {
  /** Fills the days grid up to the limit (6 rows) with invisible day cells if necessary, so that the
   *  height of the grid is always consistent regardless of the displayed month. The height is still
   *  dynamic (not just some static number), it depends on the styling, which is customizable. */
  maxHeight?: boolean;
  elements?: {
    weekdays?: IElementProps<HTMLDivElement> & { day?: StrictOmit<IElementProps<HTMLDivElement>, "ref"> };
    days?: IElementProps<HTMLDivElement> & { day?: StrictOmit<IElementProps<HTMLDivElement>, "ref"> };
  };
}

interface IDefaultDatePickerCalendarDaysState {
  month: moment.Moment;
  navigation?: "back" | "forward";
}

class DefaultDatePickerCalendarDays extends React.Component<
  IDefaultDatePickerCalendarDaysProps,
  IDefaultDatePickerCalendarDaysState
> {
  public static getDisplayedDates(date: moment.Moment) {
    return getMonthDates(date, false);
  }

  public state: IDefaultDatePickerCalendarDaysState = { month: this.props.month };

  public shouldComponentUpdate(
    nextProps: IDefaultDatePickerCalendarDaysProps,
    nextState: IDefaultDatePickerCalendarDaysState
  ): boolean {
    return shouldComponentUpdateDeep(this, nextProps, nextState);
  }

  public componentDidUpdate(prevProps: Readonly<IDefaultDatePickerCalendarDaysProps>): void {
    const { month } = this.props;
    const monthHasChanged = !month.isSame(prevProps.month, "month");
    if (monthHasChanged) {
      const navigation = month.isBefore(prevProps.month, "month") ? "back" : "forward";
      this.setState({ navigation }, () => this.setState({ month }));
    }
  }

  public render() {
    const { date, countryCode, className, style, containerRef, elements } = this.props;
    const { month, navigation } = this.state;

    const dates = this.getMonthDates();
    const weekdays = Weekdays.weekdays(countryCode);
    const transitionName = navigation === "back" ? "slideRight" : navigation === "forward" ? "slideLeft" : "instant";

    return (
      <div className={cx(styles.container, className)} style={style} ref={containerRef}>
        <div
          className={cx(styles.weekdays, elements?.weekdays?.className)}
          style={elements?.weekdays?.style}
          ref={elements?.weekdays?.ref}
        >
          {weekdays.map(day => (
            <div
              key={day}
              className={cx(styles.weekday, elements?.weekdays?.day?.className)}
              style={elements?.weekdays?.day?.style}
            >
              {moment.weekdaysMin(day)}
            </div>
          ))}
        </div>
        <div className={styles.daysContainer}>
          <TransitionGroup component={null}>
            <CSSTransition
              key={month.format("YYYY-MM")}
              timeout={navigation ? 300 : 0}
              classNames={Utilities.getTransitionClassNames(styles, transitionName)}
              onExited={this.onTransitionExited}
            >
              <div
                className={cx(styles.days, elements?.days?.className)}
                style={elements?.days?.style}
                ref={elements?.days?.ref}
              >
                {dates.map(d => (
                  <div
                    key={d.format("L")}
                    className={cx(styles.day, elements?.days?.day?.className, {
                      [styles.hidden]: !d.isSame(month, "month"),
                      [styles.selected]: date && d.isSame(date, "date"),
                      [styles.disabled]: this.isDateDisabled(d)
                    })}
                    style={elements?.days?.day?.style}
                    onClick={this.onDayClick(d.date())}
                  >
                    {d.date()}
                  </div>
                ))}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    );
  }

  private isDateDisabled(date: moment.Moment) {
    const { minDate, maxDate, dateStatuses } = this.props;
    return isDateDisabled(date, dateStatuses, minDate, maxDate);
  }

  private getMonthDates() {
    const { countryCode, maxHeight } = this.props;
    const { month } = this.state;
    const dates = getMonthDates(month, true, countryCode);
    if (maxHeight && dates.length < 42 /* 6 weeks */) {
      const datesToAdd = 42 - dates.length;
      const lastDate = _.last(dates)!;
      for (let i = 1; i <= datesToAdd; i++) {
        dates.push(lastDate.clone().add(i, "days"));
      }
    }
    return dates;
  }

  private changeDate = (date: moment.Moment) => {
    if (this.isDateDisabled(date)) {
      return;
    }

    this.props.onDateChange?.(date);
  };

  private onDayClick = _.memoize((day: number) => () => this.changeDate(this.props.month.clone().date(day)));

  private onTransitionExited = () => this.setState({ navigation: undefined });
}

export default DefaultDatePickerCalendarDays;
