import React from "react";
import colors from "utilities/colors";

const Calendar = (props: {
  size?: number;
  color?: string;
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 19}
    height={props.size || 19}
    viewBox="0 0 19 19"
    className={props.className}
    style={props.style}
    onClick={props.onClick}
  >
    <g fill={props.color || colors.black} fillRule="nonzero">
      <path
        d="M5 0c.276 0 .5.224.5.5v.75h7V.5c0-.276.224-.5.5-.5h.5c.276 0 .5.224.5.5v.75h2c.918 0 1.671.707 1.744 1.606L17.75 3v14c0 .918-.707 1.671-1.606 1.744L16 18.75H2c-.918 0-1.671-.707-1.744-1.606L.25 17V3c0-.918.707-1.671 1.606-1.744L2 1.25h2V.5c0-.276.224-.5.5-.5H5zm11.25 6.75H1.75V17c0 .118.082.217.193.243L2 17.25h14c.118 0 .217-.082.243-.193L16.25 17V6.75zM6 13v2H4v-2h2zm4 0v2H8v-2h2zM6 9v2H4V9h2zm4 0v2H8V9h2zm4 0v2h-2V9h2zm2-6.25H2c-.118 0-.217.082-.243.193L1.75 3v2.25h14.5V3c0-.118-.082-.217-.193-.243L16 2.75z"
        transform="translate(-560 -318) translate(20 18) translate(48 285) translate(492 15)"
      />
    </g>
  </svg>
);
export default Calendar;
