import React, { PureComponent } from "react";
import styles from "./bentoBoxResponsive.module.scss";
import colors from "utilities/colors";
import cx from "classnames";
import AlbumLock from "../Icons/AlbumLock";
import Image from "components/Image";

export interface IBentoBoxPropsPhoto {
  url: string;
  srcset?: string;
  isProtected: boolean;
  alt: string;
}

export interface IBentoBoxProps {
  id: string;
  photos: IBentoBoxPropsPhoto[];
  defaultPictureUrl: string;
  onClick?: ((id: string) => void) | undefined;
}

const getBentoTile = (content: IBentoBoxPropsPhoto) => {
  return (
    <div className={styles.bentoTile}>
      <Image
        src={content.url}
        srcset={content.srcset}
        alt={content.alt}
        className={cx(styles.bentoTileImg, content.isProtected ? styles.protectedImage : "")}
      />
      <span>{content.alt || "Picture One"}</span>
      {content.isProtected && (
        <div className={styles.albumLock}>
          <AlbumLock color={colors.darkGrey} size={22} />
        </div>
      )}
    </div>
  );
};

class BentoBoxResponsive extends PureComponent<IBentoBoxProps> {
  public render() {
    const { id, photos, onClick, defaultPictureUrl } = this.props;

    function handleClick() {
      if (onClick) onClick(id);
    }

    // Ensure that we have 3 pictures to fill the bentobox.
    // If not we add the remanding pictures with empty placeholders
    while (photos.length < 3) {
      photos.push({
        url: defaultPictureUrl,
        isProtected: false,
        alt: "No Picture"
      });
    }

    return (
      <div className={styles.container} onClick={handleClick}>
        <div className={styles.photoColumn}>{getBentoTile(photos[0])}</div>
        <div className={styles.photoColumn}>
          <div className={styles.photoRow}>{getBentoTile(photos[1])}</div>
          <div className={styles.photoRow}>{getBentoTile(photos[2])}</div>
        </div>
      </div>
    );
  }
}

export default BentoBoxResponsive;
