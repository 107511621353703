import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import WidgetLayout from "../../../layouts/widget";
import { IAppState } from "../../../store/state";
import { stripeFactoryReady } from "../../../store/widget/actions";

function mapStateToProps(state: IAppState) {
  return {
    stripe: state.widget.stripe
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    onStripeFactoryReady: (stripeFactory: stripe.StripeStatic) => dispatch(stripeFactoryReady(stripeFactory))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetLayout);
