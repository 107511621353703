import cx from "classnames";
import * as React from "react";
import { useEffect } from "react";
import colors from "utilities/colors";
import CloseIcon from "../../../components/Icons/ClientView/Close";
import useInputActivity from "../../../hooks/useInputActivity";
import { IVideoBase } from "utilities/getImgUrl";
import NzVideo from "../../NzVideo";
import styles from "./lightbox.module.scss";
import { IAnalyticParams } from "../../NzVideo/Player";

export interface ILightboxProps {
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  video: IVideoBase;
  className?: string;
  style?: React.CSSProperties;
  analyticsParams?: IAnalyticParams;
  onClose: () => void;
}

function Lightbox(props: ILightboxProps) {
  const { thumbnailUrlTemplate, videoUrlTemplate, video, className, style, analyticsParams, onClose } = props;

  const activity = useInputActivity(2500);
  useEffect(onComponentUpdate);

  function onComponentUpdate() {
    function onDocumentKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") {
        onClose();
      }
      event.stopPropagation();
    }

    document.addEventListener("keydown", onDocumentKeyDown, true);

    return function cleanup() {
      document.removeEventListener("keydown", onDocumentKeyDown, true);
    };
  }

  function onCloseClick() {
    onClose();
  }

  return (
    <div className={cx(styles.container, { [styles.activity]: activity }, className)} style={style}>
      <CloseIcon size={16} color={colors.white} className={styles.close} onClick={onCloseClick} />
      <NzVideo
        thumbnailUrlTemplate={thumbnailUrlTemplate}
        videoUrlTemplate={videoUrlTemplate}
        video={video}
        analyticsParams={analyticsParams}
        playable={true}
        autoplay={true}
        imagePlacement="contain"
        quality={{ default: "best", useSession: false }}
      />
    </div>
  );
}

export default Lightbox;
