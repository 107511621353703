import cx from "classnames";
import { IGallerySettings } from "models/blocks";
import Divider from "../../Divider";
import { IToaster } from "../../Toaster";
import { IRoutes, IClientViewFolderState, IPage, IPhotographerFoldersState } from "../../../models/models";
import React from "react";
import { IExtraPage } from "../../ClientView/Breadcrumb";
import { DynamicFolder } from "../../ClientView/DynamicFolder";
import { IZenBaseBlockProps } from "../zenBaseBlocks";
import styles from "./zenLinkedFolderBlock.module.scss";
import { GRID_TYPE } from "utilities/constant";
import { parseBody } from "utilities/blocks/string";

export interface ILinkedFolderBLockProps extends IZenBaseBlockProps {
  title?: string;
  body?: string;
  isEdit: boolean;
  sitePages: IPage[];
  routes?: IRoutes;
  showTitle?: boolean;
  showInfo?: boolean;
  perPage?: string;
  order?: string;
  folderId: string;
  pageAlias: string;
  extraPages?: IExtraPage[];
  appCdnUrl?: string;
  subdomain?: string;
  gallerySettings?: IGallerySettings;
  clientViewFolder: IClientViewFolderState;
  photographerFolders: IPhotographerFoldersState;
  isDevelopment?: boolean;
  rootFolderId?: string;
  error?: Error;
  isPublish?: boolean;
  visibleItemsCount?: number;
  onShowAlertError: (toaster: IToaster) => void;
  desktopEnabled?: boolean;
  tabletEnabled?: boolean;
  mobileEnabled?: boolean;
}

export class ZenLinkedFolderBlock extends React.Component<ILinkedFolderBLockProps> {
  constructor(props: ILinkedFolderBLockProps) {
    super(props);
  }

  public render() {
    const {
      siteTheme,
      appCdnUrl,
      folderId,
      error,
      subdomain,
      sitePages,
      pageAlias,
      extraPages,
      photographerFolders,
      isPublish,
      isDevelopment,
      alignment,
      rootFolderId,
      perPage,
      order,
      showInfo,
      visibleItemsCount,
      padding,
      divider,
      readOnly,
      isEdit,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      onShowAlertError,
      showTitle
    } = this.props;

    const title = parseBody(this.props.title || "");
    const body = parseBody(this.props.body || "");
    const gallerySettings = this.props.gallerySettings || getGalleryDefaultSettings(showTitle);
    const clientViewFolder: IClientViewFolderState = {
      ...this.props.clientViewFolder,
      ...(title && { name: title }),
      ...(body && { description: body })
    };
    const isShowInfo = showInfo === true;
    let visibilityClass = "";

    if (!isEdit && !desktopEnabled) {
      visibilityClass = styles.hideDesktop;
    }
    if (!isEdit && !tabletEnabled) {
      visibilityClass = cx(visibilityClass, styles.hideTablet);
    }
    if (!isEdit && !mobileEnabled) {
      visibilityClass = cx(visibilityClass, styles.hideMobile);
    }

    // in case if folder deleted we won't show anything for site visitor
    if (!isEdit && clientViewFolder.errorStatus === 404) {
      return null;
    }

    return (
      <>
        <div className={visibilityClass}>
          <DynamicFolder
            appCdnUrl={appCdnUrl || ""}
            folderId={folderId}
            rootFolderId={rootFolderId || folderId}
            error={error}
            subdomain={subdomain}
            sitePages={sitePages}
            siteTheme={siteTheme}
            pageAlias={pageAlias}
            extraPages={extraPages}
            gallerySettings={gallerySettings}
            clientViewFolder={clientViewFolder}
            photographerFolders={photographerFolders}
            isPublish={isPublish}
            isDevelopment={isDevelopment || false}
            onShowAlertError={onShowAlertError}
            alignment={alignment}
            perPage={perPage}
            order={order}
            showInfo={isShowInfo}
            visibleItemsCount={visibleItemsCount}
            padding={padding}
            readonly={readOnly}
            isNormalCase
            isLinkedFolder
          />
          {divider !== "none" && (
            <Divider
              templateBackgroundColor={siteTheme.backgroundColor.value}
              dividerType={divider}
              padding={padding}
            />
          )}
        </div>
      </>
    );
  }
}

const getGalleryDefaultSettings = (showTitle: boolean = true): IGallerySettings => {
  return {
    gridType: GRID_TYPE.MASONRY,
    title: "",
    folderGridType: GRID_TYPE.GRID,
    showTitles: showTitle,
    allowShowCaption: showTitle
  };
};
