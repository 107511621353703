import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { hideModal } from "../../store/modal/actions";
import { IAppState } from "../../store/state";

export type InjectedModalProps<TModalProps> = TModalProps & {
  onHideModal: () => void;
};

const withModal = <P extends InjectedModalProps<TModalProps>, TModalProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Pick<P, Exclude<keyof P, keyof InjectedModalProps<TModalProps>>>> => {
  class WithModal extends React.Component<
    Pick<P, Exclude<keyof P, keyof InjectedModalProps<TModalProps>>> & InjectedModalProps<TModalProps>
  > {
    public render() {
      return <Component {...(this.props as P)} />;
    }
  }

  function mapStateToProps({ modal }: IAppState) {
    return {
      ...modal.modalProps
    };
  }

  function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
    return {
      onHideModal: () => dispatch(hideModal())
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithModal as any) as any;
};

export default withModal;
