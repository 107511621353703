import classNames from "classnames";
import React from "react";
import isEqual from "lodash/isEqual";
import { IComponentBaseProps, resolveValue } from "../../utils";
import styles from "./timePeriodUnit.module.scss";
import { isOldEdge } from "utilities/responsive";

export type TimePeriod = "am" | "pm";

interface ITimePeriodUnitProps extends IComponentBaseProps<HTMLInputElement> {
  defaultValue?: TimePeriod;
  value?: TimePeriod;
  disabled?: boolean;
  tabIndex?: number;
  onChange?: (value: TimePeriod) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

interface ITimePeriodUnitState {
  value?: TimePeriod;
}

class TimePeriodUnit extends React.Component<ITimePeriodUnitProps, ITimePeriodUnitState> {
  public state: ITimePeriodUnitState = {};

  public shouldComponentUpdateDeep<TProps, TState>(
    component: React.Component<TProps, TState>,
    nextProps: TProps,
    nextState: TState
  ) {
    return !isEqual(component.props, nextProps) || !isEqual(component.state, nextState);
  }

  public shouldComponentUpdate(nextProps: ITimePeriodUnitProps, nextState: ITimePeriodUnitState): boolean {
    return this.shouldComponentUpdateDeep(this, nextProps, nextState);
  }

  public render() {
    const { containerRef, style, className, disabled, tabIndex } = this.props;

    return (
      <input
        type="text"
        value={this.value}
        disabled={disabled}
        maxLength={2}
        tabIndex={tabIndex}
        onChange={this.onChange} // no-op function to fix warning
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onKeyDown={this.onKeyDown}
        className={classNames(styles.container, isOldEdge && styles.oldEdge, className)}
        style={style}
        ref={containerRef}
      />
    );
  }

  private get value() {
    return resolveValue(this.props.value, this.state.value, this.props.defaultValue, "am");
  }

  private onChange = () => undefined;

  private onFocus = (event: React.FocusEvent<HTMLInputElement>) => this.props.onFocus?.(event);

  private onBlur = (event: React.FocusEvent<HTMLInputElement>) => this.props.onBlur?.(event);

  private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === "p") {
      this.changeValue("pm");
    } else if (event.key.toLowerCase() === "a") {
      this.changeValue("am");
    }
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      this.changeValue(this.value === "am" ? "pm" : "am");
    } else {
      this.props.onKeyDown?.(event);
    }

    const isCharacter = event.key.length === 1; // probably not 100% reliable
    if (isCharacter) {
      event.preventDefault();
    }
  };

  protected changeValue(value: TimePeriod) {
    if (value === this.value) {
      return;
    }

    if (this.props.value === undefined) {
      this.setState({ value }, () => this.props.onChange?.(value));
    } else {
      this.props.onChange?.(value);
    }
  }
}

export default TimePeriodUnit;
