import classNames from "classnames";
import React from "react";
import Measure, { BoundingRect, ContentRect } from "react-measure";
import styles from "./index.module.scss";

interface IModalPageProps {
  className?: string;
}

interface IModalPageState {
  height?: number;
}

class ModalPage extends React.Component<IModalPageProps, IModalPageState> {
  public state: IModalPageState = {};

  public componentDidMount() {
    this.originalRootHeight = this.root.style.height;
    this.originalRootHeight = this.root.style.overflow;
    this.root.style.overflow = "hidden";
    this.setRootHeight();
  }

  public componentDidUpdate() {
    this.setRootHeight();
  }

  public componentWillUnmount() {
    this.root.style.height = this.originalRootHeight || "";
    this.root.style.overflow = this.originalRootOverflow;
  }

  public render() {
    const { className, children } = this.props;

    return (
      <Measure bounds={true} onResize={this.onResize}>
        {({ measureRef }) => (
          <div className={classNames(styles.container, className)} ref={measureRef}>
            {children}
          </div>
        )}
      </Measure>
    );
  }

  private onResize = (contentRect: ContentRect) => {
    const bounds = contentRect.bounds as BoundingRect;
    this.setState({ height: bounds.height });
  };

  private setRootHeight = () => {
    this.root.style.height = this.state.height ? `${this.state.height}px` : "";
  };

  private get root() {
    return document.getElementById("root")!;
  }

  private originalRootHeight: string | null = null;
  private originalRootOverflow: string = "";
}

export default ModalPage;
