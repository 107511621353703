import cx from "classnames";
import _ from "lodash";
import React, { PureComponent } from "react";
import caretArrow from "../../icons/caret_small_grey.svg";
import styles from "./stepNavigation.module.scss";

interface IStepNav {
  label: string;
  value: string;
}

interface IStepNavProps {
  actualStep: string;
  stepNavs: IStepNav[];
  onChangeStep: (actualStep: string) => void;
  disabled?: boolean;
}

interface IStep extends IStepNav {
  selected: boolean;
  last: boolean;
  onChangeStep?: (actualStep: string) => void;
  disabled?: boolean;
}

const Step: React.FC<IStep> = props => {
  const stepStyles = cx(styles.step, {
    [styles.selected]: props.selected
  });

  function selectStep() {
    if (!props.disabled && props.onChangeStep) {
      props.onChangeStep(props.value);
    }
  }

  return (
    <div className={styles.stepContainer}>
      <li className={stepStyles} onClick={selectStep}>
        {props.label}
      </li>
      {!props.last && <img className={styles.caret} src={caretArrow} />}
    </div>
  );
};

class StepNavigation extends PureComponent<IStepNavProps> {
  public render() {
    const { stepNavs, actualStep, onChangeStep, disabled } = this.props;
    let navigateToIndex: number = stepNavs.length;
    return (
      <div className={styles.breadcrumb}>
        <ul>
          {_.map(stepNavs, (step, index) => {
            if (actualStep === step.value) {
              navigateToIndex = index;
            }

            return (
              <Step
                key={step.value}
                {...step}
                selected={actualStep === step.value}
                last={stepNavs.length === index + 1}
                disabled={index > navigateToIndex ? disabled : false}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}

export default StepNavigation;
