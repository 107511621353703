import cx from "classnames";
import MoreIcon from "components/Icons/More";
import { IColor } from "../../../../models/models";
import React, { memo } from "react";
import styles from "./index.module.scss";

interface ITagProps {
  tag: string;
  buttonsStyle: string;
  accentColor: IColor;
  className?: string;
  isDarkBackground?: boolean;
}

const Tag = (props: ITagProps) => {
  const { tag, buttonsStyle, className, isDarkBackground } = props;

  return (
    <div
      className={cx(
        styles.root,
        styles.buttonStyles,
        styles[buttonsStyle],
        {
          [styles.darkBackground]: isDarkBackground // This applies only in disabled (styled button) state which we don't have as of now for tags
        },
        styles.notClickable,
        className
      )}
    >
      <span className={styles.text}>{tag}</span>
    </div>
  );
};

export default memo(Tag);

interface IMoreTagProps {
  onClick: () => void;
  className?: string;
  style?: React.CSSProperties;
  buttonsStyle: string;
  isDarkBackground?: boolean;
}

export const MoreTag = memo((props: IMoreTagProps) => {
  const { onClick, className, style, buttonsStyle, isDarkBackground } = props;

  return (
    <div
      className={cx(
        styles.root,
        styles.buttonStyles,
        styles[buttonsStyle],
        {
          [styles.darkBackground]: isDarkBackground // This applies only in disabled (styled button) state which we don't have as of now for tags
        },
        className
      )}
      onClick={onClick}
      style={style}
    >
      <MoreIcon width={18} height={4} />
    </div>
  );
});
