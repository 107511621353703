import React from "react";
import styles from "../zenContactMeBlock.module.scss";
import cx from "classnames";
import PhoneInput from "components/PhoneInput";

export interface IPhoneFormGroupProps {
  getFontColor: () => string;
  handlePhoneBlur: () => void;
  onChangeNumber: (value: string) => void;
  phoneNumber: string;
  phoneNumberError: string;
  phoneInputClassName?: string;
  international?: boolean;
  defaultCountry?: string;
}

class PhoneFormGroup extends React.Component<IPhoneFormGroupProps> {
  public render() {
    const {
      phoneNumber,
      phoneNumberError,
      getFontColor,
      phoneInputClassName,
      international,
      defaultCountry
    } = this.props;

    return (
      <div className={styles.formGroup} style={{ color: getFontColor() }}>
        <div className={styles.label} style={{ color: getFontColor() }}>
          PHONE NUMBER
        </div>
        <PhoneInput
          inputClassName={cx(phoneInputClassName, phoneNumberError !== "" ? styles.error : styles.noBorder)}
          placeholder="Enter phone number"
          value={phoneNumber}
          numberError={phoneNumberError}
          international={international}
          defaultCountry={defaultCountry}
          onChangeNumber={this.props.onChangeNumber}
          onBlur={this.props.handlePhoneBlur}
        />
      </div>
    );
  }
}

export default PhoneFormGroup;
