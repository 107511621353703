


import React from "react";
import IIConProps from "../IIconProps";

interface ICheckMarkProps extends IIConProps {
    small?: boolean;
    color?: string
    fill?: string
}

const CheckMarkCircle = (props: ICheckMarkProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
            <g fill="none" fillRule="evenodd">
                <circle cx="14" cy="14" r="14" fill={props.color || "#FFF"} />
                <path fill={props.fill || "#8cd07d"} d="M9.745 12.88L8 14.73l4.83 4.561 6.79-8.73L17.612 9l-5.072 6.521z" />
            </g>
        </svg>
    );
};

export default CheckMarkCircle;

