import { FormTextArea } from "@zenfolio/core-components";
import * as React from "react";
import eventTracker from "../../../../utilities/eventTracker";
import { IEditableWidgetStepProps, IWidgetStep } from "../../contracts";
import styles from "./index.module.scss";

export interface IDetailsParams {
  additionalInfo: string | null;
}

interface IDetailsState extends IDetailsParams {}

interface IDetailsProps extends IEditableWidgetStepProps {
  photographerName: string;
}

class DetailsStep extends React.Component<IDetailsProps, IDetailsState> implements IWidgetStep {
  constructor(props: IDetailsProps) {
    super(props);

    this.state = {
      additionalInfo: props.getValues().additionalInfo
    };
  }

  public componentDidMount() {
    eventTracker.events.consumer.detailsReached();
    this.props.onRef(this);
  }

  public componentWillUnmount() {
    this.props.onRef(undefined);
  }

  public render() {
    const { photographerName } = this.props;
    const { additionalInfo } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <label>Notes for {photographerName}</label>
          <FormTextArea
            className={styles.textAreaWrapper}
            textAreaClassName={styles.textArea}
            placeholder={`Please share any information that will help ${photographerName} give you the best shoot possible.`}
            value={additionalInfo || ""}
            onChange={this.handleAdditionalInfoChanged}
            maxLength={1000}
            charCount={true}
          />
        </div>
      </div>
    );
  }

  public saveAndContinue = (): void => {
    const { additionalInfo } = this.state;
    if (this.props.getValues().additionalInfo !== additionalInfo) {
      this.props.updateValues({
        additionalInfo
      });
    }
  };

  public deferNavigation = (): boolean => {
    return false;
  };

  private handleAdditionalInfoChanged = (additionalInfo: string) => {
    this.setState({
      additionalInfo
    });
  };
}

export default DetailsStep;
