import cx from "classnames";
import _ from "lodash";
import React, { Component, ContextType, createRef, RefObject, SyntheticEvent } from "react";

import { getValidUrl } from "utilities/blocks/blockInteractions";
import Hamburger from "../../../components/Hamburger";
import Caret from "../../../components/Icons/Caret";
import Cart from "../../../components/Icons/Cart";
import {
  FacebookLink,
  InstagramLink,
  LinkedinLink,
  TwitterLink,
  YoutubeLink
} from "../../../components/Icons/SocialLinks";
import MenuMobile from "../../../components/MenuMobile";
import loading from "../../../icons/loading.svg";
import { searchNode } from "utilities/blocks/blockInteractions";
import {
  getSiteFonts,
  isClientSideRendering,
  isSinglePageSite,
  universalWindow as window
} from "utilities/blocks/site";
import colors from "utilities/colors";
import AccountIcon from "../../Icons/AccountIcon";
import MenuItem from "components/MenuMobile/MenuItem";
import styles from "./zenNavigationBlock.module.scss";
import Image from "../../Image";
import { PageContext } from "utilities/pageContext";
import { IRoutes, INavigationMenu, INavigationMenuItem, IZenSiteTheme } from "../../../models/models";
import { NAVBAR_OPACITY_AMOUNT, optionsColorProperty } from "utilities/constant";
import { LINKED_GALLERY_ACTIONS_BAR_ID } from "../zenLinkedGalleryBlock/consts";

export interface ILogoBranding {
  logoText: string | null;
  logoId: string | null;
  logoSrcSet: string | null;
  alternateLogoId: string | null;
  alternateLogoSrcSet: string | null;
  faviconId: string | null;
}

export enum LOGO_TYPE {
  NONE = "none",
  PRIMARY = "primary",
  SECONDARY = "secondary"
}

export enum LOGO_SIZE {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large"
}

export interface INavigationBlockProps {
  siteTheme: IZenSiteTheme;
  stickyNav: boolean;
  numberOfItems: number;
  socialMedia: boolean;
  showCart: boolean;
  clientPortalLink: string;
  showAccount: boolean;
  divider: string;
  spacing: number;
  hoverState: string;
  studioName: string;
  navbarColor: string;
  dropdownColor: string;
  menuItems: INavigationMenu;
  isSelected: boolean;
  facebook: string;
  instagram: string;
  twitter: string;
  youtube: string;
  linkedIn: string;
  hasLinkedGalleryBar?: boolean;
  isTransparent?: boolean;
  overlapHero?: boolean;
  opacity?: NAVBAR_OPACITY_AMOUNT;
  showLogo?: boolean;
  logoSize?: string;
  logoType?: string;
  logoBranding?: ILogoBranding;
  isPreview?: boolean;
  layout?: string;
  forceDesktop?: boolean;
  isEditionView?: boolean;
  closeMenuMobile?: boolean;
  siteSubdomain?: string;
  siteRoutes?: IRoutes;
  isHero?: boolean;
  templatePreview?: boolean;
  templateId?: string;
  logoUrl?: string;
  cartBadge?: number;
  cartTooltip?: JSX.Element;
  accountTooltip?: JSX.Element;
  onLayoutChange?: (layout: string) => void;
  onClickCart?: () => void;
  onClickAccount?: () => void;
  onLogoBrandingChange?: () => void;
}

interface INavigationBlockState {
  containerWidth: number;
  containerHeight: number;
  headerHeight: number;
  showMobileMenu: boolean | null;
  layout: string;
  studioName: string;
  studioLogo: string | null;
  studioSrcSet: string | null;
  subMenuVisible?: string;
  disableTransparency: boolean;
  linkedGalleryBarOffset: number | null;
}

const MOBILE_BREAK = 767;
const BACKGROUND_MAP: { [key: string]: string } = {
  creme: "252, 252, 244",
  white: "255, 255, 255",
  black: "0, 0, 0",
  grey: "50, 50, 50"
}
const COLOR_MAP: { [key: string]: string } = {
  creme: "96, 96, 96",
  white: "0, 0, 0",
  black: "255, 255, 255",
  grey: "96, 96, 96"
}
const TRANSPARENCY_COLOR_MAP: { [key: string]: string } = {
  creme: "0, 0, 0",
  white: "0, 0, 0",
  black: "255, 255, 255",
  grey: "255, 255, 255"
}

const getBackgroundColor = (props: INavigationBlockProps, disableTransparency?: boolean): string => {
  const mappedBackground = BACKGROUND_MAP[props.navbarColor] || BACKGROUND_MAP.black
  return props.isTransparent && !disableTransparency ? `rgba(${mappedBackground}, ${props.opacity})` : `rgb(${mappedBackground})`
}

const getColorWithTransparency = (props: INavigationBlockProps, disableTransparency?: boolean): string | undefined => {
  const mappedColor = COLOR_MAP[props.navbarColor] || COLOR_MAP.black
  const mappedTransparencyColor = TRANSPARENCY_COLOR_MAP[props.navbarColor] || TRANSPARENCY_COLOR_MAP.black
  return props.isTransparent && !disableTransparency ? `rgb(${mappedTransparencyColor})` : `rgb(${mappedColor})`
}

export class ZenNavigationBlock extends Component<INavigationBlockProps, INavigationBlockState> {
  public static defaultProps = {
    stickyNav: false,
    numberOfItems: 10,
    socialMedia: false,
    showCart: false,
    clientPortalLink: "",
    showAccount: true,
    divider: "bar",
    spacing: 20,
    hoverState: "lighten",
    studioName: "",
    navbarColor: "white",
    dropdownColor: "black",
    isSelected: false
  };

  public static contextType = PageContext;
  private containerRef: RefObject<HTMLDivElement>;
  private headerRef: RefObject<HTMLDivElement>;
  private resizeObserver?: ResizeObserver;
  private throttleListener!: ReturnType<typeof _.throttle>

  constructor(props: INavigationBlockProps, context: ContextType<typeof PageContext>) {
    super(props);
    this.containerRef = createRef<HTMLDivElement>();
    this.headerRef = createRef<HTMLDivElement>();
    this.state = {
      containerWidth: 0,
      containerHeight: 0,
      headerHeight: 0,
      showMobileMenu: null,
      layout: this.props.layout || "A",
      studioName: this.getStudioName(),
      studioLogo: this.getStudioLogo(),
      studioSrcSet: this.getStudioSrcSet(),
      disableTransparency: false,
      linkedGalleryBarOffset: null
    };
  }

  public componentDidMount() {
    const { layout, isTransparent, stickyNav, hasLinkedGalleryBar } = this.props;
    const containerRef = this.containerRef.current;

    if (isTransparent && stickyNav && hasLinkedGalleryBar) {
      this.throttleListener = _.throttle(this.updateDisableTransparency, 50)

      document.body.addEventListener("scroll", this.throttleListener);
    }

    if (layout) {
      this.setState({ layout });
    }

    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();

    if (typeof ResizeObserver !== "undefined" && containerRef) {
      this.resizeObserver = new ResizeObserver(entries => {
        const entry = entries[0];
        // We always want to return height of navbar with padding for any layout
        const borderBoxHeight = entry.contentRect ? entry.contentRect.height : entry.borderBoxSize[0].blockSize;

        // Notify other blocks the new height
        this.context.setNavBarHeight(borderBoxHeight);
      });

      // start observing for resize
      this.resizeObserver.observe(containerRef);
    }
  }

  public componentDidUpdate(prevProps: INavigationBlockProps, prevState: any) {
    const {
      layout,
      onLayoutChange,
      menuItems,
      logoBranding,
      onLogoBrandingChange,
      showLogo,
      logoType,
      logoSize,
      isEditionView,
    } = this.props;

    if (prevProps.layout !== layout && layout && layout !== this.state.layout) {
      this.setState({ layout });
    }

    // There could be a case where you have a navbar layout C on a multipage site, and remove one site page leving only one
    // In that case, the nav will transform to lite and the layout C is no longer valid, so changing to A
    if (prevProps.menuItems !== menuItems) {
      if (isSinglePageSite(menuItems) && this.state.layout === "C") {
        this.setState({ layout: "A" }, () => {
          if (onLayoutChange) {
            onLayoutChange(this.state.layout);
          }
        });
      }
    }

    if (
      logoBranding !== prevProps.logoBranding ||
      showLogo !== prevProps.showLogo ||
      logoType !== prevProps.logoType ||
      logoSize !== prevProps.logoSize
    ) {
      if (onLogoBrandingChange) {
        onLogoBrandingChange();
      }

      if (JSON.stringify(logoBranding) !== JSON.stringify(prevProps.logoBranding) && isEditionView) {
        this.setState({ studioLogo: loading });
        setTimeout(() => {
          this.setState({
            studioLogo: this.getStudioLogo(),
            studioSrcSet: this.getStudioSrcSet(),
            studioName: this.getStudioName()
          });
        }, 500);
      } else {
        this.setState({
          studioLogo: this.getStudioLogo(),
          studioSrcSet: this.getStudioSrcSet(),
          studioName: this.getStudioName()
        });
      }
    }
  }

  public UNSAFE_componentWillMount() {
    this.updateWindowDimensions();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.body.removeEventListener("scroll", this.throttleListener);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  public UNSAFE_componentWillReceiveProps(prevProps: INavigationBlockProps) {
    const { closeMenuMobile } = this.props;

    if (closeMenuMobile !== prevProps.closeMenuMobile && this.state.showMobileMenu) {
      this.setState({ showMobileMenu: false });
    }
  }

  private getStudioLogo = (): string | null => {
    const { showLogo, logoType, logoBranding } = this.props;
    const logoId = logoBranding?.logoId;
    const alternateLogoId = logoBranding?.alternateLogoId;
    let result = null;

    if (showLogo && logoType === LOGO_TYPE.PRIMARY && logoId && logoId !== "") {
      result = logoId;
    }

    if (
      showLogo &&
      (logoType === LOGO_TYPE.SECONDARY || (logoType === LOGO_TYPE.PRIMARY && logoId === "")) &&
      alternateLogoId &&
      alternateLogoId !== ""
    ) {
      result = alternateLogoId;
    }

    return result;
  };

  private getStudioSrcSet = (): string | null => {
    const { showLogo, logoType, logoBranding } = this.props;
    const logoSrcSet = logoBranding?.logoSrcSet;
    const alternateLogoSrcSet = logoBranding?.alternateLogoSrcSet;

    if (showLogo && logoType === LOGO_TYPE.PRIMARY && logoSrcSet) {
      return logoSrcSet;
    }

    if (
      showLogo &&
      (logoType === LOGO_TYPE.SECONDARY || (logoType === LOGO_TYPE.PRIMARY && !logoSrcSet)) &&
      alternateLogoSrcSet
    ) {
      return alternateLogoSrcSet;
    }

    return null;
  };

  private getStudioName = () => {
    const { studioName, logoBranding } = this.props;
    const logoText = logoBranding?.logoText;

    let result = studioName;

    if (logoText && logoText !== "") {
      result = logoText;
    }

    return result;
  };

  private isTabletView = () => {
    const { isEditionView } = this.props;
    const TABLET_MAX_WIDTH = 1024;
    const TABLET_MIN_WIDTH = 768;
    const EDITOR_PANEL_WIDTH = 338;

    return (
      window.innerWidth < (isEditionView ? EDITOR_PANEL_WIDTH + TABLET_MAX_WIDTH : TABLET_MAX_WIDTH) &&
      window.innerWidth > (isEditionView ? EDITOR_PANEL_WIDTH + TABLET_MIN_WIDTH : TABLET_MIN_WIDTH)
    );
  };

  private updateDisableTransparency = () => {
    const linkedGalleryBar = document.getElementById(LINKED_GALLERY_ACTIONS_BAR_ID)!;

    if (_.isNil(this.state.linkedGalleryBarOffset)) {
      this.setState({ linkedGalleryBarOffset: linkedGalleryBar?.offsetTop || null });

      return;
    }

    const baseOffset = this.state.linkedGalleryBarOffset - this.state.containerHeight;
    const scrollLessThanBaseOffset = document.body.scrollTop < baseOffset;
    const scrollMoreThanCurrentOffset = document.body.scrollTop > (linkedGalleryBar?.offsetTop + this.state.containerHeight / 2);

    if ((scrollLessThanBaseOffset || scrollMoreThanCurrentOffset) && this.state.disableTransparency) {
      this.setState({ disableTransparency: false });
    } else if (!scrollLessThanBaseOffset && !scrollMoreThanCurrentOffset && !this.state.disableTransparency) {
      this.setState({ disableTransparency: true });
    }
  }

  private updateWindowDimensions = () => {
    const { forceDesktop, isEditionView } = this.props;
    const containerRef = this.containerRef?.current;
    const headerRef = this.headerRef?.current;

    if (containerRef) {
      if (this.state.containerWidth !== containerRef.offsetWidth) {
        this.setState({
          containerWidth: containerRef.offsetWidth
        });
      }

      if (this.state.containerHeight !== containerRef.offsetHeight) {
        if (this.context.setNavBarHeight) {
          this.context.setNavBarHeight(containerRef.offsetHeight);
        }

        this.setState({
          containerHeight: containerRef.offsetHeight
        });
      }

      if (headerRef) {
        if (this.state.headerHeight !== headerRef.offsetHeight) {
          this.setState({
            headerHeight: headerRef.offsetHeight
          });
        }
      }
    }
  };

  private getNavigateLink = (node: INavigationMenuItem | undefined): string | undefined => {
    const { templatePreview, templateId, siteRoutes, siteSubdomain } = this.props;
    const isExternal = !!node ? node.isExternal : false;
    if (isExternal && !!node) {
      return node.menuLink ? getValidUrl(node.menuLink) : undefined;
    } else if (siteSubdomain && siteRoutes && !!node) {
      const url = siteRoutes[node.id];
      const includeSubdomain =
        process.env.REACT_APP_PAGE_LINKS_INCLUDE_SUBDOMAIN &&
        process.env.REACT_APP_PAGE_LINKS_INCLUDE_SUBDOMAIN === "1";
      if (url) {
        return `${includeSubdomain ? `/${siteSubdomain}` : ""}/${url}`;
      }
    } else {
      if (!!node) {
        const currentLocation = window?.location?.href || "";
        const noghost =
          isClientSideRendering && !_.isEmpty(currentLocation)
            ? new URL(currentLocation).searchParams.has("noghost")
            : false;
        const searchParams = noghost ? "?noghost" : "";
        if (templatePreview) {
          return `/clientview/templates/${templateId}/pages/${node.id}${searchParams}`;
        } else {
          return `/clientview/pages/${node.id}${searchParams}`;
        }
      }
    }
    return "/";
  };

  private redirectToPage = (pageId: string) => {
    const { templatePreview, templateId, menuItems } = this.props;

    const parentNode: INavigationMenuItem = {
      id: "",
      menuCaption: "",
      menuName: "",
      childs: menuItems.items
    };

    const node = searchNode(parentNode, pageId, "", false);
    const isExternal = node ? node.isExternal : false;
    const noghost = new URL(window.location.href).searchParams.has("noghost");
    const searchParams = noghost ? "?noghost" : "";

    if (isExternal && node) {
      if (node.openInNewTab) {
        window.open(getValidUrl(node.menuLink), "_blank");
      } else {
        window.location.href = getValidUrl(node.menuLink);
      }
      return;
    }

    if (node) {
      if (templatePreview) {
        window.location.href = `/clientview/templates/${templateId}/pages/${node.id}${searchParams}`;
      } else {
        window.location.href = `/clientview/pages/${node.id}${searchParams}`;
      }
    }
  };

  private handleMenuClick = (e: SyntheticEvent<HTMLSpanElement, MouseEvent>, id: string) => {
    const { siteRoutes, isSelected, isEditionView } = this.props;
    const { subMenuVisible } = this.state;

    if (isEditionView) {
      e.preventDefault();
      return;
    }

    if (e.target) {
      if (isSelected && subMenuVisible === (e.target as HTMLElement).id) {
        this.setState({ subMenuVisible: "" });
      }

      if (!siteRoutes) {
        e.preventDefault();
        this.redirectToPage(id);
      }
    }
  };

  private handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => {
    const { siteRoutes, isEditionView } = this.props;

    if (isEditionView) {
      e.preventDefault();
      return;
    } else if (e.target && !siteRoutes) {
      e.preventDefault();
      this.redirectToPage(id);
    }
  };

  private setLayoutState = (layout: string) => {
    const { menuItems, onLayoutChange } = this.props;

    this.setState(
      {
        layout: layout === "A" ? "B" : layout === "B" ? (!isSinglePageSite(menuItems) ? "C" : "A") : "A"
      },
      () => {
        if (onLayoutChange) {
          onLayoutChange(this.state.layout);
        }
        this.updateWindowDimensions();
      }
    );
  };

  private buildMenu = (item: INavigationMenuItem, addDivider: boolean, itemsNumber: number = 6) => {
    const { hoverState, dropdownColor, divider, navbarColor, isEditionView, siteRoutes, siteSubdomain } = this.props;
    const { subMenuVisible, layout } = this.state;
    const itemSpacing = 32 - itemsNumber * 2;
    const navbarMenuSubClass = styles.navBarMenuSub;
    const itemLink = this.getNavigateLink(item);

    return (
      <li
        className={cx(styles.navBarMenuItemContainer, { ["divider-" + divider]: addDivider })}
        key={item.id || item.menuName || ""}
      >
        <a
          href={item.menuLink ? itemLink : undefined}
          className={cx(styles.navBarMenuItem, hoverState === "lighten" ? styles.lighten : styles.underline)}
          onMouseOver={e => {
            this.setState({ subMenuVisible: item.menuName });
          }}
          id={item.menuName}
          onClick={e => this.handleLinkClick(e, item.id)}
          style={{
            margin: "0 " + itemSpacing + "px"
          }}
          target={item.openInNewTab ? "_blank" : ""}
        >
          {item.menuCaption.toUpperCase()}
          {item.childs && (
            <div className={styles.caretContainer}>
              <Caret size={12} minX={0} minY={-3} open />
            </div>
          )}
        </a>
        {item.childs && (
          <ul
            className={cx(
              navbarMenuSubClass,
              subMenuVisible === item.menuName ? styles.active : "",
              styles.subMenuBlack
            )}
            onMouseLeave={e => {
              this.setState({ subMenuVisible: "" });
            }}
          >
            <div
              style={{
                transform: `translateY(${layout !== "C" ? "-20px" : "-5px"})`
              }}
              className={dropdownColor === "white" ? styles.dropdownWhite : styles.dropdownBlack}
            >
              {item.childs.map(subitem => {
                const subItemLink = this.getNavigateLink(subitem);
                return (
                  <li className={styles.menuItem} key={subitem.id || subitem.menuName || ""}>
                    {!subitem.childs && (
                      <a
                        href={subItemLink}
                        className={cx(
                          styles.navBarMenuItemSub,
                          hoverState === "lighten" ? styles.lighten : styles.underline
                        )}
                        onClick={e => this.handleLinkClick(e, subitem.id)}
                        target={subitem.openInNewTab ? "_blank" : ""}
                      >
                        {subitem.menuCaption}
                      </a>
                    )}
                    {subitem.childs && subitem.childs.length > 0 && (
                      <MenuItem
                        key={subitem.id || subitem.menuName || ""}
                        id={subitem.id}
                        link={subItemLink}
                        delay={`0s`}
                        open={true}
                        color={this.getMenuItemTextColor(navbarColor, dropdownColor, true)}
                        initOpenItem={false}
                        childs={subitem.childs ? subitem.childs : []}
                        backgroundColor={dropdownColor}
                        hoverState={hoverState}
                        onItemClick={this.handleMenuClick}
                        fontSize={14}
                        className={styles.desktopMenuItem}
                        preventAnchorLink={isEditionView || !siteRoutes}
                        isDesktop
                        formatLink={this.getNavigateLink}
                      >
                        {subitem.menuCaption}
                      </MenuItem>
                    )}
                  </li>
                );
              })}
            </div>
          </ul>
        )}
      </li>
    );
  };

  private handleShowMobile = (showMobileMenu: boolean) => {
    const container = this.containerRef.current;

    if (container !== null) {
      if (showMobileMenu) {
        this.setState({ showMobileMenu }, () => {
          this.hideNextSiblings(container);
        });
      }
      else {
        this.showNextSiblings(container);
        this.setState({ showMobileMenu });
      }
    }
  };

  private showNextSiblings = (element: HTMLDivElement, isShow: boolean = true) => {
    if (!this.props.isEditionView && element) {
      let el = element.nextElementSibling;
      while (el) {
        if (isShow) { el.classList.remove(styles.forceHidden); }
        else { el.classList.add(styles.forceHidden); }

        el = el.nextElementSibling;
      }
    }
  };

  private hideNextSiblings = (element: HTMLDivElement) => {
    if (!this.props.isEditionView && element) {
      setTimeout(
        (ele: HTMLDivElement) => {
          this.showNextSiblings(ele, false);
        },
        1000,
        element
      );
    }
  };

  private getMenuItemTextColor = (background: string, dropdownColor: string, isSubItemColor: boolean = false) => {
    let newColor = colors.white;

    if (!this.state.showMobileMenu && !isSubItemColor) {
      const options = _.find(optionsColorProperty, { value: background });
      if (options) {
        switch (background) {
          case optionsColorProperty[0].value:
          case optionsColorProperty[1].value:
            newColor = colors.black;
            break;
          case optionsColorProperty[2].value:
          case optionsColorProperty[3].value:
            newColor = colors.white;
            break;
          default:
            newColor = colors.white;
            break;
        }
      }
    } else {
      switch (dropdownColor) {
        case "white":
          newColor = colors.black;
          break;
        case "black":
          newColor = colors.white;
          break;
        default:
          newColor = colors.white;
          break;
      }
    }

    return newColor;
  };

  private getMobileNavBar = (
    navbarColor: string,
    dropdownColor: string,
    hoverState: string,
    showCart: boolean,
    isTabletView: boolean
  ) => {
    const { showMobileMenu, studioName, studioLogo, studioSrcSet } = this.state;
    const {
      menuItems,
      isEditionView,
      siteTheme,
      cartTooltip,
      accountTooltip,
      facebook,
      instagram,
      twitter,
      youtube,
      linkedIn,
      siteRoutes,
      socialMedia,
      showAccount,
      clientPortalLink,
      cartBadge,
      siteSubdomain,
      onClickCart,
      onClickAccount,
      logoSize,
      isPreview
    } = this.props;
    const homepage = menuItems.items.length > 0 ? menuItems.items[0] : undefined;
    const homeLink = this.getNavigateLink(homepage);

    return (
      <>
        <div
          style={{
            backgroundColor: !showMobileMenu ? "transparent" : dropdownColor,
            height: isEditionView ? "130px" : "92px"
          }}
          className={styles.headerMobile}
        />
        {!isSinglePageSite(menuItems) && (
          <div className={styles.hamburgerMobile}>
            <Hamburger
              color={this.getMenuItemTextColor(navbarColor, dropdownColor)}
              open={showMobileMenu || false}
              onOpenChange={this.handleShowMobile}
            />
          </div>
        )}
        <div className={cx(styles.iconsRightMobile, { [styles.disableEvents]: isEditionView })}>
          {showAccount && (
            <div className={styles.accountIcon}>
              {accountTooltip ? (
                accountTooltip
              ) : onClickAccount ? (
                <div className={styles.noGhost} onClick={onClickAccount}>
                  <AccountIcon size={24} />
                </div>
              ) : (
                <a className={styles.noGhost} href={clientPortalLink} target="_blank">
                  <AccountIcon size={24} />
                </a>
              )}
            </div>
          )}
          {showCart && (
            <div className={cx(styles.cartMobile)}>
              <div className={styles.cartIcon} onClick={onClickCart}>
                <Cart size={24} color={this.getMenuItemTextColor(navbarColor, dropdownColor)} />
                {!!cartBadge && <span className={styles.cartBadge}>{cartBadge}</span>}
                {cartTooltip}
              </div>
            </div>
          )}
        </div>
        <div className={styles.navBarMobileContainer}>
          {studioName && !studioLogo && (
            <a
              href={homeLink}
              style={{
                color: this.getMenuItemTextColor(navbarColor, dropdownColor)
              }}
              className={cx(styles.navBarLogoMobile)}
              onClick={e => this.handleLinkClick(e, homepage?.id || "")}
            >
              {studioName}
            </a>
          )}

          {studioLogo && (
            <div className={cx(styles.flexCenter, this.getLogoParentSize(logoSize))}>
              <a className={cx({ [styles.disableEvents]: isEditionView })} href={homeLink}>
                <Image
                  src={studioLogo}
                  srcset={studioSrcSet || ""}
                  alt="logo"
                  className={cx(styles.navBarLogo, this.getLogoSize(logoSize), styles.border)}
                  disableStyles={true}
                />
              </a>
            </div>
          )}
        </div>
        <MenuMobile
          siteTheme={siteTheme}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
          youtube={youtube}
          linkedIn={linkedIn}
          logoSize={logoSize}
          open={showMobileMenu}
          menu={menuItems}
          siteSubdomain={siteSubdomain}
          isTabletView={isTabletView}
          clientPortalLink={clientPortalLink}
          socialMedia={socialMedia}
          showAccount={showAccount}
          backgroundColor={dropdownColor}
          hoverState={hoverState}
          color={this.getMenuItemTextColor(navbarColor, dropdownColor)}
          isEditionView={isEditionView ? isEditionView : false}
          onItemClick={this.handleMenuClick}
          onClickAccount={onClickAccount}
          noSiteRoutes={!siteRoutes}
          formatLink={this.getNavigateLink}
          isPreview={isPreview}
        />
      </>
    );
  };

  private cancelDrag = (event: SyntheticEvent) => {
    event.preventDefault();
    return false;
  };

  private ghost = () => {
    const { facebook, instagram, twitter, youtube, linkedIn } = this.props;
    const isEmpty = !facebook && !instagram && !twitter && !youtube && !linkedIn;

    return isEmpty;
  };

  private getGhostClass = (prop: string) => {
    return !prop ? styles.ghost : styles.noGhost;
  };

  private getLogoSize = (logoSize?: string) => {
    const { showLogo } = this.props;
    let result = styles.navBarLogoSmall;

    if (window.innerWidth > MOBILE_BREAK && showLogo) {
      switch (logoSize) {
        case LOGO_SIZE.MEDIUM:
          result = styles.navBarLogoMedium;
          break;
        case LOGO_SIZE.LARGE:
          result = styles.navBarLogoLarge;
          break;
      }
    }

    return result;
  };

  private getLogoParentSize = (logoSize?: string) => {
    let result = styles.navBarLogoParentSmall;
    const { showLogo } = this.props;

    if (showLogo) {
      switch (logoSize) {
        case LOGO_SIZE.MEDIUM:
          result = styles.navBarLogoParentMedium;
          break;
        case LOGO_SIZE.LARGE:
          result = styles.navBarLogoParentLarge;
          break;
      }
    }

    return result;
  };

  private getNavSize = (logoSize?: string) => {
    let result = styles.navHeightSmall;
    const { showLogo } = this.props;

    if (showLogo) {
      switch (logoSize) {
        case LOGO_SIZE.MEDIUM:
          result = styles.navHeightMedium;
          break;
        case LOGO_SIZE.LARGE:
          result = styles.navHeightLarge;
          break;
      }
    }

    return result;
  };

  private getDesktopNavBar = (
    props: INavigationBlockProps,
    navbarMenuClass: string,
    visibleItems: INavigationMenu,
    moreItems: INavigationMenuItem[]
  ) => {
    const {
      showCart,
      cartTooltip,
      accountTooltip,
      socialMedia,
      showAccount,
      facebook,
      instagram,
      twitter,
      youtube,
      linkedIn,
      showLogo,
      logoSize,
      menuItems,
      siteSubdomain,
      isEditionView,
      clientPortalLink
    } = props;
    const { showMobileMenu, layout, studioName, studioLogo, studioSrcSet } = this.state;
    const homepage = menuItems.items.length > 0 ? menuItems.items[0] : undefined;
    const homeLink = this.getNavigateLink(homepage);

    let leftItems = 0;
    if (layout === "B") {
      leftItems = Math.floor(visibleItems.items.length / 2);
      if (moreItems.length > 0 || leftItems !== visibleItems.items.length / 2) {
        leftItems++;
      }
    }

    if (layout === "A" || layout === "B") {
      const useLayoutBClass = layout === "B" && !isSinglePageSite(menuItems);

      return (
        <>
          {leftItems > 0 && !isSinglePageSite(menuItems) && (
            <ul
              className={cx(navbarMenuClass, this.getNavSize(logoSize), {
                [styles.layoutB]: useLayoutBClass,
                [styles.layoutBLeft]: useLayoutBClass
              })}
              id="navbarLeftMenu"
            >
              {visibleItems.items.map((item, index) => {
                if (index < leftItems) {
                  return this.buildMenu(item, index > 0 && visibleItems.items.length > 0, visibleItems.items.length);
                }
                return null;
              })}
            </ul>
          )}
          <span
            className={styles.navbarToggle}
            id="navbarToggle"
            onClick={() => this.setState({ showMobileMenu: !showMobileMenu })}
          />
          {studioName && !studioLogo && (
            <a
              href={homeLink}
              className={cx(
                styles.navBarLogo,
                styles.studioNameLogo,
                isSinglePageSite(menuItems) ? styles.navBarLogoCenter : styles.withNavItems,
                {
                  [styles.layoutB]: useLayoutBClass
                }
              )}
              onClick={e => this.handleLinkClick(e, homepage?.id || "")}
            >
              {studioName}
            </a>
          )}
          {studioLogo && (
            <div
              className={cx(
                {
                  [styles.flexCenter]: layout === "B",
                  [styles.layoutB]: layout === "B" && !isSinglePageSite(menuItems)
                },
                this.getLogoParentSize(logoSize)
              )}
            >
              <a className={cx({ [styles.disableEvents]: isEditionView })} href={homeLink}>
                <Image
                  src={studioLogo}
                  srcset={studioSrcSet || ""}
                  alt="logo"
                  className={cx(styles.navBarLogo, this.getLogoSize(logoSize), styles.border)}
                  disableStyles={true}
                />
              </a>
            </div>
          )}
          <ul
            className={cx(navbarMenuClass, {
              [styles.layoutB]: layout === "B" && !isSinglePageSite(menuItems),
              [styles.layoutBRight]: layout === "B" && !isSinglePageSite(menuItems)
            })}
            id="navbarRightMenu"
          >
            {!isSinglePageSite(menuItems) &&
              visibleItems.items.map((item, index) => {
                if (index >= leftItems) {
                  return this.buildMenu(
                    item,
                    index > 0 && visibleItems.items.length > 0 && index > leftItems,
                    visibleItems.items.length
                  );
                }
                return null;
              })}
            {!isSinglePageSite(menuItems) &&
              moreItems.length > 0 &&
              this.buildMenu(
                {
                  id: "more",
                  menuCaption: "MORE",
                  menuName: "more",
                  childs: moreItems
                },
                visibleItems.items.length > 0 && leftItems !== visibleItems.items.length,
                visibleItems.items.length
              )}
            {showAccount && (
              <li className={styles.accountIcon}>
                {accountTooltip ? (
                  accountTooltip
                ) : props.onClickAccount ? (
                  <div className={styles.noGhost} onClick={props.onClickAccount}>
                    <AccountIcon size={24} />
                  </div>
                ) : (
                  <a className={styles.noGhost} href={clientPortalLink} target="_blank">
                    <AccountIcon size={24} />
                  </a>
                )}
              </li>
            )}
            {showCart && (
              <li className={styles.desktopCartIcon}>
                <div className={cx(styles.cartIcon)} onClick={props.onClickCart}>
                  <Cart size={24} />
                  {!!props.cartBadge && <span className={styles.cartBadge}>{props.cartBadge}</span>}
                  {cartTooltip}
                </div>
              </li>
            )}
          </ul>
        </>
      );
    } else {
      return (
        <>
          <div
            className={cx(styles.layoutCHeader, this.getNavSize(logoSize), {
              [styles.layoutCHeaderMargin]: showLogo
            })}
            ref={this.headerRef}
          >
            <div className={styles.layoutCHeaderTitle}>
              <span
                className={styles.navbarToggle}
                id="navbarToggle"
                onClick={e => this.setState({ showMobileMenu: !showMobileMenu })}
              />
              {studioName && !studioLogo && (
                <a href={homeLink} onClick={e => this.handleLinkClick(e, homepage?.id || "")} className={styles.navBarLogo}>
                  {studioName}
                </a>
              )}
              {studioLogo && (
                <a className={cx({ [styles.disableEvents]: isEditionView })} href={homeLink}>
                  <Image
                    src={studioLogo}
                    srcset={studioSrcSet || ""}
                    alt="logo"
                    className={cx(styles.navBarLogo, this.getLogoSize(logoSize), styles.border)}
                    disableStyles={true}
                  />
                </a>
              )}
            </div>
            {socialMedia && (
              <div className={styles.socialMedia}>
                {(facebook || (isEditionView && this.ghost())) && (
                  <a className={this.getGhostClass(facebook)} href={facebook} target="_blank">
                    <FacebookLink size={36} />
                  </a>
                )}

                {(twitter || (isEditionView && this.ghost())) && (
                  <a className={this.getGhostClass(twitter)} href={twitter} target="_blank">
                    <TwitterLink size={36} />
                  </a>
                )}
                {(instagram || (isEditionView && this.ghost())) && (
                  <a className={this.getGhostClass(instagram)} href={instagram} target="_blank">
                    <InstagramLink size={36} />
                  </a>
                )}
                {(youtube || (isEditionView && this.ghost())) && (
                  <a className={this.getGhostClass(youtube)} href={youtube} target="_blank">
                    <YoutubeLink size={36} />
                  </a>
                )}
                {(linkedIn || (isEditionView && this.ghost())) && (
                  <a className={this.getGhostClass(linkedIn)} href={linkedIn} target="_blank">
                    <LinkedinLink size={36} />
                  </a>
                )}
              </div>
            )}
            <div className={styles.iconsRight}>
              {showAccount && (
                <div className={styles.accountIcon}>
                  {accountTooltip ? (
                    accountTooltip
                  ) : props.onClickAccount ? (
                    <div className={styles.noGhost} onClick={props.onClickAccount}>
                      <AccountIcon size={24} />
                    </div>
                  ) : (
                    <a className={styles.noGhost} href={clientPortalLink} target="_blank">
                      <AccountIcon size={24} />
                    </a>
                  )}
                </div>
              )}
              {showCart && (
                <div className={styles.cartIcon} onClick={props.onClickCart}>
                  <Cart size={24} />
                  {!!props.cartBadge && <span className={styles.cartBadge}>{props.cartBadge}</span>}
                  {cartTooltip}
                </div>
              )}
            </div>
          </div>
          <span className={styles.divider} />
          <ul className={navbarMenuClass}>
            {visibleItems.items.map((item, index) => {
              if (index >= leftItems) {
                return this.buildMenu(
                  item,
                  index > 0 && visibleItems.items.length > 0 && index > leftItems,
                  visibleItems.items.length
                );
              }
              return null;
            })}
            {!isSinglePageSite(menuItems) &&
              moreItems.length > 0 &&
              this.buildMenu(
                {
                  id: "more",
                  menuCaption: "MORE",
                  menuName: "more",
                  childs: moreItems
                },
                true,
                visibleItems.items.length
              )}
          </ul>
        </>
      );
    }
  };

  public getIpadProMargin = (logoSize?: string) => {
    const { showLogo, layout } = this.props;
    let result = layout === "C" ? "33px" : "0px";

    if (showLogo && logoSize && layout === "C") {
      switch (logoSize) {
        case LOGO_SIZE.MEDIUM:
          result = "52px";
          break;
        case LOGO_SIZE.LARGE:
          result = "72px";
          break;
      }
    }

    return result;
  };

  public render() {
    const {
      navbarColor,
      menuItems,
      spacing,
      numberOfItems,
      isSelected,
      forceDesktop,
      dropdownColor,
      hoverState,
      showCart,
      isEditionView,
      stickyNav,
      isTransparent,
      overlapHero,
      siteTheme,
      logoSize
    } = this.props;
    const { showMobileMenu, containerWidth, containerHeight, layout } = this.state;

    // this is the fixed width for each navigation element
    const menuWidth: number = 130;
    let myContainerWidth: number = containerWidth;

    if (forceDesktop) {
      myContainerWidth = 2400;
    }
    let navbarClass = cx(styles.zenContainerNavbar);
    const navbarStyle = {
      backgroundColor: getBackgroundColor(this.props, this.state.disableTransparency),
      color: getColorWithTransparency(this.props, this.state.disableTransparency)
    }

    if (isSelected) {
      navbarClass = cx(navbarClass, styles.selected);
    }

    const visibleItems: INavigationMenu = { items: [] };
    const moreItems: INavigationMenuItem[] = [];
    const navItems: number = numberOfItems;
    let totalWidth: number = 320; // this is the width of the logo plus haburguer menu
    totalWidth += menuWidth + 2 * spacing;

    // Calculate main navbar menu and more sub-menu
    for (let i: number = 0; i < menuItems.items.length; i++) {
      // if the number of the desired navItems does not fit the screen width, the reduce navItems
      totalWidth += menuWidth + 2 * spacing;
      if (i < navItems && totalWidth <= myContainerWidth) {
        visibleItems.items.push(menuItems.items[i]);
      } else {
        moreItems.push({
          id: menuItems.items[i].id,
          menuCaption: menuItems.items[i].menuCaption,
          menuName: menuItems.items[i].menuName,
          menuLink: menuItems.items[i].menuLink,
          openInNewTab: menuItems.items[i].openInNewTab,
          isExternal: menuItems.items[i].isExternal,
          childs: menuItems.items[i].childs
        });
      }
    }
    // Add class for nav background
    if (navbarColor === "creme") {
      navbarClass = cx(navbarClass, styles.navbarCreme);
    }
    if (navbarColor === "white") {
      navbarClass = cx(navbarClass, styles.navbarWhite);
    }
    if (navbarColor === "black") {
      navbarClass = cx(navbarClass, styles.navbarBlack);
    }
    if (navbarColor === "grey") {
      navbarClass = cx(navbarClass, styles.navbarGrey);
    }
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    navbarClass = cx(
      navbarClass,
      layout === "A" ? styles.layoutA : layout === "B" ? styles.layoutB : styles.layoutC,
      styles[siteFonts.secondary]
    );
    const navbarMenuClass = cx(styles.navBarMenu, showMobileMenu ? styles.active : "");
    const arrowBackground = dropdownColor === "black" ? "rgba(255, 255, 255, 0.3)" : "#efefef";
    const dropdownMenuColor = dropdownColor === "black" ? "#ffffff" : "#000000";

    return (
      <nav
        id="navBar"
        className={cx(styles.navBarRoot, {
          [styles.forceDesktop]: forceDesktop,
          [styles.stickyNav]: stickyNav && !isEditionView,
          [styles.absoluteNav]: isTransparent && overlapHero && !stickyNav && !isEditionView
        })}
        onDragStart={this.cancelDrag}
        ref={this.containerRef}
      >
        <style>
          {`
            :root {
              --ipad-pro-margin: ${this.getIpadProMargin(logoSize)};
              --dropdown-arrow-background: ${arrowBackground};
              --dropdown-menu-color: ${dropdownMenuColor};
              }
            `}
        </style>
        <div className={cx(navbarClass, styles.showDesktop, styles.desktopWrapper)} style={navbarStyle}>
          {this.getDesktopNavBar(this.props, navbarMenuClass, visibleItems, moreItems)}
        </div>
        <div className={cx(navbarClass, styles.showMobile, styles.zenContainerNavbarMobile)} style={navbarStyle}>
          {this.getMobileNavBar(navbarColor, dropdownColor, hoverState, showCart, this.isTabletView())}
        </div>
        <div
          className={cx(styles.navbarLayout, isSelected ? styles.navbarLayoutVisible : "")}
          style={{ marginTop: containerHeight }}
          onClick={e => {
            this.setLayoutState(layout);
          }}
        >
          <div className={styles.tooltip}>
            <p>Change Block Layout</p>
          </div>
          <span>LAYOUT</span>
          <div className={styles.navbarLayoutBox}>{layout}</div>
        </div>
      </nav>
    );
  }
}
