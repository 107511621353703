import React from "react";
import IIConProps from "../IIconProps";

const Lock = (props: IIConProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16">
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M6 .193c-2.493 0-4.5 1.988-4.5 4.456v.743c-.825 0-1.5.669-1.5 1.486v7.427c0 .816.675 1.485 1.5 1.485h9c.825 0 1.5-.669 1.5-1.485V6.878c0-.817-.675-1.486-1.5-1.486V4.65C10.5 2.181 8.493.193 6 .193zM6 1.68c1.707 0 3 1.28 3 2.97v.743H3V4.65c0-1.69 1.293-2.97 3-2.97zm0 7.427c.825 0 1.5.668 1.5 1.485 0 .817-.675 1.485-1.5 1.485s-1.5-.668-1.5-1.485c0-.817.675-1.485 1.5-1.485z"
      />
    </svg>
  );
};

export default Lock;
