import cx from "classnames";
import Caret from "components/Icons/Caret";
import Close from "components/Icons/ClientView/Close";
import LightBox from "components/LightBox";
import _ from "lodash";

import React, { PureComponent } from "react";
import { isMobile } from "react-device-detect";
import { formatMediaUrl, MESizeMethod } from "utilities/getImgUrl";
import { universalWindow as window } from "utilities/blocks/site";
import colors from "utilities/colors";
import { isGIFPhoto } from "utilities/image";
import { Utilities } from "utilities/utilities";
import {
  ICartItem,
  IClientViewCommentingState,
  IClientViewFavoritesState,
  IUnreadComment,
  PreviewModes
} from "../../../../components/ClientView/models";
import { IMediaId, PhotoOrVideo } from "../../../../models";
import CartAddedIcon from "../Views/components/Media/icons/icon-cart-added.svg";
import CartHover from "../Views/components/Media/icons/icon-cart-hover-black.svg";
import CartHoverClick from "../Views/components/Media/icons/icon-cart-hover-added.svg";
import CartHoverRemove from "../Views/components/Media/icons/icon-cart-hover-remove.svg";
import Cart from "../Views/components/Media/icons/icon-cart-black.svg";
import CommentHoverClick from "../Views/components/Media/icons/icon-comment-click.svg";
import NewCommentHover from "../Views/components/Media/icons/icon-comment-hover-new.svg";
import CommentHover from "../Views/components/Media/icons/icon-comment-hover.svg";
import NewComment from "../Views/components/Media/icons/icon-comment-new.svg";
import Comment from "../Views/components/Media/icons/icon-comment.svg";
import Commented from "../Views/components/Media/icons/icon-commented.svg";
import DownloadHoverClick from "../Views/components/Media/icons/icon-download-hover-click.svg";
import DownloadHover from "../Views/components/Media/icons/icon-download-hover.svg";
import Download from "../Views/components/Media/icons/icon-download.svg";
import Downloaded from "../Views/components/Media/icons/icon-downloaded.svg";
import FavoriteHoverClick from "../Views/components/Media/icons/icon-favorite-hover-click.svg";
import FavoriteHoverRemove from "../Views/components/Media/icons/icon-favorite-hover-remove.svg";
import FavoriteHover from "../Views/components/Media/icons/icon-favorite-hover.svg";
import Favorite from "../Views/components/Media/icons/icon-favorite.svg";
import Favorited from "../Views/components/Media/icons/icon-favorited.svg";
import ShareHoverClick from "../Views/components/Media/icons/icon-share-hover-click.svg";
import ShareHover from "../Views/components/Media/icons/icon-share-hover.svg";
import Share from "../Views/components/Media/icons/icon-share.svg";
import CartIcon from "icons/cart.svg";
import QuickShopPreview, { IQuickShopItem } from "../Views/components/QuickShop/Preview";
import { getButtonAction, getButtonActionHover } from "../Views/imgButtonAction";
import styles from "./styles.module.scss";
import { isShoppingAllowedForMedia, isVideo, toMediaId } from "utilities/helpers";
import { LIGHT_BOX_SIZE_CODE } from "utilities/constant";

export interface ILightboxViewProps {
  albumId: string;
  allMedia: PhotoOrVideo[];
  isEcom?: boolean;
  clientViewFavorites: IClientViewFavoritesState;
  clientViewCommenting?: IClientViewCommentingState;
  cartItems?: ICartItem[];
  allowFavorite: boolean;
  allowPhotoDownload: boolean;
  allowVideoDownload: boolean;
  allowShareGallery?: boolean;
  onShareClick?: () => void;
  blockKeys?: boolean;
  allowMediaCommenting?: boolean;
  onResetFavoriteFlag: () => void;
  onClearFavoriteCallback: () => void;
  onHideModal: () => void;
  onClose: () => void;
  onToggleFavorite: () => void;
  onDownloadMedia: () => void;
  onMediaView?: (mediaId: IMediaId, albumId: string) => void;
  onMediaCommentClick?: (e: React.MouseEvent<HTMLImageElement>, mediaId: IMediaId) => void;
  currentIndex: number;
  onShowShopLightbox?: () => void;
  onSlideChange?: (mediaId: IMediaId) => void;
  onNewSelectedPicture?: (selectedIndex: number) => void;
  isActivity?: boolean;
  previewMode?: PreviewModes;
  renderActivity?: JSX.Element | null;
  quickShopItems?: IQuickShopItem[];
  onClickQuickShopItem?: (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => void;
  photoUrlTemplate: string;
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  videoAnalyticsType?: string;
  hideName?: boolean;
  captionsMap?: Record<string, string>;
  cartBadge?: number;
  onClickButtonCart?: () => void;
  isSingleView?: boolean;
  totalCount?: number;
  observerComponent?: JSX.Element;
}

interface ILightboxViewState {
  isFavorite: boolean;
  hasUnreadComment: boolean | undefined;
  hasCommented: boolean | undefined;
  currentIndex: number;
  hoveredIcon: number;
  clickedIcon: number;
  shopIsVisible: boolean;
  statsAreVisible: boolean;
  timeOutView: NodeJS.Timeout | null;
  isShowQuickShop: boolean;
  photosInCart: string[];
  videosInCart: string[];
  cartItems?: ICartItem[];
}

export class LightboxView extends PureComponent<ILightboxViewProps, ILightboxViewState> {
  constructor(props: ILightboxViewProps) {
    super(props);

    this.state = {
      currentIndex: props.currentIndex,
      isFavorite: false,
      hasUnreadComment: false,
      hasCommented: false,
      shopIsVisible: false,
      hoveredIcon: -1,
      clickedIcon: -1,
      statsAreVisible: false,
      timeOutView: null,
      isShowQuickShop: false,
      photosInCart: [],
      videosInCart: [],
      cartItems: props.cartItems || []
    };
  }

  private refreshCurrentMediaState = (currentMedia: PhotoOrVideo) => {
    const { clientViewFavorites, onClearFavoriteCallback, clientViewCommenting } = this.props;
    const isFavorite = isVideo(currentMedia)
      ? clientViewFavorites.favoriteVideos.some(fv => fv.videoId === currentMedia.id)
      : clientViewFavorites.favoritePhotos.some(fp => fp.photoId === currentMedia.id);
    const matchesComment = (cm: IUnreadComment) => {
      return (isVideo(currentMedia) ? cm.videoId : cm.photoId) === currentMedia.id;
    };
    const hasUnreadComment = clientViewCommenting?.commentedMedia.some(cm => matchesComment(cm) && cm.hasUnreadComment);
    const hasCommented = !!clientViewCommenting?.commentedMedia.find(matchesComment);
    this.setState({ isFavorite, hasUnreadComment, hasCommented }, clientViewFavorites.actionCallback || undefined);
    onClearFavoriteCallback();
  };

  public mediaView = () => {
    this.setState({
      timeOutView: window.setTimeout(() => {
        if (this.props.onMediaView) {
          this.props.onMediaView(toMediaId(this.props.allMedia[this.props.currentIndex]), this.props.albumId);
        }
      }, 3000)
    });
  };

  public listenKeyboard = (event: any) => {
    if (!this.props.blockKeys) {
      if (event.keyCode === 27) {
        if (this.state.timeOutView) {
          clearInterval(this.state.timeOutView);
        }
        this.close();
      }
    }
  };

  public componentDidMount() {
    window.addEventListener("keydown", this.listenKeyboard, true);
    this.setState({
      photosInCart: Utilities.getAllCartPhotos(this.props.cartItems || []),
      videosInCart: Utilities.getAllCartVideos(this.props.cartItems || [])
    });
  }

  public componentWillUnmount() {
    window.removeEventListener("keydown", this.listenKeyboard, true);
  }

  public componentDidUpdate(prevProps: ILightboxViewProps) {
    const { clientViewFavorites, onResetFavoriteFlag, allMedia } = this.props;
    const { currentIndex } = this.state;

    if (
      clientViewFavorites.favoritesUpdated &&
      (prevProps.clientViewFavorites.favoritePhotos !== this.props.clientViewFavorites.favoritePhotos ||
        prevProps.clientViewFavorites.favoriteVideos !== this.props.clientViewFavorites.favoriteVideos)
    ) {
      this.setState((prevState: ILightboxViewState) => ({
        isFavorite: !prevState.isFavorite
      }));
      onResetFavoriteFlag();
    }

    if (!prevProps.clientViewFavorites.favoritesLoaded && clientViewFavorites.favoritesLoaded) {
      this.refreshCurrentMediaState(allMedia[currentIndex]);
    }

    if (this.props.currentIndex !== prevProps.currentIndex) {
      if (this.state.currentIndex === -1 && this.props.onMediaView) {
        if (this.state.timeOutView) {
          clearInterval(this.state.timeOutView);
        }
        this.mediaView();
      }
      this.setState({ currentIndex: this.props.currentIndex }, () => {
        this.refreshCurrentMediaState(allMedia[this.props.currentIndex]);
      });
    }

    if (this.props.clientViewCommenting !== prevProps.clientViewCommenting) {
      this.refreshCurrentMediaState(allMedia[currentIndex]);
    }

    if (!_.isEqual(this.state.cartItems, this.props.cartItems)) {
      this.setState({
        cartItems: this.props.cartItems,
        photosInCart: Utilities.getAllCartPhotos(this.props.cartItems || []),
        videosInCart: Utilities.getAllCartVideos(this.props.cartItems || [])
      });
    }
  }

  private handleSlideChange = (index: number) => {
    const { clientViewFavorites, allMedia, onSlideChange, onNewSelectedPicture, clientViewCommenting } = this.props;
    if (index > -1 && allMedia.length) {
      const currentMedia = allMedia[index];
      const isFavorite = isVideo(currentMedia)
        ? clientViewFavorites.favoriteVideos.some(fv => fv.videoId === currentMedia.id)
        : clientViewFavorites.favoritePhotos.some(fp => fp.photoId === currentMedia.id);
      const matchesComment = (cm: IUnreadComment) => {
        return (isVideo(currentMedia) ? cm.videoId : cm.photoId) === currentMedia.id;
      };
      const hasUnreadComment = clientViewCommenting?.commentedMedia.some(
        cm => matchesComment(cm) && cm.hasUnreadComment
      );
      const hasCommented = clientViewCommenting?.commentedMedia.some(cm => matchesComment(cm) && cm.hasCommented);

      if (
        index !== this.state.currentIndex ||
        this.state.isFavorite !== isFavorite ||
        this.state.hasUnreadComment !== hasUnreadComment ||
        this.state.hasCommented !== hasCommented
      ) {
        if (this.state.timeOutView) {
          clearInterval(this.state.timeOutView);
        }
        this.setState(
          {
            currentIndex: index,
            isFavorite,
            hasUnreadComment,
            hasCommented
          },
          () => {
            this.mediaView();
          }
        );
      }

      if (onSlideChange) {
        onSlideChange(toMediaId(currentMedia));
      }

      if (onNewSelectedPicture) {
        onNewSelectedPicture(index);
      }
    }
  };

  private close = () => {
    this.props.onHideModal();
    this.props.onClose();
  };

  private setHoveredIcon = (index: number) => {
    this.setState({ hoveredIcon: index });
  };

  private setClickedIcon = (index: number) => {
    this.setState({ clickedIcon: index });
  };

  private handleClickCart = () => {
    const isQuickShopEnabled = Utilities.checkQuickShopEnabled(this.props.quickShopItems);
    if (isQuickShopEnabled && isMobile) {
      this.handleShowQuickShopLightBox();
    } else {
      this.handleShowShopLightbox();
    }
  };

  private getOptions = (
    isFavorite: boolean,
    showCart: boolean,
    hasUnreadComment: boolean = false,
    hasCommented: boolean = false
  ) => {
    const {
      allMedia,
      onToggleFavorite,
      onDownloadMedia,
      allowFavorite,
      allowPhotoDownload,
      allowVideoDownload,
      allowMediaCommenting,
      allowShareGallery,
      onShareClick
    } = this.props;
    const { currentIndex } = this.state;
    const currentMedia = allMedia[currentIndex];
    const allowMediaDownload = isVideo(currentMedia) ? allowVideoDownload : allowPhotoDownload;

    const favIcon: string = getButtonAction(
      1,
      1,
      this.state.clickedIcon,
      this.state.hoveredIcon,
      isFavorite,
      Favorite,
      FavoriteHover,
      FavoriteHoverClick,
      Favorited,
      FavoriteHoverRemove
    );
    const downloadIcon: string = getButtonAction(
      2,
      2,
      this.state.clickedIcon,
      this.state.hoveredIcon,
      false,
      Download,
      DownloadHover,
      DownloadHoverClick,
      Downloaded,
      ""
    );
    const cartIcon: string = getButtonAction(
      3,
      3,
      this.state.clickedIcon,
      this.state.hoveredIcon,
      false,
      Cart,
      CartHover,
      CartHoverClick,
      CartAddedIcon,
      CartHoverRemove
    );
    const shareIcon: string = getButtonActionHover(
      4,
      4,
      this.state.clickedIcon,
      this.state.hoveredIcon,
      Share,
      ShareHover,
      ShareHoverClick
    );
    const commentIcon: string = getButtonAction(
      5,
      5,
      this.state.clickedIcon,
      this.state.hoveredIcon,
      hasUnreadComment,
      hasCommented || hasUnreadComment ? Commented : Comment,
      hasCommented || hasUnreadComment ? CommentHoverClick : CommentHover,
      CommentHoverClick,
      NewComment,
      hasCommented || hasUnreadComment ? CommentHoverClick : NewCommentHover
    );

    const isHideCommentTootlip = this.props.previewMode && this.props.previewMode != PreviewModes.Desktop;

    return (
      <React.Fragment>
        {this.props.isEcom && showCart && isShoppingAllowedForMedia(currentMedia) && (
          <div className={cx(styles.icon, styles.iconMargin)}>
            <img
              onMouseEnter={(e: React.MouseEvent<HTMLImageElement>) => this.setHoveredIcon(3)}
              onMouseLeave={(e: React.MouseEvent<HTMLImageElement>) => this.setHoveredIcon(-1)}
              onMouseDown={(e: React.MouseEvent<HTMLImageElement>) => this.setClickedIcon(3)}
              onMouseUp={(e: React.MouseEvent<HTMLImageElement>) => this.setClickedIcon(-1)}
              onClick={this.handleClickCart}
              src={cartIcon}
              alt=""
            />
          </div>
        )}
        {allowFavorite && (
          <div className={styles.icon} onClick={() => onToggleFavorite()}>
            <img
              onMouseEnter={(e: React.MouseEvent<HTMLImageElement>) => this.setHoveredIcon(1)}
              onMouseLeave={(e: React.MouseEvent<HTMLImageElement>) => this.setHoveredIcon(-1)}
              onMouseDown={(e: React.MouseEvent<HTMLImageElement>) => this.setClickedIcon(1)}
              onMouseUp={(e: React.MouseEvent<HTMLImageElement>) => this.setClickedIcon(-1)}
              src={favIcon}
              alt=""
            />
          </div>
        )}
        {allowMediaCommenting && (
          <div
            data-tooltip={isHideCommentTootlip ? "" : "Ask your photographer a question or request changes"}
            className={cx(styles.icon, styles.iconMargin, isHideCommentTootlip ? "" : styles.mediaCommenting)}
          >
            <img
              onMouseEnter={(e: React.MouseEvent<HTMLImageElement>) => this.setHoveredIcon(5)}
              onMouseLeave={(e: React.MouseEvent<HTMLImageElement>) => this.setHoveredIcon(-1)}
              onMouseDown={(e: React.MouseEvent<HTMLImageElement>) => this.setClickedIcon(5)}
              onMouseUp={(e: React.MouseEvent<HTMLImageElement>) => this.setClickedIcon(-1)}
              src={commentIcon}
              onClick={this.handleCommentMedia}
              alt=""
            />
          </div>
        )}
        {allowMediaDownload && (
          <div className={cx(styles.icon, styles.iconMargin)} onClick={() => onDownloadMedia()}>
            <img
              onMouseEnter={(e: React.MouseEvent<HTMLImageElement>) => this.setHoveredIcon(2)}
              onMouseLeave={(e: React.MouseEvent<HTMLImageElement>) => this.setHoveredIcon(-1)}
              onMouseDown={(e: React.MouseEvent<HTMLImageElement>) => this.setClickedIcon(2)}
              onMouseUp={(e: React.MouseEvent<HTMLImageElement>) => this.setClickedIcon(-1)}
              src={downloadIcon}
              alt=""
            />
          </div>
        )}
        {allowShareGallery && (
          <div className={cx(styles.icon, styles.iconMargin)} onClick={onShareClick}>
            <img
              onMouseEnter={(e: React.MouseEvent<HTMLImageElement>) => this.setHoveredIcon(4)}
              onMouseLeave={(e: React.MouseEvent<HTMLImageElement>) => this.setHoveredIcon(-1)}
              onMouseDown={(e: React.MouseEvent<HTMLImageElement>) => this.setClickedIcon(4)}
              onMouseUp={(e: React.MouseEvent<HTMLImageElement>) => this.setClickedIcon(-1)}
              src={shareIcon}
              alt=""
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  private handleShowShopLightbox = () => {
    const { onShowShopLightbox } = this.props;
    if (onShowShopLightbox) {
      onShowShopLightbox();
    } else {
      this.setState({ shopIsVisible: true });
    }
  };

  private handleShowQuickShopLightBox = () => {
    this.setState({ isShowQuickShop: true });
  };

  private handleCloseQuickShopLightBox = () => {
    this.setState({ isShowQuickShop: false });
  };

  private handleCommentMedia = (e: React.MouseEvent<HTMLImageElement>) => {
    if (this.props.onMediaCommentClick) {
      this.props.onMediaCommentClick(e, toMediaId(this.props.allMedia[this.state.currentIndex]));
    }
  };

  private handleCloseStatsLightbox = () => {
    this.setState({ statsAreVisible: !this.state.statsAreVisible });
  };

  public getHeader = () => {
    const { isFavorite, hasUnreadComment, hasCommented } = this.state;
    const { isEcom, cartBadge, onClickButtonCart } = this.props;

    return (
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <div className={styles.backButton} onClick={this.close}>
            <Close color={colors.white} size={16} />
          </div>
        </div>
        <div className={styles.headerCenter}>
          <div className={styles.optionsContainer}>
            {this.getOptions(isFavorite, !!isEcom, hasUnreadComment, hasCommented)}
          </div>
        </div>
        <div className={styles.headerRight}>
          {/* {this.props.isEcom && (
            <div className={styles.shopButton} onClick={this.handleShowShopLighbox}>
              <Shop color={colors.white} width={66} height={19} />
            </div>
          )} */}
          {isEcom && (
            <div className={styles.cartSection}>
              <div className={styles.viewCart} onClick={onClickButtonCart}>
                View Cart
              </div>
              <div onClick={onClickButtonCart} className={styles.cartIcon}>
                <img src={CartIcon} />
                {!!cartBadge && <span className={styles.cartBadge}>{cartBadge}</span>}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  private addItemToCartFromQuickShop = (e: React.MouseEvent<HTMLElement>) => {
    const { onClickQuickShopItem, allMedia } = this.props;
    const currentMedia = allMedia[this.state.currentIndex];
    if (onClickQuickShopItem) {
      onClickQuickShopItem(e, currentMedia);
    }
  };

  public render() {
    const {
      isActivity,
      renderActivity,
      allMedia,
      photoUrlTemplate,
      thumbnailUrlTemplate,
      videoUrlTemplate,
      quickShopItems,
      videoAnalyticsType,
      albumId,
      hideName,
      captionsMap,
      isSingleView,
      totalCount,
      observerComponent
    } = this.props;
    const { shopIsVisible, statsAreVisible, currentIndex, isShowQuickShop } = this.state;
    const currentMedia = allMedia[currentIndex];
    return (
      <div className={styles.container} style={{ height: `${window.innerHeight}px` }}>
        <div className={styles.lightBoxContainer}>
          {!!currentMedia && (
            <LightBox
              isHeaderAbsolute
              selectedIndex={currentIndex}
              lessWidth={(shopIsVisible && this.props.isEcom) || isActivity ? 375 : 0}
              lessHeight={isMobile && this.props.isActivity ? 62 : 0}
              images={allMedia}
              header={this.getHeader()}
              onSlideChange={this.handleSlideChange}
              areStatsOpen={isActivity && isMobile && statsAreVisible}
              photoUrlTemplate={photoUrlTemplate}
              thumbnailUrlTemplate={thumbnailUrlTemplate}
              videoUrlTemplate={videoUrlTemplate}
              videoAnalyticsParams={
                videoAnalyticsType && albumId ? { type: videoAnalyticsType, galleryId: albumId } : undefined
              }
              hideName={hideName}
              captionsMap={captionsMap}
              isSingleView={isSingleView}
              totalCount={totalCount}
              observerComponent={observerComponent}
            />
          )}
        </div>
        {isShowQuickShop && quickShopItems && !!currentMedia && (
          <QuickShopPreview
            className={styles.quickShopPreview}
            items={quickShopItems}
            media={currentMedia}
            imageUrl={formatMediaUrl(
              currentMedia,
              photoUrlTemplate,
              thumbnailUrlTemplate,
              LIGHT_BOX_SIZE_CODE,
              MESizeMethod.Contain
            )}
            onClickShopAllProduct={this.handleShowShopLightbox}
            onClickAddItem={this.addItemToCartFromQuickShop}
            onCloseQuickShop={this.handleCloseQuickShopLightBox}
            isGIFPhoto={isGIFPhoto(currentMedia.fileName)}
          />
        )}

        {isMobile && this.props.isActivity && (
          <div
            className={cx(styles.isActivityButton, {
              [styles.isActivityOpenButton]: statsAreVisible
            })}
            onClick={this.handleCloseStatsLightbox}
          >
            <Caret
              color="#ffffff"
              size={16}
              minX={0}
              minY={0}
              open={statsAreVisible ? statsAreVisible : false}
              down={!statsAreVisible}
            />
            <span className={styles.label}>{!statsAreVisible ? "STATS" : "CLOSE"}</span>
          </div>
        )}

        {((isActivity && !isMobile) || (isActivity && isMobile && statsAreVisible)) && (
          <div className={styles.activityContainer}>{renderActivity}</div>
        )}
      </div>
    );
  }
}
