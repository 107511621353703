import NzVideo from "../../../NzVideo";
import { IVideoPlayer } from "../../../Video/Player";
import React from "react";
import styles from "../zenHeroVideo.module.scss";
import cx from "classnames";
import { IVideoProperty } from "../../blockModels";
import { HeroImageStyle } from "utilities/constant";

interface GalleryVideo extends IVideoProperty {
  playable?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  defaultVolume?: number;
  styleMode: HeroImageStyle;
  globalKeyboardControls?: boolean;
}

export const GalleryVideo = React.forwardRef((props: GalleryVideo, ref: React.Ref<IVideoPlayer>) => {
  const { styleMode, detail, ...otherProps } = props;
  const imageStyleClass = styleMode === HeroImageStyle.FIXED ? styles.imageFixed : styles.imageCover;
  return (
    <NzVideo
      {...otherProps}
      ref={ref}
      preload={false}
      video={detail}
      analyticsParams={
        otherProps.galleryId ? { type: "website_block_hero_video", galleryId: otherProps.galleryId } : undefined
      }
      imagePlacement="cover"
      className={cx(styles.galleryVideo, imageStyleClass)}
    />
  );
});
