export enum EVENT_NAME {
  CLOSE_LIGHT_BOX_VIEW = "CLOSE_LIGHT_BOX_VIEW",
}

class EventBus {
  emit = (eventName: EVENT_NAME, detail?: any) => {
    const event = new CustomEvent(eventName, {
      detail: {
        payload: detail,
      },
    });
    window.dispatchEvent(event);
  };

  on = (eventName: EVENT_NAME, callback: any) => {
    window.addEventListener(eventName, callback, false);
  };

  clear = (eventName: EVENT_NAME, callback: any) => {
    window.removeEventListener(eventName, callback, false);
  };

  once = (eventName: EVENT_NAME, callback: any) => {
    window.addEventListener(eventName, callback, { once: true });
  };
}

const eventBus = new EventBus();

export default eventBus;
