import React, { useRef } from "react";
import { Utilities } from "utilities/utilities";

export default function useCombinedRef<T>(
  externalRef?: React.Ref<T>,
  initialValue: T | null = null
): { combinedRef: React.Ref<T>; internalRef: React.RefObject<T> } {
  const internalRef = useRef<T>(initialValue);
  const combinedRef = Utilities.combineRefs(internalRef, externalRef);
  return { combinedRef, internalRef };
}
