import React from "react";

const IconAlignCenter = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8H9V7H15V8ZM18 11H6V10H18V11ZM9 14H15V13H9V14ZM18 17H6V16H18V17Z"
    />
  </svg>
);

export default IconAlignCenter;
