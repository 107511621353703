import { AlertError } from "@zenfolio/core-components";
import * as React from "react";
import { ErrorInfo } from "react";
import NewRelic from "../../../utilities/newRelic";

interface IErrorBoundaryState {
  error?: Error;
  errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {
  constructor(props: any) {
    super(props);

    this.state = { error: undefined, errorInfo: undefined };
  }

  public componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
    NewRelic.logError(error, errorInfo.componentStack ? { componentStack: errorInfo.componentStack } : undefined);

    this.setState({
      error,
      errorInfo
    });
  };

  public render() {
    if (this.state.errorInfo) {
      return handleError(this);
    }
    return this.props.children;
  }
}

const handleError = (ctx: any) => (
  <AlertError
    message={
      ctx.state.error &&
      ctx.state.error.toString() + " Stack: " + ctx.state.errorInfo.componentStack &&
      ctx.state.errorInfo.componentStack.toString()
    }
  />
);
