export enum CustomizeActionType {
  RESET_CUSTOMIZE_DATA = "RESET_CUSTOMIZE_DATA",

  LOAD_CUSTOMIZE_DATA_STARTED = "LOAD_CUSTOMIZE_DATA_STARTED",
  LOAD_CUSTOMIZE_DATA_SUCCESS = "LOAD_CUSTOMIZE_DATA_SUCCESS",
  LOAD_CUSTOMIZE_DATA_ERROR = "LOAD_CUSTOMIZE_DATA_ERROR",

  UPDATE_CUSTOMIZE_DATA_STARTED = "UPDATE_CUSTOMIZE_DATA_STARTED",
  UPDATE_CUSTOMIZE_DATA_SUCCESS = "UPDATE_CUSTOMIZE_DATA_SUCCESS",
  UPDATE_CUSTOMIZE_DATA_ERROR = "UPDATE_CUSTOMIZE_DATA_ERROR"
}
