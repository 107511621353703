import React from "react";
import IIConProps from "../IIconProps";

interface IPasswordEyeProps extends IIConProps {
  small?: boolean;
}

const PasswordEye = (props: IPasswordEyeProps) => {
  if (props.small) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width}
        height={props.height}
        viewBox="0 0 16 9"
      >
        <path
          fill={props.color}
          fillRule="nonzero"
          d="M15.445 3.885C14.86 3.236 11.753 0 8 0 4.248 0 1.14 3.236.555 3.884L0 4.5l.555.616C1.14 5.764 4.248 9 8 9c3.755 0 6.861-3.236 7.446-3.885L16 4.5l-.555-.615zM8 7.049c-1.088 0-1.973-1.144-1.973-2.549S6.912 1.95 8 1.95 9.973 3.096 9.973 4.5c0 1.405-.885 2.55-1.973 2.55z"
        />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`${props.minX || 0} ${props.minY || 0} ${props.width} ${
        props.height
      }`}
    >
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M23.008 5.508C22.156 4.588 17.628 0 12.158 0 6.691 0 2.161 4.588 1.308 5.508L.5 6.381l.809.873c.852.92 5.382 5.508 10.85 5.508 5.472 0 9.997-4.589 10.85-5.509l.807-.872-.808-.873zm-10.85 4.488c-1.585 0-2.875-1.622-2.875-3.615s1.29-3.615 2.875-3.615c1.586 0 2.876 1.622 2.876 3.615s-1.29 3.615-2.876 3.615z"
      />
    </svg>
  );
};

export default PasswordEye;
