import classNames from "classnames";
import React from "react";
import { Utilities } from "utilities/utilities";
import styles from "./hyperText.module.scss";

interface IHyperTextProps {
  className?: string;
  style?: React.CSSProperties;
  text: string | null | undefined;
}

export default class HyperText extends React.PureComponent<IHyperTextProps> {
  public render() {
    const { className, style, text } = this.props;

    return (
      <div
        className={classNames(styles.container, className)}
        style={style}
        dangerouslySetInnerHTML={{ __html: Utilities.highlightLinksInText(text) }}
      ></div>
    );
  }
}
