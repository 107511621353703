import React from "react";

export interface IServiceBlockWrapperProps {}

const ZenServiceBlockWrapper: React.FC<IServiceBlockWrapperProps> = props => {
  // TODO: In future here we should have Booking widget connect logic
  // and data preparation
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default ZenServiceBlockWrapper;
