import { Dispatch } from "redux";
import { IApiServices } from "../../api";
import colors from "../../utilities/colors";
import IError from "../../utilities/error";
import * as logging from "../../utilities/logging";
import { IAppState } from "../state";
import { ActionType } from "./constants";
import { ActivityTypeCode, IBilling, ICalculatedTax } from "./model";

interface IReset {
  type: ActionType.RESET;
}

export type ResetAction = IReset;

export const reset = (): IReset => ({ type: ActionType.RESET });

interface ILoadStarted {
  type: ActionType.LOAD_STARTED;
  bookingId: string;
}

interface ILoadSuccess {
  type: ActionType.LOAD_SUCCESS;
  bookingId: string;
  billing: IBilling;
}

interface ILoadError {
  type: ActionType.LOAD_ERROR;
  bookingId: string;
  error: IError;
}

export type LoadAction = ILoadStarted | ILoadSuccess | ILoadError;

const loadStarted = (bookingId: string): ILoadStarted => ({
  type: ActionType.LOAD_STARTED,
  bookingId
});

const loadSuccess = (bookingId: string, billing: IBilling): ILoadSuccess => ({
  type: ActionType.LOAD_SUCCESS,
  bookingId,
  billing
});

const loadError = (bookingId: string, error: IError): ILoadError => ({
  type: ActionType.LOAD_ERROR,
  bookingId,
  error
});

export const load = (bookingId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadStarted(bookingId));
    try {
      const billing = await apiService.billing.load(bookingId);
      dispatch(loadSuccess(bookingId, billing));
    } catch (error) {
      dispatch(loadError(bookingId, error));
    }
  };
};

interface ISendInvoiceStarted {
  type: ActionType.SEND_INVOICE_STARTED;
  invoiceId: string;
}

interface ISendInvoiceSuccess {
  type: ActionType.SEND_INVOICE_SUCCESS;
  invoiceId: string;
  billing: IBilling;
}

interface ISendInvoiceError {
  type: ActionType.SEND_INVOICE_ERROR;
  invoiceId: string;
  error: IError;
}

export type SendInvoiceAction = ISendInvoiceStarted | ISendInvoiceSuccess | ISendInvoiceError;

const sendInvoiceStarted = (invoiceId: string): ISendInvoiceStarted => ({
  type: ActionType.SEND_INVOICE_STARTED,
  invoiceId
});

const sendInvoiceSuccess = (invoiceId: string, billing: IBilling): ISendInvoiceSuccess => ({
  type: ActionType.SEND_INVOICE_SUCCESS,
  invoiceId,
  billing
});

const sendInvoiceError = (invoiceId: string, error: IError): ISendInvoiceError => ({
  type: ActionType.SEND_INVOICE_ERROR,
  invoiceId,
  error
});

export const sendInvoice = (invoiceId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(sendInvoiceStarted(invoiceId));
    try {
      const billing = await apiService.billing.sendInvoice(invoiceId);
      dispatch(sendInvoiceSuccess(invoiceId, billing));
    } catch (error) {
      dispatch(sendInvoiceError(invoiceId, error));
    }
  };
};

interface IEditInvoiceStarted {
  type: ActionType.EDIT_INVOICE_STARTED;
  invoiceId: string;
}

interface IEditInvoiceSuccess {
  type: ActionType.EDIT_INVOICE_SUCCESS;
  invoiceId: string;
  billing: IBilling;
}

interface IEditInvoiceError {
  type: ActionType.EDIT_INVOICE_ERROR;
  invoiceId: string;
  error: IError;
}

export type EditInvoiceAction = IEditInvoiceStarted | IEditInvoiceSuccess | IEditInvoiceError;

const editInvoiceStarted = (invoiceId: string): IEditInvoiceStarted => ({
  type: ActionType.EDIT_INVOICE_STARTED,
  invoiceId
});

const editInvoiceSuccess = (invoiceId: string, billing: IBilling): IEditInvoiceSuccess => ({
  type: ActionType.EDIT_INVOICE_SUCCESS,
  invoiceId,
  billing
});

const editInvoiceError = (invoiceId: string, error: IError): IEditInvoiceError => ({
  type: ActionType.EDIT_INVOICE_ERROR,
  invoiceId,
  error
});

export const editInvoice = (invoiceId: string, amount: number, internalNote?: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(editInvoiceStarted(invoiceId));
    try {
      const billing = await apiService.billing.updateInvoice(invoiceId, amount, internalNote);
      dispatch(editInvoiceSuccess(invoiceId, billing));
    } catch (error) {
      dispatch(editInvoiceError(invoiceId, error));
    }
  };
};

interface IAddInvoiceStarted {
  type: ActionType.ADD_INVOICE_STARTED;
  bookingId: string;
}

interface IAddInvoiceSuccess {
  type: ActionType.ADD_INVOICE_SUCCESS;
  bookingId: string;
  billing: IBilling;
}

interface IAddInvoiceError {
  type: ActionType.ADD_INVOICE_ERROR;
  bookingId: string;
  error: IError;
}

export type AddInvoiceAction = IAddInvoiceStarted | IAddInvoiceSuccess | IAddInvoiceError;

const addInvoiceStarted = (bookingId: string): IAddInvoiceStarted => ({
  type: ActionType.ADD_INVOICE_STARTED,
  bookingId
});

const addInvoiceSuccess = (bookingId: string, billing: IBilling): IAddInvoiceSuccess => ({
  type: ActionType.ADD_INVOICE_SUCCESS,
  bookingId,
  billing
});

const addInvoiceError = (bookingId: string, error: IError): IAddInvoiceError => ({
  type: ActionType.ADD_INVOICE_ERROR,
  bookingId,
  error
});

export const addInvoice = (
  bookingId: string,
  amount: number,
  sendNotification: boolean,
  clientNote?: string,
  internalNote?: string
) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(addInvoiceStarted(bookingId));
    try {
      const billing = await apiService.billing.addInvoice(
        bookingId,
        amount,
        sendNotification,
        clientNote,
        internalNote
      );
      dispatch(addInvoiceSuccess(bookingId, billing));
    } catch (error) {
      dispatch(addInvoiceError(bookingId, error));
    }
  };
};

interface IDeleteInvoiceStarted {
  type: ActionType.DELETE_INVOICE_STARTED;
  invoiceId: string;
}

interface IDeleteInvoiceSuccess {
  type: ActionType.DELETE_INVOICE_SUCCESS;
  invoiceId: string;
  billing: IBilling;
}

interface IDeleteInvoiceError {
  type: ActionType.DELETE_INVOICE_ERROR;
  invoiceId: string;
  error: IError;
}

export type DeleteInvoiceAction = IDeleteInvoiceStarted | IDeleteInvoiceSuccess | IDeleteInvoiceError;

const deleteInvoiceStarted = (invoiceId: string): IDeleteInvoiceStarted => ({
  type: ActionType.DELETE_INVOICE_STARTED,
  invoiceId
});

const deleteInvoiceSuccess = (invoiceId: string, billing: IBilling): IDeleteInvoiceSuccess => ({
  type: ActionType.DELETE_INVOICE_SUCCESS,
  invoiceId,
  billing
});

const deleteInvoiceError = (invoiceId: string, error: IError): IDeleteInvoiceError => ({
  type: ActionType.DELETE_INVOICE_ERROR,
  invoiceId,
  error
});

export const deleteInvoice = (invoiceId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(deleteInvoiceStarted(invoiceId));
    try {
      const billing = await apiService.billing.deleteInvoice(invoiceId);
      dispatch(deleteInvoiceSuccess(invoiceId, billing));
    } catch (error) {
      dispatch(deleteInvoiceError(invoiceId, error));
    }
  };
};

interface IRefundStarted {
  type: ActionType.REFUND_STARTED;
  activityId: string;
  activityType: ActivityTypeCode;
  amount: number;
}

interface IRefundSuccess {
  type: ActionType.REFUND_SUCCESS;
  activityId: string;
  billing: IBilling;
}

interface IRefundError {
  type: ActionType.REFUND_ERROR;
  activityId: string;
  error: IError;
}

export type RefundAction = IRefundStarted | IRefundSuccess | IRefundError;

const refundStarted = (activityId: string, activityType: ActivityTypeCode, amount: number): IRefundStarted => ({
  type: ActionType.REFUND_STARTED,
  activityId,
  activityType,
  amount
});

const refundSuccess = (activityId: string, billing: IBilling): IRefundSuccess => ({
  type: ActionType.REFUND_SUCCESS,
  activityId,
  billing
});

const refundError = (activityId: string, error: IError): IRefundError => ({
  type: ActionType.REFUND_ERROR,
  activityId,
  error
});

export const refund = (activityId: string, activityType: ActivityTypeCode, amount: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(refundStarted(activityId, activityType, amount));
    try {
      const billing = await apiService.billing.refund(activityId, activityType, amount);
      dispatch(refundSuccess(activityId, billing));
    } catch (error) {
      dispatch(refundError(activityId, error));
    }
  };
};

interface IMarkAsPaidStarted {
  type: ActionType.MARK_AS_PAID_STARTED;
  invoiceId: string;
}

interface IMarkAsPaidSuccess {
  type: ActionType.MARK_AS_PAID_SUCCESS;
  invoiceId: string;
  billing: IBilling;
}

interface IMarkAsPaidError {
  type: ActionType.MARK_AS_PAID_ERROR;
  invoiceId: string;
  error: IError;
}

export type MarkAsPaidAction = IMarkAsPaidStarted | IMarkAsPaidSuccess | IMarkAsPaidError;

const markAsPaidStarted = (invoiceId: string): IMarkAsPaidStarted => ({
  type: ActionType.MARK_AS_PAID_STARTED,
  invoiceId
});

const markAsPaidSuccess = (invoiceId: string, billing: IBilling): IMarkAsPaidSuccess => ({
  type: ActionType.MARK_AS_PAID_SUCCESS,
  invoiceId,
  billing
});

const markAsPaidError = (invoiceId: string, error: IError): IMarkAsPaidError => ({
  type: ActionType.MARK_AS_PAID_ERROR,
  invoiceId,
  error
});

export const markAsPaid = (invoiceId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(markAsPaidStarted(invoiceId));
    try {
      const billing = await apiService.billing.markAsPaid(invoiceId);
      dispatch(markAsPaidSuccess(invoiceId, billing));
    } catch (error) {
      dispatch(markAsPaidError(invoiceId, error));
    }
  };
};

interface ICalculateTaxForNewInvoiceStarted {
  type: ActionType.CALCULATE_TAX_FOR_NEW_INVOICE_STARTED;
  bookingId: string;
  amount: number;
}

interface ICalculateTaxForNewInvoiceSuccess {
  type: ActionType.CALCULATE_TAX_FOR_NEW_INVOICE_SUCCESS;
  bookingId: string;
  amount: number;
  calculatedTax: ICalculatedTax;
}

interface ICalculateTaxForNewInvoiceError {
  type: ActionType.CALCULATE_TAX_FOR_NEW_INVOICE_ERROR;
  bookingId: string;
  amount: number;
  error: IError;
}

export type CalculateTaxForNewInvoiceAction =
  | ICalculateTaxForNewInvoiceStarted
  | ICalculateTaxForNewInvoiceSuccess
  | ICalculateTaxForNewInvoiceError;

const calculateTaxForNewInvoiceStarted = (bookingId: string, amount: number): ICalculateTaxForNewInvoiceStarted => ({
  type: ActionType.CALCULATE_TAX_FOR_NEW_INVOICE_STARTED,
  bookingId,
  amount
});

const calculateTaxForNewInvoiceSuccess = (
  bookingId: string,
  amount: number,
  calculatedTax: ICalculatedTax
): ICalculateTaxForNewInvoiceSuccess => ({
  type: ActionType.CALCULATE_TAX_FOR_NEW_INVOICE_SUCCESS,
  bookingId,
  amount,
  calculatedTax
});

const calculateTaxForNewInvoiceError = (
  bookingId: string,
  amount: number,
  error: IError
): ICalculateTaxForNewInvoiceError => ({
  type: ActionType.CALCULATE_TAX_FOR_NEW_INVOICE_ERROR,
  bookingId,
  amount,
  error
});

export const calculateTaxForNewInvoice = (bookingId: string, amount: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    logging.log("%cCalculating tax for new invoice...", logging.getLogStyle(colors.filterOrangeText));

    dispatch(calculateTaxForNewInvoiceStarted(bookingId, amount));

    try {
      const calculatedTax = await apiService.billing.calculateTaxForNewInvoice(bookingId, amount);

      logging.log("%cCalculated tax: %o", logging.getLogStyle(colors.filterOrangeText), calculatedTax);

      dispatch(calculateTaxForNewInvoiceSuccess(bookingId, amount, calculatedTax));
    } catch (error) {
      logging.logError(
        "%cFailed to calculate tax for new invoice with an error: %o",
        logging.getLogStyle(colors.redNotification),
        error
      );

      dispatch(calculateTaxForNewInvoiceError(bookingId, amount, error));
    }
  };
};

interface ICalculateTaxForEditingInvoiceStarted {
  type: ActionType.CALCULATE_TAX_FOR_EDITING_INVOICE_STARTED;
  invoiceId: string;
  amount: number;
}

interface ICalculateTaxForEditingInvoiceSuccess {
  type: ActionType.CALCULATE_TAX_FOR_EDITING_INVOICE_SUCCESS;
  invoiceId: string;
  amount: number;
  calculatedTax: ICalculatedTax;
}

interface ICalculateTaxForEditingInvoiceError {
  type: ActionType.CALCULATE_TAX_FOR_EDITING_INVOICE_ERROR;
  invoiceId: string;
  amount: number;
  error: IError;
}

export type CalculateTaxForEditingInvoiceAction =
  | ICalculateTaxForEditingInvoiceStarted
  | ICalculateTaxForEditingInvoiceSuccess
  | ICalculateTaxForEditingInvoiceError;

const calculateTaxForEditingInvoiceStarted = (
  invoiceId: string,
  amount: number
): ICalculateTaxForEditingInvoiceStarted => ({
  type: ActionType.CALCULATE_TAX_FOR_EDITING_INVOICE_STARTED,
  invoiceId,
  amount
});

const calculateTaxForEditingInvoiceSuccess = (
  invoiceId: string,
  amount: number,
  calculatedTax: ICalculatedTax
): ICalculateTaxForEditingInvoiceSuccess => ({
  type: ActionType.CALCULATE_TAX_FOR_EDITING_INVOICE_SUCCESS,
  invoiceId,
  amount,
  calculatedTax
});

const calculateTaxForEditingInvoiceError = (
  invoiceId: string,
  amount: number,
  error: IError
): ICalculateTaxForEditingInvoiceError => ({
  type: ActionType.CALCULATE_TAX_FOR_EDITING_INVOICE_ERROR,
  invoiceId,
  amount,
  error
});

export const calculateTaxForEditingInvoice = (invoiceId: string, amount: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    logging.log("%cCalculating tax for editing invoice...", logging.getLogStyle(colors.filterOrangeText));

    dispatch(calculateTaxForEditingInvoiceStarted(invoiceId, amount));

    try {
      const calculatedTax = await apiService.billing.calculateTaxForEditingInvoice(invoiceId, amount);

      logging.log("%cCalculated tax: %o", logging.getLogStyle(colors.filterOrangeText), calculatedTax);

      dispatch(calculateTaxForEditingInvoiceSuccess(invoiceId, amount, calculatedTax));
    } catch (error) {
      logging.logError(
        "%cFailed to calculate tax for editing invoice with an error: %o",
        logging.getLogStyle(colors.redNotification),
        error
      );

      dispatch(calculateTaxForEditingInvoiceError(invoiceId, amount, error));
    }
  };
};
