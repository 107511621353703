import cx from "classnames";
import React from "react";
import styles from "./toolbar.module.scss";

interface IToolbarProps {
  className: string;
  ref?: React.Ref<HTMLDivElement>;
}

const Toolbar: React.ComponentType<IToolbarProps> = React.forwardRef(
  ({ className, ...props }: IToolbarProps, ref?: React.Ref<HTMLDivElement>) => (
    <div {...props} ref={ref} className={cx(styles.zenContainer, className)} />
  )
);

export default Toolbar;
