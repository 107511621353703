export default [
  {
    name: "Aland Islands",
    isoCode: "AX",
    currency: "eur",
    regionCode: null
  },
  {
    name: "Albania",
    isoCode: "AL",
    currency: "all",
    regionCode: 3
  },
  {
    name: "Algeria",
    isoCode: "DZ",
    currency: "dzd",
    regionCode: 5
  },
  {
    name: "American Samoa",
    isoCode: "AS",
    currency: "usd",
    regionCode: 10
  },
  {
    name: "Andorra",
    isoCode: "AD",
    currency: "eur",
    regionCode: 3
  },
  {
    name: "Angola",
    isoCode: "AO",
    currency: "aoa",
    regionCode: 6
  },
  {
    name: "Anguilla",
    isoCode: "AI",
    currency: "xcd",
    regionCode: 2
  },
  {
    name: "Antarctica",
    isoCode: "AQ",
    currency: "aad",
    regionCode: 11
  },
  {
    name: "Antigua And Barbuda",
    isoCode: "AG",
    currency: "xcd",
    regionCode: 2
  },
  {
    name: "Argentina",
    isoCode: "AR",
    currency: "ars",
    regionCode: 2
  },
  {
    name: "Armenia",
    isoCode: "AM",
    currency: "amd",
    regionCode: 4
  },
  {
    name: "Aruba",
    isoCode: "AW",
    currency: "awg",
    regionCode: 2
  },
  {
    name: "Australia",
    isoCode: "AU",
    currency: "aud",
    regionCode: 10
  },
  {
    name: "Austria",
    isEuropeanUnion: true,
    isoCode: "AT",
    currency: "eur",
    regionCode: 3
  },
  {
    name: "Azerbaijan",
    isoCode: "AZ",
    currency: "azn",
    regionCode: 4
  },
  {
    name: "Bahrain",
    isoCode: "BH",
    currency: "bhd",
    regionCode: 5
  },
  {
    name: "Bangladesh",
    isoCode: "BD",
    currency: "bdt",
    regionCode: 7
  },
  {
    name: "Barbados",
    isoCode: "BB",
    currency: "bbd",
    regionCode: 2
  },
  {
    name: "Belgium",
    isoCode: "BE",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Belize",
    isoCode: "BZ",
    currency: "bzd",
    regionCode: 2
  },
  {
    name: "Benin",
    isoCode: "BJ",
    currency: "xof",
    regionCode: 6
  },
  {
    name: "Bermuda",
    isoCode: "BM",
    currency: "bmd",
    regionCode: 2
  },
  {
    name: "Bhutan",
    isoCode: "BT",
    currency: "btn",
    regionCode: 7
  },
  {
    name: "Bolivia",
    isoCode: "BO",
    currency: "bob",
    regionCode: 2
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    isoCode: "BQ",
    currency: "usd",
    regionCode: null
  },
  {
    name: "Bosnia and Herzegovina",
    isoCode: "BA",
    currency: "bam",
    regionCode: 3
  },
  {
    name: "Botswana",
    isoCode: "BW",
    currency: "bwp",
    regionCode: 6
  },
  {
    name: "Bouvet Island",
    isoCode: "BV",
    currency: "nok",
    regionCode: null
  },
  {
    name: "Brazil",
    isoCode: "BR",
    currency: "brl",
    regionCode: 2
  },
  {
    name: "British Indian Ocean Territory",
    isoCode: "IO",
    currency: "usd",
    regionCode: 7
  },
  {
    name: "Brunei",
    isoCode: "BN",
    currency: "bnd",
    regionCode: 9
  },
  {
    name: "Bulgaria",
    isoCode: "BG",
    currency: "bgn",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Burkina Faso",
    isoCode: "BF",
    currency: "xof",
    regionCode: 6
  },
  {
    name: "Burundi",
    isoCode: "BI",
    currency: "bif",
    regionCode: 6
  },
  {
    name: "Cambodia",
    isoCode: "KH",
    currency: "khr",
    regionCode: 9
  },
  {
    name: "Cameroon",
    isoCode: "CM",
    currency: "xaf",
    regionCode: 6
  },
  {
    name: "Canada",
    isoCode: "CA",
    currency: "cad",
    regionCode: 1
  },
  {
    name: "Cape Verde",
    isoCode: "CV",
    currency: "cve",
    regionCode: 6
  },
  {
    name: "Cayman Islands",
    isoCode: "KY",
    currency: "kyd",
    regionCode: 2
  },
  {
    name: "Central African Republic",
    isoCode: "CF",
    currency: "xaf",
    regionCode: 6
  },
  {
    name: "Chad",
    isoCode: "TD",
    currency: "xaf",
    regionCode: 6
  },
  {
    name: "Chile",
    isoCode: "CL",
    currency: "clp",
    regionCode: 2
  },
  {
    name: "China",
    isoCode: "CN",
    currency: "cny",
    regionCode: null
  },
  {
    name: "Christmas Island",
    isoCode: "CX",
    currency: "aud",
    regionCode: 9
  },
  {
    name: "Cocos (Keeling) Islands",
    isoCode: "CC",
    currency: "aud",
    regionCode: 9
  },
  {
    name: "Colombia",
    isoCode: "CO",
    currency: "cop",
    regionCode: 2
  },
  {
    name: "Comoros",
    isoCode: "KM",
    currency: "kmf",
    regionCode: 6
  },
  {
    name: "Congo",
    isoCode: "CG",
    currency: "xaf",
    regionCode: 6
  },
  {
    name: "Cook Islands",
    isoCode: "CK",
    currency: "nzd",
    regionCode: 10
  },
  {
    name: "Costa Rica",
    isoCode: "CR",
    currency: "crc",
    regionCode: 2
  },
  {
    name: "Cote D'Ivoire (Ivory Coast)",
    isoCode: "CI",
    currency: "xof",
    regionCode: 6
  },
  {
    name: "Croatia",
    isoCode: "HR",
    currency: "hrk",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Curaçao",
    isoCode: "CW",
    currency: "ang",
    regionCode: 2
  },
  {
    name: "Cyprus",
    isoCode: "CY",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Czech Republic",
    isoCode: "CZ",
    currency: "czk",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Democratic Republic of the Congo",
    isoCode: "CD",
    currency: "cdf",
    regionCode: 6
  },
  {
    name: "Denmark",
    isoCode: "DK",
    currency: "dkk",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Djibouti",
    isoCode: "DJ",
    currency: "djf",
    regionCode: 6
  },
  {
    name: "Dominica",
    isoCode: "DM",
    currency: "xcd",
    regionCode: 2
  },
  {
    name: "Dominican Republic",
    isoCode: "DO",
    currency: "dop",
    regionCode: 2
  },
  {
    name: "East Timor",
    isoCode: "TL",
    currency: "usd",
    regionCode: 9
  },
  {
    name: "Ecuador",
    isoCode: "EC",
    currency: "usd",
    regionCode: 2
  },
  {
    name: "Egypt",
    isoCode: "EG",
    currency: "egp",
    regionCode: 5
  },
  {
    name: "El Salvador",
    isoCode: "SV",
    currency: "usd",
    regionCode: 2
  },
  {
    name: "Equatorial Guinea",
    isoCode: "GQ",
    currency: "xaf",
    regionCode: 6
  },
  {
    name: "Eritrea",
    isoCode: "ER",
    currency: "ern",
    regionCode: 6
  },
  {
    name: "Estonia",
    isoCode: "EE",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Ethiopia",
    isoCode: "ET",
    currency: "etb",
    regionCode: 6
  },
  {
    name: "Falkland Islands",
    isoCode: "FK",
    currency: "fkp",
    regionCode: 11
  },
  {
    name: "Faroe Islands",
    isoCode: "FO",
    currency: "dkk",
    regionCode: 3
  },
  {
    name: "Fiji Islands",
    isoCode: "FJ",
    currency: "fjd",
    regionCode: 10
  },
  {
    name: "Finland",
    isoCode: "FI",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "France",
    isoCode: "FR",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "French Guiana",
    isoCode: "GF",
    currency: "eur",
    regionCode: 2
  },
  {
    name: "French Polynesia",
    isoCode: "PF",
    currency: "xpf",
    regionCode: 10
  },
  {
    name: "French Southern Territories",
    isoCode: "TF",
    currency: "eur",
    regionCode: 11
  },
  {
    name: "Gabon",
    isoCode: "GA",
    currency: "xaf",
    regionCode: null
  },
  {
    name: "Gambia The",
    isoCode: "GM",
    currency: "gmd",
    regionCode: 6
  },
  {
    name: "Georgia",
    isoCode: "GE",
    currency: "gel",
    regionCode: 11
  },
  {
    name: "Germany",
    isoCode: "DE",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Ghana",
    isoCode: "GH",
    currency: "ghs",
    regionCode: 6
  },
  {
    name: "Gibraltar",
    isoCode: "GI",
    currency: "gip",
    regionCode: 3
  },
  {
    name: "Greece",
    isoCode: "GR",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Greenland",
    isoCode: "GL",
    currency: "dkk",
    regionCode: 1
  },
  {
    name: "Grenada",
    isoCode: "GD",
    currency: "xcd",
    regionCode: 2
  },
  {
    name: "Guadeloupe",
    isoCode: "GP",
    currency: "eur",
    regionCode: 2
  },
  {
    name: "Guam",
    isoCode: "GU",
    currency: "usd",
    regionCode: 9
  },
  {
    name: "Guatemala",
    isoCode: "GT",
    currency: "gtq",
    regionCode: 2
  },
  {
    name: "Guernsey and Alderney",
    isoCode: "GG",
    currency: "gbp",
    regionCode: 3
  },
  {
    name: "Guinea",
    isoCode: "GN",
    currency: "gnf",
    regionCode: 6
  },
  {
    name: "Guinea-Bissau",
    isoCode: "GW",
    currency: "xof",
    regionCode: 6
  },
  {
    name: "Guyana",
    isoCode: "GY",
    currency: "gyd",
    regionCode: 2
  },
  {
    name: "Haiti",
    isoCode: "HT",
    currency: "htg",
    regionCode: 2
  },
  {
    name: "Heard Island and McDonald Islands",
    isoCode: "HM",
    currency: "aud",
    regionCode: 11
  },
  {
    name: "Honduras",
    isoCode: "HN",
    currency: "hnl",
    regionCode: 2
  },
  {
    name: "Hong Kong S.A.R.",
    isoCode: "HK",
    currency: "hkd",
    regionCode: 8
  },
  {
    name: "Hungary",
    isoCode: "HU",
    currency: "huf",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Iceland",
    isoCode: "IS",
    currency: "isk",
    regionCode: 3
  },
  {
    name: "India",
    isoCode: "IN",
    currency: "inr",
    regionCode: 7
  },
  {
    name: "Indonesia",
    isoCode: "ID",
    currency: "idr",
    regionCode: 9
  },
  {
    name: "Ireland",
    isoCode: "IE",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Israel",
    isoCode: "IL",
    currency: "ils",
    regionCode: 5
  },
  {
    name: "Italy",
    isoCode: "IT",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Jamaica",
    isoCode: "JM",
    currency: "jmd",
    regionCode: 2
  },
  {
    name: "Japan",
    isoCode: "JP",
    currency: "jpy",
    regionCode: 8
  },
  {
    name: "Jersey",
    isoCode: "JE",
    currency: "gbp",
    regionCode: 3
  },
  {
    name: "Jordan",
    isoCode: "JO",
    currency: "jod",
    regionCode: 5
  },
  {
    name: "Kazakhstan",
    isoCode: "KZ",
    currency: "kzt",
    regionCode: 4
  },
  {
    name: "Kenya",
    isoCode: "KE",
    currency: "kes",
    regionCode: 6
  },
  {
    name: "Kiribati",
    isoCode: "KI",
    currency: "aud",
    regionCode: 10
  },
  {
    name: "Kosovo",
    isoCode: "XK",
    currency: "eur",
    regionCode: null
  },
  {
    name: "Kuwait",
    isoCode: "KW",
    currency: "kwd",
    regionCode: 5
  },
  {
    name: "Kyrgyzstan",
    isoCode: "KG",
    currency: "kgs",
    regionCode: 4
  },
  {
    name: "Laos",
    isoCode: "LA",
    currency: "lak",
    regionCode: 9
  },
  {
    name: "Latvia",
    isoCode: "LV",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Lebanon",
    isoCode: "LB",
    currency: "lbp",
    regionCode: 5
  },
  {
    name: "Lesotho",
    isoCode: "LS",
    currency: "lsl",
    regionCode: 6
  },
  {
    name: "Liberia",
    isoCode: "LR",
    currency: "lrd",
    regionCode: 6
  },
  {
    name: "Libya",
    isoCode: "LY",
    currency: "lyd",
    regionCode: 5
  },
  {
    name: "Liechtenstein",
    isoCode: "LI",
    currency: "chf",
    regionCode: 3
  },
  {
    name: "Lithuania",
    isoCode: "LT",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Luxembourg",
    isoCode: "LU",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Macau S.A.R.",
    isoCode: "MO",
    currency: "mop",
    regionCode: 8
  },
  {
    name: "Macedonia",
    isoCode: "MK",
    currency: "mkd",
    regionCode: 3
  },
  {
    name: "Madagascar",
    isoCode: "MG",
    currency: "mga",
    regionCode: 6
  },
  {
    name: "Malawi",
    isoCode: "MW",
    currency: "mwk",
    regionCode: 6
  },
  {
    name: "Malaysia",
    isoCode: "MY",
    currency: "myr",
    regionCode: 9
  },
  {
    name: "Maldives",
    isoCode: "MV",
    currency: "mvr",
    regionCode: 7
  },
  {
    name: "Malta",
    isoCode: "MT",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Man (Isle of)",
    isoCode: "IM",
    currency: "gbp",
    regionCode: 3
  },
  {
    name: "Marshall Islands",
    isoCode: "MH",
    currency: "usd",
    regionCode: 9
  },
  {
    name: "Martinique",
    isoCode: "MQ",
    currency: "eur",
    regionCode: 2
  },
  {
    name: "Mauritania",
    isoCode: "MR",
    currency: "mro",
    regionCode: 6
  },
  {
    name: "Mauritius",
    isoCode: "MU",
    currency: "mur",
    regionCode: 6
  },
  {
    name: "Mayotte",
    isoCode: "YT",
    currency: "eur",
    regionCode: 6
  },
  {
    name: "Mexico",
    isoCode: "MX",
    currency: "mxn",
    regionCode: 2
  },
  {
    name: "Micronesia",
    isoCode: "FM",
    currency: "usd",
    regionCode: 9
  },
  {
    name: "Moldova",
    isoCode: "MD",
    currency: "mdl",
    regionCode: 3
  },
  {
    name: "Monaco",
    isoCode: "MC",
    currency: "eur",
    regionCode: 3
  },
  {
    name: "Mongolia",
    isoCode: "MN",
    currency: "mnt",
    regionCode: 8
  },
  {
    name: "Montserrat",
    isoCode: "MS",
    currency: "xcd",
    regionCode: 2
  },
  {
    name: "Morocco",
    isoCode: "MA",
    currency: "mad",
    regionCode: 5
  },
  {
    name: "Mozambique",
    isoCode: "MZ",
    currency: "mzn",
    regionCode: 6
  },
  {
    name: "Myanmar",
    isoCode: "MM",
    currency: "mmk",
    regionCode: 9
  },
  {
    name: "Namibia",
    isoCode: "NA",
    currency: "nad",
    regionCode: 6
  },
  {
    name: "Nauru",
    isoCode: "NR",
    currency: "aud",
    regionCode: 10
  },
  {
    name: "Nepal",
    isoCode: "NP",
    currency: "npr",
    regionCode: 7
  },
  {
    name: "Netherlands",
    isoCode: "NL",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "New Caledonia",
    isoCode: "NC",
    currency: "xpf",
    regionCode: 10
  },
  {
    name: "New Zealand",
    isoCode: "NZ",
    currency: "nzd",
    regionCode: 10
  },
  {
    name: "Niger",
    isoCode: "NE",
    currency: "xof",
    regionCode: 6
  },
  {
    name: "Nigeria",
    isoCode: "NG",
    currency: "ngn",
    regionCode: 6
  },
  {
    name: "Niue",
    isoCode: "NU",
    currency: "nzd",
    regionCode: 10
  },
  {
    name: "Norfolk Island",
    isoCode: "NF",
    currency: "aud",
    regionCode: 10
  },
  {
    name: "Northern Mariana Islands",
    isoCode: "MP",
    currency: "usd",
    regionCode: 9
  },
  {
    name: "Norway",
    isoCode: "NO",
    currency: "nok",
    regionCode: 3
  },
  {
    name: "Oman",
    isoCode: "OM",
    currency: "omr",
    regionCode: 5
  },
  {
    name: "Pakistan",
    isoCode: "PK",
    currency: "pkr",
    regionCode: 7
  },
  {
    name: "Palau",
    isoCode: "PW",
    currency: "usd",
    regionCode: 9
  },
  {
    name: "Panama",
    isoCode: "PA",
    currency: "pab",
    regionCode: 2
  },
  {
    name: "Papua new Guinea",
    isoCode: "PG",
    currency: "pgk",
    regionCode: 10
  },
  {
    name: "Paraguay",
    isoCode: "PY",
    currency: "pyg",
    regionCode: 2
  },
  {
    name: "Peru",
    isoCode: "PE",
    currency: "pen",
    regionCode: 2
  },
  {
    name: "Philippines",
    isoCode: "PH",
    currency: "php",
    regionCode: 9
  },
  {
    name: "Pitcairn Island",
    isoCode: "PN",
    currency: "nzd",
    regionCode: 10
  },
  {
    name: "Poland",
    isoCode: "PL",
    currency: "pln",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Portugal",
    isoCode: "PT",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Puerto Rico",
    isoCode: "PR",
    currency: "usd",
    regionCode: 2
  },
  {
    name: "Qatar",
    isoCode: "QA",
    currency: "qar",
    regionCode: 5
  },
  {
    name: "Reunion",
    isoCode: "RE",
    currency: "eur",
    regionCode: 6
  },
  {
    name: "Romania",
    isoCode: "RO",
    currency: "ron",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Rwanda",
    isoCode: "RW",
    currency: "rwf",
    regionCode: 6
  },
  {
    name: "Saint Helena",
    isoCode: "SH",
    currency: "shp",
    regionCode: 6
  },
  {
    name: "Saint Kitts And Nevis",
    isoCode: "KN",
    currency: "xcd",
    regionCode: 2
  },
  {
    name: "Saint Lucia",
    isoCode: "LC",
    currency: "xcd",
    regionCode: null
  },
  {
    name: "Saint Pierre and Miquelon",
    isoCode: "PM",
    currency: "eur",
    regionCode: 1
  },
  {
    name: "Saint Vincent And The Grenadines",
    isoCode: "VC",
    currency: "xcd",
    regionCode: 2
  },
  {
    name: "Saint-Barthelemy",
    isoCode: "BL",
    currency: "eur",
    regionCode: null
  },
  {
    name: "Saint-Martin (French part)",
    isoCode: "MF",
    currency: "eur",
    regionCode: null
  },
  {
    name: "Samoa",
    isoCode: "WS",
    currency: "wst",
    regionCode: 10
  },
  {
    name: "San Marino",
    isoCode: "SM",
    currency: "eur",
    regionCode: 3
  },
  {
    name: "Sao Tome and Principe",
    isoCode: "ST",
    currency: "std",
    regionCode: 6
  },
  {
    name: "Saudi Arabia",
    isoCode: "SA",
    currency: "sar",
    regionCode: 5
  },
  {
    name: "Senegal",
    isoCode: "SN",
    currency: "xof",
    regionCode: 6
  },
  {
    name: "Seychelles",
    isoCode: "SC",
    currency: "scr",
    regionCode: 6
  },
  {
    name: "Sierra Leone",
    isoCode: "SL",
    currency: "sll",
    regionCode: 6
  },
  {
    name: "Singapore",
    isoCode: "SG",
    currency: "sgd",
    regionCode: 9
  },
  {
    name: "Sint Maarten (Dutch part)",
    isoCode: "SX",
    currency: "ang",
    regionCode: 2
  },
  {
    name: "Slovakia",
    isoCode: "SK",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Slovenia",
    isoCode: "SI",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Solomon Islands",
    isoCode: "SB",
    currency: "sbd",
    regionCode: 10
  },
  {
    name: "Somalia",
    isoCode: "SO",
    currency: "sos",
    regionCode: 6
  },
  {
    name: "South Africa",
    isoCode: "ZA",
    currency: "zar",
    regionCode: 6
  },
  {
    name: "South Georgia",
    isoCode: "GS",
    currency: "gbp",
    regionCode: null
  },
  {
    name: "South Korea",
    isoCode: "KR",
    currency: "krw",
    regionCode: 8
  },
  {
    name: "Spain",
    isoCode: "ES",
    currency: "eur",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Sri Lanka",
    isoCode: "LK",
    currency: "lkr",
    regionCode: 7
  },
  {
    name: "Suriname",
    isoCode: "SR",
    currency: "srd",
    regionCode: 2
  },
  {
    name: "Svalbard And Jan Mayen Islands",
    isoCode: "SJ",
    currency: "nok",
    regionCode: 3
  },
  {
    name: "Swaziland",
    isoCode: "SZ",
    currency: "szl",
    regionCode: 6
  },
  {
    name: "Sweden",
    isoCode: "SE",
    currency: "sek",
    isEuropeanUnion: true,
    regionCode: 3
  },
  {
    name: "Switzerland",
    isoCode: "CH",
    currency: "chf",
    regionCode: 3
  },
  {
    name: "Taiwan",
    isoCode: "TW",
    currency: "twd",
    regionCode: 8
  },
  {
    name: "Tajikistan",
    isoCode: "TJ",
    currency: "tjs",
    regionCode: 4
  },
  {
    name: "Tanzania",
    isoCode: "TZ",
    currency: "tzs",
    regionCode: 6
  },
  {
    name: "Thailand",
    isoCode: "TH",
    currency: "thb",
    regionCode: 9
  },
  {
    name: "The Bahamas",
    isoCode: "BS",
    currency: "bsd",
    regionCode: 2
  },
  {
    name: "Togo",
    isoCode: "TG",
    currency: "xof",
    regionCode: 6
  },
  {
    name: "Tokelau",
    isoCode: "TK",
    currency: "nzd",
    regionCode: 10
  },
  {
    name: "Tonga",
    isoCode: "TO",
    currency: "top",
    regionCode: 10
  },
  {
    name: "Trinidad And Tobago",
    isoCode: "TT",
    currency: "ttd",
    regionCode: 2
  },
  {
    name: "Tunisia",
    isoCode: "TN",
    currency: "tnd",
    regionCode: 5
  },
  {
    name: "Turkey",
    isoCode: "TR",
    currency: "try",
    regionCode: 5
  },
  {
    name: "Turkmenistan",
    isoCode: "TM",
    currency: "tmt",
    regionCode: 4
  },
  {
    name: "Turks And Caicos Islands",
    isoCode: "TC",
    currency: "usd",
    regionCode: 2
  },
  {
    name: "Tuvalu",
    isoCode: "TV",
    currency: "aud",
    regionCode: 10
  },
  {
    name: "Uganda",
    isoCode: "UG",
    currency: "ugx",
    regionCode: 6
  },
  {
    name: "Ukraine",
    isoCode: "UA",
    currency: "uah",
    regionCode: 3
  },
  {
    name: "United Arab Emirates",
    isoCode: "AE",
    currency: "aed",
    regionCode: 5
  },
  {
    name: "United Kingdom",
    isoCode: "GB",
    currency: "gbp",
    regionCode: 3
  },
  {
    name: "United States",
    isoCode: "US",
    currency: "usd",
    regionCode: 1
  },
  {
    name: "United States Minor Outlying Islands",
    isoCode: "UM",
    currency: "usd",
    regionCode: null
  },
  {
    name: "Uruguay",
    isoCode: "UY",
    currency: "uyu",
    regionCode: 2
  },
  {
    name: "Uzbekistan",
    isoCode: "UZ",
    currency: "uzs",
    regionCode: 4
  },
  {
    name: "Vanuatu",
    isoCode: "VU",
    currency: "vuv",
    regionCode: 10
  },
  {
    name: "Vatican City State (Holy See)",
    isoCode: "VA",
    currency: "eur",
    regionCode: 3
  },
  {
    name: "Vietnam",
    isoCode: "VN",
    currency: "vnd",
    regionCode: 9
  },
  {
    name: "Virgin Islands (British)",
    isoCode: "VG",
    currency: "usd",
    regionCode: 2
  },
  {
    name: "Virgin Islands (US)",
    isoCode: "VI",
    currency: "usd",
    regionCode: 2
  },
  {
    name: "Wallis And Futuna Islands",
    isoCode: "WF",
    currency: "xpf",
    regionCode: 10
  },
  {
    name: "Western Sahara",
    isoCode: "EH",
    currency: "mad",
    regionCode: 5
  },
  {
    name: "Zambia",
    isoCode: "ZM",
    currency: "zmw",
    regionCode: 6
  }
];
