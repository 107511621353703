import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IMarkAsPaidOwnProps, IMarkAsPaidProps } from ".";
import { markAsPaid as markAsPaidAction } from "../../../../../store/billing/actions";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ billing: { markAsPaid } }: IAppState) {
  return { markAsPaid };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    markAsPaid: {
      onMarkAsPaid: (invoiceId: string) => dispatch(markAsPaidAction(invoiceId))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: IMarkAsPaidOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    markAsPaid: { ...stateProps.markAsPaid, ...dispatchProps.markAsPaid }
  } as IMarkAsPaidProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
