import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

export interface INavLinkProps {
  disabled?: boolean;
  active?: boolean;
  className?: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export class NavLink extends React.Component<INavLinkProps> {
  constructor(props: INavLinkProps) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  private handleOnClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (this.props.disabled) {
      e.preventDefault();
      return;
    }

    if (this.props.href === "#") {
      e.preventDefault();
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  public render() {
    const { className, active, disabled } = this.props;

    const classes = classNames(
      className,
      styles.navLink,
      disabled && styles.disabled,
      active && styles.active
    );
    const emptyHref: string = undefined!;
    return (
      <a onClick={this.handleOnClick} className={classes} href={emptyHref}>
        {this.props.children}
      </a>
    );
  }
}
