import React from "react";
import ModalNote from "../../../../components/modalNote";
import { ICancelBookingModalProps as IApproveBookingModalProps } from "../../../../store/modal/model";
import eventTracker from "../../../../utilities/eventTracker";
import { hasActionCompleted } from "../../../../utilities/helpers";
import withModal, { InjectedModalProps } from "../../withModal";
import ModalDialog from "../modalDialog";
import styles from "./index.module.scss";
import { IApprove } from "./model";
import withStore from "./withStore";

interface IApproveBookingProps extends InjectedModalProps<IApproveBookingModalProps> {
  approve: IApprove;
}

interface IApproveBookingState {
  note: string;
}

class ApproveBooking extends React.Component<IApproveBookingProps, IApproveBookingState> {
  public state: IApproveBookingState = { note: "" };

  public componentDidUpdate(prevProps: IApproveBookingProps) {
    const approveHasCompleted = hasActionCompleted(prevProps.approve.status, this.props.approve.status);
    if (approveHasCompleted) {
      this.props.onHideModal();
    }
  }

  public render() {
    const { note } = this.state;
    const approvePending = this.props.approve.status === "Pending";

    return (
      <ModalDialog
        title="Approve photoshoot request"
        okText="Approve Request"
        cancelText="Close"
        onCancel={this.onCancel}
        onOk={this.onOk}
        okPending={approvePending}
      >
        <div className={styles.container}>
          <div className={styles.text}>Great news! Approving this request will add it to your calendar.</div>
          <ModalNote className={styles.note} note={note} onChange={this.onNoteChange} />
        </div>
      </ModalDialog>
    );
  }

  private onNoteChange = (note: string) => this.setState({ note });

  private onCancel = () => this.props.onHideModal();

  private onOk = () => {
    eventTracker.events.photographer.bookings.bookingApproved(this.props.bookingId);
    this.props.approve.onApprove(this.props.bookingId, this.state.note);
  };
}

export default withModal(withStore(ApproveBooking));
