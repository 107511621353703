export const calculateIdxToLoad = (total: number, currentIdx = 0, loadNumber = 5) => {
  const start = currentIdx - loadNumber > 0 ? currentIdx - loadNumber : 0;
  const end = currentIdx + loadNumber < total - 1 ? currentIdx + loadNumber : total - 1;
  const idxList = [];
  for (let i = start; i <= end; i++) {
    idxList.push(i);
  }

  return idxList;
};
