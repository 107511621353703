export enum StripeActionType {
  SETUP_STRIPE_STARTED = "SETUP_STRIPE_STARTED",
  SETUP_STRIPE_SUCCESS = "SETUP_STRIPE_SUCCESS",
  SETUP_STRIPE_ERROR = "SETUP_STRIPE_ERROR",

  LOAD_STRIPE_SETTINGS_STARTED = "LOAD_STRIPE_SETTINGS_STARTED",
  LOAD_STRIPE_SETTINGS_SUCCESS = "LOAD_STRIPE_SETTINGS_SUCCESS",
  LOAD_STRIPE_SETTINGS_ERROR = "LOAD_STRIPE_SETTINGS_ERROR",

  TOGGLE_STIPE_APPLE_PAY_STARTED = "TOGGLE_STIPE_APPLE_PAY_STARTED",
  TOGGLE_STIPE_APPLE_PAY_SUCCESS = "TOGGLE_STIPE_APPLE_PAY_SUCCESS",
  TOGGLE_STIPE_APPLE_PAY_ERROR = "TOGGLE_STIPE_APPLE_PAY_ERROR",

  UPDATE_STATEMENT_DESCRIPTOR_STARTED = "UPDATE_STATEMENT_DESCRIPTOR_STARTED",
  UPDATE_STATEMENT_DESCRIPTOR_SUCCESS = "UPDATE_STATEMENT_DESCRIPTOR_SUCCESS",
  UPDATE_STATEMENT_DESCRIPTOR_ERROR = "UPDATE_STATEMENT_DESCRIPTOR_ERROR",

  DISCONNECT_STRIPE_STARTED = "DISCONNECT_STRIPE_STARTED",
  DISCONNECT_STRIPE_SUCCESS = "DISCONNECT_STRIPE_SUCCESS",
  DISCONNECT_STRIPE_ERROR = "DISCONNECT_STRIPE_ERROR",

  GET_PAYMENT_DASHBOARD_LINK_STARTED = "GET_PAYMENT_DASHBOARD_LINK_STARTED",
  GET_PAYMENT_DASHBOARD_LINK_SUCCESS = "GET_PAYMENT_DASHBOARD_LINK_SUCCESS",
  GET_PAYMENT_DASHBOARD_LINK_ERROR = "GET_PAYMENT_DASHBOARD_LINK_ERROR"
}
