import React, { PureComponent } from "react";
import cx from "classnames";
import styles from "./phone.module.scss";
import PhoneInput, { Country } from "react-phone-number-input";
import enhanceWithClickOutside from "react-click-outside";
import { CountryManager } from "utilities/country";

export interface IPhoneFormGroupProps {
  onBlur?: () => void;
  onChangeNumber: (value: string) => void;
  value: string;
  numberError: string;
  inputClassName?: string;
  placeholder?: string;
  className?: string;
  international?: boolean;
  defaultCountry?: string;
}

class PhoneFormGroup extends PureComponent<IPhoneFormGroupProps> {
  public handleClickOutside = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };
  public render() {
    const { value, inputClassName, placeholder, international, defaultCountry, onChangeNumber, className } = this.props;
    const countries = CountryManager.getAllCountries(true).map(c => c.isoCode) as Country[];

    return (
      <PhoneInput
        className={cx(styles.phone, className, { [styles.local]: !international })}
        numberInputProps={{ className: inputClassName || "" }}
        placeholder={placeholder || ""}
        value={value}
        limitMaxLength={true}
        onChange={(value: string) => onChangeNumber(value)}
        international={international}
        countryCallingCodeEditable={false}
        showCountrySelect={false}
        defaultCountry={(defaultCountry as Country) || "US"}
        addInternationalOption={false}
        countries={countries}
      />
    );
  }
}

export default enhanceWithClickOutside(PhoneFormGroup);
