import ShareIcon from "components/Icons/Share";
import React from "react";
import styles from "./share.module.scss";

interface IShareProps {
  onShareClick?: () => void;
}

const Share = (props: IShareProps) => {
  return (
    <div className={styles.shareContainer} onClick={props.onShareClick}>
      <span className={styles.text}>Share</span>
      <ShareIcon width={32} height={32} />
    </div>
  );
};

export default Share;
