import { Utilities } from "@zenfolio/core-components";
import { push } from "connected-react-router";
import _ from "lodash";
import { Dispatch } from "redux";
import { IApiServices } from "../../api";
import { ILoadServiceDetailsRequest, IToggleServiceStateRequest } from "../../api/services";
import UrlConstants from "../../components/router/constants";
import IError from "../../utilities/error";
import { stringsAreEqualIgnoreCase } from "../../utilities/helpers";
import { addNotification, NotificationType } from "../notification/actions";
import { IShootType } from "../shootTypes/model";
import { IAppState } from "../state";
import { ServiceActionType } from "./constants";
import { IServiceCreator, IServiceDetailsApi, IServiceItem, IServiceUpdater } from "./model";

export interface IFetchServicesStarted {
  type: ServiceActionType.LOAD_SERVICES_STARTED;
}

export interface IFetchServicesSuccess {
  type: ServiceActionType.LOAD_SERVICES_SUCCESS;
  filter?: string;
  services: IServiceItem[];
}

export interface IServicesFetchError {
  type: ServiceActionType.LOAD_SERVICES_ERROR;
  error: IError;
}

export type ServicesFetchAction = IFetchServicesStarted | IFetchServicesSuccess | IServicesFetchError;

const fetchServicesStarted = (): IFetchServicesStarted => ({
  type: ServiceActionType.LOAD_SERVICES_STARTED
});

const fetchServicesSuccess = (services: IServiceItem[], filter?: string): IFetchServicesSuccess => ({
  type: ServiceActionType.LOAD_SERVICES_SUCCESS,
  filter,
  services
});

const fetchServicesError = (error: IError) => ({
  type: ServiceActionType.LOAD_SERVICES_ERROR,
  error
});

export const doFetchServices = (filter?: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(fetchServicesStarted());
    try {
      const response = await apiService.services.fetchServices({ name: filter });
      dispatch(fetchServicesSuccess(response.services, filter));
    } catch (error) {
      dispatch(fetchServicesError(error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface ILoadDetailsStarted {
  type: ServiceActionType.LOAD_SERVICE_DETAILS_STARTED;
  serviceId: string;
}

interface ILoadServiceDetailsSuccess {
  type: ServiceActionType.LOAD_SERVICE_DETAILS_SUCCESS;
  serviceId: string;
  service: IServiceDetailsApi;
}

interface ILoadServiceDetailsError {
  type: ServiceActionType.LOAD_SERVICE_DETAILS_ERROR;
  serviceId: string;
  error: IError;
}

interface IResetServiceDetails {
  type: ServiceActionType.RESET_SERVICE_DETAILS_DETAILS;
}

export type LoadServiceDetailsAction =
  | ILoadDetailsStarted
  | ILoadServiceDetailsSuccess
  | ILoadServiceDetailsError
  | IResetServiceDetails;

const loadDetailsStarted = (serviceId: string): ILoadDetailsStarted => ({
  type: ServiceActionType.LOAD_SERVICE_DETAILS_STARTED,
  serviceId
});

const loadDetailsSuccess = (serviceId: string, service: IServiceDetailsApi): ILoadServiceDetailsSuccess => ({
  type: ServiceActionType.LOAD_SERVICE_DETAILS_SUCCESS,
  serviceId,
  service
});

const loadDetailsError = (serviceId: string, error: IError): ILoadServiceDetailsError => ({
  type: ServiceActionType.LOAD_SERVICE_DETAILS_ERROR,
  serviceId,
  error
});

export const doLoadServiceDetails = (request: ILoadServiceDetailsRequest) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadDetailsStarted(request.photographerServiceId));

    try {
      const serviceDetails = (await apiService.services.loadDetails(request)).serviceDetails;
      if (
        serviceDetails.shootType &&
        serviceDetails.originalShootType &&
        stringsAreEqualIgnoreCase(serviceDetails.shootType.name, serviceDetails.originalShootType.name)
      ) {
        serviceDetails.shootType.name = serviceDetails.originalShootType.name;
      }

      dispatch(loadDetailsSuccess(request.photographerServiceId, serviceDetails));
    } catch (error) {
      dispatch(loadDetailsError(request.photographerServiceId, error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

const resetServiceDetails = () => ({
  type: ServiceActionType.RESET_SERVICE_DETAILS_DETAILS
});

export const doResetServiceDetails = () => {
  return (dispatch: Dispatch) => {
    dispatch(resetServiceDetails());
  };
};

interface IUpdateServiceDetailsStarted {
  type: ServiceActionType.UPDATE_SERVICE_DETAILS_STARTED;
  serviceId: string;
  updater: IServiceUpdater;
}

interface IUpdateServiceDetailsSuccess {
  type: ServiceActionType.UPDATE_SERVICE_DETAILS_SUCCESS;
  serviceId: string;
  updater: IServiceUpdater;
}

interface IUpdateServiceDetailsError {
  type: ServiceActionType.UPDATE_SERVICE_DETAILS_ERROR;
  serviceId: string;
  error: IError;
}

export type UpdateServiceDetailsAction =
  | IUpdateServiceDetailsStarted
  | IUpdateServiceDetailsSuccess
  | IUpdateServiceDetailsError;

const updateServiceDetailsStarted = (serviceId: string, updater: IServiceUpdater): IUpdateServiceDetailsStarted => ({
  type: ServiceActionType.UPDATE_SERVICE_DETAILS_STARTED,
  serviceId,
  updater
});

const updateServiceDetailsSuccess = (serviceId: string, updater: IServiceUpdater): IUpdateServiceDetailsSuccess => ({
  type: ServiceActionType.UPDATE_SERVICE_DETAILS_SUCCESS,
  serviceId,
  updater
});

const updateServiceDetailsError = (serviceId: string, error: IError): IUpdateServiceDetailsError => ({
  type: ServiceActionType.UPDATE_SERVICE_DETAILS_ERROR,
  serviceId,
  error
});

export const doUpdateDetails = (serviceId: string, updater: IServiceUpdater) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(updateServiceDetailsStarted(serviceId, updater));

    try {
      const serviceDetails = updater.serviceDetails.filter(value => !!Utilities.trim(value));

      const shootTypeId = updater.shootType!.id;
      await apiService.services.updateDetails({
        serviceId,
        ...updater,
        serviceDetails,
        shootTypeId
      });
      dispatch(updateServiceDetailsSuccess(serviceId, updater));
      dispatch(addNotification(`${updater.name} Saved!`, NotificationType.Success, true));
      dispatch(push(UrlConstants.services));
    } catch (error) {
      dispatch(updateServiceDetailsError(serviceId, error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface ICreateServiceStarted {
  type: ServiceActionType.CREATE_SERVICE_STARTED;
  creator: IServiceCreator;
}

interface ICreateServiceSuccess {
  type: ServiceActionType.CREATE_SERVICE_SUCCESS;
  serviceId: string;
  creator: IServiceCreator;
}

interface ICreateServiceError {
  type: ServiceActionType.CREATE_SERVICE_ERROR;
  error: IError;
}

export type CreateDetailsAction = ICreateServiceStarted | ICreateServiceSuccess | ICreateServiceError;

const createServiceStarted = (creator: IServiceCreator): ICreateServiceStarted => ({
  type: ServiceActionType.CREATE_SERVICE_STARTED,
  creator
});

const createServiceSuccess = (serviceId: string, creator: IServiceCreator): ICreateServiceSuccess => ({
  type: ServiceActionType.CREATE_SERVICE_SUCCESS,
  serviceId,
  creator
});

const createServiceError = (error: IError): ICreateServiceError => ({
  type: ServiceActionType.CREATE_SERVICE_ERROR,
  error
});

export const doCreateService = (creator: IServiceCreator) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(createServiceStarted(creator));

    try {
      const serviceDetails = _.compact(creator.serviceDetails);

      const shootTypeId = creator.shootType!.id;
      const response = await apiService.services.createService({
        ...creator,
        serviceDetails,
        shootTypeId
      });
      dispatch(createServiceSuccess(response.photographerServiceId, creator));
      dispatch(addNotification(`${creator.name} Saved!`, NotificationType.Success, true));
      dispatch(push(UrlConstants.services));
    } catch (error) {
      dispatch(createServiceError(error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface IDeleteServiceStarted {
  type: ServiceActionType.DELETE_SERVICE_STARTED;
  serviceId: string;
}

interface IDeleteServiceSuccess {
  type: ServiceActionType.DELETE_SERVICE_SUCCESS;
  serviceId: string;
}

interface IDeleteServiceError {
  type: ServiceActionType.DELETE_SERVICE_ERROR;
  serviceId: string;
  error: IError;
}

export type DeleteServiceAction = IDeleteServiceStarted | IDeleteServiceSuccess | IDeleteServiceError;

const deleteServiceStarted = (serviceId: string): IDeleteServiceStarted => ({
  type: ServiceActionType.DELETE_SERVICE_STARTED,
  serviceId
});

const deleteServiceSuccess = (serviceId: string): IDeleteServiceSuccess => ({
  type: ServiceActionType.DELETE_SERVICE_SUCCESS,
  serviceId
});

const deleteServiceError = (serviceId: string, error: IError): IDeleteServiceError => ({
  type: ServiceActionType.DELETE_SERVICE_ERROR,
  serviceId,
  error
});

export const doDeleteService = (photographerServiceId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(deleteServiceStarted(photographerServiceId));

    try {
      await apiService.services.deleteService({ photographerServiceId });
      dispatch(deleteServiceSuccess(photographerServiceId));
    } catch (error) {
      dispatch(deleteServiceError(photographerServiceId, error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface IToggleServiceStateStarted {
  type: ServiceActionType.TOGGLE_SERVICE_STATE_STARTED;
  serviceId: string;
}

interface IToggleServiceStateSuccess {
  type: ServiceActionType.TOGGLE_SERVICE_STATE_SUCCESS;
  serviceId: string;
  isEnabled: boolean;
}

interface IToggleServiceStateError {
  type: ServiceActionType.TOGGLE_SERVICE_STATE_ERROR;
  serviceId: string;
  error: IError;
}

export type ToggleServiceStateAction =
  | IToggleServiceStateStarted
  | IToggleServiceStateSuccess
  | IToggleServiceStateError;

const toggleServiceStateStarted = (serviceId: string): IToggleServiceStateStarted => ({
  type: ServiceActionType.TOGGLE_SERVICE_STATE_STARTED,
  serviceId
});

const toggleServiceStateSuccess = (serviceId: string, isEnabled: boolean): IToggleServiceStateSuccess => ({
  type: ServiceActionType.TOGGLE_SERVICE_STATE_SUCCESS,
  serviceId,
  isEnabled
});

const toggleServiceStateError = (serviceId: string, error: IError): IToggleServiceStateError => ({
  type: ServiceActionType.TOGGLE_SERVICE_STATE_ERROR,
  serviceId,
  error
});

export const doToggleServiceState = (request: IToggleServiceStateRequest) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(toggleServiceStateStarted(request.serviceId));
    try {
      await apiService.services.toggleServiceState(request);
      dispatch(toggleServiceStateSuccess(request.serviceId, request.isEnabled));
    } catch (error) {
      dispatch(toggleServiceStateError(request.serviceId, error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface IDuplicateServiceStarted {
  type: ServiceActionType.DUPLICATE_SERVICE_STARTED;
  serviceId: string;
}

interface IDuplicateServiceSuccess {
  type: ServiceActionType.DUPLICATE_SERVICE_SUCCESS;
  serviceId: string;
  duplicatedService: IServiceItem;
}

interface IDuplicateServiceError {
  type: ServiceActionType.DUPLICATE_SERVICE_ERROR;
  serviceId: string;
  error: IError;
}

export type DuplicateServiceAction = IDuplicateServiceStarted | IDuplicateServiceSuccess | IDuplicateServiceError;

const duplicateServiceStarted = (serviceId: string): IDuplicateServiceStarted => ({
  type: ServiceActionType.DUPLICATE_SERVICE_STARTED,
  serviceId
});

const duplicateServiceSuccess = (serviceId: string, duplicatedService: IServiceItem): IDuplicateServiceSuccess => ({
  type: ServiceActionType.DUPLICATE_SERVICE_SUCCESS,
  serviceId,
  duplicatedService
});

const duplicateServiceError = (serviceId: string, error: IError): IDuplicateServiceError => ({
  type: ServiceActionType.DUPLICATE_SERVICE_ERROR,
  serviceId,
  error
});

export const doDuplicateService = (photographerServiceId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(duplicateServiceStarted(photographerServiceId));

    try {
      const response = await apiService.services.duplicateService({ photographerServiceId });
      dispatch(duplicateServiceSuccess(photographerServiceId, response));
    } catch (error) {
      dispatch(duplicateServiceError(photographerServiceId, error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface IReorderServicesStarted {
  type: ServiceActionType.REORDER_SERVICES_STARTED;
  shootType: IShootType;
  originalShootType: IShootType;
  serviceId: string;
  serviceIds: string[];
}

interface IReorderServicesSuccess {
  type: ServiceActionType.REORDER_SERVICES_SUCCESS;
}

interface IReorderServicesError {
  type: ServiceActionType.REORDER_SERVICES_ERROR;
  originalServices: IServiceItem[];
  error: IError;
}

export type ReorderServicesAction = IReorderServicesStarted | IReorderServicesSuccess | IReorderServicesError;

const reorderServicesStarted = (
  shootType: IShootType,
  originalShootType: IShootType,
  serviceId: string,
  serviceIds: string[]
): IReorderServicesStarted => ({
  type: ServiceActionType.REORDER_SERVICES_STARTED,
  shootType,
  originalShootType,
  serviceId,
  serviceIds
});

const reorderServicesSuccess = (): IReorderServicesSuccess => ({
  type: ServiceActionType.REORDER_SERVICES_SUCCESS
});

const reorderServiceError = (originalServices: IServiceItem[], error: IError): IReorderServicesError => ({
  type: ServiceActionType.REORDER_SERVICES_ERROR,
  originalServices,
  error
});

export const doReorderServices = (
  shootType: IShootType,
  originalShootType: IShootType,
  serviceId: string,
  serviceIds: string[]
) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    const originalServices = getState().services.fetchState.services;

    const photographerServiceIds = _.sortBy(
      originalServices
        .slice()
        .filter(item => item.shootType.id === shootType.id || item.id === serviceId)
        .map(item => item.id),
      id => serviceIds.indexOf(id)
    );

    dispatch(reorderServicesStarted(shootType, originalShootType, serviceId, serviceIds));

    try {
      await apiService.services.reorderServices({
        shootTypeId: shootType.id,
        photographerServiceIds
      });
      dispatch(reorderServicesSuccess());
    } catch (error) {
      dispatch(reorderServiceError(originalServices, error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface IRenameShootTypeStarted {
  type: ServiceActionType.RENAME_SHOOT_TYPE_STARTED;
}

interface IRenameShootTypeSuccess {
  type: ServiceActionType.RENAME_SHOOT_TYPE_SUCCESS;
  shootType: IShootType;
  originalShootType: IShootType;
}

interface IRenameShootTypeError {
  type: ServiceActionType.RENAME_SHOOT_TYPE_ERROR;
  error: IError;
}

export type RenameShootTypeAction = IRenameShootTypeStarted | IRenameShootTypeSuccess | IRenameShootTypeError;

const renameShootTypeStarted = (): IRenameShootTypeStarted => ({
  type: ServiceActionType.RENAME_SHOOT_TYPE_STARTED
});

const renameShootTypeSuccess = (shootType: IShootType, originalShootType: IShootType): IRenameShootTypeSuccess => ({
  type: ServiceActionType.RENAME_SHOOT_TYPE_SUCCESS,
  shootType,
  originalShootType
});

const renameShootTypeError = (error: IError): IRenameShootTypeError => ({
  type: ServiceActionType.RENAME_SHOOT_TYPE_ERROR,
  error
});

export const doRenameShootType = (shootType: IShootType) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiShootType: IApiServices) => {
    dispatch(renameShootTypeStarted());

    try {
      const originalShootType = await apiShootType.shootTypes.renameShootType(shootType);
      dispatch(renameShootTypeSuccess(shootType, originalShootType));
      dispatch(addNotification(`${shootType.name} Saved!`, NotificationType.Success));
    } catch (error) {
      dispatch(renameShootTypeError(error));
    }
  };
};

interface IDeleteShootTypeStarted {
  type: ServiceActionType.DELETE_SHOOT_TYPE_STARTED;
  shootTypeId: string;
}

interface IDeleteShootTypeSuccess {
  type: ServiceActionType.DELETE_SHOOT_TYPE_SUCCESS;
  shootTypeId: string;
}

interface IDeleteShootTypeError {
  type: ServiceActionType.DELETE_SHOOT_TYPE_ERROR;
  originalServices: IServiceItem[];
  shootTypeId: string;
  error: IError;
}

export type DeleteShootTypeAction = IDeleteShootTypeStarted | IDeleteShootTypeSuccess | IDeleteShootTypeError;

const deleteShootTypeStarted = (shootTypeId: string): IDeleteShootTypeStarted => ({
  type: ServiceActionType.DELETE_SHOOT_TYPE_STARTED,
  shootTypeId
});

const deleteShootTypeSuccess = (shootTypeId: string): IDeleteShootTypeSuccess => ({
  type: ServiceActionType.DELETE_SHOOT_TYPE_SUCCESS,
  shootTypeId
});

const deleteShootTypeError = (
  originalServices: IServiceItem[],
  shootTypeId: string,
  error: IError
): IDeleteShootTypeError => ({
  type: ServiceActionType.DELETE_SHOOT_TYPE_ERROR,
  originalServices,
  shootTypeId,
  error
});

export const doDeleteShootType = (shootTypeId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiShootType: IApiServices) => {
    const originalServices = getState().services.fetchState.services;
    dispatch(deleteShootTypeStarted(shootTypeId));
    try {
      await apiShootType.shootTypes.deleteShootType({ shootTypeId });
      dispatch(deleteShootTypeSuccess(shootTypeId));
    } catch (error) {
      dispatch(deleteShootTypeError(originalServices, shootTypeId, error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface IReorderShootTypesStarted {
  type: ServiceActionType.REORDER_SHOOT_TYPES_STARTED;
  shootTypeIds: string[];
}

interface IReorderShootTypesSuccess {
  type: ServiceActionType.REORDER_SHOOT_TYPES_SUCCESS;
}

interface IReorderShootTypesError {
  type: ServiceActionType.REORDER_SHOOT_TYPES_ERROR;
  originalServices: IServiceItem[];
  error: IError;
}

export type ReorderShootTypesAction = IReorderShootTypesStarted | IReorderShootTypesSuccess | IReorderShootTypesError;

const reorderShootTypesStarted = (shootTypeIds: string[]): IReorderShootTypesStarted => ({
  type: ServiceActionType.REORDER_SHOOT_TYPES_STARTED,
  shootTypeIds
});

const reorderShootTypesSuccess = (): IReorderShootTypesSuccess => ({
  type: ServiceActionType.REORDER_SHOOT_TYPES_SUCCESS
});

const reorderShootTypeError = (originalServices: IServiceItem[], error: IError): IReorderShootTypesError => ({
  type: ServiceActionType.REORDER_SHOOT_TYPES_ERROR,
  originalServices,
  error
});

export const doReorderShootTypes = (shootTypeIds: string[]) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiShootType: IApiServices) => {
    const originalServices = getState().services.fetchState.services;
    dispatch(reorderShootTypesStarted(shootTypeIds));

    try {
      await apiShootType.shootTypes.reorderShootTypes({ shootTypeIds });
      dispatch(reorderShootTypesSuccess());
    } catch (error) {
      dispatch(reorderShootTypeError(originalServices, error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};
