import { IZenSiteTheme } from "../../../../models/models";
import ZenLoading from "components/blocks/zenLoading";
import _ from "lodash";
import React, { useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import usePrevious from "../../../../hooks/usePrevious";
import useWindowLoad from "../../../../hooks/useWindowLoad";
import useWindowSize from "../../../../hooks/useWindowSize";
import { compactLayoutWidth, IBlogPostInfo } from "../model";
import BlogPost from "./BlogPost";
import styles from "./blogPosts.module.scss";
import { IPhotoFull } from "utilities/getImgUrl";

interface IBlogPostsProps {
  siteTheme: IZenSiteTheme;
  photoUrlTemplate: string;
  blogPosts: IBlogPostInfo[];
  photosMap: Record<string, IPhotoFull | null>;
  loading?: boolean;
  onSelectBlogPost?: (blogPost: IBlogPostInfo) => void;
  buildBlogPostUrl?: (blogPost: IBlogPostInfo) => string;
}

export interface IBlogPosts {
  scrollIntoView(): void;
}

const columnsCount = 2;

const BlogPosts = React.forwardRef((props: IBlogPostsProps, ref: React.Ref<IBlogPosts>) => {
  const { siteTheme, photoUrlTemplate, blogPosts, photosMap, loading, onSelectBlogPost, buildBlogPostUrl } = props;

  useImperativeHandle(ref, () => ({ scrollIntoView }));

  const containerRef = useRef<HTMLDivElement>(null);
  const [blogPostsColumns, setBlogPostsColumns] = useState<Record<string, number>>({});
  const windowSize = useWindowSize();
  const prevWindowSize = usePrevious(windowSize);
  const prevBlogPosts = usePrevious(blogPosts);
  const prevPhotosMap = usePrevious(photosMap);
  useWindowLoad(refreshBlogPostsColumns);

  useLayoutEffect(onComponentUpdate);

  function onComponentUpdate() {
    if (prevBlogPosts !== blogPosts || prevPhotosMap !== photosMap || !_.isEqual(prevWindowSize, windowSize)) {
      refreshBlogPostsColumns();
    }
  }

  function scrollIntoView() {
    containerRef.current?.scrollIntoView({
      block: "start",
      behavior: "smooth"
    });
  }

  function refreshBlogPostsColumns() {
    if (!containerRef.current) {
      return;
    }

    const blogPostsColumns: Record<string, number> = {};

    if (windowSize.width > compactLayoutWidth) {
      const columnHeights = _.fill(Array<number>(columnsCount), 0);
      const columnsMap = _.keyBy(
        containerRef.current.getElementsByClassName(styles.blogPost),
        bp => (bp as HTMLDivElement).dataset.blogPostId
      );

      for (const blogPost of blogPosts) {
        const minColumnHeight = _.min(columnHeights);
        const minColumnIndex = _.indexOf(columnHeights, minColumnHeight);

        columnHeights[minColumnIndex] += (columnsMap[blogPost.id] as HTMLDivElement).clientHeight;
        blogPostsColumns[blogPost.id] = minColumnIndex;
      }
    } else {
      for (const blogPost of blogPosts) {
        blogPostsColumns[blogPost.id] = 0;
      }
    }

    setBlogPostsColumns(blogPostsColumns);
  }

  function renderColumn(column: number) {
    return (
      <div key={column} className={styles.column}>
        {_.chain(blogPosts)
          .filter(
            (blogPost, index) =>
              (!blogPostsColumns.hasOwnProperty(blogPost.id) && index % columnsCount === column) ||
              blogPostsColumns[blogPost.id] === column
          )
          .map(blogPost => (
            <BlogPost
              key={blogPost.id}
              className={styles.blogPost}
              siteTheme={siteTheme}
              photoUrlTemplate={photoUrlTemplate}
              blogPost={blogPost}
              photoInfo={photosMap[blogPost.coverPhotoId] || undefined}
              onSelect={onSelectBlogPost}
              buildUrl={buildBlogPostUrl}
            />
          ))
          .value()}
      </div>
    );
  }

  return (
    <div className={styles.container} ref={containerRef}>
      {_.map(_.range(0, columnsCount), column => renderColumn(column))}
      {loading && <ZenLoading siteTheme={siteTheme} spinnerClassName={styles.spinner} />}
    </div>
  );
});

export default BlogPosts;
