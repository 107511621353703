import * as React from "react";
import cx from "classnames";

import styles from "./videoDuration.module.scss";

export function formatDuration(duration: number): string {
  duration = Math.round(duration / 1000);
  const minutes = Math.floor(duration / 60);
  const seconds = duration - minutes * 60;
  return `${minutes}:${String(seconds).padStart(2, "0")}`;
}

interface IVideoDurationProps {
  className?: string;
  style?: React.CSSProperties;
  durationMilliseconds: number;
}

const VideoDuration: React.FC<IVideoDurationProps> = props => {
  return (
    <div className={cx(styles.container, props.className)} style={props.style}>
      {formatDuration(props.durationMilliseconds)}
    </div>
  );
};

export default VideoDuration;
