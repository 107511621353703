import classNames from "classnames";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { hideModal } from "../../../../store/modal/actions";
import { IModalState } from "../../../../store/modal/reducer";
import { IAppState } from "../../../../store/state";
import ModalDialog from "../modalDialog";
import styles from "./index.module.scss";

interface IConfirmationProps {
  modal: IModalState;
  onHideModal: () => void;
}

class Confirmation extends PureComponent<IConfirmationProps> {
  public render() {
    const { modal } = this.props;
    return (
      <ModalDialog
        okWhenClosed={modal.modalProps.okWhenClosed}
        className={classNames(styles.confirmation, modal.modalProps.className)}
        title={modal.modalProps.title}
        cancelText={modal.modalProps.cancelText}
        okText={modal.modalProps.okText}
        onCancel={this.onCancel}
        onOk={this.onOk}
      >
        {modal.modalProps.message}
      </ModalDialog>
    );
  }

  private onClose = () => {
    this.props.onHideModal();
  };

  private onCancel = () => {
    if (this.props.modal.modalProps.onCancel) {
      this.props.modal.modalProps.onCancel();
    }

    this.onClose();
  };

  private onOk = () => {
    if (this.props.modal.modalProps.onOk) {
      this.props.modal.modalProps.onOk();
    }

    this.onClose();
  };
}

const mapStateToProps = (state: IAppState) => ({
  modal: state.modal
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onHideModal: () => dispatch(hideModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
