import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import PageHeader from "../../../components/layout/header";
import { doFetchServices } from "../../../store/services/actions";
import { IAppState } from "../../../store/state";

function mapStateToProps(state: IAppState) {
  return {};
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    onChangeSearchInput: (value: string) => dispatch(doFetchServices(value)),
    onClearSearchInput: () => dispatch(doFetchServices())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
