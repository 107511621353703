import moment from "moment";

export function formatPublishDate(publishDate?: string) {
  return publishDate
    ? moment
        .utc(publishDate)
        .local()
        .format("MMMM D, YYYY")
    : "";
}
