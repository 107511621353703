import React from "react";

interface IPresentsSVGProps {
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  color?: string;
}

function PresentsSVG(props: IPresentsSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width}
      height={props.height}
      viewBox={`${props.left} ${props.top} ${props.width} ${props.height}`} // origin: 0 0 427 393
    >
      <defs>
        <filter id="prefix__a" width="131.7%" height="131.7%" x="-15.9%" y="-15.9%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="12"/>
        </filter>
        <filter id="prefix__b" width="131.7%" height="131.7%" x="-15.9%" y="-15.9%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="7.2"/>
        </filter>
        <filter id="prefix__c" width="105.2%" height="105.2%" x="-2.6%" y="-2.6%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2"/>
        </filter>
        <filter id="prefix__e" width="136%" height="136.7%" x="-18%" y="-18.4%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="8"/>
        </filter>
        <filter id="prefix__g" width="108.7%" height="108.7%" x="-4.3%" y="-4.4%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2"/>
        </filter>
        <filter id="prefix__i" width="115%" height="115.3%" x="-7.5%" y="-7.7%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2"/>
        </filter>
        <path
          fill={props.color}
          id="prefix__d"
          d="M126 0a1.2 1.2 0 011.2 1.2l.001 76.664c16.262-19.217 26.99-29.475 32.184-30.774 9.6-2.4 23.068 13.026 18 22.8-2.864 5.525-15.663 16.428-38.396 32.71H229.2a1.2 1.2 0 011.2 1.2v20.4a1.2 1.2 0 01-1.2 1.2l-81.537.002C167.5 142.12 178.08 153.119 179.4 158.4c2.4 9.6-13.026 23.068-22.8 18-5.141-2.666-14.94-13.935-29.4-33.808V228a1.2 1.2 0 01-1.2 1.2h-20.4a1.2 1.2 0 01-1.2-1.2v-86.057c-15.452 18.119-25.697 27.808-30.735 29.067-9.6 2.4-23.068-13.025-18-22.8 2.238-4.317 10.545-11.92 24.92-22.809L1.2 125.4a1.2 1.2 0 01-1.2-1.2v-20.4a1.2 1.2 0 011.2-1.2h78.53C58.897 85.153 47.804 73.72 46.45 68.305c-2.4-9.6 13.026-23.068 22.8-18 5.796 3.005 17.513 16.945 35.15 41.82V1.2a1.2 1.2 0 011.2-1.2H126zm1.201 130.03v2.706c18.328 25.192 28.928 38.146 31.799 38.864 2.4 1.2 6 0 4.8-3.6-.735-2.204-12.934-14.86-36.599-37.97zm-66.736 20.58c-1.2 2.4 0 6 3.6 4.8 1.989-.663 12.494-10.666 31.515-30.01l-5.231.001c-19.296 14.299-29.257 22.702-29.884 25.21zm108.52-87.92c-2.304.768-16.037 14.072-41.2 39.91h1.37c28.193-20.366 42.67-32.07 43.43-35.11 1.2-2.4 0-6-3.6-4.8zM62.05 58.705c.792 2.376 14.909 16.9 42.35 43.574v-.356C82.526 71.5 70.01 55.895 66.85 55.105c-2.4-1.2-6 0-4.8 3.6z"
        />
        <path
          fill={props.color}
          id="prefix__h"
          d="M75.6 0a.72.72 0 01.72.72v45.998c9.758-11.53 16.195-17.685 19.311-18.464 5.76-1.44 13.841 7.816 10.8 13.68-1.719 3.315-9.398 9.857-23.037 19.626h54.126a.72.72 0 01.72.72v12.24a.72.72 0 01-.72.72H88.597c11.903 10.032 18.25 16.631 19.043 19.8 1.44 5.76-7.815 13.84-13.68 10.8-3.085-1.6-8.964-8.36-17.64-20.284V136.8a.72.72 0 01-.72.72H63.36a.72.72 0 01-.72-.72V85.165c-9.27 10.872-15.418 16.685-18.44 17.441-5.76 1.44-13.841-7.815-10.8-13.68 1.343-2.59 6.327-7.152 14.952-13.685L.72 75.24a.72.72 0 01-.72-.72V62.28a.72.72 0 01.72-.72l47.12.001C35.339 51.092 28.682 44.233 27.87 40.983c-1.44-5.76 7.815-13.84 13.68-10.8 3.478 1.803 10.508 10.168 21.09 25.093V.72a.72.72 0 01.72-.72H75.6zm.72 78.017v1.625c10.998 15.115 17.357 22.887 19.08 23.318 1.44.72 3.6 0 2.88-2.16-.44-1.322-7.76-8.916-21.96-22.783zm-40.04 12.35c-.72 1.44 0 3.6 2.16 2.88 1.193-.399 7.496-6.4 18.908-18.006H54.21C42.632 83.82 36.655 88.86 36.28 90.366zm65.112-52.753c-1.382.461-9.622 8.443-24.72 23.946h.82c16.918-12.22 25.604-19.242 26.06-21.066.72-1.44 0-3.6-2.16-2.88zm-64.161-2.39c.475 1.424 8.945 10.14 25.41 26.144v-.214C49.516 42.9 42.006 33.537 40.11 33.064c-1.44-.72-3.6 0-2.88 2.16z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(15 27)">
        <rect
          width="226.8"
          height="226.8"
          x="55"
          y="43"
          fill="#000"
          fillOpacity="0.2"
          filter="url(#prefix__a)"
          rx="2.4"
          transform="rotate(-15 168.4 156.4)"
        />
        <rect
          width="136.08"
          height="136.08"
          x="234"
          y="188"
          fill="#000"
          fillOpacity="0.2"
          filter="url(#prefix__b)"
          rx="1.44"
          transform="rotate(15 302.04 256.04)"
        />
        <g transform="rotate(-15 234.043 27.755)">
          <rect width="226.8" height="226.8" x="4.624" y="2.098" fill="#E2D7CD" rx="2.4"/>
          <path
            fill="#000"
            fillOpacity="0.1"
            d="M126.93 5.727c.662 0 1.2.538 1.2 1.2v76.664c16.262-19.217 26.99-29.475 32.185-30.773 9.6-2.4 23.068 13.026 18 22.8-2.865 5.524-15.663 16.428-38.396 32.71h90.21a1.2 1.2 0 011.2 1.2v20.4a1.2 1.2 0 01-1.2 1.2h-81.537c19.838 16.72 30.417 27.719 31.737 33 2.4 9.6-13.025 23.068-22.8 18-5.14-2.666-14.94-13.936-29.399-33.808v85.407a1.2 1.2 0 01-1.2 1.2h-20.4a1.2 1.2 0 01-1.2-1.2V147.67c-15.452 18.119-25.697 27.808-30.736 29.068-9.6 2.4-23.068-13.026-18-22.8 2.239-4.318 10.545-11.92 24.92-22.81H2.129c-.662 0-1.2-.538-1.2-1.2v-20.4a1.2 1.2 0 011.2-1.2l78.53-.001C59.828 90.88 48.734 79.448 47.38 74.032c-2.4-9.6 13.026-23.068 22.8-18 5.796 3.006 17.513 16.946 35.15 41.82V6.927c0-.662.538-1.2 1.2-1.2h20.4zm1.2 130.03v2.706c18.328 25.192 28.928 38.147 31.8 38.864 2.4 1.2 6 0 4.8-3.6-.735-2.203-12.935-14.86-36.6-37.97zm-66.736 20.58c-1.2 2.4 0 6 3.6 4.8 1.99-.662 12.494-10.665 31.515-30.009h-5.23c-19.297 14.299-29.258 22.702-29.885 25.21zm108.52-87.92c-2.303.769-16.037 14.072-41.2 39.911h1.37c28.194-20.367 42.67-32.07 43.43-35.11 1.2-2.4 0-6-3.6-4.8zM62.98 64.433c.792 2.376 14.909 16.9 42.35 43.575v-.357C83.457 77.228 70.94 61.622 67.78 60.832c-2.4-1.2-6 0-4.8 3.6z"
            filter="url(#prefix__c)"
          />
          <g transform="translate(2.224 .898)">
            <mask id="prefix__f" fill="#fff">
              <use xlinkHref="#prefix__d"/>
            </mask>
            <use fill={props.color} xlinkHref="#prefix__d"/>
            <path
              fill="#000"
              fillOpacity="0.1"
              d="M177.385 69.89c-3.378 6.517-20.578 20.517-51.6 42-1.176.473-2.26.85-3.254 1.134 3.002.077 6.758.402 11.269.976 28.8 23.2 44 38 45.6 44.4 2.4 9.6-13.026 23.068-22.8 18-6.33-3.282-19.72-19.604-40.17-48.966-22.262 27.489-36.517 42.014-42.765 43.576-9.6 2.4-23.068-13.025-18-22.8 2.992-5.771 16.828-17.414 41.507-34.928-1.582-.152-3.29-.344-5.122-.577-28.8-23.2-44-38-45.6-44.4-2.4-9.6 13.026-23.068 22.8-18 6.516 3.379 20.516 20.579 42 51.6.395.983.724 1.903.987 2.76a12.104 12.104 0 011.761-.217c.053-3.077.382-6.997.987-11.758 23.2-28.8 38-44 44.4-45.6 9.6-2.4 23.068 13.026 18 22.8zM120.6 123.6c22.4 31.2 35.2 47.2 38.4 48 2.4 1.2 6 0 4.8-3.6-.8-2.4-15.2-17.2-43.2-44.4zm-13.512-9.973l-.197.011c-.145.01-.294.017-.445.024-29.872 21.502-45.199 33.818-45.981 36.948-1.2 2.4 0 6 3.6 4.8 2.359-.786 16.7-14.714 43.023-41.783zm61.897-50.937c-2.4.8-17.2 15.2-44.4 43.2 31.2-22.4 47.2-35.2 48-38.4 1.2-2.4 0-6-3.6-4.8zM62.05 58.705c.8 2.4 15.2 17.2 43.2 44.4-22.4-31.2-35.2-47.2-38.4-48-2.4-1.2-6 0-4.8 3.6z"
              filter="url(#prefix__e)"
              mask="url(#prefix__f)"
            />
            <path
              fill={props.color}
              d="M177.385 69.89c-3.378 6.517-20.578 20.517-51.6 42-1.176.473-2.26.85-3.254 1.134 3.002.077 6.758.402 11.269.976 28.8 23.2 44 38 45.6 44.4 2.4 9.6-13.026 23.068-22.8 18-6.33-3.282-19.72-19.604-40.17-48.966-22.262 27.489-36.517 42.014-42.765 43.576-9.6 2.4-23.068-13.025-18-22.8 2.992-5.771 16.828-17.414 41.507-34.928-1.582-.152-3.29-.344-5.122-.577-28.8-23.2-44-38-45.6-44.4-2.4-9.6 13.026-23.068 22.8-18 6.516 3.379 20.516 20.579 42 51.6.395.983.724 1.903.987 2.76a12.104 12.104 0 011.761-.217c.053-3.077.382-6.997.987-11.758 23.2-28.8 38-44 44.4-45.6 9.6-2.4 23.068 13.026 18 22.8zM120.6 123.6c22.4 31.2 35.2 47.2 38.4 48 2.4 1.2 6 0 4.8-3.6-.8-2.4-15.2-17.2-43.2-44.4zm-13.512-9.973l-.197.011c-.145.01-.294.017-.445.024-29.872 21.502-45.199 33.818-45.981 36.948-1.2 2.4 0 6 3.6 4.8 2.359-.786 16.7-14.714 43.023-41.783zm61.897-50.937c-2.4.8-17.2 15.2-44.4 43.2 31.2-22.4 47.2-35.2 48-38.4 1.2-2.4 0-6-3.6-4.8zM62.05 58.705c.8 2.4 15.2 17.2 43.2 44.4-22.4-31.2-35.2-47.2-38.4-48-2.4-1.2-6 0-4.8 3.6z"
              mask="url(#prefix__f)"
            />
          </g>
        </g>
        <g transform="rotate(15 -468.937 997.629)">
          <rect width="136.08" height="136.08" x="1.44" y="0.72" fill="#EAE3DC" rx="1.44"/>
          <path
            fill="#000"
            fillOpacity="0.1"
            d="M76.376 2.898a.72.72 0 01.72.72l.001 45.998c9.757-11.53 16.194-17.685 19.31-18.464 5.76-1.44 13.842 7.815 10.8 13.68-1.718 3.315-9.397 9.857-23.037 19.626h54.126a.72.72 0 01.72.72v12.24a.72.72 0 01-.72.72H89.373c11.904 10.031 18.251 16.631 19.043 19.8 1.44 5.76-7.815 13.84-13.68 10.8-3.084-1.6-8.964-8.361-17.639-20.285v51.245a.72.72 0 01-.72.72h-12.24a.72.72 0 01-.72-.72V88.063c-9.271 10.872-15.418 16.685-18.442 17.441-5.76 1.44-13.84-7.815-10.8-13.68 1.344-2.59 6.327-7.152 14.952-13.686H1.497a.72.72 0 01-.72-.72v-12.24a.72.72 0 01.72-.72h47.12C36.114 53.99 29.458 47.132 28.646 43.882c-1.44-5.76 7.815-13.841 13.68-10.8 3.477 1.803 10.507 10.167 21.09 25.093V3.618a.72.72 0 01.72-.72h12.24zm.721 78.017v1.625c10.997 15.114 17.357 22.887 19.08 23.318 1.44.72 3.6 0 2.88-2.16-.441-1.322-7.761-8.917-21.96-22.783zM37.055 93.264c-.72 1.44 0 3.6 2.16 2.88 1.194-.398 7.496-6.4 18.909-18.005h-3.138c-11.578 8.579-17.555 13.62-17.93 15.125zm65.113-52.752c-1.383.46-9.623 8.443-24.72 23.946h.82c16.917-12.22 25.604-19.242 26.06-21.066.72-1.44 0-3.6-2.16-2.88zm-64.162-2.391c.476 1.425 8.946 10.14 25.41 26.145v-.214C50.293 45.798 42.783 36.435 40.887 35.96c-1.44-.72-3.6 0-2.88 2.16z"
            filter="url(#prefix__g)"
          />
          <mask id="prefix__j" fill="#fff">
            <use xlinkHref="#prefix__h"/>
          </mask>
          <use fill={props.color} xlinkHref="#prefix__h"/>
          <path
            fill="#000"
            fillOpacity="0.2"
            d="M106.431 41.934c-2.027 3.91-12.347 12.31-30.96 25.2-.706.284-1.358.511-1.955.681 1.801.045 4.056.24 6.764.585 17.28 13.92 26.4 22.8 27.36 26.64 1.44 5.76-7.815 13.84-13.68 10.8-3.798-1.97-11.832-11.762-24.102-29.379-13.357 16.493-21.91 25.208-25.66 26.145-5.76 1.44-13.84-7.815-10.8-13.68 1.796-3.463 10.098-10.449 24.906-20.958-.95-.09-1.974-.205-3.074-.345-17.28-13.92-26.4-22.8-27.36-26.64-1.44-5.76 7.815-13.84 13.68-10.8 3.91 2.027 12.31 12.347 25.2 30.96.237.59.435 1.143.593 1.657.38-.074.733-.117 1.057-.131.03-1.846.228-4.199.591-7.055 13.92-17.28 22.8-26.4 26.64-27.36 5.76-1.44 13.841 7.816 10.8 13.68zM72.36 74.16c13.44 18.72 21.12 28.32 23.04 28.8 1.44.72 3.6 0 2.88-2.16-.48-1.44-9.12-10.32-25.92-26.64zm-8.107-5.984l-.03.001a12.57 12.57 0 01-.354.02c-17.925 12.901-27.12 20.291-27.59 22.17-.72 1.44 0 3.6 2.16 2.88 1.415-.473 10.02-8.83 25.814-25.071zm37.138-30.562c-1.44.48-10.32 9.12-26.64 25.92 18.72-13.44 28.32-21.12 28.8-23.04.72-1.44 0-3.6-2.16-2.88zm-64.161-2.39c.48 1.44 9.12 10.32 25.92 26.64-13.44-18.72-21.12-28.32-23.04-28.8-1.44-.72-3.6 0-2.88 2.16z"
            filter="url(#prefix__i)"
            mask="url(#prefix__j)"
          />
          <path
            fill={props.color}
            d="M106.431 41.934c-2.027 3.91-12.347 12.31-30.96 25.2-.706.284-1.358.511-1.955.681 1.801.045 4.056.24 6.764.585 17.28 13.92 26.4 22.8 27.36 26.64 1.44 5.76-7.815 13.84-13.68 10.8-3.798-1.97-11.832-11.762-24.102-29.379-13.357 16.493-21.91 25.208-25.66 26.145-5.76 1.44-13.84-7.815-10.8-13.68 1.796-3.463 10.098-10.449 24.906-20.958-.95-.09-1.974-.205-3.074-.345-17.28-13.92-26.4-22.8-27.36-26.64-1.44-5.76 7.815-13.84 13.68-10.8 3.91 2.027 12.31 12.347 25.2 30.96.237.59.435 1.143.593 1.657.38-.074.733-.117 1.057-.131.03-1.846.228-4.199.591-7.055 13.92-17.28 22.8-26.4 26.64-27.36 5.76-1.44 13.841 7.816 10.8 13.68zM72.36 74.16c13.44 18.72 21.12 28.32 23.04 28.8 1.44.72 3.6 0 2.88-2.16-.48-1.44-9.12-10.32-25.92-26.64zm-8.107-5.984l-.03.001a12.57 12.57 0 01-.354.02c-17.925 12.901-27.12 20.291-27.59 22.17-.72 1.44 0 3.6 2.16 2.88 1.415-.473 10.02-8.83 25.814-25.071zm37.138-30.562c-1.44.48-10.32 9.12-26.64 25.92 18.72-13.44 28.32-21.12 28.8-23.04.72-1.44 0-3.6-2.16-2.88zm-64.161-2.39c.48 1.44 9.12 10.32 25.92 26.64-13.44-18.72-21.12-28.32-23.04-28.8-1.44-.72-3.6 0-2.88 2.16z"
            mask="url(#prefix__j)"
          />
        </g>
      </g>
    </svg>
  );
}

PresentsSVG.defaultProps = {
  color: "#D1B4CE",
  left: 0,
  top: 0,
  width: 427,
  height: 393
};

export default PresentsSVG;
