import React from "react";
import IIConProps, { initializeIconProps } from "../IIconProps";

const Loading = (rawProps: IIConProps) => {
  const props = initializeIconProps(rawProps);
  props.color = props.color ?? "#FFF";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
    >
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    className="login-circle-fill"
                    fill={props.color}
                    fill-opacity=".6"
                    fill-rule="nonzero"
                    d="M20.684 23.451C19.295 24.327 17.686 24.8 16 24.8c-1.696 0-3.314-.478-4.709-1.364L7.83 26.872c.132.1.266.197.403.292C10.494 28.741 13.186 29.6 16 29.6c2.815 0 5.507-.86 7.769-2.436.13-.09.257-.182.382-.277"
                    transform="translate(-628.000000, -620.000000) translate(388.000000, 306.000000) translate(132.000000, 179.000000) translate(40.000000, 130.000000) translate(72.000000, 9.000000)"
                  />
                  <path
                    className="login-circle-stroke"
                    stroke={props.color}
                    stroke-width="4.8"
                    d="M23.92 23.92c4.373-4.374 4.373-11.466 0-15.84h0c-4.374-4.373-11.466-4.373-15.84 0-4.373 4.374-4.373 11.466 0 15.84"
                    transform="translate(-628.000000, -620.000000) translate(388.000000, 306.000000) translate(132.000000, 179.000000) translate(40.000000, 130.000000) translate(72.000000, 9.000000)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Loading;
