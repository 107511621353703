import React from "react";
import { ActivityTypeCode } from "../../../../../store/billing/model";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import ModalDialog from "../../modalDialog";
import styles from "./index.module.scss";
import { IRefund } from "./model";
import withStore from "./withStore";

export interface IRefundOwnProps {
  activityId: string;
  activityType: ActivityTypeCode;
  amount: number;
  onClose: () => void;
  onError: () => void;
}

export interface IRefundProps extends IRefundOwnProps {
  refund: IRefund;
}

class Refund extends React.Component<IRefundProps> {
  public componentDidUpdate(prevProps: IRefundProps) {
    const { refund, onClose, onError } = this.props;
    const refundHasCompleted = hasActionCompleted(prevProps.refund.status, refund.status);
    if (refundHasCompleted) {
      if (refund.status === "Success") {
        onClose();
      } else if (refund.status === "Error") {
        onError();
      }
    }
  }

  public render() {
    const { refund } = this.props;

    return (
      <ModalDialog
        title="SEND REFUND?"
        okText="Confirm"
        cancelText="Cancel"
        noBodyPadding={true}
        okPending={refund.status === "Pending"}
        onCancel={this.onCancel}
        onOk={this.onOk}
      >
        <div className={styles.container}>
          Refunding this payment will automatically contact Stripe, process the refund and notify the client. Once
          refunded, the refund cannot be reversed.
        </div>
      </ModalDialog>
    );
  }

  private onCancel = () => this.props.onClose();

  private onOk = () => this.props.refund.onRefund(this.props.activityId, this.props.activityType, this.props.amount);
}

export default withStore(Refund);
