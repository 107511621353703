import React from "react";
import IIConProps from "../../IIconProps";

const FavoriteWithBadge = ({ minX = 0, minY = 0, ...props }: IIConProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox={`${minX} ${minY} ${props.size} ${props.size}`}
  >
    <g fill="none" fillRule="evenodd" transform="translate(-8 -7)">
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M28.534 12.314A6.979 6.979 0 0 0 28 15.009c-.77.455-1.553 1.114-2.373 1.973L24 18.687l-1.628-1.705c-1.728-1.81-3.29-2.732-4.918-2.732-2.697 0-4.704 1.953-4.704 4.607 0 .337.045.8.187 1.444.229 1.042.648 2.189 1.308 3.418 1.764 3.285 4.896 6.56 9.755 9.641 5.023-3.178 8.196-6.496 9.92-9.759.295-.559.536-1.096.728-1.61a6.996 6.996 0 0 0 2.47-.317C36.238 24.807 33.36 30.432 24 36c-13.3-7.912-13.5-16.286-13.5-17.143 0-3.955 3.073-6.857 6.954-6.857 2.741 0 4.91 1.714 6.546 3.429 1.213-1.272 2.719-2.543 4.534-3.115z"
        opacity={props.opacity}
      />
      <circle cx="35" cy="15" r="5" fill="#8CD07D" />
    </g>
  </svg>
);

export default FavoriteWithBadge;
