import cx from "classnames";
import AlbumLock from "../../Icons/AlbumLock";
import React from "react";
import { getSiteFonts, getUUID } from "utilities/blocks/site";
import TextEditor from "../../TextEditor";
import { getInteractionUrlType, INTERACTION_TYPE, onLinkClick } from "utilities/blocks/blockInteractions";
import ContextMenu from "utilities/contextMenu";
import colors from "utilities/colors";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks";
import { normalizeHtml } from "../../TextEditor/utils";
import styles from "./zenTextImageBlock.module.scss";
import { PageContext } from "utilities/pageContext";
import { ILink, IRoutes } from "../../../models/models";
import { LinkTypeOption } from "utilities/constant";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { parseBody } from "utilities/blocks/string";

export interface ITextImageBlockProps extends IZenBaseBlockProps {
  imageUrl: string;
  imageName: string;
  imageHeight: number;
  imageWidth: number;
  imageId: string;
  altText?: string;
  maskType: string;
  maskPosition: string;
  titleText?: string;
  titlePosition?: string;
  verticalAlign?: string;
  subtitleText?: string;
  blockText?: string;
  interactionType?: string;
  interactionPage?: string;
  interactionUrl?: string;
  interactionNewTab?: boolean;
  pages?: any;
  routes?: IRoutes;
  onTextChange?: (data: any) => void;
  focalX?: number;
  focalY?: number;
  link?: ILink;
  isPublish?: boolean;
  getInteractionUrl?: getInteractionUrlType;
  editorId?: string;
  onBlur?: (ev: any) => void;
}

interface ITextImageBlockState {
  containerWidth: number;
  blockText: string;
}

const BRAKE_MOBILE = 450;
const BRAKE_TABLES = 975;
const RECTANGLE_RATIO = 4 / 3;

class ZenTextImageBlock extends React.Component<ITextImageBlockProps, ITextImageBlockState> {
  static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  public static defaultProps = {
    maskType: "none",
    maskPosition: "center",
    size: 0
  };

  private Container: any;

  constructor(props: ITextImageBlockProps) {
    super(props);
    this.Container = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      containerWidth: 1096,
      blockText:
        parseBody(this.props.blockText || "") ||
        "I am a photographer ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure.quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequaquis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequa."
    };
  }

  public componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    // required to adjust view for mobile after first render
    this.updateWindowDimensions();

    // sometimes in web editor more time is needed to correctly get the container size
    setTimeout(() => {
      this.updateWindowDimensions();
    }, 300);
  }

  public UNSAFE_componentWillMount() {
    this.updateWindowDimensions();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public updateWindowDimensions = () => {
    if (this.Container && this.Container.current) {
      this.setState({ containerWidth: this.Container.current.offsetWidth });
    }
  };

  private onTextblockChange = (newTextblock: string) => {
    if (normalizeHtml(this.state.blockText) !== newTextblock) {
      this.setState({ blockText: newTextblock });

      if (this.props.onTextChange) {
        this.props.onTextChange({
          blockText: newTextblock
        });
      }
    }
  };

  private hasLinkType = (): boolean => {
    const { link } = this.props;

    switch (link?.type) {
      case LinkTypeOption.GALLERY:
        return link?.albumFolderData !== undefined;
      case LinkTypeOption.URL:
        return link?.url != undefined && link?.url != "";
      case LinkTypeOption.PAGE:
        return link?.page != undefined && link?.page != "";
      default:
        return true;
    }
  };

  private hasInteractions = (): boolean => {
    const { interactionType, interactionUrl, interactionPage, interactionNewTab } = this.props;

    switch (interactionType) {
      case INTERACTION_TYPE.URL:
        return interactionUrl !== undefined && interactionUrl !== "";
      case INTERACTION_TYPE.PAGE:
        return interactionPage !== undefined && interactionPage !== "";
      case INTERACTION_TYPE.NONE:
        return false;
      default:
        return false;
    }
  };

  public handleButtonClick = () => {
    const {
      pages,
      link,
      routes,
      interactionType,
      interactionPage,
      interactionUrl,
      interactionNewTab,
      readOnly
    } = this.props;

    if (readOnly && pages) {
      onLinkClick(
        pages,
        interactionNewTab,
        interactionUrl,
        interactionPage,
        interactionType,
        routes ? routes : {},
        link,
        this.props.isPublish,
        this.props.getInteractionUrl
      );
    }
  };

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      layout,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      fontFamily,
      divider,
      padding,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      titlePosition,
      imageUrl,
      maskType,
      fullWidth,
      verticalAlign,
      imageHeight = 800, // default value for pages missing default img dimensions
      imageWidth = 800, // default value for pages missing default img dimensions
      focalX,
      focalY,
      imageId,
      link
    } = this.props;

    const titleText = parseBody(this.props.titleText || "");
    const subtitleText = parseBody(this.props.subtitleText || "");
    const { blockText, containerWidth } = this.state;

    const newFocalX = focalX ?? 50;
    const newFocalY = focalY ?? 50;

    // If there is not imageId present then the image is default
    const isGhost = !this.context.noGhost && !imageId;

    let maskWidth: number = 0;
    let maskHeight: number = 0;
    let borderRadius: number = 0;

    let newSize = layout === "A" || layout === "C" ? 375 : 575;

    let myImageWidth: number = imageWidth;
    let myImageHeight: number = imageHeight;

    if (maskType === "none") {
      myImageWidth = newSize;
      myImageHeight = (myImageWidth * imageHeight) / imageWidth;
    } else {
      if (imageWidth > imageHeight) {
        if (maskType === "portrait") {
          // Landscape picture, portrait mask
          myImageHeight = newSize * RECTANGLE_RATIO;
          myImageWidth = (myImageHeight * imageWidth) / imageHeight;
        } else {
          // Landscape picture, other mask
          myImageHeight = newSize;
          myImageWidth = (myImageHeight * imageWidth) / imageHeight;
        }
      } else {
        // Portrait picture
        myImageWidth = newSize;
        myImageHeight = (myImageWidth * imageHeight) / imageWidth;
      }
    }

    if (containerWidth <= BRAKE_MOBILE && containerWidth > 0) {
      newSize = containerWidth;
    }
    if (containerWidth <= BRAKE_TABLES && containerWidth > BRAKE_MOBILE) {
      newSize = containerWidth / 2;
    }

    if (maskType === "circle" || maskType === "square") {
      maskWidth = newSize;
      maskHeight = newSize;
    }

    if (maskType === "circle") {
      borderRadius = newSize / 2;
    }
    if (maskType === "landscape") {
      maskWidth = newSize;
      if (maskWidth > containerWidth && containerWidth > 0) {
        maskWidth = containerWidth;
      }
      maskHeight = maskWidth / RECTANGLE_RATIO;
    }
    if (maskType === "portrait") {
      maskWidth = newSize;
      maskHeight = newSize * RECTANGLE_RATIO;
    }

    if (myImageWidth < maskWidth) {
      myImageWidth = maskWidth;
      myImageHeight = (myImageWidth * imageHeight) / imageWidth;
    }

    if (myImageHeight < maskHeight) {
      myImageHeight = maskHeight;
      myImageWidth = (myImageHeight * imageWidth) / imageHeight;
    }

    if (maskType === "none") {
      maskWidth = 0;
      maskHeight = 0;
      borderRadius = 0;
    }

    const blockTextColor = getBlockTextColor(
      backgroundType === "none" && backgroundColor ? siteTheme.backgroundColor.value : backgroundColor,
      siteTheme.accentColor.value,
      backgroundType,
      siteTheme.backgroundColor.value
    );

    const titleClass = cx(styles.title);
    const titleStyle = { color: siteTheme.accentColor.value };

    const subtitleClass = cx(styles.subtitle);
    const subtitleStyle = { color: blockTextColor };
    const blockClass = cx(styles.block);
    const blockStyle = { color: blockTextColor };

    let containerClass = styles.zenContainer;
    if (layout) {
      containerClass = cx(
        containerClass,
        layout === "A"
          ? styles.layoutA
          : layout === "B"
          ? styles.layoutB
          : layout === "C"
          ? styles.layoutC
          : styles.layoutD
      );
    }

    if (containerWidth <= BRAKE_MOBILE) {
      containerClass = cx(containerClass, styles.zenMobile);
    }

    if (verticalAlign && verticalAlign === "top") {
      containerClass = cx(containerClass, styles.alignTop);
    }
    if (verticalAlign && verticalAlign === "bottom") {
      containerClass = cx(containerClass, styles.alignBottom);
    }
    if (verticalAlign && verticalAlign === "center") {
      containerClass = cx(containerClass, styles.alignCenter);
    }

    const titlesClass = cx(styles.titles, titlePosition && titlePosition === "below" ? styles.below : styles.above);
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    const hasLink = this.props.link ? this.hasLinkType() : this.hasInteractions();
    const editorId = this.props.editorId || getUUID();
    const bgColorTextEditor = backgroundType === "none" ? siteTheme.backgroundColor.value : backgroundColor;

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        readOnly={readOnly}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        layout={""}
        selectedBlock={selectedBlock}
        fullWidth={fullWidth}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div ref={this.Container} className={containerClass}>
            <div
              className={cx(styles.picture, { [styles.clickable]: hasLink && this.props.readOnly })}
              style={{
                width: maskWidth !== 0 ? maskWidth + "px" : newSize + "px",
                height: maskHeight !== 0 ? maskHeight + "px" : myImageHeight + "px",
                borderRadius: borderRadius !== 0 ? borderRadius + "px" : "0"
              }}
              onContextMenu={ContextMenu.handleBlockContextMenu}
              onClick={this.handleButtonClick}
            >
              <div
                className={
                  (link?.albumFolderData?.album.isGalleryProtected ||
                    (link?.albumFolderData?.album.isVisitorAllowed === false && !link?.albumFolderData?.folder.id)) &&
                  link?.type === LinkTypeOption.GALLERY
                    ? styles.protectedImage
                    : ""
                }
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: borderRadius !== 0 ? borderRadius + "px" : "0",
                  backgroundImage: "url(" + imageUrl + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: newFocalX + "% " + newFocalY + "%",
                  backgroundSize: "cover",
                  opacity: isGhost ? 0.4 : 1
                }}
              />
              {(link?.albumFolderData?.album.isGalleryProtected ||
                (link?.albumFolderData?.album.isVisitorAllowed === false && !link?.albumFolderData?.folder.id)) &&
                link?.type === LinkTypeOption.GALLERY &&
                maskType !== "circle" && (
                  <div className={styles.albumLock}>
                    <AlbumLock color={colors.darkGrey} size={24} />
                  </div>
                )}
            </div>
            <div
              className={cx(styles.textBlock, styles[siteFonts.primary], {
                [styles.left]: layout === "C" || layout === "D",
                [styles.right]: layout === "A" || layout === "B"
              })}
            >
              <div className={titlesClass}>
                {titleText && titleText !== "" && (
                  <div
                    className={cx(titleClass, styles[siteFonts.primaryExtra], { [styles.titleClickable]: !readOnly })}
                    style={titleStyle}
                  >
                    <span>{titleText}</span>
                  </div>
                )}
                {subtitleText && subtitleText !== "" && (
                  <div
                    className={cx(subtitleClass, styles[siteFonts.secondary], {
                      [styles.subtitleClickable]: !readOnly
                    })}
                    style={subtitleStyle}
                  >
                    <span>{subtitleText}</span>
                  </div>
                )}
              </div>
              <div className={blockClass} style={blockStyle}>
                <div className={cx(styles.textEditor, styles[siteFonts.secondary])}>
                  <TextEditor
                    showLink
                    isFloating
                    backgroundColor={bgColorTextEditor}
                    accentColor={siteTheme.accentColor.value}
                    contents={blockText}
                    onChange={this.onTextblockChange}
                    placeHolder={
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    }
                    pages={this.props.pages}
                    readOnly={readOnly}
                    editorId={editorId}
                    onBlur={this.props.onBlur}
                  />
                </div>
              </div>
            </div>
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}

export default ZenTextImageBlock;
