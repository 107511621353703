import { FormTextArea, Utilities } from "@zenfolio/core-components";
import React from "react";
import FractionalPriceInput, { IFractionalValue } from "../../../../../../components/fractionalPriceInput";
import styles from "./index.module.scss";

export type Amount = IFractionalValue | null;

interface ISetupProps {
  amount: Amount;
  internalNote?: string;
  maxAmount: number;
  isTaxable: boolean;
  initialTaxAmount: number;
  taxAmount?: number;
  minAmount?: number;
  onChange: (amount: Amount, errorMessage?: string, internalNote?: string) => void;
  onAmountBlur: (amount: Amount, valid: boolean) => void;
}

interface ISetupState {
  amount: Amount;
  internalNote?: string;
  errorMessage?: string;
}

class Setup extends React.Component<ISetupProps, ISetupState> {
  constructor(props: ISetupProps) {
    super(props);

    this.state = {
      ...Setup.defaultState,
      amount: this.props.amount,
      internalNote: this.props.internalNote
    };
  }

  public render() {
    const { amount, isTaxable, initialTaxAmount, taxAmount } = this.props;
    const { amount: newAmount, errorMessage, internalNote } = this.state;

    const total =
      newAmount != null && (!isTaxable || taxAmount != null) ? newAmount.floatValue + (taxAmount || 0) : null;
    const currencySymbol = Utilities.getCurrencyInfo(Utilities.getCurrencyFormattingInfo("US").currencyCode).symbol;

    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.oldInvoice}>
            <div className={styles.amount}>
              <label>
                Invoice
                <br />
                Amount
              </label>
              <em>{Utilities.formatFractionalNumberWithCurrency(amount!.floatValue)}</em>
            </div>
            {isTaxable && (
              <div className={styles.tax}>
                <label>Tax</label>
                <em>{Utilities.formatFractionalNumberWithCurrency(initialTaxAmount)}</em>
              </div>
            )}
            <div className={styles.total}>
              <label>TOTAL</label>
              <em>{Utilities.formatFractionalNumberWithCurrency(amount!.floatValue + initialTaxAmount)}</em>
            </div>
          </div>

          <div className={styles.newInvoice}>
            <div className={styles.amount}>
              <label>
                New Invoice
                <br />
                Amount
              </label>
              <FractionalPriceInput
                value={newAmount}
                errorMessage={errorMessage}
                inputClassName={styles.input}
                errorMessageClassName={styles.error}
                forceShowingError={true}
                onChange={this.onAmountChange}
                onBlur={this.onAmountBlur}
              />
            </div>
            {isTaxable && (
              <div className={styles.tax}>
                <label>Tax</label>
                <em>{taxAmount != null ? Utilities.formatFractionalNumberWithCurrency(taxAmount) : currencySymbol}</em>
              </div>
            )}
            <div className={styles.total}>
              <label>TOTAL</label>
              <em>{total != null ? Utilities.formatFractionalNumberWithCurrency(total) : currencySymbol}</em>
            </div>
          </div>
        </div>

        <div className={styles.note}>
          Internal Note <span className={styles.tips}>(optional)</span>
        </div>
        <FormTextArea
          className={styles.textAreaWrapper}
          textAreaClassName={styles.textArea}
          placeholder={"Leave yourself a note…"}
          value={internalNote}
          onChange={this.onInternalNoteChanged}
          maxLength={1000}
          charCount={false}
        />
      </div>
    );
  }

  private onAmountChange = (value: Amount) => this.setAmount(value);

  private onAmountBlur = () => {
    this.props.onAmountBlur(this.state.amount, !this.state.errorMessage);
  };

  private setAmount = (amount: Amount) => {
    const { maxAmount, minAmount } = this.props;

    let errorMessage: string | undefined;
    if (minAmount != null && (!amount || amount.floatValue < minAmount)) {
      errorMessage = `Value cannot be lower than ${Utilities.formatNumberWithCurrency(minAmount)}`;
    } else if (!amount || amount.floatValue < 1 || amount.floatValue > maxAmount) {
      errorMessage = `Min. $1 Max. ${Utilities.formatNumberWithCurrency(maxAmount)}`;
    }

    this.setState({ amount, errorMessage }, this.onChange);
  };

  private onInternalNoteChanged = (internalNote: string) => {
    this.setState({ internalNote }, this.onChange);
  };

  private onChange = () => {
    const { amount, errorMessage, internalNote } = this.state;
    this.props.onChange(amount, errorMessage, internalNote);
  };

  public static defaultState: ISetupState = { amount: null, errorMessage: undefined, internalNote: undefined };
}

export default Setup;
