export type NavigationDirection = "forward" | "back";

export interface IPassiveNavigation {
  position: number;
}

export interface IActiveNavigation {
  minPosition?: number;
  maxPosition?: number;
}

export interface IActiveControlledNavigation extends IActiveNavigation {
  position: number;
  onChange: (direction: NavigationDirection) => void;
}

export interface IActiveUncontrolledNavigation extends IActiveNavigation {
  defaultPosition: number;
  onChange: (position: number) => void;
}

export type Navigation = IPassiveNavigation | IActiveControlledNavigation | IActiveUncontrolledNavigation;

export function isPassiveNavigation(navigation: Navigation): navigation is IPassiveNavigation {
  return navigation && "position" in navigation && !("onChange" in navigation);
}

export function isActiveControlledNavigation(navigation: Navigation): navigation is IActiveControlledNavigation {
  return navigation && "position" in navigation && "onChange" in navigation;
}

export function isActiveUncontrolledNavigation(navigation: Navigation): navigation is IActiveUncontrolledNavigation {
  return navigation && "defaultPosition" in navigation;
}
