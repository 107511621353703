import cx from "classnames";
import Calendar from "components/Icons/Calendar";
import React from "react";
import { IElementProps } from "../../../models/props";
import colors from "../../../utilities/colors";
import { DateFormat } from "../../../utilities/dateFormat";
import { Utilities } from "../../../utilities/utilities";
import { IDatePickerInputProps } from "../models";
import styles from "./defaultDatePickerInput.module.scss";

const { shouldComponentUpdateDeep, resolveValue } = Utilities;

export type Size = "normal" | "compact";

export interface IDefaultDatePickerInputProps extends IDatePickerInputProps {
  size?: Size;
  format?: string;
  countryCode?: string;
  elements?: {
    value?: IElementProps<HTMLDivElement>;
    clear?: IElementProps<HTMLButtonElement>;
    icon?: IElementProps<HTMLButtonElement>;
  };
}

class DefaultDatePickerInput extends React.Component<IDefaultDatePickerInputProps> {
  public static defaultProps: Partial<IDefaultDatePickerInputProps> = {
    size: "normal",
    countryCode: Utilities.iso3166DefaultCountryCode
  };

  public shouldComponentUpdate(nextProps: IDefaultDatePickerInputProps): boolean {
    return shouldComponentUpdateDeep(this, nextProps);
  }

  public render() {
    const { date, required, placeholder, disabled, className, style, containerRef } = this.props;
    const { size, countryCode } = this.props;
    const { value, clear, icon } = this.props.elements || {};
    const format = this.props.format || DateFormat.longStringFormat(countryCode!);

    return (
      <div
        className={cx(styles.container, className, {
          [styles.compact]: size === "compact",
          [styles.disabled]: disabled
        })}
        style={style}
        ref={containerRef}
      >
        <div
          onMouseDown={!disabled ? this.onValueMouseDown : undefined}
          className={cx(styles.value, { [styles.empty]: !date }, value?.className)}
          style={value?.style}
          ref={value?.ref}
        >
          {date ? date.format(format) : placeholder || "\u00A0"}
        </div>
        {!required && date && (
          <button
            onClick={!disabled ? this.onClearClick : undefined}
            className={cx(styles.clear, clear?.className)}
            style={clear?.style}
            ref={clear?.ref}
          >
            Clear
          </button>
        )}
        <button
          onClick={!disabled ? this.onIconClick : undefined}
          className={cx(styles.icon, icon?.className)}
          style={icon?.style}
          ref={icon?.ref}
        >
          <Calendar color={colors.filterOrangeText} />
        </button>
      </div>
    );
  }

  private onValueMouseDown = (event: React.MouseEvent) => {
    if (event.button === 0) {
      this.props.onOpenToggle?.();
    }
  };

  private onIconClick = () => this.props.onOpenToggle?.();

  private onClearClick = () => {
    if (this.props.date === undefined) {
      this.setState({ date: null });
    }
    this.props.onClear?.();
  };
}

export default DefaultDatePickerInput;
