import { isClientSideRendering } from "./site";

declare global {
  interface Window {
    lazySizes: any;
  }
}

export const handleLazyLoaded = (
  e: Event,
  revealTopClassName: string,
  revealBottomClassName: string,
  revealFlagClassName: string,
  loadedClassName: string
) => {
  if (e.target && isClientSideRendering) {
    const img = e.target as HTMLImageElement;

    if (!img.parentElement?.classList.contains(loadedClassName)) {
      img.parentElement?.getElementsByClassName(revealTopClassName)[0]?.classList.add(revealFlagClassName);
      img.parentElement?.getElementsByClassName(revealBottomClassName)[0]?.classList.add(revealFlagClassName);

      img.parentElement?.classList.add(loadedClassName);
      window.lazySizes?.autoSizer.checkElems();
    }
  }
};
