import React from "react";
import cx from "classnames";

// Local Modules
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import TextEditor from "../../TextEditor";
import styles from "./zenHeadingBlock.module.scss";
import { getSiteFonts, getUUID } from "utilities/blocks/site";
import { parseBody } from "utilities/blocks/string";

export interface IZenHeadingBlockProps extends IZenBaseBlockProps {
  titleText: string;
  headingSize: number;
  onTextChange?: (data: any) => void;
  onHeadingChange?: (size: number) => void;
  onToggleDropdown?: (isOpen: boolean) => void;
  editorId?: string;
}

interface IZenHeadingBlockState {
  titleText: string;
  headingSize: number;
}

class ZenHeadingBlock extends React.Component<IZenHeadingBlockProps, IZenHeadingBlockState> {
  constructor(props: IZenHeadingBlockProps) {
    super(props);

    this.state = {
      titleText: parseBody(this.props.titleText) || "Title",
      headingSize: this.props.headingSize
    };
  }

  public componentDidMount() {
    if (this.props.titleText) {
      this.setState({ titleText: parseBody(this.props.titleText) });
    }

    if (this.props.headingSize) {
      this.setState({ headingSize: this.props.headingSize });
    }
  }

  private onTitleChange = (newTitle: string) => {
    if (this.state.titleText !== newTitle) {
      this.setState({ titleText: newTitle });
      if (this.props.onTextChange) {
        this.props.onTextChange({
          titleText: newTitle
        });
      }
    }
  };

  private onHeadingChange = (newHeadingSize: number) => {
    if (this.state.headingSize !== newHeadingSize) {
      this.setState({ headingSize: newHeadingSize });
      if (this.props.onHeadingChange) {
        this.props.onHeadingChange(newHeadingSize);
      }
    }
  };

  public render() {
    const {
      layout,
      divider,
      padding,
      readOnly,
      alignment,
      siteTheme,
      fontFamily,
      selectedBlock,
      tabletEnabled,
      mobileEnabled,
      desktopEnabled,
      backgroundType,
      backgroundColor,
      backgroundWidth,
      backgroundOpacity,
      animationScrollIn,
      animationScrollOut
    } = this.props;

    const { titleText, headingSize } = this.state;
    const titleStyle = { color: siteTheme.accentColor.value, fontSize: headingSize };
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    const editorId = this.props.editorId || getUUID();
    const bgColorTextEditor = backgroundType === "none" ? siteTheme.backgroundColor.value : backgroundColor;

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        readOnly={readOnly}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        layout={layout}
        selectedBlock={selectedBlock}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div className={cx(styles.zenContainer)}>
            <div
              className={cx(styles.title, styles[siteFonts.primary], styles[siteFonts.primaryExtra])}
              style={titleStyle}
            >
              <TextEditor
                isFloating
                contents={titleText}
                onChange={this.onTitleChange}
                placeHolder={"Add a title"}
                readOnly={readOnly}
                showLink={false}
                showHeadings={true}
                backgroundColor={bgColorTextEditor}
                accentColor={siteTheme.accentColor.value}
                headingSize={Number(headingSize)}
                onHeadingChange={this.onHeadingChange}
                onToggleDropdown={this.props.onToggleDropdown}
                editorId={editorId}
              />
            </div>
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}

export default ZenHeadingBlock;
