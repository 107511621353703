import { TextInput } from "@zenfolio/core-components";
import * as React from "react";
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router";
import { IRoute } from "../../components/router/routes";
import { AsyncLoader } from "../../utilities/asyncLoader";
import { AuthenticationManager } from "../../utilities/authenticationManager";
import { fastScrollClassName, openInNewTab } from "../../utilities/helpers";
import { NavigationManager } from "../../utilities/navigationManager";
import styles from "./index.module.scss";

export interface IPageLayoutProps extends RouteComponentProps {
  routes: IRoute[];
}

interface ITestLayoutState {
  iframeUrl: string;
  title: string;
  inputPhotographerId: string | null;
  iframePhotographerId: string | null;
  loadingToken: boolean;
}

class TestLayout extends React.PureComponent<IPageLayoutProps, ITestLayoutState> {
  private rootRef = React.createRef<HTMLDivElement>();
  private iframeRef = React.createRef<HTMLIFrameElement>();

  constructor(props: any) {
    super(props);

    let testPhotographerId = NavigationManager.getTestPhotographerId();
    if (testPhotographerId) {
      testPhotographerId = testPhotographerId.toLowerCase();
    }

    this.state = {
      iframeUrl: "",
      title: TestLayout.buildTitle(),
      inputPhotographerId: testPhotographerId,
      iframePhotographerId: testPhotographerId,
      loadingToken: !!testPhotographerId
    };
  }

  public componentDidMount() {
    this.updateMinHeight();

    AsyncLoader.subscribe(AsyncLoader.iframeResizerParent, (iframeResize: any) => {
      iframeResize(
        {
          log: false,
          checkOrigin: false,
          warningTimeout: 0
        },
        `.${styles.iframe}`
      );
    });

    window.addEventListener("message", this.processIframeEvent);
    window.addEventListener("resize", this.updateMinHeight);
    window.addEventListener("popstate", this.updateIframeUrl);

    this.reloadToken();
  }

  private processIframeEvent = (event: MessageEvent) => {
    const data = NavigationManager.parseMessage(event);
    if (!data || !data.type) {
      return;
    }

    if (data.type === "path") {
      this.updateParentUrl(NavigationManager.setPathOnTestParentUrl(data.path), data.replace);
    } else if (data.type === "redirect") {
      window.location.href = data.url;
    } else if (data.type === "modal-shown") {
      document.documentElement.classList.add(styles.modalVisible);
      document.documentElement.addEventListener("click", this.onClickOutside, true);
      document.documentElement.addEventListener("touchstart", this.onClickOutside, true);
    } else if (data.type === "modal-closed") {
      document.documentElement.classList.remove(styles.modalVisible);
      document.documentElement.removeEventListener("click", this.onClickOutside, true);
      document.documentElement.removeEventListener("touchstart", this.onClickOutside, true);
    } else if (data.type === "title-changed") {
      this.setState({ title: TestLayout.buildTitle(data.title) });
    } else if (data.type === "fast-scroll-started") {
      document.documentElement.classList.add(fastScrollClassName);
    } else if (data.type === "fast-scroll-finished") {
      document.documentElement.classList.remove(fastScrollClassName);
    } else if (data.type === "redirect-to-homepage") {
      alert("Not supported inside emulator!");
    } else if (data.type === "open-in-new-tab") {
      openInNewTab(NavigationManager.setPathOnTestParentUrl(data.path));
    }
  };

  public render() {
    const { iframeUrl, inputPhotographerId, loadingToken, title } = this.state;

    return (
      <React.Fragment>
        <Helmet title={title} />
        <div className={styles.container} ref={this.rootRef}>
          <div className={styles.header}>
            <img className={styles.logo} alt="Zenfolio" src={require("../../assets/img/zenfolio.png")} />
          </div>
          <div className={styles.menu}>
            <label>Photographer ID:</label>
            <TextInput
              value={inputPhotographerId || ""}
              placeholder="Photographer ID"
              onChange={this.onPhotographerIdChange}
              onBlur={this.onPhotographerIdBlur}
              className={styles.input}
              disabled={loadingToken}
            />
          </div>
          <div className={styles.wrapper}>
            <iframe className={styles.iframe} title="BookMe" src={iframeUrl} ref={this.iframeRef} scrolling="no" />
          </div>
        </div>
      </React.Fragment>
    );
  }

  private static buildTitle(title?: string) {
    return `Zenfolio | ${title || "BookMe"}`;
  }

  private onPhotographerIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const photographerId = event.target.value.toLowerCase();

    this.setState({
      inputPhotographerId: photographerId
    });
  };

  private onPhotographerIdBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const photographerId = event.target.value.toLowerCase();

    this.updateParentUrl(NavigationManager.setPhotographerIdOnTestParentUrl(photographerId), false);

    this.setState(
      {
        inputPhotographerId: photographerId,
        iframePhotographerId: photographerId,
        loadingToken: true
      },
      () => this.reloadToken()
    );
  };

  private reloadToken() {
    AuthenticationManager.getTestAccessTokenByPhotographerId(this.state.iframePhotographerId)
      .then(token => this.setToken(token))
      .catch(() => this.setToken(null));
  }

  private setToken(token: string | null) {
    const photographerId = this.state.iframePhotographerId;

    if (!token) {
      AuthenticationManager.savePhotographerStorage(photographerId ? { photographerId } : null);
    }

    this.setState({
      loadingToken: false,
      iframeUrl: NavigationManager.buildTestIframeUrl(token)
    });
  }

  private updateParentUrl(url: string, replace: boolean) {
    if (replace) {
      NavigationManager.replaceUrl(url);
    } else {
      NavigationManager.pushUrl(url);
    }
  }

  private updateMinHeight = () => {
    if (this.rootRef.current && this.iframeRef.current) {
      const minHeight = Math.floor(this.rootRef.current.offsetHeight - 143);
      this.iframeRef.current.style.minHeight = `${minHeight}px`;
    }
  };

  private updateIframeUrl = () => {
    window.location.reload();
  };

  private onClickOutside = () => {
    if (this.iframeRef.current) {
      NavigationManager.postChildMessage(this.iframeRef.current.contentWindow!, { type: "close-modal" });
    }
  };
}

export default TestLayout;
