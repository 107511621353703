import cx from "classnames";
import React, { PropsWithChildren, PureComponent } from "react";
import closeBlackBtn from "icons/cross-black.svg";
import closeWhiteBtn from "icons/cross-white.svg";
import styles from "./modal.module.scss";

interface IModalNotification {
  show: boolean;
  message: string;
}

export interface IModalProps {
  onClose: () => void;
  onCloseNotification?: () => void;
  lg?: boolean;
  notification?: IModalNotification;
  isLightbox?: boolean;
  fullHeight?: boolean;
  smallWithButtons?: boolean;
  hideCloseButton?: boolean;
  title?: string;
  largeImgCloseButton?: boolean;
  className?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  overlayClassName?: string;
}

class Modal extends PureComponent<IModalProps> {
  public listenKeyboard = (evt: KeyboardEvent) => {
    if (evt.key === "Escape" || evt.keyCode === 27) {
      evt.stopImmediatePropagation();
      this.props.onClose();
    }
  };

  public componentDidMount() {
    window.addEventListener("keydown", this.listenKeyboard, true);
  }

  public componentWillUnmount() {
    window.removeEventListener("keydown", this.listenKeyboard, true);
  }

  public onOverlayClick = (evt: React.BaseSyntheticEvent) => {
    if (!evt || !evt.target) {
      return;
    }

    const target = evt.target as Element;
    if (!target.classList.contains(styles.overlay)) {
      return;
    }

    this.props.onClose();
  };

  public onDialogClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
  };

  public render() {
    const { notification, onClose, onCloseNotification, className, innerRef, overlayClassName } = this.props;

    const dialogStyles = cx(styles.dialog, {
      [styles.largeDialog]: this.props.lg,
      [styles.lightbox]: this.props.isLightbox,
      [styles.fullHeight]: this.props.fullHeight,
      [styles.smallWithButtons]: this.props.smallWithButtons,
      [styles.lightboxNoPos]: this.props.largeImgCloseButton
    });
    const overlayStyles = cx(styles.overlay, overlayClassName, {
      [styles.lightbox]: this.props.isLightbox,
      [styles.centerModal]: !this.props.fullHeight,
      [styles.overlayStandard]: !this.props.fullHeight
    });

    return (
      <div>
        {notification && notification.show && (
          <div className={styles.notification}>
            {notification.message}
            <button className={styles.closeBtn} onClick={onCloseNotification}>
              <img src={closeWhiteBtn} alt="close" />
            </button>
          </div>
        )}
        <div className={overlayStyles} onMouseDown={this.onOverlayClick} onTouchStart={this.onOverlayClick}>
          <div className={cx(dialogStyles, className)} onMouseDown={this.onDialogClick} ref={innerRef}>
            {this.props.title && (
              <div className={styles.header}>
                <h2 className={styles.title}>{this.props.title}</h2>
              </div>
            )}
            {!this.props.hideCloseButton && (
              <button className={styles.closeBtn} onClick={onClose}>
                <img src={this.props.isLightbox ? closeWhiteBtn : closeBlackBtn} alt="close" />
              </button>
            )}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default React.forwardRef<HTMLDivElement, PropsWithChildren<IModalProps>>((props, ref?) => (
  <Modal {...props} innerRef={ref}>
    {props.children}
  </Modal>
));
