export enum ActionType {
  RESET = "BILLING_RESET",
  LOAD_STARTED = "BILLING_LOAD_STARTED",
  LOAD_SUCCESS = "BILLING_LOAD_SUCCESS",
  LOAD_ERROR = "BILLING_LOAD_ERROR",
  SEND_INVOICE_STARTED = "BILLING_SEND_INVOICE_STARTED",
  SEND_INVOICE_SUCCESS = "BILLING_SEND_INVOICE_SUCCESS",
  SEND_INVOICE_ERROR = "BILLING_SEND_INVOICE_ERROR",
  REFUND_STARTED = "BILLING_REFUND_STARTED",
  REFUND_SUCCESS = "BILLING_REFUND_SUCCESS",
  REFUND_ERROR = "BILLING_REFUND_ERROR",
  MARK_AS_PAID_STARTED = "BILLING_MARK_AS_PAID_STARTED",
  MARK_AS_PAID_SUCCESS = "BILLING_MARK_AS_PAID_SUCCESS",
  MARK_AS_PAID_ERROR = "BILLING_MARK_AS_PAID_ERROR",
  EDIT_INVOICE_STARTED = "BILLING_EDIT_INVOICE_STARTED",
  EDIT_INVOICE_SUCCESS = "BILLING_EDIT_INVOICE_SUCCESS",
  EDIT_INVOICE_ERROR = "BILLING_EDIT_INVOICE_ERROR",
  ADD_INVOICE_STARTED = "BILLING_ADD_INVOICE_STARTED",
  ADD_INVOICE_SUCCESS = "BILLING_ADD_INVOICE_SUCCESS",
  ADD_INVOICE_ERROR = "BILLING_ADD_INVOICE_ERROR",
  DELETE_INVOICE_STARTED = "BILLING_DELETE_INVOICE_STARTED",
  DELETE_INVOICE_SUCCESS = "BILLING_DELETE_INVOICE_SUCCESS",
  DELETE_INVOICE_ERROR = "BILLING_DELETE_INVOICE_ERROR",
  CALCULATE_TAX_FOR_NEW_INVOICE_STARTED = "BILLING_CALCULATE_TAX_FOR_NEW_INVOICE_STARTED",
  CALCULATE_TAX_FOR_NEW_INVOICE_SUCCESS = "BILLING_CALCULATE_TAX_FOR_NEW_INVOICE_SUCCESS",
  CALCULATE_TAX_FOR_NEW_INVOICE_ERROR = "BILLING_CALCULATE_TAX_FOR_NEW_INVOICE_ERROR",
  CALCULATE_TAX_FOR_EDITING_INVOICE_STARTED = "BILLING_CALCULATE_TAX_FOR_EDITING_INVOICE_STARTED",
  CALCULATE_TAX_FOR_EDITING_INVOICE_SUCCESS = "BILLING_CALCULATE_TAX_FOR_EDITING_INVOICE_SUCCESS",
  CALCULATE_TAX_FOR_EDITING_INVOICE_ERROR = "BILLING_CALCULATE_TAX_FOR_EDITING_INVOICE_ERROR"
}
