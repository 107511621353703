import moment from "moment";
import React, { PureComponent } from "react";
import enhanceWithClickOutside from "react-click-outside";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "./daypicker.css";
import styles from "./dayPicker.module.scss";

interface INavbarProps {
  onPreviousClick?: any;
  onNextClick?: any;
  localeUtils?: any;
  month?: any;
  className?: any;
}

interface IWeekDayProps {
  weekday?: any;
  className?: any;
  localeUtils?: any;
  locale?: any;
}

const NavBar: React.FC<INavbarProps> = props => {
  const handlePreviousClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onPreviousClick();
  };
  const handleNextClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onNextClick();
  };
  const actualMonth = moment(props.month).format("MMMM YYYY");

  const navbarStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px"
  };
  return (
    <div style={navbarStyles}>
      <button className={styles.leftArrow} onClick={handlePreviousClick}>
        ←
      </button>
      <p className={styles.actualMonth}>{actualMonth}</p>
      <button className={styles.rightArrow} onClick={handleNextClick}>
        →
      </button>
    </div>
  );
};

const Weekday: React.FC<IWeekDayProps> = ({ weekday, localeUtils, locale }) => {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  const weekDayStyles = {
    display: "table-cell",
    padding: "0.5em",
    color: "#8b9898",
    fontSize: "12px",
    fontFamily: "Nunito Sans"
  };
  return <div style={weekDayStyles}>{weekdayName.slice(0, 2)}</div>;
};

function renderDay(day: any) {
  const date = day.getDate();

  return <div className={styles.date}>{date}</div>;
}

const CaptionElement = () => null;

interface ISingleDayPicker {
  onClickOutside?: () => void;
  onClick: (date: string, callback?: () => void) => void;
  selectedDays?: string | Date | moment.Moment;
  handleClick?: () => void;
  disableToday?: boolean;
}

class SingleDayPicker extends PureComponent<ISingleDayPicker> {
  public componentDidMount() {
    if ("ontouchstart" in document.documentElement) {
      document.body.style.cursor = "pointer";
    }
  }

  public handleDayClick = (day: any, modifiers: any = {}) => {
    if (modifiers.disabled) {
      return;
    }
    this.props.onClick(moment(day).format("YYYY-MM-DD"), this.props.handleClick);
  };

  public handleClickOutside = () => {
    const { onClickOutside } = this.props;
    if (onClickOutside) {
      onClickOutside();
    }
  };

  public render() {
    const selectedDays = this.props.selectedDays ? new Date(this.props.selectedDays as any) : undefined;

    return (
      <div className={styles.container}>
        <DayPicker
          fixedWeeks={true}
          captionElement={<CaptionElement />}
          renderDay={renderDay}
          weekdayElement={<Weekday />}
          navbarElement={<NavBar />}
          onDayClick={this.handleDayClick}
          selectedDays={selectedDays}
          disabledDays={this.disabledDays}
          month={this.props.selectedDays ? selectedDays : new Date()}
        />
      </div>
    );
  }

  private disabledDays = (date: Date) => {
    const tomorrow = moment()
      .add(1, "day")
      .startOf("day")
      .toDate();

    return date < tomorrow;
  };
}

export default enhanceWithClickOutside(SingleDayPicker);
