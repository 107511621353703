import _ from "lodash";
import moment from "moment";

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const DaysOfWeek = {
  numericOrder: _.range(0, 7) as DayOfWeek[],
  fromSunday: _.range(0, 7) as DayOfWeek[],
  fromMonday: [1, 2, 3, 4, 5, 6, 0] as DayOfWeek[]
};

export function formatTimeOfDay(minutes: number, format: string = "hh:mm A") {
  return moment
    .utc()
    .startOf("day")
    .add(minutes, "minutes")
    .format(format);
}

export function getDayOfWeekName(dayOfWeek: DayOfWeek) {
  switch (dayOfWeek) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
  }
}

export function splitDateTime(dateTimeValue: Date | null) {
  const dateTime = dateTimeValue ? moment(dateTimeValue) : undefined;
  const date = dateTime ? dateTime.clone().startOf("day") : undefined;
  const time = dateTime ? dateTime.diff(date, "minutes") : undefined;

  return { date, time };
}
