import { FormTextInput, PhoneNumber, Validation } from "@zenfolio/core-components";
import * as React from "react";
import { getFirstError } from "../../../../../../containers/widget/selectors";
import styles from "./index.module.scss";

interface IContactInfoProps {
  email: string;
  name: string;
  phoneNumber: string;
  onNameChange(value: string): void;
  onEmailChange(value: string): void;
  onPhoneNumberChange(value: string): void;
  onValidationChanged(contactInfoValid: boolean): void;
}

interface IContactInfoState {
  email: string;
  name: string;
  phoneNumber: string;
  contactInfoValid: boolean;
}

class ContactInfo extends React.Component<IContactInfoProps, IContactInfoState> {
  constructor(props: IContactInfoProps) {
    super(props);

    this.state = {
      name: props.name || "",
      email: props.email || "",
      phoneNumber: props.phoneNumber || "",
      contactInfoValid: false
    };
  }

  public componentDidMount() {
    this.updateValidate();
  }

  public render() {
    const { name, email, phoneNumber } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.title}>
            <p>Your contact information</p>
          </div>

          <div className={styles.field}>
            <FormTextInput
              className={styles.textInput}
              errorClassName={styles.textInputError}
              value={name}
              placeholder="Name"
              onChange={this.onNameChange}
              maxLength={120}
              errorMessage={this.nameErrorMessage}
            />
          </div>
          <div className={styles.field}>
            <FormTextInput
              className={styles.textInput}
              errorClassName={styles.textInputError}
              value={email}
              placeholder="Email"
              onChange={this.onEmailChange}
              maxLength={Validation.maxEmailTotalLength}
              errorMessage={this.emailErrorMessage}
            />
          </div>
          <div className={styles.field}>
            <PhoneNumber
              className={styles.textInput}
              errorClassName={styles.textInputError}
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={this.onPhoneNumberChange}
              errorMessage={this.phoneNumberErrorMessage}
              maxLength={Validation.maxPhoneNumberLength}
            />
          </div>
        </div>
      </div>
    );
  }

  private get nameErrorMessage() {
    const errors = [Validation.errorIfEmpty(this.state.name, "Please provide a name.")];

    return getFirstError(errors);
  }

  private get emailErrorMessage() {
    const errors = [
      Validation.errorIfEmpty(this.state.email, "Please provide an email address."),
      !Validation.isValidEmail(this.state.email) ? "Verify your email address." : ""
    ];

    return getFirstError(errors);
  }

  private get phoneNumberErrorMessage() {
    const errors = [
      Validation.errorIfEmpty(this.state.phoneNumber, "Please provide a phone number."),
      !Validation.isValidPhoneNumber(this.state.phoneNumber) ? "Verify your phone number." : ""
    ];

    return getFirstError(errors);
  }

  private onNameChange = (value: string) => {
    this.setState(
      {
        name: value
      },
      this.updateValidate
    );
    this.props.onNameChange(value);
  };

  private onEmailChange = (value: string) => {
    this.setState(
      {
        email: value
      },
      this.updateValidate
    );

    this.props.onEmailChange(value);
  };

  private onPhoneNumberChange = (value: string) => {
    this.setState(
      {
        phoneNumber: value
      },
      this.updateValidate
    );

    this.props.onPhoneNumberChange(value);
  };

  private isContactInformationValid = (): boolean => {
    return !this.nameErrorMessage && !this.emailErrorMessage && !this.phoneNumberErrorMessage;
  };

  private updateValidate = () => {
    const { onValidationChanged } = this.props;

    const contactInfoValid = this.isContactInformationValid();
    const contactInfoValidChanged = this.state.contactInfoValid !== contactInfoValid;

    if (contactInfoValidChanged) {
      this.setState(
        {
          contactInfoValid
        },
        () => onValidationChanged(contactInfoValid)
      );
    }
  };
}

export default ContactInfo;
