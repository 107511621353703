import React, { ReactNode } from "react";
import { GoogleCalendarModalCode, IGoogleCalendarModalProps } from "../../../../store/modal/model";
import withModal, { InjectedModalProps } from "../../withModal";
import Disconnect from "./disconnect";
import NoCalendarWarning from "./noCalendarWarning";
import SelectCalendar from "./selectCalendar";
import withStore from "./withStore";

type InternalModalCode = GoogleCalendarModalCode | "no-calendar-warning";

interface IGoogleCalendarProps extends InjectedModalProps<IGoogleCalendarModalProps> {
  onReset: () => void;
}

interface IGoogleCalendarState {
  modal: InternalModalCode;
}

class GoogleCalendar extends React.Component<IGoogleCalendarProps, IGoogleCalendarState> {
  public state: IGoogleCalendarState;

  constructor(props: IGoogleCalendarProps) {
    super(props);
    this.state = { modal: props.modal };
  }

  public componentWillUnmount() {
    this.props.onReset();
  }

  public render() {
    const { modal } = this.state;

    const modalRenderers: Record<InternalModalCode, () => ReactNode> = {
      "select-calendar": () => this.renderSelectCalendar(),
      "no-calendar-warning": () => this.renderNoCalendarWarning(),
      disconnect: () => this.renderDisconnect()
    };

    return modalRenderers[modal]();
  }

  private renderSelectCalendar() {
    return <SelectCalendar currentCalendar={this.props.currentCalendar!} onClose={this.onSelectCalendarClose} />;
  }

  private renderNoCalendarWarning() {
    return <NoCalendarWarning onOk={this.onNoCalendarWarningOk} onCancel={this.onNoCalendarWarningCancel} />;
  }

  private renderDisconnect() {
    return <Disconnect onClose={this.onDisconnectClose} />;
  }

  private onNoCalendarWarningOk = () => this.props.onHideModal();

  private onNoCalendarWarningCancel = () => this.setState({ modal: "select-calendar" });

  private onDisconnectClose = () => this.props.onHideModal();

  private onSelectCalendarClose = (calendarSelected: boolean) =>
    calendarSelected ? this.props.onHideModal() : this.setState({ modal: "no-calendar-warning" });
}

export default withModal(withStore(GoogleCalendar));
