import React, { createRef } from "react";
import ReCaptchaV2, { ReCAPTCHA } from "react-google-recaptcha";

export interface IReCaptchaProps {
  isEnabled: boolean;
}

class ReCaptcha extends React.Component<IReCaptchaProps> {
  constructor(props: IReCaptchaProps) {
    super(props);
  }

  private recaptchaRef = createRef<ReCAPTCHA>();
  
  public getToken = async () => {
    const captchaToken = await this.recaptchaRef?.current?.executeAsync();
    return captchaToken || "";
  }

  public render() {
    if (!this.props.isEnabled){
      return null;
    }

    return (
      <ReCaptchaV2
        ref={this.recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
      />
    );
  }
}

export default ReCaptcha;