import _ from "lodash";
import * as React from "react";
import { universalWindow as window } from "utilities/blocks/site";

export interface IWindowSize {
  width: number;
  height: number;
}

export interface IInjectedWindowSizeProps {
  window: IWindowSize;
}

interface IWithWindowSizeState {
  window: IWindowSize;
}

const withWindowSize = <P extends IInjectedWindowSizeProps>(
  Component: React.ComponentType<P>,
  throttle?: number
): React.ComponentType<Pick<P, Exclude<keyof P, keyof IInjectedWindowSizeProps>>> => {
  class WithWindowSize extends React.Component<
    Pick<P, Exclude<keyof P, keyof IInjectedWindowSizeProps>>,
    IWithWindowSizeState
  > {
    public state: IWithWindowSizeState = {
      window: { width: window.innerWidth, height: window.innerHeight }
    };

    public componentDidMount() {
      window.addEventListener("resize", this.onWindowResize);
    }

    public componentWillUnmount() {
      window.removeEventListener("resize", this.onWindowResize);
    }

    public render() {
      return <Component {...(this.props as any)} window={this.state.window} />;
    }

    private onWindowResize = _.throttle(() => {
      const windowSize = {
        width: window.innerWidth,
        height: window.innerHeight
      };
      if (!_.isEqual(windowSize, this.state.window)) {
        this.setState({ window: windowSize });
      }
    }, throttle);
  }

  return WithWindowSize;
};

export default withWindowSize;
