import React from "react";
import { connect } from "react-redux";
import { IAppState } from "../../store/state";
import ApproveBooking from "./modals/approveBooking";
import BookableHours from "./modals/bookableHours";
import BookingBilling from "./modals/bookingBilling";
import CancelBooking from "./modals/cancelBooking";
import Confirmation from "./modals/confirmation";
import ConnectError from "./modals/connectError";
import DeclineBooking from "./modals/declineBooking";
import GoogleCalendar from "./modals/googleCalendar";
import RescheduleBooking from "./modals/rescheduleBooking";
import WidgetError from "./modals/widgetError";

export enum ModalTypes {
  CONNECT_ERROR = "CONNECT_ERROR",
  CONFIRMATION = "CONFIRMATION",
  BOOKABLE_HOURS = "BOOKABLE_HOURS",
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  WIDGET_ERROR = "WIDGET_ERROR",
  CANCEL_BOOKING = "CANCEL_BOOKING",
  APPROVE_BOOKING = "APPROVE_BOOKING",
  DECLINE_BOOKING = "DECLINE_BOOKING",
  BOOKING_BILLING = "BOOKING_BILLING",
  RESCHEDULE_BOOKING = "RESCHEDULE_BOOKING"
}

const MODAL_COMPONENTS: any = {
  CONNECT_ERROR: ConnectError,
  CONFIRMATION: Confirmation,
  BOOKABLE_HOURS: BookableHours,
  GOOGLE_CALENDAR: GoogleCalendar,
  WIDGET_ERROR: WidgetError,
  CANCEL_BOOKING: CancelBooking,
  APPROVE_BOOKING: ApproveBooking,
  DECLINE_BOOKING: DeclineBooking,
  BOOKING_BILLING: BookingBilling,
  RESCHEDULE_BOOKING: RescheduleBooking
};

const ModalRoot: React.FC<any> = props => {
  if (!props.modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[props.modalType];

  return <SpecificModal />;
};

const mapStateToProps = (state: IAppState) => ({
  modalType: state.modal.modalType
});

export default connect(mapStateToProps)(ModalRoot);
