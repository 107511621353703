import cx from "classnames";
import AlbumLock from "components/Icons/AlbumLock";
import Image from "components/Image";
import React from "react";
import { isIOS } from "react-device-detect";
import styled, { keyframes } from "styled-components";
import { getSiteFonts, universalWindow as window } from "utilities/blocks/site";
import {
  executeHeroScroll,
  getFinalInteractionUrl,
  getInteractionUrlType
} from "utilities/blocks/blockInteractions";
import ContextMenu from "utilities/contextMenu";
import { padNumber, stringIsGuid } from "utilities/blocks/string";
import colors from "utilities/colors";
import CustomCarousel from "../../CustomCarousel";
import CaretDown from "../../Icons/CaretDown";
import { ILink, IRoutes, IZenSiteTheme } from "../../../models/models";
import isEmpty from "lodash/isEmpty";
import styles from "./zenHeroCarousel.module.scss";
import {
  HeroAnimationType,
  HeroCaretType,
  HeroNavigationType,
  HeroSpeedType,
  HeroTransitionType,
  LinkTypeOption
} from "utilities/constant";
import { ICarouselImageData } from "../../../models/blocks";
import { PageContext } from "utilities/pageContext";
import { ScreenSizeBreakPoints } from "../../../utilities/screenResolution";

export interface IHeroCarouselBlockProps {
  customNavbarHeight?: number;
  readOnly?: boolean;
  desktopEnabled?: boolean;
  tabletEnabled?: boolean;
  mobileEnabled?: boolean;
  caret?: HeroCaretType;
  animation: HeroAnimationType;
  navigation: HeroNavigationType;
  transition: HeroTransitionType;
  speed: HeroSpeedType;
  layout: string;
  selectedIndex?: number;
  pages?: any;
  routes?: IRoutes;
  isEditionView?: boolean;
  isPublish?: boolean;
  siteTheme: IZenSiteTheme;
  emptyFolderImage?: string;
  photos: ICarouselImageData[];
  captionsEnabled: boolean;
  onUpdateDimensions?: () => void;
  getInteractionUrl?: getInteractionUrlType;
}

interface IHeroCarouselState {
  isHoverCarousel: boolean;
  controlHeight: string;
  controlHeightNumber: number;
  navBarHeight?: number;
  currentPicture: number;
}

export class ZenHeroCarouselBlock extends React.Component<IHeroCarouselBlockProps, IHeroCarouselState> {
  private Container: React.RefObject<HTMLDivElement>;

  static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  constructor(props: IHeroCarouselBlockProps) {
    super(props);
    this.state = {
      isHoverCarousel: true,
      controlHeight: "",
      controlHeightNumber: 0,
      currentPicture: -1
    };
    this.Container = React.createRef();
  }

  public componentDidMount() {
    this.updateControlHeight();
    this.determineVisibility();
    window.addEventListener("resize", this.changeOrientation);
    window.addEventListener("orientationchange", this.changeOrientation);
  }

  public componentDidUpdate() {
    if (this.state.navBarHeight !== this.context.navBarHeight) {
      this.setState({ navBarHeight: this.context.navBarHeight }, () => this.updateControlHeight());
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.changeOrientation);
    window.removeEventListener("orientationchange", this.changeOrientation);
  }

  private determineVisibility = () => {
    const { desktopEnabled, tabletEnabled, mobileEnabled } = this.props;

    if (window.innerWidth <= ScreenSizeBreakPoints.Mobile && mobileEnabled) {
      this.context.setHasHero();
    } else if (
      window.innerWidth > ScreenSizeBreakPoints.Mobile &&
      window.innerWidth <= ScreenSizeBreakPoints.Tablet &&
      tabletEnabled
    ) {
      this.context.setHasHero();
    } else if (desktopEnabled) {
      this.context.setHasHero();
    }
  };

  private cancelDrag = (event: any) => {
    event.preventDefault();
    return false;
  };

  private setCurrentPicture = (picture: number) => {
    if (picture !== this.state.currentPicture) {
      this.setState({ currentPicture: picture });
    }
  };

  private handleCaretClick = (e: any) => {
    if (this.Container.current) {
      executeHeroScroll(this.Container.current.clientHeight);
    }
  };

  private statusFormatter = (current: number, total: number): string => {
    return `${padNumber(current, 2)}/${padNumber(total, 2)}`;
  };

  private getLink = (
    label: string,
    disableEvents: boolean,
    isNewTab: string,
    interactionLink: string,
    link?: ILink
  ) => {
    if (disableEvents) {
      return (
        <div className={cx(styles.photoButton)}>
          <div>{label}</div>
        </div>
      );
    } else {
      return (
        <a
          rel="noopener noreferrer"
          target={isNewTab}
          href={link?.button ? interactionLink : ""}
          className={cx(styles.photoButton)}
        >
          <div>{label}</div>
        </a>
      );
    }
  };

  private getPhotoInfo = (photo: ICarouselImageData) => {
    let interactionLink = "";
    if (photo.interactionType !== LinkTypeOption.NONE || (photo.link && this.props.pages)) {
      if (this.props.isPublish && this.props.getInteractionUrl) {
        interactionLink = this.props.getInteractionUrl(
          this.props.pages,
          photo.link?.type ? photo.link?.type : photo.interactionType || "",
          photo.link?.url ? photo.link?.url : photo.interactionUrl || "",
          photo.link?.page ? photo.link?.page : photo.interactionPage || "",
          this.props.routes ? this.props.routes : {},
          photo.link?.albumFolderData?.album.id || "",
          photo.link?.albumFolderData
            ? {
                gridType: photo.link?.albumFolderData?.album.gridType,
                title: photo.link?.albumFolderData?.title,
                folderGridType: photo.link?.albumFolderData?.folder.gridType,
                showTitles: photo.link?.albumFolderData?.showTitleAndText
              }
            : null,
          photo.link?.albumFolderData?.folder.id || "",
          photo.link?.albumFolderData?.galleryFolderAlias
        );
      } else {
        interactionLink = getFinalInteractionUrl(
          this.props.isPublish ? this.props.isPublish : false,
          this.props.pages,
          photo.interactionUrl,
          photo.interactionPage,
          photo.interactionType,
          this.props.routes ? this.props.routes : {},
          photo.link
        );
      }
    }

    let viewMore = photo.link ? photo.link?.label : "";
    if (isEmpty(viewMore)) {
      viewMore = "View More";
    }
    const isNewTab = photo.interactionNewTab || photo.link?.newTab ? "_blank" : "";
    const disableEvents =
      this.props.isEditionView ||
      (!interactionLink && !photo?.link) ||
      (photo?.link?.type === LinkTypeOption.GALLERY && !photo?.link?.albumFolderData?.imageUrl) ||
      (photo?.link?.type === LinkTypeOption.URL && (!photo?.link?.url || photo?.link?.url.trim() === ""));
    const showButton = photo.link?.button === true;
    const isShowCaption = this.props.captionsEnabled === true;
    return (
      <React.Fragment>
        {isShowCaption && (
          <div className={cx(styles.photoPanel, { [`slide-${photo.index}`]: this.props.isEditionView })}>
            <div className={styles.photoInfo}>
              {photo.title && (
                <div
                  className={cx(styles.linkContainer, {
                    [styles.linkClickable]: !disableEvents
                  })}
                >
                  {this.getLink(photo.title, disableEvents, isNewTab, interactionLink, photo.link)}
                </div>
              )}
              {photo.caption && (
                <div
                  className={cx(styles.linkContainer, {
                    [styles.linkClickable]: !disableEvents
                  })}
                >
                  {this.getLink(photo.caption, disableEvents, isNewTab, interactionLink, photo.link)}
                </div>
              )}
            </div>
            {showButton && (
              <div className={styles.photoButtonContainer}>
                <div className={cx({ [styles.linkClickable]: !disableEvents })}>
                  {this.getLink(viewMore || "", disableEvents, isNewTab, interactionLink, photo.link)}
                </div>
              </div>
            )}
          </div>
        )}
        {this.props.animation === HeroAnimationType.SLIDE && this.getCaret(isShowCaption)}
      </React.Fragment>
    );
  };

  private getPhotoUrl = (photo: ICarouselImageData) => {
    let result = { photoUrl: photo.url, photoSrcset: photo.srcset };
    const isAlbumFolderType =
      photo.link?.type === LinkTypeOption.GALLERY || photo.link?.type === LinkTypeOption.ALBUMFOLDER;

    const isGalleryLink =
      isAlbumFolderType &&
      photo.link?.albumFolderData &&
      !photo.link.albumFolderData.isFolderFlow &&
      photo.link?.albumFolderData?.imageUrl;

    const isFolderLink =
      isAlbumFolderType &&
      photo.link?.albumFolderData &&
      photo.link.albumFolderData.isFolderFlow &&
      this.props.emptyFolderImage;

    if (!photo?.link?.replacedImage) {
      if (isGalleryLink && photo?.link?.albumFolderData) {
        result = { photoUrl: photo.link.albumFolderData.imageUrl, photoSrcset: photo.link.albumFolderData.imageSrcSet };
      } else if (isFolderLink && photo?.link?.albumFolderData && this.props.emptyFolderImage) {
        result = { photoUrl: this.props.emptyFolderImage, photoSrcset: "" }
      } else {
        result = { photoUrl: photo.url, photoSrcset: photo.srcset };
      }
    }

    return result;
  };

  private getCarouselSlideItem = (
    photo: ICarouselImageData,
    idx: number,
    controlHeight: string,
    isImageLock: boolean,
    isGhost: boolean
  ) => {
    const newFocalX = photo.focalX ?? 50;
    const newFocalY = photo.focalY ?? 50;

    const {photoUrl, photoSrcset} = this.getPhotoUrl(photo);

    return (
      <div key={`slide-${idx}'}`} style={{ height: controlHeight, position: "relative" }}>
        <Image
          src={photoUrl}
          srcset={photoSrcset}
          alt={photo.altText || photo.fileName}
          width={photo.width}
          height={photo.height}
          className={cx(styles.imageCoverSlide, { [styles.lockImage]: isImageLock })}
          styles={{
            objectPosition: newFocalX + "% " + newFocalY + "%"
          }}
          isGhost={isGhost}
        />
        {isImageLock && (
          <div className={styles.albumLock}>
            <AlbumLock color={colors.darkGrey} size={24} />
          </div>
        )}
        {this.getPhotoInfo(photo)}
      </div>
    );
  };

  private getCaret = (isContent: boolean) => {
    const { caret, siteTheme } = this.props;
    return (
      <React.Fragment>
        {caret && (
          <div className={isContent ? styles.caretContainer : styles.caretContainerNoContent}>
            <span className={styles.circle} onClick={this.handleCaretClick}>
              <CaretDown
                color={
                  !caret || caret === HeroCaretType.WHITE_CIRCLE
                    ? colors.white
                    : caret === HeroCaretType.BLACK_CIRCLE
                    ? colors.black
                    : siteTheme.accentColor.value
                }
                size={40}
              />
            </span>
          </div>
        )}
      </React.Fragment>
    );
  };

  private getCarouselScrollItem = (
    photo: ICarouselImageData,
    idx: number,
    controlHeight: string,
    isImageLock: boolean,
    isGhost: boolean
  ) => {
    const newFocalX = photo.focalX ?? 50;
    const newFocalY = photo.focalY ?? 50;

    const {photoUrl, } = this.getPhotoUrl(photo);

    let styleImage = {
      height: controlHeight,
      opacity: isGhost ? 0.4 : 1,
      backgroundImage: `url(${photoUrl})`,
      backgroundPosition: newFocalX + "% " + newFocalY + "%"
    } as React.CSSProperties;

    if (isImageLock) {
      styleImage.filter = `blur(10px)`;
      styleImage.WebkitFilter = `blur(10px)`;
      styleImage.msFilter = `blur(10px)`;
    }

    let animationClass = "";
    if (isIOS || window.innerWidth < 1024) {
      animationClass = styles.animationScrolInactive;
    } else {
      animationClass = styles.animationScrollActive;
    }
    return (
      <div style={{ position: "relative" }} key={`scroll-${idx}'}`}>
        <div style={styleImage} className={cx(styles.imageCoverScroll, animationClass)}></div>
        <div style={{ position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px" }}>
          {isImageLock && (
            <div className={styles.albumLock}>
              <AlbumLock color={colors.darkGrey} size={24} />
            </div>
          )}
          {this.getPhotoInfo(photo)}
        </div>
      </div>
    );
  };

  private getSpeed = () => {
    const { speed } = this.props;
    return speed === HeroSpeedType.SLOW ? 10000 : speed === HeroSpeedType.MEDIUM ? 8000 : 6000;
  };

  private getCarouselCrossFade = (
    photo: ICarouselImageData,
    idx: number,
    controlHeight: string,
    isImageLock: boolean,
    isGhost: boolean
  ) => {
    const newFocalX = photo.focalX ?? 50;
    const newFocalY = photo.focalY ?? 50;

    const maxOpacity = isGhost ? "0.4" : "1";

    const animationDuration = (this.props.photos.length * this.getSpeed()) / 1000;
    const noIntervals = animationDuration / this.props.photos.length;
    const intervalPer =
      (noIntervals / animationDuration + (noIntervals / animationDuration) * (this.props.photos.length / 100)) * 100 -
      1;

    let firstSlice = idx * intervalPer + 1 - intervalPer * 0.2;
    let mediumSlice = idx * intervalPer + intervalPer / 2;
    let lastSlice = idx * intervalPer + intervalPer + intervalPer * 0.2;

    let imageAnimationStr = `
    0% {
      opacity: ${idx === 0 ? maxOpacity : "0"};
      animation-timing-function: ease-in;
    }

    ${Math.round(firstSlice)}% {
      opacity: ${idx === 0 ? maxOpacity : "0"};
      transform: scale(1);
    }

    ${Math.round(mediumSlice)}% {
      opacity: ${maxOpacity};
      transform: scale(1.05);
    }`;

    if (lastSlice < 100) {
      imageAnimationStr += `${Math.round(lastSlice)}% {
        opacity: 0;
        transform: scale(1.01);
      }`;
    }

    if (idx === 0) {
      imageAnimationStr += `
    ${(this.props.photos.length - 1) * intervalPer + intervalPer / 2}% {
      opacity: 0;
      transform: scale(0.99);
    }`;
    }

    imageAnimationStr += `
    100% {
      opacity: ${idx === 0 ? maxOpacity : 0};
      transform: scale(1);
    }`;

    let imageAnimation = keyframes`${imageAnimationStr}`;

    const {photoUrl, } = this.getPhotoUrl(photo);

    let styleListItemSpan = {
      height: controlHeight,
      backgroundImage: `url(${photoUrl})`,
      backgroundPosition: newFocalX + "% " + newFocalY + "%"
    } as React.CSSProperties;

    if (isImageLock) {
      styleListItemSpan.filter = `blur(10px)`;
      styleListItemSpan.WebkitFilter = `blur(10px)`;
      styleListItemSpan.msFilter = `blur(10px)`;
    }

    const SpanAnimation = styled.span`
      animation: ${imageAnimation} ${animationDuration}s linear infinite 0s;
    `;

    if (idx > 0) {
      styleListItemSpan = {
        ...styleListItemSpan
      };
    }

    if (this.props.isEditionView) {
      const currentSelectedIndex = this.props.selectedIndex ? this.props.selectedIndex : 0;
      styleListItemSpan = {
        ...styleListItemSpan,
        opacity: currentSelectedIndex === idx ? maxOpacity : 0
      };
    }

    return (
      <li className={styles.imageCoverCross} key={`cross-fade-${idx}'}`}>
        <SpanAnimation style={styleListItemSpan}>Image</SpanAnimation>
        {isImageLock && (
          <div className={styles.albumLock}>
            <AlbumLock color={colors.darkGrey} size={24} />
          </div>
        )}
      </li>
    );
  };

  private getCarouselItem = (
    animationType: HeroAnimationType,
    photo: ICarouselImageData,
    idx: number,
    controlHeight: string
  ) => {
    let result: any = {};

    const isImageLock =
      (photo.link?.albumFolderData?.album.isGalleryProtected ||
        (photo.link?.albumFolderData?.album.isVisitorAllowed === false && !photo.link?.albumFolderData?.folder.id)) &&
      photo.link?.type === LinkTypeOption.GALLERY;
    const isGhost = !this.context.noGhost && !stringIsGuid(photo.id) && !photo.noGhost;

    switch (animationType) {
      case HeroAnimationType.SLIDE:
        result = this.getCarouselSlideItem(photo, idx, controlHeight, isImageLock, isGhost);
        break;
      case HeroAnimationType.SCROLL:
        result = this.getCarouselScrollItem(photo, idx, controlHeight, isImageLock, isGhost);
        break;
      case HeroAnimationType.CROSS_FADE:
        result = this.getCarouselCrossFade(photo, idx, controlHeight, isImageLock, isGhost);
        break;
    }

    return result;
  };

  public changeOrientation = () => {
    this.updateControlHeight();
  };

  public updateControlHeight = () => {
    const controlHeightEditor = "100vh";
    const isServer = typeof window === "undefined";

    let controlHeightPublish = "";
    if (!isServer) {
      controlHeightPublish = `${window.innerHeight}px`;
    } else {
      controlHeightPublish = controlHeightEditor;
    }

    const controlHeight = this.props.isEditionView ? controlHeightEditor : controlHeightPublish;
    this.setState({ controlHeightNumber: window.innerHeight, controlHeight });
  };

  public heightStr = () => `${window.innerHeight - this.context.navBarHeight}`;

  public render() {
    const {
      readOnly,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      navigation,
      transition,
      photos,
      siteTheme,
      captionsEnabled
    } = this.props;

    let containerClass = cx(styles.zenContainer);
    if (readOnly && !desktopEnabled) {
      containerClass = cx(containerClass, styles.hideDesktop);
    }
    if (readOnly && !tabletEnabled) {
      containerClass = cx(containerClass, styles.hideTablet);
    }
    if (readOnly && !mobileEnabled) {
      containerClass = cx(containerClass, styles.hideMobile);
    }

    const autoPlay = transition === HeroTransitionType.AUTOMATIC && !this.props.isEditionView;

    let currentImageHasEmptyData = false;
    if (this.state.currentPicture >= 0) {
      const currentPhoto = photos[this.state.currentPicture];
      if (currentPhoto) {
        currentImageHasEmptyData = !(currentPhoto.title || currentPhoto.caption || currentPhoto.linkCaption);
      }
    }

    const height = `calc(${this.state.controlHeight} - ${this.context.navBarHeight}px)`;
    const isShowCaption = captionsEnabled === true;
    const hasGhostImages = photos.some(img => !stringIsGuid(img.id));

    const containerStyles: React.CSSProperties =
      (this.props.animation === HeroAnimationType.SCROLL || this.props.animation === HeroAnimationType.CROSS_FADE) &&
      hasGhostImages
        ? { height, backgroundColor: "transparent" }
        : { height };

    const handleMouseEnter = () => {
      if (this.props.animation !== HeroAnimationType.CROSS_FADE) {
        this.setState({ isHoverCarousel: true });
      }
    };

    const handleMouseLeave = () => {
      if (this.props.animation !== HeroAnimationType.CROSS_FADE) {
        this.setState({ isHoverCarousel: false });
      }
    };
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    return (
      <div ref={this.Container} onDragStart={this.cancelDrag} className={containerClass}>
        <style>
          {`
            :root {
              --arrow-color: ${navigation};
              --hoverBottom: ${this.props.animation === HeroAnimationType.CROSS_FADE ? "40px" : "150px"};
              --navHeight: ${this.heightStr()}px;
              --template-bg-color: ${siteTheme.backgroundColor.value}
              }
            `}
        </style>
        <div
          style={containerStyles}
          className={cx(
            styles.zenContent,
            this.state.isHoverCarousel ? styles.onCarouselHover : styles.onCarouselNotHover,
            styles[siteFonts.secondary],
            { [styles.sliderGhost]: hasGhostImages && this.props.animation === HeroAnimationType.SLIDE }
          )}
          onContextMenu={ContextMenu.handleBlockContextMenu}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <CustomCarousel
            useKeyboardArrows={!this.props.isEditionView}
            animationType={this.props.animation}
            showThumbs={false}
            showIndicators={false}
            infiniteLoop
            isEditionView={this.props.isEditionView}
            controlHeightStyle={`calc(${this.state.controlHeight} - ${this.context.navBarHeight}px)`}
            navbarHeight={this.context.navBarHeight}
            controlHeight={this.state.controlHeightNumber - this.context.navBarHeight}
            selectedItem={this.props.selectedIndex}
            autoPlay={autoPlay}
            interval={this.getSpeed()}
            statusFormatter={this.statusFormatter}
            onSetCurrentPicture={this.setCurrentPicture}
          >
            {photos.map((photo: ICarouselImageData, idx: number) => {
              return this.getCarouselItem(
                this.props.animation,
                photo,
                idx,
                `calc(${this.state.controlHeight} - ${this.context.navBarHeight}px)`
              );
            })}
          </CustomCarousel>
          {this.props.animation !== HeroAnimationType.SLIDE &&
            this.getCaret(!currentImageHasEmptyData && isShowCaption)}
        </div>
      </div>
    );
  }
}
