import cx from "classnames";
import React from "react";
import styles from "./tooltip.module.scss";

export enum TooltipType {
  Top,
  Bottom
}

interface ITooltipProps {
  children: React.ReactNode;
  active: boolean;
  type: TooltipType;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
}

const Tooltip: React.FC<ITooltipProps> = props => {
  let tooltipClass;
  switch (props.type) {
    case TooltipType.Top:
      tooltipClass = styles.tooltipTop;
      break;
    case TooltipType.Bottom:
      tooltipClass = styles.tooltipBotom;
      break;
    default:
      tooltipClass = styles.tooltipBotom;
      break;
  }
  return (
    <div
      className={props.active ? cx(tooltipClass, styles.active) : cx(tooltipClass, styles.inactive)}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default Tooltip;
