import React, { Component } from "react";
import cx from "classnames";
import _ from "lodash";

import styles from "./scrollAnimation.module.scss";

interface IScrollAnimationProps {
  offset: number;
  duration: number;
  initiallyVisible: boolean;
  delay: number;
  animateOnce: boolean;
  animatePreScroll: boolean;
  fadeInAndOut: boolean;
  isPreview: boolean;
  animateOut?: string;
  animateIn?: string;
  scrollableParentSelector?: string;
  className?: string;
  style?: any;
};

interface IScrollAnimationState {
  classes: string;
  style:  {
    animationDuration?: string,
    opacity?: number,
  };
};

interface IVisibility {
  isOnScreen: boolean,
  isInViewport: boolean,
};

export default class ScrollAnimation extends Component<IScrollAnimationProps, IScrollAnimationState> {
  public static defaultProps = {
    offset: 150,
    duration: 1,
    initiallyVisible: false,
    delay: 0,
    animateOnce: false,
    animatePreScroll: true,
  };

  private listener: () => void;
  private throttleListener!: ReturnType<typeof _.throttle>
  private visibility: IVisibility;
  private delayedAnimationTimeout!: NodeJS.Timeout;
  private callbackTimeout!: NodeJS.Timeout;
  private isAnimating: boolean = false;
  private scrollableParent: any;
  private node?: HTMLElement | null;

  constructor(props: IScrollAnimationProps) {
    super(props);
    this.listener = this.handleScroll.bind(this);
    this.visibility = {
      isOnScreen: false,
      isInViewport: false,
    };

    this.state = {
      classes: "animated",
      style: {
        animationDuration: `${this.props.duration}s`,
        opacity: this.props.initiallyVisible ? 1 : 0.3,
      }
    };
  };

    private getElementTop(elm: HTMLElement) {
    let yPos = 0;

    while (elm?.offsetTop !== undefined && elm?.clientTop !== undefined) {
      yPos += elm.offsetTop + elm.clientTop;
      elm = elm.offsetParent as HTMLElement;
    };

    return yPos;
  };

  private getScrollPos(): number {
    if (this.scrollableParent.pageYOffset !== undefined) {
      return this.scrollableParent.pageYOffset;
    };

    return this.scrollableParent.scrollTop;
  };

  private calculatePercentage = () => {
    // default value to make opacity = 1
    let percent = 10;

    if(this.node) {
      const actualPos = this.getScrollPos() + window.innerHeight - 94 - this.getElementTop(this.node);

      percent = Number(
        (
          (actualPos * 100) /
          (this.getElementTop(this.node) + this.node.clientHeight - this.getElementTop(this.node))
        ).toFixed(10)
        // we need it to make start percentage value upper to 3% for better user experience
      ) + 3;
    };

    return percent;
  };

  private getScrollableParentHeight(): number {
    if (this.scrollableParent.innerHeight !== undefined) {
      return this.scrollableParent.innerHeight;
    };

    return this.scrollableParent.clientHeight;
  };

  private getViewportTop() {
    return this.getScrollPos() + this.props.offset;
  };

  private getViewportBottom() {
    return this.getScrollPos() + this.getScrollableParentHeight() - this.props.offset;
  };

  private checkIsInViewport(y: number) {
    return y >= this.getViewportTop() && y <= this.getViewportBottom();
  };

  private checkIsAboveViewport(y: number) {
    return y < this.getViewportTop();
  };

  private checkIsBelowViewport(y: number) {
    return y > this.getViewportBottom();
  };

  private checkIsInOrAroundViewport(elementTop: number, elementBottom: number) {
    return (
      this.checkIsInViewport(elementTop) ||
      this.checkIsInViewport(elementBottom) ||
      (this.checkIsAboveViewport(elementTop) && this.checkIsBelowViewport(elementBottom))
    );
  };

  private checkIsOnScreen(elementTop: number, elementBottom: number) {
    return !this.checkIsAboveScreen(elementBottom) && !this.checkIsBelowScreen(elementTop);
  };

  private checkIsAboveScreen(y: number) {
    return y < this.getScrollPos();
  };

  private checkIsBelowScreen(y: number) {
    return y > this.getScrollPos() + this.getScrollableParentHeight();
  };

  private getVisibility(): IVisibility {
    if (this.node && this.node !== null) {
      const elementTop = this.getElementTop(this.node) - this.getElementTop(this.scrollableParent);
      const elementBottom = elementTop + this.node.clientHeight;

      return {
        isOnScreen: this.checkIsOnScreen(elementTop, elementBottom),
        isInViewport: this.checkIsInOrAroundViewport(elementTop, elementBottom),
      };
    };

    return {
      isOnScreen: false,
      isInViewport: false,
    };
  };

  public componentDidMount() {
    this.scrollableParent = document.body;
    this.throttleListener = _.throttle(this.listener, 50)

    if (this.scrollableParent && this.scrollableParent.addEventListener) {
      this.scrollableParent.addEventListener("scroll", this.throttleListener);
    } else {
      console.warn(`Cannot find element by locator: ${this.props.scrollableParentSelector}`);
    };

    if (this.props.animatePreScroll) {
      this.handleScroll();
    };
  };

  public componentWillUnmount() {
    clearTimeout(this.delayedAnimationTimeout);
    clearTimeout(this.callbackTimeout);

    window?.removeEventListener("scroll", this.throttleListener);
  };

  checkWasVisibilityChanged(previousVis: IVisibility, currentVis: IVisibility) {
    return previousVis.isInViewport !== currentVis.isInViewport || previousVis.isOnScreen !== currentVis.isOnScreen;
  };

    private animate(animation: string, callback: () => void) {
    this.delayedAnimationTimeout = setTimeout(() => {
      this.isAnimating = true;
      this.setState({
        classes: `animated ${animation}`,
        style: {
          animationDuration: `${this.props.duration}s`,
        }
      });
      this.callbackTimeout = setTimeout(callback, this.props.duration * 1000);
    }, this.props.delay);
  };

    private animateIn(animation: string) {
    this.animate(animation, () => {
      if (!this.props.animateOnce) {
        this.setState({
          style: {
            animationDuration: `${this.props.duration}s`,
            opacity: 1,
          }
        });
        this.isAnimating = false;
      };
    });
  };

  private handleFadeIn() {
    const currentVis = this.getVisibility();

    if (this.checkWasVisibilityChanged(this.visibility, currentVis)) {
      clearTimeout(this.delayedAnimationTimeout);
      if (currentVis.isOnScreen && currentVis.isInViewport && this.props.animateIn) {
        this.animateIn(this.props.animateIn);
      };
      this.visibility = currentVis;
    };
  };

  private handleFadeInOut() {
    const percentage = this.calculatePercentage();

    this.setState({
      style: {
        opacity: Number((percentage / 100).toFixed(10)),
      }
    });
  };

  private handleScroll() {
    if (!this.isAnimating) {
      if (this.props.fadeInAndOut) {
        this.handleFadeInOut();
      } else {
        this.handleFadeIn();
      };
    };
  };

  public render() {
    const classes = cx(
      this.props.className ? `${this.props.className} ${this.state.classes}` : this.state.classes,
      styles.scrollFxInRange,
    );

    return (
      <div
        ref={node => {
          this.node = node;
        }}
        className={classes}
        style={Object.assign({}, this.state.style, this.props.style)}
      >
        {this.props.children}
      </div>
    );
  };
};
