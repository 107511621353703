import { FormTextArea, Utilities } from "@zenfolio/core-components";
import classNames from "classnames";
import React from "react";
import FractionalPriceInput, { IFractionalValue } from "../../../../../../components/fractionalPriceInput";
import styles from "./index.module.scss";

export type Amount = IFractionalValue | null;

interface ISetupProps {
  amount: Amount;
  clientNote?: string;
  internalNote?: string;
  sendNotification: boolean;
  maxAmount: number;
  isTaxable: boolean;
  taxAmount?: number;
  onChange: (
    amount: Amount,
    valid: boolean,
    sendNotification: boolean,
    clientNote?: string,
    internalNote?: string
  ) => void;
  onAmountBlur: (amount: Amount, valid: boolean) => void;
}

interface ISetupState {
  amount: Amount;
  valid: boolean;
  clientNote?: string;
  internalNote?: string;
  sendNotification: boolean;
}

class Setup extends React.Component<ISetupProps, ISetupState> {
  public state: ISetupState = Setup.defaultState;

  constructor(props: ISetupProps) {
    super(props);

    this.state = {
      ...Setup.defaultState,
      amount: this.props.amount,
      clientNote: this.props.clientNote,
      internalNote: this.props.internalNote,
      sendNotification: this.props.sendNotification
    };
  }

  public componentDidUpdate(prevProps: ISetupProps, prevState: ISetupState) {
    const { sendNotification } = this.props;
    if (sendNotification !== prevProps.sendNotification) {
      this.setState({ sendNotification });
    }
  }

  public render() {
    const { maxAmount, sendNotification, isTaxable, taxAmount } = this.props;
    const { amount: newAmount, valid, clientNote, internalNote } = this.state;

    const limits = `Min. ${Utilities.formatNumberWithCurrency(maxAmount)} Max. ${Utilities.formatNumberWithCurrency(
      maxAmount
    )}`;

    const total =
      newAmount != null && (!isTaxable || taxAmount != null) ? newAmount.floatValue + (taxAmount || 0) : null;
    const currencySymbol = Utilities.getCurrencyInfo(Utilities.getCurrencyFormattingInfo("US").currencyCode).symbol;

    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.newInvoice}>
            <div className={styles.amount}>
              <label>
                Amount
                <br />
                to Invoice
              </label>
              <FractionalPriceInput
                value={newAmount}
                errorMessage={valid ? null : limits}
                inputClassName={styles.input}
                errorMessageClassName={styles.error}
                onChange={this.onAmountChange}
                onBlur={this.onAmountBlur}
              />
            </div>
            {isTaxable && (
              <div className={styles.tax}>
                <label>Tax</label>
                <em>{taxAmount != null ? Utilities.formatFractionalNumberWithCurrency(taxAmount) : currencySymbol}</em>
              </div>
            )}
            <div className={styles.total}>
              <label>TOTAL</label>
              <em>{total != null ? Utilities.formatFractionalNumberWithCurrency(total) : currencySymbol}</em>
            </div>
          </div>
        </div>

        <div className={classNames(styles.note, !sendNotification && styles.disabled)}>
          Note to Client <span className={styles.tips}>(optional)</span>
        </div>
        <FormTextArea
          className={styles.textAreaWrapper}
          textAreaClassName={classNames(styles.textArea, styles.noteToClient)}
          value={clientNote}
          disabled={!sendNotification}
          onChange={this.onClientNoteChanged}
          maxLength={1000}
          charCount={false}
        />

        <div className={styles.note}>
          Internal Note <span className={styles.tips}>(optional)</span>
        </div>
        <FormTextArea
          className={styles.textAreaWrapper}
          textAreaClassName={styles.textArea}
          placeholder={"Leave yourself a note…"}
          value={internalNote}
          onChange={this.onInternalNoteChanged}
          maxLength={1000}
          charCount={false}
        />
      </div>
    );
  }

  private onAmountChange = (value: Amount) => this.setAmount(value);

  private onAmountBlur = () => {
    this.props.onAmountBlur(this.state.amount, this.state.valid);
  };

  private setAmount = (amount: Amount) => {
    const valid = !!amount && 1 <= amount.floatValue && amount.floatValue <= this.props.maxAmount;
    this.setState({ amount, valid }, this.onChange);
  };

  private onClientNoteChanged = (clientNote: string) => {
    this.setState({ clientNote }, this.onChange);
  };

  private onInternalNoteChanged = (internalNote: string) => {
    this.setState({ internalNote }, this.onChange);
  };

  private onChange = () => {
    const { amount, valid, sendNotification, internalNote, clientNote } = this.state;
    this.props.onChange(amount, valid, sendNotification, clientNote, internalNote);
  };

  public static defaultState: ISetupState = {
    amount: null,
    valid: false,
    sendNotification: true,
    clientNote: undefined,
    internalNote: undefined
  };
}

export default Setup;
