import React from "react";

function RulerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="#757575">
        <path fill="#FFF" d="M3 16V0h10v16"/>
        <circle cx="8" cy="3" r="1"/>
        <path
          strokeLinecap="square"
          d="M8.5 7.5h4m-2.833 2h2.666m-2.666 2h2.666m-3.833 2h4"
        />
      </g>
    </svg>
  );
}

export default RulerIcon;
