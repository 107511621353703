import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { EventData as SwipeEventData, Swipeable } from "react-swipeable";
import styles from "./monthPicker.module.scss";

interface IMonthPickerProps {
  defaultMonth?: moment.Moment;
  month?: moment.Moment;
  maxMonth?: moment.Moment;
  minMonth?: moment.Moment;
  onChange?: (month: moment.Moment) => void;
  className?: string;
  style?: React.CSSProperties;
}

interface IMonthPickerState {
  month?: moment.Moment;
  lastAnimMonth?: moment.Moment;
  swipeDistance?: number;
}

class MonthPicker extends React.Component<IMonthPickerProps, IMonthPickerState> {
  public state: IMonthPickerState = {};

  public componentDidMount() {
    this.setState({ lastAnimMonth: this.month });
    this.monthsRef.current!.addEventListener("animationend", this.onAnimEnd);
  }

  public componentWillUnmount() {
    this.monthsRef.current!.removeEventListener("animationend", this.onAnimEnd);
  }

  public render() {
    const { className, style } = this.props;
    const { lastAnimMonth, swipeDistance } = this.state;
    const currentMonth = lastAnimMonth || this.month;
    const animating = this.animating;
    const months = [-3, -2, -1, 0, 1, 2, 3].map(o => currentMonth.clone().add(o, "month"));

    const isCurrentMonth = (month: moment.Moment) => month.isSame(this.month, "month");
    const scaleFn = (month: moment.Moment) => {
      const position = month.diff(this.month, "month") * monthsUI.distance - swipeDistance!;
      const scale = 1 - Math.min(1, Math.abs(position) / monthsUI.distance) * (1 - monthsUI.scale);
      return Math.round(scale * 100) / 100;
    };

    if (this.monthsRef.current) {
      if (animating) {
        const newMonthsDiff = lastAnimMonth!.diff(this.month, "month");
        if (newMonthsDiff !== this.lastMonthsDiff) {
          this.lastMonthsDiff = newMonthsDiff;
          this.monthsRef.current.style.setProperty(
            "--months-anim-transform",
            `translateX(calc(100% / 7 * ${newMonthsDiff}))`
          );
        }
      }

      const newSwipeDistance = swipeDistance || 0;
      if (newSwipeDistance !== this.lastSwipeDistance) {
        this.lastSwipeDistance = newSwipeDistance;
        this.monthsRef.current.style.setProperty(
          "--months-swipe-transform",
          `translateX(${-(newSwipeDistance || 0)}px)`
        );
      }
    }

    return (
      <div className={classNames(styles.container, className)} style={style}>
        <Swipeable
          className={styles.monthsContainer}
          onSwiping={this.onMonthsSwiping}
          onSwipedLeft={this.onMonthsSwiped}
          onSwipedRight={this.onMonthsSwiped}
        >
          <div
            ref={this.monthsRef}
            className={classNames(styles.months, {
              [styles.anim]: animating,
              [styles.swipe]: !animating && swipeDistance !== undefined
            })}
          >
            {months.map(month => (
              <div
                key={month.format("YYYY-MM")}
                className={classNames(styles.month, {
                  [styles.current]: this.isInRange(month) && isCurrentMonth(month),
                  [styles.empty]: !this.isInRange(month)
                })}
                onClick={this.onMonthClick(month)}
                style={{ transform: swipeDistance === undefined || animating ? undefined : `scale(${scaleFn(month)})` }}
              >
                {this.isInRange(month) ? month.format("MMMM 'YY") : null}
              </div>
            ))}
          </div>
        </Swipeable>
        <div className={styles.curtain} />
      </div>
    );
  }

  private get month() {
    const { props, state } = this;

    return (props.month !== undefined
      ? props.month
      : state.month !== undefined
      ? state.month
      : props.defaultMonth || this.now
    )
      .clone()
      .startOf("month");
  }

  private get animating() {
    const { lastAnimMonth } = this.state;
    return lastAnimMonth && !lastAnimMonth.isSame(this.month, "month");
  }

  private get minNavigation() {
    return [-2, -1, 0].filter(o => this.isInRange(this.month.clone().add(o, "month")))[0];
  }

  private get maxNavigation() {
    return [2, 1, 0].filter(o => this.isInRange(this.month.clone().add(o, "month")))[0];
  }

  private isInRange = (month: moment.Moment) =>
    month.isBetween(this.props.minMonth || month, this.props.maxMonth || month, "month", "[]");

  private boundedSwipeDistance = (swipeDistance: number) =>
    Math.max(this.minNavigation * monthsUI.distance, Math.min(this.maxNavigation * monthsUI.distance, swipeDistance));

  private boundedSwipeNavigation = (swipeDistance: number) =>
    (swipeDistance < 0 ? -1 : 1) * Math.ceil(Math.abs(this.boundedSwipeDistance(swipeDistance)) / monthsUI.distance);

  private changeMonth = (month: moment.Moment) => {
    if (this.animating || !this.isInRange(month) || month.isSame(this.month, "month")) {
      return;
    }

    const onChange = (m: moment.Moment) => {
      if (this.props.onChange) {
        this.props.onChange(m);
      }
    };

    if (this.props.month === undefined) {
      this.setState({ month }, () => onChange(this.month));
    } else {
      onChange(month);
    }
  };

  private onMonthClick = _.memoize(
    (month: moment.Moment) => () => this.changeMonth(month),
    month => (month as moment.Moment).format("YYYY-MM")
  );

  private onMonthsSwiping = (eventData: SwipeEventData) =>
    !this.animating && this.setState({ swipeDistance: this.boundedSwipeDistance(eventData.deltaX) });

  private onMonthsSwiped = (eventData: SwipeEventData) =>
    this.changeMonth(this.month.add(this.boundedSwipeNavigation(eventData.deltaX), "month"));

  private onAnimEnd = (event: AnimationEvent) => {
    if (event.target === this.monthsRef.current!) {
      this.setState({ lastAnimMonth: this.month, swipeDistance: undefined });
    }
  };

  private lastMonthsDiff: number = 0;
  private lastSwipeDistance: number = 0;
  private monthsRef = React.createRef<HTMLDivElement>();
  private readonly now = moment();
}

const monthsUI = { scale: 0.75, distance: 120 };

export default MonthPicker;
