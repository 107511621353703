import React, { PureComponent } from "react";
import styles from "./selectDropdown.module.scss";
import Select from "react-select";
import colors from "utilities/colors";
import cx from "classnames";
import { universalWindow as window } from "utilities/blocks/site";

const UpChevron = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="none"
      fillRule="evenodd"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16 14l-4-4-4 4"
    />
  </svg>
);

const DownChevron = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="none"
      fillRule="evenodd"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8 10l4 4 4-4"
    />
  </svg>
);

interface ISelectDropdown {
  placeholder?: string;
  options?: any;
  onChange: (value: any) => void;
  isSearchable?: boolean;
  paddingLeft?: number;
  value?: any;
  onInputChange?: any;
  icon?: any;
  onFocus?: () => void;
  optionColor: string;
  optionFontFamily?: string;
  background: string;
  placeholderColor: string;
  className?: string;
  fullWidth: boolean;
}

interface IOptionRadius {
  label: string;
  value: number;
}

export interface ISelectDropdownProps {
  viewportWidth: number;
  value: IOptionRadius | string | null;
  isOpen: boolean;
}

const noOptionsMsg = () => null;

class SelectDropdown extends PureComponent<ISelectDropdown, ISelectDropdownProps> {
  public state = {
    value: this.props.value ? (this.props.value.value ? this.props.value : null) : null,
    viewportWidth: window.innerWidth,
    isOpen: false
  };

  private handleMenuOpen = () => {
    this.setState({ isOpen: true });
  };

  private handleMenuClose = () => {
    this.setState({ isOpen: false });
  };

  public UNSAFE_componentWillMount() {
    this.updateWindowDimensions();
  }

  public componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { value, isReset, resetValuesFinished } = nextProps;
    if (isReset) {
      this.setState(
        {
          value: null
        },
        () => {
          resetValuesFinished();
        }
      );
    }
    if ((value && value.value !== this.props.value.value) || value === 0) {
      this.setState({
        value
      });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth });
  };

  public handleChange = (value: any) => {
    const { onChange } = this.props;
    this.setState({ value });
    onChange(value);
  };
  public render() {
    const DropdownIndicator = (props: any) => {
      return (
        <div className={styles.indicator}>
          {this.state.isOpen ? (
            <UpChevron color={this.props.optionColor} />
          ) : (
            <DownChevron color={this.props.optionColor} />
          )}
        </div>
      );
    };

    const icon = () => {
      if (this.props.icon) {
        return {
          alignItems: "center",
          display: "flex",

          ":before": {
            content: `url(${this.props.icon})`,
            display: "block",
            marginRight: 16,
            height: 11,
            width: 11,
            marginTop: -3
          }
        };
      } else {
        return {};
      }
    };
    const isMobileSize = this.state.viewportWidth < 767;
    const customStyles = {
      control: (provided: any, state: any) => {
        return {
          ...provided,
          minHeight: 50,
          height: 50,
          width: isMobileSize || this.props.fullWidth ? "100%" : "576px",
          boxShadow: "none",
          border: "none",
          background: this.props.background,
          color: this.props.optionColor,
          fontSize: 18,
          caretColor: colors.orange,
          borderRadius: "0",
          "&:hover": { borderColor: colors.inputGray }
        };
      },
      singleValue: (provided: any) => ({
        ...provided,
        lineHeight: "22px",
        paddingRight: 16,
        marginLeft: 8,
        maxWidth: "calc(100% - 55px)",
        color: this.props.optionColor
      }),
      input: (provided: any) => ({
        ...provided,
        ...icon(),
        margin: this.props.icon || this.props.isSearchable ? 0 : 2,
        div: { width: "100% !important" },
        input: {
          maxWidth: 50,
          width: "50 !important"
        }
      }),
      valueContainer: (provided: any) => ({
        paddingLeft: this.props.paddingLeft || 8,
        width: "100%"
      }),
      indicatorSeparator: () => ({
        display: "none"
      }),
      dropdownIndicator: () =>
        ({
          display: "block",
          paddingRight: 18,
          position: "absolute",
          right: 0
        } as React.CSSProperties),
      menu: (provided: any) => ({
        ...provided,
        paddingTop: 0,
        marginTop: "5px",
        width: isMobileSize || this.props.fullWidth ? "100%" : "576px",
        backgroundColor: this.props.background,
        borderRadius: "0"
      }),
      placeholder: (provided: any) => ({
        ...provided,
        fontSize: 18,
        color: `${this.props.placeholderColor} !important`,
        ...icon(),
        margin: this.props.icon || this.props.isSearchable ? 0 : 2
      }),
      menuList: (provided: any) => ({
        ...provided,
        padding: "0",
        height: "auto"
      }),
      option: (provided: any, state: any) => {
        return {
          ...provided,
          textOverflow: "elipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textAlign: "left",
          color: this.props.optionColor,
          fontWeight: 500,
          fontSize: 18,
          opacity: state.isDisabled ? ".5" : "1",
          padding: "16px",
          cursor: state.isDisabled ? "default" : "pointer",
          backgroundColor: this.props.background
        };
      }
    };
    return (
      <Select
        className={cx("react-select-container", styles.zenContainerDropdown, this.props.className)}
        classNamePrefix="react-select"
        styles={customStyles}
        options={this.props.options}
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
        value={this.state.value}
        isDisabled={false}
        isSearchable={this.props.isSearchable}
        onInputChange={this.props.onInputChange ? value => this.props.onInputChange(value) : undefined}
        noOptionsMessage={noOptionsMsg}
        onFocus={this.props.onFocus || undefined}
        filterOption={() => true}
        onMenuOpen={this.handleMenuOpen}
        onMenuClose={this.handleMenuClose}
        components={{ DropdownIndicator }}
      />
    );
  }
}

export default SelectDropdown;
