import React, { useEffect } from "react";
import styles from "./user.module.scss";
import cx from "classnames";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import ZenButton from "components/blocks/zenButton";
import AgreementContent from "./agreementContent";
import { getSiteFonts } from "utilities/blocks/site";
import { IZenSiteTheme } from "../../../../models/models";

interface IUserAgreementProps {
  show: boolean;
  siteTheme: IZenSiteTheme;
  onHideAgreement: () => void;
}

const UserAgreement: React.FC<IUserAgreementProps> = props => {
  const fontColor = getBlockTextColor(
    props.siteTheme.backgroundColor.value,
    props.siteTheme.accentColor.value,
    "none",
    props.siteTheme.backgroundColor.value
  );

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const keyPressHandler = (e: any) => {
    if (e.keyCode === 27) {
      props.onHideAgreement();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyPressHandler);

    return () => {
      window.removeEventListener("keydown", keyPressHandler);
    };
  }, []);
  const siteFonts = getSiteFonts(props.siteTheme.fontsStyle);
  return (
    <div
      className={cx(styles.modal, styles[siteFonts.primary], {
        [styles.hide]: !props.show
      })}
      onClick={props.onHideAgreement}
    >
      <div
        className={styles.container}
        style={{ backgroundColor: props.siteTheme.backgroundColor.value }}
        onClick={handleContainerClick}
      >
        <h2
          style={{
            color: fontColor
          }}
        >
          USER AGREEMENT
        </h2>
        {props.show && <AgreementContent siteTheme={props.siteTheme}></AgreementContent>}
        <div className={styles.buttonContainer}>
          <ZenButton
            siteTheme={props.siteTheme}
            labelText={"CLOSE"}
            layout="A"
            accentColor={props.siteTheme.accentColor}
            onClick={props.onHideAgreement}
          />
        </div>
      </div>
    </div>
  );
};

export default UserAgreement;
