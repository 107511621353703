import cx from "classnames";
import { ILink, INavigationMenuItem, IRoutes } from "../../../models/models";
import { GRID_TYPE, LinkTypeOption } from "utilities/constant";
import React from "react";
import { onLinkClick, getInteractionUrlType } from "utilities/blocks/blockInteractions";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import ZenBookmeButton from "../zenBookmeButton";
import ZenButton from "../zenButton";
import styles from "./zenButtonBlock.module.scss";
import { parseBody } from "utilities/blocks/string";

export interface IGallerySettings {
  gridType: GRID_TYPE;
  title?: string;
  folderGridType?: GRID_TYPE;
  showTitles: boolean;
}

export interface IButtonBlockBlockProps extends IZenBaseBlockProps {
  labelText?: string;
  interactionType?: string;
  interactionPage?: string;
  interactionUrl?: string;
  interactionNewTab?: boolean;
  link?: ILink;
  pages?: INavigationMenuItem[];
  routes?: IRoutes;
  disabled?: boolean;
  isDarkBackground?: boolean;
  isEditionView?: boolean;
  bookmeUrl?: string;
  isPublish?: boolean;
  getInteractionUrl?: getInteractionUrlType;
}

export class ZenButtonBlock extends React.Component<IButtonBlockBlockProps, any> {
  public static defaultProps = {
    labelText: "Button"
  };

  public shouldComponentUpdate(nextProps: IButtonBlockBlockProps) {
    return nextProps !== this.props;
  }

  public handleButtonClick = () => {
    const {
      pages,
      link,
      routes,
      interactionType,
      interactionPage,
      interactionUrl,
      interactionNewTab,
      getInteractionUrl
    } = this.props;

    if (pages && !this.props.isEditionView) {
      onLinkClick(
        pages,
        interactionNewTab,
        interactionUrl,
        interactionPage,
        interactionType,
        routes ? routes : {},
        link,
        this.props.isPublish,
        getInteractionUrl
      );
    }
  };

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      disabled,
      isDarkBackground,
      layout,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      fontFamily,
      divider,
      padding,
      link,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      isEditionView
    } = this.props;

    const labelText = parseBody(this.props.labelText || "");

    const baseButtonProps = {
      isDarkBackground,
      disabled,
      accentColor: siteTheme.accentColor,
      siteTheme,
      layout,
      labelText: link?.label || labelText
    };

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        readOnly={readOnly}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        selectedBlock={selectedBlock}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div className={styles.zenContainer}>
            <div className={cx(styles.content, { [styles.buttonClickable]: isEditionView })}>
              {link?.type === LinkTypeOption.BOOKME || this.props.interactionType === LinkTypeOption.BOOKME ? (
                <ZenBookmeButton {...baseButtonProps} bookmeUrl={link?.bookmeUrl! || this.props.bookmeUrl!} />
              ) : (
                <ZenButton {...baseButtonProps} onClick={this.handleButtonClick} />
              )}
            </div>
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}
