import { Utilities } from "@zenfolio/core-components";
import { IAddress } from "@zenfolio/core-components/dist/models/location";
import classNames from "classnames";
import * as React from "react";
import { ReactStripeElements } from "react-stripe-elements";
import { IInvoiceInfo, IInvoicePaymentCreator, IPaymentCreator } from "../../../../../store/widget/model";
import { ILoadInvoiceState, IPayInvoiceState } from "../../../../../store/widget/reducers";
import Auxiliary from "../../../../../utilities/auxiliary";
import eventTracker from "../../../../../utilities/eventTracker";
import { hasActionCompletedWithError, hasActionSuccessfullyCompleted } from "../../../../../utilities/helpers";
import Loader from "../../../../layout/loader";
import { addressMarginBottom, dropDownHeight } from "../../../../shoppingAddress";
import {
  Direction,
  emptyShoppingAddressComponents,
  IEditableWidgetStepProps,
  IShoppingAddressComponents,
  IValidatableWidgetStep
} from "../../../contracts";
import { buildPaymentMethodOptions, buildShoppingAddress, scrollToSelector } from "../../../helpers";
import CardInfo from "../common/cardInfo";
import CustomerInfo from "./customerInfo";
import PaymentDetails from "./details";
import styles from "./index.module.scss";

interface IPaymentProps extends IEditableWidgetStepProps, ReactStripeElements.InjectedStripeProps {
  onPayInvoice: (paymentCreator: IPaymentCreator, invoicePaymentCreator: IInvoicePaymentCreator) => void;
  onEnsureVisible: (top: number, height: number, minimum?: boolean) => void;
  loadInvoiceInfo: IInvoiceInfo;
  loadInvoiceInfoState: ILoadInvoiceState;
  payInvoiceState: IPayInvoiceState;
  invoiceId: string;
}

export interface IPaymentParams {}

interface IPaymentState extends IPaymentParams {
  cardholderName: string;
  zipCode: string;
  isCardInfoValid: boolean;
  detailsOpen: boolean;
  billingAddress: IShoppingAddressComponents;
  billingZipCodeError: boolean;
}

class PaymentStep extends React.Component<IPaymentProps, IPaymentState> implements IValidatableWidgetStep {
  constructor(props: IPaymentProps) {
    super(props);

    this.state = {
      zipCode: "",
      cardholderName: "",
      isCardInfoValid: false,
      detailsOpen: false,
      billingAddress: emptyShoppingAddressComponents,
      billingZipCodeError: false
    };
  }

  public componentDidUpdate(prevProps: IPaymentProps, prevState: IPaymentState) {
    if (
      !this.state.billingZipCodeError &&
      prevProps.payInvoiceState.paymentFailureCode !== this.props.payInvoiceState.paymentFailureCode &&
      this.props.payInvoiceState.paymentFailureCode === "zipCode"
    ) {
      this.setState({ billingZipCodeError: true });
    }

    if (this.state.billingZipCodeError && prevState.billingAddress.zipCode !== this.state.billingAddress.zipCode) {
      this.setState({ billingZipCodeError: false });
    }

    const loadInvoiceInfoHasCompleted = hasActionSuccessfullyCompleted(
      prevProps.loadInvoiceInfoState.status,
      this.props.loadInvoiceInfoState.status
    );

    if (loadInvoiceInfoHasCompleted) {
      const {
        country,
        houseNumber,
        streetName,
        city,
        state,
        zipCode,
        latitude,
        longitude,
        addressLine2,
        cardholderName,
        bookingId
      } = this.props.loadInvoiceInfo.bookingInfo;

      const billingAddress: IAddress = {
        country,
        houseNumber,
        streetName,
        city,
        state,
        zipCode,
        latitude,
        longitude
      };

      eventTracker.context.photographer.businessName = this.props.loadInvoiceInfo.photographerInfo.businessName;
      eventTracker.context.consumer.bookingId = bookingId;
      eventTracker.context.consumer.service = {
        name: this.props.loadInvoiceInfo.serviceInfo.name,
        duration: this.props.loadInvoiceInfo.bookingInfo.duration
      };
      eventTracker.events.consumer.paymentReached();

      this.setState({
        cardholderName,
        billingAddress: Utilities.isAddressWithStreet(billingAddress)
          ? {
              addressInputValue: billingAddress,
              addressLine2,
              city: billingAddress.city,
              state: billingAddress.state,
              zipCode: billingAddress.zipCode
            }
          : emptyShoppingAddressComponents
      });
    }

    const payInvoiceHasCompleted = hasActionSuccessfullyCompleted(
      prevProps.payInvoiceState.status,
      this.props.payInvoiceState.status
    );

    const payInvoiceHasCompletedWithError = hasActionCompletedWithError(
      prevProps.payInvoiceState.status,
      this.props.payInvoiceState.status
    );

    if (payInvoiceHasCompletedWithError && this.props.payInvoiceState.paymentFailureCode) {
      eventTracker.events.consumer.paymentFailed(this.props.payInvoiceState.paymentFailureCode);
    }

    if (payInvoiceHasCompleted) {
      eventTracker.events.consumer.paymentCompleted({
        paidAmount: this.props.loadInvoiceInfo.paymentActionInfo.totalDueToday,
        totalAmount: this.props.loadInvoiceInfo.serviceInfo.price,
        transactionId: this.props.payInvoiceState.transactionId!
      });
      this.props.nextStep!();
    }

    const wasValid = PaymentStep.isValid(prevState);
    const isValid = PaymentStep.isValid(this.state);

    if (wasValid !== isValid) {
      this.props.updateValidate(this.props.name, isValid);
    }
  }

  public componentDidMount() {
    this.props.onRef(this);
    this.props.updateValidate(this.props.name, PaymentStep.isValid(this.state));
  }

  public componentWillUnmount() {
    this.props.onRef(undefined);
  }

  public render() {
    const { cardholderName, billingAddress, billingZipCodeError } = this.state;
    const { stripe, payInvoiceState, loadInvoiceInfo } = this.props;

    const loading = !stripe || !loadInvoiceInfo;

    return (
      <div
        ref={this.containerRef}
        className={classNames(styles.container, loading ? styles.loading : null)}
        style={{ paddingBottom: dropDownHeight - addressMarginBottom }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Auxiliary>
            <CustomerInfo invoice={loadInvoiceInfo} />
            <div className={styles.details}>
              <PaymentDetails invoice={loadInvoiceInfo} />
            </div>
            <CardInfo
              cardholderName={cardholderName}
              billingAddress={billingAddress}
              billingZipCodeError={billingZipCodeError}
              onBillingAddressChange={this.onBillingAddressChange}
              onCardholderNameChange={this.onCardholderNameChange}
              onValidationChanged={this.onCardInfoValidationChanged}
              onScrollToSelector={this.onScrollToSelector}
              paymentFailureCode={payInvoiceState.paymentFailureCode}
            />
          </Auxiliary>
        )}
      </div>
    );
  }

  private onScrollToSelector = (selector: string, customHeight?: number) =>
    scrollToSelector(selector, this.containerRef, this.props.onEnsureVisible, customHeight);

  private onBillingAddressChange = (addressComponents: Partial<IShoppingAddressComponents>) => {
    this.setState(state => ({
      billingAddress: {
        ...state.billingAddress,
        ...addressComponents
      }
    }));
  };

  private static getValidatedBillingAddress(state: IPaymentState) {
    return buildShoppingAddress(state.billingAddress, state.billingZipCodeError);
  }

  private get validatedBillingAddress() {
    return PaymentStep.getValidatedBillingAddress(this.state);
  }

  private static isValid(state: IPaymentState) {
    return state.isCardInfoValid && !!PaymentStep.getValidatedBillingAddress(state);
  }

  public isValid = (): boolean => {
    return PaymentStep.isValid(this.state);
  };

  public saveAndContinue = (direction: Direction): void => {
    if (this.isValid() && direction === Direction.Forward) {
      this.payInvoice();
    }
  };

  public deferNavigation = (): boolean => {
    return true;
  };

  private onCardholderNameChange = (cardholderName: string) => {
    this.setState({
      cardholderName
    });
  };

  private onCardInfoValidationChanged = (isCardInfoValid: boolean) => {
    this.setState({
      isCardInfoValid
    });
  };

  private payInvoice = () => {
    const { cardholderName } = this.state;
    const { validatedBillingAddress } = this;
    const { stripe, onPayInvoice, invoiceId, loadInvoiceInfo } = this.props;

    const paymentCreator: IPaymentCreator = {
      stripe: stripe!,
      paymentMethodOptions: buildPaymentMethodOptions(
        loadInvoiceInfo.customerInfo.email,
        loadInvoiceInfo.customerInfo.phoneNumber,
        cardholderName,
        validatedBillingAddress!
      )
    };

    const invoicePaymentCreator: IInvoicePaymentCreator = {
      invoiceId,
      billingAddress: validatedBillingAddress!,
      useStrongCustomerAuthentication: true
    };

    onPayInvoice(paymentCreator, invoicePaymentCreator);
  };

  private readonly containerRef = React.createRef<HTMLDivElement>();
}

export default PaymentStep;
