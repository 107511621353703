import { Collapse, Panel, Utilities } from "@zenfolio/core-components";
import classNames from "classnames";
import moment, { Moment } from "moment";
import * as React from "react";
import { SendInvoiceOption } from "../../../../../../store/services/model";
import Auxiliary from "../../../../../../utilities/auxiliary";
import { formatTimeOfDay, splitDateTime } from "../../../../../../utilities/datetime";
import { isFreeService } from "../../../../../services/common";
import { IWidgetValues } from "../../../../contracts";
import { renderAddress } from "../../../../helpers";
import styles from "./index.module.scss";

interface IPaymentDetailsState {
  detailsOpen: boolean;
}

interface IPaymentDetailsProps {
  values: IWidgetValues;
  photographerTimezoneOffsetMinutes: number;
  detailsOpen: boolean;
  initialTaxAmount?: number;
  totalTaxAmount?: number;
}

class PaymentDetails extends React.Component<IPaymentDetailsProps, IPaymentDetailsState> {
  constructor(props: IPaymentDetailsProps) {
    super(props);

    this.state = {
      detailsOpen: props.detailsOpen
    };
  }

  public render() {
    const { detailsOpen } = this.state;
    const { totalTaxAmount } = this.props;
    const { service, dateTime, shootLocation, additionalInfo } = this.props.values;

    const dateTimeInfo = splitDateTime(dateTime);

    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.collapse}>
            <Collapse>
              <Panel
                name="details"
                headerClassName={styles.header}
                contentClassName={styles.panel}
                {...Panel.defaultProps}
                open={detailsOpen}
                title={this.renderTitle()}
                onChange={this.onClickCollapse}
              >
                <div className={styles.detail}>
                  <div className={styles.info}>
                    <label>Service</label>
                    <div className={styles.value}>{service!.name}</div>
                  </div>

                  <div className={styles.info}>
                    <label>Date</label>
                    <div className={styles.value}>{dateTimeInfo.date!.format("MMMM D, YYYY")}</div>
                  </div>

                  <div className={styles.info}>
                    <label>Time</label>
                    <div className={styles.value}>{formatTimeOfDay(dateTimeInfo.time!, "h:mm a")}</div>
                  </div>

                  <div className={styles.info}>
                    <label>Location</label>
                    <div className={styles.value}>{renderAddress(shootLocation!, false)}</div>
                  </div>

                  {shootLocation!.locationDetails && (
                    <div className={styles.info}>
                      <label>Location Details</label>
                      <div className={styles.value}>{shootLocation!.locationDetails}</div>
                    </div>
                  )}

                  {service!.askAdditionalInfo && (
                    <div className={styles.info}>
                      <label>About the Occasion</label>
                      <div className={classNames(styles.value, additionalInfo ? null : styles.empty)}>
                        {additionalInfo || "No details"}
                      </div>
                    </div>
                  )}

                  {this.renderAmount("Service Total", service!.price, totalTaxAmount)}
                  {service!.useDeposit && this.renderDepositDescription()}
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }

  private renderAmount(name: string, value: number, tax?: number) {
    return (
      <div className={styles.info}>
        <label>{name}</label>
        <div className={classNames(styles.value, tax != null && styles.taxable)}>
          {Utilities.formatFractionalNumberWithCurrency(value + (tax || 0))}
          {tax != null && <em>incl. tax</em>}
        </div>
      </div>
    );
  }

  private renderDepositDescription = (): React.ReactNode => {
    const { service, dateTime } = this.props.values;
    const { deposit, price } = service!;
    const { photographerTimezoneOffsetMinutes, initialTaxAmount, totalTaxAmount } = this.props;

    let invoiceDate: Moment = moment(dateTime!);

    if (deposit.sendInvoiceOption === SendInvoiceOption.AfterShootDate) {
      invoiceDate = invoiceDate.add(deposit.sendInvoiceDays, "days");
    } else if (deposit.sendInvoiceOption === SendInvoiceOption.BeforeShootDate) {
      invoiceDate = invoiceDate.add(-deposit.sendInvoiceDays, "days");
    }

    invoiceDate = invoiceDate.utcOffset(photographerTimezoneOffsetMinutes, true);

    const photographerDate: Moment = moment().utcOffset(photographerTimezoneOffsetMinutes);

    if (invoiceDate.isBefore(photographerDate)) {
      invoiceDate = photographerDate;
    }

    return (
      <Auxiliary>
        {this.renderAmount(
          `Amount due on ${invoiceDate.format("MMMM D, YYYY")}`,
          price - deposit!.depositAmount!,
          totalTaxAmount != null && initialTaxAmount != null ? totalTaxAmount - initialTaxAmount : undefined
        )}
        {this.renderAmount("Due today", deposit!.depositAmount!, initialTaxAmount)}
      </Auxiliary>
    );
  };

  private renderTitle = (): React.ReactNode => {
    const { initialTaxAmount, totalTaxAmount } = this.props;
    const service = this.props.values.service!;
    const isFree = isFreeService(service);

    return (
      <div className={styles.info}>
        <div className={styles.name}>Details</div>
        {service!.useDeposit
          ? this.renderTitleImpl("Today", service.deposit!.depositAmount! + (initialTaxAmount || 0))
          : this.renderTitleImpl(isFree ? "Today" : "Total", service.price + (totalTaxAmount || 0))}
      </div>
    );
  };

  private renderTitleImpl = (label: string, price: number): React.ReactNode => {
    return (
      <Auxiliary>
        <label>{label}:</label>
        <div className={styles.price}>
          <em>USD</em> {Utilities.formatFractionalNumberWithCurrency(price)}
        </div>
      </Auxiliary>
    );
  };

  private onClickCollapse = (key: string, open: boolean) => {
    this.setState({
      detailsOpen: open
    });
  };
}

export default PaymentDetails;
