import { Utilities } from "@zenfolio/core-components";
import React from "react";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import ModalDialog from "../../modalDialog";
import styles from "./index.module.scss";
import { ISendInvoice } from "./model";
import withStore from "./withStore";

export interface ISendInvoiceOwnProps {
  invoiceId: string;
  invoiceAmount: number;
  onClose: () => void;
  resend?: boolean;
}

export interface ISendInvoiceProps extends ISendInvoiceOwnProps {
  sendInvoice: ISendInvoice;
}

class SendInvoice extends React.Component<ISendInvoiceProps> {
  public componentDidUpdate(prevProps: ISendInvoiceProps) {
    const { sendInvoice, onClose } = this.props;
    const sendInvoiceHasCompleted = hasActionCompleted(prevProps.sendInvoice.status, sendInvoice.status);
    if (sendInvoiceHasCompleted) {
      onClose();
    }
  }

  public render() {
    const { invoiceAmount, sendInvoice, resend } = this.props;

    return (
      <ModalDialog
        title={resend ? "RESEND INVOICE?" : "SEND INVOICE?"}
        okText="Confirm"
        cancelText="Cancel"
        okDisabled={sendInvoice.status === "Pending"}
        onCancel={this.onCancel}
        onOk={this.onOk}
      >
        <div className={styles.container}>
          {resend
            ? `Resend this invoice for $${Utilities.formatNumber(invoiceAmount)}?`
            : `Send invoice for $${Utilities.formatNumber(invoiceAmount)}?`}
        </div>
      </ModalDialog>
    );
  }

  private onCancel = () => this.props.onClose();

  private onOk = () => this.props.sendInvoice.onSend(this.props.invoiceId);
}

export default withStore(SendInvoice);
