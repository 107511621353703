import React from "react";
import cx from "classnames";
import _ from "lodash";
import styles from "./largeBannerDiscount4.module.scss";
import { IBannerContent } from "models/coupon";
import { BannerViewMode, BannerFontStyle, BannerDiscountType } from "utilities/constant";
// @ts-ignore
import discount4Img from "./imageDiscount4.png";

export interface ILargeBannerDiscount4Props {
  content?: IBannerContent;
  viewMode: BannerViewMode;
  customClassName?: string;
  onClick?: () => void;
}

const DEFAULT_COLORS = {
  white: "#ffffff",
  lightGreen: "#b0c1b0",
  darkGreen: "#627f62"
};

export class LargeBannerDiscount4 extends React.PureComponent<ILargeBannerDiscount4Props> {
  public getProps = () => {
    const defaultProps = {
      viewMode: BannerViewMode.DesktopClient,
      content: {
        header: {
          text: "SALE",
          color: DEFAULT_COLORS.white
        },
        body: {
          text: "20% off all purchases",
          color: DEFAULT_COLORS.darkGreen
        },
        code: {
          text: "Code: [code]",
          color: DEFAULT_COLORS.white
        },
        button: {
          text: "SHOP NOW",
          color: DEFAULT_COLORS.lightGreen,
          backgroundColor: DEFAULT_COLORS.white
        },
        fontStyle: BannerFontStyle.Serif,
        backgroundColor: DEFAULT_COLORS.lightGreen,
        discountAmount: "20",
        discountType: BannerDiscountType.PercentOff,
        backgroundImage: discount4Img
      }
    };
    const renderProps = _.merge(defaultProps, this.props);
    return renderProps;
  };

  private renderDiscountSymbol = () => {
    const discountType = this.getProps().content?.discountType;
    return (
      (discountType === BannerDiscountType.PercentOff && "%") || (discountType === BannerDiscountType.DollarOff && "$")
    );
  };
  private getLargeBannerImage = () => {
    const { viewMode, content } = this.getProps();

    switch (viewMode) {
      case BannerViewMode.DesktopClient: {
        return content.backgroundImage;
      }
      case BannerViewMode.MobileClient: {
        return content.backgroundImageMobile || content.backgroundImage;
      }
      default:
        return content.backgroundImage;
    }
  };
  public render() {
    const { content, viewMode, customClassName } = this.getProps();
    return (
      <div
        className={cx(
          styles.container,
          [styles[viewMode]],
          [styles[content.fontStyle || BannerFontStyle.SansSerif]],
          customClassName
        )}
        style={{ backgroundColor: content.backgroundColor }}
      >
        <div className={styles.leftContent}>
          <div className={styles.header} style={{ color: content.header?.color }}>
            {content?.header?.text}
          </div>
          <div className={styles.body} style={{ color: content.body?.color }}>
            {/* <span className={styles.percent}>{content.discountAmount}</span>
            {this.renderDiscountSymbol()} */}
            {content?.body?.text}
          </div>
          <div className={styles.code} style={{ color: content.code?.color }}>
            {content?.code?.text}
          </div>
          <div className={styles.btnContainer}>
            <button
              className={styles.button}
              style={{ color: content.button?.color, backgroundColor: content.button?.backgroundColor }}
              onClick={this.props.onClick}
            >
              <div>{content?.button?.text}</div>
            </button>
          </div>
        </div>

        <div className={styles.rightContent} style={{ color: content.header?.color }}>
          <img className={styles.img} src={this.getLargeBannerImage()} />
        </div>
      </div>
    );
  }
}

export default LargeBannerDiscount4;
