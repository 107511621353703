import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";

import cx from "classnames";
import _ from "lodash";
import React from "react";
import { handleLazyLoaded } from "utilities/blocks/images";
import { getImageAspectRatio, getImageWidth, getNameProps } from "utilities/blocks/masonry";
import { Utilities } from "utilities/utilities";
import { getPictureStyle } from "utilities/blocks/blockColors";
import colors from "utilities/colors";
import { IPhotoMasonry, NameType, NameVisibility } from "../blockModels";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import ZenLandscapePhotoTile from "./ZenLandscapePhotoTile";
import styles from "./zenMasonryLandscapeBlock.module.scss";
import { universalWindow as window } from "utilities/blocks/masonry";
import { GalleryPresentSettingList } from "utilities/constant";

export interface IMasonryLandscapeBlockProps extends IZenBaseBlockProps {
  albumID?: number | null;
  images: IPhotoMasonry[];
  imageUpdated?: { index: number; image: IPhotoMasonry };
  rowHeight: number;
  layout?: string;
  mobileLayout?: GalleryPresentSettingList;
  selectedIndex?: number;
  galleryStyle: string;
  dropShadow: boolean;
  className?: string;
  isEditionView?: boolean;
  overlayComponent?: Array<JSX.Element | null>;
  overlayStyle?: string;
  blockImageEvents?: boolean;
  contentsRef?: React.RefObject<HTMLDivElement>;
  isEditor?: boolean;
  showNames?: NameType;
  namesVisibility?: NameVisibility;
  onPhotoClick?: (evt: React.MouseEvent<HTMLElement>, photoIndex: number) => void;
  onItemsPerRow?: (items: number) => void;
  onHoverPhoto?: (hoveredPhotoIndex: number) => void;
  getHoverComponent?: (index: number) => JSX.Element;
  getBadgeComponent?: (index: number) => JSX.Element | Element | null;
  hasLightbox?: boolean;
  showLightbox?: boolean;
}

interface IMasonryLandscapeBlockState {
  itemsPerRow: number;
}

export class ZenMasonryLandscapeBlock extends React.Component<IMasonryLandscapeBlockProps, IMasonryLandscapeBlockState> {
  state = {
    itemsPerRow: 0
  };

  public static defaultProps = {
    images: [],
    rowHeight: 250,
    accentColor: colors.white
  };

  public MasonryRef: React.RefObject<HTMLDivElement>;

  constructor(props: IMasonryLandscapeBlockProps) {
    super(props);
    this.MasonryRef = React.createRef();
  }

  public shouldComponentUpdate(nextProps: IMasonryLandscapeBlockProps) {
    if (nextProps.selectedIndex !== this.props.selectedIndex) {
      this.validateImageHighlight(nextProps);
    }
    return true;
  }

  public componentDidMount() {
    const masonryRef = this.getRef();
    if (masonryRef && masonryRef.current) {
      masonryRef.current?.ownerDocument?.addEventListener("lazyloaded", this.handleLazyLoaded);
      this.updateSize();
    }

    window.addEventListener("resize", this.updateSize);
  }

  public componentWillUnmount() {
    const masonryRef = this.getRef();
    if (masonryRef.current) {
      masonryRef.current?.ownerDocument?.removeEventListener("lazyloaded", this.handleLazyLoaded);
    }

    window.removeEventListener("resize", this.updateSize);
  }
  private getRef = () => {
    return this.props.contentsRef ? this.props.contentsRef : this.MasonryRef;
  };

  private getMobileColumns(): number {
    const { mobileLayout } = this.props;
    return mobileLayout && mobileLayout == GalleryPresentSettingList.TwoColumn ? 2 : 1;
  }

  private isMobileLayout = () =>
    this.props.mobileLayout && this.getMobileColumns() == this.state.itemsPerRow && window.innerWidth <= 500;

  private getItemsPerRow() {
    if (window.innerWidth <= 500) {
      return this.props.mobileLayout ? this.getMobileColumns() : 1;
    } else if (window.innerWidth > 501 && window.innerWidth <= 800) {
      return 2;
    } else if (window.innerWidth > 801 && window.innerWidth <= 1000) {
      let result = 3;

      if (this.props.layout && this.props.layout === "C") {
        result = 2;
      }

      return result;
    } else {
      let result = 4;

      if (this.props.layout && this.props.layout === "C") {
        result = 2;
      } else if (this.props.layout && this.props.layout === "B") {
        result = 3;
      }

      return result;
    }
  }

  private updateSize = () => {
    this.setState(
      {
        itemsPerRow: this.getItemsPerRow()
      },
      () => {
        if (this.props.onItemsPerRow) {
          this.props.onItemsPerRow(this.state.itemsPerRow);
        }
      }
    );
  };

  private handleLazyLoaded = (e: Event) => {
    handleLazyLoaded(e, styles.imgRevealTop, styles.imgRevealBottom, styles.triggerReveal, styles.containerLoaded);
  };

  private addHighlight = (element: HTMLElement | null) => {
    element?.classList.add("outlineHighlight");
  };

  private removeHighlight = (element: HTMLElement | null) => {
    element?.classList.remove("outlineHighlight");
  };

  private validateSingleImage = (index: number, selectedIndex: number) => {
    const masonryRef = this.getRef();
    const image = masonryRef.current?.getElementsByTagName("img")[index];
    const attrIndex = image?.getAttribute("data-index");
    if (image && attrIndex && parseInt(attrIndex, 10) === selectedIndex) {
      this.addHighlight(image.parentElement);
      this.scrollIntoImgView(image);
    }
  };

  private validateImageHighlight = (nextProps: IMasonryLandscapeBlockProps) => {
    const masonryRef = this.getRef();
    if (masonryRef && nextProps.selectedIndex !== undefined) {
      const listElements = masonryRef.current?.getElementsByTagName("img");

      if (listElements) {
        let index = 0;
        for (const element of Array.from(listElements)) {
          this.removeHighlight(element.parentElement);
          this.validateSingleImage(index, nextProps.selectedIndex);
          index = index + 1;
        }
      }
    }
  };

  private scrollIntoImgView = (element: HTMLImageElement) => {
    element.scrollIntoView({
      block: "center",
      behavior: "smooth"
    });
  };

  public render() {
    const {
      className,
      images,
      siteTheme,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      readOnly,
      divider,
      alignment,
      fontFamily,
      padding,
      animationScrollIn,
      animationScrollOut,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      selectedBlock,
      dropShadow,
      overlayComponent,
      overlayStyle,
      noPadding,
      galleryStyle,
      isEditionView,
      onPhotoClick,
      onHoverPhoto,
      getHoverComponent,
      getBadgeComponent,
      isEditor,
      showNames,
      namesVisibility,
      hasLightbox,
      showLightbox
    } = this.props;

    const itemsPerRow = this.state.itemsPerRow || this.getItemsPerRow();
    const borderStyle = getPictureStyle(galleryStyle, siteTheme.backgroundColor.value, backgroundColor);
    const rows = _.chunk(images, itemsPerRow);
    let rowSize = 0;
    const paddingStyle = this.isMobileLayout() ? 7 : 12;

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        readOnly={readOnly}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        paddingMobile={window.innerWidth <= (!this.props.isEditionView ? 768 : 1157)}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        selectedBlock={selectedBlock}
        noPadding={noPadding}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div
            ref={this.props.contentsRef || this.MasonryRef}
            className={cx(
              styles.zenContainerMasonry,
              styles.masonryHorizontal,
              className,
              this.isMobileLayout() ? styles.zenMobileModeContainerMasonry : ""
            )}
          >
            {rows.map((row, rowIdx) => {
              const rowAspectRatio = row.map(image => getImageAspectRatio(image));
              const rowAspectRatioSum = _.sum(rowAspectRatio);

              if (rowSize === 0) {
                rowSize = row.length;
              }

              return row.map((image, imgIdx) => {
                let currentImage =
                  this.props.imageUpdated && imgIdx === this.props.imageUpdated?.index
                    ? { ...this.props.imageUpdated.image, url: "" }
                    : image;

                const imageWidth = getImageWidth(
                  image,
                  rowAspectRatioSum,
                  rowAspectRatio,
                  imgIdx,
                  this.state.itemsPerRow
                );

                const imageAspectRatio =
                  image.height && image.width ? (image.width / image.height).toFixed(3) : undefined;

                const indexImage = imgIdx + rowIdx * this.state.itemsPerRow;
                const isGif = Utilities.isGifImage(currentImage.file?.name ?? currentImage.fileName);
                const isPng = Utilities.isPngImage(currentImage.file?.name ?? currentImage.fileName);
                const isVideo = currentImage?.video?.isVideo
                const { dominantColor } = image;

                return (
                  <ZenLandscapePhotoTile
                    key={`${currentImage.url}-${indexImage}`}
                    src={currentImage.url}
                    srcset={currentImage.srcset ? currentImage.srcset : undefined}
                    aspectRatio={imageAspectRatio}
                    alt={currentImage.altText ? currentImage.altText : ""}
                    index={indexImage}
                    dropShadow={dropShadow}
                    blockImageEvents={this.props.blockImageEvents}
                    onClick={
                      onPhotoClick
                        ? (e: React.MouseEvent<HTMLElement>) =>
                            onPhotoClick ? onPhotoClick(e, rowIdx * rowSize + imgIdx) : null
                        : undefined
                    }
                    backgroundColor={backgroundColor}
                    overlayComponent={overlayComponent ? overlayComponent[indexImage] : undefined}
                    overlayStyle={overlayStyle}
                    focalX={currentImage.focalX}
                    focalY={currentImage.focalY}
                    isEditionView={isEditionView}
                    isFallback={currentImage.isFallback!}
                    imageWidth={`calc(${imageWidth} - ${imgIdx < row.length - 1 ? (isEditionView ? 8 : 12) : 0}px)`}
                    borderStyle={borderStyle}
                    galleryStyle={galleryStyle}
                    paddingRight={`${imgIdx < row.length - 1 ? `${isEditionView ? 8 : paddingStyle}px` : "0px"}`}
                    paddingBottom={`${isEditionView ? 8 : paddingStyle}px`}
                    animationClasses={
                      !isGif
                        ? {
                            imgContainer: cx(styles.lazyLoadingContainer, {[styles.dominantColor]: !!dominantColor }),
                            imgContainerLoaded: styles.containerLoaded,
                            revealContainerTop: styles.imgRevealTop,
                            revealContainerBottom: styles.imgRevealBottom,
                            revealFlag: styles.triggerReveal
                          }
                        : undefined
                    }
                    duration={currentImage?.video?.durationMilliseconds}
                    isEditor={isEditor}
                    onHoverPhoto={onHoverPhoto}
                    getHoverComponent={getHoverComponent}
                    getBadgeComponent={getBadgeComponent}
                    isGif={isGif}
                    isPng={isPng}
                    isVideo={isVideo}
                    {...getNameProps(image, indexImage, images.length, showNames, namesVisibility)}
                    hasLightbox={hasLightbox}
                    showLightbox={showLightbox}
                    dominantColor={image.dominantColor}
                  />
                );
              });
            })}
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}
