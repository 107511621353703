import React from "react";
import { ICroppingData } from "../../components/CropperEditor";
import AlertIcon from "../../components/CropperEditor/ui/alertIcon";
import { PIXEL_PER_INCH } from "../model";
import styles from "./withValidation.module.scss";
import cx from "classnames";
import { Utilities } from "utilities/utilities";

export interface IValidationProps {
  /** Enable resolution validation */
  enableResolutionValidation?: boolean;
  /** Enable resolution validation */
  enableRatioValidation?: boolean;
  /** Original product size width (inch) */
  productWidth: number;
  /** Original product size height (inch) */
  productHeight: number;
  /** Original Photo height in pixel
   */
  originalPhotoHeight?: number;
  /** Original Photo width in pixel.
   */
  originalPhotoWidth?: number;

  /** Cropping Data */
  croppingData?: {
    cropping?: ICroppingData;
    /** Zoom scales */
    zoomScale: number;
    /** Photo rotation degree */
    rotate: number;
  };
}

export const isResolutionValid = (
  originalPhotoWidth: number,
  originalPhotoHeight: number,
  productHeight: number,
  productWidth: number
) => {
  const photoWidth = Utilities.roundingTwoDecimalsNumber(originalPhotoWidth || 0);
  const photoHeight = Utilities.roundingTwoDecimalsNumber(originalPhotoHeight || 0);
  const roundedProductWidth = Utilities.roundingTwoDecimalsNumber(productWidth * PIXEL_PER_INCH);
  const roundedProductHeight = Utilities.roundingTwoDecimalsNumber(productHeight * PIXEL_PER_INCH);

  return photoHeight >= roundedProductHeight && photoWidth >= roundedProductWidth;
};

export const isRatioValid = (
  originalPhotoWidth: number,
  originalPhotoHeight: number,
  productHeight: number,
  productWidth: number
) => {
  const productWidthPixel = productWidth * PIXEL_PER_INCH;
  const productHeightPixel = productHeight * PIXEL_PER_INCH;
  const photoWidth = originalPhotoWidth || 0;
  const photoHeight = originalPhotoHeight || 0;
  const productRatio = productWidthPixel / productHeightPixel;
  const photoRatio = photoWidth / photoHeight;

  return Math.abs(productRatio - photoRatio) <= 0.01;
};

/**
 * Auto Detect orientation of photo
 * @param Component
 */
function withValidation<T extends IValidationProps>(Component: React.ComponentType<T>) {
  return class extends React.Component<T> {
    get hasCroppingInfo(): boolean {
      const { croppingData } = this.props;
      return !!croppingData && (croppingData?.cropping?.width || 0) > 0 && (croppingData?.cropping?.height || 0) > 0;
    }
    get photoWidth(): any {
      const { croppingData } = this.props;
      const originalPhotoWidth = this.props.originalPhotoWidth as number;

      return this.hasCroppingInfo ? originalPhotoWidth * (croppingData?.cropping?.width || 0) : originalPhotoWidth;
    }

    get photoHeight(): any {
      const { croppingData } = this.props;
      const originalPhotoHeight = this.props.originalPhotoHeight as number;

      return this.hasCroppingInfo ? originalPhotoHeight * (croppingData?.cropping?.height || 0) : originalPhotoHeight;
    }
    get isResolutionValid() {
      const { productHeight, productWidth } = this.props;
      return isResolutionValid(this.photoWidth, this.photoHeight, productHeight, productWidth);
    }

    get isRatioValid() {
      const { productHeight, productWidth } = this.props;

      return isRatioValid(this.photoWidth, this.photoHeight, productHeight, productWidth);
    }

    public render() {
      const { enableResolutionValidation, enableRatioValidation } = this.props;
      if (!enableResolutionValidation && !enableRatioValidation) {
        return (
          <Component
            {...(this.props as T)}
            isInvalidResolution={enableResolutionValidation && !this.isResolutionValid}
            isInvalidRatio={enableRatioValidation && !this.isRatioValid}
          />
        );
      }
      return (
        <div className={styles.container}>
          <Component
            {...(this.props as T)}
            isInvalidResolution={enableResolutionValidation && !this.isResolutionValid}
            isInvalidRatio={enableRatioValidation && !this.isRatioValid}
          />
          {enableResolutionValidation && !this.isResolutionValid && (
            <div id="resContainer" className={cx(styles.resolutionContainer)}>
              <div className={styles.alert}>
                <AlertIcon />
                <div className={styles.text}>Photo is too small for this product.</div>
              </div>
            </div>
          )}
          {enableRatioValidation && this.isResolutionValid && !this.isRatioValid && (
            <div className={cx(styles.resolutionContainer)}>
              <div className={styles.alert}>
                <AlertIcon />
                <div className={styles.text}>
                  <div>{`Photo does not fit.`}</div>
                  <div>Please crop/edit the photo.</div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  };
}

export default withValidation;
