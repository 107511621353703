import _ from "lodash";
import * as React from "react";
import CorePlayer, {
  IPlaybackStatsRecord,
  IQualityOptions,
  IVideoPlayer,
  LeaveAction,
  VideoPlacement
} from "../../../components/Video/Player";
import { formatVideoUrl, IVideoBase } from "utilities/getImgUrl";
import useAnalytics from "./useAnalytics";

export interface IAnalyticParams {
  galleryId: string;
  type: string;
}
export interface IPlayerProps {
  videoUrlTemplate: string;
  video: IVideoBase;
  /**
   * Default volume in the range [0, 1]. Default is 0.5. Session-wide volume takes precedence.
   */
  defaultVolume?: number;
  /**
   * Specifies whether the video should be muted by default.
   */
  defaultMuted?: boolean;
  /**
   * Specifies whether the video should automatically play.
   */
  autoplay?: boolean;
  /**
   * Specifies whether the video playback should be looped.
   */
  loop?: boolean;
  /**
   * Specifies the action that is executed when the video leaves the viewport. *"none"* by default.
   */
  leaveAction?: LeaveAction;
  /**
   * Specifies whether to disable (hide) the Picture-in-picture feature. *true* by default.
   */
  disablePiP?: boolean;
  /**
   * Specifies whether the component should listen to keyboard events on document level, recognize relevant
   * key presses and intercept them. Use with caution because it might affect functionality of other elements
   * on the page, if they use the same key presses for something else. *true* by default.
   */
  globalKeyboardControls?: boolean;
  /**
   * Specifies whether the user can interact with the video. If set to *false* then all video controls are hidden,
   * the video is not focusable and ignores all mouse and keyboard inputs. *true* by default.
   */
  interactive?: boolean;
  /**
   * Specifies how video should be positioned inside container. *"contain"* by default.
   */
  videoPlacement?: VideoPlacement;
  /**
   * Specifies whether the video should be shown in "background" mode. Background video automatically plays,
   * it is muted, looped, not interactive, and covers its container. Basically this prop is just a shortcut for
   * a combination of several other props. You can still override those props.
   */
  backgroundMode?: boolean;
  /**
   * Specifies whether to show the loading indicator. *true* by default.
   */
  loadingIndicator?: boolean;
  /**
   * Specifies parameters for playback analytics. If specified, then the component automatically sends playback
   * analytics based on these parameters, process environment variables and local storage data.
   */
  analyticsParams?: IAnalyticParams;
  /**
   * Specifies quality control settings.
   */
  quality?: IQualityOptions;
  className?: string;
  style?: React.CSSProperties;
  /**
   * Called when autoplay succeeds.
   */
  onAutoplaySuccess?: () => void;
  /**
   * Called when autoplay fails (blocked by browser).
   */
  onAutoplayFail?: () => void;
  /**
   * Called when the video leaves the viewport.
   */
  onLeave?: () => void;
  /**
   * Called when the video starts playing (for the first time), either as a result of clicking on the play button,
   * or because of autoplay. If the video has been *stopped* (not just paused, or ended), then this callback resets
   * and can be executed again under the same conditions.
   */
  onStart?: () => void;
  /**
   * Called when the video is *stopped* (not just paused, or ended). Video can be stopped imperatively
   * (using *stop* method) or via the *leaveAction*.
   */
  onStop?: () => void;
  /**
   * Called when enough data has been loaded to start playing the video.
   */
  onVideoLoad?: () => void;
  /**
   * Called when an error occurs when loading the video.
   */
  onVideoError?: () => void;
  /**
   * Called when a playback stats record is generated.
   */
  onPlaybackStats?: (record: IPlaybackStatsRecord) => void;
}

/**
 * NextZen Video Player component. Adapts core *Player* component for NextZen needs. Integrates with NextZen
 * data objects and sets some defaults that match NextZen use cases.
 */
const Player = React.forwardRef((props: IPlayerProps, ref: React.Ref<IVideoPlayer>) => {
  const { videoUrlTemplate, video, ...core } = props;

  core.defaultVolume = _.defaultTo(core.defaultVolume, 0.5);
  core.disablePiP = _.defaultTo(core.disablePiP, true);
  core.globalKeyboardControls = _.defaultTo(core.globalKeyboardControls, true);
  core.onPlaybackStats = useAnalytics(props);

  const videoSrc = formatVideoUrl(videoUrlTemplate, video);

  return <CorePlayer ref={ref} video={{ src: videoSrc, type: "application/x-mpegURL" }} {...core} />;
});

export default Player;
