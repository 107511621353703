import React, { PureComponent } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { hexToRgb, getBackGroundTile, getFontColor } from "utilities/blocks/blockColors";
import { getSiteFonts } from "utilities/blocks/site";
import { renderLineBreaks } from "utilities/blocks/string";
import { IZenSiteTheme } from "../../../../models/models";
import { testimonialPlaceHolders } from "utilities/placeholdes";

interface ICardProps {
  index: number;
  quote: string;
  source: string;
  shootType: string;
  fontStyle: string;
  siteTheme: IZenSiteTheme;
  selectCard: (index: number) => void;
  isSelected: boolean;
  accentColor: string;
  focusSection: (index: number, input: string) => void;
  backgroundColor: string;
  backgroundType?: string;
  backgroundOpacity?: number;
}

interface IQuotations {
  accentColor: string;
}

const Quotations = ({ accentColor }: IQuotations) => {
  const bgRegex = /^(?:white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\))$/i;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
      <g fill="none" fillRule="evenodd">
        <g fill={accentColor.match(bgRegex) ? "#000" : "#fff"} fillRule="nonzero">
          <path
            d="M28.904 41c-.192 0-.423-.02-.693-.06-.27-.04-.578-.159-.925-.358-1.426-.876-2.495-1.85-3.208-2.925-.713-1.075-1.07-2.388-1.07-3.94 0-1.115.318-2.27.954-3.463.636-1.194 1.58-2.269 2.833-3.224 1.252-.955 2.803-1.632 4.653-2.03v1.254c-1.272.278-2.438.836-3.497 1.671-1.06.836-1.59 1.871-1.59 3.105 0 .676.173 1.343.52 2 .347.657 1.156 1.323 2.428 2l2.139 1.134v.717l-1.099 2.806c-.308.875-.79 1.313-1.445 1.313zm11.561 0c-.193 0-.424-.02-.694-.06-.27-.04-.578-.159-.924-.358-1.426-.876-2.496-1.85-3.208-2.925-.713-1.075-1.07-2.388-1.07-3.94 0-1.115.318-2.27.954-3.463.636-1.194 1.58-2.269 2.832-3.224 1.253-.955 2.804-1.632 4.653-2.03v1.254c-1.271.278-2.437.836-3.497 1.671-1.06.836-1.59 1.871-1.59 3.105 0 .676.174 1.343.521 2 .347.657 1.156 1.323 2.428 2l2.138 1.134v.717l-1.098 2.806c-.308.875-.79 1.313-1.445 1.313z"
            transform="translate(-698 -409) translate(388 260) translate(133 124) translate(153.992) rotate(180 33.008 33)"
          />
        </g>
      </g>
    </svg>
  );
};

class Card extends PureComponent<ICardProps> {
  constructor(props: ICardProps) {
    super(props);
  }

  public render() {
    const {
      index,
      quote,
      source,
      shootType,
      fontStyle,
      siteTheme,
      selectCard,
      isSelected,
      accentColor,
      focusSection,
      backgroundType,
      backgroundColor,
      backgroundOpacity
    } = this.props;

    const bgColor = getBackGroundTile(siteTheme.backgroundColor.value);
    const textColor = { color: getFontColor(siteTheme.backgroundColor.value) };
    const bgOrAccent = backgroundType === "color" && backgroundColor === "accent" ? accentColor : backgroundColor;
    const rgb = backgroundColor && hexToRgb(bgOrAccent);
    const containerStyles =
      rgb && backgroundType === "color"
        ? {
            backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${backgroundOpacity ? backgroundOpacity / 100 : "1"})`
          }
        : { backgroundColor: bgColor };
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    return (
      <div
        className={cx(styles.container, styles[fontStyle], { [styles.withOutline]: isSelected })}
        style={containerStyles}
        onClick={() => selectCard(index)}
      >
        <div className={styles.quotation} style={{ backgroundColor: accentColor }}>
          <Quotations accentColor={accentColor} />
        </div>
        <div onClick={() => focusSection(index, "quote")} className={styles.quote} style={textColor}>
          {quote ? renderLineBreaks(quote) : testimonialPlaceHolders[index].quote}
        </div>
        <div className={styles.footer}>
          <div
            onClick={() => focusSection(index, "source")}
            className={cx(styles.source, styles[siteFonts.secondary])}
            style={{ color: accentColor }}
          >
            {source}
          </div>
          <div
            onClick={() => focusSection(index, "shootType")}
            className={cx(styles.shootType, styles[siteFonts.secondary])}
            style={textColor}
          >
            {shootType}
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
