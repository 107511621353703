import cx from "classnames";
import AlbumLock from "../../Icons/AlbumLock";
import Image from "../../Image";
import React from "react";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { getValidUrl } from "utilities/blocks/blockInteractions";
import { getSiteFonts } from "utilities/blocks/site";
import { getInteractionUrlType, onLinkClick } from "utilities/blocks/blockInteractions";
import ContextMenu from "utilities/contextMenu";
import { parseBody, stringIsGuid } from "utilities/blocks/string";
import colors from "utilities/colors";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks";
import styles from "./zenImageBlock.module.scss";
import { ILink, IRoutes } from "../../../models/models";
import { PageContext } from "utilities/pageContext";
import { LinkTypeOption } from "utilities/constant";

export interface IImageBlockProps extends IZenBaseBlockProps {
  imageName: string;
  imageUrl: string;
  imageSrcSet?: string;
  imageHeight: number;
  imageWidth: number;
  imageId: string;
  altText?: string;
  maskType: string;
  size: number;
  fullWidth: boolean;
  maskPosition: string;
  imageAlignment?: string;
  frameStyle?: string;
  dropShadow: boolean;
  captionText?: string;
  interactionType?: string;
  interactionPage?: string;
  interactionUrl?: string;
  interactionNewTab?: boolean;
  pages?: any;
  routes?: IRoutes;
  focalX?: number;
  focalY?: number;
  link?: ILink;
  isPublish?: boolean;
  getInteractionUrl?: getInteractionUrlType;
}

interface IImageBlockState {
  containerWidth: number;
}

const rectangleRatio = 4 / 3;

export class ZenImageBlock extends React.Component<IImageBlockProps, IImageBlockState> {
  static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  public static defaultProps = {
    maskType: "none",
    maskPosition: "center",
    dropShadow: false,
    fullWidth: false,
    size: 0
  };
  private Container: any;

  constructor(props: IImageBlockProps) {
    super(props);
    this.Container = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = { containerWidth: 0 };
  }

  public componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
  }

  public componentDidUpdate(prevProps: IImageBlockProps) {
    if (prevProps.fullWidth !== this.props.fullWidth) {
      this.updateWindowDimensions();
    }
  }

  public UNSAFE_componentWillMount() {
    this.updateWindowDimensions();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public updateWindowDimensions = () => {
    if (this.Container && this.Container.current) {
      this.setState({ containerWidth: this.Container.current.offsetWidth });
    }
  };

  public handleButtonClick = () => {
    const {
      readOnly,
      pages,
      interactionType,
      interactionPage,
      interactionUrl,
      interactionNewTab,
      routes,
      link,
      isPublish,
      getInteractionUrl
    } = this.props;

    if (readOnly && pages && link) {
      onLinkClick(
        pages,
        interactionNewTab || false,
        interactionType ? interactionType : "",
        interactionUrl ? interactionUrl : "",
        interactionPage ? interactionPage : "",
        routes ? routes : {},
        link,
        isPublish,
        getInteractionUrl
      );
    }
  };

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      layout,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      fontFamily,
      divider,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      padding,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      imageId,
      altText,
      maskType,
      size,
      fullWidth,
      imageAlignment,
      dropShadow,
      focalX,
      focalY,
      link
    } = this.props;

    const imageUrl = parseBody(this.props.imageUrl || "");
    const imageSrcSet = parseBody(this.props.imageSrcSet || "");
    const imageName = parseBody(this.props.imageName || "");
    const captionText = parseBody(this.props.captionText || "");
    const newFocalX = focalX ?? 50;
    const newFocalY = focalY ?? 50;

    const { containerWidth } = this.state;

    // using Number(..) here in case these props came as strings in client view
    const imageHeight = Number(this.props.imageHeight);
    const imageWidth = Number(this.props.imageWidth);

    const newSize: number = Number(size) < containerWidth && !fullWidth ? Number(size) : containerWidth;

    let maskWidth: number = 0;
    let maskHeight: number = 0;
    let borderRadius: number = 0;

    let myImageWidth: number = imageWidth;
    let myImageHeight: number = imageHeight;

    if (maskType === "none") {
      myImageWidth = newSize;
      myImageHeight = (myImageWidth * imageHeight) / imageWidth;
    } else {
      if (imageWidth > imageHeight) {
        if (maskType === "portrait") {
          // Landscape picture, portrait mask
          myImageHeight = newSize * rectangleRatio;
          myImageWidth = (myImageHeight * imageWidth) / imageHeight;
        } else {
          // Landscape picture, other mask
          myImageHeight = newSize;
          myImageWidth = (myImageHeight * imageWidth) / imageHeight;
        }
      } else {
        // Portrait picture
        myImageWidth = newSize;
        myImageHeight = (myImageWidth * imageHeight) / imageWidth;
      }
    }

    if (maskType === "circle" || maskType === "square") {
      maskWidth = newSize;
      maskHeight = newSize;
    }

    if (maskType === "circle") {
      borderRadius = newSize / 2;
    }
    if (maskType === "landscape") {
      maskWidth = newSize;
      if (maskWidth > containerWidth && containerWidth > 0) {
        maskWidth = containerWidth;
      }
      maskHeight = maskWidth / rectangleRatio;
    }
    if (maskType === "portrait") {
      maskWidth = newSize;
      maskHeight = newSize * rectangleRatio;
    }

    if (myImageWidth < maskWidth) {
      myImageWidth = maskWidth;
      myImageHeight = (myImageWidth * imageHeight) / imageWidth;
    }

    if (myImageHeight < maskHeight) {
      myImageHeight = maskHeight;
      myImageWidth = (myImageHeight * imageWidth) / imageHeight;
    }

    if (maskType === "none") {
      maskWidth = 0;
      maskHeight = 0;
      borderRadius = 0;
    }

    let pictureClass = cx(styles.picture);

    if (maskType === "none") {
      pictureClass = cx(pictureClass, styles.noMask);
    }

    const containerStyle = {
      justifyContent: imageAlignment === "center" ? "center" : imageAlignment === "right" ? "flex-end" : "flex-start"
    };
    const contentClass = cx(styles.zenContent);
    let containerContentClass = "";

    if (dropShadow) {
      containerContentClass = cx(containerContentClass, styles.dropShadow);
    }

    const captionClass = cx(
      styles.captionText,
      alignment === "center" ? styles.captionCenter : alignment === "right" ? styles.captionRight : styles.captionLeft,
      { [styles.captionClickable]: !readOnly }
    );

    const captionStyle = {
      width: maskWidth !== 0 ? maskWidth + "px" : "100%",
      color: getBlockTextColor(
        backgroundType === "none" ? siteTheme.backgroundColor.value : backgroundColor,
        siteTheme.accentColor.value,
        backgroundType,
        siteTheme.backgroundColor.value
      )
    };

    const getImage = (image: React.ReactNode) => {
      return (
        <React.Fragment>
          {!this.context.isPreview && this.props.interactionType !== "none" && this.props.interactionUrl ? (
            <a href={getValidUrl(this.props.interactionUrl)} target={this.props.interactionNewTab ? "_blank" : ""}>
              {image}
            </a>
          ) : (
            <React.Fragment>{image}</React.Fragment>
          )}
        </React.Fragment>
      );
    };

    if (maskType === "none") {
      maskWidth = 0;
      maskHeight = 0;
      borderRadius = 0;
    }

    // If id is not GUID then is default
    const isGhost = !this.context.noGhost && !stringIsGuid(imageId);
    const imgProtected =
      (link?.albumFolderData?.album.isGalleryProtected ||
        (link?.albumFolderData?.album.isVisitorAllowed === false && !link?.albumFolderData?.folder.id)) &&
      link?.type === LinkTypeOption.GALLERY;
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        readOnly={readOnly}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        layout={layout}
        selectedBlock={selectedBlock}
        fullWidth={fullWidth}
        noPadding={fullWidth}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div
            ref={this.Container}
            className={styles.zenContainer}
            style={{ ...containerStyle, cursor: link ? "pointer" : "default" }}
            onClick={this.handleButtonClick}
          >
            <div
              id={"ContainerContent"}
              className={cx(containerContentClass, styles[siteFonts.primary])}
              style={{
                width: maskWidth !== 0 ? `${maskWidth + 8}px` : "auto",
                height: maskHeight !== 0 ? `${maskHeight + 8}px` : "auto",
                borderRadius: borderRadius !== 0 ? borderRadius + "px" : "0",
                marginBottom: captionText !== "" && maskType !== "none" ? "35px" : "0"
              }}
            >
              {getImage(
                <div className={styles.imgWrapper}>
                  <div
                    className={cx(contentClass, { [styles.protectedImage]: imgProtected })}
                    style={{
                      width: maskWidth !== 0 ? maskWidth + "px" : newSize + "px",
                      height: maskHeight !== 0 ? maskHeight + "px" : myImageHeight + "px",
                      borderRadius: borderRadius !== 0 ? borderRadius + "px" : "0"
                    }}
                    onContextMenu={ContextMenu.handleBlockContextMenu}
                  >
                    <Image
                      src={imageUrl}
                      srcset={imageSrcSet}
                      alt={altText || imageName}
                      width={imageWidth}
                      height={imageHeight}
                      fitParent={true}
                      className={styles.imgTag}
                      styles={{
                        objectPosition: newFocalX + "% " + newFocalY + "%"
                      }}
                      isGhost={isGhost}
                    />
                  </div>

                  {(link?.albumFolderData?.album.isGalleryProtected ||
                    (link?.albumFolderData?.album.isVisitorAllowed === false && !link?.albumFolderData?.folder.id)) &&
                    link?.type === LinkTypeOption.GALLERY &&
                    maskType !== "circle" && (
                      <div className={styles.albumLock}>
                        <AlbumLock color={colors.darkGrey} size={24} />
                      </div>
                    )}
                </div>
              )}

              {captionText !== "" && (
                <p className={cx(captionClass, styles[siteFonts.secondary])} style={captionStyle}>
                  {captionText}
                </p>
              )}
            </div>
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}
