import { getUserIdFromToken, isErrorType } from "@zenfolio/core-components";
import jwtDecode from "jwt-decode";
import { identityClient } from "../api";
import { apiType } from "../api/apiType";
import { identityInvalidRefreshTokenError } from "../api/errorTypes";
import IError from "./error";

interface IBookingWidgetSignupResponse {
  accessToken: string;
  refreshToken: string;
}

interface IBookingWidgetLoginResponse {
  token: string;
}

interface IPhotographerStorage {
  photographerId?: string;
  refreshToken?: string;
  accessToken?: string;
}

export abstract class AuthenticationManager {
  private static readonly photographerStorageKey: string = "photographer_storage";
  private static readonly tokenReservedLifetime: number = 2 * 60 * 60;

  public static getUserId(decodedToken: any) {
    return getUserIdFromToken(decodedToken);
  }

  public static async getTestAccessTokenByPhotographerId(photographerId: string | null) {
    if (!photographerId) {
      return null;
    }

    const storage = AuthenticationManager.getPhotographerStorage();
    if (
      !storage.photographerId ||
      storage.photographerId.toLowerCase() !== photographerId.toLowerCase() ||
      !storage.refreshToken
    ) {
      return await AuthenticationManager.recreateRefreshToken(photographerId);
    }

    const token = AuthenticationManager.getAndValidateBookingWidgetAccessToken(storage);
    if (token) {
      return token;
    }

    return await AuthenticationManager.generateAccessToken(storage);
  }

  public static getPhotographerStorage(): IPhotographerStorage {
    let result: IPhotographerStorage | null = null;
    const value = localStorage.getItem(AuthenticationManager.photographerStorageKey);

    if (value) {
      try {
        result = JSON.parse(value);
        // tslint:disable-next-line:no-empty
      } catch (e) {}
    }

    return result || {};
  }

  public static savePhotographerStorage(storage: IPhotographerStorage | null) {
    if (storage) {
      localStorage.setItem(AuthenticationManager.photographerStorageKey, JSON.stringify(storage));
    } else {
      localStorage.removeItem(AuthenticationManager.photographerStorageKey);
    }
  }

  private static async recreateRefreshToken(photographerId: string) {
    try {
      const response = await identityClient.post<IBookingWidgetSignupResponse>(`${apiType.identities}widget/signup`, {
        photographerExternalId: photographerId
      });

      AuthenticationManager.savePhotographerStorage({
        photographerId,
        refreshToken: response.refreshToken,
        accessToken: response.accessToken
      });

      return response.accessToken;
    } catch (error) {
      return null;
    }
  }

  private static async generateAccessToken(storage: IPhotographerStorage) {
    let response: IBookingWidgetLoginResponse | null = null;

    try {
      response = await identityClient.post<IBookingWidgetLoginResponse>(`${apiType.identities}widget/login`, {
        refreshToken: storage.refreshToken
      });
    } catch (error) {
      if (isErrorType((error as IError).type, identityInvalidRefreshTokenError)) {
        return await AuthenticationManager.recreateRefreshToken(storage.photographerId!);
      }
    }

    if (!response) {
      return null;
    }

    AuthenticationManager.savePhotographerStorage({
      ...storage,
      accessToken: response.token
    });

    return response.token;
  }

  private static getAndValidateBookingWidgetAccessToken(storage: IPhotographerStorage) {
    if (!storage.accessToken) {
      return null;
    }

    try {
      // decode the token
      const payload: any = jwtDecode(storage.accessToken);

      // validate expiration time
      const expirationTime: number = payload.exp || 0;
      const currentTime = new Date().getTime() / 1000 + AuthenticationManager.tokenReservedLifetime;

      if (currentTime >= expirationTime) {
        return null;
      }

      // validate photographer id
      if (
        !storage.photographerId ||
        storage.photographerId.toLowerCase() !== AuthenticationManager.getUserId(payload).toLowerCase()
      ) {
        return null;
      }

      return storage.accessToken;
    } catch {
      return null;
    }
  }
}
