import { Collapse, Panel } from "@zenfolio/core-components";
import classNames from "classnames";
import * as React from "react";
import Helmet from "react-helmet";
import { Link, RouteComponentProps } from "react-router-dom";
import { ActionStatus } from "../../store/common";
import { IProfileState } from "../../store/profile/state/model";
import Auxiliary from "../../utilities/auxiliary";
import colors from "../../utilities/colors";
import Circle from "../icons/Circle";
import Success from "../icons/Success";
import Loader from "../layout/loader";
import UrlConstants from "../router/constants";
import styles from "./index.module.scss";

enum CollapsePanelKey {
  Profile = "profile",
  Services = "services",
  Activation = "activation"
}

interface IPanelState {
  [CollapsePanelKey.Profile]: boolean;
  [CollapsePanelKey.Services]: boolean;
  [CollapsePanelKey.Activation]: boolean;
  [index: string]: boolean;
}

interface IWelcomeProps extends RouteComponentProps {
  loadStatus: ActionStatus;
  profileState: IProfileState;
  onProfileState: () => void;
}

interface IWelcomeState {
  panelState: IPanelState;
}

class Welcome extends React.Component<IWelcomeProps, IWelcomeState> {
  constructor(props: IWelcomeProps) {
    super(props);

    this.state = { panelState: Welcome.defaultsPanelState };
  }

  public componentDidMount() {
    const { onProfileState } = this.props;
    onProfileState();
  }

  public render() {
    const { loadStatus } = this.props;

    return (
      <div className={classNames(styles.container, loadStatus === "Error" ? styles.error : null)}>
        <Helmet title="Welcome" />
        {loadStatus === "Success" && this.renderSteps()}
        {loadStatus === "Pending" && <Loader />}
      </div>
    );
  }

  private renderSteps() {
    const { panelState } = this.state;
    const { profileState } = this.props;

    return (
      <Auxiliary>
        <p className={styles.description}>
          BookMe, a booking tool by photographers for photographers, is the easiest way to take bookings online. Start
          taking bookings in less than 3 minutes. Use this checklist to make sure you’re all set up.
        </p>
        <div className={styles.collapse}>
          <Collapse>
            <Panel
              name={CollapsePanelKey.Profile}
              headerClassName={styles.header}
              contentClassName={styles.panel}
              {...Panel.defaultProps}
              open={panelState.profile}
              title="Complete your Profile"
              onChange={this.onClickCollapse}
              step={this.renderStepIcon(this.isProfileComplete(), 1)}
            >
              <div className={styles.actions}>
                {this.renderStatus(
                  UrlConstants.profileShootLocation,
                  profileState.profile.shootLocation,
                  "Enter a Primary Location address."
                )}
                {this.renderStatus(
                  UrlConstants.profileAvailability,
                  profileState.profile.availability,
                  "Set up your Bookable Hours and connect Google Calendar. (optional)"
                )}
                {this.renderStatus(
                  UrlConstants.profileContactInformation,
                  profileState.profile.contacts,
                  "Confirm your Contact Information."
                )}
                {this.renderStatus(
                  UrlConstants.profileCollectingPayments,
                  profileState.profile.payments,
                  "Connect with Stripe to start accepting payments."
                )}
              </div>
            </Panel>
            <Panel
              name={CollapsePanelKey.Services}
              headerClassName={styles.header}
              contentClassName={styles.panel}
              {...Panel.defaultProps}
              open={panelState.services}
              title="Create a Service"
              onChange={this.onClickCollapse}
              step={this.renderStepIcon(this.isServiceComplete(), 2)}
            >
              <div className={styles.actions}>
                {this.renderStatus(
                  UrlConstants.services,
                  profileState.services.createdAndEnabled,
                  "Create and enable at least one service."
                )}
              </div>
            </Panel>
            <Panel
              name={CollapsePanelKey.Activation}
              headerClassName={styles.header}
              contentClassName={styles.panel}
              {...Panel.defaultProps}
              open={panelState.activation}
              title="Activate BookMe"
              onChange={this.onClickCollapse}
              step={this.renderStepIcon(this.isActivationComplete(), 3)}
            >
              <div className={styles.actions}>
                {this.renderStatus(UrlConstants.activation, profileState.activation.enabled, "Activate BookMe")}
              </div>
            </Panel>
          </Collapse>
        </div>
      </Auxiliary>
    );
  }

  private onClickCollapse = (key: string, open: boolean) => {
    this.setState({
      panelState: this.getPanelState(key, open)
    });
  };

  private getPanelState(key: string, open: boolean): IPanelState {
    const panelState = Object.assign({}, Welcome.defaultsPanelState);
    panelState[key] = open;
    return panelState;
  }

  private isProfileComplete = () => {
    const { profileState } = this.props;
    const { shootLocation, availability, payments, contacts } = profileState.profile;
    return shootLocation && availability && payments && contacts;
  };

  private isServiceComplete = () => {
    const { profileState } = this.props;
    return profileState.services.createdAndEnabled;
  };

  private isActivationComplete = () => {
    const { profileState } = this.props;
    return profileState.activation.enabled;
  };

  private renderStatus = (to: string, status: boolean, text: string) => {
    return (
      <p>
        {this.renderStatusIcon(status)}
        <Link to={to} className={status ? styles.active : undefined}>
          {text}
        </Link>
      </p>
    );
  };

  private renderStatusIcon = (status: boolean) => {
    return (
      <span className={styles.icon}>
        {status ? <Success size={12} fill={colors.darkGreen} /> : Circle(styles.circle)}
      </span>
    );
  };

  private renderStepIcon = (checked: boolean, step: number) => {
    return checked ? <Success size={24} fill={colors.darkGreen} /> : step;
  };

  public static defaultsProfileState: IProfileState = {
    profile: {
      shootLocation: false,
      availability: false,
      payments: false,
      contacts: false
    },
    services: {
      createdAndEnabled: false
    },
    activation: {
      enabled: false
    }
  };

  public static defaultsPanelState: IPanelState = {
    [CollapsePanelKey.Profile]: false,
    [CollapsePanelKey.Services]: false,
    [CollapsePanelKey.Activation]: false
  };
}

export default Welcome;
