import React from "react";
import IIConProps from "components/Icons/IIconProps";

const IconDownload = (props: IIConProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path
        fill={props.color}
        d="M14.229 14.984H1.887c-.49 0-.887-.396-.887-.887v-3.529c0-.49.396-.887.887-.887.49 0 .887.396.887.887v2.642h10.568v-2.642c0-.49.396-.887.887-.887.49 0 .887.396.887.887v3.529c-.019.49-.415.887-.887.887zm-5.53-5.435c-.358.358-.943.358-1.302 0L4.793 6.963c-.358-.358-.358-.943 0-1.302.359-.358.944-.358 1.302 0l1.076 1.076v-4.85c0-.49.396-.887.887-.887.49 0 .887.396.887.887v4.831l1.076-1.057c.358-.358.943-.358 1.302 0 .358.359.358.944 0 1.302L8.7 9.55z"
      />
    </svg>
  );
};

export default IconDownload;
