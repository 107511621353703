import cx from "classnames";
import Image from "components/Image";
import { IZenSiteTheme } from "../../../models/models";
import { PageContext } from "utilities/pageContext";
import { IHeroFramesImageData } from "utilities/placeholdes";
import React from "react";
import { isIOS, isIPad13, isTablet } from "react-device-detect";
import { Fade, Slide } from "react-slideshow-image";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import styles from "./zenHeroAnimatedFramesBlock.module.scss";
import { universalWindow as window } from "../../../utilities";
import { ScreenSizeBreakPoints } from "../../../utilities/screenResolution";

interface IHeroFrameProps {
  style: string;
  display: string;
  zIndex: number;
  photos: IHeroFramesImageData[];
  width: string;
  height: string;
  delay: number;
  selectedIndex: number;
}

interface IHeroFramesPropertiesState {
  navBarHeight: number;
  blockHeight: string;
  leftImageHeight: string;
  leftImageWidth: string;
  middleImageHeight: string;
  middleImageWidth: string;
  rightImageHeight: string;
  rightImageWidth: string;
  leftImageLeft: string;
  leftImageTop: string;
  rightImageLeft: string;
  rightImageTop: string;
  contentPadding: string;
  middleMargin: string;
  selectedIndexLeft: number;
  selectedIndexMiddle: number;
  selectedIndexRight: number;
  animationCount: number;
  leftFramePhotos: IHeroFramesImageData[];
  middleFramePhotos: IHeroFramesImageData[];
  rightFramePhotos: IHeroFramesImageData[];
  fadeAnimTime: number;
}

export interface IHeroFramesPropertiesProps extends IZenBaseBlockProps {
  templateAccentColor: string;
  templateBackgroundColor: string;
  transition: string;
  speed: string;
  parallaxEnabled: boolean;
  padding: string;
  leftFramePhotos: IHeroFramesImageData[];
  middleFramePhotos: IHeroFramesImageData[];
  rightFramePhotos: IHeroFramesImageData[];
  backgroundType: string;
  backgroundOpacity: number;
  backgroundWidth: string;
  backgroundColor: string;
  customNavbarHeight?: number;
  layout: string;
  notAnimate?: boolean;
  isPublish?: boolean;
  selectedFrame?: number;
  isEditionView?: boolean;
  siteTheme: IZenSiteTheme;
  onFrameSelected?: (frame: number) => void;
}

enum DEVICE {
  MOBILE = 0,
  TABLET = 1,
  DESKTOP = 2
}

export class ZenHeroAnimatedFramesBlock extends React.PureComponent<
  IHeroFramesPropertiesProps,
  IHeroFramesPropertiesState
> {
  public static defaultProps = {
    maskType: "none",
    maskPosition: "center",
    dropShadow: false,
    fullWidth: false,
    size: 0
  };

  static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  private LAYOUT_A_HEIGHT = 696;
  private LAYOUT_B_HEIGHT = 520;
  private LAYOUT_C_HEIGHT = 616;
  private LAYOUT_D_HEIGHT = 480;

  private LAYOUT_A_LEFT_RATIO = 2 / 3;
  private LAYOUT_A_MIDDLE_RATIO = 5 / 7;
  private LAYOUT_A_RIGHT_RATIO = 2 / 3;

  private LAYOUT_A_LEFT_HEIGHT = 408;
  private LAYOUT_A_MIDDLE_HEIGHT = 616;
  private LAYOUT_A_RIGHT_HEIGHT = 408;

  private LAYOUT_B_LEFT_RATIO = 5 / 7;
  private LAYOUT_B_MIDDLE_RATIO = 1 / 1;
  private LAYOUT_B_RIGHT_RATIO = 5 / 7;

  private LAYOUT_B_LEFT_WIDTH = 272;
  private LAYOUT_B_MIDDLE_WIDTH = 440;
  private LAYOUT_B_RIGHT_WIDTH = 272;

  private LAYOUT_C_LEFT_RATIO = 5 / 7;
  private LAYOUT_C_MIDDLE_RATIO = 1 / 1;
  private LAYOUT_C_RIGHT_RATIO = 5 / 7;

  private LAYOUT_C_LEFT_WIDTH = 272;
  private LAYOUT_C_MIDDLE_WIDTH = 440;
  private LAYOUT_C_RIGHT_WIDTH = 272;

  private LAYOUT_D_LEFT_RATIO = 5 / 7;
  private LAYOUT_D_MIDDLE_RATIO = 2 / 3;
  private LAYOUT_D_RIGHT_RATIO = 5 / 7;

  private LAYOUT_D_LEFT_WIDTH = 272;
  private LAYOUT_D_MIDDLE_WIDTH = 600;
  private LAYOUT_D_RIGHT_WIDTH = 272;

  private Container: React.RefObject<HTMLDivElement>;
  private containerRef = React.createRef<HTMLDivElement>();

  private myRefSlideShowLeft = React.createRef<any>();
  private myRefSlideShowMiddle = React.createRef<any>();
  private myRefSlideShowRight = React.createRef<any>();

  private frameChange: any;

  private handleLazyLoaded = (e: Event) => {
    window.lazySizes?.autoSizer.checkElems();
  };

  constructor(props: IHeroFramesPropertiesProps) {
    super(props);

    this.state = {
      navBarHeight: 0,
      blockHeight: "0px",
      leftImageHeight: "0px",
      leftImageWidth: "0px",
      middleImageHeight: "0px",
      middleImageWidth: "0px",
      rightImageHeight: "0px",
      rightImageWidth: "0px",
      leftImageLeft: "0px",
      rightImageLeft: "0px",
      leftImageTop: "0px",
      rightImageTop: "0px",
      contentPadding: "40px",
      middleMargin: "8px",
      selectedIndexLeft: 0,
      selectedIndexMiddle: 0,
      selectedIndexRight: 0,
      animationCount: 0,
      leftFramePhotos: this.props.leftFramePhotos,
      middleFramePhotos: this.props.middleFramePhotos,
      rightFramePhotos: this.props.rightFramePhotos,
      fadeAnimTime: 0
    };

    this.Container = React.createRef();
  }

  public componentDidMount() {
    this.setLayoutConfig();
    this.determineVisibility();
    window.addEventListener("resize", this.changeOrientationSize);
    window.addEventListener("orientationchange", this.changeOrientationSize);
    window.addEventListener("lazyloaded", this.handleLazyLoaded);

    if (!this.props.notAnimate) {
      this.frameChange = this.getFrameChangeInterval();
    }
  }

  private determineVisibility = () => {
    const { desktopEnabled, tabletEnabled, mobileEnabled } = this.props;

    if (window.innerWidth <= ScreenSizeBreakPoints.Mobile && mobileEnabled) {
      this.context.setHasHero();
    } else if (
      window.innerWidth > ScreenSizeBreakPoints.Mobile &&
      window.innerWidth <= ScreenSizeBreakPoints.Tablet &&
      tabletEnabled
    ) {
      this.context.setHasHero();
    } else if (desktopEnabled) {
      this.context.setHasHero();
    }
  };

  public increaseLeft = () => {
    const incrementLeft = this.state.selectedIndexLeft + 1;
    const selectedIndexLeft =
      this.state.selectedIndexLeft === this.props.leftFramePhotos.length - 1 ? 0 : incrementLeft;
    this.setState(
      {
        selectedIndexLeft
      },
      this.increaseAnimationCount
    );
    if (this.myRefSlideShowLeft.current && this.state.leftFramePhotos.length > 1) {
      this.myRefSlideShowLeft.current.goNext();
    }
  };

  public increaseMiddle = () => {
    const incrementMiddle = this.state.selectedIndexMiddle + 1;
    const selectedIndexMiddle =
      this.state.selectedIndexMiddle === this.props.middleFramePhotos.length - 1 ? 0 : incrementMiddle;
    this.setState(
      {
        selectedIndexMiddle
      },
      this.increaseAnimationCount
    );
    if (this.myRefSlideShowMiddle.current && this.state.middleFramePhotos.length > 1) {
      this.myRefSlideShowMiddle.current.goNext();
    }
  };

  public increaseRight = () => {
    const incrementRight = this.state.selectedIndexRight + 1;
    const selectedIndexRight =
      this.state.selectedIndexRight === this.props.rightFramePhotos.length - 1 ? 0 : incrementRight;
    this.setState(
      {
        selectedIndexRight
      },
      this.increaseAnimationCount
    );
    if (this.myRefSlideShowRight.current && this.state.rightFramePhotos.length > 1) {
      this.myRefSlideShowRight.current.goNext();
    }
  };

  public getFrameChangeInterval() {
    return setInterval(() => {
      switch (this.state.animationCount) {
        case 0:
          this.increaseLeft();
          break;
        case 2:
          this.increaseMiddle();
          break;
        case 1:
          this.increaseRight();
          break;
      }
    }, this.getDelay(this.props.speed.toLowerCase()));
  }

  public getDelay(speed: string) {
    let result = 1000;
    switch (speed) {
      case "fast":
        result = 1000;
        break;
      case "normal":
        result = 2000;
        break;
      case "slow":
        result = 3000;
        break;
    }

    this.setState({ fadeAnimTime: result / 1000 });
    return result;
  }

  public increaseAnimationCount() {
    this.setState({ animationCount: this.state.animationCount === 2 ? 0 : this.state.animationCount + 1 });
  }

  public getFrames(): IHeroFrameProps[] {
    let result: IHeroFrameProps[] = [
      {
        style: styles.leftFrame,
        display: `${
          this.getDevice() === DEVICE.MOBILE ? (this.state.animationCount === 0 ? "block" : "none") : "block"
        }`,
        zIndex: 101,
        photos: this.state.leftFramePhotos,
        width: this.state.leftImageWidth,
        height: this.state.leftImageHeight,
        selectedIndex: this.state.selectedIndexLeft,
        delay: 3000
      },
      {
        style: styles.middleFrame,
        display: `${
          this.getDevice() === DEVICE.MOBILE ? (this.state.animationCount === 1 ? "block" : "none") : "block"
        }`,
        zIndex: 100,
        photos: this.state.middleFramePhotos,
        width: this.state.middleImageWidth,
        height: this.state.middleImageHeight,
        selectedIndex: this.state.selectedIndexMiddle,
        delay: 5000
      },
      {
        style: styles.rightFrame,
        display: `${
          this.getDevice() === DEVICE.MOBILE ? (this.state.animationCount === 2 ? "block" : "none") : "block"
        }`,
        zIndex: 100,
        photos: this.state.rightFramePhotos,
        width: this.state.rightImageWidth,
        height: this.state.rightImageHeight,
        selectedIndex: this.state.selectedIndexRight,
        delay: 4000
      }
    ];
    return result;
  }

  public getDevice() {
    const isMobileView =
      this.containerRef && this.containerRef.current ? this.containerRef.current?.offsetWidth <= 720 : false;

    const isTabletView =
      this.containerRef && this.containerRef.current
        ? this.containerRef.current?.offsetWidth > 720 && this.containerRef.current?.offsetWidth <= 1024
        : false;

    const isMobileDevice = isMobileView;
    const isTabletDevice = isTablet || isIOS || isIPad13 || isTabletView;

    if (isMobileDevice) {
      return DEVICE.MOBILE;
    } else if (isTabletDevice) {
      return DEVICE.TABLET;
    } else {
      return DEVICE.DESKTOP;
    }
  }

  public setMobileLayoutParameters(callback: any) {
    const windowWidth = this.containerRef && this.containerRef.current ? this.containerRef.current.offsetWidth : 1;

    this.LAYOUT_A_HEIGHT = windowWidth / this.LAYOUT_A_MIDDLE_RATIO;
    this.LAYOUT_A_MIDDLE_HEIGHT = this.LAYOUT_A_HEIGHT;
    this.LAYOUT_A_LEFT_HEIGHT = (windowWidth * 0.62) / this.LAYOUT_A_MIDDLE_RATIO;
    this.LAYOUT_A_RIGHT_HEIGHT = (windowWidth * 0.62) / this.LAYOUT_A_MIDDLE_RATIO;

    this.LAYOUT_B_HEIGHT = windowWidth / this.LAYOUT_B_MIDDLE_RATIO;
    this.LAYOUT_B_MIDDLE_WIDTH = this.LAYOUT_B_HEIGHT;
    this.LAYOUT_B_LEFT_WIDTH = windowWidth * 0.62;
    this.LAYOUT_B_RIGHT_WIDTH = windowWidth * 0.62;

    this.LAYOUT_C_HEIGHT = windowWidth / this.LAYOUT_C_MIDDLE_RATIO;
    this.LAYOUT_C_MIDDLE_WIDTH = this.LAYOUT_C_HEIGHT;
    this.LAYOUT_C_LEFT_WIDTH = windowWidth * 0.62;
    this.LAYOUT_C_RIGHT_WIDTH = windowWidth * 0.62;

    this.LAYOUT_D_HEIGHT = windowWidth * this.LAYOUT_D_MIDDLE_RATIO;
    this.LAYOUT_D_MIDDLE_WIDTH = windowWidth;
    this.LAYOUT_D_LEFT_WIDTH = windowWidth * 0.62;
    this.LAYOUT_D_RIGHT_WIDTH = windowWidth * 0.62;

    callback();
  }

  public setTabletLayoutParameters(callback: any) {
    this.LAYOUT_A_HEIGHT = 502;
    this.LAYOUT_A_LEFT_HEIGHT = 280;
    this.LAYOUT_A_MIDDLE_HEIGHT = 422;
    this.LAYOUT_A_RIGHT_HEIGHT = 280;

    this.LAYOUT_B_HEIGHT = 381;
    this.LAYOUT_B_LEFT_WIDTH = 186;
    this.LAYOUT_B_MIDDLE_WIDTH = 301;
    this.LAYOUT_B_RIGHT_WIDTH = 186;

    this.LAYOUT_C_HEIGHT = 605;
    this.LAYOUT_C_LEFT_WIDTH = 267;
    this.LAYOUT_C_MIDDLE_WIDTH = 431;
    this.LAYOUT_C_RIGHT_WIDTH = 267;

    this.LAYOUT_D_HEIGHT = 353;
    this.LAYOUT_D_LEFT_WIDTH = 186;
    this.LAYOUT_D_MIDDLE_WIDTH = 410;
    this.LAYOUT_D_RIGHT_WIDTH = 186;

    callback();
  }

  public setDesktopLayoutParameters(callback: any) {
    this.LAYOUT_A_HEIGHT = 696;
    this.LAYOUT_A_LEFT_HEIGHT = 408;
    this.LAYOUT_A_MIDDLE_HEIGHT = 616;
    this.LAYOUT_A_RIGHT_HEIGHT = 408;

    this.LAYOUT_B_HEIGHT = 520;
    this.LAYOUT_B_LEFT_WIDTH = 272;
    this.LAYOUT_B_MIDDLE_WIDTH = 440;
    this.LAYOUT_B_RIGHT_WIDTH = 272;

    this.LAYOUT_C_HEIGHT = 616;
    this.LAYOUT_C_LEFT_WIDTH = 272;
    this.LAYOUT_C_MIDDLE_WIDTH = 440;
    this.LAYOUT_C_RIGHT_WIDTH = 272;

    this.LAYOUT_D_HEIGHT = 480;
    this.LAYOUT_D_LEFT_WIDTH = 272;
    this.LAYOUT_D_MIDDLE_WIDTH = 600;
    this.LAYOUT_D_RIGHT_WIDTH = 272;

    callback();
  }

  public checkDevice() {
    const device: DEVICE = this.getDevice();

    switch (device) {
      case DEVICE.MOBILE:
        this.setMobileLayoutParameters(() => {
          this.setState({ contentPadding: "0px", middleMargin: "0px" });
        });
        break;
      case DEVICE.TABLET:
        this.setTabletLayoutParameters(() => {
          this.setState({ contentPadding: "0px", middleMargin: "8px" });
        });
        break;
      case DEVICE.DESKTOP:
        this.setDesktopLayoutParameters(() => {
          this.setState({ contentPadding: "40px", middleMargin: "8px" });
        });
        break;
    }
  }

  public updateLayoutAState() {
    const windowWidth = this.containerRef && this.containerRef.current ? this.containerRef.current.offsetWidth : 1;

    let resultLayoutHeight = this.LAYOUT_A_HEIGHT;
    let resultLeftImageHeight = this.LAYOUT_A_LEFT_HEIGHT;
    let resultLeftImageWidth = resultLeftImageHeight * this.LAYOUT_A_LEFT_RATIO;
    let resultMiddleImageHeight = this.LAYOUT_A_MIDDLE_HEIGHT;
    let resultMiddleImageWidth = resultMiddleImageHeight * this.LAYOUT_A_MIDDLE_RATIO;
    let resultRightImageHeight = this.LAYOUT_A_RIGHT_HEIGHT;
    let resultRightImageWidth = resultRightImageHeight * this.LAYOUT_A_RIGHT_RATIO;
    let resultLeftImageLeft = this.getDevice() === DEVICE.MOBILE ? `-${windowWidth * 0.19 - 32}px` : "0px";
    let resultRightImageLeft = this.getDevice() === DEVICE.MOBILE ? `${windowWidth * 0.19 - 32}px` : "0px";
    let resultLeftImageTop = "0px";
    let resultRightImageTop = "0px";

    this.setState({
      blockHeight: `${resultLayoutHeight}px`,
      leftImageHeight: `${resultLeftImageHeight}px`,
      leftImageWidth: `${resultLeftImageWidth}px`,
      middleImageHeight: `${resultMiddleImageHeight}px`,
      middleImageWidth: `${resultMiddleImageWidth}px`,
      rightImageHeight: `${resultRightImageHeight}px`,
      rightImageWidth: `${resultRightImageWidth}px`,
      leftImageLeft: `${resultLeftImageLeft}`,
      rightImageLeft: `${resultRightImageLeft}`,
      leftImageTop: `${resultLeftImageTop}`,
      rightImageTop: `${resultRightImageTop}`
    });
  }

  public updateLayoutBState() {
    let resultLeftImageLeft = "0px";
    let resultRightImageLeft = "0px";

    let resultLeftImageTop = "0px";
    let resultRightImageTop = "0px";

    let resultLayoutHeight = this.LAYOUT_B_HEIGHT;
    let resultLeftImageWidth = this.LAYOUT_B_LEFT_WIDTH;
    let resultLeftImageHeight = resultLeftImageWidth * this.LAYOUT_B_LEFT_RATIO;
    let resultMiddleImageWidth = this.LAYOUT_B_MIDDLE_WIDTH;
    let resultMiddleImageHeight = resultMiddleImageWidth * this.LAYOUT_B_MIDDLE_RATIO;
    let resultRightImageWidth = this.LAYOUT_B_RIGHT_WIDTH;
    let resultRightImageHeight = resultRightImageWidth * this.LAYOUT_B_RIGHT_RATIO;

    this.setState({
      blockHeight: `${resultLayoutHeight}px`,
      leftImageHeight: `${resultLeftImageHeight}px`,
      leftImageWidth: `${resultLeftImageWidth}px`,
      middleImageHeight: `${resultMiddleImageHeight}px`,
      middleImageWidth: `${resultMiddleImageWidth}px`,
      rightImageHeight: `${resultRightImageHeight}px`,
      rightImageWidth: `${resultRightImageWidth}px`,
      leftImageLeft: `${resultLeftImageLeft}`,
      rightImageLeft: `${resultRightImageLeft}`,
      leftImageTop: `${resultLeftImageTop}`,
      rightImageTop: `${resultRightImageTop}`
    });
  }

  public updateLayoutCState() {
    const windowWidth = this.containerRef && this.containerRef.current ? this.containerRef.current.offsetWidth : 1;

    let resultLayoutHeight = this.LAYOUT_C_HEIGHT;
    let resultLeftImageWidth = this.LAYOUT_C_LEFT_WIDTH;
    let resultLeftImageHeight = resultLeftImageWidth * this.LAYOUT_C_LEFT_RATIO;
    let resultMiddleImageWidth = this.LAYOUT_C_MIDDLE_WIDTH;
    let resultMiddleImageHeight = resultMiddleImageWidth * this.LAYOUT_C_MIDDLE_RATIO;
    let resultRightImageWidth = this.LAYOUT_C_RIGHT_WIDTH;
    let resultRightImageHeight = resultRightImageWidth * this.LAYOUT_C_RIGHT_RATIO;
    let resultLeftImageLeft = this.getDevice() === DEVICE.MOBILE ? `-${windowWidth * 0.19 - 32}px` : "136px";
    let resultRightImageLeft = this.getDevice() === DEVICE.MOBILE ? `${windowWidth * 0.19 - 32}px` : "-136px";
    let resultLeftImageTop = this.getDevice() === DEVICE.MOBILE ? `-${windowWidth * 0.28 - 16}px` : "-169px";
    let resultRightImageTop = this.getDevice() === DEVICE.MOBILE ? `${windowWidth * 0.28 - 16}px` : "169px";

    this.setState({
      blockHeight: `${resultLayoutHeight}px`,
      leftImageHeight: `${resultLeftImageHeight}px`,
      leftImageWidth: `${resultLeftImageWidth}px`,
      middleImageHeight: `${resultMiddleImageHeight}px`,
      middleImageWidth: `${resultMiddleImageWidth}px`,
      rightImageHeight: `${resultRightImageHeight}px`,
      rightImageWidth: `${resultRightImageWidth}px`,
      leftImageLeft: `${resultLeftImageLeft}`,
      rightImageLeft: `${resultRightImageLeft}`,
      leftImageTop: `${resultLeftImageTop}`,
      rightImageTop: `${resultRightImageTop}`
    });
  }

  public updateLayoutDState() {
    const windowWidth = this.containerRef && this.containerRef.current ? this.containerRef.current.offsetWidth : 1;

    let resultLayoutHeight = this.LAYOUT_D_HEIGHT;
    let resultLeftImageWidth = this.LAYOUT_D_LEFT_WIDTH;
    let resultLeftImageHeight = resultLeftImageWidth * this.LAYOUT_D_LEFT_RATIO;
    let resultMiddleImageWidth = this.LAYOUT_D_MIDDLE_WIDTH;
    let resultMiddleImageHeight = resultMiddleImageWidth * this.LAYOUT_D_MIDDLE_RATIO;
    let resultRightImageWidth = this.LAYOUT_D_RIGHT_WIDTH;
    let resultRightImageHeight = resultRightImageWidth * this.LAYOUT_D_RIGHT_RATIO;
    let resultLeftImageLeft = this.getDevice() === DEVICE.MOBILE ? `-${windowWidth * 0.19 - 32}px` : "90px";
    let resultRightImageLeft = this.getDevice() === DEVICE.MOBILE ? `${windowWidth * 0.19 - 32}px` : "-90px";
    let resultLeftImageTop = "0px";
    let resultRightImageTop = "0px";

    this.setState({
      blockHeight: `${resultLayoutHeight}px`,
      leftImageHeight: `${resultLeftImageHeight}px`,
      leftImageWidth: `${resultLeftImageWidth}px`,
      middleImageHeight: `${resultMiddleImageHeight}px`,
      middleImageWidth: `${resultMiddleImageWidth}px`,
      rightImageHeight: `${resultRightImageHeight}px`,
      rightImageWidth: `${resultRightImageWidth}px`,
      leftImageLeft: `${resultLeftImageLeft}`,
      rightImageLeft: `${resultRightImageLeft}`,
      leftImageTop: `${resultLeftImageTop}`,
      rightImageTop: `${resultRightImageTop}`
    });
  }

  public setLayoutConfig() {
    this.checkDevice();

    switch (this.props.layout) {
      case "A":
        this.updateLayoutAState();
        break;
      case "B":
        this.updateLayoutBState();
        break;
      case "C":
        this.updateLayoutCState();
        break;
      case "D":
        this.updateLayoutDState();
        break;
    }
  }

  public resetInterval() {
    clearInterval(this.frameChange);
    this.frameChange = this.getFrameChangeInterval();
  }

  public componentDidUpdate(prevProps: IHeroFramesPropertiesProps) {
    if (this.props.speed !== prevProps.speed && !this.props.notAnimate) {
      clearInterval(this.frameChange);
      this.frameChange = this.getFrameChangeInterval();
    }

    if (this.props.layout !== prevProps.layout) {
      this.setLayoutConfig();
    }

    if (JSON.stringify(this.props.leftFramePhotos) !== JSON.stringify(prevProps.leftFramePhotos)) {
      this.setState({ leftFramePhotos: this.props.leftFramePhotos }, this.resetInterval);
    }

    if (JSON.stringify(this.props.middleFramePhotos) !== JSON.stringify(prevProps.middleFramePhotos)) {
      this.setState({ middleFramePhotos: this.props.middleFramePhotos }, this.resetInterval);
    }

    if (JSON.stringify(this.props.rightFramePhotos) !== JSON.stringify(prevProps.rightFramePhotos)) {
      this.setState({ rightFramePhotos: this.props.rightFramePhotos }, this.resetInterval);
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.changeOrientationSize);
    window.removeEventListener("orientationchange", this.changeOrientationSize);
    window.removeEventListener("lazyloaded", this.handleLazyLoaded);
    clearInterval(this.frameChange);
  }

  private changeOrientationSize = () => {
    this.setLayoutConfig();
  };

  private cancelDrag = (event: any) => {
    event.preventDefault();
    return false;
  };

  private getRef = (index: number) => {
    switch (index) {
      case 0:
        return this.myRefSlideShowLeft;
      case 1:
        return this.myRefSlideShowMiddle;
      case 2:
        return this.myRefSlideShowRight;
      default:
        return this.myRefSlideShowLeft;
    }
  };

  private getFrameImage(
    frame: IHeroFrameProps,
    photo: IHeroFramesImageData,
    indexFrame: number,
    indexPhoto: number,
    addImageFade: boolean = false
  ) {
    if (photo === undefined || !frame.photos === undefined || !frame.photos[indexPhoto] === undefined) {
      return <></>;
    }

    const { url, srcset, width, height, altText, name } = photo;

    let isImageSelected =
      (indexFrame === 0 && this.state.selectedIndexLeft === indexPhoto) ||
      (indexFrame === (this.getDevice() === DEVICE.MOBILE ? 2 : 1) && this.state.selectedIndexMiddle === indexPhoto) ||
      (indexFrame === (this.getDevice() === DEVICE.MOBILE ? 1 : 2) && this.state.selectedIndexRight === indexPhoto);

    const isGhost = !this.context.noGhost && photo.isFallback;

    return (
      <div
        key={`photo-frame-${indexFrame}-${indexPhoto}`}
        className={cx(isGhost ? styles.whiteBack : styles.transparentBack)}
      >
        <div
          style={{
            width: `${frame.width}`,
            height: `${frame.height}`,
            backgroundColor: "transparent"
          }}
          className={cx(
            styles.img,
            { [styles.image]: addImageFade && !isGhost },
            {
              [styles.fadeImg]:
                addImageFade &&
                isImageSelected &&
                !isGhost &&
                this.props.transition === "slide + fade" &&
                this.getDevice() !== DEVICE.MOBILE
            },
            {
              [styles.fadeImg2]:
                addImageFade &&
                !isImageSelected &&
                !isGhost &&
                this.props.transition === "slide + fade" &&
                this.getDevice() !== DEVICE.MOBILE
            },
            {
              [styles.fadeImgFallback]: addImageFade && isImageSelected && isGhost && this.getDevice() !== DEVICE.MOBILE
            },
            {
              [styles.fadeImg2Fallback]:
                addImageFade && !isImageSelected && isGhost && this.getDevice() !== DEVICE.MOBILE
            },
            { [styles.fallbackImage]: isGhost },
            { [styles.imageFallback]: addImageFade && isGhost },
            {
              [styles.mobileFade]:
                this.props.transition !== "slide" && isImageSelected && this.getDevice() === DEVICE.MOBILE
            },
            {
              [styles.mobileSlideLeft]:
                this.props.transition === "slide" &&
                isImageSelected &&
                this.getDevice() === DEVICE.MOBILE &&
                indexFrame === 0
            },
            {
              [styles.mobileSlideMiddle]:
                this.props.transition === "slide" &&
                isImageSelected &&
                this.getDevice() === DEVICE.MOBILE &&
                indexFrame === 1
            },
            {
              [styles.mobileSlideRight]:
                this.props.transition === "slide" &&
                isImageSelected &&
                this.getDevice() === DEVICE.MOBILE &&
                indexFrame === 2
            }
          )}
        >
          <Image
            src={url}
            srcset={srcset}
            alt={altText || name}
            width={width}
            height={height}
            fitParent={true}
            styles={{
              objectPosition: frame.photos[indexPhoto].focalX + "% " + frame.photos[indexPhoto].focalY + "%"
            }}
          />
        </div>
      </div>
    );
  }

  private getFrameMobile(frame: IHeroFrameProps, indexFrame: number) {
    return (
      <>
        {frame.photos.map((photo: any, indexPhoto: number) => {
          if (indexPhoto === this.state.selectedIndexLeft && this.state.animationCount === 0) {
            return this.getFrameImage(frame, photo, indexFrame, indexPhoto);
          }

          if (indexPhoto === this.state.selectedIndexRight && this.state.animationCount === 1) {
            return this.getFrameImage(frame, photo, indexFrame, indexPhoto);
          }

          if (indexPhoto === this.state.selectedIndexMiddle && this.state.animationCount === 2) {
            return this.getFrameImage(frame, photo, indexFrame, indexPhoto);
          }

          return null;
        })}
      </>
    );
  }

  private getFrameFadeComponent(frame: IHeroFrameProps, indexFrame: number) {
    return (
      <Fade ref={this.getRef(indexFrame)} arrows={false} autoplay={false}>
        {frame.photos.map((photo: any, indexPhoto: number) => {
          return this.getFrameImage(frame, photo, indexFrame, indexPhoto);
        })}
      </Fade>
    );
  }

  private getFrameSlideComponent(frame: IHeroFrameProps, indexFrame: number, addImageFade: boolean) {
    return (
      <Slide ref={this.getRef(indexFrame)} arrows={false} autoplay={false}>
        {frame.photos.map((photo: any, indexPhoto: number) => {
          return this.getFrameImage(frame, photo, indexFrame, indexPhoto, addImageFade);
        })}
      </Slide>
    );
  }

  private getFrame(frame: IHeroFrameProps, indexFrame: number) {
    try {
      if (this.getDevice() === DEVICE.MOBILE) {
        return this.getFrameMobile(frame, indexFrame);
      } else if (this.props.transition === "fade") {
        return this.getFrameFadeComponent(frame, indexFrame);
      } else if (this.props.transition === "slide") {
        return this.getFrameSlideComponent(frame, indexFrame, false);
      } else if (this.props.transition === "slide + fade") {
        return this.getFrameSlideComponent(frame, indexFrame, true);
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public render() {
    const {
      readOnly,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      isEditionView,
      siteTheme,
      padding,
      animationScrollIn,
      animationScrollOut,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      selectedBlock,
      fullWidth,
      onFrameSelected,
      layout
    } = this.props;

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        readOnly={readOnly}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        selectedBlock={selectedBlock}
        fullWidth={fullWidth}
        noPaddingMobile={true}
      >
        <div ref={this.Container} onDragStart={this.cancelDrag}>
          <style>
            {`
            :root {
              --accent-color: ${siteTheme.accentColor.value};
              --coverSize: ${this.state.blockHeight};
              --leftImageHeight: ${this.state.leftImageHeight};
              --leftImageWidth: ${this.state.leftImageWidth};
              --middleImageHeight: ${this.state.middleImageHeight};
              --middleImageWidth: ${this.state.middleImageWidth};
              --rightImageHeight: ${this.state.rightImageHeight};
              --rightImageWidth: ${this.state.rightImageWidth};
              --leftImageLeft: ${this.state.leftImageLeft};
              --leftImageTop: ${this.state.leftImageTop};
              --rightImageLeft: ${this.state.rightImageLeft};
              --rightImageTop: ${this.state.rightImageTop};
              --contentPadding: ${this.state.contentPadding};
              --middleMargin: ${this.state.middleMargin};
              --fadeAnimTime: ${`${this.state.fadeAnimTime}s`};
              --fadeAnimTimeSlide: ${`${this.state.fadeAnimTime / 2}s`};
              }
            `}
          </style>
          <div className={styles.zenContainer} ref={this.containerRef}>
            <div className={styles.content}>
              {this.getFrames().map((frame, indexFrame) => {
                return (
                  <div
                    key={`frame-${indexFrame}`}
                    className={cx(
                      frame.style,
                      { [styles.hightlight]: this.props.selectedFrame === indexFrame },
                      { [styles.cursorHover]: this.props.isEditionView }
                    )}
                    onClick={() => (onFrameSelected ? onFrameSelected(indexFrame) : null)}
                    style={{
                      display: frame.display,
                      zIndex: frame.zIndex
                    }}
                  >
                    {this.getFrame(frame, indexFrame)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ZenBaseBlock>
    );
  }
}
