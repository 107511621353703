import { Loading } from "@zenfolio/core-components";
import React from "react";
import LinkButton from "../../../../../components/linkButton";
import { IActivity, IBilling } from "../../../../../store/billing/model";
import ActivitiesComponent from "./activities";
import ActivityActions from "./activityActions";
import styles from "./index.module.scss";
import { ActivityActionCode, ILoad } from "./model";
import withStore from "./withStore";

export interface IActivitiesOwnProps {
  bookingId: string;
  onActivityAction: (activity: IActivity, action: ActivityActionCode) => void;
  onAddInvoice: () => void;
}

export interface IActivitiesProps extends IActivitiesOwnProps {
  billing?: IBilling;
  load: ILoad;
}

interface IActivitiesState {
  selectedActivityId?: string;
  resetActions: boolean;
}

class Activities extends React.Component<IActivitiesProps, IActivitiesState> {
  public state: IActivitiesState = { resetActions: false };

  public componentDidMount() {
    if (!this.props.billing) {
      this.props.load.onLoad(this.props.bookingId);
    }
  }

  public render() {
    const { load, billing, onAddInvoice } = this.props;
    const { selectedActivityId, resetActions } = this.state;

    return (
      <div className={styles.container}>
        {load.status === "Pending" && <Loading styles={{ margin: "151px auto", height: "50px" }} />}
        {load.status === "Success" && (
          <>
            <div className={styles.title}>Activity</div>
            <ActivitiesComponent
              className={styles.activities}
              activities={billing!.activities}
              selectedActivityId={selectedActivityId}
              onSelect={this.onActivitySelect}
            />
            <LinkButton className={styles.addInvoice} onClick={onAddInvoice}>
              Add New Invoice
            </LinkButton>
            <ActivityActions
              className={styles.actions}
              activityStatus={this.selectedActivity === undefined ? undefined : this.selectedActivity!.status.code}
              availableRefundAmount={
                this.selectedActivity === undefined ? undefined : this.selectedActivity?.availableRefundAmount
              }
              reset={resetActions}
              onAction={this.onActivityAction}
            />
          </>
        )}
      </div>
    );
  }

  private get selectedActivity() {
    return this.state.selectedActivityId === undefined
      ? undefined
      : this.props.billing!.activities.find(activity => activity.id === this.state.selectedActivityId);
  }

  private onActivitySelect = (activityId: string) =>
    this.setState({ selectedActivityId: activityId, resetActions: true });

  private onActivityAction = (action: ActivityActionCode) => {
    this.setState({ resetActions: false });
    this.props.onActivityAction(this.selectedActivity!, action);
  };
}

export default withStore(Activities);
