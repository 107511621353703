import React from "react";
import IIConProps from "../IIconProps";

const Cart = (props: IIConProps) => {
  const opacity = props.opacity || 1;
  const width = props.width || props.size;
  const height = props.height || props.size;
  const minX = props.minX || 0;
  const minY = props.minY || 0;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`${minX} ${minY} ${32} ${32}`}>
      <path
        fill={props.color}
        fillOpacity={opacity}
        fillRule="nonzero"
        d="M9.674 10.25l2.115 8.036h14.254l2.783-8.036H9.674zM9.082 8h21.325c.773 0 1.316.762 1.063 1.493L27.907 19.78a1.125 1.125 0 0 1-1.063.757H12.381l.583 2.214H26a1.25 1.25 0 0 1 0 2.5H12a1.25 1.25 0 0 1-1.209-.932L6.036 6.25H3a1.25 1.25 0 1 1 0-2.5h4a1.25 1.25 0 0 1 1.209.932L9.082 8zM13 30a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm12 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
      />
    </svg>
  );
};

export default Cart;
