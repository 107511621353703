import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ISendInvoiceOwnProps, ISendInvoiceProps } from ".";
import { sendInvoice as sendInvoiceAction } from "../../../../../store/billing/actions";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ billing: { sendInvoice } }: IAppState) {
  return { sendInvoice };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    sendInvoice: {
      onSend: (invoiceId: string) => dispatch(sendInvoiceAction(invoiceId))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: ISendInvoiceOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    sendInvoice: { ...stateProps.sendInvoice, ...dispatchProps.sendInvoice }
  } as ISendInvoiceProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
