import { countries, FormTextInput, SelectDropdown, Utilities } from "@zenfolio/core-components";
import { IAddressInputErrors } from "@zenfolio/core-components/dist/components/AddressInput";
import { AddressInputValue } from "@zenfolio/core-components/dist/components/AddressInput/model";
import { IAddress } from "@zenfolio/core-components/dist/models/location";
import cx from "classnames";
import _ from "lodash";
import * as React from "react";
import { AddressInputWithApi } from "../../api";
import Arrow from "../icons/Arrow";
import { IShoppingAddressComponents, IState } from "../widget/contracts";
import {
  allStates,
  cityErrorMessage,
  formatStreet,
  validateAddressInputValue,
  validateState,
  zipCodeErrorMessage
} from "../widget/helpers";
import styles from "./index.module.scss";

// keep in sync with ./index.module.scss
const inputHeight = 46;
export const dropDownHeight = 200;

// effective minimum margin between the address and bottom of the block
export const addressMarginBottom = 16 /* inner */ + 1 /* border */ + 12 /* outer */;

interface IShoppingAddressProps extends IShoppingAddressComponents {
  className?: string;
  emptyClassName?: string;
  placeholder: string;
  zipCodeError: boolean;
  onAddressSubmit?: () => void;
  onAddressComponentsChange: (addressComponents: Partial<IShoppingAddressComponents>, callback?: () => void) => void;
  onScrollToSelector: (selector: string, customHeight?: number) => void;
}

interface IShoppingAddressState {
  countryOpen: boolean;
}

const incorrectAddressMessage = "Verify the address is correct";
const emptyAddressMessage = "Select from the dropdown options";

const addressErrors: IAddressInputErrors = {
  locationNotFoundError: incorrectAddressMessage,
  locationIncompleteError: incorrectAddressMessage,
  locationEmptyError: emptyAddressMessage
};

class ShoppingAddress extends React.PureComponent<IShoppingAddressProps, IShoppingAddressState> {
  constructor(props: IShoppingAddressProps) {
    super(props);

    this.state = {
      countryOpen: false
    };
  }

  public componentDidUpdate(prevProps: IShoppingAddressProps) {
    if (!prevProps.zipCodeError && this.props.zipCodeError) {
      this.scrollToClassName(styles.zipCode);
    }
  }

  public render() {
    const { countryOpen } = this.state;
    const { className, emptyClassName, placeholder, addressInputValue } = this.props;
    const isValid = validateAddressInputValue(addressInputValue);
    const availableCountries = _.filter(countries, c => c.value === "US");

    return (
      <div className={cx(styles.container, className, !isValid && emptyClassName)}>
        <div className={cx(styles.country, countryOpen && styles.open)}>
          <SelectDropdown
            placeholder="Country"
            options={availableCountries}
            onChange={_.noop}
            width="100%"
            maxWidth="100%"
            value={_.first(availableCountries)}
            indicator={false}
            isSearchable={false}
            openMenuOnFocus={true}
            autoScroll={true}
            onMenuOpen={this.onCountryOpen}
            onMenuClose={this.onCountryClose}
          />
          <Arrow className={styles.arrow} />
        </div>
        <AddressInputWithApi
          address={addressInputValue}
          onChange={this.onAddressInputValueChange}
          onFocus={this.addressInputOnFocus}
          placeholder={placeholder}
          errors={addressErrors}
          isCompleteAddress={Utilities.isAddressWithStreet}
          formatAddress={this.formatAddress}
          className={styles.addressInput}
          errorClassName={styles.withError}
          errorMessageClassName={cx(styles.errorMessage, !addressInputValue && styles.emptyErrorMessage)}
        />
        {isValid ? this.renderAddressComponents() : <div className={styles.emptyMessage}>{emptyAddressMessage}</div>}
      </div>
    );
  }

  private renderAddressComponents() {
    const { addressLine2, city, state, zipCode, zipCodeError, onAddressSubmit } = this.props;
    const validatedState = validateState(state);

    return (
      <>
        <FormTextInput
          placeholder="Street Address 2"
          value={addressLine2}
          onChange={this.onAddressLine2Change}
          maxLength={200}
          className={styles.input}
        />
        <FormTextInput
          placeholder="City"
          value={city}
          onChange={this.onCityChange}
          maxLength={200}
          className={styles.input}
          errorClassName={styles.errorMessage}
          errorMessage={cityErrorMessage(city)}
          onBlur={onAddressSubmit}
        />
        <div className={styles.state}>
          <SelectDropdown
            placeholder="State"
            options={allStates}
            onChange={this.onStateDropDownChange}
            width="100%"
            maxWidth="100%"
            value={validatedState || 0}
            indicator={true}
            isSearchable={false}
            openMenuOnFocus={true}
            autoScroll={true}
            onMenuOpen={this.onStateMenuOpen}
          />
        </div>
        <FormTextInput
          placeholder={"Zip"}
          value={zipCode}
          onChange={this.onZipCodeChange}
          maxLength={10}
          className={cx(styles.input, styles.zipCode)}
          errorClassName={styles.errorMessage}
          errorMessage={zipCodeErrorMessage(zipCode, zipCodeError)}
          forceShowingError={zipCodeError}
          onBlur={onAddressSubmit}
        />
      </>
    );
  }

  private formatAddress = (address: IAddress) => {
    if (!Utilities.isAddressWithStreet(address)) {
      return Utilities.getFullAddress(address);
    }

    return formatStreet(address);
  };

  private scrollToClassName(className: string, customHeight?: number) {
    if (this.props.className) {
      this.props.onScrollToSelector(`.${this.props.className} .${className}`, customHeight);
    }
  }

  private onAddressInputValueChange = (addressInputValue: AddressInputValue) => {
    const address = validateAddressInputValue(addressInputValue);
    this.props.onAddressComponentsChange(
      {
        addressInputValue,
        ...(address
          ? {
              city: address.city,
              state: validateState(address.state) ? address.state : "",
              zipCode: address.zipCode
            }
          : null)
      },
      this.props.onAddressSubmit
    );
  };

  private onAddressLine2Change = (value: string) => {
    this.props.onAddressComponentsChange({ addressLine2: value });
  };

  private onCityChange = (value: string) => {
    this.props.onAddressComponentsChange({ city: value });
  };

  private onStateDropDownChange = (state: IState) => {
    this.props.onAddressComponentsChange({ state: state.value }, this.props.onAddressSubmit);
  };

  private onZipCodeChange = (value: string) => {
    this.props.onAddressComponentsChange({ zipCode: value });
  };

  private addressInputOnFocus = () => {
    this.scrollToClassName(styles.addressInput, inputHeight + dropDownHeight);
  };

  private onCountryOpen = () => {
    this.setState({ countryOpen: true });
  };

  private onCountryClose = () => {
    this.setState({ countryOpen: false });
  };

  private onStateMenuOpen = _.debounce(() => {
    this.scrollToClassName(`${styles.state} .react-select-container`, inputHeight + dropDownHeight);
  }, 150);
}

export default ShoppingAddress;
