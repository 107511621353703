import React, { PureComponent } from "react";
import { WithStore, CarouselInjectedProps, Slider, Slide } from "pure-react-carousel";
import LightboxImage, { ILightboxImage } from "./../LightboxImage";
import { calculateIdxToLoad } from "../util";
import { universalWindow as window } from "utilities/blocks/site";
import { PhotoOrVideo } from "../../../models/models";
import { IAnalyticParams } from "../../NzVideo/Player";

export interface IImageSliderStyles {
  carouselSlider: string;
  carouselSlideInner: string;
  imgContainer: string;
}

interface IImageSliderProps extends CarouselInjectedProps {
  headerHeight?: number;
  currentSlide?: number;
  styles: IImageSliderStyles;
  images: PhotoOrVideo[];
  slideHeight: number;
  isAdjustedIndex?: boolean;
  photoUrlTemplate: string;
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  videoAnalyticsParams?: IAnalyticParams;
  onSlideChange?: (index: number, indexWasAdjusted?: boolean) => void;
  onVideoPlayingChange?: (videoPlaying: boolean) => void;
  isSingleView?: boolean;
}

interface IImageSliderState {
  loaded: number[];
}

class ImageSlider extends PureComponent<IImageSliderProps, IImageSliderState> {
  private readonly currentLightboxImageRef = React.createRef<ILightboxImage>();

  constructor(props: IImageSliderProps) {
    super(props);

    this.state = {
      loaded: calculateIdxToLoad(props.images.length, props.currentSlide, 2)
    };
  }

  public componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  public componentWillUnmount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  private onKeyDown = (event: KeyboardEvent) => {
    const { carouselStore, images, isSingleView } = this.props;
    const carouselStoreState: any = { ...carouselStore } as any;
    const currentSlide = carouselStoreState.getStoreState().currentSlide;

    if (isSingleView === true) return;

    if (event.keyCode === 37 && currentSlide > 0) {
      carouselStoreState.setStoreState({ currentSlide: currentSlide - 1 });
    }

    if (event.keyCode === 39 && currentSlide < images.length - 1) {
      carouselStoreState.setStoreState({ currentSlide: currentSlide + 1 });
    }
  };

  private onVideoStart = () => {
    this.props.onVideoPlayingChange?.(true);
  };

  public componentDidUpdate(prevProps: IImageSliderProps) {
    const { onSlideChange, onVideoPlayingChange, carouselStore, images } = this.props;
    const { loaded } = this.state;

    const carouselStoreState: any = { ...carouselStore } as any;
    const currentSlide = carouselStoreState.getStoreState().currentSlide;

    if (!prevProps.isAdjustedIndex && this.props.isAdjustedIndex) {
      carouselStoreState.setStoreState({ currentSlide: this.props.currentSlide });
      return;
    }

    if (onSlideChange) {
      onSlideChange(currentSlide || 0, this.props.isAdjustedIndex);
    }

    if (prevProps.currentSlide !== currentSlide) {
      this.currentLightboxImageRef.current?.stopVideo();
      onVideoPlayingChange?.(false);

      const updatedList = [...loaded];
      const currentIndex = currentSlide!;
      const idxToLoad = calculateIdxToLoad(images.length, currentIndex);

      for (let i = idxToLoad[0]; i <= idxToLoad[idxToLoad.length - 1]; i++) {
        if (!loaded.some(x => x === i)) {
          updatedList.push(i);
        }
      }
      this.setState({ loaded: updatedList });
    }
  }

  public render() {
    const {
      images,
      styles,
      headerHeight,
      photoUrlTemplate,
      thumbnailUrlTemplate,
      videoUrlTemplate,
      currentSlide,
      videoAnalyticsParams
    } = this.props;
    const { loaded } = this.state;

    return (
      <Slider className={styles.carouselSlider}>
        {images.map((img: PhotoOrVideo, idx: number) => (
          <Slide key={`${idx}_${img}`} index={idx} innerClassName={styles.carouselSlideInner}>
            <LightboxImage
              ref={idx === currentSlide ? this.currentLightboxImageRef : undefined}
              className={styles.imgContainer}
              isVisible={loaded.some(x => x === idx)}
              image={img}
              style={{
                width: "100%",
                height: `calc(${this.props.slideHeight ? this.props.slideHeight : window.innerHeight}px - 124px - ${
                  headerHeight ? headerHeight : 0
                }px)`
              }}
              photoUrlTemplate={photoUrlTemplate}
              thumbnailUrlTemplate={thumbnailUrlTemplate}
              videoUrlTemplate={videoUrlTemplate}
              videoAnalyticsParams={videoAnalyticsParams}
              onVideoStart={this.onVideoStart}
            />
          </Slide>
        ))}
      </Slider>
    );
  }
}

export default WithStore<IImageSliderProps, any>(ImageSlider, (state: any) => ({
  currentSlide: state.currentSlide
}));
