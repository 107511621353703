import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./paperPrint.module.scss";
import { Frame } from "../Frame";
import { CANVAS_WRAP_TYPE, IFrame, IMat, MAT_COLOR, PIXEL_PER_INCH } from "../../designer/model";
import { Mat } from "../Mat";
import { withAutoDetectOrientation, IAutoDetectOrientationProps } from "../../designer/withAutoDetectOrientation";
import { withValidation, IValidationProps } from "../../designer/withValidation";
import CanvasWrapPlaceholder from "../CanvasPrint/CanvasWrapPlaceholder";
import { ICroppingDataPaperPrint } from "../BeautyShot";
import CanvasNaturalWrap from "../CanvasPrint/CanvasNaturalWrap";
import CanvasSolidBorderWrap from "../CanvasPrint/CanvasSolidBorderWrap";
import CanvasNaturalWrapPreview from "../CanvasPrint/CanvasNaturalWrapPreview";
import PaperPrintPhoto from "./PaperPrintPhoto";
import { Utilities } from "utilities/utilities";
import { Unit, formatCanvasWithUnit } from "utilities/unit";

export interface IPaperPrintViewProps extends IAutoDetectOrientationProps, IValidationProps {
  /** Original product size width (inch) */
  productWidth: number;
  /** Original product size height (inch) */
  productHeight: number;
  /** Photo URL (uncropped) (optional) */
  photoSrc?: string;
  /** Frame (optional) */
  frame?: IFrame;
  /** Mat (optional) */
  mat?: IMat;
  /** Photo object fit mode (default is contain mode) */
  isPhotoCover?: boolean;
  /** PaperType (optional) */
  isBlackAndWhite?: boolean;
  /** Photo Cropping Data
   * If do not input data, it will use the photoSrc only.
   * If have data input, it will render photo by cropping + photoSrc .
   */
  croppingData?: ICroppingDataPaperPrint;
  unit?: Unit;
  isCropLineThumbnail?: boolean;
  isMpixMetalPrint?: boolean;
  canvasPrintDetails?: ICanvasPrintProps;
  updateProductAfterChangeOption?: () => void;
  isInvalidResolution?: boolean;
  isInvalidRatio?: boolean;
  classname?: string;
}

export interface ICanvasPrintProps {
  canvasWrapType: CANVAS_WRAP_TYPE | undefined;
  canvasWrapBorder: number; // per inch
  isShoppingCartItem?: boolean;
  isPreviewMode?: boolean;
}

enum ICanvasWrapTypeColor {
  PHOTO_WRAP = "rgba(255, 255, 255, 0.6)",
  BLACK_WRAP = "rgba(0, 0, 0, 1)",
  WHITE_WRAP = "rgba(255, 255, 255, 1)",
  PHOTO_WRAP_IN_SHOPPING_CART = "rgba(5, 122, 255, 0.6)"
}

interface IPaperPrintViewState {
  isHorizontal: boolean;
  canvasWrap: CANVAS_WRAP_TYPE;
  canvasWrapBorder: number;
  printedHeightInPixel: number;
  printedWidthInPixel: number;
  printedHeightInPixelForCanvasSolidWrap: number;
  printedWidthInPixelForCanvasSolidWrap: number;
}

interface IPaperPrintViewState {}

const DEFAULT_FRAME_WIDTH = 0.25 * 96;
const DEFAULT_MAT_WIDTH = 2 * 96;
const DEFAULT_PRODUCT_WIDTH = 8 * PIXEL_PER_INCH;
const DEFAULT_PRODUCT_HEIGHT = 10 * PIXEL_PER_INCH;
const DEFAULT_PRODUCT_FONT_SIZE = 16;
const DEFAULT_CANVAS_WRAP_BORDER = 2.125;
const ORIGINAL_PHOTO_WIDTH = 1;
const ORIGINAL_PHOTO_HEIGHT = 1;
const DEFAULT_ALIGN_CENTER_POSITION = 2;
const RADIO_TO_SCALE = 1.5;
const SCALE_NUMBER = {
  DEFAULT: 0.4,
  PRODUCT_VERTICAL: 2.4,
  PHOTO_VERTICAL: 1
};

class PaperPrint extends React.PureComponent<IPaperPrintViewProps, IPaperPrintViewState> {
  constructor(props: IPaperPrintViewProps) {
    super(props);

    this.state = {
      isHorizontal: Utilities.isHorizontal(this.props.originalPhotoWidth || 0, this.props.originalPhotoHeight || 0),
      canvasWrap: CANVAS_WRAP_TYPE.PHOTO_WRAP,
      canvasWrapBorder: this.props.canvasPrintDetails?.canvasWrapBorder || DEFAULT_CANVAS_WRAP_BORDER,
      printedHeightInPixel: this.props.productHeight * PIXEL_PER_INCH,
      printedWidthInPixel: this.props.productWidth * PIXEL_PER_INCH,
      printedHeightInPixelForCanvasSolidWrap:
        (this.props.productHeight + (this.props.canvasPrintDetails?.canvasWrapBorder || 0) * 2) * PIXEL_PER_INCH,
      printedWidthInPixelForCanvasSolidWrap:
        (this.props.productWidth + (this.props.canvasPrintDetails?.canvasWrapBorder || 0) * 2) * PIXEL_PER_INCH
    };
  }

  private containerRef = React.createRef<SVGSVGElement>();
  private placeHolderRef = React.createRef<SVGSVGElement>();
  private photoRef = React.createRef<SVGImageElement>();
  private frameRef = React.createRef<SVGSVGElement>();
  private clipPathMpixMetalId = `trim-` + Math.random();

  public componentDidMount() {
    this.attachPhotoDragFunc();
  }

  public componentDidUpdate(prevProps: IPaperPrintViewProps, prevState: IPaperPrintViewState) {
    this.attachPhotoDragFunc();
    if (
      prevProps.originalPhotoWidth !== this.props.originalPhotoWidth ||
      prevProps.originalPhotoHeight !== this.props.originalPhotoHeight
    ) {
      this.setState({
        isHorizontal: Utilities.isHorizontal(this.props.originalPhotoWidth || 0, this.props.originalPhotoHeight || 0),
        canvasWrapBorder: this.props.canvasPrintDetails?.canvasWrapBorder || 0,
        printedHeightInPixel: this.props.productHeight * PIXEL_PER_INCH,
        printedWidthInPixel: this.props.productWidth * PIXEL_PER_INCH,
        printedHeightInPixelForCanvasSolidWrap:
          (this.props.productHeight + (this.props.canvasPrintDetails?.canvasWrapBorder || 0) * 2) * PIXEL_PER_INCH,
        printedWidthInPixelForCanvasSolidWrap:
          (this.props.productWidth + (this.props.canvasPrintDetails?.canvasWrapBorder || 0) * 2) * PIXEL_PER_INCH
      });
    }
    if (prevProps.productHeight !== this.props.productHeight || prevProps.productWidth !== this.props.productWidth) {
      this.setState({
        isHorizontal: Utilities.isHorizontal(this.props.originalPhotoWidth || 0, this.props.originalPhotoHeight || 0),
        canvasWrapBorder: this.props.canvasPrintDetails?.canvasWrapBorder || 0,
        printedHeightInPixel: this.props.productHeight * PIXEL_PER_INCH,
        printedWidthInPixel: this.props.productWidth * PIXEL_PER_INCH,
        printedHeightInPixelForCanvasSolidWrap:
          (this.props.productHeight + (this.props.canvasPrintDetails?.canvasWrapBorder || 0) * 2) * PIXEL_PER_INCH,
        printedWidthInPixelForCanvasSolidWrap:
          (this.props.productWidth + (this.props.canvasPrintDetails?.canvasWrapBorder || 0) * 2) * PIXEL_PER_INCH
      });
    }
  }

  private get wrapTextFontSize() {
    return DEFAULT_PRODUCT_FONT_SIZE * (this.printHeightInPixel / DEFAULT_PRODUCT_WIDTH);
  }

  private get wrapTextPositionX() {
    return this.printHeightInPixel / DEFAULT_ALIGN_CENTER_POSITION;
  }

  private get printHeightInPixel() {
    return this.props.canvasPrintDetails?.canvasWrapType !== CANVAS_WRAP_TYPE.PHOTO_WRAP
      ? this.state.printedHeightInPixelForCanvasSolidWrap
      : this.state.printedHeightInPixel;
  }
  private get printWidthInPixel() {
    return this.props.canvasPrintDetails?.canvasWrapType !== CANVAS_WRAP_TYPE.PHOTO_WRAP
      ? this.state.printedWidthInPixelForCanvasSolidWrap
      : this.state.printedWidthInPixel;
  }

  private get canvasWrapBorderInPixel() {
    return this.state.canvasWrapBorder * PIXEL_PER_INCH;
  }

  private get canvasWrapBorderInPixelForBothSide() {
    return this.canvasWrapBorderInPixel * 2;
  }

  private get productActualHeightInPixel() {
    const { frame, mat } = this.props;
    return this.printHeightInPixel + (frame?.frameWidth || 0) * 2 + (mat?.matWidth || 0) * 2;
  }

  private get productActualWidthInPixel() {
    const { frame, mat } = this.props;
    return this.printWidthInPixel + (frame?.frameWidth || 0) * 2 + (mat?.matWidth || 0) * 2;
  }

  private get matWidthInPixel() {
    const { frame, mat } = this.props;

    return (mat?.matWidth || 0) + (frame?.frameWidth || 0);
  }

  private get matHeightInPixel() {
    const { frame, mat } = this.props;
    if (!mat?.matHeight) return 0;
    return mat.matHeight + (frame?.frameWidth || 0);
  }

  // PORT-43724 We need to scale thumbnail for 5x30 5x15
  private get scaleNumber() {
    const { isHorizontal } = this.state;
    const { isCropLineThumbnail, frame, productWidth, productHeight } = this.props;
    if (!isCropLineThumbnail || frame) return 0;
    let ratio = productWidth / productHeight;
    let alpha = SCALE_NUMBER.DEFAULT;
    if (productWidth < productHeight) {
      ratio = productHeight / productWidth;
      alpha = SCALE_NUMBER.PRODUCT_VERTICAL;
    }
    if (!isHorizontal) {
      alpha = SCALE_NUMBER.PHOTO_VERTICAL;
    }
    return ratio > RADIO_TO_SCALE ? ratio / 2 + alpha : 0;
  }

  private get style(): CSSProperties {
    const scale = this.scaleNumber;
    return scale ? { WebkitTransform: `scale(${scale})`, transform: `scale(${scale})` } : {};
  }

  private attachPhotoDragFunc = () => {
    const { frame } = this.props;

    // if (frame) {
    //   const zoom = SvgPanZoom(this.frameRef.current as SVGSVGElement, { viewportSelector: this.photoRef.current as SVGImageElement, controlIconsEnabled: false });
    //   zoom.zoom(1);
    // } else {
    //   const zoom = SvgPanZoom(this.placeHolderRef.current as SVGSVGElement, { viewportSelector: this.photoRef.current as SVGImageElement, controlIconsEnabled: false });
    //   zoom.zoom(1);
    // }
  };

  get hasCroppingInfo(): boolean {
    const { croppingData } = this.props;
    return !!croppingData && (croppingData.cropping?.width || 0) > 0 && (croppingData.cropping?.height || 0) > 0;
  }

  get getThumbnailSize(): { width: number; height: number; x: string; y: string } {
    const { originalPhotoHeight, originalPhotoWidth, croppingData } = this.props;

    let imageWidth = 0;
    let imageHeight = 0;
    let x = "";
    let y = "";

    if (!originalPhotoWidth || !originalPhotoHeight || !croppingData)
      return { x, y, width: imageWidth, height: imageHeight };

    const { cropping } = croppingData;

    if (originalPhotoWidth / originalPhotoHeight >= this.printWidthInPixel / this.printHeightInPixel) {
      imageWidth = this.printWidthInPixel;
      imageHeight = originalPhotoHeight / (originalPhotoWidth / imageWidth);
      x = `${(cropping?.left || 0) * 100}%`;
      y = `${(cropping?.top || 0) * imageHeight + (this.printHeightInPixel - imageHeight) / 2}`;
    } else {
      imageHeight = this.printHeightInPixel;
      imageWidth = originalPhotoWidth / (originalPhotoHeight / imageHeight);
      y = `${(cropping?.top || 0) * 100}%`;
      x = `${(cropping?.left || 0) * imageWidth + (this.printWidthInPixel - imageWidth) / 2}`;
    }

    const width = imageWidth * (croppingData.cropping?.width || 0);
    const height = imageHeight * (croppingData.cropping?.height || 0);

    return { width, height, x, y };
  }

  private renderImageWithCropLine = () => {
    const thumbnailSize = this.getThumbnailSize;
    const thumbnailCropWidth = thumbnailSize.width;
    const thumbnailCropHeight = thumbnailSize.height;
    const thumbnailCropX = thumbnailSize.x;
    const thumbnailCropY = thumbnailSize.y;
    const strokeWidth = 4 - this.scaleNumber;

    return (
      <>
        {this.renderImageWithoutCropping("brightness(0.7)")}
        <svg
          viewBox={`0 0 ${this.printWidthInPixel} ${this.printHeightInPixel}`}
          width={thumbnailCropWidth}
          height={thumbnailCropHeight}
          x={thumbnailCropX}
          y={thumbnailCropY}
        >
          {this.renderImageWithCropping()}
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            stroke="#057aff"
            vectorEffect="non-scaling-stroke"
            strokeWidth={`${strokeWidth > 1 ? strokeWidth : 1}px`}
            fill="none"
          ></rect>
        </svg>
      </>
    );
  };

  private renderImageWithoutCropping = (filter: string = "") => {
    const { isBlackAndWhite, isPhotoCover } = this.props;

    return (
      <image
        ref={this.photoRef}
        // to display as true size, don't define width height of image
        width="100%"
        height="100%"
        preserveAspectRatio={isPhotoCover ? "xMinYMin slice" : ""}
        xlinkHref={this.props.photoSrc}
        filter={isBlackAndWhite ? `url(#grayScaleFilter)` : filter}
      />
    );
  };

  private renderImageWithCropping = () => {
    const { isBlackAndWhite, isPhotoCover, croppingData } = this.props;
    const height = croppingData?.cropping?.height || 0;
    const left = croppingData?.cropping?.left || 0;
    const top = croppingData?.cropping?.top || 0;
    const width = croppingData?.cropping?.width || 0;
    const rotateDegree = croppingData?.rotate || 0;

    // Idea:
    // 1. Zoom the cropped area big enough to fill the placeholder, by zooming the image.
    //    set the width and height of image to zoom
    //    width=croppedWidth/imageWidth -> in order to zoom the cropped area width, we need to zoom 1/width percent
    //    height=croppedWidth/imageWidth -> in order to zoom the cropped area height, we need to zoom 1/height percent
    // 2. After zoom the image, move the image by x,y, to make the cropped area displayed exactly in the placeholder
    //    the x distance to move = left [the left of cropping info] * 100[percent] * (1/width)[the zoom percent in step one]
    //    the y distance to move = top [the top of cropping info] * 100[percent] * (1/height)[the zoom percent in step one]

    const imageWidth = (1 / width) * 100;
    const imageHeight = (1 / height) * 100;
    const x = left * 100 * (1 / width);
    const y = top * 100 * (1 / height);

    const transformOriginX = (width * 100) / 2 + x;
    const transformOriginY = (height * 100) / 2 + y;

    return (
      <image
        ref={this.photoRef}
        width={`${imageWidth}%`}
        height={`${imageHeight}%`}
        x={`${-x}%`}
        y={`${-y}%`}
        preserveAspectRatio={isPhotoCover ? "xMinYMin slice" : ""}
        xlinkHref={this.props.photoSrc}
        filter={isBlackAndWhite ? `url(#grayScaleFilter)` : ""}
        transform-origin={`${transformOriginX}% ${transformOriginY}%`}
        transform={`rotate(${rotateDegree})`}
      />
    );
  };

  private renderCanvasStrokeBorder = (canvasWrapType: CANVAS_WRAP_TYPE) => {
    switch (canvasWrapType) {
      case CANVAS_WRAP_TYPE.PHOTO_WRAP:
        return this.props?.canvasPrintDetails?.isShoppingCartItem
          ? ICanvasWrapTypeColor.PHOTO_WRAP_IN_SHOPPING_CART
          : ICanvasWrapTypeColor.PHOTO_WRAP;
      case CANVAS_WRAP_TYPE.BLACK_WRAP:
        return ICanvasWrapTypeColor.BLACK_WRAP;
      case CANVAS_WRAP_TYPE.WHITE_WRAP:
        return ICanvasWrapTypeColor.WHITE_WRAP;
      default:
        return ICanvasWrapTypeColor.PHOTO_WRAP;
    }
  };

  private renderLeftCanvasPlaceHolder = () => {
    return (
      <CanvasWrapPlaceholder
        className={cx(styles.wrapText, styles.wrapTextLeft)}
        offsetX={-this.wrapTextPositionX}
        offsetY={this.canvasWrapBorderInPixel / DEFAULT_ALIGN_CENTER_POSITION}
        style={{
          fontSize: `${this.wrapTextFontSize}px`
        }}
        title={`WRAP AREA ${formatCanvasWithUnit(this.props.canvasPrintDetails?.canvasWrapBorder, this.props.unit)}`}
      />
    );
  };

  private renderRightCanvasPlaceHolder = () => {
    const fontSize = DEFAULT_PRODUCT_FONT_SIZE * (this.printHeightInPixel / DEFAULT_PRODUCT_WIDTH);
    return (
      <CanvasWrapPlaceholder
        className={cx(styles.wrapText, styles.wrapTextRight)}
        offsetX={this.wrapTextPositionX}
        offsetY={this.canvasWrapBorderInPixel / DEFAULT_ALIGN_CENTER_POSITION - this.printWidthInPixel}
        style={{
          fontSize: `${this.wrapTextFontSize}px`
        }}
        title={`WRAP AREA ${formatCanvasWithUnit(this.props.canvasPrintDetails?.canvasWrapBorder, this.props.unit)}`}
      />
    );
  };

  private renderPhoto = () => {
    const { isCropLineThumbnail, frame, isInvalidRatio, isInvalidResolution, isMpixMetalPrint } = this.props;
    return (
      <PaperPrintPhoto
        isInvalidRatio={isInvalidRatio}
        isInvalidResolution={isInvalidResolution}
        matWidthInPixel={this.matWidthInPixel}
        printWidthInPixel={this.printWidthInPixel}
        printHeightInPixel={this.printHeightInPixel}
        renderImage={
          this.hasCroppingInfo
            ? isCropLineThumbnail && !frame
              ? this.renderImageWithCropLine()
              : this.renderImageWithCropping()
            : this.renderImageWithoutCropping()
        }
        mpixMetalPrintClipPath={isMpixMetalPrint ? this.renderClipPath() : <></>}
        clipPathMpixMetalId={this.clipPathMpixMetalId}
      />
    );
  };

  private renderCanvas = () => {
    const { canvasPrintDetails } = this.props;
    const canvasWrapType = canvasPrintDetails?.canvasWrapType || CANVAS_WRAP_TYPE.PHOTO_WRAP;
    if (canvasWrapType !== CANVAS_WRAP_TYPE.PHOTO_WRAP) {
      return this.renderCanvasSolidBorderWrap();
    } else if (canvasWrapType === CANVAS_WRAP_TYPE.PHOTO_WRAP) {
      if (!canvasPrintDetails?.isPreviewMode) {
        return this.renderCanvasNaturalWrap();
      } else {
        return this.renderCanvasNaturalWrapPreview();
      }
    } else {
      return null;
    }
  };

  private renderCanvasPlaceHolder = () => {
    return (
      <>
        {this.renderLeftCanvasPlaceHolder()}
        {this.renderRightCanvasPlaceHolder()}
      </>
    );
  };

  private renderCanvasSolidBorderWrap = () => {
    const { canvasPrintDetails, isInvalidRatio, isInvalidResolution } = this.props;
    return (
      <CanvasSolidBorderWrap
        canvasPrintDetails={canvasPrintDetails}
        isInvalidRatio={isInvalidRatio}
        isInvalidResolution={isInvalidResolution}
        printWidthInPixel={this.printWidthInPixel}
        printHeightInPixel={this.printHeightInPixel}
        canvasWrapBorderInPixel={this.canvasWrapBorderInPixel}
        stroke={this.renderCanvasStrokeBorder(canvasPrintDetails?.canvasWrapType || CANVAS_WRAP_TYPE.BLACK_WRAP)}
        canvasWrapBorderInPixelForBothSide={this.canvasWrapBorderInPixelForBothSide}
        renderImage={this.renderImageWithCropping()}
        renderCanvasPlaceHolder={
          canvasPrintDetails && !canvasPrintDetails.isShoppingCartItem ? this.renderCanvasPlaceHolder() : <></>
        }
      />
    );
  };

  private renderCanvasNaturalWrap = () => {
    const { canvasPrintDetails, isInvalidRatio, isInvalidResolution } = this.props;
    return (
      <CanvasNaturalWrap
        canvasPrintDetails={canvasPrintDetails}
        isInvalidRatio={isInvalidRatio}
        isInvalidResolution={isInvalidResolution}
        printWidthInPixel={this.printWidthInPixel}
        printHeightInPixel={this.printHeightInPixel}
        canvasWrapBorderInPixel={this.canvasWrapBorderInPixel}
        stroke={this.renderCanvasStrokeBorder(canvasPrintDetails?.canvasWrapType || CANVAS_WRAP_TYPE.PHOTO_WRAP)}
        canvasWrapBorderInPixelForBothSide={this.canvasWrapBorderInPixelForBothSide}
        renderImage={this.renderImageWithCropping()}
        renderCanvasPlaceHolder={
          canvasPrintDetails && !canvasPrintDetails.isShoppingCartItem ? this.renderCanvasPlaceHolder() : <></>
        }
      />
    );
  };

  private renderCanvasNaturalWrapPreview = () => {
    return (
      <CanvasNaturalWrapPreview
        printWidthInPixel={this.printWidthInPixel}
        printHeightInPixel={this.printHeightInPixel}
        canvasWrapBorderInPixel={this.canvasWrapBorderInPixel}
        canvasWrapBorderInPixelForBothSide={this.canvasWrapBorderInPixelForBothSide}
        renderImage={this.renderImageWithCropping()}
      />
    );
  };

  private renderClipPath = () => {
    const rx = this.printHeightInPixel / 56.2;
    return (
      <clipPath id={this.clipPathMpixMetalId}>
        <rect width={this.printWidthInPixel} height={this.printHeightInPixel} rx={rx} />
      </clipPath>
    );
  };

  public render() {
    const { frame, mat, canvasPrintDetails, isMpixMetalPrint, classname } = this.props;
    const hasShadow = !!isMpixMetalPrint;
    return (
      <svg
        ref={this.containerRef}
        className={cx(styles.printContainer, classname, { [styles.shadow]: hasShadow })}
        viewBox={`0 0 ${this.productActualWidthInPixel} ${this.productActualHeightInPixel}`}
        style={this.style}
        width="100%"
        height="100%"
      >
        <Frame
          frameKey={frame?.frameKey || ""}
          productHeight={this.productActualHeightInPixel}
          productWidth={this.productActualWidthInPixel}
          edgesSrc={frame?.edgesSrc}
          frameWidth={frame?.frameWidth || 0}
          isDisable={!frame}
          forwardedRef={this.frameRef}
        >
          <Mat
            matColor={mat?.color || MAT_COLOR.None}
            isDisable={!mat || mat?.color === MAT_COLOR.None}
            matWidth={this.matWidthInPixel}
            matHeight={this.matHeightInPixel}
            productHeight={this.productActualHeightInPixel}
            productWidth={this.productActualWidthInPixel}
          >
            {canvasPrintDetails ? this.renderCanvas() : this.renderPhoto()}
          </Mat>
        </Frame>
      </svg>
    );
  }
}

export default withAutoDetectOrientation(withValidation(PaperPrint));
