import { Autosuggest, FormTextInput, Validation } from "@zenfolio/core-components";
import { AddressInputValue } from "@zenfolio/core-components/dist/components/AddressInput/model";
import classNames from "classnames";
import _ from "lodash";
import * as React from "react";
import { IAddressSelectionOwnProps } from "../../containers/addressSelection";
import { MaxSearchAddressRadius } from "../../utilities/constants";
import { hasActionCompleted, isEnterKey } from "../../utilities/helpers";
import { getFullAddress, isAddress, isAddressPrediction, isCompleteAddress } from "../addressInput";
import styles from "./index.module.scss";
import Map, { ICoordinates } from "./map";
import { IAddressInfo, IGeoCodePlaceDetails, ILoadPlaceDetails, ISearchAddress } from "./model";

export interface IAddressSelectionProps extends IAddressSelectionOwnProps {
  searchAddress: ISearchAddress;
  loadPlaceDetails: ILoadPlaceDetails;
  geoCodePlaceDetails: IGeoCodePlaceDetails;
}

interface IAddressSelectionState {
  address: AddressInputValue | undefined;
  addressDetails: string | null | undefined;
  locationIsFocused: boolean | null;
  locationWasFocused: boolean;
  addressChanged: boolean;
}

class AddressSelection extends React.Component<IAddressSelectionProps, IAddressSelectionState> {
  constructor(props: IAddressSelectionProps) {
    super(props);

    this.state = {
      address: props.defaultAddress || undefined,
      addressDetails: props.defaultDetails || undefined,
      locationIsFocused: null,
      locationWasFocused: false,
      addressChanged: false
    };
  }

  public componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress, true);

    const { searchAddress, geoCodePlaceDetails } = this.props;
    searchAddress.onReset();
    geoCodePlaceDetails.loadPlaceDetailsFromLocationOrIpReset();
  }

  public componentDidUpdate(prevProps: IAddressSelectionProps) {
    const { loadPlaceDetails, geoCodePlaceDetails } = this.props;

    this.loadPlaceDetailsForPlaceId(loadPlaceDetails, prevProps);
    this.loadPlaceDetailsForLocationOrIp(geoCodePlaceDetails, prevProps);
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, true);
    this.props.searchAddress.onReset();
  }

  public render() {
    const {
      className,
      formClassName,
      fieldClassName,
      locationClassName,
      locationErrorClassName,
      errorClassName,
      descriptionLabelClassName,
      searchAddress,
      title,
      icon,
      addressPlaceholder,
      descriptionPlaceholder,
      forceShowingError,
      showMap,
      descriptionLabel,
      coordinate,
      radius,
      validationInProgress,
      showDescriptionCharCount,
      descriptionMaxLength
    } = this.props;
    const addressInfo = this.addressInfo;
    const errorMessage = (forceShowingError || this.state.locationIsFocused != null) && addressInfo.errorMessage;
    const mapShowMarker = addressInfo.coordinates && !validationInProgress;
    const mapShowCircle =
      !addressInfo.coordinates || (addressInfo.isComplete && addressInfo.errorMessage) || validationInProgress;

    return (
      <div className={classNames(styles.container, className)}>
        <div className={classNames(styles.form, formClassName)}>
          <div
            className={classNames(
              styles.field,
              styles.location,
              errorMessage ? styles.locationError : null,
              errorMessage ? locationErrorClassName : null,
              fieldClassName,
              locationClassName
            )}
          >
            <label>{title}</label>
            <Autosuggest
              value={addressInfo.value}
              suggestions={searchAddress.predictions || []}
              onChange={this.onAddressChange}
              onGetSuggestions={this.onGetSuggestions}
              onSuggestionsClearRequested={this.onAddressSuggestionsClearRequested}
              onSuggestionSelected={this.onAddressSuggestionSelected}
              onFocus={this.onAddressFocus}
              onClickIcon={this.onSuggestAddress}
              onBlur={this.onAddressBlur}
              placeholder={addressPlaceholder}
              icon={icon}
            />
            {errorMessage ? <div className={classNames(styles.error, errorClassName)}>{errorMessage}</div> : null}
          </div>

          {showMap && (
            <div className={styles.map}>
              {mapShowCircle && <div className={styles.header}>Service Radius</div>}
              <Map
                marker={mapShowMarker ? addressInfo.coordinates! : undefined}
                circle={mapShowCircle ? { center: coordinate, radius } : undefined}
                zoom={mapShowCircle ? undefined : 15}
              />
            </div>
          )}

          <div className={classNames(styles.field, fieldClassName)}>
            {descriptionLabel && (
              <div className={classNames(styles.descriptionLabel, descriptionLabelClassName)}>{descriptionLabel}</div>
            )}
            <FormTextInput
              value={this.addressDetails || ""}
              placeholder={descriptionPlaceholder}
              onChange={this.onAddressDetailsChange}
              styles={{ height: 50, paddingLeft: 16 }}
              maxLength={descriptionMaxLength}
            />
            {showDescriptionCharCount && (
              <div className={styles.charCount}>
                {(this.addressDetails || "").length}/{descriptionMaxLength}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private get address() {
    return this.state.address !== undefined ? this.state.address : this.props.shootLocation;
  }

  private get addressDetails() {
    return this.state.addressDetails !== undefined
      ? this.state.addressDetails
      : (this.props.shootLocation || { locationDetails: null }).locationDetails;
  }

  private get addressInfo(): IAddressInfo {
    const address = this.address;
    const { locationIsFocused, locationWasFocused, addressChanged } = this.state;
    const searchAddressError = this.props.searchAddress.error;

    if (isAddressPrediction(address)) {
      return {
        value: address.description || "",
        isComplete: false,
        coordinates: null,
        errorMessage:
          Validation.errorIfEmpty(address.description, this.props.locationEmptyError) ||
          (searchAddressError ? AddressSelection.locationNotFoundError : "")
      };
    }

    if (isAddress(address)) {
      const isComplete = isCompleteAddress(address);
      return {
        value: getFullAddress(address),
        isComplete,
        coordinates: { lat: address.latitude, lng: address.longitude },
        errorMessage: isComplete
          ? this.props.onValidate
            ? this.props.onValidate(address)
            : ""
          : AddressSelection.locationIncompleteError
      };
    }

    return {
      value: address || "",
      isComplete: false,
      coordinates: null,
      errorMessage:
        (addressChanged || locationWasFocused ? Validation.errorIfEmpty(address, this.props.locationEmptyError) : "") ||
        (searchAddressError || locationIsFocused === false ? AddressSelection.locationNotFoundError : "")
    };
  }

  private onSuggestAddress = () => {
    const { loadPlaceDetailsFromIp, loadPlaceDetailsFromLocation } = this.props.geoCodePlaceDetails;
    if ("geolocation" in navigator) {
      const options: PositionOptions = { enableHighAccuracy: true };
      navigator.geolocation.getCurrentPosition(
        position => {
          const location: ICoordinates = { lat: position.coords.latitude, lng: position.coords.longitude };
          loadPlaceDetailsFromLocation(location);
        },
        () => {
          loadPlaceDetailsFromIp();
        },
        options
      );
    } else {
      loadPlaceDetailsFromIp();
    }
  };

  private onAddressChange = (event: React.FormEvent<any>, { newValue }: any) => {
    this.updateAddress(newValue);
  };

  private onAddressDetailsChange = (value: string) => {
    this.setState({ addressDetails: value }, () => {
      this.props.onAddressDetailsChanged?.(this.addressDetails);
    });
  };

  private updateAddress = (address: AddressInputValue) => {
    const { onAddressChanged } = this.props;

    this.setState({ address, addressChanged: true }, () => {
      if (onAddressChanged) {
        onAddressChanged(address);
      }
    });
  };

  private onAddressSuggestionsClearRequested = () => {
    this.props.searchAddress.onClearPredictions();
  };

  private onAddressSuggestionSelected = (event: any, { suggestionIndex }: any) => {
    this.selectSuggestion(suggestionIndex);
  };

  private selectSuggestion = (suggestionIndex: number) => {
    const { searchAddress } = this.props;
    if (!_.isEmpty(searchAddress.predictions)) {
      const address = searchAddress.predictions![suggestionIndex];
      this.updateAddress(address);
      this.props.loadPlaceDetails.onLoad(address.placeId);
    }
  };

  private onAddressFocus = () => {
    this.setState({ locationIsFocused: true });
  };

  private onAddressBlur = () => {
    this.setState({ locationIsFocused: false, locationWasFocused: true });
  };

  private handleKeyPress = (evt: KeyboardEvent) => {
    if (!evt || !evt.key) {
      return;
    }

    if (this.state.locationIsFocused && isEnterKey(evt)) {
      this.selectSuggestion(0);
    }
  };

  private onGetSuggestions = (query: string) => {
    const { coordinate, radius } = this.props;

    if (query) {
      this.props.searchAddress.onSearch({
        query,
        lat: coordinate.lat,
        lng: coordinate.lng,
        radius: Math.min(radius, MaxSearchAddressRadius),
        country: "US",
        address: true
      });
    } else {
      this.props.searchAddress.onReset();
    }
  };

  private loadPlaceDetailsForLocationOrIp(
    geoCodePlaceDetails: IGeoCodePlaceDetails,
    prevProps: IAddressSelectionProps
  ) {
    const loadPlaceDetailsFromIpHasCompleted = hasActionCompleted(
      prevProps.geoCodePlaceDetails.loadFromIpStatus,
      geoCodePlaceDetails.loadFromIpStatus
    );

    const loadPlaceDetailsFromLocationHasCompleted = hasActionCompleted(
      prevProps.geoCodePlaceDetails.loadFromLocationStatus,
      geoCodePlaceDetails.loadFromLocationStatus
    );

    if (loadPlaceDetailsFromLocationHasCompleted || loadPlaceDetailsFromIpHasCompleted) {
      this.updateAddress(geoCodePlaceDetails.placeDetails);
    }
  }

  private loadPlaceDetailsForPlaceId(loadPlaceDetails: ILoadPlaceDetails, prevProps: IAddressSelectionProps) {
    const loadPlaceDetailsHasCompleted = hasActionCompleted(prevProps.loadPlaceDetails.status, loadPlaceDetails.status);

    if (loadPlaceDetailsHasCompleted) {
      this.updateAddress(loadPlaceDetails.placeDetails);
    }
  }

  private static readonly locationIncompleteError = "Please verify your building number.";
  private static readonly locationNotFoundError =
    "We’re unable to find this address. Please verify the address and try again.";
}

export default AddressSelection;
