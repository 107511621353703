import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import ConfirmationStep from "../../../../../components/widget/steps/confirmation/booking";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ widget }: IAppState) {
  return {
    photographerTimezoneOffsetMinutes: widget.loadWidgetInfo.photographerTimezoneOffsetMinutes,
    initialTaxAmount: widget.createBookingState.initialTaxAmount,
    totalTaxAmount: widget.createBookingState.totalTaxAmount
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationStep);
