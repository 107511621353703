import React from "react";
import styles from "./bannerSnipe.module.scss";
import cx from "classnames";
import { BannerFontStyle, BannerDiscountType } from "utilities/constant";
import { ICurrency } from "models";

export interface IBannerSnipeProps {
  fontStyle: BannerFontStyle;
  backgroundColor?: string;
  textColor?: string;
  discountAmount?: string;
  discountType: BannerDiscountType;
  currency?: ICurrency;
}

export class BannerSnipe extends React.PureComponent<IBannerSnipeProps> {
  public static defaultProps: IBannerSnipeProps = {
    discountType: BannerDiscountType.PercentOff,
    fontStyle: BannerFontStyle.SansSerif,
    backgroundColor: "#c11616",
    textColor: "#ffffff",
    discountAmount: "20"
  };

  private renderDiscountInfo = () => {
    const { discountAmount, discountType, currency } = this.props;

    switch (discountType) {
      case BannerDiscountType.DollarOff: {
        return (
          <React.Fragment>
            <div className={styles.symbolOnlyDollar}>{currency?.symbol}</div>
            <div className={styles.percentOff}>{discountAmount}</div>
            <div className={styles.symbolOnlyOff}>OFF</div>
          </React.Fragment>
        );
      }
      case BannerDiscountType.PercentOff: {
        return (
          <React.Fragment>
            <div className={styles.percentOff}>{discountAmount}</div>
            <div className={styles.group}>
              <div className={styles.symbol}>%</div>
              <div className={styles.text}>OFF</div>
            </div>
          </React.Fragment>
        );
      }
      case BannerDiscountType.FreeProduct: {
        return <div className={styles.textOnly}>FREE PRODUCT</div>;
      }
      case BannerDiscountType.FreeShipping: {
        return <div className={styles.textOnly}>FREE SHIPPING</div>;
      }
    }
  };

  public render() {
    const { fontStyle, backgroundColor, textColor } = this.props;
    return (
      <div
        className={cx(styles.container, [styles[fontStyle || BannerFontStyle.SansSerif]])}
        style={{ backgroundColor }}
      >
        <div className={styles.border} style={{ borderColor: textColor, color: textColor }}>
          {this.renderDiscountInfo()}
        </div>
      </div>
    );
  }
}

export default BannerSnipe;
