import React from "react";
import { ReactEditor, useSlate } from "slate-react";
import { Editor, Element } from "slate";
import find from "lodash/find";

import Button from "../Button";
import ButtonIcon from "../ButtonIcon";

interface IMarkButtonProps {
  className?: string;
  format: string;
  iconClassName: string;
  isDisabled?: boolean;
  activeClassName?: string;
}

const toggleMark = (editor: ReactEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: ReactEditor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const MarkButton = (props: IMarkButtonProps) => {
  const editor = useSlate();
  return (
    <Button
      className={props.className}
      isActive={isMarkActive(editor, props.format)}
      activeClassName={props.activeClassName}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, props.format);
      }}
    >
      <ButtonIcon className={props.iconClassName} />
    </Button>
  );
};

export default MarkButton;
