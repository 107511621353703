import cx from "classnames";
import { IZenSiteTheme } from "../../../../../models/models";
import last from "lodash/last";
import React, { FC } from "react";
import { getFontColor } from "utilities/blocks/blockColors";
import { formatPhotoSrcSet, formatPhotoUrl, IPhotoFull, MESizeMethod } from "utilities/getImgUrl";
import { getSiteFonts } from "utilities/blocks/site";
import { processStaticImageUrl } from "utilities/blocks/url";
import colors, { getContrastYIQ } from "utilities/colors";
import {
  columnGap,
  compactLayoutWidth,
  IBlogPostInfo,
  maxColumnWidth,
  paddingDesktop,
  paddingMobile
} from "../../model";
import { formatPublishDate } from "../../utils";
import styles from "./blogPost.module.scss";
import { allImageSizes } from "utilities/constant";

interface IBlogPostProps {
  className?: string;
  siteTheme: IZenSiteTheme;
  photoUrlTemplate: string;
  blogPost: IBlogPostInfo;
  photoInfo?: IPhotoFull;
  onSelect?: (blogPost: IBlogPostInfo) => void;
  buildUrl?: (blogPost: IBlogPostInfo) => string;
}

const sizeMethod = MESizeMethod.Contain;
const noContentWidth = 677;
const noContentHeight = 720;

const BlogPost: FC<IBlogPostProps> = props => {
  const { className, siteTheme, photoUrlTemplate, blogPost, photoInfo, onSelect, buildUrl } = props;
  const siteFonts = getSiteFonts(siteTheme.fontsStyle);
  const normalTextColor = getFontColor(siteTheme.backgroundColor.value);
  const titleTextColor = getContrastYIQ(siteTheme.backgroundColor.value, {
    lightColor: colors.white,
    darkColor: colors.black
  });

  function onClick(e: React.MouseEvent) {
    if (!buildUrl) {
      e.preventDefault();
      onSelect?.(blogPost);
    }
  }

  return (
    <a
      data-blog-post-id={blogPost.id}
      href={buildUrl?.(blogPost)}
      className={cx(styles.container, styles[siteFonts.secondary], className)}
      onClick={onClick}
      style={{ color: normalTextColor }}
    >
      <div
        className={styles.imageWrapper}
        style={{
          paddingBottom:
            ((photoInfo ? photoInfo.height : noContentHeight) / (photoInfo ? photoInfo.width : noContentWidth)) * 100 +
            "%"
        }}
      >
        <img
          src={
            photoInfo
              ? formatPhotoUrl(photoUrlTemplate, photoInfo, last(allImageSizes)!.code, sizeMethod)
              : processStaticImageUrl("/storage/images/common/pictureNoContent.png")
          }
          sizes={
            photoInfo
              ? `(max-width: ${compactLayoutWidth}px) calc(100vw - ${2 * paddingMobile}px), (max-width: ${2 *
                  paddingDesktop +
                  2 * maxColumnWidth +
                  columnGap}px) calc((100vw - ${2 * paddingDesktop + columnGap}px) / 2), ${maxColumnWidth}px`
              : undefined
          }
          srcSet={photoInfo ? formatPhotoSrcSet(photoUrlTemplate, photoInfo, sizeMethod) : undefined}
          alt={photoInfo ? photoInfo.altText || photoInfo.title : undefined}
        />
      </div>
      {blogPost.author && <div className={styles.author}>{blogPost.author}</div>}
      <div className={cx(styles.title, styles[siteFonts.primary])} style={{ color: titleTextColor }}>
        {blogPost.title}
      </div>
      {blogPost.publishDate && <div className={styles.date}>{formatPublishDate(blogPost.publishDate)}</div>}
    </a>
  );
};

export default BlogPost;
