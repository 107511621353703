import cx from "classnames";
import { IZenSiteTheme } from "../../../../models/models";
import ZenButton from "../../../blocks/zenButton";
import isEqual from "lodash/isEqual";
import last from "lodash/last";
import React, { FC, MouseEventHandler, useLayoutEffect, useRef, useState } from "react";
import { getBackgroundColor, getFontColor } from "utilities/blocks/blockColors";
import { formatPhotoSrcSet, formatPhotoUrl, IPhotoFull, MESizeMethod } from "utilities/getImgUrl";
import { getSiteFonts } from "utilities/blocks/site";
import { processStaticImageUrl } from "utilities/blocks/url";
import colors, { getContrastYIQ } from "utilities/colors";
import usePrevious from "../../../../hooks/usePrevious";
import useWindowLoad from "../../../../hooks/useWindowLoad";
import useWindowSize from "../../../../hooks/useWindowSize";
import { IBlogPostInfo } from "../model";
import { formatPublishDate } from "../utils";
import styles from "./featuredPost.module.scss";
import { allImageSizes } from "utilities/constant";

interface IFeaturedPostProps {
  photoUrlTemplate: string;
  siteTheme: IZenSiteTheme;
  featuredBlogPost?: IBlogPostInfo;
  photoInfo?: IPhotoFull;
  onFeaturedPostClick?: () => void;
  blogPostUrl: string;
}

const sizeMethod = MESizeMethod.Contain;

const FeaturedPost: FC<IFeaturedPostProps> = props => {
  const { photoUrlTemplate, siteTheme, featuredBlogPost, photoInfo, onFeaturedPostClick, blogPostUrl } = props;
  const author = featuredBlogPost ? featuredBlogPost.author : "Kieran O.";
  const date = featuredBlogPost ? formatPublishDate(featuredBlogPost.publishDate) : "July 18, 2018";
  const siteFonts = getSiteFonts(siteTheme.fontsStyle);
  const normalTextColor = getFontColor(siteTheme.backgroundColor.value);
  const backgroundColor = getBackgroundColor(siteTheme.backgroundColor.value);
  const titleTextColor = getContrastYIQ(backgroundColor, {
    lightColor: colors.white,
    darkColor: colors.black
  });

  const headerRef = useRef<HTMLDivElement>(null);
  const [headerMultiline, setHeaderMultiline] = useState(false);
  const windowSize = useWindowSize();
  const prevWindowSize = usePrevious(windowSize);
  const prevFeaturedBlogPost = usePrevious(featuredBlogPost);
  useWindowLoad(refreshTitle);

  useLayoutEffect(onComponentUpdate);

  function onComponentUpdate() {
    if (prevFeaturedBlogPost !== featuredBlogPost || !isEqual(prevWindowSize, windowSize)) {
      refreshTitle();
    }
  }

  function refreshTitle() {
    if (!headerRef.current) {
      return;
    }

    const style = window.getComputedStyle(headerRef.current, null);
    const height = parseFloat(style.getPropertyValue("height"));
    const lineHeight = parseFloat(style.getPropertyValue("line-height"));

    setHeaderMultiline(lineHeight > 0 && height / lineHeight > 1);
  }

  const onButtonSeoWrapperClick: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div
      className={cx(styles.container, styles[siteFonts.secondary])}
      style={{ color: normalTextColor, backgroundColor }}
    >
      <div className={styles.imageWrapper}>
        <img
          src={
            featuredBlogPost
              ? photoInfo
                ? formatPhotoUrl(photoUrlTemplate, photoInfo, last(allImageSizes)!.code, sizeMethod)
                : processStaticImageUrl("/storage/images/common/pictureNoContent.png")
              : processStaticImageUrl(`/storage/images/blocks/blog-landing/featured-post-cover.jpg`)
          }
          sizes={photoInfo ? "100vw" : undefined}
          srcSet={photoInfo ? formatPhotoSrcSet(photoUrlTemplate, photoInfo, sizeMethod) : undefined}
          alt={photoInfo ? photoInfo.altText || photoInfo.title : undefined}
          style={{
            objectPosition:
              featuredBlogPost && photoInfo
                ? `${featuredBlogPost.coverPhotoFocalPointX}% ${featuredBlogPost.coverPhotoFocalPointY}%`
                : "center"
          }}
        />
      </div>
      {(author || date) && (
        <div ref={headerRef} className={cx(styles.header, headerMultiline && styles.multiline)}>
          {author && <div className={styles.author}>{author}</div>}
          {date && (
            <div className={styles.date}>
              {author && <div className={styles.separator}>•</div>}
              {date}
            </div>
          )}
        </div>
      )}
      <div className={styles.content}>
        <div className={cx(styles.title, styles[siteFonts.primary])} style={{ color: titleTextColor }}>
          {featuredBlogPost ? featuredBlogPost.title : "Niklaus + Camille | Saint Anne's in New Orleans"}
        </div>
        {(!featuredBlogPost || featuredBlogPost.seoDescription) && (
          <div className={styles.description}>
            {featuredBlogPost
              ? featuredBlogPost.seoDescription
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <a href={blogPostUrl || undefined} onClick={onButtonSeoWrapperClick}>
          <ZenButton
            className={styles.button}
            onClick={onFeaturedPostClick}
            siteTheme={siteTheme}
            layout="A"
            labelText="Read More"
            accentColor={siteTheme.accentColor}
          />
        </a>
      </div>
    </div>
  );
};

export default FeaturedPost;
