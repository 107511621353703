import { FormTextInput } from "@zenfolio/core-components";
import classNames from "classnames";
import _ from "lodash";
import * as React from "react";
import LinkButton from "../linkButton";
import styles from "./index.module.scss";

interface ISearchInputProps {
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onClear: () => void;
  disabled?: boolean;
  className?: string;
}

interface ISearchInputState {
  value: string | null;
}

class SearchInput extends React.Component<ISearchInputProps, ISearchInputState> {
  constructor(props: ISearchInputProps) {
    super(props);
    this.state = {
      value: this.props.value || ""
    };
  }

  public render() {
    const { placeholder, disabled, className } = this.props;
    const { value } = this.state;

    return (
      <span className={classNames(styles.container, className)}>
        <FormTextInput
          className={styles.input}
          value={value || ""}
          placeholder={placeholder}
          disabled={disabled}
          onChange={this.onChange}
        />
        <LinkButton className={classNames(_.isEmpty(value) && styles.inactive)} onClick={this.onClear}>
          Reset
        </LinkButton>
      </span>
    );
  }

  private onChange = (value: string) => {
    this.setState({
      value
    });

    this.debouncedOnChange(value);
  };

  private onClear = () => {
    this.setState({
      value: null
    });

    this.props.onClear();
  };

  private debouncedOnChange = _.debounce(this.props.onChange, 150);
}

export default SearchInput;
