import cx from "classnames";
import ZenBookmeButton from "../../zenBookmeButton";
import ZenButton from "../../zenButton";
import Image from "../../../Image";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { getBackGroundTile, getFontColor, hexToRgb } from "utilities/blocks/blockColors";
import { onLinkClick } from "utilities/blocks/blockInteractions";
import { getSiteFonts, getUUID } from "utilities/blocks/site";
import { renderLineBreaks } from "utilities/blocks/string";
import { Utilities } from "utilities/utilities";
import ContactForm from "../contactForm";
import { IService } from "../index";
import ServiceRadioGroup from "../serviceRadioGroup";
import styles from "./styles.module.scss";
import { INavigationMenuItem, IZenSiteTheme } from "../../../../models/models";
import { PageContext } from "utilities/pageContext";
import { LinkTypeOption } from "utilities/constant";

export interface IServiceComponentProps {
  siteTheme: IZenSiteTheme;
  value: IService;
  index: number;
  isLast: boolean;
  accentColor: string;
  backgroundColor: string;
  showImage: boolean;
  readOnly: boolean;
  layout: string;
  selectedIndex: number;
  subdomain?: string;
  studioName: string;
  isEditionView?: boolean;
  bookMePhotographerId?: string;
  bookMeWidgetUrl?: string;
  setSelectedIndex: (idx: number) => void;
  onSendButtonClick: (formData: any, onSuccess: () => void, onError: (error: any) => void) => void;
  isPublish?: boolean;
  SSRContextManager?: any;
  international?: boolean;
  hasFractionalPrices?: boolean;
  countryCode?: string;
  currencyCode?: string;
}

const TILE_HEIGHT_IMAGE = 1228;
const TILE_HEIGHT_NO_IMAGE = 798;

const Service: React.FC<IServiceComponentProps> = props => {
  const pageContext = useContext(PageContext);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [packages, setPackages] = useState(props.value.packages);
  const [selectedPackageName, setSelectedPackageName] = useState(
    props.value.packages.filter(pack => pack.isSelected)[0]
      ? props.value.packages.filter(pack => pack.isSelected)[0].title
      : ""
  );
  const [isFormOpen, setFormOpen] = useState(false);
  const [isScrollContent, setScrollContent] = useState(true);
  const [isWidgetOpen, setWidgetOpen] = useState(false);

  const [isValid, setIsValid] = useState({});

  const isValidPackage = () => {
    const result = _.get(isValid, getSelectedPackage(), false);
    return result;
  };

  const formatPrice = (value: number, hasAbbreviation: boolean = false) => {
    const price = props.hasFractionalPrices
      ? Utilities.formatFractionalNumberWithCurrency(value, props.countryCode, props.currencyCode, hasAbbreviation)
      : Utilities.formatNumberWithCurrency(value, props.countryCode, props.currencyCode, hasAbbreviation);

    return price;
  };

  const scrollContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isBookMePackagesChanged = props.bookMePhotographerId && !_.isEqual(packages, props.value.packages);
    if (props.isEditionView || isBookMePackagesChanged) {
      setPackages(props.value.packages);
    }

    let isScrolling: any;

    if (scrollContent && scrollContent.current) {
      scrollContent.current.addEventListener("scroll", () => {
        setScrollContent(true);
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(() => setScrollContent(true), 66);
      });
    }
  }, [props.accentColor, props.value, props.layout, props.isEditionView]);

  const siteFonts = getSiteFonts(props.siteTheme.fontsStyle);
  const handleButtonClick = (index: number, service: IService) => {
    const { link } = service;

    if (link?.type === LinkTypeOption.URL) {
      onLinkClick(
        [] as INavigationMenuItem[],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        link,
        props.isPublish
      );
    } else {
      if (!props.isEditionView) {
        setFormOpen(true);
        props.setSelectedIndex(index);
      }
    }
  };

  const handleFormClose = () => {
    setFormOpen(false);
    props.setSelectedIndex(-1);
  };

  const handleWidgetOpenChange = (widgetOpen: boolean) => {
    setWidgetOpen(widgetOpen);
  };

  const renderPrice = () => {
    const data = props.value.packages
      .filter(pack => pack.isVisible && (props.bookMePhotographerId || pack.price))
      .map(pack => pack.price);
    const priceClassNames = cx(styles.price, styles[siteFonts.secondary]);
    if (data.length <= 0) {
      return <p className={priceClassNames}>Contact for Pricing</p>;
    }

    const min = Math.min(...data);
    const max = Math.max(...data);
    if (min === max) {
      return <p className={priceClassNames}>{formatPrice(min, true)}</p>;
    } else {
      return (
        <p className={priceClassNames}>
          {formatPrice(min, true)} - {formatPrice(max, true)}
        </p>
      );
    }
  };

  const newFocalX = props.value.focalX ?? 50;
  const newFocalY = props.value.focalY ?? 50;

  // If id is null is default image
  const isGhost = !pageContext.noGhost && !props.value.imageId;

  const getTileImage = () => {
    return (
      <Image
        src={props.value.imageUrl}
        srcset={props.value.imageSrcSet || undefined}
        alt={props.value.altText ? props.value.altText : ""}
        className={cx(styles.image, { [styles.imageClickable]: props.isEditionView })}
        styles={{
          objectPosition: newFocalX + "% " + newFocalY + "%"
        }}
        isGhost={isGhost}
      />
    );
  };

  const onUpdateValidate = (id: string, state: boolean) => {
    setIsValid(prevState => ({
      ...prevState,
      [id]: state
    }));
  };

  const uniqueId = useMemo(() => getUUID(), []);

  const getAboutSection = () => {
    return (
      <div className={cx(styles.scrollContainerParent, styles[siteFonts.primary])}>
        <div
          ref={scrollContent}
          className={cx(
            styles.scrollContainer,
            isScrollContent ? styles.scrollContainerOn : styles.scrollContainerOff,
            styles[siteFonts.secondary]
          )}
        >
          <div className={cx(styles.scrollContent, isScrollContent ? styles.scrollContentOn : styles.scrollContentOff)}>
            {props.value.aboutService.trim() !== "" && (
              <React.Fragment>
                <div className={styles.aboutTitle}>About This Service</div>
                <div
                  className={cx(styles.about, !isAboutOpen ? styles.isAboutOpenFalse : styles.isAboutOpenTrue, {
                    [styles.aboutServiceClickable]: props.isEditionView
                  })}
                >
                  {renderLineBreaks(props.value.aboutService)}
                  {!isAboutOpen && props.value.aboutService.length > 226 && (
                    <div className={styles.readMore}>
                      <div
                        className={styles.readMoreText}
                        onClick={() => {
                          setIsAboutOpen(!isAboutOpen);
                        }}
                      >
                        Read more
                      </div>
                    </div>
                  )}
                </div>
                {isAboutOpen && props.value.aboutService.length > 125 && (
                  <div
                    className={styles.readLess}
                    onClick={() => {
                      setIsAboutOpen(!isAboutOpen);
                    }}
                  >
                    Read less
                  </div>
                )}
              </React.Fragment>
            )}
            <div className={styles.pricingTitle}>Pricing</div>
            <ServiceRadioGroup
              uniqueRadioGroupName={uniqueId}
              isEditionView={props.isEditionView}
              packages={packages}
              color={props.accentColor}
              layout={props.layout}
              showImage={props.showImage}
              setSelectedPackName={setSelectedPackageName}
              backgroundColor={props.backgroundColor}
              bookMePhotographerId={props.bookMePhotographerId}
              onUpdateValidate={onUpdateValidate}
              formatPrice={formatPrice}
            />
          </div>
        </div>
      </div>
    );
  };

  const getBookMeUrlBuilder = (): string => {
    const photographerId = props.bookMePhotographerId!;
    const serviceId = getSelectedPackage();
    const builder = new URL(props.bookMeWidgetUrl!);

    builder.searchParams.set("photographerId", photographerId);
    builder.searchParams.set("serviceId", serviceId);

    return builder.href;
  };

  const getSelectedPackage = () => {
    const selectedPackage = _.first(packages.filter(pack => pack.isSelected));
    return selectedPackage ? selectedPackage.id : "";
  };

  const renderButton = () => {
    const isBookMeLink = props.value.link?.type === LinkTypeOption.BOOKME;
    return props.bookMePhotographerId || isBookMeLink ? (
      <ZenBookmeButton
        className={styles.button}
        disabled={!isValidPackage() && !isBookMeLink}
        labelText={props.value.link?.label || props.value.buttonLabel}
        siteTheme={props.siteTheme}
        bookmeUrl={props.value.link?.bookmeUrl || getBookMeUrlBuilder()}
        widgetPadding={20}
        widgetMarginBottom={30}
        onWidgetOpenChange={handleWidgetOpenChange}
        isEditionView={props.isEditionView}
        accentColor={props.siteTheme.accentColor}
      />
    ) : (
      <ZenButton
        className={cx(styles.button, { [styles.buttonClickable]: props.isEditionView })}
        onClick={() => handleButtonClick(props.value.index, props.value)}
        siteTheme={props.siteTheme}
        layout={"A"}
        labelText={props.value.link?.label || props.value.buttonLabel}
      />
    );
  };

  const serviceLayoutB = (props: IServiceComponentProps) => {
    return (
      <React.Fragment>
        <div
          className={cx(
            styles.serviceBContainer,
            props.index % 2 === 0 ? styles.layoutAlignRow : styles.layoutAlignReverse
          )}
        >
          {props.showImage && getTileImage()}
          <div className={cx(styles.serviceInfoContainer, { [styles.hasImage]: props.showImage })}>
            <div className={styles.titleContainer}>
              <hr className={styles.divider} />
              <div className={cx(styles.categoryContainer, styles[siteFonts.primary])}>
                <p
                  className={cx(styles.category, styles[siteFonts.primaryExtra], {
                    [styles.tileTitleClickable]: props.isEditionView
                  })}
                >
                  {props.value.category}
                </p>
                {renderPrice()}
              </div>
              <hr className={styles.divider} />
            </div>
            {getAboutSection()}
            <div
              className={cx(
                styles.buttonContainer,
                props.showImage ? styles.justifyContentStart : styles.layoutAlignReverse
              )}
            >
              {renderButton()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const serviceLayoutA = (props: IServiceComponentProps) => {
    return (
      <React.Fragment>
        {props.showImage && getTileImage()}
        <div className={cx(styles.titleContainer, styles[siteFonts.primary])}>
          <p
            className={cx(styles.category, styles[siteFonts.primaryExtra], {
              [styles.tileTitleClickable]: props.isEditionView
            })}
          >
            {props.value.category}
          </p>
          <hr className={styles.divider} />
          {renderPrice()}
          <hr className={styles.divider} />
        </div>
        {getAboutSection()}
        <div className={styles.buttonContainer}>{renderButton()}</div>
      </React.Fragment>
    );
  };
  const bgColor = getBackGroundTile(props.backgroundColor);
  const rgbBgColor = hexToRgb(bgColor)!;
  const transparentBgColor = `rgba(${rgbBgColor.r}, ${rgbBgColor.g}, ${rgbBgColor.b}, 0)`;
  return (
    <div
      style={{
        outline: props.value.isSelected && props.isEditionView ? "solid 2px #f15a0b" : "",
        display: props.value.isVisible ? "block" : "none",
        color: getFontColor(props.backgroundColor)
      }}
      className={cx(
        props.layout === "A"
          ? styles.zenContainerServiceMain
          : cx(styles.zenContainerServiceMainB, props.bookMePhotographerId ? styles.widget : undefined),
        props.value.isVisible ? styles.displayBlock : styles.displayNone,
        isWidgetOpen && styles.widgetOpen,
        { [`service-${props.index}`]: props.isEditionView }
      )}
      data-index={props.index}
    >
      <style>
        {`
            :root {
              --svc-background-color: ${bgColor};
              --svc-background-color-transparent: ${transparentBgColor};
              --accent-color: ${props.accentColor};
              --container-height: ${props.showImage ? TILE_HEIGHT_IMAGE : TILE_HEIGHT_NO_IMAGE};
              --theme-color: ${props.backgroundColor};
              }
            `}
      </style>
      {isFormOpen && props.selectedIndex === props.index && (
        <ContactForm
          siteTheme={props.siteTheme}
          value={props.value}
          packTitle={selectedPackageName}
          onSendButtonClick={props.onSendButtonClick}
          onClose={handleFormClose}
          accentColor={props.accentColor}
          renderLineBreaks={renderLineBreaks}
          subdomain={props.subdomain}
          backgroundColor={props.backgroundColor}
          studioName={props.studioName}
          layout={props.layout}
          international={props.international}
          countryCode={props.countryCode}
        />
      )}
      {props.layout === "A" ? serviceLayoutA(props) : serviceLayoutB(props)}
    </div>
  );
};

export default Service;
