import classNames from "classnames";
import React from "react";
import arrowIcon from "../arrow.svg";
import { IComponentBaseProps, IElementBaseProps } from "../utils";
import styles from "./numericInputHost.module.scss";

interface INumericInputProps extends IComponentBaseProps<HTMLDivElement> {
  inputContainer?: IElementBaseProps<HTMLDivElement>;
  buttonsContainer?: IElementBaseProps<HTMLDivElement>;
  errorContainer?: IElementBaseProps<HTMLDivElement>;
  incrementDisabled?: boolean;
  decrementDisabled?: boolean;
  disabled?: boolean;
  errorMessage?: string; // pass empty string to show error state without message
  onIncrement?: () => void;
  onDecrement?: () => void;
}

class NumericInputHost extends React.Component<INumericInputProps> {
  public componentWillUnmount() {
    this.clearTimers();
  }

  public componentDidUpdate() {
    const { incrementDisabled, decrementDisabled, disabled } = this.props;

    if ((incrementDisabled || disabled) && this.incrementing) {
      this.clearTimers();
    }

    if ((decrementDisabled || disabled) && this.decrementing) {
      this.clearTimers();
    }
  }

  public render() {
    const { inputContainer, buttonsContainer, errorContainer, containerRef, style, className, children } = this.props;
    const { incrementDisabled, decrementDisabled, disabled, errorMessage } = this.props;

    return (
      <div className={classNames(styles.container, className)} style={style} ref={containerRef}>
        <div
          className={classNames(
            styles.input,
            inputContainer?.className,
            (errorMessage || errorMessage === "") && styles.error
          )}
          style={inputContainer?.style}
          ref={inputContainer?.ref}
        >
          {children}
          <div
            className={classNames(styles.buttons, buttonsContainer?.className)}
            style={buttonsContainer?.style}
            ref={buttonsContainer?.ref}
          >
            <button
              tabIndex={-1}
              className={classNames(styles.button, (disabled || incrementDisabled) && styles.disabled)}
              onMouseDown={this.onIncrementMouseDown}
              onMouseUp={this.onIncrementMouseUp}
              onMouseLeave={this.onIncrementMouseLeave}
            >
              <img className={styles.increment} src={arrowIcon} />
            </button>
            <button
              tabIndex={-1}
              className={classNames(styles.button, (disabled || decrementDisabled) && styles.disabled)}
              onMouseDown={this.onDecrementMouseDown}
              onMouseUp={this.onDecrementMouseUp}
              onMouseLeave={this.onDecrementMouseLeave}
            >
              <img className={styles.decrement} src={arrowIcon} />
            </button>
          </div>
        </div>
        {errorMessage && (
          <div
            className={classNames(styles.errorMessage, errorContainer?.className)}
            style={errorContainer?.style}
            ref={errorContainer?.ref}
          >
            {errorMessage}
          </div>
        )}
      </div>
    );
  }

  private onIncrementMouseDown = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!this.props.disabled && event.button === 0) {
      if (!this.props.incrementDisabled) {
        this.props.onIncrement?.();
        this.clearTimers();
        this.delayTimer = setTimeout(() => {
          this.delayTimer = undefined;
          this.incrementing = true;
          this.intervalTimer = setInterval(() => this.props.onIncrement?.(), NumericInputHost.repeatInterval);
        }, NumericInputHost.repeatDelay);
      }
    }
  };

  private onDecrementMouseDown = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!this.props.disabled && event.button === 0) {
      if (!this.props.decrementDisabled) {
        this.props.onDecrement?.();
        this.clearTimers();
        this.delayTimer = setTimeout(() => {
          this.delayTimer = undefined;
          this.decrementing = true;
          this.intervalTimer = setInterval(() => this.props.onDecrement?.(), NumericInputHost.repeatInterval);
        }, NumericInputHost.repeatDelay);
      }
    }
  };

  private onIncrementMouseUp = () => this.clearTimers();
  private onIncrementMouseLeave = () => this.clearTimers();
  private onDecrementMouseUp = () => this.clearTimers();
  private onDecrementMouseLeave = () => this.clearTimers();

  private clearTimers() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
      this.intervalTimer = undefined;
    }

    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
      this.delayTimer = undefined;
    }
  }

  private incrementing: boolean = false;
  private decrementing: boolean = false;
  private intervalTimer?: NodeJS.Timeout;
  private delayTimer?: NodeJS.Timeout;

  private static readonly repeatDelay = 500;
  private static readonly repeatInterval = 50;
}

export default NumericInputHost;
