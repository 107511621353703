import { IRoute } from "../../components/router/routes";
import { MenuAction } from "./actions";
import { MenuActionType } from "./constants";

export interface IMenuState {
  items: IRoute[];
}

const initialState: IMenuState = {
  items: []
};

export default function menuReducer(state: IMenuState = initialState, action: MenuAction) {
  switch (action.type) {
    case MenuActionType.SET_MENU:
      return { ...state, items: action.items };
    default:
      return state;
  }
}
