import React from "react";
import IIConProps from "../../IIconProps";

const Download = ({ minX = 0, minY = 0, ...props }: IIConProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox={`${minX} ${minY} ${32} ${32}`}
  >
    <g fill="none" fillRule="evenodd" opacity={props.opacity}>
      <path
        stroke={props.color}
        strokeLinecap="round"
        strokeWidth="2.25"
        d="M28.25 21v6a1.5 1.5 0 0 1-1.5 1.5h-21a1.5 1.5 0 0 1-1.5-1.5v-6"
      />
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M15.125 19.815V3.765a1.125 1.125 0 0 1 2.25 0v16.04l2.865-2.633a1.125 1.125 0 0 1 1.523 1.656l-4.752 4.366a1.125 1.125 0 0 1-1.52.002l-4.782-4.365a1.125 1.125 0 0 1 1.518-1.662l2.898 2.646z"
      />
    </g>
  </svg>
);

export default Download;
