import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { reset } from "../../../../store/billing/actions";
import { IAppState } from "../../../../store/state";

function mapStateToProps({ billing: { billing } }: IAppState) {
  return { billing };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    onReset: () => dispatch(reset())
  };
}

export default connect(mapStateToProps, mapDispatchToProps);
