import LikeIcon from "components/Icons/Like";
import React from "react";
import styles from "./likesCounter.module.scss";

export interface ILikesCounterProps {
  count?: number;
  onClick?: () => void;
  liked?: boolean;
}

const LikesCounter = (props: ILikesCounterProps) => {
  const { count, onClick, liked } = props;

  return (
    <div className={styles.likesCounterContainer}>
      <span className={styles.count}>{count ?? 0}</span>
      <div className={styles.iconContainer} onClick={onClick}>
        <LikeIcon width={32} height={32} stroke="#e60000" fill={liked ? "#e60000" : undefined} />
      </div>
    </div>
  );
};

export default LikesCounter;
