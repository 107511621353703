import { IBaseAddressInfo } from "@zenfolio/core-components";
import { AddressInputValue } from "@zenfolio/core-components/dist/components/AddressInput/model";
import { getFullAddress, isAddress, isAddressPrediction, isCompleteAddress } from ".";

export function getAddressInfo(address: AddressInputValue): IBaseAddressInfo {
  if (isAddressPrediction(address)) {
    return {
      value: address.description || "",
      isComplete: false,
      coordinates: null
    };
  }

  if (isAddress(address)) {
    const isComplete = isCompleteAddress(address);

    return {
      value: getFullAddress(address),
      isComplete,
      coordinates: { lat: address.latitude, lng: address.longitude }
    };
  }

  return {
    value: address || "",
    isComplete: false,
    coordinates: null
  };
}
