import cx from "classnames";
import _ from "lodash";
import React from "react";
import { Utilities } from "../../../utilities/utilities";
import { IDatePickerCalendarYearsProps } from "../models";
import styles from "./defaultDatePickerCalendarYears.module.scss";

const { shouldComponentUpdateDeep } = Utilities;

export interface IDefaultDatePickerCalendarYearsProps extends IDatePickerCalendarYearsProps {}

class DefaultDatePickerCalendarYears extends React.Component<IDefaultDatePickerCalendarYearsProps> {
  public shouldComponentUpdate(nextProps: IDefaultDatePickerCalendarYearsProps): boolean {
    return shouldComponentUpdateDeep(this, nextProps);
  }

  public render() {
    const { year, className, style, containerRef } = this.props;

    return (
      <div className={cx(styles.container, className)} style={style} ref={containerRef}>
        {_.range(year - 12, year + 13).map(num => (
          <div
            key={num}
            className={cx(styles.year, { [styles.selected]: year === num })}
            onClick={this.onYearClick(num)}
          >
            {num}
          </div>
        ))}
      </div>
    );
  }

  private onYearClick = _.memoize((year: number) => () => this.props.onYearChange?.(year));
}

export default DefaultDatePickerCalendarYears;
