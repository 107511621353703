import { Utilities } from "@zenfolio/core-components";
import React from "react";
import eventTracker from "../../../../../utilities/eventTracker";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import ModalDialog from "../../modalDialog";
import styles from "./index.module.scss";
import { IMarkAsPaid } from "./model";
import withStore from "./withStore";

export interface IMarkAsPaidOwnProps {
  invoiceId: string;
  photographerBalanceChargeAmount: number;
  onClose: () => void;
  onError: () => void;
}

export interface IMarkAsPaidProps extends IMarkAsPaidOwnProps {
  markAsPaid: IMarkAsPaid;
}

class MarkAsPaid extends React.Component<IMarkAsPaidProps> {
  public componentDidUpdate(prevProps: IMarkAsPaidProps) {
    const { markAsPaid, onClose, onError } = this.props;
    const markAsPaidHasCompleted = hasActionCompleted(prevProps.markAsPaid.status, markAsPaid.status);
    if (markAsPaidHasCompleted) {
      if (markAsPaid.status === "Success") {
        onClose();
      } else if (markAsPaid.status === "Error") {
        onError();
      }
    }
  }

  public render() {
    const { markAsPaid, photographerBalanceChargeAmount } = this.props;

    return (
      <ModalDialog
        title="MARK AS PAID?"
        okText="Confirm"
        cancelText="Cancel"
        noBodyPadding={true}
        okDisabled={markAsPaid.status === "Pending"}
        onCancel={this.onCancel}
        onOk={this.onOk}
      >
        <div className={styles.container}>
          {photographerBalanceChargeAmount > 0
            ? `Your account will be debited ${Utilities.formatFractionalNumberWithCurrency(
                photographerBalanceChargeAmount
              )} for the tax + fees for the service.`
            : "Marking this invoice as paid will close this invoice and apply the payment to the session."}
        </div>
      </ModalDialog>
    );
  }

  private onCancel = () => this.props.onClose();

  private onOk = () => {
    eventTracker.events.photographer.bookings.invoiceMarkedAsPaid();
    this.props.markAsPaid.onMarkAsPaid(this.props.invoiceId);
  };
}

export default withStore(MarkAsPaid);
