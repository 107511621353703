import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Swipeable } from "react-swipeable";
import arrowLeft from "../arrow-left.svg";
import arrowRight from "../arrow-right.svg";
import styles from "./dayPicker.module.scss";

export type DayStatus = "normal" | "empty";

interface IDayPickerProps {
  defaultDay?: moment.Moment;
  day?: moment.Moment;
  minDay?: moment.Moment;
  maxDay?: moment.Moment;
  onChange?: (day: moment.Moment) => void;
  dayStatuses?: DayStatus[];
  className?: string;
  style?: React.CSSProperties;
}

interface IDayPickerState {
  day?: moment.Moment;
  showLine?: boolean;
}

class DayPicker extends React.Component<IDayPickerProps, IDayPickerState> {
  public state: IDayPickerState = {};

  public componentDidMount() {
    this.setState({ showLine: true });
  }

  public render() {
    const { className, style } = this.props;
    const { showLine } = this.state;
    const selectedDay = this.day;
    const days = getWeekDays(selectedDay);
    const dayStatuses = this.props.dayStatuses || _.range(0, 7).map(() => "normal");
    const selectedDayNumberRef = this.dayNumberRefs[selectedDay.isoWeekday() % 7].current;
    const linePosition = selectedDayNumberRef ? selectedDayNumberRef!.offsetLeft : 0;

    return (
      <div className={classNames(styles.container, className)} style={style}>
        <div
          className={classNames(styles.arrow, { [styles.blocked]: !this.canNavigateBack })}
          onClick={this.onArrowPrevClick}
        >
          <img alt="" src={arrowLeft} />
        </div>
        <Swipeable
          className={styles.daysContainer}
          onSwipedLeft={this.onDaysSwipedLeft}
          onSwipedRight={this.onDaysSwipedRight}
        >
          <div className={styles.days}>
            {days.map((day, idx) => (
              <div
                className={classNames(styles.day, {
                  [styles.selected]: this.isInRange(day) && day.isSame(selectedDay, "day"),
                  [styles.empty]: this.isInRange(day) && dayStatuses[idx] === "empty",
                  [styles.disabled]: !this.isInRange(day)
                })}
                key={idx}
                onClick={this.onDayClick(day)}
              >
                <div className={styles.name}>{DayPicker.dayNames[idx]}</div>
                <div className={styles.number} ref={this.dayNumberRefs[idx]}>
                  {day.date()}
                </div>
              </div>
            ))}
          </div>
          {showLine && <div className={styles.line} style={{ transform: `translateX(${linePosition}px)` }} />}
        </Swipeable>
        <div
          className={classNames(styles.arrow, styles.right, { [styles.blocked]: !this.canNavigateForward })}
          onClick={this.onArrowNextClick}
        >
          <img alt="" src={arrowRight} />
        </div>
      </div>
    );
  }

  private get day() {
    const { props, state } = this;

    return (props.day !== undefined ? props.day : state.day !== undefined ? state.day : props.defaultDay || this.now)
      .clone()
      .startOf("day");
  }

  private get canNavigateBack() {
    const days = getWeekDays(this.day);
    return this.isInRange(days[0].clone().add(-1, "day"));
  }

  private get canNavigateForward() {
    const days = getWeekDays(this.day);
    return this.isInRange(days[6].clone().add(1, "day"));
  }

  private isInRange = (day: moment.Moment) =>
    day.isBetween(this.props.minDay || day, this.props.maxDay || day, "day", "[]");

  private changeDay = (day: moment.Moment) => {
    if (!this.isInRange(day) || day.isSame(this.day, "day")) {
      return;
    }

    const onChange = (d: moment.Moment) => {
      if (this.props.onChange) {
        this.props.onChange(d);
      }
    };

    if (this.props.day === undefined) {
      this.setState({ day }, () => onChange(this.day));
    } else {
      onChange(day);
    }
  };

  private navigate = (increment: number) => {
    const { minDay, maxDay } = this.props;
    let day = this.day.add(increment, "week");
    day = this.isInRange(day) ? day : (increment === 1 ? maxDay! : minDay!).clone();
    this.changeDay(day);
  };

  private onDayClick = _.memoize(
    (day: moment.Moment) => () => this.changeDay(day),
    day => (day as moment.Moment).format("YYYY-MM-DD")
  );

  private onArrowPrevClick = () => this.navigate(-1);
  private onArrowNextClick = () => this.navigate(1);
  private onDaysSwipedLeft = () => this.canNavigateForward && this.navigate(1);
  private onDaysSwipedRight = () => this.canNavigateBack && this.navigate(-1);

  private readonly dayNumberRefs = _.range(0, 7).map(() => React.createRef<HTMLDivElement>());
  private readonly now = moment();
  private static readonly dayNames = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
}

export function getWeekDays(day: moment.Moment) {
  const startOfWeek = day
    .clone()
    .startOf("isoWeek")
    .add(day.isoWeekday() === 7 ? 6 : -1, "day");
  const days = _.range(0, 7).map(i => startOfWeek.clone().add(i, "day"));
  return days;
}

export default DayPicker;
