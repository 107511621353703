import React, { useState, useEffect } from "react";
import styles from "./menuOption.module.scss";
import cx from "classnames";
// ES6 import doesnt work well when consuming this component from site-viewer
// it loads an object instead of a react component
const OutsideClickHandler = require("react-outside-click-handler").default;

export interface IMenuOptionProps {
  disableClick: boolean;
  iconSrcActive: string;
  iconSrcDisabled?: string;
  hide?: boolean;
  style?: React.CSSProperties;
  divider?: boolean;
  onClickDisabled?: () => void;
  svgIcon?: JSX.Element;
}

const MenuOption: React.FC<IMenuOptionProps> = props => {
  const [state, setState] = useState(false);

  const menuClick = () => {
    setState(!state);
  };

  const onMenuClick = () => {
    if (!props.disableClick) {
      menuClick();
    } else if (props.onClickDisabled) {
      props.onClickDisabled();
    }
  };

  return (
    <React.Fragment>
      <div
        style={props.style}
        className={cx(
          styles.menu,
          { [styles.displayNone]: props.hide },
          { [styles.divider]: props.divider }
        )}
      >
        <OutsideClickHandler onOutsideClick={() => (state ? menuClick() : null)}>
          <div onClick={onMenuClick} className={styles.iconMenu}>
            {props.svgIcon ? (
              props.svgIcon
            ) : (
              <img
                className={styles.icon}
                src={
                  !props.disableClick
                    ? props.iconSrcActive
                    : props.iconSrcDisabled
                    ? props.iconSrcDisabled
                    : props.iconSrcActive
                }
              />
            )}
          </div>
        </OutsideClickHandler>
        {React.Children.map(props.children, child => {
          return React.cloneElement(child as any, {
            className: cx(state ? styles.displayBlock : styles.displayNone)
          });
        })}
      </div>
    </React.Fragment>
  );
};

export default MenuOption;
