import React from "react";
import { initializeIconProps } from "../../../utilities/icons";
import IIConProps from "../IIconProps";

const Customize = (rawProps: IIConProps) => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`${props.minX} ${props.minY} 24 24`}
    >
      <g fill="none" fillRule="evenodd" className="no-stroke">
        <path
          fill={props.color}
          fillRule="nonzero"
          d="M24.56 8.604c.174.173.194.443.059.637l-.058.07-4.647 4.646 5 5-3.707 3.707-5-5-3.646 3.647c-.02.02-.039.036-.059.05l-.013.009c-.03.022-.065.04-.102.054l-.059.018-4 1c-.335.084-.642-.188-.62-.516l.014-.09 1-4v-.001c.01-.036.022-.071.04-.105.023-.047.052-.088.087-.123l.005-.003 3.646-3.647-5-5 3.707-3.707 5 5 4.647-4.646c.173-.174.443-.193.637-.058l.07.058 3 3zm-13.333 12.08l-1.75-1.749-.583 2.334 2.333-.585zm-1.312-2.727l2.293 2.293 3.293-3.293H15.5l6-6-2.293-2.293-2.293 2.292v.001l-7 7zM12.12 7.576l-.913-.912-2.293 2.293 4.293 4.293 2.293-2.293-.87-.872-1.384 1.384-.509-.51 1.383-1.383-.493-.493-.892.892-.509-.509.892-.893-.489-.488-1.383 1.384-.509-.51 1.383-1.383zm8 8l-.913-.912-2.293 2.293 4.293 4.293 2.293-2.293-.871-.872-1.383 1.384-.509-.51 1.383-1.383-.493-.493-.892.892-.509-.509.892-.892-.489-.489-1.383 1.384-.509-.51 1.383-1.383zm3.38-6.619l-2.293-2.293-1.292 1.293 2.293 2.293L23.5 8.957z"
          transform="translate(-4 -2)"
        />
      </g>
    </svg>
  );
};

export default Customize;
