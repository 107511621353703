import { IAddress } from "@zenfolio/core-components/dist/models/location";
import {
  IAvailability,
  IBookingCreator,
  IInvoiceInfo,
  IInvoicePaymentCreator,
  IShippingInfo,
  ITaxInfo,
  IWidgetInfo
} from "../store/widget/model";
import { apiType } from "./apiType";
import { widgetClient } from "./index";

export interface ILoadWidgetInfoResponse extends IWidgetInfo {}

export interface ILocationValidateResponse {
  isValid: boolean;
}

export interface ILocationValidateRequest {
  photographerServiceId: string;
  address: IAddress;
}

export interface IApiServicesStepActivation {
  loadWidgetInfo: () => Promise<ILoadWidgetInfoResponse>;
}

export interface ICreateBookingRequest extends IBookingCreator {}

export interface ICreateBookingResponse {
  bookingId: string;
  bookingTransactionId?: string;
}

export interface ILoadInvoiceInfoResponse extends IInvoiceInfo {}

export interface IPayInvoiceRequest extends IInvoicePaymentCreator {}

export interface IPayInvoiceInfoResponse {
  transactionId: string;
}

export interface ICalculateServiceTaxRequest extends IShippingInfo {}

export interface ICalculateServiceTaxResponse {
  initial: ITaxInfo;
  total: ITaxInfo;
}

export interface IApiWidget {
  loadWidgetInfo: () => Promise<ILoadWidgetInfoResponse>;
  loadInvoiceInfo: (invoiceId: string) => Promise<ILoadInvoiceInfoResponse>;
  payInvoice: (request: IPayInvoiceRequest) => Promise<IPayInvoiceInfoResponse>;
  validateLocation: (request: ILocationValidateRequest) => Promise<ILocationValidateResponse>;
  loadAvailability: (serviceId: string) => Promise<IAvailability>;
  createBooking: (request: ICreateBookingRequest) => Promise<ICreateBookingResponse>;
  calculateServiceTax: (
    serviceId: string,
    request: ICalculateServiceTaxRequest
  ) => Promise<ICalculateServiceTaxResponse>;
}

const api: IApiWidget = {
  loadWidgetInfo: () => widgetClient.get<ILoadWidgetInfoResponse>(`${apiType.bookingWidget}widget/info`),
  loadInvoiceInfo: (invoiceId: string) =>
    widgetClient.get<ILoadInvoiceInfoResponse>(`${apiType.bookingWidget}billing/invoices/details/${invoiceId}`),
  payInvoice: (request: IPayInvoiceRequest) =>
    widgetClient.post<IPayInvoiceInfoResponse>(`${apiType.bookingWidget}billing/invoices/pay`, request),
  validateLocation: (request: ILocationValidateRequest) =>
    widgetClient.post<ILocationValidateResponse>(
      `${apiType.bookingWidget}widget/location/${request.photographerServiceId}/validate`,
      request.address
    ),
  loadAvailability: (serviceId: string) =>
    widgetClient.get<IAvailability>(`${apiType.bookingWidget}widget/availabilities/${serviceId}/calendar`),
  createBooking: (request: ICreateBookingRequest) =>
    widgetClient.post<ICreateBookingResponse>(`${apiType.bookingWidget}bookings`, {
      ...request,
      expectedInitialTax: request.expectedInitialTax || 0,
      expectedTotalTax: request.expectedTotalTax || 0
    }),
  calculateServiceTax: (serviceId: string, request: ICalculateServiceTaxRequest) =>
    widgetClient.post<ICalculateServiceTaxResponse>(`${apiType.bookingWidget}widget/tax/${serviceId}`, request)
};

export default api;
