import colors from "../colors";
import { matchMediaPhone } from "../responsive";

function safeGetLocalStorageItem(key: string) {
  if (typeof window === "undefined") {
    return "";
  }

  try {
    return window?.localStorage?.getItem?.(key) || "";
  } catch (e) {
    return "";
  }
}

const videoUploadEnabledLocally = safeGetLocalStorageItem("video-upload-enabled") === "true";

function videoUploadEnabled() {
  // @ts-ignore
  return process.env.REACT_APP_ENABLE_VIDEO_UPLOAD === "true" || videoUploadEnabledLocally;
}

interface IColor {
  name: string;
  value: string;
}

function getHighQualitySizeCode(
  sizeCode4k: MESizeCodes,
  sizeCode2k: MESizeCodes,
  use2kAsAFallback: boolean = false
): MESizeCodes {
  if (typeof window !== "undefined" && !matchMediaPhone) {
    const devicePixelRatio = window.devicePixelRatio || 1;
    const maxScreenDimension = Math.max(window.screen.width, window.screen.height);
    const physicalResolution = Math.ceil(maxScreenDimension * devicePixelRatio);

    // 4K monitors
    if (physicalResolution >= 3840) {
      return sizeCode4k;
    }

    // 2K monitors
    if (physicalResolution >= 2048) {
      return sizeCode2k;
    }
  }

  // everything else
  return use2kAsAFallback ? sizeCode2k : MESizeCodes.SIZE1170x1170;
}

export const COLLECTION = {
  ALL_MEDIA_ID: "allMedia",
  ALL_MEDIA: videoUploadEnabled() ? "All Media" : "All Photos",
  FAVORITE_MEDIA_ID: "favoriteMedia",
  FAVORITE_MEDIA: "Favorites",
  EXCLUDE_COLLECTION: "Not in a Collection",
  ICON_LABEL: "Filter"
};

export const defaultAccentColors = [
  { name: "Black", value: "#000000" },
  { name: "Red", value: "#bf211e" },
  { name: "Orange", value: "#e69d4d" },
  { name: "Blue", value: "#3e6990" },
  { name: "Gold", value: "#9f936a" },
  { name: "Plum", value: "#8a4f7d" },
  { name: "Grape", value: "#7470a8" },
  { name: "Green", value: "#8eb169" },
  { name: "Khaki", value: "#afa798" },
  { name: "Brick", value: "#a6565b" },
  { name: "Yellow", value: "#e8c165" },
  { name: "Sky", value: "#7eadd5" },
  { name: "Sand", value: "#cfb391" },
  { name: "Pink", value: "#d1b4ce" },
  { name: "Lilac", value: "#b2abc9" },
  { name: "Aqua", value: "#7aa0a3" }
];

export const defaultBackgroundColors: IColor[] = [
  {
    name: "White",
    value: colors.white.toLocaleLowerCase()
  },
  {
    name: "Cream",
    value: colors.creme.toLocaleLowerCase()
  },
  {
    name: "Gray",
    value: colors.grayNav.toLocaleLowerCase()
  },
  {
    name: "Black",
    value: colors.black.toLocaleLowerCase()
  }
];

export enum cartTypes {
  PACKAGE = "Package",
  DIGITAL = "DigitalALaCarte",
  PHYSICAL = "PhysicalALaCarte"
}

export enum LinkTypeOption {
  GALLERY = "Gallery",
  URL = "URL",
  PAGE = "Page",
  BOOKME = "BookMe",
  ALBUMFOLDER = "albumFolder",
  NONE = "none",
  CONTACT = "Contact"
}

export enum LINK_TYPE {
  TIER_1 = 0,
  TIER_1_AND_BOOK_ME = 1,
  TIER_2 = 2,
  TIER_2_AND_BOOK_ME = 3,
  TIER_1_AND_BOOK_ME_NO_GALLERY = 4,
  TIER_2_AND_BOOK_ME_NO_GALLERY = 5,
  TIER_1_NO_GALLERY = 6,
  TIER_2_NO_GALLERY = 7
}

export enum GRID_TYPE {
  MASONRY = 1,
  JUSTIFIED = 2,
  STACKED = 3,
  GRID = 4
}

enum MESizeCodes {
  SIZE60x60 = "XXS",
  SIZE96x96 = "XS",
  SIZE264x264 = "S",
  SIZE352x352 = "M",
  SIZE500x500 = "L",
  SIZE685x685 = "XL",
  SIZE1170x1170 = "XXL",
  SIZE1488x1488 = "X2XL",
  SIZE2975x2975 = "X3XL"
}

export const allImageSizes = [
  { code: MESizeCodes.SIZE60x60, width: 60, height: 60 },
  { code: MESizeCodes.SIZE96x96, width: 96, height: 96 },
  { code: MESizeCodes.SIZE264x264, width: 264, height: 264 },
  { code: MESizeCodes.SIZE352x352, width: 352, height: 352 },
  { code: MESizeCodes.SIZE500x500, width: 500, height: 500 },
  { code: MESizeCodes.SIZE685x685, width: 685, height: 685 },
  { code: MESizeCodes.SIZE1170x1170, width: 1170, height: 1170 },
  { code: MESizeCodes.SIZE1488x1488, width: 1488, height: 1488 },
  { code: MESizeCodes.SIZE2975x2975, width: 2975, height: 2975 }
];

export const FULL_SCREEN_SIZE_CODE = getHighQualitySizeCode(MESizeCodes.SIZE2975x2975, MESizeCodes.SIZE1488x1488);
export const LIGHT_BOX_SIZE_CODE = getHighQualitySizeCode(MESizeCodes.SIZE2975x2975, MESizeCodes.SIZE1488x1488, true);
export const FULL_SCREEN_SIZE_CODE_HERO_IMAGE = getHighQualitySizeCode(
  MESizeCodes.SIZE2975x2975,
  MESizeCodes.SIZE1488x1488,
  true
);

export enum BannerDiscountType {
  PercentOff = "PercentOff",
  DollarOff = "DollarOff",
  FreeProduct = "FreeProduct",
  FreeShipping = "FreeShipping"
}

export enum BannerViewMode {
  MobileClient = "MobileClient",
  MobilePreview = "MobilePreview",
  DesktopClient = "DesktopClient",
  DesktopPreview = "DesktopPreview"
}

export enum BannerType {
  LargeBanner = "Large Banner",
  TextBanner = "Text Banner"
}

export enum BannerFontStyle {
  Serif = "Serif",
  SansSerif = "SansSerif"
}

export enum ZenBlogPostHeaderBlockAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right"
}

export enum GalleryPresentSettingList {
  None = 0,
  Serif = 1,
  SansSerif = 2,
  White = 3,
  Cream = 4,
  Gray = 5,
  PortraitLarge = 6,
  LandscapeLarge = 7,
  GridLarge = 8,
  Stacked = 9,
  Cover = 10,
  TitleOnly = 11,
  Black = 12,
  GridSmall = 13,
  PortraitSmall = 14,
  LandscapeSmall = 15,
  HideTitle = 16,
  HideDate = 17,
  FocalX = 18,
  FocalY = 19,
  OneColumn = 20,
  TwoColumn = 21,
  ShowCaptions = 22,
  LayoutOptionsContentFilenames = 23,
  LayoutOptionsContentSequenceNumbers = 24,
  LayoutOptionsShowOnlyOnHover = 25,
  LayoutOptionsShowAlways = 26,
  NoCover = 27,
  LayoutOptionsContentNone = 28
}

export enum BANNER_DESIGN_IDS {
  Discount1 = "Discount1",
  Discount4 = "Discount4",
  Seasonal2 = "Seasonal2",
  Seasonal3 = "Seasonal3",
  Seasonal5 = "Seasonal5",
  Fathers = "Fathers",
  Mothers = "Mothers",
  FlashSale = "FlashSale",
  Halloween = "Halloween",
  Holiday = "Holiday",
  Spring = "Spring",
  Summer = "Summer",
  Valentine = "Valentine",
  BacktoSchool = "BacktoSchool",
  FreeShipping = "Freeshipping",
  BoxingDay = "BoxingDay"
}

// This name need to map with react component name of the design
export enum BANNER_DESIGN_NAMES {
  Discount1 = "LargeBannerDiscount1",
  Discount4 = "LargeBannerDiscount4",
  Seasonal2 = "LargeBannerSeasonal1",
  Seasonal3 = "LargeBannerSeasonal3",
  Seasonal5 = "LargeBannerSeasonal3",
  BacktoSchool = "BannerBackToSchool",
  Fathers = "BannerFathers",
  Mothers = "BannerMothers",
  FlashSale = "BannerFlashSale",
  Halloween = "BannerHalloween",
  Holiday = "BannerHoliday",
  Spring = "BannerSpring",
  Summer = "BannerSummer",
  Valentine = "BannerValentine",
  BoxingDay = "BannerBoxingDay"
}

export enum GalleryPresentSettingType {
  FontStyle = 0,
  GridBackgroundColor = 1,
  GridType = 2,
  TitleStyle = 3,
  HideTitle = 4,
  HideDate = 5,
  FocalX = 6,
  FocalY = 7,
  MobileGridType = 8,
  ShowCaptions = 9,
  LayoutOptionsContent = 10,
  LayoutOptionsShow = 11
}

export enum PRINT_IDS {
  LargeFormatPrintsCategoryId = "fe2a3e05-7b65-49e8-b1db-1409add78bc8",
  SquarePrintsCategoryId = "7c1e0e83-f927-4e79-b177-c5c704086542",
  PhotoPrintsCategoryId = "c07ce7f0-843c-4fd1-9207-b2a2df74cebe"
}

export enum WALL_ART_IDS {
  FramedPrintCategoryId = "3f431fe7-7110-4e7f-8449-f90d1f58e1e9",
  MetalPrintCategoryId = "e7804891-2044-4652-ae40-b93d05754d90",
  CanvasCategoryId = "7f3e0996-d0de-4df0-89af-12da5dcdb05b"
}

export const MAPPING_ID_TYPE = {
  [WALL_ART_IDS.FramedPrintCategoryId.toString()]: "Framed Print",
  [PRINT_IDS.LargeFormatPrintsCategoryId.toString()]: "Print",
  [PRINT_IDS.SquarePrintsCategoryId.toString()]: "Print",
  [PRINT_IDS.PhotoPrintsCategoryId.toString()]: "Print",
  [WALL_ART_IDS.MetalPrintCategoryId.toString()]: "Metal Print",
  [WALL_ART_IDS.CanvasCategoryId.toString()]: "Canvas"
};

export declare enum SendInvoiceOption {
  None = "none",
  SameAsShootDate = "sameAsShootDate",
  BeforeShootDate = "beforeShootDate",
  AfterShootDate = "afterShootDate",
  Manually = "manually"
}

export const optionsColorProperty = [
  { value: "white", label: "White" },
  { value: "creme", label: "Cream" },
  { value: "grey", label: "Grey" },
  { value: "black", label: "Black" }
];

export const themeFontsStyles = [
  { name: "fonts-raleway", fonts: "raleway|notosans" },
  { name: "fonts-playfair", fonts: "playfair|arima" },
  { name: "fonts-oswald", fonts: "oswald|titillium" },
  { name: "fonts-lato", fonts: "spectral|lato" },
  { name: "fonts-abhaya", fonts: "abhayalibre|cardo" },
  { name: "fonts-pinyonscript", fonts: "pinyonscript|josefinslab" },
  { name: "fonts-montserrat", fonts: "montserrat|mavenpro" },
  { name: "fonts-medulaone", fonts: "medulaone|opensans" },
  { name: "fonts-lobster", fonts: "lobster|poppins" },
  { name: "fonts-josefinsans", fonts: "josefinsans|montserrat" },
  { name: "fonts-quattrocento", fonts: "quattrocento|palanquin" },
  { name: "fonts-kavoon", fonts: "kavoon|bellota" },
  { name: "fonts-happymonkey", fonts: "happymonkey|baloo2" },
  { name: "fonts-michroma", fonts: "michroma|raleway" },
  { name: "fonts-zendots", fonts: "zendots|khand" },
  { name: "fonts-zillaslab", fonts: "zillaslab|robotoslab" }
];

export enum HeroImageStyle {
  FIXED = "fixed",
  COVER = "cover"
}

export enum HeroImageAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right"
}

export enum HeroImageMaskPosition {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom"
}

export enum HeroImageCaretType {
  NONE = "none",
  WHITE_CIRCLE = "white-circle",
  BLACK_CIRCLE = "black-circle"
}

export const gridTypes = [
  {
    type: GRID_TYPE.MASONRY,
    value: "Masonry Portrait"
  },
  {
    type: GRID_TYPE.JUSTIFIED,
    value: "Masonry Landscape"
  },
  {
    type: GRID_TYPE.STACKED,
    value: "Stacked"
  },
  {
    type: GRID_TYPE.GRID,
    value: "Grid"
  }
];

export enum HeroCaretType {
  ACCENT_COLOR = "accent-circle",
  WHITE_CIRCLE = "white-circle",
  BLACK_CIRCLE = "black-circle"
}

export enum HeroNavigationType {
  WHITE = "white",
  BLACK = "black"
}

export enum HeroTransitionType {
  AUTOMATIC = "automatic",
  MANUAL = "manual"
}

export enum HeroSpeedType {
  SLOW = "slow",
  MEDIUM = "medium",
  FAST = "fast"
}

export enum HeroAnimationType {
  SLIDE = "slide",
  SCROLL = "scroll",
  CROSS_FADE = "cross-fade"
}

export enum SEND_TO_EMAIL {
  ACCOUNT = 1,
  CONTACT = 2
}

export enum NAVBAR_OPACITY_AMOUNT {
  TRANSPARENT = 0,
  LOW = 0.3,
  HIGH = 0.6
}

export enum DISPLAY_PAGE {
  BOTH = 0,
  HEADER = 1,
  FOOTER = 2
}
