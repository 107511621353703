import cx from "classnames";
import React from "react";
import styles from "./textOrPlaceholder.module.scss";

interface IProps {
  text?: string;
  placeholder: string;
}

const TextOrPlaceholder = (props: IProps) => {
  const classes = [styles.text];

  const hasText = !!props.text;

  if (!hasText) {
    classes.push(styles.placeholder);
  }

  return <span className={cx(classes)}>{hasText ? props.text : props.placeholder}</span>;
};

export default TextOrPlaceholder;
