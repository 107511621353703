import { formatErrorType } from "@zenfolio/core-components";
import jwtDecode from "jwt-decode";
import { ThunkDispatch } from "redux-thunk";
import { IApiServices } from "../../api";
import * as errorTypes from "../../api/errorTypes";
import { default as globalRoutes, IRoute } from "../../components/router/routes";
import { AuthenticationManager } from "../../utilities/authenticationManager";
import IError from "../../utilities/error";
import { setNeverActivatedWidgetBeforeSetting } from "../../utilities/helpers";
import { NavigationManager } from "../../utilities/navigationManager";
import NewRelic from "../../utilities/newRelic";
import { filterRoutes, getMainLayoutRoutes } from "../helpers";
import { doSetMenu } from "../menu/actions";
import { IAppState } from "../state";
import { SessionActionType } from "./constants";
import { IUserInfo } from "./model";

export interface ILoginStarted {
  type: SessionActionType.LOGIN_STARTED;
}

export interface ILoginSuccess {
  type: SessionActionType.LOGIN_SUCCESS;
  routes: IRoute[];
  token: string;
  userInfo: IUserInfo;
}

export interface ILoginError {
  type: SessionActionType.LOGIN_ERROR;
  error: IError;
}

export interface IUserInfoUpdated {
  type: SessionActionType.USER_INFO_UPDATED;
  routes: IRoute[];
  userInfo: IUserInfo;
}

export interface IUpdateIsStripeSetupComplete {
  type: SessionActionType.UPDATE_IS_STRIPE_SETUP_COMPLETE;
  isStripeSetupComplete: boolean | null;
}

export interface IUpdateIsReauthorizedRequired {
  type: SessionActionType.UPDATE_IS_REAUTHORIZED_REQUIRED;
  isReauthorizedRequired: boolean;
}

export interface IUpdatePendingBookingsCount {
  type: SessionActionType.UPDATE_PENDING_BOOKINGS_COUNT;
  pendingBookingsCount: number;
  atLeast: boolean;
}

export interface IDecrementPendingBookingsCount {
  type: SessionActionType.DECREMENT_PENDING_BOOKINGS_COUNT;
}

export type SessionAction =
  | ILoginStarted
  | ILoginSuccess
  | ILoginError
  | IUserInfoUpdated
  | IUpdateIsStripeSetupComplete
  | IUpdateIsReauthorizedRequired
  | IUpdatePendingBookingsCount
  | IDecrementPendingBookingsCount;

export const loginStarted = (): ILoginStarted => ({
  type: SessionActionType.LOGIN_STARTED
});

export const loginSuccess = (routes: IRoute[], token: string, userInfo: IUserInfo): ILoginSuccess => ({
  type: SessionActionType.LOGIN_SUCCESS,
  routes,
  token,
  userInfo
});

export const loginError = (error: IError): ILoginError => ({
  type: SessionActionType.LOGIN_ERROR,
  error
});

export const userInfoUpdated = (routes: IRoute[], userInfo: IUserInfo): IUserInfoUpdated => ({
  type: SessionActionType.USER_INFO_UPDATED,
  routes,
  userInfo
});

export const updateIsStripeSetupComplete = (isStripeSetupComplete: boolean | null) => ({
  type: SessionActionType.UPDATE_IS_STRIPE_SETUP_COMPLETE,
  isStripeSetupComplete
});

export const updateIsReauthorizedRequired = (isReauthorizedRequired: boolean) => ({
  type: SessionActionType.UPDATE_IS_REAUTHORIZED_REQUIRED,
  isReauthorizedRequired
});

export const updatePendingBookingsCount = (pendingBookingsCount: number, atLeast: boolean) => ({
  type: SessionActionType.UPDATE_PENDING_BOOKINGS_COUNT,
  pendingBookingsCount,
  atLeast
});

export const decrementPendingBookingsCount = () => ({
  type: SessionActionType.DECREMENT_PENDING_BOOKINGS_COUNT
});

export const loginFromIframe = () => {
  return async (dispatch: ThunkDispatch<any, any, any>, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loginStarted());

    try {
      if (await NavigationManager.instance.init()) {
        const token = NavigationManager.instance.token!;
        const decodedToken: any = jwtDecode(NavigationManager.instance.token!);
        const id = AuthenticationManager.getUserId(decodedToken);
        const scopes = extractScopes(decodedToken.scope);

        NewRelic.refreshUserId(id);

        if (scopes.photographer) {
          const state = await apiService.session.loadState();
          const userInfo = { ...state, id, scopes, showCongratulations: false };
          const filteredRoutes = filterRoutes(globalRoutes, userInfo);
          const menuItems = getMainLayoutRoutes(filteredRoutes);
          setNeverActivatedWidgetBeforeSetting(id, !userInfo.welcomeClosed);
          dispatch(doSetMenu(menuItems));
          dispatch(loginSuccess(filteredRoutes, token, userInfo));
          return;
        }
      }

      dispatch(loginError({ type: formatErrorType(errorTypes.userNotAuthenticated) }));
    } catch (error) {
      dispatch(loginError(error));
    }
  };
};

export const updateUserInfo = (userInfo: IUserInfo) => {
  return (dispatch: ThunkDispatch<any, any, any>, getState: () => IAppState, apiService: IApiServices) => {
    const filteredRoutes = filterRoutes(globalRoutes, userInfo);
    const menuItems = getMainLayoutRoutes(filteredRoutes);
    dispatch(doSetMenu(menuItems));
    dispatch(userInfoUpdated(filteredRoutes, userInfo));
  };
};

export function extractScopes(scopesData: string) {
  const scopesList = scopesData.split(" ").map((scopeItem: string) => {
    return scopeItem.split(":")[1];
  });

  const scopes: { [name: string]: boolean } = scopesList.reduce((acc: { [name: string]: boolean }, val: string) => {
    acc[val] = true;
    return acc;
  }, {});

  return scopes;
}
