export default interface IIConProps {
  width?: string | number;
  height?: string | number;
  size?: string | number;
  minX?: string | number;
  minY?: string | number;
  color?: string;
  fill?: string;
  opacity?: string;
  stroke?: string;
  strokeWidth?: string;
  disableTransform?: boolean;
}

export const initializeIconProps = (props: IIConProps) => ({
  ...props,
  minX: props.minX || 0,
  minY: props.minY || 0,
  width: props.width || props.size,
  height: props.height || props.size,
  stroke: props.stroke || props.color
});
