export enum ActionType {
  SETUP_CONNECT_STARTED = "PROFILE_GOOGLE_CALENDAR_SETUP_CONNECT_STARTED",
  SETUP_CONNECT_SUCCESS = "PROFILE_GOOGLE_CALENDAR_SETUP_CONNECT_SUCCESS",
  SETUP_CONNECT_ERROR = "PROFILE_GOOGLE_CALENDAR_SETUP_CONNECT_ERROR",
  LOAD_CALENDARS_STARTED = "PROFILE_GOOGLE_CALENDAR_LOAD_CALENDARS_STARTED",
  LOAD_CALENDARS_SUCCESS = "PROFILE_GOOGLE_CALENDAR_LOAD_CALENDARS_SUCCESS",
  LOAD_CALENDARS_ERROR = "PROFILE_GOOGLE_CALENDAR_LOAD_CALENDARS_ERROR",
  LOAD_CALENDARS_RESET = "PROFILE_GOOGLE_CALENDAR_LOAD_CALENDARS_RESET",
  DISCONNECT_STARTED = "PROFILE_GOOGLE_CALENDAR_DISCONNECT_STARTED",
  DISCONNECT_SUCCESS = "PROFILE_GOOGLE_CALENDAR_DISCONNECT_SUCCESS",
  DISCONNECT_ERROR = "PROFILE_GOOGLE_CALENDAR_DISCONNECT_ERROR"
}
