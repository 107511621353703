import { Loading } from "@zenfolio/core-components";
import * as React from "react";

export interface ILoaderProps {}

const Loader = (props: { styles?: React.CSSProperties }) => {
  return <Loading styles={props.styles} />;
};

export default Loader;
