import React, { useContext } from "react";
import styles from "./zenAnimatedComponent.module.scss";
import { PageContext } from "utilities/pageContext";
import ScrollAnimation from "components/hoc/ScrollAnimation";

export interface IZenAnimatedComponentProps {
  animationScrollIn?: string;
  animationScrollOut?: string;
  readOnly?: boolean;
  children?: React.ReactNode;
}

const ZenAnimatedComponent: React.FC<IZenAnimatedComponentProps> = props => {
  const pageContext = useContext(PageContext);
  if (props.readOnly && props.animationScrollIn === "Fade In") {
    return (
      <ScrollAnimation
        animateIn={styles.fadeIn}
        scrollableParentSelector="body"
        fadeInAndOut={props.animationScrollOut === "Fade Out"}
        isPreview={pageContext.isPreview}
      >
        {props.children}
      </ScrollAnimation>
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default ZenAnimatedComponent;
