import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IRefundOwnProps, IRefundProps } from ".";
import { refund as refundAction } from "../../../../../store/billing/actions";
import { ActivityTypeCode } from "../../../../../store/billing/model";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ billing: { refund } }: IAppState) {
  return { refund };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    refund: {
      onRefund: (activityId: string, activityType: ActivityTypeCode, amount: number) =>
        dispatch(refundAction(activityId, activityType, amount))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: IRefundOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    refund: { ...stateProps.refund, ...dispatchProps.refund }
  } as IRefundProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
