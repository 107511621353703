import React from "react";
import IIConProps from "../IIconProps";

const MoreIcon = (props: IIConProps) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`${props.minX || 0} ${props.minY || 0} ${props.width} ${props.height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm7 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm7 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        fill={props.fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default MoreIcon;
