import React from "react";

const Arrow = (props: { className?: string }) => {
  return (
    <svg width="10" height="11" xmlns="http://www.w3.org/2000/svg" className={props.className}>
      <path d="m5 8.107-5-6h10z" fill="#757575" fillRule="evenodd" />
    </svg>
  );
};

export default Arrow;
