import React from "react";
import IIConProps from "components/Icons/IIconProps";
import { getRadius } from "components/Icons/utils";

const DownloadMedia = ({ minX = 0, minY = 0, ...props }: IIConProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox={`${minX} ${minY} ${props.size} ${props.size}`}
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx={getRadius(props.size)}
        cy={getRadius(props.size)}
        r={getRadius(props.size)}
        fill="#000"
        opacity=".8"
      />
      <g stroke={props.color} strokeLinecap="round" strokeWidth="2">
        <path
          strokeLinejoin="round"
          d="M12 18h4c1.333 0 2-.667 2-2V2c0-1.333-.667-2-2-2h-4"
          transform="rotate(90 9.5 15.5)"
        />
        <path d="M13 9L0.057 9" transform="rotate(90 9.5 15.5)" />
        <path strokeLinejoin="round" d="M10 13L14 9 10 5" transform="rotate(90 9.5 15.5)" />
      </g>
    </g>
  </svg>
);

export default DownloadMedia;
