import React from "react";
import HoursMinutesInput, { IHoursMinutesInputProps } from "../HoursMinutesInput";

export interface IDurationInputProps extends Omit<IHoursMinutesInputProps, "mode"> {}

class DurationInput extends React.Component<IDurationInputProps> {
  public render() {
    return <HoursMinutesInput {...this.props} mode="duration" />;
  }
}

export default DurationInput;
