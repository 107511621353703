import React from "react";
import IIConProps from "../IIconProps";

const ViewsIcon = (props: IIConProps) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`${props.minX || 0} ${props.minY || 0} ${props.width} ${props.height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M28.667 16c-8 9.333-16 9.333-24 0 8-9.333 16-9.333 24 0z"
          stroke={props.color || "currentColor"}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <g transform="translate(9.667 9)">
          <circle stroke={props.color || "currentColor"} strokeWidth="1.5" cx="7" cy="7" r="7" />
          <path
            d="M7 4a3 3 0 1 1-2.867 2.115 1.5 1.5 0 1 0 1.983-1.983C6.395 4.046 6.692 4 7 4z"
            fill={props.color || "currentColor"}
          />
        </g>
      </g>
    </svg>
  );
};

export default ViewsIcon;
