import cx from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { StrictOmit } from "types";
import { IComponentBaseProps, IElementProps } from "../../../models/props";
import { Utilities } from "../../../utilities/utilities";
import DatePicker, { IDatePickerProps } from "../DatePicker";
import DefaultDatePickerCalendarContainer from "../DefaultDatePickerCalendarContainer";
import DefaultDatePickerCalendarDays from "../DefaultDatePickerCalendarDays";
import DefaultDatePickerCalendarHeader from "../DefaultDatePickerCalendarHeader";
import DefaultDatePickerCalendarMonths from "../DefaultDatePickerCalendarMonths";
import DefaultDatePickerCalendarYears from "../DefaultDatePickerCalendarYears";
import DefaultDatePickerInput, { Size as InputSize } from "../DefaultDatePickerInput";
import { IDatePickerCalendarContainerProps, IDatePickerInputProps, getMonthDates } from "../models";
import styles from "./defaultDatePicker.module.scss";

const { shouldComponentUpdateDeep } = Utilities;

export type CalendarPosition = "left" | "center" | "right";

interface IDefaultDatePickerProps extends StrictOmit<IDatePickerProps, "components" | "componentsProps"> {
  size?: InputSize;
  calendarPosition?: CalendarPosition;
  elements?: {
    input?: IElementProps<HTMLDivElement> & {
      value?: IElementProps<HTMLDivElement>;
      clear?: IElementProps<HTMLButtonElement>;
      icon?: IElementProps<HTMLButtonElement>;
    };
    calendar?: IElementProps<HTMLDivElement>;
  };
}

class DefaultDatePicker extends React.Component<IDefaultDatePickerProps> {
  public static getDisplayedDates(date: moment.Moment) {
    return getMonthDates(date, false);
  }

  public shouldComponentUpdate(nextProps: IDefaultDatePickerProps): boolean {
    return shouldComponentUpdateDeep(this, nextProps);
  }

  public componentWillUnmount() {
    Utilities.combineRefsCached.cache.delete(this.containerRef);
  }

  public render() {
    const { size, calendarPosition, elements, ...baseProps } = this.props;

    function getComponentPropsFromElementProps<T>(
      element: IElementProps<T> | undefined
    ): IComponentBaseProps<T> | undefined {
      return element ? { className: element.className, style: element.style, containerRef: element.ref } : undefined;
    }

    return (
      <DatePicker
        {...baseProps}
        className={cx(styles.container, baseProps.className)}
        containerRef={Utilities.combineRefsCached(this.containerRef)(baseProps.containerRef)}
        components={{
          input: this.renderInput,
          calendar: {
            container: this.renderCalendarContainer,
            header: DefaultDatePickerCalendarHeader,
            days: DefaultDatePickerCalendarDays,
            years: DefaultDatePickerCalendarYears,
            months: DefaultDatePickerCalendarMonths
          }
        }}
        componentsProps={{
          input: getComponentPropsFromElementProps(this.props.elements?.input),
          calendar: {
            container: getComponentPropsFromElementProps(this.props.elements?.calendar),
            years: { className: styles.years },
            months: { className: styles.months }
          }
        }}
      />
    );
  }

  private renderInput = (props: IDatePickerInputProps) => {
    const { size, elements } = this.props;
    return <DefaultDatePickerInput {...props} size={size} elements={elements?.input} />;
  };

  private renderCalendarContainer = (props: IDatePickerCalendarContainerProps) => {
    const { className, onClose, ...otherProps } = props;
    const { calendarPosition } = this.props;
    const { containerRef } = this;

    useEffect(onUpdate);

    function onUpdate() {
      document.addEventListener("mousedown", onDocumentMouseDown);

      return function cleanup() {
        document.removeEventListener("mousedown", onDocumentMouseDown);
      };
    }

    function onDocumentMouseDown(event: MouseEvent) {
      if (event.button === 0 && props.open && !containerRef.current?.contains(event.target as Element)) {
        onClose?.();
      }
    }

    return (
      <DefaultDatePickerCalendarContainer
        {...otherProps}
        className={cx(props.className, styles.calendar, styles[calendarPosition || "right"])}
      />
    );
  };

  private readonly containerRef = React.createRef<HTMLDivElement>();
}

export default DefaultDatePicker;
