import React, { FC } from "react";

interface CaptionProps {
  name: string;
  caption?: string;
  className?: string;
};

const Caption: FC<CaptionProps> = ({name, caption, className = ""}) => {
  return (
    <div className={className}>
      <strong>{name}</strong>
      <p>{caption}</p>
    </div>
  );
};

export default Caption;
