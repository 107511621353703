import _ from "lodash";
import IError from "../../utilities/error";
import { ActionStatus } from "../common";
import {
  CalculateServiceTaxAction,
  CreateBookingAction,
  LoadAvailabilityAction,
  LoadInvoiceStateAction,
  LoadWidgetStateAction,
  PayInvoiceAction,
  ValidateLocationAction
} from "./actions";
import { WidgetActionType } from "./constants";
import { IAvailability, ICalculateServiceTaxQuery, IInvoiceInfo, ITaxInfo, IWidgetInfo } from "./model";

interface ILoadWidgetState {
  status: ActionStatus;
  error: IError | null;
}

const initialLoadWidgetState: ILoadWidgetState = {
  status: "Init",
  error: null
};

export interface IValidateLocationState {
  status: ActionStatus;
  error: IError | null;
}

const initialValidateLocationState: IValidateLocationState = {
  status: "Init",
  error: null
};

interface ILoadAvailabilityState {
  status: ActionStatus;
  serviceId: string | null;
  availability: IAvailability | null;
  error: IError | null;
}

const initialLoadAvailabilityState: ILoadAvailabilityState = {
  status: "Init",
  serviceId: null,
  availability: null,
  error: null
};

export interface ICreateBookingState {
  status: ActionStatus;
  error: IError | null;
  paymentFailureCode: string | null;
  bookingId: string | null;
  transactionId: string | null;
  initialTaxAmount?: number;
  totalTaxAmount?: number;
}

const initialCreateBookingState: ICreateBookingState = {
  status: "Init",
  error: null,
  paymentFailureCode: null,
  bookingId: null,
  transactionId: null
};

export interface ILoadInvoiceState {
  status: ActionStatus;
  error: IError | null;
}

const initialLoadInvoiceState: ILoadInvoiceState = {
  status: "Init",
  error: null
};

export interface IPayInvoiceState {
  status: ActionStatus;
  error: IError | null;
  paymentFailureCode: string | null;
  transactionId: string | null;
}

const initialPayInvoiceState: IPayInvoiceState = {
  status: "Init",
  error: null,
  paymentFailureCode: null,
  transactionId: null
};

export interface ICalculateServiceTaxState {
  status: ActionStatus;
  error: IError | null;
  query: ICalculateServiceTaxQuery | null;
  initialTax: ITaxInfo | null;
  totalTax: ITaxInfo | null;
}

const initialCalculateServiceTaxState: ICalculateServiceTaxState = {
  status: "Init",
  error: null,
  query: null,
  initialTax: null,
  totalTax: null
};

export interface IWidgetState {
  stripe: stripe.Stripe | null;
  stripeFactory: stripe.StripeStatic | null;
  stripeAccountId: string;
  loadWidgetInfo: IWidgetInfo;
  loadWidgetInfoState: ILoadWidgetState;
  locationIsValid: boolean;
  validateLocation: IValidateLocationState;
  loadAvailability: ILoadAvailabilityState;
  createBookingState: ICreateBookingState;
  loadInvoiceInfo: IInvoiceInfo | null;
  loadInvoiceInfoState: ILoadInvoiceState;
  payInvoiceState: IPayInvoiceState;
  calculateServiceTaxState: ICalculateServiceTaxState;
}

const initialWidgetState: IWidgetState = {
  stripe: null,
  stripeFactory: null,
  stripeAccountId: "",
  loadWidgetInfo: {
    primaryShootLocation: {
      zipCode: "",
      country: "",
      state: "",
      city: "",
      streetName: "",
      houseNumber: "",
      locationDetails: "",
      latitude: 0,
      longitude: 0
    },
    services: [],
    photographerName: "",
    photographerTimezoneOffsetMinutes: 0,
    photographerBusinessName: "",
    stripeAccountId: ""
  },
  loadWidgetInfoState: initialLoadWidgetState,
  locationIsValid: false,
  validateLocation: initialValidateLocationState,
  loadAvailability: initialLoadAvailabilityState,
  createBookingState: initialCreateBookingState,
  loadInvoiceInfo: null,
  loadInvoiceInfoState: initialLoadInvoiceState,
  payInvoiceState: initialPayInvoiceState,
  calculateServiceTaxState: initialCalculateServiceTaxState
};

export const widget = (
  state: IWidgetState = initialWidgetState,
  action:
    | LoadWidgetStateAction
    | ValidateLocationAction
    | LoadAvailabilityAction
    | CreateBookingAction
    | LoadInvoiceStateAction
    | PayInvoiceAction
    | CalculateServiceTaxAction
): IWidgetState => {
  switch (action.type) {
    case WidgetActionType.LOAD_WIDGET_STATE_STARTED:
      return {
        ...state,
        loadWidgetInfoState: {
          status: "Pending",
          error: null
        }
      };
    case WidgetActionType.LOAD_WIDGET_STATE_SUCCESS:
      return {
        ...state,
        loadWidgetInfoState: {
          status: "Success",
          error: null
        },
        loadWidgetInfo: action.info
      };
    case WidgetActionType.LOAD_WIDGET_STATE_ERROR:
      return {
        ...state,
        loadWidgetInfoState: {
          status: "Error",
          error: action.error
        }
      };

    case WidgetActionType.RESET_WIDGET_STATE:
      return {
        ...initialWidgetState,
        stripe: state.stripe,
        stripeFactory: state.stripeFactory,
        stripeAccountId: state.stripeAccountId
      };

    case WidgetActionType.LOAD_INVOICE_STATE_STARTED:
      return {
        ...state,
        loadInvoiceInfoState: {
          status: "Pending",
          error: null
        }
      };
    case WidgetActionType.LOAD_INVOICE_STATE_SUCCESS:
      return {
        ...state,
        loadInvoiceInfoState: {
          status: "Success",
          error: null
        },
        loadInvoiceInfo: action.info
      };
    case WidgetActionType.LOAD_INVOICE_STATE_ERROR:
      return {
        ...state,
        loadInvoiceInfoState: {
          status: "Error",
          error: action.error
        }
      };
    case WidgetActionType.VALIDATE_LOCATION_STARTED:
      return {
        ...state,
        locationIsValid: false,
        validateLocation: {
          status: "Pending",
          error: null
        }
      };
    case WidgetActionType.VALIDATE_LOCATION_SUCCESS:
      return {
        ...state,
        validateLocation: {
          status: "Success",
          error: null
        },
        locationIsValid: action.isValid
      };
    case WidgetActionType.VALIDATE_LOCATION_ERROR:
      return {
        ...state,
        validateLocation: {
          status: "Error",
          error: action.error
        }
      };

    case WidgetActionType.LOAD_AVAILABILITY_STARTED:
      return {
        ...state,
        loadAvailability: {
          status: "Pending",
          serviceId: action.serviceId,
          availability: null,
          error: null
        }
      };
    case WidgetActionType.LOAD_AVAILABILITY_SUCCESS:
      return state.loadAvailability.serviceId === action.serviceId
        ? {
            ...state,
            loadAvailability: {
              ...state.loadAvailability,
              status: "Success",
              availability: action.availability,
              error: null
            }
          }
        : state;
    case WidgetActionType.LOAD_AVAILABILITY_ERROR:
      return state.loadAvailability.serviceId === action.serviceId
        ? {
            ...state,
            loadAvailability: {
              ...state.loadAvailability,
              status: "Error",
              availability: null,
              error: action.error
            }
          }
        : state;

    case WidgetActionType.CREATE_BOOKING_STARTED:
      return {
        ...state,
        createBookingState: {
          status: "Pending",
          error: null,
          paymentFailureCode: null,
          bookingId: null,
          transactionId: null,
          initialTaxAmount: undefined,
          totalTaxAmount: undefined
        }
      };
    case WidgetActionType.CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        createBookingState: {
          status: "Success",
          error: null,
          paymentFailureCode: null,
          bookingId: action.bookingId,
          transactionId: action.transactionId || null,
          initialTaxAmount: action.initialTaxAmount,
          totalTaxAmount: action.totalTaxAmount
        }
      };
    case WidgetActionType.CREATE_BOOKING_ERROR:
      return {
        ...state,
        createBookingState: {
          status: "Error",
          error: action.error,
          paymentFailureCode: null,
          bookingId: null,
          transactionId: null,
          initialTaxAmount: undefined,
          totalTaxAmount: undefined
        }
      };
    case WidgetActionType.RESET_BOOKING_ERROR:
      return {
        ...state,
        createBookingState: {
          status: "Init",
          error: null,
          paymentFailureCode: action.failureCode,
          bookingId: null,
          transactionId: null,
          initialTaxAmount: 0,
          totalTaxAmount: 0
        },
        payInvoiceState: {
          status: "Init",
          error: null,
          paymentFailureCode: action.failureCode,
          transactionId: null
        }
      };

    case WidgetActionType.PAY_INVOICE_STARTED:
      return {
        ...state,
        payInvoiceState: {
          status: "Pending",
          error: null,
          paymentFailureCode: null,
          transactionId: null
        }
      };
    case WidgetActionType.PAY_INVOICE_SUCCESS:
      return {
        ...state,
        payInvoiceState: {
          status: "Success",
          error: null,
          paymentFailureCode: null,
          transactionId: action.transactionId
        }
      };
    case WidgetActionType.PAY_INVOICE_ERROR:
      return {
        ...state,
        payInvoiceState: {
          status: "Error",
          error: action.error,
          paymentFailureCode: null,
          transactionId: null
        }
      };

    case WidgetActionType.STRIPE_FACTORY_READY:
      return initStripe(state, action.stripeFactory, state.stripeAccountId);
    case WidgetActionType.STRIPE_ACCOUNT_ID_READY:
      return initStripe(state, state.stripeFactory, action.stripeAccountId);

    case WidgetActionType.CALCULATE_SERVICE_TAX_STARTED:
      return {
        ...state,
        calculateServiceTaxState: {
          ...state.calculateServiceTaxState,
          status: "Pending",
          query: action.query
        }
      };
    case WidgetActionType.CALCULATE_SERVICE_TAX_SUCCESS:
      return state.calculateServiceTaxState.status === "Pending" &&
        _.isEqual(state.calculateServiceTaxState.query, action.query)
        ? {
            ...state,
            calculateServiceTaxState: {
              ...state.calculateServiceTaxState,
              status: "Success",
              error: null,
              initialTax: action.initialTax,
              totalTax: action.totalTax
            }
          }
        : state;
    case WidgetActionType.CALCULATE_SERVICE_TAX_ERROR:
      return state.calculateServiceTaxState.status === "Pending" &&
        _.isEqual(state.calculateServiceTaxState.query, action.query)
        ? {
            ...state,
            calculateServiceTaxState: {
              ...state.calculateServiceTaxState,
              status: "Error",
              error: action.error
            }
          }
        : state;

    default:
      return state;
  }
};

function initStripe(state: IWidgetState, stripeFactory: stripe.StripeStatic | null, stripeAccountId: string) {
  return {
    ...state,
    stripeFactory,
    stripeAccountId,
    stripe:
      stripeFactory && stripeAccountId && (!state.stripe || state.stripeAccountId !== stripeAccountId)
        ? stripeFactory(process.env.REACT_APP_STRIPE_KEY!, {
            stripeAccount: stripeAccountId,
            locale: "en"
          })
        : state.stripe
  };
}

export default widget;
