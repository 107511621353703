import React, { useContext, useEffect, useState } from "react";
import { IVideoCoverImageData, IVideoProperty, VideoType } from "../blockModels";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks";
import styles from "./zenHeroVideo.module.scss";
import cx from "classnames";
import ContextMenu from "utilities/contextMenu";
import playIcon from "../../../icons/btn-play-video.svg";
import videoErrorImg from "../../../icons/video-unavailable.svg";
import { getVideoFromUrl } from "utilities/video";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";
import { getSiteFonts } from "utilities/blocks/site";
import colors from "utilities/colors";
import CaretDown from "../../Icons/CaretDown";
import { executeHeroScroll } from "utilities/blocks/blockInteractions";
import { IVideoPlayer } from "../../Video/Player";
import { GalleryVideo } from "./GalleryVideo";
import { ScreenSizeBreakPoints } from "utilities/screenResolution";
import ReactDOM from "react-dom";
import Lightbox from "../../VideoPreview/Lightbox";
import {
  HeroCaretType,
  HeroImageAlignment,
  HeroImageCaretType,
  HeroImageStyle,
  LinkTypeOption
} from "utilities/constant";
import { PageContext } from "utilities/pageContext";
import { parseBody } from "utilities/blocks/string";

import "./external-styles.scss";

export interface HeroVideoBlock extends IZenBaseBlockProps {
  readOnly?: boolean;
  isEdit?: boolean;
  title?: string;
  caption?: string;
  alignment: HeroImageAlignment;
  caret?: HeroCaretType | HeroImageCaretType;
  verticalAlign?: string;
  isPublish?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  audio?: boolean;
  videoCoverImage?: IVideoCoverImageData;
  cover?: boolean;
  tint?: boolean;
  medialVideo?: MediaVideo;
  galleryVideo?: IVideoProperty;
  isSourceGallery?: boolean;
  linkType: LinkTypeOption;
  styleMode: HeroImageStyle;
  isShowPanel?: boolean;
  useLightbox?: boolean;
  globalKeyboardControls?: boolean;
}

export type MediaVideo = {
  videoUrl: string;
  defaultVideoUrl: string;
};
const isServer = typeof window === "undefined";
export const ZenHeroVideoBlock = (props: HeroVideoBlock) => {
  const {
    siteTheme,
    readOnly,
    desktopEnabled,
    tabletEnabled,
    mobileEnabled,
    fontFamily,
    animationScrollIn,
    animationScrollOut,
    selectedBlock,
    videoCoverImage,
    cover,
    medialVideo,
    autoplay,
    loop,
    audio,
    isEdit,
    alignment,
    verticalAlign,
    styleMode,
    caret,
    linkType,
    galleryVideo,
    tint,
    isShowPanel,
    useLightbox
  } = props;
  const title = parseBody(props.title || "");
  const caption = parseBody(props.caption || "");
  const [showLightbox, setShowLightbox] = useState<boolean>(false);
  const [playAction, setPlayAction] = useState<boolean>(false);
  const [videoError, setVideoError] = useState<any>(null);
  const [blockHeight, setBlockHeight] = useState<string>("auto");
  const [isShowTitle, setIsShowTitle] = useState<boolean>(true);
  const context = useContext(PageContext);
  const [videoContainerWidth, setVideoContainerWidth] = useState<number>(10);
  const containerRef = React.createRef<HTMLDivElement>();
  const urlVideoRef = React.createRef<HTMLDivElement>();
  const galleryVideoRef = React.createRef<IVideoPlayer>();
  const youtubeRef = React.createRef<YouTube>();
  const vimeoRef = React.createRef<Vimeo>();

  const video = medialVideo
    ? getVideoFromUrl(medialVideo.videoUrl) || getVideoFromUrl(medialVideo.defaultVideoUrl)
    : null;

  const isShowCoverImage = videoCoverImage && cover && !playAction;
  const siteFonts = getSiteFonts(siteTheme.fontsStyle);
  const imageStyleClass = cx(styleMode === HeroImageStyle.FIXED ? styles.imageFixed : styles.imageCover, { [styles.vimeoContainer]: video?.type === VideoType.Vimeo });
  const isLinkTypeAsGallery = linkType === LinkTypeOption.GALLERY;

  useEffect(() => {
    window.addEventListener("resize", changeOrientation);
    window.addEventListener("orientationchange", changeOrientation);
    determineVisibility();
    changeOrientation();
    return () => {
      window.removeEventListener("resize", changeOrientation);
      window.removeEventListener("orientationchange", changeOrientation);
    };
  }, []);

  useEffect(() => {
    if (isEdit) {
      setPlayAction(false);
      setIsShowTitle(true);
    }
  }, [videoCoverImage, cover]);

  const changeOrientation = () => {
    updateWindowDimensions();
    updateControlHeight();
  };

  const updateControlHeight = () => {
    const controlHeightEditor = "100vh";
    let controlHeightPublish = "";
    if (!isServer) {
      controlHeightPublish = `${window.innerHeight - context.navBarHeight}px`;
    } else {
      controlHeightPublish = controlHeightEditor;
    }
    const blockHeight = isEdit ? controlHeightEditor : controlHeightPublish;
    setBlockHeight(blockHeight);
  };

  const determineVisibility = () => {
    const { desktopEnabled, tabletEnabled, mobileEnabled } = props;
    if (window.innerWidth <= ScreenSizeBreakPoints.Mobile && mobileEnabled) {
      context.setHasHero();
    } else if (
      window.innerWidth > ScreenSizeBreakPoints.Mobile &&
      window.innerWidth <= ScreenSizeBreakPoints.Tablet &&
      tabletEnabled
    ) {
      context.setHasHero();
    } else if (desktopEnabled) {
      context.setHasHero();
    }
  };

  const updateWindowDimensions = () => setVideoContainerWidth(urlVideoRef?.current?.offsetWidth || 10);

  const handleYoutubePlay = () => {
    setPlayAction(true);
  };

  const handleVideoPause = () => setPlayAction(false);

  const handleYoutubeReady = (e: any) => {
    if (!audio) {
      e.target.setVolume(0);
    }
  };
  const handleYoutubeEnd = (e: any) => {
    if (loop) {
      e.target.playVideo();
    } else {
      e.target.playVideo();
      e.target.pauseVideo();
      setPlayAction(false);
    }
  };
  const handleVideoError = (e: any) => {
    setVideoError(e);
  };
  const handleVideoPlay = (e: any) => {
    if (useLightbox) {
      const galleryPlayer = galleryVideoRef?.current;
      galleryPlayer?.pause();

      setShowLightbox(true);

      return;
    }

    if (isEdit) {
      return;
    }

    setPlayAction(true);
  };

  const handleLightboxClose = () => {
    setShowLightbox(false);

    const galleryPlayer = galleryVideoRef?.current;

    galleryPlayer?.play();
  };

  const handleVimeoEnd = (e: any) => {
    setPlayAction(false);
  };

  const handleCaretClick = () => {
    if (containerRef.current) {
      executeHeroScroll(containerRef.current.clientHeight);
    }
  };

  const onClickTitle = () => {
    const youtubePlayer = youtubeRef.current?.getInternalPlayer;
    const vimeoPlayer = vimeoRef.current?.props;
    const galleryPlayer = galleryVideoRef?.current;

    if (useLightbox) {
      galleryPlayer?.pause();

      setShowLightbox(true);

      return;
    }

    if (youtubePlayer || vimeoPlayer || galleryPlayer) {
      setPlayAction(true);
      setIsShowTitle(false);
      if (youtubePlayer) {
        const { playVideo } = youtubePlayer();
        playVideo();
      } else if (galleryPlayer) {
        galleryPlayer.play();
      }
    }
  };

  const getCaretColor = (caret: HeroCaretType | HeroImageCaretType) => {
    switch (caret) {
      case HeroCaretType.BLACK_CIRCLE:
        return colors.black;
      case HeroCaretType.WHITE_CIRCLE:
        return colors.white;
      default:
        return siteTheme.accentColor.value;
    }
  };

  const renderCaret = () => {
    if (caret && styleMode !== HeroImageStyle.FIXED) {
      const caretColor = getCaretColor(caret);
      return (
        <div className={styles.caretContainer}>
          <span className={styles.circle} onClick={handleCaretClick}>
            <CaretDown color={caretColor} size={42} />
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderPlayButton = () => {
    return (
      <div className={cx(styles.overlay)} onClick={handleVideoPlay}>
        <img src={playIcon} width="96px" height="96px" />
      </div>
    );
  };

  const renderIntroCover = () => {
    const alignmentClass = styles[`${alignment}Align`];
    const verticalClass = styles[`${verticalAlign}Vertical`];
    return (
      <div
        className={cx(styles.textContainer, imageStyleClass, verticalClass, alignmentClass, styles[siteFonts.primary])}
      >
        <div className={cx(styles.title, styles[siteFonts.primary], { [styles.titleClickable]: isEdit })}>{title}</div>
        <div
          className={cx(styles.caption, styles[siteFonts.secondary], {
            [styles.captionClickable]: isEdit
          })}
        >
          {caption}
        </div>
        {renderPlayButton()}
      </div>
    );
  };

  const renderTitleWithOutCover = () => {
    const alignmentClass = styles[`${alignment}Align`];
    const verticalClass = styles[`${verticalAlign}Vertical`];
    return (
      <div
        className={cx(styles.intro, imageStyleClass, verticalClass, alignmentClass, styles[siteFonts.primary])}
        onClick={onClickTitle}
      >
        <div className={cx(styles.title, styles[siteFonts.primary], { [styles.titleClickable]: isEdit })}>{title}</div>
        <div
          className={cx(styles.caption, styles[siteFonts.secondary], {
            [styles.captionClickable]: isEdit
          })}
        >
          {caption}
        </div>
        {isLinkTypeAsGallery && (!autoplay || useLightbox) && renderPlayButton()}
      </div>
    );
  };

  const renderCoverVideoImage = () => {
    const newFocalX = videoCoverImage!.focalX ?? 50;
    const newFocalY = videoCoverImage!.focalY ?? 50;

    return (
      <div className={styles.coverImageParent}>
        <div
          className={cx(styles.coverVideo, imageStyleClass)}
          onContextMenu={ContextMenu.handleBlockContextMenu}
          style={{
            backgroundImage: "url(" + videoCoverImage!.url + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: newFocalX + "% " + newFocalY + "%",
            backgroundSize: "cover",
            alignItems: "flex-end",
            opacity: videoCoverImage?.isFallback ? 0.4 : 1,
            backgroundColor: `rgba(0,0,0,${tint ? "0.3" : "0"})`
          }}
        >
          {renderIntroCover()}
        </div>
        {renderCaret()}
      </div>
    );
  };

  const renderUrlVideoBlock = () => {
    if (video) {
      switch (video?.type) {
        case VideoType.Youtube:
          return (
            <div className={styles.mediaVideo}>
              {isEdit && isShowPanel && <div className={cx(styles.youtube, imageStyleClass, styles.outer)} />}
              <YouTube
                ref={youtubeRef}
                videoId={video.id}
                className={cx(styles.youtube, imageStyleClass)}
                opts={{
                  width: "100%",
                  playerVars: {
                    autoplay: autoplay || cover ? 1 : 0,
                    mute: !audio ? 1 : 0,
                    loop: loop ? 1 : 0
                  }
                }}
                onPlay={handleYoutubePlay}
                onReady={handleYoutubeReady}
                onEnd={handleYoutubeEnd}
                onError={handleVideoError}
                onPause={handleVideoPause}
              />
            </div>
          );
        case VideoType.Vimeo:
          return (
            <div className={styles.mediaVideo}>
              {isEdit && isShowPanel && <div className={cx(styles.vimeo, imageStyleClass, styles.outer)} />}
              <Vimeo
                ref={vimeoRef}
                video={video.id}
                className={cx(styles.vimeo, imageStyleClass)}
                responsive
                height={blockHeight}
                width={videoContainerWidth}
                autoplay={autoplay || cover ? true : false}
                loop={loop ? true : false}
                volume={!audio ? 0 : 1}
                onError={handleVideoError}
                onEnd={handleVimeoEnd}
                onPlay={handleYoutubePlay}
                onPause={handleVideoPause}
              />
            </div>
          );

        default:
          return <div />;
      }
    } else {
      return (
        <div className={styles.errorContainer} ref={urlVideoRef}>
          <img src={videoErrorImg} />
        </div>
      );
    }
  };

  const renderGalleryVideoBlock = () => {
    return galleryVideo ? (
      <>
        {useLightbox &&
          !isEdit &&
          showLightbox &&
          ReactDOM.createPortal(
            <Lightbox
              thumbnailUrlTemplate={galleryVideo.thumbnailUrlTemplate}
              videoUrlTemplate={galleryVideo.videoUrlTemplate}
              video={galleryVideo.detail}
              onClose={handleLightboxClose}
            />,
            document.body
          )}
        <GalleryVideo
          {...galleryVideo}
          globalKeyboardControls={props.globalKeyboardControls}
          styleMode={styleMode}
          ref={galleryVideoRef}
          playable={false}
          autoplay={autoplay || cover ? true : false}
          loop={loop ? true : false}
          defaultVolume={!audio ? 0 : 1}
        />
      </>
    ) : null;
  };

  const renderVideo = () => {
    return isLinkTypeAsGallery ? renderGalleryVideoBlock() : renderUrlVideoBlock();
  };

  const renderContent = () => {
    return (
      <div className={styles.zenContainer} ref={containerRef}>
        <style>
          {`
            :root {
              --accent-color: ${siteTheme.accentColor.value};
              --coverSize: ${blockHeight};
              }
            `}
        </style>
        <div
          className={cx(styles.picture, imageStyleClass, { [styles.error]: videoError })}
          onContextMenu={ContextMenu.handleBlockContextMenu}
        >
          {isShowCoverImage ? renderCoverVideoImage() : renderVideo()}
          {!isShowCoverImage && !playAction && renderCaret()}
          {!cover && (isShowTitle || isEdit) && renderTitleWithOutCover()}
        </div>
      </div>
    );
  };

  return (
    <ZenBaseBlock
      siteTheme={siteTheme}
      desktopEnabled={desktopEnabled}
      tabletEnabled={tabletEnabled}
      mobileEnabled={mobileEnabled}
      readOnly={readOnly}
      fontFamily={fontFamily}
      animationScrollIn={animationScrollIn}
      animationScrollOut={animationScrollOut}
      selectedBlock={selectedBlock}
      fullWidth
      noPadding
    >
      <ZenAnimatedComponent
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        readOnly={readOnly}
      >
        {renderContent()}
      </ZenAnimatedComponent>
    </ZenBaseBlock>
  );
};
