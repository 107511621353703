import _ from "lodash";
import moment from "moment";
import { IActivity } from "../billing/model";

// for internal use
enum BookingStatusCodeEnum {
  completed,
  upcoming,
  canceled,
  pending
}

export type BookingStatusCode = keyof typeof BookingStatusCodeEnum;

export function isBookingStatusCode(value: string): value is BookingStatusCode {
  return value in BookingStatusCodeEnum;
}

export interface IBookingStatus {
  code: BookingStatusCode;
  name: string;
}

export interface IBookingCustomer {
  name: string;
  email: string;
  phoneNumber: string;
}

export interface IBookingBase {
  id: string;
  status: IBookingStatus;
  name: string;
  startDateTime: Date;
  duration: number;
  totalAmount: number;
  customer: IBookingCustomer;
}

export interface IBooking extends IBookingBase {
  address: string;
  locationDetails?: string;
  additionalInfo?: string;
  paidAmount: number;
  remainingAmount: number;
  canceledOn?: Date;
  canceledNote?: string;
  latestBillingActivity?: IActivity;
  approvalRequired: boolean;
  acceptanceNote?: string;
  internalNote?: string;
  isTaxable: boolean;
}

export interface IBookingsQuery {
  status: BookingStatusCode;
  name?: string;
}

export interface IBookingUpdater {
  name: string;
  customer: IBookingCustomer;
  address: string;
  locationDetails?: string;
  additionalInfo?: string;
  acceptanceNote?: string;
  internalNote?: string;
  canceledNote?: string;
}

export interface IBookingPatch {
  bookingId: string;
  latestBillingActivity?: IActivity;
  paidAmount?: number;
  remainingAmount?: number;
  totalAmount?: number;
  internalNote?: string;
}

export interface IRescheduleSettings {
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  clientNote?: string;
  internalNote?: string;
  sendNotification?: boolean;
}

export interface IRescheduleConflictBase {
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
}

export interface IRescheduleCalendarConflict extends IRescheduleConflictBase {
  eventName: string;
}

export interface IRescheduleBookingConflict extends IRescheduleConflictBase {
  bookingId: string;
  bookingName: string;
  bookingStatus: IBookingStatus;
}

export interface IRescheduleConflicts {
  calendar: IRescheduleCalendarConflict[];
  bookings: IRescheduleBookingConflict[];
}

export interface IRescheduleResult {
  bookingStatus?: IBookingStatus;
  internalNote?: string;
  conflicts?: IRescheduleConflicts;
}

export function rescheduleConflictsEmpty(conflicts?: IRescheduleConflicts) {
  return !conflicts || _.isEmpty(_.pickBy(conflicts, v => v && v.length > 0));
}
