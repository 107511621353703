import cx from "classnames";
import defaultTo from "lodash/defaultTo";
import * as React from "react";
import { RefForwardingComponent, useEffect, useImperativeHandle, useRef, useState } from "react";
import { isIOS } from "react-device-detect";
import Player, {
  IPlaybackStatsRecord,
  IPlayerProps,
  IQualityOptions,
  IVideo,
  IVideoPlayer,
  LeaveAction,
  VideoPlacement
} from "./Player";
import Thumbnail, { ImagePlacement, IThumbnailProps, Overlay, Processing } from "./Thumbnail";
import styles from "./video.module.scss";

export interface IVideoProps {
  thumbnailImageSrc?: string;
  video?: IVideo;
  /**
   * Specifies whether to show processing indicator and optional message. *true* is the same
   * as *"spinner"*.
   */
  processing?: Processing;
  /**
   * Specifies whether to show the play button in thumbnail. You can customize its size in *thumbnailOverlay*.
   */
  playable?: boolean;
  /**
   * Thumbnail overlay configuration.
   */
  thumbnailOverlay?: Overlay;
  /**
   * Specifies whether you *prefer* the video to be preloaded while thumbnail is shown. Whether it will actually
   * be preloaded and how depends on many things, so this is just a preference. If *playable* is set
   * to *true*, then this is enabled by default.
   */
  preload?: boolean;
  /**
   * Specifies whether the video should automatically play. Note that autoplay might fail sometimes,
   * and in that case thumbnail will be shown, so you might want to configure thumbnail in all cases.
   */
  autoplay?: boolean;
  /**
   * Specifies how image should be positioned inside thumbnail. *"cover"* by default.
   */
  thumbnailImagePlacement?: ImagePlacement;
  /**
   * Default volume in the range [0, 1]. Session-wide volume takes precedence.
   */
  defaultVolume?: number;
  /**
   * Specifies whether the video should be muted by default.
   */
  defaultMuted?: boolean;
  /**
   * Specifies whether the video playback should be looped.
   */
  loop?: boolean;
  /**
   * Specifies the action that is executed when the video player leaves the viewport. *"none"* by default.
   */
  playerLeaveAction?: LeaveAction;
  /**
   * Specifies whether to disable (hide) the Picture-in-picture feature.
   */
  disablePiP?: boolean;
  /**
   * Specifies whether the component should listen to keyboard events on document level, recognize relevant
   * key presses and intercept them. Use with caution because it might affect functionality of other elements
   * on the page, if they use the same key presses for something else. *false* by default.
   */
  globalKeyboardControls?: boolean;
  /**
   * Specifies whether the user can interact with the video player. If set to *false* then all player controls
   * are hidden, the player is not focusable and ignores all mouse and keyboard inputs. *true* by default.
   */
  playerInteractive?: boolean;
  /**
   * Specifies how video should be positioned inside container. *"contain"* by default.
   */
  videoPlacement?: VideoPlacement;
  /**
   * Specifies whether the video should be shown in "background" mode. Background video automatically plays,
   * it is muted, looped, not interactive, and covers its container. Basically this prop is just a shortcut for
   * a combination of several other props. You can still override those props.
   */
  backgroundMode?: boolean;
  /**
   * Specifies whether to show the loading indicator in the video player. *true* by default.
   */
  playerLoadingIndicator?: boolean;
  /**
   * Specifies quality control settings.
   */
  quality?: IQualityOptions;
  className?: string;
  style?: React.CSSProperties;
  containerRef?: React.Ref<HTMLDivElement>;
  /**
   * Called when the thumbnail is clicked. Clicks on the play button will not execute this callback.
   */
  onThumbnailClick?: () => void;
  /**
   * Called every time the thumbnail image (*thumbnailImageSrc*) is loaded.
   */
  onThumbnailImageLoad?: () => void;
  /**
   * Called every time an error occurs when loading the thumbnail image (*thumbnailImageSrc*).
   */
  onThumbnailImageError?: () => void;
  /**
   * Called when the video player leaves the viewport.
   */
  onPlayerLeave?: () => void;
  /**
   * Called when the video starts playing (for the first time), either as a result of clicking on the play button,
   * or because of autoplay. If the video has been *stopped* (not just paused, or ended), then this callback resets
   * and can be executed again under the same conditions.
   */
  onStart?: () => void;
  /**
   * Called when the video is *stopped* (not just paused, or ended). Video can be stopped imperatively
   * (using *stop* method) or via the *playerLeaveAction*.
   */
  onStop?: () => void;
  /**
   * Called when enough data has been loaded to start playing the video.
   */
  onVideoLoad?: () => void;
  /**
   * Called when an error occurs when loading the video.
   */
  onVideoError?: () => void;
  /**
   * Called when a playback stats record is generated.
   */
  onPlaybackStats?: (record: IPlaybackStatsRecord) => void;
}

function forwardRef(Component: RefForwardingComponent<IVideoPlayer, IVideoProps>) {
  return React.forwardRef(Component);
}

/**
 * Video component. Connects Video Thumbnail and Video Player. Use it if you need both
 * thumbnail and player together, with thumbnail showing first and its play button starting
 * video playback, or video auto playing after "processing" state (which is technically
 * a thumbnail). You also should use this component instead of just using Video Player if you
 * use autoplay feature, because sometimes autoplay may fail and in that case you probably want
 * thumbnail to be shown instead. You can also use it in "only thumbnail" and "only player" modes,
 * although it probably doesn't make much sense.
 */
const Video: ReturnType<typeof forwardRef> & {
  /**
   * Video Thumbnail component.
   */
  Thumbnail: typeof Thumbnail;
  /**
   * Video Player component.
   */
  Player: typeof Player;
} = React.forwardRef((props: IVideoProps, ref: React.Ref<IVideoPlayer>) => {
  const { video, processing, backgroundMode, className, style, containerRef } = props;
  const { thumbnailImageSrc, thumbnailOverlay, thumbnailImagePlacement, playable } = props;
  const { defaultVolume, defaultMuted, loop, playerLeaveAction, disablePiP, quality } = props;
  const { globalKeyboardControls, playerInteractive, videoPlacement, playerLoadingIndicator } = props;
  const { onThumbnailClick, onThumbnailImageLoad, onThumbnailImageError, onStart, onStop } = props;
  const { onVideoLoad, onVideoError, onPlaybackStats } = props;

  const autoplay = defaultTo(props.autoplay, backgroundMode ? true : undefined);

  const [showPlayer, setShowPlayer] = useState<boolean>(!!autoplay);
  const playerRef = useRef<IVideoPlayer>(null);
  useImperativeHandle(ref, () => ({ pause, resume, play, stop, getVjsPlayer }));
  useEffect(onAutoplayChange, [autoplay]);

  const preload = isIOS ? true : defaultTo(props.preload, !!playable);
  const componentContainerProps = { className, style, containerRef };
  const preloadContainerProps = { className: cx(styles.preloadContainer, className), style, ref: containerRef };
  const thumbnailProps: IThumbnailProps = {
    imagePlacement: thumbnailImagePlacement,
    playable,
    overlay: thumbnailOverlay,
    imageSrc: thumbnailImageSrc,
    onPlay: onThumbnailPlay,
    onClick: onThumbnailClick,
    onImageLoad: onThumbnailImageLoad,
    onImageError: onThumbnailImageError
  };
  const playerProps: IPlayerProps & { ref: React.Ref<IVideoPlayer> } = {
    ref: playerRef,
    defaultVolume,
    defaultMuted,
    loop,
    disablePiP,
    video,
    interactive: false,
    videoPlacement,
    loadingIndicator: playerLoadingIndicator,
    quality,
    onVideoLoad,
    onVideoError,
    onPlaybackStats
  };
  const playerShownProps: IPlayerProps = {
    autoplay: true,
    leaveAction: playerLeaveAction,
    globalKeyboardControls,
    interactive: playerInteractive,
    backgroundMode,
    onLeave: onPlayerLeave,
    onStart,
    onStop,
    onAutoplayFail: onPlayerAutoplayFail
  };

  function onAutoplayChange() {
    if (autoplay && !showPlayer) {
      setShowPlayer(true);
    }
  }

  function onPlayerAutoplayFail() {
    setShowPlayer(false);
  }

  function onThumbnailPlay() {
    play();
  }

  function onPlayerLeave() {
    if (playerLeaveAction === "stop") {
      setShowPlayer(false);
    }
    props.onPlayerLeave?.();
  }

  function pause() {
    playerRef.current?.pause();
  }

  function resume() {
    playerRef.current?.resume();
  }

  function play() {
    playerRef.current?.play();
    setShowPlayer(true);
  }

  function stop() {
    playerRef.current?.stop();
    setShowPlayer(false);
  }

  function getVjsPlayer() {
    return (playerRef.current as any)?.getVjsPlayer();
  }

  if (processing) {
    return <Thumbnail {...componentContainerProps} {...thumbnailProps} processing={processing} />;
  } else if (!showPlayer) {
    return playable && preload && video ? (
      <div {...preloadContainerProps}>
        <Thumbnail {...thumbnailProps} className={styles.thumbnail} />
        <Player {...playerProps} className={styles.hiddenPlayer} />
      </div>
    ) : (
      <Thumbnail {...componentContainerProps} {...thumbnailProps} />
    );
  } else {
    return playable && preload && video ? (
      <div {...preloadContainerProps}>
        <Thumbnail {...thumbnailProps} className={styles.hiddenThumbnail} />
        <Player {...playerProps} {...playerShownProps} />
      </div>
    ) : (
      <Player {...componentContainerProps} {...playerProps} {...playerShownProps} />
    );
  }
}) as any;

Video.Thumbnail = Thumbnail;
Video.Player = Player;

export default Video;
