import * as React from "react";
import Helmet from "react-helmet";
import { StripeProvider } from "react-stripe-elements";
import Content from "../../components/layout/content";
import Widget from "../../containers/widget";
import { AsyncLoader } from "../../utilities/asyncLoader";
import { CustomizeManager } from "../../utilities/customizeManager";

export interface IWidgetLayoutProps {
  stripe: stripe.Stripe | null;
  onStripeFactoryReady: (stripeFactory: stripe.StripeStatic) => void;
}

class WidgetLayout extends React.PureComponent<IWidgetLayoutProps> {
  public componentDidMount() {
    AsyncLoader.subscribe(AsyncLoader.stripeFactory, this.onStripeFactoryReady);
    CustomizeManager.apply();
  }

  public componentWillUnmount() {
    AsyncLoader.unsubscribe(AsyncLoader.stripeFactory, this.onStripeFactoryReady);
    CustomizeManager.reset();
  }

  public render() {
    return (
      <React.Fragment>
        <Helmet title="BookMe" />
        <Content>
          <StripeProvider stripe={this.props.stripe}>
            <Widget
              nextButtonText="Continue"
              prevButtonText="Back"
              orderButtonText="Pay"
              confirmationButtonText="Book Another Session"
              prevButtonOnLastStep={false}
            />
          </StripeProvider>
        </Content>
      </React.Fragment>
    );
  }

  private onStripeFactoryReady = (stripeFactory: stripe.StripeStatic) => {
    this.props.onStripeFactoryReady(stripeFactory);
  };
}

export default WidgetLayout;
