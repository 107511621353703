import _ from "lodash";
import { Node } from "slate";
import {
  defaultValue,
  ISerializationOptions,
  serializeHtml
} from "../components/RichTextEditor/serialize/serialize-html";

export abstract class RichText {
  public static toJSON(value: Node[] | null | undefined): string {
    return RichText.isDisplayable(value) ? JSON.stringify(value) : "";
  }

  public static fromJSON(value: string | null | undefined): Node[] | undefined {
    if (value) {
      try {
        const result = JSON.parse(value);
        if (_.isArray(result)) {
          return result;
        }
      } catch (e) {}
    }

    return undefined;
  }

  public static toHTML(value: Node[] | null | undefined, options?: ISerializationOptions): string {
    return RichText.isDisplayable(value) ? serializeHtml(value, options) : "";
  }

  public static getLength(value: Node[] | null | undefined): number {
    let length = 0;

    if (RichText.isNotEmpty(value)) {
      for (const node of value) {
        length += Node.string(node).length;
      }

      // line breaks
      length += value.length - 1;
    }

    return length;
  }

  public static isDisplayable(value: Node[] | null | undefined): value is Node[] {
    return RichText.getLength(value) > 0;
  }

  public static clone(value: Node[] | null | undefined) {
    return value ? [...value] : [...defaultValue];
  }

  private static isNotEmpty(value: Node[] | null | undefined): value is Node[] {
    return !_.isEmpty(value) && !_.isEqual(value, defaultValue);
  }
}
