export default [
  {
    name: "North America",
    code: 1
  },
  {
    name: "Latin America & Caribbean",
    code: 2
  },
  {
    name: "Europe",
    code: 3
  },
  {
    name: "Eastern Europe",
    code: 4
  },
  {
    name: "Middle East & North Africa",
    code: 5
  },
  {
    name: "Sub-Saharan Africa",
    code: 6
  },
  {
    name: "South Asia",
    code: 7
  },
  {
    name: "East Asia",
    code: 8
  },
  {
    name: "Southeast Asia",
    code: 9
  },
  {
    name: "South Pacific",
    code: 10
  },
  {
    name: "Antarctica",
    code: 11
  }
];
