import React, { PureComponent } from "react";
import Fullscreen from "react-full-screen";
import tempIco from "./expandfullscreen.svg";
import styles from "./imageFullScreen.module.scss";

interface IImageFullScreen {
  src: string;
}

class ImageFullScreen extends PureComponent<IImageFullScreen> {
  public state = {
    isFull: false
  };

  private handleGoFull = () => {
    this.setState({ isFull: true });
  };

  public render() {
    const { src } = this.props;

    return (
      <div>
        <div className={styles.expandBtn}>
          <button onClick={this.handleGoFull}>
            <img src={tempIco} alt="fullscreen" />
          </button>
        </div>
        <Fullscreen enabled={this.state.isFull} onChange={this.onFullscreenChange}>
          <div style={{ backgroundImage: `url('${src}')` }} className={styles.fullScreen} />
        </Fullscreen>
      </div>
    );
  }

  private onFullscreenChange = (isFull: boolean) => this.setState({ isFull });
}

export default ImageFullScreen;
