import { DateTimePicker } from "@zenfolio/core-components";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { ActionStatus } from "../../../../store/common";
import { IAvailability, IAvailabilityDay } from "../../../../store/widget/model";
import { splitDateTime } from "../../../../utilities/datetime";
import eventTracker from "../../../../utilities/eventTracker";
import { hasActionCompleted } from "../../../../utilities/helpers";
import Loader from "../../../layout/loader";
import { IEditableWidgetStepProps, IWidgetStep } from "../../contracts";
import styles from "./index.module.scss";

const { getWeekDays } = DateTimePicker;

export interface IDateTimeParams {
  dateTime: Date;
}

interface IDateTimeProps extends IEditableWidgetStepProps {
  availability: IAvailability | null;
  availabilityServiceId: string | null;
  loadAvailabilityStatus: ActionStatus;
  onLoadAvailability: (serviceId: string) => void;
}

interface IDateTimeState {
  date?: moment.Moment;
  time?: number | null;
}

class DateTimeStep extends React.Component<IDateTimeProps, IDateTimeState> implements IWidgetStep {
  constructor(props: IDateTimeProps) {
    super(props);

    this.state = splitDateTime(props.getValues().dateTime);
  }

  public componentDidMount() {
    const { onRef, onLoadAvailability, getValues, loadAvailabilityStatus, availabilityServiceId } = this.props;
    const serviceId = getValues().service!.id;

    eventTracker.events.consumer.dateAndTimeReached();

    if (loadAvailabilityStatus === "Init" || availabilityServiceId !== serviceId) {
      onLoadAvailability(serviceId);
    } else if (!this.state.date) {
      this.setState({ date: moment(this.defaultDate) });
    }

    onRef(this);
    this.updateValidate();
  }

  public componentDidUpdate(prevProps: IDateTimeProps) {
    const { loadAvailabilityStatus } = this.props;
    const loadAvailabilityHasCompleted = hasActionCompleted(prevProps.loadAvailabilityStatus, loadAvailabilityStatus);

    if (loadAvailabilityHasCompleted) {
      this.setState({ date: moment(this.defaultDate) });
    }

    this.updateValidate();
  }

  public componentWillUnmount() {
    this.props.onRef(undefined);
  }

  public render() {
    const { date, time } = this.state;
    const { availability } = this.props;
    const times = date ? this.getTimeSlots(date) : undefined;
    const minDate = availability ? moment(availability.days[0].date) : undefined;
    const maxDate = availability ? moment(_.last(availability.days)!.date) : undefined;
    const dayStatuses = date
      ? getWeekDays(date).map(d => (this.getTimeSlots(d).length === 0 ? "empty" : "normal"))
      : undefined;

    return (
      <div className={styles.container}>
        {date ? (
          <DateTimePicker
            className={styles.picker}
            scrollbar={{}}
            noTimeText="No Availability"
            allowTimeGrouping={true}
            date={date}
            dayStatuses={dayStatuses}
            minDate={minDate}
            maxDate={maxDate}
            time={time === undefined ? null : time}
            times={times}
            onChange={this.onDateTimeChange}
          />
        ) : (
          <Loader styles={{ marginBottom: 0 }} />
        )}
      </div>
    );
  }

  public saveAndContinue = (): void => {
    const { date, time } = this.state;

    if (time == null) {
      return;
    }

    const dateTime = date!
      .clone()
      .add(time, "minute")
      .toDate();
    const oldDateTime = this.props.getValues().dateTime;

    if (!oldDateTime || oldDateTime.getTime() !== dateTime.getTime()) {
      this.props.updateValues({ dateTime });
    }
  };

  public deferNavigation = (): boolean => {
    return false;
  };

  private get defaultDate() {
    if (!this.props.availability) {
      return undefined;
    }

    const days = this.props.availability.days;
    const firstAvailableDay = days.find(d => d.availableTimeSlots.length > 0);
    return (firstAvailableDay || days[0]).date;
  }

  private updateValidate() {
    const { updateValidate, name } = this.props;
    updateValidate(name, this.state.time != null);
  }

  private onDateTimeChange = (date: moment.Moment, time: number | null) => {
    const { date: oldDate, time: oldTime } = this.state;
    const dateHasChanged = !date.isSame(oldDate, "day");
    time = dateHasChanged && oldTime != null && this.getTimeSlots(date).indexOf(oldTime) !== -1 ? oldTime : time;
    this.setState({ date, time });
  };

  private getTimeSlots = (date: moment.Moment) => {
    if (!this.props.availability) {
      return [];
    }

    return this.getTimeSlotsMap(this.props.availability.days)[date.format("YYYY-MM-DD")] || [];
  };

  private getTimeSlotsMap = _.memoize((availabilityDays: IAvailabilityDay[]) =>
    _.mapValues(
      _.keyBy(availabilityDays, d => moment(d.date).format("YYYY-MM-DD")),
      d => d.availableTimeSlots
    )
  );
}

export default DateTimeStep;
