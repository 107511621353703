import _ from "lodash";
import { useEffect, useState } from "react";

/**
 * Returns the current state of input activity. Mouse, touch and keyboard events are considered input activity.
 * @param inactivityTimeout The inactivity timeout (in milliseconds).
 * @param initialState The initial state of input activity.
 * @returns
 */
export default function useInputActivity(inactivityTimeout: number, initialState: boolean = true): boolean {
  const [activity, setActivity] = useState<boolean>(initialState);

  useEffect(onComponentMount, []);

  function onComponentMount() {
    function onActivity() {
      setActivity(true);
      debouncedInactivity();
    }

    const debouncedInactivity = _.debounce(() => setActivity(false), inactivityTimeout);
    debouncedInactivity();

    document.addEventListener("mousemove", onActivity, true);
    document.addEventListener("mousedown", onActivity, true);
    document.addEventListener("touchstart", onActivity, true);
    document.addEventListener("keydown", onActivity, true);

    return function cleanup() {
      document.removeEventListener("mousemove", onActivity, true);
      document.removeEventListener("mousedown", onActivity, true);
      document.removeEventListener("touchstart", onActivity, true);
      document.removeEventListener("keydown", onActivity, true);
      debouncedInactivity.cancel();
    };
  }

  return activity;
}
