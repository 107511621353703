import * as React from "react";
import styles from "./index.module.scss";

export interface ILogo {
  imgSrc: string;
  imgAlt: string;
}

export interface ILogoProps {
  logo: ILogo;
}

const Logo: React.SFC<ILogoProps> = ({ logo }) => {
  return (
    <div className={styles.logo}>
      <img alt={logo.imgAlt} src={logo.imgSrc} />
    </div>
  );
};

export default Logo;
