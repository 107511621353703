import React from "react";
import cx from "classnames";

import styles from "./campaign.module.scss";
import _ from "lodash";
// @ts-ignore
import Background from "../images/halloween.jpg";
// @ts-ignore
import BackgroundMobile from "../images/halloween-mobile.png";

import { IBannerContent } from "models/coupon";
import { BannerSnipe } from "../BannerSnipe";
import { ICurrency } from "models";
import { BannerDiscountType, BannerFontStyle, BannerViewMode } from "utilities/constant";

export interface IProps {
  content?: IBannerContent;
  viewMode: BannerViewMode;
  onClick?: () => void;
  currency?: ICurrency;
}

const DEFAULT_COLORS = {
  white: "#ffffff",
  yellow: "#f0cc32",
  black: "#000000"
};

export class CampaignBanner extends React.PureComponent<IProps> {
  public getProps = (): IProps => {
    const defaultProps = {
      viewMode: BannerViewMode.DesktopClient,
      content: {
        header: {
          text: "BLACK FRIDAY",
          color: DEFAULT_COLORS.black
        },
        body: {
          text: "20% OFF ALL PURCHASES",
          color: DEFAULT_COLORS.white
        },
        code: {
          text: "Code: [code]",
          color: DEFAULT_COLORS.black
        },
        button: {
          text: "SHOP NOW",
          color: DEFAULT_COLORS.yellow,
          backgroundColor: DEFAULT_COLORS.white
        },
        fontStyle: BannerFontStyle.Serif,
        backgroundColor: DEFAULT_COLORS.yellow,
        discountAmount: "20",
        discountType: BannerDiscountType.PercentOff,
        backgroundImage: Background,
        backgroundImageMobile: BackgroundMobile
      }
    };
    const renderProps = _.merge(defaultProps, this.props);
    return renderProps;
  };

  private getLargeBannerImage = () => {
    const { viewMode, content } = this.getProps();

    switch (viewMode) {
      case BannerViewMode.DesktopClient: {
        return content?.backgroundImage;
      }
      case BannerViewMode.MobileClient: {
        return content?.backgroundImageMobile;
      }
      default:
        return content?.backgroundImage;
    }
  };

  public render() {
    const { content, viewMode, currency } = this.getProps();

    return (
      <React.Fragment>
        <div
          className={cx(
            styles.container,
            [styles[viewMode]],
            [styles[content?.fontStyle || BannerFontStyle.SansSerif]]
          )}
          style={{ backgroundImage: `url(${this.getLargeBannerImage()})` }}
        >
          <div className={styles.content} style={{ backgroundColor: content?.backgroundColor }}>
            <div className={styles.border} style={{ borderColor: content?.header?.color }}>
              <div className={styles.top} style={{ borderColor: content?.header?.color }}>
                <div className={styles.body} style={{ color: content?.body?.color }}>
                  {content?.body?.text}
                </div>
                <div
                  className={styles.header}
                  style={{ color: content?.header?.color }}
                >
                  {content?.header?.text}
                </div>
              </div>
              <div className={styles.code} style={{ color: content?.code?.color }}>
                {content?.code?.text}
              </div>
            </div>
            {content?.showSnipe && (
              <div className={styles.snipe}>
                <BannerSnipe
                  fontStyle={content.fontStyle}
                  discountType={content.discountType}
                  discountAmount={content.discountAmount}
                  currency={currency}
                />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CampaignBanner;
