import classNames from "classnames";
import React from "react";
import styles from "./badge.module.scss";

interface IBadgeProps {
  value: string;
  className?: string;
  style?: React.CSSProperties;
}

class Badge extends React.PureComponent<IBadgeProps> {
  public render() {
    const { className, style, value } = this.props;

    return (
      <span className={classNames(styles.container, className)} style={style}>
        {value}
      </span>
    );
  }
}

export default Badge;
