import { ModalTypes } from "../../containers/modal";
import { ModalActionType } from "./constants";
import {
  IApproveBookingModalProps,
  IBookableHoursModalProps,
  IBookingBillingModalProps,
  ICancelBookingModalProps,
  IDeclineBookingModalProps,
  IGoogleCalendarModalProps,
  IRescheduleBookingModalProps,
  IWidgetErrorModalProps
} from "./model";

interface IShowModal {
  type: ModalActionType.SHOW_MODAL;
  modalType: string;
  modalProps: any;
}

interface IHideModal {
  type: ModalActionType.HIDE_MODAL;
}

export type Action = IShowModal | IHideModal;

export const showModal = (modalType: string, modalProps: any = {}) => ({
  type: ModalActionType.SHOW_MODAL,
  modalType,
  modalProps
});

export const hideModal = () => ({
  type: ModalActionType.HIDE_MODAL
});

export const showBookableHoursModal = (props: IBookableHoursModalProps) => ({
  type: ModalActionType.SHOW_MODAL,
  modalType: ModalTypes.BOOKABLE_HOURS,
  modalProps: props
});

export const showGoogleCalendarModal = (props: IGoogleCalendarModalProps) => ({
  type: ModalActionType.SHOW_MODAL,
  modalType: ModalTypes.GOOGLE_CALENDAR,
  modalProps: props
});

export const showWidgetErrorModal = (props: IWidgetErrorModalProps) => ({
  type: ModalActionType.SHOW_MODAL,
  modalType: ModalTypes.WIDGET_ERROR,
  modalProps: props
});

export const showCancelBookingModal = (props: ICancelBookingModalProps) => ({
  type: ModalActionType.SHOW_MODAL,
  modalType: ModalTypes.CANCEL_BOOKING,
  modalProps: props
});

export const showApproveBookingModal = (props: IApproveBookingModalProps) => ({
  type: ModalActionType.SHOW_MODAL,
  modalType: ModalTypes.APPROVE_BOOKING,
  modalProps: props
});

export const hideApproveBookingModal = () => ({
  type: ModalActionType.HIDE_MODAL,
  modalType: ModalTypes.APPROVE_BOOKING
});

export const showDeclineBookingModal = (props: IDeclineBookingModalProps) => ({
  type: ModalActionType.SHOW_MODAL,
  modalType: ModalTypes.DECLINE_BOOKING,
  modalProps: props
});

export const showBookingBillingModal = (props: IBookingBillingModalProps) => ({
  type: ModalActionType.SHOW_MODAL,
  modalType: ModalTypes.BOOKING_BILLING,
  modalProps: props
});

export const showRescheduleBookingModal = (props: IRescheduleBookingModalProps) => ({
  type: ModalActionType.SHOW_MODAL,
  modalType: ModalTypes.RESCHEDULE_BOOKING,
  modalProps: props
});
