import { IShootType } from "../store/shootTypes/model";
import { apiType } from "./apiType";
import { apiClient } from "./index";

export interface IFetchShootTypesResponse {
  customShootTypes: IShootType[];
  defaultShootTypes: IShootType[];
}

export interface IDeleteShootTypeRequest {
  shootTypeId: string;
}

export interface IReorderShootTypesRequest {
  shootTypeIds: string[];
}

export interface IRenameShootTypeRequest {
  id: string;
  name: string;
}

export interface IRenameShootTypeResponse extends IShootType {}

export interface IApiShootTypes {
  fetchShootTypes: () => Promise<IFetchShootTypesResponse>;
  renameShootType: (request: IRenameShootTypeRequest) => Promise<IRenameShootTypeResponse>;
  deleteShootType: (request: IDeleteShootTypeRequest) => Promise<void>;
  reorderShootTypes: (request: IReorderShootTypesRequest) => Promise<void>;
}

const api: IApiShootTypes = {
  fetchShootTypes: () => apiClient.get<IFetchShootTypesResponse>(`${apiType.bookingWidget}photographer/shoot-types`),

  renameShootType: (request: IRenameShootTypeRequest) =>
    apiClient.put<IRenameShootTypeResponse>(`${apiType.bookingWidget}photographer/shoot-types`, request),

  deleteShootType: (request: IDeleteShootTypeRequest) =>
    apiClient.request<void>({
      method: "delete",
      url: `${apiType.bookingWidget}photographer/shoot-types`,
      data: request
    }),

  reorderShootTypes: (request: IReorderShootTypesRequest) =>
    apiClient.put<void>(`${apiType.bookingWidget}photographer/shoot-types/sort`, request)
};

export default api;
