import React, { useEffect, useState } from "react";
import styles from "./Actions.module.scss";
import cx from "classnames";
import FavoriteIcon from "../../../Icons/ClientView/Favorite";
import FavoriteWithBadgeIcon from "../../../Icons/ClientView/FavoriteWithBadge";
import CartIcon from "../../../Icons/Cart";
import ShareIcon from "../../../Icons/ClientView/Share";
import DownloadIcon from "../../../Icons/ClientView/Download";
import { isMobile } from "react-device-detect";
import More from "../../../Icons/ClientView/More";
import Comment from "../../../Icons/ClientView/Comment";
import MenuOption from "../../../ClientView/CommonUI/MenuOption";
import { universalWindow as window } from "utilities/blocks/site";
import colors from "utilities/colors";
import { IZenSiteTheme } from "../../../../models/models";
import { getSiteFonts } from "utilities/blocks/site";
import { LINKED_GALLERY_ACTIONS_BAR_ID } from "../consts";

export interface IActionsProps {
  lineColor: string;
  titlesColor: string;
  bgColor: string;
  name: string;
  hasFavorites: boolean;
  hasUnreadComments: boolean | undefined;
  opacity: ".4" | "1";
  isEcom: boolean;
  token: string;
  iconMore: string;
  iconShare: string;
  iconDownload: string;
  allowFavorite?: boolean;
  disabledShop?: boolean;
  cartBadge?: number;
  cartTooltip?: JSX.Element;
  allowShareGallery: boolean;
  allowGalleryPhotosDownload: boolean;
  allowGalleryVideosDownload: boolean;
  allowMediaCommenting?: boolean;
  onClickShop?: () => void;
  onClickCart?: () => void;
  onMediaFavoritesViewClick: (e: React.MouseEvent<HTMLElement>) => void;
  onMediaCommentsViewClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onDownloadClick: (e: React.MouseEvent<HTMLElement>) => void;
  onShareClick: (e: React.MouseEvent<HTMLElement>) => void;
  studioName?: string;
  siteTheme?: IZenSiteTheme;
  topValue?: number;
}

const Actions: React.FC<IActionsProps> = props => {
  const [isMobileByWidth, setIsMobileByWidth] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      if (window) {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  const isMobileDevice = window ? isMobileByWidth : isMobile;

  const handleResize = () => {
    setIsMobileByWidth(window.innerWidth < 768);
  };

  const allowGalleryDownload = props.allowGalleryPhotosDownload || props.allowGalleryVideosDownload;
  const isDarkBackground: boolean = props.bgColor === colors.grayNav || props.bgColor === colors.black;
  const titlesColor = isDarkBackground ? props.titlesColor : colors.grayTextNav;
  const subtitleColor = isDarkBackground ? colors.grayTextNav : colors.grayLightTextNav;

  let fontSecondary;
  if (props.siteTheme) {
    const siteFonts = getSiteFonts(props.siteTheme.fontsStyle);
    fontSecondary = styles[siteFonts.secondary];
  }

  return (
    <div
      id={LINKED_GALLERY_ACTIONS_BAR_ID}
      className={styles.layout}
      style={{ borderBottomColor: props.lineColor, backgroundColor: props.bgColor, top: props.topValue || 0 }}
    >
      <div className={styles.actionsContainer}>
        <div className={styles.wrapper}>
          <div className={styles.actionsLeft}>
            <div id="infoLeft" className={styles.infoLeft}>
              <h3 style={{ color: titlesColor }} className={fontSecondary}>
                {props.name}
              </h3>
              <p style={{ color: subtitleColor }} className={fontSecondary}>
                by {props.studioName}
              </p>
              <div
                className={cx(
                  styles.btnShop,
                  fontSecondary,
                  { [styles.displayNone]: !props.isEcom },
                  { [styles.disabled]: props.disabledShop }
                )}
                onClick={props.onClickShop}
              >
                <span>Shop</span>
              </div>
            </div>
          </div>
          <div className={styles.actionsRight}>
            {props.allowFavorite && (
              <div
                className={cx(styles.icon, styles.iconFavorite, styles.iconMargin)}
                onClick={props.onMediaFavoritesViewClick}
              >
                {!props.hasFavorites ? (
                  <FavoriteIcon color={props.titlesColor} size={32} opacity={props.opacity} />
                ) : (
                  <FavoriteWithBadgeIcon color={props.titlesColor} size={32} opacity={props.opacity} />
                )}
              </div>
            )}
            {props.allowMediaCommenting && (
              <div
                className={cx(styles.icon, styles.iconComment, styles.iconMargin)}
                onClick={props.onMediaCommentsViewClick}
              >
                <Comment
                  hasUnreadComment={props.hasUnreadComments}
                  color={props.titlesColor}
                  size={32}
                  opacity={props.opacity}
                />
              </div>
            )}
            {allowGalleryDownload && (
              <div className={cx(styles.downloadIcon, styles.icon, styles.iconMargin)} onClick={props.onDownloadClick}>
                <DownloadIcon color={props.titlesColor} size={32} opacity={props.opacity} />
              </div>
            )}
            {props.allowShareGallery && (
              <div className={cx(styles.shareIcon, styles.icon, styles.iconMargin)} onClick={props.onShareClick}>
                <ShareIcon color={props.titlesColor} size={32} opacity={props.opacity} />
              </div>
            )}
            <div
              className={cx(styles.icon, styles.iconMargin, styles.cartIcon, { [styles.displayNone]: !props.isEcom })}
              onClick={props.onClickCart}
            >
              {props.isEcom && (
                <div>
                  <CartIcon color={props.titlesColor} size={32} opacity={props.opacity} />
                  {!!props.cartBadge && <span className={styles.cartBadge}>{props.cartBadge}</span>}
                  {props.cartTooltip}
                </div>
              )}
            </div>
            <div
              className={cx(
                styles.btnShop,
                fontSecondary,
                { [styles.displayNone]: !props.isEcom },
                { [styles.disabled]: props.disabledShop }
              )}
              onClick={props.onClickShop}
            >
              Shop
            </div>
            <MenuOption
              disableClick={false}
              style={{ maxWidth: "20px" }}
              iconSrcActive={props.iconMore}
              svgIcon={<More color={props.titlesColor} opacity={props.opacity} />}
              hide={!isMobileDevice || (!props.allowShareGallery && !allowGalleryDownload)}
            >
              <div>
                <div className={cx(styles.iconMoreBlock)}>
                  <div className={cx(styles.moreMenu)}>
                    <div className={styles.arrowBox} />
                    <ul>
                      {props.allowShareGallery && (
                        <li>
                          <a href="#" onClick={props.onShareClick}>
                            <span>
                              <img src={props.iconShare} width="24" />
                            </span>
                            Share
                          </a>
                        </li>
                      )}
                      {allowGalleryDownload && (
                        <li>
                          <a href="#" onClick={props.onDownloadClick}>
                            <span>
                              <img src={props.iconDownload} width="24" />
                            </span>
                            Download
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </MenuOption>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Actions;
