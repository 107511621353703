import React from "react";
import IIConProps from "../../IIconProps";

const ArrowScrollDown = (props: IIConProps) => {
  const width = props.width ? props.width : props.size;
  const height = props.height ? props.width : props.size;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`${props.minX} ${props.minY} ${width} ${height}`}
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 2l19 14L42 2"
      />
    </svg>
  );
};

export default ArrowScrollDown;
