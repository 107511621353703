import IError from "../../../utilities/error";
import { ActionStatus } from "../../common";
import { LoadAction, UpdateAction } from "./actions";
import { ActionType } from "./constants";
import { IShootLocation } from "./model";

export interface IShootLocationState {
  loadStatus: ActionStatus;
  updateStatus: ActionStatus;
  shootLocation: IShootLocation | null;
  error: IError | null;
}

const initialLoadState: IShootLocationState = {
  loadStatus: "Init",
  updateStatus: "Init",
  shootLocation: null,
  error: null
};

export const shootLocation = (
  state: IShootLocationState = initialLoadState,
  action: LoadAction | UpdateAction
): IShootLocationState => {
  switch (action.type) {
    case ActionType.LOAD_STARTED:
      return {
        ...state,
        loadStatus: "Pending",
        shootLocation: null,
        error: null
      };
    case ActionType.LOAD_SUCCESS:
      return {
        ...state,
        loadStatus: "Success",
        shootLocation: action.shootLocation.zipCode ? action.shootLocation : null
      };
    case ActionType.LOAD_ERROR:
      return {
        ...state,
        loadStatus: "Error",
        error: action.error
      };
    case ActionType.UPDATE_STARTED:
      return {
        ...state,
        updateStatus: "Pending",
        shootLocation: action.shootLocation,
        error: null
      };
    case ActionType.UPDATE_SUCCESS:
      return {
        ...state,
        updateStatus: "Success"
      };
    case ActionType.UPDATE_ERROR:
      return {
        ...state,
        updateStatus: "Error",
        error: action.error
      };
    default:
      return state;
  }
};

export default shootLocation;
