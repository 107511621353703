import { IBookMePackage, SendInvoiceOption } from "components/blocks/zenServiceBlock/zenServiceBlock";
import { FormCheckbox } from "components/FormCheckbox";
import _ from "lodash";
import pluralize from "pluralize";
import React from "react";
import colors from "utilities/colors";
import { isFreeService } from "./helper";
import styles from "./index.module.scss";
import { IBookMeService } from "models/models";

export interface IServiceNotesProps {
  service: IBookMePackage;
  accentColor: string;
  layoutB: boolean;
  formatPrice: (value: number) => string;
  onUpdateValidate: (serviceId: string, state: boolean) => void;
}

interface IServiceNotesState {
  agreePayDeposit: boolean;
}

class ServiceNote extends React.Component<IServiceNotesProps, IServiceNotesState> {
  constructor(props: IServiceNotesProps) {
    super(props);
    this.state = {
      agreePayDeposit: false
    };
  }

  public componentDidMount() {
    this.updateValidate();
  }

  public componentDidUpdate(prevProps: IServiceNotesProps) {
    if (!_.isEqual(prevProps.service, this.props.service)) {
      this.updateValidate();
    }
  }

  public render() {
    const notes = this.renderNotes(this.props.service);
    if (!notes) {
      return null;
    }

    return <div className={styles.container}>{notes}</div>;
  }

  private renderNotes = (service: IBookMeService): React.ReactNode => {
    if (isFreeService(service)) {
      return service.useDeposit ? this.renderFreeServiceDeposit(service) : this.renderApprovalRequiredNote(service);
    } else {
      return service.useDeposit ? this.renderDeposit(service) : this.renderApprovalRequiredNote(service);
    }
  };

  private renderDeposit = (service: IBookMeService): React.ReactNode => {
    const deposit = service.deposit;
    const backgroundColor = this.props.accentColor === colors.white ? colors.black : this.props.accentColor;
    const style = {
      "--Zf-FormCheckbox-checkedBackgroundColor": backgroundColor,
      "--Zf-FormCheckbox-checkedBorderColor": backgroundColor
    } as React.CSSProperties;

    return (
      <div style={style} className={styles.deposit}>
        {this.renderApprovalRequiredNote(service)}
        <div>
          {`${this.props.formatPrice(deposit!.depositAmount!)} booking fee at time of ${
            service.approvalRequired ? "approval" : "booking"
          }.`}
        </div>
        {this.renderDepositDescription(service)}
        <FormCheckbox
          checked={this.state.agreePayDeposit}
          onChange={this.handleChangeDepositRequired}
          className={styles.depositRequired}
        >
          I acknowledge I am paying a non-refundable booking fee upon approval
        </FormCheckbox>
      </div>
    );
  };

  private renderFreeServiceDeposit = (service: IBookMeService): React.ReactNode => {
    const deposit = service.deposit;
    return (
      <div className={styles.deposit}>
        {this.renderApprovalRequiredNote(service)}
        <div>
          {`${this.props.formatPrice(deposit!.depositAmount!)} ${
            service.approvalRequired ? "is due at time of booking" : "booking fee at time of booking"
          }.`}
        </div>
        {service.approvalRequired ? this.renderFreeDepositDescription(service) : this.renderDepositDescription(service)}
      </div>
    );
  };

  private renderApprovalRequiredNote = (service: IBookMeService) => {
    if (!service.approvalRequired) {
      return null;
    }

    return (
      <div className={styles.approvalRequiredNote}>
        <div>Appointment subject to photographer approval.</div>
        {!isFreeService(service) && <div>Credit card will not be charged until appointment is approved.</div>}
      </div>
    );
  };

  private renderDepositDescription = (service: IBookMeService): React.ReactNode => {
    const deposit = service.deposit;
    switch (deposit!.sendInvoiceOption) {
      case SendInvoiceOption.SameAsShootDate:
        return (
          <div>
            {`Remainder amount ${this.props.formatPrice(
              service.price - deposit!.depositAmount!
            )} is due on the day of the shoot.`}
          </div>
        );
      case SendInvoiceOption.Manually:
        return (
          <div>{`Remainder amount due is ${this.props.formatPrice(service.price - deposit!.depositAmount!)}.`}</div>
        );
      case SendInvoiceOption.BeforeShootDate:
      case SendInvoiceOption.AfterShootDate:
        return (
          <div>
            {`Remainder ${this.props.formatPrice(
              service.price - deposit!.depositAmount!
            )} is due ${this.getDaysPeriodDescription(deposit!.sendInvoiceDays, deposit!.sendInvoiceOption)}.`}
          </div>
        );

      default:
        return null;
    }
  };

  private renderFreeDepositDescription = (service: IBookMeService): React.ReactNode => {
    const deposit = service.deposit;
    switch (deposit!.sendInvoiceOption) {
      case SendInvoiceOption.SameAsShootDate:
        return (
          <div>{`The full amount of ${this.props.formatPrice(service.price)} is due on the day of the shoot.`}</div>
        );
      case SendInvoiceOption.Manually:
        return <div>{`The full amount due is ${this.props.formatPrice(service.price)}.`}</div>;
      case SendInvoiceOption.BeforeShootDate:
      case SendInvoiceOption.AfterShootDate:
        return (
          <div>
            {`The full amount of ${this.props.formatPrice(service.price)} is due ${this.getDaysPeriodDescription(
              deposit!.sendInvoiceDays,
              deposit!.sendInvoiceOption
            )}.`}
          </div>
        );

      default:
        return null;
    }
  };

  private getDaysPeriodDescription = (days: number, sendInvoiceOption: SendInvoiceOption) => {
    switch (sendInvoiceOption) {
      case SendInvoiceOption.BeforeShootDate:
        return `${pluralize("day", days, true)} before shoot`;

      case SendInvoiceOption.AfterShootDate:
        return `${pluralize("day", days, true)} after shoot`;

      default:
        return "";
    }
  };

  private handleChangeDepositRequired = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    this.setState(
      {
        agreePayDeposit: event.target.checked
      },
      this.updateValidate
    );
  };

  private updateValidate() {
    const { onUpdateValidate, service } = this.props;
    onUpdateValidate(service.id, this.isValid());
  }

  public isValid = (): boolean => {
    const { agreePayDeposit } = this.state;
    const { service } = this.props;

    if (isFreeService(service)) {
      return true;
    }

    return service.useDeposit ? agreePayDeposit : true;
  };
}

export default ServiceNote;
