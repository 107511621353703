import IIConProps from "../components/icons/IIconProps";

export const initializeIconProps = (props: IIConProps) => ({
  ...props,
  minX: props.minX || 0,
  minY: props.minY || 0,
  width: props.width || props.size,
  height: props.height || props.size,
  stroke: props.stroke || props.color,
  opacity: props.opacity || 1
});
