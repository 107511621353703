import { Moment } from "moment";
import { ITimeSlot } from "../profile/availability/model";
import { IShootType } from "../shootTypes/model";

export enum SendInvoiceOption {
  None = "none",
  SameAsShootDate = "sameAsShootDate",
  BeforeShootDate = "beforeShootDate",
  AfterShootDate = "afterShootDate",
  Manually = "manually"
}

export enum ServiceAvailability {
  Global,
  Custom
}

export enum SessionsPerDayLimit {
  NotSet,
  Set
}

export interface IDeposit {
  depositAmount: number | null;
  sendInvoiceOption: SendInvoiceOption;
  sendInvoiceDays: number;
}

export interface IBaseService {
  name: string;
  duration: number;
  radius: number;
  useDeposit: boolean;
  deposit: IDeposit | null;
  approvalRequired: boolean;
}

export interface IServiceItem extends IBaseService {
  id: string;
  enabled: boolean;
  currency: string;
  order: number;
  shootType: IShootType;
  originalShootType: IShootType;
  price: number;
}

export interface IAvailability {
  availableStartDate: Moment | null;
  availableEndDate: Moment | null;
  availableHours: ITimeSlot[];
  preEventBuffer: number | null;
  postEventBuffer: number | null;
  sessionsPerDay: number | null;
}

export interface IAvailabilityEdit extends IAvailability {
  serviceAvailability: ServiceAvailability;
  sessionsPerDayLimit: SessionsPerDayLimit;
}

export interface IServiceDetails extends IBaseService, IAvailability {
  askAdditionalInfo: boolean;
  serviceDetails: string[];
  shootType: IShootType | undefined;
  originalShootType: IShootType | undefined;
  price: number | null;
}

export interface IServiceDetailsApi extends Omit<IServiceDetails, "availableStartDate" | "availableEndDate"> {
  availableStartDate: string | null;
  availableEndDate: string | null;
}

export interface IServiceCreator extends IServiceDetailsApi {}

export interface IServiceUpdater extends IServiceDetailsApi {}
