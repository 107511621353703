import _ from "lodash";
import { ICustomizeData } from "../store/customize/model";

type CustomizeUrlMapping = {
  [key in keyof ICustomizeData]: { queryParam: string; defaultValue: ICustomizeData[key] };
};

interface ICustomizeCssMapping {
  [key: string]: keyof ICustomizeData;
}

const urlMapping: CustomizeUrlMapping = {
  accentColor: { queryParam: "accentColor", defaultValue: "#FF5A00" },
  buttonPlacementType: { queryParam: "buttonPlacementType", defaultValue: "right" }
};

const cssMapping: ICustomizeCssMapping = {
  "--Zf-FormCheckbox-checkedBackgroundColor": "accentColor",
  "--Zf-FormCheckbox-checkedBorderColor": "accentColor",
  "--Zf-DayPicker-selectedNameColor": "accentColor",
  "--Zf-DayPicker-selectedNumberColor": "accentColor",
  "--Zf-DayPicker-lineBorderColor": "accentColor",
  "--Zf-TimePicker-selectedBorderColor": "accentColor",
  "--Zf-Widget-progressBackgroundColor": "accentColor",
  "--Zf-Widget-openButtonBackgroundColor": "accentColor",
  "--Zf-Services-activeServiceBorderColor": "accentColor",
  "--Zf-Services-detailIconFillColor": "accentColor",
  "--Zf-Services-activeShootTypeMarkerColor": "accentColor"
};

export abstract class CustomizeManager {
  public static apply() {
    const data = CustomizeManager.getData();

    _.forEach(cssMapping, (mapping, key) => {
      document.documentElement.style.setProperty(key, data[mapping]);
    });
  }

  public static reset() {
    for (const key of _.keys(cssMapping)) {
      document.documentElement.style.removeProperty(key);
    }
  }

  public static getData = _.memoize(
    (): ICustomizeData => {
      const urlParams = new URLSearchParams(window.location.search);
      const customizeData = {} as ICustomizeData;

      _.forEach(urlMapping, (mapping, key) => {
        const queryValue: any = urlParams.get(mapping.queryParam);
        customizeData[key as keyof ICustomizeData] = queryValue ? queryValue : mapping.defaultValue;
      });

      return customizeData;
    }
  );
}
