import { FormCheckbox } from "@zenfolio/core-components";
import React from "react";
import ModalInfo from "../../../../components/modalInfo";
import ModalNote from "../../../../components/modalNote";
import { ICancelBookingModalProps } from "../../../../store/modal/model";
import { hasActionCompleted } from "../../../../utilities/helpers";
import withModal, { InjectedModalProps } from "../../withModal";
import ModalDialog from "../modalDialog";
import styles from "./index.module.scss";
import { ICancel } from "./model";
import withStore from "./withStore";

interface ICancelBookingProps extends InjectedModalProps<ICancelBookingModalProps> {
  cancel: ICancel;
}

interface ICancelBookingState {
  note: string;
  confirmed: boolean;
}

class CancelBooking extends React.Component<ICancelBookingProps, ICancelBookingState> {
  public state: ICancelBookingState = { note: "", confirmed: false };

  public componentDidUpdate(prevProps: ICancelBookingProps) {
    const cancelHasCompleted = hasActionCompleted(prevProps.cancel.status, this.props.cancel.status);
    if (cancelHasCompleted) {
      this.props.onHideModal();
    }
  }

  public render() {
    const { note, confirmed } = this.state;
    const cancelPending = this.props.cancel.status === "Pending";

    return (
      <ModalDialog
        title="CANCEL BOOKING"
        okText="Yes, Cancel"
        cancelText="Close"
        onCancel={this.onCancel}
        onOk={this.onOk}
        okDisabled={!confirmed || cancelPending}
      >
        <div className={styles.container}>
          <ModalInfo
            title="Canceling this booking will not issue a refund. You will need to select the refund link to issue the refund."
            items={[
              "Canceling this appointment will send an email to the customer informing them of the cancellation.",
              "The time range of this appointment will be open and available for bookings."
            ]}
            important={true}
          />
          <ModalNote className={styles.note} note={note} onChange={this.onNoteChange} />
          <FormCheckbox className={styles.confirm} checked={confirmed} onChange={this.onConfirmChange}>
            Cancel this booking
          </FormCheckbox>
        </div>
      </ModalDialog>
    );
  }

  private onNoteChange = (note: string) => this.setState({ note });

  private onConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ confirmed: event.target.checked });

  private onCancel = () => this.props.onHideModal();

  private onOk = () => this.props.cancel.onCancel(this.props.bookingId, this.state.note);
}

export default withModal(withStore(CancelBooking));
