import React from "react";
import IIConProps from "../../IIconProps";

const GoTop = (props: IIConProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox={`${props.minX} ${props.minY} ${props.size} ${props.size}`}
  >
    <g fill="none" fillRule="evenodd">
      <rect width={props.size} height={props.size} fill="#000" fillOpacity="0.06" rx="5" />
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M26.695 23.565v12.732c0 .94-.77 1.703-1.718 1.703-.95 0-1.719-.763-1.719-1.703V23.582l-4.377 3.986a1.73 1.73 0 0 1-2.428-.102 1.693 1.693 0 0 1 .103-2.407l7.258-6.61a1.73 1.73 0 0 1 2.321-.004l7.305 6.61c.701.635.75 1.712.11 2.407a1.73 1.73 0 0 1-2.427.11l-4.428-4.007z"
      />
      <rect width="18" height="3" x="16" y="12" fill="#FFF" rx="1.5" />
    </g>
  </svg>
);

export default GoTop;
