import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IRescheduleOwnProps, IRescheduleProps } from ".";
import { reschedule as rescheduleAction } from "../../../../../store/bookings/actions";
import { IRescheduleSettings } from "../../../../../store/bookings/model";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ bookings: { reschedule } }: IAppState) {
  return { reschedule };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    reschedule: {
      onReschedule: (bookingId: string, settings: IRescheduleSettings, force?: boolean) =>
        dispatch(rescheduleAction(bookingId, settings, force))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: IRescheduleOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    reschedule: { ...stateProps.reschedule, ...dispatchProps.reschedule }
  } as IRescheduleProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
