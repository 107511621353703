import React from "react";
import IIConProps from "../IIconProps";

const AccountIcon = (props: IIConProps) => {
  const opacity = props.opacity || 1;
  const width = props.width || props.size;
  const height = props.height || props.size;
  const minX = props.minX || 0;
  const minY = props.minY || 0;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width as number - 2}
      height={height as number - 2}
      viewBox={`${minX} ${minY} ${width} ${height}`}
    >
      <g fillOpacity={opacity} fill="none" fillRule="evenodd" strokeWidth="1.75">
        <path d="M12 .875c3.073 0 5.854 1.245 7.867 3.258S23.125 8.927 23.125 12c0 3.073-1.245 5.854-3.258 7.867S15.073 23.125 12 23.125c-3.073 0-5.854-1.245-7.867-3.258S.875 15.073.875 12c0-3.073 1.245-5.854 3.258-7.867S8.927.875 12 .875z" />
        <g strokeLinecap="round" transform="translate(4.5 5)">
          <ellipse cx="7.5" cy="4.364" rx="4.5" ry="4.364" />
          <path d="M14.936 15.049c-.48-3.567-3.626-6.322-7.436-6.322-3.797 0-6.934 2.736-7.431 6.285" />
        </g>
      </g>
    </svg>
  );
};

export default AccountIcon;
