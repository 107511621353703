export class LazyLoader {
  private connected: boolean = false;
  private scheduledObservers: Element[] = [];
  private loadedObjects: Record<string, boolean> = {};
  private callbacks: Record<string, () => void> = {};
  private intersectionObserver: IntersectionObserver | null = null;

  public connect(options: IntersectionObserverInit) {
    if (!this.connected) {
      if (typeof IntersectionObserver !== "undefined") {
        this.intersectionObserver = new IntersectionObserver(this.observerCallback, options);
      } else {
        this.intersectionObserver = null;
      }

      this.connected = true;
      this.processScheduledObservers();
    }
  }

  public disconnect() {
    if (this.connected) {
      this.intersectionObserver?.disconnect();
      this.reset();
    }
  }

  public isLoaded(objectId: string) {
    return this.connected && (!this.intersectionObserver || this.loadedObjects[objectId] === true);
  }

  public markAsLoaded(objectId: string) {
    if (this.connected && this.intersectionObserver) {
      this.loadedObjects[objectId] = true;
    }
  }

  public observe(target: Element, onLazyLoaded: () => void) {
    if (target.id) {
      this.callbacks[target.id] = onLazyLoaded;
    }

    if (this.connected) {
      this.intersectionObserver?.observe(target);
    } else {
      this.scheduledObservers.push(target);
    }
  }

  public unobserve(target: Element, oldId?: string) {
    const targetId = oldId || target.id;
    if (targetId) {
      delete this.callbacks[targetId];
    }

    if (this.connected) {
      this.intersectionObserver?.unobserve(target);
    } else if (this.scheduledObservers.length > 0) {
      const targetIndex = this.scheduledObservers.indexOf(target);
      if (targetIndex >= 0) {
        this.scheduledObservers.splice(targetIndex, 1);
      }
    }
  }

  private processScheduledObservers() {
    if (this.intersectionObserver) {
      for (const target of this.scheduledObservers) {
        this.intersectionObserver.observe(target);
      }
    }

    this.scheduledObservers = [];
  }

  private reset() {
    this.connected = false;
    this.scheduledObservers = [];
    this.loadedObjects = {};
    this.callbacks = {};
    this.intersectionObserver = null;
  }

  private observerCallback = (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        if (entry.target.id) {
          this.callbacks[entry.target.id]?.();
        }

        this.unobserve(entry.target);
      }
    }
  };
}
