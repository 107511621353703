import React from "react";
import { Elements } from "react-stripe-elements";

class Checkout extends React.Component {
  public render() {
    return (
      <Elements
        fonts={[
          {
            cssSrc: "https://fonts.creatorcdn.com/apis/css?family=Nunito+Sans:300,400,600,700,900"
          }
        ]}
      >
        {this.props.children}
      </Elements>
    );
  }
}

export default Checkout;
