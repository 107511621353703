import { IVideo, PhotoOrVideo } from "../../models/models";
import { IMediaName, mediaUrlEncode } from "../blocks/url";
import { allImageSizes } from "../constant";
import { isVideo } from "../helpers";

export enum MESizeCodes {
  SIZE60x60 = "XXS",
  SIZE96x96 = "XS",
  SIZE264x264 = "S",
  SIZE352x352 = "M",
  SIZE500x500 = "L",
  SIZE685x685 = "XL",
  SIZE1170x1170 = "XXL",
  SIZE1488x1488 = "X2XL",
  SIZE2975x2975 = "X3XL"
}

export enum MESizeMethod {
  Contain = 1,
  Cover = 2,
  MaxPixels = 3
}

export interface IPhotoBase {
  id: string;
  name: string;
  title?: string;
  photoVersion?: number;
  watermarkVersion?: number;
  caption?: string;
}

export interface IPhotoFull extends IPhotoBase {
  width: number;
  height: number;
  altText?: string;
}

export interface IThumbnailBase {
  name: string;
  title?: string;
  thumbnailId?: string;
  durationMilliseconds?: number;
  videoVersion?: number;
  processingWatermark?: true;
}

export interface IVideoBase extends IThumbnailBase {
  id: string;
}

export interface IMediaSources {
  imageUrl: string;
  imageSrcSet: string;
}

interface ImageSize {
  width: number;
  height: number;
}

function safeGetLocalStorageItem(key: string) {
  if (typeof window === "undefined") {
    return "";
  }

  try {
    return window?.localStorage?.getItem?.(key) || "";
  } catch (e) {
    return "";
  }
}

const videoUploadEnabledLocally = safeGetLocalStorageItem("video-upload-enabled") === "true";

export function videoUploadEnabled() {
  return process.env.REACT_APP_ENABLE_VIDEO_UPLOAD === "true" || videoUploadEnabledLocally;
}

export function getImageUrl(
  appCdnUrl: string,
  photoId: string,
  sizeCode: MESizeCodes,
  includeWatermark = false,
  method = 1,
  includeSoftDeletedPhoto = false
) {
  let composeImgUrl = `${appCdnUrl}/${photoId}?sizeCode=${sizeCode}&method=${method}`;

  if (includeWatermark) {
    composeImgUrl = `${composeImgUrl}&addWatermark=${includeWatermark}`;
  }

  if (includeSoftDeletedPhoto) {
    composeImgUrl = `${composeImgUrl}&softDeletedOk=true`;
  }

  // temporary fix for caching issues (PORT-24334)
  const chunkSizeInMinutes = 30;
  const secondsSinceEpoch = Math.floor(Date.now() / 60000);
  const chunkNumber = Math.floor(secondsSinceEpoch / chunkSizeInMinutes);
  const endOfNextChunk = (chunkNumber + 2) * chunkSizeInMinutes;
  const watermarkVersion =
    (includeWatermark && typeof window !== "undefined" && safeGetLocalStorageItem("watermarkVersion")) || "0";

  composeImgUrl = `${composeImgUrl}&ts=${endOfNextChunk}-${watermarkVersion}`;

  return composeImgUrl;
}

export function getImageUrlOneDimension(
  appCdnUrl: string,
  photoId: string,
  sizeTypeCode: MESizeCodes,
  includeWatermark = false
) {
  return getImageUrl(appCdnUrl, photoId, sizeTypeCode, includeWatermark);
}

export function isImageReady(image: { width?: number; height?: number }) {
  return image.width != null && image.height != null;
}

export function isThumbnailReady(thumbnail: IThumbnailBase) {
  return !!thumbnail.thumbnailId && !thumbnail.processingWatermark;
}

export function isVideoReady(video: IThumbnailBase) {
  return video.durationMilliseconds != null;
}

export function getPhotoVersion(photo: IPhotoBase) {
  return photo.photoVersion || 1;
}

export function formatPhotoUrl(
  photoUrlTemplate: string,
  photo: IPhotoBase,
  sizeCode: MESizeCodes | null,
  resizeMethod: MESizeMethod
) {
  let photoUrl: string = sizeCode === null ? photoUrlTemplate : photoUrlTemplate.replace("~sizeCode~", sizeCode);

  photoUrl = photoUrl
    .replace("~resizeMethod~", resizeMethod.toString())
    .replace("~photoId~", photo.id)
    .replace("~photoVersion~", getPhotoVersion(photo).toString())
    .replace("~photoTitle~", mediaUrlEncode(photo as IMediaName, photo.id));

  // if photo has custom watermark and in the URL we have "addWatermark" equals "1" (second flag),
  // then we need to replace watermark version section in the URL with the custom one for this photo
  if (photo.watermarkVersion && photoUrl.match(/\/\d+\/(\d+)\/\d+\//)?.[1] === "1") {
    photoUrl = photoUrl.replace(/\/\d+-\d+-\d+\//, `/0-0-${photo.watermarkVersion}/`);
  }

  photoUrl = processUrlWithoutDomain(photoUrl);

  return photoUrl;
}

export function getVideoVersion(video: IThumbnailBase) {
  return video.videoVersion || 1;
}

export function formatPhotoSrcSet(photoUrlTemplate: string, photo: IPhotoBase, resizeMethod: MESizeMethod) {
  let sources: Array<string> = [];

  for (const imageSize of allImageSizes) {
    sources.push(formatPhotoUrl(photoUrlTemplate, photo, imageSize.code, resizeMethod) + ` ${imageSize.width}w`);
  }

  return sources.join(", ");
}

export function formatVideoSrcSet(thumnailUrlTemplate: string, photo: IPhotoBase, resizeMethod: MESizeMethod) {
  let sources: Array<string> = [];

  for (const imageSize of allImageSizes) {
    sources.push(
      formatThumbnailUrl(
        thumnailUrlTemplate,
        {
          ...photo,
          videoVersion: photo.photoVersion
        },
        imageSize.code,
        resizeMethod
      ) + ` ${imageSize.width}w`);
  }

  return sources.join(", ");
}

export function formatThumbnailUrl(
  thumbnailUrlTemplate: string,
  thumbnail: IThumbnailBase | IVideo,
  sizeCode: MESizeCodes,
  resizeMethod: MESizeMethod
) {
  let thumbnailUrl: string = thumbnailUrlTemplate.replace("~sizeCode~", sizeCode);

  thumbnailUrl = thumbnailUrl
    .replace("~resizeMethod~", resizeMethod.toString())
    .replace("~thumbnailId~", thumbnail.thumbnailId!)
    .replace("~videoVersion~", getVideoVersion(thumbnail).toString())
    .replace("~thumbnailTitle~", mediaUrlEncode(thumbnail as IMediaName, thumbnail.thumbnailId));

  thumbnailUrl = processUrlWithoutDomain(thumbnailUrl);

  return thumbnailUrl;
}

export function formatVideoUrl(videoUrlTemplate: string, video: IVideoBase) {
  let videoUrl: string = videoUrlTemplate
    .replace("~videoId~", video.id)
    .replace("~videoVersion~", getVideoVersion(video).toString());

  videoUrl = processUrlWithoutDomain(videoUrl);

  return videoUrl;
}

export function formatFaceUrl(faceUrlTemplate: string, faceId: string, resizeMethod: MESizeMethod) {
  return formatPhotoUrl(
    faceUrlTemplate,
    {
      id: faceId,
      name: "null"
    },
    null,
    resizeMethod
  );
}

export function formatMediaUrl(
  media: PhotoOrVideo,
  photoUrlTemplate: string,
  thumbnailUrlTemplate: string,
  sizeCode: MESizeCodes,
  resizeMethod: MESizeMethod
) {
  return isVideo(media)
    ? formatThumbnailUrl(thumbnailUrlTemplate, media, sizeCode, resizeMethod)
    : formatPhotoUrl(photoUrlTemplate, media, sizeCode, resizeMethod);
}

export function formatMediaSrcSet(
  media: PhotoOrVideo,
  photoUrlTemplate: string,
  thumbnailUrlTemplate: string,
  resizeMethod: MESizeMethod
) {
  return isVideo(media)
    ? formatVideoSrcSet(thumbnailUrlTemplate, media, resizeMethod)
    : formatPhotoSrcSet(photoUrlTemplate, media, resizeMethod);
}

export function formatMediaSources(
  media: PhotoOrVideo,
  photoUrlTemplate: string,
  thumbnailUrlTemplate: string,
  sizeCode: MESizeCodes,
  resizeMethod: MESizeMethod
): IMediaSources {
  if (isVideo(media)) {
    return {
      imageUrl: formatThumbnailUrl(thumbnailUrlTemplate, media, sizeCode, resizeMethod),
      imageSrcSet: formatVideoSrcSet(thumbnailUrlTemplate, media, resizeMethod)
    };
  } else {
    return {
      imageUrl: formatPhotoUrl(photoUrlTemplate, media, sizeCode, resizeMethod),
      imageSrcSet: formatPhotoSrcSet(photoUrlTemplate, media, resizeMethod)
    };
  }
}

export function getImageSizeCode(width: number, height: number): MESizeCodes {
  const devicePixelRatio = (typeof window !== "undefined" && window.devicePixelRatio) || 1;
  const physicalWidth = width * devicePixelRatio;
  const physicalHeight = height * devicePixelRatio;
  const size =
    allImageSizes.find(size => size.width >= physicalWidth && size.height >= physicalHeight) ||
    allImageSizes[allImageSizes.length - 1];

  return size.code;
}

export function getImageSizeCodeForContainMethod(
  photoWidth: number,
  photoHeight: number,
  areaWidth: number,
  areaHeight: number,
  maxSizeCode?: MESizeCodes
): MESizeCodes {
  let result: MESizeCodes;
  const devicePixelRatio = (typeof window !== "undefined" && window.devicePixelRatio) || 1;
  const physicalAreWidth = areaWidth * devicePixelRatio;
  const physicalAreaHeight = areaHeight * devicePixelRatio;

  for (const imageSize of allImageSizes) {
    result = imageSize.code;

    if (imageSize.code === maxSizeCode || photoWidth < imageSize.width || photoHeight < imageSize.height) {
      return result;
    }

    const calcSize = getImageSizeForContainMethod(photoWidth, photoHeight, imageSize.width, imageSize.height);

    if (calcSize.width >= physicalAreWidth && calcSize.height >= physicalAreaHeight) {
      return result;
    }
  }

  return result!;
}

function getImageSizeForContainMethod(
  photoWidth: number,
  photoHeight: number,
  sizeCodeWidth: number,
  sizeCodeHeight: number
): ImageSize {
  let calcWidth: number;
  let calcHeight: number;
  const photoRatio = photoWidth / photoHeight;
  const sizeCodeRatio = sizeCodeWidth / sizeCodeHeight;

  if (photoRatio > sizeCodeRatio) {
    calcWidth = sizeCodeWidth;
    calcHeight = Math.round(sizeCodeWidth / photoRatio);
  } else {
    calcHeight = sizeCodeHeight;
    calcWidth = Math.round(sizeCodeHeight * photoRatio);
  }

  return { width: calcWidth, height: calcHeight };
}

function processUrlWithoutDomain(url: string) {
  if (url[0] === "/") {
    return `${process.env.REACT_APP_MEDIA_CDN_HOST || ""}${url}`;
  }

  return url;
}
