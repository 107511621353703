import { combineReducers } from "redux";
import IError from "../../../utilities/error";
import { ActionStatus } from "../../common";
import { DisconnectAction, LoadCalendarsAction, SetupConnectAction } from "./actions";
import { ActionType } from "./constants";
import { IConnectSetup, IGoogleCalendar } from "./model";

export interface ISetupConnectState {
  status: ActionStatus;
  redirectUri: string | null;
  connectSetup: IConnectSetup | null;
  error: IError | null;
}

const initialSetupConnect: ISetupConnectState = {
  status: "Init",
  redirectUri: null,
  connectSetup: null,
  error: null
};

export const setupConnect = (
  state: ISetupConnectState = initialSetupConnect,
  action: SetupConnectAction
): ISetupConnectState => {
  switch (action.type) {
    case ActionType.SETUP_CONNECT_STARTED:
      return {
        status: "Pending",
        redirectUri: action.redirectUri,
        connectSetup: null,
        error: null
      };
    case ActionType.SETUP_CONNECT_SUCCESS:
      return action.redirectUri === state.redirectUri
        ? {
            ...state,
            status: "Success",
            connectSetup: action.connectSetup,
            error: null
          }
        : state;
    case ActionType.SETUP_CONNECT_ERROR:
      return action.redirectUri === state.redirectUri
        ? {
            ...state,
            status: "Error",
            connectSetup: null,
            error: action.error
          }
        : state;
    default:
      return state;
  }
};

export interface ILoadCalendarsState {
  status: ActionStatus;
  calendars: IGoogleCalendar[] | null;
  error: IError | null;
}

const initialLoadCalendars: ILoadCalendarsState = {
  status: "Init",
  calendars: null,
  error: null
};

export const loadCalendars = (
  state: ILoadCalendarsState = initialLoadCalendars,
  action: LoadCalendarsAction
): ILoadCalendarsState => {
  switch (action.type) {
    case ActionType.LOAD_CALENDARS_STARTED:
      return {
        status: "Pending",
        calendars: null,
        error: null
      };
    case ActionType.LOAD_CALENDARS_SUCCESS:
      return {
        ...state,
        status: "Success",
        calendars: action.calendars,
        error: null
      };
    case ActionType.LOAD_CALENDARS_ERROR:
      return {
        ...state,
        status: "Error",
        calendars: null,
        error: action.error
      };
    case ActionType.LOAD_CALENDARS_RESET:
      return initialLoadCalendars;
    default:
      return state;
  }
};

export interface IDisconnectState {
  status: ActionStatus;
  error: IError | null;
}

const initialDisconnect: IDisconnectState = {
  status: "Init",
  error: null
};

export const disconnect = (state: IDisconnectState = initialDisconnect, action: DisconnectAction): IDisconnectState => {
  switch (action.type) {
    case ActionType.DISCONNECT_STARTED:
      return {
        status: "Pending",
        error: null
      };
    case ActionType.DISCONNECT_SUCCESS:
      return {
        ...state,
        status: "Success",
        error: null
      };

    case ActionType.DISCONNECT_ERROR:
      return {
        ...state,
        status: "Error",
        error: action.error
      };
    default:
      return state;
  }
};

export default combineReducers({
  setupConnect,
  loadCalendars,
  disconnect
});
