import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAddInvoiceOwnProps, IAddInvoiceSetupProps } from ".";
import { addInvoice as addInvoiceAction, calculateTaxForNewInvoice } from "../../../../../store/billing/actions";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ billing: { addInvoice, calculateTaxForNewInvoice: calculateTax } }: IAppState) {
  return { addInvoice, calculateTax };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    addInvoice: {
      addInvoice: (
        bookingId: string,
        amount: number,
        sendNotification: boolean,
        clientNote?: string,
        internalNote?: string
      ) => dispatch(addInvoiceAction(bookingId, amount, sendNotification, clientNote, internalNote))
    },
    calculateTax: {
      calculateTax: (bookingId: string, amount: number) => dispatch(calculateTaxForNewInvoice(bookingId, amount))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: IAddInvoiceOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    addInvoice: { ...stateProps.addInvoice, ...dispatchProps.addInvoice },
    calculateTax: { ...stateProps.calculateTax, ...dispatchProps.calculateTax }
  } as IAddInvoiceSetupProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
