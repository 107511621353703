import React from "react";
import IIConProps from "components/Icons/IIconProps";

const IconHide = (props: IIConProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd" stroke={props.color} strokeWidth="1.33">
        <path
          fillRule="nonzero"
          d="M20.632 7.8c-.781-.844-4.932-5.05-9.945-5.05-5.012 0-9.164 4.206-9.946 5.049l-.741.8.741.8c.782.844 4.934 5.05 9.946 5.05 5.016 0 9.165-4.207 9.945-5.05l.741-.8-.74-.8zm-9.945 4.113c-1.453 0-2.636-1.487-2.636-3.314s1.183-3.313 2.636-3.313 2.635 1.486 2.635 3.313c0 1.827-1.182 3.314-2.635 3.314z"
          transform="translate(1.25 3)"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.042 17.875L9.25 11.875M12.833 6.417L14.537 4.031 17.417 0"
          transform="translate(1.25 3)"
        />
      </g>
    </svg>
  );
};

export default IconHide;
