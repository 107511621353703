import {
  IAlbumContentState,
  ICollection,
  IExpirationData,
  IPhoto,
  IPresent,
  IVideo,
  PhotoOrVideo
} from "../../../models/models";

function safeGetLocalStorageItem(key: string) {
  if (typeof window === "undefined") {
    return "";
  }

  try {
    return window?.localStorage?.getItem?.(key) || "";
  } catch (e) {
    return "";
  }
}

const videoUploadEnabledLocally = safeGetLocalStorageItem("video-upload-enabled") === "true";

function videoUploadEnabled() {
  return process.env.REACT_APP_ENABLE_VIDEO_UPLOAD === "true" || videoUploadEnabledLocally;
}

export interface ICollectionOptions {
  collectionId: string;
  name: string;
  mediaSortIndices?: any[];
}

export interface IPageableContext {
  firstItem: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  lastItem: number;
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export interface IClientViewGalleryState extends IAlbumContentState {
  photographerId: string;
  albumAlias: string;
  isProtected: boolean;
  visitorEmail: string;
  visitorId: string;
  isSystemAlbum: boolean;
  askPassword: boolean;
  invalidPassword: boolean;
  logged: boolean;
  passwordError: string;
  isVisitorAllowed: boolean;
  isVisitorEmailRequired: boolean;
  hasClients: boolean;
  hasGuests: boolean;
  isEcom: boolean;
  pricingId: string;
  albumPresent: IPresent | null;
  collections?: ICollection[];
  collection?: string;
  parentFolderId?: string;
  expiration?: IExpirationData;
  mediaFiltered?: PhotoOrVideo[];
  medias?: PhotoOrVideo[] | null;
  pageableContext?: IPageableContext;
  coverPhoto: IPhoto | null;
  coverVideo: IVideo | null;
}

export interface IClientViewFavoritesState {
  visitorId: string;
  visitorEmail: string;
  favoritePhotos: IFavoritePhoto[];
  favoriteVideos: IFavoriteVideo[];
  favoritesLoaded: boolean;
  favoritesUpdated: boolean;
  code: number | null;
  isFetching: boolean;
  isUpdating: boolean;
  error: string;
  status: number | null;
  actionCallback: (() => void) | null;
}

export interface IUnreadComment {
  threadId: string;
  photoId: string | null;
  videoId: string | null;
  hasUnreadComment: boolean;
  hasCommented: boolean;
}

export interface IClientViewCommentingState {
  commentedMedia: IUnreadComment[];
  isFetching: boolean;
  code: number | null;
  error: string;
  status: number | null;
}

export interface IGalleryDownloadsData {
  photoIds: string[];
  videoIds: string[];
  totalPhotoDownloads: number;
  remainingPhotoDownloads: number;
  totalVideoDownloads: number;
  remainingVideoDownloads: number;
  availableMultiDownloadResolutions: number[];
}

export interface IClientViewDownloadState {
  isFetching: boolean;
  error: string;
  downloads: IGalleryDownloadsData;
  galleryPhotosDownloadSuccess: boolean;
  galleryVideosDownloadSuccess: boolean;
}

export enum IDENTITY_ACCESS_LEVEL {
  CLIENT = 1,
  GUEST = 2,
  VISITOR = 3
}

export interface IIdentityState {
  accessLevel: IDENTITY_ACCESS_LEVEL;
  isVerifiedInClientPortal: boolean;
  token: string;
  isFetching: boolean;
  error: string;
  code: number;
  status: number;
  userName: string;
  userEmail: string;
  resendVerifiedEmail: boolean;
}

export interface IFavoritePhoto {
  visitorId: string;
  photoId: string;
}

export interface IFavoriteVideo {
  visitorId: string;
  videoId: string;
}

export interface IClientViewEcomState {
  cart?: ICart;
}

export interface ICart {
  id: string;
  price: number;
  currency: string;
  cartItems: ICartItem[];
}

export interface ICartItem {
  id: string;
  type: string;
  price: number;
  currency: string;
  packageId?: string;
  package?: any;
  productCode?: string;
  productSnapshots: any[];
  physicalProductSnapshots?: any;
}

export enum GalleryPresentSettingType {
  FontStyle = 0,
  GridBackgroundColor = 1,
  GridType = 2,
  TitleStyle = 3,
  HideTitle = 4,
  HideDate = 5,
  FocalX = 6,
  FocalY = 7,
  MobileGridType = 8,
  ShowCaptions = 9,
  LayoutOptionsContent = 10,
  LayoutOptionsShow = 11
}

export enum GalleryPresentSettingList {
  None = 0,
  Serif = 1,
  SansSerif = 2,
  White = 3,
  Cream = 4,
  Gray = 5,
  PortraitLarge = 6,
  LandscapeLarge = 7,
  GridLarge = 8,
  Stacked = 9,
  Cover = 10,
  TitleOnly = 11,
  Black = 12,
  GridSmall = 13,
  PortraitSmall = 14,
  LandscapeSmall = 15,
  HideTitle = 16,
  HideDate = 17,
  FocalX = 18,
  FocalY = 19,
  OneColumn = 20,
  TwoColumn = 21,
  ShowCaptions = 22,
  LayoutOptionsContentFilenames = 23,
  LayoutOptionsContentSequenceNumbers = 24,
  LayoutOptionsShowOnlyOnHover = 25,
  LayoutOptionsShowAlways = 26,
  NoCover = 27,
  LayoutOptionsContentNone = 28
}

export const favoritesInitialState: IClientViewFavoritesState = {
  visitorId: "",
  visitorEmail: "",
  favoritePhotos: [],
  favoriteVideos: [],
  favoritesLoaded: false,
  favoritesUpdated: false,
  code: null,
  isFetching: false,
  isUpdating: false,
  error: "",
  status: null,
  actionCallback: null
};

export enum cartTypes {
  PACKAGE = "Package",
  DIGITAL = "DigitalALaCarte",
  PHYSICAL = "PhysicalALaCarte"
}

export const COLLECTION = {
  ALL_MEDIA_ID: "allMedia",
  ALL_MEDIA: videoUploadEnabled() ? "All Media" : "All Photos",
  FAVORITE_MEDIA_ID: "favoriteMedia",
  FAVORITE_MEDIA: "Favorites",
  EXCLUDE_COLLECTION: "Not in a Collection",
  ICON_LABEL: "Filter"
};

export enum PreviewModes {
  Desktop = 1,
  Tablet = 2,
  Mobile = 3
}
