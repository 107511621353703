import classNames from "classnames";
import moment from "moment";
import React from "react";
import DayPicker, { DayStatus } from "../DayPicker";
import MonthPicker from "../MonthPicker";
import styles from "./datePicker.module.scss";

interface IDatePickerProps {
  defaultDate?: moment.Moment;
  date?: moment.Moment;
  maxDate?: moment.Moment;
  minDate?: moment.Moment;
  dayStatuses?: DayStatus[];
  onChange?: (date: moment.Moment) => void;
  className?: string;
  style?: React.CSSProperties;
}

interface IDatePickerState {
  date?: moment.Moment;
}

class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {
  public state: IDatePickerState = {};

  public render() {
    const { className, minDate, maxDate, dayStatuses, style } = this.props;
    const date = this.date;

    return (
      <div className={classNames(styles.container, className)} style={style}>
        <MonthPicker month={date} minMonth={minDate} maxMonth={maxDate} onChange={this.onMonthChange} />
        <DayPicker
          day={date}
          minDay={minDate}
          maxDay={maxDate}
          dayStatuses={dayStatuses}
          onChange={this.onDayChange}
          className={styles.day}
        />
      </div>
    );
  }

  private get date() {
    const { props, state } = this;

    return (props.date !== undefined
      ? props.date
      : state.date !== undefined
      ? state.date
      : props.defaultDate || this.now
    )
      .clone()
      .startOf("day");
  }

  private changeDate = (date: moment.Moment) => {
    const onChange = (d: moment.Moment) => {
      if (this.props.onChange) {
        this.props.onChange(d);
      }
    };

    if (this.props.date === undefined) {
      this.setState({ date }, () => onChange(this.date));
    } else {
      onChange(date);
    }
  };

  private onMonthChange = (month: moment.Moment) => {
    const { minDate, maxDate } = this.props;
    const getWeek = (val: moment.Moment) => val.isoWeekYear() + (val.isoWeekday() === 7 ? 1 : 0);
    const week = getWeek(month);

    let date = month.clone().isoWeekday(this.date.isoWeekday());
    date = getWeek(date) === week ? date : getWeek(date) < week ? date.add(1, "week") : date.add(-1, "week");
    date = date.isSame(month, "month") ? date : month;

    date = !minDate || date.isSameOrAfter(minDate, "day") ? date : minDate.clone().startOf("day");
    date = !maxDate || date.isSameOrBefore(maxDate, "day") ? date : maxDate.clone().startOf("day");

    this.changeDate(date);
  };

  private onDayChange = (day: moment.Moment) => this.changeDate(day);

  private readonly now = moment();
}

export default DatePicker;
