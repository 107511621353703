import React from "react";
import IIConProps from "../../IIconProps";

const Share = ({ minX = 0, minY = 0, ...props }: IIConProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox={`${minX} ${minY} ${32} ${32}`}
  >
    <path
      fill={props.color}
      fillRule="nonzero"
      d="M14.082 18.215l5.195 2.6a5 5 0 0 1 3.405-1.325c2.722 0 4.943 2.149 4.943 4.818 0 2.669-2.221 4.817-4.943 4.817-2.722 0-4.943-2.148-4.943-4.817 0-.564.099-1.105.28-1.607l-5.052-2.528a4.993 4.993 0 0 1-3.649 1.567c-2.722 0-4.943-2.148-4.943-4.817s2.221-4.817 4.943-4.817a5 5 0 0 1 3.384 1.305l5.3-4.164a4.703 4.703 0 0 1-.263-1.555c0-2.669 2.221-4.817 4.943-4.817 2.722 0 4.943 2.148 4.943 4.817 0 2.67-2.221 4.818-4.943 4.818-1.34 0-2.559-.521-3.45-1.368l-5.266 4.137a4.711 4.711 0 0 1 .116 2.936zM9.318 19.49c1.496 0 2.693-1.158 2.693-2.567 0-1.41-1.197-2.567-2.693-2.567s-2.693 1.158-2.693 2.567c0 1.41 1.197 2.567 2.693 2.567zm13.364-9.23c1.496 0 2.693-1.158 2.693-2.568 0-1.409-1.197-2.567-2.693-2.567s-2.693 1.158-2.693 2.567c0 1.41 1.197 2.568 2.693 2.568zm0 16.615c1.496 0 2.693-1.158 2.693-2.567 0-1.41-1.197-2.568-2.693-2.568s-2.693 1.158-2.693 2.568c0 1.409 1.197 2.567 2.693 2.567z"
      opacity={props.opacity}
    />
  </svg>
);

export default Share;
