export enum WidgetActionType {
  LOAD_WIDGET_STATE_STARTED = "LOAD_WIDGET_STATE_STARTED",
  LOAD_WIDGET_STATE_SUCCESS = "LOAD_WIDGET_STATE_SUCCESS",
  LOAD_WIDGET_STATE_ERROR = "LOAD_WIDGET_STATE_ERROR",
  RESET_WIDGET_STATE = "RESET_WIDGET_STATE",

  VALIDATE_LOCATION_STARTED = "VALIDATE_LOCATION_STARTED",
  VALIDATE_LOCATION_SUCCESS = "VALIDATE_LOCATION_SUCCESS",
  VALIDATE_LOCATION_ERROR = "VALIDATE_LOCATION_ERROR",

  LOAD_AVAILABILITY_STARTED = "WIDGET_LOAD_AVAILABILITY_STARTED",
  LOAD_AVAILABILITY_SUCCESS = "WIDGET_LOAD_AVAILABILITY_SUCCESS",
  LOAD_AVAILABILITY_ERROR = "WIDGET_LOAD_AVAILABILITY_ERROR",

  CREATE_BOOKING_STARTED = "CREATE_BOOKING_STARTED",
  CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS",
  CREATE_BOOKING_ERROR = "CREATE_BOOKING_ERROR",
  RESET_BOOKING_ERROR = "RESET_BOOKING_ERROR",

  LOAD_INVOICE_STATE_STARTED = "LOAD_INVOICE_STATE_STARTED",
  LOAD_INVOICE_STATE_SUCCESS = "LOAD_INVOICE_STATE_SUCCESS",
  LOAD_INVOICE_STATE_ERROR = "LOAD_INVOICE_STATE_ERROR",

  PAY_INVOICE_STARTED = "PAY_INVOICE_STARTED",
  PAY_INVOICE_SUCCESS = "PAY_INVOICE_SUCCESS",
  PAY_INVOICE_ERROR = "PAY_INVOICE_ERROR",

  STRIPE_FACTORY_READY = "STRIPE_FACTORY_READY",
  STRIPE_ACCOUNT_ID_READY = "STRIPE_ACCOUNT_ID_READY",

  CALCULATE_SERVICE_TAX_STARTED = "CALCULATE_SERVICE_TAX_STARTED",
  CALCULATE_SERVICE_TAX_SUCCESS = "CALCULATE_SERVICE_TAX_SUCCESS",
  CALCULATE_SERVICE_TAX_ERROR = "CALCULATE_SERVICE_TAX_ERROR"
}
