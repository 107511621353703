import React from "react";
import IIConProps from "../../IIconProps";

const Favorite = (props: IIConProps) => {
  const opacity = props.opacity || 1;
  const width = props.width || props.size;
  const height = props.height || props.size;
  const minX = props.minX || 0;
  const minY = props.minY || 0;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`${minX} ${minY} ${32} ${32}`}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={props.color}
        strokeWidth="2.25"
        d="M16 26.686l.025-.016c5.517-3.386 9.005-6.975 10.89-10.543 1.138-2.158 1.493-3.86 1.46-4.895v.003l-.003-.072c0-3.358-2.59-6.038-5.827-6.038-2 0-3.812 1.07-5.731 3.08L16 9.058l-.814-.853c-1.92-2.01-3.731-3.08-5.732-3.08-3.312 0-5.829 2.45-5.829 5.732 0 .414.052.957.213 1.686.251 1.142.706 2.386 1.416 3.708 1.93 3.596 5.384 7.146 10.746 10.435z"
        opacity={opacity}
      />
    </svg>
  );
};

export default Favorite;
