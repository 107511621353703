import React from "react";
import cx from "classnames";
import styles from "./myAccount.module.scss";
import iconMyGallery from "icons/icon-my-galleries.svg";
import iconBookings from "icons/icon-booking.svg";
import iconInvoices from "icons/icon-invoices.svg";
import iconOrders from "icons/icon-orders.svg";
import iconSettings from "icons/icon-settings.svg";
import iconSignOut from "icons/icon-sign-out.svg";

export enum ACCOUNT_OPTION_TYPE {
  MY_GALLERIES = "My Galleries",
  BOOKINGS = "Bookings",
  INVOICES = "Invoices",
  ORDERS = "Orders",
  SETTINGS = "Settings"
}

export interface IVisibleOption {
  type: ACCOUNT_OPTION_TYPE;
  href: string;
  quantity?: number;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export interface IMyAccountProps {
  className?: string;
  userName: string;
  userEmail: string;
  style?: any;
  visibleOptions?: IVisibleOption[];
  onLogOutClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onGalleriesClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

interface IMenuOption {
  iconSrc: string;
  label: ACCOUNT_OPTION_TYPE;
  href?: string;
  quantity?: number;
  onCLick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const MyAccount: React.FC<IMyAccountProps> = props => {
  const getMenuOption = (type: ACCOUNT_OPTION_TYPE, icon: string) => {
    const option = props.visibleOptions?.find(visibleOption => visibleOption.type === type);

    return {
      label: type,
      iconSrc: icon,
      href: option?.href,
      quantity: option?.quantity,
      onCLick: option?.onClick
    };
  };

  const menuOptions: IMenuOption[] = [
    getMenuOption(ACCOUNT_OPTION_TYPE.MY_GALLERIES, iconMyGallery),
    getMenuOption(ACCOUNT_OPTION_TYPE.BOOKINGS, iconBookings),
    getMenuOption(ACCOUNT_OPTION_TYPE.INVOICES, iconInvoices),
    getMenuOption(ACCOUNT_OPTION_TYPE.ORDERS, iconOrders),
    getMenuOption(ACCOUNT_OPTION_TYPE.SETTINGS, iconSettings)
  ];

  const renderOptions: IMenuOption[] = menuOptions.filter((option: IMenuOption) =>
    props.visibleOptions ? props.visibleOptions.some(optionVisible => optionVisible.type === option.label) : false
  );

  return (
    <React.Fragment>
      <div style={props.style} className={cx(styles.userMenuLayer, props.className)}>
        <div className={styles.arrowBox}></div>
        <div className={styles.userMenuBox}>
          <div className={styles.userHead}>
            <h4>{props.userName}</h4>
            <p>{props.userEmail}</p>
          </div>
          <ul className={styles.displayNone}>
            {renderOptions.map((option: IMenuOption, index: number) => {
              return (
                <li key={`my-account-option-${index}`}>
                  <div className={styles.listOptionContainer}>
                    <a href={option.href} onClick={option.onCLick}>
                      <span>
                        <img src={option.iconSrc} width="24" />
                      </span>
                      <span>{option.label}</span>
                      {option.quantity && <span className={styles.quantityNum}>{option.quantity}</span>}
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className={styles.signOut}>
            <a href="#" onClick={props.onLogOutClick}>
              <span>
                <img src={iconSignOut} width="24" />
              </span>
              <span>Sign Out</span>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyAccount;
