import React from "react";
import cx from "classnames";
import MarkButton from "../MarkButton";
import Toolbar from "../Toolbar";
import styles from "./fixedToolbar.module.scss";

interface IFixedToolbar {
  className?: string;
  extraText?: string;
  extraComponent?: JSX.Element;
}

const FixedToolbar = (props: IFixedToolbar) => {
  return (
    <Toolbar className={props.className || ""}>
      <label>{props.extraText}</label>
      {props.extraComponent && <div className={styles.extraComponent}>{props.extraComponent}</div>}
      <MarkButton format="bold" iconClassName={"fa fa-bold " + styles.buttonIcon} />
      <MarkButton format="italic" iconClassName={cx("fa fa-italic", styles.buttonIcon)} />
      <MarkButton format="underline" iconClassName={cx("fa fa-underline", styles.buttonIcon)} />
    </Toolbar>
  );
};

export default FixedToolbar;
