import checkMarkIconUrl from "icons/checkMarkWhite.svg";
import videojs from "video.js";
import { Quality } from ".";
import { getQualityName } from "../utils";
import styles from "./item.module.scss";

const MenuItem = videojs.getComponent("MenuItem");

export interface IItemOptions extends videojs.MenuItemOptions {
  quality: Quality;
}

class Item extends MenuItem {
  constructor(player: videojs.Player, options: IItemOptions) {
    const { quality } = options;
    super(player, { ...options, label: quality === "auto" ? "Auto" : getQualityName(quality) });

    this.addClass(styles.container);
    this.selected(!!options.selected);
  }

  public initChildren(): void {
    super.initChildren();

    const imgEl = new Image(24, 21);
    imgEl.src = checkMarkIconUrl;
    imgEl.alt = "Selected";
    imgEl.classList.add(styles.mark);
    this.el().prepend(imgEl);
  }

  public selected(selected: boolean): void {
    super.selected(selected);
    this.toggleClass(styles.selected, selected);
  }

  public handleClick(event: videojs.EventTarget.Event) {
    super.handleClick(event);
    this.trigger("select");
  }
}

export default Item;
