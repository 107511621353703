import React, { PureComponent } from "react";
import styles from "./carousel.module.scss";
import cx from "classnames";
import Caret from "../Icons/Caret";

export interface ISmoothCarouselProps {
  customClassName?: string;
  childrenClassName?: string;
  nextBtnStyle?: string;
  backBtnStyle?: string;
  children?: React.ReactNode;
  iconColor?: string;
  // the width distance (in px) to slide when click next button
  slideStep?: number;
}

interface ISmoothCarouselState {
  showBackButton: boolean;
  showNextButton: boolean;
  scrollOffset: number;
}

class SmoothCarousel extends PureComponent<
  ISmoothCarouselProps,
  ISmoothCarouselState
> {
  public state = {
    showBackButton: false,
    showNextButton: true,
    scrollOffset: 0
  };
  private carouselRef: React.RefObject<HTMLDivElement>;

  constructor(props: ISmoothCarouselProps) {
    super(props);
    this.carouselRef = React.createRef();
  }

  private onNextClick = () => {
    const carousel = this.carouselRef.current;
    if (carousel) {
      const newScroll = carousel.scrollLeft + (this.props.slideStep || carousel.offsetWidth);
      carousel.scrollLeft = newScroll;
      this.setState({ scrollOffset: newScroll }, () =>
        this.checkShowBackButton()
      );
    }
  };

  private onBackClick = () => {
    const carousel = this.carouselRef.current;
    if (carousel) {
      let newScroll = carousel.scrollLeft - (this.props.slideStep || carousel.offsetWidth);
      if (newScroll < 0) {
        newScroll = 0;
      }
      carousel.scrollLeft = newScroll;
      this.setState({ scrollOffset: newScroll }, () =>
        this.checkShowBackButton()
      );
    }
  };

  private checkShowBackButton = () => {
    const carousel = this.carouselRef.current;
    const { scrollOffset } = this.state;
    if (carousel) {
      if (scrollOffset === 0) {
        this.setState({ showBackButton: false });
      } else {
        this.setState({ showBackButton: true });
      }
    }
  };

  private checkShowNextButton = () => {
    // setTimeOut is to fix issue https://github.com/facebook/react/issues/13108

    setTimeout(() => {
      const carousel = this.carouselRef.current;
      if (carousel) {
        if (
          carousel.scrollWidth - this.state.scrollOffset ===
          carousel.clientWidth
        ) {
          this.setState({ showNextButton: false });
        } else {
          this.setState({ showNextButton: true });
        }
      }
    }, 1);
  };

  public componentDidMount() {
    window.addEventListener("resize", this.checkShowNextButton);
    this.checkShowNextButton();
  }

  public componentDidUpdate(prevProps: ISmoothCarouselProps) {
    if (this.props.children !== prevProps.children) {
      const carousel = this.carouselRef.current;

      if (carousel && carousel.scrollLeft === 0) {
        this.setState({ scrollOffset: 0 }, () => {
          this.checkShowNextButton();
          this.checkShowBackButton();
        });
      }
    } else {
      this.checkShowNextButton();
      this.checkShowBackButton();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.checkShowNextButton);
  }

   public render() {
    const {
      iconColor,
      nextBtnStyle,
      backBtnStyle,
      customClassName,
      childrenClassName
    } = this.props;
    const color = iconColor ? iconColor : "#757575";
    return (
      <div className={cx(styles.container, customClassName)}>
        {this.state.showNextButton ? (
          <div
            className={cx(styles.nextBtn, nextBtnStyle)}
            onClick={this.onNextClick}
          >
            <Caret color={color} size={14} minX={0} minY={0} open={false} />
          </div>
        ) : null}

        {this.state.showBackButton ? (
          <div
            className={cx(styles.backBtn, backBtnStyle)}
            onClick={this.onBackClick}
          >
            <Caret color={color} size={14} minX={0} minY={0} open={false} />
          </div>
        ) : null}
        <div
          ref={this.carouselRef}
          className={cx(styles.children, childrenClassName)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default SmoothCarousel;
