import moment from "moment";

export function formatTime(time: number, format: string = "hh:mm A"): string {
  return moment
    .utc()
    .startOf("day")
    .add(time, "minutes")
    .format(format);
}

export function dayOfWeekName(dayOfWeek: number): string {
  switch (dayOfWeek) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "Sunday";
  }
}
