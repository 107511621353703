import cx from "classnames";
import React from "react";
import styles from "./button.module.scss";

interface ITextEditorButtonProps {
  className?: string;
  reversed?: boolean;
  isActive: boolean;
  activeClassName?: string;
  onMouseDown: (event: any) => void;
  ref?: React.Ref<HTMLSpanElement>;
}

const Button: React.ComponentType<ITextEditorButtonProps> = React.forwardRef(
  (
    { className, activeClassName, reversed, isActive, onMouseDown, ...props }: ITextEditorButtonProps,
    ref?: React.Ref<HTMLSpanElement>
  ) => (
    <span
      {...props}
      ref={ref}
      className={cx(
        className,
        styles.zenContainer,
        reversed ? styles.reversed : "",
        isActive ? cx(styles.active, activeClassName) : ""
      )}
      onMouseDown={onMouseDown}
    />
  )
);

export default Button;
