import { IAddress, statesUS, Utilities, Validation } from "@zenfolio/core-components";
import { AddressInputValue } from "@zenfolio/core-components/dist/components/AddressInput/model";
import _ from "lodash";
import * as React from "react";
import { ReactStripeElements } from "react-stripe-elements";
import { IShootLocation } from "../../store/profile/shootLocation/model";
import { IShoppingAddress } from "../../store/widget/model";
import Auxiliary from "../../utilities/auxiliary";
import { isAddress } from "../addressInput";
import { IShoppingAddressComponents, IState } from "./contracts";

export function renderAddress(address: IShootLocation, includeLocationDetails: boolean): React.ReactNode {
  return (
    <Auxiliary>
      {`${address.houseNumber} ${address.streetName}${
        includeLocationDetails && address.locationDetails ? `, ${address.locationDetails}` : ""
      }`}
      <br />
      {address.city}, {address.state} {address.zipCode}
    </Auxiliary>
  );
}

export function scrollToSelector(
  selector: string,
  containerRef: React.RefObject<HTMLDivElement>,
  onEnsureVisible: (top: number, height: number, minimum?: boolean) => void,
  customHeight?: number
) {
  const padding = 8;
  if (containerRef.current) {
    const element = containerRef.current.querySelector(selector) as HTMLElement;
    if (element) {
      onEnsureVisible(
        element.offsetTop - padding,
        (customHeight != null ? customHeight : element.offsetHeight) + 2 * padding,
        customHeight != null
      );
    }
  }
}

export function buildPaymentMethodOptions(
  email: string,
  phoneNumber: string,
  cardholderName: string,
  billingAddress: IShoppingAddress
): stripe.CreatePaymentMethodOptions {
  return {
    billing_details: {
      email,
      name: cardholderName,
      phone: phoneNumber,
      address: {
        line1: formatStreet(billingAddress),
        city: billingAddress.city,
        country: billingAddress.country,
        line2: billingAddress.addressLine2 || "",
        postal_code: billingAddress.zipCode,
        state: billingAddress.state
      }
    }
  };
}

export function formatStreet(address: IAddress) {
  return _.chain([address.houseNumber, address.streetName])
    .filter(Validation.isNotEmptyString)
    .join(" ")
    .value();
}

export function buildTokenOptions(
  cardholderName: string,
  billingAddress: IShoppingAddress
): ReactStripeElements.TokenOptions {
  return {
    name: cardholderName,
    address_line1: formatStreet(billingAddress),
    address_line2: billingAddress.addressLine2 || "",
    address_city: billingAddress.city,
    address_state: billingAddress.state,
    address_zip: billingAddress.zipCode,
    address_country: billingAddress.country,
    currency: "usd"
  };
}

export const allStates: IState[] = _.map(statesUS, state => ({ value: state.value, label: state.value }));

export function isValid(components: IShoppingAddressComponents, zipCodeError: boolean) {
  return (
    validateState(components.state) &&
    !cityErrorMessage(components.city) &&
    !zipCodeErrorMessage(components.zipCode, zipCodeError) &&
    validateAddressInputValue(components.addressInputValue)
  );
}

export function validateAddressInputValue(addressInputValue: AddressInputValue) {
  return isAddress(addressInputValue) && Utilities.isAddressWithStreet(addressInputValue) ? addressInputValue : null;
}

export function validateState(state: string) {
  return (state && _.find(allStates, s => s.value === state)) || null;
}

export function cityErrorMessage(city: string) {
  return Validation.errorIfEmpty(city, "Verify your city");
}

export function zipCodeErrorMessage(zipCode: string, zipCodeError: boolean) {
  return zipCodeError || !Validation.isNotEmptyString(zipCode) || !Validation.isValidZipCode(zipCode)
    ? "Verify your zip code"
    : null;
}

export function buildShoppingAddress(
  components: IShoppingAddressComponents,
  zipCodeError: boolean
): IShoppingAddress | null {
  const address = isValid(components, zipCodeError);

  return address
    ? {
        ...address,
        city: components.city,
        state: components.state,
        zipCode: components.zipCode,
        addressLine2: components.addressLine2
      }
    : null;
}

export function showTaxes() {
  return process.env.REACT_APP_ENABLE_TAXES === "true";
}
