import cx from "classnames";
import React, { RefForwardingComponent, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { createEditor, Editor, Node, Range, Transforms } from "slate";
import { withHistory } from "slate-history";
import { ReactEditor, Slate, withReact } from "slate-react";

import { RichText } from "utilities/richText";
import { pipe } from "./common/pipe";
import { Section, Toolbar } from "./components/toolbar";
import { PluginEditor } from "./core/plugin-editor";
import { BLOCK_ALIGN_CENTER, BLOCK_ALIGN_LEFT, BLOCK_ALIGN_RIGHT, BLOCK_LINK, SlatePlugin } from "./core/types";
import styles from "./index.module.scss";
import { AlignPlugin, ButtonAlign } from "./plugins/blocks/align";
import { ButtonLink, LinkPlugin } from "./plugins/blocks/link";
import { ButtonBulletedList, ButtonNumberedList, ListPlugin } from "./plugins/blocks/list";
import { BoldPlugin, ButtonBold } from "./plugins/marks/bold";
import { ButtonItalic, ItalicPlugin } from "./plugins/marks/italic";
import { ButtonUnderline, UnderlinePlugin } from "./plugins/marks/underline";
import { withInline } from "./plugins/with-inline";
import { withInsertBreakHack } from "./plugins/with-insert-break-hack";
import { withPasteHtml } from "./plugins/with-paste-html";
import { withTextLimit } from "./plugins/with-text-limit";
import { defaultValue } from "./serialize/serialize-html";

export interface IRichTextEditorProps {
  defaultValue?: Node[];
  placeholder?: string;
  maxLength?: number;
  className?: string;
  contentClassName?: string;
  toolbarClassName?: string;
  sectionClassName?: string;
  linkPopoverContentClassName?: string;
  textOnly?: boolean;
  showAlign?: boolean;
  showList?: boolean;
  onBlur?: () => void;
  onChange?: (value: Node[]) => void;
}

export interface IRichTextEditor {
  focus(): void;
  getSelection(): Range | null;
  insertText(selection: Range | null, text: string): void;
}

function forwardRef(Component: RefForwardingComponent<IRichTextEditor, IRichTextEditorProps>) {
  return React.forwardRef(Component);
}

// Based on: https://github.com/lingjieee/fantasy-editor
const RichTextEditor: ReturnType<typeof forwardRef> = React.forwardRef(
  (props: IRichTextEditorProps, ref: React.Ref<IRichTextEditor>) => {
    const externalValue = props.defaultValue || defaultValue;
    const [value, setValue] = useState<Node[]>(externalValue);

    useEffect(() => {
      setValue(externalValue);
    }, [setValue, externalValue]);

    const plugins: SlatePlugin[] = useMemo(() => {
      const list: SlatePlugin[] = [];

      list.push(BoldPlugin());
      list.push(ItalicPlugin());
      list.push(UnderlinePlugin());
      props.showAlign && list.push(AlignPlugin());
      list.push(LinkPlugin());
      props.showList && list.push(ListPlugin());

      return list;
    }, []);

    const editor = useMemo<ReactEditor>(
      () =>
        pipe(
          createEditor(),
          ...[
            withReact,
            withHistory,
            withInline([BLOCK_LINK]),
            withPasteHtml(props.showList, props.maxLength),
            withInsertBreakHack()
          ],
          ...(props.maxLength != null ? [withTextLimit(props.maxLength)] : [])
        ),
      []
    );

    useImperativeHandle(ref, () => ({ focus, getSelection, insertText }));

    const onChange = useCallback(
      (value: Node[]) => {
        setValue(value);

        if (props.onChange && editor.operations.some(op => "set_selection" !== op.type)) {
          props.onChange(value);
        }
      },
      [setValue, editor, props.onChange]
    );

    function focus() {
      ReactEditor.focus(editor);
    }

    function getSelection() {
      return editor.selection;
    }

    function insertText(selection: Range | null, text: string) {
      ReactEditor.focus(editor);

      if (selection) {
        Transforms.select(editor, selection);
      } else {
        Transforms.select(editor, Editor.end(editor, []));
      }

      Transforms.insertText(editor, text);
    }

    return (
      <div className={cx(styles.editor, props.className)}>
        <Slate editor={editor} value={value} onChange={onChange}>
          {!props.textOnly && (
            <Toolbar className={props.toolbarClassName}>
              <Section className={props.sectionClassName}>
                <ButtonBold />
                <ButtonItalic />
                <ButtonUnderline />
              </Section>

              {props.showAlign && (
                <Section className={props.sectionClassName}>
                  <ButtonAlign type={BLOCK_ALIGN_LEFT} />
                  <ButtonAlign type={BLOCK_ALIGN_CENTER} />
                  <ButtonAlign type={BLOCK_ALIGN_RIGHT} />
                </Section>
              )}
              <Section className={props.sectionClassName}>
                <ButtonLink popoverContentClassName={props.linkPopoverContentClassName} />
              </Section>
              {props.showList && (
                <Section className={props.sectionClassName}>
                  <ButtonBulletedList />
                  <ButtonNumberedList />
                </Section>
              )}
            </Toolbar>
          )}
          <PluginEditor
            plugins={plugins}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            className={cx(styles.content, !RichText.isDisplayable(value) && styles.placeholder, props.contentClassName)}
          />
        </Slate>
      </div>
    );
  }
);

export default RichTextEditor;
