import { NotificationActionType } from "./constants";

export enum NotificationType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error"
}

export interface INotificationMessage {
  message: string;
  type: NotificationType;
  pin: boolean;
}

export interface IAddNotificationAction {
  type: NotificationActionType.ADD_NOTIFICATION;
  notification: INotificationMessage;
}

export interface IRemoveNotificationAction {
  type: NotificationActionType.REMOVE_NOTIFICATION;
  force: boolean;
}

export type Action = IAddNotificationAction | IRemoveNotificationAction;

export const addNotification = (
  message: string,
  type: NotificationType,
  pin: boolean = false
): IAddNotificationAction => ({
  type: NotificationActionType.ADD_NOTIFICATION,
  notification: { message, type, pin }
});

export const removeNotification = (force: boolean = true): IRemoveNotificationAction => ({
  type: NotificationActionType.REMOVE_NOTIFICATION,
  force
});
