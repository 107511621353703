import { apiClient } from "..";
import { IAvailability, IBookableHours } from "../../store/profile/availability/model";
import { apiType } from "../apiType";
import { ILoadProfileStateResponse } from "./state";

export interface IApiAvailability {
  load: () => Promise<IAvailability>;
  updateBookableHours: (updater: IBookableHours) => Promise<ILoadProfileStateResponse>;
  updateGoogleCalendar: (calendarId: string) => Promise<void>;
}

const api: IApiAvailability = {
  load: () => apiClient.get<IAvailability>(`${apiType.bookingWidget}photographer/profile/availability`),

  updateBookableHours: (updater: IBookableHours) =>
    apiClient.put<ILoadProfileStateResponse>(`${apiType.bookingWidget}photographer/profile/availability`, updater),

  updateGoogleCalendar: (calendarId: string) =>
    apiClient.put<void>(`${apiType.bookingWidget}photographer/calendar`, { externalCalendarId: calendarId })
};

export default api;
