import * as React from "react";
import { useImperativeHandle, useRef, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import ReactDOM from "react-dom";
import { IVideoPlayer } from "../../components/Video/Player";
import { ImagePlacement } from "../../components/Video/Thumbnail";
import { IVideoBase, MESizeCodes, MESizeMethod } from "utilities/getImgUrl";
import NzVideo from "../NzVideo";
import Lightbox from "./Lightbox";
import { IAnalyticParams } from "../NzVideo/Player";

export type PreviewMode = "video" | "thumbnail";

export interface IVideoPreviewProps {
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  video: IVideoBase;
  forcedSizeCode?: MESizeCodes;
  forcedSizeMethod?: MESizeMethod;
  /**
   * Specifies preview mode. Default value depends on device type: *thumbnail* on mobile phone, *video* otherwise.
   * If *video* autoplay fails, then it falls back to *thumbnail*.
   */
  mode?: PreviewMode;
  /**
   * Specifies how preview thumbnail should be positioned inside container. *"cover"* by default.
   */
  thumbnailPlacement?: ImagePlacement;
  /**
   * Specifies whether to show the loading indicator in the video preview player. *true* by default.
   */
  playerLoadingIndicator?: boolean;
  className?: string;
  style?: React.CSSProperties;
  /**
   * Specifies parameters for playback analytics. If specified, then the component automatically sends playback
   * analytics based on these parameters, process environment variables and local storage data.
   */
  analyticsParams?: IAnalyticParams;
  /**
   * Called when either preview thumbnail is loaded, or enough data has been loaded to start playing preview video.
   */
  onLoad?: () => void;
  /**
   * Called when an error occurs when loading preview thumbnail or video.
   */
  onError?: () => void;
}

export interface IVideoPreview {
  /**
   * Opens the video in the built-in fullscreen lightbox. Also pauses the "background" video.
   */
  open(): void;
}

/**
 * Video Preview component. Shows preview of the video and allows you to *"open"* it. Preview is either a *"background"*
 * video, or a thumbnail. "Opening" a video pauses the "background" video and opens the built-in fullscreen lightbox
 * with the video in "normal" mode, and closing the lightbox returns back to the "background" video and resumes it.
 */
const VideoPreview = React.forwardRef((props: IVideoPreviewProps, ref: React.Ref<IVideoPreview>) => {
  const { thumbnailUrlTemplate, videoUrlTemplate, video, className, style } = props;
  const { mode = isMobileOnly ? "thumbnail" : "video", thumbnailPlacement = "cover" } = props;
  const { playerLoadingIndicator = true, onLoad, onError } = props;
  const { forcedSizeCode, forcedSizeMethod, analyticsParams } = props;

  const [showLightbox, setShowLightbox] = useState<boolean>(false);
  const videoRef = useRef<IVideoPlayer>(null);
  useImperativeHandle(ref, () => ({ open }));

  function onLightboxClose() {
    mode === "video" && videoRef.current?.resume();
    setShowLightbox(false);
  }

  function open() {
    mode === "video" && videoRef.current?.pause();
    setShowLightbox(true);
  }

  function onImageOrVideoLoad() {
    onLoad?.();
  }

  function onImageOrVideoError() {
    onError?.();
  }

  return (
    <>
      {showLightbox &&
        ReactDOM.createPortal(
          <Lightbox
            thumbnailUrlTemplate={thumbnailUrlTemplate}
            videoUrlTemplate={videoUrlTemplate}
            video={video}
            analyticsParams={analyticsParams}
            onClose={onLightboxClose}
          />,
          document.body
        )}
      <NzVideo
        ref={videoRef}
        thumbnailUrlTemplate={thumbnailUrlTemplate}
        videoUrlTemplate={videoUrlTemplate}
        forcedSizeCode={forcedSizeCode}
        forcedSizeMethod={forcedSizeMethod}
        video={video}
        backgroundMode={mode === "video"}
        imagePlacement={thumbnailPlacement}
        thumbnailOverlay={{ duration: false }}
        playerLoadingIndicator={playerLoadingIndicator}
        quality={{ force: "best" }}
        className={className}
        style={style}
        onThumbnailImageLoad={onImageOrVideoLoad}
        onThumbnailImageError={onImageOrVideoError}
        onVideoLoad={onImageOrVideoLoad}
        onVideoError={onImageOrVideoError}
      />
    </>
  );
});

export default VideoPreview;
