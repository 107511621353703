import * as H from "history";
import { matchPath } from "react-router";
import { IRoute } from "../components/router/routes";
import { IUserInfo } from "./session/model";

export function getRouteBasePath(route: IRoute) {
  return route.layout + route.path;
}

export function getRouteFullPath(route: IRoute) {
  let path = getRouteBasePath(route);

  if (route.params) {
    path += route.params;
  }

  return path;
}

export function filterRoutes(routes: IRoute[], userInfo: IUserInfo) {
  return routes.filter(route => !route.isHidden || !route.isHidden(userInfo));
}

export function getDefaultRoute(filteredRoutes: IRoute[]) {
  return filteredRoutes.find(route => !!route.isDefault) || filteredRoutes[0];
}

export function getCurrentRoute(filteredRoutes: IRoute[], location: H.Location<any>) {
  return filteredRoutes.find(route => {
    const routePath = getRouteFullPath(route);
    return !!matchPath(location.pathname, routePath);
  });
}

export function getCurrentHeader(filteredRoutes: IRoute[], location: H.Location<any>) {
  const current = getCurrentRoute(filteredRoutes, location);

  return (current && (current.header || current.title)) || "Not Found";
}

export function getMainLayoutRoutes(filteredRoutes: IRoute[]) {
  return filteredRoutes.filter(route => route.layout === "/main");
}

export function getPageLayoutRoutes(filteredRoutes: IRoute[]) {
  return filteredRoutes.filter(route => route.layout === "/page");
}

export function insertItem<T>(items: T[], item: T, index: number) {
  return [...items.slice(0, index), item, ...items.slice(index)];
}

export function removeItem<T>(items: T[], index: number) {
  return [...items.slice(0, index), ...items.slice(index + 1)];
}

export function updateItem<T>(items: T[], item: T, index: number) {
  return items.map((currentItem, currentIndex) => {
    if (currentIndex !== index) {
      return currentItem;
    }

    return item;
  });
}
