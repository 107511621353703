import cx from "classnames";
import ArrowScrollDown from "components/Icons/ClientView/ArrowScrollDown";
import spinnerIconUrl from "components/Loading/spinner.svg";
import VideoPreview, { IVideoPreview } from "components/VideoPreview";
import playVideoSmallIconUrl from "icons/btn-play-video-small.svg";
import playVideoIconUrl from "icons/btn-play-video.svg";
import { PhotoOrVideo } from "../../../../models/models";
import React, { useRef, useState } from "react";
import { mobileVendor } from "react-device-detect";
import { formatPhotoUrl, IVideoBase, MESizeMethod } from "utilities/getImgUrl";
import { DateFormat } from "utilities/dateFormat";
import styles from "./HeroImage.module.scss";
import { FULL_SCREEN_SIZE_CODE_HERO_IMAGE } from "utilities/constant";
import { isVideo } from "utilities/helpers";

export interface IHeroImageProps {
  style: React.CSSProperties;
  showCover: boolean;
  heroImage?: PhotoOrVideo;
  heroImageFocalX?: number;
  heroImageFocalY?: number;
  photoUrlTemplate: string;
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  accentColor: string;
  name: string;
  myDate: string;
  colors: any;
  onHandleCaretClick?: (e: React.MouseEvent<HTMLElement>) => void;
  handleBlockContextMenu: (e: React.MouseEvent<HTMLElement>) => void;
  studioName?: string;
  countryCode?: string;
  galleryId?: string;
  videoAnalyticsBaseType?: string;
  albumNameClassName?: string;
}

const HeroImage: React.FC<IHeroImageProps> = props => {
  const newFocalX = props.heroImageFocalX ?? 50;
  const newFocalY = props.heroImageFocalY ?? 50;
  const hasHeroVideo = props.heroImage && isVideo(props.heroImage);

  const viewPreviewRef = useRef<IVideoPreview>(null);
  const [videoPreviewLoaded, setVideoPreviewLoaded] = useState<boolean>(false);

  function onVideoPreviewLoad() {
    setVideoPreviewLoaded(true);
  }

  function onVideoPreviewOpen() {
    viewPreviewRef.current?.open();
  }

  function renderVideoIcon(useSmall: boolean) {
    return videoPreviewLoaded ? (
      <img
        className={useSmall ? styles.videoPlaySmall : styles.videoPlay}
        src={useSmall ? playVideoSmallIconUrl : playVideoIconUrl}
        alt="Play"
        onClick={onVideoPreviewOpen}
      />
    ) : (
      <img className={useSmall ? styles.spinnerSmall : styles.spinner} src={spinnerIconUrl} alt="Spinner" />
    );
  }

  return (
    <div
      onContextMenu={props.handleBlockContextMenu}
      className={cx(
        props.showCover ? styles.heroImageContainer : styles.headerContainer,
        hasHeroVideo && styles.withVideo,
        mobileVendor && mobileVendor.toLowerCase() === "iphone" ? styles.isIphoneDevice : ""
      )}
      style={
        props.showCover && !hasHeroVideo
          ? {
              backgroundImage: props.heroImage
                ? `url(${formatPhotoUrl(
                    props.photoUrlTemplate,
                    props.heroImage,
                    FULL_SCREEN_SIZE_CODE_HERO_IMAGE,
                    MESizeMethod.Contain
                  )})`
                : undefined,
              backgroundRepeat: "no-repeat",
              backgroundPosition: newFocalX + "% " + newFocalY + "%"
            }
          : {}
      }
    >
      {hasHeroVideo && (
        <>
          <VideoPreview
            ref={viewPreviewRef}
            video={props.heroImage as IVideoBase}
            thumbnailUrlTemplate={props.thumbnailUrlTemplate}
            videoUrlTemplate={props.videoUrlTemplate}
            playerLoadingIndicator={false}
            forcedSizeCode={FULL_SCREEN_SIZE_CODE_HERO_IMAGE}
            forcedSizeMethod={MESizeMethod.Contain}
            thumbnailPlacement={{ focalPoint: { x: newFocalX, y: newFocalY } }}
            analyticsParams={
              props.galleryId && props.videoAnalyticsBaseType
                ? { type: `${props.videoAnalyticsBaseType}_hero`, galleryId: props.galleryId }
                : undefined
            }
            onLoad={onVideoPreviewLoad}
          />
          <div className={videoPreviewLoaded ? styles.videoReadyOverlay : styles.videoLoadingOverlay} />
          {renderVideoIcon(true)}
        </>
      )}
      <div className={styles.studioContainer} style={!props.showCover ? props.style : {}}>
        {props.studioName}
      </div>
      <div className={styles.albumTitleContainer}>
        <div
          className={cx(styles.albumName, props.albumNameClassName)}
          style={!props.showCover ? { color: props.accentColor } : {}}
        >
          {props.name}
        </div>
        <div className={styles.albumModifiedDate} style={!props.showCover ? props.style : {}}>
          {props.myDate && DateFormat.long(props.countryCode ? props.countryCode : "US", props.myDate)}
        </div>
        {hasHeroVideo && renderVideoIcon(false)}
      </div>
      {props.showCover && (
        <div className={styles.goBottomLink} onClick={props.onHandleCaretClick}>
          <ArrowScrollDown width={44} height={18} minX={0} minY={0} color={props.colors.white} />
        </div>
      )}
    </div>
  );
};

export default HeroImage;
