import React from "react";
import { ICanvasPhotoProps, wrapBorderSize } from ".";
import styles from "./canvasPrint.module.scss";

class CanvasSolidBorderWrap extends React.PureComponent<ICanvasPhotoProps> {
  constructor(props: ICanvasPhotoProps) {
    super(props);
  }

  render() {
    const {
      canvasPrintDetails,
      isInvalidRatio,
      isInvalidResolution,
      printWidthInPixel,
      printHeightInPixel,
      canvasWrapBorderInPixel,
      stroke,
      canvasWrapBorderInPixelForBothSide,
      renderImage,
      renderCanvasPlaceHolder
    } = this.props;

    return (
      <>
        <rect
          className={styles.borderGElement}
          width={printWidthInPixel}
          strokeWidth="2px"
          strokeOpacity="1"
          stroke="#cccccc"
          height={printHeightInPixel}
          fill={stroke}
        />
        <svg
          x={canvasWrapBorderInPixel}
          y={canvasWrapBorderInPixel}
          viewBox={`0 0 ${printWidthInPixel - canvasWrapBorderInPixelForBothSide} ${printHeightInPixel -
            canvasWrapBorderInPixelForBothSide}`}
          width={printWidthInPixel - canvasWrapBorderInPixelForBothSide}
          height={printHeightInPixel - canvasWrapBorderInPixelForBothSide}
        >
          <filter id="grayScaleFilter">
            <feColorMatrix type="saturate" values="0" />
          </filter>
          <rect
            width={printWidthInPixel - canvasWrapBorderInPixelForBothSide}
            height={printHeightInPixel - canvasWrapBorderInPixelForBothSide}
          />
          {renderImage}
          {canvasPrintDetails && !canvasPrintDetails.isShoppingCartItem && (
            <svg>
              <rect
                width={printWidthInPixel - canvasWrapBorderInPixelForBothSide}
                height={printHeightInPixel - canvasWrapBorderInPixelForBothSide}
                strokeWidth={wrapBorderSize(printWidthInPixel) * 2}
                strokeOpacity="1"
                stroke="#057aff"
                fillOpacity={isInvalidRatio || isInvalidResolution ? 1 : 0}
                fill={isInvalidRatio || isInvalidResolution ? "rgba(0, 0, 0, 0.4)" : "none"}
              />
            </svg>
          )}
        </svg>
        {renderCanvasPlaceHolder}
      </>
    );
  }
}

export default CanvasSolidBorderWrap;
