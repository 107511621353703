import cx from "classnames";
import MediaOverlayActions from "../../ClientView/CommonUI/Views/components/Media/MediaOverlayActions";
import QuickShopPreview, { IQuickShopItem } from "../../ClientView/CommonUI/Views/components/QuickShop/Preview";
import {
  GalleryPresentSettingList,
  GalleryPresentSettingType,
} from "utilities/constant";
import {
  IClientViewFavoritesState,
  IClientViewGalleryState,
  IIdentityState
} from "../../ClientView/models";
import Divider from "../../Divider";
import { ZenHeroImage as ZenHeroImageBlock } from "../zenHeroImageBlock";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { parseHtmlInnerJsonStringExt } from "utilities/blocks/string";
import { getAllQuerystringParameters } from "utilities/blocks/url";
import colors, { setOpacity } from "utilities/colors";
import {
  COLLECTION,
  FULL_SCREEN_SIZE_CODE,
  HeroCaretType,
  HeroImageAlignment,
  HeroImageMaskPosition,
  HeroImageStyle,
  LinkTypeOption
} from "utilities/constant";
import { DateFormat } from "utilities/dateFormat";
import { getAlbumPresentSettings } from "utilities/galleryPresentSetting";
import { formatPhotoSrcSet, formatPhotoUrl, formatThumbnailUrl, MESizeCodes, MESizeMethod } from "utilities/getImgUrl";
import { isShoppingAllowedForMedia, isVideo } from "utilities/helpers";
import { isGIFPhoto } from "utilities/image";
import { Utilities } from "utilities/utilities";
import React from "react";
import { isMobile } from "react-device-detect";
import { ICollection, ICurrency, IFolderAccessDto, ISetting, PhotoOrVideo, INavigationMenuItem } from "../../../models/models";
import { Collections } from "../../ClientView/CommonUI/Collections";
import CouponBanners from "../../ClientView/CommonUI/CouponBanners";
import People, { IPersonFilter } from "../../ClientView/CommonUI/People";
import CartIcon from "../../ClientView/CommonUI/Views/components/Media/icons/icon-cart.svg";
import { getActiveCollection, getCollectionsOptions } from "../../ClientView/CommonUI/Views/utils";
import Comment from "../../Icons/ClientView/Comment";
import FavoriteIcon from "../../Icons/ClientView/Favorite";
import { IPhotoMasonry, IVideoProperty } from "../blockModels";
import { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import { ZenHeroVideoBlock } from "../zenHeroVideoBlock";
import { ZenMasonryBlock } from "../zenMasonryBlock";
import { ZenMasonryLandscapeBlock } from "../zenMasonryLandscapeBlock";
import Actions from "./Actions";
import GalleryHeader from "./GalleryHeader";
import iconDownload from "./icons/icon-download-2.svg";
import iconEdit from "./icons/icon-edit.svg";
import iconMoreOptions from "./icons/icon-more-options.svg";
import iconRename from "./icons/icon-rename.svg";
import iconShare from "./icons/icon-share-2.svg";
import iconMore from "./icons/iconMore.png";
import styles from "./zenLinkedGalleryBlock.module.scss";
import { IGalleryCoupon } from "models/coupon";

interface ILinkedGalleryIdentityState {
  isVerifiedInClientPortal: boolean;
  token: string;
  isFetching: boolean;
  error: string;
  code: number;
  status: number;
  userName: string;
  userEmail: string;
  resendVerifiedEmail: boolean;
}

interface IFacialRecognitionBaseState {
  photosAnalyzed: number;
  totalPhotos: number;
  lastUpdateTimestamp: number;
  canceling: boolean;
}

export interface IGalleryDetails {
  name: string;
  isProtected: boolean;
  isVisitorAllowed: boolean;
  isVisitorEmailRequired: boolean;
  photoCount: number;
  videoCount: number;
  coverImage: string;
  storeIsOn?: boolean;
  allowShoppingCart?: boolean;
  allowPeopleFilter?: boolean;
  collections?: ICollection[];
  folderAccessDto?: IFolderAccessDto;
}

export interface ILinkedGalleryBlockProps extends IZenBaseBlockProps {
  subtitlePosition?: string;
  galleryId?: string;
  albumAlias?: string;
  isTextVisible?: boolean;
  titleText?: string;
  shootDate?: string;
  blockText?: string;
  pages?: INavigationMenuItem[];
  photoUrlTemplate: string;
  isEditor?: boolean;
  images: IPhotoMasonry[];
  showLightbox: boolean;
  galleryDetails?: IGalleryDetails | string;
  clientViewFavorites: IClientViewFavoritesState;
  clientViewGallery?: IClientViewGalleryState;
  clientViewIdentity?: IIdentityState;
  allowFavorite: boolean;
  allowMediaCommenting?: boolean;
  allowPhotoDownload: boolean;
  allowVideoDownload: boolean;
  allowGalleryPhotosDownload: boolean;
  allowGalleryVideosDownload: boolean;
  allowShareGallery: boolean;
  allowShoppingCart?: boolean;
  allowPeopleFilter: boolean;

  studioName?: string;
  cartBadge?: number;
  cartTooltip?: JSX.Element;
  disabledShop?: boolean;

  allMedia: PhotoOrVideo[];

  onMediaFavoritesViewClick: () => void;
  onMediaCommentsViewClick?: () => void;

  onClickButtonCart?: () => void;
  onClickButtonShop?: () => void;
  onDownloadClick: (e: React.MouseEvent<HTMLElement>) => void;
  onShareClick: (e: React.MouseEvent<HTMLElement>) => void;

  onPhotoClick?: (evt: React.MouseEvent<HTMLElement>, photoIndex: number) => void;
  onHoverPhoto?: (hoveredPhotoIndex: number) => void;
  onGetTitle?: (photo: IPhotoMasonry) => string;
  onHandleCollectionSelected?: (collectionId: string) => void;

  onMediaClick: (e: React.MouseEvent<HTMLElement>, mediaIndex: number) => void;
  onMediaFavoriteClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaDownloadClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaCartClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaCommentClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  quickShopItems?: IQuickShopItem[];
  onClickQuickShopItem?: (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => void;

  getSetting: (settingType: number) => ISetting | undefined;

  favoritesFilterSelected?: boolean;
  commentsFilterSelected?: boolean;

  peopleImgs: IPersonFilter[];
  selectedPeople: string[];
  selectPerson: (id: string) => void;
  deselectPerson: (id: string) => void;

  currency?: ICurrency;
  couponList?: IGalleryCoupon[];
  onClickBannerButton?: () => void;

  breadcrumbBar?: React.ReactNode;
}

interface ILinkedGalleryBlockState {
  collectionId: string;
  actionsBarTopValue: number;
  isRenderQuickShop: boolean;
  selectedIndex: number;
}

export class ZenLinkedGalleryBlock extends React.Component<ILinkedGalleryBlockProps, ILinkedGalleryBlockState> {
  constructor(props: ILinkedGalleryBlockProps) {
    super(props);

    this.state = {
      collectionId: COLLECTION.ALL_MEDIA_ID,
      actionsBarTopValue: 0,
      isRenderQuickShop: false,
      selectedIndex: -1
    };
  }

  public componentDidMount() {
    const navBarElement = document.getElementById(`navBar`);
    const navBarPosition = navBarElement
      ? window.getComputedStyle(navBarElement, null)?.getPropertyValue("position")
      : "";
    const isNavBarSticky = navBarPosition === "fixed";

    if (!this.props.isEditor && isNavBarSticky) {
      this.setState({
        actionsBarTopValue: navBarElement?.offsetHeight ? navBarElement.offsetHeight - 1 : 0
      });
    }
  }

  private handleCollectionSelected = (collectionId: string) => {
    this.setState({ collectionId }, () => {
      if (this.props.onHandleCollectionSelected) {
        this.props.onHandleCollectionSelected(this.state.collectionId);
      }
    });
  };

  private handleShowQuickShop = (index: number) => {
    this.setState({ isRenderQuickShop: true });
    this.setState({ selectedIndex: index });
  };

  private handleCloseQuickShop = () => {
    this.setState({ isRenderQuickShop: false });
    this.setState({ selectedIndex: -1 });
  };

  private addItemToCartFromQuickShop = (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => {
    const { onClickQuickShopItem } = this.props;
    if (onClickQuickShopItem) {
      onClickQuickShopItem(e, media);
    }
  };

  private onClickShopAllProduct = (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => {
    e.preventDefault();
    e.stopPropagation();

    this.handleCloseQuickShop();

    const { onMediaCartClick } = this.props;

    if (onMediaCartClick) {
      onMediaCartClick(e, media);
    }
  };

  private renderQuickShop = () => {
    const { quickShopItems, allMedia, images } = this.props;
    const { selectedIndex, isRenderQuickShop } = this.state;
    const media = allMedia[selectedIndex];
    const imageUrl = images[selectedIndex]?.url;
    if (isRenderQuickShop && quickShopItems) {
      return (
        <QuickShopPreview
          className={styles.quickShopPreview}
          items={quickShopItems || []}
          media={media}
          imageUrl={imageUrl}
          onClickShopAllProduct={e => this.onClickShopAllProduct(e, media)}
          onClickAddItem={e => this.addItemToCartFromQuickShop(e, media)}
          onCloseQuickShop={this.handleCloseQuickShop}
          isGIFPhoto={isGIFPhoto(media.fileName)}
        />
      );
    }
    return null;
  };

  private getBadgeComponent = (index: number) => {
    const { allMedia } = this.props;
    const isQuickShopEnable =
      Utilities.checkQuickShopEnabled(this.props.quickShopItems) &&
      allMedia[index] &&
      isShoppingAllowedForMedia(allMedia[index]);

    return isMobile && isQuickShopEnable ? (
      <span
        className={styles.cartHoverIcon}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          this.handleShowQuickShop(index);
        }}
      >
        <img src={CartIcon} alt="" />
      </span>
    ) : null;
  };

  private getOverlayActions = (index: number) => {
    if (isMobile) {
      return <div />;
    }
    const { getSetting, siteTheme } = this.props;
    const gridType = getSetting(GalleryPresentSettingType.GridType);
    const isLandscape =
      gridType &&
      (gridType.id === GalleryPresentSettingList.LandscapeSmall ||
        gridType.id === GalleryPresentSettingList.LandscapeLarge);
    const bgColorSetting = getSetting(GalleryPresentSettingType.GridBackgroundColor);
    const bgColor = bgColorSetting?.value ? bgColorSetting.value : siteTheme.backgroundColor.value;

    return (
      <MediaOverlayActions
        {...this.props}
        clientViewFavorites={this.props.clientViewFavorites}
        bgColor={bgColor}
        isLandscape={isLandscape!}
        index={index}
      />
    );
  };

  private getLayoutName = (gridType?: ISetting) => {
    switch (gridType?.id) {
      case GalleryPresentSettingList.PortraitLarge:
      case GalleryPresentSettingList.GridSmall:
      case GalleryPresentSettingList.LandscapeSmall:
        return "B";
      case GalleryPresentSettingList.LandscapeLarge:
        return "C";
      default:
        return "A";
    }
  };

  private isPortraitLayout = (gridType?: ISetting) => {
    switch (gridType?.id) {
      case GalleryPresentSettingList.LandscapeSmall:
      case GalleryPresentSettingList.LandscapeLarge:
        return false;
      default:
        return true;
    }
  };

  private isGridLayout = (gridType?: ISetting) => {
    switch (gridType?.id) {
      case GalleryPresentSettingList.GridSmall:
      case GalleryPresentSettingList.GridLarge:
        return true;
      default:
        return false;
    }
  };

  private renderZenHeroImageBlock = (headerText: string, subtitleText: string) => {
    const { siteTheme, clientViewGallery } = this.props;
    let imageUrl: string = "", imageSrcSet: string = "";
    if (clientViewGallery && clientViewGallery.coverPhoto) {
      imageUrl = formatPhotoUrl(
        clientViewGallery.heroImageUrlTemplate || clientViewGallery.photoUrlTemplate,
        clientViewGallery.coverPhoto,
        FULL_SCREEN_SIZE_CODE,
        MESizeMethod.Contain
      );
      imageSrcSet = formatPhotoSrcSet(
        clientViewGallery.heroImageUrlTemplate || clientViewGallery.photoUrlTemplate,
        clientViewGallery.coverPhoto,
        MESizeMethod.Contain
      );
    }
    return (
      <>
        <ZenHeroImageBlock
          desktopEnabled={true}
          tabletEnabled={true}
          mobileEnabled={true}
          alignment={HeroImageAlignment.RIGHT}
          styleMode={HeroImageStyle.FIXED}
          headerText={headerText}
          subtitleText={subtitleText}
          imageUrl={imageUrl}
          imageSrcSet={imageSrcSet}
          imageId={clientViewGallery?.coverPhoto?.id}
          tint={false}
          maskPosition={HeroImageMaskPosition.CENTER}
          siteTheme={siteTheme}
          imageHeight={clientViewGallery?.coverPhoto?.height || 0}
          imageWidth={clientViewGallery?.coverPhoto?.width || 0}
          caret={HeroCaretType.ACCENT_COLOR}
          isPublish={true}
          link={{
            button: false
          }}
        />
      </>
    );
  };

  private renderZenHeroVideoBlock = (headerText: string, subtitleText: string) => {
    const { siteTheme, galleryId, clientViewGallery } = this.props;
    let videoThumbnail;
    let galleryVideo: IVideoProperty | undefined;
    if (clientViewGallery?.coverVideo && isVideo(clientViewGallery?.coverVideo)) {
      videoThumbnail = formatThumbnailUrl(
        clientViewGallery?.thumbnailUrlTemplate || "",
        clientViewGallery?.coverVideo,
        MESizeCodes.SIZE264x264,
        MESizeMethod.Contain
      );
      galleryVideo = {
        thumbnailUrlTemplate: clientViewGallery.thumbnailUrlTemplate,
        videoUrlTemplate: clientViewGallery.videoUrlTemplate,
        galleryId: galleryId || "",
        galleryName: clientViewGallery.name || "",
        videoThumbnail,
        detail: clientViewGallery?.coverVideo,
        duration: ""
      };
    }
    return (
      <ZenHeroVideoBlock
        selectedBlock={false}
        useLightbox={true}
        readOnly
        isEdit={false}
        title={headerText}
        styleMode={HeroImageStyle.FIXED}
        caption={subtitleText}
        alignment={HeroImageAlignment.RIGHT}
        desktopEnabled={true}
        tabletEnabled={true}
        mobileEnabled={true}
        backgroundWidth="fixed"
        animationScrollIn="none"
        animationScrollOut="none"
        verticalAlign="bottom"
        linkType={LinkTypeOption.GALLERY}
        siteTheme={siteTheme}
        autoplay={true}
        galleryVideo={galleryVideo}
      />
    );
  };

  private renderZenHeroBlock = () => {
    const { clientViewGallery } = this.props;
    let subtitleText: string = "";
    let headerText: string = "";
    const settingList: ISetting[] = clientViewGallery?.albumPresent?.settings || [];
    const isHideTitle = !!settingList.find(
      (item: ISetting) => item.id === GalleryPresentSettingList.HideTitle && item.value === "true"
    );
    const isHideDate = !!settingList.find((item: ISetting) => {
      return item.id === GalleryPresentSettingList.HideDate && item.value === "true";
    });
    if (!isHideDate) {
      subtitleText = DateFormat.long("US", Date.parse(clientViewGallery?.shootDate || clientViewGallery?.dateCreated || ""));
      if (!isHideTitle) {
        headerText = clientViewGallery?.name || "";
      }
    } else if (!isHideTitle) {
      subtitleText = clientViewGallery?.name || "";
    }

    if (clientViewGallery?.coverVideo && isVideo(clientViewGallery?.coverVideo)) {
      return this.renderZenHeroVideoBlock(headerText, subtitleText);
    }
    return this.renderZenHeroImageBlock(headerText, subtitleText);
  };

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      layout,
      fontFamily,
      divider,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      padding,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      isTextVisible,
      titleText,
      shootDate,
      blockText,
      images,
      galleryDetails,
      onMediaClick,
      onHoverPhoto,
      onGetTitle,
      isEditor,
      clientViewFavorites,
      clientViewGallery,
      onMediaFavoritesViewClick,
      onMediaCommentsViewClick,
      studioName,
      cartBadge,
      cartTooltip,
      disabledShop,
      onClickButtonCart,
      onClickButtonShop,
      onDownloadClick,
      onShareClick,
      allowMediaCommenting,
      allowFavorite,
      allowShareGallery,
      allowPeopleFilter,
      allowShoppingCart,
      peopleImgs,
      selectedPeople,
      selectPerson,
      deselectPerson,
      getSetting,
      favoritesFilterSelected,
      commentsFilterSelected,
      currency,
      couponList,
      onClickBannerButton,
      breadcrumbBar,
      galleryId,
      showLightbox
    } = this.props;

    const bgColorSetting = getSetting(GalleryPresentSettingType.GridBackgroundColor);
    const bgColor = (bgColorSetting?.value || siteTheme.backgroundColor.value).toLowerCase();

    const isDarkBackground: boolean = bgColor === colors.grayNav || bgColor === colors.black;
    const lineColor = isDarkBackground ? colors.grayTextNav : colors.grey;
    const textColor = getBlockTextColor(bgColor, siteTheme.accentColor.value, "color", bgColor);
    const opacity =
      bgColor === colors.white ? ".4" : bgColor === colors.creme ? ".4" : bgColor === colors.grayNav ? "1" : "1";

    const parsedGalleryDetails: IGalleryDetails | undefined =
      typeof galleryDetails === "string" ? parseHtmlInnerJsonStringExt(galleryDetails) : galleryDetails;
    const hasFavorites = clientViewFavorites.favoritePhotos.length > 0 || clientViewFavorites.favoriteVideos.length > 0;
    const allowGalleryPhotosDownload = this.props.allowGalleryPhotosDownload || false;
    const allowGalleryVideosDownload = this.props.allowGalleryVideosDownload || false;
    const photoCount = clientViewGallery?.photoCount || parsedGalleryDetails?.photoCount || 0;
    const videoCount = clientViewGallery?.videoCount || parsedGalleryDetails?.videoCount || 0;
    const canDownloadGalleryPhotos = allowGalleryPhotosDownload && photoCount > 0;
    const canDownloadGalleryVideos = allowGalleryVideosDownload && videoCount > 0;

    const collectionsProp = clientViewGallery?.collections || parsedGalleryDetails?.collections || [];
    const collectionsOptions = getCollectionsOptions(collectionsProp, !!clientViewGallery?.medias?.length);
    const selectedCollectionIndex = getActiveCollection(collectionsOptions, this.state.collectionId);
    const withPeopleFilter =
      allowPeopleFilter && this.state.collectionId === COLLECTION.ALL_MEDIA_ID && peopleImgs.length > 0;
    const showLimitedPhotos = isEditor && parsedGalleryDetails && parsedGalleryDetails.photoCount > 15;
    const showNoFilteredPhotos = !images?.length && (favoritesFilterSelected || commentsFilterSelected);

    const fadeoutBackground = `linear-gradient(to bottom, ${setOpacity(bgColor, 0)} 0%, ${setOpacity(
      bgColor,
      0.9
    )} 50%, ${bgColor} 100%)`;
    const limitedPhotosCountBg = bgColor === colors.white || bgColor === colors.creme ? "#000" : "#fff";
    const limitedPhotosCountColor = bgColor === colors.white || bgColor === colors.creme ? "#fff" : "#000";
    const containerStyle = {
      paddingBottom: padding ? padding : "0px",
      backgroundColor: bgColor
    };

    let visibilityClass;

    if (readOnly && !desktopEnabled) {
      visibilityClass = styles.hideDesktop;
    }
    if (readOnly && !tabletEnabled) {
      visibilityClass = cx(visibilityClass, styles.hideTablet);
    }
    if (readOnly && !mobileEnabled) {
      visibilityClass = cx(visibilityClass, styles.hideMobile);
    }

    // showing image name (on hover or always)
    const { nameType, nameVisibility } = getAlbumPresentSettings(clientViewGallery?.albumPresent?.settings || []);
    // setting layout type
    const mobileGridType = getSetting(GalleryPresentSettingType.MobileGridType);
    const gridType = getSetting(GalleryPresentSettingType.GridType);
    let layoutName = this.getLayoutName(gridType);
    let isPortraitLayout = this.isPortraitLayout(gridType);
    let isGridLayout = this.isGridLayout(gridType);
    const showDescription = isTextVisible && (clientViewGallery?.description || blockText);

    // render image/video hero block
    let heroBlock = null;
    if (typeof window !== "undefined") {
      const urlParams = getAllQuerystringParameters(window.location.search);
      if (urlParams.ah && clientViewGallery && galleryId) {
        heroBlock = this.renderZenHeroBlock();
      }
    }

    return (
      <>
        <div className={visibilityClass} style={containerStyle}>
          {!!breadcrumbBar && breadcrumbBar}
          {heroBlock && <div className={cx({[styles.heroBlock]: !breadcrumbBar})}>{heroBlock}</div>}
          <Actions
            siteTheme={siteTheme}
            lineColor={lineColor}
            titlesColor={textColor}
            bgColor={bgColor}
            name={clientViewGallery?.name || parsedGalleryDetails?.name || ""}
            hasFavorites={hasFavorites}
            hasUnreadComments={false}
            opacity={opacity}
            isEcom={allowShoppingCart || false}
            iconMore={iconMore}
            iconShare={iconShare}
            iconDownload={iconDownload}
            allowFavorite={allowFavorite}
            allowShareGallery={allowShareGallery}
            allowGalleryPhotosDownload={canDownloadGalleryPhotos}
            allowGalleryVideosDownload={canDownloadGalleryVideos}
            allowMediaCommenting={allowMediaCommenting}
            onMediaFavoritesViewClick={onMediaFavoritesViewClick}
            onMediaCommentsViewClick={onMediaCommentsViewClick}
            studioName={studioName}
            cartBadge={cartBadge}
            cartTooltip={cartTooltip}
            disabledShop={disabledShop}
            onClickCart={onClickButtonCart}
            onClickShop={onClickButtonShop}
            onDownloadClick={onDownloadClick}
            onShareClick={onShareClick}
            token={this.props.clientViewIdentity?.token || ""}
            topValue={this.state.actionsBarTopValue || 0}
          />
          <div className={cx(styles.collectionsContainer)}>
            <Collections
              siteTheme={siteTheme}
              onOptionSelected={this.handleCollectionSelected}
              collectionsOptions={collectionsOptions}
              defaultActiveIndex={getActiveCollection(collectionsOptions, clientViewGallery?.collection)}
              lineColor={lineColor}
              accentColor={textColor}
              iconMoreOptions={iconMoreOptions}
              iconRename={iconRename}
              isEditor={isEditor}
              reducedContent={true}
              bgColor={bgColor}
            />
          </div>
          {showDescription && (
            <GalleryHeader
              siteTheme={siteTheme}
              readOnly={readOnly}
              desktopEnabled={desktopEnabled}
              tabletEnabled={tabletEnabled}
              mobileEnabled={mobileEnabled}
              alignment={alignment}
              fontFamily={fontFamily}
              padding={"20px"}
              layout={layout}
              selectedBlock={selectedBlock}
              paddingMobile={true}
              titleText={titleText}
              shootDate={shootDate}
              blockText={clientViewGallery?.description || blockText}
              bgColor={bgColor}
            />
          )}
          {withPeopleFilter && (
            <People
              peopleImgs={peopleImgs}
              iconMoreOptions={iconMoreOptions}
              iconEdit={iconEdit}
              accentColor={siteTheme.accentColor.value}
              bgColor={bgColor}
              selectedPeople={selectedPeople}
              titlesColor={textColor}
              opacity={opacity}
              selectPerson={selectPerson}
              deselectPerson={deselectPerson}
              isEditor={isEditor}
              reducedContent={true}
            />
          )}

          {!!couponList?.length && (
            <div className={styles.couponBannerWrapper}>
              <CouponBanners currency={currency} couponList={couponList} onClickBannerButton={onClickBannerButton} />
            </div>
          )}

          <div className={cx(styles.masonryWrapper, { [styles.enlargedSpace]: !showDescription || !isTextVisible })}>
            {isPortraitLayout && (
              <ZenMasonryBlock
                siteTheme={siteTheme}
                readOnly={readOnly}
                desktopEnabled={desktopEnabled}
                tabletEnabled={tabletEnabled}
                mobileEnabled={mobileEnabled}
                fontFamily={fontFamily}
                animationScrollIn={animationScrollIn}
                animationScrollOut={animationScrollOut}
                backgroundColor={bgColor}
                layout={layoutName}
                isStacked={gridType?.id === GalleryPresentSettingList.Stacked ? true : false}
                resizeToFit={isGridLayout}
                mobileLayout={mobileGridType?.id}
                images={images}
                fullWidth={true}
                brakePoints={[400, 500, 600, 700]}
                galleryStyle="none"
                dropShadow={false}
                onPhotoClick={onMediaClick}
                onHoverPhoto={onHoverPhoto}
                onGetTitle={onGetTitle}
                isEditor={isEditor}
                getHoverComponent={this.getOverlayActions}
                getBadgeComponent={this.getBadgeComponent}
                showNames={nameType}
                namesVisibility={nameVisibility}
              />
            )}
            {!isPortraitLayout && (
              <ZenMasonryLandscapeBlock
                siteTheme={siteTheme}
                readOnly={readOnly}
                desktopEnabled={desktopEnabled}
                tabletEnabled={tabletEnabled}
                mobileEnabled={mobileEnabled}
                fontFamily={fontFamily}
                animationScrollIn={animationScrollIn}
                animationScrollOut={animationScrollOut}
                backgroundColor={bgColor}
                layout={layoutName}
                mobileLayout={mobileGridType?.id}
                images={images}
                fullWidth={true}
                galleryStyle="none"
                dropShadow={false}
                onPhotoClick={onMediaClick}
                onHoverPhoto={onHoverPhoto}
                isEditor={isEditor}
                getHoverComponent={this.getOverlayActions}
                getBadgeComponent={this.getBadgeComponent}
                showNames={nameType}
                namesVisibility={nameVisibility}
              />
            )}

            {this.renderQuickShop()}

            {showNoFilteredPhotos && (
              <div className={styles.noFilteredPhotos}>
                {favoritesFilterSelected && <FavoriteIcon color={textColor} size={64} opacity={opacity} />}
                {commentsFilterSelected && <Comment color={textColor} size={64} opacity={opacity} />}

                <span style={{ color: textColor, opacity }}>
                  {favoritesFilterSelected && "No favorites selected. Return to the gallery to favorite photos."}
                  {commentsFilterSelected && "No photos with comments yet."}
                </span>
              </div>
            )}

            {showLimitedPhotos && (
              <div
                className={styles.limitedImages}
                style={{ backgroundImage: fadeoutBackground, color: limitedPhotosCountBg }}
              >
                <div
                  className={styles.limitedImagesCount}
                  style={{ backgroundColor: limitedPhotosCountBg, color: limitedPhotosCountColor }}
                >
                  {parsedGalleryDetails ? parsedGalleryDetails.photoCount : 0} Photos
                </div>
                Click preview to see the full gallery.
              </div>
            )}
          </div>
        </div>
        <Divider templateBackgroundColor={bgColor} dividerType={divider} padding={padding} />
      </>
    );
  }
}
