import IError from "../../utilities/error";
import { ActionStatus } from "../common";
import { ShootTypesFetchAction } from "./actions";
import { ShootTypesActionType } from "./constants";
import { IShootType } from "./model";

export interface IShootTypesState {
  fetchStatus: ActionStatus;
  shootTypes: IShootType[][];
  error: IError | null;
}

const initialShootTypesState: IShootTypesState = {
  fetchStatus: "Init",
  shootTypes: [],
  error: null
};

const shootTypes = (
  state: IShootTypesState = initialShootTypesState,
  action: ShootTypesFetchAction
): IShootTypesState => {
  switch (action.type) {
    case ShootTypesActionType.LOAD_SHOOT_TYPES_STARTED:
      return { ...state, fetchStatus: "Pending", error: null };
    case ShootTypesActionType.LOAD_SHOOT_TYPES_SUCCESS:
      return {
        ...state,
        fetchStatus: "Success",
        shootTypes: action.shootTypes,
        error: null
      };
    case ShootTypesActionType.LOAD_SHOOT_TYPES_ERROR:
      return {
        ...state,
        fetchStatus: "Error",
        shootTypes: [],
        error: action.error
      };

    default:
      return state;
  }
};

export default shootTypes;
