import { AddressInput, formatErrorType } from "@zenfolio/core-components";
import axios, { AxiosRequestConfig } from "axios";
import _ from "lodash";
import IError from "../utilities/error";
import * as logging from "../utilities/logging";
import { NavigationManager } from "../utilities/navigationManager";
import embed, { IApiActivationEmbed } from "./activation/embed";
import pages, { IApiActivationPages } from "./activation/pages";
import billing, { IApiBilling } from "./billing";
import bookings, { IApiBookings } from "./bookings";
import customize, { IApiCustomize } from "./customize";
import * as errorTypes from "./errorTypes";
import location, { IApiLocation } from "./location";
import availability, { IApiAvailability } from "./profile/availability";
import contactInfo, { IApiContactInfo } from "./profile/contactInfo";
import googleCalendar, { IApiGoogleCalendar } from "./profile/googleCalendar";
import payments, { IApiPayments } from "./profile/payments";
import shootLocation, { IApiShootLocation } from "./profile/shootLocation";
import state, { IApiProfileState } from "./profile/state";
import services, { IApiPhotographerServices } from "./services";
import session, { IApiSession } from "./session";
import shootTypes, { IApiShootTypes } from "./shootTypes";
import widget, { IApiWidget } from "./widget";

export interface IProblemDetails {
  type: string;
  title: string;
  status?: number;
  detail?: string;
  exception?: string;
  errors?: { [property: string]: string[] };
}

export interface IApiError extends IError {
  title: string;
  message: string;
  status?: number | undefined;
  errors?: { [property: string]: string[] };
}

export interface IApiClient {
  request<T = any>(config: AxiosRequestConfig): Promise<T>;
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete(url: string, config?: AxiosRequestConfig): Promise<any>;
  head(url: string, config?: AxiosRequestConfig): Promise<any>;
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

const serviceUnavailableError: IProblemDetails = {
  type: formatErrorType(errorTypes.serviceUnavailableError),
  title: "Service unavailable, please try again."
};

const isProblemDetails = (error: any): error is IProblemDetails => {
  return !!error.type;
};

export const wrapApiError = (error?: any): IApiError => {
  const problemDetails: IProblemDetails = error && isProblemDetails(error) ? error : serviceUnavailableError;
  const message = problemDetails.errors
    ? _.flatten(_.values(problemDetails.errors)).join("\n")
    : problemDetails.detail || problemDetails.title;

  return {
    ...problemDetails,
    type: problemDetails.type,
    title: problemDetails.title,
    message,
    status: problemDetails.status,
    errors: problemDetails.errors
  };
};

const processApiError = (error: any, message: string): Promise<never> => {
  logging.log(message, error);
  return Promise.reject(wrapApiError(error && error.response && error.response.data));
};

const authorizationHeader = "Authorization";
const subscriptionKeyHeader = "Ocp-Apim-Subscription-Key";
const photographerIdHeader = "X-Zenfolio-Photographer-ID";
const parentRefererHeader = "X-Zenfolio-Parent-Referer";
const apiKeyHeader = "X-API-Key";
const appCodeHeader = "X-APP-CODE";

enum ApiClientConfig {
  Normal,
  Widget,
  Identity
}

const setupApiClient = (clientConfig: ApiClientConfig): IApiClient => {
  const axiosWrapper = axios.create(
    clientConfig === ApiClientConfig.Identity
      ? undefined
      : {
          baseURL: process.env.REACT_APP_API_BASE_URL
        }
  );

  axiosWrapper.interceptors.request.use(
    config => {
      const token = (clientConfig === ApiClientConfig.Normal && NavigationManager.instance.token) || "";
      if (token) {
        config.headers[authorizationHeader] = `Bearer ${token}`;
      }

      const photographerId =
        (clientConfig === ApiClientConfig.Widget && NavigationManager.instance.widgetPhotographerId) || "";
      if (photographerId) {
        config.headers[photographerIdHeader] = photographerId;
      }

      if (clientConfig === ApiClientConfig.Identity) {
        const apiKey = process.env.REACT_APP_X_API_KEY || "";
        if (apiKey) {
          config.headers[apiKeyHeader] = apiKey;
        }

        config.headers[appCodeHeader] = "PhotographerClient";
      } else {
        const subscriptionKey: string = process.env.REACT_APP_SUBSCRIPTION_KEY || "";
        if (subscriptionKey) {
          config.headers[subscriptionKeyHeader] = subscriptionKey;
        }

        if (NavigationManager.instance.parentReferer) {
          config.headers[parentRefererHeader] = NavigationManager.instance.parentReferer;
        }
      }

      return config;
    },
    error => {
      return processApiError(error, "Received error during sending API request:");
    }
  );

  axiosWrapper.interceptors.response.use(
    response => {
      logging.log("Received API response from the server:", response);
      return response.data;
    },
    error => {
      return processApiError(error, "Received API error from the server:");
    }
  );

  return axiosWrapper as any;
};

export const apiClient = setupApiClient(ApiClientConfig.Normal);
export const widgetClient = setupApiClient(ApiClientConfig.Widget);
export const identityClient = setupApiClient(ApiClientConfig.Identity);

export const AddressInputWithApi = AddressInput.withApi(apiClient);

export interface IApiServices {
  session: IApiSession;
  location: IApiLocation;
  profile: {
    payments: IApiPayments;
    shootLocation: IApiShootLocation;
    contactInfo: IApiContactInfo;
    state: IApiProfileState;
    availability: IApiAvailability;
    googleCalendar: IApiGoogleCalendar;
  };
  services: IApiPhotographerServices;
  activation: {
    pages: IApiActivationPages;
    embed: IApiActivationEmbed;
  };
  customize: IApiCustomize;
  widget: IApiWidget;
  bookings: IApiBookings;
  billing: IApiBilling;
  shootTypes: IApiShootTypes;
}

const api: IApiServices = {
  session,
  location,
  profile: { payments, shootLocation, contactInfo, state, availability, googleCalendar },
  services,
  activation: { pages, embed },
  customize,
  widget,
  bookings,
  billing,
  shootTypes
};

export default api;
