import cx from "classnames";
import Image from "../../Image";
import VideoDuration from "../../VideoDuration";
import playVideoSmallIconUrl from "icons/btn-play-video-small.svg";
import playVideoIconUrl from "icons/btn-play-video.svg";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { IBlockPhotoAnimationClasses, IPhotoNameProps } from "../blockModels";
import Name, { showName } from "./name";
import styles from "./zenPhotoTile.module.scss";
import { matchMediaPhone } from "utilities/responsive";
import { PageContext } from "../../../utilities/pageContext";

export interface IPhotoTileProps extends Partial<IPhotoNameProps> {
  resizeToFit?: boolean;
  fullWidth?: boolean;
  addOpacity?: boolean;
  src: string;
  srcset?: string;
  alt?: string;
  borderWidth?: number;
  dropShadow?: boolean;
  followContainerSize?: boolean;
  index: number;
  overlayComponent?: JSX.Element | null;
  overlayStyle?: string;
  title?: string;
  titleClassName?: string;
  photoContainerStyle?: string;
  height?: number;
  focalX?: number;
  focalY?: number;
  rowHeight?: number;
  isEditionView?: boolean;
  isEditor?: boolean;
  blockImageEvents?: boolean;
  animationClasses?: IBlockPhotoAnimationClasses;
  duration?: number;
  isGif?: boolean;
  isPng?: boolean;
  aspectRatio?: string;
  onTileMounted?: () => void;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseOver?: (evt: React.FormEvent<HTMLElement>) => void;
  getHoverComponent?: (index: number) => JSX.Element;
  getBadgeComponent?: (index: number) => JSX.Element | Element | null;
  onHoverPhoto?: (hoveredPhotoIndex: number) => void;
  hasLightbox?: boolean;
  showLightbox?: boolean;
  dominantColor?: string;
}

const ZenPhotoTile: React.FC<IPhotoTileProps> = ({
  src,
  srcset,
  alt,
  index,
  borderWidth,
  dropShadow,
  resizeToFit,
  title,
  overlayComponent,
  overlayStyle,
  fullWidth,
  photoContainerStyle,
  addOpacity,
  height,
  focalX,
  focalY,
  animationClasses,
  rowHeight,
  titleClassName,
  followContainerSize,
  blockImageEvents,
  duration,
  isEditor,
  isGif,
  isPng,
  onTileMounted,
  onMouseOver,
  onClick,
  getHoverComponent,
  getBadgeComponent,
  onHoverPhoto,
  aspectRatio,
  name,
  nameAlignment,
  nameVisibility,
  isVideo,
  hasLightbox,
  showLightbox,
  dominantColor
}) => {
  const pageContext = useContext(PageContext);
  const ref = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const lazyLoaded = useRef(false);
  const [pictureHeight, setPictureHeight] = useState(0);
  const [focalStateX, setFocalX] = useState(focalX ?? 50);
  const [focalStateY, setFocalY] = useState(focalY ?? 50);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (onTileMounted) {
      onTileMounted();
    }

    setFocalX(focalStateX);
    setFocalY(focalStateY);

    if (
      containerRef.current &&
      lazyLoaded.current &&
      animationClasses?.imgContainerLoaded &&
      !containerRef.current.classList.contains(animationClasses.imgContainerLoaded)
    ) {
      containerRef.current.classList.add(animationClasses.imgContainerLoaded);
    }
  }, [dropShadow, onTileMounted, focalX, focalY, fullWidth]);

  const onPictureLoad = () => {
    setPictureHeight(ref && ref.current ? ref.current.offsetHeight : 0);
  };

  const styleContainer: React.CSSProperties = {
    height:
      height && pictureHeight === 0 && !resizeToFit
        ? height + "px"
        : resizeToFit
        ? rowHeight
          ? rowHeight + "px"
          : "300px"
        : "unset"
  };

  const onContextMenuHandle = () => {
    return false;
  };

  const styleContainerFollow: React.CSSProperties = {
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px"
  };

  // Keeps a flag if the image was lazyloaded by lazysizes library
  // so we can reapply our custom class in case of DOM changes
  if (
    containerRef.current &&
    !lazyLoaded.current &&
    animationClasses &&
    animationClasses.imgContainerLoaded &&
    containerRef.current.classList.contains(animationClasses.imgContainerLoaded)
  ) {
    lazyLoaded.current = true;
  }

  const getImagePadding = (borderWidth?: number) => {
    return borderWidth && `${(borderWidth > 1 ? 1 : 2) * borderWidth}px`;
  };

  const onClickImageParent = (evt: React.MouseEvent<HTMLElement>) => {
    if (blockImageEvents && onClick) {
      onClick(evt);
    }
  };

  const renderPlayIcon = (duration?: number) => {
    const imageWidth = (ref.current?.offsetWidth || 221) / 2;
    return (
      <Fragment>
        <div className={styles.videoPlay}>
          <img
            src={matchMediaPhone ? playVideoIconUrl : playVideoSmallIconUrl}
            alt="Play"
            className={styles.videoPlay}
            style={{
              left: imageWidth + "px"
            }}
          />
        </div>
        {duration && <VideoDuration className={styles.videoDuration} durationMilliseconds={duration} />}
      </Fragment>
    );
  };

  const checkClickable = (): boolean => {
    if (hasLightbox) {
      return !!showLightbox || !!isVideo;
    } else {
      return !!onClick;
    }
  };

  const isGhost = !pageContext.noGhost && addOpacity;
  const isTransparent = isGif || isPng;
  const withDuration = isEditor && !!duration;
  const showHoverComponent = isHovered && !!getHoverComponent;
  const isClickable = checkClickable();  
  const dominantColorStyle = {
    backgroundColor: dominantColor
  }

  return (
    <>
      <div
        ref={containerRef}
        unselectable="on"
        draggable={false}
        data-index={index}
        onClick={onClickImageParent}
        style={followContainerSize ? styleContainerFollow : styleContainer}
        className={cx(
          isGif ? styles.gif : animationClasses?.imgContainer,
          styles.zenContainerPhotoTile,
          !isGhost ? "canSelect" : "",
          photoContainerStyle,
          !followContainerSize ? (resizeToFit ? styles.photoTileResize : styles.photoTile) : styles.photoTileFollow,
          isClickable ? styles.clickable : "",
          dropShadow ? styles.dropShadow : "",
          fullWidth ? styles.photoTileNoMargin : ""
        )}
        onMouseEnter={e => {
          setIsHovered(true);
          if (onHoverPhoto) {
            onHoverPhoto(index);
          }
        }}
        onMouseLeave={e => {
          setIsHovered(false);
          if (onHoverPhoto) {
            onHoverPhoto(-1);
          }
        }}
      >
        <Image
          ref={ref}
          data-index={index}
          onLoad={onPictureLoad}
          src={src}
          srcset={srcset}
          aspectratio={aspectRatio}
          className={cx(styles.masonryBrickImage, styles.transparentMansonryBrickImage)}
          styles={{
            objectPosition: focalStateX + "% " + focalStateY + "%",
            padding: getImagePadding(borderWidth),
          }}
          alt={alt ? alt : name}
          onMouseOver={onMouseOver}
          onClick={onClick}
          isGhost={isGhost}
          isClickable={isClickable}
        />
        {isVideo && renderPlayIcon(duration)}
        {showName(isHovered, name, nameVisibility) && (
          <Name
            name={name!}
            alignment={nameAlignment}
            isHovered={showHoverComponent}
            withDuration={withDuration || isVideo}
          />
        )}
        {getBadgeComponent && getBadgeComponent(index)}
        {overlayComponent && <div className={overlayStyle}>{overlayComponent}</div>}
        {showHoverComponent && <div className={overlayStyle}>{getHoverComponent!(index)}</div>}
        {(
          <>
            <div style={dominantColorStyle} className={animationClasses?.revealContainerBottom} />
            <div style={dominantColorStyle} className={animationClasses?.revealContainerTop} />
          </>
        )}
      </div>
      {title && <div className={titleClassName}>{title}</div>}
    </>
  );
};

export default ZenPhotoTile;
