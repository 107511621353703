import React from "react";

interface IMobilePresentsSVGProps {
  color?: string;
  width?: number;
}

function MobilePresentsSVG(props: IMobilePresentsSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width}
      height="153"
      viewBox={`0 20 369 160`}
    >
      <defs>
        <filter id="prefix__aa" width="131.7%" height="131.7%" x="-15.9%" y="-15.9%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="9.216"/>
        </filter>
        <filter id="prefix__bb" width="131.7%" height="131.7%" x="-15.9%" y="-15.9%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="5.53"/>
        </filter>
        <filter id="prefix__cc" width="105.2%" height="105.2%" x="-2.6%" y="-2.6%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="1.536"/>
        </filter>
        <filter id="prefix__ee" width="136%" height="136.7%" x="-18%" y="-18.4%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="6.144"/>
        </filter>
        <filter id="prefix__gg" width="108.7%" height="108.7%" x="-4.3%" y="-4.4%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="1.536"/>
        </filter>
        <filter id="prefix__ii" width="115%" height="115.3%" x="-7.5%" y="-7.7%" filterUnits="objectBoundingBox">
          <feGaussianBlur in="SourceGraphic" stdDeviation="1.536"/>
        </filter>
        <path
          fill={props.color}
          id="prefix__dd"
          d="M96.768 0c.509 0 .922.413.922.922V59.8c12.489-14.759 20.728-22.637 24.718-23.635 7.373-1.843 17.716 10.004 13.824 17.51-2.2 4.243-12.029 12.617-29.487 25.121h69.28c.51 0 .922.413.922.922v15.668a.92.92 0 01-.921.921h-62.622c15.236 12.84 23.361 21.289 24.375 25.344 1.843 7.373-10.004 17.717-17.51 13.824-3.949-2.047-11.475-10.702-22.58-25.965v65.594a.921.921 0 01-.921.922H81.101a.922.922 0 01-.922-.922v-66.092c-11.867 13.915-19.735 21.357-23.604 22.324-7.373 1.843-17.717-10.004-13.824-17.51 1.719-3.316 8.098-9.156 19.138-17.518H.922A.922.922 0 010 95.386V79.718c0-.509.413-.921.922-.921l60.31-.002c-16-13.398-24.519-22.177-25.558-26.337-1.844-7.372 10.003-17.716 17.51-13.824 4.451 2.308 13.45 13.014 26.996 32.118V.922A.92.92 0 0181.1 0h15.668zm.922 99.861v2.08c14.076 19.347 22.217 29.297 24.422 29.848 1.843.921 4.608 0 3.686-2.765-.564-1.692-9.933-11.413-28.108-29.163zM46.437 115.67c-.922 1.843 0 4.608 2.765 3.686 1.527-.509 9.595-8.191 24.203-23.047h-4.016c-14.82 10.981-22.47 17.435-22.952 19.36zm83.344-67.523c-1.77.59-12.317 10.807-31.641 30.65h1.051c21.653-15.64 32.77-24.629 33.355-26.963.921-1.844 0-4.608-2.765-3.687zm-82.127-3.06c.609 1.824 11.45 12.979 32.525 33.464v-.274c-16.8-23.365-26.412-35.35-28.839-35.957-1.843-.922-4.608 0-3.687 2.765z"
        />
        <path
          fill={props.color}
          id="prefix__hh"
          d="M58.06 0c.306 0 .554.248.554.553V35.88c7.494-8.855 12.437-13.582 14.83-14.18 4.424-1.107 10.63 6.002 8.295 10.505-1.32 2.546-7.218 7.57-17.693 15.074h41.57c.305 0 .552.247.552.552v9.4a.552.552 0 01-.553.553H68.042c9.142 7.705 14.017 12.773 14.626 15.207 1.105 4.423-6.003 10.63-10.507 8.294-2.369-1.228-6.885-6.421-13.547-15.579v39.356a.553.553 0 01-.553.553h-9.4a.552.552 0 01-.553-.553V65.407c-7.12 8.35-11.842 12.814-14.163 13.395-4.424 1.105-10.63-6.003-8.295-10.507 1.032-1.99 4.86-5.493 11.484-10.51H.553A.554.554 0 010 57.231v-9.4c0-.305.248-.553.553-.553H36.74c-9.6-8.04-14.712-13.307-15.336-15.803-1.106-4.424 6.002-10.63 10.506-8.294 2.671 1.384 8.07 7.808 16.197 19.27V.553c0-.305.248-.553.553-.553h9.4zm.554 59.917v1.247c8.446 11.609 13.33 17.579 14.653 17.91 1.106.552 2.765 0 2.212-1.66-.338-1.015-5.96-6.848-16.865-17.497zm-30.752 9.484c-.553 1.106 0 2.765 1.659 2.212.917-.305 5.757-4.915 14.522-13.828h-2.41C32.74 64.374 28.15 68.245 27.863 69.4zm50.006-40.513c-1.061.354-7.39 6.484-18.984 18.39h.63C72.507 37.893 79.178 32.5 79.528 31.1c.553-1.106 0-2.765-1.659-2.212zM28.593 27.05c.365 1.095 6.87 7.788 19.514 20.079v-.165C38.027 32.947 32.26 25.756 30.804 25.392c-1.105-.553-2.764 0-2.211 1.66z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(26 34)">
        <rect
          width="174.182"
          height="174.182"
          x="28.24"
          y="20.024"
          fill="#000"
          fillOpacity="0.2"
          filter="url(#prefix__aa)"
          rx="1.843"
          transform="rotate(-15 115.331 107.115)"
        />
        <rect
          width="104.509"
          height="104.509"
          x="206.112"
          y="64.984"
          fill="#000"
          fillOpacity="0.2"
          filter="url(#prefix__bb)"
          rx="1.106"
          transform="rotate(15 258.367 117.239)"
        />
        <g transform="rotate(-15 202.532 24.316)">
          <rect width="174.182" height="174.182" x="3.551" y="1.611" fill="#E2D7CD" rx="1.843"/>
          <path
            fill="#000"
            fillOpacity="0.1"
            d="M97.482 4.399a.92.92 0 01.921.921V64.2c12.49-14.76 20.73-22.638 24.719-23.635 7.373-1.843 17.716 10.004 13.824 17.51-2.2 4.243-12.03 12.617-29.487 25.121h69.28c.51 0 .922.413.922.922v15.667c0 .51-.413.922-.922.922h-62.621c15.236 12.84 23.361 21.288 24.375 25.344 1.843 7.373-10.004 17.716-17.51 13.824-3.949-2.048-11.475-10.703-22.58-25.966v65.595a.92.92 0 01-.921.921H81.815a.92.92 0 01-.922-.921V113.41c-11.867 13.915-19.735 21.356-23.605 22.324-7.372 1.843-17.716-10.004-13.824-17.51 1.72-3.317 8.1-9.156 19.139-17.518l-60.968-.001a.921.921 0 01-.921-.922V84.117c0-.509.412-.922.921-.922h60.31c-15.998-13.4-24.518-22.179-25.558-26.338-1.843-7.373 10.004-17.716 17.51-13.824 4.452 2.308 13.45 13.014 26.996 32.118V5.32c0-.51.413-.922.922-.922h15.667zm.921 99.861v2.08c14.077 19.347 22.218 29.296 24.423 29.847 1.843.922 4.608 0 3.686-2.764-.564-1.693-9.933-11.414-28.109-29.163zm-51.252 15.807c-.922 1.844 0 4.608 2.765 3.687 1.527-.51 9.595-8.192 24.203-23.047h-4.017c-14.82 10.98-22.47 17.434-22.951 19.36zm83.344-67.522c-1.77.59-12.317 10.806-31.642 30.65h1.052c21.653-15.641 32.77-24.63 33.354-26.964.922-1.843 0-4.608-2.764-3.686zm-82.127-3.06c.608 1.824 11.45 12.978 32.525 33.464v-.275C64.093 59.311 54.48 47.326 52.055 46.72c-1.844-.921-4.608 0-3.687 2.765z"
            filter="url(#prefix__cc)"
          />
          <g transform="translate(1.708 .69)">
            <mask id="prefix__ff" fill="#fff">
              <use xlinkHref="#prefix__dd"/>
            </mask>
            <use fill={props.color} xlinkHref="#prefix__dd"/>
            <path
              fill="#000"
              fillOpacity="0.1"
              d="M136.232 53.676c-2.595 5.004-15.805 15.756-39.629 32.256-.902.362-1.733.652-2.496.87 2.305.06 5.189.31 8.651.75 22.119 17.818 33.792 29.184 35.021 34.1 1.843 7.372-10.004 17.716-17.51 13.823-4.861-2.52-15.145-15.055-30.85-37.605-17.098 21.111-28.046 32.266-32.844 33.466-7.373 1.843-17.717-10.004-13.824-17.51C45.049 109.393 55.675 100.45 74.628 87a130.575 130.575 0 01-3.934-.442c-22.118-17.818-33.792-29.184-35.02-34.1-1.844-7.372 10.003-17.716 17.51-13.824C58.188 41.23 68.94 54.44 85.44 78.264c.304.754.556 1.46.758 2.118a9.408 9.408 0 011.353-.166c.04-2.364.293-5.375.758-9.03 17.817-22.118 29.184-33.792 34.099-35.02 7.373-1.844 17.716 10.003 13.824 17.51zM92.62 94.925c17.203 23.961 27.033 36.25 29.491 36.864 1.843.921 4.608 0 3.686-2.765-.614-1.843-11.673-13.21-33.177-34.1zm-10.377-7.66l-.152.01-.34.018c-22.943 16.513-34.714 25.972-35.315 28.376-.922 1.843 0 4.608 2.765 3.686 1.812-.604 12.826-11.3 33.042-32.09zm47.537-39.119c-1.843.615-13.21 11.674-34.1 33.178 23.962-17.203 36.25-27.034 36.865-29.491.921-1.844 0-4.608-2.765-3.687zm-82.127-3.06c.615 1.843 11.674 13.21 33.178 34.099-17.203-23.962-27.034-36.25-29.491-36.864-1.843-.922-4.608 0-3.687 2.765z"
              filter="url(#prefix__ee)"
              mask="url(#prefix__ff)"
            />
            <path
              fill={props.color}
              d="M136.232 53.676c-2.595 5.004-15.805 15.756-39.629 32.256-.902.362-1.733.652-2.496.87 2.305.06 5.189.31 8.651.75 22.119 17.818 33.792 29.184 35.021 34.1 1.843 7.372-10.004 17.716-17.51 13.823-4.861-2.52-15.145-15.055-30.85-37.605-17.098 21.111-28.046 32.266-32.844 33.466-7.373 1.843-17.717-10.004-13.824-17.51C45.049 109.393 55.675 100.45 74.628 87a130.575 130.575 0 01-3.934-.442c-22.118-17.818-33.792-29.184-35.02-34.1-1.844-7.372 10.003-17.716 17.51-13.824C58.188 41.23 68.94 54.44 85.44 78.264c.304.754.556 1.46.758 2.118a9.408 9.408 0 011.353-.166c.04-2.364.293-5.375.758-9.03 17.817-22.118 29.184-33.792 34.099-35.02 7.373-1.844 17.716 10.003 13.824 17.51zM92.62 94.925c17.203 23.961 27.033 36.25 29.491 36.864 1.843.921 4.608 0 3.686-2.765-.614-1.843-11.673-13.21-33.177-34.1zm-10.377-7.66l-.152.01-.34.018c-22.943 16.513-34.714 25.972-35.315 28.376-.922 1.843 0 4.608 2.765 3.686 1.812-.604 12.826-11.3 33.042-32.09zm47.537-39.119c-1.843.615-13.21 11.674-34.1 33.178 23.962-17.203 36.25-27.034 36.865-29.491.921-1.844 0-4.608-2.765-3.687zm-82.127-3.06c.615 1.843 11.674 13.21 33.178 34.099-17.203-23.962-27.034-36.25-29.491-36.864-1.843-.922-4.608 0-3.687 2.765z"
              mask="url(#prefix__ff)"
            />
          </g>
        </g>
        <g transform="rotate(15 -106.599 825.528)">
          <rect width="104.509" height="104.509" x="1.106" y="0.553" fill="#EAE3DC" rx="1.106"/>
          <path
            fill="#000"
            fillOpacity="0.1"
            d="M58.657 2.225c.306 0 .553.248.553.553v35.327c7.494-8.855 12.437-13.582 14.831-14.18 4.424-1.106 10.63 6.002 8.294 10.506-1.32 2.546-7.217 7.57-17.693 15.073h41.57c.305 0 .553.247.553.553v9.4a.554.554 0 01-.553.553H68.638c9.142 7.704 14.017 12.773 14.626 15.206 1.106 4.424-6.002 10.63-10.506 8.295-2.37-1.229-6.885-6.422-13.548-15.58v39.357a.553.553 0 01-.553.553h-9.4a.553.553 0 01-.553-.553V67.633c-7.12 8.349-11.841 12.814-14.163 13.394-4.424 1.106-10.63-6.002-8.294-10.506 1.031-1.99 4.859-5.493 11.483-10.511H1.15a.554.554 0 01-.554-.553v-9.4c0-.306.248-.553.553-.553h36.187C27.736 41.464 22.624 36.196 22 33.7c-1.105-4.423 6.003-10.63 10.507-8.294 2.67 1.385 8.07 7.808 16.197 19.27V2.778c0-.305.247-.553.553-.553h9.4zm.553 59.917v1.248c8.446 11.608 13.33 17.578 14.654 17.909 1.105.553 2.764 0 2.211-1.66-.338-1.015-5.96-6.847-16.865-17.497zm-30.752 9.485c-.552 1.106 0 2.765 1.66 2.212.916-.306 5.756-4.915 14.521-13.828h-2.41c-8.891 6.588-13.482 10.46-13.77 11.616zm50.007-40.514c-1.062.354-7.39 6.484-18.985 18.39h.63c12.993-9.384 19.663-14.777 20.014-16.178.553-1.106 0-2.765-1.66-2.212zm-49.276-1.836c.365 1.094 6.87 7.787 19.515 20.078v-.164C38.623 35.173 32.856 27.98 31.4 27.618c-1.106-.553-2.765 0-2.212 1.659z"
            filter="url(#prefix__gg)"
          />
          <mask id="prefix__jj" fill="#fff">
            <use xlinkHref="#prefix__hh"/>
          </mask>
          <use fill={props.color} xlinkHref="#prefix__hh"/>
          <path
            fill="#000"
            fillOpacity="0.2"
            d="M81.74 32.205c-1.558 3.003-9.484 9.454-23.778 19.354-.542.218-1.042.392-1.5.523 1.383.035 3.114.185 5.193.45 13.271 10.69 20.275 17.51 21.013 20.459 1.105 4.423-6.003 10.63-10.507 8.294-2.916-1.512-9.086-9.033-18.51-22.563-10.258 12.667-16.827 19.36-19.706 20.08-4.424 1.105-10.63-6.003-8.295-10.507 1.38-2.66 7.755-8.024 19.127-16.095a78.434 78.434 0 01-2.36-.265c-13.271-10.691-20.276-17.51-21.013-20.46-1.106-4.424 6.002-10.63 10.506-8.294 3.003 1.557 9.454 9.482 19.354 23.777.182.453.334.877.455 1.272.293-.057.563-.09.812-.1.024-1.418.175-3.225.454-5.418 10.69-13.271 17.51-20.275 20.46-21.013 4.423-1.106 10.63 6.003 8.294 10.506zm-26.168 24.75c10.322 14.377 16.22 21.75 17.695 22.118 1.106.553 2.765 0 2.212-1.659-.369-1.106-7.004-7.925-19.907-20.46zm-6.226-4.596c-.1.007-.196.012-.295.017C35.285 62.284 28.223 67.959 27.862 69.4c-.553 1.106 0 2.765 1.659 2.212 1.087-.362 7.696-6.78 19.825-19.254zm28.522-23.471c-1.105.368-7.925 7.004-20.46 19.906 14.378-10.322 21.75-16.22 22.12-17.694.552-1.106 0-2.765-1.66-2.212zM28.593 27.05c.368 1.106 7.004 7.926 19.906 20.46-10.322-14.377-16.22-21.75-17.695-22.119-1.105-.553-2.764 0-2.211 1.66z"
            filter="url(#prefix__ii)"
            mask="url(#prefix__jj)"
          />
          <path
            fill={props.color}
            d="M81.74 32.205c-1.558 3.003-9.484 9.454-23.778 19.354-.542.218-1.042.392-1.5.523 1.383.035 3.114.185 5.193.45 13.271 10.69 20.275 17.51 21.013 20.459 1.105 4.423-6.003 10.63-10.507 8.294-2.916-1.512-9.086-9.033-18.51-22.563-10.258 12.667-16.827 19.36-19.706 20.08-4.424 1.105-10.63-6.003-8.295-10.507 1.38-2.66 7.755-8.024 19.127-16.095a78.434 78.434 0 01-2.36-.265c-13.271-10.691-20.276-17.51-21.013-20.46-1.106-4.424 6.002-10.63 10.506-8.294 3.003 1.557 9.454 9.482 19.354 23.777.182.453.334.877.455 1.272.293-.057.563-.09.812-.1.024-1.418.175-3.225.454-5.418 10.69-13.271 17.51-20.275 20.46-21.013 4.423-1.106 10.63 6.003 8.294 10.506zm-26.168 24.75c10.322 14.377 16.22 21.75 17.695 22.118 1.106.553 2.765 0 2.212-1.659-.369-1.106-7.004-7.925-19.907-20.46zm-6.226-4.596c-.1.007-.196.012-.295.017C35.285 62.284 28.223 67.959 27.862 69.4c-.553 1.106 0 2.765 1.659 2.212 1.087-.362 7.696-6.78 19.825-19.254zm28.522-23.471c-1.105.368-7.925 7.004-20.46 19.906 14.378-10.322 21.75-16.22 22.12-17.694.552-1.106 0-2.765-1.66-2.212zM28.593 27.05c.368 1.106 7.004 7.926 19.906 20.46-10.322-14.377-16.22-21.75-17.695-22.119-1.105-.553-2.764 0-2.211 1.66z"
            mask="url(#prefix__jj)"
          />
        </g>
      </g>
    </svg>
  );
}

MobilePresentsSVG.defaultProps = {
  color: "#D1B4CE",
  width: 369
};

export default MobilePresentsSVG;
