import classNames from "classnames";
import moment from "moment";
import React from "react";
import { ScrollbarProps } from "react-custom-scrollbars";
import DatePicker from "./DatePicker";
import styles from "./dateTimePicker.module.scss";
import DayPicker, { DayStatus, getWeekDays } from "./DayPicker";
import MonthPicker from "./MonthPicker";
import TimePicker from "./TimePicker";
import { NavigationDirection } from "./TimePicker/navigation";

interface IDateTimePickerProps {
  defaultDate?: moment.Moment;
  date?: moment.Moment;
  maxDate?: moment.Moment;
  minDate?: moment.Moment;
  dayStatuses?: DayStatus[];
  defaultTime?: number | null;
  time?: number | null;
  times?: number[];
  noTimeText?: string;
  allowTimeGrouping?: boolean;
  onChange?: (date: moment.Moment, time: number | null) => void;
  scrollbar?: ScrollbarProps;
  className?: string;
  style?: React.CSSProperties;
}

interface IDateTimePickerState {
  date?: moment.Moment;
  time?: number | null;
}

class DateTimePicker extends React.Component<IDateTimePickerProps, IDateTimePickerState> {
  public static DatePicker = DatePicker;
  public static DayPicker = DayPicker;
  public static MonthPicker = MonthPicker;
  public static TimePicker = TimePicker;
  public static getWeekDays = getWeekDays;

  public state: IDateTimePickerState = {};

  public render() {
    const { className, scrollbar, style } = this.props;
    const { minDate, maxDate, dayStatuses, noTimeText, allowTimeGrouping, times } = this.props;

    return (
      <div className={classNames(styles.container, className)} style={style}>
        <DatePicker
          date={this.date}
          minDate={minDate}
          maxDate={maxDate}
          dayStatuses={dayStatuses}
          onChange={this.onDateChange}
        />
        <TimePicker
          time={this.time}
          times={times}
          noTimeText={noTimeText}
          allowGrouping={allowTimeGrouping}
          onChange={this.onTimeChange}
          navigation={{
            position: this.date.valueOf(),
            minPosition: minDate ? minDate.valueOf() : undefined,
            maxPosition: maxDate ? maxDate.valueOf() : undefined,
            onChange: this.onTimeNavigationChange
          }}
          className={styles.time}
          scrollbar={scrollbar}
        />
      </div>
    );
  }

  private get date() {
    const { props, state } = this;

    return (props.date !== undefined
      ? props.date
      : state.date !== undefined
      ? state.date
      : props.defaultDate || this.now
    )
      .clone()
      .startOf("day");
  }

  private get time() {
    const { props, state } = this;
    return props.time !== undefined ? props.time : state.time !== undefined ? state.time : props.defaultTime;
  }

  private changeDateTime = (date: moment.Moment, time: number | null) => {
    const onChange = (d: moment.Moment, t: number | null) => {
      if (this.props.onChange) {
        this.props.onChange(d, t);
      }
    };

    if (this.props.date === undefined || this.props.time === undefined) {
      let updater = {};
      updater = this.props.date === undefined ? { ...updater, date } : updater;
      updater = this.props.time === undefined ? { ...updater, time } : updater;
      this.setState(updater, () => onChange(this.date, this.time!));
    } else {
      onChange(date, time);
    }
  };

  private onDateChange = (date: moment.Moment) => this.changeDateTime(date, null);

  private onTimeChange = (time: number) => this.changeDateTime(this.date, time);

  private onTimeNavigationChange = (direction: NavigationDirection) =>
    this.changeDateTime(this.date.add(direction === "forward" ? 1 : -1, "day"), null);

  private readonly now = moment();
}

export default DateTimePicker;
