import { Badge, Nav, Navbar, NavbarBrand, NavItem, NavLink } from "@zenfolio/core-components";
import classNames from "classnames";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import alertIcon from "../../../../assets/icons/alert.svg";
import { getCurrentRoute, getRouteBasePath } from "../../../../store/helpers";
import { IUserInfo } from "../../../../store/session/model";
import Logo, { ILogo } from "../../../logo";
import { IRoute } from "../../../router/routes";
import styles from "./index.module.scss";

export interface ISideBarProps extends RouteComponentProps {
  logo: ILogo;
  items: IRoute[];
  userInfo: IUserInfo | null;
}

class SideBar extends React.Component<ISideBarProps> {
  public render() {
    const { logo } = this.props;
    const selected = this.findSelectedMenuItem();

    return (
      <Navbar>
        <NavbarBrand onClick={this.onLogoClick}>
          <Logo logo={logo} />
        </NavbarBrand>
        <Nav className={styles.navigation}>{this.generateMenuItems(selected)}</Nav>
      </Navbar>
    );
  }

  private findSelectedMenuItem() {
    return getCurrentRoute(this.props.items, this.props.location);
  }

  private generateMenuItems = (selected: IRoute | undefined) => {
    const { userInfo } = this.props;

    return this.props.items.map((item: IRoute) => {
      const isActive = item === selected;
      const classes = classNames(styles.link, isActive && styles.active);

      const onSelectMenuItem = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
        this.onSelectMenuItem(event, item);

      const addon = item.getAddon && userInfo ? item.getAddon(userInfo) : undefined;

      return (
        <NavLink key={item.key} onClick={onSelectMenuItem}>
          <NavItem active={isActive} className={classes}>
            <div className={styles.icon}>{item.icon}</div>
            <p>{item.title}</p>
            {addon && addon.type === "onOff" && <em>{addon.value ? "On" : "Off"}</em>}
            {addon && addon.type === "badge" && <Badge className={styles.badge} value={addon.value} />}
            {addon && addon.type === "alert" && addon.value && (
              <img className={styles.alert} src={alertIcon} alt="alert" />
            )}
          </NavItem>
        </NavLink>
      );
    });
  };

  private onLogoClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    this.props.history.push("/");
  };

  private onSelectMenuItem = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: IRoute) => {
    event.preventDefault();
    this.props.history.push(getRouteBasePath(item));
  };
}

export default SideBar;
