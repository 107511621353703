import cx from "classnames";
import { IZenSiteTheme } from "../../../models/models";
import { Spinner } from "components/Spinner";
import React, { FC } from "react";
import { hexToRgb } from "utilities/blocks/blockColors";
import styles from "./zenLoading.module.scss";

interface IZenLoadingProps {
  siteTheme: IZenSiteTheme;
  className?: string;
  spinnerClassName?: string;
}

const ZenLoading: FC<IZenLoadingProps> = props => {
  const { siteTheme, className, spinnerClassName } = props;
  const backgroundRgb = hexToRgb(siteTheme.backgroundColor.value)!;

  return (
    <div
      className={cx(styles.loading, className)}
      style={{ background: `rgba(${backgroundRgb.r}, ${backgroundRgb.g}, ${backgroundRgb.b}, 0.5)` }}
    >
      <Spinner hexColor={siteTheme.accentColor.value} className={spinnerClassName} />
    </div>
  );
};

export default ZenLoading;
