import { combineReducers } from "redux";
import IError from "../../../utilities/error";
import { ActionStatus, IBaseState } from "../../common";
import {
  GetPaymentDashboardAction,
  LoadStripeSettingsAction,
  SetupStripeAction,
  ToggleApplePayAction,
  UpdateStatementDescriptorAction
} from "./actions";
import { StripeActionType } from "./constants";
import { IStripeSettings } from "./model";

export interface IStripeSetupState extends IBaseState {
  paymentUri: string;
}

const initialStripeState: IStripeSetupState = {
  status: "Init",
  error: null,
  paymentUri: "#"
};

const stripeSetup = (state: IStripeSetupState = initialStripeState, action: SetupStripeAction): IStripeSetupState => {
  switch (action.type) {
    case StripeActionType.SETUP_STRIPE_STARTED:
      return { ...state, status: "Pending", error: null };
    case StripeActionType.SETUP_STRIPE_SUCCESS:
      return {
        ...state,
        status: "Success",
        paymentUri: action.paymentUri,
        error: null
      };
    case StripeActionType.SETUP_STRIPE_ERROR:
      return {
        ...state,
        status: "Error",
        error: action.error
      };
    default:
      return state;
  }
};

export interface ILoadStripeSettingsState {
  loadStriptSettingsStatus: ActionStatus;
  toggleStripeApplePayStatus: ActionStatus;
  updateStatementDescriptorStatus: ActionStatus;
  currentSettings: IStripeSettings;
  error: IError | null;
}

const initialLoadStripeSettingsState: ILoadStripeSettingsState = {
  loadStriptSettingsStatus: "Init",
  error: null,
  toggleStripeApplePayStatus: "Init",
  updateStatementDescriptorStatus: "Init",
  currentSettings: {
    showPaymentRequestButton: false,
    stripeEmail: "",
    statementDescriptor: null,
    isStripeSetupComplete: null
  }
};

export const loadStripeSettings = (
  state: ILoadStripeSettingsState = initialLoadStripeSettingsState,
  action: LoadStripeSettingsAction | ToggleApplePayAction | UpdateStatementDescriptorAction
): ILoadStripeSettingsState => {
  switch (action.type) {
    case StripeActionType.LOAD_STRIPE_SETTINGS_STARTED:
      return { ...state, loadStriptSettingsStatus: "Pending", error: null };
    case StripeActionType.LOAD_STRIPE_SETTINGS_SUCCESS:
      return {
        ...state,
        currentSettings: action.settings,
        loadStriptSettingsStatus: "Success",
        error: null
      };
    case StripeActionType.LOAD_STRIPE_SETTINGS_ERROR:
      return {
        ...state,
        loadStriptSettingsStatus: "Error",
        error: action.error
      };

    case StripeActionType.TOGGLE_STIPE_APPLE_PAY_STARTED:
      return {
        ...state,
        toggleStripeApplePayStatus: "Pending",
        error: null
      };
    case StripeActionType.TOGGLE_STIPE_APPLE_PAY_SUCCESS:
      return { ...state, toggleStripeApplePayStatus: "Success", error: null };
    case StripeActionType.TOGGLE_STIPE_APPLE_PAY_ERROR:
      return { ...state, toggleStripeApplePayStatus: "Error", error: action.error };

    case StripeActionType.UPDATE_STATEMENT_DESCRIPTOR_STARTED:
      return {
        ...state,
        updateStatementDescriptorStatus: "Pending",
        error: null
      };
    case StripeActionType.UPDATE_STATEMENT_DESCRIPTOR_SUCCESS:
      return { ...state, updateStatementDescriptorStatus: "Success", error: null };
    case StripeActionType.UPDATE_STATEMENT_DESCRIPTOR_ERROR:
      return { ...state, updateStatementDescriptorStatus: "Error", error: action.error };
    default:
      return state;
  }
};
export interface IGetPaymentDashboardLinkState {
  getPaymentDashboardLinkStatus: ActionStatus;
  dashboardLink: string | null;
  error: IError | null;
}

const initialGetPaymentDashboardLinkState: IGetPaymentDashboardLinkState = {
  getPaymentDashboardLinkStatus: "Init",
  dashboardLink: null,
  error: null
};

export const getPaymentDashboardLink = (
  state: IGetPaymentDashboardLinkState = initialGetPaymentDashboardLinkState,
  action: GetPaymentDashboardAction
): IGetPaymentDashboardLinkState => {
  switch (action.type) {
    case StripeActionType.GET_PAYMENT_DASHBOARD_LINK_STARTED:
      return { getPaymentDashboardLinkStatus: "Pending", dashboardLink: null, error: null };
    case StripeActionType.GET_PAYMENT_DASHBOARD_LINK_SUCCESS:
      return {
        getPaymentDashboardLinkStatus: "Success",
        dashboardLink: action.dashboardLink,
        error: null
      };
    case StripeActionType.GET_PAYMENT_DASHBOARD_LINK_ERROR:
      return {
        getPaymentDashboardLinkStatus: "Error",
        dashboardLink: null,
        error: action.error
      };
    default:
      return state;
  }
};

export default combineReducers({
  setup: stripeSetup,
  settings: loadStripeSettings,
  paymentDashboard: getPaymentDashboardLink
});
