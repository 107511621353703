import TimeInput from "components/NumericInputs/TimeInput";
import React, { PureComponent } from "react";
import styles from "./timeSlot.module.scss";

class TimeSlot extends PureComponent<any> {
  private onChangeStart = (value: number) => {
    const { index, step, onStartChange } = this.props;
    value = Math.floor(value / step) * step;
    onStartChange(value, index);
  };

  private onChangeEnd = (value: number) => {
    const { index, step, onEndChange } = this.props;
    value = Math.ceil(value / step) * step;
    onEndChange(value, index);
  };

  private handleDeleteSlot = () => {
    const { index, onDeleteSlot } = this.props;
    onDeleteSlot(index);
  };

  public render() {
    const { start, end, step, timeInputButtonsContainer } = this.props;

    return (
      <div className={styles.slot}>
        <div className={styles.selection}>
          <div className={styles.selectDay}>
            <label className={styles.label}>Start</label>
            <TimeInput
              value={start.value}
              step={step}
              min={start.min}
              max={start.max}
              buttonsContainer={timeInputButtonsContainer}
              onChange={this.onChangeStart}
            />
          </div>
        </div>

        <div className={styles.selection}>
          <div className={styles.selectDay}>
            <label className={styles.label}>End</label>
            <TimeInput
              value={end.value}
              step={step}
              min={end.min}
              max={end.max}
              buttonsContainer={timeInputButtonsContainer}
              onChange={this.onChangeEnd}
            />
          </div>
        </div>

        <button className={styles.deleteSlot} onClick={this.handleDeleteSlot}>
          <div className={styles.line} />
        </button>
      </div>
    );
  }
}

export default TimeSlot;
