import classNames from "classnames";
import React from "react";
import styles from "./numericPlusMinus.module.scss";

interface INumericPlusMinusProps {
  defaultValue?: number;
  value?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
  className?: string;
  step?: number;
}

interface INumericPlusMinusState {
  value?: number;
  step: number;
}

class NumericPlusMinus extends React.Component<INumericPlusMinusProps, INumericPlusMinusState> {
  constructor(props: INumericPlusMinusState) {
    super(props);

    this.state = {
      step: this.props.step || NumericPlusMinus.defaults.step
    };
  }

  public render() {
    const { className, disabled, min, max } = this.props;
    const { step } = this.state;
    const value = this.value;

    const minusDisabled = (min !== undefined && value - step < min) || disabled;
    const plusDisabled = (max !== undefined && value + step > max) || disabled;

    return (
      <div className={classNames(styles.container, className)}>
        <button className={styles.button} onClick={this.onMinusClick} disabled={minusDisabled}>
          −
        </button>
        <span className={styles.value}>{value}</span>
        <button className={styles.button} onClick={this.onPlusClick} disabled={plusDisabled}>
          +
        </button>
      </div>
    );
  }

  private get value() {
    return NumericPlusMinus.resolveValue(this.props, this.state);
  }

  private onPlusClick = () => this.onChange(this.state.step);

  private onMinusClick = () => this.onChange(-this.state.step);

  private onChange = (increment: number) => {
    const onChange = (value: number) => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    };

    if (this.props.value === undefined) {
      this.setState(
        (state, props) => ({ value: NumericPlusMinus.resolveValue(props, state) + increment }),
        () => onChange(this.value)
      );
    } else {
      onChange(this.value + increment);
    }
  };

  private static resolveValue(props: INumericPlusMinusProps, state: INumericPlusMinusState) {
    return props.value !== undefined ? props.value : state.value !== undefined ? state.value : props.defaultValue || 0;
  }

  private static readonly defaults: INumericPlusMinusState = {
    step: 1
  };
}

export default NumericPlusMinus;
