import _ from "lodash";
import moment from "moment";
import { IComponentBaseProps } from "../../models/props";
import Weekdays from "../../utilities/weekdays";

export interface IDatePickerInputProps extends IComponentBaseProps<HTMLDivElement> {
  date?: moment.Moment | null;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  format?: string;
  countryCode?: string;
  onOpenToggle?: () => void;
  onClear?: () => void;
}

export interface IDatePickerCalendarContainerProps extends IComponentBaseProps<HTMLDivElement> {
  open?: boolean;
  onClose?: () => void;
}

export interface IDatePickerCalendarHeaderProps extends IComponentBaseProps<HTMLDivElement> {
  mode: CalendarMode;
  month: moment.Moment;
  onMonthClick?: () => void;
  onYearClick?: () => void;
  onNavigate?: (direction: NavigationDirection) => void;
}

export interface IDatePickerCalendarDaysProps extends IComponentBaseProps<HTMLDivElement> {
  month: moment.Moment;
  date?: moment.Moment | null;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  dateStatuses?: DateStatusProvider;
  countryCode?: string;
  onDateChange?: (date: moment.Moment) => void;
}

export interface IDatePickerCalendarYearsProps extends IComponentBaseProps<HTMLDivElement> {
  year: number;
  onYearChange?: (year: number) => void;
}

export interface IDatePickerCalendarMonthsProps extends IComponentBaseProps<HTMLDivElement> {
  month: number;
  onMonthChange?: (month: number) => void;
}

export type DateStatus = "normal" | "disabled";
export type CalendarMode = "days" | "months" | "years";
export type NavigationDirection = "back" | "forward";
export type MonthChangeAction = "navigate-back" | "navigate-forward" | "pick-month" | "pick-year";
export type DateStatusProvider = DateStatus[] | ((date: moment.Moment) => DateStatus);

export type DatePickerInput = React.ComponentType<IDatePickerInputProps>;
export type DatePickerCalendarContainer = React.ComponentType<IDatePickerCalendarContainerProps>;
export type DatePickerCalendarHeader = React.ComponentType<IDatePickerCalendarHeaderProps>;
export type DatePickerCalendarDays = React.ComponentType<IDatePickerCalendarDaysProps>;
export type DatePickerCalendarYears = React.ComponentType<IDatePickerCalendarYearsProps>;
export type DatePickerCalendarMonths = React.ComponentType<IDatePickerCalendarMonthsProps>;

export function getMonthDates(date: moment.Moment, fillWeeks: boolean, countryCode?: string) {
  let startDate = date.clone().startOf("month");
  startDate = fillWeeks ? Weekdays.startOfWeek(startDate, countryCode) : startDate;
  let endDate = date.clone().endOf("month");
  endDate = fillWeeks ? Weekdays.endOfWeek(endDate, countryCode) : endDate;
  const dates = _.range(0, endDate.diff(startDate, "day") + 1).map(i => startDate.clone().add(i, "days"));
  return dates;
}

export function isDateDisabled(
  date: moment.Moment,
  dateStatuses?: DateStatusProvider,
  minDate?: moment.Moment,
  maxDate?: moment.Moment
) {
  const dateStatus = dateStatuses
    ? _.isFunction(dateStatuses)
      ? dateStatuses(date)
      : dateStatuses[date.date() - 1]
    : "normal";
  return !date.isBetween(minDate || date, maxDate || date, "date", "[]") || dateStatus === "disabled";
}
