import cx from "classnames";
import _defaults from "lodash/defaults";
import * as React from "react";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import videojs, { VideoJsPlayerOptions } from "video.js";
import useCombinedRef from "../../../hooks/useCombinedRef";
import "./PlaybackStatsPlugin";
import { IPlaybackStatsRecord } from "./PlaybackStatsPlugin";
import styles from "./player.module.scss";
import "./QualitySelectorPlugin";
import { IQualityOptions } from "./QualitySelectorPlugin";

export interface IVideo {
  src: string;
  type: string;
}

export type LeaveAction = "none" | "pause" | "stop";

export type VideoPlacement = "contain" | "cover";

export interface IVideoPlayer {
  pause(): void;
  resume(): void;
  play(): void;
  stop(): void;
  // It exists, but use it only for debug.
  // getVjsPlayer(): videojs.Player | undefined;
}

export interface IPlayerProps {
  video?: IVideo;
  /**
   * Default volume in the range [0, 1]. Session-wide volume takes precedence.
   */
  defaultVolume?: number;
  /**
   * Specifies whether the video should be muted by default.
   */
  defaultMuted?: boolean;
  /**
   * Specifies whether the video should automatically play.
   */
  autoplay?: boolean;
  /**
   * Specifies whether the video playback should be looped.
   */
  loop?: boolean;
  /**
   * Specifies the action that is executed when the video leaves the viewport. *"none"* by default.
   */
  leaveAction?: LeaveAction;
  /**
   * Specifies whether to disable (hide) the Picture-in-picture feature.
   */
  disablePiP?: boolean;
  /**
   * Specifies whether the component should listen to keyboard events on document level, recognize relevant
   * key presses and intercept them. Use with caution because it might affect functionality of other elements
   * on the page, if they use the same key presses for something else. *false* by default.
   */
  globalKeyboardControls?: boolean;
  /**
   * Specifies whether the user can interact with the video. If set to *false* then all video controls are hidden,
   * the video is not focusable and ignores all mouse and keyboard inputs. *true* by default.
   */
  interactive?: boolean;
  /**
   * Specifies how video should be positioned inside container. *"contain"* by default.
   */
  videoPlacement?: VideoPlacement;
  /**
   * Specifies whether the video should be shown in "background" mode. Background video automatically plays,
   * it is muted, looped, not interactive, and covers its container. Basically this prop is just a shortcut for
   * a combination of several other props. You can still override those props.
   */
  backgroundMode?: boolean;
  /**
   * Specifies whether to show the loading indicator. *true* by default.
   */
  loadingIndicator?: boolean;
  /**
   * Specifies quality control settings.
   */
  quality?: IQualityOptions;
  className?: string;
  style?: React.CSSProperties;
  containerRef?: React.Ref<HTMLDivElement>;
  /**
   * Called when autoplay succeeds.
   */
  onAutoplaySuccess?: () => void;
  /**
   * Called when autoplay fails (blocked by browser).
   */
  onAutoplayFail?: () => void;
  /**
   * Called when the video leaves the viewport.
   */
  onLeave?: () => void;
  /**
   * Called when the video starts playing (for the first time), either as a result of clicking on the play button,
   * because of autoplay, or imperatively (using *play* method). If the video has been *stopped* (not just paused,
   * or ended), then this callback resets and can be executed again under the same conditions.
   */
  onStart?: () => void;
  /**
   * Called when the video is *stopped* (not just paused, or ended). Video can be stopped imperatively
   * (using *stop* method) or via the *leaveAction*.
   */
  onStop?: () => void;
  /**
   * Called when enough data has been loaded to start playing the video.
   */
  onVideoLoad?: () => void;
  /**
   * Called when an error occurs when loading the video.
   */
  onVideoError?: () => void;
  /**
   * Called when a playback stats record is generated.
   */
  onPlaybackStats?: (record: IPlaybackStatsRecord) => void;
}

const Player = React.forwardRef((props: IPlayerProps, ref: React.Ref<IVideoPlayer>) => {
  return <PlayerImpl key={props.video?.src || "empty"} ref={ref} {...props} />;
});

const PlayerImpl = React.forwardRef((props: IPlayerProps, ref: React.Ref<IVideoPlayer>) => {
  const { video, defaultVolume, leaveAction, disablePiP, backgroundMode, globalKeyboardControls } = props;
  const { loadingIndicator = true, quality, className, style } = props;
  const { onLeave, onStart, onStop, onVideoLoad, onVideoError, onPlaybackStats } = props;

  const globalDefaults: Partial<IPlayerProps> = { interactive: true, videoPlacement: "contain", leaveAction: "none" };
  const backgroundModeDefaults: Partial<IPlayerProps> = {
    autoplay: true,
    defaultMuted: true,
    loop: true,
    interactive: false,
    videoPlacement: "cover"
  };
  const defaults = _defaults({}, props, backgroundMode ? backgroundModeDefaults : null, globalDefaults);
  const { autoplay, defaultMuted, loop, interactive, videoPlacement } = defaults as Required<typeof defaults>;

  const [playerReady, setPlayerReady] = useState<boolean>(false);
  const [autoplayFailed, setAutoplayFailed] = useState<boolean>(false);
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const { internalRef: internalContainerRef, combinedRef: containerRef } = useCombinedRef(props.containerRef);
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<videojs.Player | undefined>();

  useImperativeHandle(ref, () => ({ pause, resume, play, stop, getVjsPlayer }));

  useEffect(onVideoPropsChange, [autoplay, loop, disablePiP, interactive]);
  useEffect(onMount, []);
  useEffect(onUpdate);

  function onVideoPropsChange() {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) {
        return;
      }

      const options: VideoJsPlayerOptions & { loadingSpinner: boolean } = {
        sources: video ? [video] : undefined,
        controls: true,
        responsive: true,
        loop,
        controlBar: { pictureInPictureToggle: !disablePiP, captionsButton: false, subsCapsButton: false },
        loadingSpinner: loadingIndicator,
        html5: {
          vhs: {
            useBandwidthFromLocalStorage: true,
            useDevicePixelRatio: true,
            experimentalBufferBasedABR: true,
            overrideNative: true
          }
        }
      };

      playerRef.current = videojs(videoElement, options, onPlayerReady);
      playerRef.current.qualitySelectorPlugin(quality);
      playerRef.current.playbackStatsPlugin();
    } else {
      const player = playerRef.current;
      const playerAny = player as any;

      tryAutoplay();

      if (!player.loop() && loop && player.ended()) {
        player.play();
      }

      if (player.controls() !== interactive) {
        player.controls(interactive);
      }

      const pipToggle = player.controlBar.getChild("PictureInPictureToggle");
      if (disablePiP && pipToggle) {
        if (playerAny.isInPictureInPicture()) {
          playerAny.exitPictureInPicture();
        }
        player.controlBar.removeChild(pipToggle);
      } else if (!disablePiP && !pipToggle) {
        const fullscreenToggle = player.controlBar.getChild("FullscreenToggle");
        player.controlBar.addChild(
          "PictureInPictureToggle",
          {},
          (fullscreenToggle
            ? player.controlBar.children().indexOf(fullscreenToggle)
            : player.controlBar.children().length) - 1
        );
        playerAny.disablePictureInPicture(false);
      }

      player.loop(!!loop);
    }
  }

  function onPlayerReady() {
    const player = playerRef.current!;
    const sessionVolume = parseFloat(sessionStorage.getItem("components-video-player-volume") || "");
    const volume = isNaN(sessionVolume) ? defaultVolume : sessionVolume;
    if (defaultMuted) {
      player.muted(true);
    }
    if (volume !== undefined) {
      player.volume(volume);
    }
    if (!interactive) {
      player.controls(false);
    }
    if (!video) {
      player.hide();
    }
    player.playsinline(true);
    tryAutoplay();
    setPlayerReady(true);
  }

  function onAutoplaySuccess() {
    if (playerRef.current) {
      props.onAutoplaySuccess?.();
    }
  }

  function onAutoplayFail(error: DOMException) {
    if (playerRef.current && error.name === "NotAllowedError") {
      setAutoplayFailed(true);
      props.onAutoplayFail?.();
    }
  }

  function onMount() {
    return onUnmount;
  }

  function onUnmount() {
    if (playerRef.current) {
      playerRef.current.dispose();
      playerRef.current = undefined;
    }
  }

  function onUpdate() {
    const player = playerRef.current;
    const intersectionObserver = new IntersectionObserver(onIntersectionChange);
    const container = internalContainerRef.current;

    function onPlayerPlay() {
      if (!hasStarted) {
        setHasStarted(true);
        onStart?.();
      }
    }

    function onPlayerLoadedData() {
      onVideoLoad?.();
    }

    function onPlayerError() {
      onVideoError?.();
    }

    function onDocumentKeyDown(event: KeyboardEvent) {
      const acceptInputs = globalKeyboardControls && interactive && player && player.hasStarted();
      if (acceptInputs && event.code === "Space" && !event.repeat) {
        togglePlay();
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
      }
    }

    function onPluginPlaybackStats(event: { record: IPlaybackStatsRecord }) {
      onPlaybackStats?.(event.record);
    }

    container && intersectionObserver.observe(container);
    document.addEventListener("keydown", onDocumentKeyDown, true);

    player && playerReady && player.on("play", onPlayerPlay);
    player && playerReady && player.on("loadeddata", onPlayerLoadedData);
    player && playerReady && player.on("error", onPlayerError);
    player && playerReady && player.playbackStatsPlugin().on("record", onPluginPlaybackStats);

    return function cleanup() {
      container && intersectionObserver.unobserve(container);
      intersectionObserver.disconnect();
      document.removeEventListener("keydown", onDocumentKeyDown, true);

      player && playerReady && player.off("play", onPlayerPlay);
      player && playerReady && player.off("loadeddata", onPlayerLoadedData);
      player && playerReady && player.off("error", onPlayerError);
      player && playerReady && player.playbackStatsPlugin().off("record", onPluginPlaybackStats);
    };
  }

  function onIntersectionChange(entries: IntersectionObserverEntry[]) {
    if (!entries[0].isIntersecting && playerRef.current) {
      if (leaveAction === "pause") {
        pause();
      } else if (leaveAction === "stop") {
        stop();
      }
      onLeave?.();
    }
  }

  function onVideoVolumeChange(event: React.SyntheticEvent<HTMLVideoElement>) {
    sessionStorage.setItem("components-video-player-volume", event.currentTarget.volume.toString());
  }

  function onVideoKeyDown(event: React.KeyboardEvent<HTMLVideoElement>) {
    const player = playerRef.current;
    if (player && player.hasStarted() && interactive && event.keyCode === 32 && !event.repeat) {
      togglePlay();
    }
  }

  function tryAutoplay() {
    const player = playerRef.current!;
    if (autoplay && video && !player.hasStarted()) {
      const playResult = videoRef.current!.play();
      if (playResult) {
        playResult.then(onAutoplaySuccess).catch(onAutoplayFail);
      }
    }
  }

  function togglePlay() {
    const player = playerRef.current;
    if (player) {
      player.paused() ? player.play() : player.pause();
    }
  }

  function pause() {
    const player = playerRef.current;
    if (player && player.hasStarted()) {
      player.pause();
    }
  }

  function resume() {
    const player = playerRef.current;
    if (player && player.hasStarted() && player.paused()) {
      player.play();
    }
  }

  function play() {
    const player = playerRef.current;
    if (player && (!player.hasStarted() || player.paused())) {
      player.play();
    }
  }

  function stop() {
    const player = playerRef.current;
    if (player && player.hasStarted()) {
      player.pause();
      player.currentTime(0);
      player.autoplay(false);
      player.hasStarted(false);
      player.bigPlayButton.show();
      setHasStarted(false);
      player.trigger("stop"); // this is a custom event
      onStop?.();
    }
  }

  function getVjsPlayer() {
    return playerRef.current;
  }

  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.hideBigPlayButton]: autoplay && !autoplayFailed,
          [styles.interactive]: interactive,
          [styles.videoCover]: videoPlacement === "cover"
        },
        className
      )}
      style={style}
      ref={containerRef}
    >
      <div data-vjs-player style={{ width: "100%", height: "100%", visibility: playerReady ? undefined : "hidden" }}>
        <video
          ref={videoRef}
          className="video-js vjs-big-play-centered"
          onVolumeChange={onVideoVolumeChange}
          onKeyDown={onVideoKeyDown}
        />
      </div>
    </div>
  );
});

export default Player;
export { IQualityOptions, IPlaybackStatsRecord };
