import { AsyncLoader } from "./asyncLoader";
import CallbackEvent from "./callbackEvent";

export interface IPageInfo {
  iframeHeight: number;
  iframeWidth: number;
  offsetLeft: number;
  offsetTop: number;
  scrollLeft: number;
  scrollTop: number;
  documentHeight: number;
  documentWidth: number;
  windowHeight: number;
  windowWidth: number;
}

class IFramePageInfoChange extends CallbackEvent<IPageInfo> {
  public constructor() {
    super({ invokeOnSubscription: true, invokeOnSubscriptionTimeout: 0 });
  }

  protected registerEvent(): void {
    AsyncLoader.subscribe(AsyncLoader.iframeResizerChild, this.asyncLoaderCallback);
  }

  protected unregisterEvent(): void {
    AsyncLoader.unsubscribe(AsyncLoader.iframeResizerChild, this.asyncLoaderCallback);

    if (this.parentIFrame) {
      this.parentIFrame.getPageInfo(false);
      this.parentIFrame = undefined;
    }
  }

  private readonly asyncLoaderCallback = (parentIFrame: any) => {
    this.parentIFrame = parentIFrame;
    parentIFrame.getPageInfo(this.eventCallback);
  };

  private parentIFrame: any;
}

const instance = new IFramePageInfoChange();

export default instance;
