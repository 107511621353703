export interface IBlogPostInfo {
  id: string;
  alias: string;
  publishDate?: string;
  coverPhotoId: string;
  coverPhotoFocalPointX: number;
  coverPhotoFocalPointY: number;
  seoDescription?: string;
  author?: string;
  title: string;
  isIndexable?: boolean;
}

export interface IBlogPostCategoryInfo {
  id: string;
  name: string;
}

export interface IBlogPostsQuery {
  skip: number;
  take: number;
  category?: string;
  enableFeaturedBlogPost: boolean;
  allBlogPostsIncludeFeatured: boolean;
  enableBlogPostCategories: boolean;
}

export interface IBlogPostsInfo {
  blogPosts: IBlogPostInfo[];
  count: number;
  featuredPost?: IBlogPostInfo;
  allCategories?: IBlogPostCategoryInfo[];
  showCategories: boolean;
}

// keep in sync with common.scss
export const maxColumnWidth = 568;
export const columnGap = 40;
export const paddingDesktop = 40;
export const paddingMobile = 16;
export const compactLayoutWidth = 767;
