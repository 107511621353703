import { ActionState } from "../common";
import {
  AddInvoiceAction,
  CalculateTaxForEditingInvoiceAction,
  CalculateTaxForNewInvoiceAction,
  DeleteInvoiceAction,
  EditInvoiceAction,
  LoadAction,
  MarkAsPaidAction,
  RefundAction,
  ResetAction,
  SendInvoiceAction
} from "./actions";
import { ActionType } from "./constants";
import { ActivityTypeCode, IBilling, ICalculatedTax } from "./model";

export interface IState {
  bookingId?: string;
  billing?: IBilling;
  load: LoadState;
  sendInvoice: SendInvoiceState;
  editInvoice: EditInvoiceState;
  addInvoice: AddInvoiceState;
  deleteInvoice: DeleteInvoiceState;
  refund: RefundState;
  markAsPaid: MarkAsPaidState;
  calculateTaxForNewInvoice: CalculateTaxForNewInvoiceState;
  calculateTaxForEditingInvoice: CalculateTaxForEditingInvoiceState;
}

type LoadState = ActionState<{ bookingId: string }>;

type SendInvoiceState = ActionState<{ invoiceId: string }>;

type EditInvoiceState = ActionState<{ invoiceId: string }>;

type AddInvoiceState = ActionState<{ bookingId: string }>;

type DeleteInvoiceState = ActionState<{ invoiceId: string }>;

type RefundState = ActionState<{
  activityId: string;
  activityType: ActivityTypeCode;
  amount: number;
}>;

type MarkAsPaidState = ActionState<{ invoiceId: string }>;

type CalculateTaxForNewInvoiceState = ActionState<{ bookingId: string; amount: number }, ICalculatedTax>;

type CalculateTaxForEditingInvoiceState = ActionState<{ invoiceId: string; amount: number }, ICalculatedTax>;

const initialState: IState = {
  load: { status: "Init" },
  sendInvoice: { status: "Init" },
  editInvoice: { status: "Init" },
  refund: { status: "Init" },
  markAsPaid: { status: "Init" },
  addInvoice: { status: "Init" },
  deleteInvoice: { status: "Init" },
  calculateTaxForNewInvoice: { status: "Init" },
  calculateTaxForEditingInvoice: { status: "Init" }
};

export const invoices = (
  state: IState = initialState,
  action:
    | ResetAction
    | LoadAction
    | SendInvoiceAction
    | RefundAction
    | MarkAsPaidAction
    | EditInvoiceAction
    | AddInvoiceAction
    | DeleteInvoiceAction
    | CalculateTaxForNewInvoiceAction
    | CalculateTaxForEditingInvoiceAction
): IState => {
  switch (action.type) {
    case ActionType.RESET:
      return initialState;
    case ActionType.LOAD_STARTED:
      return {
        ...state,
        bookingId: action.bookingId,
        billing: undefined,
        load: {
          status: "Pending",
          bookingId: action.bookingId
        }
      };
    case ActionType.LOAD_SUCCESS:
      return state.load.status === "Pending" && state.load.bookingId === action.bookingId
        ? {
            ...state,
            billing: action.billing,
            load: {
              ...state.load,
              status: "Success"
            }
          }
        : state;
    case ActionType.LOAD_ERROR:
      return state.load.status === "Pending" && state.load.bookingId === action.bookingId
        ? {
            ...state,
            load: {
              ...state.load,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.SEND_INVOICE_STARTED:
      return {
        ...state,
        sendInvoice: {
          status: "Pending",
          invoiceId: action.invoiceId
        }
      };
    case ActionType.SEND_INVOICE_SUCCESS:
      return state.sendInvoice.status === "Pending" && state.sendInvoice.invoiceId === action.invoiceId
        ? {
            ...state,
            billing: action.billing,
            sendInvoice: {
              ...state.sendInvoice,
              status: "Success"
            }
          }
        : state;
    case ActionType.SEND_INVOICE_ERROR:
      return state.sendInvoice.status === "Pending" && state.sendInvoice.invoiceId === action.invoiceId
        ? {
            ...state,
            sendInvoice: {
              ...state.sendInvoice,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.EDIT_INVOICE_STARTED:
      return {
        ...state,
        editInvoice: {
          status: "Pending",
          invoiceId: action.invoiceId
        }
      };
    case ActionType.EDIT_INVOICE_SUCCESS:
      return state.editInvoice.status === "Pending" && state.editInvoice.invoiceId === action.invoiceId
        ? {
            ...state,
            billing: action.billing,
            editInvoice: {
              ...state.editInvoice,
              status: "Success"
            }
          }
        : state;
    case ActionType.EDIT_INVOICE_ERROR:
      return state.editInvoice.status === "Pending" && state.editInvoice.invoiceId === action.invoiceId
        ? {
            ...state,
            editInvoice: {
              ...state.editInvoice,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.ADD_INVOICE_STARTED:
      return {
        ...state,
        addInvoice: {
          status: "Pending",
          bookingId: action.bookingId
        }
      };
    case ActionType.ADD_INVOICE_SUCCESS:
      return state.addInvoice.status === "Pending" && state.addInvoice.bookingId === action.bookingId
        ? {
            ...state,
            bookingId: action.bookingId,
            billing: action.billing,
            addInvoice: {
              ...state.addInvoice,
              status: "Success"
            }
          }
        : state;
    case ActionType.ADD_INVOICE_ERROR:
      return state.addInvoice.status === "Pending" && state.addInvoice.bookingId === action.bookingId
        ? {
            ...state,
            addInvoice: {
              ...state.addInvoice,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.DELETE_INVOICE_STARTED:
      return {
        ...state,
        deleteInvoice: {
          status: "Pending",
          invoiceId: action.invoiceId
        }
      };
    case ActionType.DELETE_INVOICE_SUCCESS:
      return state.deleteInvoice.status === "Pending" && state.deleteInvoice.invoiceId === action.invoiceId
        ? {
            ...state,
            billing: action.billing,
            deleteInvoice: {
              ...state.deleteInvoice,
              status: "Success"
            }
          }
        : state;
    case ActionType.DELETE_INVOICE_ERROR:
      return state.deleteInvoice.status === "Pending" && state.deleteInvoice.invoiceId === action.invoiceId
        ? {
            ...state,
            deleteInvoice: {
              ...state.deleteInvoice,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.REFUND_STARTED:
      return {
        ...state,
        refund: {
          status: "Pending",
          activityId: action.activityId,
          activityType: action.activityType,
          amount: action.amount
        }
      };
    case ActionType.REFUND_SUCCESS:
      return state.refund.status === "Pending" && state.refund.activityId === action.activityId
        ? {
            ...state,
            billing: action.billing,
            refund: {
              ...state.refund,
              status: "Success"
            }
          }
        : state;
    case ActionType.REFUND_ERROR:
      return state.refund.status === "Pending" && state.refund.activityId === action.activityId
        ? {
            ...state,
            refund: {
              ...state.refund,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.MARK_AS_PAID_STARTED:
      return {
        ...state,
        markAsPaid: {
          status: "Pending",
          invoiceId: action.invoiceId
        }
      };
    case ActionType.MARK_AS_PAID_SUCCESS:
      return state.markAsPaid.status === "Pending" && state.markAsPaid.invoiceId === action.invoiceId
        ? {
            ...state,
            billing: action.billing,
            markAsPaid: {
              ...state.markAsPaid,
              status: "Success"
            }
          }
        : state;
    case ActionType.MARK_AS_PAID_ERROR:
      return state.markAsPaid.status === "Pending" && state.markAsPaid.invoiceId === action.invoiceId
        ? {
            ...state,
            markAsPaid: {
              ...state.markAsPaid,
              status: "Error",
              error: action.error
            }
          }
        : state;

    case ActionType.CALCULATE_TAX_FOR_NEW_INVOICE_STARTED:
      return {
        ...state,
        calculateTaxForNewInvoice: {
          status: "Pending",
          bookingId: action.bookingId,
          amount: action.amount
        }
      };
    case ActionType.CALCULATE_TAX_FOR_NEW_INVOICE_SUCCESS:
      return state.calculateTaxForNewInvoice.status === "Pending" &&
        state.calculateTaxForNewInvoice.bookingId === action.bookingId &&
        state.calculateTaxForNewInvoice.amount === action.amount
        ? {
            ...state,
            calculateTaxForNewInvoice: {
              ...state.calculateTaxForNewInvoice,
              ...action.calculatedTax,
              status: "Success"
            }
          }
        : state;
    case ActionType.CALCULATE_TAX_FOR_NEW_INVOICE_ERROR:
      return state.calculateTaxForNewInvoice.status === "Pending" &&
        state.calculateTaxForNewInvoice.bookingId === action.bookingId &&
        state.calculateTaxForNewInvoice.amount === action.amount
        ? {
            ...state,
            calculateTaxForNewInvoice: {
              ...state.calculateTaxForNewInvoice,
              status: "Error",
              error: action.error
            }
          }
        : state;

    case ActionType.CALCULATE_TAX_FOR_EDITING_INVOICE_STARTED:
      return {
        ...state,
        calculateTaxForEditingInvoice: {
          status: "Pending",
          invoiceId: action.invoiceId,
          amount: action.amount
        }
      };
    case ActionType.CALCULATE_TAX_FOR_EDITING_INVOICE_SUCCESS:
      return state.calculateTaxForEditingInvoice.status === "Pending" &&
        state.calculateTaxForEditingInvoice.invoiceId === action.invoiceId &&
        state.calculateTaxForEditingInvoice.amount === action.amount
        ? {
            ...state,
            calculateTaxForEditingInvoice: {
              ...state.calculateTaxForEditingInvoice,
              ...action.calculatedTax,
              status: "Success"
            }
          }
        : state;
    case ActionType.CALCULATE_TAX_FOR_EDITING_INVOICE_ERROR:
      return state.calculateTaxForEditingInvoice.status === "Pending" &&
        state.calculateTaxForEditingInvoice.invoiceId === action.invoiceId &&
        state.calculateTaxForEditingInvoice.amount === action.amount
        ? {
            ...state,
            calculateTaxForEditingInvoice: {
              ...state.calculateTaxForEditingInvoice,
              status: "Error",
              error: action.error
            }
          }
        : state;

    default:
      return state;
  }
};

export default invoices;
