import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { decline as declineAction } from "../../../../store/bookings/actions";
import { IAppState } from "../../../../store/state";

function mapStateToProps({ bookings: { decline } }: IAppState) {
  return { decline };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    decline: {
      onDecline: (bookingId: string, note: string) => dispatch(declineAction(bookingId, note))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: any) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    decline: { ...stateProps.decline, ...dispatchProps.decline }
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
