import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { SketchPicker } from "react-color";
import styles from "./sketchColorPicker.module.scss";

export interface IHslColor {
  a?: number;
  h: number;
  l: number;
  s: number;
}

export interface IRgbColor {
  a?: number;
  b: number;
  g: number;
  r: number;
}

export type Color = string | IHslColor | IRgbColor;

export interface IColorResult {
  hex: string;
  hsl: IHslColor;
  rgb: IRgbColor;
}

export interface ISketchColorPickerProps {
  color?: Color;
  disableAlpha?: boolean;
  presetColors?: string[];
  width?: string;
  onChange?: (color: IColorResult) => void;
  className?: string;
  styles?: any;
}

const SketchColorPicker: React.FC<ISketchColorPickerProps> = props => {
  const effectiveProps = { ...props };
  if (effectiveProps.disableAlpha) {
    effectiveProps.className = classNames(styles.container, styles.alphaDisabled, effectiveProps.className);
    effectiveProps.styles = _.merge(
      { disableAlpha: { color: { height: "24px" }, hue: { height: "24px" } } },
      effectiveProps.styles
    );
  } else {
    effectiveProps.className = classNames(styles.container, effectiveProps.className);
  }

  return <SketchPicker {...effectiveProps} />;
};

export default SketchColorPicker;
