import React from "react";

const IconAlignRight = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 8H12V7H18V8ZM18 10V11H6V10H18ZM12 14H18V13H12V14ZM18 16V17H6V16H18Z"
    />
  </svg>
);

export default IconAlignRight;
