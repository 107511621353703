import ContactInformation from "../../../components/profile/contactInfo";
import IError from "../../../utilities/error";
import { ActionStatus } from "../../common";
import { LoadContactInfoAction, UpdateContactInfoAction } from "./actions";
import { ActionType } from "./constants";
import { IContactInfo } from "./model";

export interface IContactInfoState {
  loadStatus: ActionStatus;
  updateStatus: ActionStatus;
  info: IContactInfo;
  error: IError | null;
}

const initialContactState: IContactInfoState = {
  loadStatus: "Init",
  updateStatus: "Init",
  info: ContactInformation.defaults.info,
  error: null
};

export const contactInfo = (
  state: IContactInfoState = initialContactState,
  action: LoadContactInfoAction | UpdateContactInfoAction
): IContactInfoState => {
  switch (action.type) {
    case ActionType.LOAD_CONTACT_INFO_STARTED:
      return {
        ...state,
        loadStatus: "Pending",
        info: ContactInformation.defaults.info,
        error: null
      };
    case ActionType.LOAD_CONTACT_INFO_SUCCESS:
      return {
        ...state,
        loadStatus: "Success",
        info: action.info
      };
    case ActionType.LOAD_CONTACT_INFO_ERROR:
      return {
        ...state,
        loadStatus: "Error",
        error: action.error
      };
    case ActionType.UPDATE_CONTACT_INFO_STARTED:
      return {
        ...state,
        updateStatus: "Pending",
        info: action.info,
        error: null
      };
    case ActionType.UPDATE_CONTACT_INFO_SUCCESS:
      return {
        ...state,
        updateStatus: "Success"
      };
    case ActionType.UPDATE_CONTACT_INFO_ERROR:
      return {
        ...state,
        updateStatus: "Error",
        error: action.error
      };
    default:
      return state;
  }
};

export default contactInfo;
