import React, { Fragment } from "react";
import cx from "classnames";
import _ from "lodash";
import SmoothCarousel from "../../../SmoothCarousel";
import CheckMarkCircle from "../../../Icons/CheckMarkCircle";
import styles from "./photoTray.module.scss";

interface IPhoto {
  id?: string;
  url: string;
  size: number;
  width?: number;
  height?: number;
  facialRecognitionStatus?: number;
  name?: string;
  sortIndex?: number;
  useTitleAsName?: boolean;
  fileName?: string;
}
interface IPhotoTrayProps {
  photos: IPhoto[];
  onSelectPhoto: (photo: IPhoto) => void;
  selectedPhoto: IPhoto | undefined;
}
interface IPhotoTrayState {
  selectedPhoto: IPhoto | undefined;
}
export class PhotoTray extends React.PureComponent<IPhotoTrayProps, IPhotoTrayState> {
  public state: IPhotoTrayState = {
    selectedPhoto: this.props.selectedPhoto
  };

  private onSelectPhoto = (photo: IPhoto) => {
    this.props.onSelectPhoto(photo);
  };

  componentDidUpdate() {
    if (!_.isEqual(this.props.selectedPhoto, this.state.selectedPhoto)) {
      this.setState({ selectedPhoto: this.props.selectedPhoto });
    }
  }

  public render() {
    const { photos } = this.props;
    return (
      <SmoothCarousel iconColor={"black"}>
        <div className={styles.container}>
          {photos.map((p, idx) => {
            const isActive = this.state.selectedPhoto?.id === p.id;
            return (
              <div key={`${idx}-${p.id}`} className={styles.images}>
                {isActive && (
                  <Fragment>
                    <div className={styles.backdrop} />
                    <div className={styles.Icon}>
                      <CheckMarkCircle />
                    </div>
                  </Fragment>
                )}
                <img src={p.url} alt="Photo" onClick={() => this.onSelectPhoto(p)} className={cx(styles.photo)} />
              </div>
            );
          })}
        </div>
      </SmoothCarousel>
    );
  }
}
