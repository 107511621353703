import {
  GalleryPresentSettingList,
  ICartItem,
  IClientViewFavoritesState,
  IClientViewCommentingState,
  IUnreadComment,
  PreviewModes
} from "components/ClientView/models";
import React, { useState } from "react";
import { getButtonAction, getButtonActionHover } from "../../../imgButtonAction";

import Cart from "../icons/icon-cart.svg";
import CartAdded from "../icons/icon-cart-added.svg";
import CartHover from "../icons/icon-cart-hover-add.svg";
import CartHoverClick from "../icons/icon-cart-hover-added.svg";
import CartHoverRemove from "../icons/icon-cart-hover-remove.svg";
import Comment from "../icons/icon-comment.svg";
import Commented from "../icons/icon-commented.svg";
import CommentHover from "../icons/icon-comment-hover.svg";
import CommentHoverClick from "../icons/icon-comment-click.svg";
import Download from "../icons/icon-download.svg";
import DownloadHover from "../icons/icon-download-hover.svg";
import DownloadHoverClick from "../icons/icon-download-hover-click.svg";
import Downloaded from "../icons/icon-downloaded.svg";
import Favorite from "../icons/icon-favorite.svg";
import FavoriteHover from "../icons/icon-favorite-hover.svg";
import FavoriteHoverClick from "../icons/icon-favorite-hover-click.svg";
import FavoriteHoverRemove from "../icons/icon-favorite-hover-remove.svg";
import Favorited from "../icons/icon-favorited.svg";
import NewComment from "../icons/icon-comment-new.svg";
import NewCommentHover from "../icons/icon-comment-hover-new.svg";
import Share from "../icons/icon-share.svg";
import ShareHover from "../icons/icon-share-hover.svg";
import ShareHoverClick from "../icons/icon-share-hover-click.svg";
import cx from "classnames";
import styles from "./mediaOverlayActions.module.scss";
import QuickShopPreview, { IQuickShopItem } from "../../QuickShop/Preview";
import { Utilities } from "utilities/utilities";
import { isMobile } from "react-device-detect";
import { PhotoOrVideo } from "../../../../../../../models/models";
import { isShoppingAllowedForMedia, isVideo } from "utilities/helpers";
import { isGIFPhoto } from "utilities/image";

interface IMediaOverlayProps {
  allMedia: PhotoOrVideo[];
  clientViewFavorites: IClientViewFavoritesState;
  clientViewCommenting?: IClientViewCommentingState;
  gridTypeId?: GalleryPresentSettingList;
  allowMediaCommenting?: boolean;
  allowPhotoDownload?: boolean;
  allowVideoDownload?: boolean;
  allowFavorite?: boolean;
  allowShoppingCart?: boolean;
  bgColor: string;
  cartItems?: ICartItem[];
  index: number;
  isLandscape: boolean;
  previewMode?: PreviewModes;
  quickShopItems?: IQuickShopItem[];
  onMediaClick: (e: React.MouseEvent<HTMLElement>, mediaIndex: number) => void;
  onMediaFavoriteClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaDownloadClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaCartClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onMediaCommentClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, media: PhotoOrVideo) => void;
  onClickQuickShopItem?: (e: React.MouseEvent<HTMLElement>, media: PhotoOrVideo) => void;
}

const MediaOverlayActions: React.FC<IMediaOverlayProps> = ({
  allowFavorite = false,
  allowShoppingCart = false,
  allowPhotoDownload = false,
  allowVideoDownload = false,
  allowMediaCommenting = false,
  allMedia,
  index,
  clientViewFavorites,
  clientViewCommenting,
  onMediaCartClick,
  onMediaFavoriteClick,
  onMediaClick,
  onMediaDownloadClick,
  onMediaCommentClick,
  quickShopItems,
  onClickQuickShopItem,
  previewMode
}) => {
  const [hoveredFavIcon, setHoveredFavIcon] = useState(-1);
  const [clickedFavIcon, setClickedFavIcon] = useState(-1);

  const [hoveredCartIcon, setHoveredCartIcon] = useState(-1);
  const [clickedCartIcon, setClickedCartIcon] = useState(-1);
  const [hoveredDownloadIcon, setHoveredDownloadIcon] = useState(-1);
  const [clickedDownloadIcon, setClickedDownloadIcon] = useState(-1);
  const [hoveredShareIcon, setHoveredShareIcon] = useState(-1);
  const [clickedShareIcon, setClickedShareIcon] = useState(-1);
  const [hoveredCommentIcon, setHoveredCommentIcon] = useState(-1);
  const [clickedCommentIcon, setClickedCommentIcon] = useState(-1);
  const [isRenderQuickShop, setIsRenderQuickShop] = useState(false);

  const media = allMedia[index];
  const mediaIsFavorite = isVideo(media)
    ? clientViewFavorites.favoriteVideos
      ? clientViewFavorites.favoriteVideos.some(fv => fv.videoId === media.id)
      : false
    : clientViewFavorites.favoritePhotos
    ? clientViewFavorites.favoritePhotos.some(fp => fp.photoId === media.id)
    : false;

  const matchesComment = (cm: IUnreadComment) => {
    return (isVideo(media) ? cm.videoId : cm.photoId) === media.id;
  };

  const hasComments = clientViewCommenting?.commentedMedia.some(matchesComment);

  const hasUnreadComment = clientViewCommenting?.commentedMedia.some(cm => matchesComment(cm) && cm.hasUnreadComment);

  const hasCommented = clientViewCommenting?.commentedMedia.find(cm => matchesComment(cm) && cm.hasCommented);

  const favIcon: string = getButtonAction(
    index,
    index,
    clickedFavIcon,
    hoveredFavIcon,
    mediaIsFavorite,
    Favorite,
    FavoriteHover,
    FavoriteHoverClick,
    Favorited,
    FavoriteHoverRemove
  );
  const cartIcon: string = getButtonAction(
    index,
    index,
    clickedCartIcon,
    hoveredCartIcon,
    false,
    Cart,
    CartHover,
    CartHoverClick,
    CartAdded,
    CartHoverRemove
  );
  const downloadIcon: string = getButtonAction(
    index,
    index,
    clickedDownloadIcon,
    hoveredDownloadIcon,
    false,
    Download,
    DownloadHover,
    DownloadHoverClick,
    Downloaded,
    ""
  );
  const shareIcon: string = getButtonActionHover(
    index,
    index,
    clickedShareIcon,
    hoveredShareIcon,
    Share,
    ShareHover,
    ShareHoverClick
  );
  const commentIcon: string = getButtonAction(
    index,
    index,
    clickedCommentIcon,
    hoveredCommentIcon,
    hasUnreadComment!,
    hasCommented || hasUnreadComment || hasComments ? Commented : Comment,
    hasCommented || hasUnreadComment ? CommentHoverClick : CommentHover,
    CommentHoverClick,
    NewComment,
    hasCommented || hasUnreadComment ? CommentHoverClick : NewCommentHover
  );

  const handleClickCartIcon = () => {
    if (Utilities.checkQuickShopEnabled(quickShopItems)) {
      setIsRenderQuickShop(true);
    }
    setHoveredCartIcon(index);
  };

  const onClickShopAllProduct = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setClickedCartIcon(index);
    if (onMediaCartClick) {
      onMediaCartClick(e, media);
    }
  };
  const onClickAddItem = (e: React.MouseEvent<HTMLElement>) => {
    if (Utilities.checkQuickShopEnabled(quickShopItems) && onClickQuickShopItem) {
      onClickQuickShopItem(e, media);
    }
  };
  const onCloseQuickShop = () => {
    setIsRenderQuickShop(false);
  };
  const isHideCommentTootlip = previewMode && previewMode != PreviewModes.Desktop;

  return (
    <div
      className={cx(styles.mediaOverlay, styles.hover)}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        onMediaClick(e, index);
      }}
    >
      {!isMobile && (
        <span className={styles.mediaActions}>
          {allowShoppingCart && isShoppingAllowedForMedia(media) && (
            <div
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();
                // tslint:disable-next-line: no-unused-expression
                onMediaCartClick && !Utilities.checkQuickShopEnabled(quickShopItems) && onMediaCartClick(e, media);
              }}
              onMouseEnter={handleClickCartIcon}
              onMouseLeave={() => setHoveredCartIcon(-1)}
              onMouseDown={() => !isRenderQuickShop && setClickedCartIcon(index)}
              onMouseUp={() => setClickedCartIcon(-1)}
            >
              <img alt="" src={cartIcon} className={cx(styles.overlayIcon, { [styles.hidden]: !cartIcon })} />
              {isRenderQuickShop && Utilities.checkQuickShopEnabled(quickShopItems) && quickShopItems && (
                <QuickShopPreview
                  items={quickShopItems || []}
                  media={media}
                  onClickShopAllProduct={e => onClickShopAllProduct(e)}
                  onClickAddItem={e => onClickAddItem(e)}
                  onCloseQuickShop={() => onCloseQuickShop()}
                  isGIFPhoto={isGIFPhoto(media.fileName)}
                />
              )}
            </div>
          )}
          {allowFavorite && (
            <div
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();
                onMediaFavoriteClick && onMediaFavoriteClick(e, media);
              }}
            >
              <img
                className={cx(styles.overlayIcon, { [styles.hidden]: !favIcon })}
                onMouseEnter={() => setHoveredFavIcon(index)}
                onMouseLeave={() => setHoveredFavIcon(-1)}
                onMouseDown={() => setClickedFavIcon(index)}
                onMouseUp={() => setClickedFavIcon(-1)}
                src={favIcon}
                alt=""
              />
            </div>
          )}
          {allowMediaCommenting && (
            <div
              className={isHideCommentTootlip ? "" : styles.mediaCommenting}
              data-tooltip={isHideCommentTootlip ? "" : "Ask your photographer a question or request changes"}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();
                onMediaCommentClick && onMediaCommentClick(e, media);
              }}
            >
              <img
                className={styles.overlayIcon}
                src={commentIcon}
                onMouseEnter={() => setHoveredCommentIcon(index)}
                onMouseLeave={() => setHoveredCommentIcon(-1)}
                onMouseDown={() => setClickedCommentIcon(index)}
                onMouseUp={() => setClickedCommentIcon(-1)}
                alt=""
              />
            </div>
          )}
          {(isVideo(media) ? allowVideoDownload : allowPhotoDownload) && (
            <div
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();
                onMediaDownloadClick && onMediaDownloadClick(e, media);
              }}
            >
              <img
                className={cx(styles.overlayIcon, { [styles.hidden]: !downloadIcon })}
                onMouseEnter={() => setHoveredDownloadIcon(index)}
                onMouseLeave={() => setHoveredDownloadIcon(-1)}
                onMouseDown={() => setClickedDownloadIcon(index)}
                onMouseUp={() => setClickedDownloadIcon(-1)}
                src={downloadIcon}
                alt=""
              />
            </div>
          )}
          {/* <div
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <img
            className={styles.hidden}
            src={shareIcon}
            onMouseEnter={() => setHoveredShareIcon(index)}
            onMouseLeave={() => setHoveredShareIcon(-1)}
            onMouseDown={() => setClickedShareIcon(index)}
            onMouseUp={() => setClickedShareIcon(-1)}
            alt=""
          />
        </div> */}
        </span>
      )}
    </div>
  );
};

export default MediaOverlayActions;
