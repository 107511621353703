export interface IUserState {
  welcomeClosed: boolean;
  widgetIsActive: boolean;
  pendingBookingsCount: number;
  isStripeSetupComplete: boolean | null;
  isReauthorizedRequired: boolean;
}

export interface IUserInfo extends IUserState {
  id: string;
  showCongratulations: boolean;
  scopes: { [name: string]: boolean };
}

export enum LoginState {
  Init,
  Pending,
  Success,
  Error
}
