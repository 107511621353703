import { ISlotRange } from "@zenfolio/core-components/dist/components/TimeSlotDay";
import _ from "lodash";
import { ITimeSlot } from "../../../../store/profile/availability/model";
import { DayOfWeek, formatTimeOfDay } from "../../../../utilities/datetime";

export function convertTimeSlotsToSlotRanges(timeSlots: ITimeSlot[]): ISlotRange[][] {
  const slotRanges: ISlotRange[][] = _.range(0, 7).map(() => [] as ISlotRange[]);

  timeSlots.forEach((timeSlot: ITimeSlot) => {
    slotRanges[timeSlot.dayOfWeek].push({
      selectedStart: {
        value: timeSlot.offsetMinutes,
        label: formatTimeOfDay(timeSlot.offsetMinutes)
      },
      selectedEnd: {
        value: timeSlot.offsetMinutes + timeSlot.durationMinutes,
        label: formatTimeOfDay(timeSlot.offsetMinutes + timeSlot.durationMinutes)
      }
    });
  });

  return slotRanges;
}

export function convertSlotRangesToTimeSlots(slotRanges: ISlotRange[][]): ITimeSlot[] {
  return _.flatten(
    slotRanges.map((daySlotRanges: ISlotRange[], index: number) =>
      (daySlotRanges || []).map((slotRange: ISlotRange) => ({
        dayOfWeek: index as DayOfWeek,
        offsetMinutes: slotRange.selectedStart.value,
        durationMinutes: slotRange.selectedEnd.value - slotRange.selectedStart.value
      }))
    )
  );
}
