import React from "react";
import { initializeIconProps } from "../../../utilities/icons";
import IIConProps from "../IIconProps";

const Profile = (rawProps: IIConProps) => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox={`${props.minX} ${props.minY} 24 24`}
    >
      <g fill="none" fillRule="evenodd" stroke={props.stroke} strokeLinejoin="round" strokeWidth="1.231">
        <path d="M10.609 14.364s-1.044-.357-1.044-2.144c-.574 0-.574-1.43 0-1.43 0-.24-1.043-2.859.696-2.501.348-1.43 4.174-1.43 4.522 0 .241.992-.348 2.325-.348 2.501.574 0 .574 1.43 0 1.43 0 1.787-1.044 2.144-1.044 2.144v1.787c1.723.664 3.43 1.213 4.304 1.84A8.31 8.31 0 0 0 20 12.22C20 7.68 16.419 4 12 4c-4.419 0-8 3.68-8 8.22A8.31 8.31 0 0 0 6.313 18c.925-.658 2.75-1.248 4.296-1.85v-1.786zM6 18.007C7.53 19.237 9.65 20 11.996 20c2.35 0 4.474-.765 6.004-2" />
      </g>
    </svg>
  );
};

export default Profile;
