import IError from "../../../utilities/error";
import { ActionStatus } from "../../common";
import { LoadActivationEmbedStateAction, UpdateActivationEmbedStateAction } from "./actions";
import { ActivationEmbedActionType } from "./constants";
import { IEmbedSettingsState } from "./model";

export interface IActivationEmbedState {
  settingsState: IEmbedSettingsState;
  load: ILoadActivationEmbedState;
  update: IUpdateActivationEmbedState;
}

export interface ILoadActivationEmbedState {
  status: ActionStatus;
  error: IError | null;
}

export interface IUpdateActivationEmbedState {
  status: ActionStatus;
  error: IError | null;
}

const initialSettingsState: IEmbedSettingsState = {};

const initialLoadActivationEmbedState: ILoadActivationEmbedState = {
  status: "Init",
  error: null
};

const initialUpdateActivationEmbedState: IUpdateActivationEmbedState = {
  status: "Init",
  error: null
};

const initialActivationEmbedState: IActivationEmbedState = {
  settingsState: initialSettingsState,
  load: initialLoadActivationEmbedState,
  update: initialUpdateActivationEmbedState
};

export const embed = (
  state: IActivationEmbedState = initialActivationEmbedState,
  action: LoadActivationEmbedStateAction | UpdateActivationEmbedStateAction
): IActivationEmbedState => {
  switch (action.type) {
    case ActivationEmbedActionType.LOAD_ACTIVATION_EMBED_STATE_STARTED:
      return {
        ...state,
        load: {
          status: "Pending",
          error: null
        }
      };
    case ActivationEmbedActionType.LOAD_ACTIVATION_EMBED_STATE_SUCCESS:
      return state.load.status === "Pending"
        ? {
            ...state,
            load: {
              status: "Success",
              error: null
            },
            settingsState: action.state
          }
        : state;
    case ActivationEmbedActionType.LOAD_ACTIVATION_EMBED_STATE_ERROR:
      return state.load.status === "Pending"
        ? {
            ...state,
            load: {
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActivationEmbedActionType.UPDATE_ACTIVATION_EMBED_STATE_STARTED:
      return {
        ...state,
        update: {
          status: "Pending",
          error: null
        }
      };
    case ActivationEmbedActionType.UPDATE_ACTIVATION_EMBED_STATE_SUCCESS:
      return state.update.status === "Pending"
        ? {
            ...state,
            update: {
              status: "Success",
              error: null
            },
            settingsState: { ...state.settingsState, invoicePaymentUrl: action.invoicePaymentUrl }
          }
        : state;
    case ActivationEmbedActionType.UPDATE_ACTIVATION_EMBED_STATE_ERROR:
      return state.update.status === "Pending"
        ? {
            ...state,
            update: {
              status: "Error",
              error: action.error
            }
          }
        : state;
    default:
      return state;
  }
};

export default embed;
