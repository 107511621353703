const colors = {
  white: "#ffffff",
  black: "#000000",
  jetBlack: "#343434",
  bodyBg: "#fbfbfd",
  inputGray: "rgba(151,151,151, .25)",
  placeholder: "rgba(0, 0, 0, 0.3)",
  grey: "#cccccc",
  lighGrey: "#ececec",
  darkGrey: "#757575",
  filterGrayBorder: "#e1e1e1",
  orange: "#f15a0b",
  lightOrange: "#f7e8da",
  darkOrange: "#d44900",
  filterOrangeText: "#ff5a00",
  crushOrange: "#ff5900",
  grayPlaceholder: "#d3d3d3",
  grayBackground: "#f6f6f6",
  grayBorder: "#a9a9a9",
  strokeOrange: "#fe884b",
  redNotification: "#c11616",
  redBackground: "#f6dcdc",
  whiteGray: "#f9f9f9",
  cremeFacialBackground: "#f7f7ea",
  grayFacialBackground: "#4a4a4a",

  creme: "#fcfcf4",
  grayNav: "#333333",
  grayTextNav: "#606060",
  grayLightTextNav: "#a3a3a3",
  graySuperLight: "#f4f4f4",
  whiteInput: "#f8f8f8",
  creamInput: "#f2f1e8",
  lightPlaceholder: "rgba(0, 0, 0, 0.2)",
  backgroundTileLightGray: "#FAFAFA",
  backgroundTileCreme: "#F7F7EF",
  backgroundTileGrey: "#2c2c2c",
  inputDark: "#333333"
};

export const getContrastYIQ = (hexcolor: string, replacement?: { darkColor: string; lightColor: string }): string => {
  hexcolor = hexcolor.replace("#", "");
  const r = parseInt(hexcolor.slice(0, 2), 16);
  const g = parseInt(hexcolor.slice(2, 4), 16);
  const b = parseInt(hexcolor.slice(4, 6), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? replacement?.darkColor ?? colors.grayNav : replacement?.lightColor ?? colors.white;
};

export const setOpacity = (hex: string, alpha: number): string =>
  `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, "0")}`;

export default colors;
