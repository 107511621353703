import * as React from "react";

interface ICollapseProps {}

class Collapse extends React.Component<ICollapseProps> {
  public render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default Collapse;
