import { LinkTypeOption, LINK_TYPE, SendInvoiceOption } from "utilities/constant";
import { IPhotoBase, IVideoBase } from "../utilities/getImgUrl";
import { IAlbumFolderData } from "./blocks";
import { IAddress } from "./location";

interface IFolder {
  name: string;
  description: string;
  folderContent: IFolderContent[];
}

interface IFolderContent {
  id: string;
  name: string;
  albumAlias: string;
  isAlbum: boolean;
  totalFolders: number;
  totalAlbums: number;
  totalPhotos: number;
  dateCreated: string;
  isVisitorAllowed: boolean;
  isVisitorEmailRequired: boolean;
  isProtected: boolean;
  coverPhoto: IPhotoBase;
  coverVideo?: IVideoBase;
  photoUrlTemplate: string;
  thumbnailUrlTemplate?: string;
  images: IPhotoFolder[];
  expiration?: IExpirationData;
  shootDate?: string;
}

interface IPhotoFolder extends IPhotoBase {
  isProtected: boolean;
  photoUrlTemplate: string;
  thumbnailUrlTemplate?: string;
  thumbnailId?: string;
  isVideo?: boolean;
}

interface IClientViewFolderState extends IFolder {
  isFetching: boolean;
  errorDescription: string;
  errorStatus: number;
  errorCode: number;
}

interface IPage {
  id: string;
  alias: string;
  name: string;
  html: string;
  css: string;
  parentId: string | null;
  sortIndex: number;
  pages: IPage[] | null;
}

export interface IExpirationData {
  active: boolean;
  expirationDate: string;
}

interface IPhotographerFolder {
  id: string;
  name: string;
  dateCreated: string;
  photoCount: number;
  videoCount: number;
  coverPhoto: IPhoto | null;
  photoUrlTemplate: string;
  isProtected: boolean;
  isAlbum: boolean;
  folders: IPhotographerFolder[];
  expiration?: IExpirationData;
}

export interface IGalleryPreview {
  id: string;
  name: string;
  count: number;
  topPhotos: IPhoto[];
  photoUrlTemplate: string;
}

interface IColor {
  name: string;
  value: string;
}

interface ITheme {
  accentColor: IColor;
  backgroundColor: IColor;
}

export interface IZenSiteTheme {
  accentColor: IColor;
  backgroundColor: IColor;
  textColor?: IColor;
  fontsStyle: string;
  buttonsStyle: string;
}

interface IPhotographerFoldersState {
  folders: IPhotographerFolder[];
  albumsCount: number;
  foldersCount: number;
  GalleriesHome: IGalleryPreview[] | null;
  error: string;
  isFetching: boolean;
  route: number[];
  initialFolder: string;
  photoCount: number;
  videoCount: number;
}

interface INavigationMenuItem {
  id: string;
  menuName: string;
  menuCaption: string;
  menuLink?: string;
  openInNewTab?: boolean;
  isExternal?: boolean;
  childs?: INavigationMenuItem[];
}

interface INavigationMenu {
  items: INavigationMenuItem[];
}

interface IRoutes {
  [routes: string]: string;
}

export interface IMediaId {
  id: string;
  isVideo?: true;
}

export interface IMediaBase extends IMediaId {
  fileName: string;
  sortIndex: number;
  size: number;
  selected: boolean;
  dateCreated?: string;
}

export interface IPhoto extends IPhotoBase, IMediaBase {
  width?: number;
  height?: number;
  url: string;
}

export interface IVideo extends IVideoBase, IMediaBase {
  thumbnailWidth?: number;
  thumbnailHeight?: number;
  hasCustomThumbnail: boolean;
}

export type PhotoOrVideo = IPhoto | IVideo;

export interface IAlbumContentState {
  id: string;
  name: string;
  caption: string;
  photoCount: number;
  videoCount: number;
  photos: IPhoto[] | null;
  videos: IVideo[] | null;
  dateModified: string;
  dateCreated: string;
  code: number | null;
  isFetching: boolean;
  error: string;
  status: number | null;
  coverPhoto: IPhoto | null;
  coverVideo: IVideo | null;
  photoUrlTemplate: string;
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  faceUrlTemplate: string;
  heroImageUrlTemplate: string | null;
  heroThumbnailUrlTemplate: string | null;
  shootDate: string;
  description?: string;
}

export interface ISetting {
  id: number;
  settingType: number;
  name: string;
  value: string | null;
}

export interface IPresent {
  id: string;
  folderId: string;
  settings: ISetting[];
}

export interface ICurrency {
  id?: string;
  code: string;
  name: string;
  symbol: string;
  country: string;
}

export enum SortOrderType {
  Custom = "custom",
  AlphabeticalAsc = "alphabeticalAsc",
  AlphabeticalDesc = "alphabeticalDesc",
  TimestampAsc = "timestampAsc",
  TimestampDesc = "timestampDesc",
  UploadDateAsc = "uploadDateAsc",
  UploadDateDesc = "uploadDateDesc"
}

export interface ICollection {
  id: string;
  collectionId: string;
  name: string;
  sortOrder: SortOrderType;
  mediaSortIndices: IMediaId[];
}

export interface IFolderAccessDto {
  allowFavorite: boolean;
  allowMediaCommenting: boolean;
  allowPhotoDownload: boolean;
  allowVideoDownload: boolean;
  allowGalleryPhotosDownload: boolean;
  allowGalleryVideosDownload: boolean;
  allowShareGallery: boolean;
}

export interface ISortOption {
  key: string;
  field: "name" | "dateCreated";
  direction: "asc" | "desc";
  name: string;
}

export const sortOptions: ISortOption[] = [
  { key: "name-asc", field: "name", direction: "asc", name: "Alphabetical  A-Z" },
  { key: "name-desc", field: "name", direction: "desc", name: "Alphabetical  Z-A" },
  { key: "date-asc", field: "dateCreated", direction: "asc", name: "Oldest to Latest" },
  { key: "date-desc", field: "dateCreated", direction: "desc", name: "Latest to Oldest" }
];

enum CONTENT_TYPE {
  SITEPAGE = 1,
  DYNAMICGALLERY = 2,
  DYNAMICFOLDER = 3,
  BLOGPOST = 4
}

const defaultSiteTheme: IZenSiteTheme = {
  accentColor: { value: "#e69d4d", name: "Orange" },
  backgroundColor: { value: "black", name: "black" },
  fontsStyle: "fonts-lato",
  buttonsStyle: "rectangle-fill"
};

export interface IZenSiteTheme {
  accentColor: IColor;
  backgroundColor: IColor;
  fontsStyle: string;
  buttonsStyle: string;
}

export type ActionStatus = "Init" | "Pending" | "Success" | "Error";

export {
  IFolderContent,
  IPhotoFolder,
  IRoutes,
  IClientViewFolderState,
  INavigationMenuItem,
  INavigationMenu,
  ITheme,
  IPage,
  IColor,
  IPhotographerFoldersState,
  IPhotographerFolder,
  CONTENT_TYPE,
  defaultSiteTheme
};

export interface IShootLocation extends IAddress {
  id?: string;
  isPrimary: boolean;
  isCustom?: boolean;
  displayName: string;
  locationDetails: string | null;
}

export interface IDeposit<T = number> {
  depositAmount: T | null;
  sendInvoiceOption: SendInvoiceOption;
  sendInvoiceDays: number;
}

export interface IPhotographerShootType {
  shootTypeId: number;
  name: string;
}

export interface IBookMeService {
  id: string;
  name: string;
  duration: number;
  radius: number;
  useDeposit: boolean;
  deposit: IDeposit | null;
  approvalRequired: boolean;
  shootLocation: IShootLocation | null;
  enabled: boolean;
  currency: string;
  order: number;
  shootType: IPhotographerShootType;
  originalShootTypeName: string;
  price: number;
  useOfflinePayment: boolean | null;
  offlinePaymentInstructions: string | null;
  askAdditionalInfo: boolean;
  serviceDetails: string[];
  dateModifiedUtc: Date;
  packageId?: string;
  packageName?: string;
  packageDetails?: string[];
  requiresShipping?: boolean;
}

export interface ICollectionOptions {
  collectionId: string;
  name: string;
  mediaSortIndices?: any[];
}

export interface ILink {
  type?: LinkTypeOption;
  label?: string;
  page?: string;
  url?: string;
  galleryFolderAlias?: string;
  replacedImage?: boolean;
  bookmeUrl?: string;
  allowShowCaption?: boolean;
  button?: boolean;
  newTab?: boolean;
  albumFolderData?: IAlbumFolderData;
}

export interface LinkOptions {
  type: LINK_TYPE;
  pages: INavigationMenuItem[];
  linkType?: LinkTypeOption;
  button: boolean;
  newTab: boolean;
  allowShowCaption?: boolean;
  label?: string;
  labelPlaceholder?: string;
  url?: string;
  interactionPage: string;
  albumFolderData?: IAlbumFolderData;
  onShowFolderAlbum?: () => void;
}
