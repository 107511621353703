import React from "react";

const IconBulletedList = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 8C7.55228 8 8 7.55228 8 7C8 6.44772 7.55228 6 7 6C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8ZM7 12.5C7.55228 12.5 8 12.0523 8 11.5C8 10.9477 7.55228 10.5 7 10.5C6.44772 10.5 6 10.9477 6 11.5C6 12.0523 6.44772 12.5 7 12.5ZM8 16C8 16.5523 7.55228 17 7 17C6.44772 17 6 16.5523 6 16C6 15.4477 6.44772 15 7 15C7.55228 15 8 15.4477 8 16ZM18 7.5H9V6.5H18V7.5ZM18 12H9V11H18V12ZM9 16.5H18V15.5H9V16.5Z"
    />
  </svg>
);

export default IconBulletedList;
