import styles from "./cropperEditor.module.scss";
import cx from "classnames";
import React from "react";
import Cropper from "cropperjs";
import mapValues from "lodash/mapValues";

import { PhotoCropper } from "../PhotoCropper";
import Button from "components/Button";
import RulerIcon from "./ui/rulerIcon";
import CropIcon from "./ui/cropIcon";
import OrientationIcon from "./ui/orientationIcon";
import ZoomSlider from "./ui/zoomSlider";

export interface ICroppingData {
  /** x coordinator ratio
   * 1>= value >= 0
   * left = cropData.x / imageData.naturalWidth
   */
  left: number;
  /** y coordinator ratio
   * 1>= value >= 0
   * top = cropData.y / imageData.naturalHeight
   */
  top: number;
  /** width ratio
   * 1>= value >= 0
   * width = cropData.width / imageData.naturalWidth
   */
  width: number;
  /** height ratio
   * 1>= value >= 0
   * height: cropData.height / imageData.naturalHeight
   */
  height: number;
}

export interface ICropperEditorData {
  cropping: ICroppingData;
  /** Zoom scales */
  zoomScale: number;
  /** Photo rotation degree */
  rotate: number;
  /** Photo that has been cropped */
  croppedPhoto: {
    width: number;
    height: number;
    /** Cropped photo URL
     * cropper.getCroppedCanvas().toDataURL()
     */
    src: string;
  };
  /** Photo src to display in cart item */
  thumbnailSrc: string;
  previewPhotoSrc?: string;
}

export interface ICropperEditorProps {
  /** Input (uncropped) photo width (in pixel) */
  photoWidth: number;
  /** Input (uncropped) photo height (in pixel) */
  photoHeight: number;
  /** Product width (in pixel) */
  productWidth: number;
  /** Product height (in pixel) */
  productHeight: number;
  /** Preview (uncropped) Photo url */
  photoSrc: string;
  /** Init Data */
  initialData: {
    cropping: ICroppingData;
    /** Zoom scales */
    zoomScale: number;
    /** Photo rotation degree */
    rotate: number;
  };
  /** Product finish size (in inch) */
  finishSize: {
    width: number;
    height: number;
  };
  showFinishedSize?: boolean;
  /** Thumbnail size (width) */
  thumbnailSize?: number;
  showProductOptionsButton?: boolean;
  externalClass?: string;
  applyButtonName?: string;
  isCardDesigner?: boolean;
  onApply: (data: ICropperEditorData) => void;
  onShowProductOptions?: () => void;
}

const THUMBNAIL_DEFAULT_SIZE = 150;

interface ICropperModalState {
  cropper: Cropper | null;
  firstRenderedCropperData: Cropper.Data | null;
  minZoomValue: number;
  maxZoomValue: number;
  zoomValue: number;
  initialAspectRatio: number;
}

export class CropperEditor extends React.PureComponent<ICropperEditorProps, ICropperModalState> {
  constructor(props: ICropperEditorProps) {
    super(props);
    this.state = {
      cropper: null,
      firstRenderedCropperData: null,
      minZoomValue: 0,
      maxZoomValue: 100,
      zoomValue: 0,
      initialAspectRatio: 0
    };
  }

  private onCrop = (cropper: Cropper, zoomRatio: number) => {
    const { minZoomValue } = this.state;
    let other = {};
    if (!minZoomValue && zoomRatio) {
      const min = zoomRatio * 100;
      other = {
        minZoomValue: min,
        maxZoomValue: min + 100
      };
    }
    this.setState({
      cropper,
      zoomValue: zoomRatio * 100,
      ...other
    });
  };

  private onZoom = (newValue: number) => {
    const { zoomValue } = this.state;
    const delta = newValue - zoomValue;
    this.state.cropper?.zoom(delta / 100);
    this.setState({ zoomValue: newValue });
  };

  private onReset = () => {
    const { cropper, firstRenderedCropperData, initialAspectRatio } = this.state;
    const { initialData } = this.props;
    if (cropper && firstRenderedCropperData) {
      cropper.reset();
      // if (initialData.zoomScale > 0) {
      //   cropper.zoomTo(initialData.zoomScale / 100);
      // }
      cropper.setAspectRatio(initialAspectRatio);

      this.setState({ zoomValue: 0 });
    }
  };

  private onSetFirstCropper = (cropper: Cropper, initialAspectRatio: number) => {
    this.setState({ firstRenderedCropperData: cropper.getData(), initialAspectRatio });
  };

  private onChangeOrientation = () => {
    const { cropper } = this.state;
    if (cropper) {
      const currentCrop: Cropper.CropBoxData = cropper.getCropBoxData();
      const width = currentCrop.width;
      const height = currentCrop.height;
      const newWidth = height;
      const newHeight = width;
      cropper.setAspectRatio(newWidth / newHeight);
      const newLeft = currentCrop.left + (width - newWidth) / 2;
      const newTop = currentCrop.top + (height - newHeight) / 2;
      cropper.setCropBoxData({
        height: newHeight,
        width: newWidth,
        left: newLeft,
        top: newTop
      });
    }
  };

  private getThumbnailSize = (cropData: Cropper.Data): { width: number; height: number } => {
    const { thumbnailSize } = this.props;

    const iconSize = thumbnailSize || THUMBNAIL_DEFAULT_SIZE;
    let iconWidth = 0;
    let iconHeight = 0;
    if (cropData.width > cropData.height) {
      iconWidth = Math.min(iconSize, cropData.width);
      iconHeight = iconSize * (cropData.height / cropData.width);
    } else {
      iconHeight = Math.min(iconSize, cropData.height);
      iconWidth = iconSize * (cropData.width / cropData.height);
    }
    return {
      width: iconWidth,
      height: iconHeight
    };
  };

  private mappingCropData = (cropData: Cropper.Data) => {
    return mapValues(cropData, (value: number) => (value > 0 ? value : 0));
  };

  private onApply = async () => {
    const { cropper, zoomValue } = this.state;
    if (cropper) {
      const imageData: Cropper.ImageData = cropper.getImageData();
      const canvasData: Cropper.CanvasData = cropper.getCanvasData();
      const cropData: Cropper.Data = this.mappingCropData(cropper.getData());
      const croppedSrc = cropper.getCroppedCanvas().toDataURL();

      const thumbnailSize = this.getThumbnailSize(cropData);
      const thumbnailSrc = cropper
        .getCroppedCanvas({ width: thumbnailSize.width, height: thumbnailSize.height })
        .toDataURL();

      const croppedData: ICropperEditorData = {
        cropping: {
          left: cropData.x / imageData.naturalWidth,
          top: cropData.y / imageData.naturalHeight,
          width: cropData.width / imageData.naturalWidth,
          height: cropData.height / imageData.naturalHeight
        },
        croppedPhoto: {
          src: croppedSrc,
          width: cropData.width,
          height: cropData.height
        },
        rotate: cropData.rotate,
        zoomScale: zoomValue,
        thumbnailSrc,
        previewPhotoSrc: this.props.photoSrc
      };
      this.props.onApply(croppedData);
    }
  };

  private onRotate = () => {
    const { cropper } = this.state;
    cropper?.rotate(45);
  };

  private onShowProductOptions = () => {
    if (this.props.onShowProductOptions) {
      this.props.onShowProductOptions();
    }
  };

  public componentWillUnmount() {
    const { cropper } = this.state;
    cropper?.destroy();
  }

  public render() {
    const {
      productWidth,
      productHeight,
      photoSrc,
      photoHeight,
      photoWidth,
      finishSize,
      externalClass,
      applyButtonName,
      showFinishedSize
    } = this.props;
    const { minZoomValue, maxZoomValue } = this.state;

    return (
      <div className={cx(styles.container, externalClass === "NZEditor" ? styles.NZEditor : {})}>
        {showFinishedSize && (
          <div className={styles.finishSize}>
            <RulerIcon />
            <span>{`Finished size is ${finishSize.width} x ${finishSize.height}"`}</span>
          </div>
        )}
        <div className={styles.cropper}>
          <div className={styles.editor}>
            <PhotoCropper
              productWidth={productWidth}
              productHeight={productHeight}
              photoSrc={photoSrc}
              onCrop={this.onCrop}
              previewPhotoHeight={photoHeight}
              previewPhotoWidth={photoWidth}
              croppingData={this.props.initialData}
              onFirstRender={this.onSetFirstCropper}
              deletectOrientationBaseOnProduct={this.props.isCardDesigner || false}
            />
          </div>
        </div>
        <div className={styles.guideline}>
          <CropIcon />
          <span>Click and drag to adjust the photo</span>
        </div>
        <div className={styles.actionContainer}>
          <div className={styles.actionGroup}>
            <div className={styles.reset}>
              {this.props.showProductOptionsButton && (
                <Button onClick={this.onShowProductOptions} styleType="plain" className={styles.btnReset}>
                  Product Options
                </Button>
              )}
            </div>
            <div className={styles.photoActions}>
              <ZoomSlider max={maxZoomValue} min={minZoomValue} value={this.state.zoomValue} onChange={this.onZoom} />
              <div>
                <Button onClick={this.onReset} styleType="plain" className={styles.btnOrientation}>
                  Reset
                </Button>
                <Button onClick={this.onChangeOrientation} styleType="plain" className={styles.btnOrientation}>
                  <OrientationIcon />
                  Orientation
                </Button>
              </div>
            </div>
            <div className={styles.apply}>
              <Button onClick={this.onApply} styleType="secondary" className={styles.btnApply}>
                {applyButtonName || "Apply"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
