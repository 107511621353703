import classNames from "classnames";
import * as React from "react";
import { PropsWithChildren } from "react";
import styles from "./index.module.scss";

export interface INavbarProps {
  className?: string;
  style?: React.CSSProperties;
}

export const Navbar = React.forwardRef<HTMLDivElement, PropsWithChildren<INavbarProps>>((props, ref?) => {
  const { className, style } = props;

  const classes = classNames(className, styles.navbar);

  return (
    <div className={classes} style={style} ref={ref}>
      {props.children}
    </div>
  );
});
