import React from "react";
import IIConProps from "../../IIconProps";

interface IComIconProps extends IIConProps {
  hasUnreadComment?: boolean;
}

const Comment = (props: IComIconProps) => {
  const opacity = props.opacity || 1;
  const width = props.width || props.size;
  const height = props.height || props.size;
  const minX = props.minX || 0;
  const minY = props.minY || 0;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`${minX} ${minY} ${props.size} ${props.size}`}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
          <g stroke={props.color} opacity={opacity} strokeWidth="2.25">
            <g>
              <g>
                <g>
                  <path
                    d="M13.579 20L12.632 20 8.842 24 8.842 20 6 20 6 8 24 8 24 14.5"
                    transform="translate(-897 -117) translate(785 93) translate(16 24) translate(96)"
                  />
                  <path
                    d="M16 23.333L21.042 23.333 25.167 27 25.167 23.333 27 23.333 27 16 16 16z"
                    transform="translate(-897 -117) translate(785 93) translate(16 24) translate(96)"
                  />
                </g>
              </g>
            </g>
          </g>
          {props.hasUnreadComment && <circle cx="26" cy="7" r="5" fill="#8CD07D" />}
        </g>
      </g>
    </svg>
  );
};

export default Comment;
