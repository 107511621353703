import React, { FunctionComponent } from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import IconAlignCenter from "../../../assets/svg/align-center";
import IconAlignLeft from "../../../assets/svg/align-left";
import IconAlignRight from "../../../assets/svg/align-right";
import { findBlockActive, isBlockActive } from "../../../common/transforms";
import { ToolbarButton } from "../../../components/toolbar-button";
import { BLOCK_ALIGN_CENTER, BLOCK_ALIGN_LEFT, BLOCK_ALIGN_RIGHT } from "../../../core/types";

const alignList: Record<string, React.ComponentType> = {
  [BLOCK_ALIGN_LEFT]: IconAlignLeft,
  [BLOCK_ALIGN_CENTER]: IconAlignCenter,
  [BLOCK_ALIGN_RIGHT]: IconAlignRight
};

interface OwnProps {
  type: string;
}

type Props = OwnProps;

const ButtonAlign: FunctionComponent<Props> = props => {
  const editor = useSlate();

  const match = findBlockActive(editor, Object.keys(alignList));
  const value: string = match?.[0]?.type;

  const onClick = () => {
    const selectValue = props.type;
    const mark = editor.selection;
    if (mark) {
      ReactEditor.focus(editor);
      Transforms.select(editor, mark);
      if (value) {
        Transforms.unwrapNodes(editor, {
          match: n => n.type === value,
          split: true
        });
      }
      if (selectValue !== value) {
        Transforms.wrapNodes(editor, {
          type: selectValue,
          children: []
        });
      }
    }
  };

  const Icon = alignList[props.type];

  return (
    <ToolbarButton onMouseDown={onClick} active={isBlockActive(editor, props.type)}>
      <Icon />
    </ToolbarButton>
  );
};

export { ButtonAlign };
