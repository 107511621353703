import _ from "lodash";
import moment from "moment";
import { Utilities } from "./utilities";

/**
 * Provides utility functions for weekdays. All input and output "day of the week" integers are in
 * the default (US) format (similar to moment.day, i.e. 0 is Sunday, 1 is Monday, ..., 6 is Saturday).
 */
abstract class Weekdays {
  /**
   * Returns the first day of the week for the specified country.
   * @param countryCode The country code.
   * @returns The first day of the week.
   */
  public static firstDayOfWeek(countryCode: string = Utilities.iso3166DefaultCountryCode): number {
    return countryCode === Utilities.iso3166UKCode ? 1 : 0;
  }

  /**
   * Returns the country-aware day of the week of the provided date.
   * @param moment The date.
   * @param countryCode The country code.
   * @returns The country-aware day of the week.
   */
  public static dayOfWeek(moment: moment.Moment, countryCode: string = Utilities.iso3166DefaultCountryCode) {
    const first = Weekdays.firstDayOfWeek(countryCode);
    const day = moment.day();
    return (day - first + 7) % 7;
  }

  /**
   * Returns the country-aware start of the week for the specified date.
   * @param date The date.
   * @param countryCode The country code.
   * @returns The country-aware start of the week.
   */
  public static startOfWeek(date: moment.Moment, countryCode: string = Utilities.iso3166DefaultCountryCode) {
    return date.add(-Weekdays.dayOfWeek(date, countryCode!), "days").startOf("day");
  }

  /**
   * Returns the country-aware end of the week for the specified date.
   * @param date The date.
   * @param countryCode The country code.
   * @returns The country-aware end of the week.
   */
  public static endOfWeek(date: moment.Moment, countryCode: string = Utilities.iso3166DefaultCountryCode) {
    return date.add(6 - Weekdays.dayOfWeek(date, countryCode!), "days").endOf("day");
  }

  /**
   * Returns the ordered array of weekdays for the specified country.
   * @param countryCode The country code.
   * @returns The ordered array of weekdays.
   */
  public static weekdays(countryCode: string = Utilities.iso3166DefaultCountryCode): number[] {
    const first = Weekdays.firstDayOfWeek(countryCode);
    return Utilities.rotateArray(_.range(0, 7), -first);
  }
}

export default Weekdays;
