import React from "react";
import { ICanvasPhotoProps, wrapBorderSize } from ".";
import styles from "./canvasPrint.module.scss";

class CanvasNaturalWrap extends React.PureComponent<ICanvasPhotoProps> {
  private uniqueId: number = Math.random();

  constructor(props: ICanvasPhotoProps) {
    super(props);
  }

  render() {
    const {
      canvasPrintDetails,
      isInvalidRatio,
      isInvalidResolution,
      printWidthInPixel,
      printHeightInPixel,
      canvasWrapBorderInPixel,
      stroke,
      canvasWrapBorderInPixelForBothSide,
      renderImage,
      renderCanvasPlaceHolder
    } = this.props;

    return (
      <>
        <defs>
          <clipPath id={`trim-option-${canvasPrintDetails?.id}-${this.uniqueId}`} transform="">
            <rect width={printWidthInPixel} height={printHeightInPixel} x="0" y="0"></rect>
          </clipPath>
        </defs>
        <g className={styles.borderGElement}>
          <g clipPath={`url(#trim-option-${canvasPrintDetails?.id}-${this.uniqueId})`}>{renderImage}</g>
          <rect
            width={printWidthInPixel - canvasWrapBorderInPixel}
            height={printHeightInPixel - canvasWrapBorderInPixel}
            strokeWidth={canvasWrapBorderInPixel}
            strokeOpacity="1"
            fillOpacity="0"
            x={canvasWrapBorderInPixel / 2}
            y={canvasWrapBorderInPixel / 2}
            stroke={stroke}
          />
          {!canvasPrintDetails?.isShoppingCartItem && (
            <rect
              x={canvasWrapBorderInPixel}
              y={canvasWrapBorderInPixel}
              width={printWidthInPixel - canvasWrapBorderInPixelForBothSide}
              height={printHeightInPixel - canvasWrapBorderInPixelForBothSide}
              strokeWidth={wrapBorderSize(printWidthInPixel)}
              strokeOpacity="1"
              fillOpacity={isInvalidRatio || isInvalidResolution ? 1 : 0}
              fill={isInvalidRatio || isInvalidResolution ? "rgba(0, 0, 0, 0.4)" : "none"}
              stroke="#057aff"
            />
          )}
          {renderCanvasPlaceHolder}
        </g>
      </>
    );
  }
}

export default CanvasNaturalWrap;
