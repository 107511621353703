import React from "react";
import cx from "classnames";

// Local Modules
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import Card from "./card";
import styles from "./zenTestimonialColumnBlock.module.scss";
import { getSiteFonts } from "utilities/blocks/site";

export interface IZenTestimonialColumnBlockProps extends IZenBaseBlockProps {
  cards: ICard[];
  selectCard: (index: number) => void;
  focusSection: (index: number, input: string) => void;
  isEditionView: boolean;
  cardAccentColor: string;
  cardBackgroundType?: string;
  cardBackgroundColor: string;
  cardBackgroundOpacity?: number;
}

export interface ICard {
  quote: string;
  source: string;
  shootType: string;
  isSelected: boolean;
  isHidden: boolean;
}

export class ZenTestimonialColumnBlock extends React.Component<IZenTestimonialColumnBlockProps> {
  constructor(props: IZenTestimonialColumnBlockProps) {
    super(props);
  }

  public render() {
    const {
      cards,
      layout,
      divider,
      padding,
      readOnly,
      alignment,
      siteTheme,
      fontFamily,
      selectCard,
      focusSection,
      isEditionView,
      selectedBlock,
      tabletEnabled,
      mobileEnabled,
      desktopEnabled,
      backgroundType,
      backgroundColor,
      backgroundWidth,
      backgroundOpacity,
      animationScrollIn,
      animationScrollOut,
      cardBackgroundType,
      cardBackgroundColor,
      cardBackgroundOpacity
    } = this.props;
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    return (
      <ZenBaseBlock
        layout={layout}
        padding={padding}
        divider={divider}
        readOnly={readOnly}
        siteTheme={siteTheme}
        alignment={alignment}
        fontFamily={fontFamily}
        selectedBlock={selectedBlock}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        backgroundType={backgroundType}
        desktopEnabled={desktopEnabled}
        backgroundColor={backgroundColor}
        backgroundWidth={backgroundWidth}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundOpacity={backgroundOpacity}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          readOnly={readOnly}
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
        >
          <div className={cx(styles.zenContainer, styles[siteFonts.primary])}>
            <div className={styles.container}>
              {cards
                .filter(card => card.isHidden === false)
                .map((card: ICard, index: number) => (
                  <div className={styles.card} key={index}>
                    <Card
                      index={index}
                      quote={card.quote}
                      source={card.source}
                      shootType={card.shootType}
                      fontStyle={siteTheme.fontsStyle}
                      siteTheme={siteTheme}
                      isSelected={card.isSelected && isEditionView}
                      selectCard={selectCard}
                      focusSection={focusSection}
                      accentColor={siteTheme.accentColor.value}
                      backgroundType={cardBackgroundType}
                      backgroundColor={cardBackgroundColor}
                      backgroundOpacity={cardBackgroundOpacity}
                    />
                  </div>
                ))}
            </div>
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}
