import { AddressInputValue } from "@zenfolio/core-components";
import { IAddress } from "@zenfolio/core-components/dist/models/location";
import * as React from "react";
import arrow from "../../../../../assets/icons/arrow.svg";
import AddressSelection from "../../../../../containers/addressSelection";
import { ActionStatus } from "../../../../../store/common";
import { IShootLocation } from "../../../../../store/profile/shootLocation/model";
import { IService } from "../../../../../store/widget/model";
import eventTracker from "../../../../../utilities/eventTracker";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import { getAddressInfo } from "../../../../addressInput/helpers";
import { IEditableWidgetStepProps, IValidatableWidgetStep } from "../../../contracts";
import styles from "./index.module.scss";

export interface IInRadiusLocationParams {
  shootLocation: IShootLocation | null;
  address: AddressInputValue;
  addressDetails: string | null;
}

interface IInRadiusLocationState extends IInRadiusLocationParams {
  service: IService | null;
  oldService: IService | null;
}

interface IInRadiusLocationProps extends IEditableWidgetStepProps {
  locationIsValid: boolean;
  locationValidationStatus: ActionStatus;
  primaryShootLocation: IShootLocation;
  validateLocation: (photographerServiceId: string, address: IAddress) => void;
}

class InRadiusLocationStep extends React.Component<IInRadiusLocationProps, IInRadiusLocationState>
  implements IValidatableWidgetStep {
  constructor(props: IInRadiusLocationProps) {
    super(props);

    const values = props.getValues();

    this.state = {
      shootLocation: values.shootLocation,
      address: values.address,
      addressDetails: values.addressDetails,
      service: values.service,
      oldService: values.oldService
    };
  }

  public componentDidMount() {
    eventTracker.events.consumer.locationReached();
    this.props.onRef(this);
    if (this.isServiceRadiusChanged() || this.isLocationChanged()) {
      this.updateValidation();
    }
  }

  public componentWillUnmount() {
    this.props.onRef(undefined);
  }

  public componentDidUpdate(prevProps: IInRadiusLocationProps, prevState: IInRadiusLocationState) {
    const { name, updateValidate, locationValidationStatus, locationIsValid } = this.props;

    const locationValidationHasCompleted = hasActionCompleted(
      prevProps.locationValidationStatus,
      locationValidationStatus
    );

    if (prevProps.locationIsValid !== locationIsValid && locationValidationHasCompleted) {
      updateValidate(name, locationIsValid);
    }
  }

  public render() {
    const { address, addressDetails, service } = this.state;
    const { primaryShootLocation, locationValidationStatus } = this.props;

    return (
      <div className={styles.container}>
        <AddressSelection
          shootLocation={null}
          defaultAddress={address}
          defaultDetails={addressDetails}
          title="Where do you want to meet?"
          addressPlaceholder="Photoshoot Location"
          descriptionPlaceholder={"(e. g. building number, landmark, ect)"}
          locationEmptyError="Please enter photoshoot location."
          onAddressChanged={this.handleAddressChanged}
          onAddressDetailsChanged={this.handleAddressDetailsChanged}
          onValidate={this.handleValidate}
          className={styles.formWrapper}
          formClassName={styles.form}
          fieldClassName={styles.field}
          locationClassName={styles.location}
          locationErrorClassName={styles.locationError}
          errorClassName={styles.error}
          coordinate={{ lat: primaryShootLocation.latitude, lng: primaryShootLocation.longitude }}
          radius={service!.radius}
          icon={arrow}
          forceShowingError={true}
          showMap={true}
          descriptionLabel="Location details"
          validationInProgress={locationValidationStatus === "Pending"}
          showDescriptionCharCount={true}
          descriptionMaxLength={1000}
        />
      </div>
    );
  }

  public saveAndContinue = (): void => {
    const { addressDetails, shootLocation, address } = this.state;

    if (this.isLocationChanged()) {
      this.props.updateValues({
        shootLocation,
        address,
        addressDetails
      });
    }
  };

  public deferNavigation = (): boolean => {
    return false;
  };

  public isValid = (): boolean => {
    const { service, address } = this.state;
    const { validateLocation, locationIsValid, locationValidationStatus } = this.props;

    const locationValidationInProgress = locationValidationStatus === "Pending";

    const addressInfo = getAddressInfo(this.state.address);

    if (!addressInfo.isComplete) {
      return false;
    }

    if (!locationValidationInProgress) {
      validateLocation(service!.id, address as IAddress);
    }

    return locationIsValid;
  };

  private isLocationChanged = () => {
    const { shootLocation } = this.state;

    return this.props.getValues().shootLocation !== shootLocation;
  };

  private handleAddressChanged = (address: AddressInputValue) => {
    this.setState(
      {
        address
      },
      () => {
        this.updateState();
        this.updateValidation();
      }
    );
  };

  private handleAddressDetailsChanged = (addressDetails: string | null) => {
    this.setState(
      {
        addressDetails
      },
      () => this.updateState()
    );
  };

  private updateState = () => {
    const { address, addressDetails } = this.state;

    this.setState({
      shootLocation: {
        ...(address as IAddress),
        locationDetails: addressDetails
      },
      address
    });
  };

  private updateValidation = () => {
    const { name, updateValidate } = this.props;
    updateValidate(name, this.isValid());
  };

  private handleValidate = () => {
    const { locationValidationStatus, locationIsValid } = this.props;
    const locationValidationInProgress = locationValidationStatus === "Pending";

    return !locationValidationInProgress && !locationIsValid ? InRadiusLocationStep.addressOutsideArea : "";
  };

  private isServiceRadiusChanged = (): boolean => {
    const { oldService, service } = this.state;
    if (!oldService) {
      return true;
    }

    return oldService.radius !== service!.radius;
  };

  private static readonly addressOutsideArea = "This address is outside of the service area.";
}

export default InRadiusLocationStep;
