import classNames from "classnames";
import React, { PureComponent } from "react";
import spinner from "../../../../assets/icons/spinner.svg";
import { isEnterKey } from "../../../../utilities/helpers";
import ModalBase from "../modalBase";
import styles from "./index.module.scss";

interface IModalDialogProps {
  title: string;
  cancelText?: string;
  okText?: string;
  className?: string;
  contentClassName?: string;
  compactHeightClassName?: string;
  okWhenClosed?: boolean;
  okDisabled?: boolean;
  okPending?: boolean;
  noFooter?: boolean;
  noBodyPadding?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  isFullScreen?: boolean;
}

class ModalDialog extends PureComponent<IModalDialogProps> {
  public componentDidMount() {
    window.addEventListener("keydown", this.listenKeyboard, true);
  }

  public componentWillUnmount() {
    window.removeEventListener("keydown", this.listenKeyboard, true);
  }

  public render() {
    const { title, cancelText, okText, className, okWhenClosed, okDisabled, okPending, contentClassName } = this.props;
    const { noFooter, noBodyPadding, isFullScreen, compactHeightClassName } = this.props;

    return (
      <ModalBase
        onClose={okWhenClosed ? this.onOk : this.onCancel}
        className={classNames(styles.modal, className, {
          [styles.noFooter]: noFooter,
          [styles.fullScreen]: isFullScreen
        })}
        isFullScreen={isFullScreen}
        compactHeightClassName={compactHeightClassName}
      >
        <div className={styles.container}>
          <h4 className={styles.title}>{title}</h4>
          <div
            className={classNames(styles.content, contentClassName, {
              [styles.noPadding]: noBodyPadding,
              [styles.noFooter]: noFooter
            })}
          >
            {this.props.children}
          </div>
          {noFooter || (
            <div className={styles.actions}>
              <button className={styles.cancel} onClick={this.onCancel}>
                {cancelText || "No"}
              </button>
              <button
                className={classNames(styles.ok, okPending ? styles.pending : "")}
                onClick={this.onOk}
                disabled={okDisabled || okPending}
              >
                <span className={classNames(okPending ? styles.hidden : "")}>{okText || "Yes"}</span>
                {okPending && <img className={styles.spinner} alt="" src={spinner} />}
              </button>
            </div>
          )}
        </div>
      </ModalBase>
    );
  }

  private listenKeyboard = (evt: KeyboardEvent) => {
    const { okDisabled, okPending, noFooter } = this.props;
    if (isEnterKey(evt) && !(okDisabled || okPending || noFooter)) {
      this.onOk();
    }
  };

  private onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  private onOk = () => {
    if (this.props.onOk) {
      this.props.onOk();
    }
  };
}

export default ModalDialog;
