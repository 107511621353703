import React from "react";

interface IButtonIconProps {
  className?: string;
  ref?: React.Ref<HTMLSpanElement>;
}

const ButtonIcon: React.ComponentType<IButtonIconProps> = React.forwardRef(
  ({ className, ...props }: IButtonIconProps, ref?: React.Ref<HTMLSpanElement>) => (
    <span {...props} ref={ref} className={className} />
  )
);

export default ButtonIcon;
