import { INode, IPage, ITextV2, NodeType } from "./interface";
import sortBy from "lodash/sortBy";

export const pageMapping = (page: IPage) => {
  const nodes = page.nodes.map((node: any) => {
    const textSpec: ITextV2 | null =
      node.type === NodeType.TEXT && node?.textSpec
        ? {
            color: node.textSpec.textColor,
            textAlign: node?.textSpec.alignType,
            font: node?.textSpec?.fontFamily,
            size: node?.textSpec?.fontSize || 12,
            style: node?.textSpec?.fontStyle || "normal",
            isMultiple: node?.textSpec?.isMultiple || false,
            lineHeight: 1.6,
            letterSpacing: 1,
            fontPath: ""
          }
        : null;
    let photoData = null;
    if (node.type === NodeType.PHOTO && node?.photoSpec?.originalPhoto) {
      photoData = {
        imageId: node.photoSpec.originalPhoto.id,
        originalHeight: node.photoSpec.originalPhoto.height || 0,
        originalWidth: node.photoSpec.originalPhoto.width || 0,
        touched: true,
        image: node.photoSpec.originalPhoto.url,
        cropping: {}
      };

      if (node.photoSpec.cropping) {
        const fitHeight = 0;
        const originalCroppingWidth =
          (node.position?.width || 1) / node.photoSpec.cropping.width / (node.photoSpec.zoom || 1);
        const originalCroppingHeight =
          (node.position?.height || 1) / node.photoSpec.cropping.height / (node.photoSpec.zoom || 1);
        photoData.cropping = {
          x: -(node.photoSpec.cropping.left || 1) * originalCroppingWidth * (node.photoSpec.zoom || 1),
          y: -(node.photoSpec.cropping.top || 1) * originalCroppingHeight * (node.photoSpec.zoom || 1),
          width: originalCroppingWidth,
          height: originalCroppingHeight,
          zoom: node.photoSpec.zoom || 1,
          src: node.photoSpec.croppedSrc
        };
      }
    }

    node.nodeType = node.type;
    return {
      ...node,
      textSpec: textSpec,
      textSvg: node?.textSvg,
      savedData: node.type === NodeType.PHOTO ? photoData : node?.textSpec?.value
    };
  });
  page.nodes = sortBy(nodes, (n: INode) => n.position?.zIndex || 0);
  return page;
};
