import React, { Fragment } from "react";
import classNames from "classnames";
import _ from "lodash";

import { IFrame, IMat, PIXEL_PER_INCH, BEAUTY_ICON_CLASSES, IEdgeWrap, CANVAS_WRAP_TYPE } from "../../designer/model";
import { PaperPrint } from "../PaperPrint";
import colors from "utilities/colors";

import { IconBed, IconDesk, IconSofaOn, IconBookCase, IconFirePlace, IconWritingDesk } from "../../../Icons/BeautyShot";

import styles from "./beautyShot.module.scss";
import RulerIcon from "../CropperEditor/ui/rulerIcon";
import { withAutoDetectOrientation, IAutoDetectOrientationProps } from "../../designer/withAutoDetectOrientation";
import { ICroppingData } from "../CropperEditor";
import { isMobile } from "react-device-detect";
import { CanvasPrint, DEFAULT_WRAP_EDGE_WIDTH } from "../CanvasPrint";
import { Utilities } from "utilities/utilities";
import { Unit, formatWithUnit } from "utilities/unit";

interface IBeautyShotBGProps {
  width: number;
  height: number;
  bottomPoint: any;
  centerPoint: any;
  url: string;
  name?: string;
  isSmallPreview?: boolean;
}

interface IPaperPrintViewProps {
  previewPhotoWidth: number;
  previewPhotoHeight: number;
  productWidth: number;
  productHeight: number;
  photoSrc?: string;
  frame?: IFrame;
  mat?: IMat;
  isBlackAndWhite?: boolean;
  croppingData?: ICroppingDataPaperPrint;
  edgeWrap?: IEdgeWrap;
  isMpixMetalPrint?: boolean;
}

export interface ICroppingDataPaperPrint {
  cropping?: ICroppingData;
  /** Zoom scales */
  zoomScale: number;
  /** Photo rotation degree */
  rotate: number;
}

interface IBeautyShotProps extends IAutoDetectOrientationProps {
  backgrounds: IBeautyShotBGProps[];
  paperPrint: IPaperPrintViewProps;
  enableSelection?: boolean;
  unit: Unit;
}

interface IBeautyShotState {
  activeClass: string;
  placeHolderLeft: number;
  placeHolderTop: number;
  placeHolderWidthInPixel: number;
  placeHolderHightInPixel: number;
  background: IBeautyShotBGProps | null | undefined;
  totalWidth: number;
  totalHeight: number;
  supportBackgrounds: IBeautyShotBGProps[];
}

const backgroundLeftOnMobile = 75;
const SMALL_PRINT = 18;
const MOBILE_STYLING = {
  large: {
    height: 812,
    left: 131
  },
  medium: {
    height: 776,
    left: 109
  },
  small: {
    height: 770,
    left: 91
  }
};

const MAXIMUM_HEIGHT = 32;

class BeautyShot extends React.PureComponent<IBeautyShotProps, IBeautyShotState> {
  public static defaultProps = {
    unit: Unit.inch
  }
  public state: IBeautyShotState = {
    activeClass: "",
    placeHolderLeft: 0,
    placeHolderTop: 0,
    placeHolderHightInPixel: 0,
    placeHolderWidthInPixel: 0,
    totalHeight: 0,
    totalWidth: 0,
    background: null,
    supportBackgrounds: []
  };

  public resizeListener = () => this.calcViewBox();
  private containerRef = React.createRef<HTMLDivElement>();
  private imageRef = React.createRef<HTMLImageElement>();

  private currentSize: number = 0;

  private getScallingProduct = () => {
    const { productWidth, productHeight } = this.props.paperPrint;
    let scale = 1;
    const { h } = this.calcDimension();
    if (h > MAXIMUM_HEIGHT) {
      scale = MAXIMUM_HEIGHT / h;
    }
    return {
      productWidth: productWidth * scale,
      productHeight: productHeight * scale
    };
  };

  private calcDimension = () => {
    const { productWidth, productHeight, frame, mat } = this.props.paperPrint;
    const frameWidth = ((frame?.frameWidth || 0) * 2) / PIXEL_PER_INCH;
    const matWidth = ((mat?.matWidth || 0) * 2) / PIXEL_PER_INCH;
    const totalWidth = matWidth + frameWidth + productWidth;
    const totalHeight = matWidth + frameWidth + productHeight;
    return {
      w: totalWidth,
      h: totalHeight
    };
  };

  private getSupportBackgrounds = () => {
    const { w, h } = this.calcDimension();
    this.currentSize = _.max([w, h]) || 0;
    if (this.currentSize <= SMALL_PRINT) {
      return (this.props.backgrounds || []).filter(bg => bg.isSmallPreview);
    }
    return (this.props.backgrounds || []).filter(bg => !bg.isSmallPreview);
  };

  private calcViewBox() {
    if (!this.state.background) {
      return;
    }
    const clientWidth = this.containerRef.current?.clientWidth || 0;
    const clientHeight = this.containerRef.current?.clientHeight || 0;
    const { frame, mat } = this.props.paperPrint;
    const { productWidth, productHeight } = this.getScallingProduct();
    const { w, h } = this.calcDimension();
    const { bottomPoint, centerPoint, width, height, isSmallPreview } = this.state.background;

    /**converting inch to pixel */
    const bgHeightInPixel = height * PIXEL_PER_INCH;
    const bgWidthInPixel = width * PIXEL_PER_INCH;
    const top = isSmallPreview ? bottomPoint?.top * PIXEL_PER_INCH : centerPoint?.top * PIXEL_PER_INCH;
    const left = isSmallPreview ? bottomPoint.left * PIXEL_PER_INCH : centerPoint.left * PIXEL_PER_INCH;

    /**Calculating placeholder frame base on current screen size */
    const frameWidth = ((frame?.frameWidth || 0) * 2) / PIXEL_PER_INCH;
    const matWidth = ((mat?.matWidth || 0) * 2) / PIXEL_PER_INCH;

    const totalWidth = productWidth + frameWidth + matWidth;
    const totalHeight = productHeight + frameWidth + matWidth;
    const pWidth = this.props.isLandscape ? totalHeight : totalWidth;
    const pHeight = this.props.isLandscape ? totalWidth : totalHeight;

    const ratioByWidth = clientWidth / width;
    const ratioByHeight = clientHeight / height;
    const pixelByInch = (ratioByWidth + ratioByHeight) / 2;
    const placeHolderWidthInPixel = pWidth * pixelByInch;
    const placeHolderHightInPixel = isSmallPreview ? pHeight * pixelByInch : pHeight * pixelByInch;

    const placeHolderTop = !isSmallPreview
      ? (clientHeight * top) / bgHeightInPixel - placeHolderHightInPixel / 2
      : (clientHeight * top) / bgHeightInPixel - placeHolderHightInPixel;

    const placeHolderLeft = (clientWidth * left) / bgWidthInPixel - placeHolderWidthInPixel / 2;

    this.setState({
      placeHolderLeft,
      placeHolderTop,
      placeHolderWidthInPixel,
      placeHolderHightInPixel,
      totalWidth: w,
      totalHeight: h
    });
  }

  private renderCanvasPhotoWrapPreview = () => {
    let { productWidth, productHeight } = this.getScallingProduct();

    const {
      photoSrc,
      previewPhotoWidth: photoWidth,
      previewPhotoHeight: photoHeight,
      croppingData,
      edgeWrap
    } = this.props.paperPrint;

    return (
      <CanvasPrint
        photoSrc={photoSrc}
        productWidth={productWidth}
        productHeight={productHeight}
        previewPhotoWidth={photoWidth}
        previewPhotoHeight={photoHeight}
        croppingData={croppingData}
        canvasPrintDetails={{
          canvasWrapType: edgeWrap?.canvasWrapType,
          canvasWrapBorder: edgeWrap?.canvasWrapBorder || DEFAULT_WRAP_EDGE_WIDTH,
          isPreviewMode: true
        }}
      />
    );
  };

  public componentDidMount() {
    const bgs = this.getSupportBackgrounds();
    const activeClass = bgs[0]?.name || "";
    this.setState({ supportBackgrounds: bgs, background: bgs[0], activeClass: activeClass.toUpperCase() }, () => {
      setTimeout(() => {
        this.calcViewBox();
      }, 1000);
    });
    window.addEventListener("resize", this.resizeListener);
  }

  public componentDidUpdate(prevProps: any) {
    if (
      prevProps.paperPrint?.productWidth !== this.props.paperPrint?.productWidth ||
      prevProps.paperPrint?.productHeight !== this.props.paperPrint?.productHeight ||
      !_.isEqual(prevProps.paperPrint.frame, this.props.paperPrint?.frame) ||
      !_.isEqual(prevProps.paperPrint.mat, this.props.paperPrint?.mat)
    ) {
      const bgs = this.getSupportBackgrounds();
      this.setState({ supportBackgrounds: bgs, background: bgs[0] });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  private renderIcon(name: string) {
    const { activeClass } = this.state;
    let properties = {
      fill: colors.white,
      color: colors.black
    };
    switch (name.toUpperCase()) {
      case BEAUTY_ICON_CLASSES.ICON_BED: {
        if (activeClass === BEAUTY_ICON_CLASSES.ICON_BED) {
          properties.fill = colors.black;
          properties.color = colors.white;
        }
        return <IconBed {...properties} />;
      }

      case BEAUTY_ICON_CLASSES.ICON_SOFA_ON: {
        if (activeClass === BEAUTY_ICON_CLASSES.ICON_SOFA_ON) {
          properties.fill = colors.black;
          properties.color = colors.white;
        }
        return <IconSofaOn {...properties} />;
      }

      case BEAUTY_ICON_CLASSES.ICON_DESK: {
        if (activeClass === BEAUTY_ICON_CLASSES.ICON_DESK) {
          properties.fill = colors.black;
          properties.color = colors.white;
        }
        return <IconDesk {...properties} />;
      }

      case BEAUTY_ICON_CLASSES.ICON_BOOKCASE: {
        if (activeClass === BEAUTY_ICON_CLASSES.ICON_BOOKCASE) {
          properties.fill = colors.black;
          properties.color = colors.white;
        }
        return <IconBookCase {...properties} />;
      }

      case BEAUTY_ICON_CLASSES.ICON_WRITTING_DESK: {
        if (activeClass === BEAUTY_ICON_CLASSES.ICON_WRITTING_DESK) {
          properties.fill = colors.black;
          properties.color = colors.white;
        }
        return <IconWritingDesk {...properties} />;
      }

      case BEAUTY_ICON_CLASSES.ICON_FIREPLACE: {
        if (activeClass === BEAUTY_ICON_CLASSES.ICON_FIREPLACE) {
          properties.fill = colors.black;
          properties.color = colors.white;
        }
        return <IconFirePlace {...properties} />;
      }

      default:
        return null;
    }
  }

  private onChangeBg(bg: IBeautyShotBGProps) {
    this.setState(
      {
        background: bg,
        activeClass: (bg.name || "").toUpperCase()
      },
      () => this.calcViewBox()
    );
  }

  get getCSSByResolution() {
    if (
      !isMobile ||
      this.currentSize > SMALL_PRINT ||
      this.state.background?.name !== BEAUTY_ICON_CLASSES.ICON_FIREPLACE
    ) {
      return {} as React.CSSProperties;
    }
    const globalHeight = window.innerHeight;
    let left: number = 0;
    if (globalHeight >= MOBILE_STYLING.large.height) {
      left = MOBILE_STYLING.large.left;
    } else if (globalHeight >= MOBILE_STYLING.medium.height) {
      left = MOBILE_STYLING.large.left;
    } else {
      left = MOBILE_STYLING.small.left;
    }
    return { position: "relative", left: `${left}px` } as React.CSSProperties;
  }

  private getWidthAndHeightPreview(width: number, height: number, unit: Unit) {
    const widthPreview = Utilities.roundingTwoDecimalsNumber(width);
    const heightPreview = Utilities.roundingTwoDecimalsNumber(height);
    return `${formatWithUnit(
      `${widthPreview}x${heightPreview}`,
      widthPreview,
      heightPreview,
      unit
    )}`;
  }

  private getExtraTopPositionForCanvasNaturalWrap(edgeWrap: IEdgeWrap | undefined) {
    return edgeWrap?.canvasWrapType === CANVAS_WRAP_TYPE.PHOTO_WRAP
      ? (edgeWrap?.canvasWrapBorder * PIXEL_PER_INCH) / 4 - 8
      : 0;
  }

  public render() {
    const {
      background,
      placeHolderTop,
      placeHolderLeft,
      placeHolderWidthInPixel,
      placeHolderHightInPixel,
      totalWidth,
      totalHeight,
      supportBackgrounds
    } = this.state;

    const { productWidth, productHeight } = this.getScallingProduct();

    const { paperPrint, enableSelection, unit } = this.props;

    const {
      photoSrc,
      frame,
      mat,
      previewPhotoWidth: photoWidth,
      previewPhotoHeight: photoHeight,
      isBlackAndWhite,
      croppingData,
      edgeWrap,
      isMpixMetalPrint
    } = paperPrint;

    const paperStyle = {
      position: "absolute",
      top: `${placeHolderTop + this.getExtraTopPositionForCanvasNaturalWrap(edgeWrap)}px`,
      left: `${placeHolderLeft}px`,
      width: placeHolderWidthInPixel,
      height: placeHolderHightInPixel,
      boxShadow: paperPrint.edgeWrap || paperPrint.isMpixMetalPrint ? "" : `0 1px 4px 0 rgba(0, 0, 0, 0.5)`
    } as React.CSSProperties;
    const imageStyle = this.getCSSByResolution;
    return (
      <Fragment>
        {background && (
          <div className={classNames(styles.container)}>
            <div className={styles.finishSize}>
              <span>{`${this.getWidthAndHeightPreview(
                this.props.paperPrint.productWidth,
                this.props.paperPrint.productHeight,
                unit
              )} ${frame?.name || ""} Preview`}</span>
            </div>
            <div
              className={classNames(styles.preview)}
              style={{
                left: isMobile && this.currentSize <= SMALL_PRINT ? `-${backgroundLeftOnMobile}px` : "0"
              }}
            >
              <div className={classNames(styles.wrapper)} ref={this.containerRef} id="node">
                <img
                  onLoad={() => {
                    this.calcViewBox();
                  }}
                  ref={this.imageRef}
                  src={background.url}
                  style={imageStyle}
                />
                <div style={paperStyle}>
                  {edgeWrap?.canvasWrapType === CANVAS_WRAP_TYPE.PHOTO_WRAP ? (
                    this.renderCanvasPhotoWrapPreview()
                  ) : (
                    <PaperPrint
                      mat={mat}
                      frame={frame}
                      photoSrc={photoSrc}
                      productWidth={productWidth}
                      productHeight={productHeight}
                      previewPhotoWidth={photoWidth}
                      previewPhotoHeight={photoHeight}
                      isBlackAndWhite={isBlackAndWhite}
                      croppingData={croppingData}
                      isMpixMetalPrint={isMpixMetalPrint}
                    />
                  )}
                </div>
              </div>
              <div
                className={classNames(styles.iconLink)}
                style={{
                  left: isMobile && this.currentSize <= SMALL_PRINT ? `${backgroundLeftOnMobile}px` : "0"
                }}
              >
                {supportBackgrounds.length > 1 &&
                  enableSelection &&
                  supportBackgrounds.map((item, idx) => (
                    <div
                      key={`${item.name}-${idx}`}
                      onClick={() => this.onChangeBg(item)}
                      className={classNames(styles.iconLinkItem)}
                    >
                      {this.renderIcon(item.name || "")}
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles.footer}>
              <RulerIcon />
              <span>{`Finished size is ${this.getWidthAndHeightPreview(totalWidth, totalHeight, unit)} ${
                mat ? `with mat option` : ``
              }`}</span>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withAutoDetectOrientation(BeautyShot);
