import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import PageLayout from "../../../layouts/page";
import { IAppState } from "../../../store/state";

function mapStateToProps(state: IAppState) {
  return {};
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout));
