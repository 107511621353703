import React from "react";
import { Editor } from "slate";
import cx from "classnames";

import styles from "./markdropdown.module.scss";
import Button from "../Button";

const DownChevron = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#a3abb4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8 10l4 4 4-4"
    />
  </svg>
);

const DropdownIndicator = (props: any) => {
  return (
    <div className={styles.indicator}>
      <DownChevron />
    </div>
  );
};

export interface IMarkDropdownProps {
  type: string;
  editor: Editor;
  onChange?: (size: number) => void;
  className?: string;
  headingSize?: number;
  onToggleDropdown?: (isOpen: boolean) => void;
}

interface IHeadingProps {
  heading: string;
  fontSize: number;
  selected: boolean;
  onMouseDown: (event: any) => void;
}

interface IHeading {
  label: string;
  value: number;
}

interface IHeadingOptions {
  large: IHeading;
  small: IHeading;
  normal: IHeading;
}

const headings: IHeadingOptions = {
  large: {
    label: "Heading 1",
    value: 32
  },
  normal: {
    label: "Heading 2",
    value: 24
  },
  small: {
    label: "Heading 3",
    value: 18
  }
};

const getSizeFromNumber = (size: number): IHeading => {
  switch (size) {
    case 32:
      return headings.large;
    case 24:
      return headings.normal;
    case 18:
      return headings.small;
    default:
      return headings.large;
  }
};

const Heading: React.FC<IHeadingProps> = ({ heading, fontSize, onMouseDown, selected }) => (
  <Button className={styles.heading} isActive={false} onMouseDown={onMouseDown}>
    <p style={{ fontSize }} className={cx({ [styles.selectedHeading]: selected })}>
      {heading}
    </p>
  </Button>
);

const MarkDropdown: React.FC<IMarkDropdownProps> = ({ ...props }: IMarkDropdownProps) => {
  const [showOptions, toggleOptions] = React.useState<boolean>(false);
  const [defaultHeading, setDefaultHeading] = React.useState<IHeading | null>(null);

  React.useEffect(() => {
    if (props.headingSize) {
      const heading = getSizeFromNumber(props.headingSize);
      setDefaultHeading(heading);
    }
  }, [props.headingSize]);

  React.useEffect(() => {
    if (props.onChange && defaultHeading) {
      props.onChange(defaultHeading.value);

      Editor.addMark(props.editor, props.type, defaultHeading.value);
    }
  }, [defaultHeading]);

  return (
    <Button
      className={styles.zenContainer}
      isActive={false}
      onMouseDown={event => {
        event.preventDefault();
        toggleOptions(!showOptions);
        props.onToggleDropdown && props.onToggleDropdown(!showOptions);
      }}
    >
      <p className={styles.label}>{defaultHeading?.label || headings.large.label}</p>
      <DropdownIndicator />
      {showOptions && (
        <div className={styles.headingsMenu}>
          <Heading
            heading={headings.large.label}
            selected={headings.large.label === (defaultHeading?.label || headings.large.label)}
            fontSize={headings.large.value}
            onMouseDown={() => setDefaultHeading(headings.large)}
          />
          <Heading
            heading={headings.normal.label}
            selected={headings.normal.label === defaultHeading?.label}
            fontSize={headings.normal.value}
            onMouseDown={() => setDefaultHeading(headings.normal)}
          />
          <Heading
            heading={headings.small.label}
            selected={headings.small.label === defaultHeading?.label}
            fontSize={headings.small.value}
            onMouseDown={() => setDefaultHeading(headings.small)}
          />
        </div>
      )}
    </Button>
  );
};

export default MarkDropdown;
