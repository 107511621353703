import { Dispatch } from "redux";
import { IApiServices } from "../../../api";
import IError from "../../../utilities/error";
import { addNotification, NotificationType } from "../../notification/actions";
import { IAppState } from "../../state";
import { ActionType } from "./constants";
import { IConnectSetup, IGoogleCalendar } from "./model";

interface ISetupConnectStarted {
  type: ActionType.SETUP_CONNECT_STARTED;
  redirectUri: string;
}

interface ISetupConnectSuccess {
  type: ActionType.SETUP_CONNECT_SUCCESS;
  redirectUri: string;
  connectSetup: IConnectSetup;
}

interface ISetupConnectError {
  type: ActionType.SETUP_CONNECT_ERROR;
  redirectUri: string;
  error: IError;
}

export type SetupConnectAction = ISetupConnectStarted | ISetupConnectSuccess | ISetupConnectError;

const setupConnectStarted = (redirectUri: string): ISetupConnectStarted => ({
  type: ActionType.SETUP_CONNECT_STARTED,
  redirectUri
});

const setupConnectSuccess = (redirectUri: string, connectSetup: IConnectSetup): ISetupConnectSuccess => ({
  type: ActionType.SETUP_CONNECT_SUCCESS,
  redirectUri,
  connectSetup
});

const setupConnectError = (redirectUri: string, error: IError): ISetupConnectError => ({
  type: ActionType.SETUP_CONNECT_ERROR,
  redirectUri,
  error
});

export const setupConnect = (redirectUri: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(setupConnectStarted(redirectUri));
    try {
      const connectSetup = await apiService.profile.googleCalendar.setupConnect(redirectUri);
      dispatch(setupConnectSuccess(redirectUri, connectSetup));
    } catch (error) {
      dispatch(setupConnectError(redirectUri, error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};

interface ILoadCalendarsStarted {
  type: ActionType.LOAD_CALENDARS_STARTED;
}

interface ILoadCalendarsSuccess {
  type: ActionType.LOAD_CALENDARS_SUCCESS;
  calendars: IGoogleCalendar[];
}

interface ILoadCalendarsError {
  type: ActionType.LOAD_CALENDARS_ERROR;
  error: IError;
}

interface ILoadCalendarsReset {
  type: ActionType.LOAD_CALENDARS_RESET;
}

export type LoadCalendarsAction =
  | ILoadCalendarsStarted
  | ILoadCalendarsSuccess
  | ILoadCalendarsError
  | ILoadCalendarsReset;

const loadCalendarsStarted = (): ILoadCalendarsStarted => ({
  type: ActionType.LOAD_CALENDARS_STARTED
});

const loadCalendarsSuccess = (calendars: IGoogleCalendar[]): ILoadCalendarsSuccess => ({
  type: ActionType.LOAD_CALENDARS_SUCCESS,
  calendars
});

const loadCalendarsError = (error: IError): ILoadCalendarsError => ({
  type: ActionType.LOAD_CALENDARS_ERROR,
  error
});

export const loadCalendars = () => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadCalendarsStarted());
    try {
      const connectSetup = await apiService.profile.googleCalendar.loadCalendars();
      dispatch(loadCalendarsSuccess(connectSetup));
    } catch (error) {
      dispatch(loadCalendarsError(error));
    }
  };
};

export const loadCalendarsReset = (): ILoadCalendarsReset => ({
  type: ActionType.LOAD_CALENDARS_RESET
});

interface IDisconnectStarted {
  type: ActionType.DISCONNECT_STARTED;
}

interface IDisconnectSuccess {
  type: ActionType.DISCONNECT_SUCCESS;
}

interface IDisconnectError {
  type: ActionType.DISCONNECT_ERROR;
  error: IError;
}

export type DisconnectAction = IDisconnectStarted | IDisconnectSuccess | IDisconnectError;

const disconnectStarted = (): IDisconnectStarted => ({
  type: ActionType.DISCONNECT_STARTED
});

const disconnectSuccess = (): IDisconnectSuccess => ({
  type: ActionType.DISCONNECT_SUCCESS
});

const disconnectError = (error: IError): IDisconnectError => ({
  type: ActionType.DISCONNECT_ERROR,
  error
});

export const disconnect = () => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(disconnectStarted());
    try {
      await apiService.profile.googleCalendar.disconnect();
      dispatch(disconnectSuccess());
    } catch (error) {
      dispatch(disconnectError(error));
      dispatch(addNotification(error.message, NotificationType.Error));
    }
  };
};
