import { apiClient } from ".";
import { ActivityTypeCode, IActivity, IBilling, ICalculatedTax } from "../store/billing/model";
import { map } from "../utilities/mapper";
import { apiType } from "./apiType";

export interface IApiBilling {
  load: (bookingId: string) => Promise<IBilling>;
  sendInvoice: (invoiceId: string) => Promise<IBilling>;
  updateInvoice: (invoiceId: string, amount: number, internalNote?: string) => Promise<IBilling>;
  addInvoice: (
    bookingId: string,
    amount: number,
    isSendEmail: boolean,
    clientNote?: string,
    internalNote?: string
  ) => Promise<IBilling>;
  deleteInvoice: (invoiceId: string) => Promise<IBilling>;
  refund: (activityId: string, activityType: ActivityTypeCode, amount: number) => Promise<IBilling>;
  markAsPaid: (invoiceId: string) => Promise<IBilling>;
  calculateTaxForNewInvoice: (bookingId: string, amount: number) => Promise<ICalculatedTax>;
  calculateTaxForEditingInvoice: (invoiceId: string, amount: number) => Promise<ICalculatedTax>;
}

const api: IApiBilling = {
  load: async (bookingId: string) =>
    mapBilling(await apiClient.get(`${apiType.bookingWidget}billing/photographer/billing/${bookingId}`)),

  sendInvoice: async (invoiceId: string) =>
    mapBilling(await apiClient.post(`${apiType.bookingWidget}billing/photographer/invoices/send`, { invoiceId })),

  updateInvoice: async (invoiceId: string, amount: number, internalNote?: string) =>
    mapBilling(
      await apiClient.put(`${apiType.bookingWidget}billing/photographer/invoices/edit`, {
        invoiceId,
        amount,
        internalNote
      })
    ),

  addInvoice: async (
    bookingId: string,
    amount: number,
    isSendEmail: boolean,
    clientNote?: string,
    internalNote?: string
  ) =>
    mapBilling(
      await apiClient.post(`${apiType.bookingWidget}billing/photographer/invoices/add`, {
        bookingId,
        amount,
        isSendEmail,
        clientNote,
        internalNote
      })
    ),

  deleteInvoice: async (invoiceId: string) =>
    mapBilling(
      await apiClient.delete(`${apiType.bookingWidget}billing/photographer/invoices/delete`, {
        data: { invoiceId }
      })
    ),

  refund: async (activityId: string, activityType: ActivityTypeCode, amount: number) =>
    mapBilling(
      await apiClient.post<IBilling>(`${apiType.bookingWidget}billing/photographer/refunds`, {
        guid: activityId,
        isInvoice: activityType === "invoice",
        amount
      })
    ),

  markAsPaid: async (invoiceId: string) =>
    mapBilling(await apiClient.post(`${apiType.bookingWidget}billing/photographer/invoices/markaspaid`, { invoiceId })),

  calculateTaxForNewInvoice: (bookingId: string, amount: number) =>
    apiClient.post<ICalculatedTax>(`${apiType.bookingWidget}billing/invoices/new/tax`, { bookingId, amount }),

  calculateTaxForEditingInvoice: (invoiceId: string, amount: number) =>
    apiClient.post<ICalculatedTax>(`${apiType.bookingWidget}billing/invoices/edit/tax`, { invoiceId, amount })
};

export function mapActivity(activity: any): IActivity {
  return map(activity)
    .rename("dateAndTime", "date")
    .rename("availableForRefund", "availableRefundAmount")
    .removeNullProps()
    .value() as IActivity;
}

export function mapBilling(billing: any): IBilling {
  return map(billing)
    .rename("billingActivities", "activities")
    .mapArray("activities", mapActivity)
    .rename("latestBillingActivity", "latestActivity")
    .map("latestActivity", mapActivity)
    .removeNullProps()
    .value() as IBilling;
}

export default api;
