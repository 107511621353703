import React from "react";
import { Helmet } from "react-helmet";

export interface IAppProps {}

export default class Index extends React.PureComponent<IAppProps, any> {
  public render() {
    return (
      <div>
        <div
          style={{
            minHeight: "calc(100vh - 500px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Helmet title="Unauthorized" />
          <div
            style={{
              maxWidth: "560px",
              backgroundColor: "#fff",
              padding: "80px 30px",
              margin: "100px auto",
              borderRadius: "10px",
              flex: "1"
            }}
          >
            <div
              style={{
                maxWidth: "400px",
                margin: "0 auto"
              }}
            >
              <h1 className="font-size-36 mb-2">Unauthorized</h1>
              <p className="mb-3">You are not authorized to view this page</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
