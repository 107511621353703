import React, { PureComponent, ReactElement } from "react";
import cx from "classnames";
import styles from "./toaster.module.scss";

export interface IToaster {
  message: string | ReactElement;
  className?: string;
  animateIn: boolean;
  animateOut: boolean;
  hide?: boolean;
  center?: boolean;
  success?: boolean;
  dismissTime?: number;
}
export enum ToasterTheme {
  Default = "default",
  Blue = "blue"
}

interface IToasterProps {
  toaster: IToaster;
  theme?: ToasterTheme;
  onHideAlertError: () => void;
}

class Toaster extends PureComponent<IToasterProps> {
  public UNSAFE_componentWillReceiveProps(nextProps: IToasterProps) {
    if (nextProps.toaster !== this.props.toaster && nextProps.toaster.animateIn) {
      setTimeout(() => {
        this.props.onHideAlertError();
      }, this.props.toaster.dismissTime ?? 3500);
    }
  }
  public render() {
    const theme = this.props.theme || ToasterTheme.Default;
    return (
      <div
        className={cx(
          styles.container,
          this.props.toaster.className,
          styles[theme],
          {
            [styles.animateIn]: this.props.toaster.animateIn
          },
          { [styles.animateOut]: this.props.toaster.animateOut },
          { [styles.center]: this.props.toaster.center },
          { [styles.success]: this.props.toaster.success },
          { [styles.hidden]: this.props.toaster.hide }
        )}
      >
        {this.props.toaster.message}
      </div>
    );
  }
}

export default Toaster;
