import IIConProps from "components/Icons/IIconProps";
import { getRadius } from "components/Icons/utils";
import React from "react";

interface IComIconProps extends IIConProps {
  hasUnreadComment?: boolean;
}

const CommentMedia = ({ minX = 0, minY = 0, ...props }: IComIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox={`${minX} ${minY} ${props.size} ${props.size}`}
  >
    <g fill="none" fillRule="evenodd">
      <circle
        cx={getRadius(props.size)}
        cy={getRadius(props.size)}
        r={getRadius(props.size)}
        fill="#000"
        opacity=".8"
      />
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke={props.color} strokeWidth="1.5">
          <g>
            <g>
              <g>
                <path
                  d="M13.579 20L12.632 20 8.842 24 8.842 20 6 20 6 8 24 8 24 14.5"
                  transform="translate(-897 -117) translate(785 93) translate(16 24) translate(96)"
                />
                <path
                  d="M16 23.333L21.042 23.333 25.167 27 25.167 23.333 27 23.333 27 16 16 16z"
                  transform="translate(-897 -117) translate(785 93) translate(16 24) translate(96)"
                />
              </g>
            </g>
          </g>
        </g>
        {props.hasUnreadComment && <circle cx="28" cy="4" r="4" fill="#8CD07D" />}
      </g>
    </g>
  </svg>
);

export default CommentMedia;
