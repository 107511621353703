import { ICurrency } from "models";
import { BannerDiscountType } from "utilities/constant";
import { formatPriceByCurrency } from "utilities/currency/currency";
import React from "react";
import styles from "./textBanner.module.scss";

export interface ITextBannerProps {
  couponCode: string;
  couponAmount: number;
  couponType: BannerDiscountType;
  backGroundColor: string;
  textColor: string;
  title: string;
  currency?: ICurrency;
}

export class TextBanner extends React.PureComponent<ITextBannerProps> {
  private getDiscountText = () => {
    const { currency, couponType } = this.props;
    switch (couponType) {
      case BannerDiscountType.DollarOff:
        return `${formatPriceByCurrency(this.props.couponAmount, currency?.symbol)}`;
      case BannerDiscountType.PercentOff:
        return `${this.props.couponAmount}%`;
      default:
        return "";
    }
  };
  private generateText = () => {
    let originText = this.props.title;
    originText = originText.replace("{discount}", `${this.getDiscountText()}`);
    originText = originText.replace("{code}", this.props.couponCode);

    return originText;
  };
  public render() {
    return (
      <div
        className={styles.container}
        style={{ backgroundColor: this.props.backGroundColor, color: this.props.textColor }}
      >
        <div className={styles.container_inner}>{this.generateText()}</div>
      </div>
    );
  }
}

export default TextBanner;
