import cx from "classnames";
import React from "react";
import styles from "./image.module.scss";

interface IImageProps {
  src: string;
  alt?: string;
  srcset?: string;
  height?: number;
  width?: number;
  aspectratio?: string;
  className?: string;
  styles?: React.CSSProperties;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseOver?: (evt: React.FormEvent<HTMLElement>) => void;
  onLoad?: () => void;
  quality?: string;
  copyEnabled?: boolean;
  isGhost?: boolean;
  priority?: boolean;
  lqip?: boolean;
  fitParent?: boolean;
  isClickable?: boolean;
  disableStyles?: boolean;
}

const Image = React.forwardRef<HTMLImageElement, IImageProps>((props, ref) => {
  const { src, srcset, alt, onClick, width, height, lqip, isClickable } = props;
  const aspectRatio = width && height ? (width / height).toFixed(3) : undefined;
  const imgClassNames = cx(props.className, "hasLazyLoad", "lazyload", {
    [styles.image]: !props.disableStyles,
    lqip_placeholder: lqip,
    is_ghost: props.isGhost,
    [styles.copyEnabled]: props.copyEnabled,
    [styles.clickEnabled]: isClickable,
    [styles.fitParent]: props.fitParent,
    [styles.isGhost]: props.isGhost
  });
  const cancelDrag = (event: any) => {
    event.preventDefault();
    return false;
  };

  return (
    <img
      ref={ref}
      src={lqip ? src : undefined}
      data-src={!lqip ? src : undefined}
      srcSet={
        !lqip && srcset ? "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : undefined
      }
      data-srcset={srcset}
      data-sizes="auto"
      data-aspectratio={props.aspectratio || aspectRatio}
      alt={alt}
      className={imgClassNames}
      style={{ ...props.styles }}
      onLoad={props.onLoad}
      onClick={onClick}
      onContextMenu={!props.copyEnabled ? cancelDrag : undefined}
      onMouseOver={props.onMouseOver}
      draggable={props.copyEnabled ? true : undefined}
    />
  );
});

export default Image;
