import _ from "lodash";
import moment from "moment";
import { ActionState } from "../common";
import {
  ApproveAction,
  CancelAction,
  DeclineAction,
  GetBookingAction,
  LoadAction,
  PatchAction,
  RescheduleAction,
  UpdateAction
} from "./actions";
import { ActionType } from "./constants";
import {
  IBooking,
  IBookingBase,
  IBookingsQuery,
  IBookingStatus,
  IBookingUpdater,
  IRescheduleResult,
  IRescheduleSettings,
  rescheduleConflictsEmpty
} from "./model";

export interface IState {
  bookings?: IBooking[];
  getBooking: GetBookingState;
  load: LoadState;
  update: UpdateState;
  cancel: CancelState;
  approve: ApproveState;
  decline: DeclineState;
  reschedule: RescheduleState;
}

type GetBookingState = ActionState<{ bookingId: string }, { booking: IBookingBase }>;

type LoadState = ActionState<{ query: IBookingsQuery }>;

type UpdateState = ActionState<{ bookingId: string; updater: IBookingUpdater }>;

type CancelState = ActionState<{ bookingId: string; note?: string }>;

type ApproveState = ActionState<{ bookingId: string; note?: string }, { bookingStatus: IBookingStatus }>;

type DeclineState = ActionState<{ bookingId: string; note: string }>;

type RescheduleState = ActionState<
  { bookingId: string; settings: IRescheduleSettings; force?: boolean },
  { result?: IRescheduleResult }
>;

const initialState: IState = {
  getBooking: { status: "Init" },
  load: { status: "Init" },
  update: { status: "Init" },
  cancel: { status: "Init" },
  approve: { status: "Init" },
  decline: { status: "Init" },
  reschedule: { status: "Init" }
};

export const bookings = (
  state: IState = initialState,
  action:
    | GetBookingAction
    | LoadAction
    | UpdateAction
    | CancelAction
    | ApproveAction
    | DeclineAction
    | RescheduleAction
    | PatchAction
): IState => {
  switch (action.type) {
    case ActionType.GET_BOOKING_STARTED:
      return {
        ...state,
        getBooking: {
          status: "Pending",
          bookingId: action.bookingId
        }
      };
    case ActionType.GET_BOOKING_SUCCESS:
      return state.getBooking.status === "Pending" && state.getBooking.bookingId === action.booking.id
        ? {
            ...state,
            getBooking: {
              ...state.getBooking,
              status: "Success",
              booking: action.booking
            }
          }
        : state;
    case ActionType.GET_BOOKING_ERROR:
      return state.getBooking.status === "Pending" && state.getBooking.bookingId === action.bookingId
        ? {
            ...state,
            getBooking: {
              ...state.getBooking,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.LOAD_STARTED:
      return {
        ...state,
        bookings: undefined,
        load: {
          status: "Pending",
          query: action.query
        }
      };
    case ActionType.LOAD_SUCCESS:
      return state.load.status === "Pending" && _.isEqual(state.load.query, action.query)
        ? {
            ...state,
            bookings: action.bookings,
            load: {
              ...state.load,
              status: "Success"
            }
          }
        : state;
    case ActionType.LOAD_ERROR:
      return state.load.status === "Pending" && _.isEqual(state.load.query, action.query)
        ? {
            ...state,
            load: {
              ...state.load,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.UPDATE_STARTED:
      return {
        ...state,
        update: {
          status: "Pending",
          bookingId: action.bookingId,
          updater: action.updater
        }
      };
    case ActionType.UPDATE_SUCCESS:
      return state.update.status === "Pending" &&
        state.update.bookingId === action.bookingId &&
        _.isEqual(state.update.updater, action.updater)
        ? {
            ...state,
            bookings: state.bookings
              ? state.bookings.map(b => (b.id === action.bookingId ? _.merge({}, b, action.updater) : b))
              : state.bookings,
            update: {
              ...state.update,
              status: "Success"
            }
          }
        : state;
    case ActionType.UPDATE_ERROR:
      return state.update.status === "Pending" &&
        state.update.bookingId === action.bookingId &&
        _.isEqual(state.update.updater, action.updater)
        ? {
            ...state,
            update: {
              ...state.update,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.CANCEL_STARTED:
      return {
        ...state,
        cancel: {
          status: "Pending",
          bookingId: action.bookingId,
          note: action.note
        }
      };
    case ActionType.CANCEL_SUCCESS:
      return state.cancel.status === "Pending" && state.cancel.bookingId === action.bookingId
        ? {
            ...state,
            cancel: {
              ...state.cancel,
              status: "Success"
            }
          }
        : state;
    case ActionType.CANCEL_ERROR:
      return state.cancel.status === "Pending" && state.cancel.bookingId === action.bookingId
        ? {
            ...state,
            cancel: {
              ...state.cancel,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.APPROVE_STARTED:
      return {
        ...state,
        approve: {
          status: "Pending",
          bookingId: action.bookingId,
          note: action.note
        }
      };
    case ActionType.APPROVE_SUCCESS:
      return state.approve.status === "Pending" && state.approve.bookingId === action.bookingId
        ? {
            ...state,
            approve: {
              ...state.approve,
              status: "Success",
              bookingStatus: action.bookingStatus
            }
          }
        : state;
    case ActionType.APPROVE_ERROR:
      return state.approve.status === "Pending" && state.approve.bookingId === action.bookingId
        ? {
            ...state,
            approve: {
              ...state.approve,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.DECLINE_STARTED:
      return {
        ...state,
        decline: {
          status: "Pending",
          bookingId: action.bookingId,
          note: action.note
        }
      };
    case ActionType.DECLINE_SUCCESS:
      return state.decline.status === "Pending" && state.decline.bookingId === action.bookingId
        ? {
            ...state,
            decline: {
              ...state.decline,
              status: "Success"
            }
          }
        : state;
    case ActionType.DECLINE_ERROR:
      return state.decline.status === "Pending" && state.decline.bookingId === action.bookingId
        ? {
            ...state,
            decline: {
              ...state.decline,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.RESCHEDULE_STARTED:
      return {
        ...state,
        reschedule: {
          status: "Pending",
          bookingId: action.bookingId,
          settings: action.settings,
          force: action.force
        }
      };
    case ActionType.RESCHEDULE_SUCCESS:
      if (state.reschedule.status === "Pending" && state.reschedule.bookingId === action.bookingId) {
        let stateBookings = state.bookings;
        if (state.reschedule.force || rescheduleConflictsEmpty(action.result.conflicts)) {
          const booking = _.find(state.bookings, { id: action.bookingId })!;
          const { startDateTime, endDateTime } = state.reschedule.settings;
          booking.startDateTime = startDateTime
            .clone()
            .local(true)
            .set({ hours: startDateTime.hours(), minutes: startDateTime.minutes() })
            .toDate();
          booking.duration = moment(endDateTime).diff(startDateTime, "minutes");
          booking.internalNote = action.result.internalNote;
          stateBookings = [...stateBookings!];
        }
        return {
          ...state,
          bookings: stateBookings,
          reschedule: {
            ...state.reschedule,
            status: "Success",
            result: action.result
          }
        };
      } else {
        return state;
      }
    case ActionType.RESCHEDULE_ERROR:
      return state.reschedule.status === "Pending" && state.reschedule.bookingId === action.bookingId
        ? {
            ...state,
            reschedule: {
              ...state.reschedule,
              status: "Error",
              error: action.error
            }
          }
        : state;
    case ActionType.PATCH_BOOKING:
      return {
        ...state,
        bookings:
          state.bookings === undefined
            ? undefined
            : state.bookings.map(b => (b.id === action.patch.bookingId ? _.defaults(action.patch, b) : b))
      };
    default:
      return state;
  }
};

export default bookings;
