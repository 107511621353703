import { Utilities } from "@zenfolio/core-components";
import _ from "lodash";
import * as React from "react";
import Helmet from "react-helmet";
import { Route, RouteComponentProps, Switch } from "react-router";
import Content from "../../components/layout/content";
import { IRoute } from "../../components/router/routes";
import PageHeader from "../../containers/layout/header";
import SideMenu from "../../containers/layout/menu/sideMenu";
import ModalRoot from "../../containers/modal";
import NotFoundPage from "../../pages/404";
import { getCurrentHeader, getMainLayoutRoutes, getRouteFullPath } from "../../store/helpers";
import { NavigationManager } from "../../utilities/navigationManager";
import styles from "./index.module.scss";

export interface IMainLayoutProps extends RouteComponentProps {
  routes: IRoute[];
}
export interface IMainLayoutState {
  showSearchPanel: boolean;
}

class MainLayout extends React.PureComponent<IMainLayoutProps, IMainLayoutState> {
  constructor(props: IMainLayoutProps) {
    super(props);

    this.state = {
      showSearchPanel: false
    };
  }

  public componentDidUpdate(prevProps: any) {
    const { location } = this.props;
    const prevLocation = prevProps.location;

    if (location !== prevLocation) {
      Utilities.scrollTop();
    }
  }

  public render() {
    return (
      <React.Fragment>
        <ModalRoot />
        <Helmet titleTemplate="BookMe | %s" onChangeClientState={NavigationManager.notifyParentOnTitleChange} />
        <div className={styles.container}>
          <SideMenu
            key="sideMenu"
            {...this.props}
            logo={{
              imgSrc: require("../../assets/icons/logo.svg"),
              imgAlt: "BookMe"
            }}
          />
          <div className={styles.content}>
            <PageHeader title={this.getHeader()} showSearchPanel={this.state.showSearchPanel} />
            <Content>
              <Switch>
                {this.getRoutes()}
                <Route key="not-found">
                  <NotFoundPage />
                </Route>
              </Switch>
            </Content>
          </div>
        </div>
      </React.Fragment>
    );
  }

  private getRoutes = () => {
    const routes = getMainLayoutRoutes(this.props.routes);

    return routes.map((route, key) => {
      return <Route path={getRouteFullPath(route)} key={key} render={this.renderRoute(route)} />;
    });
  };

  private renderRoute = _.memoize((route: IRoute) => {
    return (routeProps: any) => {
      const props = { ...routeProps, updateSearchPanelVisibility: this.updateSearchPanelVisibility };
      return React.createElement(route.component, props);
    };
  });

  private getHeader = () => {
    const routes = getMainLayoutRoutes(this.props.routes);

    return getCurrentHeader(routes, this.props.location);
  };

  private updateSearchPanelVisibility = (showSearchPanel: boolean) => {
    this.setState({
      showSearchPanel
    });
  };
}

export default MainLayout;
