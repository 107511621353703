import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import PrimaryOnlyLocationStep from "../../../../../components/widget/steps/location/primaryOnly";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ widget }: IAppState) {
  return {
    shootLocation: widget.loadWidgetInfo.primaryShootLocation
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryOnlyLocationStep);
