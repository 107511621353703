import { IRoute } from "../../components/router/routes";
import IError from "../../utilities/error";
import { SessionAction } from "./actions";
import { SessionActionType } from "./constants";
import { IUserInfo, LoginState } from "./model";

export interface ISessionState {
  loginState: LoginState;
  routes: IRoute[];
  token: string | null;
  userInfo: IUserInfo | null;
  error: IError | null;
}

const initialSessionState: ISessionState = {
  loginState: LoginState.Init,
  routes: [],
  token: null,
  userInfo: null,
  error: null
};

const session = (state: ISessionState = initialSessionState, action: SessionAction): ISessionState => {
  switch (action.type) {
    case SessionActionType.LOGIN_STARTED:
      return {
        ...state,
        ...initialSessionState,
        loginState: LoginState.Pending
      };
    case SessionActionType.LOGIN_SUCCESS:
      return {
        ...state,
        loginState: LoginState.Success,
        token: action.token,
        userInfo: action.userInfo,
        routes: action.routes,
        error: null
      };
    case SessionActionType.LOGIN_ERROR:
      return {
        ...state,
        ...initialSessionState,
        loginState: LoginState.Error,
        error: action.error
      };
    case SessionActionType.USER_INFO_UPDATED:
      return {
        ...state,
        routes: action.routes,
        userInfo: action.userInfo
      };
    case SessionActionType.UPDATE_IS_STRIPE_SETUP_COMPLETE:
      return {
        ...state,
        userInfo: { ...state.userInfo!, isStripeSetupComplete: action.isStripeSetupComplete }
      };
    case SessionActionType.UPDATE_IS_REAUTHORIZED_REQUIRED:
      return {
        ...state,
        userInfo: { ...state.userInfo!, isReauthorizedRequired: action.isReauthorizedRequired }
      };
    case SessionActionType.UPDATE_PENDING_BOOKINGS_COUNT:
      return {
        ...state,
        userInfo: {
          ...state.userInfo!,
          pendingBookingsCount: action.atLeast
            ? Math.max(action.pendingBookingsCount, state.userInfo!.pendingBookingsCount || 0)
            : action.pendingBookingsCount
        }
      };
    case SessionActionType.DECREMENT_PENDING_BOOKINGS_COUNT:
      return {
        ...state,
        userInfo: { ...state.userInfo!, pendingBookingsCount: state.userInfo!.pendingBookingsCount - 1 }
      };

    default:
      return state;
  }
};

export default session;
