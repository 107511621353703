import IIConProps, { initializeIconProps } from "components/Icons/IIconProps";
import React from "react";

const Close: React.FC<IIConProps & { className?: string; onClick?: () => void }> = rawProps => {
  const props = initializeIconProps(rawProps);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox={`${props.minX} ${props.minY} ${props.size} ${props.size}`}
      className={rawProps.className}
      onClick={rawProps.onClick}
    >
      <defs>
        <filter id="a" width="107.4%" height="110%" x="-3.7%" y="-5%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <path
        fill={props.color}
        fillRule="nonzero"
        d="M8 6.232l4.116-4.116a1.25 1.25 0 0 1 1.768 1.768L9.768 8l4.116 4.116a1.25 1.25 0 0 1-1.768 1.768L8 9.768l-4.116 4.116a1.25 1.25 0 0 1-1.768-1.768L6.232 8 2.116 3.884a1.25 1.25 0 0 1 1.768-1.768L8 6.232z"
        filter="url(#a)"
      />
    </svg>
  );
};

export default Close;
