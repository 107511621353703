import { IAddressPrediction } from "@zenfolio/core-components";
import { IAddressQuery, IPlaceDetails } from "@zenfolio/core-components/dist/models/location";
import { Dispatch } from "redux";
import { IApiServices } from "../../api";
import { ICoordinates } from "../../components/addressSelection/map";
import IError from "../../utilities/error";
import { IAppState } from "../state";
import { ActionType } from "./constants";

interface ISearchAddressClearPredictions {
  type: ActionType.SEARCH_ADDRESS_CLEAR_PREDICTIONS;
}

interface ISearchAddressReset {
  type: ActionType.SEARCH_ADDRESS_RESET;
}

interface ISearchAddressStarted {
  type: ActionType.SEARCH_ADDRESS_STARTED;
  query: IAddressQuery;
  seamless: boolean;
}

interface ISearchAddressSuccess {
  type: ActionType.SEARCH_ADDRESS_SUCCESS;
  query: IAddressQuery;
  predictions: IAddressPrediction[];
}

interface ISearchAddressError {
  type: ActionType.SEARCH_ADDRESS_ERROR;
  query: IAddressQuery;
  error: IError;
}

export type SearchAddressAction =
  | ISearchAddressReset
  | ISearchAddressClearPredictions
  | ISearchAddressStarted
  | ISearchAddressSuccess
  | ISearchAddressError;

const searchAddressStarted = (query: IAddressQuery, seamless: boolean): ISearchAddressStarted => ({
  type: ActionType.SEARCH_ADDRESS_STARTED,
  query,
  seamless
});

const searchAddressSuccess = (query: IAddressQuery, predictions: IAddressPrediction[]): ISearchAddressSuccess => ({
  type: ActionType.SEARCH_ADDRESS_SUCCESS,
  query,
  predictions
});

const searchAddressError = (query: IAddressQuery, error: IError): ISearchAddressError => ({
  type: ActionType.SEARCH_ADDRESS_ERROR,
  query,
  error
});

export const searchAddress = (query: IAddressQuery, seamless: boolean = false) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(searchAddressStarted(query, seamless));
    try {
      const predictions = await apiService.location.searchAddress(query);
      dispatch(searchAddressSuccess(query, predictions));
    } catch (error) {
      dispatch(searchAddressError(query, error));
    }
  };
};

export const resetSearchAddress = () => ({ type: ActionType.SEARCH_ADDRESS_RESET });

export const clearSearchAddressPredictions = () => ({ type: ActionType.SEARCH_ADDRESS_CLEAR_PREDICTIONS });

interface ILoadPlaceDetailsStarted {
  type: ActionType.LOAD_PLACE_DETAILS_STARTED;
  placeId: string;
}

interface ILoadPlaceDetailsSuccess {
  type: ActionType.LOAD_PLACE_DETAILS_SUCCESS;
  placeId: string;
  placeDetails: IPlaceDetails;
}

interface ILoadPlaceDetailsError {
  type: ActionType.LOAD_PLACE_DETAILS_ERROR;
  placeId: string;
  error: IError;
}

export type LoadPlaceDetailsAction = ILoadPlaceDetailsStarted | ILoadPlaceDetailsSuccess | ILoadPlaceDetailsError;

const loadPlaceDetailsStarted = (placeId: string): ILoadPlaceDetailsStarted => ({
  type: ActionType.LOAD_PLACE_DETAILS_STARTED,
  placeId
});

const loadPlaceDetailsSuccess = (placeId: string, placeDetails: IPlaceDetails): ILoadPlaceDetailsSuccess => ({
  type: ActionType.LOAD_PLACE_DETAILS_SUCCESS,
  placeId,
  placeDetails
});

const loadPlaceDetailsError = (placeId: string, error: IError): ILoadPlaceDetailsError => ({
  type: ActionType.LOAD_PLACE_DETAILS_ERROR,
  placeId,
  error
});

export const loadPlaceDetails = (placeId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadPlaceDetailsStarted(placeId));
    try {
      const placeDetails = await apiService.location.loadPlaceDetails(placeId);
      dispatch(loadPlaceDetailsSuccess(placeId, placeDetails));
    } catch (error) {
      dispatch(loadPlaceDetailsError(placeId, error));
    }
  };
};

export interface ILoadPlaceDetailsFromLocationOrIpReset {
  type: ActionType.LOAD_PLACE_DETAILS_RESET;
}

interface ILoadPlaceDetailsFromLocationStarted {
  type: ActionType.LOAD_PLACE_DETAILS_FROM_LOCATION_STARTED;
}

interface ILoadPlaceDetailsFromLocationSuccess {
  type: ActionType.LOAD_PLACE_DETAILS_FROM_LOCATION_SUCCESS;
  placeDetails: IPlaceDetails;
}

interface ILoadPlaceDetailsFromLocationError {
  type: ActionType.LOAD_PLACE_DETAILS_FROM_LOCATION_ERROR;
  error: IError;
}

export type LoadPlaceDetailsFromLocationAction =
  | ILoadPlaceDetailsFromLocationStarted
  | ILoadPlaceDetailsFromLocationSuccess
  | ILoadPlaceDetailsFromLocationError;

export const doLoadPlaceDetailsFromLocationOrIpReset = (): ILoadPlaceDetailsFromLocationOrIpReset => ({
  type: ActionType.LOAD_PLACE_DETAILS_RESET
});

const loadPlaceDetailsFromLocationStarted = (): ILoadPlaceDetailsFromLocationStarted => ({
  type: ActionType.LOAD_PLACE_DETAILS_FROM_LOCATION_STARTED
});

const loadPlaceDetailsFromLocationSuccess = (placeDetails: IPlaceDetails): ILoadPlaceDetailsFromLocationSuccess => ({
  type: ActionType.LOAD_PLACE_DETAILS_FROM_LOCATION_SUCCESS,
  placeDetails
});

const loadPlaceDetailsFromLocationError = (error: IError): ILoadPlaceDetailsFromLocationError => ({
  type: ActionType.LOAD_PLACE_DETAILS_FROM_LOCATION_ERROR,
  error
});

export const doLoadPlaceDetailsFromLocation = (location: ICoordinates) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadPlaceDetailsFromLocationStarted());
    try {
      const placeDetails = await apiService.location.loadPlaceDetailsFromLocation(location);
      dispatch(loadPlaceDetailsFromLocationSuccess(placeDetails));
    } catch (error) {
      dispatch(loadPlaceDetailsFromLocationError(error));
    }
  };
};

interface ILoadPlaceDetailsFromIpStarted {
  type: ActionType.LOAD_PLACE_DETAILS_FROM_IP_STARTED;
}

interface ILoadPlaceDetailsFromIpSuccess {
  type: ActionType.LOAD_PLACE_DETAILS_FROM_IP_SUCCESS;
  placeDetails: IPlaceDetails;
}

interface ILoadPlaceDetailsFromIpError {
  type: ActionType.LOAD_PLACE_DETAILS_FROM_IP_ERROR;
  error: IError;
}

export type LoadPlaceDetailsFromIpAction =
  | ILoadPlaceDetailsFromIpStarted
  | ILoadPlaceDetailsFromIpSuccess
  | ILoadPlaceDetailsFromIpError;

const loadPlaceDetailsFromIpStarted = (): ILoadPlaceDetailsFromIpStarted => ({
  type: ActionType.LOAD_PLACE_DETAILS_FROM_IP_STARTED
});

const loadPlaceDetailsFromIpSuccess = (placeDetails: IPlaceDetails): ILoadPlaceDetailsFromIpSuccess => ({
  type: ActionType.LOAD_PLACE_DETAILS_FROM_IP_SUCCESS,
  placeDetails
});

const loadPlaceDetailsFromIpError = (error: IError): ILoadPlaceDetailsFromIpError => ({
  type: ActionType.LOAD_PLACE_DETAILS_FROM_IP_ERROR,
  error
});

export const doLoadPlaceDetailsFromIp = () => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadPlaceDetailsFromIpStarted());
    try {
      const placeDetails = await apiService.location.loadPlaceDetailsFromIp();
      dispatch(loadPlaceDetailsFromIpSuccess(placeDetails));
    } catch (error) {
      dispatch(loadPlaceDetailsFromIpError(error));
    }
  };
};
