import pluralize from "pluralize";
import React from "react";
import styles from "./viewsCounter.module.scss";

export interface IViewsCounterProps {
  count?: number;
}

const ViewsCounter = (props: IViewsCounterProps) => {
  const { count } = props;

  return (
    <div className={styles.viewsCounterContainer}>
      <span>{pluralize("view", count ?? 0, true)}</span>
    </div>
  );
};

export default ViewsCounter;
