import round from "lodash/round";
import React from "react";
import { ICroppingData } from "../../components/CropperEditor";

export interface IAutoDetectOrientationProps {
  /** Original product size width (inch) */
  productWidth: number;
  /** Original product size height (inch) */
  productHeight: number;
  /** Preview Photo height in pixel (this is used for auto orientation).
   */
  previewPhotoHeight: number;
  /** Preview Photo width in pixel (this is used for auto orientation).
   */
  previewPhotoWidth: number;
  /** Result from auto detect orientation */
  isLandscape?: boolean;
  /** Cropping Data */
  croppingData?: {
    cropping?: ICroppingData;
    /** Zoom scales */
    zoomScale: number;
    /** Photo rotation degree */
    rotate: number;
  };
  deletectOrientationBaseOnProduct?: boolean;
}
/**
 * Auto Detect orientation of photo
 * @param Component
 */
export function withAutoDetectOrientation<T extends IAutoDetectOrientationProps>(Component: React.ComponentType<T>) {
  return class extends React.Component<T> {
    get hasCroppingInfo(): boolean {
      const { croppingData } = this.props;
      return !!croppingData && (croppingData.cropping?.width || 0) > 0 && (croppingData.cropping?.height || 0) > 0;
    }
    get photoWidth(): number {
      const { previewPhotoWidth, croppingData } = this.props;

      return this.hasCroppingInfo ? previewPhotoWidth * (croppingData?.cropping?.width || 0) : previewPhotoWidth;
    }

    get photoHeight(): number {
      const { previewPhotoHeight, croppingData } = this.props;

      return this.hasCroppingInfo ? previewPhotoHeight * (croppingData?.cropping?.height || 0) : previewPhotoHeight;
    }
    public adjustOrientationBasedOnPhoto = () => {
      const { productHeight: originProductHeight, productWidth: originProductWidth } = this.props;

      const isLandscape = this.props.deletectOrientationBaseOnProduct
        ? round(originProductWidth) >= round(originProductHeight)
        : this.photoWidth > this.photoHeight;
      let productHeight = originProductHeight;
      let productWidth = originProductWidth;

      if (isLandscape) {
        productWidth = Math.max(originProductHeight, originProductWidth);
        productHeight = Math.min(originProductHeight, originProductWidth);
      } else {
        productWidth = Math.min(originProductHeight, originProductWidth);
        productHeight = Math.max(originProductHeight, originProductWidth);
      }
      return {
        productWidth,
        productHeight,
        isLandscape
      };
    };

    public render() {
      const { productWidth, productHeight, isLandscape } = this.adjustOrientationBasedOnPhoto();
      return (
        <Component
          {...(this.props as T)}
          productHeight={productHeight}
          productWidth={productWidth}
          isLandscape={isLandscape}
        />
      );
    }
  };
}
