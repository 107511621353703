import { IProfileActivationState } from "../../store/profile/state/model";
import { apiType } from "../apiType";
import { apiClient } from "../index";

export interface ILoadActivationPagesStateResponse extends IProfileActivationState {}

export interface IUpdateActivationPagesStateRequest {
  isEnabled: boolean;
}

export interface IApiActivationPages {
  loadActivationPagesState: () => Promise<ILoadActivationPagesStateResponse>;
  updateActivationPagesState: (request: IUpdateActivationPagesStateRequest) => Promise<void>;
}

const api: IApiActivationPages = {
  loadActivationPagesState: () =>
    apiClient.get<ILoadActivationPagesStateResponse>(`${apiType.bookingWidget}photographer/activation`),
  updateActivationPagesState: (request: IUpdateActivationPagesStateRequest) =>
    apiClient.put<void>(`${apiType.bookingWidget}photographer/activation`, request)
};

export default api;
