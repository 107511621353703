import { IPhotoMasonry, IPhotoNameProps, NameType, NameVisibility } from "components/blocks/blockModels";
import { Utilities } from "utilities/utilities";
import { isClientSideRendering } from "./site";

export const getImageAspectRatio = (image: any) => {
  if (image.height && image.width) {
    return image.height / image.width;
  }
  return 0;
};

export const universalWindow = isClientSideRendering
  ? (window as any)
  : {
      innerWidth: 1024,
      addEventListener: (name: string, callback: any) => false
    };

// get image width of each image on row where row heigh it is same for all images and
// aspect ratio of all images images is kept for each column , formulas where obtained after
// linear ecuations were solved for 1, 2 ,3 and 4 columns.
// ----
// 1) case 2 columns:
// AspectRation1 = H1 /W1 , AspectRation2 = H2 /W2 , W1 + W1 = 100%
// ----
// 2) case 3 columns:
// AspectRation1 = H1 /W1 , AspectRation2 = H2 /W2 , W1 + W1 = 100%
// AspectRation3 = H3 /W3 , AspectRation2 = H2 /W2 , W1 + W1 + W3 = 100%
// ----
// 3) case 4 columns:
// AspectRation1 = H1 /W1 , AspectRation2 = H2 /W2 , W1 + W1 = 100%
// AspectRation3 = H3 /W3 , AspectRation2 = H2 /W2 , W1 + W1 + W3 = 100%
// AspectRation4 = H4 /W4 , AspectRation3 = H3 /W3 , W1 + W1 + W3 + W4 = 100%

export const getImageWidth = (
  currentImage: any,
  rowAspectRatioSum: number,
  rowAspectRatio: number[],
  indexColumn: number,
  itemsPerRow: number
) => {
  let result = `${(getImageAspectRatio(currentImage) / rowAspectRatioSum) * 100}%`;

  if (rowAspectRatio.length === 1) {
    result = `${100 / itemsPerRow}%`;
  } else if (rowAspectRatio.length === 2) {
    if (indexColumn === 0) {
      result = `${(100 * rowAspectRatio[1]) / (rowAspectRatio[0] + rowAspectRatio[1])}%`;
    }

    if (indexColumn === 1) {
      let res0 = (100 * rowAspectRatio[1]) / (rowAspectRatio[0] + rowAspectRatio[1]);
      result = `${100 - res0}%`;
    }
  } else if (rowAspectRatio.length === 3) {
    if (indexColumn === 0) {
      result = `${(100 * rowAspectRatio[1] * rowAspectRatio[2]) /
        (rowAspectRatio[1] * rowAspectRatio[2] +
          rowAspectRatio[0] * rowAspectRatio[2] +
          rowAspectRatio[1] * rowAspectRatio[0])}%`;
    }

    if (indexColumn === 1) {
      result = `${(100 * rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[0]) /
        (rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[1] +
          rowAspectRatio[0] * rowAspectRatio[2] * rowAspectRatio[1] +
          rowAspectRatio[1] * rowAspectRatio[0] * rowAspectRatio[1])}%`;
    }

    if (indexColumn === 2) {
      let res0 =
        (100 * rowAspectRatio[1] * rowAspectRatio[2]) /
        (rowAspectRatio[1] * rowAspectRatio[2] +
          rowAspectRatio[0] * rowAspectRatio[2] +
          rowAspectRatio[1] * rowAspectRatio[0]);

      let res1 =
        (100 * rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[0]) /
        (rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[1] +
          rowAspectRatio[0] * rowAspectRatio[2] * rowAspectRatio[1] +
          rowAspectRatio[1] * rowAspectRatio[0] * rowAspectRatio[1]);

      result = `${100 - res0 - res1}%`;
    }
  } else if (rowAspectRatio.length === 4) {
    if (indexColumn === 0) {
      result = `${(100 * rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3]) /
        (rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3] +
          rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[0] +
          rowAspectRatio[1] * rowAspectRatio[3] * rowAspectRatio[0] +
          rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[0])}%`;
    }

    if (indexColumn === 1) {
      result = `${(100 * rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[0]) /
        (rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[1] +
          rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[0] * rowAspectRatio[1] +
          rowAspectRatio[1] * rowAspectRatio[3] * rowAspectRatio[0] * rowAspectRatio[1] +
          rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[0] * rowAspectRatio[1])}%`;
    }

    if (indexColumn === 2) {
      result = `${(100 * rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[0]) /
        (rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[2] +
          rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[0] * rowAspectRatio[2] +
          rowAspectRatio[1] * rowAspectRatio[3] * rowAspectRatio[0] * rowAspectRatio[2] +
          rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[0] * rowAspectRatio[2])}%`;
    }

    if (indexColumn === 3) {
      let res0 =
        (100 * rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3]) /
        (rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3] +
          rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[0] +
          rowAspectRatio[1] * rowAspectRatio[3] * rowAspectRatio[0] +
          rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[0]);

      let res1 =
        (100 * rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[0]) /
        (rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[1] +
          rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[0] * rowAspectRatio[1] +
          rowAspectRatio[1] * rowAspectRatio[3] * rowAspectRatio[0] * rowAspectRatio[1] +
          rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[0] * rowAspectRatio[1]);

      let res2 =
        (100 * rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[0]) /
        (rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[2] +
          rowAspectRatio[2] * rowAspectRatio[3] * rowAspectRatio[0] * rowAspectRatio[2] +
          rowAspectRatio[1] * rowAspectRatio[3] * rowAspectRatio[0] * rowAspectRatio[2] +
          rowAspectRatio[1] * rowAspectRatio[2] * rowAspectRatio[0] * rowAspectRatio[2]);

      result = `${100 - res0 - res1 - res2}%`;
    }
  }

  return result;
};

export const getNameProps = (
  image: IPhotoMasonry,
  idx: number,
  length: number,
  showNames?: NameType,
  namesVisibility?: NameVisibility
): IPhotoNameProps | undefined => {
  if (!showNames || showNames === "none") {
    return undefined;
  }

  return {
    name: showNames === "sequenceNumber" ? Utilities.addLeadingZeros(idx + 1, length) : image.name || image.fileName!,
    nameAlignment: showNames === "fileName" && image.name && image.name !== image.fileName ? "left" : "right",
    nameVisibility: namesVisibility,
    isVideo: image.isVideo || !!image.video
  };
};
