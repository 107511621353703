import cx from "classnames";
import { FormTextInput } from "components/FormTextInput";
import Popover from "components/Popover";
import { isValidURL, urlWithHttp } from "components/TextEditor/utils/withLinks";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Editor, Range, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import IconLink from "../../../assets/svg/link";
import { isBlockActive } from "../../../common/transforms";
import { ToolbarButton } from "../../../components/toolbar-button";
import { BLOCK_LINK } from "../../../core/types";
import styles from "./button-link.module.scss";

interface OwnProps {
  popoverContentClassName?: string;
}

type Props = OwnProps;

const ButtonLink: FunctionComponent<Props> = props => {
  const editor = useSlate();
  const [value, setValue] = useState({
    editable: true,
    text: "",
    link: ""
  });
  const [mark, setMark] = useState<any>({
    selection: null,
    isCollapsed: false
  });
  const [visible, setVisible] = useState(false);

  const toggleLink = () => {
    const active = isBlockActive(editor, BLOCK_LINK);
    if (active) {
      Transforms.unwrapNodes(editor, {
        match: n => n.type === BLOCK_LINK
      });
    } else {
      const { selection } = editor;
      const isCollapsed = !selection || Range.isCollapsed(selection);
      setMark({
        selection,
        isCollapsed
      });
      setValue({
        editable: !!isCollapsed,
        text: "",
        link: ""
      });
      setVisible(true);
    }
  };

  const onInsert = (value: InputValue) => {
    const { selection, isCollapsed } = mark;
    const link = {
      type: BLOCK_LINK,
      url: urlWithHttp(value.link),
      children: isCollapsed ? [{ text: value.text }] : []
    };

    ReactEditor.focus(editor);
    selection && Transforms.select(editor, selection);

    if (isCollapsed) {
      Transforms.insertNodes(editor, link, selection ? { at: selection } : undefined);

      // move cursor to the end of the link after insertion
      setTimeout(() => {
        try {
          const path = ReactEditor.findPath(editor, link);
          Transforms.select(editor, Editor.end(editor, path));
        } catch (e) {}
      }, 0);
    } else if (selection) {
      Transforms.wrapNodes(editor, link, { split: true, at: selection });
      Transforms.collapse(editor, { edge: "end" });
    }

    setVisible(false);
  };

  const renderButton = (ref: React.Ref<HTMLElement>) => {
    return (
      <ToolbarButton ref={ref} onMouseDown={toggleLink} active={isBlockActive(editor, BLOCK_LINK)}>
        <IconLink />
      </ToolbarButton>
    );
  };

  return (
    <Popover
      className={styles.popover}
      content={{ className: cx(styles.content, props.popoverContentClassName) }}
      visible={visible}
      onVisibleChange={setVisible}
      renderHandle={renderButton}
    >
      {visible && <Input {...value} onSave={onInsert} />}
    </Popover>
  );
};

interface InputValue {
  text: string;
  link: string;
}

interface InputProps {
  editable: boolean;
  text: string;
  link: string;
  onSave: (value: InputValue) => void;
}

const Input: React.FC<InputProps> = props => {
  const { editable, onSave } = props;

  const inputRef = useRef<any>();

  const [link, setLink] = useState(props.link || "");
  const [text, setText] = useState((editable && props.text) || "");

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const isValid = link && (!editable || text) && isValidURL(link);

  const handleSave = () => {
    if (isValid) {
      onSave({ text, link });
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave();
    }
  };

  return (
    <div className={styles.container}>
      {editable && (
        <FormTextInput
          ref={inputRef}
          placeholder="Text"
          value={text}
          onChange={setText}
          onKeyDown={onKeyDown}
          className={styles.input}
        />
      )}
      <FormTextInput
        ref={!editable ? inputRef : undefined}
        placeholder="URL"
        value={link}
        onChange={setLink}
        onKeyDown={onKeyDown}
        className={styles.input}
      />
      <button className={styles.save} disabled={!isValid} onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export { ButtonLink };
