import _ from "lodash";
import videojs from "video.js";
import "videojs-contrib-quality-levels";
import { QualityLevel, QualityLevelList } from "videojs-contrib-quality-levels";
import { getQualityName, getQualityValue } from "../utils";
import Menu from "./Menu";
import { log, sessionStorageKey } from "./utils";

const Plugin = videojs.getPlugin("plugin");

export type QualityPreset = "auto" | "best";
export type Quality = QualityLevel | "auto";

export interface IQualityOptions {
  /**
   * Specifies the quality that should be forced, which also means not allowing manual quality selection at all.
   * Overrides all other options. Not specified by default.
   */
  force?: QualityPreset;
  /**
   * Specifies the default quality. Session-wide quality takes precedence if *useSession* is enabled.
   * *"auto"* by default.
   */
  default?: QualityPreset;
  /**
   * Specifies whether the default quality should be taken from the session, and last selected quality saved
   * in the session. *true* by default.
   */
  useSession?: boolean;
}

export interface IQualitySelectorPluginOptions extends IQualityOptions {}

class QualitySelectorPlugin extends Plugin {
  private static applyDefaults(options: IQualitySelectorPluginOptions): IQualitySelectorPluginOptions {
    const defaults: IQualitySelectorPluginOptions = {
      force: undefined,
      default: "auto",
      useSession: true
    };
    return _.defaults({}, options, defaults);
  }

  constructor(player: videojs.Player, options: IQualitySelectorPluginOptions) {
    super(player);
    this.qualityOptions = QualitySelectorPlugin.applyDefaults(options);

    if (!this.qualityOptions.force && this.qualityOptions.useSession) {
      const sessionQualityString = sessionStorage.getItem(sessionStorageKey);
      const sessionQualityValue = parseInt(sessionQualityString || "", 10);
      const sessionQuality =
        sessionQualityString === "auto" ? "auto" : isNaN(sessionQualityValue) ? undefined : sessionQualityValue;
      this.storedQuality = sessionQuality;
    }

    if (!this.qualityOptions.force) {
      player.on("ready", () => {
        const anchor =
          player.controlBar.getChild("PictureInPictureToggle") ?? player.controlBar.getChild("FullscreenToggle");

        const menu = (this.menu = new Menu(player));
        menu.on("qualityselect", (event: any) => this.onMenuQualitySelect(event.quality));

        player.controlBar.addChild(
          menu,
          {},
          (anchor ? player.controlBar.children().indexOf(anchor) : player.controlBar.children().length) - 1
        );
      });
    }

    const qualityLevels = player.qualityLevels();
    qualityLevels.on("change", () => {
      log("Quality Level changed to", getQualityName(qualityLevels[qualityLevels.selectedIndex]));
    });
    qualityLevels.on(
      "addqualitylevel",
      _.debounce(() => this.onQualityAdd(), 0)
    );
  }

  private get qualityList(): QualityLevelList {
    return this.player.qualityLevels();
  }

  private get qualities(): QualityLevel[] {
    return _.chain(this.qualityList)
      .filter(q => !!q.height && !!q.width)
      .orderBy([getQualityValue, q => q.bitrate], ["desc", "desc"])
      .uniqBy(getQualityValue)
      .value();
  }

  private get selectedQuality(): Quality {
    const { storedQuality } = this;

    if (storedQuality === undefined) {
      const quality = this.qualityOptions.force ?? this.qualityOptions.default!;
      if (quality === "best") {
        return _.chain(this.qualities)
          .orderBy(getQualityValue, "desc")
          .first()
          .value();
      } else {
        return "auto";
      }
    }

    return storedQuality === "auto"
      ? "auto"
      : _.chain(this.qualities)
          .orderBy(getQualityValue, "desc")
          .find(q => getQualityValue(q) <= storedQuality)
          .value();
  }

  private onQualityAdd() {
    this.applyQuality();
    this.menu?.setQualities(["auto", ...this.qualities]);
    this.menu?.selectQuality(this.selectedQuality);
  }

  private onMenuQualitySelect(quality: Quality) {
    this.storeQuality(quality);
    this.applyQuality();
    this.menu!.selectQuality(quality);
  }

  private storeQuality(quality: Quality) {
    const value = quality === "auto" ? "auto" : getQualityValue(quality);
    this.storedQuality = value;
    if (this.qualityOptions.useSession) {
      sessionStorage.setItem(sessionStorageKey, value.toString());
    }
  }

  private applyQuality() {
    const { selectedQuality, qualityList } = this;

    _.forEach(qualityList, q => {
      q.enabled = selectedQuality === "auto" ? true : q === selectedQuality;
    });
  }

  private menu?: Menu;
  private storedQuality?: number | "auto";
  private readonly qualityOptions: IQualityOptions;
}

videojs.registerPlugin("qualitySelectorPlugin", QualitySelectorPlugin);

declare module "video.js" {
  export interface VideoJsPlayer {
    qualitySelectorPlugin(options?: IQualitySelectorPluginOptions): QualitySelectorPlugin;
  }
}

export default QualitySelectorPlugin;
