import React from "react";
import styles from "./zenButtonTitleBlock.module.scss";
import cx from "classnames";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import { IRoutes, ILink } from "../../../models/models";
import { onLinkClick, getInteractionUrlType } from "utilities/blocks/blockInteractions";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import ContextMenu from "utilities/contextMenu";
import ZenButton from "components/blocks/zenButton";
import { getSiteFonts } from "utilities/blocks/site";
import ZenBookmeButton from "../zenBookmeButton";
import { LinkTypeOption } from "utilities/constant";
import { parseBody, renderLineBreaks } from "utilities/blocks/string";

export interface IButtonTitleBlockProps extends IZenBaseBlockProps {
  titleText?: string;
  titlePosition?: string;
  verticalAlign?: string;
  blockText?: string | undefined;
  isPublish?: boolean;
  link?: ILink;
  pages?: any;
  routes?: IRoutes;
  bookmeUrl?: string;
  disabled?: boolean;
  isDarkBackground?: boolean;
  isEditionView?: boolean;
  onTextChange?: (data: any) => void;
  getInteractionUrl?: getInteractionUrlType;
}

interface IButtonTitleBlockState {
  containerWidth: number;
}

const BRAKE_MOBILE = 450;
const BRAKE_TABLES = 975;
const RECTANGLE_RATIO = 4 / 3;

export class ZenButtonTitleBlock extends React.Component<IButtonTitleBlockProps, IButtonTitleBlockState> {
  public static defaultProps = {
    size: 0
  };

  private Container: any;

  constructor(props: IButtonTitleBlockProps) {
    super(props);
    this.Container = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      containerWidth: 1096
    };
  }

  public componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    // required to adjust view for mobile after first render
    this.updateWindowDimensions();

    // sometimes in web editor more time is needed to correctly get the container size
    setTimeout(() => {
      this.updateWindowDimensions();
    }, 300);
  }

  public UNSAFE_componentWillMount() {
    this.updateWindowDimensions();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public updateWindowDimensions = () => {
    if (this.Container && this.Container.current) {
      this.setState({ containerWidth: this.Container.current.offsetWidth });
    }
  };

  public handleButtonClick = () => {
    const { readOnly, pages, routes, link, isEditionView, getInteractionUrl } = this.props;

    const pagesObj = pages ? pages : null;

    if (readOnly && pagesObj && !isEditionView) {
      onLinkClick(
        pages,
        undefined,
        undefined,
        undefined,
        undefined,
        routes ? routes : {},
        link,
        this.props.isPublish,
        getInteractionUrl
      );
    }
  };

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      layout,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      fontFamily,
      divider,
      padding,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      titlePosition,
      isDarkBackground,
      fullWidth,
      disabled,
      link,
      blockText,
      verticalAlign
    } = this.props;
    const { containerWidth } = this.state;
    const labelText = this.props.link?.label || "BOOK";
    const parsedBody = blockText && parseBody(blockText);
    let newSize = layout === "A" || layout === "C" ? 375 : 575;
    const titleText = parseBody(this.props.titleText || "");

    if (containerWidth <= BRAKE_MOBILE && containerWidth > 0) {
      newSize = containerWidth;
    }
    if (containerWidth <= BRAKE_TABLES && containerWidth > BRAKE_MOBILE) {
      newSize = containerWidth / 2;
    }

    const blockTextColor = getBlockTextColor(
      backgroundType === "none" && backgroundColor ? siteTheme.backgroundColor.value : backgroundColor,
      siteTheme.accentColor.value,
      backgroundType,
      siteTheme.backgroundColor.value
    );

    const titleClass = cx(styles.title);
    const titleStyle = { color: siteTheme.accentColor.value };

    const blockClass = cx(styles.block);
    const blockStyle = { color: blockTextColor };
    const btnContainerClass = cx(styles.btnContainer);
    const btnContainerStyle = { color: blockTextColor };

    let containerClass = styles.zenContainer;
    if (layout) {
      containerClass = cx(containerClass, layout === "A" ? styles.layoutA : styles.layoutB);
    }

    if (containerWidth <= BRAKE_MOBILE) {
      containerClass = cx(containerClass, styles.zenMobile);
    }

    if (verticalAlign && verticalAlign === "top") {
      containerClass = cx(containerClass, styles.alignTop);
    }
    if (verticalAlign && verticalAlign === "bottom") {
      containerClass = cx(containerClass, styles.alignBottom);
    }
    if (verticalAlign && verticalAlign === "center") {
      containerClass = cx(containerClass, styles.alignCenter);
    }
    const titlesClass = cx(styles.titles, layout === "A" ? styles.layoutA : styles.layoutB);
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    const baseButtonProps = {
      isDarkBackground,
      disabled,
      accentColor: siteTheme.accentColor,
      siteTheme,
      layout,
      labelText: link?.label || labelText
    };

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        readOnly={readOnly}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        layout={""}
        selectedBlock={selectedBlock}
        fullWidth={fullWidth}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <div ref={this.Container} className={containerClass}>
            <div
              onClick={this.handleButtonClick}
              className={styles.picture}
              onContextMenu={ContextMenu.handleBlockContextMenu}
            />
            <div
              className={cx(styles.textBlock, styles[siteFonts.primary], {
                [styles.textRight]: alignment === "right",
                [styles.textCenter]: alignment === "center",
                [styles.textLeft]: alignment === "left"
              })}
            >
              <div className={titlesClass}>
                <div
                  className={cx(titleClass, styles[siteFonts.primaryExtra], { [styles.titleClickable]: !readOnly })}
                  style={titleStyle}
                >
                  <span>{titleText ? titleText : "Contact Me"}</span>
                </div>
                {parsedBody && (
                  <div
                    className={cx(blockClass, styles[siteFonts.secondary], { [styles.blockClickable]: !readOnly })}
                    style={blockStyle}
                  >
                    <span>{renderLineBreaks(parsedBody, true)}</span>
                  </div>
                )}
              </div>
              <div className={btnContainerClass} style={btnContainerStyle}>
                <div className={cx(styles.textEditor, styles[siteFonts.secondary])} style={siteFonts.buttonStyles}>
                  {link?.type === LinkTypeOption.BOOKME ? (
                    <ZenBookmeButton {...baseButtonProps} bookmeUrl={link?.bookmeUrl! || this.props.bookmeUrl!} />
                  ) : (
                    <ZenButton
                      {...baseButtonProps}
                      className={styles.button}
                      labelText={labelText}
                      onClick={this.handleButtonClick}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}
