import React, { createRef } from "react";
import styles from "./zenContactMeBlock.module.scss";
import cx from "classnames";
import Textarea from "react-expanding-textarea";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Validation } from "utilities/validation";
import { ISelectDropdownOptions } from "../../SelectDropdown";
import SelectDropdown from "./selectDropdown";
import PhoneFormGroup from "./phoneFormGroup";
import ReCaptcha from "../../ReCatpcha";
import {
  getFontColor,
  getBackgroundColor,
  getOptionsColor,
  getPlaceholderColor,
  getBlockTextColor
} from "utilities/blocks/blockColors";

import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import { parseBody, renderLineBreaks } from "utilities/blocks/string";
import ZenButton from "components/blocks/zenButton";
import colors from "utilities/colors";
import { getSiteFonts } from "utilities/blocks/site";

export interface IContactMeBlockProps extends IZenBaseBlockProps {
  contactMeTitle: string;
  contactMeBody: string;
  confirmationTitle: string;
  confirmationBody: string;
  buttonText: string;
  imageAlignment: string;
  shootType: ISelectDropdownOptions;
  shootTypeList: string;
  discover: ISelectDropdownOptions;
  discoverList: string;
  subdomain?: string;
  onButtonClick?: (formData: any, onSuccess: () => void, onError: (error: any) => void) => void;
  showThankYou?: boolean;
  international?: boolean;
  defaultCountry?: string;
  sendTo?: string;
}

interface IContactMeBlockState {
  name: string;
  email: string;
  phoneNumber: string;
  comment: string;
  nameError: string;
  emailError: string;
  phoneNumberError: string;
  commentError: string;
  shootType: ISelectDropdownOptions;
  discover: ISelectDropdownOptions;
  showThankYou: boolean;
  shootTypeIsOpen: boolean;
  discoverIsOpen: boolean;
  containerWidth: number;
}

export class ZenContactMeBlock extends React.Component<IContactMeBlockProps, IContactMeBlockState> {
  constructor(props: IContactMeBlockProps) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comment: "",
      nameError: "",
      emailError: "",
      commentError: "",
      phoneNumberError: "",
      shootType: { value: "", label: "" },
      discover: { value: "", label: "" },
      showThankYou: false,
      shootTypeIsOpen: false,
      discoverIsOpen: false,
      containerWidth: 1096
    };
  }

  private divRef = createRef<HTMLDivElement>();
  private containerRef = createRef<HTMLDivElement>();
  private reCaptchaRef = createRef<any>();

  private onChangeShootType = (shootType: ISelectDropdownOptions): void => {
    this.setState({ shootType });
  };

  private onChangeDiscover = (discover: ISelectDropdownOptions): void => {
    this.setState({ discover });
  };

  private handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value });
  };

  private handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  private handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ comment: e.target.value });
  };

  private handleNameBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.checkNameEmpty();
  };

  private checkNameEmpty = () => {
    if (this.state.name === "") {
      this.setState({ nameError: "Please enter your name" });
      return;
    }

    this.setState({ nameError: "" });
  };

  private handleEmailBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.checkEmailEmpty();

    if (!Validation.isValidMail(this.state.email.trim())) {
      this.setState({ emailError: "Please enter a valid email" });
      return;
    } else {
      const emailArray = this.state.email.split("@");
      if (emailArray[0].length > 64) {
        this.setState({ emailError: "Please enter a valid email" });
        return;
      }

      if (emailArray[1].length > 255) {
        this.setState({ emailError: "Please enter a valid email" });
        return;
      }
    }

    this.setState({ emailError: "" });
  };

  private checkEmailEmpty = () => {
    if (this.state.email === "") {
      this.setState({ emailError: "Please enter your email" });
      return;
    }
  };

  private handlePhoneChange = (value: string) => {
    this.setState({ phoneNumber: value });
  };

  private handleCommentBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.checkCommentEmpty();
  };

  private checkCommentEmpty = () => {
    if (this.state.comment === "") {
      this.setState({ commentError: "Please enter your comment" });
      return;
    }

    this.setState({ commentError: "" });
  };

  private handlePhoneBlur = () => {
    const { phoneNumber } = this.state;
    if (phoneNumber === "" || phoneNumber === undefined || phoneNumber === null) {
      this.setState({ phoneNumberError: "" });
    } else if (!isValidPhoneNumber(phoneNumber)) {
      this.setState({ phoneNumberError: "Please enter a valid phone number" });
    } else {
      this.setState({ phoneNumberError: "" });
    }
  };

  private getFormData = async () => {
    const data: any = {
      name: this.state.name,
      email: this.state.email,
      comment: this.state.comment,
      extraInformation: {},
      sendToEmail: this.props.sendTo,
      captchaToken: ""
    };

    if (this.state.shootType.value !== "") {
      data.extraInformation.shootType = this.state.shootType.value;
    }
    if (this.state.discover.value !== "") {
      data.extraInformation.discover = this.state.discover.value;
    }

    if (this.state.phoneNumber !== "" && this.state.phoneNumber !== undefined && this.state.phoneNumber !== null) {
      data.extraInformation.number = this.state.phoneNumber;
    }

    if (this.reCaptchaRef?.current) {
      data.captchaToken = await this.reCaptchaRef.current.getToken();
    }

    return data;
  };

  private updateWindowDimensions = () => {
    if (this.containerRef && this.containerRef.current) {
      this.setState({ containerWidth: this.containerRef.current.offsetWidth });
    }
  };

  private handleButtonClick = async (isButtonDisabled: boolean) => {
    const { onButtonClick } = this.props;
    if (onButtonClick && !isButtonDisabled) {
      onButtonClick(
        await this.getFormData(),
        () =>
          this.setState({ showThankYou: true }, () => {
            if (this.divRef.current) {
              this.divRef.current.scrollIntoView({
                block: "center",
                behavior: "smooth"
              });
            }
          }),
        (error: any) => console.log(error)
      );
    } else if (isButtonDisabled) {
      this.checkCommentEmpty();
      this.checkEmailEmpty();
      this.checkNameEmpty();
    }
  };

  private handleBeforeUnload = (e: any) => {
    const { name, email, phoneNumber, comment, shootType, discover } = this.state;
    if (
      !this.state.showThankYou &&
      (name !== "" ||
        email !== "" ||
        (phoneNumber !== "" && phoneNumber !== undefined) ||
        comment !== "" ||
        shootType.value !== "" ||
        discover.value !== "")
    ) {
      return "You are about to leave this page without sending your question. Are you sure you want to leave?";
    }
    return undefined;
  };

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public componentDidMount() {
    if (this.props.readOnly) {
      window.onbeforeunload = this.handleBeforeUnload;
    }

    window.addEventListener("resize", this.updateWindowDimensions);
    // required to adjust view for mobile after first render
    this.updateWindowDimensions();

    // sometimes in web editor more time is needed to correctly get the container size
    setTimeout(() => {
      this.updateWindowDimensions();
    }, 300);
  }

  public componentDidUpdate(prevProps: IContactMeBlockProps) {
    if (prevProps.shootTypeList !== this.props.shootTypeList && this.state.shootType.value !== "") {
      const shootTypesDropDown: ISelectDropdownOptions[] = this.props.shootTypeList.split("\n").map(item => {
        return {
          label: item,
          value: item
        };
      });
      if (shootTypesDropDown.find(item => item.value === this.state.shootType.value) === undefined) {
        this.setState({ shootType: { value: "", label: "" } });
      }
    }

    if (prevProps.discoverList !== this.props.discoverList && this.state.discover.value !== "") {
      const discoverDropDown: ISelectDropdownOptions[] = this.props.discoverList.split("\n").map(item => {
        return {
          label: item,
          value: item
        };
      });
      if (discoverDropDown.find(item => item.value === this.state.discover.value) === undefined) {
        this.setState({ discover: { value: "", label: "" } });
      }
    }
  }

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      fontFamily,
      divider,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      padding,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      contactMeBody,
      imageAlignment,
      shootType,
      discover,
      confirmationBody,
      international,
      defaultCountry
    } = this.props;
    const {
      name,
      email,
      phoneNumber,
      comment,
      nameError,
      emailError,
      commentError,
      phoneNumberError,
      showThankYou,
      containerWidth
    } = this.state;

    const contactMeTitle = parseBody(this.props.contactMeTitle || "");
    const confirmationTitle = parseBody(this.props.confirmationTitle || "");
    const buttonText = parseBody(this.props.buttonText || "");
    const shootTypeList = parseBody(this.props.shootTypeList || "");
    const discoverList = parseBody(this.props.discoverList || "");
    const parsedContactMeBody = parseBody(contactMeBody);
    const parsedConfirmationBody = parseBody(confirmationBody);
    const bgColor = siteTheme.backgroundColor.value;
    const isDarkBackground: boolean = bgColor === colors.inputDark || bgColor === colors.black;

    const isButtonDisabled =
      name === "" || email === "" || comment === "" || nameError !== "" || emailError !== "" || commentError !== "";

    const buttonContainerClass = cx(
      styles.formGroup,
      styles.buttonContainer,
      imageAlignment === "left" ? styles.leftAlign : imageAlignment === "right" ? styles.rightAlign : styles.centerAlign
    );

    let containerClass = "";

    const layout = this.props.layout === "D" && !contactMeTitle && !contactMeBody ? "A" : this.props.layout;

    if (!showThankYou) {
      if (layout === "B") {
        containerClass = styles.layoutB;
      } else if (layout === "C") {
        containerClass = styles.layoutC;
      } else if (layout === "D") {
        containerClass = styles.layoutD;
      }
    }

    const BRAKE_MOBILE = 450;
    const BRAKE_TABLES = 975;

    let newSize = 375;

    if (containerWidth <= BRAKE_MOBILE && containerWidth > 0) {
      newSize = containerWidth;
    }
    if (containerWidth <= BRAKE_TABLES && containerWidth > BRAKE_MOBILE) {
      newSize = containerWidth / 2;
    }

    const leftStyles: React.CSSProperties = layout === "D" && !showThankYou ? { maxWidth: `${newSize}px` } : {};

    const shootTypesDropDown: ISelectDropdownOptions[] = shootTypeList.split("\n").map(item => {
      return {
        label: item,
        value: item
      };
    });

    const discoverDropDown: ISelectDropdownOptions[] = discoverList.split("\n").map(item => {
      return {
        label: item,
        value: item
      };
    });
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);
    const isRenderReCaptchaControl = process.env.REACT_APP_ENABLE_RECAPTCHA === "true" || false;

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        readOnly={readOnly}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        layout={""}
        selectedBlock={selectedBlock}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <style>
            {`
              :root {
                --ddl-background: ${getBackgroundColor(siteTheme.backgroundColor.value)};
                --option-color: ${getOptionsColor(siteTheme.backgroundColor.value)};
                --cb-placeholder: ${getPlaceholderColor(siteTheme.backgroundColor.value)};
                --font-color: ${getFontColor(siteTheme.backgroundColor.value)};
              }
              .grecaptcha-badge {
                z-index: 99999999999999999;
              }
            `}
          </style>

          <div ref={this.containerRef} className={cx(styles.zenContainer, containerClass)}>
            <div className={layout === "D" && !showThankYou ? styles.left : ""} style={leftStyles}>
              <div
                ref={this.divRef}
                className={cx(styles.title, styles.margin, styles[siteFonts.primary], styles[siteFonts.primaryExtra], {
                  [styles.layoutD]: layout === "D" && !showThankYou,
                  [styles.titleClickable]: !readOnly
                })}
                style={{ color: siteTheme.accentColor.value }}
              >
                {showThankYou ? confirmationTitle : contactMeTitle}
              </div>
              <div
                className={cx(styles.subtitle, styles.margin, styles[siteFonts.secondary], {
                  [styles.layoutD]: layout === "D" && !showThankYou,
                  [styles.subtitleClickable]: !readOnly
                })}
                style={{
                  color: getBlockTextColor(
                    backgroundColor,
                    siteTheme.accentColor.value,
                    backgroundType,
                    siteTheme.backgroundColor.value
                  )
                }}
              >
                {showThankYou
                  ? parsedConfirmationBody
                    ? renderLineBreaks(parsedConfirmationBody)
                    : null
                  : parsedContactMeBody
                  ? renderLineBreaks(parsedContactMeBody)
                  : null}
              </div>
            </div>
            {!showThankYou && (
              <div
                className={cx(styles.form, layout === "D" ? styles.right : "")}
                style={{
                  backgroundColor: siteTheme.backgroundColor.value
                }}
                onClick={this.handlePhoneBlur}
              >
                <div className={styles.formGroup}>
                  <div className={cx(styles.label)} style={{ color: getFontColor(siteTheme.backgroundColor.value) }}>
                    NAME *
                  </div>
                  <input
                    className={cx(
                      nameError !== "" ? styles.error : styles.noBorder,
                      styles[siteFonts.secondary],
                      styles.hintColor
                    )}
                    placeholder="Your name"
                    maxLength={30}
                    value={name}
                    onChange={this.handleNameChange}
                    onBlur={this.handleNameBlur}
                    onFocus={this.handlePhoneBlur}
                  />
                  <div className={cx(styles.errorMsg, styles[siteFonts.secondary])}>{nameError}</div>
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.label} style={{ color: getFontColor(siteTheme.backgroundColor.value) }}>
                    EMAIL *
                  </div>
                  <input
                    className={cx(emailError !== "" ? styles.error : styles.noBorder, styles[siteFonts.secondary])}
                    placeholder="Your e-mail"
                    value={email}
                    maxLength={320}
                    onChange={this.handleEmailChange}
                    onBlur={this.handleEmailBlur}
                    onFocus={this.handlePhoneBlur}
                  />
                  <div className={cx(styles.errorMsg, styles[siteFonts.secondary])}>{emailError}</div>
                </div>
                <PhoneFormGroup
                  getFontColor={() => getFontColor(siteTheme.backgroundColor.value)}
                  handlePhoneBlur={this.handlePhoneBlur}
                  phoneNumber={phoneNumber}
                  phoneNumberError={phoneNumberError}
                  phoneInputClassName={styles[siteFonts.secondary]}
                  international={international}
                  defaultCountry={defaultCountry}
                  onChangeNumber={this.handlePhoneChange}
                />
                {shootType && (
                  <div className={styles.formGroup}>
                    <div className={styles.label} style={{ color: getFontColor(siteTheme.backgroundColor.value) }}>
                      WHAT KIND OF SHOOT TYPE ARE YOU INTERESTED IN?
                    </div>
                    <SelectDropdown
                      className={cx(styles.dropdown, styles[siteFonts.secondary], {
                        [styles.shootTypesClickable]: !readOnly
                      })}
                      options={shootTypesDropDown}
                      onChange={this.onChangeShootType}
                      optionColor={getOptionsColor(siteTheme.backgroundColor.value)}
                      background={getBackgroundColor(siteTheme.backgroundColor.value)}
                      placeholderColor={getPlaceholderColor(siteTheme.backgroundColor.value)}
                      placeholder="Select an option"
                      value={this.state.shootType}
                      onFocus={this.handlePhoneBlur}
                      fullWidth={layout !== "A"}
                    />
                  </div>
                )}
                {discover && (
                  <div className={styles.formGroup}>
                    <div className={styles.label} style={{ color: getFontColor(siteTheme.backgroundColor.value) }}>
                      HOW DID YOU DISCOVER ME?
                    </div>
                    <SelectDropdown
                      className={cx(styles.dropdown, styles[siteFonts.secondary], {
                        [styles.discoverClickable]: !readOnly
                      })}
                      options={discoverDropDown}
                      onChange={this.onChangeDiscover}
                      optionColor={getOptionsColor(siteTheme.backgroundColor.value)}
                      background={getBackgroundColor(siteTheme.backgroundColor.value)}
                      placeholderColor={getPlaceholderColor(siteTheme.backgroundColor.value)}
                      placeholder="Select an option"
                      value={this.state.discover}
                      onFocus={this.handlePhoneBlur}
                      fullWidth={layout !== "A"}
                    />
                  </div>
                )}
                <div className={styles.formGroup}>
                  <div className={styles.label} style={{ color: getFontColor(siteTheme.backgroundColor.value) }}>
                    COMMENT *
                  </div>
                  <Textarea
                    className={cx(
                      styles.textArea,
                      commentError !== "" ? styles.error : styles.noBorder,
                      styles[siteFonts.secondary]
                    )}
                    placeholder="Your message"
                    maxLength={500}
                    value={comment}
                    onChange={this.handleCommentChange}
                    onBlur={this.handleCommentBlur}
                    onFocus={this.handlePhoneBlur}
                  />
                  <div className={cx(styles.errorMsg, styles[siteFonts.secondary])}>{commentError}</div>
                </div>
                <ReCaptcha ref={this.reCaptchaRef} isEnabled={isRenderReCaptchaControl}></ReCaptcha>
                <div className={buttonContainerClass}>
                  <ZenButton
                    className={!readOnly ? styles.buttonClickable : ""}
                    onClick={() => this.handleButtonClick(isButtonDisabled)}
                    disabled={isButtonDisabled}
                    isDarkBackground={isDarkBackground}
                    siteTheme={siteTheme}
                    layout={"A"}
                    labelText={buttonText}
                  />
                </div>
              </div>
            )}
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}
