import { Button } from "@zenfolio/core-components";
import classNames from "classnames";
import _ from "lodash";
import * as React from "react";
import Scrollbars from "react-custom-scrollbars";
import { ShootTypeSection } from "../../store/shootTypes/actions";
import { IShootType } from "../../store/shootTypes/model";
import Auxiliary from "../../utilities/auxiliary";
import { hideTrackHorizontal } from "../../utilities/helpers";
import styles from "./index.module.scss";

interface IShootTypesProps {
  shootTypes: IShootType[][];
  activeShootType: IShootType | undefined;
  onChangeShootType: (shootType: IShootType) => void;
  onBlur?: () => void;
}

interface IShootTypesState {
  visible: boolean;
  activeShootType: IShootType | undefined;
}

class ShootTypes extends React.Component<IShootTypesProps, IShootTypesState> {
  constructor(props: IShootTypesProps) {
    super(props);
    this.state = {
      visible: false,
      activeShootType: props.activeShootType
    };
  }

  public componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  public componentDidUpdate(prevProps: IShootTypesProps) {
    if (prevProps.activeShootType !== this.props.activeShootType) {
      this.setState({
        activeShootType: this.props.activeShootType
      });
    }
  }

  public render() {
    const { visible, activeShootType } = this.state;
    const { shootTypes } = this.props;
    return (
      <div ref={this.containerRef} className={styles.container}>
        <Button
          type="button"
          styleType={activeShootType ? "primary" : "borderedMedium"}
          className={classNames(styles.button, styles.main, activeShootType && styles.active)}
          onClick={this.onToggleShootTypeDialog}
        >
          {activeShootType ? activeShootType.name : "Select"}
        </Button>
        {visible && (
          <div className={styles.wrapper}>
            <Scrollbars renderTrackHorizontal={hideTrackHorizontal}>
              {!_.isEmpty(shootTypes[ShootTypeSection.Custom]) && (
                <Auxiliary>
                  <h4 className={styles.title}>Your shoot types</h4>
                  {this.renderSection(ShootTypeSection.Custom)}
                </Auxiliary>
              )}
              <h4 className={styles.title}>Default shoot types</h4>
              {this.renderSection(ShootTypeSection.Default)}
            </Scrollbars>
          </div>
        )}
      </div>
    );
  }

  private renderSection = (section: ShootTypeSection) => {
    const { shootTypes } = this.props;
    const { activeShootType } = this.state;

    return (
      <div className={styles.shootTypes}>
        {shootTypes[section].map((shootType, index) => {
          const onSelectShootType = () => this.onSelectShootType(shootType);
          return (
            <Button
              key={index}
              type="button"
              styleType={shootType.id === activeShootType?.id ? "primary" : "borderedMedium"}
              className={classNames(styles.button, shootType.id === activeShootType?.id && styles.active)}
              onClick={onSelectShootType}
            >
              {shootType.name}
            </Button>
          );
        })}
      </div>
    );
  };

  private onSelectShootType = (activeShootType: IShootType) => {
    this.setState(
      {
        visible: false,
        activeShootType
      },
      () => this.props.onChangeShootType(activeShootType)
    );
  };
  public handleClickOutside = (event: any) => {
    if (this.state.visible && this.containerRef && !this.containerRef.current!.contains(event.target)) {
      this.setState(
        {
          visible: false
        },
        () => {
          if (this.props.onBlur) {
            this.props.onBlur();
          }
        }
      );
    }
  };

  private onToggleShootTypeDialog = () => {
    if (this.props.shootTypes.length > 0) {
      this.setState({
        visible: !this.state.visible
      });
    }
  };

  private readonly containerRef = React.createRef<HTMLDivElement>();
}

export default ShootTypes;
