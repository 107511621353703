import classNames from "classnames";
import * as React from "react";
import checked from "./checked.svg";
import styles from "./index.module.scss";

interface IFormCheckboxProps {
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  tabIndex?: number;
  children?: React.ReactNode;
}

export class FormCheckbox extends React.Component<IFormCheckboxProps> {
  public render() {
    const { className, children, onChange, tabIndex, ...attrs } = this.props;
    const labelClasses = classNames(className, styles.label);

    return (
      <div className={styles.container}>
        <label className={labelClasses}>
          <input {...attrs} type="checkbox" checked={this.props.checked} onChange={onChange} tabIndex={tabIndex} />
          <span aria-hidden="true">{this.props.checked && <img src={checked} alt="checked icon" />}</span>
          <div>{children}</div>
        </label>
      </div>
    );
  }
}
