import { Button, FormTextInput, PhoneNumber, Validation } from "@zenfolio/core-components";
import classNames from "classnames";
import * as React from "react";
import { Col, Row } from "react-flexbox-grid";
import Helmet from "react-helmet";
import { IContactInfo } from "../../../store/profile/contactInfo/model";
import eventTracker from "../../../utilities/eventTracker";
import { hasActionCompleted } from "../../../utilities/helpers";
import Loader from "../../layout/loader";
import { IContactInformation } from "../model";
import styles from "./index.module.scss";

export interface IContactInformationProps {
  className?: string;
  contactInfo: IContactInformation;
}

interface IContactInformationState {
  info: IContactInfo;
}

class ContactInformation extends React.Component<IContactInformationProps, IContactInformationState> {
  public state: IContactInformationState = { info: ContactInformation.defaults.info };

  public componentDidMount() {
    const { contactInfo } = this.props;
    contactInfo.onLoad();
  }

  public componentDidUpdate(prevProps: IContactInformationProps) {
    const { contactInfo } = this.props;

    const loadContactInfoHasCompleted = hasActionCompleted(prevProps.contactInfo.loadStatus, contactInfo.loadStatus);

    if (loadContactInfoHasCompleted) {
      this.setState({
        info: contactInfo.info
      });
    }
  }

  public render() {
    const { className, contactInfo } = this.props;
    const { firstName, lastName, email, businessName, phoneNumber } = this.state.info;

    const updatePending = contactInfo.updateStatus === "Pending";

    return (
      <div>
        <div className={classNames(styles.container, className)}>
          <Helmet title="Contact Information" />
          <div className={styles.description}>
            Verify the information below is correct. We’ve pulled this information from your Zenfolio account. Any
            changes made here won’t reflect on your Zenfolio account, this information is just for BookMe.
          </div>

          {contactInfo.loadStatus === "Success" && (
            <div className={styles.form}>
              <Row>
                <Col xs={6}>
                  <div className={styles.field}>
                    <label>First Name</label>
                    <FormTextInput
                      value={firstName}
                      placeholder="First Name"
                      onChange={this.onFirstNameChange}
                      styles={{ height: 50, paddingLeft: 16 }}
                      maxLength={120}
                      errorMessage={this.firstNameErrorMessage}
                    />
                  </div>
                </Col>
                <Col xs={6}>
                  <div className={styles.field}>
                    <label>Last Name</label>
                    <FormTextInput
                      value={lastName}
                      placeholder="Last Name"
                      onChange={this.onLastNameChange}
                      styles={{ height: 50, paddingLeft: 16 }}
                      maxLength={120}
                      errorMessage={this.lastNameErrorMessage}
                    />
                  </div>
                </Col>

                <Col xs={12}>
                  <div className={styles.field}>
                    <label>Email</label>
                    <FormTextInput
                      value={email}
                      placeholder="Email"
                      onChange={this.onEmailChange}
                      styles={{ height: 50, paddingLeft: 16 }}
                      maxLength={Validation.maxEmailTotalLength}
                      errorMessage={this.emailErrorMessage}
                    />
                  </div>
                </Col>

                <Col xs={12}>
                  <div className={styles.field}>
                    <label>Business Name</label>
                    <FormTextInput
                      value={businessName || ""}
                      placeholder="Business Name"
                      onChange={this.onBusinessNameChange}
                      styles={{ height: 50, paddingLeft: 16 }}
                      maxLength={120}
                      errorMessage={this.businessNameErrorMessage}
                    />
                  </div>
                </Col>

                <Col xs={12}>
                  <div className={styles.field}>
                    <label>Phone Number</label>
                    <PhoneNumber
                      value={phoneNumber}
                      placeholder="Phone Number"
                      onChange={this.onPhoneNumberChange}
                      styles={{ height: 50, paddingLeft: 16 }}
                      maxLength={Validation.maxPhoneNumberLength}
                      errorMessage={this.phoneNumberErrorMessage}
                    />
                  </div>
                </Col>
              </Row>

              <Button
                type="button"
                styleType="primary"
                onClick={this.onSaveClick}
                height={50}
                disabled={!this.isValid || updatePending}
                styles={{ width: 295, marginTop: 25, height: 50 }}
              >
                Save Changes
              </Button>
            </div>
          )}
          {contactInfo.loadStatus === "Pending" && <Loader />}
        </div>
      </div>
    );
  }

  private get firstNameErrorMessage() {
    return Validation.errorIfEmpty(this.state.info.firstName, "Please provide a first name.");
  }

  private get lastNameErrorMessage() {
    return Validation.errorIfEmpty(this.state.info.lastName, "Please provide a last name.");
  }

  private get emailErrorMessage() {
    return (
      Validation.errorIfEmpty(this.state.info.email, "Please provide an email address.") ||
      (!Validation.isValidEmail(this.state.info.email) ? "Verify your email address." : "")
    );
  }

  private get businessNameErrorMessage() {
    return Validation.errorIfEmpty(this.state.info.businessName, "Please provide a business name.");
  }

  private get phoneNumberErrorMessage() {
    return (
      Validation.errorIfEmpty(this.state.info.phoneNumber, "Please provide a phone number.") ||
      (!Validation.isValidPhoneNumber(this.state.info.phoneNumber) ? "Verify your phone number." : "")
    );
  }

  private get isValid() {
    return (
      !this.firstNameErrorMessage &&
      !this.lastNameErrorMessage &&
      !this.emailErrorMessage &&
      !this.businessNameErrorMessage &&
      !this.phoneNumberErrorMessage
    );
  }

  private onFirstNameChange = (value: string) => {
    this.setState({
      info: {
        ...this.state.info,
        firstName: value
      }
    });
  };

  private onLastNameChange = (value: string) => {
    this.setState({
      info: {
        ...this.state.info,
        lastName: value
      }
    });
  };

  private onEmailChange = (value: string) => {
    this.setState({
      info: {
        ...this.state.info,
        email: value
      }
    });
  };

  private onBusinessNameChange = (value: string) => {
    this.setState({
      info: {
        ...this.state.info,
        businessName: value
      }
    });
  };

  private onPhoneNumberChange = (value: string) => {
    this.setState({
      info: {
        ...this.state.info,
        phoneNumber: value
      }
    });
  };

  private onSaveClick = () => {
    if (this.isValid) {
      eventTracker.events.photographer.profile.contactInfoUpdated();
      this.props.contactInfo.onUpdate(this.state.info);
    }
  };

  public static defaults = {
    info: {
      firstName: "",
      lastName: "",
      email: "",
      businessName: "",
      phoneNumber: ""
    }
  };
}

export default ContactInformation;
