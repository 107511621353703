export enum SessionActionType {
  LOGIN_STARTED = "LOGIN_STARTED",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",

  USER_INFO_UPDATED = "USER_INFO_UPDATED",
  UPDATE_PENDING_BOOKINGS_COUNT = "SESSION_UPDATE_PENDING_BOOKINGS_COUNT",
  DECREMENT_PENDING_BOOKINGS_COUNT = "SESSION_DECREMENT_PENDING_BOOKINGS_COUNT",
  UPDATE_IS_STRIPE_SETUP_COMPLETE = "SESSION_UPDATE_IS_STRIPE_SETUP_COMPLETE",
  UPDATE_IS_REAUTHORIZED_REQUIRED = "SESSION_UPDATE_IS_REAUTHORIZED_REQUIRED"
}
