import React, { useState, useEffect } from "react";

import { ICollectionOptions, COLLECTION } from "./../../models";
import IconHide from "./../Views/icons/icon-eye-disabled";
import cx from "classnames";
import styles from "./collections.module.scss";
import { ScrollableNav } from "components/Navigation/ScrollableNav";
import { IZenSiteTheme } from "../../../../models/models";
import { getSiteFonts } from "utilities/blocks/site";

export interface ICollectionsProps {
  collectionsOptions?: ICollectionOptions[];
  accentColor: string;
  lineColor: string;
  iconMoreOptions: string;
  iconRename: string;
  isEditor?: boolean;
  defaultActiveIndex?: number;
  isDisplayFavorite?: boolean;
  onOptionSelected?: (collectionId: string) => void;
  hoverColor?: string;
  reducedContent?: boolean;
  bgColor?: string;
  siteTheme?: IZenSiteTheme;
}

const Collections: React.FC<ICollectionsProps> = props => {
  const [optionSelected, setOptionSelected] = useState(
    props.collectionsOptions ? props.collectionsOptions[props.defaultActiveIndex || 0].collectionId : null
  );

  useEffect(() => {
    const activeOption = props.collectionsOptions
      ? props.collectionsOptions[props.defaultActiveIndex || 0].collectionId
      : null;
    setOptionSelected(activeOption);
  }, [props.defaultActiveIndex, props.collectionsOptions]);

  const handleClickOption = (collectionId: string) => {
    setOptionSelected(collectionId);
    if (props.onOptionSelected) {
      props.onOptionSelected(collectionId);
    }
  };

  let fontSecondary: string;
  if (props.siteTheme) {
    const siteFonts = getSiteFonts(props.siteTheme.fontsStyle);
    fontSecondary = styles[siteFonts.secondary];
  }

  return (
    <div
      style={{ borderColor: props.lineColor }}
      className={cx(styles.layout, {
        [styles.displayNone]: props.collectionsOptions?.length === 1,
        [styles.pointerEventsNone]: props.isEditor
      })}
    >
      <style>
        {`
          :root {
            --collection-hover-color: ${props.hoverColor || props.accentColor};
            --collection-menu-max-width: ${props.reducedContent ? "1176px" : "1270px"};
            }
        `}
      </style>
      <ScrollableNav className={styles.galleryNames} bgColor={props.bgColor}>
        <div className={styles.wrapper}>
          <ul className={styles.galleryNameList}>
            {props.collectionsOptions?.map((option: ICollectionOptions, index: number) => {
              return (
                <li
                  key={`collection-${index}`}
                  className={cx(styles.galleryNameItem, {
                    [styles.isFavorite]: props.isDisplayFavorite && option.collectionId === COLLECTION.FAVORITE_MEDIA_ID
                  })}
                  onClick={() => {
                    handleClickOption(option.collectionId);
                  }}
                >
                  <span className={cx(styles.galleryLabel, fontSecondary)} style={{ color: props.accentColor }}>
                    <span className={cx(styles.labelIcon, styles.displayNone)}>
                      <IconHide color={props.accentColor} />
                    </span>{" "}
                    <span
                      style={{ borderColor: props.accentColor }}
                      className={cx(styles.label, { [styles.selected]: optionSelected === option?.collectionId })}
                    >
                      {option.name}
                    </span>
                  </span>
                  <div className={cx(styles.galleryLabelEdit, styles.displayNone)}>
                    <div className={styles.galleryLabelOptions}>
                      <img src={props.iconMoreOptions} width="13" />
                    </div>
                    <div className={styles.galleryLabelEditMenu}>
                      <div className={styles.arrowBox} />
                      <ul>
                        <li>
                          <a href="#">
                            <span>
                              <img src={props.iconRename} width="24" />
                            </span>
                            Rename
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <span>
                              <IconHide color={props.accentColor} />
                            </span>
                            Unhide
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </ScrollableNav>
    </div>
  );
};

export default Collections;
