import React from "react";

const Widget = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="229" height="136" viewBox="0 0 229 136">
      <defs>
        <filter id="prefix__c" width="100.7%" height="100.8%" x="-.4%" y="-.3%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.198765851 0" />
        </filter>
        <rect id="prefix__b" width="1406" height="1319" x="14" y="98" rx="5" />
        <path id="prefix__a" d="M0 0H1478V2708H0z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-575 -952)">
        <path fill="#FFF" d="M0 0H1440V1478H0z" />
        <g transform="translate(-22 81)">
          <use fill="#F8F7F7" href="#prefix__a" />
        </g>
        <use fill="#000" filter="url(#prefix__c)" href="#prefix__b" />
        <use fill="#FFF" href="#prefix__b" />
        <g>
          <text fill="#757575" fontSize="16" transform="translate(413 956)">
            <tspan x="24" y="16">
              Primary Shoot Location
            </tspan>
          </text>
          <g transform="translate(413 956) translate(0 31)">
            <text fill="#757575" fontSize="16">
              <tspan x="24" y="29">
                The client’s location{" "}
              </tspan>
            </text>
            <text fill="#757575" fontSize="16">
              <tspan x="254" y="29">
                miles from Primary Shoot Location.
              </tspan>
            </text>
            <rect width="72" height="49" x="176.5" y=".5" fill="#FFF" stroke="#CCC" rx="5" />
          </g>
        </g>
        <g>
          <path
            fill="#383C42"
            fillOpacity=".95"
            d="M307 0c2.761 0 5 2.239 5 5v288c0 2.761-2.239 5-5 5H19c-2.761 0-5-2.239-5-5v-12.363c-.603-.066-1.19-.315-1.672-.747L1.77 270.43c-1.234-1.106-1.338-3.002-.232-4.236.073-.082.15-.16.232-.233l10.56-9.46c.481-.432 1.068-.68 1.67-.747L14 5c0-2.761 2.239-5 5-5h288z"
            transform="translate(533 810)"
          />
          <g transform="translate(533 810) translate(42 142)">
            <rect width="228" height="135" x=".375" y=".375" fill="#FFF" stroke="#FF5A00" strokeWidth=".75" rx="3.75" />
            <text fill="gray" fontSize="9">
              <tspan x="10.5" y="29.25">
                300
              </tspan>
            </text>
            <text fill="#757575" fontSize="7.5">
              <tspan x="6" y="29">
                $
              </tspan>
            </text>
            <text fill="#757575" fontSize="9">
              <tspan x="32.989" y="29.25">
                2 hr
              </tspan>
            </text>
            <text fill="#000" fontSize="10.5">
              <tspan x="27" y="67.25">
                Three outfit changes
              </tspan>
            </text>
            <path
              fill="#FF5A00"
              d="M19.014 60.234c.282-.298.756-.313 1.057-.035l-.012-.012c.301.278.32.747.041 1.045l-4.511 4.837c-.373.4-.97.392-1.332-.017l-2.068-2.332c-.272-.306-.247-.78.054-1.059l-.013.012c.301-.278.766-.257 1.039.049l1.397 1.567c.137.153.362.156.502.008l3.846-4.063z"
            />
            <text fill="#000" fontSize="10.5">
              <tspan x="27" y="47">
                Includes 30 beautifully edited photos
              </tspan>
            </text>
            <path
              fill="#FF5A00"
              d="M19.014 39.984c.282-.298.756-.313 1.057-.035l-.012-.012c.301.278.32.747.041 1.045l-4.511 4.837c-.373.4-.97.392-1.332-.017l-2.068-2.332c-.272-.306-.247-.78.054-1.059l-.013.012c.301-.278.766-.257 1.039.049l1.397 1.567c.137.153.362.156.502.008l3.846-4.063z"
            />
            <text fill="#000" fontSize="10.5">
              <tspan x="27" y="87.5">
                Hair and makeup
              </tspan>
            </text>
            <text fill="#000" fontSize="10.5">
              <tspan x="27" y="109.25">
                Up to 2 additional subjects can be{" "}
              </tspan>{" "}
              <tspan x="27" y="125">
                included in the shoot
              </tspan>
            </text>
            <path
              fill="#FF5A00"
              d="M19.014 80.484c.282-.298.756-.313 1.057-.035l-.012-.012c.301.278.32.747.041 1.045l-4.511 4.837c-.373.4-.97.392-1.332-.017l-2.068-2.332c-.272-.306-.247-.78.054-1.059l-.013.012c.301-.278.766-.257 1.039.049l1.397 1.567c.137.153.362.156.502.008l3.846-4.063zM19.014 102.984c.282-.298.756-.313 1.057-.035l-.012-.012c.301.278.32.747.041 1.045l-4.511 4.837c-.373.4-.97.392-1.332-.017l-2.068-2.332c-.272-.306-.247-.78.054-1.059l-.013.012c.301-.278.766-.257 1.039.049l1.397 1.567c.137.153.362.156.502.008l3.846-4.063z"
            />
            <text fill="#000" fontSize="10.5" fontWeight="600">
              <tspan x="6" y="17">
                Senior Portrait - Gold Package
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Widget;
