import { FormCheckbox, Utilities } from "@zenfolio/core-components";
import React from "react";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import ModalDialog from "../../modalDialog";
import styles from "./index.module.scss";
import { IDeleteInvoice } from "./model";
import withStore from "./withStore";

export interface IDeleteInvoiceOwnProps {
  invoiceId: string;
  newRemainingAmount: number;
  onClose: () => void;
}

export interface IDeleteInvoiceProps extends IDeleteInvoiceOwnProps {
  deleteInvoice: IDeleteInvoice;
}

interface IDeleteInvoiceState {
  confirmed: boolean;
}

class DeleteInvoice extends React.Component<IDeleteInvoiceProps, IDeleteInvoiceState> {
  public state: IDeleteInvoiceState = { confirmed: false };

  public componentDidUpdate(prevProps: IDeleteInvoiceProps) {
    const { deleteInvoice, onClose } = this.props;
    const deleteInvoiceHasCompleted = hasActionCompleted(prevProps.deleteInvoice.status, deleteInvoice.status);
    if (deleteInvoiceHasCompleted) {
      onClose();
    }
  }

  public render() {
    const { newRemainingAmount, deleteInvoice } = this.props;
    const { confirmed } = this.state;

    return (
      <ModalDialog
        title={"DELETE INVOICE?"}
        okText="Confirm"
        cancelText="Cancel"
        okDisabled={!confirmed || deleteInvoice.status === "Pending"}
        onCancel={this.onCancel}
        onOk={this.onOk}
        className={styles.modal}
      >
        <div className={styles.container}>
          <div className={styles.message}>
            Are you sure you want to permanently delete this invoice? This cannot be reversed.
          </div>
          <div className={styles.warning}>
            <span className={styles.accent}>IMPORTANT:</span> Deleting this invoice will lower the Remaining Amount Due
            to ${Utilities.formatNumber(newRemainingAmount)}.
          </div>
          <FormCheckbox className={styles.confirm} checked={confirmed} onChange={this.onConfirmChange}>
            Yes, delete invoice
          </FormCheckbox>
        </div>
      </ModalDialog>
    );
  }

  private onConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ confirmed: event.target.checked });

  private onCancel = () => this.props.onClose();

  private onOk = () => this.props.deleteInvoice.onDelete(this.props.invoiceId);
}

export default withStore(DeleteInvoice);
