import React from "react";
import styles from "./paperPrint.module.scss";

interface IPaperPrintPhotoProps {
  printWidthInPixel: number;
  printHeightInPixel: number;
  matWidthInPixel: number;
  renderImage: JSX.Element;
  clipPathMpixMetalId: string;
  isInvalidRatio?: boolean;
  isInvalidResolution?: boolean;
  mpixMetalPrintClipPath?: JSX.Element;
}

interface IPaperPrintPhotoStates {
  printWidthInPixel: number;
  printHeightInPixel: number;
  matWidthInPixel: number;
  renderImage: JSX.Element;
  clipPathMpixMetalId: string;
}

class PaperPrintPhoto extends React.Component<IPaperPrintPhotoProps, IPaperPrintPhotoStates> {
  constructor(props: IPaperPrintPhotoProps) {
    super(props);

    this.state = {
      printWidthInPixel: this.props.printWidthInPixel,
      printHeightInPixel: this.props.printHeightInPixel,
      matWidthInPixel: this.props.matWidthInPixel,
      renderImage: this.props.renderImage,
      clipPathMpixMetalId: this.props.clipPathMpixMetalId
    };
  }
  private placeHolderRef = React.createRef<SVGSVGElement>();

  public componentDidUpdate(prevProps: IPaperPrintPhotoProps, prevState: IPaperPrintPhotoStates) {
    if (
      prevProps.printWidthInPixel !== this.props.printWidthInPixel ||
      prevProps.printHeightInPixel !== this.props.printHeightInPixel ||
      prevProps.matWidthInPixel !== this.props.matWidthInPixel ||
      prevProps.renderImage !== this.props.renderImage
    ) {
      this.setState({
        printWidthInPixel: this.props.printWidthInPixel,
        printHeightInPixel: this.props.printHeightInPixel,
        matWidthInPixel: this.props.matWidthInPixel,
        renderImage: this.props.renderImage,
        clipPathMpixMetalId: this.props.clipPathMpixMetalId
      });
    }
  }


  render() {
    const {
      isInvalidRatio,
      isInvalidResolution,
      matWidthInPixel,
      printWidthInPixel,
      printHeightInPixel,
      renderImage,
      mpixMetalPrintClipPath,
      clipPathMpixMetalId
    } = this.props;
    return (
      <svg
        ref={this.placeHolderRef}
        className={styles.placeHolder}
        x={matWidthInPixel}
        y={matWidthInPixel}
        viewBox={`0 0 ${printWidthInPixel} ${printHeightInPixel}`}
        width={printWidthInPixel}
        height={printHeightInPixel}
      >
        <defs>{mpixMetalPrintClipPath}</defs>
        <filter id="grayScaleFilter">
          <feColorMatrix type="saturate" values="0" />
        </filter>
        <g clipPath={`url(#${clipPathMpixMetalId}`}>{renderImage}</g>
        <rect width="100%" height="100%" fill={isInvalidRatio || isInvalidResolution ? "rgba(0, 0, 0, 0.4)" : "none"} />
      </svg>
    );
  }
}

export default PaperPrintPhoto;
