import React, { createRef } from "react";
import styles from "./zenClientLoginBlock.module.scss";
import cx from "classnames";
import { Validation } from "utilities/validation";
import {
  getFontColor,
  getBackgroundColor,
  getOptionsColor,
  getPlaceholderColor,
  getBlockTextColor
} from "utilities/blocks/blockColors";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks/index";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import { parseBody, renderLineBreaks } from "utilities/blocks/string";
import ZenButton from "components/blocks/zenButton";
import colors from "utilities/colors";
import { getSiteFonts } from "utilities/blocks/site";
import iconEyeDisabled from "../../../icons/icon-eye-disabled.svg";
import iconEyeEnabled from "../../../icons/icon-eye-enabled.svg";

export interface IClientLoginBlockProps extends IZenBaseBlockProps {
  title: string;
  body: string;
  buttonText: string;
  buttonAlignment: string;
  subdomain?: string;
  onButtonClick?: (formData: any, onSuccess: (cpUrl: string) => void, onError: (error: any) => void) => void;
}

interface IClientLoginBlockState {
  email: string;
  password: string;
  emailError: string;
  passwordError: string;
  containerWidth: number;
  loginError: string;
  passwordInputType: string;
  isLoading: boolean;
}

export class ZenClientLoginBlock extends React.Component<IClientLoginBlockProps, IClientLoginBlockState> {
  constructor(props: IClientLoginBlockProps) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      containerWidth: 1096,
      loginError: "",
      passwordInputType: "password",
      isLoading: false
    };
  }

  private divRef = createRef<HTMLDivElement>();
  private containerRef = createRef<HTMLDivElement>();

  private handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  private handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.target.value });
  };

  private handlePasswordBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.checkPasswordEmpty();
  };

  private checkPasswordEmpty = () => {
    if (this.state.password === "") {
      this.setState({ passwordError: "Please enter your password" });
      return;
    }

    this.setState({ passwordError: "", loginError: "" });
  };

  private handleEmailBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.checkEmailEmpty();

    if (!Validation.isValidMail(this.state.email.trim())) {
      this.setState({ emailError: "Please enter a valid email" });
      return;
    } else {
      const emailArray = this.state.email.split("@");
      if (emailArray[0].length > 64) {
        this.setState({ emailError: "Please enter a valid email" });
        return;
      }

      if (emailArray[1].length > 255) {
        this.setState({ emailError: "Please enter a valid email" });
        return;
      }
    }

    this.setState({ emailError: "", loginError: "" });
  };

  private checkEmailEmpty = () => {
    if (this.state.email === "") {
      this.setState({ emailError: "Please enter your email" });
      return;
    }
  };

  private getFormData = () => {
    const data: any = {
      email: this.state.email,
      password: this.state.password
    };

    return data;
  };

  private updateWindowDimensions = () => {
    if (this.containerRef && this.containerRef.current) {
      this.setState({ containerWidth: this.containerRef.current.offsetWidth });
    }
  };

  private handleButtonClick = (isButtonDisabled: boolean) => {
    const { onButtonClick } = this.props;
    const isSafariDevice = window.navigator.userAgent.includes("Safari") && !!window.navigator.maxTouchPoints;
    if (onButtonClick && !isButtonDisabled) {
      this.setState({ isLoading: true, loginError: "" });
      onButtonClick(
        this.getFormData(),
        (cpUrl: string) => {
          if (isSafariDevice) {
            window.location.assign(cpUrl);
          } else {
            window.open(cpUrl, "_blank");
          }
          this.setState({ loginError: "", isLoading: false });
        },
        (error: any) => this.setState({ loginError: error, isLoading: false })
      );
    } else if (isButtonDisabled) {
      this.checkEmailEmpty();
      this.checkPasswordEmpty();
    }
  };

  private togglePasswordInputType = () => {
    const { passwordInputType } = this.state;
    this.setState({
      passwordInputType: passwordInputType === "password" ? "text" : "password"
    });
  };

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    // required to adjust view for mobile after first render
    this.updateWindowDimensions();

    // sometimes in web editor more time is needed to correctly get the container size
    setTimeout(() => {
      this.updateWindowDimensions();
    }, 300);
  }

  public render() {
    const {
      readOnly,
      alignment,
      siteTheme,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      fontFamily,
      divider,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      padding,
      animationScrollIn,
      animationScrollOut,
      selectedBlock,
      body,
      buttonAlignment,
      layout
    } = this.props;
    const {
      password,
      email,
      passwordError,
      emailError,
      containerWidth,
      loginError,
      passwordInputType,
      isLoading
    } = this.state;

    const title = parseBody(this.props.title || "");
    const buttonText = parseBody(this.props.buttonText || "");
    const parsedBody = parseBody(body);
    const bgColor = siteTheme.backgroundColor.value;
    const isDarkBackground: boolean = bgColor === colors.inputDark || bgColor === colors.black;

    const isButtonDisabled = password === "" || email === "" || passwordError !== "" || emailError !== "";

    const buttonContainerClass = cx(
      styles.formGroup,
      styles.buttonContainer,
      buttonAlignment === "left"
        ? styles.leftAlign
        : buttonAlignment === "right"
        ? styles.rightAlign
        : styles.centerAlign
    );

    let containerClass = "";

    if (layout === "B") {
      containerClass = styles.layoutB;
    }

    const BRAKE_MOBILE = 450;
    const BRAKE_TABLES = 975;

    let newSize = 375;

    if (containerWidth <= BRAKE_MOBILE && containerWidth > 0) {
      newSize = containerWidth;
    }
    if (containerWidth <= BRAKE_TABLES && containerWidth > BRAKE_MOBILE) {
      newSize = containerWidth / 2;
    }

    const leftStyles: React.CSSProperties = layout === "B" ? { maxWidth: `${newSize}px` } : {};

    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    return (
      <ZenBaseBlock
        siteTheme={siteTheme}
        readOnly={readOnly}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        layout={""}
        selectedBlock={selectedBlock}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <style>
            {`
            :root {
              --ddl-background: ${getBackgroundColor(siteTheme.backgroundColor.value)};
              --option-color: ${getOptionsColor(siteTheme.backgroundColor.value)};
              --cb-placeholder: ${getPlaceholderColor(siteTheme.backgroundColor.value)};
              --font-color: ${getFontColor(siteTheme.backgroundColor.value)};
              }
            `}
          </style>

          <div ref={this.containerRef} className={cx(styles.zenContainer, containerClass)}>
            <div className={layout === "B" ? styles.left : ""} style={leftStyles}>
              <div
                ref={this.divRef}
                className={cx(styles.title, styles.margin, styles[siteFonts.primary], styles[siteFonts.primaryExtra], {
                  [styles.layoutB]: layout === "B",
                  [styles.titleClickable]: !readOnly
                })}
                style={{ color: siteTheme.accentColor.value }}
              >
                {title}
              </div>
              {parsedBody && (
                <div
                  className={cx(styles.subtitle, styles.margin, styles[siteFonts.secondary], {
                    [styles.layoutB]: layout === "B",
                    [styles.subtitleClickable]: !readOnly
                  })}
                  style={{
                    color: getBlockTextColor(
                      backgroundColor,
                      siteTheme.accentColor.value,
                      backgroundType,
                      siteTheme.backgroundColor.value
                    )
                  }}
                >
                  {renderLineBreaks(parsedBody, true)}
                </div>
              )}
            </div>

            <div
              className={cx(styles.form, layout === "B" ? styles.right : "")}
              style={{
                backgroundColor: siteTheme.backgroundColor.value
              }}
            >
              <div className={cx(styles.inputs, { [styles.layoutB]: layout === "B" })}>
                <div className={styles.formGroup}>
                  <input
                    className={cx(emailError !== "" ? styles.error : styles.noBorder, styles[siteFonts.secondary])}
                    placeholder="Email address"
                    value={email}
                    maxLength={320}
                    onChange={this.handleEmailChange}
                    onBlur={this.handleEmailBlur}
                  />
                  <div className={cx(styles.errorMsg, styles[siteFonts.secondary])}>{emailError}</div>
                </div>
                <div className={cx(styles.formGroup, styles.passwordContainer)}>
                  <input
                    className={cx(passwordError !== "" ? styles.error : styles.noBorder, styles[siteFonts.secondary])}
                    placeholder="Password"
                    value={password}
                    type={passwordInputType}
                    maxLength={320}
                    onChange={this.handlePasswordChange}
                    onBlur={this.handlePasswordBlur}
                  />
                  {passwordInputType === "password" ? (
                    <img src={iconEyeDisabled} onClick={() => this.togglePasswordInputType()} />
                  ) : (
                    <img src={iconEyeEnabled} onClick={() => this.togglePasswordInputType()} />
                  )}
                  <div className={cx(styles.errorMsg, styles[siteFonts.secondary])}>{passwordError}</div>
                </div>
              </div>
              <div className={cx(styles.errorLogin, styles[siteFonts.secondary])}>{loginError}</div>
              <div className={buttonContainerClass}>
                <ZenButton
                  className={!readOnly ? styles.buttonClickable : ""}
                  onClick={() => this.handleButtonClick(isButtonDisabled || isLoading)}
                  disabled={isButtonDisabled}
                  isDarkBackground={isDarkBackground}
                  siteTheme={siteTheme}
                  layout={"A"}
                  labelText={buttonText}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}
