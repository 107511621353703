import React from "react";
import PhotoIcon from "./photoIcon";
import styles from "./zoomSlider.module.scss";

export interface IZoomSliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
}

class ZoomSlider extends React.PureComponent<IZoomSliderProps> {
  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(Number(event.currentTarget.value));
  }
  public render() {
    const { min, max, value } = this.props;
    return <div className={styles.container}>
      <div className={styles.zoomContainer}>
        <div>
          <PhotoIcon size={14} />
        </div>

        <div className={styles.zoom} >
          <input type="range" min={min} max={max} value={value} className={styles.slider} onChange={this.onChange} />
        </div>
        <div>
          <PhotoIcon size={20} />
        </div>
      </div>

      <div className={styles.zoomTip} />
    </div>
  }
}

export default ZoomSlider;