import { FormTextInput, Validation } from "@zenfolio/core-components";
import classNames from "classnames";
import * as React from "react";
import { CardCVCElement, CardExpiryElement, CardNumberElement } from "react-stripe-elements";
import { getFirstError } from "../../../../../../containers/widget/selectors";
import colors from "../../../../../../utilities/colors";
import ShoppingAddress from "../../../../../shoppingAddress";
import { IShoppingAddressComponents } from "../../../../contracts";
import styles from "./index.module.scss";

interface ICardInfoProps {
  cardholderName: string;
  billingAddress?: IShoppingAddressComponents;
  billingZipCodeError?: boolean;
  paymentFailureCode: string | null;
  onCardholderNameChange: (value: string) => void;
  onValidationChanged: (cardValid: boolean) => void;
  onScrollToSelector: (selector: string, customHeight?: number) => void;
  onBillingAddressSubmit?: () => void;
  onBillingAddressChange?: (addressComponents: Partial<IShoppingAddressComponents>) => void;
}

interface ICardInfo {
  cardNumber: boolean;
  cardExpiry: boolean;
  cardCvc: boolean;
}

interface ICardNumberChanged {
  cardNumberChanged: boolean;
}

interface ICardExpiryChanged {
  cardExpiryChanged: boolean;
}

interface ICardCvcChanged {
  cardCvcChanged: boolean;
}

interface ICardInfoState extends ICardNumberChanged, ICardExpiryChanged, ICardCvcChanged {
  card: ICardInfo;
  cardNumberError: boolean;
  cardExpiryError: boolean;
  cardCvcError: boolean;
  cardNumberInvalid: boolean;
  cardExpiryInvalid: boolean;
  cardCvcInvalid: boolean;
  cardholderNameChanged: boolean;
  cardholderName: string;
  cardValid: boolean;
}

const defaultCardFieldsState = {
  cardNumberInvalid: false,
  cardExpiryInvalid: false,
  cardCvcInvalid: false,
  cardNumberChanged: false,
  cardExpiryChanged: false,
  cardCvcChanged: false,
  cardholderNameChanged: false
};

class CardInfo extends React.Component<ICardInfoProps, ICardInfoState> {
  constructor(props: ICardInfoProps) {
    super(props);

    this.state = {
      card: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false
      },
      cardholderName: props.cardholderName || "",
      cardNumberError: false,
      cardExpiryError: false,
      cardCvcError: false,
      cardValid: true,
      ...defaultCardFieldsState
    };
  }

  public componentDidMount() {
    this.updateValidate();
  }

  public componentDidUpdate(prevProps: ICardInfoProps, prevState: ICardInfoState) {
    if (prevProps.paymentFailureCode !== this.props.paymentFailureCode) {
      this.handlePaymentFailureCode();
    } else if (prevState.cardNumberChanged !== this.state.cardNumberChanged && this.state.cardNumberChanged) {
      this.setState({ cardValid: true, cardNumberInvalid: false }, this.updateValidate);
    } else if (prevState.cardExpiryChanged !== this.state.cardExpiryChanged && this.state.cardExpiryChanged) {
      this.setState({ cardValid: true, cardExpiryInvalid: false }, this.updateValidate);
    } else if (prevState.cardCvcChanged !== this.state.cardCvcChanged && this.state.cardCvcChanged) {
      this.setState({ cardValid: true, cardCvcInvalid: false }, this.updateValidate);
    } else if (
      prevState.cardholderNameChanged !== this.state.cardholderNameChanged &&
      this.state.cardholderNameChanged
    ) {
      this.setState({ cardValid: true }, this.updateValidate);
    }

    if (this.props.cardholderName !== prevProps.cardholderName) {
      this.setState({
        cardholderName: this.fixCardholderName(this.props.cardholderName)
      });
    }
  }

  public render() {
    const {
      billingAddress,
      billingZipCodeError,
      onBillingAddressSubmit,
      onBillingAddressChange,
      onScrollToSelector
    } = this.props;
    const { cardholderName, cardValid } = this.state;
    const { cardNumberErrorMessage, cardExpiryErrorMessage, cardCvcErrorMessage } = this;

    const elementStyles: any = {
      base: {
        color: colors.black,
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "44px",
        fontSmoothing: "antialiased",
        fontFamily: '"Nunito Sans", sans-serif',
        "::placeholder": { color: colors.grayPlaceholder },
        "::-ms-clear": { display: "none" }
      },
      invalid: {
        color: colors.black
      }
    };

    return (
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.title}>
            <p>Payment</p>
          </div>
          <div className={classNames(styles.card, cardValid ? null : styles.cardError)}>
            <div className={styles.field}>
              <CardNumberElement
                style={elementStyles}
                placeholder="Card number"
                className={classNames(styles.number, cardNumberErrorMessage ? styles.elementError : null)}
                onBlur={this.onBlurCardNumber}
                onFocus={this.onFocusCardNumber}
                onChange={this.onCardNumberChange}
              />
              {cardNumberErrorMessage && <div className={styles.error}>{cardNumberErrorMessage}</div>}
            </div>
            <div className={styles.field}>
              <div
                className={classNames(
                  styles.info,
                  !cardExpiryErrorMessage && !cardCvcErrorMessage ? styles.infoValid : null
                )}
              >
                <CardExpiryElement
                  style={elementStyles}
                  placeholder="MM/YY"
                  className={classNames(styles.expiry, cardExpiryErrorMessage ? styles.elementError : null)}
                  onBlur={this.onBlurCardExpiry}
                  onFocus={this.onFocusCardExpiry}
                  onChange={this.onCardExpiryChange}
                />
                <CardCVCElement
                  style={elementStyles}
                  placeholder="CVV"
                  className={classNames(styles.cvc, cardCvcErrorMessage ? styles.elementError : null)}
                  onBlur={this.onBlurCardCvc}
                  onFocus={this.onFocusCardCvc}
                  onChange={this.onCardCvcChange}
                />
              </div>

              {cardExpiryErrorMessage && <div className={styles.error}>{cardExpiryErrorMessage}</div>}
              {cardCvcErrorMessage && <div className={styles.error}>{cardCvcErrorMessage}</div>}
            </div>
            <div className={styles.field}>
              <FormTextInput
                className={classNames(styles.textInput, styles.cardHolder)}
                errorClassName={styles.textInputError}
                placeholder="Name on card"
                value={cardholderName}
                onChange={this.onCardholderNameChange}
                maxLength={22}
                errorMessage={this.cardholderNameErrorMessage}
              />
            </div>
            {billingAddress ? (
              <ShoppingAddress
                {...billingAddress}
                zipCodeError={billingZipCodeError!}
                placeholder="Billing Address"
                onAddressSubmit={onBillingAddressSubmit}
                onAddressComponentsChange={onBillingAddressChange!}
                onScrollToSelector={onScrollToSelector}
                className={styles.billingAddress}
              />
            ) : null}
          </div>
          {!cardValid && <div className={styles.paymentError}>Something went wrong. Please try again.</div>}
        </div>
      </div>
    );
  }

  private isCardValid = (): boolean => {
    const { cardNumber, cardExpiry, cardCvc } = this.state.card;
    return (
      cardNumber &&
      cardExpiry &&
      cardCvc &&
      !this.cardholderNameErrorMessage &&
      !this.state.cardNumberInvalid &&
      !this.state.cardExpiryInvalid &&
      !this.state.cardCvcInvalid
    );
  };

  private fixCardholderName = (cardholderName: string) => {
    return cardholderName ? cardholderName.toUpperCase() : cardholderName;
  };

  private get cardNumberErrorMessage() {
    if (!this.state.cardNumberError && !this.state.cardNumberInvalid) {
      return undefined;
    }

    const { cardNumber } = this.state.card;
    const errors = [!cardNumber || this.state.cardNumberInvalid ? "Verify your credit card." : ""];

    return getFirstError(errors);
  }

  private get cardExpiryErrorMessage() {
    if (!this.state.cardExpiryError && !this.state.cardExpiryInvalid) {
      return undefined;
    }

    const { cardExpiry } = this.state.card;
    const errors = [!cardExpiry || this.state.cardExpiryInvalid ? "Verify your expiration date." : ""];

    return getFirstError(errors);
  }

  private get cardCvcErrorMessage() {
    if (!this.state.cardCvcError && !this.state.cardCvcInvalid) {
      return undefined;
    }

    const { cardCvc } = this.state.card;
    const errors = [!cardCvc || this.state.cardCvcInvalid ? "Verify your CVV." : ""];

    return getFirstError(errors);
  }

  private scrollToClassName(className: string) {
    this.props.onScrollToSelector(`.${className}`);
  }

  private get cardholderNameErrorMessage() {
    const { cardholderName } = this.state;

    const errors = [
      Validation.errorIfEmpty(cardholderName, "Please enter the name on the card."),
      !Validation.isAlpha(cardholderName) ? "Verify your name on the card." : ""
    ];

    return getFirstError(errors);
  }

  private onBlurCardNumber = () => {
    this.setState({ cardNumberError: true });
  };

  private onFocusCardNumber = () => {
    this.scrollToClassName(styles.number);
  };

  private onCardNumberChange = (el: stripe.elements.ElementChangeResponse) => {
    this.onCardChange(el, { cardNumberChanged: true });
  };

  private onBlurCardCvc = () => {
    this.setState({ cardCvcError: true });
  };

  private onFocusCardCvc = () => {
    this.scrollToClassName(styles.cvc);
  };

  private onCardCvcChange = (el: stripe.elements.ElementChangeResponse) => {
    this.onCardChange(el, { cardCvcChanged: true });
  };

  private onBlurCardExpiry = () => {
    this.setState({ cardExpiryError: true });
  };

  private onFocusCardExpiry = () => {
    this.scrollToClassName(styles.expiry);
  };

  private onCardExpiryChange = (el: stripe.elements.ElementChangeResponse) => {
    this.onCardChange(el, { cardExpiryChanged: true });
  };

  private onCardChange = (
    el: stripe.elements.ElementChangeResponse,
    changedState: ICardNumberChanged | ICardExpiryChanged | ICardCvcChanged
  ) => {
    this.setState(
      {
        ...(changedState as any),
        card: {
          ...this.state.card,
          [el.elementType]: el.complete
        }
      },
      this.updateValidate
    );
  };

  private onCardholderNameChange = (cardholderName: string) => {
    cardholderName = this.fixCardholderName(cardholderName);

    this.setState(
      {
        cardholderName,
        cardholderNameChanged: true
      },
      this.updateValidate
    );

    this.props.onCardholderNameChange(cardholderName);
  };

  private handlePaymentFailureCode() {
    const afterChange = (scrollTo?: string) => {
      this.updateValidate();

      if (scrollTo) {
        this.scrollToClassName(scrollTo);
      }
    };

    switch (this.props.paymentFailureCode) {
      case "number":
        this.setState(
          {
            ...defaultCardFieldsState,
            cardNumberInvalid: true
          },
          () => afterChange(styles.number)
        );
        break;
      case "expiry":
        this.setState(
          {
            ...defaultCardFieldsState,
            cardExpiryInvalid: true
          },
          () => afterChange(styles.expiry)
        );
        break;
      case "cvc":
        this.setState(
          {
            ...defaultCardFieldsState,
            cardCvcInvalid: true
          },
          () => afterChange(styles.cvc)
        );
        break;
      case "generic":
        this.setState(
          {
            ...defaultCardFieldsState,
            cardValid: false
          },
          () => afterChange(styles.card)
        );
        break;
      default:
        this.setState(defaultCardFieldsState, () => afterChange());
        break;
    }
  }

  private updateValidate = () => {
    const { onValidationChanged } = this.props;
    const cardValid = this.isCardValid();
    onValidationChanged(cardValid);
  };
}

export default CardInfo;
