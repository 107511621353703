import cx from "classnames";
import _ from "lodash";
import React from "react";
import { getBlockTextColor } from "utilities/blocks/blockColors";
import { IRoutes, ILink } from "../../../models/models";
import ZenAnimatedComponent from "../zenAnimatedComponent";
import ZenBaseBlock, { IZenBaseBlockProps } from "../zenBaseBlocks";
import FeaturedWork from "./featuredWork/index";
import styles from "./zenFeaturedWorksBlock.module.scss";
import { getInteractionUrlType } from "utilities/blocks/blockInteractions";
import { parseBody, renderLineBreaks } from "utilities/blocks/string";
import { portfolioErrorGoBack } from "components/ClientView/DynamicGallery/Views/PrivateError";
import { getSiteFonts, getSiteScrollbarWidth } from "utilities/blocks/site";
import { GRID_TYPE, LinkTypeOption } from "utilities/constant";

export interface IGallerySettings {
  gridType: GRID_TYPE;
  title?: string;
  folderGridType?: GRID_TYPE;
  showTitles: boolean;
  allowShowCaption?: boolean;
}

export interface IFeature {
  id: string;
  title: string;
  body: string;
  galleryId?: string;
  galleryFolderAlias?: string;
  galleryIsProtected?: boolean;
  galleryHasVisitorsAllowed?: boolean;
  galleryIsVisitorEmailRequired?: boolean;
  folderId?: string;
  alignment: string;
  index: number;
  buttonLabel: string;
  imageUrl: string;
  imageSrcSet?: string;
  imageName: string;
  imageHeight: number;
  imageWidth: number;
  imageId: string;
  coverId?: string;
  isSelected: boolean;
  isVisible: boolean;
  interactionType?: string;
  interactionPage?: string;
  interactionNewTab?: boolean;
  interactionUrl?: string;
  gallerySettings?: IGallerySettings;
  getGalleryPhoto?: boolean;
  folderCount?: number;
  albumCount?: number;
  focalX?: number;
  focalY?: number;
  altText?: string;
  photoAlbumId?: string;
  link?: ILink;
  isLoading?: boolean;
  photoUrlTemplate?: string;
}

export interface IFeaturedWorksBlockProps extends IZenBaseBlockProps {
  photoUrlTemplate?: string;
  features?: IFeature[];
  title: string;
  body: string;
  updateBlockState?: boolean;
  isEdit: boolean;
  pages?: any;
  routes?: IRoutes;
  isPublish?: boolean;
  blockImageEvents?: boolean;
  setFeatures?: (value: IFeature[], needSaveChanges?: boolean) => void;
  getInteractionUrl?: getInteractionUrlType;
}

interface IFeaturedWorksBlockState {
  features?: IFeature[];
  updateFeatures: boolean;
  layout: string;
  scaleFactor?: number;
}

// this brake value matches the value used in media query
const MOBILE_BRAKE_WIDTH = 450;
const MAX_CONTAINER_WIDTH = 1176;
export class ZenFeaturedWorks extends React.Component<IFeaturedWorksBlockProps, IFeaturedWorksBlockState> {
  private mainContainerRef: React.RefObject<HTMLDivElement>;
  private worksContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: IFeaturedWorksBlockProps) {
    super(props);
    this.state = {
      features: this.props.features ? this.initFeatures(this.props.features) : [],
      updateFeatures: false,
      layout: "A"
    };

    this.mainContainerRef = React.createRef();
    this.worksContainerRef = React.createRef();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IFeaturedWorksBlockProps) {
    if (this.props.layout !== nextProps.layout && nextProps.layout) {
      this.setState({ layout: nextProps.layout }, () => {
        this.validateLayout();
      });
    }

    // using lodash to detect only real diffs between feature collections,
    // because array reference is always new
    const featuresChanges = _(nextProps.features).xorWith(this.state.features, _.isEqual);

    if ((nextProps.features && !featuresChanges.isEmpty()) || nextProps.features !== this.state.features) {
      this.setState({ features: nextProps.features });
    }
  }

  public componentDidMount() {
    window.addEventListener("resize", this.resizeListener);
    this.validateLayout();
    portfolioErrorGoBack();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  private checkLegacyLinkData = (feature: IFeature) => {
    if (!feature.link && feature.interactionType === "url") {
      feature.link = {
        type: LinkTypeOption.URL,
        url: feature.interactionUrl,
        button: true,
        newTab: feature.interactionNewTab,
        label: feature.buttonLabel
      };
    }

    return feature;
  };

  private initFeatures = (features: IFeature[]) => {
    return features.map(feature =>
      this.checkLegacyLinkData({ ...feature, isSelected: !this.props.readOnly ? false : feature.isSelected })
    );
  };

  private scrollIntoView = (works: IFeature[]) => {
    works
      .filter(work => work.isVisible)
      .map(work => work.isSelected)
      .forEach((selected, index) => {
        if (selected && this.worksContainerRef && this.worksContainerRef.current) {
          this.worksContainerRef.current.children[index].scrollIntoView({
            block: "center",
            behavior: "smooth"
          });
        }
      });
  };

  public shouldComponentUpdate(nextProps: IFeaturedWorksBlockProps, nextState: IFeaturedWorksBlockState) {
    if (nextProps.updateBlockState !== this.props.updateBlockState && nextProps.features) {
      if (nextProps.features !== this.props.features) {
        this.scrollIntoView(nextProps.features);
      }
    }

    if (nextProps.updateBlockState !== this.props.updateBlockState) {
      this.setState({ updateFeatures: !this.state.updateFeatures });
    }

    return (
      nextProps.updateBlockState !== this.props.updateBlockState ||
      nextProps.layout !== this.props.layout ||
      nextState.layout !== this.state.layout ||
      nextState.scaleFactor !== this.state.scaleFactor ||
      nextProps.siteTheme !== this.props.siteTheme
    );
  }

  private validateLayout = () => {
    const containerEl = this.mainContainerRef.current ? this.mainContainerRef.current : null;
    const scrollbarWidth = containerEl ? getSiteScrollbarWidth(containerEl) : 0;
    const blockWidth = containerEl ? containerEl.offsetWidth : null;

    if (this.props.layout === "B" && blockWidth && blockWidth <= MOBILE_BRAKE_WIDTH - scrollbarWidth) {
      this.setState({ layout: "A" });
    } else if (this.props.layout === "B") {
      this.setState({ layout: "B" });
    } else {
      this.setState({ layout: "A" });
    }

    if (blockWidth) {
      this.setState({
        scaleFactor: blockWidth <= MAX_CONTAINER_WIDTH ? blockWidth / MAX_CONTAINER_WIDTH : 1
      });
    }
  };

  public resizeListener = () => this.validateLayout();

  public getFeaturesMap = (features: IFeature[], layout: string) => {
    const { siteTheme, isEdit, readOnly, isPublish, getInteractionUrl } = this.props;
    const renderFeatures = !isEdit ? features.filter(feat => feat.isVisible) : features;
    return renderFeatures
      .map((feature: IFeature, index: number) => {
        return (
          <React.Fragment key={index}>
            <FeaturedWork
              index={index}
              value={feature}
              isLast={features.length - 1 === index}
              siteTheme={siteTheme}
              isEdit={isEdit}
              themeColor={siteTheme.backgroundColor ? siteTheme.backgroundColor : { name: "custom", value: "#FAFAFA" }}
              layout={layout}
              pages={this.props.pages}
              routes={this.props.routes}
              readOnly={readOnly}
              isPublish={isPublish}
              getInteractionUrl={getInteractionUrl}
              blockImageEvents={this.props.blockImageEvents}
            />
          </React.Fragment>
        );
      });
  };

  public render() {
    const {
      siteTheme,
      desktopEnabled,
      tabletEnabled,
      mobileEnabled,
      readOnly,
      divider,
      alignment,
      fontFamily,
      padding,
      animationScrollIn,
      animationScrollOut,
      backgroundType,
      backgroundColor,
      backgroundOpacity,
      backgroundWidth,
      selectedBlock,
      body
    } = this.props;
    const title = parseBody(this.props.title || "")
    const parsedFeatures = this.state.features || [];
    const parsedBody = parseBody(body);
    const emptyFeatures = parsedFeatures.find(feat => feat.isVisible) === undefined;
    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    return (
      <ZenBaseBlock
        ref={this.mainContainerRef}
        siteTheme={siteTheme}
        desktopEnabled={desktopEnabled}
        tabletEnabled={tabletEnabled}
        mobileEnabled={mobileEnabled}
        readOnly={readOnly}
        divider={divider}
        alignment={alignment}
        fontFamily={fontFamily}
        padding={padding}
        animationScrollIn={animationScrollIn}
        animationScrollOut={animationScrollOut}
        backgroundType={backgroundType}
        backgroundColor={backgroundColor}
        backgroundOpacity={backgroundOpacity}
        backgroundWidth={backgroundWidth}
        selectedBlock={selectedBlock}
        paddingMobile={true}
      >
        <ZenAnimatedComponent
          animationScrollIn={animationScrollIn}
          animationScrollOut={animationScrollOut}
          readOnly={readOnly}
        >
          <style>
            {`
            :root {
              --text-color: ${getBlockTextColor(
                backgroundType === "none" ? siteTheme.backgroundColor.value : backgroundColor,
                siteTheme.accentColor.value,
                backgroundType,
                siteTheme.backgroundColor.value
              )};
              --fw-scale-factor: ${this.state.scaleFactor || 1};
              }
            `}
          </style>
          <div
            className={cx(
              styles.titlesContainer,
              alignment === "left"
                ? styles.titlesLeft
                : alignment === "right"
                ? styles.titlesRigth
                : styles.titlesCenter,
              styles[siteFonts.primary]
            )}
          >
            <p
              className={cx(styles.titlesTitle, styles[siteFonts.primaryExtra], { [styles.titleClickable]: !readOnly })}
            >
              {title}
            </p>
            {parsedBody && (
              <div
                className={cx(styles.titleBody, styles[siteFonts.secondary], { [styles.subtitleClickable]: !readOnly })}
              >
                {renderLineBreaks(parsedBody, true)}
              </div>
            )}
          </div>
          {parsedFeatures && (
            <div
              ref={this.worksContainerRef}
              className={cx(styles.zenContainerFeaturedWorks, emptyFeatures ? styles.noContent : "")}
              data-outside-subblock={true}
            >
              {this.getFeaturesMap(parsedFeatures, this.state.layout)}
            </div>
          )}
        </ZenAnimatedComponent>
      </ZenBaseBlock>
    );
  }
}
