import React from "react";
import IIConProps from "../IIconProps";

const LikeIcon = (props: IIConProps) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`${props.minX || 0} ${props.minY || 0} ${props.width} ${props.height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1 9.893c1.1 0 2.086.446 2.798 1.168a4.052 4.052 0 0 1 1.16 2.85c-.022 1.035-.656 5.694-8.393 10.359-7.897-4.77-8.376-9.729-8.39-10.507 0-1.111.42-2.071 1.117-2.756.718-.705 1.723-1.114 2.842-1.114 1.644 0 2.92 1.054 3.894 2.058.18.186.362.382.539.59.176-.208.358-.404.538-.59.974-1.004 2.25-2.058 3.894-2.058z"
        stroke={props.stroke || "currentColor"}
        strokeWidth="1.5"
        fill={props.fill || "none"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default LikeIcon;
