import classNames from "classnames";
import React from "react";
import { Utilities } from "utilities/utilities";
import styles from "./alert.module.scss";
import checkmark from "./checkmark.svg";

type AlertType = "success" | "error";
type ScrollToPreset = "self" | "page-top";
type ScrollTo = boolean | ScrollToPreset | HTMLElement;

interface IAlertProps {
  type: AlertType;
  message: string;
  duration?: number;
  scrollTo?: ScrollTo;
  className?: string;
  version?: number;
}

interface IAlertState {
  hidden?: boolean;
}

class Alert extends React.PureComponent<IAlertProps, IAlertState> {
  public state: IAlertState = {};

  public componentDidMount() {
    this.onUpdate();
  }
  public componentDidUpdate(prevProps: IAlertProps) {
    this.onUpdate(prevProps);
  }
  public componentWillUnmount() {
    this.clearTimer();
  }

  public render() {
    const { className, type, message } = this.props;
    const { hidden } = this.state;

    const containerClassName = classNames(styles.container, className, {
      [styles.success]: type === "success",
      [styles.error]: type === "error",
      [styles.hidden]: hidden
    });

    return (
      <div className={containerClassName} ref={this.container}>
        {type === "success" && (
          <span className={styles.circle}>
            <img className={styles.icon} alt="" src={checkmark} />
          </span>
        )}
        <span className={styles.message}>{message}</span>
      </div>
    );
  }

  private onUpdate(prevProps?: IAlertProps) {
    const { message, type, version } = this.props;
    if (!prevProps || prevProps.message !== message || prevProps.type !== type || prevProps.version !== version) {
      this.recreateTimer();
      this.scrollTo();
    }
  }

  private clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  private recreateTimer() {
    this.clearTimer();
    const { duration } = this.props;
    if (duration) {
      this.timer = setTimeout(() => {
        this.timer = undefined;
        this.setState({ hidden: true });
      }, duration);
    }
  }

  private scrollTo() {
    const { scrollTo } = this.props;
    if (scrollTo) {
      const offsetTop =
        scrollTo === true || scrollTo === "self"
          ? this.container.current!.offsetTop
          : scrollTo === "page-top"
          ? null
          : scrollTo.offsetTop;

      if (offsetTop == null) {
        Utilities.scrollTop();
      } else {
        Utilities.scrollTo(0, offsetTop);
      }
    }
  }

  private timer?: NodeJS.Timeout;
  private container = React.createRef<HTMLDivElement>();
}

export default Alert;
