import React, { useState, useEffect } from "react";
import styles from "./hamburger.module.scss";
import cx from "classnames";

export interface IHamburger {
  open: boolean;
  color: string;
  scale?: number;
  onOpenChange?: (newState: boolean) => void;
}

const Hamburger: React.FC<IHamburger> = props => {
  const [open, setOpen] = useState(props.open ? props.open : false);
  const [color, setColor] = useState(props.color ? props.color : "black");

  const hamburgerStyles = {
    lineTop: {
      transform: open ? "rotate(45deg)" : "none"
    },
    lineMiddle: {
      opacity: open ? 0 : 1,
      transform: open ? "translateX(-12px)" : "none"
    },
    lineBottom: {
      transform: open ? "translateX(-18%) rotate(-45deg) translateY(100%)" : "none"
    }
  };

  const handleClick = () => {
    setOpen(!open);
    if (props.onOpenChange) {
      props.onOpenChange(!open);
    }
  };

  useEffect(() => {
    setOpen(props.open);
    setColor(props.color);
  }, [props.open, props.color]);

  return (
    <>
      <style>
        {`
          :root {
            --hamburger-color: ${color};
            }
        `}
      </style>
      <div
        className={cx(styles.hamburger, open && styles.isOpen)}
        onClick={e => {
          handleClick();
        }}
      >
        <span style={{ ...hamburgerStyles.lineTop }} />
        <span style={{ ...hamburgerStyles.lineMiddle }} />
        <span style={{ ...hamburgerStyles.lineBottom }} />
      </div>
    </>
  );
};

export default Hamburger;
