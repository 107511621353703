import React from "react";

import styles from "./canvasPrint.module.scss";
import cx from "classnames";
import { PaperPrint, IPaperPrintViewProps } from "../PaperPrint";
import { CANVAS_WRAP_TYPE, PIXEL_PER_INCH } from "../../designer/model";
import { withAutoDetectOrientation, IAutoDetectOrientationProps } from "../../designer/withAutoDetectOrientation";
import { IValidationProps } from "../../designer/withValidation";
import { Utilities } from "utilities/utilities";
import { Unit } from "utilities/unit";

export interface ICanvasPhotoEditorProps extends IAutoDetectOrientationProps, IValidationProps, IPaperPrintViewProps {
  canvasPrintDetails?: ICanvasPrintProps;
  unit?: Unit;
  updateProductAfterChangeOption?: () => void;
}

export interface ICanvasPrintProps {
  id?: string;
  canvasWrapType: CANVAS_WRAP_TYPE | undefined;
  canvasWrapBorder: number; // per inch
  isShoppingCartItem?: boolean;
  isPreviewMode?: boolean;
}

export interface ICanvasPhotoProps {
  isShoppingCartItem?: boolean;
  isInvalidRatio?: boolean;
  isInvalidResolution?: boolean;
  canvasPrintDetails?: ICanvasPrintProps;
  printWidthInPixel: number;
  printHeightInPixel: number;
  canvasWrapBorderInPixel: number;
  canvasWrapBorderInPixelForBothSide: number;
  stroke?: string | undefined;
  renderImage: JSX.Element;
  renderCanvasPlaceHolder?: JSX.Element;
}

interface ICanvasPhotoEditorState {}

export const DEFAULT_WRAP_EDGE_WIDTH = 2.125;
export const DEFAULT_PRODUCT_WIDTH = 8 * PIXEL_PER_INCH;
export const DEFAULT_BLUE_BORDER_WIDTH = 2;

export const wrapBorderSize = (productPrintedSize: number) => {
  return DEFAULT_BLUE_BORDER_WIDTH * (productPrintedSize / DEFAULT_PRODUCT_WIDTH);
};

export const calculateProductSizeForCanvas = (productSize: number, canvasPrintDetails: ICanvasPrintProps) => {
  let border = 0;
  if (canvasPrintDetails && canvasPrintDetails.canvasWrapType === CANVAS_WRAP_TYPE.PHOTO_WRAP) {
    border = canvasPrintDetails.canvasWrapBorder * 2;
  }
  return productSize + border;
};

const validateSwitchNaturalAndSolidWrapChange = (
  prevCanvasWrapType: CANVAS_WRAP_TYPE,
  newCanvasWrapType: CANVAS_WRAP_TYPE
) => {
  return prevCanvasWrapType === CANVAS_WRAP_TYPE.PHOTO_WRAP || newCanvasWrapType === CANVAS_WRAP_TYPE.PHOTO_WRAP;
};

class CanvasPhotoEditor extends React.Component<ICanvasPhotoEditorProps, ICanvasPhotoEditorState> {
  constructor(props: ICanvasPhotoEditorProps) {
    super(props);

    this.state = {
      isHorizontal: Utilities.isHorizontal(this.props.originalPhotoWidth || 0, this.props.originalPhotoHeight || 0)
    };
  }

  public componentDidUpdate(prevProps: ICanvasPhotoEditorProps, prevState: ICanvasPhotoEditorState) {
    if (
      prevProps.originalPhotoWidth !== this.props.originalPhotoWidth ||
      prevProps.originalPhotoHeight !== this.props.originalPhotoHeight
    ) {
      this.setState({
        isHorizontal: Utilities.isHorizontal(this.props.originalPhotoWidth || 0, this.props.originalPhotoHeight || 0)
      });
    }

    //Update after change Natural <=> Solid Wrap
    const prevCanvasWrapType = prevProps.canvasPrintDetails?.canvasWrapType || CANVAS_WRAP_TYPE.PHOTO_WRAP;
    const newCanvasWrapType = this.props.canvasPrintDetails?.canvasWrapType || CANVAS_WRAP_TYPE.PHOTO_WRAP;
    if (
      prevCanvasWrapType !== newCanvasWrapType &&
      validateSwitchNaturalAndSolidWrapChange(prevCanvasWrapType, newCanvasWrapType) &&
      this.props.updateProductAfterChangeOption
    ) {
      this.props.updateProductAfterChangeOption();
    }
  }

  public render() {
    const {
      previewPhotoWidth,
      previewPhotoHeight,
      photoSrc,
      originalPhotoHeight,
      originalPhotoWidth,
      croppingData,
      canvasPrintDetails,
      enableRatioValidation,
      enableResolutionValidation,
      productHeight,
      productWidth,
      unit
    } = this.props;

    let h = productHeight;
    let w = productWidth;

    if (canvasPrintDetails) {
      w = calculateProductSizeForCanvas(productWidth, canvasPrintDetails);
      h = calculateProductSizeForCanvas(productHeight, canvasPrintDetails);
    }

    return (
      <div
        className={cx(styles.canvasPhotoEditorContainer, {
          [styles.thumbnailShoppingCard]: canvasPrintDetails?.isShoppingCartItem
        })}
      >
        <PaperPrint
          previewPhotoWidth={previewPhotoWidth}
          previewPhotoHeight={previewPhotoHeight}
          productHeight={h}
          productWidth={w}
          photoSrc={photoSrc}
          originalPhotoHeight={originalPhotoHeight}
          originalPhotoWidth={originalPhotoWidth}
          croppingData={croppingData}
          canvasPrintDetails={canvasPrintDetails}
          enableRatioValidation={enableRatioValidation}
          enableResolutionValidation={enableResolutionValidation}
          unit={unit}
        />
      </div>
    );
  }
}

export const CanvasPrint = withAutoDetectOrientation(CanvasPhotoEditor);
