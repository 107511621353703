import cx from "classnames";
import _ from "lodash";
import { IZenSiteTheme, CONTENT_TYPE, IPage, IPhotographerFolder } from "../../../models/models";
import React from "react";
import { getSiteFonts, universalWindow as window } from "utilities/blocks/site";
import { stringIsGuid } from "utilities/blocks/string";
import { getAllQuerystringParameters, getFolderUrlAlias } from "utilities/blocks/url";
import styles from "./breadcrumb.module.scss";
import BreadcrumbItem from "./breadcrumbItem";
import BreadcrumbSeparator from "./breadcrumbSeparator";

export interface IBreadcrumbItem {
  to: string;
  label: string;
  isFull?: boolean;
  level: number;
  itemId?: string;
  isSubPage?: boolean;
  pageId?: string;
}

export interface IBreadcrumbProps {
  sitePages: IPage[];
  separator: string;
  show: boolean;
  siteTheme: IZenSiteTheme;
}

export interface IExtraPage {
  relativeUrl: string;
  title: string;
}

const MAX_ITEMS = 4;

export const getFlattenPages = (page: IPage, pageId: string, flattenPages: IPage[]) => {
  if (page.pages) {
    for (const currentPage of page.pages) {
      // setting manually the parentId until API populates the prop in the DTO
      currentPage.parentId = page.id;
      flattenPages.push(currentPage);
      getFlattenPages(currentPage, pageId, flattenPages);
    }
  }
};

const addExtraPages = (result: IBreadcrumbItem[], extraPages?: IExtraPage[]) => {
  _.each(extraPages, extraPage => {
    result.push({
      to: extraPage.relativeUrl,
      label: extraPage.title,
      isFull: false,
      level: 1
    });
  });
};

const getParentPage = (page: IPage, pages: IPage[]) => {
  return page.parentId && _.find(pages, { id: page.parentId });
};

const pagesBreadcrumbsPublish = (page: IPage, pages: IPage[], subdomain: string, isDevelopment: boolean) => {
  const result: IBreadcrumbItem[] = [];
  let baseUrl = `${isDevelopment ? `/${subdomain}` : ""}`;

  const parentPage = getParentPage(page, pages);
  if (parentPage) {
    result.push({
      to: `${baseUrl}/${parentPage.alias}`,
      label: parentPage.name,
      isFull: false,
      pageId: parentPage.id,
      level: 1
    });

    baseUrl += parentPage ? `/${parentPage.alias}` : "";
  }

  result.push({
    to: `${baseUrl}/${page.alias}`,
    label: page.name,
    isFull: false,
    level: 1,
    pageId: page.id,
    ...(parentPage && { isSubPage: true })
  });

  return result;
};

const pagesBreadcrumbs = (page: IPage, pages: IPage[]) => {
  const result: IBreadcrumbItem[] = [];
  const baseUrl = "/clientview/pages/";

  const parentPage = getParentPage(page, pages);
  if (parentPage) {
    result.push({
      to: `${baseUrl}${parentPage.id}`,
      label: parentPage.name,
      isFull: false,
      level: 1
    });
  }

  result.push({
    to: `${baseUrl}${page.id}`,
    label: page.name,
    isFull: false,
    level: 1
  });

  return result;
};

export const getPagesBreadcrumbs = (
  pages: IPage[],
  pageId: string,
  subdomain: string,
  isDevelopment: boolean,
  isPublish?: boolean,
  extraPages?: IExtraPage[]
): IBreadcrumbItem[] => {
  let result: IBreadcrumbItem[] = [];
  const pagesNode: IPage = {
    id: "root",
    alias: "rootAlias",
    name: "rootName",
    html: "rootHtml",
    css: "rootCss",
    parentId: "rootParentId",
    sortIndex: 0,
    pages: _.cloneDeep(pages)
  };
  const flattenPages: IPage[] = [];
  let currentPage: IPage | undefined;
  getFlattenPages(pagesNode, pageId, flattenPages);
  currentPage = _.find(flattenPages, { id: pageId });

  if (currentPage) {
    result = isPublish
      ? pagesBreadcrumbsPublish(currentPage, flattenPages, subdomain, isDevelopment)
      : pagesBreadcrumbs(currentPage, flattenPages);
  }

  addExtraPages(result, extraPages);

  return result;
};

export const searchFolderPath = (
  item: IPhotographerFolder,
  id: string,
  path: string,
  exact: boolean = false
): string | undefined => {
  // if exact=false we build from root the whole path until the folder is found
  // if exact=true we only keep the root and the searched folder
  const addCurrentItem = path === "" || item.id === "rootFolders" || !exact || (exact && item.id === id);

  if (addCurrentItem) {
    path += `${path !== "" && path !== "rootFolders" ? "/" : ""}${item.id}#${item.name}`;
  }

  if (id === item.id) {
    return path;
  }

  if (item.folders && item.folders.length && item.folders.length > 0) {
    for (const folder of item.folders) {
      const currentResult = searchFolderPath(folder, id, path, exact);
      if (currentResult !== undefined) {
        return currentResult;
      }
    }
  }

  return;
};

interface IPortfolioDynamicViewQueryParams {
  ct: string; // content type
  gt: string; // grid type
  id: string; // page id
  rootId: string; // root folder id
  st: string; // show titles
  lf: string; // linked folder id
  rootPageId: string; // root page id
  srf: boolean; // show root folder name
  ah: boolean; // add HeroBlock
}

export const currentBreadcrumb = (
  breadcrumbItems: IBreadcrumbItem[],
  nextProps: any,
  isFolderView: boolean,
  rootFolderId?: string
): IBreadcrumbItem[] => {
  const breadcrumb: IBreadcrumbItem[] = [...breadcrumbItems];

  // Folder tree routes
  const currentFolders: IPhotographerFolder = {
    id: "rootFolders",
    name: "root folder",
    photoCount: 0,
    videoCount: 0,
    dateCreated: "",
    isProtected: false,
    isAlbum: false,
    coverPhoto: null,
    photoUrlTemplate: "",
    // dont include folder "My Folders"
    folders:
      nextProps.photographerFolders && nextProps.photographerFolders.folders.length > 0
        ? nextProps.photographerFolders.folders[0].folders
        : []
  };
  const urlParams = getAllQuerystringParameters(window.location.search) as IPortfolioDynamicViewQueryParams;
  const isRootUrl = !!!rootFolderId;
  const albumFolderParentId = isFolderView
    ? nextProps.folderId
    : isRootUrl
    ? null
    : nextProps.clientViewGallery.parentFolderId;
  const folderPath = searchFolderPath(currentFolders, albumFolderParentId, "", isRootUrl);

  if (folderPath !== undefined) {
    const pathArray = folderPath.split("/");
    let includePath = !rootFolderId;
    pathArray.slice(1, pathArray.length).forEach((folder: any) => {
      const folderData = folder.split("#");
      if (rootFolderId && folderData[0] === rootFolderId) {
        includePath = true;
      }

      if (includePath) {
        const currentFolderPath = !rootFolderId
          ? `${folderData[0]}`
          : rootFolderId === folderData[0]
          ? `${rootFolderId}`
          : `${rootFolderId}/subfolders/${folderData[0]}`;

        const linkedFolderParam = buildLinkedFolderQueryParam(urlParams.lf);

        breadcrumb.push({
          to: `${window.location.origin}/clientview/pages/${
            window.location.pathname.split("/")[3]
          }/folders/${currentFolderPath}?gt=${urlParams.gt}&st=${urlParams.st}${linkedFolderParam}${
            urlParams.srf ? "&srf=" + urlParams.srf : ""
          }${urlParams.ah ? "&ah=" + urlParams.ah : ""}`,
          label: folderData[1],
          isFull: true,
          level: 1,
          itemId: folderData[0]
        });
      }
    });
  }

  // Current Gallery Route
  if (!isFolderView) {
    breadcrumb.push({
      to: `${window.location.origin}${window.location.pathname}${window.location.search}`,
      label: nextProps.clientViewGallery.name,
      isFull: true,
      level: 1,
      itemId: nextProps.clientViewGallery.id
    });
  }
  if (urlParams.srf) {
    return breadcrumb;
  }
  return excludeLinkedFolderItem(breadcrumb, urlParams.lf);
};

const checkIsRootPage = (currentPage: IBreadcrumbItem | undefined) => {
  const urlParams = getAllQuerystringParameters(window.location.search) as IPortfolioDynamicViewQueryParams;
  const linkedFolderParam = buildLinkedFolderQueryParam(urlParams.lf);
  return (
    window.location.search.includes(`rootId=${urlParams.rootId}${linkedFolderParam}`) &&
    (urlParams.rootPageId === currentPage?.itemId || urlParams.rootPageId === currentPage?.pageId)
  );
};

export const currentBreadcrumbPublish = (
  breadcrumbItems: IBreadcrumbItem[],
  nextProps: any,
  isFolderView: boolean
): IBreadcrumbItem[] => {
  let breadcrumb: IBreadcrumbItem[] = [...breadcrumbItems];

  // Folder tree routes
  const currentFolders: IPhotographerFolder = {
    id: "rootFolders",
    name: "root folder",
    photoCount: 0,
    videoCount: 0,
    dateCreated: "",
    isProtected: false,
    isAlbum: false,
    coverPhoto: null,
    photoUrlTemplate: "",
    // dont include folder "My Folders"
    folders:
      nextProps.photographerFolders && nextProps.photographerFolders.folders.length > 0
        ? nextProps.photographerFolders.folders
        : []
  };
  const urlParams = getAllQuerystringParameters(window.location.search) as IPortfolioDynamicViewQueryParams;
  const rootFolderId = urlParams.rootId;
  const breadcrumbSubpageItem = breadcrumb.find(item => item.isSubPage);
  const isSkipSubpage = !checkIsRootPage(breadcrumbSubpageItem);
  if (isSkipSubpage) {
    breadcrumb = breadcrumb.filter(item => !item.isSubPage);
  }
  const isRootUrl = !rootFolderId || (!!rootFolderId && rootFolderId === nextProps.folderId);
  const albumFolderParentId = isFolderView
    ? nextProps.folderId
    : isRootUrl
    ? null
    : nextProps.clientViewGallery.parentFolderId;
  const folderPath = searchFolderPath(currentFolders, albumFolderParentId, "", isRootUrl);

  if (folderPath !== undefined) {
    const pathArray = folderPath.split("/");
    let currentPath = `${_.last(breadcrumbItems)?.to}`;
    let includePath = !rootFolderId;

    pathArray.slice(1, pathArray.length).forEach((folder: string, index) => {
      const parts = folder.split("#");
      const folderId = parts[0];
      const folderName = parts[1];

      if (rootFolderId && folderId === rootFolderId) {
        includePath = true;
      }

      if (includePath) {
        const linkedFolderParam = buildLinkedFolderQueryParam(urlParams.lf);
        currentPath += `/${getFolderUrlAlias(parts[1])}`;
        const newBreadcumb = {
          to: `${currentPath}?ct=${CONTENT_TYPE.DYNAMICFOLDER}&gt=${urlParams.gt}&st=${urlParams.st}&id=${
            parts[0]
          }&rootId=${rootFolderId}${linkedFolderParam}${
            urlParams.rootPageId ? "&rootPageId=" + urlParams.rootPageId : ""
          }${urlParams.srf ? "&srf=" + urlParams.srf : ""}${urlParams.ah ? "&ah=" + urlParams.ah : ""}`,
          label: parts[1],
          isFull: true,
          level: 1,
          itemId: parts[0]
        };
        breadcrumb.push(newBreadcumb);
      }
    });
  }

  // Current Album Route
  if (!isFolderView) {
    breadcrumb.push({
      to: `${window.location.origin}${window.location.pathname}${window.location.search}`,
      label: nextProps.clientViewGallery.name,
      isFull: true,
      level: 1,
      itemId: nextProps.clientViewGallery.id
    });
  }
  if (urlParams.srf) {
    return breadcrumb;
  }
  return excludeLinkedFolderItem(breadcrumb, urlParams.lf);
};

export const getCurrentBreadcrumb = (
  breadcrumbItems: IBreadcrumbItem[],
  nextProps: any,
  isFolderView: boolean,
  rootFolderId?: string,
  isPublish?: boolean
) => {
  return isPublish
    ? currentBreadcrumbPublish(breadcrumbItems, nextProps, isFolderView)
    : currentBreadcrumb(breadcrumbItems, nextProps, isFolderView, rootFolderId);
};

export const excludeLinkedFolderItem = (breadcrumbItems: IBreadcrumbItem[], linkedFolderId: string) =>
  breadcrumbItems && stringIsGuid(linkedFolderId)
    ? _.filter(breadcrumbItems, i => i.itemId !== linkedFolderId)
    : breadcrumbItems;

export const buildLinkedFolderQueryParam = (linkedFolderId: string) => {
  return stringIsGuid(linkedFolderId) ? `&lf=${linkedFolderId}` : "";
};

const Breadcrumb: React.FC<IBreadcrumbProps> = props => {
  let children = React.Children.toArray(props.children);

  if (children.length > MAX_ITEMS) {
    children.splice(1, children.length - MAX_ITEMS);
  }

  const lastIndex = children.length - 1;

  children = children.map((child: any, index: any) => (
    <BreadcrumbItem
      key={`breadcrumb_item${index}`}
      className={index === lastIndex ? styles.activeItem : ""}
      last={index === lastIndex}
    >
      {child}
    </BreadcrumbItem>
  ));

  children = children.reduce((acc: any, child: any, index: any) => {
    const notLast = index < lastIndex;
    if (notLast) {
      acc.push(
        child,
        <BreadcrumbSeparator
          key={`breadcrumb_sep${index}`}
          className={index === lastIndex - 1 ? styles.activeItem : ""}
        >
          {props.separator}
        </BreadcrumbSeparator>
      );
    } else {
      acc.push(child);
    }
    return acc;
  }, []);
  const siteFonts = getSiteFonts(props.siteTheme.fontsStyle);

  return (
    <React.Fragment>
      <style>
        {`
      :root {
        --breadcrumb-color: #a3a3a3;
        }
      `}
      </style>
      <div
        style={{ visibility: props.show ? "visible" : "hidden" }}
        className={cx(styles.breadcrumbContainer, styles[siteFonts.primary])}
      >
        <div className={cx(styles.breadcrumb, styles[siteFonts.secondary])}>
          <ol className={styles.list}>{children}</ol>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Breadcrumb;
