import { Dispatch } from "redux";
import { IRoute } from "../../components/router/routes";
import { IAppState } from "../state";
import { MenuActionType } from "./constants";

interface ISetMenu {
  type: MenuActionType.SET_MENU;
  items: IRoute[];
}

export type MenuAction = ISetMenu;

export const setMenu = (items: IRoute[]): ISetMenu => ({
  type: MenuActionType.SET_MENU,
  items
});

export const doSetMenu = (items: IRoute[]) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch(setMenu(items));
  };
};
