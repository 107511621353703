import cx from "classnames";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Utilities } from "../../../utilities/utilities";
import { IDatePickerCalendarMonthsProps } from "../models";
import styles from "./defaultDatePickerCalendarMonths.module.scss";

const { shouldComponentUpdateDeep } = Utilities;

export interface IDefaultDatePickerCalendarMonthsProps extends IDatePickerCalendarMonthsProps {}

class DefaultDatePickerCalendarMonths extends React.Component<IDefaultDatePickerCalendarMonthsProps> {
  public shouldComponentUpdate(nextProps: IDefaultDatePickerCalendarMonthsProps): boolean {
    return shouldComponentUpdateDeep(this, nextProps);
  }

  public render() {
    const { month, className, style, containerRef } = this.props;

    return (
      <div className={cx(styles.container, className)} style={style} ref={containerRef}>
        {moment.months().map((name, num) => (
          <div
            key={num}
            className={cx(styles.month, { [styles.selected]: month === num })}
            onClick={this.onMonthClick(num)}
          >
            {name}
          </div>
        ))}
      </div>
    );
  }

  private onMonthClick = _.memoize((month: number) => () => this.props.onMonthChange?.(month));
}

export default DefaultDatePickerCalendarMonths;
