import React from "react";

function ScissorsLine() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="268" viewBox="0 0 36 268">
      <g fill="#FFF" fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M21.071 255.355V268h-6.106v-12.645h6.106zm0-18.355v12.248h-6.106V237h6.106zm0-53.806v7.248h-6.106v-7.248h6.106zm0-20.355v14.248h-6.106V162.84h6.106zm0-20.355v14.248h-6.106v-14.248h6.106zm0-20.355v14.248h-6.106V122.13h6.106zm0-20.355v14.249h-6.106v-14.249h6.106zm0-20.355v14.249h-6.106V81.419h6.106zm0-20.354v14.248h-6.106V61.065h6.106zm0-20.355v14.248h-6.106V40.71h6.106zm0-20.355v14.248h-6.106V20.355h6.106zm0-20.355v14.248h-6.106V0h6.106z"
        />
        <path d="M24.458 187.974c2.507 2.078 3.558 4.91 3.154 8.497l-.045.36-6.476 18.253 2.59 7.247-.067.264a7.294 7.294 0 014.212-1.337c4.148 0 7.511 3.485 7.511 7.784 0 4.299-3.363 7.784-7.511 7.784-2.948 0-5.499-1.76-6.729-4.32l-.006.025-.062-.17a7.958 7.958 0 01-.43-1.188l-2.564-7.086-2.563 7.086a8.005 8.005 0 01-.306.9l-.124.288-.062.17-.006-.025c-1.23 2.56-3.781 4.32-6.729 4.32-4.148 0-7.511-3.485-7.511-7.784 0-4.3 3.363-7.784 7.511-7.784a7.3 7.3 0 014.212 1.337l-.067-.264 2.59-7.247-6.476-18.252-.045-.361c-.404-3.587.647-6.419 3.154-8.497l4.812 14.337 1.61 3.708 1.613-3.714 4.81-14.33zm3.368 36.505c-2.432 0-4.404 2.043-4.404 4.563 0 2.52 1.972 4.563 4.404 4.563 2.431 0 4.403-2.043 4.403-4.563 0-2.52-1.972-4.563-4.403-4.563zm-19.58 0c-2.432 0-4.404 2.043-4.404 4.563 0 2.52 1.972 4.563 4.403 4.563 2.432 0 4.404-2.043 4.404-4.563 0-2.52-1.972-4.563-4.404-4.563zm9.79-10.199l-.06.005c-.52.059-.924.514-.924 1.068 0 .593.464 1.073 1.036 1.073l-.053-.003.06-.003c.52-.058.924-.514.924-1.067 0-.575-.435-1.044-.983-1.073z" />
      </g>
    </svg>
  );
}

export default ScissorsLine;
