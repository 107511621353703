import _ from "lodash";
import { IServiceDetails, IServiceItem } from "../../store/services/model";
import { formatTimeOfDay } from "../../utilities/datetime";

export function renderDurationAsTime(durationInMin: number) {
  return formatTimeOfDay(durationInMin, "H:mm");
}

function renderDurationAsString(durationInMin: number, hrUnit: string, minUnit: string) {
  const durationHours = Math.floor(durationInMin / 60);
  const durationMinutes = durationInMin % 60;

  let durationAsString = durationHours > 0 ? durationHours + hrUnit : "";
  if (durationMinutes > 0) {
    durationAsString += (durationAsString ? " " : "") + durationMinutes + minUnit;
  }

  return durationAsString;
}

export function renderDurationAsStringShort(durationInMin: number) {
  return renderDurationAsString(durationInMin, "h", "m");
}

export function renderDurationAsStringLong(durationInMin: number) {
  return renderDurationAsString(durationInMin, " hr", " min");
}

export function getGroupedServices<T extends IServiceItem>(services: T[]) {
  return _.chain(services)
    .groupBy(item => {
      return item.shootType.id;
    })
    .map(value => ({ shootType: value[0].shootType, originalShootType: value[0].originalShootType, services: value }))
    .value();
}

export enum PaymentType {
  None,
  NoPayment,
  Partial,
  Full
}

export function getPaymentType(service: IServiceDetails | IServiceItem): PaymentType {
  if (service.useDeposit && service.deposit!.depositAmount === 0) {
    return PaymentType.NoPayment;
  }

  return service.useDeposit ? PaymentType.Partial : PaymentType.Full;
}

export function isZeroPrice(service: IServiceDetails | IServiceItem) {
  return service.price === 0;
}

export function isFreeService(service: IServiceDetails | IServiceItem) {
  return getPaymentType(service) === PaymentType.NoPayment || isZeroPrice(service);
}
