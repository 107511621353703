import { size } from "lodash";
import React from "react";

function PhotoIcon(props: { size: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={props.size}
      height={props.size}
      version="1.1"
      viewBox={`0 0 512 512`}
      xmlSpace="preserve"
    >
      <path d="M0 66v380h512V66H0zm482 350H51.213L192.5 274.713l58 58 134-134 97.5 97.5V416zm0-162.213l-97.5-97.5-134 134-58-58L30 394.787V96h452v157.787z"></path>
      <path d="M120 126c-33.084 0-60 26.916-60 60s26.916 60 60 60 60-26.916 60-60-26.916-60-60-60zm0 90c-16.542 0-30-13.458-30-30s13.458-30 30-30 30 13.458 30 30-13.458 30-30 30z"></path>
    </svg>
  );
}

export default PhotoIcon;
