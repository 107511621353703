import { SelectDropdown } from "@zenfolio/core-components";
import React, { ReactNode } from "react";
import ModalInfo from "../../../../../components/modalInfo";
import { IGoogleCalendar } from "../../../../../store/profile/googleCalendar/model";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import ModalDialog from "../../modalDialog";
import styles from "./index.module.scss";
import { ILoadCalendars, ISave } from "./model";
import withStore from "./withStore";

export interface ISelectCalendarOwnProps {
  currentCalendar: IGoogleCalendar | null;
  onClose: (calendarSelected: boolean) => void;
}

export interface ISelectCalendarProps extends ISelectCalendarOwnProps {
  loadCalendars: ILoadCalendars;
  save: ISave;
}

interface ISelectCalendarState {
  selectedCalendar?: IGoogleCalendar;
  showNoCalendarWarning?: boolean;
}

class SelectCalendar extends React.Component<ISelectCalendarProps, ISelectCalendarState> {
  public state: ISelectCalendarState = {};

  public componentDidMount() {
    const { calendars } = this.props.loadCalendars;
    if (!calendars) {
      this.props.loadCalendars.onLoad();
    } else if (calendars.length > 0) {
      this.setState({ selectedCalendar: this.props.currentCalendar || calendars[0] });
    }
  }

  public componentDidUpdate(prevProps: ISelectCalendarProps) {
    const { calendars, status: loadCalendarsStatus } = this.props.loadCalendars;
    const saveStatus = this.props.save.status;
    const loadCalendarsHasCompleted = hasActionCompleted(prevProps.loadCalendars.status, loadCalendarsStatus);
    const saveHasCompleted = hasActionCompleted(prevProps.save.status, saveStatus);

    if (loadCalendarsHasCompleted && calendars && calendars.length > 0) {
      this.setState({ selectedCalendar: this.props.currentCalendar || calendars[0] });
    }

    if (saveHasCompleted) {
      this.props.onClose(true);
    }
  }

  public render() {
    const { loadCalendars, save, currentCalendar } = this.props;
    const { selectedCalendar } = this.state;

    const savePending = save.status === "Pending";

    return (
      <ModalDialog
        title={`${currentCalendar ? "CHANGE" : "SELECT"} GOOGLE CALENDAR`}
        okText="Save"
        cancelText="Cancel"
        onCancel={this.onCancel}
        onOk={this.onOk}
        okDisabled={savePending || loadCalendars.status !== "Success" || !selectedCalendar}
        okPending={savePending}
      >
        <div className={styles.container}>
          <ModalInfo
            title="Select the Google Calendar that you will synchronize with BookMe."
            items={this.renderInfoItems()}
          />
          <div className={styles.calendarLabel}>Select a calendar</div>
          <SelectDropdown
            options={this.calendarOptions}
            value={this.selectedCalendarOption}
            onChange={this.onCalendarChange}
            isDisabled={loadCalendars.status !== "Success"}
            placeholder=""
            width={"100%"}
            minHeight={50}
            indicator={true}
            isSearchable={false}
            openMenuOnFocus={true}
            autoScroll={true}
          />
        </div>
      </ModalDialog>
    );
  }

  private get calendarOptions() {
    const calendars = this.props.loadCalendars.calendars;
    return calendars ? calendars.map(c => ({ label: c.name, value: c.id })) : undefined;
  }

  private get selectedCalendarOption() {
    const { selectedCalendar } = this.state;
    return selectedCalendar ? this.calendarOptions!.find(c => c.value === selectedCalendar.id) : undefined;
  }

  private renderInfoItems = () => {
    const { currentCalendar } = this.props;
    return (currentCalendar
      ? [
          <>
            Any changes you make to your appointments on <strong>{currentCalendar.name}</strong> will not send a
            notification to your client.
          </>,
          <>
            If you remove appointments from <strong>{currentCalendar.name}</strong> your clients will not be notified
            and the information shown on Zenfolio will not be up to date.
          </>
        ]
      : [
          "Appointments booked through BookMe will appear on this calendar.",
          "Events created on your Google Calendar will show you as unavailable for bookings in BookMe."
        ]) as ReactNode[];
  };

  private onCalendarChange = (option: any) =>
    this.setState({ selectedCalendar: this.props.loadCalendars.calendars!.find(c => c.id === option.value)! });

  private onCancel = () => this.props.onClose(!!this.props.currentCalendar);

  private onOk = () => this.props.save.onSave(this.state.selectedCalendar!);
}

export default withStore(SelectCalendar);
