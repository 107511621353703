import { Action } from "./actions";
import { ModalActionType } from "./constants";

export interface IModalState {
  modalType: string | null;
  modalProps: any;
}

const initialState: IModalState = {
  modalType: null,
  modalProps: {}
};

const modal = (state: IModalState = initialState, action: Action): IModalState => {
  switch (action.type) {
    case ModalActionType.SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    case ModalActionType.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default modal;
