import { IAddressPrediction } from "@zenfolio/core-components";
import { IAddressQuery, IPlaceDetails } from "@zenfolio/core-components/dist/models/location";
import _ from "lodash";
import { combineReducers } from "redux";
import IError from "../../utilities/error";
import { ActionStatus } from "../common";
import {
  ILoadPlaceDetailsFromLocationOrIpReset,
  LoadPlaceDetailsAction,
  LoadPlaceDetailsFromIpAction,
  LoadPlaceDetailsFromLocationAction,
  SearchAddressAction
} from "./actions";
import { ActionType } from "./constants";

export interface ISearchAddressState {
  status: ActionStatus;
  query: IAddressQuery | null;
  predictions: IAddressPrediction[] | null;
  error: IError | null;
}

const initialSearchAddressState: ISearchAddressState = {
  status: "Init",
  query: null,
  predictions: null,
  error: null
};

export const searchAddress = (
  state: ISearchAddressState = initialSearchAddressState,
  action: SearchAddressAction
): ISearchAddressState => {
  switch (action.type) {
    case ActionType.SEARCH_ADDRESS_RESET:
      return initialSearchAddressState;
    case ActionType.SEARCH_ADDRESS_CLEAR_PREDICTIONS:
      return {
        ...state,
        predictions: []
      };
    case ActionType.SEARCH_ADDRESS_STARTED:
      return {
        status: "Pending",
        query: action.query,
        predictions: action.seamless ? state.predictions : null,
        error: action.seamless ? state.error : null
      };
    case ActionType.SEARCH_ADDRESS_SUCCESS:
      return _.isEqual(action.query, state.query)
        ? {
            ...state,
            status: "Success",
            predictions: action.predictions,
            error: null
          }
        : state;
    case ActionType.SEARCH_ADDRESS_ERROR:
      return _.isEqual(action.query, state.query)
        ? {
            ...state,
            status: "Error",
            error: action.error,
            predictions: null
          }
        : state;
    default:
      return state;
  }
};

export interface ILoadPlaceDetailsState {
  status: ActionStatus;
  placeId: string | null;
  placeDetails: IPlaceDetails | null;
  error: IError | null;
}

const initialLoadPlaceDetailsState: ILoadPlaceDetailsState = {
  status: "Init",
  placeId: null,
  placeDetails: null,
  error: null
};

export const loadPlaceDetails = (
  state: ILoadPlaceDetailsState = initialLoadPlaceDetailsState,
  action: LoadPlaceDetailsAction
): ILoadPlaceDetailsState => {
  switch (action.type) {
    case ActionType.LOAD_PLACE_DETAILS_STARTED:
      return {
        status: "Pending",
        placeId: action.placeId,
        placeDetails: null,
        error: null
      };
    case ActionType.LOAD_PLACE_DETAILS_SUCCESS:
      return action.placeId === state.placeId
        ? {
            ...state,
            status: "Success",
            placeDetails: action.placeDetails
          }
        : state;
    case ActionType.LOAD_PLACE_DETAILS_ERROR:
      return action.placeId === state.placeId
        ? {
            ...state,
            status: "Error",
            error: action.error
          }
        : state;
    default:
      return state;
  }
};

export interface ILoadPlaceDetailsFromLocationOrIpState {
  loadFromLocation: ActionStatus;
  loadFromIpStatus: ActionStatus;
  placeDetails: IPlaceDetails | null;
  error: IError | null;
}

const initialILoadPlaceDetailsFromLocationOrIpState: ILoadPlaceDetailsFromLocationOrIpState = {
  loadFromLocation: "Init",
  loadFromIpStatus: "Init",
  placeDetails: null,
  error: null
};

export const loadPlaceDetailsFromLocationOrIpState = (
  state: ILoadPlaceDetailsFromLocationOrIpState = initialILoadPlaceDetailsFromLocationOrIpState,
  action: LoadPlaceDetailsFromLocationAction | LoadPlaceDetailsFromIpAction | ILoadPlaceDetailsFromLocationOrIpReset
): ILoadPlaceDetailsFromLocationOrIpState => {
  switch (action.type) {
    case ActionType.LOAD_PLACE_DETAILS_FROM_LOCATION_STARTED:
      return {
        ...state,
        loadFromLocation: "Pending",
        placeDetails: null,
        error: null
      };
    case ActionType.LOAD_PLACE_DETAILS_FROM_LOCATION_SUCCESS:
      return {
        ...state,
        loadFromLocation: "Success",
        placeDetails: action.placeDetails
      };
    case ActionType.LOAD_PLACE_DETAILS_FROM_LOCATION_ERROR:
      return {
        ...state,
        loadFromLocation: "Error",
        error: action.error
      };

    case ActionType.LOAD_PLACE_DETAILS_FROM_IP_STARTED:
      return {
        ...state,
        loadFromIpStatus: "Pending",
        placeDetails: null,
        error: null
      };
    case ActionType.LOAD_PLACE_DETAILS_FROM_IP_SUCCESS:
      return {
        ...state,
        loadFromIpStatus: "Success",
        placeDetails: action.placeDetails
      };
    case ActionType.LOAD_PLACE_DETAILS_FROM_IP_ERROR:
      return {
        ...state,
        loadFromIpStatus: "Error",
        error: action.error
      };
    case ActionType.LOAD_PLACE_DETAILS_RESET:
      return initialILoadPlaceDetailsFromLocationOrIpState;

    default:
      return state;
  }
};

export default combineReducers({
  searchAddress,
  loadPlaceDetails,
  loadPlaceDetailsFromLocationOrIpState
});
