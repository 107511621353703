import React from "react";

interface ICanvasWrapPlaceholderProps {
  className: string;
  title: string;
  style?: Object;
  offsetX : number;
  offsetY: number;
}

class CanvasWrapPlaceholder extends React.PureComponent<ICanvasWrapPlaceholderProps> {
  public render() {
    const { className, title, style, offsetX, offsetY } = this.props;
    return <text x={offsetX} y={offsetY} textAnchor="middle"
    dominantBaseline="central" className={className} style={style}>{title}</text>;
  }
}
export default CanvasWrapPlaceholder;
