import moment from "moment";

const iso3166DefaultCountryCode = "US";

function validDateOrDefault(date: moment.MomentInput) {
  return moment(date).isValid() ? moment(date) : moment();
}

export const parseDateAsUtc = (date: string): Date | undefined => {
  if (!date) {
    return undefined;
  }

  return moment.utc(date).toDate();
};

export enum stringFormat {
  US_LONG_FORMAT = "MMM DD, YYYY",
  US_SHORT_FORMAT = "MM/DD/YYYY",
  OTHER_LONG_FORMAT = "DD-MMM-YYYY",
  OTHER_SHORT_FORMAT = "DD MMM"
}

const TIME_FORMAT = "hh:mm:ss A";

export abstract class DateFormat {
  public static short(
    countryCode: string,
    date: moment.MomentInput,
    includeTime?: boolean,
    usShortFormat?: string
  ): string {
    return validDateOrDefault(date).format(DateFormat.shortStringFormat(countryCode, includeTime, usShortFormat));
  }

  public static long(
    countryCode: string,
    date: moment.MomentInput,
    includeTime?: boolean,
    usLongFormat?: string
  ): string {
    return validDateOrDefault(date).format(DateFormat.longStringFormat(countryCode, includeTime, usLongFormat));
  }

  public static shortStringFormat(countryCode: string, includeTime?: boolean, usShortFormat?: string): string {
    return DateFormat.getStringFormat(
      countryCode,
      usShortFormat || stringFormat.US_SHORT_FORMAT,
      stringFormat.OTHER_SHORT_FORMAT,
      includeTime
    );
  }

  public static longStringFormat(countryCode: string, includeTime?: boolean, usLongFormat?: string): string {
    return DateFormat.getStringFormat(
      countryCode,
      usLongFormat || stringFormat.US_LONG_FORMAT,
      stringFormat.OTHER_LONG_FORMAT,
      includeTime
    );
  }

  private static getStringFormat(
    countryCode: string,
    usFormat: string,
    otherFormat: string,
    includeTime?: boolean
  ): string {
    const upperCaseCountry = countryCode.toUpperCase();
    if (upperCaseCountry === iso3166DefaultCountryCode) {
      return includeTime ? `${usFormat} ${TIME_FORMAT}` : usFormat;
    }
    return includeTime ? `${otherFormat} ${TIME_FORMAT}` : otherFormat;
  }
}
