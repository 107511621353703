import cx from "classnames";
import moment from "moment";
import React from "react";
import { IComponentBaseProps } from "../../../models/props";
import { Utilities } from "../../../utilities/utilities";
import {
  CalendarMode,
  DatePickerCalendarContainer,
  DatePickerCalendarDays,
  DatePickerCalendarHeader,
  DatePickerCalendarMonths,
  DatePickerCalendarYears,
  DatePickerInput,
  DateStatusProvider,
  MonthChangeAction,
  NavigationDirection
} from "../models";
import styles from "./datePicker.module.scss";

const { shouldComponentUpdateDeep, resolveValue } = Utilities;

export interface IDatePickerProps extends IComponentBaseProps<HTMLDivElement> {
  date?: moment.Moment | null;
  defaultDate?: moment.Moment | null;
  open?: boolean;
  defaultOpen?: boolean;
  month?: moment.Moment;
  defaultMonth?: moment.Moment;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  format?: string;
  dateStatuses?: DateStatusProvider;
  countryCode?: string;
  components: {
    input: DatePickerInput;
    calendar: {
      container: DatePickerCalendarContainer;
      header: DatePickerCalendarHeader;
      days: DatePickerCalendarDays;
      years: DatePickerCalendarYears;
      months: DatePickerCalendarMonths;
    };
  };
  componentsProps?: {
    input?: IComponentBaseProps<HTMLDivElement>;
    calendar?: {
      container?: IComponentBaseProps<HTMLDivElement>;
      header?: IComponentBaseProps<HTMLDivElement>;
      days?: IComponentBaseProps<HTMLDivElement>;
      years?: IComponentBaseProps<HTMLDivElement>;
      months?: IComponentBaseProps<HTMLDivElement>;
    };
  };
  onOpen?: () => void;
  onClose?: () => void;
  onDateChange?: (date: moment.Moment | null) => void;
  onMonthChange?: (month: moment.Moment, action: MonthChangeAction) => void;
}

interface IDatePickerState {
  open?: boolean;
  date?: moment.Moment | null;
  month?: moment.Moment;
  mode: CalendarMode;
}

class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {
  public state: IDatePickerState = { mode: "days" };

  public shouldComponentUpdate(nextProps: IDatePickerProps, nextState: IDatePickerState): boolean {
    return shouldComponentUpdateDeep(this, nextProps, nextState);
  }

  public render() {
    const { placeholder, required, disabled, minDate, maxDate, dateStatuses } = this.props;
    const { format, countryCode, className, style, containerRef } = this.props;
    const { input, calendar } = this.props.componentsProps || {};
    const { open, date, month } = this;
    const { mode } = this.state;

    const DatePickerInput = this.props.components.input;
    const DatePickerCalendarContainer = this.props.components.calendar.container;
    const DatePickerCalendarHeader = this.props.components.calendar.header;
    const DatePickerCalendarDays = this.props.components.calendar.days;
    const DatePickerCalendarYears = this.props.components.calendar.years;
    const DatePickerCalendarMonths = this.props.components.calendar.months;

    return (
      <div className={cx(styles.container, className)} style={style} ref={containerRef}>
        <DatePickerInput
          date={date}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          format={format}
          countryCode={countryCode}
          className={input?.className}
          style={input?.style}
          containerRef={input?.containerRef}
          onOpenToggle={this.onInputOpenToggle}
          onClear={this.onInputClear}
        />
        <DatePickerCalendarContainer
          open={open}
          className={cx(styles.calendarContainer, calendar?.container?.className)}
          style={calendar?.container?.style}
          containerRef={calendar?.container?.containerRef}
          onClose={this.onCalendarContainerClose}
        >
          <DatePickerCalendarHeader
            mode={mode}
            month={month}
            className={calendar?.header?.className}
            style={calendar?.header?.style}
            containerRef={calendar?.header?.containerRef}
            onMonthClick={this.onCalendarHeaderMonthClick}
            onYearClick={this.onCalendarHeaderYearClick}
            onNavigate={this.onCalendarHeaderNavigate}
          />
          <DatePickerCalendarDays
            month={month}
            date={date}
            minDate={minDate}
            maxDate={maxDate}
            dateStatuses={dateStatuses}
            countryCode={countryCode}
            className={cx(styles.days, calendar?.days?.className, { [styles.hidden]: mode !== "days" })}
            style={calendar?.days?.style}
            containerRef={calendar?.days?.containerRef}
            onDateChange={this.onCalendarDaysDateChange}
          />
          {mode === "years" && (
            <DatePickerCalendarYears
              year={month.year()}
              className={cx(styles.years, calendar?.years?.className)}
              style={calendar?.years?.style}
              containerRef={calendar?.years?.containerRef}
              onYearChange={this.onCalendarYearsYearChange}
            />
          )}
          {mode === "months" && (
            <DatePickerCalendarMonths
              month={month.month()}
              className={cx(styles.months, calendar?.months?.className)}
              style={calendar?.months?.style}
              containerRef={calendar?.months?.containerRef}
              onMonthChange={this.onCalendarMonthsMonthChange}
            />
          )}
        </DatePickerCalendarContainer>
      </div>
    );
  }

  private get open() {
    return resolveValue(this.props.open, this.state?.open, this.props.defaultOpen, false);
  }

  private get date() {
    const defaultDate = this.props.required ? moment() : null;
    return resolveValue(this.props.date, this.state?.date, this.props.defaultDate, defaultDate);
  }

  private get month() {
    return resolveValue(this.props.month, this.state?.month, this.props.defaultMonth, this.date || moment());
  }

  private onInputOpenToggle = () => this.openOrClose(true);

  private onInputClear = () => {
    this.changeDate(null);
    this.openOrClose(false);
  };

  private onCalendarContainerClose = () => {
    this.openOrClose(false);
  };

  private onCalendarHeaderMonthClick = () => {
    this.setState({ mode: this.state.mode === "months" ? "days" : "months" });
  };

  private onCalendarHeaderYearClick = () => {
    this.setState({ mode: this.state.mode === "years" ? "days" : "years" });
  };

  private onCalendarHeaderNavigate = (direction: NavigationDirection) => {
    const month = this.month.clone().add(direction === "back" ? -1 : 1, "month");
    if (this.props.month === undefined) {
      this.setState({ month });
    }
    this.props.onMonthChange?.(month, direction === "back" ? "navigate-back" : "navigate-forward");
  };

  private onCalendarDaysDateChange = (date: moment.Moment) => {
    this.changeDate(date);
    this.openOrClose(false);
  };

  private onCalendarYearsYearChange = (year: number) => {
    this.setState({ mode: "days" });
    this.changeMonth(this.month.clone().year(year), "pick-year");
  };

  private onCalendarMonthsMonthChange = (month: number) => {
    this.setState({ mode: "days" });
    this.changeMonth(this.month.clone().month(month), "pick-month");
  };

  private openOrClose(open: boolean) {
    if (this.open === open) {
      return;
    }
    if (this.props.open === undefined) {
      this.setState({ open });
    }
    if (!open) {
      this.setState({ month: undefined });
    }
    (open ? this.props.onOpen : this.props.onClose)?.();
  }

  private changeDate(date: moment.Moment | null) {
    if (this.date === date || (this.date && date && this.date.isSame(date, "date"))) {
      return;
    }
    if (this.props.date === undefined) {
      this.setState({ date });
    }
    this.props.onDateChange?.(date);
  }

  private changeMonth = (month: moment.Moment, action: MonthChangeAction) => {
    if (this.month === month || (this.month && month && this.month.isSame(month, "month"))) {
      return;
    }
    if (this.props.month === undefined) {
      this.setState({ month });
    }
    this.props.onMonthChange?.(month, action);
  };
}

export default DatePicker;
