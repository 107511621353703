import classNames from "classnames";
import React from "react";
import crossIcon from "../../icons/cross-simple.svg";
import warning from "../../icons/warning-sm.svg";
import styles from "./alertError.module.scss";

interface IAlertError {
  message: string;
  onClose?: () => void;
  className?: string;
}

const AlertError: React.FC<IAlertError> = ({ message, onClose, className }) => (
  <div className={classNames(className, styles.status)}>
    <img src={warning} alt="warning" />
    {onClose && <img className={styles.cross} src={crossIcon} onClick={onClose} alt="close" />}
    <div>{message}</div>
  </div>
);

export default AlertError;
