import cx from "classnames";
import _ from "lodash";
import React from "react";
import PhoneInput, { Country, getCountries } from "react-phone-number-input";
import InputBasic from "react-phone-number-input/input";
import { Utilities } from "../../utilities/utilities";
import countries from "../../utilities/country/countries";
import flagAU from "./flags/AU.png";
import flagCA from "./flags/CA.png";
import flagUK from "./flags/UK.png";
import flagUS from "./flags/US.png";
import styles from "./phoneNumber.module.scss";

interface IPhoneNumberProps {
  disabled?: boolean;
  placeholder?: string;
  value: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  errorMessage?: string | null;
  className?: string;
  styles?: any;
  forceShowingError?: boolean;
  maxLength?: number;
  errorClassName?: string;
  withErrorClassName?: string;
  countryCode?: string;
  withFlag?: boolean;
  withCountrySelect?: boolean;
  flagClassName?: string;
  wrapperClassName?: string;
  onCountryChange?: (country: string) => void;
}

interface IPhoneNumberState {
  displayError: boolean;
}

export class PhoneNumber extends React.Component<IPhoneNumberProps, IPhoneNumberState> {
  public state: IPhoneNumberState = { displayError: false };
  private countriesMap: Record<string, Country[]> = {};

  public render() {
    const {
      value,
      placeholder,
      disabled,
      maxLength,
      withFlag,
      withCountrySelect,
      flagClassName,
      wrapperClassName
    } = this.props;
    const withErrorClassName = this.errorMessage && cx(styles.errorHighlighting, this.props.withErrorClassName);
    const className = cx(
      styles.container,
      this.props.className,
      withErrorClassName,
      (withFlag || withCountrySelect) && styles.withFlags
    );

    const getCountryFlag = (countryCode: string) => {
      switch (countryCode) {
        case Utilities.iso3166CanadaCode:
          return flagCA;
        case Utilities.iso3166UKCode:
          return flagUK;
        case Utilities.iso3166AustraliaCode:
          return flagAU;
        default:
          return flagUS;
      }
    };

    const countryCode = this.props.countryCode?.toUpperCase() || Utilities.iso3166DefaultCountryCode;

    const commonProps = {
      placeholder,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.props.onFocus,
      style: this.props.styles,
      disabled,
      maxLength
    };

    const inputComponent = <InputBasic country={countryCode as Country} className={className} {...commonProps} />;

    return (
      <>
        {withCountrySelect ? (
          <PhoneInput
            defaultCountry={countryCode as Country}
            onCountryChange={this.onCountryChange}
            international={false}
            addInternationalOption={false}
            initialValueFormat="national"
            countries={this.buildCountries(countryCode)}
            countryOptionsOrder={this.buildCountries(countryCode)}
            className={cx(styles.wrapper, wrapperClassName, withErrorClassName)}
            limitMaxLength={maxLength != null}
            numberInputProps={{ className }}
            countrySelectProps={{ tabIndex: -1 }}
            {...commonProps}
          />
        ) : withFlag ? (
          <div className={cx(styles.wrapper, wrapperClassName)}>
            <div
              className={cx(styles.flags, withErrorClassName, flagClassName)}
              style={{ backgroundImage: `url(${getCountryFlag(countryCode)})` }}
            />
            {inputComponent}
          </div>
        ) : (
          inputComponent
        )}
        {this.errorMessage && <div className={cx(styles.error, this.props.errorClassName)}>{this.errorMessage}</div>}
      </>
    );
  }

  private onChange = (value: string) => {
    this.props.onChange?.(value || "");
  };

  private onBlur = () => {
    this.setState({ displayError: true }, () => {
      this.props.onBlur?.();
    });
  };

  private onCountryChange = (country?: Country) => {
    if (country) {
      this.setState({ displayError: true }, () => {
        this.props.onCountryChange?.(country);
      });
    }
  };

  private buildCountries(defaultCountry: string) {
    let result = this.countriesMap[defaultCountry];
    if (!result) {
      result = _.chain(countries)
        .map(c => c.isoCode)
        .orderBy([c => c != defaultCountry])
        .intersection(getCountries())
        .value() as Country[];
      this.countriesMap[defaultCountry] = result;
    }

    return result;
  }

  private get errorMessage() {
    if (!this.props.forceShowingError && !this.state.displayError) {
      return undefined;
    }

    return this.props.errorMessage;
  }
}
