export type Callback<T> = (data: T) => void;

export interface ICallbackEventOptions {
  invokeOnSubscription?: boolean;
  invokeOnSubscriptionTimeout?: number;
}

abstract class CallbackEvent<T> {
  public constructor(options?: ICallbackEventOptions) {
    this.options = options || {};
  }

  public subscribe(callback: Callback<T>) {
    const { invokeOnSubscription, invokeOnSubscriptionTimeout } = this.options;

    this.callbacks.push(callback);

    if (this.callbacks.length === 1) {
      this.registerEvent();
    } else if (invokeOnSubscription && this.data) {
      if (invokeOnSubscriptionTimeout === undefined) {
        callback(this.data);
      } else {
        setTimeout(() => this.data && callback(this.data), invokeOnSubscriptionTimeout);
      }
    }
  }

  public unsubscribe(callback: Callback<T>) {
    const index = this.callbacks.indexOf(callback);

    if (index >= 0) {
      this.callbacks.splice(index, 1);

      if (this.callbacks.length === 0) {
        this.unregisterEvent();
      }
    }
  }

  protected get eventCallback(): Callback<T> {
    return this.invokeCallbacks;
  }

  protected abstract registerEvent(): void;

  protected abstract unregisterEvent(): void;

  private readonly invokeCallbacks = (data: T) => {
    this.data = data;

    for (const callback of this.callbacks) {
      callback(data);
    }
  };

  private data?: T;
  private readonly callbacks: Array<Callback<T>> = [];
  private readonly options: ICallbackEventOptions;
}

export default CallbackEvent;
