import React, { Ref, Fragment } from "react";

// This Mat type suffix can be removed later
// When frame images are uploaded to S3

interface IFrameProps {
  /** Original product size width (pixel) */
  productWidth: number;
  /** Original product size height (pixel) */
  productHeight: number;
  /** Frame uniq key 
   */
  frameKey: string;
  /** Original frame width (pixel) */
  frameWidth: number;
  forwardedRef?: Ref<SVGSVGElement>;
  isDisable?: boolean;
  /** Direct url of frame fragment */
  edgesSrc?: {
    left: string;
    top: string;
    right: string;
    bottom: string;
  }
}

interface IFrameState {
  isExceptional: boolean;
}

export class Frame extends React.PureComponent<IFrameProps, IFrameState> {
  public state = {
    isExceptional: false
  }

  private imageRef = React.createRef<HTMLImageElement>();

  public componentDidMount() {
    this.detectExceptionalFrame();
  }

  public componentDidUpdate() {
    this.detectExceptionalFrame();
  }

  private detectExceptionalFrame = () => {
    const nw = this.imageRef?.current?.offsetWidth || 0;
    const nh = this.imageRef?.current?.offsetHeight || 0;
    if (Math.max(nw, nh) <= 1000) {
      this.setState({ isExceptional: true });
    }
  }

  public render() {
    const {
      productWidth,
      productHeight,
      frameKey,
      edgesSrc,
      isDisable
    } = this.props;
    const { isExceptional } = this.state;
    const { frameWidth } = this.props;

    const leftEdge = `${frameKey}-left`;
    const topEdge = `${frameKey}-top`;
    const rightEdge = `${frameKey}-right`;
    const bottomEdge = `${frameKey}-bottom.`;

    if (isDisable) { return this.props.children; }
    return (
      <Fragment>
        <svg
          viewBox={`0 0 ${productWidth} ${productHeight}`}
          ref={this.props.forwardedRef}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          <defs>
            <pattern id={leftEdge} width={1} height={1}>
              <image
                href={edgesSrc?.left || `${leftEdge}`}
                x="0" y="0"
                width={frameWidth}
                height={isExceptional ? productHeight : undefined}
                preserveAspectRatio={isExceptional ? "none" : undefined} />
            </pattern>
          </defs>
          <defs>
            <pattern id={topEdge} width={1} height={1}>
              <image

                href={edgesSrc?.top || `${topEdge}`}
                x="0" y="0"
                height={frameWidth}
                width={isExceptional ? productWidth : undefined}
                preserveAspectRatio={isExceptional ? "none" : undefined} />
            </pattern>
          </defs>
          <defs>
            <pattern id={rightEdge} width={1} height={1}>
              <image
                href={edgesSrc?.right || `${rightEdge}`}
                x="0" y="0"
                width={frameWidth}
                height={isExceptional ? productHeight : undefined}
                preserveAspectRatio={isExceptional ? "none" : undefined} />
            </pattern>
          </defs>
          <defs>
            <pattern id={bottomEdge} width={1} height={1}>
              <image href={edgesSrc?.bottom || `${bottomEdge}`}
                x="0" y="0"
                height={frameWidth}
                width={isExceptional ? productWidth : undefined}
                preserveAspectRatio={isExceptional ? "none" : undefined} />
            </pattern>
          </defs>

          {/* A frame is formed by 4 edges
      An Edge is shaped by 4 points
      Each point is defined by a pair (x, y) in the user coordinate system
      Each pair is separated by a space */}

          {/* A frame have width, height and thickness of its edges 
      based on that, we determine polygon points position.*/}

          {/* polygon points of left edge
      (topLeft, topRight, bottomRight, bottomLeft)*/}
          {this.props.children}
          <polygon points={`0,0 ${frameWidth},${frameWidth} ${frameWidth},${productHeight - frameWidth} 0,${productHeight}`} fill={`url(#${leftEdge})`} />
          {/* polygon points of top edge
      (topLeft, topRight, bottomRight, bottomLeft)*/}
          <polygon points={`0,0 ${productWidth},0 ${productWidth - frameWidth},${frameWidth} ${frameWidth},${frameWidth}`} fill={`url(#${topEdge})`} />
          {/* polygon points of right edge
      (bottomLeft, topLeft, topRight, bottomRight)*/}
          <polygon points={`${productWidth - frameWidth},${productHeight - frameWidth} ${productWidth - frameWidth},${frameWidth} ${productWidth},0 ${productWidth},${productHeight}`} fill={`url(#${rightEdge})`} />
          {/* polygon points of bottom edge
      (bottomRight, bottomLeft, topLeft, topRight)*/}
          <polygon points={`${productWidth},${productHeight} 0,${productHeight} ${frameWidth},${productHeight - frameWidth} ${productWidth - frameWidth},${productHeight - frameWidth}`} fill={`url(#${bottomEdge})`} />
        </svg>
        <img
          src={edgesSrc?.top || `${topEdge}`}
          ref={this.imageRef}
          style={{ visibility: "hidden" }}
        />
      </Fragment>
    )
  }
}
