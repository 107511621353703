import React, { PureComponent } from "react";
import styles from "./lightbox.module.scss";
import Close from "../Icons/ClientView/Close";
import Caret from "../Icons/Caret";
import colors from "utilities/colors";
import cx from "classnames";
import { Swipeable } from "react-swipeable";
import ContextMenu from "utilities/contextMenu";
import { universalWindow as window } from "utilities/blocks/site";
import { getButtonAction } from "../ClientView/CommonUI/Views/imgButtonAction";
import CartAddedIcon from "../ClientView/CommonUI/Views/components/Media/icons/icon-cart-added.svg";
import CartHover from "../ClientView/CommonUI/Views/components/Media/icons/icon-cart-hover-black.svg";
import CartHoverClick from "../ClientView/CommonUI/Views/components/Media/icons/icon-cart-hover-added.svg";
import CartHoverRemove from "../ClientView/CommonUI/Views/components/Media/icons/icon-cart-hover-remove.svg";
import Cart from "../ClientView/CommonUI/Views/components/Media/icons/icon-cart-black.svg";
import QuickShopPreview, { IQuickShopItem } from "../ClientView/CommonUI/Views/components/QuickShop/Preview";
import { ICartItem } from "../ClientView/models";
import { NameType } from "../blocks/blockModels";
import { Utilities } from "utilities/utilities";
import { isGIFPhoto } from "utilities/image";
import CartIcon from "icons/cart.svg";
import { isMobile } from "react-device-detect";
import isEqual from "lodash/isEqual";
import NzVideo from "../NzVideo";
import { IVideo } from "../../models/models";
import { IAnalyticParams } from "../NzVideo/Player";
import Caption from "./Caption";
import { PageContext } from "utilities/pageContext";
import { NAME_TYPE } from "utilities/galleryPresentSetting";

export interface ILightboxZoomImage {
  url: string;
  urlZoom: string;
  urlZoomMax: string;
  fileName: string;
  caption?: string;
  name?: string;
  id?: string;
  isVideo?: boolean;
}

export interface LightBoxGalleryVideo {
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  video: IVideo;
  url: string;
  urlZoom?: string;
  urlZoomMax?: string;
  fileName?: string;
  name?: string;
  caption?: string;
  id?: string;
  isVideo?: boolean;
}

export type LightBoxGalleryItem = ILightboxZoomImage | LightBoxGalleryVideo;

export interface ILightBoxProps {
  images: LightBoxGalleryItem[];
  selectedIndexImage: number;
  nameType?: NameType;
  cartItems?: ICartItem[];
  showImgName?: boolean;
  isEcom?: boolean;
  allowZoom?: boolean;
  quickShopItems?: IQuickShopItem[];
  videoAnalyticsParams?: IAnalyticParams;
  cartBadge?: number;
  onClickButtonCart?: () => void;
  onClose?: () => void;
  onShowShopLightbox?: (index: number) => void;
  onClickQuickShopItem?: (e: React.MouseEvent<HTMLElement>, photo: ILightboxZoomImage) => void;
}

interface ILightBoxState {
  cartItems?: ICartItem[];
  selectedIndex: number;
  applyZoom: boolean;
  hoveredIcon: number;
  clickedIcon: number;
  isShowQuickShop: boolean;
  photosInCart: string[];
}

class LightBoxZoom extends PureComponent<ILightBoxProps, ILightBoxState> {
  public static contextType = PageContext;
  public context!: React.ContextType<typeof PageContext>;

  constructor(props: ILightBoxProps) {
    super(props);
    this.state = {
      selectedIndex: props.selectedIndexImage,
      applyZoom: false,
      hoveredIcon: -1,
      clickedIcon: -1,
      isShowQuickShop: false,
      photosInCart: [],
      cartItems: props.cartItems || []
    };
  }

  public componentDidMount() {
    window.addEventListener("keydown", this.keyPressHandler);
    // In preview mode we change vertical overflow to auto so we can scroll only inside lightbox
    // The style will be removed in unmount
    if (this.context.isPreview) {
      // Get the body of main document instead of preview iframe
      const docBodyEl = window.parent.document.body;
      docBodyEl.style.overflowY = "auto";
    }

    this.setState({
      photosInCart: Utilities.getAllCartPhotos(this.props.cartItems || [])
    });
  }

  public componentWillUnmount() {
    window.removeEventListener("keydown", this.keyPressHandler);
    if (this.context.isPreview) {
      const docBodyEl = window.parent.document.body;
      docBodyEl.style.overflowY = "";
    }
  }

  public static getDerivedStateFromProps(props: ILightBoxProps, state: ILightBoxState) {
    if (!isEqual(state.cartItems, props.cartItems)) {
      return {
        cartItems: props.cartItems,
        photosInCart: Utilities.getAllCartPhotos(props.cartItems || [])
      };
    }
    return null;
  }

  private handleLeftClick = () => {
    const { selectedIndex, applyZoom } = this.state;
    if (selectedIndex > 0 && !applyZoom) {
      this.setState({ selectedIndex: selectedIndex - 1 });
    }
  };

  private handleRightClick = () => {
    const { selectedIndex, applyZoom } = this.state;
    if (selectedIndex < this.props.images.length - 1 && !applyZoom) {
      this.setState({ selectedIndex: selectedIndex + 1 });
    }
  };

  private close = () => {
    const { applyZoom } = this.state;
    if (applyZoom) {
      this.setState({ applyZoom: false });
    } else if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private getImgZoomSrc = () => {
    const { selectedIndex } = this.state;
    if (typeof window !== "undefined") {
      const devicePixelRatio = window.devicePixelRatio || 1;
      const maxScreenDimension = Math.max(window.screen.width, window.screen.height);
      const physicalResolution = Math.ceil(maxScreenDimension * devicePixelRatio);

      // 4K monitors
      if (physicalResolution >= 3840) {
        return this.props.images[selectedIndex].urlZoomMax;
      }
    }

    // everything else
    return this.props.images[selectedIndex].urlZoom;
  };

  private handleCloseClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  private handleImageClick = () => {
    const { applyZoom } = this.state;
    if (this.props.allowZoom) {
      this.setState({ applyZoom: !applyZoom });
    }
  };

  private keyPressHandler = (e: any) => {
    if (e.keyCode === 27) {
      this.close();
    }
    if (e.keyCode === 37) {
      this.handleLeftClick();
    }
    if (e.keyCode === 39) {
      this.handleRightClick();
    }
  };

  private setClickedIcon = (index: number) => {
    this.setState({ clickedIcon: index });
  };

  private setHoveredIcon = (index: number) => {
    this.setState({ hoveredIcon: index });
  };

  private handleShowShopLightbox = () => {
    const { selectedIndex } = this.state;
    const { onShowShopLightbox } = this.props;
    if (onShowShopLightbox) {
      onShowShopLightbox(selectedIndex);
    }
  };

  private handleShowQuickShopLightBox = () => {
    this.setState({ isShowQuickShop: true });
  };

  private handleCloseQuickShopLightBox = () => {
    this.setState({ isShowQuickShop: false });
  };

  private addItemToCartFromQuickShop = (e: React.MouseEvent<HTMLElement>) => {
    const { onClickQuickShopItem, images } = this.props;
    const currentPhoto = images[this.state.selectedIndex] as ILightboxZoomImage;
    if (onClickQuickShopItem) {
      onClickQuickShopItem(e, currentPhoto);
    }
  };

  private handleClickCart = () => {
    const { quickShopItems } = this.props;
    const isQuickShopEnabled = Utilities.checkQuickShopEnabled(quickShopItems);
    if (isQuickShopEnabled && isMobile) {
      this.handleShowQuickShopLightBox();
    } else {
      this.handleShowShopLightbox();
    }
  };

  private renderIcons = () => {
    const { selectedIndex } = this.state;
    const currentPhoto = this.props.images[selectedIndex];
    const cartIcon: string = getButtonAction(
      3,
      3,
      this.state.clickedIcon,
      this.state.hoveredIcon,
      false,
      Cart,
      CartHover,
      CartHoverClick,
      CartAddedIcon,
      CartHoverRemove
    );

    return (
      this.props.isEcom &&
      !currentPhoto.isVideo && (
        <div className={cx(styles.icon, styles.iconMargin)}>
          <img
            onMouseEnter={() => this.setHoveredIcon(3)}
            onMouseLeave={() => this.setHoveredIcon(-1)}
            onMouseDown={() => this.setClickedIcon(3)}
            onMouseUp={() => this.setClickedIcon(-1)}
            onClick={this.handleClickCart}
            src={cartIcon}
            alt=""
          />
        </div>
      )
    );
  };

  private getImageName = (nameType: NameType, idx: number, total: number, image: LightBoxGalleryItem): string => {
    return nameType === NAME_TYPE.sequenceNumber
      ? Utilities.addLeadingZeros(idx + 1, total)
      : image.name || image.fileName!;
  };

  public render() {
    const { applyZoom, selectedIndex, isShowQuickShop } = this.state;
    const {
      isEcom,
      images,
      cartBadge,
      showImgName,
      quickShopItems,
      videoAnalyticsParams,
      nameType = NAME_TYPE.sequenceNumber,
      onClickButtonCart
    } = this.props;

    let currentPhoto = images[selectedIndex];
    const imageName = this.getImageName(nameType, selectedIndex, images.length, currentPhoto);
    const currentPhotoImg = images[selectedIndex].urlZoom;
    const currentVideo = images[selectedIndex].isVideo ? (images[selectedIndex] as LightBoxGalleryVideo) : null;

    if (applyZoom) {
      return (
        <div className={styles.zoom}>
          <img
            className={styles.zoomedImage}
            alt={images[selectedIndex].fileName}
            src={this.getImgZoomSrc()}
            onClick={this.handleImageClick}
            onContextMenu={ContextMenu.handleBlockContextMenu}
          />
        </div>
      );
    } else {
      return (
        <div>
          <Swipeable onSwipedRight={this.handleLeftClick} onSwipedLeft={this.handleRightClick}>
            {isShowQuickShop && quickShopItems && (
              <QuickShopPreview
                className={styles.quickShopPreview}
                items={quickShopItems}
                media={currentPhoto as ILightboxZoomImage}
                imageUrl={currentPhotoImg}
                onClickShopAllProduct={this.handleShowShopLightbox}
                onClickAddItem={this.addItemToCartFromQuickShop}
                onCloseQuickShop={this.handleCloseQuickShopLightBox}
                isGIFPhoto={isGIFPhoto(currentPhotoImg)}
              />
            )}
            <div style={{ height: `${window.innerHeight}px` }} className={styles.lightBox}>
              <div className={styles.header}>
                <div className={styles.backButton} onClick={this.handleCloseClick}>
                  <Close color={colors.white} size={16} />
                </div>
                <div className={styles.actions}>{this.renderIcons()}</div>
                {isEcom && (
                  <div className={styles.cartSection}>
                    <div className={styles.viewCart} onClick={onClickButtonCart}>
                      View Cart
                    </div>
                    <div onClick={onClickButtonCart} className={styles.cartIcon}>
                      <img src={CartIcon} />
                      {!!cartBadge && <span className={styles.cartBadge}>{cartBadge}</span>}
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.content}>
                {selectedIndex !== 0 && (
                  <div className={cx(styles.arrow, styles.back)} onClick={this.handleLeftClick}>
                    <Caret color={colors.white} size={16} minX={0} minY={0} open={false} />
                  </div>
                )}
                {currentVideo ? (
                  <NzVideo
                    video={currentVideo.video}
                    analyticsParams={videoAnalyticsParams}
                    thumbnailUrlTemplate={currentVideo.thumbnailUrlTemplate}
                    videoUrlTemplate={currentVideo.videoUrlTemplate}
                    imagePlacement="contain"
                    playable={true}
                    preload={false}
                    style={{ height: "100%" }}
                    autoplay={true}
                  />
                ) : (
                  <div className={styles.lightBoxContent}>
                    <div
                      className={cx(styles.imgContainer, { [styles.zoom]: this.props.allowZoom })}
                      style={{
                        width: "100%",
                        height: `100%`,
                        backgroundImage: `url(${images[selectedIndex].url})`
                      }}
                      onClick={this.handleImageClick}
                      onContextMenu={ContextMenu.handleBlockContextMenu}
                    />
                  </div>
                )}
                {showImgName && !currentVideo && (
                  <Caption name={imageName} caption={currentPhoto.caption} className={styles.descriptionWrapper} />
                )}
                {selectedIndex !== images.length - 1 && (
                  <div className={styles.arrow} onClick={this.handleRightClick}>
                    <Caret color={colors.white} size={16} minX={0} minY={0} open={false} />
                  </div>
                )}
              </div>
            </div>
          </Swipeable>
        </div>
      );
    }
  }
}

export default LightBoxZoom;
