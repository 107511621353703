import { connect } from "react-redux";
import { injectStripe } from "react-stripe-elements";
import { ThunkDispatch } from "redux-thunk";
import PaymentStep from "../../../../../components/widget/steps/payment/booking";
import { IAppState } from "../../../../../store/state";
import {
  doCalculateServiceTax,
  doCreateBooking,
  doCreateFreeServiceBooking
} from "../../../../../store/widget/actions";
import { IBookingCreator, ICalculateServiceTaxQuery, IPaymentCreator } from "../../../../../store/widget/model";

function mapStateToProps({ widget }: IAppState) {
  return {
    createBookingState: widget.createBookingState,
    photographerTimezoneOffsetMinutes: widget.loadWidgetInfo.photographerTimezoneOffsetMinutes,
    businessName: widget.loadWidgetInfo.photographerBusinessName,
    calculateServiceTaxState: widget.calculateServiceTaxState
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    onCreateBooking: (paymentCreator: IPaymentCreator, bookingCreator: IBookingCreator) =>
      dispatch(doCreateBooking(paymentCreator, bookingCreator)),
    onCreateFreeServiceBooking: (bookingCreator: IBookingCreator) =>
      dispatch(doCreateFreeServiceBooking(bookingCreator)),
    onCalculateServiceTax: (query: ICalculateServiceTaxQuery) => dispatch(doCalculateServiceTax(query))
  };
}

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(PaymentStep));
