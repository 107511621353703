import React, { Fragment, useState, useEffect } from "react";
import styles from "./dropdownPropertyTree.module.scss";
import cx from "classnames";
import { INavigationMenuItem } from "models/models";

export interface IDropdownPropertyTreeProps {
  pages: INavigationMenuItem[];
  interactionPage: string;
  style?: any;
  onSetValue?: (pageId: string | null) => void;
  handleMenuOpen?: () => void;
}

export type Matcher = ((node: INavigationMenuItem, path: INavigationMenuItem[]) => boolean) | string;

export const findNodeInTree = (
  node: INavigationMenuItem,
  matcher: Matcher,
  path: INavigationMenuItem[] = []
): [INavigationMenuItem | null, INavigationMenuItem[] | null] => {
  let matchFunction;
  if (typeof matcher === "string") {
    // If passed in a string, compare by ID, otherwise invoke comparator as function
    matchFunction = (node: INavigationMenuItem) => {
      return node.id === matcher;
    };
  } else {
    matchFunction = matcher;
  }

  if (matchFunction(node, path)) {
    return [node, path];
  }

  if (!node.childs?.length) return [null, null];

  for (let i = 0; i < (node.childs?.length || 0); i += 1) {
    let childNode = node.childs[i];

    const [resultNode, resultPath] = findNodeInTree(childNode, matcher, [...path, node]);
    if (resultNode) return [resultNode, resultPath];
  }

  return [null, null];
};

function searchNode(
  node: INavigationMenuItem,
  matchingId: string,
  path: string,
  getPath?: false
): INavigationMenuItem | null;
function searchNode(node: INavigationMenuItem, matchingId: string, path: string, getPath?: true): string;
function searchNode(
  node: INavigationMenuItem,
  matchingId: string,
  path: string,
  getPath: boolean = false
): INavigationMenuItem | string | null {
  const [matchingNode, matchingPath] = findNodeInTree(node, matchingId);

  if (!matchingNode) return null;

  if (getPath) {
    return [...(matchingPath || []), matchingNode]
      .filter(Boolean)
      .map(node => node.menuLink)
      .join("/");
  }

  return matchingNode;
}

export { searchNode };

const DropdownPropertyTree: React.FC<IDropdownPropertyTreeProps> = props => {
  const parentNode: INavigationMenuItem = {
    id: "parent_id",
    menuCaption: "parent_caption",
    menuName: "parent_name",
    childs: props.pages
  };
  const [dropdownState, setDropdown] = useState<boolean>(false);
  const page = props.interactionPage ? searchNode(parentNode, props.interactionPage, "") : null;

  const bem = (style: string) => {
    return styles[style];
  };
  const DownChevron = () => (
    <svg id="downChevronTree" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#1E1F22"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 10l4 4 4-4"
      />
    </svg>
  );

  const addClickListener = (signal: any) => {
    if (signal) {
      window.addEventListener("mousedown", (evt: any) => {
        const clicked = evt.target;
        if (
          (clicked.id !== "dropdownPropertyTree__options" &&
            clicked &&
            clicked.classList.length > 0 &&
            clicked.classList[0].search("dropdownPropertyTree") !== 0) ||
          (clicked && clicked.classList[0] === undefined && clicked.id !== "downChevronTree")
        ) {
          setDropdown(false);
        }
      });
    }
  };

  const handleDropdownClick = () => {
    if (!dropdownState && props.handleMenuOpen) {
      props.handleMenuOpen();
    }
    setDropdown(!dropdownState);
  };

  const handlePageClick = (page: INavigationMenuItem | null) => {
    props.onSetValue?.(page?.id || null);
    setDropdown(false);
  };

  useEffect(() => {
    const abortController = new AbortController();

    addClickListener(abortController.signal);
    return () => {
      window.removeEventListener("click", () => null);
      abortController.abort();
    };
  }, []);

  const mapPagesItems = (pages: INavigationMenuItem[], style: any) => {
    return pages.map((page: INavigationMenuItem, index: number) => (
      <Fragment key={`${page.menuCaption}`}>
        <div
          style={style}
          className={bem("dropdownPropertyTree__page")}
          onMouseDown={e => {
            e.preventDefault();
            handlePageClick(page);
          }}
        >
          <div>{page.menuCaption}</div>
        </div>
        {page &&
          page.childs &&
          page.childs.length > 0 &&
          mapPagesItems(page.childs, {
            marginLeft: "20px",
            width: "calc(100% - 20px)"
          })}
      </Fragment>
    ));
  };

  return (
    <div style={props.style ? props.style : {}} className={bem("dropdownPropertyTree")}>
      {props.pages.length > 0 && (
        <div className={bem("dropdownPropertyTree__controlParent")}>
          <div id="dropdownPropertyTree" className={cx(bem("dropdownPropertyTree__control"), "dropdownPropertyTree")}>
            <div onMouseDown={handleDropdownClick} className={bem("dropdownPropertyTree__selection")}>
              <div className={bem("dropdownPropertyTree__selection--text")}>{page ? page.menuCaption : ""}</div>
              <div className={bem("dropdownPropertyTree__selection--arrow")}>
                <DownChevron />
              </div>
            </div>
            {dropdownState && (
              <div id="optionsContainer" className={bem("dropdownPropertyTree__optionsContainer")}>
                <div id="dropdownPropertyTree__options" className={bem("dropdownPropertyTree__options")}>
                  {/* Empty option. Allows unlinking a page, and shows up when there's no selected page for a link */}
                  <div
                    className={bem("dropdownPropertyTree__page")}
                    onMouseDown={e => {
                      e.preventDefault();
                      handlePageClick(null);
                    }}
                  >
                    <div>&nbsp;</div>
                  </div>
                  {mapPagesItems(props.pages, { marginLeft: "0px" })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownPropertyTree;
