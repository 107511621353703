import classNames from "classnames";
import _ from "lodash";
import * as React from "react";
import Transition, { TransitionProps } from "react-transition-group/Transition";
import { TIMEOUT, TRANSITION_CLASS_MAP, TRANSITION_KEYS } from "./constants";
import greySVG from "./grey.svg";
import styles from "./index.module.scss";
import { getNodeHeight } from "./utils";

interface IPanelProps extends TransitionProps {
  name: string;
  title: React.ReactNode;
  open: boolean;
  onChange?: (name: string, open: boolean) => void;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  step?: React.ReactNode;
  showArrow: boolean;
}

interface IPanelState {
  open: boolean;
  height: number | null;
}

class Panel extends React.Component<IPanelProps, IPanelState> {
  public static defaultProps = {
    open: false,
    appear: false,
    enter: true,
    exit: true,
    timeout: TIMEOUT.COLLAPSE,
    showArrow: true
  };

  constructor(props: IPanelProps) {
    super(props);

    this.state = {
      height: null,
      open: props.open
    };
  }

  public componentDidUpdate(prevProps: IPanelProps, prevState: IPanelState) {
    const { open } = this.props;
    if (open !== prevProps.open) {
      this.setState({ open });
    }
  }

  public render() {
    const {
      className,
      headerClassName,
      contentClassName,
      children,
      timeout,
      title,
      step,
      showArrow,
      ...attrs
    } = this.props;
    const { height, open } = this.state;
    const transitionProps = _.pick(attrs, TRANSITION_KEYS);
    const childProps = _.omit(attrs, TRANSITION_KEYS);

    return (
      <div className={classNames(className, styles.container)}>
        <div className={classNames(headerClassName, styles.header)} onClick={this.toggle}>
          <h3>
            {step && <span className={styles.step}>{step}</span>}
            <span className={styles.title}>{title}</span>
          </h3>
          {showArrow && (
            <img
              className={styles.arrow}
              src={greySVG}
              alt="arrow icon"
              style={!open ? { transform: "rotate(180deg)" } : undefined}
            />
          )}
        </div>

        <Transition
          {...transitionProps}
          in={open}
          onEntering={this.onEntering}
          onEntered={this.onEntered}
          onExit={this.onExit}
          onExiting={this.onExiting}
          onExited={this.onExited}
          timeout={timeout}
        >
          {status => {
            const style = {
              height: height || undefined,
              display: (status !== "exited" && "block") || undefined
            };

            const classes = classNames(contentClassName, TRANSITION_CLASS_MAP[status] || styles.Panel);

            return (
              <div {...childProps} style={{ ...style }} className={classes}>
                {children}
              </div>
            );
          }}
        </Transition>
      </div>
    );
  }

  public onEntering = (node: HTMLElement, isAppearing: boolean) => {
    this.setState({ height: getNodeHeight(node) });
    if (this.props.onEntering) {
      this.props.onEntering(node, isAppearing);
    }
  };

  public onEntered = (node: HTMLElement, isAppearing: boolean) => {
    this.setState({ height: null });
    if (this.props.onEntered) {
      this.props.onEntered(node, isAppearing);
    }
  };

  public onExit = (node: HTMLElement) => {
    this.setState({ height: getNodeHeight(node) });

    if (this.props.onExit) {
      this.props.onExit(node);
    }
  };

  public onExiting = (node: HTMLElement) => {
    this.setState({ height: 0 });
    if (this.props.onExiting) {
      this.props.onExiting(node);
    }
  };

  public onExited = (node: HTMLElement) => {
    this.setState({ height: null });
    if (this.props.onExited) {
      this.props.onExited(node);
    }
  };

  private toggle = () => {
    const { onChange, name } = this.props;

    const open = !this.state.open;
    this.setState({ open });

    if (onChange) {
      onChange(name, open);
    }
  };
}

export default Panel;
