import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IDeleteInvoiceOwnProps, IDeleteInvoiceProps } from ".";
import { deleteInvoice as deleteInvoiceAction } from "../../../../../store/billing/actions";
import { IAppState } from "../../../../../store/state";

function mapStateToProps({ billing: { deleteInvoice } }: IAppState) {
  return { deleteInvoice };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    deleteInvoice: {
      onDelete: (invoiceId: string) => dispatch(deleteInvoiceAction(invoiceId))
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: IDeleteInvoiceOwnProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    deleteInvoice: { ...stateProps.deleteInvoice, ...dispatchProps.deleteInvoice }
  } as IDeleteInvoiceProps;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
