import { ICollectionOptions, ICollection } from "../../../../models";
import { COLLECTION } from "utilities/constant";

export const getCollectionsOptions = (collectionsArray: ICollection[], skipIndices?: boolean): ICollectionOptions[] => {
  const result: ICollectionOptions[] = [{ collectionId: COLLECTION.ALL_MEDIA_ID, name: COLLECTION.ALL_MEDIA }];
  const collections = collectionsArray?.filter(collection => collection.name !== COLLECTION.EXCLUDE_COLLECTION);

  if (collections) {
    collections
      .filter(collection => (skipIndices ? true : collection.mediaSortIndices.length >= 0))
      .forEach(collection => {
        result.push({
          collectionId: collection.collectionId ? collection.collectionId : collection.id,
          name: collection.name
        });
      });
  }

  return result;
};

export const getActiveCollection = (collections: ICollectionOptions[], collectionId?: string) => {
  const index = collections?.findIndex(item => item.collectionId === collectionId);
  return index !== -1 ? index : 0;
};
