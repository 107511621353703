import { Validation } from "@zenfolio/core-components";
import React from "react";
import ModalNote from "../../../../components/modalNote";
import { IDeclineBookingModalProps } from "../../../../store/modal/model";
import eventTracker from "../../../../utilities/eventTracker";
import { hasActionCompleted } from "../../../../utilities/helpers";
import withModal, { InjectedModalProps } from "../../withModal";
import ModalDialog from "../modalDialog";
import styles from "./index.module.scss";
import { IDecline } from "./model";
import withStore from "./withStore";

interface IDeclineBookingProps extends InjectedModalProps<IDeclineBookingModalProps> {
  decline: IDecline;
}

interface IDeclineBookingState {
  note: string;
}

class DeclineBooking extends React.Component<IDeclineBookingProps, IDeclineBookingState> {
  public state: IDeclineBookingState = { note: "" };

  public componentDidUpdate(prevProps: IDeclineBookingProps) {
    const declineHasCompleted = hasActionCompleted(prevProps.decline.status, this.props.decline.status);
    if (declineHasCompleted) {
      this.props.onHideModal();
    }
  }

  public render() {
    const { customerName } = this.props;
    const { note } = this.state;
    const declinePending = this.props.decline.status === "Pending";

    return (
      <ModalDialog
        title="Decline photoshoot request"
        okText="Decline Request"
        cancelText="Close"
        onCancel={this.onCancel}
        onOk={this.onOk}
        okDisabled={!Validation.isNotEmptyString(note)}
        okPending={declinePending}
      >
        <div className={styles.container}>
          <div className={styles.text}>This will decline your photoshoot request from {customerName}.</div>
          <ModalNote
            className={styles.note}
            note={note}
            onChange={this.onNoteChange}
            placeholder={`Explain to ${customerName} why you are declining the shoot…`}
            required={true}
          />
        </div>
      </ModalDialog>
    );
  }

  private onNoteChange = (note: string) => this.setState({ note });

  private onCancel = () => this.props.onHideModal();

  private onOk = () => {
    eventTracker.events.photographer.bookings.bookingDeclined(this.props.bookingId);
    this.props.decline.onDecline(this.props.bookingId, this.state.note);
  };
}

export default withModal(withStore(DeclineBooking));
