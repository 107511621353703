import { Button } from "@zenfolio/core-components";
import React from "react";
import { toFractionalValue } from "../../../../../components/fractionalPriceInput";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import styles from "./index.module.scss";
import { ICalculateTaxForEditingInvoice, IEditInvoice } from "./model";
import Setup, { Amount } from "./setup";
import withStore from "./withStore";

export interface IEditInvoiceOwnProps {
  invoiceId: string;
  amount: number;
  maxAmount: number;
  minAmount?: number;
  isTaxable: boolean;
  initialTaxAmount: number;
  onCancel: () => void;
  onSave: () => void;
  onError: () => void;
}

export interface IEditInvoiceSetupProps extends IEditInvoiceOwnProps {
  editInvoice: IEditInvoice;
  calculateTax: ICalculateTaxForEditingInvoice;
}

interface IEditInvoiceSetupState {
  amount: Amount;
  errorMessage?: string;
  internalNote?: string;
}

class EditInvoiceSetup extends React.Component<IEditInvoiceSetupProps, IEditInvoiceSetupState> {
  constructor(props: IEditInvoiceSetupProps) {
    super(props);

    this.state = {
      ...Setup.defaultState,
      amount: toFractionalValue(props.amount)
    };
  }

  public componentDidUpdate(prevProps: IEditInvoiceSetupProps, prevState: IEditInvoiceSetupState) {
    const { editInvoice, onSave, onError } = this.props;

    const saveInvoiceHasCompleted = hasActionCompleted(prevProps.editInvoice.status, editInvoice.status);

    if (saveInvoiceHasCompleted) {
      if (editInvoice.status === "Success") {
        onSave();
      } else if (editInvoice.status === "Error") {
        onError();
      }
    }
  }

  public render() {
    const { amount, maxAmount, minAmount, isTaxable, initialTaxAmount } = this.props;
    const { errorMessage, internalNote, amount: newAmount } = this.state;

    const effectiveTax = this.getEffectiveTax(newAmount);
    const saveDisabled =
      this.props.editInvoice.status === "Pending" ||
      !!errorMessage ||
      amount === null ||
      (isTaxable && effectiveTax == null);

    return (
      <div className={styles.container}>
        <Setup
          amount={toFractionalValue(amount)}
          maxAmount={maxAmount}
          minAmount={minAmount}
          internalNote={internalNote}
          isTaxable={isTaxable}
          initialTaxAmount={initialTaxAmount}
          taxAmount={isTaxable ? effectiveTax : undefined}
          onChange={this.onSetupChange}
          onAmountBlur={this.onAmountBlur}
        />
        <div className={styles.actions}>
          <button className={styles.cancel} onClick={this.onCancelClick}>
            Cancel
          </button>
          <Button className={styles.save} disabled={saveDisabled} onClick={this.onSaveClick}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  private getEffectiveTax(amount: Amount) {
    if (amount) {
      if (amount.floatValue === this.props.amount) {
        return this.props.initialTaxAmount;
      }

      if (
        this.props.invoiceId === this.props.calculateTax.invoiceId &&
        amount.floatValue === this.props.calculateTax.amount &&
        this.props.calculateTax.status === "Success"
      ) {
        return this.props.calculateTax.taxAmount;
      }
    }

    return undefined;
  }

  private onSetupChange = (amount: Amount, errorMessage?: string, internalNote?: string) => {
    this.setState({ amount, errorMessage, internalNote });
  };

  private onAmountBlur = (amount: Amount, valid: boolean) => {
    if (this.props.isTaxable && valid && this.getEffectiveTax(amount) == null) {
      this.props.calculateTax.calculateTax(this.props.invoiceId, amount!.floatValue);
    }
  };

  private onSaveClick = () => {
    const { editInvoice } = this.props;

    editInvoice.updateInvoice(this.props.invoiceId, this.state.amount!.floatValue, this.state.internalNote);
  };

  private onCancelClick = () => this.props.onCancel();
}

export default withStore(EditInvoiceSetup);
