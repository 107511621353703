import {
  ICarouselImageData,
  IEditorImageData,
  IFeature,
  IInstagramImage,
  IMediaVideo,
  IService,
  ICard
} from "../../models/blocks";
import { processStaticImageUrl } from "../blocks/url";
import { getUUID } from "../blocks/site";
import { IBookMePackage } from "components/blocks/zenServiceBlock";
import { IFolderContent, INavigationMenu } from "../../models/models";

export interface IHeroFramesImageData extends IEditorImageData {}

export interface IEditorMediaData extends IEditorImageData {
  video?: IMediaVideo;
  videoUrlTemplate?: string;
}

export const photoCarouselPlaceholders: IEditorMediaData[] = [
  {
    id: "1",
    index: 0,
    isFallback: true,
    url:
      "https://www.rebuckandassociates.com/sites/rebuckandassociates.com/files/styles/medium/public/young-family_0.jpg?itok=-pf7NpuK",
    fileName: "1.jpg",
    width: 465,
    height: 311
  },
  {
    id: "2",
    index: 1,
    isFallback: true,
    url: "https://i0.wp.com/despiertamedia.com/wp-content/uploads/2020/02/baby.jpg?fit=968%2C645&ssl=1",
    fileName: "2.jpg",
    width: 968,
    height: 465
  },
  {
    id: "3",
    index: 2,
    isFallback: true,
    url: "https://southernweddings.com/wp-content/uploads/2018/06/dogs-at-wedding.jpg",
    fileName: "dogs-at-wedding.jpg",
    width: 1200,
    height: 1800
  },
  {
    id: "4",
    index: 3,
    isFallback: true,
    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/River_Otter-27527.jpg/1024px-River_Otter-27527.jpg",
    fileName: "Otter.jpg",
    width: 1024,
    height: 1024
  },
  {
    id: "5",
    index: 4,
    isFallback: true,
    url: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Wildmeerschweinchen-06.jpg",
    fileName: "guinea-pig.jpg",
    width: 698,
    height: 522
  },
  {
    id: "6",
    index: 5,
    isFallback: true,
    url: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Capibara_2_edit.jpg",
    fileName: "capibara.jpg",
    width: 1300,
    height: 900
  },
  {
    id: "7",
    index: 6,
    isFallback: true,
    url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Oryctolagus_cuniculus_Tasmania_2.jpg/800px-Oryctolagus_cuniculus_Tasmania_2.jpg",
    fileName: "rabbit.jpg",
    width: 800,
    height: 1000
  },
  {
    id: "8",
    index: 7,
    isFallback: true,
    url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Koala_climbing_tree.jpg/1024px-Koala_climbing_tree.jpg",
    fileName: "koala.jpg",
    width: 1024,
    height: 1007
  },
  {
    url:
      "https://app.zenfolio.dev/vt/3b829627-461b-426b-9e9b-cb7f6139b82f/0/0/XXL/0-0-0/53ef8338-2fdd-467a-94ab-ce953742a971/1/1/sample-mp4-file.mp4?fjkss=exp=1673283600~hmac=03cdafc39fc267d5525086ef0c761c93534d7a8d80acb2c3a416563da849018f",
    id: "53ef8338-2fdd-467a-94ab-ce953742a971",
    index: 4,
    fileName: "sample-mp4-file.mp4",
    album: "3b829627-461b-426b-9e9b-cb7f6139b82f",
    height: 240,
    width: 320,
    focalX: 50,
    focalY: 50,
    video: {
      videoVersion: 0,
      thumbnailId: "53ef8338-2fdd-467a-94ab-ce953742a971",
      thumbnailWidth: 320,
      thumbnailHeight: 240,
      durationMilliseconds: 125952,
      hasCustomThumbnail: false,
      isVideo: true,
      id: "454a5e93-da8e-436d-9d8f-bd9ca44595c0",
      fileName: "sample-mp4-file.mp4",
      name: "sample-mp4-file.mp4",
      sortIndex: 4,
      size: 10546620,
      thumbnailUrlTemplate:
        "/vt/3b829627-461b-426b-9e9b-cb7f6139b82f/0/0/~sizeCode~/0-0-0/~thumbnailId~/~videoVersion~/~resizeMethod~/~thumbnailTitle~?fjkss=exp=1673283600~hmac=03cdafc39fc267d5525086ef0c761c93534d7a8d80acb2c3a416563da849018f",
      album: "3b829627-461b-426b-9e9b-cb7f6139b82f",
      galleryId: "3b829627-461b-426b-9e9b-cb7f6139b82f",
      selected: false
    }
  }
];

export const featuresPlaceholders: IFeature[] = [
  {
    id: "1",
    index: 0,
    title: "Family",
    body: "Duis aute irure dolor in reprehenderit",
    alignment: "left",
    buttonLabel: "See More",
    interactionType: "none",
    interactionNewTab: false,
    interactionPage: "",
    interactionUrl: "",
    imageUrl:
      "https://www.rebuckandassociates.com/sites/rebuckandassociates.com/files/styles/medium/public/young-family_0.jpg?itok=-pf7NpuK",
    imageHeight: 3840,
    imageWidth: 5760,
    imageName: "family.jpg",
    imageId: "",
    isSelected: false,
    isVisible: true,
    focalX: 0,
    focalY: 0
  },
  {
    id: "2",
    index: 1,
    title: "Baby",
    body: "Duis aute irure dolor in reprehenderit",
    alignment: "center",
    buttonLabel: "See More",
    interactionType: "none",
    interactionNewTab: false,
    interactionPage: "",
    interactionUrl: "",
    imageUrl: "https://i0.wp.com/despiertamedia.com/wp-content/uploads/2020/02/baby.jpg?fit=968%2C645&ssl=1",
    imageHeight: 850,
    imageWidth: 1276,
    imageName: "baby.jpeg",
    imageId: "",
    isSelected: false,
    isVisible: true,
    focalX: 50,
    focalY: 50
  },
  {
    id: "3",
    index: 2,
    title: "Wedding",
    body: "Duis aute irure dolor in reprehenderit",
    alignment: "right",
    buttonLabel: "See More",
    interactionType: "none",
    interactionNewTab: false,
    interactionPage: "",
    interactionUrl: "",
    imageUrl: "https://southernweddings.com/wp-content/uploads/2018/06/dogs-at-wedding.jpg",
    imageName: "wedding.jpg",
    imageHeight: 5101,
    imageWidth: 3401,
    imageId: "",
    isSelected: false,
    isVisible: true,
    focalX: 100,
    focalY: 100
  }
];

export const heroCarouselPlaceholders: ICarouselImageData[] = [
  {
    id: "1",
    index: 1,
    fileName: "Weddings",
    url:
      "https://www.rebuckandassociates.com/sites/rebuckandassociates.com/files/styles/medium/public/young-family_0.jpg?itok=-pf7NpuK",
    width: 1440,
    height: 916,
    title: "My Weddings",
    caption: "Capturing moments and memories",
    focalX: 0,
    focalY: 0
  },
  {
    id: "2",
    index: 2,
    fileName: "Picture 2",
    url: "https://i0.wp.com/despiertamedia.com/wp-content/uploads/2020/02/baby.jpg?fit=968%2C645&ssl=1",
    width: 1440,
    height: 916,
    title: "Picture Two",
    caption: "Capturing moments and memories",
    focalX: 50,
    focalY: 50
  },
  {
    id: "3",
    index: 3,
    fileName: "Picture 3",
    url: "https://southernweddings.com/wp-content/uploads/2018/06/dogs-at-wedding.jpg",
    width: 1440,
    height: 916,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  },
  {
    id: "4",
    index: 4,
    fileName: "Picture 4",
    url: "https://southernweddings.com/wp-content/uploads/2017/01/Main-Squeeze.jpg",
    width: 690,
    height: 276,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  },
  {
    id: "5",
    index: 5,
    fileName: "Picture 5",
    url: "https://southernweddings.com/wp-content/uploads/2015/12/camilyn-beth-dresses-218x330.jpg",
    width: 218,
    height: 330,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  },
  {
    id: "6",
    index: 6,
    fileName: "Picture 6",
    url: "https://southernweddings.com/wp-content/uploads/2011/02/brooke+victor3.jpg",
    width: 376,
    height: 246,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  },
  {
    id: "7",
    index: 7,
    fileName: "Picture 7",
    url: "https://southernweddings.com/wp-content/uploads/2015/03/bouquet-toss1-506x370.jpg",
    width: 376,
    height: 246,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  },
  {
    id: "8",
    index: 8,
    fileName: "Picture 8",
    url: "https://southernweddings.com/wp-content/uploads/2015/03/wedding-toast-506x370.jpg",
    width: 376,
    height: 246,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  },
  {
    id: "9",
    index: 9,
    fileName: "Picture 9",
    url: "https://southernweddings.com/wp-content/uploads/2015/03/alea-moore-photography-244x370.jpg",
    width: 376,
    height: 246,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  },
  {
    id: "10",
    index: 10,
    fileName: "Picture 10",
    url: "https://southernweddings.com/wp-content/uploads/2015/03/cathedral-veil-244x370.jpg",
    width: 376,
    height: 246,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  },
  {
    id: "11",
    index: 11,
    fileName: "Picture 11",
    url: "https://southernweddings.com/wp-content/uploads/2015/03/alea-moore-506x370.jpg",
    width: 376,
    height: 246,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  },
  {
    id: "12",
    index: 12,
    fileName: "Picture 12",
    url: "https://southernweddings.com/wp-content/uploads/2015/03/navy-suit-groomsmen-506x370.jpg",
    width: 376,
    height: 246,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  },
  {
    id: "13",
    index: 13,
    fileName: "Picture 13",
    url: "https://southernweddings.com/wp-content/uploads/2015/03/gray-bridesmaid-dresses2-244x370.jpg",
    width: 376,
    height: 246,
    title: "Picture Three",
    caption: "Capturing moments and memories",
    focalX: 100,
    focalY: 100
  }
];

export const getBookMeServicesPlaceHolders = (isDarkBackground: boolean): IService[] => {
  return [
    {
      id: getUUID(),
      index: 0,
      category: "FAMILY",
      isConnected: false,
      aboutService:
        "Our Family packages are perfect for capturing moments that will last a lifetime. The base package includes an hour shoot at Paintbrush Park, while the other packages will be shot at a location of your choice.",
      showMore: false,
      showContact: false,
      buttonLabel: "Book",
      linkType: "none",
      imageUrl: processStaticImageUrl(`/storage/images/bookme-block/${isDarkBackground ? "dark" : "light"}/family.jpg`),
      imageName: "family.jpg",
      imageWidth: 850,
      imageHeight: 1275,
      imageId: "",
      confirmationTitle: "",
      confirmationBody: "",
      packages: [
        {
          id: "1",
          title: "Gold Package",
          price: 100,
          includes: [
            "1 hour in Paintbrush Park",
            "Up to 4 family members can join the shoot",
            "Each additional subject is $20"
          ],
          duration: 60,
          isSelected: true,
          isVisible: true
        },
        {
          id: "2",
          title: "Silver Package",
          price: 100,
          includes: [],
          duration: 60,
          isSelected: false,
          isVisible: true
        },
        {
          id: "3",
          title: "Bronze Package",
          price: 150,
          duration: 60,
          includes: [],
          isSelected: false,
          isVisible: true
        }
      ] as IBookMePackage[],
      isSelected: false,
      isVisible: true
    },
    {
      id: getUUID(),
      index: 1,
      category: "BIRTHDAY AND CELEBRATION",
      isConnected: false,
      aboutService:
        "Is a friend or loved one reaching a milestone? Or maybe you're just wanting to capture a once-in-a-lifetime night out on the town. A birthday investment means keeping those birthday, quinceaneras, or bat mitzvah memories for years to come. Consider starting a photo gallery to record each birthday milestone. Start today!",
      showMore: true,
      showContact: true,
      buttonLabel: "Book",
      linkType: "none",
      imageUrl: processStaticImageUrl(
        `/storage/images/bookme-block/${isDarkBackground ? "dark" : "light"}/birthday.jpg`
      ),
      imageName: "birthday.jpg",
      imageWidth: 1390,
      imageHeight: 782,
      imageId: "",
      confirmationTitle: "",
      confirmationBody: "",
      packages: [
        {
          id: "4",
          title: "Something Small",
          price: 50,
          includes: [
            "Good for up to 10 people",
            "Includes 5 digitally edited photos",
            "Pictures taken with DSLR and iPhone"
          ],
          duration: 30,
          isSelected: true,
          isVisible: true
        },
        {
          id: "5",
          title: "A Big Group",
          price: 100,
          includes: [],
          duration: 60,
          isSelected: false,
          isVisible: true
        },
        {
          id: "6",
          title: "A Huge Celebration",
          price: 150,
          includes: [],
          duration: 60,
          isSelected: false,
          isVisible: true
        }
      ] as IBookMePackage[],
      isSelected: false,
      isVisible: true
    },
    {
      id: getUUID(),
      index: 2,
      category: "WEDDING",
      isConnected: false,
      aboutService:
        "Create lasting memories by capturing your special day with professional photographic service. Additional to photography I do real state videos too. I will use my drone and zero shake camera to show your listing from top to bottom. There is a free travel charge in the South Hills.",
      showMore: true,
      showContact: true,
      buttonLabel: "Book",
      linkType: "none",
      imageUrl: processStaticImageUrl(
        `/storage/images/bookme-block/${isDarkBackground ? "dark" : "light"}/wedding.jpg`
      ),
      imageName: "wedding.jpg",
      imageWidth: 1275,
      imageHeight: 850,
      focalX: 85,
      imageId: "",
      confirmationTitle: "",
      confirmationBody: "",
      packages: [
        {
          id: "7",
          title: "Gold Wedding",
          price: 5000,
          includes: ["Includes 30 digital photos", "2 photographers on-site", "3 videos, 30 min each"],
          duration: 480,
          isSelected: true,
          isVisible: true
        },
        {
          id: "8",
          title: "Silver Wedding",
          price: 3000,
          includes: [],
          duration: 240,
          isSelected: false,
          isVisible: true
        },
        {
          id: "9",
          title: "Bronze Wedding",
          price: 1000,
          includes: [],
          duration: 60,
          isSelected: false,
          isVisible: true
        }
      ] as IBookMePackage[],
      isSelected: false,
      isVisible: true
    }
  ];
};

export const servicesPlaceholders: IService[] = [
  {
    id: "1",
    index: 0,
    category: "SENIOR PORTRAIT",
    isConnected: false,
    isSelected: false,
    aboutService:
      "A senior portrait session will take you through 3 parts of Pittsburgh’s North Side. Starting at Allegheny Commons Park, moving to PNC Park and ending by walking along the river and standing on the Clemente Bridge. It will last a little over an hour. If weather is poor we can reschedule or conduct the shoot in my studio. I estimate this shoot to take 2 hours.",
    showMore: false,
    showContact: false,
    buttonLabel: "BOOK",
    linkType: "none",
    imageUrl:
      "https://www.rebuckandassociates.com/sites/rebuckandassociates.com/files/styles/medium/public/young-family_0.jpg?itok=-pf7NpuK",
    imageName: "senior.jpg",
    imageWidth: 400,
    imageHeight: 500,
    imageId: "",
    confirmationTitle: "Thank you for your message",
    confirmationBody:
      "Thanks for reaching out. I'll be in touch at my earliest convenience. I look forward to speaking with you.",
    packages: [
      {
        id: "1",
        title: "Bronze Package",
        price: 100,
        includes: [
          "Includes 10 beautifully edited images",
          "One outfit change",
          "10 photos, shot in 3 downtown locations.",
          "Beautifully edited photos."
        ],
        isSelected: true,
        isVisible: true
      },
      {
        id: "2",
        title: "Silver Package",
        price: 200,
        includes: ["Includes 15 beautifully edited photos", "Two outfit changes", "Hair and makeup", ""],
        isSelected: false,
        isVisible: true
      },
      {
        id: "3",
        title: "Gold Package",
        price: 300,
        includes: [
          "Includes 30 beautifully edited photos",
          "Three outfit changes",
          "Hair and makeup",
          "Up to 2 additional subjects can be included in the shoot."
        ],
        isSelected: false,
        isVisible: true
      }
    ],
    isVisible: true,
    focalX: 0,
    focalY: 0
  },
  {
    id: "2",
    index: 1,
    category: "REAL STATE",
    isSelected: false,
    isConnected: false,
    aboutService:
      "Sometimes the key to selling a house, is by how the images look online. Additional to photography, I do real estate videos too. I will use my drone and zero shake camera to show your listing from top to bottom. There is a free travel charge in the South Hills.",
    showMore: false,
    showContact: true,
    buttonLabel: "BOOK",
    linkType: "none",
    imageUrl: "https://i0.wp.com/despiertamedia.com/wp-content/uploads/2020/02/baby.jpg?fit=968%2C645&ssl=1",
    imageName: "realestate.jpg",
    imageWidth: 541,
    imageHeight: 361,
    imageId: "",
    confirmationTitle: "Thank you for your message",
    confirmationBody:
      "Thanks for reaching out. I'll be in touch at my earliest convenience. I look forward to speaking with you.",
    packages: [
      {
        id: "4",
        title: "Small Building Package",
        price: 100,
        includes: [
          "Photoshoot for up to 1000st ft",
          "15 digital pictures of both interior and exterior.",
          "Addtl images available for purchase.",
          "All printing, distribution, & ownership rights."
        ],
        isSelected: true,
        isVisible: true
      },
      {
        id: "5",
        title: "Medium Building Package",
        price: 200,
        includes: [
          "Photoshoot for up to 1900st ft buildings. ",
          "30 digital pictures of both interior and exterior.",
          "Addtl images available for purchase",
          "All printing, distribution, & ownership rights."
        ],
        isSelected: false,
        isVisible: true
      },
      {
        id: "6",
        title: "Large Building Package",
        price: 500,
        includes: [
          "Photoshoot for up to 2900st ft buildings.",
          "40 digital pictures of both interior and exterior.",
          "Addtl images available for purchase",
          "All printing, distribution, & ownership rights."
        ],
        isSelected: false,
        isVisible: true
      },
      {
        id: "7",
        title: "Massive Building Package",
        price: 800,
        includes: [
          "Photoshoot for up to 3000+st ft buildings.",
          "60 digital pictures of both interior and exterior.",
          "Addtl images available for purchase",
          "All printing, distribution, & ownership rights."
        ],
        isSelected: false,
        isVisible: true
      }
    ],
    isVisible: true,
    focalX: 50,
    focalY: 50
  },
  {
    id: "3",
    index: 2,
    category: "ENGAGEMENT",
    isConnected: false,
    isSelected: false,
    aboutService:
      "Is a friend or loved one reaching a milestone? Or maybe you're just wanting to capture a once-in-a-lifetime night out on the town. A birthday investment means keeping those birthday, quinceaneras, or bat mitzvah memories for years to come. Consider starting a photo album to record each birthday milestone. Start today!",
    showMore: true,
    showContact: true,
    buttonLabel: "BOOK",
    linkType: "none",
    imageUrl: "https://southernweddings.com/wp-content/uploads/2018/06/dogs-at-wedding.jpg",
    imageName: "engagement.jpg",
    imageWidth: 200,
    imageHeight: 400,
    imageId: "",
    confirmationTitle: "Thank you for your message",
    confirmationBody:
      "Thanks for reaching out. I'll be in touch at my earliest convenience. I look forward to speaking with you.",
    packages: [
      {
        id: "8",
        title: "The Lil' Package",
        price: 150,
        includes: ["Up to 30 minutes", "1 outfit, 1 location", "10 digital hi-res images, color only", ""],
        isSelected: true,
        isVisible: true
      },
      {
        id: "9",
        title: "The Standard Package",
        price: 300,
        includes: ["Up to 45 minutes", "1 outfit, 2 location", "15 digital hi-res images, color and B&W", ""],
        isSelected: false,
        isVisible: true
      },
      {
        id: "10",
        title: "The Large Package",
        price: 500,
        includes: [
          "Up to 2 hours",
          "3 outfits, 3 locations",
          "35 digital hi-res images, color and B&W",
          "2 8x10 canvas prints"
        ],
        isSelected: false,
        isVisible: true
      }
    ],
    isVisible: true,
    focalX: 100,
    focalY: 100
  }
];

export const getNewServicePlaceholder = (index: number): IService => ({
  id: getUUID(),
  category: "Birthday & Celebration",
  isConnected: false,
  index,
  aboutService:
    "Is a friend or loved one reaching a milestone? Or maybe you're just wanting to capture a once-in-a-lifetime night out on the town. A birthday investment means keeping those birthday, quinceaneras, or bat mitzvah memories for years to come. Consider starting a photo album to record each birthday milestone. Start today!",
  showMore: false,
  showContact: false,
  buttonLabel: "BOOK",
  linkType: "none",
  imageUrl: "https://southernweddings.com/wp-content/uploads/2018/06/dogs-at-wedding.jpg",
  imageName: "engagement.jpg",
  imageWidth: 200,
  imageHeight: 400,
  imageId: "",
  packages: [
    {
      id: getUUID(),
      title: "Something Small",
      price: 50,
      includes: [
        "Good for up to 10 people",
        "Includes 5 digitally edited photos",
        "Pictures taken with DSLR and iPhone",
        ""
      ],
      isSelected: true,
      isVisible: true
    },
    {
      id: getUUID(),
      title: "A Big Group",
      price: 200,
      includes: [
        "Good for up to 30 people",
        "Includes 20 digitally edited photos",
        "Pictures taken with DSLR, iPhone, and Polaroid ",
        ""
      ],
      isSelected: false,
      isVisible: true
    },
    {
      id: getUUID(),
      title: "A Huge Celebration",
      price: 500,
      includes: [
        "Good for 50+ people",
        "Includes 30 digitally edited photos",
        "10 posed & staged digital pictures",
        "Includes photobooth for your guests to use!"
      ],
      isSelected: false,
      isVisible: true
    }
  ],
  isSelected: false,
  isVisible: true,
  confirmationTitle: "Thank you for your message",
  confirmationBody:
    "Thanks for reaching out. I'll be in touch at my earliest convenience. I look forward to speaking with you."
});

export const testimonialPlaceHolders: ICard[] = [
  {
    quote: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolor nulla pariatur.",
    source: "Andrew Scott",
    shootType: "Family Session",
    isSelected: false,
    isHidden: false
  },
  {
    quote: "Duis aute irure dolor in reprehenderit in esse anum voluptate velit esse cillum dolore eu fugiat.",
    source: "BRIANNA SIMS",
    shootType: "Business Branding",
    isSelected: false,
    isHidden: false
  },
  {
    quote: "Duis aute irure dolor in reprehenderit in voluptate velit dolore eu fugiat nulla pariatur.",
    source: "MATT ACCOSTA",
    shootType: "Wedding Package",
    isSelected: false,
    isHidden: false
  }
];

export const heroFramesLeftPlaceholders: IHeroFramesImageData[] = [
  {
    id: "1",
    index: 1,
    fileName: "Weddings",
    url: "https://southernweddings.com/wp-content/uploads/2015/03/bouquet-toss1-506x370.jpg",
    width: 1440,
    height: 916,
    focalX: 0,
    focalY: 0
  },
  {
    id: "2",
    index: 1,
    fileName: "Weddings",
    url:
      "https://www.rebuckandassociates.com/sites/rebuckandassociates.com/files/styles/medium/public/young-family_0.jpg?itok=-pf7NpuK",
    width: 1440,
    height: 916,
    focalX: 50,
    focalY: 50
  },
  {
    id: "3",
    index: 1,
    fileName: "Weddings",
    url: "https://i0.wp.com/despiertamedia.com/wp-content/uploads/2020/02/baby.jpg?fit=968%2C645&ssl=1",
    width: 1440,
    height: 916,
    focalX: 100,
    focalY: 100
  }
];

export const heroFramesMiddlePlaceholders: IHeroFramesImageData[] = [
  {
    id: "1",
    index: 1,
    fileName: "Weddings",
    url:
      "https://www.rebuckandassociates.com/sites/rebuckandassociates.com/files/styles/medium/public/young-family_0.jpg?itok=-pf7NpuK",
    width: 1440,
    height: 916,
    focalX: 0,
    focalY: 0
  },
  {
    id: "2",
    index: 1,
    fileName: "Weddings",
    url: "https://southernweddings.com/wp-content/uploads/2015/03/bouquet-toss1-506x370.jpg",
    width: 1440,
    height: 916,
    focalX: 0,
    focalY: 0
  },
  {
    id: "3",
    index: 1,
    fileName: "Weddings",
    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/River_Otter-27527.jpg/1024px-River_Otter-27527.jpg",
    width: 1440,
    height: 916,
    focalX: 0,
    focalY: 0
  }
];

export const heroFramesRightPlaceholders: IHeroFramesImageData[] = [
  {
    id: "1",
    index: 1,
    fileName: "Weddings",
    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/River_Otter-27527.jpg/1024px-River_Otter-27527.jpg",
    width: 1440,
    height: 916,
    focalX: 0,
    focalY: 0
  },
  {
    id: "2",
    index: 1,
    fileName: "Weddings",
    url:
      "https://www.rebuckandassociates.com/sites/rebuckandassociates.com/files/styles/medium/public/young-family_0.jpg?itok=-pf7NpuK",
    width: 1440,
    height: 916,
    focalX: 0,
    focalY: 0
  },
  {
    id: "3",
    index: 1,
    fileName: "Weddings",
    url: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Capibara_2_edit.jpg",
    width: 1440,
    height: 916,
    focalX: 0,
    focalY: 0
  }
];

export const linkedFolderPlaceholders: IFolderContent[] = [
  {
    id: "8155e52b-e205-4649-a5cb-bd995719fa0c",
    name: "Untitled 41",
    isAlbum: true,
    albumAlias: "untitled-41",
    totalFolders: 0,
    totalAlbums: 0,
    totalPhotos: 245,
    isVisitorAllowed: true,
    isProtected: false,
    isVisitorEmailRequired: false,
    photoUrlTemplate: "https://southernweddings.com/wp-content/uploads/2018/06/dogs-at-wedding.jpg",
    coverPhoto: {
      id: "f690a575-fd6e-4347-a914-1ed5b116ff09",
      name: "4 - Copy (2) - Copy.jpg",
      photoVersion: 1
    },
    dateCreated: "2022-09-15T04:44:51.306106",
    images: [
      {
        id: "f690a575-fd6e-4347-a914-1ed5b116ff09",
        name: "4 - Copy (2) - Copy.jpg",
        isProtected: false,
        photoVersion: 1,
        photoUrlTemplate: "https://southernweddings.com/wp-content/uploads/2018/06/dogs-at-wedding.jpg"
      }
    ]
  },
  {
    id: "8155e52b-e205-4649-a5cb-bd995719fa0c",
    name: "Untitled 42",
    isAlbum: true,
    albumAlias: "untitled-42",
    totalFolders: 0,
    totalAlbums: 0,
    totalPhotos: 245,
    isProtected: true,
    isVisitorAllowed: true,
    isVisitorEmailRequired: false,
    photoUrlTemplate:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/River_Otter-27527.jpg/1024px-River_Otter-27527.jpg",
    coverPhoto: {
      id: "f690a575-fd6e-4347-a914-1ed5b116ff09",
      name: "4 - Copy (2) - Copy.jpg",
      photoVersion: 1
    },
    dateCreated: "2022-09-15T04:44:51.306106",
    images: [
      {
        id: "f690a575-fd6e-4347-a914-1ed5b116ff09",
        name: "4 - Copy (2) - Copy.jpg",
        isProtected: false,
        photoVersion: 1,
        photoUrlTemplate:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/River_Otter-27527.jpg/1024px-River_Otter-27527.jpg"
      }
    ]
  },
  {
    id: "8155e52b-e205-4649-a5cb-bd995719fa0c",
    name: "Untitled 43",
    isAlbum: false,
    albumAlias: "untitled-43",
    totalFolders: 1,
    totalAlbums: 2,
    totalPhotos: 0,
    isVisitorAllowed: true,
    isProtected: false,
    isVisitorEmailRequired: false,
    photoUrlTemplate: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Wildmeerschweinchen-06.jpg",
    coverPhoto: {
      id: "",
      name: "",
      photoVersion: 1
    },
    dateCreated: "2022-09-15T04:44:51.306106",
    images: []
  },
  {
    id: "8155e52b-e205-4649-a5cb-bd995719fa0c",
    name: "Untitled 44",
    isAlbum: true,
    albumAlias: "untitled-44",
    totalFolders: 0,
    totalAlbums: 0,
    totalPhotos: 245,
    isProtected: true,
    isVisitorAllowed: true,
    isVisitorEmailRequired: false,
    photoUrlTemplate:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/River_Otter-27527.jpg/1024px-River_Otter-27527.jpg",
    coverPhoto: {
      id: "f690a575-fd6e-4347-a914-1ed5b116ff09",
      name: "4 - Copy (2) - Copy.jpg",
      photoVersion: 1
    },
    dateCreated: "2022-09-15T04:44:51.306106",
    images: [
      {
        id: "f690a575-fd6e-4347-a914-1ed5b116ff09",
        name: "4 - Copy (2) - Copy.jpg",
        isProtected: false,
        photoVersion: 1,
        photoUrlTemplate:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/River_Otter-27527.jpg/1024px-River_Otter-27527.jpg"
      }
    ]
  }
];

export const mockedMenu: INavigationMenu = {
  items: [
    {
      id: "21",
      menuName: "home",
      menuCaption: "Home",
      menuLink: "home"
    },
    {
      id: "22",
      menuName: "portfolio",
      menuCaption: "Portfolio",
      childs: [
        {
          id: "23",
          menuName: "weddings",
          menuCaption: "Weddings",
          menuLink: "portfolio/wedings"
        },
        {
          id: "24",
          menuName: "headshots",
          menuCaption: "Headshots",
          menuLink: "portfolio/headshots"
        },
        {
          id: "25",
          menuName: "sports",
          menuCaption: "Sport Teams",
          menuLink: "portfolio/sports"
        },
        {
          id: "26",
          menuName: "all",
          menuCaption: "All Portfolio",
          menuLink: "portfolio"
        }
      ]
    },
    {
      id: "27",
      menuName: "clients",
      menuCaption: "Clients",
      childs: [
        {
          id: "28",
          menuName: "client1",
          menuCaption: "CLIENT 1",
          menuLink: "client1"
        },
        {
          id: "29",
          menuName: "client2",
          menuCaption: "CLIENT 2",
          menuLink: "chalient2"
        },
        {
          id: "30",
          menuName: "client3",
          menuCaption: "CLIENT 3",
          menuLink: "client3"
        }
      ]
    },
    {
      id: "31",
      menuName: "services",
      menuCaption: "Services",
      menuLink: "services"
    },
    {
      id: "32",
      menuName: "about",
      menuCaption: "About",
      menuLink: "about"
    },
    {
      id: "33",
      menuName: "contact",
      menuCaption: "Contact",
      menuLink: "contact"
    },
    {
      id: "34",
      menuName: "extensions",
      menuCaption: "Extensions",
      childs: [
        {
          id: "35",
          menuName: "submenu1",
          menuCaption: "Sub Item 1",
          menuLink: "portfolio/wedings"
        },
        {
          id: "36",
          menuName: "submenu2",
          menuCaption: "Sub Item 2",
          menuLink: "portfolio/headshots"
        },
        {
          id: "37",
          menuName: "submenu3",
          menuCaption: "Sub Item 3",
          menuLink: "portfolio/sports"
        },
        {
          id: "38",
          menuName: "submenu4",
          menuCaption: "Sub Item 4",
          menuLink: "portfolio"
        }
      ]
    },
    {
      id: "39",
      menuName: "extensions2",
      menuCaption: "Extensions 2",
      childs: [
        {
          id: "40",
          menuName: "extsubmenu1",
          menuCaption: "Sub Item 2.1",
          menuLink: "portfolio/wedings"
        },
        {
          id: "41",
          menuName: "extsubmenu2",
          menuCaption: "Sub Item 2.2",
          menuLink: "portfolio"
        }
      ]
    }
  ]
};

export const instagramPlaceholders: IInstagramImage[] = [
  {
    id: "1",
    isFallback: true,
    url:
      "https://www.rebuckandassociates.com/sites/rebuckandassociates.com/files/styles/medium/public/young-family_0.jpg?itok=-pf7NpuK",
    description: "#exposurephotography Sneak peek at the product shoot we’re doing today. Yummy!"
  },
  {
    id: "2",
    isFallback: true,
    url: "https://i0.wp.com/despiertamedia.com/wp-content/uploads/2020/02/baby.jpg?fit=968%2C645&ssl=1",
    description: "#exposurephotography Sneak peek at the product shoot we’re doing today. Yummy!"
  },
  {
    id: "3",
    isFallback: true,
    url: "https://southernweddings.com/wp-content/uploads/2018/06/dogs-at-wedding.jpg",
    description: "#exposurephotography Sneak peek at the product shoot we’re doing today. Yummy!"
  },
  {
    id: "4",
    isFallback: true,
    url: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/River_Otter-27527.jpg/1024px-River_Otter-27527.jpg",
    description: "#exposurephotography Sneak peek at the product shoot we’re doing today. Yummy!"
  },
  {
    id: "5",
    isFallback: true,
    url: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Wildmeerschweinchen-06.jpg",
    description: "#exposurephotography Sneak peek at the product shoot we’re doing today. Yummy!"
  },
  {
    id: "6",
    isFallback: true,
    url: "https://upload.wikimedia.org/wikipedia/commons/c/cc/Capibara_2_edit.jpg",
    description: "#exposurephotography Sneak peek at the product shoot we’re doing today. Yummy!"
  },
  {
    id: "7",
    isFallback: true,
    url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Oryctolagus_cuniculus_Tasmania_2.jpg/800px-Oryctolagus_cuniculus_Tasmania_2.jpg",
    description: "#exposurephotography Sneak peek at the product shoot we’re doing today. Yummy!"
  },
  {
    id: "8",
    isFallback: true,
    url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Koala_climbing_tree.jpg/1024px-Koala_climbing_tree.jpg",
    description: "#exposurephotography Sneak peek at the product shoot we’re doing today. Yummy!"
  }
];
