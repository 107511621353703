import cx from "classnames";
import Caret from "components/Icons/Caret";
import { ButtonBack, ButtonNext, CarouselProvider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import colors from "utilities/colors";
import { PhotoOrVideo } from "../../models";
import { IAnalyticParams } from "../NzVideo/Player";
import DotsImageGroup from "./DotsImageGroup";
import ImageSlider, { IImageSliderStyles } from "./ImageSlider";
import styles from "./lightBox.module.scss";

interface IDimensionsInfo {
  containerSize: number;
  dimension: string;
  containerWidth: number;
  containerHeight: number;
}

export interface ILightBoxProps {
  images: PhotoOrVideo[];
  lessWidth?: number;
  lessHeight?: number;
  selectedIndex?: number;
  headerClassName?: string;
  header?: React.ReactNode;
  isHeaderAbsolute?: boolean;
  onSlideChange?: (index: number) => void;
  areStatsOpen?: boolean;
  photoUrlTemplate: string;
  thumbnailUrlTemplate: string;
  videoUrlTemplate: string;
  videoAnalyticsParams?: IAnalyticParams;
  hideName?: boolean;
  captionsMap?: Record<string, string>;
  isSingleView?: boolean;
  totalCount?: number;
  observerComponent?: JSX.Element;
}

const BATCH_SIZE = 20;
const THRESHOLD = 5;

const LightBox: React.FC<ILightBoxProps> = props => {
  let divRef = React.createRef<HTMLDivElement>();
  let headerControlsRef = React.createRef<HTMLDivElement>();
  const [dimensionsInfo, setDimensionsInfo] = useState({
    containerSize: 0,
    dimension: "",
    containerWidth: 0,
    containerHeight: 0
  } as IDimensionsInfo);

  const totalCount = props.totalCount || props.images.length;

  const [blockHide, setBlockHide] = useState(false);
  const [slideHeight, setSlideHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [blockSmallCarousel, setBlockSmallCarousel] = useState(false);

  // To avoid overloading the Slider component with large galleries we use a local state images array that is loaded in batches
  // We also have a local selectedIndex state variable based on this reduced array
  const [images, setImages] = useState<PhotoOrVideo[]>(
    props.images.slice(
      Math.max((props.selectedIndex || 0) - BATCH_SIZE, 0),
      Math.min((props.selectedIndex || 0) + BATCH_SIZE, totalCount)
    )
  );
  const [firstIdx, setFirstIdx] = useState(Math.max((props.selectedIndex || 0) - BATCH_SIZE, 0));
  const [lastIdx, setLastIdx] = useState(Math.min(totalCount, (props.selectedIndex || 0) + BATCH_SIZE));
  const [selectedIndex, setSelectedIndex] = useState(
    (props.selectedIndex || 0) > BATCH_SIZE
      ? (props.selectedIndex || 0) - Math.max((props.selectedIndex || 0) - BATCH_SIZE, 0)
      : props.selectedIndex || 0
  );
  const [isShown, setShown] = useState(true);
  const [isAdjustedIndex, setIsAdjustedIndex] = useState(false);

  const blockHideRef = useRef<boolean>(blockHide);
  blockHideRef.current = blockHide;

  const sliderStyles: IImageSliderStyles = {
    carouselSlider: styles.carouselSlider,
    carouselSlideInner: styles.carouselSlideInner,
    imgContainer: styles.imgContainer
  };

  const getDimensionsInfo = () => {
    const dimensionsInfo: IDimensionsInfo = {
      containerSize: 0,
      containerWidth: 0,
      containerHeight: 0,
      dimension: ""
    };
    if (divRef.current) {
      const { offsetHeight, offsetWidth } = divRef.current as any;
      const headerControlsHeight = headerControlsRef.current ? headerControlsRef.current?.offsetHeight : 0;
      const offsetHeightAdj = 2 * offsetHeight - headerControlsHeight - 120;
      const offsetWidthAdj = offsetWidth - 160;
      dimensionsInfo.containerSize = offsetHeightAdj < offsetWidthAdj ? offsetHeightAdj : offsetWidthAdj;
      dimensionsInfo.dimension = offsetHeightAdj < offsetWidthAdj ? "height" : "width";
      dimensionsInfo.containerWidth = offsetWidthAdj;
      dimensionsInfo.containerHeight = offsetHeightAdj;
    }

    return dimensionsInfo;
  };

  const updateDimensionsInfo = () => {
    setDimensionsInfo(getDimensionsInfo());
  };

  const handleSlideChange = (index: number, indexWasAdjusted?: boolean) => {
    if (indexWasAdjusted) {
      setIsAdjustedIndex(false);
      return;
    }

    if (index >= 0 && index < totalCount) {
      let newIndex = index;

      if (firstIdx > 0 && index <= THRESHOLD) {
        const newFirstIdx = Math.max(firstIdx - BATCH_SIZE, 0);
        setImages(props.images.slice(newFirstIdx, firstIdx).concat(images));
        setFirstIdx(newFirstIdx);
        setIsAdjustedIndex(true);
        newIndex = firstIdx + index;
      }

      if (lastIdx < totalCount && index >= images.length - THRESHOLD) {
        const updatedImages = images.concat(props.images.slice(lastIdx, lastIdx + BATCH_SIZE));
        setImages(updatedImages);
        setLastIdx(Math.min(updatedImages.length, lastIdx + BATCH_SIZE));
      }
      setSelectedIndex(newIndex);
      if (props.onSlideChange) {
        // Here we have to return a regular index based on the whole images collection
        props.onSlideChange(newIndex + firstIdx);
      }
    }
  };

  const setShownHandler = () => {
    setShown(true);
  };

  const enableBlockHide = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setBlockHide(true);
  };

  const disableBlockHide = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setBlockHide(false);
  };

  const handleTabEvent = (e: React.MouseEvent<HTMLElement>) => {
    const isDotClick = Array.from((e.target as Element).classList).some((item: any) => item.includes("dot"));
    if (isMobile && !isDotClick) {
      setBlockHide(false);
    } else if (isMobile && !isShown && isDotClick) {
      setBlockHide(false);
      setShown(true);
    }
  };

  const handleResize = () => {
    setSlideHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isShown) {
      setTimeout(() => {
        if (!blockHideRef.current) {
          setShown(false);
        }
      }, 2500);
    }

    updateDimensionsInfo();
    setHeaderHeight(headerControlsRef.current && !props.isHeaderAbsolute ? headerControlsRef.current?.offsetHeight : 0);
  }, [isShown, blockHide, props.images, props.selectedIndex]);

  const shownEffect = isShown ? styles.shown : styles.noShown;

  const getSmallCarouselHeight = () => {
    return window.innerHeight - 60 - headerHeight - (props.lessHeight || 0);
  };

  const mediaItem = images[selectedIndex];
  const caption = props.captionsMap?.[mediaItem.id];

  return (
    <React.Fragment>
      <style>
        {`
            :root {
              --arrows-top: ${headerHeight}px
              }
            `}
      </style>
      <div
        style={{
          maxWidth: props.lessWidth && !isMobile ? `calc(100% - ${props.lessWidth}px)` : "100%",
          height: `${window.innerHeight}px`
        }}
        ref={divRef}
        className={styles.lightBox}
        onMouseMove={setShownHandler}
      >
        <div style={{ height: `${window.innerHeight}px` }} className={styles.lightBoxContent}>
          <div
            ref={headerControlsRef}
            onMouseLeave={disableBlockHide}
            style={{ maxWidth: props.lessWidth && !isMobile ? `calc(100vw - ${props.lessWidth}px)` : "100vw" }}
            onMouseOver={enableBlockHide}
            className={cx(styles.headerControls, props.headerClassName, shownEffect, {
              [styles.headerAbsolute]: props.isHeaderAbsolute
            })}
          >
            {props.header}
          </div>

          <div className={props.areStatsOpen ? styles.providerContainer : ""} onClick={handleTabEvent}>
            <CarouselProvider
              className={styles.carousel}
              currentSlide={selectedIndex}
              naturalSlideWidth={dimensionsInfo.containerWidth}
              naturalSlideHeight={dimensionsInfo.containerHeight}
              totalSlides={totalCount}
              visibleSlides={1}
              touchEnabled={!props.isSingleView}
              dragEnabled={!props.isSingleView}
            >
              <ImageSlider
                styles={sliderStyles}
                currentSlide={selectedIndex}
                onSlideChange={handleSlideChange}
                images={images}
                headerHeight={headerHeight}
                slideHeight={slideHeight}
                isAdjustedIndex={isAdjustedIndex}
                photoUrlTemplate={props.photoUrlTemplate}
                thumbnailUrlTemplate={props.thumbnailUrlTemplate}
                videoUrlTemplate={props.videoUrlTemplate}
                videoAnalyticsParams={props.videoAnalyticsParams}
                onVideoPlayingChange={setBlockSmallCarousel}
                isSingleView={props.isSingleView}
              />

              {!props.isSingleView && (
                <React.Fragment>
                  {selectedIndex !== 0 && (
                    <ButtonBack
                      className={cx(styles.carouselBack, shownEffect)}
                      children={
                        <div onMouseLeave={disableBlockHide} onMouseOver={enableBlockHide}>
                          {" "}
                          <Caret color={colors.white} size={16} minX={0} minY={0} open={false} />
                        </div>
                      }
                    />
                  )}

                  {selectedIndex !== images.length - 1 && (
                    <ButtonNext
                      className={cx(styles.carouselNext, shownEffect)}
                      children={
                        <div onMouseLeave={disableBlockHide} onMouseOver={enableBlockHide}>
                          <Caret color={colors.white} size={16} minX={0} minY={0} open={false} />
                        </div>
                      }
                    />
                  )}

                  <div
                    onClick={handleTabEvent}
                    style={{
                      top: getSmallCarouselHeight()
                    }}
                    onMouseLeave={disableBlockHide}
                    onMouseOver={enableBlockHide}
                    className={cx(
                      styles.smallCarousel,
                      { [!isShown ? styles.backIndex : styles.topIndex]: isMobile },
                      blockSmallCarousel ? styles.noShown : shownEffect
                    )}
                  >
                    {!props.hideName && <div className={styles.imageName}>{mediaItem.name || mediaItem.fileName}</div>}
                    {!caption || <div className={styles.caption}>{caption}</div>}
                    <DotsImageGroup
                      className={cx(styles.dotsImageGroup)}
                      currentIndex={selectedIndex}
                      rawIndex={firstIdx + selectedIndex}
                      images={images}
                      totalCount={totalCount}
                      photoUrlTemplate={props.photoUrlTemplate}
                      thumbnailUrlTemplate={props.thumbnailUrlTemplate}
                      observerComponent={props.observerComponent}
                    />
                  </div>
                </React.Fragment>
              )}
            </CarouselProvider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LightBox;
