import React, { Component } from "react";
import styles from "../zenInstagramBlock.module.scss";
import cx from "classnames";
import Caret from "components/Icons/Caret32px";
import { Swipeable } from "react-swipeable";
import { isMobile } from "react-device-detect";
import Images from "./images";
import { IInstagramImage, IInstagramImageLayoutB } from "../";

export interface ICarouselProps {
  caretColor: string;
  appearanceWidth: string;
  photosLength: number;
  layout?: string;
  hasNextPage: boolean;
  goNext: (target: number, pos: number, ref: React.RefObject<HTMLDivElement>) => void;
  goBack: (target: number, pos: number, ref: React.RefObject<HTMLDivElement>, callback?: () => void) => void;
  getImagesNextPage: () => void;
  images: IInstagramImage[];
  imagesLayoutB: IInstagramImageLayoutB[];
  readOnly?: boolean;
  isFetchingImages: boolean;
  currentPhoto: number;
  caption: boolean;
  lightbox: string;
  handleImageClick: (index: number) => void;
}

interface ICarouselState {
  canGoBack: boolean;
  canGoNext: boolean;
  centerPhotos: boolean;
  isMobile: boolean;
}

class DesktopCarousel extends Component<ICarouselProps, ICarouselState> {
  public imagesContainerRef: React.RefObject<HTMLDivElement>;
  public imagesContentRef: React.RefObject<HTMLDivElement>;

  constructor(props: ICarouselProps) {
    super(props);
    this.state = {
      canGoBack: false,
      canGoNext: false,
      centerPhotos: false,
      isMobile: false
    };

    this.imagesContainerRef = React.createRef();
    this.imagesContentRef = React.createRef();
  }

  private getImageSize = () => {
    const { layout, appearanceWidth } = this.props;
    const IMAGE_MARGIN_RIGHT = 12;
    const IMAGE_SIZE = 285;
    const IMAGE_SIZE_LAYOUT_C = 164;

    if (layout == "C") {
      return appearanceWidth === "fixed" ? IMAGE_SIZE_LAYOUT_C + IMAGE_MARGIN_RIGHT : IMAGE_SIZE_LAYOUT_C;
    } else {
      return appearanceWidth === "fixed" || layout === "B" ? IMAGE_SIZE + IMAGE_MARGIN_RIGHT : IMAGE_SIZE;
    }
  };

  private getPhotosLength = (imgSize: number) => {
    const { layout, appearanceWidth, photosLength } = this.props;
    if (layout == "C") {
      return appearanceWidth === "fixed" ? imgSize * photosLength - 4 : imgSize * photosLength;
    } else {
      return appearanceWidth === "fixed" || layout === "B" ? imgSize * photosLength - 12 : imgSize * photosLength;
    }
  };

  private getStepAmountPrevBtn = (pos: number) => {
    const imgSize = this.getImageSize();
    const position = pos * -1;
    // checks if space left is smaller than a photo
    if (position < imgSize) {
      return position;
    } else {
      return imgSize;
    }
  };

  private getStepAmountNextBtn = (pos: number, containerWidth: number) => {
    const { photosLength } = this.props;
    const imgSize = this.getImageSize();
    // checks if space left is smaller than a photo
    if (imgSize * photosLength - containerWidth + pos < imgSize) {
      return (imgSize * photosLength - containerWidth + pos) * -1;
    } else {
      return imgSize * -1;
    }
  };

  private getMoreImages = () => {
    const { hasNextPage, isFetchingImages } = this.props;
    if (this.imagesContainerRef.current && this.imagesContentRef.current && hasNextPage && !isFetchingImages) {
      const imgSize = this.getImageSize();
      const photolenght = this.getPhotosLength(imgSize);
      const pos = Number(this.imagesContentRef.current.style.left.replace("px", "")) * -1;

      // if there are at least three images remaining we request more photos
      if (photolenght - (this.imagesContainerRef.current.offsetWidth + pos) < imgSize * 3) {
        this.props.getImagesNextPage();
      }
    }
  };

  private hidePrevButton = () => {
    this.setState({ canGoBack: false });
  };

  public handlePrevButtonClick = () => {
    if (this.imagesContentRef.current && this.state.canGoBack) {
      const pos = Number(this.imagesContentRef.current.style.left.replace("px", ""));
      const target = this.getStepAmountPrevBtn(pos);
      this.props.goBack(target, pos, this.imagesContentRef, this.hidePrevButton);

      setTimeout(() => {
        this.validateShowNextButton();
      }, 400);
    }
  };

  public handleNextButtonClick = () => {
    if (this.imagesContainerRef.current && this.imagesContentRef.current && this.state.canGoNext) {
      const pos = Number(this.imagesContentRef.current.style.left.replace("px", ""));
      const target = this.getStepAmountNextBtn(pos, this.imagesContainerRef.current.offsetWidth);

      this.props.goNext(target, pos, this.imagesContentRef);

      this.setState({ canGoBack: true });

      this.getMoreImages();

      setTimeout(() => {
        this.validateShowNextButton();
      }, 400);
    }
  };

  private validateShowNextButton = () => {
    if (this.imagesContainerRef.current && this.imagesContentRef.current) {
      const imgSize = this.getImageSize();
      const photolenght = this.getPhotosLength(imgSize);
      const pos = Number(this.imagesContentRef.current.style.left.replace("px", "")) * -1;

      if (this.imagesContainerRef.current.offsetWidth + pos >= photolenght) {
        this.setState({ canGoNext: false });
      } else {
        this.setState({ canGoNext: true });
      }
      if (this.imagesContainerRef.current.offsetWidth > photolenght) {
        this.setState({ centerPhotos: true });
      } else {
        this.setState({ centerPhotos: false });
      }
    }
  };

  private goToFirstImage = () => {
    if (this.imagesContentRef.current) {
      this.imagesContentRef.current.style.left = "0px";
      this.setState({ canGoBack: false });
      this.validateShowNextButton();
    }
  };

  public componentDidMount() {
    this.validateShowNextButton();
    window.addEventListener("resize", this.validateShowNextButton);
    window.addEventListener("orientationchange", this.validateShowNextButton);

    if (isMobile) {
      this.setState({ isMobile: true });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.validateShowNextButton);
    window.removeEventListener("orientationchange", this.validateShowNextButton);
  }

  public componentDidUpdate(prevProps: ICarouselProps) {
    if (this.props.layout !== prevProps.layout) {
      this.goToFirstImage();
    } else if (this.props.appearanceWidth !== prevProps.appearanceWidth) {
      this.goToFirstImage();
      this.validateShowNextButton();
    }
    if (this.props.photosLength !== prevProps.photosLength) {
      this.validateShowNextButton();
    }
  }

  public shouldComponentUpdate(nextProps: ICarouselProps, nextState: ICarouselState) {
    return (
      nextProps.images !== this.props.images ||
      nextProps.layout !== this.props.layout ||
      nextProps.caption !== this.props.caption ||
      nextProps.lightbox !== this.props.lightbox ||
      nextProps.caretColor !== this.props.caretColor ||
      nextProps.appearanceWidth !== this.props.appearanceWidth ||
      this.state !== nextState
    );
  }

  public render() {
    const { images, imagesLayoutB, layout, readOnly, currentPhoto, caption, lightbox, handleImageClick } = this.props;
    return (
      <div className={styles.sliderBlock} ref={this.imagesContainerRef}>
        <Swipeable onSwipedRight={this.handlePrevButtonClick} onSwipedLeft={this.handleNextButtonClick}>
          {this.state.canGoBack && !this.state.isMobile && (
            <button className={styles.sliderArrow + " " + styles.prevArrow} onClick={this.handlePrevButtonClick}>
              <Caret left color={this.props.caretColor} size={32} />
            </button>
          )}
          <div
            className={cx(styles.sliderContainer, { [styles.center]: this.state.centerPhotos })}
            ref={this.imagesContentRef}
            style={{ left: 0 }}
          >
            <Images
              images={images}
              imagesLayoutB={imagesLayoutB}
              layout={layout}
              isMobileSize={false}
              readOnly={readOnly}
              currentPhoto={currentPhoto}
              caption={caption}
              lightbox={lightbox}
              handleImageClick={handleImageClick}
            />
          </div>
          {this.state.canGoNext && !this.state.isMobile && (
            <button className={styles.sliderArrow + " " + styles.nextArrow} onClick={this.handleNextButtonClick}>
              <Caret left={false} color={this.props.caretColor} size={32} />
            </button>
          )}
        </Swipeable>
      </div>
    );
  }
}

export default DesktopCarousel;
