import React from "react";
import IIConProps from "../../IIconProps";

const More = (props: IIConProps) => {
  const opacity = props.opacity || 1;
  const width = props.width || props.size;
  const height = props.height || props.size;
  const minX = props.minX || 0;
  const minY = props.minY || 0;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g fillRule="evenodd" fill={props.color} opacity={opacity} transform="rotate(-90 16 16)">
        <circle cx="8.5" cy="16.5" r="2.5" />
        <circle cx="23.5" cy="16.5" r="2.5" />
        <circle cx="16" cy="16.5" r="2.5" />
      </g>
    </svg>
  );
};

export default More;
