import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

export interface IFieldProps {
  label: string;
  optional?: boolean;
  disabled?: boolean;
  className?: string;
}

class Field extends React.Component<IFieldProps> {
  public render() {
    const { label, children, optional, disabled, className } = this.props;

    return (
      <div className={classNames(styles.field, className)}>
        <div className={classNames(styles.label, disabled && styles.disabled)}>
          {label} {optional && <span className={styles.optional}>(optional)</span>}
        </div>
        {children}
      </div>
    );
  }
}

export default Field;
