import { Video, VideoType } from "components/blocks/blockModels";

export const validVideoURL = (str: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const getVideoFromUrl = (url: string): Video | null => {
  let videoId: string | string[] = "";
  if (!url || !validVideoURL(url)) {
    return null;
  }
  const modifiedUrl: string[] = url.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (modifiedUrl[2] !== undefined) {
    videoId = modifiedUrl[2].split(/[^0-9a-z_\-]/i);
    videoId = videoId[0];
    return { type: VideoType.Youtube, id: videoId };
  }
  videoId = url;
  if (videoId.indexOf(VideoType.Vimeo) !== -1) {
    const splitedVimeoUrl: string[] = videoId.split("/");
    return { type: VideoType.Vimeo, id: splitedVimeoUrl[splitedVimeoUrl.length - 1] };
  }
  return null;
};
