import { postcodeValidator } from "postcode-validator";
import trim from "lodash/trim";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js/min";

export abstract class Validation {
  public static readonly maxEmailUserPartLength = 64;
  public static readonly maxEmailDomainPartLength = 254;
  public static readonly maxEmailTotalLength = 256;

  public static readonly maxPhoneNumberLength = 50;

  public static isNotEmptyString(str: string | null | undefined) {
    return trim(str || "") !== "";
  }

  public static errorIfEmpty(str: string | null | undefined, error: string) {
    return Validation.isNotEmptyString(str) ? "" : error;
  }

  public static extractEmail = (s: string) => {
    const indexStart = s.indexOf("<");
    const indexClose = s.indexOf(">");
    if (indexStart > -1 && indexClose > -1) {
      return s.substring(indexStart + 1, s.length - 1).trim();
    } else {
      return s.trim();
    }
  };

  public static isValidMail = (s: string) => {
    const email = Validation.extractEmail(s);
    return Validation.isValidEmail(email);
  };

  public static isValidEmail(email: string) {
    // https://github.com/JeremySkinner/FluentValidation/blob/master/src/FluentValidation/Validators/EmailValidator.cs#L43
    if (
      // eslint-disable-next-line
      !/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
        email
      )
    ) {
      return false;
    }

    // https://github.com/Zenfolio/zf-utilities/blob/develop/Zenfolio.Common.API/Validation/Extensions.cs#L302
    if (email.length > Validation.maxEmailTotalLength) {
      return false;
    }

    const emailParts = email.split("@");
    if (emailParts.length !== 2) {
      return false;
    }

    return (
      emailParts[0].length <= Validation.maxEmailUserPartLength &&
      emailParts[1].length <= Validation.maxEmailDomainPartLength
    );
  }

  public static parsePhoneNumber(str: string | null | undefined, iso3166CountryCode: CountryCode = "US") {
    if (str) {
      try {
        return parsePhoneNumberFromString(str, iso3166CountryCode) || null;
        // tslint:disable-next-line:no-empty
      } catch (e) {}
    }

    return null;
  }

  public static isValidPhoneNumber(str: string | null | undefined, iso3166CountryCode: CountryCode = "US") {
    const phoneNumber = this.parsePhoneNumber(str, iso3166CountryCode);

    return phoneNumber ? phoneNumber.isValid() === true && phoneNumber.country === iso3166CountryCode : false;
  }

  public static isValidZipCode(str: string, countryCode?: string | null) {
    return postcodeValidator(str, countryCode?.toUpperCase() || "US");
  }

  public static isAlpha(str: string) {
    return /^[a-z ]+$/i.test(str);
  }
}
