import cx from "classnames";
import moment from "moment";
import { IZenSiteTheme } from "../../../models/models";
import { PageContext } from "utilities/pageContext";
import React, { Component, ContextType, createRef, RefObject, SyntheticEvent } from "react";
import { getBlockTextColor } from "utilities/blocks/blockColors";

import { getSiteFonts } from "utilities/blocks/site";
import LikesCounter from "./options/LikesCounter/LikesCounter";
import Share from "./options/Share/Share";
import ViewsCounter from "./options/ViewsCounter/ViewsCounter";
import TextOrPlaceholder from "./TextOrPlaceholder/TextOrPlaceholder";

import styles from "./zenBlogPostHeader.module.scss";

export enum ZenBlogPostHeaderBlockAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right"
}

export interface IBlogPostHeaderSettings {
  author: string;
  title: string;
  showPublishDate: boolean;
  showViews: boolean;
  showLikes: boolean;
  showShare: boolean;
  alignment: ZenBlogPostHeaderBlockAlignment;
}

export interface IZenBlogPostHeaderBlockProps {
  siteTheme: IZenSiteTheme;
  isSelected: boolean;
  backgroundType?: string;
  backgroundColor?: string;

  config: IBlogPostHeaderSettings;
  publishDate?: Date;
  viewsCounter?: number;
  likesCounter?: number;
  onShareClick?: () => void;
  onLikeClick?: () => void;
  liked?: boolean;

  editMode?: boolean;
}

interface IZenBlogPostHeaderBlockState {}

export class ZenBlogPostHeaderBlock extends Component<IZenBlogPostHeaderBlockProps, IZenBlogPostHeaderBlockState> {
  public static contextType = PageContext;
  private containerRef: RefObject<HTMLDivElement>;

  constructor(props: IZenBlogPostHeaderBlockProps, context: ContextType<typeof PageContext>) {
    super(props);
    this.containerRef = createRef<HTMLDivElement>();

    this.state = {};
  }

  private cancelDrag = (event: SyntheticEvent) => {
    event.preventDefault();
    return false;
  };

  public render() {
    const {
      isSelected,
      siteTheme,
      config,
      publishDate,
      viewsCounter,
      likesCounter,
      onShareClick,
      backgroundType,
      backgroundColor,
      onLikeClick,
      liked,
      editMode
    } = this.props;

    const siteFonts = getSiteFonts(siteTheme.fontsStyle);

    const titleClass = cx(styles.title, styles[siteFonts.primary], editMode && styles.clickable);
    const titleStyle = { color: siteTheme.accentColor.value };

    const colorStyles = {
      backgroundColor: siteTheme.backgroundColor ? siteTheme.backgroundColor.value : undefined,
      color: getBlockTextColor(
        backgroundType === "none" ? siteTheme.backgroundColor.value : backgroundColor,
        siteTheme.accentColor.value,
        backgroundType,
        siteTheme.backgroundColor.value
      )
    };

    let contentContainerClass = cx(styles.content, styles[siteFonts.secondary]);

    let rootContainerClass = cx(styles.root);

    if (isSelected) {
      rootContainerClass = cx(rootContainerClass, styles.selected);
    }

    const hasAnyOptions = config.showLikes || config.showViews || config.showShare;

    const publishDateString =
      config.showPublishDate && publishDate
        ? moment(publishDate)
            .format("MMMM D, YYYY")
            .toUpperCase()
        : "<PUBLISH DATE>";

    const getAlignmentClass = () => {
      switch (config.alignment) {
        case ZenBlogPostHeaderBlockAlignment.LEFT:
          return styles.alignLeft;
        case ZenBlogPostHeaderBlockAlignment.RIGHT:
          return styles.alignRight;
        default:
          return styles.alignCenter;
      }
    };

    const getOptionsAlignmentClass = () => {
      switch (config.alignment) {
        case ZenBlogPostHeaderBlockAlignment.LEFT:
          return styles.alignFlexLeft;
        case ZenBlogPostHeaderBlockAlignment.RIGHT:
          return styles.alignFlexRight;
        default:
          return styles.alignFlexCenter;
      }
    };

    const textAlignmentClass = getAlignmentClass();
    contentContainerClass = cx(contentContainerClass, textAlignmentClass);

    const optionsAlignmentClass = getOptionsAlignmentClass();

    return (
      <div
        id="blogHeader"
        onDragStart={this.cancelDrag}
        ref={this.containerRef}
        className={rootContainerClass}
        style={colorStyles}
      >
        <div className={contentContainerClass}>
          <div className={cx(styles.authorContainer, editMode && styles.clickable)}>
            {(editMode || config.author) && (
              <div className={styles.author}>
                <TextOrPlaceholder text={config.author} placeholder="Author" />
              </div>
            )}
          </div>
          <div className={titleClass} style={titleStyle}>
            <TextOrPlaceholder text={config.title} placeholder="Title" />
          </div>
          {config.showPublishDate ? <div className={styles.publishDate}>{publishDateString}</div> : null}
          {hasAnyOptions ? (
            <div className={cx(styles.optionsContainer, optionsAlignmentClass)}>
              {config.showViews ? <ViewsCounter count={viewsCounter} /> : null}
              {config.showLikes ? (
                <LikesCounter count={likesCounter} onClick={liked ? undefined : onLikeClick} liked={liked} />
              ) : null}
              {config.showShare ? <Share onShareClick={onShareClick} /> : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
