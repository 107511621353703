import { NavigationManager } from "./navigationManager";

export default abstract class NewRelic {
  public static readonly isEnabled = process.env.REACT_APP_NEW_RELIC_ENABLED === "true";

  public static init() {
    NewRelic.refreshUserId();
  }

  public static refreshUserId(id: string = "") {
    if (NewRelic.isEnabled) {
      newrelic.setUserId((id || NavigationManager.instance.widgetPhotographerId)?.toLowerCase() || null);
    }
  }

  public static logError(error: Error, customAttributes?: Record<string, string | number>) {
    if (NewRelic.isEnabled) {
      newrelic.noticeError(error, customAttributes);
    }
  }
}
