import React, { ReactElement, useState, useEffect } from "react";
import cx from "classnames";
import styles from "./multipleToast.module.scss";
import cautionIcon from "./icon-caution-red.svg";

export enum TOAST_TYPE {
  GENERAL = 0,
  ALBUM_PREVIEW = 1,
  ALBUM_SHARE = 2
}

export enum TOAST_LAYOUT_TYPE {
  DANGER = "toastDanger"
}

export enum TOAST_POSITION {
  TOP_RIGHT = "topRight"
}

export interface IMultipleToast {
  message: ReactElement | null;
  className?: string;
  animateIn: boolean;
  type: TOAST_TYPE | null;
  typeLayout: TOAST_LAYOUT_TYPE | null;
  dismissTime?: number;
}

export interface IMultipleToastProps {
  toastList: any[];
  className?: string;
  position: TOAST_POSITION;
  autoDelete: boolean;
  dismissTime: number;
}

const MultipleToast: React.FC<IMultipleToastProps> = props => {
  const { toastList, position, autoDelete, dismissTime } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);

    // eslint-disable-next-line
  }, [toastList]);

  const deleteToast = (id: any) => {
    const listItemIndex = list.findIndex(e => e.id === id);
    const toastListItem = toastList.findIndex(e => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };

  const getPositionStyle = (position: TOAST_POSITION) => {
    let result = {};
    switch (position) {
      case TOAST_POSITION.TOP_RIGHT:
        result = styles.topRight;
        break;
    }
    return result;
  };

  const getLayoutStyle = (typeLayout: TOAST_LAYOUT_TYPE) => {
    let result = {};
    switch (typeLayout) {
      case TOAST_LAYOUT_TYPE.DANGER:
        result = styles.toastDanger;
        break;
    }
    return result;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [toastList, autoDelete, dismissTime, list]);
  return (
    <React.Fragment>
      <div className={cx(styles.notificationContainer, getPositionStyle(position), props.className)}>
        {list.map((toast, i) => {
          return (
            <div
              key={i}
              className={cx(
                styles.notification,
                styles.toast,
                getPositionStyle(position),
                getLayoutStyle(toast.typeLayout)
              )}
              style={{ backgroundColor: toast.backgroundColor }}
            >
              <div key={i} className={cx(styles.alertMessage)}>
                <span className={styles.message}>{toast.description}</span>
                <span>
                  <img alt="messageImage" src={cautionIcon} width="24" />
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default MultipleToast;
