import { Button, FormProgress, isErrorType, Utilities } from "@zenfolio/core-components";
import classNames from "classnames";
import _ from "lodash";
import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { isMobile } from "react-device-detect";
import {
  bookingDoesNotBelongPhotographerError,
  bookingPaymentError,
  createPaymentMethodError,
  inappropriateInvoiceStatusError,
  invoiceDoesNotExistError,
  photographerDoesNotExistError,
  widgetIsNotActivatedError
} from "../../api/errorTypes";
import bookingsIcon from "../../assets/icons/bookings.svg";
import spinner from "../../assets/icons/spinner.svg";
import ModalRoot from "../../containers/modal";
import BookingConfirmationStep from "../../containers/widget/steps/confirmation/booking";
import InvoiceConfirmationStep from "../../containers/widget/steps/confirmation/invoice";
import DateTimeStep from "../../containers/widget/steps/dateTime";
import DetailsStep from "../../containers/widget/steps/details";
import InRadiusLocationStep from "../../containers/widget/steps/location/inRadius";
import PrimaryOnlyLocationStep from "../../containers/widget/steps/location/primaryOnly";
import BookingPaymentStep from "../../containers/widget/steps/payment/booking";
import InvoicePaymentStep from "../../containers/widget/steps/payment/invoice";
import ServicesStep from "../../containers/widget/steps/services";
import { insertItem, removeItem, updateItem } from "../../store/helpers";
import { IWidgetErrorModalProps } from "../../store/modal/model";
import { IService } from "../../store/widget/model";
import Auxiliary from "../../utilities/auxiliary";
import colors from "../../utilities/colors";
import { CustomizeManager } from "../../utilities/customizeManager";
import IError from "../../utilities/error";
import eventTracker from "../../utilities/eventTracker";
import { hideTrackHorizontal, isEnterKey } from "../../utilities/helpers";
import { NavigationManager } from "../../utilities/navigationManager";
import Checkout from "../checkout";
import LinkButton from "../linkButton";
import { isFreeService } from "../services/common";
import LockIcon from "./../icons/Lock";
import { Direction, IValidatableWidgetStep, IWidgetStep, IWidgetValues, LayoutType, StepParams } from "./contracts";
import styles from "./index.module.scss";
import { StepName } from "./steps/constants";

interface IStep {
  name: StepName;
  title: string;
  component: any;
  validated: boolean;
  fullSize?: boolean;
}

interface INavigationButtonsVisibility {
  prevVisible: boolean;
  nextVisible: boolean;
}

interface IWidgetProps {
  onLoadWidgetState: () => void;
  onLoadInvoiceState: (invoiceId: string) => void;
  onResetWidget: () => void;
  onResetBookingError: (failureCode: string) => void;
  onWidgetError: (error: IWidgetErrorModalProps) => void;
  nextButtonText: string;
  prevButtonText: string;
  orderButtonText: string;
  confirmationButtonText: string;
  prevButtonOnLastStep: boolean;
  isBookingCreating: boolean;
  isInvoicePaying: boolean;
  error: IError | null;
}

export interface IWidgetState {
  visible: boolean;
  hidden: boolean;
  animating: boolean;
  expanding: boolean;
  values: IWidgetValues;
  step: number;
  steps: IStep[];
  layoutType: LayoutType;
}

class Widget extends React.Component<IWidgetProps, IWidgetState> {
  constructor(props: IWidgetProps) {
    super(props);

    this.isInIframe = NavigationManager.isInIframe();

    this.state = {
      visible: false,
      hidden: false,
      animating: false,
      expanding: false,
      values: Widget.defaultValues,
      step: 0,
      layoutType: Widget.defaultLayoutType,
      steps: this.buildSteps()
    };
  }

  public componentDidMount() {
    this.loadData();

    document.addEventListener("keydown", this.handleKeyPress, true);
    document.addEventListener("click", this.handleClickOutside, true);
    document.addEventListener("touchstart", this.handleClickOutside, true);

    if (NavigationManager.isInIframe()) {
      window.addEventListener("message", this.processMessage);
      NavigationManager.postParentMessage({ type: "loaded", isMobile });
    } else {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("orientationchange", this.onResize);
    }

    if (this.invoiceId) {
      this.toggleBookingWidget();
    }

    eventTracker.context.consumer.flow = this.invoiceId ? "invoice" : "booking";
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, true);
    document.removeEventListener("click", this.handleClickOutside, true);
    document.removeEventListener("touchstart", this.handleClickOutside, true);

    if (NavigationManager.isInIframe()) {
      window.removeEventListener("message", this.processMessage);
    } else {
      window.removeEventListener("resize", this.onResize);
      window.removeEventListener("orientationchange", this.onResize);
    }
  }

  public componentDidUpdate(prevProps: IWidgetProps, prevState: IWidgetState) {
    if (this.props.error && !prevProps.error) {
      const errorInfo = this.getErrorInformation(this.props.error);
      this.props.onWidgetError(errorInfo);
    }

    if (prevState) {
      const { visible, animating, expanding } = this.state;

      if (prevState.animating !== animating && !animating && !visible) {
        NavigationManager.postParentMessage({ type: "collapsed" });
      } else if (prevState.expanding !== expanding && expanding) {
        NavigationManager.postParentMessage({ type: "expanding" });
      }

      if (
        this.state.step === this.getStepIndex(StepName.Service) &&
        prevState.step !== this.getStepIndex(StepName.Service)
      ) {
        eventTracker.events.consumer.selectServiceReached();
      }
    }
  }

  private loadData() {
    const { onLoadWidgetState, onLoadInvoiceState } = this.props;
    this.invoiceId ? onLoadInvoiceState(this.invoiceId) : onLoadWidgetState();
  }

  public render() {
    const { prevVisible, nextVisible } = this.getNavigationButtonsVisibility();
    const { visible, animating, step, steps, hidden, layoutType } = this.state;
    const { isBookingCreating, isInvoicePaying } = this.props;
    const { title, component, fullSize } = this.currentStep;

    const buttonDisabled = this.saveAndContinueDisabled;
    const isPaymentStep = step === this.getStepIndex(StepName.Payment);

    const containerClasses = classNames(
      classNames(
        styles.container,
        !this.isInIframe && styles.selfHosted,
        !visible && (animating ? styles.collapsing : styles.collapsed),
        layoutType !== LayoutType.Desktop && styles.mobile,
        layoutType === LayoutType.Landscape && styles.landscape,
        this.placementClassName
      )
    );

    return (
      !hidden && (
        <div ref={this.containerRef} className={containerClasses}>
          <div className={styles.popupBoxWrapper} ref={this.popupBoxWrapperRef} onTransitionEnd={this.onTransitionEnd}>
            <div className={styles.popupBox}>
              <ModalRoot />
              <div className={styles.header}>
                <LinkButton
                  className={classNames(styles.backButton, !prevVisible && styles.hidden)}
                  onClick={this.back}
                >
                  {this.props.prevButtonText}
                </LinkButton>
                <label>{title}</label>
                <div className={styles.minimize} onClick={this.closeBookingWidget}>
                  <i className={styles.minimizeIcon} />
                </div>
              </div>
              {!this.invoiceId && <FormProgress className={styles.progress} value={step} max={steps.length - 1} />}
              <div className={classNames(styles.content, !fullSize && styles.contentScrolled)}>
                {(isBookingCreating || isInvoicePaying) && (
                  <div className={styles.overlay}>
                    <img className={styles.spinner} alt="Spinner" src={spinner} />
                  </div>
                )}
                {fullSize ? (
                  component
                ) : (
                  <Scrollbars renderTrackHorizontal={hideTrackHorizontal} ref={this.scrollbarsRef}>
                    {component}
                  </Scrollbars>
                )}
              </div>
              <div className={classNames(styles.action, isPaymentStep && styles.withMessage)}>
                <Button
                  className={classNames(styles.button, !nextVisible && styles.hidden)}
                  styleType="secondary"
                  onClick={this.saveAndContinue}
                  disabled={buttonDisabled}
                >
                  {this.nextButtonContent}
                </Button>
                {isPaymentStep && (
                  <div className={styles.message}>
                    By clicking Continue, you agree to the{" "}
                    <a
                      href="https://zenfolio.com/bookme/customerterms"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.link}
                    >
                      Customer Terms
                    </a>
                  </div>
                )}
              </div>
              {!this.hidePoweredBy && <div className={styles.footer}>Powered by Zenfolio</div>}
            </div>
          </div>

          <div className={styles.openButton} ref={this.openButtonRef}>
            <LinkButton onClick={this.toggleBookingWidget}>
              <img src={bookingsIcon} alt="bookings icon" />
              <label>Book a Session</label>
            </LinkButton>
          </div>
        </div>
      )
    );
  }

  private buildSteps = (): IStep[] => {
    return this.invoiceId ? this.getInvoicePaymentSteps() : this.getBookingSteps();
  };

  private getBookingSteps = (): IStep[] => {
    return [
      {
        name: StepName.Service,
        title: "Select a Service",
        component: (
          <ServicesStep
            name={StepName.Service}
            onRef={this.setActiveComponent}
            getValues={this.getValues}
            updateValues={this.updateValues}
            updateValidate={this.updateValidated}
            onScrollTop={this.onScrollTop}
          />
        ),
        validated: false
      },
      {
        name: StepName.DateTime,
        title: "Select a Date & Time",
        component: (
          <DateTimeStep
            name={StepName.DateTime}
            onRef={this.setActiveComponent}
            getValues={this.getValues}
            updateValues={this.updateValues}
            updateValidate={this.updateValidated}
          />
        ),
        validated: true,
        fullSize: true
      },
      {
        name: StepName.Location,
        title: "Location",
        component: null,
        validated: false
      },
      {
        name: StepName.Payment,
        title: "Your Order",
        component: (
          <Checkout>
            <BookingPaymentStep
              name={StepName.Payment}
              onRef={this.setActiveComponent}
              getValues={this.getValues}
              updateValues={this.updateValues}
              updateValidate={this.updateValidated}
              nextStep={this.nextStep}
              onEnsureVisible={this.onEnsureVisible}
            />
          </Checkout>
        ),
        validated: false
      },
      {
        name: StepName.Confirmation,
        title: "Confirmation",
        component: (
          <BookingConfirmationStep
            name={StepName.Confirmation}
            onRef={this.setActiveComponent}
            getValues={this.getValues}
            resetValues={this.hardResetValues}
            nextStep={this.nextStep}
          />
        ),
        validated: true
      }
    ];
  };

  private getInvoicePaymentSteps = (): IStep[] => {
    return [
      {
        name: StepName.Payment,
        title: "Your Order",
        component: (
          <Checkout>
            <InvoicePaymentStep
              invoiceId={this.invoiceId!}
              name={StepName.Payment}
              onRef={this.setActiveComponent}
              getValues={this.getValues}
              updateValues={this.updateValues}
              updateValidate={this.updateValidated}
              nextStep={this.nextStep}
              onEnsureVisible={this.onEnsureVisible}
            />
          </Checkout>
        ),
        validated: false
      },
      {
        name: StepName.Confirmation,
        title: "Confirmation",
        component: (
          <InvoiceConfirmationStep
            name={StepName.Confirmation}
            onRef={this.setActiveComponent}
            getValues={this.getValues}
            switchWidgetMode={this.switchWidgetMode}
            nextStep={this.nextStep}
          />
        ),
        validated: true
      }
    ];
  };

  public saveAndContinue = () => {
    this.checkMoveAllowed()
      .then((isValid: boolean) => {
        this.updateCurrentStepValidated(isValid);

        if (isValid) {
          this.activeStep!.saveAndContinue(Direction.Forward);

          if (this.state.step === this.getStepIndex(StepName.Service)) {
            this.adjustStepsForSelectedService();
          }

          if (!this.activeStep!.deferNavigation()) {
            this.nextStep();
          }
        }
      })
      .catch(() => {
        this.updateCurrentStepValidated(false);
      });
  };

  public back = () => {
    this.activeStep!.saveAndContinue(Direction.Backward);
    this.prevStep();
  };

  private closeBookingWidget = () => {
    this.setState(state => {
      if (!state.visible || state.animating || state.expanding) {
        return null;
      }

      return { visible: false, animating: true, expanding: false };
    });
  };

  private toggleBookingWidget = () => {
    const { visible, animating, expanding, step } = this.state;
    if (!visible && !this.invoiceId && !(animating || expanding)) {
      eventTracker.events.consumer.widgetOpened();
      if (step === this.getStepIndex(StepName.Service) && this.neverOpened) {
        eventTracker.events.consumer.selectServiceReached();
        this.neverOpened = false;
      }
    }

    this.setState(state => {
      if (state.animating || state.expanding) {
        return null;
      }

      if (!state.visible && this.isInIframe) {
        return { visible: false, animating: false, expanding: true };
      }

      return { visible: !state.visible, animating: true, expanding: false };
    });
  };

  private onTransitionEnd = (event: React.TransitionEvent<HTMLDivElement>) => {
    if (event.propertyName === "height") {
      this.setState({ animating: false });
    }
  };

  private processMessage = (event: MessageEvent) => {
    const data = NavigationManager.parseMessage(event);
    if (!data || !data.type) {
      return;
    }

    if (data.type === "close") {
      this.closeBookingWidget();
    } else if (data.type === "escape") {
      if (!this.props.error) {
        this.closeBookingWidget();
      }
    } else if (data.type === "enter") {
      if (!this.saveAndContinueDisabled) {
        this.saveAndContinue();
      }
    } else if (data.type === "expanded") {
      this.setState(state => {
        if (!state.expanding) {
          return null;
        }

        return { visible: true, animating: true, expanding: false };
      });
    } else if (data.type === "layout-type-changed") {
      this.setState({ layoutType: data.layoutType });
    }
  };

  private adjustStepsForSelectedService = () => {
    const { service } = this.state.values;

    if (service!.askAdditionalInfo) {
      this.addDetailsStep();
    } else {
      this.removeDetailsStep();
    }

    this.updateLocationStep(service);
  };

  private isServiceRadiusChanged = (): boolean => {
    const { oldService, service } = this.state.values;

    if (!oldService) {
      return true;
    }

    if (oldService.radius !== service!.radius) {
      const oldServiceInRadius = oldService.radius > 0;
      const newServiceInRadius = service!.radius > 0;

      return oldServiceInRadius !== newServiceInRadius;
    }

    return false;
  };

  private updateLocationStep(service: IService | null) {
    if (!this.isServiceRadiusChanged()) {
      return;
    }

    const inRadius = service!.radius > 0;

    this.setState({
      steps: updateItem(
        this.state.steps,
        {
          name: StepName.Location,
          title: "Location",
          component: inRadius ? (
            <InRadiusLocationStep
              name={StepName.Location}
              onRef={this.setActiveComponent}
              getValues={this.getValues}
              updateValues={this.updateValues}
              updateValidate={this.updateValidated}
            />
          ) : (
            <PrimaryOnlyLocationStep
              name={StepName.Location}
              onRef={this.setActiveComponent}
              getValues={this.getValues}
              updateValues={this.updateValues}
              updateValidate={this.updateValidated}
            />
          ),
          validated: inRadius ? false : true
        },
        this.getStepIndex(StepName.Location)
      )
    });
  }

  private removeDetailsStep() {
    if (!this.isDetailsStepExists()) {
      return;
    }

    this.setState({
      steps: removeItem(this.state.steps, this.getStepIndex(StepName.Details))
    });
  }

  private addDetailsStep() {
    if (this.isDetailsStepExists()) {
      return;
    }

    this.setState({
      steps: insertItem(
        this.state.steps,
        {
          name: StepName.Details,
          title: "Details",
          component: (
            <DetailsStep
              name={StepName.Details}
              onRef={this.setActiveComponent}
              getValues={this.getValues}
              updateValues={this.updateValues}
              updateValidate={this.updateValidated}
            />
          ),
          validated: true
        },
        this.getStepIndex(StepName.Location) + 1
      )
    });
  }

  private isDetailsStepExists = (): boolean => {
    return this.getStepIndex(StepName.Details) !== -1;
  };

  private getStepIndex = (stepName: StepName): number => {
    return this.state.steps.findIndex(step => {
      return step.name === stepName;
    });
  };

  public getValues = (): IWidgetValues => {
    return this.state.values;
  };

  public updateValues = (values: StepParams) => {
    const serviceHasChanged = "service" in values;

    this.setState({
      values: {
        ...this.state.values,
        dateTime: serviceHasChanged ? null : this.state.values.dateTime,
        ...values
      }
    });
  };

  private getErrorMessageWithPhoneNumber(error: IError) {
    return (
      "Oops! Something went wrong, to book please contact the studio" +
      (error.phoneNumber
        ? ` at <a href="tel:${error.phoneNumber}">${Utilities.formatPhoneNumber(error.phoneNumber as string)}</a>`
        : "") +
      "."
    );
  }

  private getErrorInformation = (error: IError): IWidgetErrorModalProps => {
    if (isErrorType(error.type, widgetIsNotActivatedError) || isErrorType(error.type, photographerDoesNotExistError)) {
      return {
        message: this.getErrorMessageWithPhoneNumber(error),
        onContinueButtonClick: this.hideWidget
      };
    } else if (
      isErrorType(error.type, invoiceDoesNotExistError) ||
      isErrorType(error.type, bookingDoesNotBelongPhotographerError)
    ) {
      return {
        message: this.getErrorMessageWithPhoneNumber(error),
        onContinueButtonClick: this.switchWidgetMode
      };
    } else if (isErrorType(error.type, inappropriateInvoiceStatusError)) {
      return {
        title: "Payment Received",
        message: "A payment has been received for this invoice. Thank you!",
        onContinueButtonClick: this.switchWidgetMode
      };
    } else if (isErrorType(error.type, createPaymentMethodError) || isErrorType(error.type, bookingPaymentError)) {
      return {
        message: error.message!,
        onContinueButtonClick: () => this.props.onResetBookingError(error.failureCode as string)
      };
    } else {
      return {
        message: "Oops! Something went wrong, please start over and try again.",
        onContinueButtonClick: this.softResetValues
      };
    }
  };

  private hideWidget = () => {
    this.resetInvoiceId();

    this.setState(
      {
        hidden: true
      },
      () => NavigationManager.postParentMessage({ type: "hide" })
    );
  };

  private switchWidgetMode = () => {
    this.resetInvoiceId();
    this.hardResetValues();

    this.setState({
      steps: this.buildSteps()
    });
  };

  private softResetValues = () => {
    const values = {
      ...this.state.values,
      service: null,
      oldService: null,
      dateTime: null
    };

    this.reset(values);
  };

  private hardResetValues = () => {
    this.reset(Widget.defaultValues);
  };

  private reset = (values: IWidgetValues) => {
    this.props.onResetWidget();

    this.setState({
      step: 0,
      values
    });

    this.updateValidated(StepName.Service, false);
    this.updateValidated(StepName.Location, false);
    this.updateValidated(StepName.Payment, false);

    this.loadData();
  };

  private getNavigationButtonsVisibility(): INavigationButtonsVisibility {
    const step = this.state.step;

    let prevVisible = true;
    let nextVisible = true;

    if (step === 0) {
      prevVisible = false;
    }

    if (step >= this.state.steps.length - 1) {
      nextVisible = true;
      prevVisible = this.props.prevButtonOnLastStep === false ? false : true;
    }

    return {
      prevVisible,
      nextVisible
    };
  }

  private get nextButtonContent() {
    const { step, values } = this.state;
    const { orderButtonText, confirmationButtonText, nextButtonText } = this.props;

    const isPaymentStep = step === this.getStepIndex(StepName.Payment);
    const isConfirmationStep = step === this.getStepIndex(StepName.Confirmation);

    if (isPaymentStep) {
      if (!values.service || !isFreeService(values.service)) {
        return (
          <Auxiliary>
            <LockIcon color={this.saveAndContinueDisabled ? colors.grey : colors.white} />
            {orderButtonText}
          </Auxiliary>
        );
      }
    } else if (isConfirmationStep) {
      return confirmationButtonText;
    }

    return nextButtonText;
  }

  private checkMoveAllowed() {
    return Promise.resolve(this.validateComponent());
  }

  private validateComponent = () => {
    if (this.isValidatable(this.activeStep)) {
      return (this.activeStep as IValidatableWidgetStep).isValid();
    }
    return true;
  };

  private setActiveComponent = (ref: IWidgetStep | undefined) => {
    this.activeStep = ref;
  };

  private updateCurrentStepValidated(validated: boolean) {
    const name = this.state.steps[this.state.step].name;
    this.updateValidated(name, validated);
  }

  private updateValidated = (name: StepName, validated: boolean) => {
    const steps = this.state.steps;
    const index = this.getStepIndex(name);
    this.setState({
      steps: updateItem(steps, { ...steps[index], validated }, index)
    });
  };

  private isValidatable(step: IWidgetStep | undefined): boolean {
    return !!step && typeof (step as IValidatableWidgetStep).isValid !== "undefined";
  }

  private prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step > 0 ? step - 1 : step
    });
  };

  private nextStep = () => {
    const { step } = this.state;
    const steps = this.state.steps.length;
    this.setState({
      step: step < steps ? step + 1 : steps
    });
  };

  private handleKeyPress = (evt: KeyboardEvent) => {
    if (!evt || !evt.key) {
      return;
    }

    if (evt.key === "Escape") {
      if (!this.props.error) {
        this.closeBookingWidget();
      }
    } else if (isEnterKey(evt) && !this.saveAndContinueDisabled) {
      this.saveAndContinue();
    }
  };

  private handleClickOutside = (evt: Event) => {
    if (!evt || !evt.target) {
      return;
    }

    const target = evt.target as Element;
    if (target.tagName === "HTML") {
      return;
    }

    if (
      this.containerRef.current &&
      this.containerRef.current.contains(target) &&
      this.popupBoxWrapperRef.current &&
      !this.popupBoxWrapperRef.current.contains(target) &&
      this.openButtonRef.current &&
      !this.openButtonRef.current.contains(target)
    ) {
      this.closeBookingWidget();
    }
  };

  private onScrollTop = (top: number) => {
    if (this.scrollbarsRef.current) {
      (this.scrollbarsRef.current as any).scrollTop(top);
    }
  };

  private onEnsureVisible = (top: number, height: number, minimum?: boolean) => {
    if (this.scrollbarsRef.current) {
      const scrollBar = this.scrollbarsRef.current as any;
      const scrollTop = scrollBar.getScrollTop();
      const clientHeight = scrollBar.getClientHeight();

      if (top < scrollTop) {
        scrollBar.scrollTop(top);
      } else {
        const diff = top + height - scrollTop - clientHeight;
        if (diff > 0) {
          if (minimum) {
            scrollBar.scrollTop(scrollTop + diff);
          } else {
            scrollBar.scrollTop(top);
          }
        }
      }
    }
  };

  private onResize = _.throttle(
    () => {
      const layoutType = Widget.calculateLayoutType();

      this.setState(state => {
        if (state.layoutType === layoutType) {
          return null;
        }

        return { layoutType };
      });
    },
    200,
    { leading: false }
  );

  private get currentStep(): IStep {
    return this.state.steps[this.state.step];
  }

  private get saveAndContinueDisabled() {
    const { isBookingCreating, isInvoicePaying, error } = this.props;

    return !(this.currentStep.validated && !isBookingCreating && !isInvoicePaying && !error);
  }

  private get placementClassName() {
    switch (CustomizeManager.getData().buttonPlacementType) {
      case "left":
        return styles.leftPlacement;
      case "center":
        return styles.centerPlacement;
      default:
        return styles.rightPlacement;
    }
  }

  private static get defaultLayoutType(): LayoutType {
    if (!NavigationManager.isInIframe()) {
      return Widget.calculateLayoutType();
    }

    let widgetLayoutType = NavigationManager.instance.widgetLayoutType;
    if (widgetLayoutType) {
      widgetLayoutType = widgetLayoutType.toUpperCase();
    }

    switch (widgetLayoutType) {
      case LayoutType.Portrait:
        return LayoutType.Portrait;
      case LayoutType.Landscape:
        return LayoutType.Landscape;
      default:
        return LayoutType.Desktop;
    }
  }

  private static calculateLayoutType(): LayoutType {
    let width: number;
    let height: number;

    if (isMobile) {
      width = window.innerWidth;
      height = window.innerHeight;
    } else {
      width = document.documentElement.clientWidth;
      height = document.documentElement.clientHeight;
    }

    let mobile: boolean;
    let landscape: boolean;

    if (width < height) {
      landscape = false;
      mobile = width <= Widget.mobileMinDimension && height <= Widget.mobileMaxDimension;
    } else {
      landscape = true;
      mobile = height <= Widget.mobileMinDimension && width <= Widget.mobileMaxDimension;
    }

    return mobile ? (landscape ? LayoutType.Landscape : LayoutType.Portrait) : LayoutType.Desktop;
  }

  private get invoiceId() {
    return NavigationManager.instance.currentInvoiceId;
  }

  private get hidePoweredBy() {
    return NavigationManager.getHidePoweredBy();
  }

  private resetInvoiceId() {
    NavigationManager.instance.resetInvoiceId();
  }
  private readonly isInIframe: boolean;

  private readonly scrollbarsRef = React.createRef<Scrollbars>();
  private readonly containerRef = React.createRef<HTMLDivElement>();
  private readonly openButtonRef = React.createRef<HTMLDivElement>();
  private readonly popupBoxWrapperRef = React.createRef<HTMLDivElement>();

  private activeStep: IWidgetStep | undefined = undefined;
  private neverOpened = true;

  private static readonly defaultValues: IWidgetValues = {
    service: null,
    oldService: null,
    shootLocation: null,
    address: null,
    addressDetails: null,
    additionalInfo: null,
    dateTime: null,
    email: null,
    name: null,
    phoneNumber: null,
    cardholderName: null,
    zipCode: null,
    agreePayDeposit: {},
    billingAddress: null,
    customerAddress: null
  };

  // Samsung Galaxy Note 5
  private static readonly mobileMinDimension = 480;
  private static readonly mobileMaxDimension = 853;
}

export default Widget;
