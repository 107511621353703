import ViewsIcon from "components/Icons/Views";
import React from "react";
import styles from "./viewsCounter.module.scss";

export interface IViewsCounterProps {
  count?: number;
}

const ViewsCounter = (props: IViewsCounterProps) => {
  const { count } = props;

  return (
    <div className={styles.viewsCounterContainer}>
      <span className={styles.count}>{count ?? 0}</span>
      <ViewsIcon width={32} height={32} />
    </div>
  );
};

export default ViewsCounter;
