import React from "react";
import cx from "classnames";
import _ from "lodash";
import styles from "./largeBannerFreeShipping.module.scss";
import { IBannerContent } from "models/coupon";
import { BannerViewMode, BannerFontStyle, BannerDiscountType } from "utilities/constant";
import ScissorsLine from "./ScissorsLine";
import ScissorsHorizLine from "./ScissorsHorizLine";
import { ICurrency } from "models";
import Background from "../images/free-shipping.png";
import { getLargeBannerStyle } from "../Service";

export interface ILargeBannerFreeShippingProps {
  content?: IBannerContent;
  viewMode: BannerViewMode;
  customClassName?: string;
  currency?: ICurrency;
  onClick?: () => void;
}

const DEFAULT_COLORS = {
  white: "#ffffff",
  lightGreen: "#a08c7b",
  darkGreen: "#4a423b"
};

export class LargeBannerFreeShipping extends React.PureComponent<ILargeBannerFreeShippingProps> {
  public getProps = () => {
    const defaultProps = {
      viewMode: BannerViewMode.DesktopClient,
      content: {
        header: {
          text: "Enjoy Free Shipping",
          color: DEFAULT_COLORS.white
        },
        body: {
          text: "on all orders",
          color: DEFAULT_COLORS.darkGreen
        },
        code: {
          text: "Code: [code]",
          color: DEFAULT_COLORS.white
        },
        button: {
          text: "SHOP NOW",
          color: DEFAULT_COLORS.lightGreen,
          backgroundColor: DEFAULT_COLORS.white
        },
        fontStyle: BannerFontStyle.Serif,
        backgroundColor: DEFAULT_COLORS.lightGreen,
        discountAmount: "20",
        discountType: BannerDiscountType.PercentOff
      }
    };
    const renderProps = _.merge(defaultProps, this.props);
    return renderProps;
  };

  private renderScissorsLine = () => {
    const { viewMode } = this.getProps();
    switch (viewMode) {
      case BannerViewMode.DesktopClient:
      case BannerViewMode.DesktopPreview: {
        return <ScissorsLine />;
      }

      case BannerViewMode.MobileClient:
      case BannerViewMode.MobilePreview: {
        return <ScissorsHorizLine />;
      }
    }
  };

  public render() {
    const { content, viewMode, customClassName } = this.getProps();
    return (
      <div
        className={cx(
          styles.container,
          [styles[viewMode]],
          [styles[content.fontStyle || BannerFontStyle.SansSerif]],
          customClassName
        )}
        style={getLargeBannerStyle(viewMode, content)}
      >
        <div className={styles.leftContent}>
          <div className={styles.header} style={{ color: content.header?.color }}>
            {content?.header?.text}
          </div>
          <div className={styles.body} style={{ color: content.body?.color }}>
            {content?.body?.text}
          </div>
          <div className={styles.code} style={{ color: content.code?.color }}>
            {content?.code?.text}
          </div>
          <div className={styles.btnContainer}>
            <button
              id="LargeBannerFreeShipingButton"
              className={styles.button}
              style={{ color: content.button?.color, backgroundColor: content.button?.backgroundColor }}
              onClick={this.props.onClick}
            >
              <div> {content?.button?.text}</div>
            </button>
          </div>
        </div>
        <div className={styles.scissors}>{this.renderScissorsLine()}</div>
        <div className={styles.rightContent} style={{ color: content.header?.color }}>
          <img src={Background} />
        </div>
      </div>
    );
  }
}

export default LargeBannerFreeShipping;
