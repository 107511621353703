import last from "lodash/last";
import { QualityLevel } from "videojs-contrib-quality-levels";
import { IPlaybackStatsRecord } from ".";

/** Returns the numeric value of the provided *quality*. The *quality* must have *width* and *height* specified. */
export function getQualityValue(quality: QualityLevel): number {
  // this is just for 16:9 AR, should work for us for now
  const knownRes = [
    { width: 256, height: 144 },
    { width: 426, height: 240 },
    { width: 640, height: 360 },
    { width: 854, height: 480 },
    { width: 1280, height: 720 },
    { width: 1920, height: 1080 },
    { width: 2560, height: 1440 },
    { width: 3840, height: 2160 }
  ];

  const res = knownRes.find(
    r =>
      (r.width === quality.width! && r.height >= quality.height!) ||
      (r.height === quality.height! && r.width >= quality.width!)
  );

  if (res) {
    return res.height;
  }

  const fallbackRes = knownRes.find(r => r.width >= quality.width! && r.height >= quality.height!) ?? last(knownRes)!;
  return fallbackRes.height;
}

/** Returns the name of the provided *quality*. The *quality* must have *width* and *height* specified. */
export function getQualityName(quality: QualityLevel): string {
  const value = getQualityValue(quality);
  return `${value}p`;
}

export function formatTime(time: number /* in seconds */): string {
  time = Math.round(time * 1000);
  const minutes = Math.floor(time / 60000);
  const seconds = Math.floor((time - minutes * 60000) / 1000);
  const milliseconds = time - minutes * 60000 - seconds * 1000;
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}.${String(milliseconds).padStart(
    3,
    "0"
  )}`;
}

export function formatPlaybackStatsRecord(record: IPlaybackStatsRecord): string {
  return `${formatTime(record.offset)} - ${formatTime(record.offset + record.duration)} | ${String(
    Math.round(record.duration * 1000)
  ).padStart(7, " ")} ms | ${String(record.quality).padStart(4, " ")}p | ${record.source}`;
}
