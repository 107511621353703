import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Transforms } from "slate";

import DropdownPropertyTree, { findNodeInTree, searchNode } from "components/DropdownPropertyTree";
import { INavigationMenuItem } from "models/models";
import { FormCheckbox } from "components/FormCheckbox";
import {
  getCurrentLinkNodes,
  isValidURL,
  LinkElement,
  unwrapLink,
  urlWithHttp,
  wrapLink
} from "components/TextEditor/utils/withLinks";
import styles from "./floatingToolbar.module.scss";

interface ILinkModal {
  editor: any;
  pages?: INavigationMenuItem[];
  savedSelection: any;
  onCancel: () => void;
}

const LinkModal = (props: ILinkModal) => {
  const editor = props.editor;
  const currentLinkNodes = getCurrentLinkNodes(props.editor);

  const parentNode: INavigationMenuItem = {
    id: "parent_id",
    menuCaption: "parent_caption",
    menuName: "parent_name",
    childs: props.pages
  };

  // The user might have highlighted text that spans more than one link. If
  // that's the case, do not populate the modal with link information. It would
  // be misleading to choose one of the links
  const currentLink = currentLinkNodes.length === 1 ? (currentLinkNodes[0] as LinkElement) : null;

  const [url, setUrl] = useState<string>(currentLink?.href || ("" as string));
  const [activeTab, setActiveTab] = useState<boolean>(false);
  const [isNewTab, setNewTab] = useState<boolean>(currentLink?.target == "_blank");
  const [selectedPageId, setSelectedPageId] = useState<any>(null);

  useEffect(() => {
    // Find current page by selected link URL
    const currentPage = currentLink
      ? findNodeInTree(parentNode, (node, path = []) => {
          const fullPath = [...path, node];
          const URL = `${fullPath.map(node => node.menuLink).join("/")}`;
          return URL === currentLink.href;
        })[0]
      : null;

    // Populate the page dropdown if a page matches the selected text URL
    if (currentPage) {
      setSelectedPageId(currentPage.id);
    }
  }, []);

  const removeLink = () => {
    Transforms.select(editor, props.savedSelection);
    unwrapLink(editor);
    Transforms.collapse(editor, { edge: "end" });
    props.onCancel();
  };

  const insertLink = () => {
    let linkURL: string;

    if (activeTab) {
      // Typing in a manual URL
      if (!isValidURL(url)) {
        return;
      }

      linkURL = urlWithHttp(url);
      setUrl("");
    } else {
      // Selecting a page
      if (!selectedPageId) {
        removeLink();
        return;
      }

      linkURL = searchNode(parentNode, selectedPageId, "", true);
      setSelectedPageId(null);
    }

    Transforms.select(editor, props.savedSelection);
    wrapLink(editor, linkURL, isNewTab);
  };

  return (
    <div className={styles.hyperLink}>
      <div className={styles.title}>
        <label>Link Text</label>
      </div>
      <div style={{ padding: "17px" }}>
        <div className={styles.type}>
          <label>Link Type</label>
          <div className={styles.options}>
            <div
              onClick={() => setActiveTab(false)}
              className={cx(styles.option, styles.firstOption, { [styles.active]: !activeTab })}
            >
              Page
            </div>
            <div
              onClick={() => setActiveTab(true)}
              className={cx(styles.option, styles.lastOption, { [styles.active]: activeTab })}
            >
              URL
            </div>
          </div>
        </div>
        <div className={styles.url}>
          <label>{activeTab ? "URL" : "Page"}</label>
          <div className={styles.pageSelection}>
            {activeTab ? (
              <input
                onChange={e => {
                  e.preventDefault();
                  setUrl(e.target.value);
                }}
                value={url}
                placeholder="Enter URL"
              />
            ) : (
              <DropdownPropertyTree
                pages={props.pages || []}
                onSetValue={pageId => setSelectedPageId(pageId)}
                interactionPage={selectedPageId}
                handleMenuOpen={() => null}
              />
            )}
            <FormCheckbox
              checked={isNewTab}
              onChange={e => {
                setNewTab(!isNewTab);
              }}
              className={styles.depositRequired}
            >
              New Tab
            </FormCheckbox>
          </div>
        </div>
        <div className={styles.footer}>
          <div
            className={styles.link}
            onClick={event => {
              event.preventDefault();
              removeLink();
            }}
          >
            Unlink
          </div>
          <div className={styles.rhs}>
            <div onClick={props.onCancel} className={styles.cancel}>
              Cancel
            </div>
            <div className={styles.confirmButton} onClick={insertLink}>
              Create
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkModal;
