import { Alert } from "@zenfolio/core-components";
import classNames from "classnames";
import * as React from "react";
import { INotificationMessage, NotificationType } from "../../store/notification/actions";
import { isDevelopment } from "../../utilities/helpers";
import styles from "./index.module.scss";

interface IToastrProps {
  version: number;
  notification: INotificationMessage | null;
  onRemoveNotification: () => void;
  pageMode?: boolean;
  className?: string;
}

class Toastr extends React.Component<IToastrProps> {
  public componentDidMount() {
    if (this.props.notification) {
      this.recreateTimer(this.props.notification != null);
    }
  }

  public componentDidUpdate(prevProps: IToastrProps) {
    if (this.props.notification !== prevProps.notification) {
      this.recreateTimer(this.props.notification != null);
    }
  }

  public componentWillUnmount() {
    this.clearTimer();
  }

  public render() {
    const { version, notification, pageMode, className } = this.props;
    return (
      <div className={classNames(styles.container, pageMode ? styles.pageMode : null, className)}>
        {notification && this.renderAlert(version, notification)}
      </div>
    );
  }

  private renderAlert = (version: number, notification: INotificationMessage) => {
    switch (notification.type) {
      case NotificationType.Success:
        return (
          <Alert
            type="success"
            className={styles.successMessage}
            message={notification.message}
            version={version}
            scrollTo="page-top"
          />
        );

      case NotificationType.Error:
        return (
          <Alert
            type="error"
            className={styles.errorMessage}
            message={isDevelopment() ? notification.message : "Please try again."}
            version={version}
            scrollTo="page-top"
          />
        );

      default:
        return null;
    }
  };

  private clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  private recreateTimer(start: boolean) {
    this.clearTimer();

    if (start) {
      this.timer = setTimeout(() => {
        this.timer = undefined;
        this.props.onRemoveNotification();
      }, 5000);
    }
  }

  private timer?: NodeJS.Timeout;
}

export default Toastr;
