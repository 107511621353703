import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  updateBookableHours as updateBookableHoursAction,
  updateBookableHoursReset as updateBookableHoursResetAction
} from "../../../../store/profile/availability/actions";
import { IBookableHours } from "../../../../store/profile/availability/model";
import { IAppState } from "../../../../store/state";

function mapStateToProps({
  profile: {
    availability: { updateBookableHours, availability }
  }
}: IAppState) {
  return {
    updateBookableHours: {
      ...updateBookableHours,
      bookableHours: updateBookableHours.status === "Success" ? availability!.bookableHours : null
    }
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    updateBookableHours: {
      onUpdate: (bookableHours: IBookableHours) => dispatch(updateBookableHoursAction(bookableHours)),
      onReset: () => dispatch(updateBookableHoursResetAction())
    }
  };
}

function mergeProps(stateProps: any, dispatchProps: any, ownProps: any) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    updateBookableHours: { ...stateProps.updateBookableHours, ...dispatchProps.updateBookableHours }
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
