import cx from "classnames";
import React, { FunctionComponent } from "react";
import styles from "./toolbar.module.scss";

interface IProps {
  className?: string;
}

const Section: FunctionComponent<IProps> = ({ className, children }) => {
  return <div className={cx(styles.section, className)}>{children}</div>;
};

const Toolbar: FunctionComponent<IProps> = ({ className, children }) => {
  return <div className={cx(styles.toolbar, className)}>{children}</div>;
};

export { Section, Toolbar };
