// tslint:disable: unified-signatures
import { BookingStatusCode, isBookingStatusCode } from "../../store/bookings/model";

export default class UrlConstants {
  public static profile: string = "/main/profile";
  public static bookings: string = "/main/bookings";
  public static customize: string = "/main/customize";
  public static services: string = "/main/services";
  public static activation: string = "/main/activation";
  public static pageServices: string = "/page/services";

  public static customizeDesign: string = `${UrlConstants.customize}/design`;
  public static bookingsUpcoming: string = `${UrlConstants.bookings}/upcoming`;
  public static activationPages: string = `${UrlConstants.activation}/pages`;

  public static profileShootLocation: string = `${UrlConstants.profile}/primary-location`;
  public static profileAvailability: string = `${UrlConstants.profile}/availability`;
  public static profileContactInformation: string = `${UrlConstants.profile}/contact-information`;
  public static profileCollectingPayments: string = `${UrlConstants.profile}/collecting-payments`;
  public static pageServicesCreate: string = `${UrlConstants.pageServices}/create`;

  public static servicesEdit(serviceId: string) {
    return `${UrlConstants.pageServices}/edit/${serviceId}`;
  }

  /**
   * Generates url using booking ID.
   * Only use this overload if you don't have booking status code. It requires an extra API call and redirect.
   */
  public static booking(bookingId: string): string;
  /**
   * Generates url using booking code and optional booking ID.
   */
  public static booking(code: BookingStatusCode, bookingId?: string): string;
  /**
   * Generates url using the provided booking object.
   */
  public static booking(booking: { id: string; status: { code: BookingStatusCode } }): string;
  public static booking(
    arg0: string | BookingStatusCode | { id: string; status: { code: BookingStatusCode } },
    arg1?: string
  ): string {
    const code = typeof arg0 === "string" ? (isBookingStatusCode(arg0) ? arg0 : undefined) : arg0.status.code;
    const bookingId = typeof arg0 === "string" ? (code ? arg1 : arg0) : arg0.id;
    return `${UrlConstants.bookings}${code ? `/${code}` : ""}${bookingId ? `/${bookingId}` : ""}`;
  }
}
