import cx from "classnames";
import React from "react";
import styles from "./textArea.module.scss";

export interface IProps {
  id?: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  value?: string;
  readonly?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  ref?: React.Ref<HTMLTextAreaElement>;
  errorMessage?: string | null | undefined;
  className?: string;
  textAreaClassName?: string;
  statusClassName?: string;
  errorClassName?: string;
  charCountClassName?: string;
  tabIndex?: number;
  maxLength?: number;
  charCount?: boolean;
  autoSize?: boolean | "height";
}

export const TextArea: React.FC<IProps> = React.forwardRef((props: IProps, ref?: React.Ref<HTMLTextAreaElement>) => {
  const errorMessage = props.errorMessage ? (
    <div className={cx(styles.error, props.errorClassName)}>{props.errorMessage}</div>
  ) : null;

  const charCount =
    props.charCount && props.maxLength ? (
      <div className={cx(styles.charCount, props.charCountClassName)}>
        {props.value ? props.value.length : 0}/{props.maxLength}
      </div>
    ) : null;

  const textArea = (
    <textarea
      id={props.id}
      className={cx(styles.textArea, props.textAreaClassName)}
      onChange={props.onChange}
      placeholder={props.placeholder || ""}
      value={props.value}
      disabled={props.disabled}
      onBlur={props.onBlur ? props.onBlur : () => null}
      onFocus={props.onFocus ? props.onFocus : () => null}
      ref={ref ? ref : null}
      readOnly={props.readonly || false}
      maxLength={props.maxLength}
      tabIndex={props.tabIndex}
      onKeyDown={props.onKeyDown}
    />
  );

  return (
    <div className={cx(styles.container, props.className)}>
      {props.autoSize ? (
        <div className={cx(styles.autoSizeContainer, { [styles.autoWidth]: props.autoSize !== "height" })}>
          <div className={cx(styles.sizingContainer, props.textAreaClassName)}>{(props.value || " ") + "\r\n"}</div>
          {textArea}
        </div>
      ) : (
        textArea
      )}
      {errorMessage || charCount ? (
        <div className={cx(styles.status, props.statusClassName)}>
          {errorMessage}
          {charCount}
        </div>
      ) : null}
    </div>
  );
});
