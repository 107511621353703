import { Button, FormCheckbox, FormTextArea, SingleDateInput, TimeInput } from "@zenfolio/core-components";
import { ITimeInputProps } from "@zenfolio/core-components/dist/components/NumericInputs/TimeInput";
import moment, { Moment } from "moment";
import React from "react";
import {
  IRescheduleConflicts,
  IRescheduleSettings,
  rescheduleConflictsEmpty
} from "../../../../../store/bookings/model";
import { hasActionCompleted } from "../../../../../utilities/helpers";
import ModalDialog from "../../modalDialog";
import { IReschedule } from "../model";
import Field from "./field";
import styles from "./index.module.scss";
import { changeDate, changeTime, getTotalMinutes, roundTime } from "./utils";
import withStore from "./withStore";

export interface IRescheduleOwnProps {
  bookingId: string;
  clientName: string;
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  clientNote?: string;
  internalNote?: string;
  sendNotification?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onConflicts: (settings: IRescheduleSettings, conflicts: IRescheduleConflicts) => void;
}

export interface IRescheduleProps extends IRescheduleOwnProps {
  reschedule: IReschedule;
}

interface IRescheduleState {
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  clientNote?: string;
  internalNote?: string;
  sendNotification: boolean;
}

class Reschedule extends React.Component<IRescheduleProps, IRescheduleState> {
  public state: IRescheduleState;

  constructor(props: IRescheduleProps) {
    super(props);
    this.state = {
      startDateTime: roundTime(moment(props.startDateTime), timeStep, "down"),
      endDateTime: roundTime(moment(props.endDateTime), timeStep, "up"),
      clientNote: props.clientNote,
      internalNote: props.internalNote,
      sendNotification: props.sendNotification !== undefined ? props.sendNotification : true
    };
  }

  public componentDidUpdate(prevProps: IRescheduleProps, prevState: IRescheduleState) {
    const { startDateTime, endDateTime } = this.state;
    const { reschedule } = this.props;
    const endDateHasDecreased = endDateTime.isBefore(prevState.endDateTime, "day");
    const rescheduleHasCompleted = hasActionCompleted(prevProps.reschedule.status, reschedule.status);

    if (endDateHasDecreased && endDateTime.isSameOrBefore(startDateTime)) {
      this.setState({ endDateTime: startDateTime.clone().add(timeStep, "minutes") });
    }

    if (rescheduleHasCompleted) {
      if (rescheduleConflictsEmpty(reschedule.result!.conflicts)) {
        this.props.onSuccess();
      } else {
        this.props.onConflicts(reschedule.settings!, reschedule.result!.conflicts!);
      }
    }
  }

  public render() {
    const { clientName, reschedule } = this.props;
    const { startDateTime, endDateTime, clientNote, internalNote, sendNotification } = this.state;
    const reschedulePending = reschedule.status === "Pending";
    const timeInputDefaultProps: Partial<ITimeInputProps> = {
      className: styles.timeInput,
      inputContainer: { className: styles.inputContainer },
      buttonsContainer: { className: styles.buttonsContainer },
      max: 1440 - timeStep,
      step: timeStep
    };

    return (
      <ModalDialog title="RESCHEDULE SHOOT" noBodyPadding={true} noFooter={true} onCancel={this.onModalCancel}>
        <div className={styles.container}>
          <div className={styles.row}>
            <Field label="Start Date">
              <SingleDateInput
                date={startDateTime}
                placeholder="Date"
                onChangeDate={this.onStartDateChange}
                isOutsideRange={this.isStartDateOutsideRange}
              />
            </Field>
            <Field label="End Date">
              <SingleDateInput
                date={endDateTime}
                placeholder="Date"
                onChangeDate={this.onEndDateChange}
                isOutsideRange={this.isEndDateOutsideRange}
              />
            </Field>
          </div>
          <div className={styles.row}>
            <Field label="Start Time" className={styles.time}>
              <TimeInput
                {...timeInputDefaultProps}
                min={0}
                value={getTotalMinutes(startDateTime)}
                onChange={this.onStartTimeChange}
              />
            </Field>
            <Field label="End Time" className={styles.time}>
              <TimeInput
                {...timeInputDefaultProps}
                min={startDateTime.isSame(endDateTime, "day") ? getTotalMinutes(startDateTime) + timeStep : 0}
                value={getTotalMinutes(endDateTime)}
                onChange={this.onEndTimeChange}
              />
            </Field>
          </div>
          <Field label="Note to Client" optional={true} disabled={!sendNotification} className={styles.clientNote}>
            <FormTextArea
              placeholder={`Explain to ${clientName} why you are rescheduling…`}
              value={clientNote || ""}
              onChange={this.onClientNoteChange}
              maxLength={1000}
              disabled={!sendNotification}
              textAreaClassName={styles.input}
            />
          </Field>
          <Field label="Internal Note" optional={true}>
            <FormTextArea
              placeholder={"Leave yourself a note…"}
              value={internalNote || ""}
              onChange={this.onInternalNoteChange}
              maxLength={1000}
            />
          </Field>
          <div className={styles.footer}>
            <FormCheckbox
              className={styles.sendNotification}
              checked={sendNotification}
              onChange={this.onSendNotificationChange}
            >
              Send notification to client
            </FormCheckbox>
            <button className={styles.cancel} onClick={this.onCancelClick}>
              Cancel
            </button>
            <Button className={styles.save} onClick={this.onSaveClick} styleType="primary" disabled={reschedulePending}>
              Save
            </Button>
          </div>
        </div>
      </ModalDialog>
    );
  }

  private isStartDateOutsideRange = () => false;

  private isEndDateOutsideRange = (date: Moment) =>
    date.isBefore(this.state.startDateTime.clone().add(timeStep, "minutes"), "day");

  private onStartDateChange = (startDate: Moment | null) => {
    this.changeStartDateTime(changeDate(this.state.startDateTime, startDate!));
  };

  private onEndDateChange = (endDate: Moment | null) =>
    this.setState({ endDateTime: changeDate(this.state.endDateTime, endDate!) });

  private onStartTimeChange = (value: number) => {
    this.changeStartDateTime(changeTime(this.state.startDateTime, Math.floor(value / timeStep) * timeStep));
  };

  private onEndTimeChange = (value: number) =>
    this.setState({ endDateTime: changeTime(this.state.endDateTime, Math.ceil(value / timeStep) * timeStep) });

  private onClientNoteChange = (clientNote: string) => this.setState({ clientNote });

  private onInternalNoteChange = (internalNote: string) => this.setState({ internalNote });

  private onSendNotificationChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ sendNotification: event.target.checked });

  private onModalCancel = () => this.props.onClose();

  private onCancelClick = () => this.props.onClose();

  private onSaveClick = () =>
    this.props.reschedule.onReschedule(this.props.bookingId, {
      startDateTime: this.state.startDateTime,
      endDateTime: this.state.endDateTime,
      clientNote: this.state.clientNote,
      internalNote: this.state.internalNote,
      sendNotification: this.state.sendNotification
    });

  private changeStartDateTime = (startDateTime: Moment) => {
    const duration = this.state.endDateTime.diff(this.state.startDateTime, "minutes");
    this.setState({ startDateTime, endDateTime: startDateTime.clone().add(duration, "minutes") });
  };
}

const timeStep = 5;

export default withStore(Reschedule);
