import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Toastr from "../../components/toastr";
import { removeNotification } from "../../store/notification/actions";
import { IAppState } from "../../store/state";

function mapStateToProps(state: IAppState) {
  return {
    version: state.notifications.version,
    notification: state.notifications.notification
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, any>) {
  return {
    onRemoveNotification: () => dispatch(removeNotification())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Toastr);
