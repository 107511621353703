import React from "react";

const IconAlignLeft = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8H6V7H12V8ZM18 10V11H6V10H18ZM6 14H12V13H6V14ZM18 16V17H6V16H18Z"
    />
  </svg>
);

export default IconAlignLeft;
