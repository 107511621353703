import { Dispatch } from "redux";
import { IApiServices } from "../../api";
import IError from "../../utilities/error";
import { IAppState } from "../state";
import { ActionType } from "./constants";
import {
  IBooking,
  IBookingBase,
  IBookingPatch,
  IBookingsQuery,
  IBookingStatus,
  IBookingUpdater,
  IRescheduleResult,
  IRescheduleSettings
} from "./model";

interface IGetBookingStarted {
  type: ActionType.GET_BOOKING_STARTED;
  bookingId: string;
}

interface IGetBookingSuccess {
  type: ActionType.GET_BOOKING_SUCCESS;
  booking: IBookingBase;
}

interface IGetBookingError {
  type: ActionType.GET_BOOKING_ERROR;
  bookingId: string;
  error: IError;
}

export type GetBookingAction = IGetBookingStarted | IGetBookingSuccess | IGetBookingError;

const getBookingStarted = (bookingId: string): IGetBookingStarted => ({
  type: ActionType.GET_BOOKING_STARTED,
  bookingId
});

const getBookingSuccess = (booking: IBookingBase): IGetBookingSuccess => ({
  type: ActionType.GET_BOOKING_SUCCESS,
  booking
});

const getBookingError = (bookingId: string, error: IError): IGetBookingError => ({
  type: ActionType.GET_BOOKING_ERROR,
  bookingId,
  error
});

export const getBooking = (bookingId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(getBookingStarted(bookingId));
    try {
      const booking = await apiService.bookings.getBooking(bookingId);
      dispatch(getBookingSuccess(booking));
    } catch (error) {
      dispatch(getBookingError(bookingId, error));
    }
  };
};

interface ILoadStarted {
  type: ActionType.LOAD_STARTED;
  query: IBookingsQuery;
}

interface ILoadSuccess {
  type: ActionType.LOAD_SUCCESS;
  query: IBookingsQuery;
  bookings: IBooking[];
}

interface ILoadError {
  type: ActionType.LOAD_ERROR;
  query: IBookingsQuery;
  error: IError;
}

export type LoadAction = ILoadStarted | ILoadSuccess | ILoadError;

const loadStarted = (query: IBookingsQuery): ILoadStarted => ({
  type: ActionType.LOAD_STARTED,
  query
});

const loadSuccess = (query: IBookingsQuery, bookings: IBooking[]): ILoadSuccess => ({
  type: ActionType.LOAD_SUCCESS,
  query,
  bookings
});

const loadError = (query: IBookingsQuery, error: IError): ILoadError => ({
  type: ActionType.LOAD_ERROR,
  query,
  error
});

export const load = (query: IBookingsQuery) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(loadStarted(query));
    try {
      const bookings = await apiService.bookings.load(query);
      dispatch(loadSuccess(query, bookings));
    } catch (error) {
      dispatch(loadError(query, error));
    }
  };
};

interface IUpdateStarted {
  type: ActionType.UPDATE_STARTED;
  bookingId: string;
  updater: IBookingUpdater;
}

interface IUpdateSuccess {
  type: ActionType.UPDATE_SUCCESS;
  bookingId: string;
  updater: IBookingUpdater;
}

interface IUpdateError {
  type: ActionType.UPDATE_ERROR;
  bookingId: string;
  updater: IBookingUpdater;
  error: IError;
}

export type UpdateAction = IUpdateStarted | IUpdateSuccess | IUpdateError;

const updateStarted = (bookingId: string, updater: IBookingUpdater): IUpdateStarted => ({
  type: ActionType.UPDATE_STARTED,
  bookingId,
  updater
});

const updateSuccess = (bookingId: string, updater: IBookingUpdater): IUpdateSuccess => ({
  type: ActionType.UPDATE_SUCCESS,
  bookingId,
  updater
});

const updateError = (bookingId: string, updater: IBookingUpdater, error: IError): IUpdateError => ({
  type: ActionType.UPDATE_ERROR,
  bookingId,
  updater,
  error
});

export const update = (bookingId: string, updater: IBookingUpdater) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(updateStarted(bookingId, updater));
    try {
      await apiService.bookings.update(bookingId, updater);
      dispatch(updateSuccess(bookingId, updater));
    } catch (error) {
      dispatch(updateError(bookingId, updater, error));
    }
  };
};

interface ICancelStarted {
  type: ActionType.CANCEL_STARTED;
  bookingId: string;
  note?: string;
}

interface ICancelSuccess {
  type: ActionType.CANCEL_SUCCESS;
  bookingId: string;
}

interface ICancelError {
  type: ActionType.CANCEL_ERROR;
  bookingId: string;
  error: IError;
}

export type CancelAction = ICancelStarted | ICancelSuccess | ICancelError;

const cancelStarted = (bookingId: string, note?: string): ICancelStarted => ({
  type: ActionType.CANCEL_STARTED,
  bookingId,
  note
});

const cancelSuccess = (bookingId: string): ICancelSuccess => ({
  type: ActionType.CANCEL_SUCCESS,
  bookingId
});

const cancelError = (bookingId: string, error: IError): ICancelError => ({
  type: ActionType.CANCEL_ERROR,
  bookingId,
  error
});

export const cancel = (bookingId: string, note?: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(cancelStarted(bookingId, note));
    try {
      await apiService.bookings.cancel(bookingId, note);
      dispatch(cancelSuccess(bookingId));
    } catch (error) {
      dispatch(cancelError(bookingId, error));
    }
  };
};

interface IApproveStarted {
  type: ActionType.APPROVE_STARTED;
  bookingId: string;
  note?: string;
}

interface IApproveSuccess {
  type: ActionType.APPROVE_SUCCESS;
  bookingId: string;
  bookingStatus: IBookingStatus;
}

interface IApproveError {
  type: ActionType.APPROVE_ERROR;
  bookingId: string;
  error: IError;
}

export type ApproveAction = IApproveStarted | IApproveSuccess | IApproveError;

const approveStarted = (bookingId: string, note?: string): IApproveStarted => ({
  type: ActionType.APPROVE_STARTED,
  bookingId,
  note
});

const approveSuccess = (bookingId: string, bookingStatus: IBookingStatus): IApproveSuccess => ({
  type: ActionType.APPROVE_SUCCESS,
  bookingId,
  bookingStatus
});

const approveError = (bookingId: string, error: IError): IApproveError => ({
  type: ActionType.APPROVE_ERROR,
  bookingId,
  error
});

export const approve = (bookingId: string, note?: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(approveStarted(bookingId, note));
    try {
      const bookingStatus = await apiService.bookings.approve(bookingId, note);
      dispatch(approveSuccess(bookingId, bookingStatus));
    } catch (error) {
      dispatch(approveError(bookingId, error));
    }
  };
};

interface IDeclineStarted {
  type: ActionType.DECLINE_STARTED;
  bookingId: string;
  note: string;
}

interface IDeclineSuccess {
  type: ActionType.DECLINE_SUCCESS;
  bookingId: string;
}

interface IDeclineError {
  type: ActionType.DECLINE_ERROR;
  bookingId: string;
  error: IError;
}

export type DeclineAction = IDeclineStarted | IDeclineSuccess | IDeclineError;

const declineStarted = (bookingId: string, note: string): IDeclineStarted => ({
  type: ActionType.DECLINE_STARTED,
  bookingId,
  note
});

const declineSuccess = (bookingId: string): IDeclineSuccess => ({
  type: ActionType.DECLINE_SUCCESS,
  bookingId
});

const declineError = (bookingId: string, error: IError): IDeclineError => ({
  type: ActionType.DECLINE_ERROR,
  bookingId,
  error
});

export const decline = (bookingId: string, note: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(declineStarted(bookingId, note));
    try {
      await apiService.bookings.decline(bookingId, note);
      dispatch(declineSuccess(bookingId));
    } catch (error) {
      dispatch(declineError(bookingId, error));
    }
  };
};

interface IRescheduleStarted {
  type: ActionType.RESCHEDULE_STARTED;
  bookingId: string;
  settings: IRescheduleSettings;
  force?: boolean;
}

interface IRescheduleSuccess {
  type: ActionType.RESCHEDULE_SUCCESS;
  bookingId: string;
  result: IRescheduleResult;
}

interface IRescheduleError {
  type: ActionType.RESCHEDULE_ERROR;
  bookingId: string;
  error: IError;
}

export type RescheduleAction = IRescheduleStarted | IRescheduleSuccess | IRescheduleError;

const rescheduleStarted = (bookingId: string, settings: IRescheduleSettings, force?: boolean): IRescheduleStarted => ({
  type: ActionType.RESCHEDULE_STARTED,
  bookingId,
  settings,
  force
});

const rescheduleSuccess = (bookingId: string, result: IRescheduleResult): IRescheduleSuccess => ({
  type: ActionType.RESCHEDULE_SUCCESS,
  bookingId,
  result
});

const rescheduleError = (bookingId: string, error: IError): IRescheduleError => ({
  type: ActionType.RESCHEDULE_ERROR,
  bookingId,
  error
});

export const reschedule = (bookingId: string, settings: IRescheduleSettings, force?: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState, apiService: IApiServices) => {
    dispatch(rescheduleStarted(bookingId, settings, force));
    try {
      const result = await apiService.bookings.reschedule(bookingId, settings, force);
      dispatch(rescheduleSuccess(bookingId, result));
    } catch (error) {
      dispatch(rescheduleError(bookingId, error));
    }
  };
};

interface IPatchBooking {
  type: ActionType.PATCH_BOOKING;
  patch: IBookingPatch;
}

export type PatchAction = IPatchBooking;

export const patchBooking = (patch: IBookingPatch): IPatchBooking => ({
  type: ActionType.PATCH_BOOKING,
  patch
});
